/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { RESEND_INVITE } from 'gql/invitation';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const ResendInviteModal = props => {
  const { isModalOpen, onChange, invite } = props;
  const { id } = invite;

  const [resendInvitation, { error: resendError, data: resendData }] = useMutation(RESEND_INVITE);

  useEffect(() => {
    if (resendData) {
      showToast('Invite has been resent.', 'success');
      onChange({ isModalOpen: false });
    }
  }, [resendData]);

  useEffect(() => {
    if (resendError) {
      showToast(resendError.message, 'error');
      onChange({ isModalOpen: false });
    }
  }, [resendError]);

  return (
    <>
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Resend Invitation"
        body="Are you sure you want to resend the Invitation?"
        cancelTitle="Cancel"
        confirmTitle="Resend"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => resendInvitation({ variables: { id } })}
      />
    </>
  );
};

ResendInviteModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  invite: PropTypes.shape().isRequired
};

export default ResendInviteModal;
