import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Typography, Button, IconButton } from '@material-ui/core';
import {
  Add as ShowIcon,
  Remove as HideIcon,
  PersonAdd as UsersIcon,
  LibraryAdd as AddIcon,
  BorderColor as EditIcon,
  PlayForWork as SwitchIcon
} from '@material-ui/icons';
import { DefaultContainerAvatar } from 'util/assets';
import { CONTAINER } from 'gql/container';
import { switchContainer } from 'actions/authActions';
import AlbError from 'components/AlbError';
import HasAnyPriv from 'components/HasAnyPriv';
import { EditContainerPath, ManageUsersPath, ObserveEventsPathV4 } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import store from 'store';
import AddContainer from './AddContainer';

const style = {
  widget: {
    height: '62px',
    padding: '13px 20px',
    borderBottom: 'solid 1px rgba(13, 26, 58, 0.2)'
  },
  widgetContent: {
    display: 'flex',
    alignItems: 'center'
  },
  widgetAction: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  displayButton: {
    width: '28px',
    height: '28px',
    marginRight: '12px',
    borderRadius: '100%',
    padding: '0px'
  },
  containerAvatar: {
    width: '28px',
    height: '28px',
    marginRight: '18px'
  },
  containerTitle: {
    fontSize: '13px',
    color: '#6f6f6f'
  },
  buttonIcons: {
    marginRight: '4px'
  }
};

class ContainerWidget extends Component {
  constructor(props) {
    super(props);
    const { showSubContainer } = this.props;
    this.state = {
      showSubContainer,
      showAddContainer: false,
      hover: false
    };
  }

  handleRouting = path => {
    const { history } = this.props;
    this.setState({
      showSubContainer: false,
      showAddContainer: false,
      hover: false
    });

    goToRoute(path, history);
  };

  toggleSubContainer = () => {
    const { showSubContainer } = this.state;
    this.setState({
      showSubContainer: !showSubContainer
    });
  };

  handleShowOverlay = () => {
    this.setState({
      hover: true
    });
  };

  handleHideOverlay = () => {
    this.setState({
      hover: false
    });
  };

  toggleAddContainer = () => {
    const { showAddContainer } = this.state;
    this.setState({
      showAddContainer: !showAddContainer
    });
  };

  handleEditContainer = () => {
    const { history, containerId } = this.props;
    goToRoute(`${EditContainerPath}?id=${containerId}`, history);
  };

  handleUserManagement = () => {
    const { history, containerId } = this.props;
    goToRoute(`${ManageUsersPath}?id=${containerId}`, history);
  };

  render() {
    const { classes, history, containerId, masterContainer } = this.props;
    const { showAddContainer, showSubContainer, hover } = this.state;

    return (
      <Query query={CONTAINER} variables={{ id: containerId }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return null;
          if (error) return <AlbError error={error} refetch={refetch} />;
          return (
            <Fragment>
              <Grid
                container
                onMouseEnter={this.handleShowOverlay}
                onMouseLeave={this.handleHideOverlay}
                className={classes.widget}
              >
                <Grid
                  item
                  className={classes.widgetContent}
                  style={{
                    paddingLeft: `${40 * (data.container.depth - 1) +
                      (!masterContainer && data.container.children.length === 0 ? 40 : 0)}px`
                  }}
                >
                  {(!masterContainer && data.container.children.length) > 0 && (
                    <IconButton
                      color="primary"
                      onClick={this.toggleSubContainer}
                      className={classes.displayButton}
                    >
                      {showSubContainer && <HideIcon />}
                      {!showSubContainer && <ShowIcon />}
                    </IconButton>
                  )}
                  <Avatar
                    alt="Container Avatar"
                    src={data.container.avatar_url || DefaultContainerAvatar}
                    className={classes.containerAvatar}
                  />
                  <Typography className={classes.containerTitle}>{data.container.name}</Typography>
                </Grid>
                {hover && (
                  <Grid item className={classes.widgetAction}>
                    <Button
                      color="primary"
                      onClick={() => {
                        store
                          .dispatch(switchContainer(containerId))
                          // .then(() => this.handleRouting(OptimizePath));
                          .then(() => this.handleRouting(ObserveEventsPathV4));
                      }}
                    >
                      <SwitchIcon className={classes.buttonIcons} />
                      Select Container
                    </Button>

                    <HasAnyPriv privs={['ORG:UPDATE']}>
                      <Button color="primary" onClick={this.handleEditContainer}>
                        <EditIcon className={classes.buttonIcons} />
                        Edit
                      </Button>
                    </HasAnyPriv>

                    <HasAnyPriv privs={['USERS', 'USERS:CREATE', 'USERS:DELETE', 'USERS:UPDATE']}>
                      <Button color="primary" onClick={this.handleUserManagement}>
                        <UsersIcon className={classes.buttonIcons} />
                        Manage Users
                      </Button>
                    </HasAnyPriv>

                    <HasAnyPriv privs={['ORG:CREATE']}>
                      <Button color="primary" onClick={this.toggleAddContainer}>
                        <AddIcon className={classes.buttonIcons} />
                        Add Container
                      </Button>
                    </HasAnyPriv>
                  </Grid>
                )}
              </Grid>
              {showAddContainer && (
                <AddContainer
                  data={data}
                  parentRefetch={refetch}
                  closeCallback={this.toggleAddContainer}
                />
              )}
              {showSubContainer &&
                data.container.children.map(child => (
                  <ContainerWidget
                    key={child.id}
                    classes={classes}
                    history={history}
                    containerId={child.id}
                  />
                ))}
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

ContainerWidget.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  containerId: PropTypes.string.isRequired,
  showSubContainer: PropTypes.bool,
  masterContainer: PropTypes.bool
};

ContainerWidget.defaultProps = {
  showSubContainer: false,
  masterContainer: false
};

export default withRouter(withStyles(style)(ContainerWidget));
