import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import { partition } from 'lodash';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import getValueAbbreviation from 'util/getValueAbbreviation';
import round from 'util/round';

const graphHeight = 415;

const useStyles = makeStyles({
  graphTabs: {
    flexWrap: 'nowrap',
    display: 'grid',
    gridTemplateColumns: props => `${props.left}fr ${props.right}fr`
  },
  graphBody: {
    position: 'relative',
    padding: '8px 24px 24px'
  },
  graphValues: {
    marginBottom: '8px'
  },
  graphFooter: {
    marginBottom: '8px'
  },
  graphTotals: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F',

    '& :first-child': {
      fontSize: '14px'
    }
  },
  funnelContainer: {
    display: 'grid',
    gridTemplateColumns: props => `${props.left}fr ${props.right}fr`
  },
  funnel: {
    position: 'relative',
    display: 'grid',
    alignItems: 'center',
    flex: 1,
    gridTemplateColumns: props => `repeat(${props.columns + 1}, 1fr)`,
    height: `${graphHeight}px`,
    marginBottom: '-10px'
  },
  funnelLabels: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '0px',
    right: '0px',
    textAlign: 'right',
    paddingRight: '10px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F'
  },
  dataValues: {
    display: 'grid',
    alignItems: 'center',
    flex: 1,
    gridTemplateColumns: props => `1.5fr repeat(${props.columns}, 1fr) 0.5fr`
  },
  footer: {
    display: 'grid',
    alignItems: 'center',
    flex: 1,
    gridTemplateColumns: props => `1.5fr ${props.first}fr ${props.second}fr 0.5fr`
  },
  column: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  columnLabel: {
    position: 'absolute',
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F',
    marginBottom: '10px'
  },
  columnLabelAnchor: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    textAlign: 'center'
  },
  bar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props => props.backgroundColor,
    height: props => props.height,
    fontWeight: 500,
    fontSize: '12px',
    color: '#6F6F6F'
  },
  barValueText: {
    color: 'white',
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  lines: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '50%',
    top: '20px',
    border: '1px solid #979797',
    borderLeft: '0px',
    borderTop: '0px',
    backgroundColor: 'transparent'
  },
  lineAnchor: {
    position: 'absolute',
    right: '0px',
    bottom: '-20px'
  },
  lineLeftAnchor: {
    position: 'absolute',
    left: '0px',
    bottom: '-20px'
  },
  lineFirst: {
    position: 'absolute',
    top: '100%',
    height: '20px',
    backgroundColor: '#979797',
    width: '1px'
  },
  lineLeft: {
    position: 'absolute',
    top: '100%',
    height: '20px',
    backgroundColor: '#979797',
    width: '1px'
  },
  line: {
    position: 'absolute',
    top: '100%',
    borderRight: '1px solid #979797'
  },
  lineFooter: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  lineHorizontal: {
    position: 'absolute',
    width: '100%',
    flex: 1,
    backgroundColor: '#C4C4C4',
    height: '1px'
  },
  lineSpacer: {
    position: 'absolute',
    width: props => `${props.spacerWidth}%`,
    height: '14px',
    right: '100%',
    backgroundColor: 'white',
    borderLeft: '1px solid #C4C4C4',
    borderRight: '1px solid #C4C4C4'
  },
  valueLabels: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F'
  },
  footerText: {
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F',
    padding: '0px 10px',
    zIndex: 1
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  inputRoot: {
    '& input': {
      padding: '4px 5px 5px'
    }
  }
});

const funnelHeight = [80, 70.5, 61, 51.5, 42, 32.5, 23, 13.5];
const leadsGradient = ['#32327D', '#47478A', '#5B5B97', '#706FA3', '#8484B1', '#9898BE', '#ADADCB'];
const opportunitiesGradient = [
  '#C40075',
  '#CA1983',
  '#D03391',
  '#D64D9E',
  '#DC66AC',
  '#E180BA',
  '#E799C8'
];

const GraphColumn = props => {
  const { name, count, value, index, left, first, last } = props;

  const columnIndex = left ? index : index + 1;
  const columnValue = left ? count.toLocaleString() : `(${count.toLocaleString()})`;

  const classes = useStyles({
    height: `${funnelHeight[columnIndex]}%`,
    backgroundColor: left ? leadsGradient[index] : opportunitiesGradient[index]
  });

  return (
    <Grid className={classes.column}>
      <Grid item className={classes.columnLabelAnchor}>
        <span className={classes.columnLabel}>{name}</span>
      </Grid>
      <Grid className={classes.bar}>
        {first && !left && (
          <>
            <div className={classes.lineFirst} style={{ right: '75%' }} />
            <div
              className={classes.lines}
              style={{
                width: '50%',
                height: '50%',
                left: 'unset',
                right: '75%',
                borderLeft: '1px solid #979797',
                borderRight: '0px',
                top: '50%',
                marginTop: '20px'
              }}
            />
            <span className={classes.lineLeftAnchor}>
              <div
                className={classes.lineLeft}
                style={{
                  height:
                    ((funnelHeight.slice().reverse()[columnIndex] / 100) * graphHeight) / 2 - 20
                }}
              />
            </span>
          </>
        )}
        {first && <div className={classes.lineFirst} />}
        {!last && (
          <>
            <div className={classes.lines} />
            <span className={classes.lineAnchor}>
              <div
                className={classes.line}
                style={{
                  height:
                    ((funnelHeight.slice().reverse()[columnIndex] / 100) * graphHeight) / 2 - 20
                }}
              />
            </span>
          </>
        )}
        <span className={classes.barValueText}>
          <span>{columnValue}</span>
          {/* assuming it will always be revenue */}
          <span>{value != null && getValueAbbreviation(value, true)}</span>
        </span>
      </Grid>
    </Grid>
  );
};

GraphColumn.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  value: PropTypes.number,
  index: PropTypes.number.isRequired,
  left: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool
};

GraphColumn.defaultProps = {
  value: null,
  left: false,
  first: false,
  last: false
};

const GraphFunnel = props => {
  const { data, labels, left } = props;

  const [all, selected] = labels;

  const classes = useStyles({ columns: data.length - (left ? 0 : 1) });

  return (
    <Grid className={classes.funnel} style={{ zIndex: left ? 2 : 1 }}>
      {left && (
        <Grid
          style={{
            backgroundColor: 'transparent',
            fontWeight: 500,
            fontSize: '12px',
            color: '#6F6F6F',
            textAlign: 'center'
          }}
          className={classes.column}
        >
          Total
        </Grid>
      )}
      {data.map((column, i) => (
        <GraphColumn
          key={column.id}
          {...column}
          index={i}
          left={left}
          first={i === 0}
          last={i === data.length - 1}
        />
      ))}
      <Grid className={classes.funnelLabels} item>
        <span>{all}</span>
        <span>{selected}</span>
      </Grid>
    </Grid>
  );
};

GraphFunnel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  left: PropTypes.bool
};

GraphFunnel.defaultProps = {
  left: false,
  labels: []
};

const GraphValues = props => {
  const { data } = props;
  const [targetPct, setTargetPct] = useState(round(data?.[0]?.conversionPct ?? 0));
  const classes = useStyles({ columns: data.length });

  const updateTargetValues = () => {
    const conversions = [];
    let carry;

    if (data?.length > 0) {
      carry = data[0].count * (targetPct / 100);
      conversions.push(carry);

      for (let i = 1; i < data.length; i += 1) {
        carry = (data[i].count + carry) * data[i].conversionPct;
        conversions.push(carry);
      }
    }

    return conversions;
  };

  const newConversionCounts = updateTargetValues();

  return (
    <Grid className={classes.dataValues}>
      <Grid item className={classes.valueLabels}>
        <Grid item>Conversion %</Grid>
        <Grid item style={{ color: '#979797' }}>
          Target %
        </Grid>
        <Grid item>Value (Avg.)</Grid>
        <Grid item>Length (Avg.)</Grid>
      </Grid>
      {data.map((
        { id, conversionPct, conversionCount, valueAvg, lengthAvg }, // name, // order, // conversionCount
        index
      ) => (
        <Grid key={id} item className={classes.valueLabels} style={{ textAlign: 'center' }}>
          <Grid item>{`${round(conversionPct * 100)}% (${Math.floor(conversionCount)})`}</Grid>
          <Grid item style={{ color: '#979797' }}>
            {index === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  type="number"
                  variant="outlined"
                  value={targetPct}
                  onChange={event => setTargetPct(event.target.value)}
                  style={{ width: '50px' }}
                  classes={{
                    root: classes.inputRoot
                  }}
                />
                {`% (${Math.floor(newConversionCounts[index])})`}
              </div>
            ) : (
              `${round(conversionPct * 100)}% (${Math.floor(newConversionCounts[index])})`
            )}
          </Grid>
          <Grid item>{valueAvg == null ? '-' : getValueAbbreviation(valueAvg, true)}</Grid>
          <Grid item>
            {Math.ceil(round(lengthAvg))} Day
            {lengthAvg === 1 ? '' : 's'}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

GraphValues.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const GraphFooter = props => {
  const { data, leadToOpportunity, opportunityToClose } = props;
  const [leads, opportunities] = data;
  const [leadToOpportunityAvg, leadToOpportunityPct] = leadToOpportunity;
  const [opportunityToCloseAvg, opportunityToClosePct] = opportunityToClose;

  // grid allocation for second line
  const second = opportunities.length - 1;

  const classes = useStyles({
    first: leads.length,
    second,
    spacerWidth: (100 / second) * 0.25
  });

  return (
    <Grid className={classes.footer}>
      <Grid item />
      <Grid item className={classes.lineFooter}>
        <span style={{ width: '1px', height: '14px', backgroundColor: '#C4C4C4' }} />
        <Grid item className={classes.footerText}>
          <span>
            {`${Math.round((leadToOpportunityPct + Number.EPSILON) * 100) / 100}%`}
            {' / '}
            {Math.ceil(Math.round((leadToOpportunityAvg + Number.EPSILON) * 100) / 100)} Day
            {leadToOpportunityAvg === 1 ? '' : 's'}
          </span>
          <span>Lead to Opportunity</span>
        </Grid>
        <span />
        {/* absolute positioned elements */}
        <Grid item className={classes.lineHorizontal} />
      </Grid>
      <Grid item className={classes.lineFooter}>
        <span />
        <Grid item className={classes.footerText}>
          <span>
            {`${Math.round((opportunityToClosePct + Number.EPSILON) * 100) / 100}%`}
            {' / '}
            {Math.ceil(Math.round((opportunityToCloseAvg + Number.EPSILON) * 100) / 100)} Day
            {opportunityToCloseAvg === 1 ? '' : 's'}
          </span>
          <span>Opportunity to Close</span>
        </Grid>
        <span style={{ width: '1px', height: '14px', backgroundColor: '#C4C4C4' }} />
        {/* absolute positioned elements */}
        <Grid item className={classes.lineSpacer} />
        <Grid item className={classes.lineHorizontal} />
      </Grid>
      <Grid item />
    </Grid>
  );
};

GraphFooter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  leadToOpportunity: PropTypes.arrayOf(PropTypes.number).isRequired,
  opportunityToClose: PropTypes.arrayOf(PropTypes.number).isRequired
};

const LeadsAndOpportunitiesGraph = props => {
  const { data, loading, error } = props;

  const {
    steps,
    states,
    category,
    leadCount,
    selectedLeadCount,
    opportunityCount,
    selectedOpportunityCount,
    leadToOpportunityAvg,
    leadToOpportunityPct,
    opportunityToCloseAvg,
    opportunityToClosePct,
    opportunityValueAvg,
    lengthAvg
  } = data;

  const [leads, opportunities] = partition(states, ({ id }) => {
    const [type] = id.split(':');

    return type === 'status';
  });

  const classes = useStyles({
    left: leads.length + 1,
    right: opportunities.length
  });

  return (
    <div>
      <Grid container item className={classes.graphTabs}>
        <BoxHeader tabs={['Leads']} />
        <BoxHeader tabs={['Opportunities']} />
      </Grid>
      <Grid container className={classes.graphBody}>
        {error && <AlbError error={error} />}
        {loading && (
          <Grid item className={classes.loadingOverlay}>
            <AlbLoading />
          </Grid>
        )}
        <Grid container style={{ opacity: loading ? 0.5 : 1 }}>
          <Grid container className={classes.funnelContainer}>
            <GraphFunnel
              data={leads}
              labels={[
                `All Leads: ${leadCount || 0}`,
                `In Selected Statuses: ${selectedLeadCount || 0}`
              ]}
              left
            />
            <GraphFunnel
              data={opportunities}
              labels={[
                `All Opportunities: ${opportunityCount || 0}`,
                `In Selected Stages: ${selectedOpportunityCount || 0}`
              ]}
            />
          </Grid>
          <Grid container className={classes.graphValues}>
            <GraphValues data={steps} />
          </Grid>
          <Grid container className={classes.graphFooter}>
            <GraphFooter
              data={[leads, opportunities]}
              leadToOpportunity={[leadToOpportunityAvg, leadToOpportunityPct]}
              opportunityToClose={[opportunityToCloseAvg, opportunityToClosePct]}
            />
          </Grid>
          <Grid container className={classes.graphTotals}>
            <Grid item>Showing: {category.toUpperCase()}</Grid>
            <Grid item>
              Avg. Opportunity Value: {getValueAbbreviation(opportunityValueAvg, true)}
            </Grid>
            <Grid item>
              Avg. Length: {Math.ceil(Math.round((lengthAvg + Number.EPSILON) * 100) / 100)} Day
              {lengthAvg === 1 ? '' : 's'}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

LeadsAndOpportunitiesGraph.propTypes = {
  data: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.shape({})),
    states: PropTypes.arrayOf(PropTypes.shape({})),
    category: PropTypes.string,
    leadCount: PropTypes.number,
    selectedLeadCount: PropTypes.number,
    opportunityCount: PropTypes.number,
    selectedOpportunityCount: PropTypes.number,
    leadToOpportunityAvg: PropTypes.number,
    leadToOpportunityPct: PropTypes.number,
    opportunityToCloseAvg: PropTypes.number,
    opportunityToClosePct: PropTypes.number,
    opportunityValueAvg: PropTypes.number,
    lengthAvg: PropTypes.number
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.shape({})
};

LeadsAndOpportunitiesGraph.defaultProps = {
  loading: false,
  error: null
};

export default LeadsAndOpportunitiesGraph;
