/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Tabs, Tab, Typography } from '@material-ui/core';
import AlbBarGraph from 'components/AlbGraphs/AlbBarGraph';
import { getEndDate, renderUILongDate } from 'util/date';
import { Palette } from 'components/AlbGraphs/Legend';
import { topPostsColors } from 'util/colors';

const useStyles = makeStyles({
  paper: {
    borderRadius: '0px',
    margin: '25px 15px'
  },
  titleText: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    padding: '0px 24px'
  },
  content: {
    flex: '1',
    padding: '16px 24px',
    paddingBottom: '24px'
  }
});

const ShareOfVoiceMindshare = props => {
  const { shareOfVoiceReport, dates } = props;

  const classes = useStyles();

  const [datasets, setDatasets] = useState([]);
  const [legend, setLegend] = useState({ palette: [] });

  const startDate = dates.start;
  const endDate = dates.end;

  const labels = Array.from(Array(Math.abs(startDate.diff(endDate, 'months')) + 1), (_, i) =>
    startDate
      .clone()
      .add(i, 'M')
      .format("MMM 'YY")
  );

  useEffect(() => {
    let customLegend = { palette: [] };
    let dataSets = [];

    if (shareOfVoiceReport?.article_searches?.length) {
      const articleSearches = shareOfVoiceReport.article_searches.map((articleSearch, i) => {
        const { name, getArticleShareCountByMonth } = articleSearch;

        const totalSharesByMonthArr = getArticleShareCountByMonth.map(
          ({ period_start_date, total_articles }) => {
            return {
              period_start_date: moment.utc(period_start_date).format("MMM 'YY"),
              total_articles
            };
          }
        );

        const totalSharesByMonth = totalSharesByMonthArr.reduce((acc, obj) => {
          acc[obj.period_start_date] = obj.total_articles;
          return acc;
        }, {});

        const backgroundColor = topPostsColors[i % topPostsColors.length];

        return {
          type: 'line',
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          backgroundColor,
          borderColor: backgroundColor,
          pointHoverBackgroundColor: backgroundColor,
          pointHoverBorderColor: backgroundColor,
          label: name,
          data: labels.map(month => totalSharesByMonth[month] || 0)
        };
      });

      const searchesPalette = articleSearches.map(({ label }, i) => ({
        key: label + i,
        label,
        color: topPostsColors[i % topPostsColors.length]
      }));

      customLegend = {
        palette: [...customLegend.palette, ...searchesPalette]
      };

      dataSets = [...articleSearches];
    }

    if (shareOfVoiceReport?.article_search_filters?.length) {
      const articleSearchFilters = shareOfVoiceReport.article_search_filters.map((filter, i) => {
        const { id, article_search_id: articleSearchId, getArticleShareCountByMonth } = filter;

        const totalSharesByMonthArr = getArticleShareCountByMonth.map(
          ({ period_start_date, total_articles }) => {
            return {
              period_start_date: moment.utc(period_start_date).format("MMM 'YY"),
              total_articles
            };
          }
        );

        const totalSharesByMonth = totalSharesByMonthArr.reduce((acc, obj) => {
          acc[obj.period_start_date] = obj.total_articles;
          return acc;
        }, {});

        const backgroundColor = topPostsColors[i % topPostsColors.length];

        return {
          type: 'line',
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          backgroundColor,
          borderColor: backgroundColor,
          pointHoverBackgroundColor: backgroundColor,
          pointHoverBorderColor: backgroundColor,
          label: `Search ${articleSearchId} Filter id: ${id}`,
          data: labels.map(month => totalSharesByMonth[month] || 0)
        };
      });

      const filtersPalette = articleSearchFilters.map(({ label }, i) => ({
        key: label + i,
        label,
        color: topPostsColors[i % topPostsColors.length]
      }));

      customLegend = {
        palette: [...customLegend.palette, ...filtersPalette]
      };

      dataSets = [...dataSets, ...articleSearchFilters];
    }

    setLegend(customLegend);
    setDatasets(dataSets);
  }, [shareOfVoiceReport]);

  return (
    <Paper className={classes.paper}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Tabs value={0} onChange={() => {}}>
          <Tab
            label="Mindshare Over Time"
            classes={{
              root: classes.tabOverRide
            }}
          />
        </Tabs>
        <Typography
          variantMapping={{ body1: 'span' }}
          className={classes.titleText}
          style={{ color: '#979797' }}
        >
          {renderUILongDate(startDate)} - {getEndDate(renderUILongDate(endDate))}
        </Typography>
      </Grid>
      <div className={classes.content}>
        <AlbBarGraph data={{ datasets, labels }} stacked={false} />
        <Palette legend={legend} />
      </div>
    </Paper>
  );
};

ShareOfVoiceMindshare.propTypes = {
  shareOfVoiceReport: PropTypes.shape({
    article_searches: PropTypes.arrayOf(PropTypes.shape()),
    article_search_filters: PropTypes.arrayOf(PropTypes.shape())
  }),
  dates: PropTypes.shape().isRequired
};

ShareOfVoiceMindshare.defaultProps = {
  shareOfVoiceReport: {}
};

export default ShareOfVoiceMindshare;
