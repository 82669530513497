import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { shortenText } from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import { BOX_DEMOGRAPHIC_WIDGET } from 'gql/analytics';
import { getDatesForAnalytics } from 'util/date';
import BoxHeader from './BoxHeader';
import BoxSocial from './BoxSocial';

const useStyles = makeStyles({
  box: {
    flex: 1,
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  content: {
    padding: '24px'
  },
  label: {
    fontSize: '30px',
    fontWeight: 500,
    color: '#32327D'
  },
  second: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#32327D'
  },
  secondSubtext: {
    color: '#6F6F6F'
  }
});

const BoxContentTop = props => {
  const { title, widget, accounts, dates, onlyVideo, prefix } = props;

  const classes = useStyles();

  const [label, setLabel] = useState('-');
  const [second, setSecond] = useState('');
  const [activeChannels, setActiveChannels] = useState([]);

  // Top Demographic, Top Country, Top City

  // To match the analytics v2 graphs and tables which always show UTC, use this Analytics function instead of converting to user timezone
  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { loading, error, data } = useQuery(BOX_DEMOGRAPHIC_WIDGET, {
    variables: {
      startDate,
      endDate,
      linkTokens: accounts.map(({ id }) => id),
      widget,
      onlyVideo
    }
  });

  useEffect(() => {
    if (data?.analyticsDemographicWidget) {
      const [primary, secondary] = data.analyticsDemographicWidget;

      setLabel(shortenText[primary?.metric] || primary?.metric || '-');
      setSecond(shortenText[secondary?.metric] || secondary?.metric || '');
    }
  }, [data]);

  useEffect(() => {
    setActiveChannels(accounts.map(({ platform }) => platform.displayName));
  }, [accounts]);

  return (
    <Box className={classes.box}>
      <BoxHeader tabs={title} prefix={prefix} />
      {loading && (
        <Grid container className={classes.content}>
          <AlbLoading />
        </Grid>
      )}
      {!loading && (
        <Grid container direction="column" align="center" className={classes.content}>
          {error && <AlbError error={error} />}
          <Grid item className={classes.label}>
            {label.toLocaleString()}
          </Grid>
          {second && (
            <Grid item className={classes.second}>
              2nd
              <span className={classes.secondSubtext}>{` = ${second}`}</span>
            </Grid>
          )}
          <BoxSocial
            activeChannels={activeChannels}
            contributingChannels={['Facebook', 'Instagram', 'Google']}
          />
        </Grid>
      )}
    </Box>
  );
};

BoxContentTop.propTypes = {
  title: PropTypes.string.isRequired,
  widget: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  onlyVideo: PropTypes.bool,
  prefix: PropTypes.string
};

BoxContentTop.defaultProps = {
  onlyVideo: false,
  prefix: ''
};

export default BoxContentTop;
