import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import HasAnyPriv from 'components/HasAnyPriv';
import Box from 'components/Box';
import AnalyticsHeader from 'components/AnalyticsHeader';
import { Dialog, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SubheaderTabs from 'components/AnalyticsHeader/SubheaderTabs';
import {
  getInitialAnalyticsEndDate,
  getInitialAnalyticsStartDate,
  checkValidDate
} from 'util/date';
import { connect } from 'react-redux';
import ThirdPartyMediaGroupModal from 'components/AlembicModalForm/ThirdPartyMediaGroupModal';
import { GET_BROADCAST_SEARCHES } from 'gql/broadcastSearch';
import { GET_PODCAST_SEARCHES } from 'gql/podcastSearch';
import { ARTICLE_SEARCHES } from 'gql/articleSearch';
import { MEDIA_GROUPS } from 'gql/mediaGroup';
import { showToast } from 'contexts/ToastContext';
import { goToRoute, goToRouteReplace, parseQueryString } from 'util/routesHelpers';
import {
  AnalyticsPath,
  ThirdPartyMediaAnalyticsSettingsPath,
  ThirdPartyMediaAnalyticsPath,
  DashboardEarnedPathV4
} from 'util/paths';
import AlbLoading from 'components/AlbLoading';
import AnalyticsPlaceholder from 'components/AnalyticsPlaceholder';
import getCurrentContainerIdFromRedux from 'util/container';
import moment from 'moment';
import { tabs, thirdPartyMediaTabs } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import { FEATURE_FLAG } from 'util/featureFlags';
import isFeatureVisible from 'util/isFeatureVisible';
import Banner from 'components/Banner';
import ThirdPartyMediaReports from './ThirdPartyMediaReports';
import ThirdPartyMediaReportsDropdown from './ThirdPartyMediaReportsDropdown';
import ThirdPartyMediaOverview from './ThirdPartyMediaOverview';
import { WEB, BROADCAST, PODCAST } from './ThirdPartyMediaConsts';

const useStyles = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  }
});

const sortReportsByName = reports =>
  reports.sort((reportA, reportB) =>
    reportA.name.toLowerCase().localeCompare(reportB.name.toLowerCase())
  );

/**
 * @name ThirdPartyMediaOverview
 * @summary The parent component of the 3rd party media analytics page.
 */

const ThirdPartyMediaAnalytics = ({ history, location, currentUser, featureFlags }) => {
  const classes = useStyles();
  // const { data: reportsData, loading: reportsLoading } = useQuery(GET_MEDIA_REPORTS);
  const [activeTab, setActiveTab] = useState(tabs.overview);
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedReportIds, setSelectedReportIds] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [modalSelectedReports, setModalSelectedReports] = useState([]);
  const [modalSelectedGroup, setModalSelectedGroup] = useState(null);
  const [additionalReports, setAdditionalReports] = useState([]);
  const [calRange, changeCalRange] = useState({
    start: getInitialAnalyticsStartDate(90, currentUser, 'days'),
    end: getInitialAnalyticsEndDate(currentUser)
  });
  const [groupModal, setGroupModal] = useState({ open: false, action: 'create' });
  const [formattedBroadcastReports, setFormattedBroadcastReports] = useState([]);
  const [formattedPodcastReports, setFormattedPodcastReports] = useState([]);
  const [formattedArticleReports, setFormattedArticleReports] = useState([]);
  const [formattedArticleFilterReports, setFormattedArticleFilterReports] = useState([]);
  const [formattedGroups, setFormattedGroups] = useState([]);
  const [completeOptions, setCompleteOptions] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);

  const dialogRef = useRef();
  const isThirdPartyAccessAllowed = isFeatureVisible(featureFlags, FEATURE_FLAG.THIRD_PARTY_MEDIA);

  // get all reports for this container and their groups
  const {
    data: broadcastReportsData,
    error: broadcastReportsError,
    refetch: refetchBroadcastReports
  } = useQuery(GET_BROADCAST_SEARCHES, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: podcastReportsData,
    error: podcastReportsError,
    refetch: refetchPodcastReports
  } = useQuery(GET_PODCAST_SEARCHES, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: articleReportsData,
    error: articleReportsError,
    refetch: refetchArticleReports
  } = useQuery(ARTICLE_SEARCHES, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: groupsData,
    error: groupsError,
    loading: groupsLoading,
    refetch: refetchGroups
  } = useQuery(MEDIA_GROUPS, { fetchPolicy: 'network-only' });

  const refetchReports = () => {
    refetchBroadcastReports();
    refetchPodcastReports();
    refetchArticleReports();
    refetchGroups();
  };

  const transformDate = (date, format) => {
    if (format === 'to_calendar') {
      return new Date(moment(date).format('L LT'));
    }

    if (format === 'to_url') {
      return moment(date)
        .format('YYYY-MM-DD')
        .toString();
    }

    return '';
  };

  // find full report object from ids.
  // if groupReports are passed in explicitly, we use those ids, otherwise use whatever is in selectedReportIds.
  const getFullReports = groupReports => {
    const fullReports = [];
    const reportIds = groupReports || selectedReportIds;

    reportIds.forEach(id => {
      const foundReport = completeOptions.find(
        report => report.id === id && report.type === 'report'
      );

      if (foundReport) {
        fullReports.push(foundReport);
      }
    });

    return fullReports;
  };

  const updatePathParams = () => {
    let start = '';
    let end = '';
    let group = '';
    let reports = '';
    let fromDash = '';

    // only way for us to know if we need to navigate back to dashboard on modal closing.
    const fromDashboard = parseQueryString('from_dash', location);
    if (fromDashboard?.length) {
      fromDash = '&from_dash=true';
    }

    const startInLocation = parseQueryString('start', location);
    if (startInLocation?.length) {
      start = `?start=${startInLocation[0]}`;
    }

    const endInLocation = parseQueryString('end', location);
    if (endInLocation?.length) {
      end = `&end=${endInLocation[0]}`;
    }

    const groupInLocation = parseQueryString('group', location);
    if (groupInLocation?.length) {
      group = `&group=${groupInLocation[0]}`;
    }

    const reportsInLocation = parseQueryString('reports', location);
    if (reportsInLocation?.length) {
      reports = `&reports=${reportsInLocation[0]}`;
    }

    const newStart = transformDate(calRange.start, 'to_url');
    const newEnd = transformDate(calRange.end, 'to_url');
    if (newStart !== startInLocation[0]) {
      start = `?start=${newStart}`;
    }

    if (newEnd !== endInLocation[0]) {
      end = `&end=${newEnd}`;
    }

    if (selectedGroup && selectedGroup.id !== groupInLocation) {
      group = `&group=${selectedGroup.id}`;
    }

    if (selectedReportIds?.length && selectedReportIds.toString() !== reportsInLocation) {
      reports = `&reports=${selectedReportIds.toString()}`;
    }

    const newPath = `${ThirdPartyMediaAnalyticsPath}${start}${end}${group}${reports}${fromDash}`;

    goToRouteReplace(newPath);
  };

  const preselectDefaultReports = () => {
    const defaultGroup = formattedGroups.find(group => group.primary);

    if (defaultGroup) {
      setSelectedGroup(defaultGroup);
      setModalSelectedGroup(defaultGroup);
      setSelectedReportIds(defaultGroup.reports);

      const selectedFullReports = [];

      defaultGroup.reports.forEach(id => {
        const foundReport = completeOptions.find(
          report => report.id === id && report.type === 'report'
        );

        if (foundReport) {
          selectedFullReports.push(foundReport);
        }
      });

      setSelectedReports(selectedFullReports);
    }
  };

  const preselectDatesFromParams = () => {
    const startDateInLocation = parseQueryString('start', location);
    const endDateInLocation = parseQueryString('end', location);

    let start;
    let end;

    if (startDateInLocation[0] && endDateInLocation[0]) {
      start = transformDate(startDateInLocation[0], 'to_calendar');
      end = transformDate(endDateInLocation[0], 'to_calendar');
    }

    if (
      (start && start.toString() !== calRange.start.toString()) ||
      (end && end.toString() !== calRange.end.toString())
    ) {
      if (checkValidDate(start) && checkValidDate(end) && start < end) {
        changeCalRange({ start, end });
      }
    }
  };

  const preselectReportsFromParams = () => {
    // check if location already has preselected group or reports and set those, otherwise set defaults.
    const reportsInLocation = parseQueryString('reports', location);
    const groupInLocation = parseQueryString('group', location);

    // converting dates to string for proper comparison
    if (groupInLocation.length || reportsInLocation.length) {
      if (groupInLocation.length) {
        const fullGroupObj = formattedGroups.find(group => group.id === groupInLocation[0]);

        if (fullGroupObj) {
          setSelectedGroup(fullGroupObj);
          setModalSelectedGroup(fullGroupObj);
        }
      }

      if (reportsInLocation.length) {
        const splitReportsArr = reportsInLocation[0].split(',');
        const fullReports = getFullReports(splitReportsArr);

        setSelectedReports(fullReports);
        setSelectedReportIds(splitReportsArr);
      }
    } else {
      preselectDefaultReports();
    }
  };

  useEffect(() => {
    if (location.pathname) {
      // get current container id prefix.
      const containerId = getCurrentContainerIdFromRedux();
      const settingsPathWithPrefix = `/${containerId}${ThirdPartyMediaAnalyticsSettingsPath}`;

      if (location.pathname === settingsPathWithPrefix) {
        setActiveTab(tabs.settings);
      }
    }

    if (activeTab === tabs.overview) {
      if (location.search) {
        // dates are preselected on first load, other items have to wait for the data.
        preselectDatesFromParams();
      }
    }
  }, []);

  const clearSelection = () => {
    setSelectedGroup(null);
    setModalSelectedGroup(null);
    setSelectedReportIds([]);
    setSelectedReports([]);

    const fromDashboard = parseQueryString('from_dash', location);

    let fromDash = '';

    if (fromDashboard.length) {
      fromDash = '&from_dash=true';
    }

    // keep current dates selected.
    const startInLocation = parseQueryString('start', location);
    let start = '';

    if (startInLocation?.length) {
      start = `?start=${startInLocation[0]}`;
    }

    const endInLocation = parseQueryString('end', location);
    let end = '';

    if (endInLocation?.length) {
      end = `&end=${endInLocation[0]}`;
    }
    const newPath = `${ThirdPartyMediaAnalyticsPath}${start}${end}${fromDash}`;

    goToRouteReplace(newPath);
  };

  useEffect(() => {
    if (activeTab === tabs.overview) {
      // if we're switching tabs to settings and back, we lose our previous reports selection and go back to the default.
      preselectDefaultReports();
      updatePathParams();
    }

    if (activeTab === tabs.settings) {
      const fromDashboard = parseQueryString('from_dash', location);
      let fromDash = '';

      if (fromDashboard.length) {
        fromDash = '&from_dash=true';
      }
      goToRouteReplace(`${ThirdPartyMediaAnalyticsSettingsPath}?mediaType=${WEB}${fromDash}`);
    }
  }, [activeTab]);

  // persist dates and reports, only in the Overview tab.
  useEffect(() => {
    if (activeTab === tabs.overview) {
      updatePathParams();
    }
  }, [selectedReportIds, selectedReports, selectedGroup, calRange]);

  const formatArticleSearchFilterReports = search => {
    const filters = search.article_search_filters;
    const searchFiltersFormatted = [];

    if (filters.length) {
      filters.forEach(filterReport => {
        searchFiltersFormatted.push({
          id: `${WEB}:filter:${filterReport.id}`,
          name: `${search.name} (Filter Id: ${filterReport.id})`,
          reportType: WEB,
          type: 'report',
          primary: false // update when we implement default reports on filters.
        });
      });
    }

    return searchFiltersFormatted;
  };

  const formatReportsData = (reports, type) => {
    // have to append the type to the id, otherwise there could be collisions since we're pulling from 3 different tables.
    let sortedReports = [];

    if (type !== WEB) {
      const formatted = reports.map(report => ({
        id: `${type}:${report.id}`,
        name: report.name,
        reportType: type,
        type: 'report',
        primary: !!report.is_default
      }));

      sortedReports = sortReportsByName(formatted);
    }

    if (type === BROADCAST) {
      setFormattedBroadcastReports(sortedReports);
      setCompleteOptions([
        ...formattedGroups,
        ...sortedReports,
        ...formattedPodcastReports,
        ...formattedArticleReports
      ]);
    }

    if (type === PODCAST) {
      setFormattedPodcastReports(sortedReports);
      setCompleteOptions([
        ...formattedGroups,
        ...formattedBroadcastReports,
        ...sortedReports,
        ...formattedArticleReports
      ]);
    }

    if (type === WEB) {
      const formattedArticleSearches = [];
      const formattedFilteredArticleSearches = [];
      // check if article search also has article search filtered reports and add those to list.
      reports.forEach(report => {
        if (report?.article_search_filters?.length > 0) {
          const filteredArticleSearches = formatArticleSearchFilterReports(report);
          formattedFilteredArticleSearches.push(...filteredArticleSearches);
        }

        formattedArticleSearches.push({
          id: `${type}:${report.id}`,
          name: report.name,
          reportType: type,
          type: 'report',
          primary: !!report.is_default
        });
      });

      const formatted = [...formattedArticleSearches, ...formattedFilteredArticleSearches];
      const sortedCombinedArticleReports = sortReportsByName(formatted);

      const sortedArticleSearches = sortReportsByName(formattedArticleSearches);
      const sortedArticleSearchFilters = sortReportsByName(formattedFilteredArticleSearches);
      setFormattedArticleReports(sortedArticleSearches);
      setFormattedArticleFilterReports(sortedArticleSearchFilters);

      setCompleteOptions([
        ...formattedGroups,
        ...formattedBroadcastReports,
        ...formattedPodcastReports,
        ...sortedCombinedArticleReports
      ]);
    }
  };

  const formatGroupsData = groups => {
    const formatted = groups.map(group => {
      const reports = [
        ...group.mediaGroupReports.broadcastSearches.map(id => `broadcast:${id}`),
        ...group.mediaGroupReports.podcastSearches.map(id => `podcast:${id}`),
        ...group.mediaGroupReports.articleSearches.map(id => `web:${id}`),
        ...group.mediaGroupReports.articleSearchFilters.map(id => `web:filter:${id}`)
      ];

      const groupFormatted = {
        id: group.id,
        name: group.name,
        type: 'group',
        reports,
        primary: !!group.is_default
      };

      return groupFormatted;
    });

    const sortedGroups = sortReportsByName(formatted);

    setFormattedGroups(sortedGroups);
    setCompleteOptions([
      ...sortedGroups,
      ...formattedBroadcastReports,
      ...formattedPodcastReports,
      ...formattedArticleReports,
      ...formattedArticleFilterReports
    ]);
  };

  useEffect(() => {
    if (broadcastReportsData?.broadcastSearches) {
      formatReportsData(broadcastReportsData.broadcastSearches, BROADCAST);
    }
  }, [broadcastReportsData]);

  useEffect(() => {
    if (podcastReportsData?.podcastSearches) {
      formatReportsData(podcastReportsData.podcastSearches, PODCAST);
    }
  }, [podcastReportsData]);

  // includes articleSearches and articleSearchFilters
  useEffect(() => {
    if (articleReportsData?.articleSearches) {
      formatReportsData(articleReportsData?.articleSearches, WEB);
    }
  }, [articleReportsData]);

  useEffect(() => {
    if (groupsData?.getMediaGroups) {
      formatGroupsData(groupsData.getMediaGroups);
    }
  }, [groupsData]);

  useEffect(() => {
    if (completeOptions.length) {
      if (formattedBroadcastReports.length === 0 && formattedPodcastReports.length === 0) {
        setActiveTab(tabs.settings);
      } else {
        preselectReportsFromParams();
      }
    }
  }, [completeOptions]);

  useEffect(() => {
    if (broadcastReportsError) {
      showToast('Error fetching broadcast reports. Please try again later.', 'error');
    }
    if (podcastReportsError) {
      showToast('Error fetching podcast reports. Please try again later.', 'error');
    }
    if (groupsError) {
      showToast('Error fetching report groups. Please try again later.', 'error');
    }
    if (articleReportsError) {
      showToast('Error fetching broadcast reports. Please try again later.', 'error');
    }
  }, [broadcastReportsError, podcastReportsError, articleReportsError, groupsError]);

  // in modal, set additional reports to whatever isn't checked.
  useEffect(() => {
    const remainingReports = [];
    const selectedIds = modalSelectedReports.map(report => report.id);

    const formattedReports = [
      ...formattedBroadcastReports,
      ...formattedPodcastReports,
      ...formattedArticleReports,
      ...formattedArticleFilterReports
    ];

    formattedReports.forEach(report => {
      if (!selectedIds.includes(report.id)) {
        remainingReports.push(report);
      }
    });

    setAdditionalReports(remainingReports);
  }, [modalSelectedReports]);

  return (
    <HasAnyPriv
      privs={[
        'TVEYES',
        'TVEYES:SEARCH',
        'TVEYES:SEARCH:CREATE',
        'TVEYES:SEARCH:DELETE',
        'TVEYES:SEARCH:UPDATE',
        'TVEYES:SEARCH:READ'
      ]}
      showAlert
    >
      <>
        <Dialog fullScreen open classes={{ paperFullScreen: classes.root }} ref={dialogRef}>
          <AnalyticsHeader
            displayTitle="3rd Party Media"
            hideAccountSelector
            calRange={calRange}
            changeCalRange={changeCalRange}
            defaultButtonLabel="90"
            onClose={() => {
              const fromDashboard = parseQueryString('from_dash', location);

              if (fromDashboard.length) {
                goToRoute(DashboardEarnedPathV4, history);
              } else {
                goToRoute(AnalyticsPath, history);
              }
            }}
            dialogRef={dialogRef}
            customDropdownComponent={
              <ThirdPartyMediaReportsDropdown
                options={completeOptions}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                setSelectedReports={setSelectedReports}
                selectedReportIds={selectedReportIds}
                setSelectedReportIds={setSelectedReportIds}
                setSelectedGroup={setSelectedGroup}
                selectedGroup={selectedGroup}
                setModalSelectedReports={setModalSelectedReports}
                setModalSelectedGroup={setModalSelectedGroup}
                getFullReports={getFullReports}
                clearSelection={clearSelection}
                settingsClickAction={() => {
                  setActiveTab(tabs.settings);
                  setOpenDropdown(false);
                }}
                setGroupModal={setGroupModal}
                isThirdPartyAccessAllowed={isThirdPartyAccessAllowed}
              />
            }
          />
          <SubheaderTabs
            tabValues={thirdPartyMediaTabs}
            activeTab={activeTab}
            callback={setActiveTab}
          />
          {groupsLoading && <AlbLoading />}

          {!groupsLoading && (
            <Container>
              {!isThirdPartyAccessAllowed && (
                <div style={{ marginTop: '20px' }}>
                  <Banner
                    useIcon
                    type="warning"
                    variant="filled"
                    message="For more information on TV, Radio, and Podcast access, please contact your Client Partner or support@getalembic.com for details."
                  />
                </div>
              )}
              <Box my={50}>
                {activeTab === tabs.overview && selectedReports.length ? (
                  <ThirdPartyMediaOverview
                    selectedDates={calRange}
                    selectedReports={selectedReports}
                  />
                ) : null}
                {activeTab === tabs.overview && !selectedReports.length ? (
                  <AnalyticsPlaceholder
                    text="You must first create or select a report or report group to display analytics data."
                    accountsButton={false}
                  />
                ) : null}
                {activeTab === tabs.settings && (
                  <ThirdPartyMediaReports
                    refetchReports={refetchReports}
                    isThirdPartyAccessAllowed={isThirdPartyAccessAllowed}
                  />
                )}
              </Box>
            </Container>
          )}
        </Dialog>
        <ThirdPartyMediaGroupModal
          open={groupModal.open}
          action={groupModal.action}
          reportsIncluded={modalSelectedReports}
          additionalReports={additionalReports}
          selectedGroup={modalSelectedGroup}
          refetchReports={refetchReports}
          onClose={() => setGroupModal({ open: false, action: 'create' })}
        />
      </>
    </HasAnyPriv>
  );
};

ThirdPartyMediaAnalytics.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    featureFlags: state.auth.featureFlags
  };
};

export default connect(mapStateToProps)(ThirdPartyMediaAnalytics);
