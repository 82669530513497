import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DELETE_INVITE } from 'gql/invitation';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const DeleteInviteModal = props => {
  const { isModalOpen, onChange, inviteId } = props;

  const [deleteInvitation, { error, data }] = useMutation(DELETE_INVITE);

  useEffect(() => {
    if (data?.deleteInvitation?.result) {
      showToast(`Invitation deleted`, 'success');
      onChange({ isModalOpen: false });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showToast(error.message, 'error');
      onChange({ isModalOpen: false });
    }
  }, [error]);

  return (
    <>
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Delete Invitation"
        body="Are you sure you want to delete the Invitation?"
        cancelTitle="Cancel"
        confirmTitle="Delete"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => deleteInvitation({ variables: { id: inviteId } })}
      />
    </>
  );
};

DeleteInviteModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  inviteId: PropTypes.string.isRequired
};

export default DeleteInviteModal;
