import React from 'react';
import PropTypes from 'prop-types';
import { AlbTooltip, getIconAttributes } from 'components/AlbTooltip';
import { Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import colors from 'util/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  grid: {
    marginTop: '25px',
    backgroundColor: 'white',

    '& > :nth-child(odd)': {
      backgroundColor: colors.wildSand
    }
  },
  tooltipGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 10px 0px'
  },
  labelGrid: {
    height: '40px',
    padding: '10px'
  },
  metricGridContainer: {
    flexDirection: 'column',
    height: '150px'
  },
  metricValue: {
    color: colors.darkBlue,
    fontSize: '30px',
    fontWeight: 500,
    marginTop: '15px',
    whiteSpace: 'pre-line'
  },
  subValue: {
    color: colors.darkBlue
  }
});

/**
 * Component for building the grid of metrics in the drawer
 * @param {object} props  The props passed to the component
 * @param {Array} props.gridItems: an array of objects containing the data for each metric, created in PostDrawerResurgence
 * @returns {object} JSX describing the grid of metrics
 */
const PostDrawerMetricGrid = props => {
  const { gridItems } = props;
  const classes = useStyles();

  if (!gridItems) {
    return null;
  }

  return (
    <Grid container className={classes.grid}>
      {gridItems.map(({ graphTitle, metricValue, subValue, tooltipText, style, gridItemSize }) => {
        return (
          <PostDrawerMetricBox
            graphTitle={graphTitle}
            metricValue={metricValue}
            subValue={subValue}
            tooltipText={tooltipText}
            key={graphTitle}
            style={style}
            gridItemSize={gridItemSize}
          />
        );
      })}
    </Grid>
  );
};

PostDrawerMetricGrid.propTypes = {
  gridItems: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

/**
 * Component that builds the box for one of the metrics in the grid
 * @param {object} props  The props for this component
 * @param {String} props.graphTitle: a string describing the metric, something like "Surge" or "Rank"
 * @param {String|Number} props.metricValue the value of the metric to display
 * @param {String} props.subValue a value displayed under the metric value
 * @param {String} props.tooltipText: a string containing the text to display in the tooltip
 * @returns {object} JSX describing the metric box
 */

const PostDrawerMetricBox = props => {
  const { graphTitle, metricValue, subValue, tooltipText, style, gridItemSize } = props;
  const classes = useStyles();

  return (
    <Grid container item className={classes.metricGridContainer} xs={gridItemSize}>
      <Grid item className={classes.tooltipGrid}>
        {tooltipText && (
          <AlbTooltip title={tooltipText}>
            <InfoIcon style={getIconAttributes()} />
          </AlbTooltip>
        )}
      </Grid>
      <Grid item className={classes.labelGrid}>
        <Typography align="center">{graphTitle}</Typography>
      </Grid>
      <Grid item>
        <Typography style={style} className={classes.metricValue} align="center">
          {metricValue}
        </Typography>
        {subValue && (
          <Typography className={classes.subValue} align="center">
            {subValue}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

PostDrawerMetricBox.propTypes = {
  graphTitle: PropTypes.string.isRequired,
  metricValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subValue: PropTypes.string,
  tooltipText: PropTypes.string,
  style: PropTypes.shape(),
  gridItemSize: PropTypes.number
};

PostDrawerMetricBox.defaultProps = {
  subValue: '',
  tooltipText: '',
  style: {},
  gridItemSize: 4
};

export default PostDrawerMetricGrid;
