import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { webAndAppsDemographicsWidget } from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import BoxContentWidget from 'components/AnalyticsSocialV2/BoxContent/BoxContentWidget';

const WebDemographics = props => {
  const { accounts, dates } = props;
  return (
    <>
      <Grid
        container
        style={{
          width: 'unset',
          flexDirection: 'column',
          margin: '25px 15px'
        }}
      >
        <BoxContentWidget
          tabData={webAndAppsDemographicsWidget}
          accounts={accounts}
          dates={dates}
        />
      </Grid>
    </>
  );
};

WebDemographics.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default WebDemographics;
