import React from 'react';
import PropTypes from 'prop-types';

const PlanIcon = props => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8387 8.21945V2.87544H2.4401L0 5.53736L2.4401 8.21945H16.8387ZM2.86358 3.80308H15.911V7.29181H2.86358L1.27046 5.53736L2.86358 3.78292V3.80308Z"
        fill={color}
      />
      <path
        d="M14.56 9.32859H0.161377V14.6726H14.56L17 11.9905L14.56 9.30843V9.32859ZM14.1566 13.745H1.08902V10.2562H14.1566L15.7498 12.0107L14.1566 13.7651V13.745Z"
        fill={color}
      />
      <path
        d="M9.4377 20.0973H7.58242V15.8221H6.65479V21.0249H10.3653V15.8221H9.4377V20.0973Z"
        fill={color}
      />
      <path
        d="M7.58242 1.52432H9.4377V2.08897H10.3653V0.59668H6.65479V2.08897H7.58242V1.52432Z"
        fill={color}
      />
    </svg>
  );
};

PlanIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

PlanIcon.defaultProps = {
  width: 17,
  height: 22,
  color: '#FEFEFE'
};

export default PlanIcon;
