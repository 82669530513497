import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Button } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import theme from 'theme';
import colors from 'util/colors';
import { CONVERSIONS_PAST_YEAR } from 'gql/dashboardIntelligenceEvent';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import moment from 'moment';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';

const styles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '20px'
  },
  typography: {
    color: '#0a1734',
    fontSize: '16px',
    fontWeight: 500,
    paddingLeft: '27px'
  },
  showMoreButton: {
    width: '250px',
    marginTop: '20px'
  }
});

const tableTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiTableRow: {
      root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'wrap'
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#f5f5f5'
        },
        '&:hover': {
          backgroundColor: colors.athensGray
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',

        '&:first-child': {
          paddingLeft: '27px'
        },

        '&:last-child': {
          paddingRight: '27px'
        }
      },
      body: {
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  }
});

const ConversionsTable = ({ event }) => {
  const classes = styles();

  const [firstFiveTableData, setFirstFiveTableData] = useState([]);
  const [fullTableData, setFullTableData] = useState([]);
  const [displayFullData, toggleDisplayFullData] = useState(false);
  // const [sortOrder, updateSortOrder] = useState({ name: 'event_date', direction: 'desc' });

  const { data, loading, error } = useQuery(CONVERSIONS_PAST_YEAR, {
    variables: {
      ...(event.type === DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL
        ? {
            detectedEventId: event.detectedEventId
          }
        : {
            linktokenEventId: event.id
          })
    },
    skip: !event
  });

  const formatData = eventData => {
    const formattedEvents = [];

    eventData.forEach(conversionEvent => {
      formattedEvents.push({
        id: conversionEvent.id,
        event_date: conversionEvent.startDate,
        conversions: conversionEvent?.metricValue?.toLocaleString()
      });
    });

    return formattedEvents;
  };

  useEffect(() => {
    const tableData = data?.conversionOutlierEventsPastYear;

    if (tableData?.length) {
      const formattedData = formatData(tableData);

      if (tableData?.length > 5) {
        setFullTableData(formattedData);

        const firstFive = formattedData.slice(0, 5);
        setFirstFiveTableData(firstFive);
      } else {
        setFirstFiveTableData(formattedData);
      }
    }
  }, [data]);

  const customDateRender = (date, tableMeta) => {
    const eventId = tableMeta.rowData[0];
    const tableData = data?.conversionOutlierEventsPastYear;
    const currEvent = tableData.find(e => e.id === eventId);

    if (currEvent) {
      const { endDate } = currEvent;

      // show range if dates are different
      if (endDate !== date) {
        return `${moment(date).format('L')} - ${moment(endDate).format('L')}`;
      }
    }
    return moment(date).format('L');
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: { display: false }
    },
    {
      name: 'event_date',
      label: 'EVENT DATE',
      options: {
        customBodyRender: customDateRender
      }
    },
    {
      name: 'conversions',
      label: 'CONVERSIONS',
      options: {
        // customBodyRender: customChangeRender,
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard'
  };

  return (
    <Grid container className={classes.container} direction="column">
      {loading && <AlbLoading />}

      {error && <AlbError error={error} />}

      {firstFiveTableData?.length ? (
        <>
          <Typography className={classes.typography}>
            Other Conversion Outliers for {event?.goal} in the Last Year
          </Typography>
          <MuiThemeProvider theme={tableTheme}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <MUIDataTable
                  data={displayFullData ? fullTableData : firstFiveTableData}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider>
          {fullTableData?.length ? (
            <Grid container justifyContent="center">
              <Button
                className={classes.showMoreButton}
                color="primary"
                variant="outlined"
                onClick={() => toggleDisplayFullData(!displayFullData)}
              >
                {displayFullData ? 'Show Less' : 'Show More'}
              </Button>
            </Grid>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};

ConversionsTable.propTypes = {
  event: PropTypes.shape().isRequired
};

export default ConversionsTable;
