/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import AlembicInputLabel from 'components/AlembicInputLabel';
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
  YouTubeLogo,
  TikTokLogo
} from 'util/assets';

const useStyles = makeStyles({
  adornmentOverride: {
    marginTop: '0px !important'
  },
  adornmentText: {
    fontWeight: '500'
  }
});

const ValuationModelForm = props => {
  const { valuationData, onChange } = props;

  const [valuationInstagram, setValuationInstagram] = useState('');
  const [valuationFacebook, setValuationFacebook] = useState('');
  const [valuationLinkedIn, setValuationLinkedIn] = useState('');
  const [valuationTwitter, setValuationTwitter] = useState('');
  const [valuationYouTube, setValuationYouTube] = useState('');
  const [valuationTikTok, setValuationTikTok] = useState('');
  const [isValuationRequired, setIsValuationRequired] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const newValuationInstagram = valuationData?.instagram ?? '';
    const newValuationFacebook = valuationData?.facebook ?? '';
    const newValuationLinkedIn = valuationData?.linkedin ?? '';
    const newValuationTwitter = valuationData?.twitter ?? '';
    const newValuationYouTube = valuationData?.youtube ?? '';
    const newValuationTikTok = valuationData?.tiktok ?? '';

    // If any of these variable has a value then we need to require a value from all them
    if (
      newValuationInstagram !== '' ||
      newValuationFacebook !== '' ||
      newValuationLinkedIn !== '' ||
      newValuationTwitter !== '' ||
      newValuationYouTube !== '' ||
      newValuationTikTok !== ''
    ) {
      setIsValuationRequired(true);
    }

    setValuationInstagram(newValuationInstagram);
    setValuationFacebook(newValuationFacebook);
    setValuationLinkedIn(newValuationLinkedIn);
    setValuationTwitter(newValuationTwitter);
    setValuationYouTube(newValuationYouTube);
    setValuationTikTok(newValuationTikTok);
  }, [
    valuationData?.instagram,
    valuationData?.facebook,
    valuationData?.linkedin,
    valuationData?.twitter,
    valuationData?.youtube,
    valuationData?.tiktok
  ]);

  useEffect(() => {
    // If all of these values are empty then we can disable the requirement
    if (
      valuationInstagram === '' &&
      valuationFacebook === '' &&
      valuationLinkedIn === '' &&
      valuationTwitter === '' &&
      valuationYouTube === '' &&
      valuationTikTok === ''
    ) {
      setIsValuationRequired(false);
      onChange({
        data: null
      });
    } else {
      setIsValuationRequired(true);
      onChange({
        data: {
          valuationInstagram,
          valuationFacebook,
          valuationLinkedIn,
          valuationTwitter,
          valuationYouTube,
          valuationTikTok
        }
      });
    }
  }, [
    valuationInstagram,
    valuationFacebook,
    valuationLinkedIn,
    valuationTwitter,
    valuationYouTube,
    valuationTikTok
  ]);

  const validators = ['minNumber:0', ...(isValuationRequired ? ['required'] : [])];
  const errorMessages = [
    'Value must be greater than or equal to 0',
    ...(isValuationRequired ? ['* Required'] : [])
  ];

  return (
    <>
      <Grid item xs={12} sm={6}>
        <AlembicInputLabel
          primaryText="Instagram Valuation"
          useWithIcon={InstagramLogo}
          iconWidth={18}
          iconHeight={18}
        />
        <TextValidator
          fullWidth
          variant="filled"
          placeholder="Ex: $7.77"
          type="number"
          inputProps={{ step: '0.01', min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.adornmentOverride}>
                <Typography className={classes.adornmentText}>$</Typography>
              </InputAdornment>
            )
          }}
          value={valuationInstagram}
          onChange={event => setValuationInstagram(event.target.value)}
          validators={validators}
          errorMessages={errorMessages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AlembicInputLabel
          primaryText="Facebook Valuation"
          useWithIcon={FacebookLogo}
          iconWidth={18}
          iconHeight={18}
        />
        <TextValidator
          fullWidth
          variant="filled"
          placeholder="Ex: $7.77"
          type="number"
          inputProps={{ step: '0.01', min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.adornmentOverride}>
                <Typography className={classes.adornmentText}>$</Typography>
              </InputAdornment>
            )
          }}
          value={valuationFacebook}
          onChange={event => setValuationFacebook(event.target.value)}
          validators={validators}
          errorMessages={errorMessages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AlembicInputLabel
          primaryText="LinkedIn Valuation"
          useWithIcon={LinkedinLogo}
          iconWidth={18}
          iconHeight={18}
        />
        <TextValidator
          fullWidth
          variant="filled"
          placeholder="Ex: $7.77"
          type="number"
          inputProps={{ step: '0.01', min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.adornmentOverride}>
                <Typography className={classes.adornmentText}>$</Typography>
              </InputAdornment>
            )
          }}
          value={valuationLinkedIn}
          onChange={event => setValuationLinkedIn(event.target.value)}
          validators={validators}
          errorMessages={errorMessages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AlembicInputLabel
          primaryText="Twitter Valuation"
          useWithIcon={TwitterLogo}
          iconWidth={22.14}
          iconHeight={18}
        />
        <TextValidator
          fullWidth
          variant="filled"
          placeholder="Ex: $7.77"
          type="number"
          inputProps={{ step: '0.01', min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.adornmentOverride}>
                <Typography className={classes.adornmentText}>$</Typography>
              </InputAdornment>
            )
          }}
          value={valuationTwitter}
          onChange={event => setValuationTwitter(event.target.value)}
          validators={validators}
          errorMessages={errorMessages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AlembicInputLabel
          primaryText="YouTube Valuation"
          useWithIcon={YouTubeLogo}
          iconWidth={25.5}
          iconHeight={18}
        />
        <TextValidator
          fullWidth
          variant="filled"
          placeholder="Ex: $7.77"
          type="number"
          inputProps={{ step: '0.01', min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.adornmentOverride}>
                <Typography className={classes.adornmentText}>$</Typography>
              </InputAdornment>
            )
          }}
          value={valuationYouTube}
          onChange={event => setValuationYouTube(event.target.value)}
          validators={validators}
          errorMessages={errorMessages}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AlembicInputLabel
          primaryText="TikTok Valuation"
          useWithIcon={TikTokLogo}
          iconWidth={18}
          iconHeight={18}
        />
        <TextValidator
          fullWidth
          variant="filled"
          placeholder="Ex: $7.77"
          type="number"
          inputProps={{ step: '0.01', min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.adornmentOverride}>
                <Typography className={classes.adornmentText}>$</Typography>
              </InputAdornment>
            )
          }}
          value={valuationTikTok}
          onChange={event => setValuationTikTok(event.target.value)}
          validators={validators}
          errorMessages={errorMessages}
        />
      </Grid>
    </>
  );
};

ValuationModelForm.propTypes = {
  valuationData: PropTypes.shape(),
  onChange: PropTypes.func.isRequired
};

ValuationModelForm.defaultProps = {
  valuationData: null
};

export default ValuationModelForm;
