import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { useSubscription, useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  GET_ASSETS,
  apiAssetDelete,
  apiAssetUpdate,
  apiGetAssets,
  ASSET_FROM_HANDLE,
  VIDEO_SUBSCRIPTION,
  ASSET_TAG_RESULT,
  GET_PUBLIC_ASSETS,
  ASSET_UPDATE
} from 'gql/asset';
import { CAMPAIGN_EVENTS_BY_ASSET } from 'gql/campaignEvent';
import { CONTAINER } from 'gql/container';
import { showToast } from 'contexts/ToastContext';
import {
  MediaPath,
  MediaSharedPublicPath,
  MediaSharedPath,
  MediaSharedContainerPath
} from 'util/paths';
import HasAnyPriv from 'components/HasAnyPriv';
import { goToRoute } from 'util/routesHelpers';
import getCurrentContainerIdFromRedux from 'util/container';
import AlbLoading from '../AlbLoading';
import MediaCard from './MediaCard';
import CreateFolderCard from './CreateFolderCard';
import MediaPreview from '../MediaPreview';
import SharedFolder from './SharedFolder';

const styles = theme => ({
  gridContainer: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  mediaGrid: {
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    position: 'absolute'
  },
  scrollContainer: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  emptyFolderText: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px'
  },
  createFolderBox: {
    width: '100%',
    marginLeft: '20px'
  }
});

const NONE_SELECTED = null;
// const ADD = { action: 'add' };
const DELETE = { action: 'delete' };
const DESELECT = { select: false };

const FETCHING_IMAGES = 'fetching';
const STACK_IMAGES = 'stack';
const LOAD_NEXT = 'load_next';
const ACTIVE_AFTER = 'active_after';
const RESET_AFTER = 'reset_after';
// number of images fetched at a time.
export const PAGINATION_COUNT = 72;

const handleAssetSelection = (selectedAssets, { asset, multiselect, select }) => {
  /* OMG YAY REDUCERS https://reactjs.org/docs/hooks-reference.html#usereducer
  @selectedAssets {Array}: User-selected assets so far
  @asset {Object}: asset data from GraphQL
  @multiselect {Boolean}: Whether the user can select one (gallery view) or more (selection view) assets
  @select {Boolean}: whether the user selected (true) or deselected (false) an asset
  */
  let selection = selectedAssets.slice();
  switch (select) {
    case true:
      if (multiselect) {
        selection = [asset].concat(selectedAssets); // Add asset to beginning of selection
      } else {
        selection = [asset]; // Replace selection with new asset
      }
      break;
    case false: // Remove the asset from the selection
      // find indexOf asset based on Id, since assets may not have true equality.
      if (selection.findIndex(el => el.id === asset.id) >= 0) {
        selection.splice(
          selection.findIndex(el => el.id === asset.id),
          1
        );
      }
      break;
    default:
      break;
  }
  return selection;
};

// recursively modify filename until we find one that doesn't match.
const generateNewFilename = async (name, count) => {
  let dir;
  let ext;

  if (count === 1) {
    dir = name.slice(0, name.indexOf('.'));
    ext = name.slice(name.indexOf('.'), name.length);
  } else {
    dir = name.slice(0, name.indexOf('('));
    ext = name.slice(name.indexOf('.'), name.length);
  }
  const newName = `${dir}(${count})${ext}`;
  const result = await apiGetAssets({ fileName: newName });

  if (result.payload.data.assets[0]) {
    const newCount = count + 1;
    // throws eslint error, but this recursive async function doesn't work properly without 'await'.
    const newFileName = await generateNewFilename(newName, newCount);
    return newFileName;
  }
  return newName;
};

// Checking if filename exists, and increment until no match is found.
const validateFilename = async filename => {
  const result = await apiGetAssets({ fileName: filename });
  const nameExists = result.payload.data.assets[0];

  if (nameExists) {
    try {
      const newName = await generateNewFilename(filename, 1);
      return newName;
    } catch (e) {
      return e;
    }
  }
  return filename;
};

const imgReducer = (state, action) => {
  switch (action.type) {
    case STACK_IMAGES:
      return { ...state, images: action.images };
    case FETCHING_IMAGES:
      return { ...state, fetching: action.fetching };
    default:
      return state;
  }
};

const afterReducer = (state, action) => {
  switch (action.type) {
    case LOAD_NEXT:
      return { ...state, after: state.after + PAGINATION_COUNT };
    case ACTIVE_AFTER:
      return { ...state, active: action.active };
    case RESET_AFTER:
      return { ...state, after: 0 };
    default:
      return state;
  }
};

const MediaGrid = props => {
  const {
    classes,
    data,
    multiselect,
    modal,
    assetGrid,
    folderGrid,
    sharedGrid,
    history,
    getFolderName,
    folderName,
    currentUser,
    containerData,
    containerAssets,
    publicAssets,
    selectedAssetsInPost,
    setSearchFilterInTabs,
    searchFilter,
    containerId,
    sortOrder,
    accounts
  } = props;
  const { refetch, fetchMore } = data;
  const { pathname, search } = window.location;

  // STATE MANAGEMENT
  const [gridAssets, updateGridAssetsDispatch] = useReducer(imgReducer, {
    images: [],
    fetching: false
  });
  const [afterImg, afterImgDispatch] = useReducer(afterReducer, { after: 0, active: false });
  const [currentAsset, setCurrentAsset] = useState(NONE_SELECTED);
  const [selectedAssets, updateAssetSelection] = useReducer(
    handleAssetSelection,
    selectedAssetsInPost ? [...selectedAssetsInPost] : []
  );
  const [currentCampaignEvents, setCurrentCampaignEvents] = useState(null);
  const [nestedContainers, updateNestedContainers] = useState(null);
  const [sharedGridHelper, updateSharedGrid] = useState(null);

  // Lazyloading function for campaign events upon an asset selection.
  const [
    getCampaignEvents,
    { data: campaignEvents, loading: campaignEventsLoading }
  ] = useLazyQuery(CAMPAIGN_EVENTS_BY_ASSET);

  // IntersectionObserver 'observes' the node after which we need to load more images.
  // Once that node is passed, we trigger the next batch.
  const scrollObserver = (entries, observer) => {
    entries.forEach(en => {
      if (en.intersectionRatio > 0) {
        // triggers the fetch.
        afterImgDispatch({ type: LOAD_NEXT });
        // have to unobserve this node after the fetch, otherwise every time we scroll past it, we'll load more images.
        observer.unobserve(en.target);
      }
    });
  };

  // lazy loading target - we use the last fetched image (passed to MediaCard) as the target for next lazy load query.
  // bottomBoundaryRef is a callback ref - so every time the ref gets reassigned to a new target, this will fire.
  const bottomBoundaryRef = useCallback(
    node => {
      if (node !== null && afterImg.active) {
        const observer = new IntersectionObserver(scrollObserver);
        observer.observe(node);
      }
    },
    [afterImg.active]
  );

  // if container id or accounts changes, refetch all assets.
  useEffect(() => {
    refetch();
  }, [containerId, accounts]);

  useEffect(() => {
    // Apollo's fetchMore lets us re-use the previous HOC query and its variables...
    // while passing in an additional variable (after) to get the next batch of photos.
    if (afterImg.active && afterImg.after > 0) {
      updateGridAssetsDispatch({ type: FETCHING_IMAGES, fetching: true });

      fetchMore({
        variables: { after: afterImg.after },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const mergedAssets = [...gridAssets.images, ...fetchMoreResult.assets];
          const deDupedAssets = [];

          mergedAssets.forEach(asset => {
            if (!deDupedAssets.map(a => a?.id).includes(asset.id)) {
              deDupedAssets.push(asset);
            }
          });

          updateGridAssetsDispatch({
            type: STACK_IMAGES,
            images: deDupedAssets
          });
          // we don't have more images to fetch. Disable "afterImgDispatch"
          if (fetchMoreResult?.assets?.length < PAGINATION_COUNT) {
            afterImgDispatch({ type: ACTIVE_AFTER, active: false });
          }
        }
      }).then(() => {
        updateGridAssetsDispatch({ type: FETCHING_IMAGES, fetching: false });
      });
    }
  }, [afterImg.after]);

  // QUERIES & MUTATIONS
  const [updateAsset] = useMutation(ASSET_UPDATE);
  const [createAssetFromHandle, { data: createdAssetData, error: createdAssetError }] = useMutation(
    ASSET_FROM_HANDLE,
    {
      onCompleted(result) {
        // update the asset with information from the public asset = current asset.
        if (result?.createAssetFromHandles?.length) {
          const newAsset = result.createAssetFromHandles[0];

          updateAsset({
            variables: {
              id: newAsset.id,
              title: currentAsset.title,
              notes: currentAsset.notes,
              tags: currentAsset.tags,
              auto_tags: currentAsset.auto_tags,
              expires_at: currentAsset.expires_at
            }
          });
        }
      }
    }
  );

  // SUBSCRIPTIONS
  const { data: videoConvertData, error: videoConvertError } = useSubscription(VIDEO_SUBSCRIPTION, {
    variables: { currentUserId: currentUser.id }
  });
  const { data: assetTagsData, error: assetTagsError } = useSubscription(ASSET_TAG_RESULT, {
    variables: { currentUserId: currentUser.id }
  });

  useEffect(() => {
    if (videoConvertError) {
      showToast(`Error: ${videoConvertError}. Please try uploading the video again. `, 'error');
    }
  }, [videoConvertError]);

  useEffect(() => {
    if (assetTagsError) {
      showToast(
        `Error: ${assetTagsError}. Video tags didn't fetch, please refresh or try again.`,
        'error'
      );
    }
  }, [assetTagsError]);

  // makes sure that when subscription data comes in, all props pass updates to children (ie MediaPreview).
  // Subscription data returns the updated asset.
  useEffect(() => {
    let updatedAsset = null;

    if (videoConvertData) {
      updatedAsset = videoConvertData.videoConversion;
      showToast('Your video has finished uploading!', 'success');
    }

    if (assetTagsData) {
      updatedAsset = assetTagsData.assetTagCompletion;
    }

    // if the updated asset is the asset in preview, refresh it with new data.
    if (updatedAsset && currentAsset && currentAsset.id === updatedAsset.id) {
      setCurrentAsset(updatedAsset);
    }

    if (videoConvertData || assetTagsData) {
      refetch();
    }
  }, [videoConvertData, assetTagsData]);

  // All the side effects.

  // Error handling and success notifications:
  useEffect(() => {
    // Success toasts:
    if (createdAssetData) {
      showToast('Success', 'success');
    }
    // Error toasts:
    if (createdAssetError) {
      const error = createdAssetError;
      if (error?.graphQLErrors?.length) {
        error.graphQLErrors.forEach(({ message }) => {
          if (message.includes('File name exists.')) {
            showToast(`Error: ${message} Please rename your file and try again.`, 'error');
          }
          showToast(`Error: ${message}`, 'error');
        });
      }
      showToast(`Error: ${error}`, 'error');
    }
  }, [createdAssetData, createdAssetError]);

  // containerData defines the queries for nested containers.
  // containerId comes from MediaLayout props and is defined by current path.
  useEffect(() => {
    if (sharedGrid) {
      if (containerData && containerData.container) {
        const childContainers = containerData.container.children;
        updateNestedContainers(childContainers);
      }
    }
  }, [containerData]);

  // In Shared Grid, makes it easier to display the right grid UI based on the path.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const currentContainer = getCurrentContainerIdFromRedux();

    if (sharedGrid) {
      if (pathname.includes(`/${currentContainer}${MediaSharedPublicPath}`)) {
        // public org assets show only images that are marked as publicly available throughout the org.
        return updateSharedGrid('PUBLIC_ORG_ASSETS');
      }
      if (pathname.includes(`/${currentContainer}${MediaSharedContainerPath}`)) {
        // child containers and assets tab shows containers, folders, and images of a child container.
        return updateSharedGrid('CHILD_CONTAINERS_AND_ASSETS');
      }
      if (pathname.includes(`/${currentContainer}${MediaSharedPath}`)) {
        // shared main should show only publicly shared assets folder and container folders
        return updateSharedGrid('SHARED_MAIN');
      }
    }
  });

  useEffect(() => {
    if (folderGrid || searchFilter || sortOrder) {
      afterImgDispatch({ type: RESET_AFTER });
    }
  }, [pathname, searchFilter, sortOrder]);

  useEffect(() => {
    if (publicAssets?.getPublicOrgAssets?.length) {
      updateGridAssetsDispatch({
        type: STACK_IMAGES,
        images: publicAssets.getPublicOrgAssets
      });
      updateGridAssetsDispatch({ type: FETCHING_IMAGES, fetching: false });
    }

    if (containerAssets?.assets?.length) {
      updateGridAssetsDispatch({ type: STACK_IMAGES, images: containerAssets.assets });
      updateGridAssetsDispatch({ type: FETCHING_IMAGES, fetching: false });
    }
  }, [publicAssets, containerAssets]);

  useEffect(() => {
    if ((assetGrid || folderGrid) && data?.assets) {
      // only used for the first fetch. After that, we use fetchMore query in useEffect above.
      if (afterImg.after === 0 || searchFilter) {
        updateGridAssetsDispatch({
          type: STACK_IMAGES,
          images: data.assets
        });

        if (data.assets.length >= PAGINATION_COUNT) {
          // set to active, will need to lazy load more assets through fetchMore.
          afterImgDispatch({ type: ACTIVE_AFTER, active: true });
        }

        updateGridAssetsDispatch({ type: FETCHING_IMAGES, fetching: false });
      }
    }
  }, [data, afterImg.after]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const searchKeyword = searchParams?.get('search');

    if (searchKeyword) {
      setSearchFilterInTabs(decodeURI(searchKeyword));
    } else {
      setSearchFilterInTabs();
    }
  });

  // Fetch campaign events for a selected asset.
  useEffect(() => {
    if (!campaignEventsLoading && campaignEvents && currentAsset) {
      setCurrentCampaignEvents(campaignEvents.campaign_events_by_asset);
    }
  }, [currentAsset, campaignEventsLoading, campaignEvents]);

  // Update cached GraphQL query after adding/deleting assets
  const updateQueryResults = async ({ action, assetData }) => {
    let updatedAssets = [...data.assets];

    switch (action) {
      case 'add':
        updatedAssets = [...assetData, ...gridAssets.images];
        if (assetData.length === 1) {
          setCurrentAsset(assetData[0]);
        }
        break;
      case 'delete':
        updatedAssets = await apiAssetDelete({ id: assetData.id }).then(result => {
          if (result && result.success) {
            if (gridAssets.images.indexOf(assetData) >= 0) {
              updatedAssets.splice(gridAssets.images.indexOf(assetData), 1);
            }
            setCurrentAsset(NONE_SELECTED);

            showToast('File has been deleted.', 'success');
          } else {
            const message = result.message || result.payload.message;
            showToast(message, 'error');
          }

          return updatedAssets;
        });
        break;
      default:
        updatedAssets = gridAssets.images;
    }
    return data.updateQuery(prevResult => ({ ...prevResult, assets: [...updatedAssets] }));
  };

  const onFolderClick = fName => {
    if (!modal) {
      if (folderGrid) {
        goToRoute(`${MediaPath}${fName}`, history);
      }
    }
  };

  // In shared tab, fetch all public company assets in Public folder.
  const getPublicOrgAssets = () => {
    goToRoute(MediaSharedPublicPath, history);
  };

  // In shared tab, callback to pull public assets and containers for a clicked container.
  const getChildrenAssetsAndContainers = id => {
    // check if we're inside a container
    const searchParams = new URLSearchParams(search);
    const container = searchParams.get('id');

    // if search query exists in url - append the next nested container with '&' instead of '?'
    if (container) {
      goToRoute(`${MediaSharedContainerPath}${search}&id=${id}`, history);
    } else {
      goToRoute(`${MediaSharedContainerPath}?id=${id}`, history);
    }
  };

  const deleteAsset = asset => {
    updateAssetSelection({ DESELECT, asset, multiselect });
    updateQueryResults({ ...DELETE, assetData: asset });
  };

  const selectAsset = ({ asset, select }) => {
    setCurrentAsset(select ? asset : NONE_SELECTED);
    updateAssetSelection({ asset, multiselect, select });
    if (select) {
      getCampaignEvents({ variables: { asset_id: asset.id } });
    }
  };

  // update asset with new fields from preview.
  const updateAssetFields = fileDetails => {
    const updateResult = apiAssetUpdate(fileDetails);

    if (updateResult?.data) {
      showToast('Image details updated.', 'success');
    }

    if (updateResult?.error) {
      showToast(`Error: "${updateResult.error}" Please try again. `, 'error');
    }

    return updateResult;
  };

  // Save public asset to user's library.
  const addToUserLibrary = async asset => {
    // check if filename exists and create a new one if it does.
    const filename = await validateFilename(asset.filename);
    // save new asset to base folder.
    createAssetFromHandle({
      variables: { uploadfolder: '', handles: [{ id: asset.handle }], newName: filename }
    });
  };

  if (data.loading) {
    return <AlbLoading />;
  }

  // Empty state in folder grid:
  if ((!gridAssets.images || !gridAssets.images[0]) && folderGrid) {
    return (
      <div>
        <Grid container className={classes.createFolderBox} alignItems="center">
          <Grid item style={{ width: '100%' }}>
            {!modal ? <CreateFolderCard uploadFolder={folderName} refetch={refetch} /> : null}
            <h2 className={classes.emptyFolderText}>
              This folder is empty.
              <HasAnyPriv privs={['SVC:ASSET:CREATE']}>
                <> Click on &quot;Add Media +&quot; to add images.</>
              </HasAnyPriv>
              <i className="fas fa-hand-point-up" style={{ marginLeft: 10 }} />
            </h2>
          </Grid>
        </Grid>
      </div>
    );
  }

  const assetTiles = gridAssets.images?.length
    ? gridAssets.images.map((asset, i) => (
        // eslint-disable-next-line react/jsx-indent
        <Grid item xs={6} md={3} lg={2} key={asset.id}>
          <MediaCard
            {...props}
            asset={asset}
            isCurrentAsset={currentAsset === asset}
            selectAsset={selectAsset}
            selectedAssets={selectedAssets}
            onFolderClick={onFolderClick}
            getFolderName={getFolderName}
            selectedAssetsInPost={selectedAssetsInPost}
            ref={i === gridAssets.images.length - 7 ? bottomBoundaryRef : null}
          />
        </Grid>
      ))
    : null;

  // child container folders and assets in container.
  const childContainerFolders =
    nestedContainers &&
    nestedContainers.map(child => (
      <Grid item xs={6} md={3} lg={2} key={child.id}>
        <SharedFolder
          folderName={child.name}
          callback={getChildrenAssetsAndContainers}
          folderId={child.id}
          key={child.id}
        />
      </Grid>
    ));

  return (
    <Grid
      container
      justifyContent="space-between"
      direction="row"
      alignContent="flex-start"
      alignItems="stretch"
      className={classes.scrollContainer}
    >
      <Grid item xs={9} className={classes.gridContainer}>
        <Grid container spacing={2} className={classes.mediaGrid}>
          {/* If 'folderGrid' is true display the 'add folder' card. */}
          {folderGrid && <CreateFolderCard uploadFolder={folderName} refetch={refetch} />}
          {/* Display current organization's assets */}
          {!sharedGrid && assetTiles}
          {/* If sharedGrid, display public org assets, and access nested container assets. */}
          {sharedGrid && sharedGridHelper === 'SHARED_MAIN' && (
            <>
              <Grid item xs={6} md={3} lg={2} key="public_media">
                <SharedFolder
                  callback={getPublicOrgAssets}
                  // eslint-disable-next-line camelcase
                  folderName={`${currentUser?.current_organization?.name}
                Shared Media`}
                />
              </Grid>
              {childContainerFolders}
            </>
          )}
          {sharedGrid && sharedGridHelper === 'PUBLIC_ORG_ASSETS' && assetTiles}
          {sharedGrid && sharedGridHelper === 'CHILD_CONTAINERS_AND_ASSETS' && (
            <>
              {childContainerFolders}
              {assetTiles}
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={3} style={{ minHeight: '70vh' }}>
        {/* shared Grid doesn't allow access to edit children containers' assets, only to copy them. */}
        <MediaPreview
          {...props}
          sharedGrid={sharedGrid}
          addToUserLibrary={sharedGrid ? addToUserLibrary : null}
          count={selectedAssets.length}
          deleteAsset={deleteAsset}
          currentAsset={multiselect ? selectedAssets[0] || null : currentAsset}
          selectedAssets={selectedAssets}
          currentCampaignEvents={currentCampaignEvents}
          updateAssetFields={updateAssetFields}
        />
      </Grid>
      {gridAssets.fetching && <AlbLoading />}
    </Grid>
  );
};

MediaGrid.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape(),
  multiselect: PropTypes.bool.isRequired,
  modal: PropTypes.bool,
  newAssets: PropTypes.arrayOf(PropTypes.shape()),
  assets: PropTypes.arrayOf(PropTypes.shape()),
  folderImages: PropTypes.arrayOf(PropTypes.shape()),
  folderGrid: PropTypes.bool,
  sharedGrid: PropTypes.bool,
  assetGrid: PropTypes.bool,
  history: PropTypes.shape(),
  match: PropTypes.shape(),
  getFolderName: PropTypes.func,
  searchFilter: PropTypes.string,
  folderName: PropTypes.string,
  currentUser: PropTypes.shape().isRequired,
  containerData: PropTypes.shape(),
  containerId: PropTypes.string,
  containerAssets: PropTypes.shape(),
  publicAssets: PropTypes.shape(),
  selectedAssetsInPost: PropTypes.arrayOf(PropTypes.shape()),
  setSharedTabContainerName: PropTypes.func,
  setSearchFilterInTabs: PropTypes.func,
  sortOrder: PropTypes.arrayOf(PropTypes.string),
  accounts: PropTypes.arrayOf(PropTypes.string)
};

MediaGrid.defaultProps = {
  modal: false,
  newAssets: [],
  assets: null,
  data: null,
  folderImages: null,
  assetGrid: false,
  folderGrid: false,
  sharedGrid: false,
  history: null,
  match: null,
  getFolderName: () => {},
  searchFilter: false,
  containerData: null,
  containerId: null,
  containerAssets: null,
  folderName: '',
  publicAssets: null,
  selectedAssetsInPost: [],
  setSharedTabContainerName: null,
  setSearchFilterInTabs: () => {},
  sortOrder: [],
  accounts: []
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.auth.currentUser
});

// Images Tab: Only image asset types and search in images.
export const ImageGrid = compose(
  connect(mapStateToProps),
  withProps({
    assetGrid: true
  }),
  graphql(GET_ASSETS, {
    skip: ownProps => ownProps.searchFilter,
    options: ownProps => ({
      variables: {
        filterBy: 'image',
        count: PAGINATION_COUNT,
        orderBy: ownProps.sortOrder,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  // Search in specific tab (image or video)
  graphql(GET_ASSETS, {
    skip: ownProps => !ownProps.searchFilter,
    options: ownProps => ({
      name: 'assets',
      variables: {
        keyword: ownProps.searchFilter,
        folderName: ownProps.folderName,
        filterBy: 'image',
        count: PAGINATION_COUNT,
        orderBy: ownProps.sortOrder,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  withStyles(styles)
)(MediaGrid);

// Videos Tab: Only video asset types and search in videos.
export const VideoGrid = compose(
  connect(mapStateToProps),
  withProps({
    assetGrid: true
  }),
  graphql(GET_ASSETS, {
    skip: ownProps => ownProps.searchFilter,
    options: ownProps => ({
      variables: {
        filterBy: 'video',
        count: PAGINATION_COUNT,
        orderBy: ownProps.sortOrder,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  // Search in specific tab (image or video)
  graphql(GET_ASSETS, {
    skip: ownProps => !ownProps.searchFilter,
    options: ownProps => ({
      name: 'assets',
      variables: {
        keyword: ownProps.searchFilter,
        folderName: ownProps.folderName,
        filterBy: 'video',
        count: PAGINATION_COUNT,
        orderBy: ownProps.sortOrder,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  withStyles(styles)
)(MediaGrid);

// Folders and images/videos. Folder tab - all assets in root folder if foldername isn't provided.
// Assets in specific folder if folderName (path) is provided.
export const FolderGrid = compose(
  connect(mapStateToProps),
  withProps({
    folderGrid: true
  }),
  graphql(GET_ASSETS, {
    skip: ownProps => ownProps.searchFilter || ownProps.modal, // non-modal media grid - Gather/Media
    options: ownProps => ({
      variables: {
        orderBy: ownProps.sortOrder,
        folderName: ownProps.folderName,
        count: PAGINATION_COUNT,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(GET_ASSETS, {
    skip: ownProps => ownProps.searchFilter || !ownProps.modal, // modal media library query (social post)
    options: ownProps => ({
      variables: {
        orderBy: ownProps.sortOrder,
        folderName: ownProps.uploadFolder,
        count: PAGINATION_COUNT,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  // Search-filtered assets. (Folders or images)
  graphql(GET_ASSETS, {
    skip: ownProps => !ownProps.searchFilter || ownProps.modal,
    options: ownProps => ({
      variables: {
        orderBy: ownProps.sortOrder,
        folderName: ownProps.folderName,
        keyword: ownProps.searchFilter,
        count: PAGINATION_COUNT,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  // Search inside media modal.
  graphql(GET_ASSETS, {
    skip: ownProps => !ownProps.searchFilter || !ownProps.modal,
    options: ownProps => ({
      variables: {
        orderBy: ownProps.sortOrder,
        folderName: ownProps.uploadFolder,
        keyword: ownProps.searchFilter,
        count: PAGINATION_COUNT,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  withStyles(styles)
)(MediaGrid);

// Shared Grid = org-wide public assets and assets in children containers
export const SharedGrid = compose(
  connect(mapStateToProps),
  withProps({
    sharedGrid: true
  }),
  graphql(CONTAINER, {
    name: 'containerData',
    skip: ownProps => !ownProps.containerId || ownProps.containerId === 'public',
    options: ownProps => ({
      variables: {
        id: ownProps.containerId,
        orderBy: ownProps.sortOrder,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(GET_ASSETS, {
    name: 'containerAssets',
    skip: ownProps =>
      !ownProps.containerId ||
      ownProps.containerId === getCurrentContainerIdFromRedux() ||
      ownProps.containerId === 'public',
    options: ownProps => ({
      variables: {
        containerId: ownProps.containerId,
        filterBy: 'image',
        keyword: ownProps.searchFilter || null,
        count: PAGINATION_COUNT,
        orderBy: ownProps.sortOrder,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(GET_PUBLIC_ASSETS, {
    name: 'publicAssets',
    options: ownProps => ({
      variables: {
        keyword: ownProps.searchFilter || null,
        count: PAGINATION_COUNT,
        orderBy: ownProps.sortOrder,
        accounts: ownProps.accounts
      },
      fetchPolicy: 'network-only'
    })
  }),
  withStyles(styles)
)(MediaGrid);
