import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import { openReplayTracker } from './util/openreplay';
import App from './App';
import client from './middleware/apolloClient';
import store from './store/index';
import ErrorBoundary from './ErrorBoundary';

/*
  Use the node environment to detect when we are in staging or production to
  determine if we should initialize Sentry because we do not want logs from
  development
*/
/*
class DebugRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null, 2));
    this.history.listen((location, action) => {
      console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
      console.log(
        `The last navigation action was ${action}`,
        JSON.stringify(this.history, null, 2)
      );
    });
  }
}
*/

if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-undef
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: `${process.env.VERSION}`,
    debug: true
  });

  openReplayTracker.start();
}

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
