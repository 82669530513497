import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, IconButton, Avatar, Popover } from '@material-ui/core';
import { KeyboardArrowDown as DownArrowIcon, Settings as SettingsIcon } from '@material-ui/icons';
import HasAnyPriv from 'components/HasAnyPriv';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import DashboardContainerNavigation from 'components/ContainerWidget/DashboardContainerNavigation';
import { CONTAINER } from 'gql/container';
import { DefaultContainerAvatar } from 'util/assets';
import { ManageOrganizationPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import isFeatureVisible from 'util/isFeatureVisible';
import { FEATURE_FLAG } from 'util/featureFlags';
import colors from 'util/colors';

const styles = makeStyles({
  containerNavButton: {
    marginLeft: '15px',
    paddingLeft: '10px'
  },
  currentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  currentContainerAvatar: {
    marginRight: '22px',
    width: '35px',
    height: '35px'
  },
  currentContainerTitle: {
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: '600',
    marginRight: '8px'
  },
  currentContainerTitleInverted: {
    color: '#0A1934'
  },
  popupHeader: {
    height: '54px',
    padding: '0px 20px',
    boxShadow: '0 2px 11px 0 rgba(0, 0, 0, 0.13)'
  },
  popupTitle: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#0d1a3a'
  },
  paper: {
    maxHeight: '630px',
    overflowY: 'auto'
  }
});

const ContainerNav = props => {
  const {
    history,
    currentContainer,
    featureFlags,
    startingContainer,
    handleContainerChange,
    analytics
  } = props;
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = styles();

  const isInsightDashboardVisible = isFeatureVisible(featureFlags, FEATURE_FLAG.INSIGHT_DASHBOARD);
  const { loading, error, data, refetch } = useQuery(CONTAINER, {
    variables: {
      id: currentContainer.id
    }
  });

  if (loading) return <AlbLoading />;
  if (error) return <AlbError error={error} refetch={refetch} />;

  return (
    <>
      <Button
        onClick={event => {
          setIsNavOpen(!isNavOpen);
          setAnchorEl(event.currentTarget);
        }}
        className={classes.containerNavButton}
        style={{ ...(analytics && { backgroundColor: 'transparent', paddingTop: '3px' }) }}
      >
        <Grid className={classes.currentContainer}>
          <Avatar
            alt="Container Avatar"
            src={data.container.avatar_url || DefaultContainerAvatar}
            className={classes.currentContainerAvatar}
          />
          <Typography
            className={`${classes.currentContainerTitle} ${
              isInsightDashboardVisible ? classes.currentContainerTitleInverted : ''
            }`}
            style={{
              maxWidth: '120px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...(analytics && { color: colors.navy })
            }}
          >
            {data.container.name}
          </Typography>
          <DownArrowIcon color="secondary" style={{ ...(analytics && { color: 'black' }) }} />
        </Grid>
      </Button>

      <Popover
        classes={{
          paper: classes.paper
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={isNavOpen}
        onClose={() => setIsNavOpen(false)}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.popupHeader}
        >
          <Typography className={classes.popupTitle}>CONTAINER NAVIGATION</Typography>
          <IconButton
            color="primary"
            onClick={() => {
              setIsNavOpen(false);
              goToRoute(ManageOrganizationPath, history);
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
        <HasAnyPriv privs={['ORG:READ']}>
          <DashboardContainerNavigation
            showSubContainer
            masterContainer
            containerId={startingContainer}
            closeWidget={() => setIsNavOpen(false)}
            handleContainerChange={handleContainerChange}
          />
        </HasAnyPriv>
      </Popover>
    </>
  );
};

ContainerNav.propTypes = {
  history: PropTypes.shape().isRequired,
  currentContainer: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  startingContainer: PropTypes.string.isRequired,
  handleContainerChange: PropTypes.func,
  analytics: PropTypes.bool
};

ContainerNav.defaultProps = {
  handleContainerChange: () => {},
  analytics: false
};

const mapStateToProps = state => {
  return {
    currentContainer: state.auth.currentContainer,
    currentOrganization: state.auth.currentOrganization,
    featureFlags: state.auth.featureFlags
  };
};

export default withRouter(connect(mapStateToProps)(ContainerNav));
