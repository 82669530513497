import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const StackedIcon = props => {
  const { accounts, width, height } = props;

  const containerWidth = width + (accounts.length - 1) * (width / 2);

  return (
    <Grid style={{ width: containerWidth, height, position: 'relative' }}>
      {accounts.map((account, index) => {
        const left = index * (width / 2);
        return (
          <img
            key={`stacked-icon-${left}`}
            alt="Social Icon"
            src={account?.platform?.icon}
            style={{ width, height, position: 'absolute', left: index > 0 ? left : null }}
          />
        );
      })}
    </Grid>
  );
};

StackedIcon.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  width: PropTypes.number,
  height: PropTypes.number
};

StackedIcon.defaultProps = {
  width: 40,
  height: 40
};

export default StackedIcon;
