import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
  YouTubeLogo,
  GoogleLogo,
  AdobeAnalyticsLogo,
  TikTokLogo
} from 'util/assets';

const useStyles = makeStyles({
  social: {
    marginTop: '5px'
  },
  channel: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px'
  },
  channelOpaque: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px',
    opacity: '0.3'
  }
});

const BoxSocial = props => {
  const { activeChannels, contributingChannels } = props;

  const classes = useStyles();

  const socials = [
    { alt: 'Facebook', logo: FacebookLogo },
    { alt: 'Twitter', logo: TwitterLogo },
    { alt: 'LinkedIn', logo: LinkedinLogo },
    { alt: 'Instagram', logo: InstagramLogo },
    { alt: 'YouTube', logo: YouTubeLogo },
    { alt: 'Google', logo: GoogleLogo },
    { alt: 'Adobe Analytics', logo: AdobeAnalyticsLogo },
    { alt: 'TikTok', logo: TikTokLogo }
  ];

  return (
    <Grid container justifyContent="center" className={classes.social}>
      {/** lower opacity for channels not included in the container's value */}
      {socials
        .filter(({ alt }) => activeChannels.includes(alt))
        .map(({ alt, logo }) => (
          <Grid
            key={alt}
            item
            className={contributingChannels.includes(alt) ? classes.channel : classes.channelOpaque}
          >
            <img src={logo} width={15} height={15} alt={alt} />
          </Grid>
        ))}
    </Grid>
  );
};

BoxSocial.propTypes = {
  activeChannels: PropTypes.arrayOf(PropTypes.string),
  contributingChannels: PropTypes.arrayOf(PropTypes.string)
};

BoxSocial.defaultProps = {
  activeChannels: [],
  contributingChannels: ['Facebook', 'Twitter', 'LinkedIn', 'Instagram', 'YouTube', 'TikTok']
};

export default BoxSocial;
