import React, { useState, useContext } from 'react';
import moment from 'moment';
import { Drawer, Grid } from '@material-ui/core';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { ArrowRight } from '@material-ui/icons';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import SourceSpikeDrawerView from 'components/SourceSpikeDrawerView';
import ConversionOutlierDrawerView from 'components/ConversionOutlierDrawerView';
import PageSpikeDrawerView from 'components/PageSpikeDrawerView';
import CorrelationDrawerView from 'components/CorrelationDrawerView';
import MultiPostResurgence from 'components/AnalyticsResurgence/MultiPostResurgence';
import DetectedEventDrawerView from 'components/DetectedEventDrawerView';
import { DETECTED_EVENT_TYPE, getDrawerHeaderTitle } from 'util/detectedEvents';
import getSimpleSocialType from 'util/getSimpleSocialType';
import ThirdPartyMediaDrawerView from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaDrawer';
import IntelligenceDrawerHeader from './IntelligenceDrawerHeader';

const useStyles = makeStyles({
  drawerPaper: {
    maxWidth: '1273px',
    flexDirection: 'row',
    overflow: 'unset'
  },
  container: {
    display: 'flex',
    width: '100%'
  },
  content: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingBottom: '30px'
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    background: '#0A1734',
    height: 'auto'
  },
  arrow: {
    color: '#ffffff'
  }
});

/**
 * @method
 * @summary Implements useContext() on DashboardIntelligenceDrawerContext and displays corresponding data in a drawer.
 * Wrapper for SourceSpikeDrawerView and ConversionOutlierDrawerView.
 * @name DashboardIntelligenceDrawer
 * @return {Object} - React JSX
 */

const DashboardIntelligenceDrawer = () => {
  const classes = useStyles();
  const drawerContext = useContext(DashboardIntelligenceDrawerContext);

  const {
    isDrawerOpen,
    isConversionOutlier,
    isSourceSpike,
    isPageSpike,
    isCorrelation,
    isMultiPostDetection,
    isDetectedEvent,
    isThirdPartyMedia,
    event,
    toggleDrawer
  } = drawerContext;

  const dateRange = event && {
    start: moment.utc(event.startDate),
    end: moment.utc(event.endDate).endOf('day')
  };

  let logoType;

  if (!(isCorrelation || isMultiPostDetection) && event?.linktoken?.type != null) {
    logoType = getSimpleSocialType(event.linktoken.type, true);
  }

  const renderLogoForEventTypes = [
    DETECTED_EVENT_TYPE.SL_CLICKS,
    ...Object.keys(DETECTED_EVENT_TYPE).filter(
      eventType =>
        eventType.startsWith('BC') ||
        eventType.startsWith('PC') ||
        eventType.startsWith('NC_PC') ||
        eventType.startsWith('NC_BC') ||
        eventType.startsWith('SB') ||
        eventType.startsWith('SP')
    )
  ];

  if (renderLogoForEventTypes.includes(event?.type)) {
    logoType = getSimpleSocialType(event.type, true);
  }

  const [graphDates, setGraphDates] = useState(null);

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {isDrawerOpen && (
          <Grid className={classes.container}>
            <div
              role="button"
              className={classes.bar}
              onClick={() => toggleDrawer(false)}
              aria-hidden="true"
            >
              <ArrowRight className={classes.arrow} />
            </div>
            <Grid
              container
              direction="column"
              wrap="nowrap"
              className={classes.content}
              style={!(isCorrelation || isMultiPostDetection) ? { maxWidth: '688px' } : null}
            >
              {!isThirdPartyMedia && (
                <IntelligenceDrawerHeader
                  toggleDrawer={toggleDrawer}
                  headerTitle={getDrawerHeaderTitle(drawerContext)}
                  event={event}
                  dates={dateRange}
                  logoType={logoType}
                  noEventText={!!isThirdPartyMedia}
                  isCorrelation={isCorrelation}
                  setGraphDates={setGraphDates}
                />
              )}
              {isSourceSpike && <SourceSpikeDrawerView event={event} dateRange={dateRange} />}
              {isConversionOutlier && (
                <ConversionOutlierDrawerView event={event} dateRange={dateRange} />
              )}
              {isPageSpike && <PageSpikeDrawerView event={event} dateRange={dateRange} />}
              {isCorrelation && <CorrelationDrawerView event={event} />}
              {isMultiPostDetection && (
                <MultiPostResurgence
                  linktokenId={event.linktokenId}
                  startDate={event.startDate}
                  endDate={event.endDate}
                  halfDrawer
                />
              )}
              {isDetectedEvent && (
                <DetectedEventDrawerView
                  detectedEvent={event.originalObject}
                  graphDates={graphDates}
                />
              )}
              {isThirdPartyMedia && <ThirdPartyMediaDrawerView details={event} />}
            </Grid>
          </Grid>
        )}
      </Drawer>
    </MuiThemeProvider>
  );
};

export default DashboardIntelligenceDrawer;
