import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import AlembicPageHeader from 'components/AlembicPageHeader';
import setPageTitle from 'util/titles';
import LinkGooglePlayConsoleAccountForm from './LinkGooglePlayStoreAccountForm';

/**
 * @method
 * @summary This component renders the flow for connecting Google Play Console
 * @name LinkGooglePlayConsoleAccountForm
 * @return {Object} - React JSX
 */
const LinkGooglePlayConsoleAccount = () => {
  useEffect(() => {
    setPageTitle('Link Google Play Console');
  }, []);

  return (
    <>
      <Box mb={30}>
        <AlembicPageHeader pageTitle="Link Google Play Console" backButton />
      </Box>

      <Paper>
        <HasAnyPriv privs={['LINKS', 'LINKS:CREATE']} showAlert>
          <Box p={24}>
            <LinkGooglePlayConsoleAccountForm />
          </Box>
        </HasAnyPriv>
      </Paper>
    </>
  );
};

export default LinkGooglePlayConsoleAccount;
