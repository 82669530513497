import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ArrowRight } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import AlembicTitleChip from 'components/AlembicTitleChip';
import colors from 'util/colors';

const useStyles = makeStyles({
  drawerPaper: {
    minWidth: '617px',
    maxWidth: '617px',
    flexDirection: 'row'
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    paddingLeft: '25px'
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    background: colors.navy
  },
  arrow: {
    color: colors.white
  },
  content: {
    flex: 1
  },
  searchCriteriaTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    color: colors.navy,
    marginBottom: '24px',
    paddingTop: '25px'
  }
});

/**
 * @method
 * @summary This component renders the drawer for the search criteria.
 * @name SearchCriteriaDrawer
 * @param {boolean} open - boolean to determine if the drawer is open or not
 * @param {Function} onClose - the onClose function from the parent component
 * @param {String} criteriaTitle - the title to display in the drawer
 * @param {Object[]} criteriaFields - Key-value pairs filters rendered in chips inside the SearchCriteriaDrawer, passed in format: [{ title: '', body: ''}]
 * @return {Object} - React JSX
 */

const SearchCriteriaDrawer = ({ open, onClose, criteriaTitle, criteriaFields }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        variant="temporary"
        open={open}
        anchor="right"
        onClose={() => onClose(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div role="button" className={classes.bar} onClick={() => onClose()} aria-hidden="true">
          <ArrowRight className={classes.arrow} />
        </div>
        <div className={classes.container}>
          <div className={classes.content}>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.searchCriteriaTitle}>{criteriaTitle}</Typography>
              </Grid>
              {criteriaFields.map((filter, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={`${filter}-${i}`} className={classes.searchCriteriaItems}>
                  <AlembicTitleChip title={filter.title} body={filter.body} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Drawer>
    </MuiThemeProvider>
  );
};

SearchCriteriaDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  criteriaTitle: PropTypes.string.isRequired,
  criteriaFields: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

SearchCriteriaDrawer.defaultProps = {
  open: false
};

export default SearchCriteriaDrawer;
