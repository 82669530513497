import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItemIcon, Box, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import moment from 'moment';
import { linePalette } from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import { GET_WEB_TOTAL_USERS_DRUID } from 'gql/dashboardIntelligenceEvent';
import { useQuery } from '@apollo/client';
import { Bar, defaults } from 'react-chartjs-2';
import colors from 'util/colors';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import GraphHeader from 'components/PostDrawerView/GraphHeader';
import { merge } from 'lodash';

merge(defaults, { global: { defaultFontFamily: 'Poppins' } });

const useStyles = makeStyles({
  graphContainer: {
    width: '100%',
    marginBottom: '20px'
  },
  graph: {
    width: '100%',
    height: '100%',
    position: 'relative',
    marginTop: '15px'
  },
  graphHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1
  },
  graphHeaderDate: {
    flex: 1
  },
  title: {
    color: '#0a1734',
    fontSize: '16px',
    fontWeight: 500
  },
  legend: {
    marginTop: '10px'
  },
  legendColor: {
    height: '16px',
    width: '16px',
    backgroundColor: linePalette.backgroundColor,
    borderRadius: '3px',
    marginRight: '10px'
  },
  legendText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '12px',
    color: '#6F6F6F',

    '& > :last-child': {
      color: '#0b1835'
    }
  },
  listItem: {
    minWidth: 'unset'
  }
});

/**
 * @method
 * @summary The graph section of @SourceSpikeDrawerView and @PageSpikeDrawerView
 * @name SpikeTotalUsersGraph
 * @param { Object } dates - the start and end date of the event in utc, used to query for timeseries data in the graph.
 * @param { String } linktokenEventId - the id of the event, used to query for timeseries data in the graph.
 * @return {Object} - React JSX
 */

const SpikeTotalUsersGraph = ({ dates, linktokenEventId, detectedEventId, spikeType }) => {
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(0); // defaulted to +/- 3 days
  const [formattedGraphData, setFormattedGraphData] = useState(null);
  const [total, setTotal] = useState();

  const { start, end } = dates;

  // the end dates have an extra day appended in order to fetch data for the last date
  // otherwise, we only request and receive data until the day prior to the end date
  const dateRangeOptions = [
    {
      label: '+/- 3D',
      range: { startDate: start.clone().subtract(3, 'd'), endDate: end.clone().add(3, 'd') }
    },
    {
      label: '+/- 7D',
      range: { startDate: start.clone().subtract(7, 'd'), endDate: end.clone().add(7, 'd') }
    },
    {
      label: '+/- 30D',
      range: { startDate: start.clone().subtract(30, 'd'), endDate: end.clone().add(30, 'd') }
    }
  ];

  const { data, error, loading } = useQuery(GET_WEB_TOTAL_USERS_DRUID, {
    variables: {
      linktokenEventId,
      detectedEventId,
      ...dateRangeOptions[selectedDateRange].range,
      spikeType
    }
  });

  const graphOptions = {
    legend: { display: false },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'label',
      intersect: false,
      callbacks: {
        label(tooltipItem, chartData) {
          const valueText = Math.round(
            chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          ).toLocaleString();

          return valueText;
        }
      },
      // hide tooltip for zero values
      filter: (tooltipItem, chartData) =>
        !!chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: colors.gray,
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600'
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            precision: 2,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600',
            callback: value => value.toLocaleString()
          }
        }
      ]
    }
  };

  useEffect(() => {
    if (data?.getWebTotalUsers) {
      const labels = [];
      const type = 'line';

      const datasets = [
        {
          label: `Total Users: ${data.getWebTotalUsers.totalUsers}`,
          type,
          data: data.getWebTotalUsers.result?.map(({ x, y }) => {
            labels.push(moment.utc(x).format('MMM D'));

            return y;
          }),
          ...linePalette,
          ...(type === 'line' && {
            fill: false,
            lineTension: 0,
            pointRadius: 0
          })
        }
      ];

      setTotal(data.getWebTotalUsers.totalUsers);
      setFormattedGraphData({ labels, datasets });
    }
  }, [data]);

  return (
    <Grid container className={classes.graphContainer} direction="column">
      <Grid container className={classes.graphHeaderDate}>
        <Grid item onClick={() => setIsCollapsed(!isCollapsed)}>
          <ListItemIcon classes={{ root: classes.listItem }}>
            {isCollapsed ? <Right /> : <Down />}
          </ListItemIcon>
        </Grid>
        <GraphHeader
          label="Total Users"
          isCollapsed={isCollapsed}
          dateRangeOptions={dateRangeOptions}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          hasGraph
          tooltipText={getToolTipTitleValue('totalUsersDetail')}
        />
      </Grid>
      <Grid item>
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          {!!error && <AlbError error={error} />}
          {!!loading && (
            <Box className={classes.loadingOverlay}>
              <AlbLoading />
            </Box>
          )}
          {formattedGraphData && (
            <Box className={classes.graph}>
              <Bar data={formattedGraphData} options={graphOptions} />
              <Grid className={classes.legend} container justifyContent="center">
                <Grid className={classes.legendColor} item />
                <Grid className={classes.legendText} item>
                  <Grid item>Total Users</Grid>
                  <Grid item>{total.toLocaleString()}</Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Collapse>
      </Grid>
    </Grid>
  );
};

SpikeTotalUsersGraph.propTypes = {
  dates: PropTypes.shape().isRequired,
  linktokenEventId: PropTypes.string,
  detectedEventId: PropTypes.string,
  spikeType: PropTypes.string.isRequired
};

SpikeTotalUsersGraph.defaultProps = {
  linktokenEventId: null,
  detectedEventId: null
};

export default SpikeTotalUsersGraph;
