import { gql } from '@apollo/client';

export const OWNED_TO_EARNED_ANALYTICS = gql`
  query ownedToEarnedAnalytics($id: ID!, $filterId: ID) {
    ownedToEarnedAnalytics(id: $id, filterId: $filterId) {
      earned {
        period_start_date
        total_articles
        total_twitter_share_count
        total_facebook_share_count
        total_pinterest_share_count
        total_linkedin_share_count
        total_content_word_count
        total_totals_share_count
      }
      owned {
        period_start_date
        total_posts
        total_impressions
        total_shares
      }
    }
  }
`;

export const ARTICLE = gql`
  query article($id: ID!) {
    article(id: $id) {
      id
      image
      thumbnail
      url
      created_at
      published_date
      title
      facebook_share_count
      twitter_share_count
      total_share_count
      total_ratings
      domain
    }
  }
`;

export const ARTICLES = gql`
  query getArticles(
    $searchID: ID!
    $perPage: Int
    $offset: Int
    $sortOrder: String
    $sortField: String
    $ignoredArticleFilter: ignoredArticleFilterEnum!
    $domainFilter: String
    $minCreatedDate: DateTimeISO
    $minPublishedDate: DateTimeISO
    $searchFilter: ID
  ) {
    getArticles(
      searchID: $searchID
      perPage: $perPage
      offset: $offset
      sortOrder: $sortOrder
      sortField: $sortField
      ignoredArticleFilter: $ignoredArticleFilter
      domainFilter: $domainFilter
      minCreatedDate: $minCreatedDate
      minPublishedDate: $minPublishedDate
      searchFilter: $searchFilter
    ) {
      search_id
      total_results
      next_page_offset
      this_page_offset
      per_page_count
      articles {
        id
        image
        thumbnail
        url
        created_at
        published_date
        title
        facebook_share_count
        twitter_share_count
        total_share_count
        domain
      }
    }
  }
`;

export const DOMAINS = gql`
  query getArticleSearchDomains(
    $searchID: ID!
    $offset: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
    $ignoredArticleFilter: ignoredArticleFilterEnum!
    $minPublishedDate: DateTimeISO
    $maxPublishedDate: DateTimeISO
    $searchFilter: ID
  ) {
    getArticleSearchDomains(
      searchID: $searchID
      offset: $offset
      perPage: $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      ignoredArticleFilter: $ignoredArticleFilter
      minPublishedDate: $minPublishedDate
      maxPublishedDate: $maxPublishedDate
      searchFilter: $searchFilter
    ) {
      search_id
      total_results
      next_page_offset
      this_page_offset
      per_page_count
      domains {
        domain
        facebook_share_count
        twitter_share_count
        total_share_count
        count
      }
    }
  }
`;

export const SET_ARTICLE_IGNORE = gql`
  mutation setArticleIgnore($id: ID!, $ignored: Boolean!) {
    setArticleIgnore(id: $id, ignored: $ignored) {
      id
      url
      published_date
      title
      facebook_share_count
      twitter_share_count
      pinterest_share_count
      total_share_count
      domain
      ignored
    }
  }
`;

export const GET_TOP_ARTICLES = gql`
  query getArticles(
    $searchID: ID!
    $perPage: Int
    $offset: Int
    $sortOrder: String
    $sortField: String
    $ignoredArticleFilter: ignoredArticleFilterEnum!
    $minPublishedDate: DateTimeISO
    $maxPublishedDate: DateTimeISO
    $valuationType: String
  ) {
    getArticles(
      searchID: $searchID
      perPage: $perPage
      offset: $offset
      sortOrder: $sortOrder
      sortField: $sortField
      ignoredArticleFilter: $ignoredArticleFilter
      minPublishedDate: $minPublishedDate
      maxPublishedDate: $maxPublishedDate
      valuationType: $valuationType
    ) {
      search_id
      total_results
      articles {
        id
        image
        thumbnail
        url
        created_at
        published_date
        title
        facebook_share_count
        twitter_share_count
        total_share_count
        domain
        valuation
      }
    }
  }
`;

export const GET_TOP_DOMAINS = gql`
  query getArticleSearchDomains(
    $searchID: ID!
    $offset: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
    $ignoredArticleFilter: ignoredArticleFilterEnum!
    $minPublishedDate: DateTimeISO
    $maxPublishedDate: DateTimeISO
    $valuationType: String
  ) {
    getArticleSearchDomains(
      searchID: $searchID
      offset: $offset
      perPage: $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      ignoredArticleFilter: $ignoredArticleFilter
      minPublishedDate: $minPublishedDate
      maxPublishedDate: $maxPublishedDate
      valuationType: $valuationType
    ) {
      search_id
      total_results
      next_page_offset
      this_page_offset
      per_page_count
      domains {
        domain
        facebook_share_count
        twitter_share_count
        total_share_count
        count
        valuation
      }
    }
  }
`;

export const GET_PAGINATED_ARTICLES = gql`
  query getPaginatedArticles(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $articleSearchIds: [ID!]
    $articleSearchFilterIds: [ID!]
    $after: Int!
    $count: Int!
    $orderBy: [String]
    $enabled: Boolean!
  ) {
    getPaginatedArticles(
      startDate: $startDate
      endDate: $endDate
      articleSearchIds: $articleSearchIds
      articleSearchFilterIds: $articleSearchFilterIds
      after: $after
      count: $count
      orderBy: $orderBy
      enabled: $enabled
    ) {
      rows {
        id
        thumbnail
        published_date
        url
        title
        total_share_count
        total_ratings
        article_search {
          name
        }
      }
      count
    }
  }
`;
