import { gql } from '@apollo/client';

export const GET_PAGINATED_MENTIONS_AND_ARTICLES = gql`
  query getPaginatedMentionsAndArticles(
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
    $articleSearchIds: [ID!]
    $articleSearchFilterIds: [ID!]
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $after: Int!
    $count: Int!
    $orderBy: [String]
    $mediaType: String!
    $isEnabled: Boolean!
  ) {
    getPaginatedMentionsAndArticles(
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
      articleSearchIds: $articleSearchIds
      articleSearchFilterIds: $articleSearchFilterIds
      startDate: $startDate
      endDate: $endDate
      after: $after
      count: $count
      orderBy: $orderBy
      mediaType: $mediaType
      isEnabled: $isEnabled
    ) {
      count
      rows {
        id
        air_time
        viewership_count
        title
        highlight_mention_text
        source
        table_name
        group_seq
        disabled
        network_affiliation
        station_name
        author
        program_title
        podcast_title
        language
        thumbnail
      }
    }
  }
`;

export const GET_MENTIONS_FROM_DETECTED_EVENT = gql`
  query getMentionsFromDetectedEvent($detectedEventId: ID!) {
    getMentionsFromDetectedEvent(detectedEventId: $detectedEventId) {
      count
      shares
      total_viewership
      rows {
        id
        air_time
        viewership
        source
        broadcast_mention_text
        podcast_mention_text
        broadcast {
          id
          network_affiliation
          station_name
          program_title
        }
        podcast {
          id
          author
          podcast_title
        }
      }
    }
  }
`;

export const ENABLE_MENTION = gql`
  mutation($id: ID!) {
    enableMention(id: $id) {
      id
      disabled
    }
  }
`;

export const DISABLE_MENTION = gql`
  mutation($id: ID!) {
    disableMention(id: $id) {
      id
      disabled
    }
  }
`;
