import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, CardActionArea, CardMedia, Typography } from '@material-ui/core';
import { FolderOpen } from '@material-ui/icons';
import { FS_THUMB_URL, GET_FOLDER_FIRST_IMAGE_AND_COUNT } from 'gql/asset';
import { showToast } from 'contexts/ToastContext';
import moment from 'moment';
import { NoVideo as NoVideoIcon } from 'util/assets';
import { formatMillisecondsDisplay } from 'util/time';
import { formatFolderName } from 'util/assetNameValidation';
import AlbLoading from '../AlbLoading';

const styles = makeStyles(() => ({
  card: {
    paddingBottom: '100%',
    position: 'relative',
    background: '#f8f9fa',
    '&:hover': {
      outline: '2px solid #0A1734',
      outlineOffset: '3px',
      cursor: 'pointer'
    },
    '& > *': {
      bottom: 0,
      height: '100%',
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%'
    }
  },
  outlined: {
    outline: '2px solid #0A1734',
    outlineOffset: '3px'
  },
  countCircle: {
    color: '#0A1734',
    backgroundColor: 'white',
    border: '1px solid #0A1734',
    borderRadius: '50%',
    fontSize: '1em',
    width: '1.5em',
    height: '1.5em',
    textAlign: 'center',
    bottom: '10px',
    right: '10px',
    position: 'absolute'
  },
  defaultFolderIcon: {
    width: '30%',
    height: 'auto',
    fontSize: '70px',
    color: '#383838'
  },
  cardContent: {
    height: '15px',
    padding: '3px',
    marginTop: '3px',
    marginBottom: '30px',
    width: '100%'
  },
  titleStyle: {
    color: '#383838',
    fontSize: '13px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    padding: '3px',
    marginTop: '3px'
  },
  loadingPlaceholder: {
    backgroundColor: '#000000',
    height: '100%',
    width: '100%',
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  videoLengthText: {
    display: 'flex',
    position: 'absolute',
    zIndex: '1',
    color: '#ffffff',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: '4%',
    marginRight: '4%'
  },
  noVideoIcon: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    background: 'lightGrey'
  }
}));

const FOLDER = 'application/vnd.alembic.folder';

// check if asset is image or folder.
function assetType(asset) {
  if (asset.mime_type === FOLDER) {
    return 'FOLDER';
  }
  if (asset.mime_type.includes('image')) {
    return 'IMAGE';
  }
  if (asset.mime_type.includes('video')) {
    return 'VIDEO';
  }
  return null;
}

const getCardImage = asset => {
  return `${FS_THUMB_URL}${asset.signed_thumbnail_url}`;
};

const checkIncompleteVideoInfo = asset => {
  const { created_at: createdAt, signed_thumbnail_url: thumbnailUrl } = asset;

  const buffer = moment(createdAt).add(1, 'minutes');
  const now = moment();

  // We didn't receive the second part of the video from FileStack. Stop loading icon / show placeholder until we retry.
  return now > buffer && !thumbnailUrl;
};

const MediaCard = React.forwardRef((props, ref) => {
  const {
    asset,
    isCurrentAsset,
    multiselect,
    selectAsset,
    modal,
    onFolderClick,
    setAssetType,
    getFolderName,
    selectedAssets,
    // need to pass this in to find index of asset (strict equality issue)
    selectedAssetsInPost
  } = props;
  const [selected, toggleSelected] = useState(false);
  const classes = styles();
  const { outlined, card, defaultFolderIcon, titleStyle, cardContent } = classes;

  useEffect(() => {
    if (!multiselect) {
      toggleSelected(isCurrentAsset);
    }
  }, [isCurrentAsset]);

  // mark all already selected assets as selected.
  useEffect(() => {
    if (multiselect && selectedAssets.length) {
      const assetAlreadySelected = selectedAssets.find(el => el.id === asset.id);
      if (assetAlreadySelected) {
        toggleSelected(true);
      }
    }
  }, [selectedAssets]);

  const type = assetType(asset);

  const getDecrementValue = () => {
    // locate correct asset object in selectedAssets.
    let index;

    if (selectedAssets.length) {
      index = selectedAssets.indexOf(asset);
      if (index === -1 && selectedAssetsInPost.length) {
        index = selectedAssets.indexOf(selectedAssetsInPost.find(el => el.id === asset.id));
      }
    }
    return index;
  };

  const handleAssetClick = filename => {
    // don't let not fully uploaded assets to be selected in modal.
    if (!asset.signed_thumbnail_url && asset.mime_type !== FOLDER) {
      return showToast('Cannot select asset that has not finished uploading yet.', 'error');
    }

    if (asset.mime_type !== FOLDER) {
      // selected asset is an image type - show in preview.
      selectAsset({ asset, select: !selected });
      toggleSelected(!selected);
      setAssetType(type);
    } else {
      // it's a folder - route to folder.
      onFolderClick(filename);
      getFolderName(filename);
    }

    return null;
  };

  if (type === 'IMAGE') {
    return (
      <div>
        <Card
          square
          elevation={0}
          key={asset.id}
          ref={ref}
          raised={false}
          className={`${card} ${selected ? outlined : ''}`}
          onClick={handleAssetClick}
        >
          <CardMedia image={getCardImage(asset)} alt={asset.filename} />
          <CardActionArea role={modal ? 'presentation' : 'button'} tabIndex={modal ? null : 0} />
          {multiselect && selected && (
            <div className={classes.countCircle}>{selectedAssets.length - getDecrementValue()}</div>
          )}
        </Card>
      </div>
    );
  }

  if (type === 'VIDEO') {
    const thumbUrl = asset.signed_thumbnail_url;
    const incompleteVideoInfo = checkIncompleteVideoInfo(asset);

    return (
      <div>
        <Card
          square
          elevation={0}
          ref={ref}
          key={asset.id}
          raised={false}
          className={`${card} ${selected ? outlined : ''}`}
          onClick={!incompleteVideoInfo ? handleAssetClick : null}
        >
          {thumbUrl && (
            <>
              <CardMedia
                image={getCardImage(asset)}
                width="300"
                height="300"
                alt={asset.filename}
              />
              <div className={classes.videoLengthText}>
                {formatMillisecondsDisplay(asset.duration)}
              </div>
            </>
          )}
          {incompleteVideoInfo && (
            <div className={classes.noVideoIcon}>
              <img
                src={NoVideoIcon}
                alt="Video File"
                width={100}
                height={100}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              />
              <Typography>Upload failed.</Typography>
              <Typography>Please try again.</Typography>
            </div>
          )}
          {!thumbUrl && !incompleteVideoInfo && (
            <div className={classes.loadingPlaceholder}>
              <AlbLoading color="inherit" />
            </div>
          )}
          <CardActionArea role={modal ? 'presentation' : 'button'} tabIndex={modal ? null : 0} />
          {multiselect && selected && (
            <div className={classes.countCircle}>
              {selectedAssets.length - selectedAssets.indexOf(asset)}
            </div>
          )}
        </Card>
      </div>
    );
  }

  if (type === 'FOLDER') {
    const fName = formatFolderName(asset.filename);

    let cleanFn = asset.filename;

    let coverImage = null;
    let assetCount = 0;
    let imageFilename = '';

    // query to get the folder preview image and the count of assets.
    const { data, loading, error } = useQuery(GET_FOLDER_FIRST_IMAGE_AND_COUNT, {
      variables: {
        folderName: cleanFn
      },
      fetchPolicy: 'network-only'
    });

    if (loading) return <AlbLoading />;
    if (error) return error;

    if (asset.filename.charAt(asset.filename.length - 1) === '/') {
      cleanFn = asset.filename.slice(0, asset.filename.length - 1);
    }

    if (data?.getFirstImageAndCount?.asset) {
      coverImage = getCardImage(data.getFirstImageAndCount.asset);
      imageFilename = data.getFirstImageAndCount.asset.filename;
    }

    if (data?.getFirstImageAndCount?.count) {
      assetCount = data.getFirstImageAndCount.count;
    }

    return (
      <div>
        <Card
          ref={ref}
          square
          elevation={0}
          className={card}
          onClick={() => handleAssetClick(cleanFn)}
        >
          {coverImage && <CardMedia image={coverImage} alt={imageFilename} />}
          {!coverImage && <FolderOpen className={defaultFolderIcon} />}
        </Card>
        <Grid container className={cardContent} justifyContent="space-between">
          <Grid item className={titleStyle}>
            {fName}
          </Grid>
          <Grid item className={titleStyle}>
            {assetCount}
          </Grid>
        </Grid>
      </div>
    );
  }
  return null;
});

MediaCard.propTypes = {
  asset: PropTypes.shape().isRequired,
  isCurrentAsset: PropTypes.bool,
  modal: PropTypes.bool.isRequired,
  multiselect: PropTypes.bool.isRequired,
  selectAsset: PropTypes.func,
  onFolderClick: PropTypes.func,
  setAssetType: PropTypes.func,
  getFolderName: PropTypes.func,
  selectedAssets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedAssetsInPost: PropTypes.arrayOf(PropTypes.shape())
};

MediaCard.defaultProps = {
  isCurrentAsset: false,
  selectAsset: () => {},
  onFolderClick: () => {},
  setAssetType: () => {},
  getFolderName: () => {},
  selectedAssetsInPost: null
};

export default MediaCard;
