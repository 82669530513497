import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Select, MenuItem, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { platforms, getPlatformName } from 'util/social';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import { renderUIDate } from 'util/date';

/*
  Post Support is a component within MediaPreview that shows a data preview
  for a particular post the asset is used in, and links to single post analytics.
*/

const styles = {
  container: {
    marginBottom: '12px'
  },
  valueTitle: {
    fontSize: '10px',
    color: '#0A1734',
    textAlign: 'center'
  },
  value: {
    textAlign: 'center',
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#32327D'
  },
  select: {
    width: '100%'
  },
  date: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily: 'Poppins',
    fontSize: '12px',
    color: '#6f6f6f'
  },
  iconAndDate: {
    margin: '10px 0px'
  },
  metrics: {
    margin: '15px 0px'
  },
  analyticsButton: {
    margin: '15px 0px'
  }
};

const getPlatform = event => {
  const platformInternalName = getPlatformName(event.linked_account);

  return platforms.find(p => p.name === platformInternalName);
};

const getFormattedDate = date => {
  if (!date) {
    return '';
  }
  return renderUIDate(date);
};

const getFormattedName = event => {
  const platform = getPlatform(event);
  const platformExternalName = platform.displayName;
  const date = getFormattedDate(event.completed_at);

  return `${date} ${platformExternalName} Post`;
};

const formatNumbers = num =>
  Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(num);

const PostSupport = ({ postData, classes }) => {
  const [selectedPost, setSelectedPost] = useState(postData[0]);
  const drawerContext = useContext(PostDrawerViewContext);

  const platform = getPlatform(selectedPost);
  const platformIcon = platform.icon;
  const formattedDate = selectedPost.completed_at
    ? getFormattedDate(selectedPost.completed_at)
    : 'N/A';

  useEffect(() => {
    setSelectedPost(postData[0]);
  }, [postData]);

  const handleDrawerOpen = () =>
    drawerContext.toggleDrawer(true, selectedPost.id, null, null, null, null);

  const getMetricValue = metric => {
    if (metric === null) {
      return '-';
    }
    return formatNumbers(metric);
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <Select
          value={selectedPost.id}
          onChange={e => {
            setSelectedPost(postData.find(post => post.id === e.target.value));
          }}
          className={classes.select}
          variant="outlined"
        >
          {postData?.length &&
            postData.map(event => (
              <MenuItem value={event.id} key={event.id}>
                {event.event_title.length
                  ? `${getFormattedDate(event.completed_at)} ${event.event_title}`
                  : getFormattedName(event)}
              </MenuItem>
            ))}
        </Select>
      </Grid>

      <Grid container alignItems="center" className={classes.iconAndDate}>
        <img src={platformIcon} alt={`${platform.name}-icon`} height="25" width="25" />
        <div className={classes.date}>Post Time: {formattedDate}</div>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" className={classes.metrics}>
        <Grid item xs={3}>
          <div className={classes.valueTitle}>Impressions</div>
          <div className={classes.value}>{getMetricValue(selectedPost.lifetime_impressions)}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.valueTitle}>Engagement</div>
          <div className={classes.value}>{getMetricValue(selectedPost.lifetime_engagement)}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.valueTitle}>Shares</div>
          <div className={classes.value}>{getMetricValue(selectedPost.lifetime_shares)}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.valueTitle}>Views</div>
          <div className={classes.value}>{getMetricValue(selectedPost.lifetime_views)}</div>
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.analyticsButton}
        onClick={() => handleDrawerOpen()}
        disabled={!selectedPost.completed_at}
      >
        View Post Analytics
      </Button>
      <Divider />
    </Grid>
  );
};

PostSupport.propTypes = {
  classes: PropTypes.shape().isRequired,
  postData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default withStyles(styles)(PostSupport);
