import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Grid, Typography } from '@material-ui/core';
import AlbHorizontalBarGraph from 'components/AlbGraphs/AlbHorizontalBarGraph';

/**
 * Bar graph for owned
 * @name OwnedBar
 * @param {Object} props - React props passed to this component
 * @param {Object} props.graphData - An array of data points with x as the label and y as the data
 * @return {Object} - React JSX
 */
const OwnedBar = props => {
  const { graphData } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (graphData?.length > 0) {
      const tempLabels = graphData.map(({ x }) => x);
      const tempData = graphData.map(({ y }) => y);

      setData({
        datasets: [
          {
            data: tempData,
            backgroundColor: '#32327D'
          }
        ],
        labels: tempLabels
      });
    } else if (graphData?.length === 0) {
      setData({
        datasets: [],
        labels: []
      });
    } else {
      setData(null);
    }
  }, [graphData]);

  if (data?.datasets?.length === 0) {
    return (
      <Grid container justifyContent="center">
        <Typography>There is no data for this graph.</Typography>
      </Grid>
    );
  }

  if (data) {
    return <AlbHorizontalBarGraph data={data} valuation />;
  }

  return <Skeleton variant="rect" width="100%" height={500} style={{ margin: '25px 0' }} />;
};

OwnedBar.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.shape())
};

OwnedBar.defaultProps = {
  graphData: null
};

export default OwnedBar;
