import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import AlbGraphDruid from 'components/AlbGraphs/AlbGraphDruid';
import AlbTableDruid from 'components/AlbGraphs/AlbTableDruid';
import AlbGeoTable from 'components/AlbGraphs/AlbGeoTable';
import Banner from 'components/Banner';
import BoxContentTablePosts from './BoxContentTablePosts';
import BoxContentTableDruid from './BoxContentTableDruid';
import BoxContentTableAge from './BoxContentTableAge';
import BoxHeader from './BoxHeader';

const useStyles = makeStyles({
  container: {
    width: '100%',
    flex: 1,
    marginBottom: '50px'
  },
  box: {
    flex: 1,
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    // overflow auto enables the graph width to be responsive, but cuts off the legend tooltip
    overflow: 'hidden'
  },
  bottomShadow: {
    boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
  },
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  graph: {
    flexDirection: 'column',
    padding: '30px'
  }
});

// tabData contains a series of graph and table queries, which are displayed according to the active tab index

const BoxContentWidget = props => {
  const { tabData, accounts, dates, onlyVideo, prefix } = props;

  const classes = useStyles();

  const tabs = tabData.map(({ tab }) => tab);

  const [activeTab, setActiveTab] = useState(0);
  const [graphBanner, setGraphBanner] = useState(null);

  const onTabChange = index => setActiveTab(index);

  useEffect(() => {
    // handles hiding impressions by type tab on unselecting FB accounts
    if (!tabData[activeTab]) setActiveTab(0);
  }, [tabData]);

  return (
    <Grid container className={classes.container}>
      <Box className={classes.box}>
        {/* GRAPH */}
        <Grid container className={classes.bottomShadow}>
          {tabData[activeTab]?.graphDruid && (
            <>
              <BoxHeader tabs={tabs} onTabChange={onTabChange} dates={dates} prefix={prefix} />
              <Grid container className={classes.graph}>
                {graphBanner && <Banner message={graphBanner} variant="filled" severity="error" />}
                <AlbGraphDruid
                  tabData={tabData[activeTab].graphDruid}
                  accounts={accounts}
                  dates={dates}
                  onlyVideo={onlyVideo}
                  setGraphBanner={setGraphBanner}
                />
              </Grid>
            </>
          )}
        </Grid>

        {/* TABLE */}
        {/* display tabs for table if the widget has no graph */}
        {!tabData[activeTab]?.graphDruid && (
          <Grid container className={classes.bottomShadow}>
            <BoxHeader tabs={tabs} onTabChange={onTabChange} dates={dates} />
          </Grid>
        )}
        {tabData[activeTab]?.tableType ? null : (
          <BoxHeader tabs={`${tabs[activeTab]} Breakdown`} breakdown prefix={prefix} />
        )}
        {tabData[activeTab]?.tableDruid && (
          <AlbTableDruid
            accounts={accounts}
            dates={dates}
            table={tabData[activeTab].tableDruid.table}
            activeTab={activeTab}
            onlyVideo={onlyVideo}
          />
        )}
        {/* only displayed in the impressions widgets, displays lifetime values queried within campaign_events */}
        {tabData[activeTab]?.postsTable && (
          <BoxContentTablePosts
            query={tabData[activeTab].postsTable.query}
            accounts={accounts}
            dates={dates}
            tableType={tabData[activeTab].tableType}
            onlyVideo={onlyVideo}
          />
        )}
        {tabData[activeTab]?.druidTable && (
          <BoxContentTableDruid
            query={tabData[activeTab].druidTable.query}
            accounts={accounts}
            dates={dates}
            onlyVideo={onlyVideo}
          />
        )}
        {/* custom tables for demographics */}
        {tabData[activeTab]?.geoTable && (
          <AlbGeoTable accounts={accounts} dates={dates} metric={tabData[activeTab].geoTable} />
        )}
        {tabData[activeTab]?.ageTable && (
          <BoxContentTableAge
            accounts={accounts}
            dates={dates}
            table={tabData[activeTab].ageTable}
          />
        )}
      </Box>
    </Grid>
  );
};

BoxContentWidget.propTypes = {
  tabData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  onlyVideo: PropTypes.bool,
  prefix: PropTypes.string
};

BoxContentWidget.defaultProps = {
  onlyVideo: false,
  prefix: ''
};

export default BoxContentWidget;
