import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import EditInPlaceInput from 'components/EditInPlaceInput';
import Box from 'components/Box';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'util/colors';
import SearchCriteriaDrawer from './SearchCriteriaDrawer';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  datesTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27px',
    color: '#0A1734'
  },
  dates: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27px',
    color: '#979797'
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px'
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  drawerLink: {
    color: colors.twitterBlue,
    marginLeft: '10px'
  }
});

/**
 * @method
 * @summary This component renders the report title section for analytics, including name, search criteria drawer, and dates.
 * @name AnalyticsTitle
 * @param {Object} children - other JSX that can be passed into the grid after the dates.
 * @param {boolean} queryLoading - Query loading state flag
 * @param {Object} queryError - Query error state object
 * @param {Object} queryData - Query successful state data object
 * @param {Function} editInputAction - callback function from wrapper component that triggers renaming mutation for a particular report.
 * @param {string} title - Title of the report
 * @param {string} criteriaTitle - Title inside the searchCriteria info component
 * @param {Object[]} criteriaFields - Key-value pairs filters rendered in chips inside the searchCriteria, passed in format: [{ title: '', body: ''}]
 * @param {string} dates - If not null, display the dates in the title section. Passed as a string.
 * @param {string} buttonTitle - title for optional action button
 * @param {Function} buttonAction - callback function for optional action button
 * @return {Object} - React JSX
 */

const AnalyticsTitle = ({
  children,
  queryLoading,
  queryError,
  queryData,
  editInputAction,
  title,
  useSearchCriteria,
  criteriaTitle,
  criteriaFields,
  dates,
  buttonTitle,
  buttonAction
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid item container justifyContent="space-between">
        {!!queryLoading && <AlbLoading />}
        {!!queryError && <AlbError error={queryError} />}
        {queryData && (
          <div className={classes.titleContainer}>
            <Box ml={16}>
              <EditInPlaceInput useEdit value={title} onChange={e => editInputAction(e)} />
            </Box>

            {useSearchCriteria && (
              <Button className={classes.drawerLink} onClick={() => setOpen(true)}>
                Search Criteria
              </Button>
            )}
          </div>
        )}
        {buttonTitle && buttonAction && (
          <Button
            onClick={buttonAction}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {buttonTitle}
          </Button>
        )}
        <div className={classes.rightContainer}>
          {dates && (
            <>
              <Typography variantMapping={{ body1: 'span' }} className={classes.datesTitle}>
                Dates:&nbsp;
              </Typography>
              <Typography
                variantMapping={{ body1: 'span' }}
                className={classes.dates}
                style={children ? { paddingRight: '10px' } : null}
              >
                {dates}
              </Typography>
            </>
          )}
          {children || null}
        </div>
      </Grid>
      <SearchCriteriaDrawer
        className={classes.drawer}
        open={open}
        onClose={() => setOpen(false)}
        criteriaTitle={criteriaTitle}
        criteriaFields={criteriaFields}
      />
    </>
  );
};

AnalyticsTitle.propTypes = {
  children: PropTypes.element,
  useSearchCriteria: PropTypes.bool,
  criteriaTitle: PropTypes.string,
  criteriaFields: PropTypes.arrayOf(PropTypes.shape({})),
  queryLoading: PropTypes.bool,
  queryError: PropTypes.shape(),
  queryData: PropTypes.shape(),
  editInputAction: PropTypes.func,
  title: PropTypes.string.isRequired,
  dates: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonAction: PropTypes.func
};

AnalyticsTitle.defaultProps = {
  children: null,
  queryLoading: null,
  queryError: null,
  queryData: null,
  editInputAction: null,
  dates: null,
  useSearchCriteria: false,
  criteriaTitle: '',
  criteriaFields: [],
  buttonTitle: null,
  buttonAction: null
};

export default AnalyticsTitle;
