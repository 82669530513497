export const selectTextOptions = {
  EQUALS: 'equals',
  CONTAINS: 'contains',
  DOES_NOT_EQUAL: 'does not equal',
  DOES_NOT_CONTAIN: 'does not contain'
};

export const selectNumberOptions = {
  IS_EQUAL_TO: 'is equal to',
  IS_NOT_EQUAL_TO: 'is not equal to',
  IS_GREATER_THAN: 'is greater than',
  IS_GREATER_THAN_OR_EQUAL_TO: 'is greater than or equal to',
  IS_LESS_THAN: 'is less than',
  IS_LESS_THAN_OR_EQUAL_TO: 'is less than or equal to',
  IS_BETWEEN: 'is between',
  IS_KNOWN: 'is known',
  IS_UNKNOWN: 'is unknown'
};

export const selectDatePickerOptions = {
  IS: 'is',
  IS_EQUAL_TO: 'is equal to',
  IS_BEFORE: 'is before',
  IS_AFTER: 'is after',
  IS_BETWEEN: 'is between',
  IS_KNOWN: 'is known',
  IS_UNKNOWN: 'is unknown'
};

export const selectCheckboxOptions = {
  TRUE: 'true',
  FALSE: 'false',
  EMPTY: null
};

export const selectZipRangeOptions = {
  IS_LESS_THAN: 'is less than',
  IS_LESS_THAN_OR_EQUAL_TO: 'is less than or equal to',
  IS_GREATER_THAN: 'is greater than',
  IS_GREATER_THAN_OR_EQUAL_TO: 'is greater than or equal to'
};

export const ApplyFieldsSubmitter = 'ApplyFields';
export const SaveFilterSubmitter = 'SaveFilter';
export const ALL_RULES = 'AND';
export const ANY_RULES = 'OR';

export const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  SELECT: 'select', // this requires an array of selectOptions to choose from.
  CUSTOM_SELECT: 'custom-select',
  RADIO: 'radio', // boolean
  CHECKBOX: 'checkbox',
  DATE: 'datepicker'
};
