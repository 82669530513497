import React from 'react';

const DashboardIntelligenceDrawerContext = React.createContext({
  isDrawerOpen: false,
  isSourceSpike: false,
  isConversionOutlier: false,
  isPageSpike: false,
  isCorrelation: false,
  isMultiPostDetection: false,
  isDetectedEvent: false,
  isThirdPartyMedia: false,
  event: null,
  toggleDrawer: () => {}
});

export default DashboardIntelligenceDrawerContext;
