import Tracker from '@openreplay/tracker';

export const openReplayTracker = new Tracker({
  projectKey: process.env.OPEN_RELAY_PROJECT_KEY
});

/**
 * Sets up metadata and user ID for the current tracker
 * @param {String} uuid the user's uuid
 * @param {Object} data an object containing key/value parameters to be sent to
 * the tracker. Metadata keys must exist on OpenReplay before they will save.
 */
export const setOpenReplayData = (uuid, data) => {
  openReplayTracker.setUserID(uuid);
  Object.keys(data).forEach(key => {
    openReplayTracker.setMetadata(key, data[key]);
  });
};
