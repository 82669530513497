import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import colors from 'util/colors';
import { AlbTooltip } from 'components/AlbTooltip';
import DateRangeOptions from './DateRangeOptions';

const useStyles = makeStyles({
  graphHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: '20px'
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginRight: '10px',
    fontSize: '16px',
    fontWeight: 500,
    color: colors.navy
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    lineClamp: 1,
    wordBreak: 'break-word'
  }
});

/**
 * @method
 * @summary Contains the graph and child graphs contained within the graph - recursively displays the PostDrawerGraph component for each child
 * @name GraphHeader
 * @param {Object} props - React props passed to this component
 * @param {String} props.label - The title of the current node layer
 * @param {String} props.tooltipText - Descriptive text for the info button on hover
 * @param {Boolean} props.hasTooltip - True if the current node has a tooltip
 * @param {Boolean} props.hasGraph - True if the current layer is intended to hold graph children and not display a graph
 * @param {Boolean} props.isCollapsed - True if the component is collapsed (not visible) in the containing component
 * @param {Object[]} props.dateRangeOptions - An array of objects containing date labels and configurations for the buttons
 * @param {Number} props.selectedDateRange - The index of currently selected date option from dateRangeOptions
 * @param {Function} props.setSelectedDateRange - A function to select a specified date range to provide to the graph query
 * @return {Object} - React JSX
 */
const GraphHeader = props => {
  const {
    label,
    tooltipText,
    hasGraph,
    isCollapsed,
    dateRangeOptions,
    selectedDateRange,
    setSelectedDateRange,
    children
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.graphHeader}>
      <Grid item className={classes.headerLeft}>
        <Tooltip title={label}>
          <Typography className={`${classes.title} ${classes.truncateText}`}>{label}</Typography>
        </Tooltip>
        {tooltipText && tooltipText !== '-' && <AlbTooltip title={tooltipText} placement="right" />}
      </Grid>
      <Grid item className={classes.headerRight}>
        {children && children}
        {hasGraph && !isCollapsed && (
          <DateRangeOptions
            dateRangeOptions={dateRangeOptions}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
        )}
      </Grid>
    </Box>
  );
};

GraphHeader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipText: PropTypes.node,
  hasGraph: PropTypes.bool,
  isCollapsed: PropTypes.bool.isRequired,
  dateRangeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDateRange: PropTypes.number.isRequired,
  setSelectedDateRange: PropTypes.func.isRequired,
  children: PropTypes.node
};

GraphHeader.defaultProps = {
  tooltipText: '',
  hasGraph: false,
  children: null
};

export default GraphHeader;
