import { showToast } from 'contexts/ToastContext';
import client from './apolloClient';

/**
 * @method
 * @summary makes a (blocking) authenticated API Call to the Alembic GraphQL API.
 * @param {gql} template - A graphQL Template
 * @param {Array} varhash - Specific privilege to check
 * @param {Bool} [mutation=false] - Optional, true if this is a GQL mutation.
 * @return {Object} return object containing success (boolean) and result payload
 */
const apolloClientHelper = async (template, variables, mutation = false) => {
  if (mutation) {
    // if mutation is true we are exec'ing a mutation
    const result = await client
      .mutate({
        variables,
        mutation: template
      })
      .then(data => {
        return {
          success: true,
          payload: data
        };
      })
      .catch(error => {
        return {
          success: false,
          payload: error
        };
      });
    return result;
  }
  // if mutation is false we are exec'ing a query
  const result = await client
    .query({
      variables,
      query: template,
      fetchPolicy: 'no-cache'
    })
    .then(data => {
      return {
        success: true,
        payload: data
      };
    })
    .catch(error => {
      return {
        success: false,
        payload: error
      };
    });
  return result;
};

export default apolloClientHelper;

export const apolloClientQuery = async (template, variables, toast, nested) => {
  try {
    const result = await client.query({
      query: template,
      variables,
      fetchPolicy: 'network-only'
    });

    return result;
  } catch (error) {
    if (toast) {
      showToast(error.message, 'error');
    }

    throw nested ? error : new Error(error);
  }
};

export const apolloClientMutation = async (template, variables) => {
  try {
    const result = await client.mutate({
      mutation: template,
      variables
    });

    return result;
  } catch (error) {
    throw new Error(error);
  }
};
