import { createTheme } from '@material-ui/core/styles';
import colors from 'util/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0d1a3a'
    },
    secondary: {
      main: '#4c6087'
    },
    error: {
      main: '#e71e27'
    },
    background: {
      default: '#F0F0F0',
      paper: '#ffffff'
    },
    darkBlue: '#0A1734',
    white: '#ffffff'
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 13,
    h1: {
      fontSize: '24px',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '26px',
      fontWeight: 'bold'
    },
    h3: {
      fontSize: '24px',
      fontWeight: 'bold'
    },
    h4: {
      fontSize: '22px',
      fontWeight: 'bold'
    },
    h5: {
      fontSize: '20px',
      fontWeight: 'bold'
    },
    h6: {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    button: {
      textTransform: 'none'
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiInputLabel: {
      disableAnimation: true,
      shrink: false
    },
    MuiInput: {
      disableUnderline: false
    },
    MuiFilledInput: {
      disableUnderline: true
    }
  },
  overrides: {
    MuiTypography: {
      colorTextPrimary: {
        color: '#0d1a3a'
      },
      colorTextSecondary: {
        color: '#ffffff'
      },
      h6: {
        color: '#0d1a3a'
      }
    },
    MuiIcon: {
      root: {
        width: 'auto',
        overflow: 'visible'
      }
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: '#0d1a3a'
      },
      colorSecondary: {
        color: '#ffffff'
      }
    },
    MuiBadge: {
      badge: {
        minWidth: 16,
        height: 16,
        color: '#ffffff'
      },
      colorSecondary: {
        backgroundColor: '#ff9800'
      }
    },
    MUIDataTable: {
      paper: {
        boxShadow: 'none'
      }
    },
    MuiDialog: {
      paperWidthSm: {
        padding: 8
        // textAlign: 'center'
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '13px',
        color: '#6f6f6f',
        marginLeft: '14px',
        marginBottom: '5px'
      },
      filled: {
        fontSize: '15px',
        fontWeight: 600,
        marginBottom: '.75em',
        marginLeft: 0,
        position: 'relative',
        transform: 'none'
      },
      shrink: {
        marginLeft: '0px',
        marginBottom: '0px'
      }
    },
    MuiInputBase: {
      input: {
        color: '#0d1a3a',
        fontSize: '12px',
        fontWeight: '600',
        opacity: '0.68'
      }
    },
    MuiFilledInput: {
      root: {
        borderColor: '#ebebeb',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        border: 'solid 1px #0d1a3a',
        '&:hover': { backgroundColor: 'inherit' },
        '&$focused': { backgroundColor: 'inherit', borderColor: '#979797' }
      },
      multiline: { padding: '9px 13px' },
      input: {
        padding: '9px 13px',
        fontWeight: 'normal'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '10px 13px 10px'
      }
    },
    MuiSelect: {
      selectMenu: {
        height: '1.1875em'
      }
    },
    MuiButton: {
      root: {
        '&$focusVisible': {
          boxShadow: 'none'
        }
      },
      label: {
        fontSize: '12px',
        fontWeight: '500'
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#ffffff',
        color: '#0d1a3a',
        '&:hover': {
          backgroundColor: '#rgba(10, 23, 52, 0.08)'
        }
      },
      containedPrimary: {
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#3c5a99'
        }
      },
      containedSecondary: {
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#0d1a3a'
        }
      },
      outlinedPrimary: {
        backgroundColor: '#ffffff',
        color: '#0d1a3a',
        '&:hover': {
          backgroundColor: 'rgba(13, 26, 58, 0.1)'
        }
      },
      outlinedSecondary: {
        backgroundColor: '#ffffff',
        color: '#4c6087',
        '&:hover': {
          backgroundColor: 'rgba(76, 96, 135, 0.1)'
        }
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:hover': {
          // #32327D at 15% opacity
          backgroundColor: colors.athensGray
        }
      }
    }
  }
});

theme.shadows[1] = '0 0 13px 0 rgba(0, 0, 0, 0.1)';

const overrides = Object.assign({}, theme.overrides, {
  MuiTab: {
    root: {
      ...theme.typography.body1,
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        ...theme.typography.body1,
        fontWeight: 500,
        minWidth: 100,
        minHeight: 64
      }
    }
  },
  MuiDrawer: {
    root: {
      zIndex: '1350 !important'
    }
  }
});

export default Object.assign({}, theme, { overrides });
