import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import AlembicPageTitle from 'components/AlembicPageTitle';
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import AnalyticsBox from 'components/AnalyticsBox';
import { AnalyticsPath, ShortlinksAnalyticsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import { getInitialAnalyticsStartDate, getInitialAnalyticsEndDate } from 'util/date';
import HasAnyPriv from 'components/HasAnyPriv';
import ShortlinkClicks from './ShortlinkClicks';
import ShortlinkTrending from './ShortlinkTrending';
import ShortlinkRecent from './ShortlinkRecent';
import ShortlinkChannels from './ShortlinkChannels';
import ShortlinkBrowsers from './ShortlinkBrowsers';
import ShortlinkLocation from './ShortlinkLocation';
import ShortlinkClicksTable from './ShortlinkClicksTable';
import ShortlinkTotal from './ShortlinkTotal';

const useStyles = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  },
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    marginBottom: '40px',
    zIndex: 1000
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease'
  },
  full: {
    marginTop: '70px',
    marginBottom: '40px'
  },
  compact: {
    marginTop: '30px',
    marginBottom: '20px'
  },
  closeIcon: {
    height: '45px',
    width: '45px',
    backgroundColor: '#0b1835',
    color: 'white',
    top: '40px',
    right: '40px',
    position: 'fixed'
  }
});

const ShortlinkAnalytics = props => {
  const {
    currentUser,
    shortlink,
    shortlinkTitle,
    setSelectedShortlink,
    displayAnalytics,
    setDisplayAnalytics,
    history
  } = props;

  const classes = useStyles();

  useEffect(() => {
    setPageTitle('Shortlinks Analytics');
  }, []);

  const [calRange, changeCalRange] = useState({
    start: getInitialAnalyticsStartDate(30, currentUser, 'days'),
    end: getInitialAnalyticsEndDate(currentUser)
  });
  const [scrolled, setScrolled] = useState(false);

  const containerRef = useRef();
  const dialogRef = useRef();

  const handleScroll = useCallback(event =>
    // 86 is an arbitrary number based on typical height of sub headers in other analytics views
    setScrolled(event.target.scrollTop > containerRef.current.offsetHeight - 86)
  );

  useEffect(() => {
    if (dialogRef?.current?.children?.length >= 3) {
      // if material ui dialog follows this structure then this DOM access should be safe
      // accessing the scrolling section of dialog
      const dialogContainer = dialogRef?.current?.children?.[2];
      const dialog = dialogContainer?.firstChild;

      dialog.addEventListener('scroll', handleScroll);

      return () => dialog.removeEventListener('scroll', handleScroll);
    }
    // to stop eslint from complaining
    return () => null;
  }, [dialogRef.current]);

  // Immediately open the page modally if we got here from the Analytics page
  const openAsModal = window.location.href.includes(ShortlinksAnalyticsPath);

  return (
    <HasAnyPriv privs={['SVC:CHAN:STATS']} showAlert>
      <Dialog
        fullScreen
        open={displayAnalytics || openAsModal}
        classes={{ paperFullScreen: classes.root }}
        ref={dialogRef}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            className={classes.header}
            ref={containerRef}
          >
            <Grid
              item
              xs={9}
              container
              justifyContent="space-between"
              alignItems="flex-start"
              className={`${classes.box} ${scrolled ? classes.compact : classes.full}`}
            >
              <AlembicPageTitle title={`${shortlink ? '' : 'All '}Shortlink Analytics`} />
              {shortlinkTitle && <Typography>{shortlinkTitle}</Typography>}
              <Grid item>
                <AnalyticsDatePicker setDateRange={changeCalRange} calRange={calRange} />
              </Grid>
            </Grid>
            <IconButton
              onClick={() => {
                // If the page was opened modally from Analytics, close button should take them back there, otherwise do the normal resets to close back to Shortlinks
                if (openAsModal) {
                  goToRoute(AnalyticsPath, history);
                } else {
                  if (setSelectedShortlink) {
                    setSelectedShortlink(null);
                  }

                  if (setDisplayAnalytics) {
                    setDisplayAnalytics(false);
                  }
                }
              }}
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AnalyticsBox tabs={['All Clicks']} dates={calRange} onChange={() => {}}>
                  <ShortlinkClicks
                    dates={calRange}
                    shortlink={shortlink}
                    userTimeZone={currentUser.time_zone}
                  />
                </AnalyticsBox>
              </Grid>

              {shortlink === null && (
                <Grid item container>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <AnalyticsBox
                      tabs={['Trending Links In Selected Period']}
                      dates={calRange}
                      onChange={() => {}}
                    >
                      <ShortlinkTrending tableOnly dates={calRange} />
                    </AnalyticsBox>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <AnalyticsBox tabs={['Recent Clicks']} dates={calRange} onChange={() => {}}>
                      <ShortlinkRecent
                        tableOnly
                        dates={calRange}
                        userTimeZone={currentUser.time_zone}
                      />
                    </AnalyticsBox>
                  </Grid>
                </Grid>
              )}

              <Grid item container>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  <AnalyticsBox tabs={['Top Sources']} dates={calRange} onChange={() => {}}>
                    <ShortlinkChannels
                      dates={calRange}
                      shortlink={shortlink}
                      userTimeZone={currentUser.time_zone}
                    />
                  </AnalyticsBox>
                </Grid>

                <Grid item xs={6} style={{ display: 'flex' }}>
                  <AnalyticsBox tabs={['Top Browsers']} dates={calRange} onChange={() => {}}>
                    <ShortlinkBrowsers
                      dates={calRange}
                      shortlink={shortlink}
                      userTimeZone={currentUser.time_zone}
                    />
                  </AnalyticsBox>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <AnalyticsBox tabs={['Demographics']} dates={calRange} onChange={() => {}}>
                  <ShortlinkLocation dates={calRange} shortlink={shortlink} />
                  <div
                    shadow
                    style={{
                      height: '14px',
                      background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
                      mixBlendMode: 'multiply'
                    }}
                  />
                  <ShortlinkClicksTable
                    table
                    dates={calRange}
                    shortlink={shortlink}
                    column="preferred_lang"
                  />
                </AnalyticsBox>
              </Grid>

              {shortlink === null && (
                <Grid item xs={12}>
                  <AnalyticsBox
                    tabs={['Link Performance Total', 'Link Performance Referrals']}
                    dates={calRange}
                    onChange={() => {}}
                  >
                    <ShortlinkTotal
                      tableOnly
                      dates={calRange}
                      shortlink={shortlink}
                      userTimeZone={currentUser.time_zone}
                    />
                    <ShortlinkClicksTable
                      tableOnly
                      dates={calRange}
                      shortlink={shortlink}
                      column="referrer_hostname"
                    />
                  </AnalyticsBox>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </HasAnyPriv>
  );
};

ShortlinkAnalytics.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  shortlink: PropTypes.string,
  shortlinkTitle: PropTypes.string,
  setSelectedShortlink: PropTypes.func,
  displayAnalytics: PropTypes.bool,
  setDisplayAnalytics: PropTypes.func,
  history: PropTypes.shape()
};

ShortlinkAnalytics.defaultProps = {
  shortlink: null,
  shortlinkTitle: null,
  displayAnalytics: true,
  setSelectedShortlink: null,
  setDisplayAnalytics: null,
  history: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(ShortlinkAnalytics);
