import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import getValueAbbreviation from 'util/getValueAbbreviation';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    '& > canvas': {
      width: '100% !important',
      zIndex: 1
    },
    '@media print': {
      '& > canvas': {
        width: '100% !important',
        height: '100% !important'
      }
    }
  },
  textContainer: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: '110px'
  },
  countText: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#32327D'
  },
  postText: {
    display: '-webkit-box',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '21px',
    color: '#6F6F6F',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineClamp: 3,
    wordBreak: 'break-word'
  },
  textPrint: {
    '@media print': {
      fontSize: '10px',
      lineHeight: '15px'
    }
  }
});

/**
 * @method
 * @summary This component renders a doughnut graph
 * @name AlbDoughnutGraph
 * @param {Object} props - React props passed to this component
 * @param {Object|Function} props.data - An object containing ChartJS specific properties such as datasets and labels
 * @param {string} props.count - The top text within the doughnut graph
 * @param {string} props.label - The bottom text within the doughnut graph
 * @param {boolean} props.large - Renders graph with 1.2:1 aspect ratio instead of default - 2:1
 * @param {Function} props.customTooltip - A custom tooltip render function
 * @param {boolean} props.currency - Indicates the tooltip values should prepend $
 * @return {Object} - React JSX
 */
const AlbDoughnutGraph = props => {
  const { data, count, label, large, customTooltip, currency } = props;
  const classes = useStyles();

  const legend = {
    display: false
  };

  const options = {
    aspectRatio: 1.2,
    cutoutPercentage: 60,
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    tooltips: {
      enabled: !customTooltip,
      callbacks: {
        label(tooltipItem, chartData) {
          const positionText = chartData.labels[tooltipItem.index];
          const valueText = Math.round(chartData.datasets[0].data[tooltipItem.index]);
          const formattedValueText = currency
            ? getValueAbbreviation(valueText, true)
            : valueText.toLocaleString();

          return `${positionText} | ${formattedValueText}`;
        }
      },
      custom: customTooltip
    }
  };

  return (
    <div className={classes.container}>
      {count && (
        <div className={classes.textContainer}>
          <Typography
            className={`${classes.countText} ${classes.textPrint}`}
            style={{ ...(large && { fontSize: '30px', lineHeight: '30px', color: '#000000' }) }}
          >
            {count}
          </Typography>
          <Typography
            className={`${classes.postText} ${classes.textPrint}`}
            style={{ ...(large && { fontSize: '16px' }) }}
          >
            {label}
          </Typography>
        </div>
      )}
      <Doughnut
        data={data}
        legend={legend}
        options={options}
        // hack to enable aspect ratio option
        {...(large && { height: null, width: null })}
      />
    </div>
  );
};

AlbDoughnutGraph.propTypes = {
  data: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  count: PropTypes.string,
  label: PropTypes.string,
  large: PropTypes.bool,
  customTooltip: PropTypes.func,
  currency: PropTypes.bool
};

AlbDoughnutGraph.defaultProps = {
  count: null,
  label: null,
  large: false,
  customTooltip: null,
  currency: false
};

export default AlbDoughnutGraph;
