/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import { formatCurrencyRound } from 'util/formatCurrency';
import getValueAbbreviation from 'util/getValueAbbreviation';
import colors from 'util/colors';
import formatSalesforceLabel from '../formatSalesforceLabel';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  text: {
    fontSize: '13px'
  },
  tableHeaderCell: {
    display: 'flex'
  },
  tableHeader: {
    flex: 1,
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase'
  }
});

const tableThemeOverrides = {
  MuiTableCell: {
    root: {
      borderBottom: 'none',
      padding: '24px'
    },
    body: {
      '&:nth-child(5)': {
        borderRight: 'solid 1px #B8B6B6'
      }
    }
  },
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap'
    }
  },
  MUIDataTableBodyRow: {
    root: {
      '&:nth-child(even)': {
        backgroundColor: '#f5f5f5'
      },
      '&:hover': {
        backgroundColor: colors.athensGray
      },
      '&:first-child': {
        borderBottom: 'solid 1px #B8B6B6'
      }
    }
  },
  MuiPaper: {
    root: {
      flex: 1
    }
  }
};

/**
 * @name HistoryForecastTable
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const HistoryForecastTable = props => {
  const { page, setPage, rowsPerPage, data, totalCount, loading, error, isRevenue } = props;

  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const [tableColumns, setTableColumns] = useState([]);

  const [truncateValues, setTruncateValues] = useState(false);

  const handleResize = useCallback(() => setTruncateValues(window.innerWidth <= 1200));

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customForecastRender = value => (
    <Typography className={classes.text} style={{ textAlign: 'center' }}>
      {value || '-'}
    </Typography>
  );

  const customHistoryRender = value => (
    <Typography className={classes.text} style={{ textAlign: 'center' }}>
      {value != null
        ? `${
            truncateValues
              ? getValueAbbreviation(value, isRevenue)
              : `${isRevenue ? '$' : ''}${formatCurrencyRound(value)}`
          }`
        : '-'}
    </Typography>
  );

  const customHeadRender = tableMeta => (
    <div className={classes.tableHeaderCell} key={tableMeta.label}>
      <Typography className={classes.tableHeader}>{tableMeta.label}</Typography>
    </div>
  );

  const customCenterHeadRender = tableMeta => {
    const [days, forecast] = tableMeta.label.split('<>');

    return (
      <div className={classes.tableHeaderCell} key={tableMeta.label}>
        <Typography align="center" className={classes.tableHeader}>
          {days}
          <br />
          {forecast}
        </Typography>
      </div>
    );
  };

  const customDateHeadRender = tableMeta => {
    const [startDate, endDate] = tableMeta.label.split('<>');

    return (
      <div className={classes.tableHeaderCell} key={tableMeta.label}>
        <Typography align="center" className={classes.tableHeader}>
          {`${moment(startDate).format('M/DD/YY')} - ${moment(endDate).format('M/DD/YY')}`}
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    const tempTableData = [];
    const tempTableColumns = [
      {
        name: 'id',
        options: { display: false }
      },
      {
        name: 'characteristic',
        label: 'Field',
        options: { customHeadLabelRender: customHeadRender }
      }
    ];

    if (data?.length) {
      let historyColumns = [];

      data.forEach(({ dimension, label, history, forecast }) => {
        if (history.length > historyColumns.length) {
          // not every row contains values spanning across all dates
          // so this is used to map out all possible years within the query
          historyColumns = history;
        }

        const historyValues = history.map(({ start_date, sum }) => ({ [start_date]: sum }));
        const forecastValues = forecast.map(({ days, change }) => ({
          [`day_${days}`]: `${change}%`
        }));

        const tempTableRow = Object.assign(
          {
            id: `${dimension}-${label}`,
            characteristic: formatSalesforceLabel({ label, dimension })
          },
          ...historyValues.concat(forecastValues)
        );

        tempTableData.push(tempTableRow);
      });

      tempTableColumns.push(
        // use historical dates to generate table columns
        ...historyColumns.map(({ start_date, end_date }) => ({
          name: start_date,
          label: `${start_date}<>${end_date}`,
          options: {
            customHeadLabelRender: customDateHeadRender,
            customBodyRender: customHistoryRender
          }
        })),
        {
          name: 'day_30',
          label: '30 Day<>Forecast',
          options: {
            customHeadLabelRender: customCenterHeadRender,
            customBodyRender: customForecastRender
          }
        },
        {
          name: 'day_60',
          label: '60 Day<>Forecast',
          options: {
            customHeadLabelRender: customCenterHeadRender,
            customBodyRender: customForecastRender
          }
        },
        {
          name: 'day_90',
          label: '90 Day<>Forecast',
          options: {
            customHeadLabelRender: customCenterHeadRender,
            customBodyRender: customForecastRender
          }
        }
      );
    }

    setTableData(tempTableData);
    setTableColumns(tempTableColumns);
    setCount(totalCount);
  }, [data, truncateValues]);

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    sort: false,
    onChangePage: setPage,
    draggableColumns: { enabled: false },
    page,
    rowsPerPage,
    count,
    customFooter: (
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) =>
      CustomFooter(
        footerCount,
        footerPage,
        footerRowsPerPage,
        changeRowsPerPage,
        changePage,
        textLabels,
        [] // rowsPerPageOptions
      )
  };

  return (
    <Grid container className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      <Grid container style={{ overflowX: 'auto', opacity: loading ? 0.5 : 1 }}>
        <AlbTable
          tableData={tableData}
          tableColumns={tableColumns}
          tableOptions={tableOptions}
          tableThemeOverrides={tableThemeOverrides}
        />
      </Grid>
    </Grid>
  );
};

HistoryForecastTable.defaultProps = {
  loading: false,
  error: null,
  data: null,
  totalCount: null,
  isRevenue: false
};

HistoryForecastTable.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  isRevenue: PropTypes.bool
};

export default HistoryForecastTable;
