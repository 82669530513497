import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CREATE_CON } from 'gql/container';
import { showToast } from 'contexts/ToastContext';

const style = {
  addContainer: {
    padding: '8px 26px',
    borderBottom: 'solid 1px rgba(13, 26, 58, 0.2)'
  }
};

const AddContainer = props => {
  const [containerName, setContainerName] = useState('');
  const { classes, data, parentRefetch, closeCallback } = props;
  const [addContainer, { isLoading, error }] = useMutation(CREATE_CON, {
    onCompleted: resultData => {
      if (resultData?.createContainer) {
        showToast('Container Created', 'success');
        parentRefetch();
      } else {
        showToast('Container Creation Failed', 'error');
      }
      closeCallback();
    },
    onError: () => {
      showToast(error.graphQLErrors[0].message, 'error');
      closeCallback();
    }
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidContainerName', value => {
      if (value.length > 0) {
        const containerRegexp = /^[a-zA-Z0-9.,!&\-_ ()#'/]+$/;
        return containerRegexp.test(value);
      }
      return false;
    });
  }, []);

  const handleInput = event => {
    setContainerName(event.target.value);
  };

  const handleSubmit = () => {
    setContainerName('');
    addContainer({
      variables: {
        parent_id: data.container.id,
        name: containerName
      }
    });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.addContainer}
      >
        <Grid item xs={4}>
          <TextValidator
            fullWidth
            autoFocus
            name="containerName"
            variant="filled"
            placeholder="New Container Name"
            value={containerName}
            onChange={handleInput}
            errorMessages={['* Required']}
          />
        </Grid>
        <Grid item xs={2}>
          <Button fullWidth type="submit" variant="contained" color="primary" disabled={isLoading}>
            Create Container
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

AddContainer.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  parentRefetch: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired
};

export default withStyles(style)(AddContainer);
