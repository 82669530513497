import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  SEARCH_SEGMENTS,
  CREATE_ADOBE_ANALYTICS_SEGMENT_LINK,
  GET_CHILD_AA_SEGMENTS
} from 'gql/segment';
import AlbError from 'components/AlbError';
import { showToast } from 'contexts/ToastContext';
import { parseQueryString, goToRoute, setQueryStringForAnalyticsModal } from 'util/routesHelpers';
import { LinkAccountsPath } from 'util/paths';
import AdobeAnalyticsSegmentsTable from 'components/AlbTable/AdobeAnalyticsSegmentsTable';

/**
 * @method
 * @summary This component contains a suggestion search field allowing the user to choose which segments to import
 * @name LinkAdobeAnalyticsAccountSegments
 * @return {Object} - React JSX
 */
const LinkAdobeAnalyticsAccountSegments = props => {
  const { history, location } = props;
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [createSegmentError, setCreateSegmentError] = useState(null);
  const [account, setAccount] = useState('');

  // after component is mount, parse the query string and update local state
  useEffect(() => {
    if (location?.search) {
      setAccount(parseInt(parseQueryString('account', location), 10));
    }
  }, []);

  useEffect(() => {
    setQueryStringForAnalyticsModal('account', [account]);
  }, [account]);

  const { data, error, loading } = useQuery(SEARCH_SEGMENTS, {
    variables: {
      linktoken_id: account,
      query: inputValue.trim()
    },
    fetchPolicy: 'no-cache'
  });

  const { loading: segmentsLoading, data: segments } = useQuery(GET_CHILD_AA_SEGMENTS, {
    variables: {
      linktoken_id: account
    },
    fetchPolicy: 'network-only'
  });

  const [createAASegmentLink] = useMutation(CREATE_ADOBE_ANALYTICS_SEGMENT_LINK, {
    onError: setCreateSegmentError,
    onCompleted: result => {
      showToast(
        // eslint-disable-next-line camelcase
        `${result?.createAdobeAnalyticsSegmentLink.token.remote_name} segment has been linked. Please enable linktoken segment to begin data collection.`,
        'success'
      );
      goToRoute(LinkAccountsPath, history);
    }
  });

  useEffect(() => {
    if (data?.searchSegments?.length) {
      const newOptions = data?.searchSegments?.map(segment => ({
        id: segment.id,
        name: segment.name,
        segment_id: segment.segment_id,
        description: segment.description ? segment.description : 'Not Available'
      }));
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [data]);

  const createSegment = () => {
    // dedupes objects in value
    const SEGMENTS = [...new Map(value.map(v => [v.id, v])).values()];
    for (let i = 0; i < SEGMENTS.length; i += 1) {
      createAASegmentLink({
        variables: { linktoken_id: account, segment_id: SEGMENTS[i].id }
      });
    }
    setValue([]);
  };

  const tableColumns = [
    { name: 'remote_id', label: 'ID' },
    { name: 'remote_name', label: 'NAME' }
  ];

  return (
    <>
      {(error || createSegmentError) && <AlbError toast error={error || createSegmentError} />}
      <ValidatorForm onSubmit={createSegment}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Select Adobe Analytics Segments</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              getOptionLabel={option =>
                `${option.name} >> ${option.description} >> ${option.segment_id}`
              }
              options={options}
              value={value}
              onChange={(e, newValue) => setValue(newValue)}
              inputValue={inputValue || ''}
              onInputChange={e => {
                setCreateSegmentError(null);
                return setInputValue(e?.target?.value || '');
              }}
              renderInput={params => (
                <TextField {...params} placeholder="Search" variant="outlined" />
              )}
              open={inputValue?.length > 2}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
              Save Segments
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>

      {segments?.getChildAASegments.length > 0 && (
        <AdobeAnalyticsSegmentsTable
          isLoading={segmentsLoading}
          tableData={segments?.getChildAASegments ?? []}
          tableColumns={tableColumns}
        />
      )}
    </>
  );
};

LinkAdobeAnalyticsAccountSegments.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default withRouter(LinkAdobeAnalyticsAccountSegments);
