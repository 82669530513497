/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { DISABLE_TOKEN } from 'gql/linktoken';
import Stop from '@material-ui/icons/Stop';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';

const DisableMenuItem = React.forwardRef((props, ref) => {
  const { id, refetch, close, remoteName } = props;
  const [disableLinktoken, { error, data }] = useMutation(DISABLE_TOKEN);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.disableToken?.result === true) {
      showToast(`Account Disabled: ${remoteName}`, 'success');
      close();
      refetch();
    }
  }, [data]);

  return (
    <MenuItem
      innerRef={ref}
      onClick={() => {
        disableLinktoken({
          variables: {
            id
          }
        });
      }}
    >
      <ListItemIcon>
        <Stop fontSize="small" />
      </ListItemIcon>
      <ListItemText>Disable</ListItemText>
    </MenuItem>
  );
});

DisableMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  remoteName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default DisableMenuItem;
