import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ARCHIVE_BROADCAST_SEARCH } from 'gql/broadcastSearch';
import { ARCHIVE_PODCAST_SEARCH } from 'gql/podcastSearch';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';
import { BROADCAST, PODCAST } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';

/**
 * @param {Boolean} isModalOpen - determines open/closed state of modal
 * @param {Function} onChange - callback function to toggle modal state and pass back data.
 * @param {String} searchId - id of search report to archive
 * @param {String} searchName - name of search report to display in modal
 * @param {String} mediaType - Required to specify if we're archiving a podcast or a broadcast, as there are two different queries.
 */
const ArchiveMediaSearchModal = props => {
  const { isModalOpen, onChange, searchId, searchName, mediaType } = props;

  const [archiveBroadcastSearch, { error: broadcastError, data: broadcastData }] = useMutation(
    ARCHIVE_BROADCAST_SEARCH
  );
  const [archivePodcastSearch, { error: podcastError, data: podcastData }] = useMutation(
    ARCHIVE_PODCAST_SEARCH
  );

  useEffect(() => {
    if (broadcastData?.disableBroadcastSearch) {
      showToast('Report archived', 'success');
      onChange({
        isModalOpen: false,
        newMediaSearchData: broadcastData.disableBroadcastSearch
      });
    }

    if (podcastData?.disablePodcastSearch) {
      showToast('Report archived', 'success');
      onChange({
        isModalOpen: false,
        newMediaSearchData: podcastData.disablePodcastSearch
      });
    }
  }, [broadcastData, podcastData]);

  useEffect(() => {
    if (broadcastError || podcastError) {
      onChange({
        isModalOpen: false
      });
    }
  }, [broadcastError, podcastError]);

  const archiveSearch = () => {
    if (mediaType === BROADCAST) {
      archiveBroadcastSearch({ variables: { id: searchId } });
    }

    if (mediaType === PODCAST) {
      archivePodcastSearch({ variables: { id: searchId } });
    }
  };

  return (
    <>
      {isModalOpen && broadcastError && <AlbError toast error={broadcastError} />}
      {isModalOpen && podcastError && <AlbError toast error={podcastError} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Archive Report"
        body={
          <>
            Are you sure you want to archive the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{searchName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Archive"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={archiveSearch}
      />
    </>
  );
};

ArchiveMediaSearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  searchId: PropTypes.string,
  searchName: PropTypes.string,
  mediaType: PropTypes.string.isRequired
};

ArchiveMediaSearchModal.defaultProps = {
  searchId: null,
  searchName: null
};

export default ArchiveMediaSearchModal;
