/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Tabs, Tab } from '@material-ui/core';
import AlbDoughnutGraph from 'components/AlbGraphs/AlbDoughnutGraph';
import AlbDoughnutGraphLegend from 'components/AlbGraphs/AlbDoughnutGraphLegend';
import { topPostsColors } from 'util/colors';
import { renderUILongDate, getStartDate } from 'util/date';

const useStyles = makeStyles({
  container: {
    height: '100%',
    flex: 1,
    padding: '16px 24px'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: '0px',
    margin: '25px 15px'
  },
  titleText: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    padding: '0px 24px'
  }
});

/**
 * @method
 * @summary This component renders the total article count of each article search contained in the competitive intelligence report in a doughnut graph
 * @name ShareOfVoiceDoughnut
 * @param {Object} props - React props passed to this component
 * @param {Object} props.shareOfVoiceReport - The result of the competitive intelligence report query
 * @return {Object} - React JSX
 */
const ShareOfVoiceDoughnut = props => {
  const { shareOfVoiceReport } = props;

  const classes = useStyles();

  const [datasets, setDatasets] = useState([]);
  const [labels, setLabels] = useState([]);
  const [legend, setLegend] = useState([]);

  useEffect(() => {
    let graphLegend = [];
    const graphLabels = [];
    let graphData = [];

    if (shareOfVoiceReport?.article_searches?.length) {
      const { article_searches: articleSearches } = shareOfVoiceReport;
      let totalArticleCount = 0;

      const graphDataArticleSearches = articleSearches.map(
        ({ name, getArticleShareCountByPlatform }) => {
          graphLabels.push(name);
          if (getArticleShareCountByPlatform?.total_articles) {
            const { total_articles } = getArticleShareCountByPlatform;

            totalArticleCount += total_articles;
            return total_articles;
          }
          return 0;
        }
      );

      const graphLegendArticleSearches = articleSearches.map(({ name }, i) => {
        let subValue = '0%';

        if (totalArticleCount > 0) {
          subValue = `${Math.round(
            (graphDataArticleSearches[i] / totalArticleCount) * 100
          ).toLocaleString()}%`;
        }

        return {
          key: name + i,
          boxColor: topPostsColors[i % topPostsColors.length],
          position: name,
          value: graphDataArticleSearches[i],
          subValue
        };
      });

      graphLegend = [...graphLegendArticleSearches];
      graphData = [...graphDataArticleSearches];
    }

    if (shareOfVoiceReport?.article_search_filters?.length) {
      const { article_search_filters: articleSearchFilters } = shareOfVoiceReport;

      let totalArticleCount = 0;

      const graphDataArticleFilters = articleSearchFilters.map(
        ({ id, article_search_id, getArticleShareCountByPlatform }) => {
          graphLabels.push(`Search ${article_search_id} Filter id: ${id}`);

          if (getArticleShareCountByPlatform?.total_articles) {
            const { total_articles } = getArticleShareCountByPlatform;

            totalArticleCount += total_articles;
            return total_articles;
          }
          return 0;
        }
      );

      const graphLegendSearchFilters = articleSearchFilters.map(
        ({ id, article_search_id: articleSearchId }, i) => {
          let subValue = '0%';

          const name = `Search ${articleSearchId} Filter id: ${id}`;

          if (totalArticleCount > 0) {
            subValue = `${Math.round(
              (graphDataArticleFilters[i] / totalArticleCount) * 100
            ).toLocaleString()}%`;
          }

          return {
            key: name + i,
            boxColor: topPostsColors[i % topPostsColors.length],
            position: name,
            value: graphDataArticleFilters[i],
            subValue
          };
        }
      );

      graphLegend = [...graphLegend, ...graphLegendSearchFilters];
      graphData = [...graphData, ...graphDataArticleFilters];
    }

    setLegend(graphLegend);
    setLabels(graphLabels);
    setDatasets([
      {
        data: graphData,
        backgroundColor: graphData.map((_, i) => topPostsColors[i % topPostsColors.length])
      }
    ]);
  }, [shareOfVoiceReport]);

  return (
    <Paper className={classes.paper}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Tabs value={0} onChange={() => {}}>
          <Tab label="Relative Mindshare" />
        </Tabs>
        <Typography
          variantMapping={{ body1: 'span' }}
          className={classes.titleText}
          style={{ color: '#979797' }}
        >
          {renderUILongDate(getStartDate(12))} - Present
        </Typography>
      </Grid>
      <Grid container className={classes.container}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          className={classes.graphContainer}
        >
          <Grid item xs={7} style={{ height: '100%' }}>
            <AlbDoughnutGraph
              data={{
                datasets,
                labels
              }}
            />
          </Grid>
          <Grid container item xs={5} direction="column" justifyContent="center">
            {legend.map(({ key, position, boxColor, value, subValue }) => (
              <AlbDoughnutGraphLegend
                key={key}
                boxColor={boxColor}
                position={position}
                value={value}
                subValue={subValue}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

ShareOfVoiceDoughnut.propTypes = {
  shareOfVoiceReport: PropTypes.shape({
    article_searches: PropTypes.arrayOf(PropTypes.shape()),
    article_search_filters: PropTypes.arrayOf(PropTypes.shape())
  })
};

ShareOfVoiceDoughnut.defaultProps = {
  shareOfVoiceReport: {}
};

export default ShareOfVoiceDoughnut;
