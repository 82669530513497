import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button, Checkbox } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { CREATE_ARTICLE_SEARCH, ARTICLE_SEARCHES } from 'gql/articleSearch';
import { MEDIA_GROUPS } from 'gql/mediaGroup';
import AlbError from 'components/AlbError';
import Box from 'components/Box';
import { showToast } from 'contexts/ToastContext';
import modalStyles from './modalStyles';

const styles = makeStyles(modalStyles);

const CreateEarnedMediaModal = props => {
  const { isModalOpen, onChange, earnedMediaFields, defaultName } = props;
  const [articleSearchName, setArticleSearchName] = useState('');
  const [isDetecting, setIsDetecting] = useState(false);

  const classes = styles();
  const [createArticleSearch, { loading, error, data }] = useMutation(CREATE_ARTICLE_SEARCH, {
    refetchQueries: [
      { query: ARTICLE_SEARCHES, variables: { enabled: true } },
      { query: MEDIA_GROUPS }
    ]
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isMaxLength', value => value.length <= 255);
  }, []);

  useEffect(() => {
    if (data?.createArticleSearch) {
      showToast('Report created.', 'success');
      setArticleSearchName('');
      onChange({ isModalOpen: false, newArticleSearch: data.createArticleSearch });
    }
  }, [data]);

  // Allow user to pass in a pre-filled default name, based on the title query
  useEffect(() => {
    if (isModalOpen) {
      setArticleSearchName(defaultName ?? '');
    }
  }, [isModalOpen]);

  const closeModal = () => {
    setArticleSearchName('');
    onChange({ isModalOpen: false });
  };

  const parseLanguagesList = languages =>
    languages.length ? languages.map(({ key }) => key).join(',') : null;

  return (
    <>
      <Dialog maxWidth="sm" open={isModalOpen} onClose={closeModal}>
        <ValidatorForm
          onSubmit={() => {
            createArticleSearch({
              variables: {
                search: {
                  ...earnedMediaFields,
                  language: parseLanguagesList(earnedMediaFields.language),
                  name: articleSearchName
                },
                isDefault: isDetecting
              }
            });
          }}
        >
          <Box p={12}>
            <Grid container>
              <Grid item container>
                <Typography className={classes.title}>Create 3rd Party Media Report</Typography>
              </Grid>
              {error && <AlbError error={error} />}
              <Grid item style={{ marginBottom: '24px' }}>
                <Typography variantMapping={{ body1: 'span' }} className={classes.noteLabel}>
                  Please note:&nbsp;
                </Typography>
                <Typography variantMapping={{ body1: 'span' }} className={classes.note}>
                  Once you create a 3rd Party Media Report, you cannot edit it. You will have to
                  make a new report in order to have new search criteria.
                </Typography>
              </Grid>
              <Grid item container>
                <Typography className={classes.label}>3rd Party Media Report Name</Typography>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item style={{ flex: 1 }}>
                  <TextValidator
                    autoFocus
                    fullWidth
                    variant="filled"
                    placeholder="Enter Report Name"
                    value={articleSearchName}
                    onChange={event => setArticleSearchName(event.target.value)}
                    validators={['required', 'isMaxLength']}
                    errorMessages={[
                      '* Required',
                      `Must not exceed 255 characters in length. Current length: ${articleSearchName.length}`
                    ]}
                  />
                </Grid>
                <Checkbox checked={isDetecting} onChange={() => setIsDetecting(!isDetecting)} />
                <Typography>Detect events?</Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" className={classes.actions}>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  Create
                </Button>
              </Grid>
            </Grid>
          </Box>
        </ValidatorForm>
      </Dialog>
    </>
  );
};

CreateEarnedMediaModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  earnedMediaFields: PropTypes.shape(),
  defaultName: PropTypes.string
};

CreateEarnedMediaModal.defaultProps = {
  earnedMediaFields: null,
  defaultName: ''
};

export default CreateEarnedMediaModal;
