import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Grid,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { CREATE_SMART_FILTER, UPDATE_SMART_FILTER } from 'gql/smartFilter';
import AlbError from 'components/AlbError';
import Box from 'components/Box';
import { showToast } from 'contexts/ToastContext';

const styles = makeStyles({
  title: {
    color: '#0A1734',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '18px'
  },

  label: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0A1734',
    marginBottom: '8px'
  },
  actions: {
    marginTop: '24px',
    '& button:last-child': {
      marginLeft: '20px'
    }
  }
});

const SaveSmartFilterModal = props => {
  const { isModalOpen, onChange, isCreate, smartFilter, requiresAll, updateFilterId } = props;
  const [saveOption, setSaveOption] = useState('create');
  const [filterName, setFilterName] = useState('');

  const classes = styles();
  const [createSmartFilter, { loading: createLoading, error: createError }] = useMutation(
    CREATE_SMART_FILTER,
    {
      onCompleted: data => {
        onChange({
          isModalOpen: false,
          smartFilter: data.createSmartFilter
        });
        showToast('Your new filter was created!', 'success');
      },
      onError: err => {
        showToast(`Error: please try again. ${err}`, 'error');
      }
    }
  );
  const [updateSmartFilter, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_SMART_FILTER,
    {
      onCompleted: data => {
        onChange({
          isModalOpen: false,
          smartFilter: data.updateSmartFilter
        });
        showToast('Filter successfully updated!', 'success');
      },
      onError: err => {
        showToast(`Error: please try again. ${err}`, 'error');
      }
    }
  );

  const closeModal = () => {
    onChange({
      isModalOpen: false
    });
  };

  return (
    <>
      <Dialog maxWidth="sm" open={isModalOpen} onClose={closeModal}>
        <Box p={12}>
          <ValidatorForm
            onSubmit={() => {
              if (saveOption === 'create') {
                createSmartFilter({
                  variables: {
                    name: filterName,
                    requires_all_fields: requiresAll,
                    is_user_managed: true,
                    smart_filter_fields: smartFilter
                  }
                });
              } else {
                updateSmartFilter({
                  variables: {
                    id: updateFilterId,
                    requires_all_fields: requiresAll,
                    is_user_managed: true,
                    smart_filter_fields: smartFilter
                  }
                });
              }
            }}
          >
            <Grid container>
              <Grid item container>
                <Typography className={classes.title}>Save Smart Filter</Typography>
              </Grid>
              {(createError || updateError) && <AlbError error={createError || updateError} />}
              {!isCreate && (
                <Grid item container>
                  <Typography className={classes.label}>Save Options</Typography>
                </Grid>
              )}
              {!isCreate && (
                <Grid item container style={{ marginBottom: '32px' }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={saveOption}
                      onChange={event => setSaveOption(event.target.value)}
                    >
                      <Grid container>
                        <FormControlLabel
                          value="create"
                          control={<Radio />}
                          label="Save as New Smart Filter"
                        />
                        <FormControlLabel
                          value="update"
                          control={<Radio />}
                          label="Update Existing Saved Filter"
                        />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              {saveOption === 'create' && (
                <Grid item container>
                  <Typography className={classes.label}>Name of Smart Filter</Typography>
                </Grid>
              )}
              {saveOption === 'create' && (
                <Grid item container>
                  <TextValidator
                    fullWidth
                    variant="filled"
                    validators={['required']}
                    errorMessages={['The filter name is required']}
                    placeholder="Enter Name for the Smart Filter"
                    value={filterName}
                    onChange={event => setFilterName(event.target.value)}
                  />
                </Grid>
              )}
              <Grid item container justifyContent="flex-end" className={classes.actions}>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={createLoading || updateLoading}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Dialog>
    </>
  );
};

SaveSmartFilterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,
  smartFilter: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requiresAll: PropTypes.bool.isRequired,
  updateFilterId: PropTypes.string
};

SaveSmartFilterModal.defaultProps = {
  updateFilterId: null
};

export default SaveSmartFilterModal;
