import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, Close, Check } from '@material-ui/icons';
import { Grid, Typography, TextField, IconButton } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { PROGRAM_LIFT_REPORT, UPDATE_PROGRAM_LIFT_REPORT } from 'gql/programLiftReport';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';

const useStyles = makeStyles({
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontWeight: '600',
    fontSize: '36px',
    color: '#32327D'
  },
  dates: {
    fontWeight: '500',
    fontSize: '24px',
    color: '#6F6F6F'
  },
  bodyContainer: {
    marginTop: '12px',
    flex: '1'
  },
  body: {
    fontWeight: '500',
    fontSize: '24px',
    color: '#0A1734'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

const defaultText = `This is a performance explanation that you can edit. As an example: The program showed
the majority of its force on October 18, 2022. The program was especially impactful for
driving a large audience to further explore our product offerings.`;

const ReportExplanation = props => {
  const { reportId, explanation } = props;
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [displayValue, setDisplayValue] = useState(explanation ?? defaultText);
  const [inputValue, setInputValue] = useState(explanation ?? '');
  const [updateProgramLiftReport] = useMutation(UPDATE_PROGRAM_LIFT_REPORT, {
    refetchQueries: [
      {
        query: PROGRAM_LIFT_REPORT,
        variables: {
          id: reportId
        }
      }
    ]
  });

  const onSubmit = () => {
    if (inputValue.length > 280) {
      showToast('Text cannot be longer than 280 characters.', 'error');
    } else {
      updateProgramLiftReport({
        variables: {
          id: reportId,
          input: {
            explanation: inputValue
          }
        }
      })
        .then(() => {
          setDisplayValue(inputValue);
          showToast('Update success', 'success');
        })
        .catch(e => {
          handleGraphQLError(e);
        });
    }
  };

  return (
    <Grid container className={classes.bodyContainer}>
      <Grid item xs={11}>
        {isEditMode === false && <Typography className={classes.body}>{displayValue}</Typography>}
        {isEditMode === true && (
          <TextField
            fullWidth
            multiline
            rows={4}
            inputProps={{
              maxLength: '280'
            }}
            variant="filled"
            value={inputValue}
            onChange={event => {
              setInputValue(event.target.value);
            }}
          />
        )}
      </Grid>
      <Grid item xs={1} className={classes.buttonContainer}>
        {isEditMode === false && (
          <IconButton
            onClick={() => {
              setIsEditMode(!isEditMode);
            }}
          >
            <Edit />
          </IconButton>
        )}
        {isEditMode === true && (
          <>
            <IconButton
              onClick={() => {
                setIsEditMode(!isEditMode);
                setInputValue(explanation ?? '');
              }}
              style={{ marginBottom: '4px' }}
            >
              <Close />
            </IconButton>
            <IconButton
              onClick={() => {
                setIsEditMode(!isEditMode);
                onSubmit();
              }}
            >
              <Check />
            </IconButton>
          </>
        )}
      </Grid>
    </Grid>
  );
};

ReportExplanation.propTypes = {
  reportId: PropTypes.string.isRequired,
  explanation: PropTypes.string
};

ReportExplanation.defaultProps = {
  explanation: null
};

const ReportDetails = props => {
  const { report, selectedDates } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{report.name}</Typography>
        <Typography className={classes.dates}>
          {selectedDates != null
            ? `${moment.utc(selectedDates.start).format('LL')} - ${moment
                .utc(selectedDates.end)
                .format('LL')}`
            : ''}
        </Typography>
      </div>
      <ReportExplanation reportId={report.id} explanation={report?.explanation} />
    </Grid>
  );
};

ReportDetails.propTypes = {
  report: PropTypes.shape().isRequired,
  selectedDates: PropTypes.shape()
};

ReportDetails.defaultProps = {
  selectedDates: null
};

const ProgramLiftDetails = props => {
  const { reportId, selectedDates } = props;

  const { loading, error, data } = useQuery(PROGRAM_LIFT_REPORT, {
    variables: {
      id: reportId
    },
    skip: reportId == null
  });

  return (
    <Grid>
      {loading && <AlbLoading />}
      {error && <AlbError error={error} />}
      {data?.programLiftReport != null && (
        <ReportDetails report={data.programLiftReport} selectedDates={selectedDates} />
      )}
    </Grid>
  );
};

ProgramLiftDetails.propTypes = {
  reportId: PropTypes.string,
  selectedDates: PropTypes.shape()
};

ProgramLiftDetails.defaultProps = {
  reportId: null,
  selectedDates: null
};

export default ProgramLiftDetails;
