import React from 'react';

const PostDrawerViewContext = React.createContext({
  isPostDrawerOpen: false,
  campaignEventId: null,
  postId: null,
  linkTokenId: null,
  startDate: null,
  endDate: null,
  isResurgence: false,
  surgeStart: null,
  surgeEnd: null,
  isInitialSurge: false,
  toggleDrawer: () => {}
});

export default PostDrawerViewContext;
