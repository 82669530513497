import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import {
  THIRD_PARTY_MENTIONS_TIME_SERIES_BY_MENTION_GROUP,
  THIRD_PARTY_RATINGS_TIME_SERIES_BY_MENTION_GROUP,
  THIRD_PARTY_MENTIONS_TIME_SERIES_BY_MENTION_GROUPS,
  THIRD_PARTY_RATINGS_TIME_SERIES_BY_MENTION_GROUPS,
  THIRD_PARTY_ARTICLE_SEARCHES_GRAPH_TOTAL
} from 'gql/analyticsThirdPartyMedia';
import { DETECTED_EVENT_MENTION_GROUPS } from 'gql/detected_event';
import { useQuery } from '@apollo/client';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';
import ThirdPartyMediaDrawerDetails from './ThirdPartyMediaDrawerDetails';
import ThirdPartyMediaDrawerGraph from './ThirdPartyMediaDrawerGraph';
import ThirdPartyMediaDrawerTable from './ThirdPartyMediaDrawerTable';
import ThirdPartyMediaDrawerArticlesTable from './ThirdPartyMediaDrawerArticlesTable';
import ThirdPartyMediaDrawerGraphAndTable from './ThirdPartyMediaDrawerGraphAndTable';
import { NARRATIVE, MENTION, MENTIONS_AND_RATINGS } from '../ThirdPartyMediaConsts';
import ThirdPartyMediaDrawerDetailsDashboard from './ThirdPartyMediaDrawerDetailsDashboard';

const useStyles = makeStyles({
  drawerPaper: {
    minWidth: '617px',
    maxWidth: '617px',
    flexDirection: 'row'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    background: '#0A1734'
  },
  arrow: {
    color: '#ffffff'
  },
  showMoreButton: {
    width: '250px'
  }
});

const excludeMentionsEventTypes = [
  DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE
];

const excludeRatingsEventTypes = [
  DETECTED_EVENT_TYPE.BC_TV_MENTIONS,
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS,
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS
];

const excludeSharesEventTypes = [DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT];
const webEventTypes = [DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT, DETECTED_EVENT_TYPE.SA_TOTAL_SHARES];

const getGraphArray = (
  details,
  excludeRatings,
  excludeMentions,
  mentionGroupIds = [],
  articleSearchIds = []
) => {
  const { drawerType } = details;

  // from mentionGroup object
  if (drawerType === NARRATIVE) {
    const mentionsAndRatingsQueryArray = [];

    mentionsAndRatingsQueryArray.push({
      label: 'Mentions',
      metric: 'mentions',
      query: THIRD_PARTY_MENTIONS_TIME_SERIES_BY_MENTION_GROUP,
      queryName: 'thirdPartyMentionsTimeSeriesByMentionGroup',
      variables: { mentionGroupId: details.id },
      firstAirDate: details.firstAirDate,
      lastAirDate: details.lastAirDate,
      startDate: details.startDate,
      endDate: details.endDate
    });

    if (!excludeRatings) {
      mentionsAndRatingsQueryArray.push({
        label: 'Ratings',
        metric: 'ratings',
        query: THIRD_PARTY_RATINGS_TIME_SERIES_BY_MENTION_GROUP,
        queryName: 'thirdPartyRatingsTimeSeriesByMentionGroup',
        variables: { mentionGroupId: details.id },
        firstAirDate: details.firstAirDate,
        lastAirDate: details.lastAirDate,
        startDate: details.startDate,
        endDate: details.endDate
      });
    }

    return mentionsAndRatingsQueryArray;
  }

  // from detectedEvent object
  // same query, different metricName
  if (drawerType === MENTIONS_AND_RATINGS) {
    const mentionsAndRatingsQueryArray = [];

    if (mentionGroupIds.length) {
      if (!excludeMentions) {
        mentionGroupIds.forEach(mentionGroupId => {
          mentionsAndRatingsQueryArray.push({
            id: mentionGroupId,
            label: 'Mentions',
            metric: 'mentions',
            query: THIRD_PARTY_MENTIONS_TIME_SERIES_BY_MENTION_GROUP,
            queryName: 'thirdPartyMentionsTimeSeriesByMentionGroup',
            variables: { mentionGroupId },
            firstAirDate: details.firstAirDate,
            lastAirDate: details.lastAirDate,
            startDate: details.startDate,
            endDate: details.endDate
          });
        });
      }

      if (!excludeRatings) {
        mentionGroupIds.forEach(mentionGroupId => {
          mentionsAndRatingsQueryArray.push({
            id: mentionGroupId,
            label: 'Ratings',
            metric: 'ratings',
            query: THIRD_PARTY_RATINGS_TIME_SERIES_BY_MENTION_GROUP,
            queryName: 'thirdPartyRatingsTimeSeriesByMentionGroup',
            variables: { mentionGroupId },
            firstAirDate: details.firstAirDate,
            lastAirDate: details.lastAirDate,
            startDate: details.startDate,
            endDate: details.endDate
          });
        });
      }
    }

    if (articleSearchIds.length) {
      const isViewershipSelected = {
        // get article count
        [DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT]: false,
        // get total share count
        [DETECTED_EVENT_TYPE.SA_TOTAL_SHARES]: true
      }[details.type];

      mentionsAndRatingsQueryArray.push({
        label: isViewershipSelected ? 'Shares' : 'Mentions',
        query: THIRD_PARTY_ARTICLE_SEARCHES_GRAPH_TOTAL,
        queryName: 'thirdPartyArticleSearchesGraphTotal',
        variables: {
          articleSearchIds,
          isViewershipSelected,
          isEnabled: true,
          // this specific graph will display shares and not ratings
          getShareCount: isViewershipSelected
        },
        firstAirDate: details.firstAirDate,
        lastAirDate: details.lastAirDate,
        startDate: details.startDate,
        endDate: details.endDate
      });
    }

    return mentionsAndRatingsQueryArray;
  }

  return [];
};

/**
 * @name ThirdPartyMediaDrawerView - this drawer displays metrics for third party media events and can be triggered through the 3P Media analytics view, or the Dashboard
 * @param {String} props.details.id - required for queries. Mention group id for single narrative view, detectedEvent Id for dashboard events
 * @param {Date} props.details.firstAirDate - used to limit the graphs by event dates
 * @param {Date} props.details.lastAirDate - used to limit the graphs by event dates
 * @param {Date} props.details.startDate - the selected start date from the DatePicker component on the analytics page
 * @param {Date} props.details.endDate - the selected end date from the DatePicker component on the analytics page
 * @param {String} props.details.drawerType - one of narrative, mention, mentions_and_ratings enums. The first render drawers in the 3P Media analytics page. The mentions_and_ratings renders drawer from dashboard detected events, which aggregates mention group data into one drawer.
 * @returns React JSX - data within a drawer view.
 */

const ThirdPartyMediaDrawerView = props => {
  const classes = useStyles();
  const { details } = props;
  const { drawerType, type } = details;

  const [mentionGroupIds, setMentionGroupIds] = useState([]);
  const [articleSearchIds, setArticleSearchIds] = useState([]);
  const [singleMentions, extractSingleMentions] = useState([]);

  const loadCount = 5;
  const [count, setCount] = useState(loadCount);

  const isWebEvent = webEventTypes.includes(type);

  const { data, loading, error } = useQuery(DETECTED_EVENT_MENTION_GROUPS, {
    variables: {
      id: details.originalObject?.id,
      getMentionGroups: !isWebEvent,
      getArticleSearches: isWebEvent
    },
    skip: drawerType !== MENTIONS_AND_RATINGS,
    fetchPolicy: 'network-only'
  });

  const onClickHandler = () => setCount(prevState => prevState + loadCount);

  useEffect(() => {
    if (data?.detectedEvent?.enabledMentionGroups) {
      setMentionGroupIds(data.detectedEvent.enabledMentionGroups);
    }

    if (data?.detectedEvent?.enabledArticleSearches) {
      setArticleSearchIds(data.detectedEvent.enabledArticleSearches);
    }
  }, [data]);

  const graphArray = getGraphArray(
    details,
    excludeRatingsEventTypes.includes(type),
    excludeMentionsEventTypes.includes(type),
    mentionGroupIds,
    articleSearchIds
  );

  return (
    <div className={classes.container}>
      {loading && <AlbLoading />}
      {error && <AlbError error={error} />}
      {(drawerType === NARRATIVE || drawerType === MENTION) && (
        <ThirdPartyMediaDrawerDetails details={details} drawerType={drawerType} />
      )}
      {drawerType === MENTIONS_AND_RATINGS && (
        <ThirdPartyMediaDrawerDetailsDashboard details={details} />
      )}
      {drawerType === NARRATIVE && (
        <>
          {graphArray.map(graph => (
            <ThirdPartyMediaDrawerGraph key={graph.label} details={{ ...graph, type }} />
          ))}
          <ThirdPartyMediaDrawerTable details={details} />
        </>
      )}
      {drawerType === MENTIONS_AND_RATINGS && !isWebEvent && (
        <>
          {graphArray.slice(0, count).map(graph => (
            <ThirdPartyMediaDrawerGraphAndTable
              key={graph.id}
              details={{ ...details, ...graph }}
              extractSingleMentions={extractSingleMentions}
            />
          ))}
          {singleMentions.length > 0 && (
            <ThirdPartyMediaDrawerGraphAndTable
              details={{
                ...details,
                ids: singleMentions,
                label: 'Other Mentions',
                variables: { mentionGroupIds: singleMentions },
                ...(!excludeRatingsEventTypes.includes(type) && {
                  metric: 'ratings',
                  query: THIRD_PARTY_RATINGS_TIME_SERIES_BY_MENTION_GROUPS,
                  queryName: 'thirdPartyRatingsTimeSeriesByMentionGroups'
                }),
                ...(!excludeMentionsEventTypes.includes(type) && {
                  metric: 'mentions',
                  query: THIRD_PARTY_MENTIONS_TIME_SERIES_BY_MENTION_GROUPS,
                  queryName: 'thirdPartyMentionsTimeSeriesByMentionGroups'
                })
              }}
              otherMentions
            />
          )}
          {mentionGroupIds.length > count && !loading && (
            <Grid container justifyContent="center" style={{ padding: '20px 0px' }}>
              <Button
                className={classes.showMoreButton}
                color="primary"
                variant="contained"
                onClick={onClickHandler}
              >
                Show More
              </Button>
            </Grid>
          )}
        </>
      )}
      {drawerType === MENTIONS_AND_RATINGS && isWebEvent && (
        <>
          {graphArray.map(graph => (
            <ThirdPartyMediaDrawerGraph key={graph.label} details={{ ...graph, type }} />
          ))}
          <ThirdPartyMediaDrawerArticlesTable
            details={details}
            articleSearchIds={articleSearchIds}
            excludeShares={excludeSharesEventTypes.includes(type)}
          />
        </>
      )}
    </div>
  );
};

ThirdPartyMediaDrawerView.propTypes = {
  details: PropTypes.shape().isRequired
};

export default ThirdPartyMediaDrawerView;
