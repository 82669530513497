/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button, Grid, Tooltip } from '@material-ui/core';
import { CAMPAIGN_EVENTS_BY_DAY } from 'gql/campaignEvent';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { storyTypes } from 'util/social';
import colors from 'util/colors';
import {
  SparklineIcon as analyzeIcon,
  EyeIcon as eyeIcon,
  TwoPeopleIcon as teamIcon
} from 'util/assets';

const styles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    minWidth: '0',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderBottom: 'solid 0.5px rgba(151, 151, 151, 0.23)',
    '& > *': {
      '&:not(:first-child)': {
        marginLeft: '5px'
      },

      '&:not(:last-child)': {
        marginRight: '5px'
      }
    }
  },
  timeText: {
    flexShrink: '0',
    color: colors.doveGray,
    fontSize: '10px',
    fontWeight: '500',
    marginRight: '4px'
  },
  accountText: {
    flexShrink: '0',
    color: '#0d1a3a',
    fontSize: '10px',
    fontWeight: '600',
    marginRight: '2px',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  bodyText: {
    flexGrow: '1',
    color: colors.doveGray,
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  metric: {
    display: 'flex',
    alignItems: 'center',

    '& > img': {
      marginRight: '3px'
    },

    '& > p': {
      color: '#33337E',
      fontSize: '10px'
    }
  },
  button: {
    minWidth: 76,
    height: 35,
    backgroundColor: '#0d1a3a',
    borderRadius: 0,

    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  buttonText: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: '600'
  },
  errorDot: {
    width: '8px',
    height: '8px',
    background: '#e71e27',
    position: 'absolute',
    zIndex: '1020',
    marginTop: '-0.5px',
    marginLeft: '18px',
    borderRadius: '100%'
  },
  buttonAnalyze: {
    color: 'white',
    padding: '6px 10px',
    marginRight: '5px',
    minWidth: 'unset',
    height: '100%',

    '& img': {
      marginRight: '6px'
    }
  }
});

const CalendarWidgetItem = ({ currentUser, data }) => {
  const {
    linked_account: account,
    display_title: displayTitle,
    start_date: startDate,
    calendar_assets: assets,
    state,
    type,
    published_url: publishedUrl,
    lifetime_impressions: impressions,
    lifetime_engagement: engagement
  } = data;
  const drawerContext = useContext(PostDrawerViewContext);

  const classes = styles();

  const CustomTooltip = withStyles({
    tooltip: {
      backgroundColor: '#0d1a3a',
      padding: '3px 10px',
      borderRadius: '0px'
    }
  })(Tooltip);

  const isStoryPost = storyTypes.includes(type);
  const isDeniedPost = state === 'DENIED';
  const isCompletedPost = state === 'COMPLETED' || (state === 'INELIGIBLE' && isStoryPost);
  const date = momentTimezone.tz(startDate, currentUser.time_zone);

  return (
    <div className={classes.container}>
      {/* Single post drawer button */}
      {!isDeniedPost && isCompletedPost ? (
        <Button
          className={classes.buttonAnalyze}
          style={{ backgroundColor: colors.navy, marginLeft: '20px' }}
          onClick={() => drawerContext.toggleDrawer(true, data.id)}
          variant="contained"
        >
          <img src={analyzeIcon} height={16} alt="Single Post View" />
          Analyze
        </Button>
      ) : (
        <div style={{ margin: '15px' }} />
      )}
      {/* Asset display */}
      {assets.length > 0 && (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 35,
            height: 35
          }}
        >
          {assets.length > 1 && (
            <div style={{ position: 'absolute', color: '#ffffff' }}>+{assets.length - 1}</div>
          )}
          {assets[0].signed_thumbnail_url ? (
            <img
              src={`https://cdn.filestackcontent.com/resize=width:200,height:200,fit:crop${assets[0].signed_thumbnail_url}`}
              alt="Thumbnail"
              width={35}
              height={35}
            />
          ) : (
            <div
              style={{
                backgroundColor: '#000000',
                height: '35px',
                width: '100%',
                color: 'white',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <AlbLoading color="inherit" />
            </div>
          )}
        </div>
      )}
      {/* Account name */}
      <Typography className={classes.accountText}>{account.remote_name}</Typography>
      {/* Post title/body text */}
      <Typography className={classes.bodyText}>{displayTitle}</Typography>
      {/* Impressions */}
      <span className={classes.metric}>
        <img src={eyeIcon} height={10} alt="Impressions" />
        <Typography className={classes.metricText}>
          {Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(
            impressions
          )}
        </Typography>
      </span>
      {/* Engagement */}
      <span className={classes.metric}>
        <img src={teamIcon} height={10} alt="Engagement" />
        <Typography className={classes.metricText}>
          {Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(
            engagement
          )}
        </Typography>
      </span>
      {/* Date display */}
      <Typography className={classes.timeText}>{`${moment(date).format('h:mma')}`}</Typography>
      {/* Post Status button */}
      <CustomTooltip
        title="Stories do not have public URLs after completion"
        placement="right"
        disableHoverListener={!isStoryPost}
      >
        <span>
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            style={{ ...(isDeniedPost && { backgroundColor: colors.red }) }}
            disabled={isStoryPost || isDeniedPost}
            onClick={() => {
              if (state === 'COMPLETED') {
                if (publishedUrl) {
                  window.open(publishedUrl);
                }
              }
            }}
          >
            <Typography color="textSecondary" className={classes.buttonText}>
              {state === 'ACTIVE' && 'Edit'}
              {state === 'PAUSED' && 'Resume'}
              {state === 'PENDING' && 'Review'}
              {state === 'ERROR' && 'Fix'}
              {state === 'DRAFT' && 'Continue'}
              {isDeniedPost && 'Denied'}
              {(state === 'COMPLETED' || (state === 'INELIGIBLE' && isStoryPost)) && 'Completed'}
            </Typography>
            {state === 'ERROR' && <div className={classes.errorDot} />}
          </Button>
        </span>
      </CustomTooltip>
    </div>
  );
};

CalendarWidgetItem.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired
};

const CalendarWidget = props => {
  const { currentUser, history, event, style, item, size, isDashboard } = props;
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);

  const [getCampaignEventsByDay, { loading, error, data }] = useLazyQuery(CAMPAIGN_EVENTS_BY_DAY, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (isWidgetVisible) {
      if (isDashboard) {
        getCampaignEventsByDay({
          variables: {
            date: event.post_date,
            type: event.type
          }
        });
      } else {
        getCampaignEventsByDay({
          variables: {
            date: event.post_date,
            type: event.type,
            campaign: event.campaign
          }
        });
      }
    }
  }, [isWidgetVisible]);

  let margin = '0px';
  if (item.length === 0) {
    margin = `-${size / 2}px`;
  } else {
    margin = `${-(size / 2) + (parseFloat(style.width) / item.length) * event.diff}px`;
  }

  return (
    <>
      <Button
        // Styling For The Circle
        style={{
          display: 'block',
          minWidth: size,
          width: size,
          height: size,
          lineHeight: '20px',
          color: '#ffffff',
          background: item.color,
          fontSize: '12px',
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: `-${size / 2}px`,
          marginLeft: margin,
          borderRadius: '100%',
          cursor: 'pointer',
          padding: '0px',
          zIndex: isWidgetVisible ? 1010 : 1000
        }}
        onMouseEnter={() => setIsWidgetVisible(true)}
        onMouseLeave={() => setIsWidgetVisible(false)}
      >
        {event.number_of_post >= 100 ? '99+' : event.number_of_post}
      </Button>
      <Paper
        onMouseEnter={() => setIsWidgetVisible(true)}
        onMouseLeave={() => setIsWidgetVisible(false)}
        style={{
          position: 'absolute',
          marginLeft: `${(parseFloat(style.width) / item.length) * event.diff}px`,
          marginTop: -20,
          zIndex: 1005,
          background: '#ffffff',
          minWidth: 420,
          maxWidth: 620,
          height: 35,
          boxShadow: '0 2px 11px 0 rgba(0, 0, 0, 0.18)',
          borderRadius: 0,
          display: isWidgetVisible ? null : 'none'
        }}
      >
        {loading && (
          <Grid container justifyContent="center">
            <AlbLoading color="inherit" />
          </Grid>
        )}
        {error && (
          <Grid container justifyContent="center">
            <AlbError error={error} />
          </Grid>
        )}
        {data?.campaign_events_by_day?.map(eventData => (
          <CalendarWidgetItem
            key={eventData.id}
            data={eventData}
            history={history}
            currentUser={currentUser}
          />
        ))}
      </Paper>
    </>
  );
};

CalendarWidget.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  event: PropTypes.shape().isRequired,
  style: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  size: PropTypes.number.isRequired,
  isDashboard: PropTypes.bool
};

CalendarWidget.defaultProps = {
  isDashboard: false
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default withRouter(connect(mapStateToProps)(CalendarWidget));
