import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UNARCHIVE_SHARE_OF_VOICE_REPORT } from 'gql/shareOfVoice';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const UnarchiveShareOfVoiceModal = props => {
  const { isModalOpen, onChange, shareOfVoiceId, shareOfVoiceName } = props;

  const [unarchiveShareOfVoiceReport, { error, data }] = useMutation(
    UNARCHIVE_SHARE_OF_VOICE_REPORT
  );

  useEffect(() => {
    if (data?.enableShareOfVoiceReport) {
      showToast(`Report unarchived`, 'success');
      onChange({
        isModalOpen: false,
        newShareOfVoiceData: data.enableShareOfVoiceReport
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Unarchive Report"
        body={
          <>
            Are you sure you want to unarchive the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{shareOfVoiceName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Unarchive"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => unarchiveShareOfVoiceReport({ variables: { id: shareOfVoiceId } })}
      />
    </>
  );
};

UnarchiveShareOfVoiceModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  shareOfVoiceId: PropTypes.string,
  shareOfVoiceName: PropTypes.string
};

UnarchiveShareOfVoiceModal.defaultProps = {
  shareOfVoiceId: null,
  shareOfVoiceName: null
};

export default UnarchiveShareOfVoiceModal;
