import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import AlembicPageHeader from 'components/AlembicPageHeader';
import setPageTitle from 'util/titles';
import LinkAdobeAnalyticsSegmentForm from './LinkAdobeAnalyticsSegmentForm';

/**
 * @method
 * @summary This component renders the view that includes the form to select the segment
 * @name LinkAdobeAnalyticsAccount
 * @return {Object} - React JSX
 */
const LinkAdobeAnalyticsSegment = () => {
  useEffect(() => {
    setPageTitle('Link Adobe Analytics Segment');
  }, []);

  return (
    <>
      <Box mb={30}>
        <AlembicPageHeader backButton pageTitle="Link Adobe Analytics Segment" />
      </Box>

      <Paper>
        <HasAnyPriv privs={['LINKS', 'LINKS:READ', 'LINKS:READ:SELF']} showAlert>
          <Box p={24}>
            <LinkAdobeAnalyticsSegmentForm />
          </Box>
        </HasAnyPriv>
      </Paper>
    </>
  );
};

export default LinkAdobeAnalyticsSegment;
