export default {
  textHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  textHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1
  },
  avatar: {
    marginLeft: '10px'
  },
  handleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px'
  },
  textHandle: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#32327D'
  },
  textTime: {
    fontSize: '10px',
    fontWeight: '500',
    color: '#6F6F6F'
  },
  externalLinkButton: {
    color: '#0A1734'
  },
  addToButton: {
    fontWeight: 600,
    fontSize: '17px',
    color: '#0A1734'
  },
  addToButtonDisabled: {
    fontWeight: 500,
    fontSize: '17px',
    color: '#6F6F6F'
  },
  formControl: {
    padding: '20px'
  }
};
