import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import AlembicPageHeader from 'components/AlembicPageHeader';
import setPageTitle from 'util/titles';
import LinkAppStoreConnectAccountForm from './LinkAppStoreConnectAccountForm';

/**
 * @method
 * @summary This component renders the flow for connecting App Store Connect
 * @name LinkAppStoreConnectAccountForm
 * @return {Object} - React JSX
 */
const LinkAppStoreConnectAccount = () => {
  useEffect(() => {
    setPageTitle('Link App Store Connect');
  }, []);

  return (
    <>
      <Box mb={30}>
        <AlembicPageHeader pageTitle="Link App Store Connect" backButton />
      </Box>

      <Paper>
        <HasAnyPriv privs={['LINKS', 'LINKS:CREATE']} showAlert>
          <Box p={24}>
            <LinkAppStoreConnectAccountForm />
          </Box>
        </HasAnyPriv>
      </Paper>
    </>
  );
};

export default LinkAppStoreConnectAccount;
