import { gql } from '@apollo/client';

export const SMART_FILTERS = gql`
  query {
    smartFilters {
      id
      name
      requires_all_fields
      smart_filter_fields {
        id
        source_column_alembic
        source_column_custom_id
        criteria
        input1
        input2
      }
    }
  }
`;

export const CREATE_SMART_FILTER = gql`
  mutation(
    $name: String
    $requires_all_fields: Boolean!
    $is_user_managed: Boolean!
    $smart_filter_fields: [SmartFilterFieldInput!]
  ) {
    createSmartFilter(
      name: $name
      requires_all_fields: $requires_all_fields
      is_user_managed: $is_user_managed
      smart_filter_fields: $smart_filter_fields
    ) {
      id
      name
      requires_all_fields
      smart_filter_fields {
        id
        source_column_alembic
        source_column_custom_id
        criteria
        input1
        input2
      }
    }
  }
`;

export const UPDATE_SMART_FILTER = gql`
  mutation(
    $id: ID!
    $requires_all_fields: Boolean!
    $is_user_managed: Boolean!
    $smart_filter_fields: [SmartFilterFieldInput!]
  ) {
    updateSmartFilter(
      id: $id
      requires_all_fields: $requires_all_fields
      is_user_managed: $is_user_managed
      smart_filter_fields: $smart_filter_fields
    ) {
      id
      name
      requires_all_fields
      smart_filter_fields {
        id
        source_column_alembic
        source_column_custom_id
        criteria
        input1
        input2
      }
    }
  }
`;

export const UPDATE_SMART_FILTER_NAME = gql`
  mutation($id: ID!, $name: String!) {
    updateSmartFilterName(id: $id, name: $name)
  }
`;

export const DELETE_SMART_FILTER = gql`
  mutation($id: ID!) {
    deleteSmartFilter(id: $id)
  }
`;
