import isValidUrl from 'util/isValidUrl';

/**
 * Truncate URLs to the last folder in its path
 * @param {String} eventLabel
 * @returns {String} truncated string
 */
const truncateEventLabel = (eventLabel, TRUNCATE_LENGTH) => {
  const validUrl = isValidUrl(eventLabel);

  if (validUrl) {
    // removes http://, https://, and trailing slash
    let url = eventLabel
      .replace('http://', '')
      .replace('https://', '')
      .replace(/\/+$/, '');
    if (url.length <= TRUNCATE_LENGTH) {
      return url;
    }

    url = url.split('/').slice(-1);

    return `${url}...`;
  }
  return `${eventLabel.slice(0, TRUNCATE_LENGTH)}${
    eventLabel.length > TRUNCATE_LENGTH ? '...' : ''
  }`;
};

export default truncateEventLabel;
