import { showToast } from 'contexts/ToastContext';

/**
 * @method
 * @summary Displays a toast on the screen given a GraphQL error object
 *
 * @param {Object} error - The error object from a GraphQL query or mutation
 *
 * @returns {void}
 */
const handleGraphQLError = error => {
  if (error.networkError) {
    showToast('An error has occurred. Cannot process request.', 'error');
  } else if (error.graphQLErrors) {
    error.graphQLErrors.forEach(gqlError => {
      showToast(gqlError?.extensions?.userFacingErrorMessage ?? gqlError.message, 'error');
    });
  } else {
    showToast('An error has occurred', 'error');
  }
};

export default handleGraphQLError;
