import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { Check, ErrorOutlined as Error } from '@material-ui/icons';
import PropTypes from 'prop-types';

/*
Yaaay! An API-responsive button!
Pass the results of an API call into ApiButton with onClick:

  <ApiButton onClick={ () => myApiCall(thing) } >
    Button text
  </ApiButton>

If the button doesn't respond, make sure the onClick() function you pass in
returns the result of the API call, and that the result is valid.
*/

const READY = 'ready';
const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';

const styles = makeStyles(theme => ({
  root: {
    lineHeight: '1.5em',
    position: 'relative',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '&.error': { backgroundColor: theme.palette.error.main },
    '&.loading': { disabled: true },
    '&.ready': {},
    '&.success': {},

    '& .icon': {
      height: '1em',
      position: 'absolute',
      right: theme.spacing(1),
      width: '.75em',
      opacity: 0,
      transition: 'opacity .75s linear',
      transitionDelay: '1s',
      '&.loading': { transition: 'none' },
      '&.visible': { opacity: 1, transition: 'none' }
    }
  }
}));

const ApiButton = ({
  callback,
  children,
  color,
  delayed,
  disabled,
  fullWidth,
  onClick,
  variant
}) => {
  const classes = styles();
  const [status, updateStatus] = useState(READY);

  const delay = time => result => new Promise(resolve => setTimeout(() => resolve(result), time));

  const handleClick = async () => {
    updateStatus(LOADING);
    return onClick()
      .then(result => {
        updateStatus(result && result.success ? SUCCESS : ERROR);
        return result;
      })
      .then(delay(delayed ? 1750 : 400))
      .then(result => {
        callback(result);
        return result;
      });
  };

  useEffect(() => {
    if (status === ERROR) {
      callback(false);
    }
    if (status === SUCCESS) {
      updateStatus(READY);
    }
  }, [status]);

  return (
    <Button
      className={`${classes.root} ${status}`}
      color={color}
      disabled={status === LOADING || disabled}
      fullWidth={fullWidth}
      onClick={handleClick}
      variant={variant}
    >
      {children}
      <CircularProgress
        size={15}
        className={`icon ${LOADING} ${status === LOADING ? 'visible' : ''}`}
      />
      <Error variant="outlined" className={`icon ${ERROR} ${status === ERROR ? 'visible' : ''}`} />
      <Check className={`icon ${SUCCESS} ${status === SUCCESS ? 'visible' : ''}`} />
    </Button>
  );
};

ApiButton.propTypes = {
  callback: PropTypes.func, // Returns true or false depending on the result of the API call
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  delayed: PropTypes.bool, // Whether to run the callback after the button animation completes
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,

  variant: PropTypes.string
};

ApiButton.defaultProps = {
  callback: () => {},
  color: 'primary',
  delayed: true,
  disabled: false,
  fullWidth: false,
  variant: 'contained'
};

export default ApiButton;
