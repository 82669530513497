import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Tabs, Tab, Divider } from '@material-ui/core';

const styles = () => ({
  tabs: {
    minHeight: '64px',
    position: 'sticky'
  },
  tab: {
    minHeight: '64px'
  }
});

class TabNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { state } = this;
    const { children, onChange, routed, external, value } = this.props;
    const { tabs, classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Tabs
          indicatorColor="primary"
          value={routed || external ? value : state.value}
          onChange={onChange || this.handleChange}
          className={classes.tabs}
        >
          {tabs.map(tab => {
            return (
              <Tab
                className={classes.tab}
                disableRipple
                key={tab}
                disabled={tab.disabled}
                label={
                  <Grid container justifyContent="center">
                    <Typography color="textPrimary">{tab}</Typography>
                  </Grid>
                }
              />
            );
          })}
        </Tabs>
        <Divider />
        {!routed && !external && children[state.value]}
        {external && children[value]}
        {routed && <Fragment>{children}</Fragment>}
      </Paper>
    );
  }
}

TabNav.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  routed: PropTypes.bool,
  external: PropTypes.bool,
  value: PropTypes.number,
  classes: PropTypes.shape()
};

TabNav.defaultProps = {
  children: null,
  onChange: null,
  value: 0,
  routed: false,
  external: false,
  classes: null
};

export const RoutedTabNav = connect((state, ownProps) => {
  return {
    value: ownProps.value,
    tabs: ownProps.routes.map(route => route.name)
  };
})(withRouter(TabNav));

export default withStyles(styles)(TabNav);
