/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { ArrowRight, Close } from '@material-ui/icons';
import { Drawer, Input, IconButton, InputAdornment } from '@material-ui/core';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import { CAMPAIGN_EVENT_LIFETIME } from 'gql/campaignEvent';
import theme from 'theme';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { supportedDrawerGraphTypes } from 'util/social';
import PostDrawerGraph from './PostDrawerGraph';
import PostDrawerDetails from './PostDrawerDetails';
import PostDrawerResurgence from './PostDrawerResurgence';
import DrawerJSON from './single_post_drawer.json';

const useStyles = makeStyles({
  drawerPaper: {
    minWidth: '617px',
    maxWidth: '617px',
    flexDirection: 'row'
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    background: '#0A1734'
  },
  arrow: {
    color: '#ffffff'
  },
  content: {
    flex: 1
  },
  contentHeader: { padding: '25px' },
  resurgence: {
    padding: '25px',
    paddingTop: '30px',
    borderBottom: '1px solid #D8D8D8'
  },
  search: {
    padding: '25px',
    paddingTop: '30px',
    borderBottom: '1px solid #D8D8D8',

    // search field
    '& > :first-child': {
      display: 'flex',
      border: '1px solid #979797',
      borderRadius: '3px',

      '& > input': {
        padding: '10px',
        fontSize: '14px'
      }
    }
  },
  contentBody: {
    '& > :nth-child(even)': {
      backgroundColor: '#f5f5f5'
    }
  }
});

/**
 * @method
 * @summary Implements useContext() on PostDrawerViewContext and displays post data in a drawer
 * @name PostDrawerView
 * @return {Object} - React JSX
 */
const PostDrawerView = () => {
  const classes = useStyles();
  const drawerContext = useContext(PostDrawerViewContext);
  const {
    isPostDrawerOpen,
    campaignEventId,
    postId,
    linkTokenId,
    startDate,
    endDate,
    isResurgence,
    surgeStart,
    surgeEnd,
    isInitialSurge,
    toggleDrawer
  } = drawerContext;

  const withCampaignEventId = campaignEventId != null;
  const withPostAndLinkTokenId = postId != null && linkTokenId != null;

  const [getCampaignEvent, { loading, error, data }] = useLazyQuery(CAMPAIGN_EVENT_LIFETIME, {
    fetchPolicy: 'no-cache'
  });

  const [graphTree, setGraphTree] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [campaignEvent, setCampaignEvent] = useState(null);

  useEffect(() => {
    if (data?.campaign_event) {
      setCampaignEvent(data.campaign_event);
    }
  }, [data]);

  useEffect(() => {
    if (isPostDrawerOpen) {
      const variables = {
        ...(withCampaignEventId && { id: campaignEventId }),
        ...(withPostAndLinkTokenId && { post_id: postId, linktoken_id: linkTokenId })
      };

      getCampaignEvent({ variables });
    } else {
      setCampaignEvent(null);
    }
  }, [isPostDrawerOpen]);

  const extractByPostType = postType => graph =>
    postType === graph.post_type || postType.includes(graph.post_type.replace('*', ''));

  // recursively generates tree based on JSON structure
  const createGraphTree = (list, id = 0) =>
    list
      .filter(item => item.parent_id === id)
      .map(item => ({ ...item, children: createGraphTree(list, item.sequence_id) }));

  useEffect(() => {
    const postType = data?.campaign_event?.type;

    if (postType) {
      // display only graphs associated with current post type
      let currentPostType = postType;
      // certain types are functionally identical and should be treated as such
      if (postType === 'INSTAGRAM_IGTV_VIDEO') {
        currentPostType = 'INSTAGRAM_VIDEO';
      }

      const graphs = DrawerJSON.filter(extractByPostType(currentPostType));

      // reverse traversal through list of graphs and determine whether a graph is able to be rendered - and render itself and the parent
      const supportedGraphList = graphs
        .slice()
        .reverse()
        .reduce(
          (acc, { enabled, graph_type, parent_id, sequence_id, graph_title, metric_name }) => {
            const parentOfRenderedGraph = acc[sequence_id];
            const enabledGraph = enabled === 'TRUE'; // enable more graphs in the JSON file as support is added
            const supportedGraphType = supportedDrawerGraphTypes.includes(graph_type); // update to support more graphs types as graphs are created
            const graphTitle = graph_title.toLowerCase();
            const metricName = metric_name.toLowerCase();
            const meetsSearchCriteria =
              graphTitle.includes(searchFilter) || metricName.includes(searchFilter);

            if (
              parentOfRenderedGraph ||
              (enabledGraph && supportedGraphType && meetsSearchCriteria)
            ) {
              acc[sequence_id] = true;
              acc[parent_id] = true;
            } else {
              acc[sequence_id] = false;
            }

            return acc;
          },
          {}
        );

      const supportedGraphs = graphs.filter(({ sequence_id }) => supportedGraphList[sequence_id]);
      const formattedTree = createGraphTree(supportedGraphs);

      setGraphTree(formattedTree);
    }
  }, [data, searchFilter]);

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        anchor="right"
        open={isPostDrawerOpen}
        onClose={() => toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div
          role="button"
          className={classes.bar}
          onClick={() => toggleDrawer(false)}
          aria-hidden="true"
        >
          <ArrowRight className={classes.arrow} />
        </div>
        {isPostDrawerOpen && (
          <div className={classes.container}>
            <div className={classes.content}>
              {!!loading && <AlbLoading />}
              {!!error && <AlbError error={error} />}
              {!!campaignEvent && (
                <>
                  <div className={classes.content}>
                    <PostDrawerDetails
                      campaignEvent={campaignEvent}
                      isInitialSurge={isInitialSurge}
                      isResurgence={isResurgence}
                      annotationsStart={
                        isResurgence || isInitialSurge ? surgeStart : campaignEvent.completed_at
                      }
                      annotationsEnd={
                        isResurgence || isInitialSurge ? surgeEnd : campaignEvent.completed_at
                      }
                    />
                  </div>
                  {(isResurgence || isInitialSurge) && (
                    <div className={classes.resurgence}>
                      <PostDrawerResurgence
                        campaignEvent={campaignEvent}
                        startDate={startDate}
                        endDate={endDate}
                        surgeStart={surgeStart}
                        surgeEnd={surgeEnd}
                        isInitialSurge={isInitialSurge}
                      />
                    </div>
                  )}
                  <div className={classes.search}>
                    <Input
                      placeholder="Search"
                      onChange={e => setSearchFilter(e.target.value.toLowerCase())}
                      value={searchFilter}
                      endAdornment={
                        searchFilter.length ? (
                          <InputAdornment>
                            <IconButton onClick={() => setSearchFilter('')}>
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        ) : null
                      }
                    />
                  </div>
                  <div className={classes.contentBody}>
                    {graphTree.map(graphProps => (
                      <PostDrawerGraph
                        key={graphProps.sequence_id}
                        {...graphProps}
                        {...drawerContext}
                        campaignEvent={campaignEvent}
                        isInitialSurge={isInitialSurge}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Drawer>
    </MuiThemeProvider>
  );
};

export default PostDrawerView;
