const SmartFiltersStyles = {
  '@media (min-width: 1280px)': {
    root: {
      marginLeft: 'calc(-100vw / 2 + 1216px / 2)',
      marginRight: 'calc(-100vw / 2 + 1216px / 2)'
    }
  },
  '@media (min-width: 960px) and (max-width: 1279px)': {
    root: {
      marginLeft: '-32px',
      marginRight: '-32px'
    }
  },
  '@media (min-width: 600px) and (max-width: 959px)': {
    root: {
      marginLeft: '-24px',
      marginRight: '-24px'
    }
  },
  '@media (max-width: 599px)': {
    root: {
      marginLeft: '-16px',
      marginRight: '-16px'
    }
  },
  root: {
    marginTop: '-80px',
    marginBottom: '30px',
    paddingTop: '80px',
    paddingBottom: '50px',
    background: '#ffffff'
  },
  hidden: {
    display: 'none'
  },
  formTitle: {
    color: 'primary',
    fontWeight: '400'
  },
  loadFilter: {
    marginRight: '15px',
    border: '1px solid #0d1a3a'
  },
  saveFilter: {
    marginRight: '10px'
  },
  formContainer: {
    marginTop: '30px',
    marginBottom: '30px',
    maxHeight: '100%'
  },
  rightFields: {
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    height: '496px'
  },
  radioLabels: {
    color: '#979797',
    fontWeight: 500,
    fontSize: '13px'
  },
  selectedField: {
    fontWeight: 900
  },
  unselectedField: {
    fontWeight: 500
  },
  evenFieldRow: {
    background: 'rgba(151, 151, 151, 0.1)'
  },
  oddFieldRow: {
    background: '#ffffff'
  },
  radioLabel: {
    fontWeight: 500,
    fontSize: '13px'
  },
  activeRadio: {
    color: '#0A1734',
    fontWeight: 500,
    fontSize: '13px'
  },
  icons: {
    color: '#686868',
    fontSize: '20px',
    marginLeft: '10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  fieldsTitle: {
    fontSize: '18px',
    fontWeight: 500,
    marginTop: '-20px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
    color: '#0A1734'
  },
  clearAll: {
    marginTop: '-20px',
    borderRadius: '3px',
    border: '1px solid #0A1734',
    color: '#0A1734'
  },
  line: {
    position: 'absolute',
    marginLeft: '-20px',
    width: '105px',
    height: '2px',
    background: '#0A1734'
  },
  spacer: {
    width: '90px'
  },
  divider: {
    flex: 1,
    backgroundColor: 'rgba(13, 26, 58, 0.2)'
  },
  actionButton: {
    width: '200px',
    marginRight: '10px',
    color: '#ffffff',
    backgroundColor: '#0d1a3a'
  }
};

export default SmartFiltersStyles;
