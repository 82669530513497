export default {
  title: {
    color: '#0A1734',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '18px'
  },
  label: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0A1734',
    marginBottom: '8px'
  },
  closeIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: '#979797'
  },
  actions: {
    marginTop: '24px',
    '& button:last-child': {
      marginLeft: '20px'
    }
  },
  list: {
    paddingLeft: '20px',
    margin: '0px',
    marginBottom: '20px'
  },
  listItem: {
    fontSize: '13px',
    lineHeight: '19px',
    color: '#585858'
  },
  noteLabel: {
    color: '#585858',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '19px'
  },
  note: {
    color: '#585858',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px'
  },
  radioGroup: {
    flexDirection: 'row'
  },
  radioLabel: {
    '& span': {
      fontSize: '13px',
      fontWeight: 500
    }
  },
  tag: {
    marginRight: '2px',
    fontSize: '10px'
  },
  paper: {
    overflowY: 'unset'
  },
  required: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#979797',
    marginBottom: '8px',
    marginLeft: '5px'
  }
};
