import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getDatesForAnalytics } from 'util/date';
import { ACTIVE_POST_AGE_RANGE } from 'gql/analyticsHypeAndFlow';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AnalyticsBox from 'components/AnalyticsBox';
import AgeRangePostColumn from './AgeRangePostColumn';

const useStyles = makeStyles({
  container: {
    height: '100%'
  }
});

/**
 * @method
 * @summary This component renders the age of the youngest, median, and oldest active post within the time range
 * @name AgeRangePost
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.accounts - An array of link token objects from @see PostAccountSelector
 * @param {Object} props.dates - An object containing start and end dates from @see AnalyticsDatePicker
 * @return {Object} - React JSX
 */
const AgeRangePost = props => {
  const { accounts, dates } = props;
  const classes = useStyles();
  const [youngest, setYoungest] = useState(null);
  const [median, setMedian] = useState(null);
  const [oldest, setOldest] = useState(null);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(
    ACTIVE_POST_AGE_RANGE,
    {
      variables: {
        startDate,
        endDate,
        linkTokens: accounts.map(({ id }) => id),
        metric: 'alembicImpressions'
      }
    }
  );

  useEffect(() => {
    if (queryData?.activePostAgeRange) {
      const [
        tempYoungest = null,
        tempMedian = null,
        tempOldest = null
      ] = queryData.activePostAgeRange;

      setYoungest(tempYoungest);
      setMedian(tempMedian);
      setOldest(tempOldest);
    }
  }, [queryData]);

  return (
    <AnalyticsBox tabs={['Age Range of Active Posts']} dates={dates} onChange={() => {}}>
      <Grid container className={classes.container}>
        {!!queryLoading && <AlbLoading />}
        {!!queryError && <AlbError error={queryError} />}
        {!!queryData?.activePostAgeRange && (
          <>
            <Grid item xs={4}>
              <AgeRangePostColumn isOuterColumn value={youngest} group="Youngest" />
            </Grid>

            <Grid item xs={4}>
              <AgeRangePostColumn value={median} group="Median" />
            </Grid>

            <Grid item xs={4}>
              <AgeRangePostColumn isOuterColumn value={oldest} group="Oldest" />
            </Grid>
          </>
        )}
      </Grid>
    </AnalyticsBox>
  );
};

AgeRangePost.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default AgeRangePost;
