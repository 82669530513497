import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Button,
  Grid,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { Close as DeleteIcon } from '@material-ui/icons';
import CheckboxValidatorElement from 'components/FormComponents/CheckboxValidatorElement';
import DatePicker from 'components/DatePicker';
import { convertToUserTimeZone } from 'util/date';
import { showToast } from 'contexts/ToastContext';
import {
  selectTextOptions,
  selectNumberOptions,
  selectZipRangeOptions,
  selectDatePickerOptions,
  selectCheckboxOptions,
  INPUT_TYPES
} from './smartFilterEnums';

const style = {
  rowContainer: {
    width: '100%'
  },
  select: {
    minWidth: '150px',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '10px',
    marginLeft: 'auto'
  },
  textField: {
    width: '150px',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      marginTop: '31px'
    }
  },
  selectItem: {
    marginLeft: '30px'
  },
  customSelect: {
    marginLeft: '20px',
    minWidth: '300px'
  },
  radioLabels: {
    fontWeight: 500,
    fontSize: '13px',
    marginRight: 'auto',
    marginLeft: '30px'
  },
  ruleConjunction: {
    position: 'absolute',
    marginTop: '12px',
    fontWeight: 600,
    fontSize: '10px',
    color: '#1DA1F2',
    background: 'transparent',
    zIndex: 1
  },
  required: {
    position: 'absolute',
    marginTop: '5px',
    paddingLeft: '5px',
    color: 'red',
    fontSize: '10px'
  },
  fieldName: {
    fontSize: '13px',
    color: '#0d1a3a',
    fontWeight: 600
  },
  remainingText: {
    fontSize: '12px',
    color: '#0A1734',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginLeft: '10px'
  }
};

const SelectedFieldBox = ({
  field,
  required,
  contacts,
  identifier,
  unselectField,
  classes,
  conjunction,
  handleCheckField,
  checkedField,
  saveCompletedFieldInputs,
  existingField,
  remainingCount,
  userTimeZone,
  customComponent,
  costToRecreate,
  charLimit,
  fieldsExceedingLimit
}) => {
  const [selectTextOption, setSelectTextOption] = useState(selectTextOptions.EQUALS);
  const [textValue, setTextValue] = useState('');
  const [selectNumberOption, setSelectNumberOption] = useState('');
  const [selectOption, setSelectOption] = useState('');
  const [numberValue, setNumberValue] = useState('');
  const [secondNumberValue, setSecondNumberValue] = useState('');
  const [selectDatePickerOption, setSelectDatePickerOption] = useState(selectDatePickerOptions.IS);
  const [datePickerValue, setDatePickerValue] = useState(null);
  const [secondDatePickerValue, setSecondDatePickerValue] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(selectCheckboxOptions.FALSE);
  // extra input for the Zip Code Range field.
  const [zipCode, setZipCode] = useState('');

  const { displayName, name } = field;
  const {
    inputType,
    validators: fieldValidators,
    errorMessages: fieldErrorMessages
  } = field.inputProps;

  const validators = checkedField ? ['required'] : [];
  const errorMessages = ['required'];

  const ENCODE = 'encode';
  const DECODE = 'decode';

  const formatSelectOption = (option, action) => {
    if (action === ENCODE) {
      return option.toLowerCase().replace(/ /g, '_');
    }

    if (action === DECODE) {
      const splitWordArr = option.split('_');
      const upperCaseArr = [];

      splitWordArr.forEach(word => {
        const upperCased = word[0].toUpperCase() + word.slice(1);

        upperCaseArr.push(upperCased);
      });

      return upperCaseArr.join(' ');
    }
    return null;
  };

  // init all input values from existing field data.
  useEffect(() => {
    // first time an existing field gets loaded.
    if (existingField) {
      switch (inputType) {
        case INPUT_TYPES.TEXT:
          setSelectTextOption(field.select);
          setTextValue(field.value);
          break;
        case INPUT_TYPES.NUMBER:
          setSelectNumberOption(field.select);
          setNumberValue(field.value);
          setSecondNumberValue(field.secondValue);
          break;
        case INPUT_TYPES.DATE:
          setSelectDatePickerOption(field.select);
          setDatePickerValue(field.value);
          setSecondDatePickerValue(field.secondValue);
          break;
        case INPUT_TYPES.CHECKBOX:
          setCheckboxValue(
            field.value === true ? selectCheckboxOptions.TRUE : selectCheckboxOptions.FALSE
          );
          break;
        case INPUT_TYPES.RADIO:
          setCheckboxValue(
            field.value === true ? selectCheckboxOptions.TRUE : selectCheckboxOptions.FALSE
          );
          break;
        case INPUT_TYPES.SELECT:
          if (costToRecreate) {
            setSelectOption(field.value);
          } else {
            setSelectOption(formatSelectOption(field.value, DECODE));
          }
          break;
        default:
          break;
      }
    }
  }, [existingField]);

  useEffect(() => {
    let completedFieldObj = null;

    switch (inputType) {
      case INPUT_TYPES.TEXT:
        completedFieldObj = contacts
          ? { select: selectTextOption, value: textValue }
          : { value: textValue };
        break;
      case INPUT_TYPES.NUMBER:
        if (name !== 'distance_from_zip') {
          if (selectNumberOption !== selectNumberOptions.IS_BETWEEN) {
            completedFieldObj = contacts
              ? { select: selectNumberOption, value: numberValue }
              : { value: parseInt(numberValue, 10) };
          } else {
            completedFieldObj = contacts
              ? {
                  select: selectNumberOption,
                  value: numberValue,
                  secondValue: secondNumberValue
                }
              : { value: parseInt(numberValue, 10), secondValue: secondNumberValue };
          }
        } else {
          completedFieldObj = {
            select: selectNumberOption,
            value: numberValue,
            secondValue: zipCode
          };
        }
        break;
      case INPUT_TYPES.DATE:
        if (selectDatePickerOption !== selectDatePickerOptions.IS_BETWEEN) {
          completedFieldObj = contacts
            ? { select: selectDatePickerOption, value: datePickerValue }
            : { value: datePickerValue };
        }

        if (selectDatePickerOption === selectDatePickerOptions.IS_BETWEEN) {
          completedFieldObj = contacts
            ? {
                select: selectDatePickerOption,
                value: datePickerValue,
                secondValue: secondDatePickerValue
              }
            : { value: datePickerValue, secondValue: secondDatePickerValue };
        }
        break;
      case INPUT_TYPES.CHECKBOX:
      case INPUT_TYPES.RADIO:
        if (checkboxValue) {
          completedFieldObj = contacts ? { select: checkboxValue } : { value: checkboxValue };
        }
        break;
      case INPUT_TYPES.SELECT:
        if (selectOption) {
          if (contacts) {
            completedFieldObj = { select: selectOption };
          } else if (costToRecreate) {
            completedFieldObj = { value: selectOption };
          } else {
            completedFieldObj = { value: formatSelectOption(selectOption, ENCODE) };
          }
        }
        break;
      default:
        break;
    }

    if (completedFieldObj) {
      completedFieldObj = Object.assign(completedFieldObj, { name });
      saveCompletedFieldInputs(identifier, completedFieldObj);
    }
  }, [
    checkedField,
    selectTextOption,
    textValue,
    selectNumberOption,
    selectOption,
    numberValue,
    secondNumberValue,
    selectDatePickerOption,
    datePickerValue,
    secondDatePickerValue,
    zipCode,
    checkboxValue
  ]);

  const handleFirstDateChange = event => {
    if (secondDatePickerValue && event.isAfter(secondDatePickerValue)) {
      setDatePickerValue(event.toISOString());
      setSecondDatePickerValue(null);
    } else {
      setDatePickerValue(event.toISOString());
    }
  };

  const handleSecondDateChange = event => {
    if (datePickerValue && event.isAfter(datePickerValue)) {
      setSecondDatePickerValue(event.toISOString());
    }
  };

  const isValidEndDate = current => current.isAfter(datePickerValue);

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      className={classes.rowContainer}
    >
      <Grid item style={{ marginRight: '16px' }}>
        <CheckboxValidatorElement
          checked={required ? true : checkedField}
          validators={[]} // we can add validators here later if we need to.
          value={required ? true : checkedField}
          onChange={() => {
            if (!required) {
              handleCheckField(field, identifier);
            } else {
              showToast("This field is required and can't be unchecked.", 'warn');
            }
          }}
        />
      </Grid>
      <Grid
        item
        style={field.name !== 'distance_from_zip' ? { width: '250px' } : { width: '200px' }}
      >
        <Typography className={classes.fieldName}>{displayName}</Typography>
        {conjunction && <Typography className={classes.ruleConjunction}>{conjunction}</Typography>}
      </Grid>
      {inputType === INPUT_TYPES.TEXT && (
        <Grid item className={classes.selectItem}>
          {contacts ? (
            <SelectValidator
              onChange={e => setSelectTextOption(e.target.value)}
              value={selectTextOption}
              className={classes.select}
              variant="outlined"
            >
              {Object.values(selectTextOptions).map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </SelectValidator>
          ) : null}
          <Grid container alignItems="center">
            <TextValidator
              className={classes.textField}
              onChange={e => setTextValue(e.target.value)}
              value={textValue}
              validators={[...validators, ...fieldValidators]}
              variant="outlined"
              errorMessages={[...errorMessages, ...fieldErrorMessages]}
              error={fieldsExceedingLimit.includes(identifier)}
            />
            {remainingCount && <div className={classes.remainingText}>{remainingCount}</div>}
            {charLimit && checkedField && <div className={classes.remainingText}>{charLimit}</div>}
          </Grid>
        </Grid>
      )}
      {inputType === INPUT_TYPES.NUMBER && name !== 'distance_from_zip' && (
        <Grid item className={classes.selectItem}>
          {contacts ? (
            <SelectValidator
              onChange={e => setSelectOption(e.target.value)}
              value={selectNumberOption}
              className={classes.select}
              variant="outlined"
            >
              {Object.values(selectNumberOptions).map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </SelectValidator>
          ) : null}
          {selectNumberOption !== selectNumberOptions.IS_KNOWN &&
            selectNumberOption !== selectNumberOptions.IS_UNKNOWN && (
              <TextValidator
                className={classes.textField}
                onChange={e => setNumberValue(e.target.value)}
                value={numberValue}
                validators={[...validators, ...fieldValidators]}
                variant="outlined"
                errorMessages={[...errorMessages, ...fieldErrorMessages]}
                type="number"
              />
            )}
          {selectNumberOption === selectNumberOptions.IS_BETWEEN && (
            <>
              <Typography style={{ padding: '5px', display: 'inline-block' }} align="center">
                AND
              </Typography>
              <TextValidator
                className={classes.textField}
                onChange={e => setSecondNumberValue(e.target.value)}
                value={secondNumberValue}
                validators={[...validators, ...fieldValidators]}
                variant="outlined"
                errorMessages={[...errorMessages, ...fieldErrorMessages]}
                type="number"
              />
            </>
          )}
        </Grid>
      )}
      {/* special case field 'distance_from_zip' has a unique input format and doesn't map to db lead fields. */}
      {inputType === INPUT_TYPES.NUMBER && name === 'distance_from_zip' && (
        <Grid item className={classes.selectItem}>
          <Typography style={{ padding: '5px', display: 'inline-block' }} align="center">
            Distance
          </Typography>
          <SelectValidator
            onChange={e => setSelectNumberOption(e.target.value)}
            value={selectNumberOption}
            className={classes.select}
            variant="outlined"
          >
            {Object.values(selectZipRangeOptions).map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectValidator>
          <TextValidator
            className={classes.textField}
            onChange={e => setNumberValue(e.target.value)}
            value={numberValue}
            validators={[...validators, ...fieldValidators]}
            variant="outlined"
            errorMessages={[...errorMessages, ...fieldErrorMessages]}
            type="number"
            style={{ width: '75px' }}
          />
          <Typography style={{ padding: '5px', display: 'inline-block' }} align="center">
            miles of
          </Typography>
          <TextValidator
            className={classes.textField}
            onChange={e => setZipCode(e.target.value)}
            value={zipCode}
            variant="outlined"
            placeholder={field.inputProps?.inputPlaceholder}
            validators={[...validators, ...fieldValidators]}
            errorMessages={[...errorMessages, ...fieldErrorMessages]}
            type="number"
            style={{ width: '125px' }}
          />
        </Grid>
      )}
      {inputType === INPUT_TYPES.DATE && (
        <>
          <Grid item className={classes.selectItem}>
            <SelectValidator
              onChange={e => setSelectDatePickerOption(e.target.value)}
              value={selectDatePickerOption}
              className={classes.select}
              variant="outlined"
            >
              {Object.values(selectDatePickerOptions).map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>
          {selectDatePickerOption !== selectDatePickerOptions.IS_KNOWN &&
            selectDatePickerOption !== selectDatePickerOptions.IS_UNKNOWN && (
              <Grid item className={classes.textField}>
                <DatePicker
                  variant="outlined"
                  value={
                    datePickerValue
                      ? convertToUserTimeZone(datePickerValue, userTimeZone).format('L')
                      : null
                  }
                  handleDateChange={handleFirstDateChange}
                  validators={[...validators, ...fieldValidators]}
                  errorMessages={[...errorMessages, ...fieldErrorMessages]}
                  timestamp={false}
                  timeZone={userTimeZone}
                />
              </Grid>
            )}
          {selectDatePickerOption === selectDatePickerOptions.IS_BETWEEN && (
            <>
              <Grid item>
                <Typography style={{ padding: '5px', display: 'inline-block' }} align="center">
                  AND
                </Typography>
              </Grid>
              <Grid item className={classes.textField}>
                <DatePicker
                  variant="outlined"
                  value={
                    secondDatePickerValue
                      ? convertToUserTimeZone(secondDatePickerValue, userTimeZone).format('L')
                      : null
                  }
                  handleDateChange={handleSecondDateChange}
                  isValidDate={isValidEndDate}
                  validators={[...validators, ...fieldValidators]}
                  errorMessages={[...errorMessages, ...fieldErrorMessages]}
                  timestamp={false}
                  timeZone={userTimeZone}
                />
              </Grid>
            </>
          )}
        </>
      )}
      {inputType === INPUT_TYPES.CHECKBOX ||
        (inputType === INPUT_TYPES.RADIO && (
          <Grid className={classes.radioLabels}>
            <RadioGroup
              row
              value={checkboxValue}
              defaultValue={field.inputProps?.default || selectCheckboxOptions.FALSE}
              onChange={e => {
                setCheckboxValue(e.target.value);
              }}
            >
              <FormControlLabel
                value={selectCheckboxOptions.TRUE}
                label="True"
                control={<Radio />}
              />
              <FormControlLabel
                value={selectCheckboxOptions.FALSE}
                label="False"
                control={<Radio />}
              />
            </RadioGroup>
          </Grid>
        ))}
      {inputType === INPUT_TYPES.SELECT && (
        <Grid item className={classes.selectItem}>
          <SelectValidator
            onChange={e => setSelectOption(e.target.value)}
            value={selectOption}
            className={classes.select}
            variant="outlined"
          >
            {field.inputProps?.selectOptions?.map(option => {
              return (
                <MenuItem key={option.key} value={option.value.value}>
                  {option.value.displayName}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </Grid>
      )}
      {inputType === INPUT_TYPES.CUSTOM_SELECT && (
        <div className={classes.customSelect}>{customComponent}</div>
      )}
      {/* Required fields cannot be removed. */}
      {!required ? (
        <Button style={{ marginRight: '10px', marginLeft: 'auto' }}>
          {/* removes the field from the right pane */}
          <DeleteIcon onClick={() => unselectField(identifier)} />
        </Button>
      ) : null}
    </Grid>
  );
};

SelectedFieldBox.propTypes = {
  field: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  contacts: PropTypes.bool,
  classes: PropTypes.shape(),
  unselectField: PropTypes.func.isRequired,
  conjunction: PropTypes.string,
  checkedField: PropTypes.bool,
  handleCheckField: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
  saveCompletedFieldInputs: PropTypes.func.isRequired,
  existingField: PropTypes.bool.isRequired,
  userTimeZone: PropTypes.string.isRequired,
  remainingCount: PropTypes.string,
  customComponent: PropTypes.element,
  costToRecreate: PropTypes.bool,
  charLimit: PropTypes.string,
  fieldsExceedingLimit: PropTypes.arrayOf(PropTypes.string)
};

SelectedFieldBox.defaultProps = {
  classes: null,
  required: false,
  contacts: false,
  conjunction: null,
  checkedField: false,
  remainingCount: null,
  customComponent: null,
  costToRecreate: false,
  charLimit: null,
  fieldsExceedingLimit: []
};

export default withStyles(style)(SelectedFieldBox);
