import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, ListItemIcon, Collapse, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right, Launch } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import CustomFooter from 'components/TablePagination';
import { customValueRender } from 'components/AlbTable/CustomRender';
import Box from 'components/Box';
import { GET_PAGINATED_ARTICLES } from 'gql/article';
import { openExternalPost } from 'util/externalLinks';
import colors from 'util/colors';

const useStyles = makeStyles({
  tableContainer: {
    flex: 1
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInner: {
    position: 'relative',
    minHeight: '40px',

    '& > :last-child': {
      paddingBottom: '0px !important'
    }
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px'
  },
  title: {
    marginRight: '10px',
    fontSize: '16px',
    fontWeight: 500,
    color: colors.navy
  },
  article: {
    display: 'flex'
  },
  image: {
    height: '58px',
    width: '58px',
    minWidth: '58px',
    borderRadius: '3px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    marginRight: '9px'
  },
  highlightMentionText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineClamp: 3,
    wordBreak: 'break-word'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const ThirdPartyMediaDrawerArticlesTable = props => {
  const { details, articleSearchIds, excludeShares } = props;

  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState(['airDate', 'desc']);

  const { data, loading, error } = useQuery(GET_PAGINATED_ARTICLES, {
    variables: {
      startDate: details.startDate,
      endDate: details.endDate,
      articleSearchIds,
      after: page * rowsPerPage,
      count: rowsPerPage,
      orderBy: sortOrder,
      enabled: true
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    const tempTableData = [];
    let tempCount = 0;

    if (data?.getPaginatedArticles?.rows?.length) {
      data.getPaginatedArticles.rows.forEach(article => {
        tempTableData.push({
          thumbnail: article.thumbnail,
          url: article.url,
          airDate: moment.utc(article.published_date).format('L LT'),
          mention: article.title,
          shares: article.total_share_count,
          ratingsAndImpactCount: article.total_ratings
        });
      });

      tempCount = data.getPaginatedArticles.count;
    }

    setTableData(tempTableData);
    setCount(tempCount);
  }, [data]);

  const customDateRender = date => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {moment.utc(date).format('L')}
      <br />
      {moment.utc(date).format('LT')}
    </div>
  );

  const customArticleRender = (title, { rowData }) => {
    const [imageUrl, publishedUrl] = rowData;

    return (
      <Box className={classes.article}>
        {imageUrl && (
          <div
            aria-hidden="true"
            className={classes.image}
            style={{ backgroundImage: `url('${imageUrl}')` }}
          />
        )}
        <Typography className={classes.highlightMentionText}>{title}</Typography>
        {publishedUrl && (
          <div style={{ alignItems: 'flex-start' }}>
            <IconButton
              style={{ padding: '3px', color: '#0A1734' }}
              onClick={() => openExternalPost(publishedUrl)}
            >
              <Launch />
            </IconButton>
          </div>
        )}
      </Box>
    );
  };

  const columns = [
    {
      name: 'thumbnail',
      options: { display: false }
    },
    {
      name: 'url',
      options: { display: false }
    },
    {
      name: 'airDate',
      label: 'DATE/TIME',
      options: { customBodyRender: customDateRender }
    },
    {
      name: 'mention',
      label: 'MENTION',
      options: { sort: false, customBodyRender: customArticleRender }
    },
    {
      name: 'shares',
      label: 'SHARES',
      options: { display: !excludeShares, sort: false, customBodyRender: customValueRender }
    },
    {
      name: 'ratingsAndImpactCount',
      label: 'RATINGS',
      options: { customBodyRender: customValueRender }
    }
  ];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      []
    );

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangePage: setPage,
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  return (
    <Grid container style={{ padding: '25px', flexWrap: 'nowrap' }}>
      <Grid
        item
        className={classes.collapse}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        <ListItemIcon classes={{ root: classes.listItem }}>
          {isCollapsed ? <Right /> : <Down />}
        </ListItemIcon>
        {!isCollapsed && <Box className={classes.expandedIndicator} />}
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Grid item className={classes.headerLeft}>
          <Typography className={classes.title}>Mentions Breakdown</Typography>
        </Grid>
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          <Grid container justify="center">
            {error && <AlbError error={error} />}
            {loading && (
              <Grid item className={classes.loadingOverlay}>
                <AlbLoading />
              </Grid>
            )}
            {!error && (
              <Grid container style={{ opacity: loading ? 0.5 : 1 }}>
                <AlbTable
                  tableData={tableData}
                  tableColumns={columns}
                  tableOptions={options}
                  tableThemeOverrides={{
                    MUIDataTableHeadRow: {
                      root: {
                        '& > :not(:nth-child(2))': {
                          width: 1
                        }
                      }
                    },
                    MUIDataTableHeadCell: {
                      root: {
                        whiteSpace: 'nowrap'
                      },
                      sortAction: {
                        alignItems: 'center',

                        '& th': {
                          padding: '0px',
                          borderBottom: 'none'
                        }
                      },
                      toolButton: {
                        marginLeft: '0px',
                        marginRight: '0px'
                      }
                    },
                    MuiTableCell: {
                      root: {
                        borderBottom: 'none'
                      },
                      body: {
                        whiteSpace: 'wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    },
                    MuiPaper: {
                      root: {
                        flex: 1
                      }
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

ThirdPartyMediaDrawerArticlesTable.propTypes = {
  details: PropTypes.shape().isRequired,
  articleSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  excludeShares: PropTypes.bool
};

ThirdPartyMediaDrawerArticlesTable.defaultProps = {
  excludeShares: false
};

export default ThirdPartyMediaDrawerArticlesTable;
