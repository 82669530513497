/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { simpleStackedTooltip } from 'components/AlbGraphs/CustomTooltipTemplates';
import colors, { topPostsColors } from 'util/colors';
import { LINKTOKEN_EVENT_TYPE, DETECTED_EVENT_TYPE, REVENUE_EVENT_TYPE } from 'util/detectedEvents';
import getValueAbbreviation from 'util/getValueAbbreviation';

const useStyles = makeStyles({
  graph: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  tooltip: {
    position: 'absolute',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    color: 'white',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    pointerEvents: 'none',
    zIndex: 1,
    opacity: 0
  }
});

const DetectedEventTableGraph = props => {
  const { id, dateRange, selectedDate, event, originalObject, isFullGraphDisplay } = props;
  const [graphData, setGraphData] = useState({ labels: [], datasets: [] });
  const [timeSeriesData, setTimeSeriesData] = useState(null);
  const [lineGraph, setLineGraph] = useState(false);
  const canvasRef = useRef();
  const tooltipRef = useRef();
  const classes = useStyles();

  const format = new Intl.PluralRules('en', { type: 'ordinal' });
  const { startDate, endDate } = dateRange.range;

  const dateLabel = selectedDate.toISOString();

  const handleFormatGraphData = result => {
    if (result) {
      const label = `${id || originalObject.post_id}-${dateLabel}`;
      const labels = [];
      const selectedDateDataset = [];
      const datasets = [
        {
          label,
          data: result.map(({ x, y }) => {
            const day = moment.utc(x).format('D');
            labels.push(day + { one: 'st', two: 'nd', few: 'rd', other: 'th' }[format.select(day)]);

            // middle of the graph
            const isSelectedDate =
              moment.utc(x).format('MMM D/YYYY') === moment.utc(selectedDate).format('MMM D/YYYY');

            selectedDateDataset.push(isSelectedDate ? y : 0);
            return !lineGraph && isSelectedDate ? 0 : y;
          }),
          type: 'bar',
          backgroundColor: '#E180BA',
          ...(lineGraph && {
            type: 'line',
            borderColor: '#E180BA',
            pointHoverBackgroundColor: '#E180BA',
            pointHoverBorderColor: '#E180BA',
            fill: false,
            lineTension: 0,
            pointRadius: 0
          })
        },
        ...(!lineGraph
          ? [
              {
                label: `${label}-selected`,
                data: selectedDateDataset,
                type: 'bar',
                backgroundColor: '#C40075'
              }
            ]
          : [])
      ];

      setGraphData({ labels, datasets });
    }
  };

  const handleFormatStackedGraphData = list => {
    if (list) {
      const labels = [];
      const topTen = list.slice(0, 10).map(({ event_label, timeseries }, i) => ({
        label: `${event_label}-${dateLabel}`,
        data: timeseries.map(({ x, y }) => {
          const day = moment.utc(x).format('D');

          if (!i) {
            labels.push(day + { one: 'st', two: 'nd', few: 'rd', other: 'th' }[format.select(day)]);
          }

          return y;
        }),
        type: 'bar',
        backgroundColor: topPostsColors[i]
      }));

      const other = list.slice(10, list.length).reduce(
        (acc, { timeseries }) => {
          // if nothing in data array - build from first dataset, otherwise aggregate
          // this entry will be passed in but won't be shown in the graph in case of lack of data
          acc.data = !acc.data.length
            ? timeseries.map(({ y }) => y)
            : acc.data.map((y, i) => y + timeseries[i].y);
          return acc;
        },
        {
          label: `${id}-${dateLabel}-other`,
          data: [],
          type: 'bar',
          backgroundColor: topPostsColors[topPostsColors.length - 1]
        }
      );

      // 'other' is shown under the top ten posts, which are sorted desc by imp
      setGraphData({ labels, datasets: [...topTen, other] });
    }
  };

  // function only used to grab canvas context, and pass graph the data and labels
  const graph = canvas => {
    canvasRef.current = canvas.getContext('2d');

    return graphData;
  };

  const sum = list =>
    list.reduce((acc, { y }) => {
      acc += y;
      return acc;
    }, 0);

  useEffect(() => {
    setLineGraph(Math.abs(moment(startDate).diff(moment(endDate), 'd')) > 30);

    switch (event.type) {
      case LINKTOKEN_EVENT_TYPE.RESURGE_MULTIPOST:
      case DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE:
      case DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE:
      case DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE:
      case DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE:
        // case DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE:
        // case DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE:
        handleFormatStackedGraphData(
          originalObject?.graph?.sort((a, b) => {
            return sum(b.timeseries) - sum(a.timeseries);
          })
        );
        break;
      case LINKTOKEN_EVENT_TYPE.RESURGE_POST: // Social
      case LINKTOKEN_EVENT_TYPE.INITIAL_SURGE:
      case LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA3: // Web
      case LINKTOKEN_EVENT_TYPE.OUTLIER_SOURCE_GA4:
      case LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA3:
      case LINKTOKEN_EVENT_TYPE.OUTLIER_PAGE_GA4:
      case LINKTOKEN_EVENT_TYPE.OUTLIER_GOAL_GA3: // Conversion
      case DETECTED_EVENT_TYPE.IM_POST_RESURGE: // Social
      case DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE:
      case DETECTED_EVENT_TYPE.EG_POST_RESURGE:
      case DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE:
      case DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE: // Web: Google Analytics
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SOURCE:
      case DETECTED_EVENT_TYPE.GA_USERS_BY_PAGE_PATH:
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_PAGE_PATH:
      case DETECTED_EVENT_TYPE.GA_USERS_BY_AD_MATCHED_QUERY:
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_GOOGLE_ADS_QUERY:
      case DETECTED_EVENT_TYPE.GA_USERS_BY_SEARCH_KEYWORD:
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_MANUAL_TERM:
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_FIRST_USER_GOOGLE_ADS_QUERY:
      case DETECTED_EVENT_TYPE.GA_USERS_BY_CHANNEL_GROUPING:
      case DETECTED_EVENT_TYPE.GA_USERS_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME:
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING:
      case DETECTED_EVENT_TYPE.GA_USERS_BY_KEYWORD:
      case DETECTED_EVENT_TYPE.GA4_TOTAL_USERS_BY_SEARCH_TERM:
      case DETECTED_EVENT_TYPE.GA_INTERACTIONS_BY_GOAL:
      case DETECTED_EVENT_TYPE.GA4_INTERACTIONS_BY_GOAL:
      case DETECTED_EVENT_TYPE.AA_USERS_BY_SOURCE: // Web: Adobe Analytics
      case DETECTED_EVENT_TYPE.AA_USERS_BY_PAGE_PATH:
      case DETECTED_EVENT_TYPE.AA_USERS_BY_SEARCH_KEYWORD:
      case DETECTED_EVENT_TYPE.AA_USERS_BY_PAID_SEARCH:
      case DETECTED_EVENT_TYPE.AA_USERS_BY_ON_SITE_SEARCH:
      case DETECTED_EVENT_TYPE.AA_USERS_BY_MARKETING_CHANNEL:
      case DETECTED_EVENT_TYPE.AA_USERS_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.AA_INTERACTIONS_BY_EVENT:
      case DETECTED_EVENT_TYPE.SL_CLICKS:
      case DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL: // Conversion: Google Analytics
      case DETECTED_EVENT_TYPE.GA_REVENUE_BY_PRODUCT:
      case DETECTED_EVENT_TYPE.GA_REVENUE_BY_SOURCE_MEDIUM:
      case DETECTED_EVENT_TYPE.GA_QUANTITY_BY_PRODUCT:
      case DETECTED_EVENT_TYPE.GA_REVENUE_PER_TRANSACTION:
      case DETECTED_EVENT_TYPE.GA_TRANSACTIONS_PER_SESSION:
      case DETECTED_EVENT_TYPE.GA4_TRANSACTIONS_PER_PURCHASER:
      case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_ITEM_NAME:
      case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_SOURCE:
      case DETECTED_EVENT_TYPE.GA4_QUANTITY_BY_ITEM_NAME:
      case DETECTED_EVENT_TYPE.GA4_CONVERSIONS_BY_GOAL:
      case DETECTED_EVENT_TYPE.GA_REVENUE_BY_CHANNEL_GROUPING:
      case DETECTED_EVENT_TYPE.GA_REVENUE_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_DEFAULT_CHANNEL_GROUPING:
      case DETECTED_EVENT_TYPE.GA4_REVENUE_BY_SESSION_CAMPAIGN_NAME:
      case DETECTED_EVENT_TYPE.AA_UNIQUE_VISITORS:
      case DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT: // Conversion: Adobe Analytics
      case DETECTED_EVENT_TYPE.AA_REVENUE_BY_SOURCE:
      case DETECTED_EVENT_TYPE.AA_REVENUE_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.AA_REVENUE_BY_CHANNEL:
      case DETECTED_EVENT_TYPE.AA_REVENUE_BY_FARE_TYPE:
      case DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_CHANNEL:
      case DETECTED_EVENT_TYPE.AA_TRANSACTIONS_BY_FARE_TYPE:
      case DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.AA_QUANTITY_BY_CHANNEL:
      case DETECTED_EVENT_TYPE.AA_QUANTITY_BY_FARE_TYPE:
      case DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_CHANNEL:
      case DETECTED_EVENT_TYPE.AA_SKYMILES_USED_BY_FARE_TYPE:
      case DETECTED_EVENT_TYPE.AP_INSTALLS_BY_DEVICE: // Conversion: Apple App Store
      case DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE:
      case DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE:
      case DETECTED_EVENT_TYPE.GO_INSTALLS: // Conversion: Google Play Store
      case DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT:
      case DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT:
      case DETECTED_EVENT_TYPE.GO_ACTIVE_DEVICE_INSTALLS:
      case DETECTED_EVENT_TYPE.GO_UNINSTALL_EVENTS:
      case DETECTED_EVENT_TYPE.SF_LEADS_LEADS:
      case DETECTED_EVENT_TYPE.SF_OPPOS_OPPORTUNITIES:
      case DETECTED_EVENT_TYPE.SF_LEADS_BY_SOURCE:
      case DETECTED_EVENT_TYPE.SF_LEADS_BY_PROGRAM:
      case DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_BY_SOURCE:
      case DETECTED_EVENT_TYPE.SF_REVENUE_BY_SOURCE:
      case DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_FROM_LEADS_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.SF_OPPORTUNITIES_DIRECT_CREATED_BY_CAMPAIGN:
      case DETECTED_EVENT_TYPE.NS_FUND_FLOW:
      case DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS:
      case DETECTED_EVENT_TYPE.BC_TV_MENTIONS:
      case DETECTED_EVENT_TYPE.BC_TV_MENTIONS_WITH_VIEWERSHIP:
      case DETECTED_EVENT_TYPE.BC_TV_VIEWERSHIP:
      case DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS:
      case DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS_WITH_AUDIENCE:
      case DETECTED_EVENT_TYPE.PC_TRANSCRIPT_AUDIENCE:
      case DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS:
      case DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS_WITH_AUDIENCE:
      case DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_OCCURRENCES:
      case DETECTED_EVENT_TYPE.NC_BC_RADIO_NARRATIVE_OCCURRENCES:
      case DETECTED_EVENT_TYPE.NC_PC_SUMMARY_NARRATIVE_OCCURRENCES:
      case DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_OCCURRENCES:
      case DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_LOCAL:
      case DETECTED_EVENT_TYPE.NC_BC_TV_NARRATIVE_RATINGS_NATIONAL:
      case DETECTED_EVENT_TYPE.NC_PC_TRANSCRIPT_NARRATIVE_RATINGS:
      case DETECTED_EVENT_TYPE.SB_REVENUE_BY_STORE:
      case DETECTED_EVENT_TYPE.SB_REVENUE_BY_CHANNEL:
      case DETECTED_EVENT_TYPE.SB_REVENUE_BY_PRODUCT:
      case DETECTED_EVENT_TYPE.SB_REVENUE_BY_LOYALTY_PROGRAM:
      case DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT1:
      case DETECTED_EVENT_TYPE.SB_REVENUE_BY_DISCOUNT2:
      case DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_STORE:
      case DETECTED_EVENT_TYPE.SB_TRANSACTIONS_BY_CHANNEL:
      case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_PRODUCT:
      case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_LOYALTY_PROGRAM:
      case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT1:
      case DETECTED_EVENT_TYPE.SB_QUANTITY_BY_DISCOUNT2:
      case DETECTED_EVENT_TYPE.SP_FOOTTRAFFIC_BY_STORE:
        setTimeSeriesData(originalObject?.graph?.[0]?.timeseries);
        break;
      // Media
      // We do not store time series data for social animal
      case DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT:
      case DETECTED_EVENT_TYPE.SA_TOTAL_SHARES:
      default:
        break;
    }
  }, [dateRange.range]);

  useEffect(() => {
    handleFormatGraphData(timeSeriesData);
  }, [timeSeriesData]);

  const custom = tooltip => {
    const { dataPoints, caretX, caretY, title, labelColors, opacity } = tooltip;
    // provide offset of canvas element to nearest relative parent
    const { offsetTop, offsetLeft } = canvasRef.current.canvas;
    const { current } = tooltipRef;

    if (opacity && dataPoints.length) {
      const legendTooltipLabelData = dataPoints.map(({ yLabel }, i) => ({
        value: REVENUE_EVENT_TYPE.includes(event.type)
          ? getValueAbbreviation(yLabel, true)
          : yLabel.toLocaleString(),
        color: labelColors[i].backgroundColor
      }));

      Object.assign(current, {
        innerHTML: simpleStackedTooltip(title, legendTooltipLabelData)
      });
    }

    Object.assign(current.style, {
      opacity,
      // hide the tooltip element if no data - even the empty div
      display: dataPoints?.length ? 'unset' : 'none',
      top: `${caretY + offsetTop - 10}px`,
      left: `${caretX + offsetLeft + 10}px`
    });
  };

  const graphOptions = {
    legend: { display: false },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      enabled: false,
      mode: 'label',
      intersect: false,
      // hide tooltip for zero values, and enable the layered dataset hack for displaying current date
      filter: tooltipItem => !!tooltipItem.yLabel,
      // custom tooltip function injected into options
      custom
    },
    scales: {
      xAxes: [
        {
          ...(!isFullGraphDisplay && { barThickness: 10 }),
          ticks: { maxTicksLimit: 7, maxRotation: 0, fontColor: '#6F6F6F', fontSize: 10 },
          gridLines: { display: false },
          stacked: true
        }
      ],
      ...(isFullGraphDisplay
        ? {
            yAxes: [
              {
                gridLines: {
                  color: colors.gray,
                  drawBorder: false
                },
                ticks: {
                  precision: 2,
                  fontColor: '#6F6F6F',
                  fontSize: 10,
                  fontStyle: '600',
                  callback: value => {
                    return value.toLocaleString();
                  }
                },
                stacked: true
              }
            ]
          }
        : {
            yAxes: [
              {
                ticks: { display: false },
                gridLines: { display: false },
                stacked: true
              }
            ]
          })
    }
  };

  return (
    <div className={classes.graph} style={{ ...(isFullGraphDisplay && { position: 'relative' }) }}>
      <Bar data={graph} options={graphOptions} />
      <div className={classes.tooltip} ref={tooltipRef} />
    </div>
  );
};

DetectedEventTableGraph.propTypes = {
  id: PropTypes.string.isRequired,
  dateRange: PropTypes.shape().isRequired,
  selectedDate: PropTypes.shape().isRequired,
  event: PropTypes.shape(),
  originalObject: PropTypes.shape(),
  isFullGraphDisplay: PropTypes.bool
};

DetectedEventTableGraph.defaultProps = {
  event: null,
  originalObject: null,
  isFullGraphDisplay: false
};

export default DetectedEventTableGraph;
