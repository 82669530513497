import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import colors from 'util/colors';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { THIRD_PARTY_MENTIONS_GRAPH_TOTAL } from 'gql/analyticsThirdPartyMedia';
import { getDatesForAnalytics } from 'util/date';
import { thirdPartyTooltip } from 'components/AlbGraphs/CustomTooltipTemplates';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%'
  },
  point: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    backgroundColor: '#0A1734',
    borderRadius: '50%',
    transformStyle: 'preserve-3d'
  },
  hidden: {
    display: 'none !important'
  },
  legendBox: {
    width: '18px',
    height: '18px',
    backgroundColor: '#32327D',
    borderRadius: '3px',
    marginRight: '20px'
  },
  legendText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const TotaledMentionsBarGraph = props => {
  const {
    selectedDates,
    broadcastSearchIds,
    podcastSearchIds,
    articleSearchIds,
    articleSearchFilterIds,
    isTotaledSwitchChecked,
    isImpactSwitchChecked,
    mediaType,
    isEnabled
  } = props;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [formattedGraphData, setFormattedGraphData] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([]);

  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);
  const classes = useStyles();

  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const { data, loading, error } = useQuery(THIRD_PARTY_MENTIONS_GRAPH_TOTAL, {
    variables: {
      startDate,
      endDate,
      articleSearchIds,
      articleSearchFilterIds,
      broadcastSearchIds,
      podcastSearchIds,
      isViewershipSelected: isImpactSwitchChecked,
      mediaType,
      isEnabled
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.thirdPartyMentionsGraphTotal?.dataset?.length > 0) {
      const { dataset, mediaTypeList } = data.thirdPartyMentionsGraphTotal;
      setFormattedGraphData(dataset);
      setMediaTypes(mediaTypeList);
    } else {
      setFormattedGraphData([]);
      setMediaTypes([]);
    }
  }, [data]);

  const options = {
    // https://www.chartjs.org/docs/2.9.4/configuration/legend.html
    legend: {
      display: false
    },
    // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
    tooltips: {
      enabled: false,
      // intersect: false,
      custom: tooltipModel => {
        const { offsetTop, offsetLeft } = canvasRef.current.canvas;
        const { dataPoints } = tooltipModel;
        // if tooltip.opacity is 0 then we need to hide the tooltip
        const tempIsTooltipVisible = tooltipModel.opacity !== 0;
        let tooltipWidth = 345;
        setIsTooltipVisible(tempIsTooltipVisible);

        if (tempIsTooltipVisible) {
          dataPoints.forEach(dataPoint => {
            if (tooltipRef.current) {
              const { x } = formattedGraphData[dataPoint.index];

              const innerHTML = thirdPartyTooltip({
                mediaTypes,
                classification: 'none',
                firstAirDate: moment.utc(x).format('l'),
                narrativeTitle: '',
                dataCount: dataPoint.yLabel,
                boxColor: '#C40075',
                dataType: isImpactSwitchChecked === true ? 'Ratings / Impact' : 'Mentions',
                isTotalGraph: isTotaledSwitchChecked,
                isNarrativesGraph: false
              });

              const style = {
                display: dataPoint.yLabel ? 'unset' : 'none'
              };

              const top = offsetTop + dataPoint.y;
              const left = offsetLeft + dataPoint.x;

              style.direction = 'ltr';
              // We subtract 2.5 to get the tooltip in the middle of the point
              style.top = `${top - 2.5}px`;
              style.left = `${left - 2.5}px`;

              if (tooltipRef.current.offsetWidth > tooltipWidth) {
                tooltipWidth = tooltipRef.current.offsetWidth;
              }

              if (left + tooltipWidth > canvasRef.current.canvas.getBoundingClientRect().width) {
                style.direction = 'rtl';
              }

              Object.assign(tooltipRef.current.style, style);
              Object.assign(tooltipRef.current, { innerHTML });
            }
          });
        }
      }
    },
    // https://www.chartjs.org/docs/2.9.4/general/interactions/
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    animation: {
      duration: 0 // general animation time
    },
    // https://www.chartjs.org/docs/2.9.4/general/performance.html#disable-animations
    responsiveAnimationDuration: 0, // animation duration after a resize
    scales: {
      xAxes: [
        {
          // https://www.chartjs.org/docs/2.9.4/axes/styling.html#grid-line-configuration
          gridLines: {
            display: false
          },
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/#tick-configuration
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/linear.html
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            callback: value => value.toLocaleString(),
            precision: 2,
            beginAtZero: true,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14
          }
        }
      ]
    }
  };

  const graphData = canvas => {
    canvasRef.current = canvas.getContext('2d');

    return {
      labels: formattedGraphData.map(dataPoint =>
        moment
          .utc(dataPoint.x)
          .format('MMM D')
          .toUpperCase()
      ),
      datasets: [
        {
          data: formattedGraphData.map(dataPoint => Math.round(dataPoint.y)),
          backgroundColor: '#32327D',
          borderColor: 'transparent'
        }
      ]
    };
  };

  return (
    <div className={classes.container}>
      {loading && (
        <Box className={classes.loadingOverlay}>
          <AlbLoading />
        </Box>
      )}
      {error && <AlbError error={error} />}
      <Bar data={graphData} options={options} />
      <Box
        ref={tooltipRef}
        className={`${classes.point} ${isTooltipVisible ? '' : classes.hidden}`}
      />
      <Grid container justifyContent="center" alignItems="flex-start">
        <div className={classes.legendBox} />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={classes.legendText} style={{ color: '#6F6F6F' }}>
            {isImpactSwitchChecked === true ? 'Ratings / Impact' : 'Mentions'}
          </Typography>
          <Typography className={classes.legendText} style={{ color: '#32327D' }}>
            {formattedGraphData
              ?.reduce((acc, currentValue) => acc + Math.round(currentValue.y), 0)
              ?.toLocaleString()}
          </Typography>
        </Box>
      </Grid>
    </div>
  );
};

TotaledMentionsBarGraph.propTypes = {
  isImpactSwitchChecked: PropTypes.bool.isRequired,
  isTotaledSwitchChecked: PropTypes.bool.isRequired,
  selectedDates: PropTypes.shape().isRequired,
  broadcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  podcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEnabled: PropTypes.bool.isRequired,
  mediaType: PropTypes.string
};

TotaledMentionsBarGraph.defaultProps = {
  mediaType: null
};

export default TotaledMentionsBarGraph;
