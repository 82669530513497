const engagementMetrics = [
  'lifetime_impressions',
  'lifetime_engagement',
  'alembicEngagementRate',
  'total_comments',
  'lifetime_shares'
];

const engagementVideoMetrics = [
  'lifetime_impressions',
  'lifetime_engagement',
  'alembicEngagementRate',
  'total_comments',
  'lifetime_shares',
  'lifetime_positive_reactions',
  'lifetime_negative_reactions'
];

export default {
  topPostsv2: {
    tabTitle: 'TOTAL',
    query: {
      type: 'post',
      metrics: ['valuationModel', 'lifetime_impressions', 'lifetime_engagement', 'lifetime_shares'],
      sortColumn: 'lifetime_impressions',
      sortOrder: 'desc'
    }
  },
  topVideosv2: {
    tabTitle: 'TOTAL',
    query: {
      type: 'post',
      metrics: ['lifetime_views', 'lifetime_engagement', 'lifetime_shares'],
      sortColumn: 'lifetime_views',
      sortOrder: 'desc'
    }
  },
  impressionsTotalv2: {
    tabTitle: 'TOTAL',
    query: {
      type: 'post',
      metrics: ['lifetime_impressions'],
      sortColumn: 'lifetime_impressions',
      sortOrder: 'desc'
    }
  },
  impressionsType: {
    query: {
      table: 'impressionsTypeTableDruid',
      metrics: ['ORGANIC IMPRESSIONS', 'PAID IMPRESSIONS']
    }
  },
  growthTotalv2: {
    tabTitle: 'TOTAL',
    table: 'followersTableDruid'
  },
  engagementTotalv2: {
    tabTitle: 'TOTAL ENGAGEMENT',
    query: {
      type: 'post',
      metrics: engagementMetrics,
      sortColumn: 'lifetime_engagement',
      sortOrder: 'desc'
    }
  },
  engagementTotalVideov2: {
    tabTitle: 'TOTAL ENGAGEMENT',
    query: {
      type: 'post',
      metrics: engagementVideoMetrics,
      sortColumn: 'lifetime_engagement',
      sortOrder: 'desc'
    }
  },
  engagementRatev2: {
    tabTitle: 'ENGAGEMENT %',
    query: {
      type: 'post',
      metrics: engagementMetrics,
      sortColumn: 'lifetime_engagement',
      sortOrder: 'desc'
    }
  },
  engagementRateVideov2: {
    tabTitle: 'ENGAGEMENT %',
    query: {
      type: 'post',
      metrics: engagementVideoMetrics,
      sortColumn: 'lifetime_engagement',
      sortOrder: 'desc'
    }
  },
  engagementCommentsv2: {
    tabTitle: 'COMMENTS',
    query: {
      type: 'post',
      metrics: engagementMetrics,
      sortColumn: 'total_comments',
      sortOrder: 'desc'
    }
  },
  engagementCommentsVideov2: {
    tabTitle: 'COMMENTS',
    query: {
      type: 'post',
      metrics: engagementVideoMetrics,
      sortColumn: 'total_comments',
      sortOrder: 'desc'
    }
  },
  engagementSharesv2: {
    tabTitle: 'SHARES',
    query: {
      type: 'post',
      metrics: engagementMetrics,
      sortColumn: 'lifetime_shares',
      sortOrder: 'desc'
    }
  },
  engagementSharesVideov2: {
    tabTitle: 'SHARES',
    query: {
      type: 'post',
      metrics: engagementVideoMetrics,
      sortColumn: 'lifetime_shares',
      sortOrder: 'desc'
    }
  },
  engagementLikesv2: {
    tabTitle: 'LIKES',
    query: {
      type: 'post',
      metrics: engagementVideoMetrics,
      sortColumn: 'lifetime_positive_reactions',
      sortOrder: 'desc'
    }
  },
  engagementDislikesv2: {
    tabTitle: 'DISLIKES',
    query: {
      type: 'post',
      metrics: engagementVideoMetrics,
      sortColumn: 'lifetime_negative_reactions',
      sortOrder: 'desc'
    }
  },
  instagramStories: {
    tabTitle: 'Instagram Stories',
    query: {
      type: 'post',
      metrics: [
        'lifetime_impressions',
        'lifetime_reach',
        'total_comments',
        'alembicForwardRate',
        'alembicBackRate',
        'alembicExitRate'
      ],
      sortColumn: 'lifetime_impressions',
      sortOrder: 'desc'
    }
  },
  videoViews: {
    query: {
      table: 'videoViewsTableDruid',
      metrics: ['VIEWS', 'AVG % VIEWED']
    }
  },
  watchTime: {
    query: {
      table: 'watchTimeTableDruid',
      metrics: ['WATCH TIME', 'AVG WATCH TIME', 'DURATION']
    }
  },
  videoLength: {
    query: {
      type: 'post',
      metrics: ['yt_duration'],
      sortColumn: 'lifetime_impressions',
      sortOrder: 'desc'
    }
  }
};
