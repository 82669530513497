import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, ClickAwayListener } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MoreVert as Actions, LocalOffer as Tag } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { DELETE_SALESFORCE_REVENUE_NOTE } from 'gql/salesforce';
import SalesforceRevenueNoteModal from 'components/AlembicModalForm/SalesforceRevenueNoteModal';
import Box from 'components/Box';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import theme from 'theme';

const useStyles = makeStyles({
  actionsWrapper: {
    position: 'absolute',
    height: '25px',
    width: '25px',
    top: '12px',
    right: '12px',
    display: 'flex',
    direction: 'rtl',
    textAlign: 'center'
  },
  actions: {
    position: 'absolute',
    alignItems: 'start',
    textAlign: 'left'
  },
  actionsMenu: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: 'white',
    padding: '15px',
    paddingRight: '50px',
    marginTop: '-12px',
    marginRight: '-12px',
    borderRadius: '0px'
  },
  actionsOptions: {
    marginRight: '20px',

    '& div': {
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },

    '& :not(:last-child)': {
      marginBottom: '20px'
    }
  },
  button: {
    transform: 'rotateY(180deg)',
    '& svg': {
      height: '25px !important',
      width: '25px !important',
      color: '#0B1835'
    }
  },
  note: {
    direction: 'ltr',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px'
  }
});

const modalTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      paper: {
        '& > form': {
          '& > div': {
            padding: '12px'
          }
        },

        '& > div': {
          padding: '12px'
        }
      },
      paperWidthSm: {
        padding: '8px'
      }
    }
  }
});

const refetchQueries = ['getSalesforceRevenueTopPerformers'];

const SalesforceBestPerformersActions = props => {
  const { item } = props;
  const { id, note } = item;

  const classes = useStyles();

  const [displayedActions, setDisplayedActions] = useState(false);
  const [isAddNoteModalOpen, toggleIsAddNoteModalOpen] = useState(false);

  const [displayHover, setDisplayHover] = useState(false);

  const [deleteNote, { error, data }] = useMutation(DELETE_SALESFORCE_REVENUE_NOTE, {
    refetchQueries
  });

  useEffect(() => {
    if (data?.deleteSalesforceRevenueNote) {
      showToast('Note removed.', 'success');
      setDisplayedActions(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
      setDisplayedActions(false);
    }
  }, [error]);

  const actions = [];

  if (note != null) {
    actions.push({
      label: 'Edit Note',
      action: () => toggleIsAddNoteModalOpen(!isAddNoteModalOpen)
    });
    actions.push({
      label: 'Remove Note',
      action: () => deleteNote({ variables: { id } })
    });
  } else {
    actions.push({
      label: 'Add Note',
      action: () => toggleIsAddNoteModalOpen(!isAddNoteModalOpen)
    });
  }

  return (
    <Box className={classes.actionsWrapper}>
      {displayedActions && (
        <Grid container wrap="nowrap" className={classes.actions}>
          <ClickAwayListener onClickAway={() => setDisplayedActions(false)}>
            <Paper className={classes.actionsMenu} style={{ zIndex: 1 }}>
              <Grid item className={classes.actionsOptions}>
                {actions.map(action => (
                  <Grid
                    item
                    key={action.label}
                    onClick={e => {
                      e.stopPropagation();
                      action.action();
                    }}
                  >
                    {action.label}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Grid>
      )}
      {note != null && !displayedActions && displayHover && (
        <Grid container wrap="nowrap" className={classes.actions}>
          <Paper className={classes.actionsMenu} style={{ zIndex: 3 }}>
            <Grid item className={classes.actionsOptions}>
              <div className={classes.note}>{note}</div>
            </Grid>
          </Paper>
        </Grid>
      )}
      <IconButton
        className={classes.button}
        size="small"
        style={{ zIndex: displayHover ? 4 : 2 }}
        onMouseOver={() => setDisplayHover(true)}
        onMouseLeave={() => setDisplayHover(false)}
        onFocus={() => setDisplayHover(true)}
        onBlur={() => setDisplayHover(false)}
        onClick={e => {
          e.stopPropagation();
          setDisplayedActions(true);
        }}
      >
        {note != null ? <Tag /> : <Actions />}
      </IconButton>
      <MuiThemeProvider theme={modalTheme}>
        {isAddNoteModalOpen && (
          <SalesforceRevenueNoteModal
            id={id}
            note={note}
            isModalOpen={isAddNoteModalOpen}
            onChange={e => toggleIsAddNoteModalOpen(e.isModalOpen)}
          />
        )}
      </MuiThemeProvider>
    </Box>
  );
};

SalesforceBestPerformersActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    note: PropTypes.string
  }).isRequired
};

export default SalesforceBestPerformersActions;
