import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Tabs,
  Tab,
  IconButton
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, FirstPage, LastPage } from '@material-ui/icons';
import { TOP_ACTIVE_POST_TABLE } from 'gql/analyticsHypeAndFlow';
import AlbError from 'components/AlbError';
import AlbLoading from 'components/AlbLoading';
import Box from 'components/Box';
import theme from 'theme';
import { getDatesForAnalytics, formatDateRange } from 'util/date';
import GraphHover from 'components/GraphHover';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import TabWithTooltip from 'components/TabWithTooltip';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  title: {
    minHeight: '64px',
    padding: '0px 24px',
    fontWeight: 500
  },
  tabsIndicator: {
    display: 'none'
  },
  titleText: {
    padding: '0px',
    maxWidth: 'unset',

    '& > span': {
      whiteSpace: 'nowrap'
    }
  },
  dates: {
    color: '#6F6F6F'
  },
  tableCell: {
    padding: '10px'
  },
  tableContent: {
    padding: '8px'
  },
  hoveredPostId: {
    backgroundColor: '#D8D8D8'
  },
  hoveredCell: {
    backgroundColor: '#0A1734',
    cursor: 'zoom-in',
    color: 'white'
  },
  paginationDates: {
    color: '#6F6F6F',
    fontWeight: 500,
    padding: '35px',

    '& > span': {
      padding: '0px 20px'
    },

    '& > button': {
      padding: '0px',
      color: 'black'
    }
  }
});

const TopTenPostsTable = props => {
  const { accounts, dates } = props;

  const classes = useStyles();

  const tableTheme = createTheme({
    ...theme,
    overrides: {
      MuiTableCell: {
        root: {
          borderBottom: 'none',

          '&:first-child': {
            padding: '16px 24px',
            borderRight: '1px solid #C4C4C4'
          },

          '&:nth-child(2)': {
            paddingLeft: '24px'
          },

          '&:last-child': {
            paddingRight: '24px'
          }
        },
        head: {
          '&:first-child': {
            borderRight: 'none'
          }
        }
      },
      MuiTableBody: {
        root: {
          '& > tr': {
            '&:first-child': {
              boxShadow: 'inset 0px 20px 13px -13px rgba(0, 0, 0, 0.1)',

              '& > th, & > td': {
                paddingTop: '24px'
              }
            },

            '&:nth-child(10)': {
              borderBottom: '1px solid #32327D'
            },

            '&:last-child': {
              boxShadow: 'inset 0px -20px 13px -13px rgba(0, 0, 0, 0.1)',

              '& > th, & > td': {
                paddingBottom: '24px'
              }
            }
          }
        }
      },
      MuiTableRow: {
        head: {
          '& > th, & > td': {
            paddingTop: '16px !important'
          }
        }
      }
    }
  });

  const [topPostsData, setTopPostsData] = useState([]);
  const [totalsData, setTotalsData] = useState([]);
  const [dateHeaders, setDateHeaders] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [hoveredPostId, setHoveredPostId] = useState(null);
  const [hoveredCellKey, setHoveredCellKey] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [linkToken, setLinkToken] = useState(null);

  useEffect(() => {
    const dateLimit = moment(dates.start).add(13, 'days');
    const fixedEnd = moment(dates.end).isAfter(dateLimit) ? dateLimit : dates.end;

    setSelectedDateRange({ start: dates.start, end: fixedEnd });
  }, [dates]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDateRange.start,
    selectedDateRange.end
  );

  const { loading, error, data: queryData } = useQuery(TOP_ACTIVE_POST_TABLE, {
    variables: {
      startDate,
      endDate,
      linkTokens: accounts.map(({ id }) => id),
      metric: 'alembicImpressions'
    }
  });

  const rows = [
    'Top Post',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    'Top 10 Post Imp. on Day',
    'Top 10 % Imp. of Total',
    'Other Posts Count',
    'Other Posts Imp.',
    'Other Posts % of Imp. Total'
  ];

  const formatTableData = data => {
    const formattedData = data.map(
      ({
        date,
        topTen,
        topTenTotal,
        topTenPercentageOfTotal,
        otherCount,
        otherTotal,
        otherPercentageOfTotal
      }) => {
        // if we are missing data from the query - under 10 posts in a day
        // we insert placeholders to fill the empty cells in the table
        const topTenImpressions = Array.from(Array(10), (_, i) => {
          if (topTen[i]) {
            const { postId, linkTokenId, value } = topTen[i];
            return { postId, linkTokenId, value, date };
          }
          return { value: '-', date };
        });

        const topTenPercentage = `${Math.round(topTenPercentageOfTotal)}%`;
        const otherPercentage = `${Math.round(otherPercentageOfTotal)}%`;

        return [
          ...topTenImpressions,
          ...[topTenTotal, topTenPercentage, otherCount, otherTotal, otherPercentage].map(
            value => ({
              value,
              date
            })
          )
        ];
      }
    );

    // rotating data to allow rendering data by row
    const rotateMatrix = matrix => matrix[0].map((column, index) => matrix.map(row => row[index]));

    if (formattedData.length) {
      const rotatedData = rotateMatrix(formattedData);
      const labeledData = rows.map((row, i) => {
        const rotatedRow = rotatedData[i] || [];

        return [row, ...rotatedRow];
      });

      // currently rendering data with an array instead of an object with keys,
      // may change if required
      setDateHeaders(data.map(({ date }) => moment.utc(date).format('MMM D')));

      const topPosts = labeledData.splice(0, 10);
      const totals = labeledData;

      setTopPostsData(topPosts);
      setTotalsData(totals);
    } else {
      setDateHeaders([]);
      setTopPostsData([]);
      setTotalsData([]);
    }
  };

  const handleTableCellClick = (e, { postId, linkTokenId }) => {
    setHoveredPostId(postId);
    setPopoverAnchorEl(e.currentTarget);
    setLinkToken(linkTokenId);
  };

  const closeGraphHover = () => {
    setHoveredPostId(null);
    setPopoverAnchorEl(null);
    setLinkToken(null);
    setHoveredCellKey(null);
  };

  useEffect(() => {
    if (queryData?.topActivePostTable) {
      formatTableData(queryData.topActivePostTable);
    }
  }, [queryData]);

  const formatValue = value =>
    typeof value === 'number'
      ? Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(value)
      : value;

  const TopPostsRow = ([title, ...topPosts]) => (
    <TableRow key={title}>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      {topPosts.map(topPost => (
        <TableCell className={classes.tableCell} key={title + topPost?.date} align="right">
          <Box
            className={`${classes.tableContent} ${
              topPost?.postId === hoveredPostId ? classes.hoveredPostId : ''
            } ${hoveredCellKey === title + topPost?.date ? classes.hoveredCell : ''}
            `}
            onMouseEnter={() => {
              if (topPost?.postId && !popoverAnchorEl) {
                setHoveredPostId(topPost.postId);
                setHoveredCellKey(title + topPost.date);
              }
            }}
            onClick={e => {
              if (topPost?.postId && !popoverAnchorEl) {
                handleTableCellClick(e, {
                  postId: topPost?.postId,
                  linkTokenId: topPost?.linkTokenId
                });
              }
            }}
          >
            {topPost?.value ? formatValue(topPost.value) : 0}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );

  const TotalsRow = ([title, ...totals]) => (
    <TableRow key={title}>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      {totals.map(total => (
        <TableCell key={title + total?.date} align="right">
          {total?.value ? formatValue(total.value) : '-'}
        </TableCell>
      ))}
    </TableRow>
  );

  const DatePagination = () => {
    const selectedStartDate = moment(selectedDateRange.start).startOf('d');
    const selectedEndDate = moment(selectedDateRange.end).startOf('d');
    const currentStartDate = moment(dates.start).startOf('d');
    const currentEndDate = moment(dates.end).startOf('d');

    const isSameStartDate = selectedStartDate.isSame(currentStartDate);
    const isBeforeStartDate = selectedStartDate.isBefore(currentStartDate);
    const isSameEndDate = selectedEndDate.isSame(currentEndDate);
    const isAfterEndDate = selectedEndDate.isAfter(currentEndDate);

    const setFirstDate = () => {
      const end = currentStartDate
        .clone()
        .add(13, 'days')
        .isAfter(currentEndDate)
        ? currentEndDate
        : currentStartDate.clone().add(13, 'days');

      setSelectedDateRange({
        start: currentStartDate,
        end
      });
    };

    const setPrevDate = () => {
      const end = selectedStartDate.clone().subtract(1, 'days');
      const start = end
        .clone()
        .subtract(13, 'days')
        .isBefore(currentStartDate)
        ? currentStartDate
        : end.clone().subtract(13, 'days');

      setSelectedDateRange({ start, end });
    };

    const setNextDate = () => {
      const start = selectedEndDate.clone().add(1, 'days');
      const end = start
        .clone()
        .add(13, 'days')
        .isAfter(currentEndDate)
        ? currentEndDate
        : start.clone().add(13, 'days');

      setSelectedDateRange({ start, end });
    };

    const setLastDate = () => {
      const start = currentEndDate
        .clone()
        .subtract(13, 'days')
        .isBefore(currentStartDate)
        ? currentStartDate
        : currentEndDate.clone().subtract(13, 'days');

      setSelectedDateRange({
        start,
        end: currentEndDate
      });
    };

    return (
      <Box
        className={classes.paginationDates}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          onClick={() => setFirstDate()}
          disabled={isSameStartDate || isBeforeStartDate}
          aria-label="First Page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={() => setPrevDate()}
          disabled={isSameStartDate || isBeforeStartDate}
          aria-label="Previous Page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <span>{formatDateRange(selectedDateRange.start, selectedDateRange.end)}</span>
        <IconButton
          onClick={() => setNextDate()}
          disabled={isSameEndDate || isAfterEndDate}
          aria-label="Next Page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={() => setLastDate()}
          disabled={isSameEndDate || isAfterEndDate}
          aria-label="Last Page"
        >
          <LastPage />
        </IconButton>
      </Box>
    );
  };

  if (error) return <AlbError error={error} />;

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.title}>
        <Tabs
          classes={{
            indicator: classes.tabsIndicator
          }}
          value={0}
        >
          <Tab
            classes={{
              root: classes.titleText
            }}
            label={
              <TabWithTooltip
                tabTitle="Age of Top 10 Posts by Imp. Activity In That Day"
                tooltipTitle={getToolTipTitleValue('ageTop10PostByImp')}
              />
            }
          />
        </Tabs>
        {/* <Grid item>Age of Top 10 Posts by Imp. Activity In That Day</Grid> */}
        <Grid item className={classes.dates}>
          {formatDateRange(dates.start, dates.end)}
        </Grid>
      </Grid>
      {loading && <AlbLoading />}
      {!loading && !!queryData?.topActivePostTable?.length && (
        <MuiThemeProvider theme={tableTheme}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>DATE / TIME</TableCell>
                {dateHeaders.map(date => (
                  <TableCell key={date} align="center">
                    {date}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {topPostsData.map(TopPostsRow)}
              {totalsData.map(TotalsRow)}
            </TableBody>
          </Table>
          {!!popoverAnchorEl && (
            <GraphHover
              open={!!popoverAnchorEl}
              anchorEl={popoverAnchorEl}
              startDate={dates.start}
              endDate={dates.end}
              eventId={hoveredPostId}
              linkTokenId={linkToken}
              withLTV
              clickBehavior
              close={closeGraphHover}
            />
          )}
        </MuiThemeProvider>
      )}
      <DatePagination />
    </>
  );
};

TopTenPostsTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default TopTenPostsTable;
