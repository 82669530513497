import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import colors, { topPostsColors } from 'util/colors';
import { uniqBy } from 'lodash';

export const Gradient = props => {
  const { gradient } = props;

  const useStyles = makeStyles({
    footer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px'
    },
    legend: {
      display: 'flex',
      alignItems: 'center',

      '& > span': {
        fontSize: '14px',
        fontWeight: 500,
        color: '#979797'
      }
    },
    gradient: {
      height: '8px',
      width: '25px'
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <span className={classes.legend}>
        <span>Low</span>
        &nbsp;&nbsp;&nbsp;
        {gradient.map(color => (
          <span key={color} className={classes.gradient} style={{ backgroundColor: color }} />
        ))}
        &nbsp;&nbsp;&nbsp;
        <span>High</span>
      </span>
    </div>
  );
};

Gradient.propTypes = {
  gradient: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const AgeGradient = props => {
  const { ranges } = props;

  const useStyles = makeStyles({
    footer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginTop: '20px'
    },
    legend: {
      display: 'flex',

      '& > span': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 500,
        color: '#979797',
        whiteSpace: 'nowrap'
      }
    },
    range: {
      marginRight: '10px'
    },
    gradient: {
      height: '12px',
      width: '40px',
      marginRight: '6px'
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <span className={classes.legend}>
        {ranges.map(({ ageRange, backgroundColor }) => (
          <span className={classes.range} key={ageRange}>
            <span className={classes.gradient} style={{ backgroundColor }} />
            <span>{ageRange}</span>
          </span>
        ))}
      </span>
    </div>
  );
};

AgeGradient.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      ageRange: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired
    })
  ).isRequired
};

export const Palette = props => {
  const { legend, hiddenDataSets, setHiddenDataSets, sm } = props;
  const { title, palette } = legend;

  const useStyles = makeStyles({
    footer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px'
    },
    legend: {
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      gridGap: `${sm ? 20 : 30}px`,

      '& > span': {
        fontSize: `${sm ? 12 : 14}px`,
        fontWeight: 500,
        color: '#6F6F6F'
      }
    },
    label: {
      ...(!!setHiddenDataSets && { cursor: 'pointer' }),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',

      '& > span': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    icon: {
      width: '17px',
      height: '13px',
      marginLeft: '-3px',
      marginRight: '5px'
    },
    gradient: {
      height: `${sm ? 15 : 18}px`,
      width: `${sm ? 15 : 18}px`,
      borderRadius: '3px',
      marginRight: `${sm ? 8 : 10}px`
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <span className={classes.legend}>
        {title && <span>{title}</span>}
        {palette.map(({ key, icon, label, color, total }) => (
          <span
            aria-hidden="true"
            className={classes.label}
            key={key || label}
            onClick={() => {
              setHiddenDataSets(prevState => ({
                ...prevState,
                [key]: !prevState[key]
              }));
            }}
          >
            <span>
              <span className={classes.gradient} style={{ backgroundColor: color }} />
              {icon && <img className={classes.icon} src={icon} alt="icon" />}
              <span style={{ textDecoration: hiddenDataSets[key] ? 'line-through' : 'initial' }}>
                {label}
              </span>
            </span>
            {total && (
              <span>
                <span className={classes.gradient} />
                <span style={{ color }}>{total}</span>
              </span>
            )}
          </span>
        ))}
      </span>
    </div>
  );
};

Palette.propTypes = {
  legend: PropTypes.shape({
    title: PropTypes.string,
    palette: PropTypes.arrayOf(PropTypes.shape()).isRequired
  }).isRequired,
  hiddenDataSets: PropTypes.shape(),
  setHiddenDataSets: PropTypes.func,
  sm: PropTypes.bool
};

Palette.defaultProps = {
  hiddenDataSets: {},
  setHiddenDataSets: null,
  sm: false
};

export const AccountsPalette = props => {
  const { accounts } = props;

  const useStyles = makeStyles({
    footer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gridGap: '30px',
      marginTop: '20px'
    },
    account: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    gradient: {
      height: '18px',
      width: '18px',
      borderRadius: '3px',
      marginRight: '10px'
    },
    labels: {
      display: 'flex',
      flexDirection: 'column'
    },
    handle: {
      fontSize: '14px',
      fontWeight: 600,
      color: colors.purple
    },
    platform: {
      fontSize: '14px',
      fontWeight: 500,
      color: colors.doveGray
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.footer}>
      {accounts.map((account, i) => {
        const { id, platform, remote_name: handle } = account;
        const { displayName } = platform;
        const backgroundColor = topPostsColors[i % topPostsColors.length];

        return (
          <span className={classes.account} key={`${displayName}-${handle}-${id}`}>
            <span className={classes.gradient} style={{ backgroundColor }} />
            <span className={classes.labels}>
              <Typography className={classes.handle}>{handle}</Typography>
              <Typography className={classes.platform}>{displayName}</Typography>
            </span>
          </span>
        );
      })}
    </div>
  );
};

AccountsPalette.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      platform: PropTypes.shape({
        displayName: PropTypes.string.isRequired
      }).isRequired,
      remote_name: PropTypes.string.isRequired
    })
  ).isRequired
};

export const EventsLegend = ({ max, ranges }) => {
  const calculatedRanges = uniqBy(
    ranges
      .map(({ color, percentage }) => ({
        color,
        percentage,
        range: Math.round(max * (percentage / 100)) || 1
      }))
      .reverse(),
    'range'
  );

  const useStyles = makeStyles({
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '15px'
    },
    legend: {
      display: 'grid',
      gridTemplateColumns: `repeat(${calculatedRanges.length}, 1fr)`,
      gridGap: '30px'
    },
    label: {
      fontFamily: 'Poppins',
      fontSize: '15px',
      fontWeight: 500,
      marginBottom: '15px'
    },
    range: {
      display: 'flex'
    },
    square: {
      height: '18px',
      width: '18px',
      borderRadius: '3px'
    },
    text: {
      color: '#6F6F6F',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      paddingLeft: '10px'
    }
  });
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <span className={classes.label}>Number of Events Per Day</span>
      <span className={classes.legend}>
        {[...calculatedRanges].reverse().map(({ color, percentage }) => (
          <span key={percentage} className={classes.range}>
            <div className={classes.square} style={{ backgroundColor: color }} />
            <span className={classes.text}>{Math.round(max * (percentage / 100)) || 1}+</span>
          </span>
        ))}
      </span>
    </div>
  );
};

EventsLegend.propTypes = {
  max: PropTypes.number.isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      percentage: PropTypes.number.isRequired
    })
  ).isRequired
};
