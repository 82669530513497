import React from 'react';

const DashboardUnderstandDrawerContext = React.createContext({
  isDrawerOpen: false,
  startDate: null,
  endDate: null,
  isSwitchChecked: null,
  event: {},
  toggleDrawer: () => {}
});

export default DashboardUnderstandDrawerContext;
