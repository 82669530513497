const styles = {
  card: {
    position: 'relative',
    padding: '19px 24px',
    height: '400px',
    borderRadius: '3px',
    fontSize: '15px',
    color: '#0d1a3a',
    fontWeight: 500,
    fontFamily: "'Poppins', sans-serif",
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.05,1.05)',
      fontWeight: 600
    }
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  body: {
    flex: '1 1 auto'
  },
  text: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#0A1734',
    margin: '0px 12px'
  },
  settingsButton: {
    position: 'absolute',
    top: '16px',
    right: '16px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '51px',
    margin: '-19px -24px',
    backgroundColor: '#091733',
    color: '#FFFFFF'
  },
  beta: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#6F6F6F',
    marginLeft: '5px'
  }
};

export default styles;
