import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import AnalyticsReportPicker from 'components/AnalyticsReportPicker';
import { goToRouteReplace } from 'util/routesHelpers';
import handleGraphQLError from 'util/error';
import { SHARE_OF_VOICE_REPORTS } from 'gql/shareOfVoice';
import { ShareOfVoiceAnalyticsPath } from 'util/paths';

/**
 * @method
 * @summary This component renders the report dropdown for the Competitive Intelligence report title section
 * @name ShareOfVoiceReportPicker
 * @param {Object} history - The react router history object
 * @param {String} currentReportId - The ID of the current Competitive Intelligence report
 * @return {Object} - wrapper around @see AnalyticsReportPicker
 */

const ShareOfVoiceReportPicker = ({ currentReportId, setSelectedReportId }) => {
  const { loading, error, data } = useQuery(SHARE_OF_VOICE_REPORTS, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const handleReportSelect = e => {
    setSelectedReportId(e.target.value);
    goToRouteReplace(`${ShareOfVoiceAnalyticsPath}/${e.target.value}`);
  };

  return (
    <AnalyticsReportPicker
      onSelect={handleReportSelect}
      selectedReport={currentReportId}
      reports={data?.shareOfVoiceReports}
      loading={loading}
    />
  );
};

ShareOfVoiceReportPicker.propTypes = {
  currentReportId: PropTypes.string.isRequired,
  setSelectedReportId: PropTypes.func.isRequired
};

export default ShareOfVoiceReportPicker;
