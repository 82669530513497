import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getIconSrc } from 'components/AnalyticsThirdPartyMedia/util';
import {
  PODCAST,
  BROADCAST,
  BROADCAST_AND_RADIO,
  WEB
} from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  nameText: {
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '20px',
    marginLeft: '20px',
    marginRight: '10px',
    minWidth: '200px'
  },
  starIcon: {
    color: '#FBBB45'
  }
});

/**
 * @method
 * @summary This component renders the report name column for multiple tables and displays a star for default
 * @name ThirdPartyMediaReportName
 * @param {Object} props - React props passed to this component
 * @param {string} props.mediaType - A string value for the report type
 * @param {string} props.name - A string value for the report name
 * @return {Object} - React JSX
 */
const ThirdPartyMediaReportName = props => {
  const { mediaType, name } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {mediaType === BROADCAST && (
        <img src={getIconSrc(BROADCAST_AND_RADIO)} alt="Television icon" width={45} height={13} />
      )}
      {mediaType === PODCAST && (
        <img src={getIconSrc(mediaType)} alt="Podcast icon" width={10} height={14} />
      )}
      {mediaType === WEB && <img src={getIconSrc(mediaType)} alt="Web icon" width={15} />}
      <Typography className={classes.nameText}>{name}</Typography>
    </div>
  );
};

ThirdPartyMediaReportName.propTypes = {
  mediaType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see ThirdPartyMediaReportName
 * @name customNameRender
 * @param {Object} name - Object passed from @see MUIDataTable
 * @return {Object} - React JSX
 */
const customNameRender = name => <ThirdPartyMediaReportName {...name} />;

export default customNameRender;
