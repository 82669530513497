import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, Typography } from '@material-ui/core';
import { AlbTooltip } from 'components/AlbTooltip';
import FeatureModal from './FeatureModal';
import styles from './styles';

const style = makeStyles(styles);

const AnalyticsCards = props => {
  const { cardsToRender, disabledCardsToRender, handleRouting } = props;
  const classes = style();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState(null);

  return (
    <>
      <Grid container spacing={2}>
        {cardsToRender.map(card => (
          <Grid key={card.title} item xs={12} sm={6} md={4} lg={3}>
            <Card
              onClick={() => {
                handleRouting(card.path);
              }}
              className={classes.card}
            >
              <div className={classes.container}>
                <Grid container alignItems="center">
                  <Typography style={{ display: 'flex', marginRight: 10 }}>
                    {card.title}
                    {card.beta && <span className={classes.beta}>beta</span>}
                  </Typography>
                  <AlbTooltip title={card.tooltipTitle} />
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.body}
                  wrap="nowrap"
                >
                  <img src={card.images[0]} width={160} height={120} alt={card.title} />
                </Grid>
                {card.showBanner && <div className={classes.footer}>{card.showBanner.footer}</div>}
              </div>
            </Card>
          </Grid>
        ))}
        {disabledCardsToRender.map(card => {
          return (
            <Grid key={card.title} item xs={12} sm={6} md={4} lg={3}>
              <Card
                onClick={() => {
                  setModal({ title: card.title, text: card.showIfDisabled.text });
                  setIsModalOpen(true);
                }}
                className={classes.card}
              >
                <div className={classes.container}>
                  <Grid container alignItems="center">
                    <Typography style={{ marginRight: 10 }}>{card.title}</Typography>
                    <AlbTooltip title={card.tooltipTitle} />
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.body}
                    wrap="nowrap"
                  >
                    <img src={card.images[0]} width={160} height={120} alt={card.title} />
                  </Grid>
                  <div className={classes.footer}>{card.showIfDisabled.footer}</div>
                </div>
              </Card>
            </Grid>
          );
        })}
        {modal && (
          <FeatureModal
            title={modal.title}
            text={modal.text}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </Grid>
    </>
  );
};

const CardType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string.isRequired
});

AnalyticsCards.propTypes = {
  handleRouting: PropTypes.func.isRequired,
  cardsToRender: PropTypes.arrayOf(CardType).isRequired,
  disabledCardsToRender: PropTypes.arrayOf(CardType)
};

AnalyticsCards.defaultProps = {
  disabledCardsToRender: []
};

export default AnalyticsCards;
