import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, ListItemIcon, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { useLazyQuery } from '@apollo/client';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import GraphHeader from 'components/PostDrawerView/GraphHeader';
import DetectedEventTableGraph from 'components/DetectedEventsTable/DetectedEventTableGraph';
import { DETECTED_EVENT } from 'gql/detected_event';
import { NARRATIVE_EVENT_TYPES } from 'util/detectedEvents';

const useStyles = makeStyles({
  graphHeaderContainer: {
    marginBottom: '24px'
  },
  collapseIconOverride: {
    minWidth: 'unset'
  }
});

/**
 * @method
 * @summary The graph section of @DetectedEventDrawerView
 * @name DetectedEventDrawerGraph
 * @param { Object } detectedEvent - the detected event object
 * @param { Object } graphDates - the start and end date of the event in utc, used to query for timeseries data in the graph.
 * @return {Object} - React JSX
 */

const DetectedEventDrawerGraph = props => {
  const { detectedEvent, graphDates } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(0); // defaulted to +/- 3 days
  const classes = useStyles();

  const startDate = moment.utc(detectedEvent.event_start_date);
  const endDate = moment.utc(detectedEvent.event_end_date).endOf('day');
  const dateRangeOptions = [
    {
      label: '+/- 3D',
      range: {
        startDate: startDate.clone().subtract(3, 'd'),
        endDate: endDate.clone().add(3, 'd')
      }
    },
    {
      label: '+/- 7D',
      range: {
        startDate: startDate.clone().subtract(7, 'd'),
        endDate: endDate.clone().add(7, 'd')
      }
    },
    {
      label: '+/- 30D',
      range: {
        startDate: startDate.clone().subtract(30, 'd'),
        endDate: endDate.clone().add(30, 'd')
      }
    }
  ];

  const [updatedDateRangeOptions, setUpdatedDateRangeOptions] = useState(dateRangeOptions);
  // force load state for narrative events until lifetime air dates are queried
  const [queryLoading, setQueryLoading] = useState(
    NARRATIVE_EVENT_TYPES.includes(detectedEvent.event_type)
  );

  useEffect(() => {
    if (graphDates) {
      setUpdatedDateRangeOptions([
        ...dateRangeOptions,
        {
          label: 'LIFETIME',
          range: {
            startDate: moment.utc(graphDates.first_air_date),
            endDate: moment.utc(graphDates.last_air_date).endOf('day')
          }
        }
      ]);
      setSelectedDateRange(3);
      setQueryLoading(false);
    }
  }, [graphDates]);

  const [getDetectedEvent, { loading, error, data }] = useLazyQuery(DETECTED_EVENT);

  useEffect(() => {
    if (detectedEvent?.id) {
      getDetectedEvent({
        variables: {
          id: detectedEvent.id,
          startDateGraph: updatedDateRangeOptions[selectedDateRange].range.startDate,
          endDateGraph: updatedDateRangeOptions[selectedDateRange].range.endDate
        }
      });
    }
  }, [selectedDateRange]);

  return (
    <Grid container direction="column">
      <Grid container className={classes.graphHeaderContainer}>
        <Grid item onClick={() => setIsCollapsed(!isCollapsed)}>
          <ListItemIcon classes={{ root: classes.collapseIconOverride }}>
            {isCollapsed ? <Right /> : <Down />}
          </ListItemIcon>
        </Grid>
        <GraphHeader
          label="" // TODO Replace with dynamic label based detected event type
          isCollapsed={isCollapsed}
          dateRangeOptions={updatedDateRangeOptions}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          hasGraph
          tooltipText={getToolTipTitleValue('totalUsersDetail')}
        />
      </Grid>
      <Grid item>
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          <div>
            {(loading || queryLoading) && <AlbLoading />}
            {error && <AlbError error={error} />}
            {data?.detectedEvent && !queryLoading && (
              <DetectedEventTableGraph
                id={detectedEvent.id}
                dateRange={{ range: { startDate, endDate } }}
                selectedDate={startDate} // The middle of the graph that gets highlighted
                event={{ type: detectedEvent.event_type }}
                originalObject={data.detectedEvent}
                isFullGraphDisplay
              />
            )}
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
};

DetectedEventDrawerGraph.propTypes = {
  detectedEvent: PropTypes.shape().isRequired,
  graphDates: PropTypes.shape()
};

DetectedEventDrawerGraph.defaultProps = {
  graphDates: null
};

export default DetectedEventDrawerGraph;
