import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { demographicsWidget } from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import BoxContentWidget from './BoxContent/BoxContentWidget';

const AnalyticsDemographics = props => {
  const { accounts, dates } = props;
  return (
    <>
      <Grid
        container
        style={{
          width: 'unset',
          flexDirection: 'column',
          margin: '25px 15px'
        }}
      >
        {/* 
          widgets display a paired graph and table 
          metrics queried by each section of the widget are assigned in AnalyticsSocialConsts          
        */}
        <BoxContentWidget
          tabData={demographicsWidget}
          accounts={accounts}
          dates={dates}
          prefix="sb"
        />
      </Grid>
    </>
  );
};

AnalyticsDemographics.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default AnalyticsDemographics;
