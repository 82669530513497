import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { CONTAINER } from 'gql/container';
import setPageTitle from 'util/titles';
import { ManageOrganizationPath } from 'util/paths';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import HasAnyPriv from 'components/HasAnyPriv';
import AlembicPageHeader from 'components/AlembicPageHeader';
import AnalyticsBox from 'components/AnalyticsBox';
import ValuationModel from 'components/ValuationModel';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContainerAvatarAndNameForm from './ContainerAvatarAndNameForm';
import ContainerEventCorrelationForm from './ContainerEventCorrelationForm';

const ContainerEdit = props => {
  const { currentContainer } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const containerId = urlParams.get('id') || currentContainer.id;

  const { loading, error, data, refetch } = useQuery(CONTAINER, {
    variables: {
      id: containerId
    }
  });

  useEffect(() => {
    setPageTitle('Edit Container');
  }, []);

  if (loading) return <AlbLoading />;
  if (error) return <AlbError error={error} refetch={refetch} />;

  return (
    <HasAnyPriv privs={['ORG:UPDATE']} showAlert>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          <AlembicPageHeader
            backButton
            backRoute={ManageOrganizationPath}
            pageTitle="Edit Container"
          />
          <AnalyticsBox
            noMargin
            tabs={['Container Avatar & Name', 'Valuation Model', 'Event Correlation']}
            onChange={() => {}}
          >
            <ContainerAvatarAndNameForm
              containerData={data?.container}
              refetchContainerData={refetch}
            />
            <ValuationModel containerData={data?.container} />
            <ContainerEventCorrelationForm containerData={data?.container} />
          </AnalyticsBox>
        </Grid>
      </Grid>
    </HasAnyPriv>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    currentContainer: state.auth.currentContainer,
    isAuthenticated: state.auth.isAuthenticated
  };
};

ContainerEdit.propTypes = {
  currentContainer: PropTypes.shape().isRequired
};

export default connect(mapStateToProps)(ContainerEdit);
