import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    background: 'rgba(0, 0, 0, 0.8)',
    borderRadius: '3px',
    padding: '15px'
  }
});

/**
 * Truncates a url above 40 characters in length, and adds a tooltip displaying the full line
 * Not necessarily only for urls, but for UX/truncation purposes
 * @param {Object} props - React props passed to this component
 * @param {string} props.url - the url to be truncated
 * @returns {Object} - React JSX object with a tooltip wrapped text string
 */
const TruncateUrl = props => {
  const { url } = props;
  const classes = useStyles();

  const truncateUrl = url?.length > 40;
  const formattedUrl = truncateUrl ? url.slice(0, 37).concat('...') : url;

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={truncateUrl ? <span>{url}</span> : ''}
      placement="top"
    >
      <strong>{formattedUrl}</strong>
    </Tooltip>
  );
};

TruncateUrl.propTypes = {
  url: PropTypes.string.isRequired
};

// exported as a simple function for convenience
const truncateUtil = url => <TruncateUrl url={url} />;

export default truncateUtil;
