import React from 'react';
import PropTypes from 'prop-types';

import ForecastGraph from 'components/ForecastGraph';

const SalesforceDealSize = props => {
  const { account } = props;

  return (
    <ForecastGraph
      accounts={[account]}
      hideTitle
      reportTitle=""
      metric="salesforceDealSize"
      chartTitle="4 Years History with Added 1 Year Forecast"
      emptyPredictions="There are no predictions to display at this time"
      yAxesLabel="Avg Deal Size"
    />
  );
};

SalesforceDealSize.propTypes = {
  account: PropTypes.shape()
};

SalesforceDealSize.defaultProps = {
  account: null
};

export default SalesforceDealSize;
