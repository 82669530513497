import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import { Edit, Delete, FileCopy } from '@material-ui/icons';
import PrivilegeTree from 'components/SecurityRoles/PrivilegeTree';

const styles = makeStyles({
  hidden: {
    display: 'none'
  }
});

const PrivilegeList = props => {
  const {
    currentTab,
    privilegeList,
    selectedRole,
    selectedPrivileges,
    onPrivilegeSelect,
    handleConfirm,
    handleOpenUpdate,
    handleOpenDuplicate,
    handleOpenDelete
  } = props;

  const classes = styles();

  const [collapse, setCollapse] = useState({ 0: true });
  const [indeterminatePrivileges, setIndeterminatePrivileges] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const createPrivilegeTree = (list, id = null) =>
    list
      .filter(item => item.parent_id === id)
      .map(item => ({ ...item, children: createPrivilegeTree(list, item.id) }));

  const createParentLookup = list => list.reverse().map(item => [item.id, item.parent_id]);

  // map out the privilege tree for initial render
  const privilegeTree = useRef(createPrivilegeTree(privilegeList)).current;
  const privilegeParentLookup = useRef(createParentLookup(privilegeList)).current;

  useEffect(() => {
    setIsDisabled(!selectedRole.role_type || selectedRole.role_type === 'S' || !currentTab);
  }, [selectedRole, currentTab]);

  useEffect(() => {
    const indeterminate = {};

    privilegeParentLookup.forEach(([priv, parent]) => {
      if (selectedPrivileges[priv] || indeterminate[priv]) {
        indeterminate[parent] = true;
      }
    });

    setIndeterminatePrivileges(indeterminate);
  }, [selectedPrivileges]);

  return (
    <Grid container wrap="nowrap" direction="column">
      <Grid container wrap="nowrap">
        <Grid xs={9} container item direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h1">
              {selectedRole.name || (
                <>
                  <i className="fas fa-hand-point-left" style={{ marginRight: 20 }} />
                  Select a Role
                </>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{selectedRole.description || ''}</Typography>
          </Grid>
        </Grid>
        <Grid xs={3} container item alignItems="flex-start" justifyContent="flex-end" wrap="nowrap">
          <Tooltip title="Edit Role name/description">
            <div>
              <IconButton
                className={isDisabled ? classes.hidden : ''}
                onClick={() => handleOpenUpdate()}
              >
                <Edit />
              </IconButton>
            </div>
          </Tooltip>
          {/* only duplication is allowed for system roles */}
          <Tooltip title="Duplicate Role">
            <div>
              <IconButton
                className={isDisabled && selectedRole.role_type !== 'S' ? classes.hidden : ''}
                onClick={() => handleOpenDuplicate()}
              >
                <FileCopy />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Delete Role">
            <div>
              <IconButton
                className={isDisabled ? classes.hidden : ''}
                onClick={() => handleOpenDelete()}
              >
                <Delete />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <PrivilegeTree
        privs={privilegeTree}
        isDisabled={isDisabled}
        selectedPrivileges={selectedPrivileges}
        indeterminatePrivileges={indeterminatePrivileges}
        onPrivilegeSelect={onPrivilegeSelect}
        setCollapse={setCollapse}
        collapse={collapse}
      />
      <Grid item style={isDisabled ? { display: 'none' } : {}}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ width: '100%' }}
          onClick={() => handleConfirm()}
        >
          Update Role Privileges
        </Button>
      </Grid>
    </Grid>
  );
};

PrivilegeList.propTypes = {
  currentTab: PropTypes.number.isRequired,
  selectedRole: PropTypes.shape(),
  privilegeList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedPrivileges: PropTypes.shape(),
  onPrivilegeSelect: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleOpenUpdate: PropTypes.func.isRequired,
  handleOpenDuplicate: PropTypes.func.isRequired,
  handleOpenDelete: PropTypes.func.isRequired
};

PrivilegeList.defaultProps = {
  selectedRole: {},
  selectedPrivileges: {}
};

export default PrivilegeList;
