import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { linePalette } from 'components/AnalyticsSocial/AnalyticsGraphConsts';

const useStyles = makeStyles({
  graph: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    width: '200px'
  }
});

/**
 * @method
 * @summary Renders the sparkline graph displaying impressions in the @see ResurgenceByPost table
 * @name ResurgenceLine
 * @param {Object} props - React props passed to this component
 * @param {String} [props.data] - The timeseries resurgence graph data
 * @return {Object} - React JSX
 */
const ResurgenceLine = props => {
  const { data } = props;
  const classes = useStyles();

  const [graphData, setGraphData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (data.length) {
      const labels = [];

      const datasets = [
        {
          label: '',
          type: 'line',
          data: data.map(({ x, y }) => {
            labels.push(moment(x).format('M/D/YY'));
            return y;
          }),
          ...linePalette,
          fill: false,
          lineTension: 0,
          pointRadius: 0
        }
      ];

      setGraphData({ labels, datasets });
    }
  }, [data]);

  const graphOptions = {
    legend: { display: false },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'label',
      intersect: false,
      callbacks: {
        label(tooltipItem, chartData) {
          const valueText = Math.round(
            chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          ).toLocaleString();

          return valueText;
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: { display: false },
          gridLines: { display: false }
        }
      ],
      yAxes: [
        {
          ticks: { display: false },
          gridLines: { display: false }
        }
      ]
    }
  };

  return (
    <div className={classes.graph}>
      <Bar data={graphData} options={graphOptions} />
    </div>
  );
};

ResurgenceLine.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default ResurgenceLine;
