import { gql } from '@apollo/client';

export const SEARCH_SEGMENTS = gql`
  query searchSegments($linktoken_id: ID!, $query: String!) {
    searchSegments(linktoken_id: $linktoken_id, query: $query) {
      id
      name
      description
      segment_id
    }
  }
`;

export const CREATE_ADOBE_ANALYTICS_SEGMENT_LINK = gql`
  mutation($linktoken_id: ID!, $segment_id: ID!) {
    createAdobeAnalyticsSegmentLink(linktoken_id: $linktoken_id, segment_id: $segment_id) {
      result
      token {
        remote_id
        remote_name
      }
    }
  }
`;

export const GET_CHILD_AA_SEGMENTS = gql`
  query getChildAASegments($linktoken_id: ID!) {
    getChildAASegments(linktoken_id: $linktoken_id) {
      remote_id
      remote_name
    }
  }
`;
