import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FlowMetricDistribution from './Graphs/FlowMetricDistribution';
import AgeRangePost from './Graphs/AgeRangePost';
import TopPostsOver30 from './Graphs/TopPostsOver30';
import ImpactVsAge from './Graphs/ImpactVsAge';

const AnalyticsFlow = props => {
  const { accounts, dates } = props;

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item sm={12} md={6}>
        <Grid container style={{ minHeight: '488px' }}>
          <FlowMetricDistribution accounts={accounts} dates={dates} />
        </Grid>
      </Grid>

      <Grid container item sm={12} md={6}>
        <Grid container>
          <AgeRangePost accounts={accounts} dates={dates} />
        </Grid>

        <Grid container>
          <TopPostsOver30 accounts={accounts} dates={dates} />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container>
          <ImpactVsAge accounts={accounts} dates={dates} />
        </Grid>
      </Grid>
    </Grid>
  );
};

AnalyticsFlow.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default AnalyticsFlow;
