import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Box from 'components/Box';
import Legend from 'components/Legend';
import { formatDateRange } from 'util/date';
import PublishedVsLongTailGraph from './PublishedVsLongTailGraph';
import PublishedVsLongTailTable from './PublishedVsLongTailTable';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    background: '#FFFFFF',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  tabOverRide: {
    fontSize: 18,
    fontWeight: 500
  },
  datesLabel: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
    color: '#0A1734'
  },
  datesText: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    color: '#6F6F6F',
    marginLeft: '4px',
    marginRight: '24px'
  }
}));

const METRIC_MAP = {
  Impressions: 'alembicImpressions',
  Engagement: 'alembicEngagement',
  Shares: 'alembicShares'
};

const PublishedVsLongTailGraphAndTable = props => {
  const { selectedAccounts, selectedDates } = props;
  const classes = useStyles();
  const [activeGraphTab, setActiveGraphTab] = useState(0);
  const graphTabs = ['Impressions', 'Engagement', 'Shares'];

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs
          value={activeGraphTab}
          onChange={(event, newValue) => {
            setActiveGraphTab(newValue);
          }}
        >
          {graphTabs.map(tab => {
            return (
              <Tab
                key={tab}
                label={tab}
                classes={{
                  root: classes.tabOverRide
                }}
              />
            );
          })}
        </Tabs>
        <Box display="flex">
          <Typography className={classes.datesLabel}>Dates:</Typography>
          <Typography className={classes.datesText}>
            {formatDateRange(selectedDates.start, selectedDates.end)}
          </Typography>
        </Box>
      </Box>

      <Box px={30} pt={35} pb={30}>
        <PublishedVsLongTailGraph
          selectedMetric={METRIC_MAP[graphTabs[activeGraphTab]]}
          selectedAccounts={selectedAccounts}
          selectedDates={selectedDates}
        />
      </Box>

      <Box mb={30}>
        <Legend
          items={[
            { color: '#C40075', label: 'Published' },
            { color: '#32327D', label: 'Long Tail' }
          ]}
        />
      </Box>

      <div
        style={{
          height: '14px',
          background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
          mixBlendMode: 'multiply'
        }}
      />

      <Tabs value={0}>
        <Tab
          label="Posts Breakdown"
          classes={{
            root: classes.tabOverRide
          }}
        />
      </Tabs>

      <PublishedVsLongTailTable
        selectedMetric={METRIC_MAP[graphTabs[activeGraphTab]]}
        selectedAccounts={selectedAccounts}
        selectedDates={selectedDates}
      />
    </div>
  );
};

PublishedVsLongTailGraphAndTable.propTypes = {
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDates: PropTypes.shape().isRequired
};

export default PublishedVsLongTailGraphAndTable;
