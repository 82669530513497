/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { DASHBOARD_INTELLIGENCE_EVENTS } from 'gql/dashboardIntelligenceEvent';
import handleGraphQLError from 'util/error';
import AlbLoading from 'components/AlbLoading';
import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import colors from 'util/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import getValueAbbreviation from 'util/getValueAbbreviation';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import openDashboardDrawer from '../../util/drawerUtil';
import getEventFormatted from '../DetectedEventsFormatted';
import SocialLogo from '../SocialLogo/SocialLogo';

const useStyles = makeStyles({
  logoCell: {
    '& > img': {
      width: '18px',
      height: '18px'
    },
    verticalAlign: 'bottom'
  },
  toolTipHeadline: {
    fontSize: 13,
    color: '#32327D',
    fontWeight: 500
  },
  toolTipNone: {
    fontSize: 10,
    color: '#32327D',
    fontWeight: 500
  },
  event: {
    fontSize: 10,
    fontWeight: 400,
    color: '#000000',
    padding: 0,
    '& strong': {
      fontWeight: 600,
      color: '#32327D'
    }
  },
  toolTip: {
    position: 'fixed',
    minHeight: '20px',
    backgroundColor: 'white',
    fontSize: '12px',
    color: colors.darkBlue,
    boxShadow: '0px 0px 6px rgba(50, 50, 125, 0.2)',
    padding: '3px 10px',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    width: '458px',
    maxHeight: '117px',
    overflow: 'auto'
  },
  toolTipRow: {
    '& > td': {
      borderBottom: 'initial',
      padding: '0px 10px 10px 0px'
    },
    cursor: 'pointer'
  }
});

const ForecastGraphToolTipDetectedEvents = props => {
  const drawerContext = useContext(DashboardIntelligenceDrawerContext);
  const socialPostDrawerContext = useContext(PostDrawerViewContext);

  const classes = useStyles();
  const { detectedEventsDate, openToolTip, toolTipData, history } = props;

  const [events, setEvents] = useState([]);

  const { loading, error, data } = useQuery(DASHBOARD_INTELLIGENCE_EVENTS, {
    variables: {
      types: ['SOCIAL', 'WEB', 'CONVERSION'],
      startDate: moment(detectedEventsDate)
        .utc()
        .subtract(2, 'days')
        .toISOString(),
      endDate: moment(detectedEventsDate)
        .utc()
        .add(2, 'days')
        .toISOString()
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.dashboardIntelligenceEvents?.web) {
      const days = data?.dashboardIntelligenceEvents?.web.map(day => {
        return day.events;
      });

      const array = [].concat(...days);

      // detected events can span across days, so we need to remove duplicate ids
      const arrayDedupe = array.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

      setEvents(arrayDedupe);
    } else {
      setEvents([]);
    }
  }, [data]);

  return (
    <div
      className={classes.toolTip}
      style={{
        display: openToolTip ? 'block' : 'none',
        top: toolTipData?.top,
        left: toolTipData?.left
      }}
    >
      <h1 className={classes.toolTipHeadline}>
        Detected Events on This Period Change +/- 2 Days of{' '}
        {moment(detectedEventsDate)
          .utc()
          .format('MM/DD/YYYY')}
      </h1>

      {loading && (
        <Box className={classes.loadingEventsContainer}>
          <AlbLoading />
        </Box>
      )}

      {!loading && (
        <Table>
          <TableBody>
            {events.length === 0 && (
              <TableRow className={classes.toolTipRow}>
                <TableCell>
                  <Typography className={classes.toolTipNone}>
                    There are no detected events for this period.
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {events.map(event => (
              <TableRow
                className={classes.toolTipRow}
                key={event.id}
                onClick={() =>
                  openDashboardDrawer(event, drawerContext, socialPostDrawerContext, history)
                }
              >
                <TableCell className={classes.logoCell}>
                  <SocialLogo type={event.sourceType} />
                </TableCell>
                <TableCell width="60%">
                  <Box className={classes.event}>
                    {getEventFormatted(event, { hideDate: true })}
                  </Box>
                </TableCell>
                <TableCell align="right" width="20%">
                  <Typography className={classes.event}>
                    <strong>
                      {event.metricValue == null
                        ? '-'
                        : Math.round(event.metricValue).toLocaleString()}
                    </strong>{' '}
                    visits
                    {/* TODO this seems wrong as goals can be any form of a metric (TBD) */}
                  </Typography>
                </TableCell>
                {event?.valuation != null && (
                  <TableCell align="right" width="20%">
                    <Typography className={classes.event}>
                      <strong>
                        {getValueAbbreviation(event.valuation)
                          ? `$${getValueAbbreviation(event.valuation)}`
                          : null}
                      </strong>{' '}
                      value
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

ForecastGraphToolTipDetectedEvents.propTypes = {
  detectedEventsDate: PropTypes.string,
  openToolTip: PropTypes.bool,
  toolTipData: PropTypes.shape(),
  history: PropTypes.shape().isRequired
};

ForecastGraphToolTipDetectedEvents.defaultProps = {
  detectedEventsDate: null,
  openToolTip: false,
  toolTipData: null
};

export default withRouter(ForecastGraphToolTipDetectedEvents);
