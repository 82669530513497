import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { UPDATE_ARTICLE_SEARCH } from 'gql/articleSearch';
import { UPDATE_BROADCAST_SEARCH } from 'gql/broadcastSearch';
import { UPDATE_PODCAST_SEARCH } from 'gql/podcastSearch';
import Box from 'components/Box';
import { PODCAST, BROADCAST, WEB } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import modalStyles from './modalStyles';

const styles = makeStyles({
  ...modalStyles,
  reportName: {
    '& input': {
      '&:focus': {
        opacity: 1
      }
    }
  }
});

const RenameThirdPartyMediaReportModal = props => {
  const { isModalOpen, onChange, reportId, reportName, mediaType } = props;

  const [newReportName, setNewReportName] = useState(reportName);

  const classes = styles();

  const MUTATION = {
    [WEB]: UPDATE_ARTICLE_SEARCH,
    [BROADCAST]: UPDATE_BROADCAST_SEARCH,
    [PODCAST]: UPDATE_PODCAST_SEARCH
  }[mediaType];

  const [renameThirdPartyMediaReport, { error, data, loading }] = useMutation(MUTATION);

  useEffect(() => {
    ValidatorForm.addValidationRule('isMaxLength', value => value.length <= 255);
  }, []);

  useEffect(() => {
    if (data) {
      showToast('Report updated.', 'success');
      setNewReportName('');
      onChange({ isModalOpen: false, ...data });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const closeModal = e => {
    setNewReportName('');
    onChange({ isModalOpen: false });
    e.stopPropagation();
  };

  return (
    <>
      <Dialog maxWidth="sm" open={isModalOpen} onClose={closeModal}>
        <ValidatorForm
          onSubmit={() => {
            const name = newReportName.trim().replace(/\s+/g, ' ');
            renameThirdPartyMediaReport({ variables: { id: reportId, patch: { name } } });
          }}
        >
          <Box p={12}>
            <Grid container>
              <Grid item container>
                <Typography className={classes.title}>Rename 3rd Party Media Report</Typography>
              </Grid>
              <Grid item container>
                <Typography className={classes.label}>3rd Party Media Report Name</Typography>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item style={{ flex: 1 }}>
                  <TextValidator
                    autoFocus
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Report Name"
                    value={newReportName}
                    onChange={event => setNewReportName(event.target.value)}
                    validators={['required', 'isMaxLength']}
                    errorMessages={[
                      '* Required',
                      `Must not exceed 255 characters in length. Current length: ${newReportName.length}`
                    ]}
                    className={classes.reportName}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent="flex-end" className={classes.actions}>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </ValidatorForm>
      </Dialog>
    </>
  );
};

RenameThirdPartyMediaReportModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired
};

export default RenameThirdPartyMediaReportModal;
