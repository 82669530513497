import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlbError from 'components/AlbError';
import { AUDIENCE_SIZE } from 'gql/analytics';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import SocialLogo from 'components/SocialLogo';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';

const audienceSizeStyles = makeStyles({
  box: {
    flex: 1,
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  container: {
    padding: '24px',
    paddingBottom: '40px',
    textAlign: 'center'
  },
  total: {
    fontSize: '26px',
    fontWeight: '500',
    lineHeight: '39px',
    textTransform: 'uppercase',
    color: '#0A1734'
  },
  totalValue: {
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '45px',
    color: '#32327D',
    '@media print': {
      fontSize: '28px'
    }
  },
  platform: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: '#0A1734'
  },
  platformIcon: {
    marginBottom: '10px'
  },
  platformValue: {
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
    color: '#6F6F6F',
    '@media print': {
      fontSize: '15px'
    }
  }
});

const AudienceSize = () => {
  const classes = audienceSizeStyles();
  const [audienceSizeCounts, setAudienceSizeCounts] = useState({});
  const [totalAudience, setTotalAudience] = useState(0);
  const { data, error } = useQuery(AUDIENCE_SIZE, {
    fetchPolicy: 'network-only'
  });
  // The above should return something like
  /*
  {
  "data": {
    "linkedAccounts": [
      {
        "type": "FB_PROFILE",
        "lifetime_followers": 54470
      },
      {
        "type": "FB_PAGE",
        "lifetime_followers": 30131
      },
      {
        "type": "FB_PAGE",
        "lifetime_followers": 71572
      },
      {
        "type": "INSTAGRAM",
        "lifetime_followers": 83403
      },
      ...
    }
  */
  useEffect(() => {
    if (!data) return;
    let facebookCounts;
    let instagramCounts;
    let linkedInCounts;
    let twitterCounts;
    let youTubeCounts;
    let tikTokCounts;

    data.linkedAccounts.forEach(account => {
      if (account.lifetime_followers != null) {
        switch (account.type) {
          case LINKTOKEN_TYPE_ENUM.FB_PROFILE:
          case LINKTOKEN_TYPE_ENUM.FB_PAGE:
            if (!facebookCounts) facebookCounts = 0;
            facebookCounts += account.lifetime_followers;
            break;
          case LINKTOKEN_TYPE_ENUM.INSTAGRAM:
            if (!instagramCounts) instagramCounts = 0;
            instagramCounts += account.lifetime_followers;
            break;
          case LINKTOKEN_TYPE_ENUM.LINKEDIN_PROFILE:
          case LINKTOKEN_TYPE_ENUM.LINKEDIN_COMPANY:
            if (!linkedInCounts) linkedInCounts = 0;
            linkedInCounts += account.lifetime_followers;
            break;
          case LINKTOKEN_TYPE_ENUM.TWITTER:
            if (!twitterCounts) twitterCounts = 0;
            twitterCounts += account.lifetime_followers;
            break;
          case LINKTOKEN_TYPE_ENUM.YOUTUBE:
            if (!youTubeCounts) youTubeCounts = 0;
            youTubeCounts += account.lifetime_followers;
            break;
          case LINKTOKEN_TYPE_ENUM.TIKTOK:
            if (!tikTokCounts) tikTokCounts = 0;
            tikTokCounts += account.lifetime_followers;
            break;
          default:
            break;
        }
      }
    });

    setTotalAudience(
      (facebookCounts ?? 0) +
        (twitterCounts ?? 0) +
        (instagramCounts ?? 0) +
        (linkedInCounts ?? 0) +
        (youTubeCounts ?? 0) +
        (tikTokCounts ?? 0)
    );

    const object = {
      facebook: facebookCounts,
      twitter: twitterCounts,
      instagram: instagramCounts,
      linkedin: linkedInCounts,
      youtube: youTubeCounts,
      tiktok: tikTokCounts
    };

    setAudienceSizeCounts(object);
  }, [data]);

  if (error) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={error.message} />
      </Grid>
    );
  }
  return (
    <Grid container className={classes.box}>
      <BoxHeader tabs={['Social Audience Size (Followers / Subscribers)']} />

      <Grid container className={classes.container}>
        <Grid item xs={3}>
          <Typography className={classes.total}>Total</Typography>
          <Typography className={classes.totalValue}>
            {totalAudience?.toLocaleString() || '-'}
          </Typography>
        </Grid>

        <Grid item xs={9} container justifyContent="space-evenly">
          {Object.entries(audienceSizeCounts)?.map(([key, value]) => (
            <Grid item key={`sizeCount-${key}`}>
              <Grid item className={classes.platformIcon}>
                <SocialLogo width={24} height={24} type={key} />
              </Grid>
              <Typography className={classes.platform}>{key}</Typography>
              <Typography
                className={classes.platformValue}
                style={{ ...(value == null && { textAlign: 'center' }) }}
              >
                {value?.toLocaleString() || '-'}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AudienceSize;
