import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import colors from 'util/colors';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { SOCIAL_IMPACT_METRIC_TIMESERIES } from 'gql/analyticsSocialImpact';
import { getDatesForAnalytics } from 'util/date';
import { socialImpactTooltip } from 'components/AlbGraphs/CustomTooltipTemplates';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%'
  },
  point: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    backgroundColor: '#0A1734',
    borderRadius: '50%',
    transformStyle: 'preserve-3d'
  },
  hidden: {
    display: 'none !important'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const METRIC_MAP = {
  alembicImpressions: 'Impressions',
  alembicEngagement: 'Engagement',
  alembicShares: 'Shares'
};

const PublishedVsLongTailGraph = props => {
  const { selectedMetric, selectedAccounts, selectedDates } = props;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [formattedGraphData, setFormattedGraphData] = useState([]);
  const [suggestedMax, setSuggestedMax] = useState(0);
  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);
  const classes = useStyles();

  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const { loading, error, data } = useQuery(SOCIAL_IMPACT_METRIC_TIMESERIES, {
    variables: {
      metric: selectedMetric,
      linkTokens: selectedAccounts.map(({ id }) => id),
      startDate,
      endDate
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.socialImpactMetricTimeSeries?.published?.length > 0) {
      const tempGraphData = [];
      const tempMaxes = [];

      tempGraphData.push({
        id: 'published',
        timeseries: data.socialImpactMetricTimeSeries.published.map(dataPoint => {
          tempMaxes.push(dataPoint.y);
          return dataPoint;
        })
      });

      tempGraphData.push({
        id: 'longTail',
        timeseries: data.socialImpactMetricTimeSeries.longTail.map(dataPoint => {
          tempMaxes.push(dataPoint.y);
          return dataPoint;
        })
      });

      setFormattedGraphData(tempGraphData);
      setSuggestedMax(Math.ceil(Math.max(...tempMaxes) * 1.1));
    } else {
      setFormattedGraphData([]);
      setSuggestedMax(0);
    }
  }, [data]);

  const options = {
    // https://www.chartjs.org/docs/2.9.4/configuration/legend.html
    legend: {
      display: false
    },
    // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
    tooltips: {
      enabled: false,
      intersect: false,
      custom: tooltipModel => {
        const { offsetTop, offsetLeft } = canvasRef.current.canvas;
        const { dataPoints } = tooltipModel;
        // if tooltip.opacity is 0 then we need to hide the tooltip
        const tempIsTooltipVisible = tooltipModel.opacity !== 0;
        let tooltipWidth = 345;
        setIsTooltipVisible(tempIsTooltipVisible);

        let type;

        if (tooltipModel.body) {
          const [line] = tooltipModel.body[0].lines;
          const [id] = line.split(':');
          type = id;
        }

        if (tempIsTooltipVisible) {
          dataPoints.forEach(dataPoint => {
            if (tooltipRef.current) {
              let property;

              if (dataPoint.datasetIndex === 0) {
                property = 'published';
              }

              if (dataPoint.datasetIndex === 1) {
                property = 'longTail';
              }

              const innerHTML = socialImpactTooltip({
                type,
                date: moment
                  .utc(data.socialImpactMetricTimeSeries[property][dataPoint.index].x)
                  .format('l'),
                metric: METRIC_MAP[selectedMetric],
                value: dataPoint.yLabel
              });

              const style = {
                display: dataPoint.yLabel ? 'unset' : 'none'
              };

              const top = offsetTop + dataPoint.y;
              const left = offsetLeft + dataPoint.x;

              style.direction = 'ltr';
              style.top = `${top - 2.5}px`;
              style.left = `${left - 2.5}px`;

              if (tooltipRef.current.offsetWidth > tooltipWidth) {
                tooltipWidth = tooltipRef.current.offsetWidth;
              }

              if (left + tooltipWidth > canvasRef.current.canvas.getBoundingClientRect().width) {
                style.direction = 'rtl';
              }

              Object.assign(tooltipRef.current.style, style);
              Object.assign(tooltipRef.current, { innerHTML });
            }
          });
        }
      }
    },
    // https://www.chartjs.org/docs/2.9.4/general/interactions/
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    animation: {
      duration: 0 // general animation time
    },
    // https://www.chartjs.org/docs/2.9.4/general/performance.html#disable-animations
    responsiveAnimationDuration: 0, // animation duration after a resize
    scales: {
      xAxes: [
        {
          // https://www.chartjs.org/docs/2.9.4/axes/styling.html#grid-line-configuration
          gridLines: {
            display: false
          },
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/#tick-configuration
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/linear.html
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14
          },
          stacked: true
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            callback: value => value.toLocaleString(),
            precision: 2,
            beginAtZero: true,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14,
            suggestedMax,
            suggestedMin: 0
          },
          stacked: true
        }
      ]
    }
  };

  const tempColors = ['#C40075', '#32327D'];

  const graphData = canvas => {
    canvasRef.current = canvas.getContext('2d');

    const labels = formattedGraphData?.[0]?.timeseries?.map(dataPoint => {
      return moment
        .utc(dataPoint.x)
        .format('MMM D')
        .toUpperCase();
    });
    const datasets = formattedGraphData.map((row, index) => {
      return {
        type: 'bar',
        label: row.id,
        data: row.timeseries.map(dataPoint => dataPoint.y),
        backgroundColor: tempColors[index],
        borderColor: 'transparent'
      };
    });

    return { labels, datasets };
  };

  return (
    <div className={classes.container}>
      {loading && (
        <Box className={classes.loadingOverlay}>
          <AlbLoading />
        </Box>
      )}
      {error && <AlbError error={error} />}
      <div style={{ position: 'relative', width: '65vw', margin: 'auto' }}>
        <Bar data={graphData} options={options} />
      </div>
      <Box
        ref={tooltipRef}
        className={`${classes.point} ${isTooltipVisible ? '' : classes.hidden}`}
      />
    </div>
  );
};

PublishedVsLongTailGraph.propTypes = {
  selectedMetric: PropTypes.string.isRequired,
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDates: PropTypes.shape().isRequired
};

PublishedVsLongTailGraph.defaultProps = {};

export default PublishedVsLongTailGraph;
