import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Grid, IconButton, TextField, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { getIconSrc } from 'components/AnalyticsThirdPartyMedia/util';
import {
  CREATE_MEDIA_GROUP,
  UPDATE_MEDIA_GROUP_REPORTS,
  UPDATE_MEDIA_GROUP_NAME,
  DELETE_MEDIA_GROUP
} from 'gql/mediaGroup';
import { showToast } from 'contexts/ToastContext';
import Box from 'components/Box';
import { BROADCAST, PODCAST, WEB } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';
import AlembicModalForm from './AlembicModalForm';

const style = makeStyles({
  reportRow: {
    width: '100%',

    '&:hover $closeButton': {
      display: 'inline-flex'
    }
  },
  reportName: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 500,
    margin: '10px'
  },
  closeButton: {
    display: 'none'
  }
});

const deleteButtonStyle = {
  backgroundColor: '#E81828',
  color: 'white',
  marginLeft: 'auto',
  marginRight: '10px'
};

const AdditionalReportsDropdown = props => {
  const { reports, removeDropdownReports } = props;
  const [selectedReport] = useState('Select Reports');
  const classes = style();

  return (
    <TextField
      fullWidth
      select
      variant="outlined"
      value={selectedReport}
      onChange={e => {
        if (reports?.length > 0) {
          const foundReport = reports.find(({ id }) => id === e.target.value);

          if (foundReport) {
            removeDropdownReports(foundReport);
          }
        }
      }}
    >
      <MenuItem hidden disabled selected value="Select Reports" style={{ display: 'none' }}>
        Select Reports
      </MenuItem>
      {reports?.length > 0 &&
        reports.map(report => (
          <MenuItem value={report.id}>
            <Box display="flex" alignItems="center">
              <img src={getIconSrc(report.reportType)} alt="report_icon" width="13px" />
              <span className={classes.reportName}>{report.name}</span>
            </Box>
          </MenuItem>
        ))}
    </TextField>
  );
};

AdditionalReportsDropdown.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  removeDropdownReports: PropTypes.func.isRequired
};

const ExistingReportsSelection = ({ reports, removeAddedReports }) => {
  const classes = style();

  return (
    <Grid container direction="column">
      {reports?.map(report => (
        <Box
          key={report.id}
          py={11}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.reportRow}
        >
          <Box display="flex" alignItems="center">
            <img src={getIconSrc(report.reportType)} alt="report_icon" width="13px" />
            <span className={classes.reportName}>{report.name}</span>
          </Box>
          <IconButton
            size="small"
            onClick={() => {
              removeAddedReports(report);
            }}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </Box>
      ))}
    </Grid>
  );
};

ExistingReportsSelection.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  removeAddedReports: PropTypes.func.isRequired
};

const ThirdPartyMediaGroupModal = ({
  open,
  action,
  reportsIncluded,
  selectedGroup,
  additionalReports,
  onClose,
  refetchReports
}) => {
  const [selectedReports, setSelectedReports] = useState([]);
  const [dropdownReports, setDropdownReports] = useState([]);
  const [groupName, setGroupName] = useState('');

  const [createMediaGroup, { data: createData, error: createError }] = useMutation(
    CREATE_MEDIA_GROUP
  );

  const [
    updateMediaGroupReports,
    { data: updateReportsData, error: updateReportsError }
  ] = useMutation(UPDATE_MEDIA_GROUP_REPORTS);

  const [updateMediaGroupName, { date: updateNameData, error: updateNameError }] = useMutation(
    UPDATE_MEDIA_GROUP_NAME
  );

  const [deleteMediaGroup, { data: deleteGroupResult, error: deleteGroupError }] = useMutation(
    DELETE_MEDIA_GROUP
  );

  const resetModalSelections = () => {
    setGroupName('');
    setDropdownReports([]);
    setSelectedReports([]);
  };

  useEffect(() => {
    if (reportsIncluded?.length > 0) {
      setSelectedReports(reportsIncluded);
    } else {
      setSelectedReports([]);
    }
  }, [reportsIncluded]);

  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.name);
    } else {
      setGroupName('');
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (additionalReports?.length > 0) {
      setDropdownReports(additionalReports);
    } else {
      setDropdownReports([]);
    }
  }, [additionalReports]);

  useEffect(() => {
    if (createData?.createMediaGroup) {
      showToast(`New group '${createData.createMediaGroup.name}' was created!`, 'success');
      resetModalSelections();
      refetchReports();
      onClose();
    }

    if (createError) {
      showToast(`Group creation failed: ${createError}`, 'error');
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateReportsData?.updateMediaGroupReports) {
      showToast('Group was updated.', 'success');
      resetModalSelections();
      refetchReports();
      onClose();
    }

    if (updateReportsError) {
      showToast('Error updating reports in group. Please try again later.', 'error');
    }
  }, [updateReportsData, updateReportsError]);

  useEffect(() => {
    if (updateNameData?.updateMediaGroupName) {
      showToast('Group name was updated', 'success');
    }

    if (updateNameError) {
      showToast('Error updating group name. Please try again later.', 'error');
    }
  }, [updateNameData, updateNameError]);

  useEffect(() => {
    if (deleteGroupResult?.deleteMediaGroup) {
      showToast('Your Group has been deleted.', 'success');
      resetModalSelections();
      refetchReports();
      onClose();
    }

    if (deleteGroupError) {
      showToast(`Error deleting group: ${deleteGroupError.message}`, 'error');
    }
  }, [deleteGroupResult, deleteGroupError]);

  const triggerMutation = () => {
    const podcastReports = [];
    const broadcastReports = [];
    const articleReports = [];
    const articleFilterReports = [];

    selectedReports.forEach(report => {
      const idArr = report.id.split(':');
      const id = idArr[idArr.length - 1];

      switch (report.reportType) {
        case BROADCAST:
          broadcastReports.push(id);
          break;
        case PODCAST:
          podcastReports.push(id);
          break;
        case WEB:
          if (report.id.startsWith('web:filter:')) {
            articleFilterReports.push(id);
          } else {
            articleReports.push(id);
          }
          break;
        default:
      }
    });

    if (action === 'create') {
      const mutationArgs = {
        name: groupName,
        podcastReportIds: podcastReports,
        broadcastReportIds: broadcastReports,
        articleReportIds: articleReports,
        articleFilterReportIds: articleFilterReports
      };

      createMediaGroup({ variables: { ...mutationArgs } });
    }

    if (action === 'update' && selectedGroup) {
      if (groupName?.length) {
        updateMediaGroupName({
          variables: { id: selectedGroup.id, name: groupName }
        });
      }

      const mutationArgs = {
        mediaGroupId: selectedGroup.id,
        podcastReportIds: podcastReports,
        broadcastReportIds: broadcastReports,
        articleReportIds: articleReports,
        articleFilterReportIds: articleFilterReports
      };

      updateMediaGroupReports({ variables: { ...mutationArgs } });
    }
  };

  const onDelete = () => {
    if (action === 'update' && selectedGroup && !selectedGroup.primary) {
      deleteMediaGroup({
        variables: { id: selectedGroup.id }
      });
    }
  };

  const updateReports = newFields => {
    const { groupName: name } = newFields;

    setGroupName(name);
  };

  // Removes a report from dropdown reports in the modal via the dropdown
  const removeDropdownReports = report => {
    const remainingReports = dropdownReports.filter(r => r.id !== report.id);

    setDropdownReports(remainingReports);
    // also add to selected reports.
    setSelectedReports([...selectedReports, report]);
  };

  // Removes a report from included reports in the modal via the 'X'.
  const removeAddedReports = report => {
    const remainingReports = selectedReports.filter(r => r.id !== report.id);

    setSelectedReports(remainingReports);
    // also add to dropdown reports.
    setDropdownReports([...dropdownReports, report]);
  };

  const formFields = [
    {
      title: 'Group Name',
      value: 'groupName',
      fieldName: 'groupName',
      disabled: selectedGroup?.primary,
      placeholder: selectedGroup ? selectedGroup.name : 'Enter Name For Group',
      validators: !selectedGroup?.primary ? ['required'] : []
    },
    {
      title: 'Add Additional Reports',
      value: 'mediaReports',
      fieldName: 'mediaReports',
      type: 'custom',
      component: (
        <AdditionalReportsDropdown
          reports={dropdownReports}
          removeDropdownReports={removeDropdownReports}
        />
      )
    },
    {
      title: 'Reports To Be Grouped',
      value: 'addedReports',
      fieldName: 'addedReports',
      type: 'custom',
      component: (
        <ExistingReportsSelection
          reports={selectedReports}
          removeAddedReports={removeAddedReports}
        />
      )
    }
  ];

  return (
    <AlembicModalForm
      open={open}
      onClose={onClose}
      title={`${action === 'create' ? 'Create New' : 'Edit'} Group of Reports`}
      saveTitle={`${action === 'create' ? 'Create' : 'Update'}`}
      fields={formFields}
      currentObject={{ groupName }}
      handleConfirm={triggerMutation}
      updateFields={updateReports}
      variant="mediumGrid"
      middleActionButton={action === 'update' && selectedGroup && !selectedGroup.primary && true} // only show Delete button in 'Update' modal
      middleActionTitle="Delete"
      handleMiddleButtonClick={onDelete}
      middleButtonStyle={deleteButtonStyle}
    />
  );
};

export default ThirdPartyMediaGroupModal;

ThirdPartyMediaGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  reportsIncluded: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  additionalReports: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedGroup: PropTypes.shape(),
  refetchReports: PropTypes.func.isRequired
};

ThirdPartyMediaGroupModal.defaultProps = {
  selectedGroup: null
};
