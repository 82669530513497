import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton, Button, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import HasAnyPriv from 'components/HasAnyPriv';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlembicModalForm from 'components/AlembicModalForm';
import AlembicModalConfirm from 'components/AlembicModalConfirm/index';
import TabNav from 'components/TabNav';
import { convertToUserTimeZone } from 'util/date';

import {
  GET_THEMES_FOR_CURRENT_CONTAINER,
  CREATE_THEME,
  UPDATE_THEME,
  DELETE_THEME
} from 'gql/theme';
import { showToast } from 'contexts/ToastContext';

const styles = makeStyles({
  svgIcon: {
    color: '#0d1a3a'
  },
  editIcon: {
    borderRadius: 0
  },
  deleteIcon: {
    borderRadius: 0
  }
});

const createFields = [
  { fieldName: 'name', title: 'Theme Name', type: 'input', validators: ['required'] }
];
const updateFields = [
  { fieldName: 'name', title: 'Theme Name', type: 'input', validators: ['required'] },
  { fieldName: 'active', title: 'Active', type: 'checkbox' }
];

const ThemeList = props => {
  const { currentUser, openCreateTheme, handleCloseCreateTheme, loading, queryError, data } = props;

  const classes = styles();

  const [openEditTheme, setEditTheme] = useState(false);
  const [openDeleteTheme, setDeleteTheme] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState({});

  const [createTheme, { error: creationError }] = useMutation(CREATE_THEME, {
    refetchQueries: () => {
      return [{ query: GET_THEMES_FOR_CURRENT_CONTAINER }];
    }
  });
  const [editTheme, { error: updateError }] = useMutation(UPDATE_THEME);
  const [deleteTheme, { error: deletionError }] = useMutation(DELETE_THEME, {
    refetchQueries: () => {
      return [{ query: GET_THEMES_FOR_CURRENT_CONTAINER }];
    }
  });

  const options = {
    pagination: false,
    selectableRows: 'none',
    responsive: 'vertical',
    rowHover: false,
    fixedHeader: false,
    sortFilterList: false,
    sort: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false
  };

  const columns = ['Name', 'Created By', 'Created At', 'Edit', 'Action'];

  const handleOpenEditTheme = theme => {
    setEditTheme(true);
    setSelectedTheme(theme);
  };

  const handleCloseEditTheme = () => {
    setEditTheme(false);
    setSelectedTheme(null);
  };

  const handleOpenDeleteTheme = theme => {
    setDeleteTheme(true);
    setSelectedTheme(theme);
  };

  const handleCloseDeleteTheme = () => {
    setDeleteTheme(false);
  };

  const handleSaveTheme = theme => {
    createTheme({ variables: theme });
    handleCloseCreateTheme();
  };

  const handleEditTheme = ({ name, active }) => {
    editTheme({ variables: { id: selectedTheme.id, name, active } });
    handleCloseEditTheme();
  };

  const handleDeleteTheme = () => {
    deleteTheme({ variables: { id: selectedTheme.id } });
    handleCloseDeleteTheme();
  };

  const handleButtonAction = theme => {
    const hasEvents = !!theme.campaign_events.length;
    const hasTopics = !!theme.topics.length;
    const action = hasEvents
      ? () => editTheme({ variables: { id: theme.id, name: theme.name, active: false } })
      : () => handleOpenDeleteTheme(theme);

    const button = (
      <HasAnyPriv privs={['THEMES:DELETE']}>
        <Button
          disabled={(hasEvents && !theme.active) || hasTopics}
          variant="outlined"
          color="primary"
          size="small"
          onClick={action}
        >
          {hasEvents && theme.active ? 'Set as Inactive' : 'Delete'}
        </Button>
      </HasAnyPriv>
    );

    if (hasEvents && !theme.active) {
      return (
        <Tooltip title="Cannot delete themes associated with a post">
          <div>{button}</div>
        </Tooltip>
      );
    }

    if (hasTopics) {
      return (
        <Tooltip title="Cannot delete themes associated with a topic">
          <div>{button}</div>
        </Tooltip>
      );
    }

    return button;
  };

  const themeItem = theme => [
    theme.name,
    `${theme.created_by_user.first_name} ${theme.created_by_user.last_name}`,
    theme.created_at
      ? convertToUserTimeZone(theme.created_at, currentUser.time_zone).format('L LT')
      : '-- --',
    <HasAnyPriv privs={['THEMES:EDIT']}>
      <IconButton
        className={classes.editIcon}
        onClick={() => handleOpenEditTheme(theme)}
        disableRipple
      >
        <EditIcon className={classes.svgIcon} />
      </IconButton>
    </HasAnyPriv>,
    handleButtonAction(theme)
  ];

  if (loading) return <AlbLoading />;
  if (queryError) return `Error! ${queryError}`;
  if (creationError && creationError.graphQLErrors) {
    showToast(
      `Theme creation failed. Error: ${creationError.graphQLErrors.map(({ message }) => message)}`,
      'error'
    );
    creationError.graphQLErrors = null;
  }
  if (updateError && updateError.graphQLErrors) {
    showToast(
      `Theme edit failed. Error: ${updateError.graphQLErrors.map(({ message }) => message)}`,
      'error'
    );
    updateError.graphQLErrors = null;
  }
  if (deletionError && deletionError.graphQLErrors) {
    showToast(
      `Theme deletion failed. Error: ${deletionError.graphQLErrors.map(({ message }) => message)}`,
      'error'
    );
    deletionError.graphQLErrors = null;
  }

  return (
    <Fragment>
      <TabNav tabs={['Active', 'Inactive']}>
        <Box mt={10} mx={10}>
          <MUIDataTable
            data={data.themes.filter(item => item.active).map(themeItem)}
            columns={columns}
            options={options}
          />
        </Box>
        <Box mt={10} mx={10}>
          <MUIDataTable
            data={data.themes.filter(item => !item.active).map(themeItem)}
            columns={columns}
            options={options}
          />
        </Box>
      </TabNav>

      <AlembicModalForm
        saveTitle="Create Theme"
        cancelTitle="Cancel"
        open={openCreateTheme}
        onClose={handleCloseCreateTheme}
        handleConfirm={handleSaveTheme}
        fields={createFields}
      />

      <AlembicModalForm
        saveTitle="Edit Theme"
        cancelTitle="Cancel"
        open={openEditTheme}
        onClose={handleCloseEditTheme}
        handleConfirm={handleEditTheme}
        fields={updateFields}
        currentObject={selectedTheme}
      />

      <AlembicModalConfirm
        isDelete
        isOpen={openDeleteTheme}
        title="Delete Theme"
        body="Are you sure you want to delete this theme?"
        cancelTitle="Cancel"
        confirmTitle="Delete theme"
        handleCancel={handleCloseDeleteTheme}
        handleConfirm={handleDeleteTheme}
      />
    </Fragment>
  );
};

ThemeList.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  openCreateTheme: PropTypes.bool.isRequired,
  handleCloseCreateTheme: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.shape(),
  queryError: PropTypes.shape()
};

ThemeList.defaultProps = {
  loading: false,
  data: {},
  queryError: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(ThemeList);
