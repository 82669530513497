import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import {
  CREATE_SALESFORCE_GROUP,
  UPDATE_SALESFORCE_GROUP,
  DELETE_SALESFORCE_GROUP
} from 'gql/salesforce';
import Box from 'components/Box';
// eslint-disable-next-line import/no-cycle
import StatusAndStageDropdown from 'components/AnalyticsSalesforce/StatusAndStageDropdown';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import theme from 'theme';
import modalStyles from './modalStyles';

const styles = makeStyles({
  ...modalStyles,
  error: {
    color: '#E81828'
  },
  textfield: {
    '& > div': { width: '100%' }
  },
  header: {
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontWeight: 500,
    backgroundColor: '#EEEEEE',
    marginLeft: '-20px',
    marginBottom: '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '20px',
    minWidth: '600px'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 500,
    marginBottom: '20px'
  },
  closeButton: {
    height: '0px',
    padding: '0px',

    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  cancelButton: {
    marginRight: '20px'
  },
  deleteButton: {
    color: '#FFFFFF',
    background: '#E81828',

    '&:hover': {
      background: '#979797'
    }
  }
});

const dropdownTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiAutocomplete: {
      inputRoot: {
        padding: '4px !important'
      }
    },
    MuiFilledInput: {
      ...theme.overrides.MuiFilledInput,
      root: {
        ...theme.overrides.MuiFilledInput.root,
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      input: {
        padding: '9px 13px',
        fontWeight: 600
      }
    },
    MuiInputBase: {
      input: {
        color: '#0d1a3a',
        fontSize: '12px',
        fontWeight: 600
      }
    }
  }
});

const refetchQueries = ['getSalesforceGroups'];

const SalesforceGroupModal = props => {
  const {
    options,
    selectedGroup,
    setSelectedGroup,
    previousSelectedOptions,
    isModalOpen,
    onChange
  } = props;

  const [groupName, setGroupName] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(previousSelectedOptions);

  const [openDropdown, setOpenDropdown] = useState(false);

  const classes = styles();

  useEffect(() => {
    ValidatorForm.addValidationRule('isMaxLength', value => value.length <= 255);
  }, []);

  const [
    createSalesforceGroup,
    { loading: createLoading, error: createError, data: createData }
  ] = useMutation(CREATE_SALESFORCE_GROUP, { refetchQueries });

  const [
    updateSalesforceGroup,
    { loading: updateLoading, error: updateError, data: updateData }
  ] = useMutation(UPDATE_SALESFORCE_GROUP, { refetchQueries });

  const [
    deleteSalesforceGroup,
    { error: deleteError, data: deleteData }
  ] = useMutation(DELETE_SALESFORCE_GROUP, { refetchQueries });

  const filterStatuses = ({ type }) => type === 'statuses';
  const filterStages = ({ type }) => type === 'stages';

  const selectedStatuses = selectedOptions.filter(filterStatuses);
  const selectedStages = selectedOptions.filter(filterStages);

  useEffect(() => {
    if (selectedGroup) {
      const { lead_status_ids: statusIds, opportunity_stage_ids: stageIds } = selectedGroup;

      const statuses = options.filter(filterStatuses).filter(({ id }) => statusIds.includes(id));
      const stages = options.filter(filterStages).filter(({ id }) => stageIds.includes(id));

      setGroupName(selectedGroup.name);
      setSelectedOptions([...statuses, ...stages]);
    }
  }, [selectedGroup]);

  const closeModal = () => {
    onChange({ isModalOpen: false });
  };

  useEffect(() => {
    if (createData?.createSalesforceGroup) {
      setSelectedGroup(createData.createSalesforceGroup);

      showToast(`Group ${selectedGroup?.id === 'default' ? 'updated' : 'created'}.`, 'success');
      closeModal();
    }
  }, [createData]);

  useEffect(() => {
    if (updateData?.updateSalesforceGroup) {
      setSelectedGroup(updateData.updateSalesforceGroup);

      showToast('Group updated.', 'success');
      closeModal();
    }
  }, [updateData]);

  useEffect(() => {
    if (deleteData?.deleteSalesforceGroup) {
      setSelectedGroup(null);

      showToast('Group deleted.', 'success');
      closeModal();
    }
  }, [deleteData]);

  useEffect(() => {
    if (createError || updateError || deleteError) {
      handleGraphQLError(createError || updateError || deleteError);
    }
  }, [createError, updateError, deleteError]);

  const clearSelection = () => {
    setSelectedOptions([]);
  };

  const removeSelectedOption = (id, type) => {
    const remainingOptions = selectedOptions.filter(
      option => option.id !== id || option.type !== type
    );

    setSelectedOptions(remainingOptions);
  };

  const deleteSelectedGroup = () => {
    deleteSalesforceGroup({
      variables: { id: selectedGroup.id }
    });
  };

  return (
    <MuiThemeProvider theme={dropdownTheme}>
      <Dialog maxWidth="sm" open={isModalOpen} onClose={closeModal}>
        <ValidatorForm
          onSubmit={() => {
            if (selectedStatuses.length < 2 || selectedStages.length < 2) {
              showToast('Group must contain at least 2 statuses and 2 stages.', 'warn');
            } else if (selectedGroup == null || selectedGroup?.id === 'default') {
              createSalesforceGroup({
                variables: {
                  name: groupName,
                  leadStatusIds: selectedStatuses.map(({ id }) => id),
                  opportunityStageIds: selectedStages.map(({ id }) => id),
                  isDefault: selectedGroup?.id === 'default'
                }
              });
            } else {
              updateSalesforceGroup({
                variables: {
                  id: selectedGroup.id,
                  name: groupName,
                  leadStatusIds: selectedStatuses.map(({ id }) => id),
                  opportunityStageIds: selectedStages.map(({ id }) => id)
                }
              });
            }
          }}
        >
          <Box p={12}>
            <Grid container>
              <Grid item container>
                <Typography className={classes.title}>
                  {`${selectedGroup == null ? 'Create' : 'Edit'} Group`}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography className={classes.label}>Group Name</Typography>
              </Grid>
              <Grid className={classes.textfield} item container>
                <TextValidator
                  autoFocus
                  fullWidth
                  variant="filled"
                  placeholder="Enter Group Name"
                  disabled={selectedGroup?.id === 'default' || selectedGroup?.is_default}
                  value={groupName}
                  onChange={event => setGroupName(event.target.value)}
                  validators={['required']}
                  errorMessages={['* Required']}
                />
              </Grid>
              <Grid item container style={{ marginTop: '24px' }}>
                <Typography className={classes.label}>
                  Add Lead Statuses / Opportunity Stages
                </Typography>
              </Grid>
              <Grid className={classes.textfield} item container>
                <StatusAndStageDropdown
                  options={options}
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  clearSelection={clearSelection}
                  modal
                />
              </Grid>
              <Grid item container style={{ marginTop: '24px' }}>
                <Typography className={classes.label}>
                  Lead Statuses / Opportunity Stages To Be Grouped
                </Typography>
              </Grid>
              <Grid className={classes.textfield} item container>
                {selectedStatuses.length > 0 && (
                  <>
                    <Grid container className={classes.header}>
                      LEAD STATUSES
                    </Grid>
                    {selectedStatuses.map(({ id, name, type }) => (
                      <Grid key={`${type}-${id}`} item className={classes.option}>
                        <span>{name}</span>
                        <IconButton
                          onClick={() => {
                            removeSelectedOption(id, type);
                          }}
                          className={classes.closeButton}
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                    ))}
                  </>
                )}
                {selectedStages.length > 0 && (
                  <>
                    <Grid container className={classes.header}>
                      OPPORTUNITY STAGES
                    </Grid>
                    {selectedStages.map(({ id, name, type }) => (
                      <Grid key={`${type}-${id}`} item className={classes.option}>
                        <span>{name}</span>
                        <IconButton
                          size="small"
                          onClick={() => {
                            removeSelectedOption(id, type);
                          }}
                          className={classes.closeButton}
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
              <Grid item container justifyContent="space-between">
                <span>
                  {selectedGroup && !selectedGroup?.is_default && (
                    <Button
                      className={classes.deleteButton}
                      variant="outlined"
                      onClick={deleteSelectedGroup}
                    >
                      Delete
                    </Button>
                  )}
                </span>
                <span>
                  <Button className={classes.cancelButton} variant="outlined" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      selectedStatuses.length < 2 ||
                      selectedStages.length < 2 ||
                      createLoading ||
                      updateLoading
                    }
                  >
                    {`${selectedGroup == null ? 'Create' : 'Update'}`}
                  </Button>
                </span>
              </Grid>
            </Grid>
          </Box>
        </ValidatorForm>
      </Dialog>
    </MuiThemeProvider>
  );
};

SalesforceGroupModal.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedGroup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    lead_status_ids: PropTypes.arrayOf(PropTypes.string),
    opportunity_stage_ids: PropTypes.arrayOf(PropTypes.string),
    is_default: PropTypes.bool
  }),
  setSelectedGroup: PropTypes.func.isRequired,
  previousSelectedOptions: PropTypes.arrayOf(PropTypes.shape()),
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

SalesforceGroupModal.defaultProps = {
  selectedGroup: null,
  previousSelectedOptions: []
};

export default SalesforceGroupModal;
