// user_sessions.js

import { gql } from '@apollo/client';

export const GET_USER_SESSIONS = gql`
  query {
    getActiveSessions {
      id
      session_id
      yours

      user_id
      expires

      browser

      country_code
      country_name
      region_name
      city_name
      postal_code

      latitude
      longitude

      created_at
      updated_at
    }
  }
`;

export const KILL_SESSION = gql`
  mutation killSession($sessionId: ID!) {
    killSession(sessionId: $sessionId) {
      session_id
      message
      result
    }
  }
`;

export const KILL_ALL_SESSIONS = gql`
  mutation killAllSessions($notThisOne: Boolean!) {
    killAllSessions(notThisOne: $notThisOne) {
      session_id
      message
      result
    }
  }
`;
