/* eslint-disable no-else-return */
/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CostToRecreateTopTable from 'components/AlbTable/CostToRecreateTopTable';
import AlbLoading from 'components/AlbLoading';

const useStyles = makeStyles({
  bold: {
    fontWeight: '600'
  }
});

/**
 * @summary Renders Top Domains Table for cost to recreate reports
 * @name TopDomainTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.startDate - Start date for all articles published on
 * @param {String} props.endDate - End date for all articles published by
 * @param {Number} props.searchID - Article search ID to pass to GQL
 * @param {String} props.valuationType - The type of 'cpm' or 'cpa' calculation
 * @return {Object} - React JSX
 */
const TopDomainTable = props => {
  const { isLoading, topDomains } = props;
  const classes = useStyles();

  // formatTable helper function
  const formatTable = dataSet =>
    dataSet.map(({ domain, shares, valuation }) => {
      return {
        domain: <div className={classes.bold}>{domain}</div>,
        total_share_count: shares,
        valuation
      };
    });

  const tableColumns = [
    { name: 'domain', label: 'DOMAIN NAME' },
    { name: 'total_share_count', label: 'SHARES' },
    { name: 'valuation', label: 'VALUE' }
  ];

  if (isLoading) {
    return <AlbLoading />;
  }

  return <CostToRecreateTopTable tableData={formatTable(topDomains)} tableColumns={tableColumns} />;
};

TopDomainTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  topDomains: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default TopDomainTable;
