export const lookBackTimeOptions = [
  { value: '90', label: '3 Months' },
  { value: '180', label: '6 Months' },
  { value: '360', label: '1 Year' },
  { value: '720', label: '2 Years' },
  { value: '1440', label: '4 Years' }
];

export const dimensionOptions = [
  { value: 'type', label: 'Type' },
  { value: 'industry', label: 'Industry' },
  { value: 'campaign_name', label: 'Campaign Name' },
  { value: 'country', label: 'Country' },
  { value: 'lead_source', label: 'Lead Source' }
];

export const opportunityOptions = [
  { value: null, label: 'All' },
  { value: true, label: 'Lead Generated Opportunities' },
  { value: false, label: 'Non-Lead Generated Opportunities' }
];
