/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { BROADCAST_SEARCHES } from 'gql/broadcastSearch';
import { PODCAST_SEARCHES } from 'gql/podcastSearch';
import { ARTICLE_SEARCHES_AGG } from 'gql/articleSearch';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import CustomFooter from 'components/TablePagination';
import { PODCAST, BROADCAST, WEB } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';
import { customValueRender } from './CustomRender';
import AlbTable from './AlbTable';
import customNameRender from './ThirdPartyMediaTableReportName';
import thirdPartyActionsRender from './ThirdPartyMediaTableActions';
import earnedMediaActionsRender from './EarnedMediaTableActions';
import customCriteriaRender from './SearchCriteria';

/**
 * @method
 * @summary This component renders a table for the broadcast, podcast and article searches
 * @name ThirdPartyMediaTable
 * @param {Boolean} props.enabled - A boolean flag to query enabled or disabled broadcast searches
 * @param {String} mediaType - persist media selection from previous screen (Broadcast/Radio, Podcast, or Web)
 * @param {Function} refetchReports - function to refetch the table query
 * @return {Object} - React JSX
 */
const ThirdPartyMediaTable = props => {
  const { enabled, mediaType, refetchReports } = props;
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['', '']);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  const [
    getBroadcastData,
    {
      loading: broadcastLoading,
      error: broadcastError,
      data: broadcastData,
      refetch: broadcastRefetch
    }
  ] = useLazyQuery(BROADCAST_SEARCHES, {
    variables: {
      enabled,
      count: rowsPerPage,
      after: page * rowsPerPage
    },
    fetchPolicy: 'no-cache'
  });

  const [
    getPodcastData,
    { loading: podcastLoading, error: podcastError, data: podcastData, refetch: podcastRefetch }
  ] = useLazyQuery(PODCAST_SEARCHES, {
    variables: {
      enabled,
      count: rowsPerPage,
      after: page * rowsPerPage
    },
    fetchPolicy: 'no-cache'
  });

  const [
    getArticlesData,
    { loading: articlesLoading, error: articlesError, data: articlesData, refetch: articlesRefetch }
  ] = useLazyQuery(ARTICLE_SEARCHES_AGG, {
    variables: {
      enabled,
      count: rowsPerPage,
      after: page * rowsPerPage
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    switch (mediaType) {
      case BROADCAST:
        getBroadcastData();
        break;
      case PODCAST:
        getPodcastData();
        break;
      case WEB:
        getArticlesData();
        break;
      default:
    }

    setPage(0);
  }, [mediaType]);

  const getQueryRefetch = () => {
    if (mediaType === BROADCAST) {
      return broadcastRefetch;
    }
    if (mediaType === PODCAST) {
      return podcastRefetch;
    }
    if (mediaType === WEB) {
      return articlesRefetch;
    }
    return null;
  };

  // if article (WEB) search has additional filtered reports, format and display them under the report.
  const formatArticleSearchFilters = (search, keywords, tempTableData) => {
    const articleFilters = search.article_search_filters;

    if (articleFilters?.length) {
      articleFilters.forEach(filter => {
        const name = `${search.name} (Filter Id: ${filter.id})`;

        const filterName = {
          id: search.id,
          mediaType,
          name,
          filterId: filter.id
        };

        const filterArticleCount = filter.articles?.count || 0;

        const filterSearchCriteria = {
          chips: [
            ...(search.query_title ? [{ title: 'Title', body: search.query_title }] : []),
            ...keywords,
            ...(filter.included_title_keywords
              ? [{ title: 'Title Filter', body: filter.included_title_keywords }]
              : []),
            ...(search.language ? [{ title: 'Language', body: search.language }] : [])
          ]
        };

        // Actions
        const filterActions = {
          mediaType: WEB,
          searchId: search.id,
          searchName: search.name,
          filterId: filter.id,
          isDetectingFilter: filter.is_detecting,
          queryRefetch: getQueryRefetch(),
          keywords: filter.included_title_keywords
        };

        const status = search.initial_search_completed ? 'Completed' : 'In Progress';

        tempTableData.push({
          reportName: filterName,
          searchCount: filterArticleCount,
          searchCriteria: filterSearchCriteria,
          status,
          isDetecting: filter.is_detecting ? 'Yes' : 'No',
          actions: filterActions
        });
      });
    }
    return tempTableData;
  };

  const formatTableData = dataRows => {
    let tempTableData = [];

    dataRows.forEach(search => {
      // Report Name
      const reportName = {
        mediaType,
        name: search?.name ?? ''
      };

      // Broadcast Count
      const searchCount = search?.total_results ?? 0;

      // Search Criteria
      const keywords =
        search.query_keywords?.split(',')?.map(keyword => ({ title: 'Keyword', body: keyword })) ||
        [];

      let searchCriteria = {
        chips: [...keywords]
      };

      if (mediaType === WEB) {
        const title = search.query_title ? [{ title: 'Title', body: search.query_title }] : [];

        const languages =
          search.language?.split(',')?.map(language => ({ title: 'Language', body: language })) ||
          [];

        // Search Criteria
        searchCriteria = {
          chips: [...title, ...keywords, ...languages]
        };
      }

      // Extracted Mention
      const mentionExtractKeyword = {
        chips: search?.mention_extract_keyword
          ? [{ title: 'Mention', body: search.mention_extract_keyword }]
          : []
      };

      // Job status
      const status = search.initial_search_completed ? 'Completed' : 'In Progress';

      // Actions
      const actions = {
        mediaType,
        searchId: search.id,
        searchName: search.name,
        isEnabled: search.enabled,
        // is_default on the backend is the same thing as isDetecting in the UI.
        isDetecting: search.is_default,
        queryRefetch: getQueryRefetch(),
        refetchReports
      };

      tempTableData.push({
        reportName,
        searchCount,
        searchCriteria,
        mentionExtractKeyword,
        status,
        isDetecting: search.is_default ? 'Yes' : 'No',
        actions
      });

      if (mediaType === WEB) {
        const articleFilters = search.article_search_filters;

        if (articleFilters) {
          tempTableData = formatArticleSearchFilters(search, keywords, tempTableData);
        }
      }
    });

    const getActions = () => {
      if (mediaType === BROADCAST || mediaType === PODCAST) {
        return thirdPartyActionsRender;
      }

      if (mediaType === WEB) {
        return earnedMediaActionsRender;
      }

      return null;
    };

    const tempTableColumns = [
      {
        name: 'reportName',
        label: 'REPORT NAME',
        options: { customBodyRender: customNameRender, sort: false }
      },
      {
        name: 'searchCount',
        label: '# OF RESULTS',
        options: { customBodyRender: customValueRender, sort: false }
      },
      {
        name: 'searchCriteria',
        label: 'SEARCH CRITERIA',
        options: { customBodyRender: customCriteriaRender, sort: false }
      },
      {
        name: 'mentionExtractKeyword',
        label: 'EXTRACTED MENTION',
        options: {
          customBodyRender: customCriteriaRender,
          sort: false,
          display: !!(mediaType === BROADCAST || mediaType === PODCAST)
        }
      },
      {
        name: 'status',
        label: 'STATUS',
        options: { sort: false }
      },
      {
        name: 'isDetecting',
        label: 'DETECTING EVENTS?',
        options: { sort: false }
      },
      {
        name: 'actions',
        label: 'ACTIONS',
        options: {
          customBodyRender: getActions(),
          setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
          sort: false
        }
      }
    ];

    setTableData(tempTableData);
    setTableColumns(tempTableColumns);
  };

  useEffect(() => {
    if (mediaType === BROADCAST && broadcastData?.listBroadcastSearches?.rows) {
      const { rows } = broadcastData.listBroadcastSearches;

      formatTableData(rows);
      setCount(broadcastData.listBroadcastSearches.count);
    }
  }, [broadcastData]);

  useEffect(() => {
    if (mediaType === PODCAST && podcastData?.listPodcastSearches?.rows) {
      const { rows } = podcastData.listPodcastSearches;

      formatTableData(rows);
      setCount(podcastData.listPodcastSearches.count);
    }
  }, [podcastData]);

  useEffect(() => {
    if (mediaType === WEB && articlesData?.listArticleSearches?.rows) {
      const { rows } = articlesData.listArticleSearches;

      formatTableData(rows);
      setCount(articlesData.listArticleSearches.count);
    }
  }, [articlesData]);

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  if (broadcastLoading || podcastLoading || articlesLoading) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbLoading />
      </Grid>
    );
  }

  if (broadcastError || podcastError || articlesError) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={broadcastError || podcastError || articlesError} />
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />
    </Grid>
  );
};

ThirdPartyMediaTable.propTypes = {
  enabled: PropTypes.bool,
  mediaType: PropTypes.string.isRequired,
  refetchReports: PropTypes.func.isRequired
};

ThirdPartyMediaTable.defaultProps = {
  enabled: true
};

export default ThirdPartyMediaTable;
