import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import AlembicModalForm from 'components/AlembicModalForm';
import PropTypes from 'prop-types';
import { ARTICLE_SEARCHES_AGG } from 'gql/articleSearch';
import { LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER } from 'gql/linktoken';
import { mapOptions } from 'util/social';
import PostAccountSelector from 'components/PostAccountSelector';
import AlembicModalConfirm from 'components/AlembicModalConfirm/AlembicModalConfirm';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OpenInNew } from '@material-ui/icons';
import AlbLoading from 'components/AlbLoading';
import { CreateEarnedMediaAnalyticsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import FORM_FIELDS from './costToRecreateFields';

const IS_FILTER = 'filtered_search';

const useStyles = makeStyles(() => ({
  emptyModalContainer: {
    marginTop: '30px'
  },
  emptyTitle: {
    fontWeight: 600,
    fontSize: '15px',
    color: '#0A1734',
    marginBottom: '15px'
  },
  createReportButton: {
    color: '#1DA1F2',
    fontSize: '14px',
    fontWeight: 500
  },
  emptyLabel: {
    color: '#E81828',
    fontSize: '14px',
    fontWeight: 500
  },
  required: {
    color: '#6F6F6F',
    fontWeight: 600,
    fontSize: '13px'
  },
  icon: {
    color: '#0A1734'
  }
}));

/**
 * @summary BasicFormCreateModal renders one of two modals for report creation (empty state or full form) for the Cost to Recreate Reports section.
 * @prop {Boolean} open - dictates the open/closed state of modal
 * @prop {Function} handleCancel - cancel action toggles modal state to closed.
 * @prop {Function} handleCreateReport - callback function triggers mutation for creating a report
 * @prop {Function} handleAdvancedClick - callback function that takes the user to the Advanced Form for report creation
 * @prop {Object} history - React Router history object.
 * @returns - React JSX
 */

const BasicFormCreateModal = ({
  open,
  handleCancel,
  handleCreateReport,
  handleAdvancedClick,
  history
}) => {
  const [formFields, setFormFields] = useState(FORM_FIELDS);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [selectedLinkedAccounts, setSelectedLinkedAccounts] = useState([]);
  const [emptyModalOpen, setEmptyModalOpen] = useState(true);

  const classes = useStyles();

  const { data: articleSearchData, loading } = useQuery(ARTICLE_SEARCHES_AGG, {
    enabled: true,
    fetchPolicy: 'network-only'
  });

  const { data } = useQuery(LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER, {
    variables: { showOnlyEnabled: true },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.linkedAccounts) {
      setLinkedAccounts(mapOptions(data.linkedAccounts, false));
      setSelectedLinkedAccounts(mapOptions(data.linkedAccounts, false));
    }
  }, [data]);

  useEffect(() => {
    let updatedFormFields = formFields;

    if (articleSearchData?.listArticleSearches?.rows?.length) {
      const selectOptions = [];

      articleSearchData.listArticleSearches.rows.forEach(articleSearch => {
        selectOptions.push({
          value: articleSearch.id,
          name: articleSearch.name
        });

        // also add their filters to this list:
        if (articleSearch.article_search_filters?.length) {
          articleSearch.article_search_filters.forEach(filter => {
            selectOptions.push({
              value: `${articleSearch.id}.${filter.id}`,
              name: `${articleSearch.name} Filter id #${filter.id}`,
              type: IS_FILTER
            });
          });
        }
      });

      updatedFormFields = formFields.map(field => {
        if (field.fieldName === 'comparedEarnedMediaReport') {
          return {
            ...field,
            options: selectOptions
          };
        }

        return field;
      });

      setFormFields(updatedFormFields);
      setEmptyModalOpen(false);
    }
  }, [articleSearchData]);

  const handleSelectAccount = accounts => setSelectedLinkedAccounts(accounts);

  const formatFieldsForMutation = fieldObj => {
    let reportId = fieldObj.comparedEarnedMediaReport;
    let isFilter = false;

    // if it's in the format '1.5' it's a filter.
    if (fieldObj.comparedEarnedMediaReport.includes('.')) {
      isFilter = true;
      // eslint-disable-next-line prefer-destructuring
      reportId = fieldObj.comparedEarnedMediaReport.split('.')[1];
    }

    return {
      reportId,
      isFilter,
      name: fieldObj.costToRecreateReportName,
      linkedAccounts: selectedLinkedAccounts.map(account => account.id),
      postTextKeywords: fieldObj.postTextKeyword ?? null,
      imageTags: fieldObj.imageTag ?? null,
      videoTags: fieldObj.videoTag ?? null,
      mediaTypes: fieldObj.mediaType ?? null,
      postTypes: fieldObj.postType ?? null,
      enabled: true
    };
  };

  // actionType: 'Create' or 'Advanced'
  const onConfirm = fieldObj => {
    // format fields for mutation
    const formattedObj = formatFieldsForMutation(fieldObj);

    // triggers the mutation in parent
    handleCreateReport(formattedObj);
  };

  const handleEmptyModalActionClick = () => goToRoute(CreateEarnedMediaAnalyticsPath, history);

  const handleAdvancedButtonClick = fieldObj => {
    const formattedObj = formatFieldsForMutation(fieldObj);

    handleAdvancedClick(formattedObj);
  };

  if (open && loading) {
    return <AlbLoading />;
  }

  if (open) {
    if (!emptyModalOpen) {
      return (
        <AlembicModalForm
          title="Create Media Valuation Report"
          note="You will need to select a 3rd Party Media Report in order to generate a Media Valuation Report.
          If you would like to add advanced options, click Advanced below."
          fields={formFields}
          open={!emptyModalOpen && open}
          onClose={handleCancel}
          handleConfirm={onConfirm}
          handleCancel={handleCancel}
          saveTitle="Create"
          variant="mediumGrid"
          middleActionButton
          middleActionTitle="Advanced"
          handleMiddleButtonClick={handleAdvancedButtonClick}
          customComponent={
            <PostAccountSelector
              handleSelectAccount={handleSelectAccount}
              singleSelectedAccounts={selectedLinkedAccounts}
              singleAccountData={linkedAccounts}
              useIconDisplay
              boxPadding={0}
            />
          }
        />
      );
    }
    return (
      <AlembicModalConfirm
        isOpen={emptyModalOpen}
        isConfirmOnly
        fullLengthBody
        width="459px"
        title="Create Media Valuation Report"
        note="You will need to select a 3rd Party Media Report in order to generate a Media Valuation Report. 
          If you would like advanced options, click advanced below."
        body={
          <Grid
            container
            className={classes.emptyModalContainer}
            direction="column"
            justifyContent="center"
          >
            <Typography className={classes.emptyTitle}>
              Compared 3rd Party Media Report <span className={classes.required}>(Required)</span>
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography className={classes.emptyLabel}>No 3rd Party Media Reports</Typography>
              <Button
                variant="text"
                className={classes.createReportButton}
                endIcon={<OpenInNew className={classes.icon} />}
                onClick={handleEmptyModalActionClick}
              >
                Create Report
              </Button>
            </Grid>
          </Grid>
        }
        confirmTitle="Cancel"
        handleCancel={() => {
          setEmptyModalOpen(false);
          handleCancel();
        }}
        handleConfirm={() => {
          setEmptyModalOpen(false);
          handleCancel();
        }}
      />
    );
  }

  return null;
};

BasicFormCreateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCreateReport: PropTypes.func.isRequired,
  handleAdvancedClick: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired
};

export default BasicFormCreateModal;
