/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { FormControl, Tab, Tabs, Box, Tooltip } from '@material-ui/core';
import { showToast } from 'contexts/ToastContext';
import InfoIcon from '@material-ui/icons/Info';
import HasAnyPriv from 'components/HasAnyPriv';
import AlbLoading from 'components/AlbLoading';
import AlbTable from 'components/AlbTable';
import AlbError from 'components/AlbError';

import { LINKTOKEN_RELATIONSHIPS, SET_TOKEN_CONTAINER } from 'gql/linktoken';
import { renderUILongDate } from 'util/date';
import handleGraphQLError from 'util/error';
import colors from 'util/colors';
import { makeStyles } from '@material-ui/core/styles';
import { ALL_ORGS_AND_CONTAINERS } from 'gql/orgMembership';
import checkForPriv from 'util/checkForPriv';

import { withRouter } from 'react-router-dom';
import OrgSelect from './OrgSelect';

import {
  customChannelRender,
  customNameRender,
  customOwnerRender,
  customActionsRender,
  isSubAccount
} from './customRenders';

const useStyles = makeStyles(theTheme => ({
  formControl: {
    margin: theTheme.spacing(0),
    padding: 0,
    minWidth: 200
  }
}));

const isLinktokenOwnedByCurrentUser = (linktokenOwnerId, currentUserId) => {
  return linktokenOwnerId === currentUserId;
};

const isLinktokenUsedInCurrentContainer = (linktokenContainerId, currentContainerId) => {
  return linktokenContainerId === currentContainerId;
};

const TABLE_COLUMN_KEYS = {
  channel: 'type',
  name: 'remote_name',
  organizationContainer: 'container'
};

const TABLE_COLUMN_VALUES = {
  type: 'channel',
  remote_name: 'name',
  container: 'organizationContainer'
};

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

TabPanel.defaultProps = {
  children: null
};

const LinkedAccountTable = props => {
  const { currentUser, currentContainer, currentPrivs, history } = props;
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [sortOrder, setSortOrder] = useState([]);
  const [orgTree, setOrgTree] = useState();
  const [currentFilter, setCurrentFilter] = useState('MINE');
  const [activeTab, setActiveTab] = React.useState(0);
  const classes = useStyles();

  // start query for the user's org and container tree
  const {
    error: orgTreeError,
    data: orgTreeData,
    refetch: orgTreeRefetch,
    loading: orgTreeLoading,
    networkStatus: orgTreeNetworkStatus
  } = useQuery(ALL_ORGS_AND_CONTAINERS, {
    variables: {
      id: currentUser.id
    },
    displayName: 'ALL_ORGS_AND_CONTAINERS',
    notifyOnNetworkStatusChange: false
  });

  // fires when org tree list available
  useEffect(() => {
    const incomingOrgTree = orgTreeData?.User;

    if (incomingOrgTree) {
      setOrgTree(incomingOrgTree.organizations);
    }
  }, [orgTreeData]);

  const {
    error: tableDataError,
    data: tableDataData,
    refetch: tableDataRefetch,
    loading: tableDataLoading,
    networkStatus: tableDataNetworkStatus
  } = useQuery(LINKTOKEN_RELATIONSHIPS, {
    variables: {
      orderBy: sortOrder,
      filter: currentFilter
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    if (tableDataError) {
      handleGraphQLError(tableDataError);
    }
  }, [tableDataError]);

  useEffect(() => {
    if (orgTreeError) {
      handleGraphQLError(orgTreeError);
    }
  }, [orgTreeError]);

  useEffect(() => {
    console.log(`${tableDataError} ${orgTreeError}`);
  }, [tableDataError, orgTreeError]);

  const handleTabChange = (event, newValue) => {
    const filterList = ['MINE', 'CONTAINER', 'ORGANIZATION'];
    // switch the tab and trigger a refetch
    setCurrentFilter(filterList[newValue]);
    setActiveTab(newValue);
    tableDataRefetch();
  };

  const [setTokenContainer, { error: mutationError }] = useMutation(SET_TOKEN_CONTAINER, {
    onCompleted: result => {
      if (result.setTokenContainer.container_id === null) {
        showToast(
          `Removed Container Assignment for ${result.setTokenContainer.remote_name}`,
          'success'
        );
      } else {
        showToast(
          `Updated Container Assignment for ${result.setTokenContainer.remote_name} to ${result.setTokenContainer.container.name}`,
          'success'
        );
      }
      tableDataRefetch();
    },
    onError: err => {
      showToast(`Error updating ${err}`, 'error');
    }
  });

  useEffect(() => {
    if (!tableDataData) {
      return;
    }

    if (tableDataData?.linktokenRelationships?.length > 0) {
      const tempTableData = tableDataData.linktokenRelationships.map(linktoken => {
        // TODO: Merge all these objects into one object, or just pass linktoken
        return {
          channel: {
            id: linktoken.id,
            type: linktoken.type,
            remote_id: linktoken.remote_id,
            remote_name: linktoken.remote_name,
            depth: linktoken.depth,
            hasSibling: linktoken.hasSibling,
            isEnabled: linktoken.enabled
          },
          owner: {
            first_name: linktoken.user.first_name,
            last_name: linktoken.user.last_name,
            avatar_url: linktoken.user.avatar_url,
            isEnabled: linktoken.enabled
          },
          name: {
            created_at: renderUILongDate(linktoken.created_at),
            avatar_url: linktoken.avatar_url,
            remote_name: linktoken.remote_name,
            remote_id: linktoken.remote_id,
            valid_token: linktoken.valid_token,
            error: linktoken.error,
            isEnabled: linktoken.enabled
          },
          organizationContainer: {
            id: linktoken.id,
            container_id: linktoken?.container?.id || -1,
            type: linktoken.type,
            isOwnedByCurrentUser: isLinktokenOwnedByCurrentUser(linktoken.user_id, currentUser.id),
            isEnabled: linktoken.enabled
          },
          action: {
            id: linktoken.id,
            type: linktoken.type,
            remote_name: linktoken.remote_name,
            valid_token: linktoken.valid_token,
            enabled: linktoken.enabled,
            user: linktoken.user,
            error: linktoken.error,
            refetch: tableDataRefetch,
            canModifyLinks: checkForPriv(currentPrivs, 'LINKS:TOGGLE'),
            canDeleteLinks: checkForPriv(currentPrivs, 'LINKS:DELETE'),
            isOwnedByCurrentUser: isLinktokenOwnedByCurrentUser(linktoken.user_id, currentUser.id),
            isUsedInCurrentContainer: isLinktokenUsedInCurrentContainer(
              linktoken?.container?.id || -1,
              currentContainer.id
            ),
            isEnabled: linktoken.enabled,
            orgTreeNetworkStatus,
            tableDataNetworkStatus,
            history
          }
        };
      });

      setTableData(tempTableData);
      setCount(tableDataData.linktokenRelationships.length);
    } else {
      setTableData([]);
    }
  }, [tableDataData]);

  useEffect(() => {
    tableDataRefetch({
      sortOrder
    });
  }, [currentContainer?.id]);

  useEffect(() => {
    tableDataRefetch({
      sortOrder
    });
  }, [sortOrder]);

  const tableColumns = [
    {
      name: 'channel',
      label: 'CHANNEL',
      options: {
        customBodyRender: customChannelRender,
        sort: false
      }
    },
    {
      name: 'name',
      label: 'NAME',
      options: {
        customBodyRender: customNameRender,
        sort: false,
        filter: true
      }
    },
    {
      name: 'owner',
      label: 'OWNER',
      options: {
        customBodyRender: customOwnerRender,
        sort: false,
        filter: true
      }
    },
    {
      name: 'organizationContainer',
      label: 'CONTAINER',
      options: {
        customBodyRender: item => {
          if (!isSubAccount(item.type)) {
            return <div>&mdash;</div>;
          }
          // you must own the object or have org update privs to remove these accounts.
          if (item.isOwnedByCurrentUser || checkForPriv(currentPrivs, 'ORG:UPDATE')) {
            return (
              <FormControl className={classes.formControl}>
                <OrgSelect
                  orgTree={orgTree}
                  item={item}
                  setToken={setTokenContainer}
                  refetch={orgTreeRefetch}
                />
              </FormControl>
            );
          }
          return item.value;
        },
        sort: false
      }
    },
    {
      name: 'action',
      label: 'ACTION',
      options: {
        customBodyRender: customActionsRender,
        sort: false
      }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    pagination: false,
    filter: false,
    search: true,
    searchOpen: false,
    filterType: 'dropdown',
    responsive: 'standard',
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    searchPlaceholder: 'Search for Name, Account ID, or Owner',
    customSearch: (searchQuery, currentRow) => {
      let isFound = false;
      // quick and dirty client side search, searches full name and remote name.
      const full_name = `${currentRow[2].first_name} ${currentRow[2].last_name}`.toLowerCase();
      if (
        currentRow[0].remote_name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0 ||
        full_name.indexOf(searchQuery.toLowerCase()) >= 0 ||
        currentRow[0].remote_id.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
      ) {
        isFound = true;
      }

      return isFound;
    },
    draggableColumns: { enabled: false },
    count,
    sortOrder: { name: TABLE_COLUMN_VALUES[sortOrder[0]], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => {
      setSortOrder([TABLE_COLUMN_KEYS[changedColumn], direction]);
    },
    textLabels: {
      body: {
        noMatch: 'No linked accounts found'
      }
    }
  };

  const tableThemeOverrides = {
    MUIDataTable: {
      paper: {
        width: '100%',
        boxShadow: 'none'
      },
      responsiveBase: {
        position: 'unset !important',
        overflow: 'initial'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        padding: '30px 16px'
      }
    },
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        borderBottom: 'none'
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:hover': {
          backgroundColor: colors.athensGray
        }
      }
    },
    MUIDataTableBody: {
      emptyTitle: {
        paddingTop: '30px'
      }
    }
  };

  // Loading is a bit of strange state. The useQuery State 'loading' will fire if a
  // initial load is underway, but once 'notifyOnNetworkStatusChange' is enabled, it will fire on every refetch or load.
  //
  // To prevent a full page reload and only disable the menus, we just look at network status

  if (orgTreeError) {
    return <AlbError refetch={orgTreeRefetch} error={orgTreeError} toast={false} />;
  }

  if (mutationError) {
    return <AlbError refetch={tableDataRefetch} error={mutationError} toast={false} />;
  }

  const a11yProps = index => {
    return {
      id: `account-tab-${index}`,
      'aria-controls': `account-tabpanel-${index}`
    };
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={
              <Tooltip title="These are accounts that you have connected to Alembic. Use this view to manage your accounts and connect them to containers in your organization.">
                <span>
                  Accounts you have connected to Alembic <InfoIcon fontSize="small" />
                </span>
              </Tooltip>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Tooltip
                title={`This shows all connected accounts from all users in your organization which are in the current container, "${currentContainer.name}"`}
              >
                <span>
                  Accounts enabled in this Container <InfoIcon fontSize="small" />
                </span>
              </Tooltip>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Tooltip title="This shows all enabled accounts across all users and containers in your organization.">
                <span>
                  Accounts enabled in your Organization <InfoIcon fontSize="small" />
                </span>
              </Tooltip>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        {tableDataLoading || orgTreeLoading ? (
          <AlbLoading />
        ) : (
          <AlbTable
            tableData={tableData}
            tableColumns={tableColumns}
            tableOptions={tableOptions}
            tableThemeOverrides={tableThemeOverrides}
          />
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {tableDataLoading || orgTreeLoading ? (
          <AlbLoading />
        ) : (
          <AlbTable
            tableData={tableData}
            tableColumns={tableColumns}
            tableOptions={tableOptions}
            tableThemeOverrides={tableThemeOverrides}
          />
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <HasAnyPriv privs={['LINKS:READ']} showAlert>
          {tableDataLoading || orgTreeLoading ? (
            <AlbLoading />
          ) : (
            <AlbTable
              tableData={tableData}
              tableColumns={tableColumns}
              tableOptions={tableOptions}
              tableThemeOverrides={tableThemeOverrides}
            />
          )}
        </HasAnyPriv>
      </TabPanel>
    </Box>
  );
};

LinkedAccountTable.propTypes = {
  history: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  currentContainer: PropTypes.shape().isRequired,
  currentPrivs: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    currentContainer: state.auth.currentContainer,
    currentPrivs: state.auth.currentPrivs
  };
};

export default connect(mapStateToProps)(withRouter(LinkedAccountTable));
