/* eslint-disable import/prefer-default-export */
const MIX_GROUP_OPTIONS = [
  { value: 'BRAND_EQUITY', title: 'Brand' },
  { value: 'DEMAND_GENERATION', title: 'Demand' },
  { value: 'AUDIENCE_INTERACTION', title: 'Interaction' },
  { value: 'CONVERSION', title: 'Conversion' }
];

const MIX_GROUP_OPTIONS_WITH_OVERALL = [
  { value: 'OVERALL', title: 'Overall' },
  ...MIX_GROUP_OPTIONS
];

const UGC_EDITABLE_LABEL_EVENT_TYPES = [
  'GA_USERS_BY_CHANNEL_GROUPING',
  'GA_USERS_BY_CAMPAIGN',
  'GA4_TOTAL_USERS_BY_DEFAULT_CHANNEL_GROUPING',
  'GA4_TOTAL_USERS_BY_SESSION_CAMPAIGN_NAME',
  'AA_USERS_BY_CAMPAIGN',
  'AA_USERS_BY_MARKETING_CHANNEL',

  'GA_USERS_BY_SOURCE',
  'GA4_TOTAL_USERS_BY_SOURCE',
  'AA_USERS_BY_SOURCE',

  'GA_USERS_BY_PAGE_PATH',
  'GA4_TOTAL_USERS_BY_PAGE_PATH',
  'AA_USERS_BY_PAGE_PATH'
];

const refetchQueries = [
  'attentionActionEventTable',
  'attentionActionTopEventsByCategory',
  'attentionActionQuad'
];

export {
  MIX_GROUP_OPTIONS,
  MIX_GROUP_OPTIONS_WITH_OVERALL,
  UGC_EDITABLE_LABEL_EVENT_TYPES,
  refetchQueries
};
