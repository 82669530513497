import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { CAUSAL_CHAINS_SUMMARY } from 'gql/causalChainsSummary';
import setPageTitle from 'util/titles';
import InsightsSummary from './InsightsSummary';

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontFamily: 'Poppins',
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 40
  }
});

const DashboardInsights = () => {
  const classes = useStyles();

  const [justifyData, setJustifyData] = useState([]);

  const timeSlice = 'ThisQuarter';

  const { data, loading, refetch } = useQuery(CAUSAL_CHAINS_SUMMARY, {
    variables: { timeSlice },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setPageTitle('Causal');
  }, []);

  useEffect(() => {
    if (data?.causalChainsSummary) {
      const tempJustify = data.causalChainsSummary.causal_chains;

      setJustifyData(tempJustify);
    } else {
      setJustifyData([]);
    }
  }, [data]);

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {!loading && <InsightsSummary justifyData={justifyData} refetch={refetch} />}
    </Grid>
  );
};

DashboardInsights.propTypes = {};

export default DashboardInsights;
