import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { ARTICLE_SEARCHES_AGG } from 'gql/articleSearch';
import { SHARE_OF_VOICE_REPORT, CREATE_SHARE_OF_VOICE_REPORT } from 'gql/shareOfVoice';
import { showToast } from 'contexts/ToastContext';
import FIELDS from 'components/AnalyticsShareOfVoice/shareOfVoiceConsts';
import { goToRoute } from 'util/routesHelpers';
import { CreateEarnedMediaAnalyticsPath } from 'util/paths';
import { withRouter } from 'react-router-dom';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { Grid, Dialog, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import colors from 'util/colors';
import handleGraphQLError from 'util/error';
import AlembicModalForm from './AlembicModalForm';

const styles = {
  emptyGrid: {
    width: 'auto',
    margin: '20px'
  },
  emptyMessage: {
    color: 'red',
    fontSize: '12px',
    marginBottom: '10px'
  },
  fixButton: {
    color: colors.navy,
    fontWeight: 600,
    width: '250px'
  }
};

const title = 'Create Competitive Intelligence Report';
const note =
  'In order to create a Competitive Intelligence report, please select a Primary Report and between 1 and 6 3rd Party Media Reports.';

const initialFields = [
  {
    fieldName: FIELDS.PRIMARY_REPORT_ID,
    title: 'Primary Report',
    type: 'dropdown',
    options: [
      {
        value: '',
        name: ''
      }
    ],
    validators: ['required']
  },
  {
    fieldName: FIELDS.EARNED_MEDIA_REPORT_IDS,
    title: '3rd Party Media Reports',
    type: 'checkboxDropdown',
    options: [
      {
        value: '',
        name: ''
      }
    ],
    validators: ['required']
  },
  {
    fieldName: FIELDS.NAME,
    title: 'Competitive Intelligence Report Name',
    validators: ['required']
  }
];

/**
 * @summary This component renders a modal for creating or editing a Competitive Intelligence report. It's a wrapper for AlembicModalForm.
 * When editing, the fields will be pre-filled, but it ultimately creates a new modified copy of the original, instead of modifying the original report.
 * @name CreateShareOfVoiceModal
 * @param {boolean} open - dictates whether or not the modal is open or closed.
 * @param {number} existingId - if present, existing values are passed in and a fetch is performed. The component is then used for editing existing values that are then saved as a new report.
 * @param {function} handleCancel - handler to close the modal
 * @param {Object} history - used for routing
 * @param {function} refetchTableData - refreshes table data once a report is created.
 * @param {Object} classes - Material UI styles prop
 * @return {Object} - React JSX
 */

const CreateShareOfVoiceModal = ({
  open,
  existingId,
  handleCancel,
  history,
  refetchTableData,
  classes
}) => {
  const { data, loading, error } = useQuery(ARTICLE_SEARCHES_AGG, {
    enabled: true,
    fetchPolicy: 'network-only'
  });
  const { data: shareOfVoiceReportData, error: shareOfVoiceReportError } = useQuery(
    SHARE_OF_VOICE_REPORT,
    {
      skip: !existingId,
      variables: {
        id: existingId
      }
    }
  );

  const [
    createShareOfVoiceReport,
    { data: createReportData, error: createReportError }
  ] = useMutation(CREATE_SHARE_OF_VOICE_REPORT);

  const [formFields, updateFormFields] = useState(initialFields);
  const [currentObj, setCurrentObj] = useState({});

  useEffect(() => {
    if (createReportData) {
      showToast(`A new Competitive Intelligence Report was created.`, 'success');
      refetchTableData();
      handleCancel(false);
    }

    if (createReportError) {
      handleGraphQLError(createReportError);
    }
  }, [createReportData, createReportError]);

  useEffect(() => {
    let updatedFormFields = formFields;

    if (data?.listArticleSearches?.rows?.length) {
      const selectOptions = [];

      data.listArticleSearches.rows.forEach(articleSearch => {
        selectOptions.push({
          value: articleSearch.id,
          name: articleSearch.name
        });

        if (articleSearch.article_search_filters) {
          articleSearch.article_search_filters.forEach(filter => {
            selectOptions.push({
              value: `${articleSearch.id}.${filter.id}`,
              name: `${articleSearch.name} Filter Id: ${filter.id}`
            });
          });
        }
      });

      updatedFormFields = formFields.map(field => {
        if (
          field.fieldName === FIELDS.PRIMARY_REPORT_ID ||
          field.fieldName === FIELDS.EARNED_MEDIA_REPORT_IDS
        ) {
          return {
            ...field,
            options: selectOptions
          };
        }

        return field;
      });

      updateFormFields(updatedFormFields);
    }

    if (shareOfVoiceReportData?.shareOfVoiceReport) {
      const { name, article_searches: articleSearches } = shareOfVoiceReportData.shareOfVoiceReport;
      const fieldObj = {};

      formFields.forEach(field => {
        let fieldValue = '';

        if (field.fieldName === FIELDS.PRIMARY_REPORT_ID && articleSearches?.length) {
          fieldValue = articleSearches[0].id || '';
        }

        if (field.fieldName === FIELDS.EARNED_MEDIA_REPORT_IDS) {
          fieldValue = articleSearches.map(({ id: articleSearchId }) => articleSearchId);
        }

        if (field.fieldName === FIELDS.NAME) {
          fieldValue = name;
        }

        fieldObj[field.fieldName] = fieldValue;
      });

      setCurrentObj(fieldObj);
    }

    if (error || shareOfVoiceReportError) {
      const errorMessage = error || shareOfVoiceReportError;
      showToast('Error getting reports, please try again later.', errorMessage);
    }
  }, [data, error, shareOfVoiceReportData, shareOfVoiceReportError]);

  const setUpEarnedMediaReports = () => goToRoute(CreateEarnedMediaAnalyticsPath, history);

  // We can create a SOV report either with article searches, or article search filters.
  // If the submitted id is in the format "1.3" - we are submitting an article search filter, the id of which is 3.
  const submitCreateShareOfVoiceReport = fieldObj => {
    const { primaryReportId, earnedMediaReportIds, name } = fieldObj;

    let finalPrimaryReport = primaryReportId;
    let primaryArticleFilterId = null;
    // Is Primary report an article search filter?
    if (primaryReportId.includes('.')) {
      // grab the id after the period - that's the article search filter id.
      [finalPrimaryReport, primaryArticleFilterId] = primaryReportId.split('.');
    }

    // split up filters and article searches into separate variables for the mutation.
    const finalEarnedMediaReportIds = [];
    const finalEarnedMediaFilterIds = [];

    earnedMediaReportIds.forEach(id => {
      if (id.includes('.')) {
        const filterId = id.split('.')[1];
        finalEarnedMediaFilterIds.push(filterId);
      } else {
        finalEarnedMediaReportIds.push(id);
      }
    });

    if (primaryReportId && earnedMediaReportIds && name) {
      createShareOfVoiceReport({
        variables: {
          primaryReportId: finalPrimaryReport,
          primaryArticleFilterId,
          earnedMediaReportIds: finalEarnedMediaReportIds,
          earnedMediaFilterIds: finalEarnedMediaFilterIds,
          name,
          enabled: true
        }
      });
    }
  };

  const handleRemovedFields = value => {
    const allOptions =
      formFields.find(field => field.fieldName === FIELDS.PRIMARY_REPORT_ID)?.options || [];

    const updatedFormFields = formFields.map(field => {
      if (field.fieldName === FIELDS.EARNED_MEDIA_REPORT_IDS) {
        const updatedOptions = [];

        allOptions.forEach(option => {
          if (option.value !== value) {
            updatedOptions.push(option);
          }
        });

        return {
          ...field,
          options: updatedOptions
        };
      }

      return field;
    });

    updateFormFields(updatedFormFields);
  };

  if (loading) {
    return (
      <Dialog maxWidth="lg" open={open} onClose={handleCancel}>
        <Grid className={classes.emptyGrid}>
          <AlbLoading />
        </Grid>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog maxWidth="lg" open={open} onClose={handleCancel}>
        <Grid className={classes.emptyGrid}>
          <AlbError />
        </Grid>
      </Dialog>
    );
  }

  if (data?.listArticleSearches?.count < 2) {
    return (
      <Dialog maxWidth="lg" open={open} onClose={handleCancel}>
        <Grid
          container
          className={classes.emptyGrid}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Typography className={classes.emptyMessage}>
            You need to have at least 2 reports set up to use this feature.
          </Typography>
          <Button
            className={classes.fixButton}
            variant="outlined"
            onClick={setUpEarnedMediaReports}
          >
            Set up 3rd Party Media Reports
          </Button>
        </Grid>
      </Dialog>
    );
  }

  if (data?.listArticleSearches?.count >= 2) {
    return (
      <AlembicModalForm
        open={open}
        title={title}
        note={note}
        fields={formFields}
        handleRemovedFields={handleRemovedFields}
        saveTitle="Save"
        cancelTitle="Cancel"
        handleConfirm={submitCreateShareOfVoiceReport}
        handleCancel={handleCancel}
        onClose={handleCancel}
        variant="mediumGrid"
        currentObject={existingId && currentObj}
      />
    );
  }

  return null;
};

CreateShareOfVoiceModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  existingId: PropTypes.string,
  history: PropTypes.shape().isRequired,
  refetchTableData: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired
};

CreateShareOfVoiceModal.defaultProps = {
  existingId: null
};

export default withRouter(withStyles(styles)(CreateShareOfVoiceModal));
