/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'util/colors';
import AnalyticsBox from 'components/AnalyticsBox';
import {
  GET_TOP_CHANNELS_AND_POSTS_RECREATE_COST_REPORT,
  GET_TOP_DOMAINS_AND_ARTICLES_RECREATE_COST_REPORT
} from 'gql/recreateCostReport';
import TopChannelTable from './Tables/TopChannelTable';
import TopArticleTable from './Tables/TopArticleTable';
import TopDomainTable from './Tables/TopDomainTable';
import TopPostTable from './Tables/TopPostTable';
import MediaValueCard from './MediaValueCard';

const useStyles = makeStyles(() => ({
  topTable: {
    marginTop: '-37px'
  },
  branchHeight: {
    height: '50px'
  },
  branchHalfHeight: {
    height: '25px'
  },
  borderRightBranch: {
    borderRight: 'solid 1px #6F6F6F'
  },
  borderLeftBranch: {
    borderLeft: 'solid 1px #6F6F6F'
  },
  borderTopBranch: {
    borderTop: 'solid 1px #6F6F6F'
  },
  flex: {
    display: 'flex'
  }
}));

/**
 * @summary Renders preview & final container for cost to recreate reports
 * @name MediaValueContainer
 * @param {Object} props - React props passed to this component
 * @param {Object} props.recreateCostReport - The recreateCostReport
 * @param {Integer} props.recreateCostReport.article_search_id - Article search ID to pass to GQL
 * @param {String} props.valuationType - The type of 'cpm' or 'cpa' calculation
 * @param {String} props.startDate - Start date to query on
 * @param {String} props.endDate - End date for to query by
 * @param {Boolean} props.isPreview - true returns a preview, false returns a final cost to recreate report
 * @param {String} props.reportId - either an article search id or article search filter id is required when preview is true
 * @param {Boolean} props.isFilter - indicates whether the report id is an article search or an article search filter.
 * @param {String} props.costToRecreateId - cost to recreate id is required when preview is false
 * @return {Object} - React JSX
 */
const MediaValueContainer = props => {
  const {
    valuationType,
    startDate,
    endDate,
    isPreview,
    reportId,
    isFilter,
    costToRecreateId,
    costToRecreateFields,
    useLifetimeValue
  } = props;

  const classes = useStyles();

  const [ownedMediaValue, setOwnedMediaValue] = useState(0);
  const [earnedMediaValue, setEarnedMediaValue] = useState(0);

  const [topChannels, setTopChannels] = useState([]);
  const [topPosts, setTopPosts] = useState([]);

  const [topDomains, setTopDomains] = useState([]);
  const [topArticles, setTopArticles] = useState([]);

  const {
    loading: topOwnedLoading,
    data: topOwnedData,
    refetch: refetch_top_channels_and_posts
  } = useQuery(GET_TOP_CHANNELS_AND_POSTS_RECREATE_COST_REPORT, {
    variables: {
      id: costToRecreateId,
      startDate,
      endDate,
      valuationType,
      isPreview,
      useLifetimeValue
    },
    fetchPolicy: 'network-only'
  });

  // previewing CTR, field values change on user intent
  useEffect(() => {
    refetch_top_channels_and_posts({
      postTextKeywords: costToRecreateFields?.postTextKeywords,
      postTypes: costToRecreateFields?.postTypes,
      videoTags: costToRecreateFields?.videoTags,
      linkedAccounts: costToRecreateFields?.linkedAccounts,
      imageTags: costToRecreateFields?.imageTags,
      mediaTypes: costToRecreateFields?.mediaTypes
    });
  }, [costToRecreateFields]);

  const { loading: topEarnedLoading, data: topEarnedData } = useQuery(
    GET_TOP_DOMAINS_AND_ARTICLES_RECREATE_COST_REPORT,
    {
      variables: {
        id: costToRecreateId,
        startDate,
        endDate,
        valuationType,
        isPreview,
        reportId,
        isFilter
      },
      fetchPolicy: 'network-only'
    }
  );

  // updates Owned Media Value aggregate
  useEffect(() => {
    if (topOwnedData?.recreateCostReportPreview?.topChannels) {
      setTopChannels(topOwnedData?.recreateCostReportPreview?.topChannels);
    } else {
      setTopChannels([]);
    }

    if (topOwnedData?.recreateCostReportPreview?.topPosts) {
      setTopPosts(topOwnedData?.recreateCostReportPreview?.topPosts);
    } else {
      setTopPosts([]);
    }

    if (topOwnedData?.recreateCostReportPreview?.totalValuation) {
      setOwnedMediaValue(topOwnedData?.recreateCostReportPreview?.totalValuation);
    } else {
      setOwnedMediaValue(0);
    }

    if (topEarnedData?.recreateCostReportEarnedPreview?.topDomains) {
      setTopDomains(topEarnedData?.recreateCostReportEarnedPreview?.topDomains);
    } else {
      setTopDomains([]);
    }

    if (topEarnedData?.recreateCostReportEarnedPreview?.topArticles) {
      setTopArticles(topEarnedData?.recreateCostReportEarnedPreview?.topArticles);
    } else {
      setTopArticles([]);
    }

    if (topEarnedData?.recreateCostReportEarnedPreview?.totalValuation) {
      setEarnedMediaValue(topEarnedData?.recreateCostReportEarnedPreview?.totalValuation);
    } else {
      setEarnedMediaValue(0);
    }
  }, [topOwnedData, topEarnedData]);

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        <Grid item sm={6}>
          <MediaValueCard
            metricName="Digital Media Value"
            pricingModel={valuationType === 'cpm' ? 'by impressions' : 'by engagement'}
            value={earnedMediaValue + ownedMediaValue}
            bgcolor="primary.main"
            tooltipKey="digitalMediaValue"
          />
        </Grid>

        {/* Branch */}
        <Grid container space={3} className={classes.branchHalfHeight}>
          <Grid item xs={6} className={classes.borderRightBranch} />
        </Grid>

        {/* Leaf */}
        <Grid container space={3} className={classes.branchHalfHeight}>
          <Grid item xs={3} className={classes.borderRightBranch} />
          <Grid item xs={3} className={classes.borderTopBranch} />
          <Grid item xs={3} className={classes.borderTopBranch} />
          <Grid item xs={3} className={classes.borderLeftBranch} />
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MediaValueCard
              metricName="Owned Media Value"
              pricingModel={valuationType === 'cpm' ? 'by impressions' : 'by engagement'}
              value={ownedMediaValue}
              bgcolor={colors.purple}
              tooltipKey="ownedMediaValue"
            />
          </Grid>
          <Grid item xs={6}>
            <MediaValueCard
              metricName="3rd Party Media Value"
              pricingModel={valuationType === 'cpm' ? 'by impressions' : 'by engagement'}
              value={earnedMediaValue}
              bgcolor={colors.pink}
              tooltipKey="earnedMediaValue"
            />
          </Grid>
        </Grid>

        {/* Stem */}
        <Grid container space={3} className={classes.branchHeight}>
          <Grid item xs={3} className={classes.borderRightBranch} />
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3} className={classes.borderLeftBranch} />
        </Grid>

        <Grid container spacing={3} className={classes.topTable}>
          <Grid item xs={6} className={classes.flex}>
            <AnalyticsBox
              noMargin
              noPadding
              tabs={['Top Accounts', 'Top Posts']}
              onChange={() => {}}
              prefix="mv"
            >
              <TopChannelTable
                isLoading={topOwnedLoading}
                topChannels={topChannels}
                valuationType={valuationType}
              />
              <TopPostTable
                isLoading={topOwnedLoading}
                topPosts={topPosts}
                valuationType={valuationType}
              />
            </AnalyticsBox>
          </Grid>
          <Grid item xs={6} className={classes.flex}>
            <AnalyticsBox
              noMargin
              noPadding
              prefix="mv"
              tabs={['Top Domains', 'Top Articles']}
              onChange={() => {}}
            >
              <TopDomainTable isLoading={topEarnedLoading} topDomains={topDomains} />
              <TopArticleTable isLoading={topEarnedLoading} topArticles={topArticles} />
            </AnalyticsBox>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

MediaValueContainer.propTypes = {
  valuationType: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isPreview: PropTypes.bool,
  reportId: PropTypes.string,
  isFilter: PropTypes.bool,
  costToRecreateId: PropTypes.string,
  costToRecreateFields: PropTypes.shape(),
  useLifetimeValue: PropTypes.bool
};

MediaValueContainer.defaultProps = {
  valuationType: null,
  startDate: null,
  endDate: null,
  isPreview: false,
  reportId: null,
  isFilter: false,
  costToRecreateId: null,
  costToRecreateFields: null,
  useLifetimeValue: null
};

export default MediaValueContainer;
