import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AlbTable from 'components/AlbTable';
import { formatCurrencyRound } from 'util/formatCurrency';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftAligned: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  }
});

/**
 * @summary This component renders a CTR's Top [X] HTML table
 * @name CostToRecreateTopTable
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.tableColumns - Column titles for the table
 * @param {String} props.tableColumns[].name - Name of property reference in tableColumns object
 * @param {String} props.tableColumns[].label - Label rendered in the column's head
 * @param {Object[]} props.tableData - An array of objects referencing JSX elements for each column
 * @return {Object} - React JSX
 */
const CostToRecreateTopTable = props => {
  const { tableColumns, tableData } = props;
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [count] = useState(0);

  const tableThemeOverrides = {
    ...(tableData.length && {
      MuiTableRow: {
        root: {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr'
        }
      },
      MuiTableBody: {
        root: {
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr '.repeat(tableData.length)
        }
      }
    }),
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        height: '100%'
      },
      responsiveBase: {
        position: 'unset !important',
        height: '100% !important'
      },
      tableRoot: {
        height: '100%'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap'
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none'
      },
      body: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        height: '100%'
      }
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    sort: false,
    pagination: false,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count
  };

  const customMediaTypeRender = text => <Grid className={classes.leftAligned}>{text}</Grid>;

  const customMetricRender = text => (
    <Grid className={classes.flex}>
      <div align="center" style={{ flex: 1 }}>
        {Math.round(text).toLocaleString()}
      </div>
    </Grid>
  );

  const customValuationRender = text => (
    <Grid className={classes.flex}>
      <div align="right" style={{ flex: 1 }}>
        {text < 0 ? '-' : ''}${formatCurrencyRound(Math.abs(text))}
      </div>
    </Grid>
  );

  const [mediaType, metric, valuation] = tableColumns;

  const styledTableColumns = [
    { ...mediaType, options: { customBodyRender: customMediaTypeRender } },
    {
      ...metric,
      options: {
        customBodyRender: customMetricRender,
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } })
      }
    },
    {
      ...valuation,
      options: {
        customBodyRender: customValuationRender,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } })
      }
    }
  ];

  return (
    <AlbTable
      tableData={tableData}
      tableColumns={styledTableColumns}
      tableOptions={tableOptions}
      tableThemeOverrides={tableThemeOverrides}
    />
  );
};

CostToRecreateTopTable.propTypes = {
  tableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default CostToRecreateTopTable;
