import store from 'store';
import isFeatureVisible from 'util/isFeatureVisible';
import { FEATURE_FLAG } from 'util/featureFlags';
import {
  LinkAccountsPath,
  // InsightPath,
  CausalPath,
  // OptimizePath
  ObserveEventsPathV4
} from './paths';

/**
 * Get the default route to dashboard
 * @param {number} currentUser container id to prefix
 * @param {string} containerIdOverride container id to use instead of currentUser.home_container_id (for non-link-only users)
 * @returns {String} path to default dashboard view
 */
const getDashboardRoute = (currentUser, containerIdOverride) => {
  const { featureFlags } = store.getState().auth;

  // if they are not logged in, then our goal is to send them to the login page
  if (!currentUser) {
    return '/users/login';
  }

  // by default the next destination is the dashboard for the user's home
  // container and the /observe/events view/page
  let containerId = containerIdOverride || currentUser.home_container_id;
  // let path = OptimizePath;
  let path = ObserveEventsPathV4;

  // if (isFeatureVisible(featureFlags, FEATURE_FLAG.INSIGHT_DASHBOARD)) {
  //   path = InsightPath;
  // }

  // if this feature flag is set, then the Causal page is shown by default instead
  if (isFeatureVisible(featureFlags, FEATURE_FLAG.CAUSAL_DASHBOARD)) {
    path = CausalPath;
  }

  // if you are a link only user, your dashboard is the link accounts page

  if (currentUser.role?.role_type === 'S' && currentUser.role?.name === 'Link Only') {
    containerId = currentUser.home_container_id;
    path = LinkAccountsPath;
  }

  return `/${containerId}${path}`;
};

export default getDashboardRoute;
