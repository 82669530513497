import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'util/colors';
import {
  FacebookLogo as facebook,
  InstagramLogo as instagram,
  LinkedinLogo as linkedin,
  TwitterLogo as twitter,
  GoogleLogo as google,
  RedditLogo as reddit
} from 'util/assets';
import { getDatesForAnalytics } from 'util/date';
import { SHORTLINK_DISTRIBUTION } from 'gql/analytics';
import { isEqual } from 'lodash';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  iconOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    pointerEvents: 'none',

    '& > span': {
      position: 'absolute',
      font: 'normal 18px Poppins'
    },

    '& > img': {
      position: 'absolute',
      height: '30px',
      width: '30px'
    }
  }
});

const ShortlinkChannels = props => {
  const { dates, shortlink } = props;

  const classes = useStyles();

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data: shortlinkData } = useQuery(SHORTLINK_DISTRIBUTION, {
    variables: {
      column: 'referrer_source',
      startDate,
      endDate,
      // eventual support for querying outside of the user's current container
      // containerIds: [sessionStorage.current_container],
      campaignIds: [],
      ...(shortlink !== null && { shortlinkIds: [shortlink] })
    }
  });

  const [graphData, setGraphData] = useState([]);
  const [icons, setIcons] = useState([]);
  const [windowWidth, setWindowWidth] = useState(null);

  const canvasContext = useRef();
  const ceiling = useRef(0);
  const iconsRef = useRef([]);

  const handleResize = useCallback(() => setWindowWidth(window.innerWidth), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (shortlinkData?.shortlinkDistribution) {
      setGraphData(shortlinkData.shortlinkDistribution);
    }
  }, [shortlinkData]);

  useEffect(() => {
    if (iconsRef.current.length) setIcons(iconsRef.current);
  }, [iconsRef.current, windowWidth]);

  const data = canvas => {
    const percentages = graphData.map(dataPoint => dataPoint.y);

    canvasContext.current = canvas.getContext('2d');
    ceiling.current = Math.max(percentages);

    return {
      labels: graphData.map(dataPoint => dataPoint.x),
      datasets: [
        {
          data: percentages,
          backgroundColor: colors.darkBlue30
        }
      ]
    };
  };

  const iconImages = {
    facebook,
    instagram,
    linkedin,
    twitter,
    google,
    reddit
  };

  const animation = {
    onComplete: ({ chart }) => {
      const ctx = canvasContext.current;

      if (chart.data.datasets.length) {
        chart.data.datasets.forEach(({ data: dataPoint, _meta }) => {
          const id = Object.keys(_meta)[0];

          const iconList = _meta[id].data.map(({ _view }, i) => {
            const { base, x, y, label } = _view;
            const value = `${Math.round(dataPoint[i])}%`;

            ctx.font = 'normal 18px Poppins';
            ctx.fillStyle = colors.navy;
            ctx.fillText(value, x + 10, y + 5);

            return { label, base, y };
          });

          if (!iconsRef.current.length || !isEqual(iconsRef.current, iconList)) {
            iconsRef.current = iconList;
          }
        });
      }
    },
    duration: 0
  };

  const afterBuildTicks = ({ ticks }) => ticks.concat([Math.max(...ticks) + 10]);

  const options = {
    animation,
    hover: {
      mode: 'nearest',
      intersect: true,
      animationDuration: 0
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true
          },
          ticks: {
            fontColor: 'transparent'
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true
          },
          ticks: {
            fontColor: 'transparent',
            beginAtZero: true,
            lineHeight: 0
          },
          afterBuildTicks
        }
      ]
    }
  };

  const TopChannelIcon = ({ label, base, y }) => {
    const left = `${base - 80}px`;
    const top = `${y - 16}px`;

    return iconImages[label] ? (
      <img key={label} style={{ left, top }} alt="icon" src={iconImages[label]} />
    ) : (
      <span key={label} style={{ left, top, color: colors.navy }}>
        {label.replace(label.charAt(0), label.charAt(0).toUpperCase())}
      </span>
    );
  };

  TopChannelIcon.propTypes = {
    label: PropTypes.string.isRequired,
    base: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  };

  return (
    <div className={classes.container}>
      <div className={classes.iconOverlay}>{!!icons.length && icons.map(TopChannelIcon)}</div>
      <HorizontalBar data={data} options={options} />
    </div>
  );
};

ShortlinkChannels.propTypes = {
  dates: PropTypes.shape().isRequired,
  shortlink: PropTypes.string
};

ShortlinkChannels.defaultProps = {
  shortlink: null
};

export default ShortlinkChannels;
