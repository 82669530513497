import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SET_DETECTING_BROADCAST_SEARCH } from 'gql/broadcastSearch';
import { SET_DETECTING_PODCAST_SEARCH } from 'gql/podcastSearch';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';
import { BROADCAST, PODCAST } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';

/** - Sets a 3rd party media report the default
 * @param {Boolean} isModalOpen - controls the state of the modal
 * @param {Function} onChange - callback function to toggle modal state and pass back result
 * @param {Boolean} setDetecting - indicates whether to set search to detect events or turn the detecting off
 * @param {String} searchId - id of report to set to default
 * @param {String} searchName - name of report to display in modal
 * @param {String} mediaType - specify either podcast or broadcast/radio report which determines type of mutation to use
 * @returns - React JSX
 */
const DetectingMediaSearchModal = props => {
  const { isModalOpen, onChange, searchId, searchName, mediaType, setDetecting } = props;

  const [setDetectingBroadcastSearch, { error: broadcastError, data: broadcastData }] = useMutation(
    SET_DETECTING_BROADCAST_SEARCH
  );
  const [setDetectingPodcastSearch, { error: podcastError, data: podcastData }] = useMutation(
    SET_DETECTING_PODCAST_SEARCH
  );

  useEffect(() => {
    if (broadcastData?.setDetectingBroadcastSearch) {
      if (broadcastData.setDetectingBroadcastSearch.is_default) {
        showToast(`Report ${searchName} is now detecting events.`, 'success');
      }

      if (!broadcastData.setDetectingBroadcastSearch.is_default) {
        showToast(`Report ${searchName} stopped detecting events.`, 'success');
      }

      onChange({
        isModalOpen: false,
        newMediaSearchData: broadcastData.setDetectingBroadcastSearch
      });
    }

    if (podcastData?.setDetectingPodcastSearch) {
      if (podcastData.setDetectingPodcastSearch.is_default) {
        showToast(`Report ${searchName} is now detecting events.`, 'success');
      }

      if (!podcastData.setDetectingPodcastSearch.is_default) {
        showToast(`Report ${searchName} stopped detecting events.`, 'success');
      }

      onChange({
        isModalOpen: false,
        newMediaSearchData: podcastData.setDetectingPodcastSearch
      });
    }
  }, [broadcastData, podcastData]);

  useEffect(() => {
    if (broadcastError || podcastError) {
      onChange({
        isModalOpen: false
      });
    }
  }, [broadcastError, podcastError]);

  // in the db - is_default is the same as isDetecting. All the default reports are detecting events.
  const setDetectEvents = () => {
    if (mediaType === BROADCAST) {
      setDetectingBroadcastSearch({ variables: { id: searchId, setDetecting } });
    }

    if (mediaType === PODCAST) {
      setDetectingPodcastSearch({ variables: { id: searchId, setDetecting } });
    }
  };

  return (
    <>
      {isModalOpen && broadcastError && <AlbError toast error={broadcastError} />}
      {isModalOpen && podcastError && <AlbError toast error={podcastError} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Set Event Detection"
        body={
          <>
            Do you want to make this report {setDetecting ? 'detect' : 'stop detecting'}{' '}
            events:&nbsp;
            <span style={{ color: '#0A1734' }}>{searchName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle={`${setDetecting ? 'Start' : 'Stop'} Detecting`}
        isDelete={!setDetecting}
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={setDetectEvents}
      />
    </>
  );
};

DetectingMediaSearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  searchId: PropTypes.string,
  searchName: PropTypes.string,
  mediaType: PropTypes.string.isRequired,
  setDetecting: PropTypes.bool.isRequired
};

DetectingMediaSearchModal.defaultProps = {
  searchId: null,
  searchName: null
};

export default DetectingMediaSearchModal;
