import * as ActionTypes from 'actions/types';

/*
  When the App is first open redux is initialized with these values.
  These values are default null because we do not want anything to
  happen until we can determine if we can fetch the user data based
  on an established session.
*/
const initialState = {
  currentUser: null,
  currentContainer: null,
  isAuthenticated: null,
  currentPrivs: [],
  featureFlags: [],
  isLoading: false,
  mfaEnrolled: false,
  mfaPassed: false
};

// This is the key held in session storage to
// in sessionStorage, the key is 'SESSION_CURRENT_CONTAINER_KEY' and the value is the id of the current container.
export const SESSION_CURRENT_CONTAINER_KEY = 'SESSION_CURRENT_CONTAINER_KEY';

/**
 * Redux Reducer to handle all aspects of user authentication and local/session storage changes.
 * Does not handle the actual authentication process, just the state of the user's session.
 * @param {*} state current redux state
 * @param {*} action payload from dispatched action
 * @returns {Object} new state
 */
const authorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHECK_EXISTING_AUTH:
      return Object.assign({}, state, action);

    case ActionTypes.AUTHENTICATED:
      // set localStorage accordingly using the API as truth
      localStorage.setItem('alb_user_id', action.user.id);
      localStorage.setItem('alb_mfa_enabled', action.user.otp_enabled);
      localStorage.setItem('alb_login_expires', action.expires);

      if (action.token) {
        localStorage.setItem('alb_jwt_token', action.token);
      }

      sessionStorage.setItem(
        SESSION_CURRENT_CONTAINER_KEY,
        action.container ? action.container?.id : action.user.home_container_id
      );
      return Object.assign(
        {},
        state,
        { currentUser: { ...action.user } },
        { mfaEnrolled: action.user.otp_enabled },
        {
          currentContainer: {
            ...(action.container ? action.container : { id: action.user.home_container_id })
          }
        },
        {
          currentOrganization: {
            ...(action.organization
              ? action.organization
              : { id: action.user.current_organization_id })
          }
        },
        { isAuthenticated: true },
        { currentPrivs: action.privs },
        { featureFlags: action.featureFlags },
        { isLoading: false } // this state always marks the end of the user load
      );
    case ActionTypes.DEAUTHENTICATE:
      if (action.clearLocalStorage) {
        localStorage.removeItem('alb_user_id');
        localStorage.removeItem('alb_login_expires');
        localStorage.removeItem('alb_mfa_enabled');
        localStorage.removeItem('alb_mfa_passed');
        localStorage.removeItem('alb_jwt_token');
        sessionStorage.removeItem(SESSION_CURRENT_CONTAINER_KEY);
      }
      return Object.assign({}, state, initialState);
    case ActionTypes.SWITCH_CONTAINER:
      sessionStorage.setItem(SESSION_CURRENT_CONTAINER_KEY, action.container.id);
      return Object.assign({}, state, { currentContainer: action.container });
    case ActionTypes.UPDATE_CONTAINER:
      return Object.assign({}, state, { currentContainer: action.container });
    case ActionTypes.UPDATE_USER:
      localStorage.setItem('alb_mfa_enabled', action.user.otp_enabled);
      if (!action.user.otp_enabled) {
        // clear this out just to be sure.
        localStorage.setItem('alb_mfa_passed', 'false');
      }
      return Object.assign({}, state, { currentUser: action.user });
    case ActionTypes.MFA_CHANGE_STATE:
      localStorage.setItem('alb_mfa_passed', action.mfaPassed);
      if (action.expires) {
        // expiration time can change once MFA has completed. update it in localStorage.
        localStorage.setItem('alb_login_expires', action.expires);
      }
      // passing mfa will typically also come with a new jwt token, so update that in localStorage.
      if (action.token) {
        localStorage.setItem('alb_jwt_token', action.token);
      }
      return Object.assign({}, state, { mfaEnrolled: true, mfaPassed: action.mfaPassed });
    case ActionTypes.MFA_ENROLL:
      localStorage.setItem('alb_mfa_enabled', 'true');
      localStorage.setItem('alb_mfa_passed', 'true');
      return Object.assign({}, state, { mfaEnrolled: true, mfaPassed: true });
    default:
      return state;
  }
};

export default authorizationReducer;
