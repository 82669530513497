/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import DashboardIntelligenceDrawerContext from './DashboardIntelligenceDrawerContext';

class DashboardDrawerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDrawer = ({
      isDrawerOpen = false,
      event = null,
      isSourceSpike = false,
      isConversionOutlier = false,
      isPageSpike = false,
      isCorrelation = false,
      isMultiPostDetection = false,
      isDetectedEvent = false,
      isThirdPartyMedia = false
    }) => {
      this.setState({
        isDrawerOpen,
        isSourceSpike,
        isConversionOutlier,
        isPageSpike,
        isCorrelation,
        isMultiPostDetection,
        isDetectedEvent,
        isThirdPartyMedia,
        event
      });
    };

    this.state = {
      isDrawerOpen: false,
      isSourceSpike: false,
      isConversionOutlier: false,
      isPageSpike: false,
      isCorrelation: false,
      isMultiPostDetection: false,
      isDetectedEvent: false,
      isThirdPartyMedia: false,
      event: null,
      toggleDrawer: this.toggleDrawer
    };
  }

  render() {
    const { children } = this.props;
    return (
      <DashboardIntelligenceDrawerContext.Provider value={this.state}>
        {children}
      </DashboardIntelligenceDrawerContext.Provider>
    );
  }
}

DashboardDrawerWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardDrawerWrapper;
