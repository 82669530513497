import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Carousel from '@brainhubeu/react-carousel';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_SALESFORCE_REVENUE_TOP_PERFORMERS } from 'gql/salesforce';
import SocialLogo from 'components/SocialLogo';
import getSimpleSocialType from 'util/getSimpleSocialType';
import getCarouselPlugins from 'util/carouselPlugins';
import getValueAbbreviation from 'util/getValueAbbreviation';
import SalesforceBestPerformersActions from './SalesforceBestPerformersActions';
import formatSalesforceLabel from './formatSalesforceLabel';

const styles = makeStyles({
  card: {
    position: 'relative',
    width: '95%',
    height: '100%',
    backgroundColor: 'white',
    padding: '22px 24px 17px 24px',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '20px'
  },
  titleText: {
    display: '-webkit-box',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 500,
    lineClamp: 4,
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    color: '#0D1A3A',
    textAlign: 'center'
  },
  leadText: {
    fontSize: '8px',
    color: '#6F6F6F',
    textAlign: 'center'
  },
  metricRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5px'
  },
  metricText: {
    fontSize: '10px',
    color: '#6F6F6F',
    fontWeight: 500
  },
  valueText: {
    fontSize: '12px',
    color: '#000000'
  },
  carousel: {
    '& li': {
      padding: '0 10px'
    },
    '& button': {
      padding: '0px',
      '& svg': {
        height: '42px',
        width: '42px'
      }
    },
    '& button:hover': {
      opacity: 1
    }
  },
  line: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '4px',
    backgroundColor: '#C4C4C4',
    marginBottom: '20px',
    borderRadius: '5px'
  },
  rank: {
    height: '4px',
    backgroundColor: '#0A1734',
    borderRadius: '5px'
  }
});

const SalesforceBestPerformers = props => {
  const { linktokenId, startDate, endDate, selectedPeriodical } = props;
  const classes = styles();

  const [bestPerformers, setBestPerformers] = useState([]);

  const { data } = useQuery(GET_SALESFORCE_REVENUE_TOP_PERFORMERS, {
    variables: { linktokenId, startDate, endDate, periodical: selectedPeriodical },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.getSalesforceRevenueTopPerformers) {
      setBestPerformers(data.getSalesforceRevenueTopPerformers);
    }
  }, [data]);

  return (
    <Grid container style={{ marginBottom: '37px' }}>
      {bestPerformers.length > 0 && (
        <Carousel
          className={classes.carousel}
          plugins={getCarouselPlugins(6, false)}
          draggable={false}
          breakpoints={{
            960: {
              plugins: getCarouselPlugins(2, false)
            },
            1280: {
              plugins: getCarouselPlugins(4, false)
            }
          }}
        >
          {bestPerformers.map(item => (
            <Box key={item.id} className={classes.card}>
              <div className={classes.titleContainer}>
                <SocialLogo
                  width={70}
                  height={70}
                  type={getSimpleSocialType(item.linktoken.type, true)}
                />
                <Typography className={classes.titleText}>{formatSalesforceLabel(item)}</Typography>
                <Typography className={classes.leadText}>
                  {item.opportunity_from_lead ? '' : 'Non-'}Lead Generated
                </Typography>
              </div>
              <div className={classes.metricRow}>
                <Typography className={classes.metricText}>Revenue</Typography>
                <Typography className={classes.valueText}>
                  {getValueAbbreviation(item.revenue, true)}
                </Typography>
              </div>
              <div className={classes.line}>
                <div
                  className={classes.rank}
                  style={{ width: `${item.revenue_rel_rank * 100}%` }}
                />
              </div>
              <div className={classes.metricRow}>
                <Typography className={classes.metricText}>Close Rate</Typography>
                <Typography className={classes.valueText}>
                  {getValueAbbreviation(item.close_rate)}%
                </Typography>
              </div>
              <div className={classes.line}>
                <div
                  className={classes.rank}
                  style={{ width: `${item.close_rate_rel_rank * 100}%` }}
                />
              </div>
              <div className={classes.metricRow}>
                <Typography className={classes.metricText}>Deal Size</Typography>
                <Typography className={classes.valueText}>
                  {getValueAbbreviation(item.deal_size, true)}
                </Typography>
              </div>
              <div className={classes.line}>
                <div
                  className={classes.rank}
                  style={{ width: `${item.deal_size_rel_rank * 100}%` }}
                />
              </div>
              <div className={classes.metricRow}>
                <Typography className={classes.metricText}>Volume</Typography>
                <Typography className={classes.valueText}>
                  {getValueAbbreviation(item.volume)}
                </Typography>
              </div>
              <div className={classes.line}>
                <div className={classes.rank} style={{ width: `${item.volume_rel_rank * 100}%` }} />
              </div>
              <SalesforceBestPerformersActions item={item} />
            </Box>
          ))}
        </Carousel>
      )}
    </Grid>
  );
};

SalesforceBestPerformers.propTypes = {
  linktokenId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedPeriodical: PropTypes.string
};

SalesforceBestPerformers.defaultProps = {
  linktokenId: null,
  startDate: null,
  endDate: null,
  selectedPeriodical: null
};

export default SalesforceBestPerformers;
