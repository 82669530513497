import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDateRange } from 'util/date';
import { AlbTooltip, getToolTipTitleValue } from 'components/AlbTooltip';
import TabWithTooltip from 'components/TabWithTooltip';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    background: '#FFFFFF',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  content: {
    flex: '1',
    padding: '16px 24px'
  },
  dates: {
    padding: '16px 24px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    size: '14px',
    color: '#6F6F6F'
  },
  tabOverRide: {
    minWidth: 'unset',
    maxWidth: 'unset'
  }
});

const AnalyticsBox = props => {
  const {
    tabs,
    children,
    singleChild,
    dates,
    onChange,
    noPadding,
    noMargin,
    prefix,
    tooltip,
    customComponent
  } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      className={classes.container}
      style={noMargin ? { marginLeft: '0px', marginRight: '0px' } : null}
    >
      <Grid container justifyContent="space-between" alignItems="center" style={{ width: 'auto' }}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
            onChange(newValue);
          }}
        >
          {tabs.map(tab => {
            return (
              <Tab
                key={tab}
                label={
                  <TabWithTooltip tabTitle={tab} tooltipTitle={getToolTipTitleValue(tab, prefix)} />
                }
                classes={{
                  root: classes.tabOverRide
                }}
                style={{
                  cursor: tabs.length === 1 ? 'default' : 'pointer'
                }}
              />
            );
          })}
        </Tabs>
        {tooltip && (
          <Grid item style={{ paddingRight: 24 }}>
            <AlbTooltip title={tooltip} />
          </Grid>
        )}
        {dates && <div className={classes.dates}>{formatDateRange(dates.start, dates.end)}</div>}
        {customComponent && <>{customComponent}</>}
      </Grid>
      <Grid
        container
        direction="column"
        className={noPadding ? '' : classes.content}
        style={{ flex: 1 }}
      >
        {children &&
          React.Children.map(children, (child, index) => {
            const { table, shadow, tableOnly, ...rest } = child.props;

            if (singleChild) {
              return (
                <div style={tableOnly ? { flex: 1, margin: '0px -24px -16px -24px' } : { flex: 1 }}>
                  <child.type {...rest} />
                </div>
              );
            }

            if (index === activeTab) {
              return (
                <div style={tableOnly ? { flex: 1, margin: '0px -24px -16px -24px' } : { flex: 1 }}>
                  <child.type {...rest} />
                </div>
              );
            }

            if (table) {
              return (
                <div style={{ margin: '0px -24px -16px -24px' }}>
                  <child.type {...rest} />
                </div>
              );
            }

            // This is a hacky solution to get the shadow below the table, a better solution is to add the shadow to the table directly
            if (shadow) {
              return (
                <div style={{ margin: '12px -24px 0px' }}>
                  <child.type {...rest} />
                </div>
              );
            }

            return null;
          })}
      </Grid>
    </div>
  );
};

AnalyticsBox.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())])
    .isRequired,
  singleChild: PropTypes.bool,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }),
  onChange: PropTypes.func.isRequired,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  prefix: PropTypes.string,
  tooltip: PropTypes.string,
  customComponent: PropTypes.shape()
};

AnalyticsBox.defaultProps = {
  singleChild: false,
  dates: null,
  noPadding: false,
  noMargin: false,
  prefix: '',
  tooltip: '',
  customComponent: null
};

export default AnalyticsBox;
