import React from 'react';
import {
  arrowsPlugin,
  slidesToShowPlugin,
  slidesToScrollPlugin,
  infinitePlugin
} from '@brainhubeu/react-carousel';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

/**
 * If there are more slides in the array than the number of slides to show, then the carousel will be infinite.
 * @param {int} numberOfSlides The number of slides in the carousel to display
 * @param {int} isInfinite if true, the carousel will be infinite, defaults to false
 * @returns {array} An array of objects that will be used to populate the carousel
 */
const getCarouselPlugins = (numberOfSlides, isInfinite = false) => [
  ...(isInfinite ? [{ resolve: infinitePlugin }] : []),
  {
    resolve: arrowsPlugin,
    options: {
      arrowLeft: (
        <IconButton
          style={{
            background: '#0D1A3A',
            color: '#ffffff',
            '&:hover': {
              background: '#0D1A3A',
              color: '#ffffff'
            }
          }}
        >
          <ArrowLeft />
        </IconButton>
      ),
      arrowLeftDisabled: (
        <IconButton
          style={{
            background: '#000000',
            color: '#ffffff',
            opacity: '0.1'
          }}
          disabled
        >
          <ArrowLeft />
        </IconButton>
      ),
      arrowRight: (
        <IconButton
          style={{
            background: '#0D1A3A',
            color: '#ffffff',
            '&:hover': {
              background: '#0D1A3A',
              color: '#ffffff'
            }
          }}
        >
          <ArrowRight />
        </IconButton>
      ),
      arrowRightDisabled: (
        <IconButton
          style={{
            background: '#000000',
            color: '#ffffff',
            opacity: '0.1'
          }}
          disabled
        >
          <ArrowRight />
        </IconButton>
      ),
      addArrowClickHandler: true
    }
  },
  {
    resolve: slidesToShowPlugin,
    options: {
      numberOfSlides
    }
  },
  {
    resolve: slidesToScrollPlugin,
    options: {
      numberOfSlides
    }
  }
];

export default getCarouselPlugins;
