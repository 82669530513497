import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import getValueAbbreviation from 'util/getValueAbbreviation';
import SegmentsTableDropdown from './SegmentsTableDropdown';

const useStyles = makeStyles({
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#C4C4C4',
    margin: '20px 0px'
  },
  tableBody: {
    position: 'relative',
    padding: '8px 24px 24px'
  },
  dataValues: {
    display: 'grid',
    alignItems: 'center',
    flex: 1,
    gridTemplateColumns: props => props.columns
  },
  valueLabels: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F',
    wordBreak: 'break-word'
  },
  segmentLabels: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F',
    wordBreak: 'break-word'
  },
  topLines: {
    display: 'grid',
    height: '5px',
    gridTemplateColumns: props => props.topLineColumns,

    '& :not(:first-child):not(:last-child)': {
      borderBottom: '1px solid #979797'
    }
  },
  bottomLines: {
    display: 'grid',
    height: '10px',
    gridTemplateColumns: props => props.bottomLineColumns,
    marginBottom: '3px'
  },
  line: {
    borderLeft: '1px solid #979797'
  },
  halo: {
    fontSize: '7px',
    fontWeight: 500,
    color: '#6F6F6F',
    height: '100%'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const SegmentsRow = props => {
  const { data } = props;

  const classes = useStyles({
    columns: `1fr repeat(${data.length}, 1fr) 0.5fr`,
    topLineColumns: `1.5fr repeat(${data.length}, 1fr)`,
    bottomLineColumns: `2fr repeat(${data.length - 2}, 1fr) 1.5fr`
  });

  return (
    <Grid container direction="column">
      <Grid className={classes.dataValues}>
        <Grid item />
        {data.map(({ id, name }) => (
          <Grid key={id} item className={classes.segmentLabels} style={{ textAlign: 'center' }}>
            <Grid item>{name}</Grid>
          </Grid>
        ))}
        <Grid item />
      </Grid>
      <Grid className={classes.topLines}>
        <Grid item />
        {data.map(({ id }) => (
          <Grid key={id} item className={classes.line} />
        ))}
      </Grid>
      <Grid className={classes.bottomLines}>
        <Grid item />
        {Array.from(Array(data.length - 1), (_, i) => data[i].id).map(id => (
          <Grid key={id} item className={classes.line} />
        ))}
      </Grid>
    </Grid>
  );
};

SegmentsRow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

const CategoryRow = props => {
  const { data } = props;
  const { category, opportunityValueAvg, lengthAvg: categoryLengthAvg, steps } = data;

  const classes = useStyles({ columns: `1.5fr repeat(${steps.length}, 1fr) 1fr` });

  return (
    <Grid className={classes.dataValues}>
      <Grid item className={classes.valueLabels} style={{ textAlign: 'center' }}>
        <Grid item>{category}</Grid>
        <Grid item>
          <br />
        </Grid>
        <Grid item>
          {opportunityValueAvg == null ? '-' : getValueAbbreviation(opportunityValueAvg, true)}
        </Grid>
        <Grid item>
          {Math.ceil(Math.round((categoryLengthAvg + Number.EPSILON) * 100) / 100)} Day
          {categoryLengthAvg === 1 ? '' : 's'}
        </Grid>
      </Grid>
      {steps.map((
        { id, conversionPct, conversionCount, valueAvg, lengthAvg } // name, // order, // conversionCount
      ) => (
        <Grid key={id} item className={classes.valueLabels} style={{ textAlign: 'center' }}>
          <Grid item>
            {`${Math.round((conversionPct + Number.EPSILON) * 100) / 100}% (${conversionCount})`}
          </Grid>
          <Grid item style={{ color: '#979797' }}>
            -{/* render target percent and count */}
          </Grid>
          <Grid item>{valueAvg == null ? '-' : getValueAbbreviation(valueAvg, true)}</Grid>
          <Grid item>
            {Math.ceil(Math.round((lengthAvg + Number.EPSILON) * 100) / 100)} Day
            {lengthAvg === 1 ? '' : 's'}
          </Grid>
        </Grid>
      ))}
      <Grid item className={classes.halo} style={{ textAlign: 'center' }}>
        <Grid item>Halo Or Dependency</Grid>
      </Grid>
    </Grid>
  );
};

CategoryRow.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.string.isRequired,
    opportunityValueAvg: PropTypes.number,
    lengthAvg: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

const SegmentsTable = props => {
  const { data, loading, error, segment, setSegment } = props;

  const [{ states }] = data;

  const classes = useStyles();

  return (
    <>
      <Grid container justifyContent="space-between">
        <span>
          <BoxHeader tabs={['By Segment']} />
        </span>
        <SegmentsTableDropdown selectedOption={segment} setSelectedOption={setSegment} />
      </Grid>
      <Grid container className={classes.tableBody}>
        {error && <AlbError error={error} />}
        {loading && (
          <Grid item className={classes.loadingOverlay}>
            <AlbLoading />
          </Grid>
        )}
        <Grid container style={{ opacity: loading ? 0.5 : 1 }}>
          <SegmentsRow data={states} />
          {data.map((category, i) => (
            <Grid key={category.category} container>
              {i !== 0 && <div className={classes.separator} />}
              <CategoryRow data={category} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

SegmentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.shape({}),
  segment: PropTypes.shape().isRequired,
  setSegment: PropTypes.func.isRequired
};

SegmentsTable.defaultProps = {
  loading: false,
  error: null
};

export default SegmentsTable;
