import React from 'react';
import PropTypes from 'prop-types';

const ValidateIcon = props => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9514 0.000732422L0 5.99804L10.9514 12.5288L21.9028 5.99804L10.9514 0.000732422ZM2.00399 6.03387L10.9514 1.1353L19.8988 6.03387L10.9514 11.3683L2.00399 6.03387Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9514 14.4092L1.25522 8.62885L0.74678 9.48475L10.9514 15.5676L21.1561 9.48475L20.6496 8.62885L10.9514 14.4092Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9514 17.4492L1.25522 11.6669L0.74678 12.5228L10.9514 18.6077L21.1561 12.5228L20.6496 11.6669L10.9514 17.4492Z"
        fill={color}
      />
    </svg>
  );
};

ValidateIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

ValidateIcon.defaultProps = {
  width: 22,
  height: 19,
  color: '#FEFEFE'
};

export default ValidateIcon;
