/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import CreateWebReportForm from 'components/AnalyticsThirdPartyMedia/CreateWebReport';
import AlembicPageHeader from 'components/AlembicPageHeader';
import AlbTable from 'components/AlbTable';
import { Grid, Paper, Tab, Tabs, Tooltip } from '@material-ui/core';
import { PROXY_NEWS_DATA } from 'gql/articleSearch';
import { showToast } from 'contexts/ToastContext';
import { useLazyQuery } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import AlbLoading from 'components/AlbLoading';
import CreateEarnedMediaModal from 'components/AlembicModalForm/CreateEarnedMediaModal';
import CustomFooter from 'components/TablePagination';
import { ThirdPartyMediaAnalyticsSettingsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import { NoImageAvailable } from 'util/assets';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import TabWithTooltip from 'components/TabWithTooltip';

const styles = {
  articleTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#32327D',
    fontFamily: 'Poppins',
    overflow: 'auto'
  },
  showMore: {
    marginLeft: 'auto',
    display: 'flex',
    fontSize: '11px',
    color: '#32327D',
    fontFamily: 'Poppins',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  dates: {
    fontSize: '12px',
    color: '#32327D',
    opacity: 0.7
  },
  ellipsis: {
    paddingLeft: '3px'
  },
  paper: {
    marginTop: '20px'
  },
  tabOverRide: {
    minWidth: 'unset',
    maxWidth: 'unset',
    cursor: 'default'
  }
};

const formatDateTime = date => moment(date).format('MM/DD/YY @ h:mm a');

const CreateMediaReport = ({ history, classes }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [currentSearchFields, setCurrentSearchFields] = useState({});

  const [tableData, setTableData] = useState([]);
  const [count] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [proxyNewsData, { data: previewsData, error: previewsError, loading }] = useLazyQuery(
    PROXY_NEWS_DATA
  );

  useEffect(() => {
    if (previewsData?.proxyNewsData) {
      const newTableData = [];
      previewsData.proxyNewsData.articles.forEach((article, i) => {
        const articleInfo = {
          image: article.thumbnail,
          title: article.title,
          publishedDate: article.published_date,
          firstSeen: article.created_at
        };

        newTableData.push({ article: articleInfo });
      });
      setTableData(newTableData);
    }

    if (previewsData?.proxyNewsData === null) {
      showToast('No data came back. Please try again later.', 'warn');
    }
  }, [previewsData]);

  useEffect(() => {
    if (previewsError) {
      showToast(`Error previewing articles. Please try again later. ${previewsError}`, 'error');
    }
  }, [previewsError]);

  const checkRequiredFields = enteredFields => {
    // NewsData requires either the query_title or query_keywords, otherwise we'll get a 400.
    const { query_title: title, query_keywords: keywords } = enteredFields;

    return title?.length || keywords?.length;
  };

  const createSearch = () => {
    const requiredFieldsPresent = checkRequiredFields(currentSearchFields);

    if (requiredFieldsPresent) {
      setIsCreateModalOpen(true);
    } else {
      showToast('Search parameters are required.', 'error');
    }
  };

  const parseLanguagesList = languages =>
    languages.length ? languages.map(({ key }) => key).join(',') : null;

  const submitProxyND = searchFields => {
    const requiredFieldsPresent = checkRequiredFields(searchFields);

    if (requiredFieldsPresent) {
      proxyNewsData({
        variables: {
          search: { ...searchFields, language: parseLanguagesList(searchFields.language) }
        }
      });
    } else {
      showToast('Please enter either a title or keywords for this search', 'warn');
    }
  };

  const customArticleRender = article => {
    const { image, title, publishedDate, firstSeen } = article;
    let articleTitle = title;

    if (title.length > 70) {
      articleTitle = title.slice(0, 70).concat('...');
    }

    return (
      <Grid container wrap="nowrap" spacing={1}>
        <Grid item>
          <img
            src={image || NoImageAvailable}
            style={{ opacity: !image ? 0.2 : 1 }}
            alt={title}
            height={154}
            width={154}
          />
        </Grid>
        <Grid item style={{ padding: '10px' }}>
          <Tooltip title={title.length > 70 ? title : ''}>
            <div className={classes.articleTitle}>{articleTitle}</div>
          </Tooltip>
          <div className={classes.dates}>Published: {formatDateTime(publishedDate)}</div>
          <div className={classes.dates}>First Seen: {formatDateTime(firstSeen)}</div>
        </Grid>
      </Grid>
    );
  };

  const tableColumns = [
    {
      name: 'article',
      label: 'ARTICLE',
      options: { customBodyRender: customArticleRender, sort: false }
    }
  ];

  const rowsPerPageOptions = [5, 10, 20, 50, 100];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter
    // sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    // onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  return (
    <>
      <AlembicPageHeader
        backButton
        pageTitle="Create 3rd Party Media Report"
        buttonTitle="Create"
        buttonOnClick={createSearch}
      />
      <CreateWebReportForm
        currentSearchFields={currentSearchFields}
        setCurrentSearchFields={setCurrentSearchFields}
      />
      <AlembicPageHeader
        pageTitle="Preview"
        buttonTitle="Generate Preview"
        buttonOnClick={() => {
          submitProxyND(currentSearchFields);
        }}
      />
      <Paper className={classes.paper}>
        {loading ? (
          <AlbLoading />
        ) : (
          <>
            <Tabs value={0}>
              <Tab
                label={
                  <TabWithTooltip
                    tabTitle="Articles"
                    tooltipTitle={getToolTipTitleValue('emArticlePreviews')}
                  />
                }
                classes={{
                  root: classes.tabOverRide
                }}
              />
            </Tabs>
            <AlbTable
              tableData={tableData}
              tableColumns={tableColumns}
              tableOptions={tableOptions}
            />
          </>
        )}
      </Paper>
      <CreateEarnedMediaModal
        isModalOpen={isCreateModalOpen}
        defaultName={currentSearchFields?.query_title ?? ''}
        onChange={e => {
          setIsCreateModalOpen(e.isModalOpen);
          // Send back to table
          if (e?.newArticleSearch) {
            goToRoute(`${ThirdPartyMediaAnalyticsSettingsPath}?mediaType=web`, history);
          }
        }}
        earnedMediaFields={currentSearchFields}
      />
    </>
  );
};

CreateMediaReport.propTypes = {
  history: PropTypes.shape().isRequired,
  classes: PropTypes.shape()
};

CreateMediaReport.defaultProps = {
  classes: null
};

export default withStyles(styles)(CreateMediaReport);
