import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import store from 'store';
import getCurrentContainerIdFromRedux from 'util/container';
import { DashboardSocialPathV4, AnalyticsPath, DashboardEarnedPathV4 } from 'util/paths';
import { getDisabledRoutes } from 'util/featureFlags';
import qs from 'query-string';

const Page404 = React.lazy(() => import('views/Page404'));

/**
 * Route type definition
 * @typedef {Object} AlembicRoute
 * @property {String} route.path The path of the route
 * @property {String} route.key The key of the route
 * @property {String} route.type A type which is used on OAuth callbacks
 * @property {Object} route.component The component that will handle this route
 * @property {Boolean} route.exact If this is an exact-match route
 * @property {Boolean} route.public If this is a un-authenticated route or not
 * @property {String}  route.location.pathname wtf?
 */

/**
 * Determine if a route is disabled based on the feature flags.
 * @param {String} route Route to check. See paths.js
 * @param {Object} featureFlags an array of [{ name, enabled }] for a particular feature.
 * @returns Boolean Returns true if enabled. False if disabled.
 */
const checkDisabledFeatureRoutes = (route, featureFlags) => {
  const disabledRoutes = getDisabledRoutes(featureFlags);
  const containerLength = '/:containerId'.length;

  const cleanRoute =
    route?.location?.pathname?.toString()?.match(/^\/\d+/) !== null
      ? route?.path?.slice(containerLength, route.path.length)
      : route;

  // Check if passed in route is in the disabled feature list.
  if (disabledRoutes.includes(cleanRoute)) {
    // route is not enabled.
    return false;
  }
  return true;
};

/**
 * Render all of the application routes based on the feature flags.
 * @param {AlembicRoute[]} routes JSON object consisting of site routes
 * @returns Object Returns a React Switch component
 */
export const RenderRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map(route => (
        <AlembicRoute {...route} />
      ))}

      <Route component={Page404} />
    </Switch>
  );
};

/**
 * Render a single route with sub-routes if any.
 * @param {AlembicRoute} route
 * @returns Route Component
 */
export const AlembicRoute = route => {
  const { path, exact, component, routes } = route;
  const { currentOrganization, featureFlags } = store.getState().auth;
  const routeEnabled = checkDisabledFeatureRoutes(route, featureFlags, currentOrganization);

  let MyComponent = Page404;

  // lastly, if you don't have access to the route, it 404's.
  if (!routeEnabled) {
    MyComponent = Page404;
  }

  MyComponent = component;

  return (
    <Route
      path={path}
      exact={exact || false}
      render={props => <MyComponent {...props} routes={routes} />}
    />
  );
};

RenderRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({}))
};

RenderRoutes.defaultProps = {
  routes: []
};

// all private routes should go through here.
export const goToRoute = (path, history) => {
  const containerId = getCurrentContainerIdFromRedux();

  // get current container id prefix.
  const pathWithPrefix = `/${containerId}${path}`;

  history.push(pathWithPrefix);
};

export const goToRouteReplace = path => {
  const containerId = getCurrentContainerIdFromRedux();

  // get current container id prefix.
  const pathWithPrefix = `/${containerId}${path}`;

  window.history.replaceState(null, '', pathWithPrefix);
};

// provide url string for a tag
export const getRouteForCurrentContainer = path => {
  const containerId = getCurrentContainerIdFromRedux();

  return `/${containerId}${path}`;
};

// query string parser
// elementName: String of searched element
export const parseQueryString = (elementName, location) => {
  const searchParams = new URLSearchParams(location.search);
  const elements = searchParams.getAll(elementName);

  // returns an array of matching elements
  return elements;
};

const sortUrlParams = params => {
  const paramsArr = Object.entries(params);
  const sortedParamsArr = [];

  // ensure the params are in their rightful places.
  paramsArr.forEach(param => {
    switch (param[0]) {
      case 'platform':
        sortedParamsArr[0] = param;
        break;
      case 'account':
        sortedParamsArr[1] = param;
        break;
      case 'metric':
        sortedParamsArr[2] = param;
        break;
      case 'start_date':
        sortedParamsArr[3] = param;
        break;
      case 'end_date':
        sortedParamsArr[4] = param;
        break;
      default:
        // put all others at the end.
        sortedParamsArr[5] = param;
    }
  });

  // if any of the params are missing, filter them out while keeping the order.
  const cleanArr = sortedParamsArr.filter(item => item);
  const sortedParamsObj = Object.fromEntries(cleanArr);

  return sortedParamsObj;
};

export const setQueryStringWithoutPageReload = qsValue => {
  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;

  // update url without causing a refresh.
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const getQueryStringValue = (key, values, queryString) => {
  const parsedString = qs.parse(queryString, { sort: false });

  let newQsValue;
  if (values.length > 1) {
    newQsValue = { ...parsedString, [key]: [values] };
  } else {
    newQsValue = { ...parsedString, [key]: values[0] };
  }

  const sortedQsValue = sortUrlParams(newQsValue);
  return qs.stringify(sortedQsValue, { sort: false });
};

// analytics query helper
export const setQueryStringValue = (key, values, queryString = window.location.search) => {
  const finalQsValue = getQueryStringValue(key, values, queryString);

  setQueryStringWithoutPageReload(`?${finalQsValue}`);
};

export const setQueryStringForAnalyticsModal = (
  key,
  values,
  queryString = window.location.search
) => {
  const finalQsValue = getQueryStringValue(key, values, queryString);

  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${finalQsValue}`;

  // update url without causing a refresh.
  window.history.replaceState({ path: newUrl }, '', newUrl);
};

export const deleteQueryStringParameter = parameter => {
  const params = new URLSearchParams(window.location.search);
  params.delete(parameter);
  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${
    params?.size ? '?' : ''
  }${params ? params.toString() : ''}`;

  window.history.replaceState({ path: newUrl }, '', newUrl);
};

/**
 * @determineAnalyticsBackRoute helps route analytics reports. Since we're using the modal approach,
 * the route has to be explicitly defined instead of just using the back
 * button. Reports can be accessed from the dashboard or from the analytics
 * tab, so we need to route users back to the right place.
 * We also have to consider different dashboard versions, since the paths will differ.
 * @param {Object} location - react router passes this prop to the component calling this function
 * @param {String} dashboardTab - if accessing reports from dashboard, need to know which tab we came from.
 * @returns {String} path to return to.
 */
export const determineAnalyticsBackRoute = (location, dashboardTab) => {
  const searchParams = new URLSearchParams(location.search);

  if (searchParams.has('from_dash')) {
    if (dashboardTab === 'social') {
      return DashboardSocialPathV4;
    }
    if (dashboardTab === 'earned') {
      return DashboardEarnedPathV4;
    }
  }

  return AnalyticsPath;
};
