import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, ClickAwayListener } from '@material-ui/core';
import { MoreVert as Actions } from '@material-ui/icons';
import Box from 'components/Box';
import ArchiveCostToRecreateModal from 'components/AlembicModalConfirm/ArchiveCostToRecreateModal';
import UnarchiveCostToRecreateModal from 'components/AlembicModalConfirm/UnarchiveCostToRecreateModal';
import DeleteCostToRecreateModal from 'components/AlembicModalConfirm/DeleteCostToRecreateModal';
import DefaultCostToRecreateModal from 'components/AlembicModalConfirm/DefaultCostToRecreateModal';
import { goToRoute } from 'util/routesHelpers';
import { withRouter } from 'react-router-dom';
import { CreateCostToRecreateAnalyticsPath } from 'util/paths';

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    direction: 'rtl',
    textAlign: 'center'
  },
  actions: {
    position: 'absolute',
    alignItems: 'start',
    textAlign: 'left'
  },
  actionsMenu: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: 'white',
    padding: '25px',
    zIndex: 1
  },
  actionsOptions: {
    marginRight: '20px',

    '& div': {
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },

    '& :not(:last-child)': {
      marginBottom: '30px'
    }
  }
});

/**
 * @method
 * @summary This component renders vertical (...) icon with a list of options when clicked @see CostToRecreateTable
 * @name CostToRecreateTableActions
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isEnabled - A boolean value for the cost to recreate report enabled state
 * @param {boolean} props.isDefault - A boolean value for the cost to recreate report default state
 * @param {string} props.costToRecreateId - A string value for the cost to recreate report id
 * @param {string} props.costToRecreateName - A string value for the cost to recreate report name
 * @param {Function} props.queryRefetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const CostToRecreateTableActions = props => {
  const { isEnabled, isDefault, costToRecreateReport, queryRefetch, history } = props;
  const [displayedActions, setDisplayedActions] = useState();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDefaultModalOpen, setIsDefaultModalOpen] = useState(false);
  const classes = useStyles();

  const actions = [
    {
      label: 'Edit',
      action: () => {
        const existingFieldsObj = {
          id: costToRecreateReport.id,
          articleSearchId: costToRecreateReport.article_search_id,
          name: costToRecreateReport.name,
          linkedAccounts: costToRecreateReport.linktokens.map(account => account.id),
          postTextKeywords: costToRecreateReport.post_text_keywords ?? null,
          imageTags: costToRecreateReport.image_tags ?? null,
          videoTags: costToRecreateReport.video_tags ?? null,
          mediaTypes: costToRecreateReport.media_types ?? null,
          postTypes: costToRecreateReport.post_types ?? null,
          enabled: isEnabled
        };

        let queryString = '';
        const keys = Object.keys(existingFieldsObj);

        keys.forEach((key, index) => {
          const value = existingFieldsObj[key];

          if (value) {
            queryString = queryString.concat(`${key}=${encodeURIComponent(value)}`);

            if (index !== keys.length - 1) {
              queryString = queryString.concat('&');
            }
          }
        });

        goToRoute(`${CreateCostToRecreateAnalyticsPath}?${queryString}`, history);
      }
    }
  ];

  if (isEnabled) {
    actions.push({
      label: 'Archive',
      action: () => {
        setIsArchiveModalOpen(!isArchiveModalOpen);
      }
    });
  } else {
    actions.push({
      label: 'Unarchive',
      action: () => {
        setIsUnarchiveModalOpen(!isUnarchiveModalOpen);
      }
    });

    actions.push({
      label: 'Delete',
      action: () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }
    });
  }

  if (isEnabled && !isDefault) {
    actions.push({
      label: 'Make Default',
      action: () => {
        setIsDefaultModalOpen(!isDefaultModalOpen);
      }
    });
  }

  return (
    <Box className={classes.actionsWrapper}>
      {displayedActions && (
        <Grid container wrap="nowrap" className={classes.actions}>
          <ClickAwayListener onClickAway={() => setDisplayedActions(false)}>
            <Paper className={classes.actionsMenu}>
              <Grid item className={classes.actionsOptions}>
                {actions.map(action => (
                  <Grid
                    item
                    key={action.label}
                    onClick={e => {
                      e.stopPropagation();
                      action.action();
                      setDisplayedActions(false);
                    }}
                  >
                    {action.label}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Grid>
      )}
      <IconButton
        size="small"
        style={displayedActions ? { zIndex: 2 } : {}}
        onClick={e => {
          e.stopPropagation();
          setDisplayedActions(true);
        }}
      >
        <Actions />
      </IconButton>
      <ArchiveCostToRecreateModal
        isModalOpen={isArchiveModalOpen}
        onChange={event => {
          setIsArchiveModalOpen(event.isModalOpen);
          if (event?.newRecreateCostReportData) {
            queryRefetch();
          }
        }}
        costToRecreateId={costToRecreateReport.id}
        costToRecreateName={costToRecreateReport.name}
      />
      <UnarchiveCostToRecreateModal
        isModalOpen={isUnarchiveModalOpen}
        onChange={event => {
          setIsUnarchiveModalOpen(event.isModalOpen);
          if (event?.newRecreateCostReportData) {
            queryRefetch();
          }
        }}
        costToRecreateId={costToRecreateReport.id}
        costToRecreateName={costToRecreateReport.name}
      />
      <DeleteCostToRecreateModal
        isModalOpen={isDeleteModalOpen}
        onChange={event => {
          setIsDeleteModalOpen(event.isModalOpen);
          if (event?.newRecreateCostReportData) {
            queryRefetch();
          }
        }}
        costToRecreateId={costToRecreateReport.id}
        costToRecreateName={costToRecreateReport.name}
      />
      <DefaultCostToRecreateModal
        isModalOpen={isDefaultModalOpen}
        onChange={event => {
          setIsDefaultModalOpen(event.isModalOpen);
          if (event?.newRecreateCostReportData) {
            queryRefetch();
          }
        }}
        costToRecreateId={costToRecreateReport.id}
        costToRecreateName={costToRecreateReport.name}
      />
    </Box>
  );
};

CostToRecreateTableActions.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired,
  costToRecreateReport: PropTypes.shape().isRequired,
  queryRefetch: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired
};

const CostToRecreateTableActionsWithRouter = withRouter(CostToRecreateTableActions);

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see CostToRecreateTableActions
 * @name customCriteriaRender
 * @param {Object} actions - Object passed from @see MUIDataTable
 * @param {string} actions.costToRecreateReport - The whole report object
 * @param {Function} actions.queryRefetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const customActionsRender = actions => {
  return (
    <CostToRecreateTableActionsWithRouter
      isEnabled={actions.costToRecreateReport.enabled}
      isDefault={actions.costToRecreateReport.is_default}
      costToRecreateReport={actions.costToRecreateReport}
      queryRefetch={actions.queryRefetch}
    />
  );
};

export default customActionsRender;
