import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, ListItemIcon, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import CustomFooter from 'components/TablePagination';
import { customValueRender } from 'components/AlbTable/CustomRender';
import Box from 'components/Box';
import { GET_MENTION_GROUP } from 'gql/mention_group';
import colors from 'util/colors';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';
import { excludedRatingsSourceTypes } from '../util';

const useStyles = makeStyles({
  tableContainer: {
    flex: 1
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInner: {
    position: 'relative',
    minHeight: '40px',

    '& > :last-child': {
      paddingBottom: '0px !important'
    }
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px'
  },
  title: {
    marginRight: '10px',
    fontSize: '16px',
    fontWeight: 500,
    color: colors.navy
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineClamp: 3,
    wordBreak: 'break-word',
    fontSize: '13px'
  }
});

const excludeRatingsEventTypes = [
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS
];

const ThirdPartyMediaDrawerTable = props => {
  const { details } = props;

  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tableData, setTableData] = useState([]);

  const excludeRatings = excludeRatingsEventTypes.includes(details.type);

  const { data: mentionGroupData, loading, error } = useQuery(GET_MENTION_GROUP, {
    variables: {
      id: details.id,
      isDisabled: false
    },
    fetchPolicy: 'network-only'
  });

  const narrativeColumns = [
    { name: 'source', label: 'SOURCE' },
    { name: 'segment', label: 'SEGMENT' },
    { name: 'airDate', label: 'AIR DATE' },
    {
      name: 'ratings',
      label: 'RATINGS',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        customBodyRender: (value, tableMeta) => (value ? customValueRender(value, tableMeta) : '-')
      }
    }
  ];

  useEffect(() => {
    const tempTableData = [];
    let mentionsArr = [];

    if (mentionGroupData?.getMentionGroup) {
      mentionsArr = mentionGroupData.getMentionGroup.mentionsNonAveraged?.rows ?? [];
    }

    if (mentionsArr.length > 0) {
      mentionsArr.forEach(mention => {
        tempTableData.push({
          ...(mention?.broadcast?.station_name &&
            mention?.broadcast?.station_guid && {
              source: `${mention.broadcast.station_name} (${mention.broadcast.station_guid})`
            }),
          ...(mention?.broadcast?.station_name &&
            mention?.broadcast?.station_guid == null && {
              source: mention.broadcast.station_name
            }),
          ...(mention?.broadcast?.station_guid &&
            mention?.broadcast?.station_name == null && {
              source: mention.broadcast.station_guid
            }),
          ...(mention?.podcast?.author && {
            source: mention.podcast.author
          }),
          ...(mention?.broadcast?.program_title && {
            segment: mention.broadcast.program_title
          }),
          ...(mention?.podcast?.podcast_title && {
            segment: mention.podcast.podcast_title
          }),
          ...(!mention?.broadcast?.program_title &&
            !mention?.podcast?.podcast_title && {
              segment: '-'
            }),
          ...(mention?.air_time && {
            airDate: moment.utc(mention.air_time).format('L LT')
          }),
          ...(mention?.broadcast_mention_text && {
            mentionText: mention.broadcast_mention_text
          }),
          ...(mention?.podcast_mention_text && {
            mentionText: mention.podcast_mention_text
          }),
          ...(mention?.viewership &&
            !excludeRatings &&
            !excludedRatingsSourceTypes.includes(mention.source) && {
              ratings: mention.viewership
            })
        });
      });
    }

    setTableData(tempTableData);
  }, [mentionGroupData]);

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      []
    );

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false, // TODO - change when query is plugged in
    // pagination: false,
    sort: false,
    rowsPerPage: 10,
    responsive: 'standard',
    customFooter
  };

  return (
    <Grid container style={{ padding: '25px' }}>
      <Grid
        item
        className={classes.collapse}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        <ListItemIcon classes={{ root: classes.listItem }}>
          {isCollapsed ? <Right /> : <Down />}
        </ListItemIcon>
        {!isCollapsed && <Box className={classes.expandedIndicator} />}
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Grid item className={classes.headerLeft}>
          <Typography className={classes.title}>Mentions Breakdown</Typography>
        </Grid>
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          <Grid container justify="center">
            {loading && <AlbLoading />}
            {error && <AlbError error={error} />}
            {!loading && !error && (
              <AlbTable
                tableData={tableData}
                tableColumns={narrativeColumns}
                tableOptions={options}
                tableThemeOverrides={{
                  MUIDataTableHeadCell: {
                    root: {
                      whiteSpace: 'nowrap'
                    }
                  },
                  MuiTableCell: {
                    root: {
                      borderBottom: 'none',

                      '&:first-child': {
                        paddingLeft: '27px'
                      },

                      '&:last-child': {
                        paddingRight: '27px'
                      }
                    },
                    body: {
                      whiteSpace: 'wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  },
                  MuiPaper: {
                    root: {
                      flex: 1
                    }
                  }
                }}
              />
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

ThirdPartyMediaDrawerTable.propTypes = {
  details: PropTypes.shape().isRequired
};

export default ThirdPartyMediaDrawerTable;
