import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, SvgIcon, Grid } from '@material-ui/core';
import { convertToUserTimeZone } from 'util/date';
import { openExternalPost } from 'util/externalLinks';

const useStyles = makeStyles({
  textHeader: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  textHeaderContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  textHandle: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#32327D'
  },
  textTime: {
    fontSize: '10px',
    fontWeight: '500',
    color: '#6F6F6F'
  },
  externalLinkButton: {
    color: '#0A1734'
  }
});

/**
 * @method
 * @summary This component renders the Article header at the top of @see ArticleDrawerView inside @see ArticleDrawerDetails
 * @name DetailsHeader
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const DetailsHeader = props => {
  const { currentUser, articleDetails } = props;
  const { created_at: firstSeenDate, published_date: publishedDate, domain, url } = articleDetails;

  const classes = useStyles();

  const formatArticleTimeAndDate = date =>
    `${convertToUserTimeZone(date, currentUser.time_zone).format(
      'MM/DD/YY'
    )} @ ${convertToUserTimeZone(date, currentUser.time_zone).format('h:mma')}`;

  return (
    <div className={classes.textHeader}>
      <div className={classes.textHeaderContent}>
        <Typography className={classes.textHandle}>{domain}</Typography>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.textTime}>
              <b>Publish Date:</b>
              <br />
              {formatArticleTimeAndDate(publishedDate)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textTime}>
              <b>First Seen Date:</b>
              <br />
              {formatArticleTimeAndDate(firstSeenDate)}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <IconButton
        className={classes.externalLinkButton}
        onClick={() => {
          if (url) {
            openExternalPost(url);
          }
        }}
        disabled={!url}
      >
        <SvgIcon viewBox="0 0 19 20" color="inherit" fontSize="small">
          <path d="M18.9954 1.6415C18.9954 1.05853 18.5228 0.585939 17.9398 0.585939L11.6065 0.585938C11.0235 0.585938 10.551 1.05853 10.551 1.6415C10.551 2.22446 11.0235 2.69706 11.6065 2.69706H15.5985L10.2457 8.04987C9.83347 8.4621 9.83347 9.13043 10.2457 9.54266C10.6579 9.95488 11.3263 9.95488 11.7385 9.54266L16.8843 4.3968V7.97484C16.8843 8.5578 17.3569 9.0304 17.9398 9.0304C18.5228 9.0304 18.9954 8.5578 18.9954 7.97484V1.6415Z" />
          <path d="M8.44445 1.64149C8.44445 1.05853 7.97186 0.585938 7.3889 0.585938H2.11111C0.945178 0.585938 0 1.53111 0 2.69705V17.4748C0 18.6408 0.945177 19.5859 2.11111 19.5859H16.8889C18.0549 19.5859 19 18.6408 19 17.4748V12.197C19 11.6141 18.5274 11.1415 17.9445 11.1415C17.3615 11.1415 16.8889 11.6141 16.8889 12.197V17.4748H2.11111V2.69705H7.3889C7.97186 2.69705 8.44445 2.22447 8.44445 1.64149Z" />
        </SvgIcon>
      </IconButton>
    </div>
  );
};

DetailsHeader.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  articleDetails: PropTypes.shape()
};

DetailsHeader.defaultProps = {
  articleDetails: {
    published_date: null,
    domain: null,
    url: null
  }
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(DetailsHeader);
