/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import AnalyticsBox from 'components/AnalyticsBox';
import AlembicPageHeader from 'components/AlembicPageHeader';
import CreateShareOfVoiceModal from 'components/AlembicModalForm/CreateShareOfVoiceReportModal';
import { LIST_SHARE_OF_VOICE_REPORTS } from 'gql/shareOfVoice';
import ShareOfVoiceTable from './Tables/ShareOfVoiceTable';

/**
 * @method
 * @summary This component renders the @see ShareOfVoiceTable with an option to select Active/Archived reports
 * @name ShareOfVoiceReports
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const ShareOfVoiceReports = () => {
  const [createReportModal, toggleCreateReportModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [enabled, setEnabled] = useState(true);

  const { loading, error, data, refetch } = useQuery(LIST_SHARE_OF_VOICE_REPORTS, {
    variables: {
      enabled,
      count: rowsPerPage,
      after: page * rowsPerPage
    },
    fetchPolicy: 'network-only'
  });

  return (
    <>
      <AlembicPageHeader
        pageTitle="Competitive Intelligence Reports"
        buttonTitle="Create New Report"
        backButton
        buttonOnClick={() => toggleCreateReportModal(true)}
      />
      <AnalyticsBox noMargin tabs={['Active', 'Archived']} onChange={() => setEnabled(!enabled)}>
        <ShareOfVoiceTable
          tableOnly
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          refetch={refetch}
          data={data}
          loading={loading}
          error={error}
        />
        <ShareOfVoiceTable
          tableOnly
          data={data}
          loading={loading}
          error={error}
          refetch={refetch}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </AnalyticsBox>
      {createReportModal && (
        <CreateShareOfVoiceModal
          open={createReportModal}
          handleCancel={() => toggleCreateReportModal(false)}
          refetchTableData={refetch}
        />
      )}
    </>
  );
};

ShareOfVoiceReports.propTypes = {};

export default ShareOfVoiceReports;
