import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import {
  Info as InfoIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Check as SuccessIcon,
  Close as CloseIcon
} from '@material-ui/icons';

const icons = {
  info: <InfoIcon style={{ color: '#979797' }} />,
  error: <ErrorIcon />,
  warning: <WarningIcon />,
  success: <SuccessIcon />
};

const colors = {
  info: 'rgba(151, 151, 151, 0.5)',
  error: 'rgba(232, 24, 40, 0.5)',
  warning: 'rgba(251, 225, 138, 0.5)',
  success: 'rgba(0, 168, 79, 0.5)'
};

const getIcon = type => {
  return icons?.[type] ?? icons.info;
};

const getColor = type => {
  return colors?.[type] ?? colors.info;
};

const useStyles = makeStyles({
  containerRoot: {
    background: props => {
      const { variant, severity } = props;

      if (variant === 'filled') {
        return getColor(severity);
      }

      return 'none';
    },
    border: props => {
      const { variant, severity } = props;

      if (variant === 'outlined') {
        return `1px solid ${getColor(severity)}`;
      }

      return 'none';
    },
    borderRadius: '3px',
    padding: '10px 12px 10px 22px',
    margin: '8px 0px'
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  textRoot: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#000000',
    marginLeft: '4px'
  }
});

/**
 * @method
 * @summary This component renders a reusable banner across its containing element
 * @name Banner
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.useIcon - Used to show or hide a complementary icon depending on the type
 * @param {boolean} props.dismissable - If the banner can be dismissed via a close button
 * @param {string} props.message - The message displayed within the banner
 * @param {string} props.variant - Determines if the color will border or fill the banner
 * @param {string} props.severity - Changes the color & icon (if useIcon) of the banner
 * @return {Object} - React JSX
 */
const Banner = props => {
  const { useIcon, dismissable, message, variant, severity } = props;
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const classes = useStyles({ variant, severity });

  return (
    <>
      {isBannerOpen && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.containerRoot}
        >
          <div className={classes.textContainer}>
            {useIcon && getIcon(severity)}
            <Typography className={classes.textRoot}>{message}</Typography>
          </div>
          {dismissable && (
            <IconButton size="small" onClick={() => setIsBannerOpen(false)}>
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      )}
    </>
  );
};

Banner.propTypes = {
  useIcon: PropTypes.bool,
  message: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  severity: PropTypes.string, // info, error, warning, success
  variant: PropTypes.string // text, outlined, filled
};

Banner.defaultProps = {
  useIcon: false,
  severity: 'info',
  variant: 'text',
  dismissable: true
};

export default Banner;
