import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Box from 'components/Box';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { DRUID_TABLE } from 'gql/analytics';
import theme from 'theme';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import AlbError from 'components/AlbError';
import AssetThumbnail from 'components/AssetThumbnail';
import CustomFooter from 'components/TablePagination';
import { trimPreviewText, storyIcons, handleNullTableValue } from 'util/social';
import { DefaultUserAvatar } from 'util/assets';
import { formatSecondsDisplay } from 'util/time';
import { openExternalPage } from 'util/externalLinks';
import { getDatesForAnalytics } from 'util/date';
import colors from 'util/colors';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  accountIcon: {
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  imageIcon: {
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  label: {
    fontSize: '13px',
    fontWeight: 700,
    color: '#32327D',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const tableTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MUIDataTableHeadRow: {
      root: {
        boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap',

        '&:nth-child(-n+2)': {
          width: 1
        }
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#f5f5f5'
        },
        '&:hover': {
          backgroundColor: colors.athensGray
        }
      }
    }
  }
});

const BoxContentTableDruid = props => {
  const { query, accounts, dates, onlyVideo } = props;
  const drawerContext = useContext(PostDrawerViewContext);
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [previewText, setPreviewText] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortOrder, updateSortOrder] = useState(['completed_at', 'desc']);

  const rowsPerPageOptions = [5, 10, 20];

  const columnsNameLookupTable = new Map();

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data, error } = useQuery(DRUID_TABLE, {
    variables: {
      // startDate: dates.start,
      // endDate: dates.end,
      startDate,
      endDate,
      linkTokens: accounts.map(account => account.id),
      table: query.table,
      after: page * rowsPerPage,
      orderBy: sortOrder,
      count: rowsPerPage,
      onlyVideo,
      ...(query.linkTokenTypes && { linkTokenTypes: query.linkTokenTypes })
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  const formatTable = ({ rows, count: rowsCount }) => {
    const formattedData = rows.map(
      ({
        id,
        // eslint-disable-next-line camelcase
        date: completed_at,
        account: linktokenId,
        body,
        type,
        assets,
        metrics,
        thumbnailUrl,
        publishedUrl
      }) => {
        const currentAccount = accounts.find(account => account.id === linktokenId) || {};
        // eslint-disable-next-line camelcase
        const { label, platform = {}, avatar_url } = currentAccount;

        return {
          id,
          linktokenId,
          thumbnailUrl,
          publishedUrl,
          completed_at,
          account: { label, src: platform?.icon },
          label,
          // eslint-disable-next-line camelcase
          avatar_url: avatar_url || DefaultUserAvatar,
          body,
          type,
          assets,
          ...metrics.reduce((acc, { metric, value }) => {
            acc[metric] = value || null;
            return acc;
          }, {})
        };
      }
    );

    setCount(rowsCount);
    setTableData(formattedData);
  };

  useEffect(() => {
    if (data?.postTable) formatTable(data.postTable);
  }, [data, accounts, dates]);

  const customDateRender = date => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {`${moment(date).format('M/DD/YY')} @ ${moment(date).format('h:mma')}`}
    </div>
  );

  const customAccountRender = (account, tableMeta) => {
    const linktokenIdIndex = columnsNameLookupTable.get('linktokenId');
    const typeIndex = columnsNameLookupTable.get('type');
    const linktokenId = tableMeta.rowData[linktokenIdIndex];
    const type = tableMeta.rowData[typeIndex];

    const socialIcon = storyIcons[type] || account.src;

    return (
      <div className={classes.accountIcon}>
        <img
          role="presentation"
          alt="logo"
          src={socialIcon}
          style={{ width: 38, height: 38 }}
          onClick={() => openExternalPage(accounts, linktokenId)}
          onKeyDown={() => openExternalPage(accounts, linktokenId)}
        />
      </div>
    );
  };

  const customImageRender = src => {
    if (src) {
      return (
        <div className={classes.imageIcon}>
          <img role="presentation" alt="logo" src={src} style={{ width: 38, height: 38 }} />
        </div>
      );
    }
    return '';
  };

  const customLabelRender = label => (
    <span role="presentation" className={classes.label}>
      {label}
    </span>
  );

  const customBodyRender = (text, tableMeta) => {
    const { rowData, rowIndex } = tableMeta;

    const assetsIndex = columnsNameLookupTable.get('assets');
    const assets = rowData[assetsIndex];
    const thumbnailIndex = columnsNameLookupTable.get('thumbnailUrl');
    const thumbnailUrl = rowData[thumbnailIndex];
    const isInPreview = rowIndex === previewText;
    const maxLengthText = 175;
    let croppedText = text;
    let continued = '';

    if (text) {
      if (text.length > maxLengthText) {
        croppedText = trimPreviewText(text, maxLengthText);
        if (!isInPreview) {
          continued = undefined;
        }
      }
    }

    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <Grid>
          <AssetThumbnail
            thumbnailUrl={thumbnailUrl}
            assets={assets}
            width={60}
            height={60}
            rounded
          />
        </Grid>
        <Typography
          style={{
            fontSize: '13px',
            marginLeft: '10px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            ...(!isInPreview && { WebkitLineClamp: 1 })
          }}
          onClick={() => {
            return isInPreview ? setPreviewText(null) : setPreviewText(rowIndex);
          }}
        >
          {rowIndex === previewText ? text : croppedText}
          {continued}
        </Typography>
      </Box>
    );
  };

  const customValueRender = value => (
    <div style={{ textAlign: 'center' }}>{handleNullTableValue(value)}</div>
  );

  const customPercentRender = percent => {
    const isPercent = true;

    return <div style={{ textAlign: 'center' }}>{handleNullTableValue(percent, isPercent)}</div>;
  };

  const customTimeRender = value => (
    <div style={{ textAlign: 'center' }}>{value ? formatSecondsDisplay(value) : '-'}</div>
  );

  const getRenderType = metric => {
    // metric.includes('%') ? customPercentRender : customValueRender,

    if (metric === 'WATCH TIME' || metric === 'AVG WATCH TIME' || metric === 'DURATION') {
      return customTimeRender;
    }

    if (metric === 'AVG % VIEWED') {
      return customPercentRender;
    }

    return customValueRender;
  };

  const onRowClick = rowData => {
    const campaignEventIdIndex = columnsNameLookupTable.get('id');
    const campaignEventId = rowData[campaignEventIdIndex];

    drawerContext.toggleDrawer(true, campaignEventId, null, null, startDate, endDate);
  };

  const columns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'linktokenId',
      options: { display: false }
    },
    {
      name: 'thumbnailUrl',
      options: { display: false }
    },
    {
      name: 'publishedUrl',
      options: { display: false }
    },
    {
      name: 'assets',
      options: { display: false }
    },
    {
      name: 'type',
      options: { display: false }
    },
    {
      name: 'account',
      label: 'ACCOUNT',
      options: { customBodyRender: customAccountRender, sort: false }
    },
    {
      name: 'avatar_url',
      label: 'HANDLE',
      options: { customBodyRender: customImageRender, sort: false }
    },
    {
      name: 'label',
      label: ' ',
      options: { customBodyRender: customLabelRender, sort: false }
    },
    {
      name: 'completed_at',
      label: 'DATE/TIME',
      options: {
        customBodyRender: customDateRender
      }
    },
    {
      name: 'body',
      label: 'POST',
      options: { customBodyRender, sort: false }
    },
    ...query.metrics.map(metric => ({
      name: metric,
      label: metric,
      options: {
        customBodyRender: getRenderType(metric),
        sort: false,
        setCellHeaderProps: () => ({
          className: classes.centeredHeader
        })
      }
    }))
  ];

  // store a column name / index lookup.
  columns.forEach((column, i) => columnsNameLookupTable.set(column.name, i));

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    onRowClick,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => updateSortOrder([changedColumn, direction])
  };

  if (error) return <AlbError error={error} />;

  return (
    <MuiThemeProvider theme={tableTheme}>
      <MUIDataTable key={count} data={tableData} columns={columns} options={options} />
    </MuiThemeProvider>
  );
};

BoxContentTableDruid.propTypes = {
  query: PropTypes.shape().isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired,
  onlyVideo: PropTypes.bool
};

BoxContentTableDruid.defaultProps = {
  onlyVideo: false
};

export default BoxContentTableDruid;
