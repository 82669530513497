import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DELETE_ANNOTATION } from 'gql/annotation';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const refetchQueries = ['annotationsForContainer', 'paginatedAnnotations'];

const DeleteAnnotationModal = props => {
  const { selectedAnnotation, isModalOpen, onChange } = props;
  const { id, message } = selectedAnnotation;

  const [deleteAnnotation, { error, data }] = useMutation(DELETE_ANNOTATION, { refetchQueries });

  useEffect(() => {
    if (data?.deleteAnnotation?.result) {
      showToast('Annotation deleted.', 'success');
      onChange({ isModalOpen: false });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({ isModalOpen: false });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Delete Annotation"
        body={
          <span style={{ fontFamily: 'Poppins' }}>
            Are you sure you want to delete the annotation:&nbsp;
            <span style={{ color: '#0A1734' }}>{message}</span>?
          </span>
        }
        cancelTitle="Cancel"
        confirmTitle="Delete"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => deleteAnnotation({ variables: { id } })}
      />
    </>
  );
};

DeleteAnnotationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedAnnotation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired
};

export default DeleteAnnotationModal;
