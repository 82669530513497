import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { UPDATE_CONVERSION_EVENT_NAME_PERSISTENCE } from 'gql/conversionEventName';
import handleGraphQLError from 'util/error';
import RESPONSE_TYPE_ENUM from 'util/responseTypeEnum';
import { showToast } from 'contexts/ToastContext';

const UpdateConversionEventNamePersistence = props => {
  const { mutationVariables, buttonText, onChange } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [updatePersistence, { error, data }] = useMutation(
    UPDATE_CONVERSION_EVENT_NAME_PERSISTENCE
  );

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
      setIsDisabled(false);
      onChange({
        responseType: RESPONSE_TYPE_ENUM.FAILED,
        error
      });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      showToast('Update Successful', 'success');
      onChange({
        responseType: RESPONSE_TYPE_ENUM.OK,
        data
      });
    }
  }, [data]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          if (mutationVariables?.id) {
            setIsDisabled(true);
            updatePersistence({
              variables: mutationVariables
            });
          }
        }}
        disabled={isDisabled}
      >
        {isDisabled ? 'Tracked!' : buttonText}
      </Button>
    </>
  );
};

UpdateConversionEventNamePersistence.propTypes = {
  mutationVariables: PropTypes.shape().isRequired,
  buttonText: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

UpdateConversionEventNamePersistence.defaultProps = {
  onChange: null
};

export default UpdateConversionEventNamePersistence;
