import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { LinkAccountsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';

const useStyles = makeStyles({
  grid: {
    height: '265px'
  },
  text: {
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '26px',
    color: '#7B7B7B'
  }
});

const AnalyticsPlaceholder = props => {
  const { text, history, accountsButton } = props;

  const classes = useStyles();

  return (
    <Paper>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.grid}
      >
        <Grid item>
          <Typography variant="h3" className={classes.text}>
            {text}
          </Typography>
        </Grid>
        {accountsButton && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => goToRoute(LinkAccountsPath, history)}
            >
              Link Accounts
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

AnalyticsPlaceholder.propTypes = {
  text: PropTypes.string,
  history: PropTypes.shape(),
  accountsButton: PropTypes.bool
};

AnalyticsPlaceholder.defaultProps = {
  text: 'You must first link or select an account to display analytics data.',
  history: null,
  accountsButton: true
};

export default AnalyticsPlaceholder;
