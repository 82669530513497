import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Grid, Typography } from '@material-ui/core';
import AlbHorizontalBarGraph from 'components/AlbGraphs/AlbHorizontalBarGraph';

/**
 * Bar graph for earned
 * @name EarnedBar
 * @return {Object} - React JSX
 */
const EarnedBar = props => {
  const { graphData } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (graphData?.length > 0) {
      const tempLabels = graphData.map(({ x }) => x);
      const tempData = graphData.map(({ y }) => y);

      setData({
        datasets: [
          {
            data: tempData,
            backgroundColor: '#C40075'
          }
        ],
        labels: tempLabels
      });
    } else if (graphData?.length === 0) {
      setData({
        datasets: [],
        labels: []
      });
    } else {
      setData(null);
    }
  }, [graphData]);

  if (data?.datasets?.length === 0) {
    return (
      <Grid container justifyContent="center">
        <Typography>There is no data for this graph.</Typography>
      </Grid>
    );
  }

  if (data) {
    return <AlbHorizontalBarGraph data={data} valuation />;
  }

  return <Skeleton variant="rect" width="100%" height={500} style={{ margin: '25px 0' }} />;
};

EarnedBar.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.shape())
};

EarnedBar.defaultProps = {
  graphData: null
};

export default EarnedBar;
