import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox, TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { formatLanguageOptionsND } from 'components/AnalyticsEarnedMedia/earnedMediaFields';
import { showToast } from 'contexts/ToastContext';
import theme from 'theme';

const styles = makeStyles({
  selectOption: {
    fontWeight: 500,
    fontStyle: 'Poppins',
    fontSize: '13px',
    color: 'black'
  },
  option: {
    '&:active': {
      backgroundColor: 'transparent'
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent'
    }
  },
  inputRoot: {
    height: '34.25px'
  },
  listbox: {
    padding: 0,
    maxHeight: '660px'
  }
});

const dropdownTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiAutocomplete: {
      root: {
        '& input': {
          fontWeight: 400,
          opacity: 0.68
        },
        '& fieldset': {
          borderColor: '#EBEBEB'
        }
      },
      option: {
        padding: '0px !important'
      },
      inputRoot: {
        padding: '4px !important'
      }
    }
  }
});

const languageDropdownOptions = formatLanguageOptionsND();

/**
 * @method
 * @summary This component renders the language selector dropdown for ND report creation
 * @name WebReportLanguageDropdown
 * @return {Object} - React JSX
 */

const WebReportLanguageDropdown = ({ selectedOptions, setSelectedOptions }) => {
  const classes = styles();

  // ND limits to 5 languages
  const limit = 5;

  const getOptionValue = option => option.value.value;

  const [openDropdown, setOpenDropdown] = useState(false);

  const clearSelection = () => {
    setSelectedOptions([]);
  };

  const onInputChange = (e, option, reason) => {
    if (reason === 'clear' || reason === 'reset') {
      clearSelection();

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onClose = () => {
    setOpenDropdown(false);
  };

  const onChange = (e, value, reason, details) => {
    let newValues = value;

    if (details) {
      if (reason === 'select-option') {
        if (value.length > limit) {
          showToast(
            `You can only select up to ${limit} languages, please unselect one first.`,
            'warn'
          );

          const prevValues = newValues.slice(0, limit);
          newValues = prevValues;
        }
      }

      setSelectedOptions(newValues);
    }
  };

  return (
    <MuiThemeProvider theme={dropdownTheme}>
      <Autocomplete
        multiple
        options={languageDropdownOptions}
        value={selectedOptions}
        size="small"
        classes={{
          option: classes.option,
          inputRoot: classes.inputRoot,
          listbox: classes.listbox
        }}
        open={openDropdown}
        onClose={onClose}
        disableCloseOnSelect
        getOptionLabel={option => option.value.displayName}
        onChange={onChange}
        onInputChange={onInputChange}
        renderOption={option => {
          const selectOption = selectedOptions.map(getOptionValue).includes(getOptionValue(option));

          return (
            <Grid container alignItems="center" key={option.key} wrap="nowrap">
              <Checkbox checked={selectOption} />
              <div className={classes.selectOption}>{option.value.displayName}</div>
            </Grid>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps
              }}
              variant="outlined"
              placeholder={selectedOptions.length ? '' : 'Languages'}
              onClick={() => setOpenDropdown(!openDropdown)}
            />
          );
        }}
        renderTags={(value, getTagProps) => {
          return (
            <>
              {value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.key}
                  label={option.value.displayName}
                  size="small"
                />
              ))}
            </>
          );
        }}
      />
    </MuiThemeProvider>
  );
};

WebReportLanguageDropdown.propTypes = {
  selectedOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelectedOptions: PropTypes.func.isRequired
};

export default WebReportLanguageDropdown;
