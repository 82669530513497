import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Tooltip } from '@material-ui/core';
import { CalendarToday, ArrowLeft, ArrowRight } from '@material-ui/icons';

const useStyle = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  pickerRow: {
    display: 'flex'
  },
  rangeRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '7px',
    '& > button': {
      margin: '0px 6px'
    }
  },
  rangeButton: {
    minWidth: '30px',
    padding: '3px 4px',
    borderRadius: '3px'
  },
  leftArrowButton: {
    width: '38px',
    height: '41px',
    minWidth: '0px',
    borderRadius: '5.5px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderRight: 'solid 1px #ffffff'
  },
  calendarButton: {
    height: '41px',
    borderRadius: '0px',
    minWidth: '260px',
    pointerEvents: 'none'
  },
  rightArrowButton: {
    width: '38px',
    height: '41px',
    minWidth: '0px',
    borderRadius: '5.5px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderLeft: 'solid 1px #ffffff'
  }
});

const handleRangeOption = (rangeOption, startDateArg, endDateArg, settings = {}) => {
  const { leftArrowClick, rightArrowClick } = settings;
  const startDate = moment.utc(startDateArg).startOf(rangeOption.rangeString);
  const endDate = moment.utc(endDateArg).endOf(rangeOption.rangeString);
  let isRightButtonDisabled = false;

  if (leftArrowClick) {
    startDate.subtract(1, rangeOption.rangeKey).startOf(rangeOption.rangeString);
    endDate
      .subtract(1, rangeOption.rangeKey)
      .endOf(rangeOption.rangeString)
      .startOf('day');
  }

  if (rightArrowClick) {
    startDate.add(1, rangeOption.rangeKey).startOf(rangeOption.rangeString);
    endDate
      .add(1, rangeOption.rangeKey)
      .endOf(rangeOption.rangeString)
      .startOf('day');
  }

  // Check if we need a full start to end range amount of days
  if (rangeOption?.minimumMonthsRequired) {
    const today = moment.utc().startOf('day');
    const diff = today.diff(startDate, 'months');

    // If the difference between today is less than the start and end of the range then move it 1 range back
    if (diff < rangeOption.minimumMonthsRequired) {
      startDate.subtract(1, rangeOption.rangeKey).startOf(rangeOption.rangeString);
      endDate
        .subtract(1, rangeOption.rangeKey)
        .endOf(rangeOption.rangeString)
        .startOf('day');
      isRightButtonDisabled = true;
    }

    // Check next range by replicating a "rightArrowClick"
    if (isRightButtonDisabled === false) {
      const nextStartDate = moment
        .utc(startDate)
        .add(1, rangeOption.rangeKey)
        .startOf(rangeOption.rangeString);
      const nextDiffToday = today.diff(nextStartDate, 'months');

      if (nextDiffToday < rangeOption.minimumMonthsRequired) {
        isRightButtonDisabled = true;
      }
    }
  }

  // Return a start and end date
  return { startDate, endDate, periodical: rangeOption.periodical, isRightButtonDisabled };
};

const handleOverviewRangeOption = (rangeOption, startDateArg, endDateArg, settings = {}) => {
  const { init, leftArrowClick, rightArrowClick } = settings;
  const startDate = moment.utc(startDateArg).startOf('day');
  const endDate = moment.utc(endDateArg).endOf('day');
  let isRightButtonDisabled = false;

  if (init) {
    startDate.subtract(1, rangeOption.rangeKey).subtract(1, 'd');
    endDate.subtract(1, 'd');
    isRightButtonDisabled = true;
  }

  if (leftArrowClick) {
    startDate.subtract(1, rangeOption.rangeKey);
    endDate.subtract(1, rangeOption.rangeKey);
  }

  if (rightArrowClick) {
    startDate.add(1, rangeOption.rangeKey);
    endDate.add(1, rangeOption.rangeKey);
  }

  if (isRightButtonDisabled === false) {
    const nextStartDate = moment
      .utc(startDate)
      .add(1, rangeOption.rangeKey)
      .add(1, 'd');
    const today = moment.utc().startOf('day');

    if (nextStartDate.isSameOrAfter(today)) {
      isRightButtonDisabled = true;
    }
  }

  // Return a start and end date
  return { startDate, endDate, periodical: rangeOption.periodical, isRightButtonDisabled };
};

const handleStaticRangeOption = rangeOption => {
  const startDate = moment
    .utc()
    .subtract(rangeOption.rangeAmount, rangeOption.rangeKey)
    .startOf(rangeOption.rangeString);
  const endDate = moment
    .utc()
    .endOf(rangeOption.rangeString)
    .startOf('day');
  // Return a start and end date
  return { startDate, endDate, periodical: rangeOption.periodical };
};

const DatePickerV2 = props => {
  const { handleDateSelect, ranges, defaultSelectedRangeIndex, initialDates } = props;
  const [selectedButton, setSelectedButton] = useState(
    initialDates == null ? defaultSelectedRangeIndex : null
  );
  const [selectedDates, setSelectedDates] = useState(null);
  const [isLeftButtonDisabled, setIsLeftButtonDisabled] = useState(false);
  const [isRightButtonDisabled, setIsRightButtonDisabled] = useState(false);
  const [isFirstClick, setIsFirstClick] = useState(true);

  const classes = useStyle();

  const setDates = (range, settings) => {
    const { useToday } = settings;
    let startDate;
    let endDate;
    let periodical;
    let setRightButtonValue;

    if (range?.static === true) {
      // If the user clicked 'LAST_90_DAYS', 'LAST_180_DAYS', 'LAST_365_DAYS'
      ({ startDate, endDate, periodical } = handleStaticRangeOption(range));

      // Left and right buttons are disabled
      setIsLeftButtonDisabled(true);
      setIsRightButtonDisabled(true);
    } else if (range?.overview) {
      ({
        startDate,
        endDate,
        periodical,
        isRightButtonDisabled: setRightButtonValue
      } = handleOverviewRangeOption(
        range,
        useToday ? moment.utc() : selectedDates.start,
        useToday ? moment.utc() : selectedDates.end,
        settings
      ));

      setIsLeftButtonDisabled(false);
      setIsRightButtonDisabled(setRightButtonValue);
    } else {
      ({
        startDate,
        endDate,
        periodical,
        isRightButtonDisabled: setRightButtonValue
      } = handleRangeOption(
        range,
        useToday ? moment.utc() : selectedDates.start,
        useToday ? moment.utc() : selectedDates.end,
        settings
      ));

      setIsLeftButtonDisabled(false);
      setIsRightButtonDisabled(setRightButtonValue);
    }

    setSelectedDates({
      start: startDate,
      end: endDate
    });
    handleDateSelect({
      start: startDate,
      end: endDate,
      periodical
    });
  };

  const handleButtonClick = settings => {
    if (initialDates == null) {
      setDates(ranges[selectedButton], settings);
    } else if (isFirstClick === false) {
      setDates(ranges[selectedButton], settings);
    } else {
      // This section runs only once when there is a custom date passed in
      // It resets the datepicker to to initial dates
      setSelectedButton(defaultSelectedRangeIndex);
      setDates(ranges[defaultSelectedRangeIndex], { useToday: true });
      setIsFirstClick(false);
    }
  };

  useEffect(() => {
    if (initialDates == null) {
      setSelectedButton(defaultSelectedRangeIndex);
      setDates(ranges[defaultSelectedRangeIndex], { useToday: true, init: true });
    } else {
      setSelectedDates({
        start: moment.utc(initialDates.start),
        end: moment.utc(initialDates.end)
      });
      handleDateSelect({
        start: moment.utc(initialDates.start),
        end: moment.utc(initialDates.end),
        periodical: 'CUSTOM'
      });
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.pickerRow}>
        <Button
          color="primary"
          variant="contained"
          className={classes.leftArrowButton}
          disabled={isLeftButtonDisabled}
          onClick={() => {
            handleButtonClick({
              leftArrowClick: true
            });
          }}
        >
          <ArrowLeft />
        </Button>

        <Tooltip title="Custom date ranges are not available for this dashboard. Use the presets and arrows to select a time frame.">
          <Box>
            <Button
              color="primary"
              variant="contained"
              className={classes.calendarButton}
              onClick={() => {}}
            >
              <CalendarToday />
              <Typography style={{ marginLeft: '12px', fontSize: '12px' }}>
                {selectedDates != null &&
                  `${moment.utc(selectedDates.start).format('MMM D, YYYY')} - ${moment
                    .utc(selectedDates.end)
                    .format('MMM D, YYYY')}`}
              </Typography>
            </Button>
          </Box>
        </Tooltip>

        <Button
          color="primary"
          variant="contained"
          className={classes.rightArrowButton}
          disabled={isRightButtonDisabled}
          onClick={() => {
            handleButtonClick({
              rightArrowClick: true
            });
          }}
        >
          <ArrowRight />
        </Button>
      </div>

      <div className={classes.rangeRow}>
        {ranges?.length &&
          ranges.map((range, index) => {
            return (
              <Button
                key={range.label}
                color="primary"
                variant={selectedButton === index ? 'contained' : null}
                className={classes.rangeButton}
                onClick={() => {
                  setSelectedButton(index);
                  setDates(ranges[index], { useToday: true, init: true });

                  if (isFirstClick === true) {
                    setIsFirstClick(false);
                  }
                }}
              >
                {range.label}
              </Button>
            );
          })}
      </div>
    </div>
  );
};

DatePickerV2.propTypes = {
  handleDateSelect: PropTypes.func.isRequired,
  ranges: PropTypes.arrayOf(PropTypes.shape()),
  defaultSelectedRangeIndex: PropTypes.number,
  initialDates: PropTypes.shape()
};

DatePickerV2.defaultProps = {
  ranges: null,
  defaultSelectedRangeIndex: 1,
  initialDates: null
};

export default DatePickerV2;
