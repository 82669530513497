/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import './treeselect.css';

// OrgSelect Pulldown ---------------------------------------------------

/**
 * given an array of containers with valid parent_id's, return a tree of the containers.
 *
 * @param {Array} containers
 * @returns Tree of containers
 */
const containersToTree = (containers, item) => {
  // construct a tree in a two-pass operation.
  // build an initial hashtable so that we can locate children
  const hashTable = Object.create(null);

  containers.forEach(aData => {
    hashTable[aData.id] = {
      label: aData.name,
      id: parseInt(aData.id, 10),
      parent_id: parseInt(aData.parent_id, 10),
      value: parseInt(aData.id, 10),
      checked: parseInt(item.container_id, 10) === parseInt(aData.id, 10),
      disabled: item.parent_id === null,
      children: []
    };
  });

  const dataTree = [
    {
      label: 'None',
      id: -1,
      value: -1,
      checked: item.container_id === -1
    }
  ];

  containers.forEach(aData => {
    // now we construct the tree.
    //
    // There are two conditions for attaching a container to the root. If you do not have a
    // parent, we push you to the root. If you are your own parent, then you are
    // the root of this container tree.
    if (aData.parent_id && aData.parent_id !== aData.id) {
      hashTable[aData.parent_id].children.push(hashTable[aData.id]);
    } else {
      dataTree.push(hashTable[aData.id]);
    }
  });

  return dataTree;
};

/**
 * This component is a bit of a hack to get around some of the limitations when
 * using maps and selects with multiple children. We essentially want to create
 * a drop-down select that contains all of the user's organizations and
 * containers and allow them to select where this token should live.
 *
 * @param {Array} props.orgTree The user's org tree
 * @param {Object} props.item The linktoken item we're rendering
 * @param {Function} props.refetch Callback to force reload of the page
 * @returns React-JSX
 */
const OrgSelect = props => {
  const { item, orgTree, setToken } = props;

  // There seems to be a mild race condition here where the orgtree hasn't been
  // loaded yet but we need the variables to create the data table. return null.
  if (orgTree.length === 0) {
    return <></>;
  }

  const containerTree = containersToTree(orgTree[0].containers, item);

  return (
    <DropdownTreeSelect
      inlineSearchInput
      data={containerTree}
      mode="radioSelect"
      texts={{ placeholder: ' ' }}
      className="mdl-alembic"
      onChange={event => {
        if (event?.id === undefined) {
          // material ui issue bug: if someone selects a subheader, it still
          // registers as a change. Ignore it.
          return;
        }

        let newContainerId = event.id;
        if (event.checked === false) {
          // since we only allow one thing to be checked, this must be a de-select
          newContainerId = -1;
        }

        setToken({
          variables: {
            id: item.id,
            container_id: newContainerId
          }
        });
      }}
    />
  );
};

OrgSelect.propTypes = {
  item: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orgTree: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      containers: PropTypes.array
    })
  ),
  setToken: PropTypes.func.isRequired
};

OrgSelect.defaultProps = {
  orgTree: []
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    currentContainer: state.auth.currentContainer
  };
};

export default connect(mapStateToProps)(OrgSelect);
