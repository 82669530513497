import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ARTICLE_SEARCH } from 'gql/articleSearch';
import { useLazyQuery } from '@apollo/client';
import AlembicInputLabel from 'components/AlembicInputLabel';
import { formatLanguageOptionsND } from 'components/AnalyticsEarnedMedia/earnedMediaFields';
// import Box from 'components/Box';
import handleGraphQLError from 'util/error';
import { parseQueryString } from 'util/routesHelpers';
// import { NewsDataLogo } from 'util/assets';
import useDebounce from 'hooks/useDebounce';
import WebReportLanguageDropdown from './WebReportLanguageDropdown';

const styles = makeStyles({
  paper: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  inputRow: {
    display: 'grid',
    columnGap: '30px',
    gridAutoFlow: 'column',
    padding: '0px 30px',
    marginBottom: '30px'
  },
  searchRulesText: {
    width: '95%',
    margin: '15px 0px 30px 0px'
  },
  searchRulesPrimary: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#0A1734'
  },
  searchRulesSecondary: {
    fontSize: '14px'
  },
  searchNote: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#0A1734',
    marginBottom: '30px'
  },
  searchNoteSecondary: {
    fontWeight: 500,
    fontSize: '10px',
    color: '#0A1734',
    marginBottom: '10px'
  },
  searchLink: {
    fontWeight: '600',
    color: '#1DA1F2',
    '&:visited': { color: 'purple' }
  },

  radioGroup: {
    flexDirection: 'row',
    marginRight: '-16px'
  },
  radio: {
    '& > span': {
      fontSize: '12px',
      fontWeight: 500
    }
  }
});

const languageDropdownOptions = formatLanguageOptionsND();
const cleanQueryKeywords = keywords => keywords.replaceAll('"', '');

const CreateWebReport = props => {
  const { location, setCurrentSearchFields } = props;
  const classes = styles();

  const [language, setLanguage] = useState([]);
  const [queryKeywords, setQueryKeywords] = useState('');
  const [keywordField, setKeywordField] = useState('query_keywords');
  const [domainBlacklist, setDomainBlacklist] = useState('');

  const debouncedKeywords = useDebounce(queryKeywords, 500);
  const debouncedBlacklist = useDebounce(domainBlacklist, 500);

  const [getExistingArticleSearch, { data, error }] = useLazyQuery(ARTICLE_SEARCH);

  useEffect(() => {
    // id of existing search if user is 'editing and creating new'
    const id = parseQueryString('id', location)?.[0];

    if (id) {
      getExistingArticleSearch({ variables: { id } });
    }
  }, []);

  useEffect(() => {
    if (data?.articleSearch) {
      const existingKeywordField = data.articleSearch.query_title
        ? 'query_title'
        : 'query_keywords';

      // split the languages back into array form to match against our list
      const existingLanguageOptions = data.articleSearch?.language
        ? data.articleSearch.language.split(',')
        : [];

      const filteredLanguageOptions = languageDropdownOptions.filter(({ key }) =>
        existingLanguageOptions.includes(key)
      );

      setKeywordField(existingKeywordField);
      setQueryKeywords(cleanQueryKeywords(data.articleSearch?.[existingKeywordField] || ''));
      setDomainBlacklist(data.articleSearch?.filter_domain_blacklist || '');
      setLanguage(filteredLanguageOptions);
    }
  }, [data]);

  useEffect(() => {
    if (error) handleGraphQLError(error);
  }, [error]);

  useEffect(() => {
    setCurrentSearchFields({
      [keywordField]: queryKeywords?.trim() || null,
      filter_domain_blacklist: domainBlacklist?.trim() || null,
      language: language || null
    });
  }, [debouncedKeywords, debouncedBlacklist, language, keywordField]);

  return (
    <Paper className={classes.paper}>
      <Tabs value={0}>
        <Tab
          label={
            <Typography style={{ fontSize: 18, fontWeight: 500 }}>Filtering Options</Typography>
          }
        />
      </Tabs>

      <Grid container className={classes.inputRow}>
        <div style={{ marginTop: '30px' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <span>
              <AlembicInputLabel primaryText="Search Parameters (Required):" />
            </span>
            <RadioGroup
              classes={{ root: classes.radioGroup }}
              value={keywordField}
              onChange={e => {
                setKeywordField(e.target.value);
              }}
            >
              <FormControlLabel
                classes={{ root: classes.radio }}
                value="query_keywords"
                control={<Radio />}
                label="EVERYWHERE"
              />
              <FormControlLabel
                classes={{ root: classes.radio }}
                value="query_title"
                control={<Radio />}
                label="TITLE ONLY"
              />
            </RadioGroup>
          </Grid>
          <TextField
            fullWidth
            variant="filled"
            placeholder="Ex. (49ers OR niners) AND football"
            value={queryKeywords}
            inputProps={{ maxLength: 512 }}
            onChange={e => {
              setQueryKeywords(e.target.value);
            }}
          />
        </div>
      </Grid>
      <Grid container className={classes.inputRow}>
        <div>
          <AlembicInputLabel primaryText="Language (Optional):" />
          <WebReportLanguageDropdown selectedOptions={language} setSelectedOptions={setLanguage} />
        </div>
        <div>
          <AlembicInputLabel primaryText="Excluded Domains (Optional):" />
          <TextField
            fullWidth
            variant="filled"
            placeholder="Ex. nytimes.com, bbc.com"
            value={domainBlacklist}
            onChange={e => {
              setDomainBlacklist(e.target.value);
            }}
          />
        </div>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item className={classes.searchRulesText}>
          <Typography className={classes.searchRulesPrimary}>How to execute search:</Typography>
          <Typography className={classes.searchRulesSecondary}>
            {'\u2022'} Please see:{' '}
            <a
              href="https://support.getalembic.com/hc/en-us/articles/9414771929229"
              className={classes.searchLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.getalembic.com/hc/en-us/articles/9414771929229
            </a>
          </Typography>
        </Grid>
        <Typography className={classes.searchNote}>
          Reports may take up to an hour to generate. You can see the status of the report from the
          3rd Party Media Reports list.
        </Typography>
        {/* <Typography className={classes.searchNoteSecondary}>
          Data provided by NewsData.io
        </Typography>
        <Box mb={20}>
          <img src={NewsDataLogo} alt="NewsData Logo" />
        </Box> */}
      </Grid>
    </Paper>
  );
};

CreateWebReport.propTypes = {
  location: PropTypes.shape().isRequired,
  setCurrentSearchFields: PropTypes.func.isRequired
};

export default withRouter(CreateWebReport);
