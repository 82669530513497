import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSubscription, useQuery } from '@apollo/client';
import { Grid, Paper, Button, Tooltip } from '@material-ui/core';
import { GET_THEMES_FOR_CURRENT_CONTAINER } from 'gql/theme';
import { GET_TOPICS_FOR_CURRENT_CONTAINER } from 'gql/topic';
import { CONTAINER_SUBSCRIPTION } from 'gql/container';
import AlembicPageTitle from 'components/AlembicPageTitle';
import Box from 'components/Box';
import ThemeList from 'components/ThemeList';
import TopicList from 'components/TopicList';
import HasAnyPriv from 'components/HasAnyPriv';
import setPageTitle from 'util/titles';

const ThemesAndTopics = props => {
  useEffect(() => {
    setPageTitle('Themes and Topics');
  }, []);

  const { currentContainer } = props;
  const [createTheme, setCreateTheme] = useState(false);
  const [createTopic, setCreateTopic] = useState(false);
  const [noActiveThemes, setNoActiveThemes] = useState(true);

  const {
    loading: themesLoading,
    error: themesQueryError,
    data: themesData = {},
    refetch: refetchGetThemes
  } = useQuery(GET_THEMES_FOR_CURRENT_CONTAINER);

  const {
    loading: topicsLoading,
    error: topicsQueryError,
    data: topicsData = {},
    refetch: refetchGetTopics
  } = useQuery(GET_TOPICS_FOR_CURRENT_CONTAINER);

  const { data: subData } = useSubscription(CONTAINER_SUBSCRIPTION, {
    variables: { containerID: currentContainer.id }
  });

  const openCreateTheme = () => setCreateTheme(true);
  const closeCreateTheme = () => setCreateTheme(false);
  const openCreateTopic = () => setCreateTopic(true);
  const closeCreateTopic = () => setCreateTopic(false);

  useEffect(() => {
    if (themesData.themes) {
      setNoActiveThemes(!themesData.themes.filter(theme => theme.active === true).length);
    }
  }, [themesData]);

  useEffect(() => {
    if (subData) {
      refetchGetThemes();
      refetchGetTopics();
    }
  }, [subData]);

  return (
    <HasAnyPriv privs={['THEMES', 'THEMES:CREATE', 'THEMES:DELETE', 'THEMES:UPDATE']} showAlert>
      <Fragment>
        <Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <AlembicPageTitle title="Themes" />
            </Grid>
            <Grid item>
              <HasAnyPriv privs={['THEMES:CREATE']}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => openCreateTheme()}
                >
                  + Add Theme
                </Button>
              </HasAnyPriv>
            </Grid>
          </Grid>
          <Paper>
            <ThemeList
              data={themesData}
              loading={themesLoading}
              queryError={themesQueryError}
              openCreateTheme={createTheme}
              handleCloseCreateTheme={closeCreateTheme}
            />
          </Paper>
        </Box>
        <Box my={30}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <AlembicPageTitle title="Topics" />
            </Grid>
            <Grid item>
              <HasAnyPriv privs={['THEMES:CREATE']}>
                <Tooltip title={noActiveThemes ? 'No currently active themes' : ''}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => openCreateTopic()}
                      disabled={noActiveThemes}
                    >
                      + Add Topic
                    </Button>
                  </div>
                </Tooltip>
              </HasAnyPriv>
            </Grid>
          </Grid>
          <Paper>
            <TopicList
              data={topicsData}
              loading={topicsLoading}
              queryError={topicsQueryError}
              openCreateTopic={createTopic}
              handleCloseCreateTopic={closeCreateTopic}
            />
          </Paper>
        </Box>
      </Fragment>
    </HasAnyPriv>
  );
};

ThemesAndTopics.propTypes = {
  currentContainer: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentContainer: state.auth.currentContainer
  };
};

export default connect(mapStateToProps)(ThemesAndTopics);
