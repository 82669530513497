/* eslint-disable camelcase */
import { compose } from 'redux';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import {
  LoginPath,
  ChangePasswordPath,
  MFAInputPath,
  ResendEmailVerificationPath
} from './util/paths';

import AlbLoading from './components/AlbLoading';

import getDashboardRoute from './util/getDashboardRoute';

const locationHelper = locationHelperBuilder({});

// This file contains logic for redux-auth-wrapper. These functions control
// redirection of the user based on the authentication state.

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => state.auth.currentUser !== null,
  authenticatingSelector: state => state.auth.isLoading,
  wrapperDisplayName: 'UserIsAuthenticated'
};

// Not used yet?
export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults);

// Redirect to the login page if the user is not authenticated.
export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: AlbLoading,
  redirectPath: LoginPath // you get sent here if you are not authenticated
});

// determine where to send the user based on their current state.
const getNextDestination = (state, ownProps) => {
  let redir;
  if (locationHelper.getRedirectQueryParam(ownProps)) {
    redir = locationHelper.getRedirectQueryParam(ownProps);
  } else {
    // they might be in a different container, so this is the only time we'll consult that for an override.
    redir = getDashboardRoute(state?.auth?.currentUser, state?.auth?.currentContainer?.id);
  }

  // force some redirects here for common mistake cases
  if (redir === '/dashboard' || redir === '/') {
    redir = getDashboardRoute(state?.auth?.currentUser, state?.auth?.currentContainer?.id);
  }

  return redir;
};

// Redirect to the dashboard if the user IS authenticated.
export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to
  // the dashboard page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => getNextDestination(state, ownProps),
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: state => state.auth.currentUser === null,
  wrapperDisplayName: 'UserIsNotAuthenticated'
});

// Used only by the MFA screen to eject the user to dashboard or query-param
// redirect if MFA has been cleared
export const userHasNotPassedMFA = connectedRouterRedirect({
  // This sends the user either to the query param redirect location if we have
  // one, or to the dashboard page if none is specified and the user is already
  // logged in and free of MFA requirements
  redirectPath: (state, ownProps) => getNextDestination(state, ownProps),
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true (either needs mfa and hasn't passed || doesn't need
  // mfa), wrapper will not redirect So if there is no user data, then we show
  // the page
  authenticatedSelector: state =>
    (state.auth.currentUser.otp_enabled && localStorage.getItem('alb_mfa_passed') !== 'true') ||
    state.auth.currentUser.otp_enabled === false,
  wrapperDisplayName: 'UserPassedMFA'
});

// Redirects to the MFA input screen if MFA hasn't been satisfied
export const userNeedsMFA = connectedRouterRedirect({
  authenticatedSelector: state =>
    state.auth.currentUser !== null &&
    (state.auth?.currentUser?.otp_enabled === false ||
      localStorage.getItem('alb_mfa_passed') === 'true'),
  authenticatingSelector: state => state.auth.isLoading,
  redirectPath: MFAInputPath,
  wrapperDisplayName: 'UserNeedsMFA'
});

// Forces a password change if the user has not changed their password but the
// API says they have to.
export const userNeedsPWChange = connectedRouterRedirect({
  authenticatedSelector: state => state.auth?.currentUser?.force_password_change !== true,
  authenticatingSelector: state => state.auth.isLoading,
  redirectPath: ChangePasswordPath,
  wrapperDisplayName: 'UserNeedsPWChange',
  allowRedirectBack: false
});

// Blocks access to the site if the user requires a confirmed email and hasn't
// done so yet.
export const userEmailVerified = connectedRouterRedirect({
  authenticatedSelector: state =>
    state.auth?.currentUser?.eula_accepted_at === null || // user has not accepted eula and doesn't have to verify email.
    (state.auth?.currentUser?.eula_accepted_at !== null &&
      state.auth?.currentUser?.email_verified === true),
  authenticatingSelector: state => state.auth.isLoading,
  redirectPath: ResendEmailVerificationPath,
  wrapperDisplayName: 'userEmailVerified',
  allowRedirectBack: false
});

export const userFullLogin = compose(userIsNotAuthenticatedRedir, userNeedsPWChange);
export const userFullAuthN = compose(
  userIsAuthenticatedRedir,
  userNeedsMFA,
  userNeedsPWChange,
  userEmailVerified
);
export const userFullAuthNnoPW = compose(userIsAuthenticatedRedir, userNeedsMFA, userEmailVerified);
export const userFullAuthMFA = compose(
  userIsAuthenticatedRedir,
  userHasNotPassedMFA,
  userNeedsPWChange,
  userEmailVerified
);
