/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { SHORTLINK_TABLE } from 'gql/analytics';
import { iso639_1, iso3166_1_a2 } from 'util/iso';
import colors from 'util/colors';
import { getDatesForAnalytics } from 'util/date';

const useStyles = makeStyles({
  columnText: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.navy,
    marginRight: '10px'
  },
  languageHeader: {
    fontSize: '13px',
    color: '#6F6F6F'
  },
  clicks: {
    fontSize: '16px',
    fontWeight: 500,
    marginRight: '10px'
  },
  percentage: {
    fontSize: '16px',
    fontWeight: 300,
    opacity: 0.7
  }
});

const ShortlinkClicksTable = props => {
  const { dates, shortlink, column } = props;

  const classes = useStyles();

  const tableTheme = createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#f5f5f5'
          },
          '&:hover': {
            backgroundColor: colors.athensGray
          }
        }
      },
      MUIDataTableHeadCell: {
        sortAction: {
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      }
    }
  });

  const [tableData, setTableData] = useState([]);

  // total count of unique clicks
  const uniqueClicksRef = useRef(0);
  // total count of total clicks
  const totalClicksRef = useRef(0);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data: shortlinkTable } = useQuery(SHORTLINK_TABLE, {
    variables: {
      column,
      startDate,
      endDate,
      // eventual support for querying outside of the user's current container
      // containerIds: [sessionStorage.current_container],
      campaignIds: [],
      ...(shortlink !== null && { shortlinkIds: [shortlink] })
    }
  });

  useEffect(() => {
    if (shortlinkTable?.shortlinkTable) {
      const shortlinks = shortlinkTable.shortlinkTable;

      setTableData(shortlinkTable.shortlinkTable);

      const { uniqueClicks, totalClicks } = shortlinks.reduce(
        (acc, curr) => {
          return {
            uniqueClicks: acc.uniqueClicks + curr.unique_clicks,
            totalClicks: acc.totalClicks + curr.total_clicks
          };
        },
        { uniqueClicks: 0, totalClicks: 0 }
      );

      uniqueClicksRef.current = uniqueClicks;
      totalClicksRef.current = totalClicks;
    }
  }, [shortlinkTable]);

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard'
  };

  const customLanguageRender = text => {
    const [languageCode, countryCode] = text.split('-');
    const language = iso639_1[languageCode];
    const country = iso3166_1_a2[countryCode];

    const parsedCodes = [...(language ? [language] : []), ...(country ? [`, ${country}`] : [])];

    return (
      <>
        <Typography className={classes.columnText} display="inline">
          {parsedCodes.length ? parsedCodes : 'Unknown'}
        </Typography>
        <Typography className={classes.languageHeader} display="inline">
          ({text})
        </Typography>
      </>
    );
  };

  const customSourceRender = text => <Typography className={classes.columnText}>{text}</Typography>;

  const customClicksRender = (text, total) => {
    const percentage = (text / total) * 100;
    const round = number => Math.round((number + Number.EPSILON) * 100) / 100;

    return (
      <>
        <Typography className={classes.clicks} display="inline">
          {text}
        </Typography>
        <Typography className={classes.percentage} display="inline">
          ({round(percentage)}%)
        </Typography>
      </>
    );
  };

  const columnLabel = {
    preferred_lang: 'LANGUAGE',
    referrer_hostname: 'SOURCE'
  };

  const columns = [
    {
      name: 'distribution',
      label: columnLabel[column],
      options: {
        customBodyRender: column === 'preferred_lang' ? customLanguageRender : customSourceRender
      }
    },
    {
      name: 'unique_clicks',
      label: 'UNIQUE CLICKS',
      options: { customBodyRender: text => customClicksRender(text, uniqueClicksRef.current) }
    },
    {
      name: 'total_clicks',
      label: 'TOTAL CLICKS',
      options: { customBodyRender: text => customClicksRender(text, totalClicksRef.current) }
    }
  ];

  return (
    <MuiThemeProvider theme={tableTheme}>
      <MUIDataTable data={tableData} options={options} columns={columns} />
    </MuiThemeProvider>
  );
};

ShortlinkClicksTable.propTypes = {
  dates: PropTypes.shape().isRequired,
  shortlink: PropTypes.string,
  column: PropTypes.string.isRequired
};

ShortlinkClicksTable.defaultProps = {
  shortlink: null
};

export default ShortlinkClicksTable;
