import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import InfiniteCalendar, { Calendar, withRange } from '@appannie/react-infinite-calendar';
import '@appannie/react-infinite-calendar/styles.css';
import moment from 'moment';

const CalendarWithRange = withRange(Calendar);

const styles = {
  applyButton: {
    margin: '10px'
  }
};

const themeOverride = {
  headerColor: '#0a1734',
  selectionColor: '#0a1734',
  accentColor: '#0a1734',
  floatingNav: {
    background: '#0a1734',
    chevron: '#fff',
    color: '#fff'
  },
  textColor: {
    active: '#fff',
    default: '#0a1734'
  }
};

const CalendarPicker = React.forwardRef(
  (
    {
      width,
      height,
      selected,
      onSelect,
      selectDateRange,
      classes,
      callback,
      delta,
      singleDate,
      allowStartAfterCurrentDate
    },
    ref
  ) => {
    const today = moment.utc();

    let maxDate = null;

    if (!allowStartAfterCurrentDate) {
      maxDate = new Date(
        today.get('year'),
        today.get('month'),
        today.get('date') - (delta ? 1 : 0)
      );
    }

    const [utcSelected, setUTCSelected] = useState();

    useEffect(() => {
      if (selected) {
        const utcStart = moment.utc(selected.start);
        const utcEnd = moment.utc(selected.end);

        const start = new Date(utcStart.get('year'), utcStart.get('month'), utcStart.get('date'));
        const end = new Date(utcEnd.get('year'), utcEnd.get('month'), utcEnd.get('date'));

        setUTCSelected({ start, end });
      }
    }, [selected]);

    if (utcSelected) {
      return (
        <>
          <InfiniteCalendar
            ref={ref}
            {...(!singleDate ? { Component: CalendarWithRange } : {})}
            width={width}
            height={height}
            locale={{ headerFormat: 'MMM Do' }}
            selected={singleDate ? utcSelected.start : utcSelected}
            onSelect={props => {
              if (singleDate) {
                selectDateRange({ start: props, end: props });
              } else {
                const { eventType, ...range } = props;

                selectDateRange(range);
              }
            }}
            theme={themeOverride}
            {...(maxDate && { maxDate })}
          />
          <div style={{ backgroundColor: 'white', textAlign: 'right', width }}>
            <Button
              className={classes.applyButton}
              variant="contained"
              color="primary"
              onClick={() => {
                onSelect(selected);
                callback();
              }}
            >
              Apply
            </Button>
          </div>
        </>
      );
    }
    return null;
  }
);

CalendarPicker.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  selected: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
  selectDateRange: PropTypes.func.isRequired,
  classes: PropTypes.shape(),
  callback: PropTypes.func,
  delta: PropTypes.bool,
  singleDate: PropTypes.bool,
  eventType: PropTypes.string,
  allowStartAfterCurrentDate: PropTypes.bool
};

CalendarPicker.defaultProps = {
  width: 400,
  height: 300,
  classes: null,
  callback: null,
  delta: false,
  singleDate: false,
  eventType: '',
  allowStartAfterCurrentDate: false
};

export default withStyles(styles)(CalendarPicker);
