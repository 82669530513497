import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { ArrowRight } from '@material-ui/icons';
import { Drawer, Typography } from '@material-ui/core';
import ArticleDrawerViewContext from 'contexts/ArticleDrawerViewContext';
import { ARTICLE, ARTICLES } from 'gql/article';
import theme from 'theme';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import ArticleDrawerDetails from './ArticleDrawerDetails';
import ArticleDrawerMetrics from './ArticleDrawerMetrics';

const useStyles = makeStyles({
  drawerPaper: {
    minWidth: '617px',
    maxWidth: '617px',
    flexDirection: 'row'
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    background: '#0A1734'
  },
  arrow: {
    color: '#ffffff'
  },
  header: {
    padding: '28px 25px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '36px',
    color: '#0A1734'
  },
  content: {
    flex: 1
  },
  contentBody: {
    '& > :nth-child(even)': {
      backgroundColor: '#f5f5f5'
    }
  }
});

/**
 * @method
 * @summary Implements useContext() on ArticleDrawerViewContext and displays article data in a drawer
 * @name ArticleDrawerView
 * @return {Object} - React JSX
 */
const ArticleDrawerView = () => {
  const classes = useStyles();
  const drawerContext = useContext(ArticleDrawerViewContext);
  const {
    isArticleDrawerOpen,
    articleSearchId,
    articleId,
    domainFilter,
    toggleDrawer
  } = drawerContext;

  const withArticleId = articleId != null;
  const withDomain = domainFilter != null;

  const [
    getArticle,
    { loading: articleLoading, error: articleError, data: articleData }
  ] = useLazyQuery(ARTICLE, {
    fetchPolicy: 'no-cache'
  });

  const [
    getArticlesByDomain,
    { loading: articlesLoading, error: articlesError, data: articlesData }
  ] = useLazyQuery(ARTICLES, {
    fetchPolicy: 'no-cache'
  });

  const [title, setTitle] = useState('');
  const [article, setArticle] = useState(null);
  const [articlesList, setArticlesList] = useState([]);

  useEffect(() => {
    if (articleData?.article) {
      setArticle(articleData.article);
      setTitle(articleData.article.title);
    }
  }, [articleData]);

  useEffect(() => {
    if (articlesData?.getArticles) {
      setArticlesList(articlesData.getArticles.articles);
      setTitle(`${domainFilter} Articles`);
    }
  }, [articlesData]);

  useEffect(() => {
    if (isArticleDrawerOpen) {
      if (withArticleId) {
        const variables = { id: articleId };

        getArticle({ variables });
      }

      if (withDomain) {
        const variables = {
          searchID: articleSearchId,
          ignoredArticleFilter: 'FILTER',
          sortOrder: 'DESC',
          sortField: 'total_share_count',
          domainFilter
        };

        getArticlesByDomain({ variables });
      }
    } else {
      setArticle(null);
      setArticlesList(null);
    }
  }, [isArticleDrawerOpen, withArticleId, withDomain]);

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        anchor="right"
        open={isArticleDrawerOpen}
        onClose={() => toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div
          role="button"
          className={classes.bar}
          onClick={() => toggleDrawer(false)}
          aria-hidden="true"
        >
          <ArrowRight className={classes.arrow} />
        </div>
        {isArticleDrawerOpen && (
          <div className={classes.container}>
            <div className={classes.content}>
              {(!!articleLoading || !!articlesLoading) && <AlbLoading />}
              {!!articleError && <AlbError error={articleError} />}
              {!!articlesError && <AlbError error={articlesError} />}
              {/* Rendering drawer for a single article */}
              {!!article && (
                <>
                  <div className={classes.header}>
                    <Typography className={classes.title}>{title}</Typography>
                    <ArticleDrawerDetails article={article} />
                  </div>
                  {/* <div className={classes.content}>
                    <ArticleDrawerMetrics article={article} />
                  </div> */}
                </>
              )}
              {/* Rendering drawer for a list of articles filtered by an article search and domain */}
              {!!articlesList?.length && (
                <>
                  <div className={classes.header}>
                    <Typography className={classes.title}>{title}</Typography>
                  </div>
                  {/* If we need to add a search field we can filter the articlesList */}
                  <div className={classes.contentBody}>
                    {articlesList.map((item, i) => (
                      <ArticleDrawerMetrics key={item.id} article={item} index={i} detailed />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Drawer>
    </MuiThemeProvider>
  );
};

export default ArticleDrawerView;
