/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
// import moment from 'moment';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'util/colors';
import AnalyticsBox from 'components/AnalyticsBox';
import { GET_SALESFORCE_REVENUE_TOTALS, GET_SALESFORCE_REVENUE_TOP_BY_LEAD } from 'gql/salesforce';
import SalesforceRevenueTable from './Tables/SalesforceRevenueTable';
import SalesforceRevenueCard from './SalesforceRevenueCard';

const useStyles = makeStyles(() => ({
  topTable: {
    marginTop: '-37px'
  },
  branchHeight: {
    height: '50px'
  },
  branchHalfHeight: {
    height: '25px'
  },
  borderRightBranch: {
    borderRight: 'solid 1px #6F6F6F'
  },
  borderLeftBranch: {
    borderLeft: 'solid 1px #6F6F6F'
  },
  borderTopBranch: {
    borderTop: 'solid 1px #6F6F6F'
  },
  flex: {
    display: 'flex'
  }
}));

/**
 * @summary Renders revenue totals and top 5 lead/non-lead tables
 * @name SalesforceRevenue
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const SalesforceRevenue = props => {
  const { linktokenId, startDate, endDate, selectedPeriodical } = props;

  const classes = useStyles();

  const [leadRevenue, setLeadRevenue] = useState(0);
  const [leadDifference, setLeadDifference] = useState(0);
  const [leadPercentChange, setLeadPercentChange] = useState(0);
  const [nonLeadRevenue, setNonLeadRevenue] = useState(0);
  const [nonLeadDifference, setNonLeadDifference] = useState(0);
  const [nonLeadPercentChange, setNonLeadPercentChange] = useState(0);
  const [totalPercentChange, setTotalPercentChange] = useState(0);

  const [leadOpportunities, setLeadOpportunities] = useState([]);
  const [nonLeadOpportunities, setNonLeadOpportunities] = useState([]);

  const { data: totalsData } = useQuery(GET_SALESFORCE_REVENUE_TOTALS, {
    variables: { linktokenId, startDate, endDate, periodical: selectedPeriodical },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (totalsData?.getSalesforceRevenueTotals) {
      const {
        lead_revenue,
        lead_revenue_difference,
        lead_revenue_percent_change,
        non_lead_revenue,
        non_lead_revenue_difference,
        non_lead_revenue_percent_change,
        total_percent_change
      } = totalsData.getSalesforceRevenueTotals;

      setLeadRevenue(lead_revenue);
      setLeadDifference(lead_revenue_difference);
      setLeadPercentChange(lead_revenue_percent_change);

      setNonLeadRevenue(non_lead_revenue);
      setNonLeadDifference(non_lead_revenue_difference);
      setNonLeadPercentChange(non_lead_revenue_percent_change);

      setTotalPercentChange(total_percent_change);
    }
  }, [totalsData]);

  const { data: topData, loading: topLoading } = useQuery(GET_SALESFORCE_REVENUE_TOP_BY_LEAD, {
    variables: { linktokenId, startDate, endDate, periodical: selectedPeriodical },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (topData?.getSalesforceRevenueTopByLead) {
      setLeadOpportunities(topData.getSalesforceRevenueTopByLead.lead_opportunities);
      setNonLeadOpportunities(topData.getSalesforceRevenueTopByLead.non_lead_opportunities);
    }
  }, [topData]);

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item sm={6}>
        <SalesforceRevenueCard
          metricName="Overall"
          value={leadRevenue + nonLeadRevenue}
          difference={leadDifference + nonLeadDifference}
          percentChange={totalPercentChange}
          bgcolor="primary.main"
          tooltipKey=""
        />
      </Grid>

      {/* Branch */}
      <Grid container space={3} className={classes.branchHalfHeight}>
        <Grid item xs={6} className={classes.borderRightBranch} />
      </Grid>

      {/* Leaf */}
      <Grid container space={3} className={classes.branchHalfHeight}>
        <Grid item xs={3} className={classes.borderRightBranch} />
        <Grid item xs={3} className={classes.borderTopBranch} />
        <Grid item xs={3} className={classes.borderTopBranch} />
        <Grid item xs={3} className={classes.borderLeftBranch} />
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SalesforceRevenueCard
            metricName="Lead Generated Opportunities"
            value={leadRevenue}
            difference={leadDifference}
            percentChange={leadPercentChange}
            bgcolor={colors.purple}
            tooltipKey=""
          />
        </Grid>
        <Grid item xs={6}>
          <SalesforceRevenueCard
            metricName="Non-Lead Generated Opportunities"
            value={nonLeadRevenue}
            difference={nonLeadDifference}
            percentChange={nonLeadPercentChange}
            bgcolor={colors.pink}
            tooltipKey=""
          />
        </Grid>
      </Grid>

      {/* Stem */}
      <Grid container space={3} className={classes.branchHeight}>
        <Grid item xs={3} className={classes.borderRightBranch} />
        <Grid item xs={3} />
        <Grid item xs={3} />
        <Grid item xs={3} className={classes.borderLeftBranch} />
      </Grid>

      <Grid container spacing={3} className={classes.topTable}>
        <Grid item xs={6} className={classes.flex}>
          <AnalyticsBox noMargin noPadding tabs={['Top 5']} onChange={() => {}} prefix="mv">
            <SalesforceRevenueTable tableData={leadOpportunities} isLoading={topLoading} />
          </AnalyticsBox>
        </Grid>
        <Grid item xs={6} className={classes.flex}>
          <AnalyticsBox noMargin noPadding prefix="mv" tabs={['Top 5']} onChange={() => {}}>
            <SalesforceRevenueTable tableData={nonLeadOpportunities} isLoading={topLoading} />
          </AnalyticsBox>
        </Grid>
      </Grid>
    </Grid>
  );
};

SalesforceRevenue.propTypes = {
  linktokenId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedPeriodical: PropTypes.string
};

SalesforceRevenue.defaultProps = {
  linktokenId: null,
  startDate: null,
  endDate: null,
  selectedPeriodical: null
};

export default SalesforceRevenue;
