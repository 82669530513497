import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Edit as EditIcon } from '@material-ui/icons';
import {
  Grid,
  IconButton,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_ANNOTATIONS_FOR_CONTAINER } from 'gql/annotation';
import CreateAnnotationModal from 'components/AlembicModalForm/CreateAnnotationModal';
import DeleteAnnotationModal from 'components/AlembicModalConfirm/DeleteAnnotationModal';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { AnnotationBlueIcon } from 'util/assets';
import theme from 'theme';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  flex: {
    display: 'flex'
  },
  padding: {
    padding: '20px 25px'
  },
  annotationIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: '10px'
  },
  annotationsList: {
    display: 'flex',
    flex: 1,
    maxHeight: '600px',
    overflowY: 'auto'
  },

  annotationsTitle: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '20px'
  },
  text: {
    padding: '8px 0px',
    fontFamily: 'Poppins',
    fontSize: '18px',
    lineHeight: '27px',
    verticalAlign: 'baseline',
    borderBottom: 'none'
  },
  dates: {
    width: '1px',
    whiteSpace: 'nowrap',
    paddingRight: '20px'
  },
  marginLeft: {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    lineHeight: '27px'
  }
});

const modalTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      paper: {
        // conditional for the create/update modal
        '& > form': {
          '& > div': {
            padding: '12px'
          }
        },

        // conditional for the delete modal
        '& > div': {
          padding: '12px'
        }
      },
      root: {
        zIndex: '1400 !important'
      },
      ...theme.overrides.MuiDialog
    }
  }
});

const AnnotationsList = props => {
  const { startDate, endDate, setIsAnnotationsCollapsed, includeTitle, dashboard } = props;

  const classes = styles();

  const [annotations, setAnnotations] = useState([]);
  const [selectedAnnotation, setSelectedAnnotation] = useState();
  const [isEditAnnotationModalOpen, toggleEditAnnotationModal] = useState(false);
  const [isDeleteAnnotationModalOpen, toggleDeleteAnnotationModal] = useState(false);

  const { data, loading, error } = useQuery(GET_ANNOTATIONS_FOR_CONTAINER, {
    variables: {
      startDate: moment.utc(startDate).startOf('d'),
      endDate: moment.utc(endDate).endOf('d')
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.annotationsForContainer?.length) {
      setAnnotations(data.annotationsForContainer);
      setIsAnnotationsCollapsed(false);
    } else {
      setAnnotations([]);
    }
  }, [data]);

  const formatAnnotationDates = annotation => {
    const { start_date: start, end_date: end } = annotation;

    const dateArray = [
      ...new Set([moment.utc(start).format('MM/DD/YYYY'), moment.utc(end).format('MM/DD/YYYY')])
    ];

    return `${dateArray.join(' - ')}`;
  };

  return (
    <Grid className={classes.container}>
      {error && <AlbError error={error} />}
      {!!annotations.length && (
        <div className={dashboard ? classes.padding : ''}>
          {includeTitle && <Grid className={classes.annotationsTitle}>Annotations</Grid>}
          <Grid className={classes.flex}>
            <div className={classes.annotationIcon}>
              <img src={AnnotationBlueIcon} alt="Annotation icon" width={28} height={28} />
            </div>
            <div className={classes.annotationsList}>
              <Table>
                <TableBody>
                  {loading && <AlbLoading />}
                  {!loading &&
                    annotations.map(annotation => (
                      <TableRow
                        key={annotation.id}
                        style={
                          annotation.message_type === 'S' ? { backgroundColor: '#dedde9' } : {}
                        }
                      >
                        <TableCell className={`${classes.text} ${classes.dates}`}>
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography>{formatAnnotationDates(annotation)}</Typography>
                          </Box>
                        </TableCell>

                        <TableCell className={`${classes.text}`}>
                          <Typography>
                            <b>{annotation.message_type === 'S' ? 'Support: ' : ''}</b>
                            {annotation.message}
                            {annotation.message_type !== 'S' && (
                              <IconButton
                                disableFocusRipple
                                size="small"
                                style={{
                                  position: 'relative',
                                  top: '-3px',
                                  marginLeft: '4px'
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedAnnotation(annotation);
                                  toggleEditAnnotationModal(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Grid>
        </div>
      )}
      {annotations?.length === 0 && dashboard && (
        <Grid container justifyContent="center">
          <Box p={16} style={{ padding: '16px' }}>
            <Typography>There are no annotations</Typography>
          </Box>
        </Grid>
      )}
      <MuiThemeProvider theme={modalTheme}>
        {isEditAnnotationModalOpen && (
          <CreateAnnotationModal
            selectedAnnotation={selectedAnnotation}
            selectedDates={{
              start: selectedAnnotation.start_date,
              end: selectedAnnotation.end_date
            }}
            isModalOpen={isEditAnnotationModalOpen}
            onChange={e => toggleEditAnnotationModal(e.isModalOpen)}
            openDeleteModal={() => {
              toggleEditAnnotationModal(false);
              toggleDeleteAnnotationModal(true);
            }}
          />
        )}
        {isDeleteAnnotationModalOpen && (
          <DeleteAnnotationModal
            selectedAnnotation={selectedAnnotation}
            isModalOpen={isDeleteAnnotationModalOpen}
            onChange={e => toggleDeleteAnnotationModal(e.isModalOpen)}
          />
        )}
      </MuiThemeProvider>
    </Grid>
  );
};

AnnotationsList.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setIsAnnotationsCollapsed: PropTypes.func,
  includeTitle: PropTypes.bool,
  dashboard: PropTypes.bool
};

AnnotationsList.defaultProps = {
  setIsAnnotationsCollapsed: () => {},
  includeTitle: false,
  dashboard: false
};

export default AnnotationsList;
