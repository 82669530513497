import moment from 'moment';
import momentTimezone from 'moment-timezone';

const DATE_FORMAT_COMPACT = 'MM/DD/YYYY';
const DATE_FORMAT_LONG = 'll';

/**
 * @method
 * @summary ensure the date is an actual date type
 * @param {string} date
 * @returns boolean
 */
// eslint-disable-next-line no-restricted-globals
export const checkValidDate = date => date instanceof Date && !isNaN(date);

/**
 * @method
 * @summary formats and localizes date to UTC
 * @param {string} date - date as ISO8601 formatted string
 * @returns standard numeric format: (mm/dd/yyyy)
 */
export const renderUTCDate = date =>
  moment(date)
    .utc()
    .format(DATE_FORMAT_COMPACT);

export const renderUTCLongDate = date =>
  moment(date)
    .utc()
    .format(DATE_FORMAT_LONG);

/**
 * @method
 * @summary formats and localizes date to user's default browser timezone
 * @param {string} date - date as ISO8601 formatted string
 * @returns standard numeric format: (mm/dd/yyyy)
 */
export const renderUIDate = date => new Date(date).toLocaleDateString();

/**
 * @method
 * @summary formats and localizes date to user's default browser timezone
 * @param {string} date - date as ISO8601 formatted string
 * @returns date with short month format (i.e. Dec 31, 2020))
 */
export const renderUILongDate = date => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };

  if (date) {
    // undefined param uses the user's default locale
    return new Date(date).toLocaleDateString(undefined, options);
  }
  return new Date().toLocaleDateString(undefined, options);
};

/**
 * @method
 * @summary formats and localizes the time portion given a date
 * @param {string} date - date as ISO8601 formatted string
 * @returns only time portion in local user timezone, in format 00:01
 */
export const renderUILocalTimeFromDate = date => {
  const dateObj = new Date(date);

  return dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const convertToUserTimeZone = (date, userTimeZone) =>
  moment(momentTimezone.tz(date, userTimeZone));

export const getCurrentTimeInUserTimeZone = userTimeZone =>
  moment(momentTimezone.tz(moment(), userTimeZone));

/**
 * @param {number} rangeOffset - number of units to deduct from current date to obtain the start date.
 * @param {Object} currentUser - current user object from mapStateToProps used to obtain the timezone.
 * @param {string} rangeKey - unit of time (days, weeks, months, etc)
 */
export const getInitialAnalyticsStartDate = (rangeOffset, currentUser, rangeKey) =>
  new Date(
    getCurrentTimeInUserTimeZone(currentUser.time_zone)
      .subtract(rangeOffset, rangeKey)
      .format('L LT')
  );

// assuming end date is current date.
export const getInitialAnalyticsEndDate = currentUser =>
  new Date(
    getCurrentTimeInUserTimeZone(currentUser.time_zone)
      .subtract(1, 'd')
      .format('L LT')
  );

export const convertDateStringToUTC = (date, userTimeZone) => {
  const dateObject = moment(date);

  // If a userTimezone is passed, convert the date object to start of the day in that user's timezone, otherwise just use UTC
  let normalizedTime;

  if (userTimeZone) {
    normalizedTime = getCurrentTimeInUserTimeZone(userTimeZone);
  } else {
    normalizedTime = dateObject.utc();
  }

  return normalizedTime
    .set({
      year: dateObject.get('year'),
      month: dateObject.get('month'),
      date: dateObject.get('date'),
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    })
    .toISOString();
};

export const getDatesForAnalytics = (startDate, endDate) => {
  const startDateObject = moment(startDate);
  const endDateObject = moment(endDate);

  const convertedStartDate = moment
    .utc()
    .set({
      year: startDateObject.get('year'),
      month: startDateObject.get('month'),
      date: startDateObject.get('date'),
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    })
    .toISOString();

  const convertedEndDate = moment
    .utc()
    .set({
      year: endDateObject.get('year'),
      month: endDateObject.get('month'),
      date: endDateObject.get('date')
    })
    .endOf('day')
    .toISOString();

  return { start: convertedStartDate, end: convertedEndDate };
};

export const formatDateRange = (startDate, endDate) => {
  return `${renderUILongDate(startDate)}
   - ${renderUILongDate(endDate)}`;
};

// 2021-10-4 to 2021-10-6 Facebook had an outage that caused a major issue with our queue system
// https://engineering.fb.com/2021/10/04/networking-traffic/outage/
export const datesWithMissingData = [
  'MAY 31/2021',
  'JUN 1/2021',
  'JUN 2/2021',
  'JUN 3/2021',
  'OCT 4/2021',
  'OCT 5/2021',
  'OCT 6/2021'
];

// Show 'present' if end date is the current date.
// passed date matches format: moment(new Date()).format('ll')
export const getEndDate = endDate => {
  if (endDate === renderUILongDate()) {
    return 'Present';
  }
  return endDate;
};

/**
 * @param {number} offset - number of months to deduct.
 * @returns - ISO date with the first day of the month given the offset.
 */
export const getStartDate = offset => {
  const startDate = new Date();

  startDate.setDate(1);
  startDate.setMonth(startDate.getMonth() - offset);

  return startDate.toISOString();
};

// used for AnalyticsDatePicker buttons.
export const mapButtonsToPicker = (button, currentUser) => {
  const { rangeKey, units } = button;

  const start = getInitialAnalyticsStartDate(units, currentUser, rangeKey);
  const end = getInitialAnalyticsEndDate(currentUser);

  return { start, end };
};

// range format: { start, end } in L LT format.
export const mapPickerToButtons = (range, rangeButtons) => {
  const momentStart = moment(range.start);
  const momentEnd = moment(range.end);
  const daysRange = moment(range.end).diff(moment(range.start), 'd') + 1;

  const formattedStart = momentStart.format('L');
  const formattedEnd = momentEnd.format('L');

  const today = moment().format('L');

  let matchingButton = {};

  // check for rolling or fixed for each range.
  switch (daysRange) {
    case 1: {
      // convert toISOString because can't check if objects are equal.

      if (formattedStart === formattedEnd && formattedStart === today) {
        matchingButton = rangeButtons.find(b => b.label === '1D');
      }
      break;
    }
    case 7: {
      const isFirstDayOfWeek = momentStart.day() === 0; // 0 = Sunday
      const isCurrentWeek = momentEnd > moment() - 7;

      // fixed (1W)
      if (isFirstDayOfWeek && isCurrentWeek) {
        matchingButton = rangeButtons.find(b => b.label === '1W');
      }

      // rolling (7d)
      if (
        formattedEnd ===
        moment()
          .subtract(1, 'd')
          .format('L')
      ) {
        matchingButton = rangeButtons.find(b => b.label === '7');
      }
      break;
    }
    case 14: {
      // rolling (14d)
      if (
        formattedEnd ===
        moment()
          .subtract(1, 'd')
          .format('L')
      ) {
        matchingButton = rangeButtons.find(b => b.label === '14');
      }
      break;
    }
    // month days
    case 28:
    case 29:
    case 30:
    case 31: {
      const isFirstDayOfMonth = momentStart.date() === 1;
      const matchingDaysInCurrentMonth = moment().daysInMonth() === daysRange;
      const isCurrentMonth = momentEnd > moment() - moment().daysInMonth();

      // fixed (1M)
      if (isFirstDayOfMonth && isCurrentMonth && matchingDaysInCurrentMonth) {
        matchingButton = rangeButtons.find(b => b.label === '1M');
      }

      // rolling (30d)
      if (
        formattedEnd ===
          moment()
            .subtract(1, 'd')
            .format('L') &&
        daysRange === 30
      ) {
        matchingButton = rangeButtons.find(b => b.label === '30');
      }
      break;
    }
    // quarter days
    case 90:
    case 91:
    case 92: {
      // rolling (90d) or fixed (1Q)
      const firstDayOfQuarter = moment()
        .startOf('quarter')
        .format('L');
      const lastDayOfQuarter = moment()
        .endOf('quarter')
        .format('L');

      // fixed (1Q)
      if (firstDayOfQuarter === formattedStart && lastDayOfQuarter === formattedEnd) {
        matchingButton = rangeButtons.find(b => b.label === '1Q');
      }

      // rolling
      if (
        formattedEnd ===
          moment()
            .subtract(1, 'd')
            .format('L') &&
        daysRange === 90
      ) {
        matchingButton = rangeButtons.find(b => b.label === '90');
      }
      break;
    }
    // 366 is leap year
    case 365:
    case 366: {
      // fixed (1Y)
      const isFirstDayOfYear = momentStart.dayOfYear() === 1;
      const isLastDayOfYear = momentEnd.dayOfYear() === 365 || momentEnd.dayOfYear() === 366;

      const isCurrentYear = momentEnd.year() === moment().year();

      if (isFirstDayOfYear && isLastDayOfYear && isCurrentYear) {
        matchingButton = rangeButtons.find(b => b.label === '1Y');
      }
      break;
    }
    default:
      break;
  }

  return matchingButton;
};
