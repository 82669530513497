import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { HorizontalBar } from 'react-chartjs-2';
import colors from 'util/colors';
import getValueAbbreviation from 'util/getValueAbbreviation';

const useStyles = makeStyles({
  chartOverflow: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& > canvas': {
      width: '100% !important'
    }
  }
});

const baseGraphOptions = {
  legend: { display: false },
  hover: {
    onHover(e) {
      const point = this.getElementAtEvent(e);
      if (point.length) {
        e.target.style.cursor = 'pointer';
      } else {
        e.target.style.cursor = 'default';
      }
    }
  },
  tooltips: {
    mode: 'label',
    callbacks: {
      label(tooltipItem, chartData) {
        const positionText = chartData.datasets[tooltipItem.datasetIndex].label;
        const valueText = Math.round(
          chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        ).toLocaleString();

        if (positionText) {
          return `${positionText} ${valueText}`;
        }

        return valueText;
      }
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: colors.gray
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5,
          maxRotation: 0,
          fontColor: colors.doveGray,
          fontSize: 10,
          fontStyle: '600',
          callback: value => {
            if (value == null) {
              return '-';
            }

            if (typeof value === 'number') {
              return value.toLocaleString();
            }

            return value;
          }
        },
        stacked: true
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          precision: 2,
          fontColor: colors.doveGray,
          fontSize: 10,
          fontStyle: '600',
          beginAtZero: true
        },
        stacked: true,
        beginAtZero: true
      }
    ]
  }
};

/**
 * @method
 * @summary This component renders a horizontal bar graph
 * @name AlbHorizontalBarGraph
 * @param {Object} props - React props passed to this component
 * @param {Object} props.data - An object containing ChartJS specific properties such as datasets and labels
 * @return {Object} - React JSX
 */
const AlbHorizontalBarGraph = props => {
  const { data, onClick, valuation } = props;
  const classes = useStyles();

  const formatValue = value => {
    let formattedValue = getValueAbbreviation(value);

    if (valuation && formattedValue) {
      formattedValue = `$${getValueAbbreviation(value)}`;
    }

    return formattedValue || '-';
  };

  const graphOptions = Object.assign({}, baseGraphOptions, {
    tooltips: {
      mode: 'label',
      callbacks: {
        label(tooltipItem, chartData) {
          const value = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

          return formatValue(value);
        }
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: colors.gray
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600',
            callback: formatValue
          },
          stacked: true
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            precision: 2,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600',
            beginAtZero: true
          },
          stacked: true,
          beginAtZero: true
        }
      ]
    }
  });

  return (
    <div className={classes.container}>
      <HorizontalBar data={data} options={{ onClick, ...graphOptions }} />
    </div>
  );
};

AlbHorizontalBarGraph.propTypes = {
  data: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  valuation: PropTypes.bool
};

AlbHorizontalBarGraph.defaultProps = {
  onClick: () => null,
  valuation: false
};

export default AlbHorizontalBarGraph;
