/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { Dialog, Grid, IconButton } from '@material-ui/core';
import moment from 'moment';
import AlembicPageTitle from 'components/AlembicPageTitle';
import AlbLoading from 'components/AlbLoading';
import LINKTOKEN_RESURGE_IMPRESSION from 'gql/linktokenresurgeimpressions';
import handleGraphQLError from 'util/error';
import { goToRoute } from 'util/routesHelpers';
import { ResurgenceAnalyticsPath } from 'util/paths';
import Overview from './Overview';
import ResurgenceByPost from './ResurgenceByPost';

const useStyles = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  },
  header: {
    height: '176px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    marginBottom: '50px',
    zIndex: 1000
  },
  headerContainer: {
    height: '100%'
  },
  closeIcon: {
    height: '47px',
    width: '47px',
    backgroundColor: '#0D1A3A',
    color: 'white'
  }
});

/**
 * @method
 * @summary This component renders the full page take over for a multi-post resurgence
 * @name MultiPostResurgence
 * @param {Object} props - React props passed to this component
 * @param {Object} props.history - The history object from react router
 * @param {string} props.location - The location object from react router
 * @return {Object} - React JSX
 */
const MultiPostResurgence = props => {
  const { history, location, linktokenId, startDate, endDate, halfDrawer } = props;
  const [overviewProps, setOverviewProps] = useState(null);
  const classes = useStyles();

  const [linktokenResurgeImpression, { loading, error, data }] = useLazyQuery(
    LINKTOKEN_RESURGE_IMPRESSION
  );

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    // sometimes these params are in the url, sometimes they are passed in as props.
    let linktoken_id;
    let begin_date;
    let end_date;

    if (location) {
      const urlParams = new URLSearchParams(location.search);
      linktoken_id = urlParams.get('linktoken_id');
      begin_date = urlParams.get('begin_date');
      end_date = urlParams.get('end_date');
    }

    if (!linktoken_id) {
      linktoken_id = linktokenId;
      begin_date = startDate;
      end_date = endDate;
    }

    if (halfDrawer) {
      begin_date = moment.utc(startDate).format('YYYY-MM-DD');
      end_date = moment.utc(endDate).format('YYYY-MM-DD');
    }

    if (linktoken_id && begin_date && end_date) {
      linktokenResurgeImpression({
        variables: {
          linktoken_id,
          begin_date,
          end_date
        },
        fetchPolicy: 'no-cache'
      });
    }
  }, []);

  useEffect(() => {
    if (data?.linktokenResurgeImpression) {
      setOverviewProps({
        linktokenResurgeImpression: data.linktokenResurgeImpression
      });
    }
  }, [data]);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <AlbLoading />
      </Grid>
    );
  }

  if (!halfDrawer) {
    return (
      <Dialog open fullScreen classes={{ paperFullScreen: classes.root }}>
        <Grid container justifyContent="center">
          <Grid item container justifyContent="center" className={classes.header}>
            <Grid
              xs={9}
              item
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.headerContainer}
            >
              <AlembicPageTitle title="Multi-Post Resurgence" nomargin />
              <IconButton
                className={classes.closeIcon}
                onClick={() => {
                  const searchParams = new URLSearchParams(location.search);
                  let dashboardPath = '';

                  if (searchParams.has('from_dash')) {
                    dashboardPath = '?from_dash';
                  }
                  if (searchParams.has('from_dashv3')) {
                    dashboardPath = '?from_dashv3';
                  }

                  goToRoute(`${ResurgenceAnalyticsPath}${dashboardPath}`, history);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid xs={9} item>
            {overviewProps && (
              <>
                <Overview {...overviewProps} />
                <ResurgenceByPost {...overviewProps} />
              </>
            )}
          </Grid>
        </Grid>
      </Dialog>
    );
  }

  // open as side drawer instead of a full page.
  if (halfDrawer) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid xs={11} item>
          {overviewProps && (
            <>
              <Overview {...overviewProps} halfDrawerView={halfDrawer} />
              <ResurgenceByPost {...overviewProps} />
            </>
          )}
        </Grid>
      </Grid>
    );
  }

  return null;
};

MultiPostResurgence.propTypes = {
  history: PropTypes.shape(),
  location: PropTypes.shape(),
  linktokenId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  halfDrawer: PropTypes.bool
};

MultiPostResurgence.defaultProps = {
  history: null,
  location: null,
  linktokenId: null,
  startDate: null,
  endDate: null,
  halfDrawer: false
};

export default MultiPostResurgence;
