import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

export const CONTAINER_NAME = gql`
  query container($id: ID!) {
    container(id: $id) {
      id
      name
    }
  }
`;

export const CONTAINER = gql`
  query container($id: ID!) {
    container(id: $id) {
      id
      name
      parent_id
      organization_id
      created_at
      updated_at
      paused
      valuation_instagram
      valuation_facebook
      valuation_linkedin
      valuation_twitter
      valuation_youtube
      valuation_tiktok
      valuation_instagram_cpa
      valuation_facebook_cpa
      valuation_linkedin_cpa
      valuation_twitter_cpa
      valuation_youtube_cpa
      valuation_tiktok_cpa
      new_events_display_default
      avatar_url
      depth
      children {
        id
        name
        parent_id
        organization_id
        created_at
        updated_at
        paused
        avatar_handle
        depth
      }
      preferences {
        id
        container_id
        key
        value_s
        value_i
        created_at
        updated_at
      }
    }
  }
`;

export const CONTAINERS = gql`
  query containers($ids: [ID]!) {
    containers(ids: $ids) {
      id
      name
    }
  }
`;

export const CONTAINERS_TREE = gql`
  query containers($ids: [ID]!, $enabled: Boolean) {
    containers(ids: $ids) {
      id
      name
      avatar_url
      linked_accounts(enabled: $enabled) {
        id
        type
      }
      children {
        id
      }
    }
  }
`;

export const CONTAINER_USERS = gql`
  query container($id: ID!) {
    container(id: $id) {
      id
      users {
        id
        first_name
        last_name
        role {
          id
          name
        }
        email
        created_at
        otp_enabled
      }
    }
  }
`;

export const SMS_USERS_IN_CONTAINER = gql`
  query usersInContainer($id: ID!, $orderBy: [String]) {
    container(id: $id) {
      id
      users(orderBy: $orderBy) {
        id
        first_name
        last_name
        title
        phone
        phone_confirmed
        phone_confirmed_at
      }
    }
  }
`;

export const CREATE_CON = gql`
  mutation createContainer($parent_id: ID!, $name: String!) {
    createContainer(parent_id: $parent_id, name: $name) {
      name
      parent_id
      organization_id
      created_at
      updated_at
      paused
      avatar_handle
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation updateContainerAvatar($id: ID!, $handle: String!) {
    updateContainerAvatar(id: $id, handle: $handle) {
      name
      parent_id
      organization_id
      created_at
      updated_at
      paused
      avatar_handle
    }
  }
`;

export const DELETE_CONTAINER_AVATAR = gql`
  mutation deleteContainerAvatar($id: ID!) {
    deleteContainerAvatar(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONTAINER = gql`
  mutation updateContainer(
    $id: ID!
    $name: String
    $valuationModelCPM: ValuationModel
    $valuationModelCPA: ValuationModel
    $newEventsDisplayDefault: Boolean
  ) {
    updateContainer(
      id: $id
      name: $name
      valuationModelCPM: $valuationModelCPM
      valuationModelCPA: $valuationModelCPA
      newEventsDisplayDefault: $newEventsDisplayDefault
    ) {
      id
      name
      valuation_instagram
      valuation_facebook
      valuation_linkedin
      valuation_twitter
      valuation_youtube
      valuation_tiktok
      valuation_instagram_cpa
      valuation_facebook_cpa
      valuation_linkedin_cpa
      valuation_twitter_cpa
      valuation_youtube_cpa
      valuation_tiktok_cpa
      new_events_display_default
    }
  }
`;

export const DELETE_CON = gql`
  mutation deleteContainer($id: ID!) {
    deleteContainer(id: $id)
  }
`;

export const SET_CONTAINER_PREFS = gql`
  mutation updateContainerPreference(
    $container_id: ID!
    $key: String!
    $value_s: String
    $value_i: Int
  ) {
    updateContainerPreference(
      container_id: $container_id
      key: $key
      value_s: $value_s
      value_i: $value_i
    ) {
      id
      container_id
      key
      value_s
      value_i
      created_at
      updated_at
    }
  }
`;

export async function apiSetContainerPrefs(data) {
  return apolloClientHelper(SET_CONTAINER_PREFS, data, true);
}

export const DELETE_CONTAINER_PREFS = gql`
  mutation deleteContainerPreference($container_id: ID!, $key: String!) {
    deleteContainerPreference(container_id: $container_id, key: $key) {
      result
    }
  }
`;

export const CONTAINER_SUBSCRIPTION = gql`
  subscription($containerID: ID!) {
    containerSubscription(containerID: $containerID) {
      id
    }
  }
`;

export const RECENT_POSTS_QUERY = gql`
  query containerEvents($containerId: ID!) {
    container(id: $containerId) {
      id
      linked_accounts(enabled: true) {
        id
        remote_name
        avatar_url
        type
        campaign_events {
          id
          completed_at
          lifetime_impressions
          lifetime_engagement
          assets {
            signed_thumbnail_url
          }
          type
          valuation
          thumbnail_url
        }
      }
    }
  }
`;

export const INTERACTIONS_QUERY = gql`
  query(
    $container_id: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $interaction_type: InteractionType!
  ) {
    container(id: $container_id) {
      id
      interaction_stats(type: $interaction_type, startDate: $startDate, endDate: $endDate) {
        timestamp
        count
      }
    }
  }
`;
