import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Person } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const FB_AGE_RESTRICTED_AVATAR = 'https://static.xx.fbcdn.net/rsrc.php/v3/y6/r/_xS7LcbxKS4.gif';

const TOOLTIP_TITLE =
  'The image for your Facebook Page will display a question mark (?) if you have age restrictions set up on your page, as Facebook prevents us from pulling the image in this case.';

const AlembicAvatar = props => {
  const { width, height, avatarSrc } = props;

  const [imgError, setImgError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (avatarSrc === FB_AGE_RESTRICTED_AVATAR) {
      setShowTooltip(true);
    }
  }, [avatarSrc]);

  if (avatarSrc && !imgError) {
    if (showTooltip) {
      return (
        <Tooltip title={TOOLTIP_TITLE} placement="top-start">
          <img
            width={width}
            height={height}
            alt="Avatar"
            style={{ borderRadius: '100%' }}
            src={avatarSrc}
            onError={() => setImgError(true)}
          />
        </Tooltip>
      );
    }
    return (
      <img
        width={width}
        height={height}
        alt="Avatar"
        style={{ borderRadius: '100%' }}
        src={avatarSrc}
        onError={() => setImgError(true)}
      />
    );
  }

  if (!avatarSrc || imgError) {
    return (
      <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          background: '#E5E5E5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100%'
        }}
      >
        <Person style={{ color: '#ffffff', fontSize: width }} />
      </div>
    );
  }

  return null;
};

AlembicAvatar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  avatarSrc: PropTypes.string
};

AlembicAvatar.defaultProps = {
  width: 50,
  height: 50,
  avatarSrc: null
};

export default AlembicAvatar;
