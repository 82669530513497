import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DELETE_PROGRAM_LIFT_REPORT } from 'gql/programLiftReport';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

/**
 * @method
 * @summary This component renders the modal used to delete program lift reports accessed from @see ProgramLiftReportTableActions
 * @name DeleteProgramLiftReportModal
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isModalOpen - A boolean value to determine the modal's open state
 * @param {Function} props.onChange - A function run on successful mutation used to update parent component
 * @param {string} props.programLiftReportId - A string value for the program lift report id
 * @param {string} props.programLiftReportName - A string value for the program lift report name
 * @return {Object} - React JSX
 */
const DeleteProgramLiftReportModal = props => {
  const { isModalOpen, onChange, programLiftReportId, programLiftReportName } = props;

  const [deleteProgramLiftReport, { error, data }] = useMutation(DELETE_PROGRAM_LIFT_REPORT);

  useEffect(() => {
    if (data?.deleteProgramLiftReport?.result) {
      showToast(`Report deleted`, 'success');
      onChange({
        isModalOpen: false,
        newProgramLiftReportData: data?.deleteProgramLiftReport?.result
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        isDelete
        title="Delete Report"
        body={
          <>
            Are you sure you want to delete the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{programLiftReportName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Delete"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => {
          deleteProgramLiftReport({ variables: { id: programLiftReportId } });
        }}
      />
    </>
  );
};

DeleteProgramLiftReportModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  programLiftReportId: PropTypes.string,
  programLiftReportName: PropTypes.string
};

DeleteProgramLiftReportModal.defaultProps = {
  programLiftReportId: null,
  programLiftReportName: null
};

export default DeleteProgramLiftReportModal;
