/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Popover, Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CAMPAIGN_EVENT_TOOLTIP } from 'gql/analyticsHypeAndFlow';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import { useLazyQuery } from '@apollo/client';
import AssetThumbnail from 'components/AssetThumbnail';
import AlbLoading from 'components/AlbLoading';
import { getDatesForAnalytics } from 'util/date';
import { InstagramLogo, FacebookLogo, TwitterLogo, YouTubeLogo, LinkedinLogo } from 'util/assets';
import getValueAbbreviation from 'util/getValueAbbreviation';
import graphHoverStyles from './graphHoverStyles';

const style = makeStyles({
  ...graphHoverStyles
});

const getLogo = type => {
  switch (type) {
    case 'TWITTER':
      return {
        logo: TwitterLogo,
        alt: 'Twitter'
      };
    case 'FB_PAGE':
      return {
        logo: FacebookLogo,
        alt: 'Facebook'
      };
    case 'INSTAGRAM':
      return {
        logo: InstagramLogo,
        alt: 'Instagram'
      };
    case 'LINKEDIN_COMPANY':
      return {
        logo: LinkedinLogo,
        alt: 'LinkedIn'
      };
    case 'YOUTUBE':
      return {
        logo: YouTubeLogo,
        alt: 'YouTube'
      };
    default:
      return null;
  }
};

/**
 * @summary Hover component that fetches and displays analytics for a particular post.
 * @param {Bool} open - Required. Parent component handles the open/close state, when hover behavior is used.
 * @prop {Function} close - Optional. Can pass in a function to close the component from the parent. Used for click behavior.
 * @prop {Bool} withLTV - Optional. Display an additional column of Lifetime Values in the table.
 * @prop {HTMLAnchorElement} anchorEl - Required. Has a lot of nuances: Parent component controls the state of the selected anchor element (e.currentTarget) and passes that in.
 * We can either pass in an anchorEl html element directly, or pass in an object for the hover component, which will ensure re-render. After the prop is passed in, the anchor HTML element is assigned to this state value.
 * @prop {Date} startDate - Required. The beginning of the date range.
 * @prop {Date} endDate - Required. The end of the date range.
 * @prop {String} eventId - Required. The postId to query.
 * @prop {String} linkTokenId - Required, for the query.
 * @prop {Object} anchorOrigin - Optional specification of the position of the element. Format: { vertical: 'top', horizontal: 'right }.
 * @prop {Bool} clickBehavior - Optional. Default: false. Sets the component to open / close via click.
 */

const GraphHover = props => {
  const {
    open,
    close,
    withLTV,
    anchorEl,
    startDate,
    endDate,
    eventId,
    linkTokenId,
    anchorOrigin,
    clickBehavior,
    missingData
  } = props;
  const classes = style();
  const drawerContext = useContext(PostDrawerViewContext);

  const [mouseEnterPopover, setMouseEnter] = useState(false);
  const [anchor, setAnchor] = useState(anchorEl);

  const [getTooltipData, { data, error, loading }] = useLazyQuery(CAMPAIGN_EVENT_TOOLTIP, {
    variables: {
      startDate: getDatesForAnalytics(startDate, endDate).start,
      endDate: getDatesForAnalytics(startDate, endDate).end,
      linkToken: linkTokenId,
      postId: eventId
    }
  });

  // hover use effect, takes in an object anchorEl.
  // sometimes hover behavior does no trigger a re-render if the html element is deemed the same,
  // thus we pass in an object holding the HTML element along with another variable, as a hack to
  // trigger the re-render. See Hype Graph for example.
  useEffect(() => {
    if (!clickBehavior && open && anchorEl.ref) {
      setAnchor(anchorEl.ref);
      getTooltipData();
    }
  }, [open, anchorEl.innerHTML]);

  // click behavior use effect.
  useEffect(() => {
    if (clickBehavior && open && anchor) {
      setAnchor(anchorEl);
      getTooltipData();
    }
  }, [open, anchor]);

  if (open && missingData) {
    return (
      <Popover
        classes={{
          root: clickBehavior ? classes.root : classes.root_no_pointer_events,
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        onClose={close}
        disableRestoreFocus
      >
        <div className={classes.noDataText}>
          NO DATA
          <br />
          <br />
          Warning: Incomplete API data received from social networks on this date.
          <br />
          This does NOT affect lifetime values.
        </div>
      </Popover>
    );
  }

  if (open && error) {
    return (
      <Popover
        classes={{
          root: clickBehavior ? classes.root : classes.root_no_pointer_events,
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        onClose={close}
        disableRestoreFocus
      >
        <div
          className={classes.noDataText}
          onMouseEnter={clickBehavior ? () => setMouseEnter(true) : null}
          onMouseLeave={clickBehavior ? () => setMouseEnter(false) : null}
        >
          Error: {error.message}
        </div>
      </Popover>
    );
  }

  if (open && data && data.campaignEventToolTip?.campaignEvent) {
    const {
      campaignEvent,
      impressions,
      engagement,
      shares,
      videoViews
    } = data.campaignEventToolTip;
    const linkedAccount = campaignEvent.linked_account;
    let title = campaignEvent.event_title || campaignEvent.event_body;

    if (title?.length > 60) {
      title = `${title.slice(0, 60)}...`;
    }
    const formattedDate = moment(campaignEvent.completed_at).format('MMM D, YYYY');
    // const publishedUrl = campaignEvent.published_url;
    const imageInfo = getLogo(linkedAccount?.type);

    const handle =
      linkedAccount?.type === 'TWITTER' || linkedAccount?.type === 'INSTAGRAM'
        ? `@${linkedAccount?.remote_name}`
        : linkedAccount?.remote_name;

    return (
      <Popover
        classes={{
          root: clickBehavior ? classes.root : classes.root_no_pointer_events,
          paper: classes.paper
        }}
        className={
          // eslint-disable-next-line no-nested-ternary
          clickBehavior ? (!mouseEnterPopover ? classes.exitCursor : classes.mouseOver) : null
        }
        open={open}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        onClose={clickBehavior ? close : null}
        disableRestoreFocus
      >
        <Grid
          container
          className={clickBehavior ? classes.grid : classes.grid_no_pointer_events}
          // the following events automatically won't work if css above is set to 'pointerEvents: none'
          onMouseEnter={() => setMouseEnter(true)}
          onMouseLeave={() => setMouseEnter(false)}
          onClick={() =>
            drawerContext.toggleDrawer(true, null, eventId, linkTokenId, startDate, endDate)
          }
        >
          <Grid item xs={3}>
            <AssetThumbnail
              thumbnailUrl={campaignEvent.thumbnailUrl}
              assets={campaignEvent.assets}
              width={140}
              height={140}
              rounded={false}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container direction="column" className={classes.contentGrid}>
              <div className={classes.headerRow}>
                <div style={{ textAlign: 'left', width: '60%' }}>{title}</div>
                <div>
                  <img src={imageInfo?.logo} alt={imageInfo?.alt} height={18} width={18} />
                </div>
                <div style={{ fontWeight: 600 }}>{handle}</div>
              </div>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={`${classes.firstDataCell} ${classes.date}`}>
                      {withLTV ? formattedDate : null}
                    </th>
                    {withLTV ? <th className={classes.dataCell}>LTV</th> : <th />}
                    <th className={classes.dataCell}>
                      {withLTV ? 'In Time Period' : `${formattedDate}`}
                    </th>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <Divider />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.headerCell}>Impressions</td>
                    {withLTV ? (
                      <td className={classes.dataCell}>
                        {getValueAbbreviation(campaignEvent.lifetime_impressions) || '-'}
                      </td>
                    ) : (
                      <td />
                    )}
                    <td className={classes.dataCell}>{getValueAbbreviation(impressions) || '-'}</td>
                  </tr>
                  <tr>
                    <td className={classes.headerCell}>Engagement</td>
                    {withLTV ? (
                      <td className={classes.dataCell}>
                        {getValueAbbreviation(campaignEvent.lifetime_engagement) || '-'}
                      </td>
                    ) : (
                      <td />
                    )}
                    <td className={classes.dataCell}>{getValueAbbreviation(engagement) || '-'}</td>
                  </tr>
                  <tr>
                    <td className={classes.headerCell}>Shares</td>
                    {withLTV ? (
                      <td className={classes.dataCell}>
                        {getValueAbbreviation(campaignEvent.lifetime_shares) || '-'}
                      </td>
                    ) : (
                      <td />
                    )}
                    <td className={classes.dataCell}>{getValueAbbreviation(shares) || '-'}</td>
                  </tr>
                  <tr>
                    <td className={classes.headerCell}>Video Views</td>
                    {withLTV ? (
                      <td className={classes.dataCell}>
                        {getValueAbbreviation(campaignEvent.lifetime_views) || '-'}
                      </td>
                    ) : (
                      <td />
                    )}
                    <td className={classes.dataCell}>{getValueAbbreviation(videoViews) || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    );
  }

  if (open && loading) {
    return (
      <Popover
        classes={{
          root: clickBehavior ? classes.root : classes.root_no_pointer_events,
          paper: classes.paper
        }}
        className={!mouseEnterPopover && classes.exitCursor}
        open={open}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        onClose={clickBehavior ? close : null}
        disableRestoreFocus
      >
        <div
          className={classes.loading}
          onMouseEnter={clickBehavior ? () => setMouseEnter(true) : null}
          onMouseLeave={clickBehavior ? () => setMouseEnter(false) : null}
        >
          <AlbLoading />
        </div>
      </Popover>
    );
  }

  if (open && data && !data.campaignEventToolTip?.campaignEvent) {
    return (
      <Popover
        classes={{
          root: clickBehavior ? classes.root : classes.root_no_pointer_events,
          paper: classes.paper
        }}
        className={!mouseEnterPopover ? classes.exitCursor : null}
        open={open}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        onClose={clickBehavior ? close : null}
        disableRestoreFocus
      >
        <div
          className={classes.noDataText}
          onMouseEnter={clickBehavior ? () => setMouseEnter(true) : null}
          onMouseLeave={clickBehavior ? () => setMouseEnter(false) : null}
        >
          Data unavailable for this post. Please try again later.
        </div>
      </Popover>
    );
  }

  return null;
};

GraphHover.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  withLTV: PropTypes.bool,
  anchorEl: PropTypes.shape(),
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  eventId: PropTypes.string.isRequired,
  linkTokenId: PropTypes.string.isRequired,
  anchorOrigin: PropTypes.shape(),
  clickBehavior: PropTypes.bool,
  missingData: PropTypes.bool
};

GraphHover.defaultProps = {
  close: null,
  withLTV: false,
  anchorEl: null,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  clickBehavior: false,
  missingData: false
};

export default GraphHover;
