/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { RECREATE_COST_REPORTS } from 'gql/recreateCostReport';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import CustomFooter from 'components/TablePagination';
import { CostToRecreateAnalyticsPath } from 'util/paths';
import AlbTable from './AlbTable';
import { customValueRender } from './CustomRender';
import customNameRender from './ReportName';
import customCriteriaRender from './SearchCriteria';
import customActionsRender from './CostToRecreateTableActions';

/**
 * @method
 * @summary This component renders a table for the article searches
 * @name CostToRecreateTable
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.enabled - A boolean flag to query enabled or disabled article searches
 * @return {Object} - React JSX
 */
const CostToRecreateTable = props => {
  const { enabled } = props;
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['', '']);

  const { loading, error, data, refetch } = useQuery(RECREATE_COST_REPORTS, {
    variables: {
      enabled,
      count: rowsPerPage,
      after: page * rowsPerPage
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data?.recreateCostReports?.rows) {
      const tempTableData = [];

      data.recreateCostReports.rows.forEach(costToRecreateReport => {
        // Report Name
        const reportName = {
          id: costToRecreateReport.id,
          path: CostToRecreateAnalyticsPath,
          name: costToRecreateReport?.name ?? '',
          isDefault: costToRecreateReport.is_default
        };

        // Article Count
        const articleCount = costToRecreateReport?.total_articles ?? 0;

        const keywords =
          costToRecreateReport?.post_text_keywords
            ?.split(',')
            ?.map(keyword => ({ title: 'Keyword', body: keyword })) || [];

        const imageTags =
          costToRecreateReport?.image_tags
            ?.split(',')
            ?.map(imageTag => ({ title: 'Image Tag', body: imageTag })) || [];

        const videoTags =
          costToRecreateReport?.video_tags
            ?.split(',')
            ?.map(videoTag => ({ title: 'Video Tag', body: videoTag })) || [];

        const mediaTypes = costToRecreateReport?.media_types
          ? [{ title: 'Media Type', body: costToRecreateReport.media_types.split('_').join(' ') }]
          : [];

        const postTypes = costToRecreateReport?.post_types
          ? [{ title: 'Post Type', body: costToRecreateReport.post_types.split('_').join(' ') }]
          : [];

        // Search Criteria
        const searchCriteria = {
          chips: [...keywords, ...imageTags, ...videoTags, ...mediaTypes, ...postTypes]
        };

        // Actions
        const actions = {
          costToRecreateReport,
          queryRefetch: refetch
        };

        tempTableData.push({
          reportName,
          articleCount,
          searchCriteria,
          actions
        });
      });

      setCount(data.recreateCostReports.count);
      setTableData(tempTableData);
    }
  }, [data?.recreateCostReports?.rows]);

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableColumns = [
    {
      name: 'reportName',
      label: 'REPORT NAME',
      options: { customBodyRender: customNameRender, sort: false }
    },
    {
      name: 'articleCount',
      label: 'ARTICLE COUNT',
      options: { customBodyRender: customValueRender, sort: false }
    },
    {
      name: 'searchCriteria',
      label: 'SEARCH CRITERIA',
      options: { customBodyRender: customCriteriaRender, sort: false }
    },
    {
      name: 'actions',
      label: 'ACTIONS',
      options: {
        customBodyRender: customActionsRender,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false
      }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  if (loading) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbLoading />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={error} />
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />
    </Grid>
  );
};

CostToRecreateTable.propTypes = {
  enabled: PropTypes.bool
};

CostToRecreateTable.defaultProps = {
  enabled: true
};

export default CostToRecreateTable;
