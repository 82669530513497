/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { SHORTLINK_RECENT } from 'gql/analytics';
import { convertToUserTimeZone } from 'util/date';
import AlbTable from 'components/AlbTable';
import { customShortlinkRender, customOwnerRender } from 'components/AlbTable/CustomRender';

const ShortlinkRecent = props => {
  const { userTimeZone } = props;
  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(SHORTLINK_RECENT);

  useEffect(() => {
    if (data?.shortlinkRecent) {
      setTableData(
        data.shortlinkRecent.map(shortlink => ({
          ...shortlink,
          short_link: shortlink,
          updated_at: convertToUserTimeZone(shortlink.updated_at, userTimeZone).format('MMM D')
        }))
      );
    }
  }, [data]);

  const tableColumns = [
    {
      name: 'short_link',
      label: 'SHORTLINK',
      options: {
        customBodyRender: customShortlinkRender
      }
    },
    {
      name: 'updated_at',
      label: 'DATE'
    },
    {
      name: 'created_by',
      label: 'OWNER',
      options: {
        customBodyRender: customOwnerRender
      }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    sort: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard'
  };

  return <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />;
};

ShortlinkRecent.propTypes = {
  userTimeZone: PropTypes.string.isRequired
};

export default ShortlinkRecent;
