import { gql } from '@apollo/client';

export const GET_DEFAULT_ARTICLE_SEARCHES = gql`
  query getDefaultArticleSearches(
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $count: Int
    $orderBy: [String]
  ) {
    getDefaultArticleSearches(
      startDate: $startDate
      endDate: $endDate
      count: $count
      orderBy: $orderBy
    ) {
      articleSearches {
        id
        name
        enabled
        query_title
        query_keywords
      }
      combinedTotalShares
      combinedArticles {
        id
        total_share_count
        domain
        title
      }
    }
  }
`;

const ARTICLE_SEARCH_FIELDS = `
  id
  name
  query_title
  query_keywords
  filter_author
  filter_min_word_count
  filter_tld
  filter_article_type
  filter_sentiment
  filter_with_image
  filter_strict_mode
  filter_domain_blacklist
  filter_keyword_blacklist
  total_results
  initial_search_completed
  language
  article_search_filters {
    id
    included_title_keywords
    articles {
      rows {
        id
      }
      count
    }
  }
`;

export const ARTICLE_SEARCH = gql`
  query articleSearch($id: ID!) {
    articleSearch(id: $id) {
      ${ARTICLE_SEARCH_FIELDS}
    }
  }
`;

export const ARTICLE_SEARCHES = gql`
  query articleSearches($enabled: Boolean, $containerId: ID) {
    articleSearches(enabled: $enabled, containerId: $containerId) {
      id
      name
      article_search_filters {
        id
      }
      is_default
    }
  }
`;

export const LIST_ARTICLE_SEARCHES = gql`
  query listArticleSearches($enabled: Boolean, $count: Int, $after: Int) {
    listArticleSearches(enabled: $enabled, count: $count, after: $after) {
      rows {
        id
        name
        enabled
        is_default
        query_title
        query_keywords
        language
        articles {
          id
        }
        article_search_filters {
          id
          included_title_keywords
          articles {
            rows {
              id
            }
            count
          }
        }
      }
      count
    }
  }
`;

export const ARTICLE_SEARCHES_AGG = gql`
  query listArticleSearchesAgg($enabled: Boolean, $count: Int, $after: Int) {
    listArticleSearches(enabled: $enabled, count: $count, after: $after) {
      rows {
        id
        name
        enabled
        is_default
        query_title
        query_keywords
        initial_search_completed
        language
        total_results
        article_search_filters {
          id
          included_title_keywords
          is_detecting
          articles {
            rows {
              id
            }
            count
          }
        }
      }
      count
    }
  }
`;

export const ARCHIVE_ARTICLE_SEARCH = gql`
  mutation disableArticleSearch($id: ID!) {
    disableArticleSearch(id: $id) {
      id
      name
      enabled
      is_default
      query_title
      query_keywords
      articles {
        id
      }
    }
  }
`;

export const UNARCHIVE_ARTICLE_SEARCH = gql`
  mutation enableArticleSearch($id: ID!) {
    enableArticleSearch(id: $id) {
      id
      name
      enabled
      is_default
      query_title
      query_keywords
      articles {
        id
      }
    }
  }
`;

export const DELETE_ARTICLE_SEARCH = gql`
  mutation deleteArticleSearch($id: ID!) {
    deleteArticleSearch(id: $id) {
      result
      message
    }
  }
`;

// isDetecting equals is_default in the db. The resolver returns the db field.
export const SET_DETECTING_ARTICLE_SEARCH = gql`
  mutation setDetectingArticleSearch($id: ID!, $setDetecting: Boolean!) {
    setDetectingArticleSearch(id: $id, setDetecting: $setDetecting) {
      id
      name
      enabled
      is_default
      query_title
      query_keywords
    }
  }
`;

export const UPDATE_ARTICLE_SEARCH = gql`
  mutation updateArticleSearch($id: ID!, $patch: ArticleSearchInput!) {
    updateArticleSearch(id: $id, patch: $patch) {
      id
    }
  }
`;

/* 
ArticleSearchInput:
theme_id: Int
topic_id: Int
name: String
query_title: String
query_keywords: String
filter_author: String
filter_min_word_count: Int
filter_tld: String
filter_article_type: String
filter_sentiment: Int
filter_with_image: Boolean
filter_strict_mode: Boolean
filter_domain_blacklist: String
filter_keyword_blacklist: String
filter_reserved: String
language: String
*/

export const CREATE_ARTICLE_SEARCH = gql`
  mutation($search: ArticleSearchInput!, $isDefault: Boolean) {
    createArticleSearch(search: $search, isDefault: $isDefault) {
      id
    }
  }
`;

const PreviewArticle = `
  content_hash
  tweet_id_str
  ignored
  created_at
  updated_at
  last_seen
  published_date
  title
  ${/** sentiment */ ''}
  language
  facebook_share_count
  twitter_share_count
  linkedin_share_count
  pinterest_share_count
  total_share_count
  image
  thumbnail
  reply_count
  url
  domain
  content_word_count
  authors
  keywords
`;

// Accepted Format/fields for ArticleSearchProxyInput
//   sortOrder: String,
//   sortField: String,
//   query_period: String,
//   query_title: String,
//   query_keywords: String,
//   filter_author: String,
//   filter_min_word_count: Int,
//   filter_tld: String,
//   filter_article_type: String,
//   filter_sentiment: Int,
//   filter_with_image: Boolean,
//   filter_strict_mode: Boolean,
//   filter_domain_blacklist: String,
//   filter_keyword_blacklist: String,
//   filter_reserved: String,
//   language: String

export const PROXY_SOCIAL_ANIMAL = gql`
  query($search: ArticleSearchProxyInput) {
    proxySocialAnimal(search: $search)
      {
        total_results
        articles {
          ${PreviewArticle}
        }
      }
  }
`;

export const PROXY_NEWS_DATA = gql`
  query($search: ArticleSearchProxyInput) {
    proxyNewsData(search: $search)
      {
        total_results
        articles {
          ${PreviewArticle}
        }
      }
  }
`;

export const PROXY_RELATED_WORDS = gql`
  query($word: String!) {
    proxyRelatedWords(word: $word) {
      word
    }
  }
`;

export const PROXY_SA_URL_SUBMIT = gql`
  mutation($url: String!) {
    proxySAUrlSubmit(url: $url) {
      result
    }
  }
`;

export const CREATE_ARTICLE_SEARCH_FILTER = gql`
  mutation($articleSearchId: ID!, $keywords: String!) {
    createArticleSearchFilter(articleSearchId: $articleSearchId, keywords: $keywords) {
      id
      article_search_id
      included_title_keywords
    }
  }
`;

export const DELETE_ARTICLE_SEARCH_FILTER = gql`
  mutation($id: ID!) {
    deleteArticleSearchFilter(id: $id) {
      result
      message
    }
  }
`;

export const UPDATE_ARTICLE_SEARCH_FILTER = gql`
  mutation($id: ID!, $newKeywords: String!) {
    updateArticleSearchFilter(id: $id, newKeywords: $newKeywords) {
      result
      message
    }
  }
`;

export const SET_DETECTING_ARTICLE_SEARCH_FILTER = gql`
  mutation setDetectingArticleSearchFilter($id: ID!, $setDetecting: Boolean!) {
    setDetectingArticleSearchFilter(id: $id, setDetecting: $setDetecting) {
      id
      is_detecting
    }
  }
`;
