import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { PROGRAM_LIFT_INTENSITIES } from 'gql/programLiftIntensity';
import { TOP_PROGRAM_LIFT_CONTRIBUTORS } from 'gql/programLiftMagnitude';
import { PROGRAM_LIFT_CONTRIBUTION_SCORES } from 'gql/programLiftContributionScore';
import { useQuery } from '@apollo/client';
import ProgramLiftDetails from './ProgramLiftDetails';
import ProgramIntensity from './ProgramIntensity';
import TopTenContributors from './TopTenContributors';
import ContributionScoreLift from './ContributionScoreLift';

const useStyles = makeStyles(() => ({
  emptyResults: {
    position: 'relative',
    marginTop: '250px',
    marginBottom: '250px',
    fontWeight: 400,
    fontSize: '24px',
    width: '100%',
    textAlign: 'center'
  },
  gridContainerWrapper: {
    backgroundColor: '#FFF',
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%' /* 16:9 aspect ratio */
  },
  gridContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '6fr 4fr' /* 60% and 40% width for columns 1 and 2 respectively */,
    gridGap: '0px'
  },
  gridItem: {
    backgroundColor: '#FFF',
    padding: '34px',
    border: '1px solid black'
  },
  topLeftQuad: {
    minHeight: '278px',
    borderTop: 'none',
    borderLeft: 'none'
  },
  topRightQuad: {
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none'
  },
  bottomLeftQuad: {
    borderTop: 'none',
    borderBottom: 'none',
    borderLeft: 'none'
  },
  bottomRightQuad: {
    borderTop: 'none',
    borderBottom: 'none',
    borderRight: 'none',
    borderLeft: 'none'
  },
  '@media (max-width: 1800px)': {
    gridContainerWrapper: {
      position: 'initial',
      paddingBottom: '0'
    },
    gridContainer: {
      position: 'initial'
    }
  }
}));

const ProgramLiftQuad = props => {
  const { reportId, selectedDates, selectedPeriodical, isSwitchChecked } = props;

  const classes = useStyles();

  const { loading: loadingIntensities, error: errorIntensities, data: dataIntensities } = useQuery(
    PROGRAM_LIFT_INTENSITIES,
    {
      variables: {
        id: reportId,
        startDate: selectedDates?.start?.toISOString(),
        endDate: selectedDates?.end?.toISOString()
      },
      skip: reportId == null || selectedDates == null
    }
  );

  const {
    loading: loadingLiftContributors,
    error: errorLiftContributors,
    data: dataContributors
  } = useQuery(TOP_PROGRAM_LIFT_CONTRIBUTORS, {
    variables: {
      programLiftReportId: reportId,
      endDate: selectedDates?.end?.toISOString(),
      periodical: selectedPeriodical,
      hideDimensions: isSwitchChecked
    },
    fetchPolicy: 'network-only',
    skip: reportId == null || selectedDates == null || selectedPeriodical == null
  });

  const {
    loading: loadingContributionScore,
    error: errorContributionScore,
    data: dataContributionScore
  } = useQuery(PROGRAM_LIFT_CONTRIBUTION_SCORES, {
    variables: {
      id: reportId,
      periodical: selectedPeriodical,
      endDate: selectedDates?.end?.toISOString()
    },
    skip: reportId == null || selectedPeriodical == null || selectedDates == null
  });

  if (
    (!dataIntensities?.programLiftIntensities ||
      dataIntensities?.programLiftIntensities?.length === 0) &&
    (!dataContributors?.topProgramLiftContributors ||
      dataContributors?.topProgramLiftContributors?.length === 0) &&
    (!dataContributionScore?.programLiftContributionScores ||
      dataContributionScore?.programLiftContributionScores?.length === 0)
  ) {
    return (
      <div className={classes.emptyResults}>
        No correlations exist in this time period to base the calculations on.
      </div>
    );
  }

  return (
    <div className={classes.gridContainerWrapper}>
      <div className={classes.gridContainer}>
        <div className={`${classes.gridItem} ${classes.topLeftQuad}`}>
          <ProgramLiftDetails reportId={reportId} selectedDates={selectedDates} />
        </div>
        <div className={`${classes.gridItem} ${classes.topRightQuad}`}>
          <ProgramIntensity
            loading={loadingIntensities}
            error={errorIntensities}
            data={dataIntensities}
          />
        </div>
        <div className={`${classes.gridItem} ${classes.bottomLeftQuad}`}>
          <TopTenContributors
            loading={loadingLiftContributors}
            error={errorLiftContributors}
            data={dataContributors}
          />
        </div>
        <div className={`${classes.gridItem} ${classes.bottomRightQuad}`}>
          <ContributionScoreLift
            loading={loadingContributionScore}
            error={errorContributionScore}
            data={dataContributionScore}
          />
        </div>
      </div>
    </div>
  );
};

ProgramLiftQuad.propTypes = {
  reportId: PropTypes.string,
  selectedDates: PropTypes.shape(),
  selectedPeriodical: PropTypes.string,
  isSwitchChecked: PropTypes.bool
};

ProgramLiftQuad.defaultProps = {
  reportId: null,
  selectedDates: null,
  selectedPeriodical: null,
  isSwitchChecked: null
};

export default ProgramLiftQuad;
