import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Grid, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles({
  headerContainer: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '0px',
    zIndex: 1000
  },
  headerTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#0A1734'
  },
  headerButton: {
    width: '45px',
    height: '45px',
    color: '#FFFFFF',
    backgroundColor: '#0D1A3A'
  },
  titleText: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27px'
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px'
  }
});

/**
 * @summary Simple header component for analytics that displays a title, a static date and a close button.
 * @name SimpleAnalyticsHeader
 * @param {string} title - The title of the analytics section
 * @param {Function} onClose - callback function that generally routes to the previous section.
 * @param {string} dates - Optional dates string for static display.
 * @return {Object} - React JSX
 */
const SimpleAnalyticsHeader = ({ title, onClose, dates }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.headerContainer}>
      <Container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ height: '176px' }}
        >
          <Grid container justifyContent="space-between" style={{ width: 'unset', flex: 1 }}>
            <Grid item>
              <Typography className={classes.headerTitle}>{title}</Typography>
            </Grid>
            {dates && (
              <div className={classes.rightContainer}>
                <Typography
                  variantMapping={{ body1: 'span' }}
                  className={classes.titleText}
                  style={{ color: '#0A1734' }}
                >
                  Dates:&nbsp;
                </Typography>
                <Typography
                  variantMapping={{ body1: 'span' }}
                  className={classes.titleText}
                  style={{ color: '#979797' }}
                >
                  {dates}
                </Typography>
              </div>
            )}
          </Grid>

          <Grid item>
            <IconButton onClick={onClose} className={classes.headerButton}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

SimpleAnalyticsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dates: PropTypes.string
};

SimpleAnalyticsHeader.defaultProps = {
  dates: null
};

export default SimpleAnalyticsHeader;
