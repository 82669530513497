import React from 'react';
import PropTypes from 'prop-types';
import ForecastGraph from 'components/ForecastGraph';

const WebConversions = props => {
  const { accounts } = props;

  return <ForecastGraph accounts={accounts} />;
};

WebConversions.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default WebConversions;
