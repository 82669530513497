import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Grid, Typography, Button, InputLabel } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { LINK_APP_STORE_CONNECT_ACCOUNT } from 'gql/linktoken';
import { goToRoute } from 'util/routesHelpers';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';
import { LinkAccountsPath } from '../../util/paths';

/**
 * @method
 * @summary This component contains a form allowing the user to input credentials required to connect an App Store Connect account
 * @name LinkAppStoreConnectAccountForm
 * @return {Object} - React JSX
 */
const LinkAppStoreConnectAccountForm = props => {
  const { history } = props;

  const [vendorId, setVendorId] = useState('');
  const [keyId, setKeyId] = useState('');
  const [issuer, setIssuer] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  const [linkAppStoreConnectAccount, { loading, error }] = useMutation(
    LINK_APP_STORE_CONNECT_ACCOUNT,
    {
      onCompleted: result => {
        if (result?.createAppStoreConnectLink?.token?.remote_name) {
          showToast(
            // eslint-disable-next-line camelcase
            `${result?.createAppStoreConnectLink.token.remote_name} App Store Connect account has been linked. `,
            'success'
          );
          goToRoute(LinkAccountsPath, history);
        }
      }
    }
  );

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  return (
    <>
      <ValidatorForm
        onSubmit={() => {
          linkAppStoreConnectAccount({
            variables: {
              vendorId: vendorId.trim(),
              keyId: keyId.trim(),
              issuer: issuer.trim(),
              private_key: privateKey.trim()
            }
          });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Enter your API Credentials</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Vendor #</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Vendor #"
              value={vendorId}
              onChange={event => setVendorId(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Key ID</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Key ID"
              value={keyId}
              onChange={event => setKeyId(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Issuer ID</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Issuer ID"
              value={issuer}
              onChange={event => setIssuer(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Private Key</InputLabel>
            <TextValidator
              multiline
              rows={10}
              fullWidth
              type="text"
              variant="filled"
              placeholder="Private Key"
              value={privateKey}
              onChange={event => setPrivateKey(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
              Save Credentials
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

LinkAppStoreConnectAccountForm.propTypes = {
  history: PropTypes.shape().isRequired
};

export default withRouter(LinkAppStoreConnectAccountForm);
