/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlbError from 'components/AlbError';
import AlbLoading from 'components/AlbLoading';
import CustomFooter from 'components/TablePagination';
import AlbTable from 'components/AlbTable';
import { WEB_AND_APPS_APPS_TABLE } from 'gql/analytics';
import { getDatesForAnalytics } from 'util/date';
import getValueAbbreviation from 'util/getValueAbbreviation';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const tableThemeOverrides = {
  MUIDataTable: {
    paper: {
      flex: 1,
      boxShadow: 'none'
    }
  },
  MUIDataTableHeadRow: {
    root: {
      boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
    }
  },
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap'
    },
    sortAction: {
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  }
};

/**
 * @summary This component renders the breakdown tables for Web and Apps apps tab
 * @name WebAppsTable
 * @param {Object} props - React props passed to this component
 * @param {Object} props.tab - The selected tab displayed in the table's container
 * @param {Object[]} props.accounts - An array of link token objects from @see PostAccountSelector
 * @param {Object} props.dates - An object containing start and end dates from @see AnalyticsDatePicker
 * @return {Object} - React JSX
 */
const WebAppsTable = props => {
  const {
    tab,
    accounts,
    dates,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortOrder,
    setSortOrder,
    count,
    setCount
  } = props;

  const classes = useStyles();

  const [tableData, setTableData] = useState([]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data, error, loading } = useQuery(WEB_AND_APPS_APPS_TABLE, {
    variables: {
      startDate,
      endDate,
      linkTokens: accounts.map(account => account.id),
      metric: `${tab.metric}Table`,
      after: page * rowsPerPage,
      count: rowsPerPage,
      orderBy: sortOrder
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    let tempTableData = [];
    let tempCount = 0;

    if (data?.webAndAppsAppsTable) {
      const { rows, count: rowCount } = data.webAndAppsAppsTable;

      tempTableData = rows;
      tempCount = rowCount;
    }

    setTableData(tempTableData);
    setCount(tempCount);
  }, [data, accounts, dates]);

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const customRevenueRender = value => (
    <div style={{ whiteSpace: 'nowrap' }}>{getValueAbbreviation(value, true, false)}</div>
  );

  const customDeviceRender = value => {
    const alternateLabel = {
      Desktop: 'Mac'
    }[value];

    return <div style={{ whiteSpace: 'nowrap' }}>{alternateLabel || value}</div>;
  };

  const revenueTabs = ['Revenue', 'Purchases'];
  const deviceTabs = ['Active Devices', 'Uninstalls'];

  const showRevenueColumn = revenueTabs.includes(tab.title);

  // don't need to sort since this table is restricted to google play (Android)
  const sortDeviceColumn = !deviceTabs.includes(tab.title);

  const countColumnLabel = {
    Installs: 'INSTALLS',
    Revenue: 'PURCHASES',
    Purchases: 'PURCHASES',
    'Active Devices': 'DAILY ACTIVE DEVICES',
    Uninstalls: 'UNINSTALLS'
  }[tab.title];

  const tableColumns = [
    {
      name: 'linktoken',
      label: 'APP',
      options: { sort: false }
    },
    {
      name: 'product',
      label: 'PRODUCT',
      options: { display: showRevenueColumn }
    },
    {
      name: 'device',
      label: 'DEVICE',
      options: { sort: sortDeviceColumn, customBodyRender: customDeviceRender }
    },
    {
      name: 'count',
      label: countColumnLabel
    },
    {
      name: 'revenue',
      label: 'TOTAL REVENUE',
      options: { display: showRevenueColumn, customBodyRender: customRevenueRender }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1].toLowerCase() },
    onColumnSortChange: (changedColumn, direction) =>
      setSortOrder([changedColumn, direction.toUpperCase()])
  };

  if (error) return <AlbError error={error} />;

  return (
    <Grid container className={classes.container}>
      {loading && (
        <Grid item className={classes.loadingOverlay}>
          <AlbLoading />
        </Grid>
      )}
      <Grid container style={{ opacity: loading ? 0.5 : 1 }}>
        <AlbTable
          tableData={tableData}
          tableColumns={tableColumns}
          tableOptions={tableOptions}
          tableThemeOverrides={tableThemeOverrides}
        />
      </Grid>
    </Grid>
  );
};

WebAppsTable.propTypes = {
  tab: PropTypes.shape().isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  sortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSortOrder: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  setCount: PropTypes.func.isRequired
};

export default WebAppsTable;
