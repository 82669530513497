import { gql } from '@apollo/client';
import { INITIAL_USER_AUTH } from './user';

export const INVITATION_USERS = gql`
  query invitations($containerId: ID!) {
    invitations(containerId: $containerId) {
      id
      recipient_first_name
      recipient_last_name
      recipient_email
      role {
        id
        name
      }
      created_at
      updated_at
    }
  }
`;

export const CREATE_INVITE = gql`
  mutation createInvitation(
    $container_id: ID!
    $recipient_first_name: String!
    $recipient_last_name: String!
    $recipient_email: String!
    $recipient_role: ID!
    $message: String
  ) {
    createInvitation(
      container_id: $container_id
      recipient_first_name: $recipient_first_name
      recipient_last_name: $recipient_last_name
      recipient_email: $recipient_email
      recipient_role: $recipient_role
      message: $message
    )
  }
`;
export const RESEND_INVITE = gql`
  mutation resendInvitation($id: ID!) {
    resendInvitation(id: $id) {
      result
      message
    }
  }
`;

export const DELETE_INVITE = gql`
  mutation deleteInvitation($id: ID!) {
    deleteInvitation(id: $id) {
      result
      message
    }
  }
`;

export const CHECK_INVITE_TOKEN = gql`
  query checkInviteToken($token: String!) {
    checkInviteToken(token: $token) {
      id
      type
      created_by
      organization_id
      container_id

      organization_name
      organization_avatar_url

      container_name

      recipient_email

      used
      valid_until
      recipient_role
      recipient_first_name
      recipient_last_name
      created_at
      updated_at
    }
  }
`;

export const CREATE_USER_FROM_INVITE = gql`
  mutation createUserFromInvite(
    $token: String!
    $company: String!
    $title: String!
    $time_zone: String!
    $password: String!
  ) {
    createUserFromInvite(
      token: $token
      company: $company
      title: $title
      time_zone: $time_zone
      password: $password
    ) {
      user {
        ${INITIAL_USER_AUTH}
      }
      expires
    }
  }
`;
