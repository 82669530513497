import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { Palette } from 'components/AlbGraphs/Legend';
import Box from 'components/Box';
import { historyForecastTooltip } from 'components/AlbGraphs/CustomTooltipTemplates';
import theme from 'theme';
import colors, { topPostsColors, topPostsGradient } from 'util/colors';
import { formatCurrencyRound } from 'util/formatCurrency';
import formatSalesforceLabel from '../formatSalesforceLabel';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  tooltip: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    pointerEvents: 'none'
  },
  hidden: {
    display: 'none !important'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const tableTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputAdornment: {
      positionStart: {
        marginRight: '0px'
      }
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: '10px'
      }
    }
  }
});

const HistoryForecastGraph = props => {
  const { data, loading, error, isRevenue } = props;

  const classes = useStyles();

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [legend, setLegend] = useState([]);
  const [dataLookup, setDataLookup] = useState({});
  const [hiddenDataSets, setHiddenDataSets] = useState({});

  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    let graphLabels = [];
    const graphDatasets = [];
    const graphLegend = [];
    let graphDataLookup = {
      tooltipInfo: {}
    };

    setHiddenDataSets({});

    if (data?.length) {
      data.forEach((dataset, i) => {
        // hide datasets where label is empty (for now)
        if (!dataset.hideTimeseries) {
          // create labels
          if (!graphLabels.length) {
            graphLabels = [
              ...dataset.timeseries.map(({ x }) => moment(x).format('M/DD/YY')),
              ...dataset.timeseriesForecast.map(({ x }) => moment(x).format('M/DD/YY'))
            ];
          }

          // interpolate historical and predictive timeseries
          const timeseries = [
            ...dataset.timeseries.map(({ y }) => y),
            ...dataset.timeseriesForecast.map(() => null)
          ];

          const timeseriesForecast = [
            ...dataset.timeseries.map(() => null),
            ...dataset.timeseriesForecast.map(({ y }) => y)
          ];

          const color = topPostsColors[i % topPostsColors.length];
          const [forecastColor] = topPostsGradient[i % topPostsGradient.length];

          graphDataLookup.tooltipInfo[formatSalesforceLabel(dataset)] = {
            label: formatSalesforceLabel(dataset),
            color
          };
          // predictive datasets will reference same tooltip lookup
          graphDataLookup.tooltipInfo[`${formatSalesforceLabel(dataset)} - Forecast`] = {
            label: formatSalesforceLabel(dataset),
            color
          };

          graphLegend.push({
            label: formatSalesforceLabel(dataset),
            backgroundColor: color
          });

          graphDatasets.push(
            {
              type: 'line',
              label: formatSalesforceLabel(dataset),
              data: timeseries,
              backgroundColor: color,
              borderColor: color,
              fill: false,
              lineTension: 0,
              pointRadius: 0,
              borderWidth: 3
            },
            {
              type: 'line',
              forecast: true,
              label: `${formatSalesforceLabel(dataset)} - Forecast`,
              data: timeseriesForecast,
              backgroundColor: forecastColor,
              borderColor: forecastColor,
              fill: false,
              lineTension: 0,
              pointRadius: 0,
              borderWidth: 3
            }
          );
        }
      });
    } else {
      graphLabels = [];
      graphDataLookup = {
        tooltipInfo: {}
      };
    }

    setLabels(graphLabels);
    setDatasets(graphDatasets);
    setLegend(graphLegend);
    setDataLookup(graphDataLookup);
  }, [data]);

  const options = {
    // https://www.chartjs.org/docs/2.9.4/configuration/legend.html
    legend: {
      display: false
    },
    // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
    tooltips: {
      enabled: false,
      intersect: false,
      custom: tooltipModel => {
        const { offsetTop, offsetLeft } = canvasRef.current.canvas;
        const { dataPoints } = tooltipModel;
        // if tooltip.opacity is 0 then we need to hide the tooltip
        const tempIsTooltipVisible = tooltipModel.opacity !== 0;
        let tooltipWidth = 295;
        setIsTooltipVisible(tempIsTooltipVisible);

        let title;

        if (tooltipModel.body) {
          const [line] = tooltipModel.body[0].lines;
          const id = line.split(':');
          title = id.slice(0, -1).join(':');
        }

        if (tempIsTooltipVisible) {
          dataPoints.forEach(dataPoint => {
            if (tooltipRef.current) {
              const innerHTML = historyForecastTooltip({
                title: dataLookup.tooltipInfo[title].label,
                value: `${isRevenue ? '$' : ''}${dataPoint.yLabel.toLocaleString('en', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2
                })}`,
                boxColor: dataLookup.tooltipInfo[title].color
              });

              const style = {};

              const top = offsetTop + dataPoint.y;
              const left = offsetLeft + dataPoint.x;

              style.direction = 'ltr';
              style.top = `${top - 2.5}px`;
              style.left = `${left - 2.5}px`;

              if (tooltipRef.current.offsetWidth > tooltipWidth) {
                tooltipWidth = tooltipRef.current.offsetWidth;
              }

              if (left + tooltipWidth > canvasRef.current.canvas.getBoundingClientRect().width) {
                style.direction = 'rtl';
              }

              Object.assign(tooltipRef.current.style, style);
              Object.assign(tooltipRef.current, { innerHTML });
            }
          });
        }
      }
    },
    // https://www.chartjs.org/docs/2.9.4/general/interactions/
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    animation: {
      duration: 0 // general animation time
    },
    // https://www.chartjs.org/docs/2.9.4/general/performance.html#disable-animations
    responsiveAnimationDuration: 0, // animation duration after a resize
    scales: {
      xAxes: [
        {
          // https://www.chartjs.org/docs/2.9.4/axes/styling.html#grid-line-configuration
          gridLines: {
            display: false
          },
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/#tick-configuration
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/linear.html
          ticks: {
            maxTicksLimit: 7,
            maxRotation: 0,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600
          },
          stacked: false
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            callback: value => `${isRevenue ? '$' : ''}${formatCurrencyRound(value)}`,
            precision: 2,
            beginAtZero: true,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 10,
            fontWeight: 600
          },
          stacked: false
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: moment()
            .utc()
            .format('M/DD/YY'),
          borderColor: '#000000',
          label: {
            content: 'Today',
            enabled: true,
            position: 'top',
            backgroundColor: 'transparent',
            fontColor: '#0D1A3A',
            fontFamily: 'Poppins',
            xAdjust: 25
          }
        }
      ]
    },
    // https://www.chartjs.org/docs/2.9.4/charts/line.html#line-styling
    spanGaps: true
  };

  const graphData = canvas => {
    canvasRef.current = canvas.getContext('2d');

    let dataSets = datasets;

    dataSets = dataSets.map(properties => {
      let key = properties.label;

      if (properties.forecast) {
        // trim forecast label from dataset
        key = key.replace(' - Forecast', '');
      }

      return {
        ...properties,
        ...(hiddenDataSets[key] && { data: null })
      };
    });

    return { labels, datasets: dataSets };
  };

  return (
    <MuiThemeProvider theme={tableTheme}>
      <Grid container className={classes.container}>
        {error && <AlbError error={error} />}
        {loading && (
          <Grid item className={classes.loadingOverlay}>
            <AlbLoading />
          </Grid>
        )}
        <Grid container style={{ padding: '30px', opacity: loading ? 0.5 : 1 }}>
          <Bar data={graphData} options={options} />
          <Grid container justifyContent="center">
            <Palette
              legend={{
                palette: legend.map(({ label, backgroundColor: color }) => {
                  return {
                    label,
                    key: label,
                    color
                  };
                })
              }}
              hiddenDataSets={hiddenDataSets}
              setHiddenDataSets={setHiddenDataSets}
            />
          </Grid>
          <Box
            ref={tooltipRef}
            className={`${classes.tooltip} ${isTooltipVisible ? '' : classes.hidden}`}
          />
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

HistoryForecastGraph.defaultProps = {
  loading: false,
  error: null,
  data: null,
  isRevenue: false
};

HistoryForecastGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  isRevenue: PropTypes.bool
};

export default HistoryForecastGraph;
