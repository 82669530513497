import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SET_ARTICLE_IGNORE, OWNED_TO_EARNED_ANALYTICS } from 'gql/article';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const IgnoreArticleModal = props => {
  const { isModalOpen, onChange, articleId, articleTitle, exclude, articleSearchId } = props;

  const [setArticleIgnore, { error, data }] = useMutation(SET_ARTICLE_IGNORE);

  useEffect(() => {
    if (data?.setArticleIgnore) {
      showToast(`Article ${exclude ? 'exclude' : 'include'}d`, 'success');
      onChange({ isModalOpen: false, newArticleData: data.setArticleIgnore });
    }
  }, [data]);

  useEffect(() => {
    if (error) onChange({ isModalOpen: false });
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        isDelete={exclude}
        title={`${exclude ? 'Exclude' : 'Include'} Article`}
        body={
          <span style={{ fontFamily: 'Poppins' }}>
            {`Are you sure you want to ${exclude ? 'exclude' : 'include'} the article:`}&nbsp;
            <span style={{ color: '#0A1734' }}>{articleTitle}</span>?
          </span>
        }
        cancelTitle="Cancel"
        confirmTitle={`${exclude ? 'Exclude' : 'Include'} Article`}
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() =>
          setArticleIgnore({
            variables: { id: articleId, ignored: exclude },
            ...(articleSearchId != null && {
              refetchQueries: [
                { query: OWNED_TO_EARNED_ANALYTICS, variables: { id: articleSearchId } }
              ]
            })
          })
        }
      />
    </>
  );
};

IgnoreArticleModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  articleId: PropTypes.string,
  articleTitle: PropTypes.string,
  exclude: PropTypes.bool,
  articleSearchId: PropTypes.string
};

IgnoreArticleModal.defaultProps = {
  articleId: null,
  articleTitle: null,
  exclude: false,
  articleSearchId: null
};

export default IgnoreArticleModal;
