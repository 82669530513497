const SOCIAL_IMPACT_CARD_TITLE = 'Social Impact';
const SOCIAL_MEDIA_CARD_TITLE = 'Social Media';
const HYPE_AND_FLOW_CARD_TITLE = 'Hype & Flow';
const THIRD_PARTY_MEDIA_CARD_TITLE = '3rd Party Media';
const SHARE_OF_VOICE_CARD_TITLE = 'Competitive Intelligence';
const MEDIA_VALUATION_CARD_TITLE = 'Media Valuation';
const ORG_TREE_CARD_TITLE = 'Organization Tree';
const SHORTLINK_CARD_TITLE = 'Shortlinks';
const RESURGENCE_CARD_TITLE = 'Resurgence';
const WEB_AND_APPS_CARD_TITLE = 'Web & Apps';
const PROGRAM_LIFT_CARD_TITLE = 'Program Lift';
const SALESFORCE_CARD_TITLE = 'Salesforce';
const TARGETING_CARD_TITLE = 'Targeting';

export {
  SOCIAL_IMPACT_CARD_TITLE,
  SOCIAL_MEDIA_CARD_TITLE,
  HYPE_AND_FLOW_CARD_TITLE,
  THIRD_PARTY_MEDIA_CARD_TITLE,
  SHARE_OF_VOICE_CARD_TITLE,
  MEDIA_VALUATION_CARD_TITLE,
  ORG_TREE_CARD_TITLE,
  SHORTLINK_CARD_TITLE,
  RESURGENCE_CARD_TITLE,
  WEB_AND_APPS_CARD_TITLE,
  PROGRAM_LIFT_CARD_TITLE,
  SALESFORCE_CARD_TITLE,
  TARGETING_CARD_TITLE
};
