import React, { useState, useEffect, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import Box from 'components/Box';
import colors from 'util/colors';
import AlbLoading from 'components/AlbLoading';
import { renderUIDate } from 'util/date';

const useStyles = makeStyles({
  timeline: {
    height: '114px',
    backgroundColor: 'white'
  },
  date: {
    height: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-1px',
    flex: 1
  },
  lineContainer: {
    flex: 1
  },
  line: {
    backgroundColor: colors.navy,
    height: '2px'
  },
  dot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: 'white',
    fontSize: '10px',
    height: '20px',
    width: '20px',
    cursor: 'pointer'
  },
  selectedDot: {
    height: '25px',
    width: '25px',
    fontSize: '13px'
  }
});

const Dot = memo(props => {
  const {
    id,
    posts,
    top,
    selectedDot,
    selectedPosts,
    highlightedPosts,
    setSelectedDot,
    handleSelectPosts,
    handleHighlightPosts,
    openPostDrawer
  } = props;
  const classes = useStyles();

  const postIds = posts.map(({ id: postId }) => postId);
  const count = posts.length;

  useEffect(() => {
    if (selectedPosts.length) {
      if (selectedPosts.every(postId => postIds.includes(postId))) setSelectedDot(id);
    } else {
      setSelectedDot(null);
    }
  }, [selectedPosts]);

  return (
    <Box
      className={`${classes.dot} ${
        id === selectedDot || postIds.includes(highlightedPosts[0]) ? classes.selectedDot : ''
      }`}
      style={{ backgroundColor: top ? colors.green : colors.red1 }}
      onMouseEnter={() => handleHighlightPosts(postIds)}
      onMouseLeave={() => handleHighlightPosts([])}
      onClick={() => {
        if (id === selectedDot) {
          setSelectedDot(null);
          handleSelectPosts([]);
        } else {
          const [eventId] = postIds;

          openPostDrawer(eventId);
          setSelectedDot(id);
          handleSelectPosts(postIds);
        }
      }}
    >
      {count > 1 && `+${count - 1}`}
    </Box>
  );
});

const sharedPropTypes = {
  selectedPosts: PropTypes.arrayOf(PropTypes.string),
  highlightedPosts: PropTypes.arrayOf(PropTypes.string),
  handleSelectPosts: PropTypes.func.isRequired,
  handleHighlightPosts: PropTypes.func.isRequired
};

const sharedDefaultProps = {
  selectedPosts: [],
  highlightedPosts: []
};

Dot.propTypes = {
  id: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  top: PropTypes.bool,
  selectedDot: PropTypes.string,
  setSelectedDot: PropTypes.func.isRequired,
  openPostDrawer: PropTypes.func.isRequired,
  ...sharedPropTypes
};

Dot.defaultProps = {
  top: false,
  selectedDot: null,
  ...sharedDefaultProps
};

const Dates = memo(props => {
  const { dateRange, top, bottom, ...dotProps } = props;
  const { startDate, endDate } = dotProps;
  const drawerContext = useContext(PostDrawerViewContext);
  const classes = useStyles();

  const openPostDrawer = eventId =>
    drawerContext.toggleDrawer(true, eventId, null, null, startDate, endDate);

  return dateRange.map(({ id, date }) => {
    const topPostsByDate = top[renderUIDate(date)];
    const bottomPostsByDate = bottom[renderUIDate(date)];

    return (
      <Grid item key={id} className={classes.date}>
        {topPostsByDate && (
          <Dot
            id={`${id}-top`}
            posts={topPostsByDate}
            openPostDrawer={openPostDrawer}
            {...dotProps}
            top
          />
        )}
        {bottomPostsByDate && (
          <Dot
            id={`${id}-bottom`}
            posts={bottomPostsByDate}
            openPostDrawer={openPostDrawer}
            {...dotProps}
          />
        )}
      </Grid>
    );
  });
});

Dates.propTypes = {
  // This component uses memo() which seems to confuse ESLint.
  // Let's ignore these warnings for now -- jna 7/21/22

  // eslint-disable-next-line react/no-unused-prop-types
  dateRange: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  top: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  bottom: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  startDate: PropTypes.instanceOf(Date).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  endDate: PropTypes.instanceOf(Date).isRequired,
  ...sharedPropTypes
};

Dates.defaultProps = {
  ...sharedDefaultProps
};

const AnalyticsTimeline = props => {
  const { dates, topPosts, bottomPosts, loading, ...dotProps } = props;
  const classes = useStyles();

  const [selectedDot, setSelectedDot] = useState(null);

  const dateLookup = posts =>
    posts.reduce((acc, curr) => {
      const date = renderUIDate(curr.date);

      if (acc[date]) {
        acc[date].push(curr);
      } else {
        acc[date] = [curr];
      }

      return acc;
    }, {});

  if (loading || !dates || dates?.length === 0) return <AlbLoading />;

  const top = dateLookup(topPosts);
  const bottom = dateLookup(bottomPosts);

  const start = moment(dates[0].date)
    .format('MMM D/YYYY')
    .split('/');
  const end = moment(dates[dates.length - 1].date)
    .format('MMM D/YYYY')
    .split('/');

  const dateLabel = date => <Box style={{ textAlign: 'center' }}>{date}</Box>;

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.timeline}
      >
        <Grid item>
          <Box ml={17} mr={12}>
            {dateLabel(start[0])}
            {dateLabel(start[1])}
          </Box>
        </Grid>
        <Grid item className={classes.lineContainer}>
          <Box className={classes.line} />
          <Grid container justifyContent="space-between">
            <Dates
              dateRange={dates}
              top={top}
              bottom={bottom}
              selectedDot={selectedDot}
              setSelectedDot={setSelectedDot}
              {...dotProps}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box ml={12} mr={17}>
            {dateLabel(end[0])}
            {dateLabel(end[1])}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

AnalyticsTimeline.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  topPosts: PropTypes.arrayOf(PropTypes.shape({})),
  bottomPosts: PropTypes.arrayOf(PropTypes.shape({})),
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  ...sharedPropTypes
};

AnalyticsTimeline.defaultProps = {
  topPosts: [],
  bottomPosts: [],
  ...sharedDefaultProps
};

export default AnalyticsTimeline;
