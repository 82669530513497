import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SET_DETECTING_ARTICLE_SEARCH } from 'gql/articleSearch';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const DetectingArticleSearchModal = props => {
  const { isModalOpen, onChange, articleSearchId, articleSearchName, setDetecting } = props;

  const [setDetectingArticleSearch, { error, data }] = useMutation(SET_DETECTING_ARTICLE_SEARCH);

  useEffect(() => {
    if (data?.setDetectingArticleSearch) {
      if (data.setDetectingArticleSearch.is_default) {
        showToast(`Report ${articleSearchName} is now detecting events.`, 'success');
      }

      if (!data.setDetectingArticleSearch.is_default) {
        showToast(`Report ${articleSearchName} stopped detecting events.`, 'success');
      }

      onChange({
        isModalOpen: false,
        newArticleSearchData: data.setDetectingArticleSearch
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Update Event Detection"
        body={
          <>
            Do you want to make this report {setDetecting ? 'detect' : 'stop detecting'} events:
            <span style={{ color: '#0A1734' }}>{articleSearchName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle={`${setDetecting ? 'Start' : 'Stop'} Detecting`}
        isDelete={!setDetecting}
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() =>
          setDetectingArticleSearch({
            variables: { id: articleSearchId, setDetecting }
          })
        }
      />
    </>
  );
};

DetectingArticleSearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  articleSearchId: PropTypes.string,
  articleSearchName: PropTypes.string,
  setDetecting: PropTypes.bool.isRequired
};

DetectingArticleSearchModal.defaultProps = {
  articleSearchId: null,
  articleSearchName: null
};

export default DetectingArticleSearchModal;
