import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import colors from 'util/colors';
import { formatSecondsDisplay } from 'util/time';
import theme from 'theme';
import { shortenText } from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import PostDrawerAgeBar from './PostDrawerAgeBar';

const useStyles = makeStyles({
  column: {
    fontSize: '13px',
    fontWeight: 700,
    color: colors.darkBlue,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  change: {
    fontSize: '13px',
    whiteSpace: 'nowrap'
  },
  positive: {
    fontWeight: 500,
    color: colors.green
  },
  negative: {
    fontWeight: 500,
    color: colors.red
  },
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  },
  empty: {
    fontFamily: 'Poppins',
    padding: '20px'
  }
});

const tableTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiTableRow: {
      root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap'
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#f5f5f5'
        },
        '&:hover': {
          backgroundColor: colors.athensGray
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none'
      },
      body: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  }
});

const PostDrawerTable = props => {
  const { tableData, type } = props;

  const classes = useStyles();

  const tableSettings = {
    DEMO_TABLE: {
      format: m => m * 60,
      sort: { name: 'total', direction: 'desc' },
      column: 'COUNTRY'
    },
    DEMO_TABLE_AGE: {
      format: ms => ms / 1000,
      sort: { name: 'column', direction: 'asc' },
      column: 'AGE RANGE'
    }
  };

  const [sortOrder, updateSortOrder] = useState(tableSettings[type].sort);

  const customTotalRender = total => (
    <div align="center">{formatSecondsDisplay(tableSettings[type].format(total))}</div>
  );

  const customChangeRender = (difference, { columnIndex, rowData }) => {
    const formattedPercentage = rowData[columnIndex + 1]
      ? `${Math.round((rowData[columnIndex + 1] + Number.EPSILON) * 100) / 100}%`
      : '-';

    const value = formatSecondsDisplay(tableSettings[type].format(difference));
    const formattedValue = difference > 0 ? `+${value}` : value;

    return (
      <div
        className={`${classes.change} ${difference > 0 ? classes.positive : ''}${
          difference < 0 ? classes.negative : ''
        }`}
        align="right"
      >
        {`${formattedPercentage} (${formattedValue})`}
      </div>
    );
  };

  const customColumnRender = column => (
    <Typography className={classes.column}>{shortenText[column] || column}</Typography>
  );

  const columns = [
    {
      name: 'column',
      label: tableSettings[type].column,
      options: {
        customBodyRender: customColumnRender
      }
    },
    {
      name: 'total',
      label: 'TOTAL',
      options: {
        customBodyRender: customTotalRender,
        setCellHeaderProps: () => ({
          className: classes.centeredHeader
        })
      }
    },
    {
      name: 'difference',
      label: 'CHANGE',
      options: {
        customBodyRender: customChangeRender,
        setCellHeaderProps: () => ({
          className: classes.rightHeader
        })
      }
    },
    {
      name: 'percentage',
      options: { display: false }
    }
  ];

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard',
    sortOrder,
    onColumnSortChange: (name, direction) => updateSortOrder({ name, direction })
  };

  return (
    <>
      {!!tableData.length && (
        <>
          {type === 'DEMO_TABLE_AGE' && <PostDrawerAgeBar data={tableData} />}
          <MuiThemeProvider theme={tableTheme}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <MUIDataTable data={tableData} columns={columns} options={options} />
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </>
      )}
      {!tableData.length && (
        <div className={classes.empty}>Sorry, no data available for this post</div>
      )}
    </>
  );
};

PostDrawerTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  type: PropTypes.string.isRequired
};

export default PostDrawerTable;
