import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button } from '@material-ui/core';
import Box from 'components/Box';

const styles = makeStyles({
  title: {
    color: '#0A1734',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '18px'
  },
  subtitle: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#0A1734',
    ontWeight: '500'
  },
  body: {
    color: 'rgba(10, 23, 52, 0.5)',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    marginBottom: '10px'
  },
  fullWidthBody: {
    color: 'rgba(10, 23, 52, 0.5)',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    marginBottom: '10px',
    width: '100%'
  },
  noteLabel: {
    color: '#585858',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '19px'
  },
  note: {
    color: '#585858',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px'
  },
  actions: {
    marginTop: '24px',
    '& button:last-child': {
      marginLeft: '20px'
    }
  },
  deleteButton: {
    color: '#FFFFFF',
    background: '#E81828',
    '&:hover': {
      background: '#979797'
    }
  },
  cancelDelete: {
    color: '#E81828',
    borderColor: '#E81828'
  },
  confirmButton: {
    color: '#FFFFFF',
    background: '#0A1734',
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  }
});

const AlembicModalConfirm = props => {
  const {
    isOpen,
    isDelete,
    isCancelDelete,
    isConfirmOnly,
    title,
    image,
    imageAlt,
    body,
    fullLengthBody,
    note,
    confirmTitle,
    cancelTitle,
    handleConfirm,
    handleCancel,
    subtitle,
    containerWidth
  } = props;
  const classes = styles();

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={handleCancel}>
      <Box p={12}>
        <Grid container style={{ width: containerWidth }}>
          {title && (
            <Grid item container>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
          )}
          {subtitle && (
            <Grid item>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Grid>
          )}
          {image && (
            <Grid
              item
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <img src={image} alt={imageAlt} />
              {imageAlt && <p>{imageAlt}</p>}
            </Grid>
          )}
          {note && (
            <Grid item>
              <Typography variantMapping={{ body1: 'span' }} className={classes.noteLabel}>
                Please note:&nbsp;
              </Typography>
              <Typography variantMapping={{ body1: 'span' }} className={classes.note}>
                {note}
              </Typography>
            </Grid>
          )}
          {body && (
            <Grid item container>
              <div className={fullLengthBody ? classes.fullWidthBody : classes.body}>{body}</div>
            </Grid>
          )}
          <Grid item container justifyContent="flex-end" className={classes.actions}>
            {!isConfirmOnly && (
              <Button
                variant="outlined"
                className={isCancelDelete ? classes.cancelDelete : null}
                onClick={() => {
                  if (isCancelDelete) {
                    handleCancel({
                      cancelDelete: true
                    });
                  } else {
                    handleCancel();
                  }
                }}
              >
                {cancelTitle}
              </Button>
            )}
            <Button
              variant="contained"
              className={isDelete ? classes.deleteButton : classes.confirmButton}
              onClick={handleConfirm}
            >
              {confirmTitle}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

AlembicModalConfirm.propTypes = {
  isDelete: PropTypes.bool,
  isCancelDelete: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isConfirmOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  note: PropTypes.string,
  cancelTitle: PropTypes.string,
  confirmTitle: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  fullLengthBody: PropTypes.bool,
  containerWidth: PropTypes.string
};

AlembicModalConfirm.defaultProps = {
  isDelete: false,
  isCancelDelete: null,
  isConfirmOnly: false,
  body: null,
  fullLengthBody: false,
  image: null,
  imageAlt: null,
  note: null,
  confirmTitle: 'Confirm',
  cancelTitle: 'Cancel',
  subtitle: null,
  containerWidth: null
};

export default AlembicModalConfirm;
