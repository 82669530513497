import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button, TextField, MenuItem } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { SMART_FILTERS } from 'gql/smartFilter';
import AlbError from 'components/AlbError';
import Box from 'components/Box';

const styles = makeStyles({
  title: {
    color: '#0A1734',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '18px'
  },
  label: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0A1734',
    marginBottom: '8px'
  },
  actions: {
    marginTop: '24px',
    '& button:last-child': {
      marginLeft: '20px'
    }
  },
  noFiltersFound: {
    fontSize: '14px',
    color: '#0A1734'
  }
});

const LoadSmartFilterModal = props => {
  const { isModalOpen, onChange } = props;
  const [selectedSmartFilter, setSelectedSmartFilter] = useState('');

  const classes = styles();
  const { error, data } = useQuery(SMART_FILTERS, {
    fetchPolicy: 'network-only'
  });

  const closeModal = () => {
    onChange({
      isModalOpen: false
    });
  };

  return (
    <>
      <Dialog maxWidth="sm" open={isModalOpen} onClose={closeModal}>
        <Box p={12}>
          <Grid container>
            <Grid item container>
              <Typography className={classes.title}>Load Saved Filter</Typography>
            </Grid>
            {error && <AlbError error={error} />}
            {data?.smartFilters?.length ? (
              <>
                <Grid item container>
                  <Typography className={classes.label}>Select Saved Filter</Typography>
                </Grid>
                <Grid item container>
                  <TextField
                    select
                    fullWidth
                    variant="filled"
                    value={selectedSmartFilter}
                    onChange={event => setSelectedSmartFilter(event.target.value)}
                  >
                    {data.smartFilters.map(smartFilter => {
                      if (smartFilter.name) {
                        return (
                          <MenuItem key={smartFilter.id} value={smartFilter.name}>
                            {smartFilter.name}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </TextField>
                </Grid>
              </>
            ) : (
              <Typography className={classes.noFiltersFound}>No saved filters found.</Typography>
            )}
            <Grid item container justifyContent="flex-end" className={classes.actions}>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const smartFilter = data.smartFilters.find(
                    ({ name }) => name === selectedSmartFilter
                  );

                  onChange({
                    isModalOpen: false,
                    smartFilter
                  });
                }}
                disabled={!selectedSmartFilter}
              >
                Load
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

LoadSmartFilterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

LoadSmartFilterModal.defaultProps = {};

export default LoadSmartFilterModal;
