import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsBox from 'components/AnalyticsBox';
import AlbGraphDruid from 'components/AlbGraphs/AlbGraphDruid';
import graph from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import TopTenPostsTable from '../TopTenPostsTable';

const ImpactVsAge = props => {
  const { accounts, dates } = props;

  return (
    <AnalyticsBox tabs={['Impact vs Age']} onChange={() => {}}>
      <AlbGraphDruid tabData={graph.impactVsAge} accounts={accounts} dates={dates} />
      <div
        shadow
        style={{
          height: '14px',
          background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
          mixBlendMode: 'multiply'
        }}
      />
      <TopTenPostsTable accounts={accounts} dates={dates} table />
    </AnalyticsBox>
  );
};

ImpactVsAge.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default ImpactVsAge;
