import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Box from 'components/Box';
import DetailsBody from 'components/PostDrawerView/DetailsBody';
import PostDrawerMetricGrid from 'components/PostDrawerView/PostDrawerMetricGrid';
import AlembicTitleChip from 'components/AlembicTitleChip';
import EditInPlaceInput from 'components/EditInPlaceInput';
import AnnotationsList from 'components/DetectedEventsTable/AnnotationsList';
import { UPDATE_MENTION_GROUP_TITLE } from 'gql/mention_group';
import colors from 'util/colors';
import { TV_ICON, RADIO_ICON, PODCAST_ICON } from 'util/assets';
import handleGraphQLError from 'util/error';
import customPromotionRender from '../Tables/NarrativeTablePromotion';
import { NARRATIVE, MENTION } from '../ThirdPartyMediaConsts';
import { MENTION_GROUP_SOURCE_ENUM } from '../util';

const useStyles = makeStyles({
  container: {
    padding: '28px 25px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px',
    color: '#0A1734',
    marginRight: '10px'
  },
  report: {
    '& > img': {
      marginRight: '10px'
    }
  },
  reportName: {
    fontSize: '16px',
    fontWeight: 500,
    color: colors.doveGray,
    marginLeft: '12px'
  },
  narrativeName: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.darkBlue
  },
  chips: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '20px',

    '& > *': {
      marginBottom: '12px'
    },

    '& > :not(:last-child)': {
      marginRight: '30px'
    }
  },
  grid: {
    marginBottom: '20px'
  }
});

const ThirdPartyMediaDrawerDetails = props => {
  const { drawerType, details } = props;
  const {
    id,
    title,
    promotionType,
    reportName,
    mediaType,
    narrativeName,
    text,
    mentions,
    ratings = '-',
    dates,
    chips,
    length
  } = details;
  const classes = useStyles();

  const dateArray = [...new Set(dates)];

  const gridItems = [
    { graphTitle: `${drawerType === NARRATIVE ? 'Total ' : ''}Mentions`, metricValue: mentions },
    { graphTitle: `${drawerType === NARRATIVE ? 'Total ' : ''}Ratings`, metricValue: ratings },
    {
      graphTitle: 'Length',
      metricValue: `${length} Day${length > 1 ? 's' : ''}`,
      subValue: `(${dateArray.join(' to ')})`,
      style: { fontSize: '19px' }
    }
  ];

  const [updateMentionGroupTitle, { error, data }] = useMutation(UPDATE_MENTION_GROUP_TITLE);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      promotionType.dataRefetch();
    }
  }, [data]);

  return (
    <div className={classes.container}>
      <Grid container alignItems="center">
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className={classes.title}>
            {drawerType === NARRATIVE && (
              <EditInPlaceInput
                useEdit
                useIcons
                value={title}
                onChange={event => {
                  updateMentionGroupTitle({
                    variables: {
                      id,
                      title: event.value
                    }
                  });
                }}
                customTitleStyle={classes.narrativeTitleText}
              />
            )}
            {drawerType === MENTION && 'Mention Details'}
          </Typography>
          <Box display="flex" alignItems="center">
            {drawerType === NARRATIVE && customPromotionRender(promotionType)}
          </Box>
        </Grid>
        <Grid
          className={classes.report}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            {mediaType === MENTION_GROUP_SOURCE_ENUM.TV && (
              <img src={TV_ICON} alt="Television icon" width={17} height={13} />
            )}
            {mediaType === MENTION_GROUP_SOURCE_ENUM.RADIO && (
              <img src={RADIO_ICON} alt="Radio icon" width={17} height={13} />
            )}
            {(mediaType === MENTION_GROUP_SOURCE_ENUM.PODCAST_TRANSCRIPT ||
              mediaType === MENTION_GROUP_SOURCE_ENUM.PODCAST_SUMMARY) && (
              <img src={PODCAST_ICON} alt="Podcast icon" width={10} height={14} />
            )}
            <Typography className={classes.reportName}>{reportName}</Typography>
          </Box>

          {drawerType === MENTION && (
            <Grid item>
              <Typography className={classes.narrativeName}>{narrativeName}</Typography>
            </Grid>
          )}
        </Grid>
        <DetailsBody eventBody={text} />
        {drawerType === MENTION && chips && (
          <div className={classes.chips}>
            {chips?.networkAffiliation && chips?.stationName == null && (
              <AlembicTitleChip title="Source" body={chips.networkAffiliation} />
            )}
            {chips?.stationName && chips?.networkAffiliation == null && (
              <AlembicTitleChip title="Source" body={chips.stationName} />
            )}
            {chips?.stationName && chips?.networkAffiliation && (
              <AlembicTitleChip
                title="Source"
                body={`${chips.stationName} (${chips.networkAffiliation})`}
              />
            )}
            {chips?.podcastAuthor && <AlembicTitleChip title="Source" body={chips.podcastAuthor} />}
            {chips?.programTitle && <AlembicTitleChip title="Segment" body={chips.programTitle} />}
            {chips?.podcastTitle && <AlembicTitleChip title="Segment" body={chips.podcastTitle} />}
            {chips?.mentionInTranscript && (
              <AlembicTitleChip title="Location" body={chips.mentionInTranscript} />
            )}
            {chips?.mentionInSummary && (
              <AlembicTitleChip title="Location" body={chips.mentionInSummary} />
            )}
            {chips?.mentionLanguage && (
              <AlembicTitleChip title="Language" body={chips.mentionLanguage} />
            )}
          </div>
        )}
        <Grid container className={classes.grid}>
          <PostDrawerMetricGrid gridItems={gridItems} />
        </Grid>
        <AnnotationsList startDate={dates[0]} endDate={dates[1]} includeTitle />
      </Grid>
    </div>
  );
};

ThirdPartyMediaDrawerDetails.propTypes = {
  drawerType: PropTypes.string.isRequired,
  details: PropTypes.shape().isRequired
};

export default ThirdPartyMediaDrawerDetails;
