import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsBox from 'components/AnalyticsBox';
import TopActivePosts from 'components/AlbGraphs/TopActivePosts';
import { getToolTipTitleValue } from 'components/AlbTooltip';

const FlowMetricDistribution = props => {
  const { accounts, dates } = props;

  return (
    <AnalyticsBox
      usePadding
      tooltip={getToolTipTitleValue('numberOfPostsContributing')}
      tabs={['Impressions', 'Engagement', 'Shares']}
      onChange={() => {}}
    >
      <TopActivePosts metric="alembicImpressions" accounts={accounts} dates={dates} />
      <TopActivePosts metric="alembicEngagement" accounts={accounts} dates={dates} />
      <TopActivePosts metric="alembicShares" accounts={accounts} dates={dates} />
    </AnalyticsBox>
  );
};

FlowMetricDistribution.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default FlowMetricDistribution;
