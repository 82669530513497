import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Grid, Box, TextField, Typography, IconButton } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { updateUser, mfaEnroll } from 'actions/authActions';
import { showToast } from 'contexts/ToastContext';
import { START_2FA_ENROLLMENT, COMPLETE_2FA_ENROLLMENT, REGEN_2FA, DISABLE_2FA } from 'gql/2fa';
import { FileCopy as CopyIcon } from '@material-ui/icons';
import AlbLoading from '../AlbLoading';
import store from '../../store/index';

const BackupCodeDisplay = props => {
  const { codes } = props;
  const backupLeft = [];
  const backupRight = [];

  if (codes?.length > 0) {
    for (let i = 0; i < 10; i += 1) {
      const html = (
        <li key={codes[i]}>
          {codes[i].substr(0, 4)}&nbsp;{codes[i].substr(4, 4)}
        </li>
      );

      if (i < 5) {
        backupLeft.push(html);
      } else {
        backupRight.push(html);
      }
    }
    return (
      <>
        <p>
          {' '}
          <b>Copy these backup codes to a safe place.</b>
        </p>
        <p>
          You can use these codes to login if you lose your device or cannot access your
          authenticator app.
        </p>

        <Grid container>
          <Grid item xs={6}>
            <ol start={1}>{backupLeft}</ol>
          </Grid>
          <Grid item xs={6}>
            <ol start={6}>{backupRight}</ol>
          </Grid>
        </Grid>
        <p>
          <b>Alembic will only show you this list once, so be sure to save it.</b>
        </p>
      </>
    );
  }

  return null;
};

BackupCodeDisplay.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string).isRequired
};

const TwoFactorControl = props => {
  const { currentUser } = props;

  const [code, setCode] = useState('');
  const [backupCodes, setBackupCodes] = useState([]);
  const [qrCode, setQrCode] = useState(false);
  const [secret, setSecret] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // handlers
  const [complete2FAEnrollment, { loading: complete2FALoading }] = useMutation(
    COMPLETE_2FA_ENROLLMENT
  );
  const [start2FAEnrollment, { loading: start2FAEnrollmentLoading }] = useMutation(
    START_2FA_ENROLLMENT
  );
  const [disable2FA, { loading: disable2FALoading }] = useMutation(DISABLE_2FA);
  const [regen2FA, { loading: regen2FALoading }] = useMutation(REGEN_2FA);

  const handleCodeChange = event => {
    setCode(event.target.value);
    setErrorMsg('');
  };

  const handleStartEnrollment = async e => {
    e.preventDefault();
    e.stopPropagation();

    await start2FAEnrollment()
      .then(result => {
        setBackupCodes(result.data.enrollOTP.codes);
        setQrCode(result.data.enrollOTP.qr_code);

        // parse out the secret from the otpauth_url
        const url = new URL(result.data.enrollOTP.otpauth_url);
        const parsedSecret = url.searchParams.get('secret');
        setSecret(parsedSecret);

        setCode('');
      })
      .catch(err => {
        setErrorMsg(err.graphQLErrors[0].message || 'Error starting enrollment');
      });
  };

  const handleCompleteEnrollment = async e => {
    e.preventDefault();
    e.stopPropagation();

    complete2FAEnrollment({
      variables: { code }
    })
      .then(() => {
        // Reset our token to match the new account state. (otp=true)
        store.dispatch(mfaEnroll());

        // we do not need to update the WS JWT here. The user already has one.
        setCode('');
        setQrCode(null);

        updateUser();
        showToast('Multi-Factor authentication enabled.', 'success');
      })
      .catch(err => {
        setErrorMsg(err.graphQLErrors[0].message);
      });
  };

  const handleDisableMFA = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (code.length !== 6 || Number.isNaN(parseInt(code, 10))) {
      setErrorMsg('Code must be six digits.');
      return;
    }

    setQrCode(null);

    await disable2FA({ variables: { code } })
      .then(() => {
        showToast('Multi-Factor Authentication disabled.', 'success');
        updateUser();
      })
      .catch(err => {
        setErrorMsg(err.graphQLErrors[0].message);
      });
  };

  const handleRegenBackupCodes = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (code.length !== 6 || Number.isNaN(parseInt(code, 10))) {
      setErrorMsg('Code must be six digits.');
      return;
    }

    try {
      await regen2FA({ variables: { code } }).then(result => {
        setCode('');
        setBackupCodes(result.data.regenOTPBackup.codes);
        showToast('Backup codes regenerated.', 'success');
      });
    } catch (rError) {
      setErrorMsg(rError.graphQLErrors[0].message);
    }
  };

  // Render

  // return the proper control based on where we are in the 2FA process
  //
  // Phase I, show the button to start 2FA process
  // Phase II, show the QR code and input box asking for the current 2FA code
  // Phase III, Inform the user 2FA is on and show backup codes.
  // Phase IV, show the disable button (which should also require the current 2FA code)

  const codeInputArea = (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Grid item xs={3}>
        <Box mt={2} mb={2}>
          <TextField
            id="codeinput"
            label="Code"
            autoFocus
            variant="filled"
            placeholder="000000"
            autoComplete="off"
            value={code || ''}
            onChange={handleCodeChange}
          />
        </Box>
      </Grid>
    </Grid>
  );

  if (currentUser.otp_enabled === true && !qrCode) {
    // Phase IV
    return (
      <>
        <Grid item xs={12}>
          <p>
            Multi-factor authentication is currently enabled for your account. To remove MFA
            protection, enter the code shown by your authenticator app and click the button.
          </p>
          <p>
            To regenerate the backup codes for your account, enter the current MFA code below and a
            new list of backup codes will be created and displayed.
          </p>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          {regen2FALoading ||
            (disable2FALoading && (
              <Grid item xs={12}>
                <Box mb={12}>
                  <AlbLoading />
                </Box>
              </Grid>
            ))}
          {codeInputArea}
          <Grid item xs={12}>
            {errorMsg && <Typography style={{ color: 'red' }}>{errorMsg}</Typography>}
            {!errorMsg && <Typography style={{ color: 'red' }}>&nbsp;</Typography>}
          </Grid>
          <Grid item xs={6} p={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleDisableMFA}
              disabled={disable2FALoading}
            >
              Disable MFA
            </Button>
          </Grid>
          <Grid item xs={6} p={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleRegenBackupCodes}
              disabled={regen2FALoading}
            >
              Regenerate Backup Codes
            </Button>
          </Grid>
          <Grid item xs={12}>
            {backupCodes && <BackupCodeDisplay codes={backupCodes} />}
          </Grid>
        </Grid>
      </>
    );
  }

  if (qrCode) {
    // Phase II
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6">Step 1</Typography>
          <hr />
          <p>
            <b>Download and install an Authenticator App.</b> This application will generate
            single-use codes so you can log in to Alembic.
          </p>
          <p>
            We recommend using{' '}
            <a href="https://authy.com/download/" target="_blank" rel="noreferrer">
              Authy
            </a>
            ,{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
              target="_blank"
              rel="noreferrer"
            >
              Google Authenticator
            </a>{' '}
            or{' '}
            <a
              href="https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app#download"
              target="_blank"
              rel="noreferrer"
            >
              Duo Mobile.
            </a>
          </p>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Step 2</Typography>
          <hr />
          <BackupCodeDisplay codes={backupCodes} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Step 3</Typography>
          <hr />
          {complete2FALoading && (
            <Grid item xs={12}>
              <Box mb={12}>
                <AlbLoading />
              </Box>
            </Grid>
          )}
          {!complete2FALoading && (
            <>
              Open your authenticator app and <b>scan the QR code below</b>.
              <Grid item xs={12} align="center">
                <img alt="QR Code" src={qrCode} />
                <IconButton
                  disableFocusRipple
                  size="small"
                  onClick={e => {
                    navigator.clipboard.writeText(secret);
                    showToast(`Secret copied to clipboard`, 'success');
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <CopyIcon fontSize="small" /> Copy Secret
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Step 4</Typography>
          <hr />
          Enter the code displayed by your authenticator app and click the button to enable MFA.
        </Grid>
        {codeInputArea}
        <Grid item xs={3} m={20}>
          {errorMsg}
          <Button fullWidth variant="contained" color="primary" onClick={handleCompleteEnrollment}>
            Enable MFA
          </Button>
        </Grid>
      </Grid>
    );
  }

  // Phase I
  return (
    <>
      {start2FAEnrollmentLoading && <AlbLoading />}

      {!start2FAEnrollmentLoading && (
        <div>
          <p>
            Multi-factor authentication adds an extra layer of security for your Alembic Account,
            designed to ensure that you&apos;re the only person who can access your account, even if
            someone knows your password.
          </p>
          <Button variant="contained" color="primary" onClick={handleStartEnrollment}>
            Start MFA Enrollment
          </Button>
        </div>
      )}
    </>
  );
};

TwoFactorControl.propTypes = {
  currentUser: PropTypes.shape()
};

TwoFactorControl.defaultProps = {
  currentUser: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(TwoFactorControl);
