import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import AlbTable from 'components/AlbTable';
import { customLinkRender, customShortlinkRender } from 'components/AlbTable/CustomRender';
import CustomFooter from 'components/TablePagination';
import { GET_CAMPAIGNS_FOR_CURRENT_CONTAINER } from 'gql/campaign';
import { SHORTLINK_TOTAL } from 'gql/analytics';
import { convertToUserTimeZone, getDatesForAnalytics } from 'util/date';

const useStyles = makeStyles({
  gray: {
    color: '#6F6F6F'
  },
  clicks: {
    textAlign: 'center'
  },
  link: {
    overflow: 'hidden',
    display: '-webkit-box',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1
  }
});

const ShortlinkTotal = props => {
  const { dates, userTimeZone, shortlink } = props;

  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [sort, setSort] = useState({ name: 'clicks', direction: 'desc' });

  const campaignLookup = useRef({});

  const { data: campaignData } = useQuery(GET_CAMPAIGNS_FOR_CURRENT_CONTAINER);

  const formatSort = ({ name, direction }) => [name, direction.replace('ending', '')];

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data: shortlinkData } = useQuery(SHORTLINK_TOTAL, {
    variables: {
      startDate,
      endDate,
      after: page * rowsPerPage,
      count: rowsPerPage,
      orderBy: formatSort(sort),
      // eventual support for querying outside of the user's current container
      // containerIds: [sessionStorage.current_container],
      campaignIds: [],
      ...(shortlink !== null && { shortlinkIds: [shortlink] })
    }
  });

  const formatTableData = ({ rows, count: rowsCount }) => {
    setCount(rowsCount);
    setTableData(
      rows.map(row => ({
        ...row,
        short_link: shortlink,
        created_at: convertToUserTimeZone(row.created_at, userTimeZone).format('L')
      }))
    );
  };

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (campaignData?.campaigns) {
      campaignLookup.current = campaignData.campaigns.reduce((acc, { id, name }) => {
        acc[id] = name;

        return acc;
      }, {});

      if (shortlinkData?.shortlinkTotal) {
        formatTableData(shortlinkData.shortlinkTotal);
      }
    }
  }, [campaignData]);

  useEffect(() => {
    if (shortlinkData?.shortlinkTotal) {
      formatTableData(shortlinkData.shortlinkTotal);
    }
  }, [shortlinkData]);

  const customClicksRender = text => (
    <div className={`${classes.clicks} ${classes.gray}`}>{text}</div>
  );

  const customCampaignRender = text => (
    <div className={classes.gray}>{campaignLookup.current[text]}</div>
  );

  const tableColumns = [
    { name: 'id', options: { display: false } },
    {
      name: 'created_at',
      label: 'DATE',
      options: {
        sort: false
      }
    },
    {
      name: 'short_link',
      label: 'SHORTLINK',
      options: {
        customBodyRender: customShortlinkRender,
        sort: false
      }
    },
    {
      name: 'long_url',
      label: 'ORIGINAL LINK',
      options: {
        customBodyRender: customLinkRender,
        sort: false
      }
    },
    {
      name: 'title',
      label: 'PAGE TITLE',
      options: {
        sort: false
      }
    },
    {
      name: 'clicks',
      label: 'TOTAL CLICKS',
      options: {
        customBodyRender: customClicksRender,
        sort: true
      }
    },
    {
      name: 'unique_clicks',
      label: 'UNIQUE CLICKS',
      options: {
        customBodyRender: customClicksRender,
        sort: true
      }
    },
    {
      name: 'campaign_id',
      label: 'CAMPAIGN',
      options: {
        customBodyRender: customCampaignRender,
        sort: false
      }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    responsive: 'standard',
    rowHover: false,
    fixedHeader: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    sortOrder: sort,
    onColumnSortChange: (changedColumn, direction) => {
      setSort({ name: changedColumn, direction });
    },
    page,
    rowsPerPage,
    count,
    customFooter: CustomFooter
  };

  return <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />;
};

ShortlinkTotal.propTypes = {
  dates: PropTypes.shape().isRequired,
  userTimeZone: PropTypes.string.isRequired,
  shortlink: PropTypes.string
};

ShortlinkTotal.defaultProps = {
  shortlink: null
};

export default ShortlinkTotal;
