import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItemIcon, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import moment from 'moment';
import { linePalette } from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import GraphBody from 'components/PostDrawerView/GraphBody';
import GraphHeader from 'components/PostDrawerView/GraphHeader';
import Box from 'components/Box';
import colors from 'util/colors';
import { getDrawerHeaderTitle, getAttentionActionTitle } from 'util/detectedEvents';

const useStyles = makeStyles({
  graphContainer: {
    flex: 1
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInner: {
    position: 'relative',
    minHeight: '40px',

    '& > :last-child': {
      paddingBottom: '0px !important'
    }
  },
  legend: {
    marginTop: '10px'
  },
  legendColor: {
    height: '16px',
    width: '16px',
    backgroundColor: colors.darkBlue,
    borderRadius: '3px',
    marginRight: '10px'
  },
  legendText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '12px',
    color: '#6F6F6F',

    '& > :last-child': {
      color: '#0b1835'
    }
  },
  dateLabel: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#0A1734',
    marginRight: '8px'
  },
  dateRange: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#6F6F6F'
  }
});

const DashboardUnderstandDrawerGraph = props => {
  const { data, loading, error, dates, eventType, eventLabel } = props;
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [total, setTotal] = useState(0);

  const label =
    eventLabel ||
    getDrawerHeaderTitle({ event: { type: eventType } }) ||
    getAttentionActionTitle(eventType);

  useEffect(() => {
    let currentTotal = 0;
    const labels = [];
    const datasets = [];

    if (data?.length > 0) {
      const tempData = [];
      data.forEach(dataPoint => {
        labels.push(moment.utc(dataPoint.x).format('MMM D'));
        tempData.push(dataPoint.y);
        currentTotal += dataPoint.y;
      });

      datasets.push({
        label,
        data: tempData,
        type: 'line',
        fill: false,
        lineTension: 0,
        pointRadius: 0,
        ...linePalette
      });
    }
    setTotal(currentTotal);
    setGraphData({ labels, datasets });
  }, [data]);

  return (
    <Grid container style={{ padding: '25px' }}>
      <Grid
        item
        className={classes.collapse}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        <ListItemIcon classes={{ root: classes.listItem }}>
          {isCollapsed ? <Right /> : <Down />}
        </ListItemIcon>
        {!isCollapsed && <Box className={classes.expandedIndicator} />}
      </Grid>
      <Grid item className={classes.graphContainer}>
        <GraphHeader
          label={label || ''}
          isCollapsed={isCollapsed}
          dateRangeOptions={[]}
          selectedDateRange={0}
          setSelectedDateRange={() => {}}
          hasGraph
        >
          {dates?.startDate && dates?.endDate ? (
            <Typography>
              <span className={classes.dateLabel}>Dates:</span>
              <span className={classes.dateRange}>
                {`${moment.utc(dates?.startDate).format('ll')} - ${moment
                  .utc(dates?.endDate)
                  .format('ll')}`}
              </span>
            </Typography>
          ) : (
            ''
          )}
        </GraphHeader>
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          <Grid container justifyContent="center">
            {loading && <AlbLoading />}
            {error && <AlbError error={error} />}
            {!loading && !error && (
              <GraphBody
                graphData={graphData}
                isCollapsed={isCollapsed}
                graphChildren={[]}
                maxWidth={480}
                hasGraph
              />
            )}
            {!isCollapsed && (
              <Grid className={classes.legend} container justifyContent="center">
                <Grid className={classes.legendColor} item />
                <Grid className={classes.legendText} item>
                  <Grid item>{label}</Grid>
                  <Grid item>{total.toLocaleString()}</Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

DashboardUnderstandDrawerGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  dates: PropTypes.shape(),
  eventType: PropTypes.string,
  eventLabel: PropTypes.string
};

DashboardUnderstandDrawerGraph.defaultProps = {
  data: [],
  loading: false,
  error: null,
  dates: {},
  eventType: null,
  eventLabel: null
};

export default DashboardUnderstandDrawerGraph;
