import React from 'react';
/**
 * A note about the keys on the tooltipText object:
 * some of the keys have a prefix, this is to help reduce namespace conflicts
 * the prefix isn't essential and it doesn't really matter what it is.
 * This code will concatenate the prefix and the key and camelCase it to get the key to use in the ToolTipText object.
 * There is no magic or special pattern here.  The tooltips are organized consistent with the spreadsheet that was
 * used to create them.  There is not a strong relationship between the sections of the listed tooltips and the UI.
 * With the component reuse, I don't see a reason to try to enforce a pattern.
 */
const tooltipText = {
  // Earned Owned
  emArticlePreviews: `All articles available based on filters set at report creation. To adjust filters and create a new report, select Edit & Save As New. Reminder: Results only show traffic from the past 13 months. `,
  emOwnedShares:
    'Combined number of times a detected article has been shared across the linked FB and Twitter accounts',
  emEarnedShares: `Combined number of times a detected article has been shared across ALL FB and Twitter accounts`,
  emOwnedAndEarned: 'Sum of Shares for both Owned and Earned Media.',
  emImpToShareRatio: `Impressions / Share Ratio. 
     Sum of all Impressions over the sum of all Shares across your Owned social channels. The lower the ratio, the more viral the content.`,
  ownedToEarnedTotalShares:
    'Total number of Shares across Facebook & Twitter for all posts published in the last 13 months, split up by Earned and Owned Media.',
  ownedToEarnedArticleShares:
    'Total number of Shares across Facebook & Twitter for all posts published in the last 13 months, split up by Earned and Owned Media.',
  ownedToEarnedArticleCounts:
    'Total number of Earned and Owned articles shared across Twitter & Facebook over the last 13 months.',
  articles: 'Top 10 articles shared over the past 13 months.',
  articleMetrics: `Share metrics for selected article. 
     Total Shares is sum of total Facebook and Twitter shares`,
  excludedArticles: `Top 10 excluded articles shared over the past 13 months. Articles are manually excluded after running the report to omit false positives. `,
  topDomains: 'Top 10 articles grouped by domain from the past 13 months.',

  // Analytic Card Desc
  socialCv: `A simple and printable dashboard of your current overall audience and metrics. Often used for sending to agencies and sponsors.`,
  socialBroadcast:
    'Detailed social metrics and KPI bands for setting goals, breaking out metrics by single post, and understanding social demographics. ',
  hypeAndFlow:
    'Advanced view of time-series reconstructed data.  Identify popular content themes and evergreen content.',
  earnedMedia: `Often used to search brand popularity and competitors. Report that pulls in brand, product, or topic results from all over the web including blogs, news, video sites, and web pages. It looks for words in the title as a filter for content published in the last 13 months. You can see media traction and the whole picture of your brand's online content performance.`,
  shareOfVoice: `Compare multiple 3rd Party Media Reports against each other. 
     Often used for competitive analysis.`,
  costToRecreate: `See the dollar value of your entire media ecosystem's data over a selected time period or lifetime value of the posts published in that time frame. Combine 3rd Party Media value and Owned social post value. This model recreates the cost of buying the same media on the open market. "If I bought this instead of creating it, would it have been cheaper or more expensive than what I spent?"`,
  email: '', // Don't have a tooltip for this and it's not needed
  organizationTree:
    'View of your entire organization; the full layout of all your Alembic containers with metrics. Compare and contrast containers by metric.',
  shortLink: `All available metrics for personalized shortlinks you've created in Alembic.
     Shortlinks can be used offline or online.`,
  resurgence:
    'Bonus metrics and valuation for past dormant social posts that are getting renewed engagement today.  ',
  webAndApps: 'Metrics from all connected web and app analytics sources. (i.e. Google Analytics)',
  thirdPartyMedia:
    'Report that pulls in and tracks mentions and ratings/impact (e.g., Nielsen) for TV, radio, podcast, and web.',
  targeting: '',

  // drawer
  drawerMain: `Note: The graphs in this drawer show real-time data, thus the values will almost always be greater than the metrics in the tables.`,

  // New Dashboard (v2)
  bestTimeToPost: 'Calculation of what day and hour your posts tend to get the most impressions.',
  mostPopularMedium:
    'Engagement rates by Media Type of your posts. Calculated from the last 12 months of lifetime value data.',
  impressionsCreatedThisLastWeek:
    'Sum of all Impressions from all connected social accounts over the last 7 days.',
  engagementsCreatedThisLastWeek:
    'Sum of all Engagements from all connected social accounts over the last 7 days.',
  tryTopOfByImpressions: 'Popular content keywords by Impressions.',
  tryBottomOfByImpressions: 'Least popular content keywords by Impressions.',

  // New Dashboard (v3)
  detectedEventsLast30Days: `Events detected per day bucketed by marketing department category. 
  Social: Outliers in social posts across connected social accounts and imported manual data. 
  Web: Outliers in web and app analytics detected.
  Conversions: Outliers in your goals and transactions data detected.`,

  revenueGenerationAndBrandValueEvents:
    'List by day of all detected events in your data on Alembic.',

  goalPrediction: `Predictive data from your conversion and web sources. Green vertical lines are "period detectors". Moments when the slope of your lines changed; went up or down. Green "Upper" and Red "Lower" dashed lines show you the possible best and worst predicted trajectories. Predicted Metrics tell you the % change in 30, 60, and 90 days to help you plan ahead.  `,
  forecast: `The anticipated value of a marketing metric, such as sales or deal size, at a given point in time. Forecast represents the most likely outcome of your marketing efforts, providing insights into the potential impact of your campaigns combined with seasonality.`,
  /* the following entries are in the source spreadsheet, they're included here for reference and completion.
        bestDayAndTimeToPost: same tooltip in v2
        yourAudienceEnjoys: same tooltip in v2
        impressionsCreatedThisLastWeek: same tooltip in v2
        engagementsCreatedThisLastWeek: same tooltip in v2
    */
  sourceSpikeDetail: 'A sudden burst of traffic has been detected on your web property. ',
  totalUsersDetail: '',
  conversionOutlierDetail:
    'An outlier in conversions has been detected. Conversions are higher or lower than the expected range.',
  digitalMediaValue:
    'Sum of 3rd Party Media and Owned Media value from your selected default reports.',
  ownedMediaValue: 'Sum of Owned Media Value from your selected default report.',
  earnedMediaValue: 'Sum of the Earned Media from your selected default report.',
  // Single Post Resurgence
  reSurgeStart:
    'Surge Start: Date the surge began. Surge End: Date the surge ended. Post age at Surge: How old was the post that surged? Noted by the surge start date. Showing: Time range that the graph is showing before and after the surge.',
  reBonusImp:
    'Bonus Impressions you gained from this surge. Based off our models this is how many users were above the expected normal amount. You would not have had these impressions if not for the surge.',
  reSurge: `The relative ranking of a single post resurgence. The resurgence is compared to the initial surge. The greater the peak delta percentage the stronger the indicator language. Ie Major or Minor
  How to calculate: For each date within the date range selected examine the peak number of posts, the peak delta count and the peak excess count. The max of their respective daily values over all date within the date range is used to calculate the daily delta (current value - previous value). The relative rank is then determined. The event with the largest daily delta is assigned 100 and all others are a percentage of that event.`,
  reComplete: 'Is the surge still in progress now or did it complete and end at an earlier date.',
  reFollowerGrowthInPeriod: 'Number of new followers added during the surge.',
  // reRank: '',
  reLength: 'Total length of the surge. Start date to End date.',
  reImpressions: `The number of times your post was seen`,
  reTwitterImpressions:
    'A count of how many times the Tweet has been viewed. This metric is only available for tweets that have been posted within the past 90 days.',
  reInstagramPhotoVideoImpressions: `The number of times all of your post and / or story was seen`,
  reInstagramAlbumImpressions: `The number of likes and comments on an album post`,
  reInstagramStoryImpressions: `The total number of times your post has been seen.`,
  reLinkedInImpressions: `The number of times each update is visible for at least 300 milliseconds with at least 50% in view on a (signed in) member’s device screen or browser window.`,
  reFacebookPageImpressions: `An impression is counted as the number of times an instance of an post is on screen for the first time. (Example: If an post is on screen and someone scrolls down, and then scrolls back up to the same ad, that counts as 1 impression. If an post is on screen for someone 2 different times in a day, that counts as 2 impressions.) This method of counting video impressions differs from industry standards for video ads. With the exception of ads on Audience Network, impressions are counted the same way for ads that contain either images or video. This means that a video is not required to start playing for the impression to be counted. In a few cases when it can't be determined whether posts are on screen, such as on feature mobile phones, impressions are counted when posts are delivered to devices. Impressions aren't counted if they come from invalid traffic we detect such as from non-human sources (such as a bot).`,
  reEngagements: `Engagements represent the number of times that a given Tweet was engaged upon by a viewer in an organic context. Engagements include, but are not limited to, Retweets, Favorties, Replies, URL Clicks, Hashtag Clicks, Mention Clicks, and Media Views. Engagements from Tweets that are seen in a Promoted or Paid context are not included.`,
  reFavorited: `A count of how many time the Tweet has been liked (favorited).`,
  reRetweets: `A count of how many timed the Tweet has been Retweeted.`,
  reReplies: `A count of how many times the Tweet has been replied to.`,
  reVideoViews: `Twitter video views metric, relies on the MRC standard of 50% if the video in view for at least two seconds. Please note that you may see a discrepancy between the video views metric displayed in the Twitter owned and operated platforms (mobile app and website) and the number that you receive via the /28hr and /historical endpoints. The video views will just include those views generated by the specific Tweet for which you are pulling metrics. This metric does not include video views on GIFs.`,
  reSaved: '',
  reLikeCount: '',
  reCommentsCount: '',

  instagramVideoVideoViews: `A view is counted when a video is watched for 3 seconds or more, and video's view count doesn't include video loops. Note that you can't see the number of video views if your video is part of a post with multiple photos and videos.`,
  instagramAlbumVideoVideoViews: `A view is counted when a video is watched for 3 seconds or more, and video's view count doesn't include video loops. Note that you can't see the number of video views if your video is part of a post with multiple photos and videos. It will be aggregated.`,
  linkedInVideoVideoViews: `Defined as the number of times your video post was viewed for three seconds or more`,
  twitterVideoViews: `Twitter video views metric, relies on the MRC standard of 50% of the video in view for at least two seconds. Please note that you may see a discrepancy between the video views metric displayed in the Twitter owned and operated platforms (mobile app and website) and the number that you receive via the /28hr and /historical endpoints. The video views will just include those views generated by the specific Tweet for which you are pulling metrics. This metric does not include video views on gifs.`,
  facebookPageVideoViews: `The number of times your video starts to play. This is counted for each impression of a video, and excludes replays`,
  youtubeVideoVideoViews: `The number of times that a video was viewed. In a playlist report, the metric indicates the number of times that a video was viewed in the context of a playlist.`,

  // Media Valuation
  mvImpressionsCpm:
    'Display the results in the report by Impressions CPM models. Edit your CPM & CPA rates in your container settings. Container settings > Edit > Valuation Model. Impressions CPM (Cost per Thousand Impressions). Owned Media = (Total Impressions/1000) x (CPM rate) for all social media data in the time period. Earned Media = ([Impressions / impression to shares ratio] / 1000) x (CPM rate) for web media over the past 13 months.',
  mvEngagementCpa:
    'Display the results in the report by Engagement CPA models. Edit your CPM & CPA rates in your container settings. Container settings > Edit > Valuation Model Engagement CPA (Cost Per Action) (CPA rate) x (Sum of total Shares, Saves, Retweets, Clicks, and Unique Clicks)',
  mvPublishedPosts:
    "Sort the report by only posts published in a time period or all posts that saw activity in a time period regardless of the date it was posted. Published Posts: Display results computed by using just the posts published within the time period. For example, in the last 30 days I've published 42 posts on social and I want to see the total of metrics for only those 42 posts. ",
  mvActivePosts:
    "Sort the report by only posts published in a time period or all posts that saw activity in a time period regardless of the date it was posted. Active Posts: Sort by all posts that saw activity in the time period no matter when they were published. For example, in the last 30 days I've published 42 posts on social but 2500 of my posts saw activity (i.e. got impressions or other engagement). NOTE: This number may be larger than expected and is based on time-series data. This larger number is expected and a good thing. If you've recently connected to Alembic there may not be enough time-series data reconstructed for this view. So only Life Time Value is recommended. Alembic requires at least 30 days of time series data for this option. ",
  mvTopDomains:
    'Domain results from your earned media report in the selected time period sorted by number of shares and media value. Sort highest/lowest by clicking the column headings.',
  mvTopArticles:
    'Individual web articles from your earned media report in the selected time period sorted by number of shares and media value. Sort highest/lowest by clicking the column headings. ',
  mvTopAccounts:
    'Linked social accounts by impressions and media value in the selected time period. Sort highest/lowest by clicking the column headings. ',
  mvTopPosts:
    'Posts in the selected time period by impressions and media value. Sort highest/lowest by clicking the column headings.',
  mvOwned:
    'Top social media posts in the selected time period from connected accounts unless filtered out when creating the report.',
  mvEarned: 'Top web articles from the Earned Media report in the selected time period.',
  mvOwnedExcluded:
    'List of social media posts manually excluded from the report. NOTE: These exclusions only affect this report.',
  mvEarnedExcluded:
    'List of Earned Media articles manually excluded from the report. NOTE: These exclusions only affect this report.',

  // GA - Web and Apps
  waTotalUsers:
    'Total number of new and returning users who visited your site during the time period.',
  waUniqueUsers:
    'Total number of new and returning unique users who visited your site during the time period.',
  waTotalConversions:
    'Total number of goals achieved in the time period as set up on your analytics platform (i.e. in Google Analytics). Examples: free trial downloads, app sign-ups, or other key actions.',
  waTotalTransactions: 'Total number of transactions achieved in the time period.',
  waRevenue: 'Total dollar value of the goals and transactions achieved in the time period.',
  waTopDemographic:
    'Top demographic reported by your analytics platform. NOTE: This needs to be configured correctly on Google Analytics itself to display.',
  waTopCountry:
    'Country with most users visiting your site as reported by your analytics platform.',
  waTopCity: 'City with most users visiting your site as reported by your analytics platform. ',
  waTotalUsersGraph:
    'Total number of new and returning users who visited your site during the time period broken out by analytics property.',
  waTopSources:
    'Total number of new and returning users who visited your site as referrals from another domain.',
  waTopPages: 'Total number of new and returning users broken out by your website pages.',
  waUsers:
    'Number of new and returning users who visited your site during the time period broken out by analytics property.',
  waFirstConvertingPaid: 'The search term that first acquired the user (with ad click).',
  waFirstConvertingPaidBreakdown: 'The search term that first acquired the user (with ad click).',
  waPaidPlusOrganic: 'The search term that led to the session.',
  waPaidPlusOrganicBreakdown: 'The search term that led to the session.',

  // Hype and Flow
  numberOfPostsContributing: `The total across your owned social, in the time period, across all posts that we are tracking. This is not listening data but your own social accounts. As an example a sports team might have a highlight clip from last year that everyone looks at again because they're playing the same team. This lets you see your evergreen social media.`,
  ageRangeOfActivePosts: 'Youngest and oldest active posts in the time period selected',
  topPostsOver30DaysOld: 'Top 10 posts older than 30 days by Impression count in the time period ',
  impactVsAge:
    'The total impressions for actively tracked posts grouped by day in UTC time. The posts impressions are bucketed also by the number of days old the post is in ranges.',
  ageTop10PostByImp:
    'Left column: Top posts, by rank, in the time period by Impressions. Horizontal rows: Each day by date in the time period. Numbers in grid: Each number is the age of a post. 0 is same day; 0 days old. Hovering over a number highlights that same post across the time period. This helps you track its movements. Click the number to see a post preview. If you click the preview, full post metrics will appear to your right. Use this grid to see the top 10 most popular posts in a time period by age. All dates are UTC time.',
  top10PostsInTimePeriod:
    "Top 10 posts in the time period by either Impressions, Engagement, or Shares. Use this to see post activity over time. How sticky is the content? A wave that stretches over time is 'stickier' than a sharp rise and fall. ",

  // Social Media
  sbValuationModel: `Media Value of the time period for selected channels. (Total Impressions / 1000) x (CPM rate) by channel in the time period. Smaller text shows % and $ change from the previous time period. If set to 7 days, this will compare to the previous 7 days. NOTE: Edit your CPM & CPA metrics in your container settings. (Container settings > Edit > Valuation Model)`,
  sbAudienceGainedLost:
    'Total Followers/Audience added or lost in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.',
  sbComments:
    'Comment counts in the time period by selected channels. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.',
  sbEngagement: `Engagement rates in the time period by selected channels. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  sbEngagementPercent: `Engagement % rates in the time period by selected channels. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  sbEngagementsGrowth:
    'Total Engagement in the time period for selected channels. Smaller text shows % and # of Engagements change from the previous time period. If set to 7 days, this will compare to the previous 7 days',
  sbGrowthOverTime:
    'Total Followers/Audience added or lost in the time period by selected channel. Hover over each bar for metrics by channel per day. This is meant to be a different visual view of similar data. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.',
  sbGrowthRateOverTime:
    'Total Followers/Audience added or lost in the time period by selected channel. Hover over each bar for metrics by channel per day. This is meant to be a different visual view of similar data. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.',
  sbImpressions:
    'Total Impressions in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.',
  sbImpressionsBreakdown:
    'Posts in the selected time period by impressions. Sort highest/lowest by clicking the impressions column heading. ',
  sbImpressionsByType:
    'Total Facebook Impressions in the time period broken up by Paid vs. Unpaid posts. Hover over each bar for metrics per day. NOTE: This shows all active posts that day, NOT just posts published that day. This is a Facebook-only metric. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.',
  sbImpressionsGrowth:
    'Total Impressions in the time period for selected channels. Smaller text shows % and # of Impressions change from the previous time period. If set to 7 days, this will compare to the previous 7 days',
  sbRecentPosts:
    'Most recent posts in the selected time period. Can sort by Date/Time by clicking the column heading. Can also sort by metric. ',
  sbShares:
    'Shares in the time period by selected channels. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the impressions bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.',
  sbTopByCampaign:
    'Posts in the selected time period sorted by Campaign. Select the campaign using the drop down menu in the top right of the table. Can also sort by metric and date. Create campaigns from the main Dashboard.',
  sbTopCity:
    'Top City on selected channels. NOTE: This is only available on Facebook, Instagram, and Google Analytics. This is for your Followers/Audience and not individual posts.',
  sbTopCountry:
    'Top Country on selected channels. NOTE: This is only available on Facebook, Instagram, and Google Analytics. This is for your Followers/Audience and not individual posts.',
  sbTopDemographic:
    'Top Demographic on selected channels. NOTE: Demographic data is only available on Facebook, Instagram, and Google Analytics. This is for your Followers/Audience and not individual posts.',
  sbTopPosts:
    'Posts in the selected time period by Impressions, Engagement, or Shares. Can sort by Date or Metric by highest/lowest by clicking the column headings. ',
  sbTotalFollowers:
    'Total Followers in the time period for selected channels. Smaller text shows % and # of Followers change from the previous time period. If set to 7 days, this will compare to the previous 7 days',
  sbLocationTotals: `Top Cities and Audience gained/lost in the time period by selected channels. NOTE: Demographic data is only available on Facebook, Instagram, and Google Analytics. This is for your Followers/Audience, not individual posts. `,
  sbGender: `Demographic breakdown by Gender and Audience gained/lost in the time period by selected channels. NOTE: Demographic data is only available on Facebook, Instagram, and Google Analytics. This is for your Followers/Audience, not individual posts. Does not include users who did not provide this information, as a result the Demographic values are less than the Total Audience numbers.  `,
  sbAge: `Demographic breakdown by Age and Audience gained/lost in the time period by selected channels. NOTE: Demographic data is only available on Facebook, Instagram, and Google Analytics. This is for your Followers/Audience, not individual posts. Does not include users who did not provide this information, as a result the Demographic values are less than the Total Audience numbers.  `,
  sbTotalValueByWeek: 'Total media valuation by week',
  sbLast30DaysRolling: 'Media valuation for last 30 days by platform',
  sbTopByThemeTopic: `Posts in the selected time period sorted by Theme/Topic. Select the Theme using the drop down menu in the top right of the table. Can also sort by metric and date. `,

  // video overview
  voViewsGrowth: `Total Video Views in the time period by selected channel. Smaller text shows % and # change from the previous time period. If set to 7 days, this will compare to the previous 7 days. `,
  voAverageWatchTime: `Average Video Watch Time in the time period by selected channel. Smaller text shows % and time change from the previous time period. If set to 7 days, this will compare to the previous 7 days. NOTE: Certain channels are calculated by Total View Time / Total Impressions. For some channels we may compute this ourselves by taking total view time / total impressions.  `,
  engagementsGrowth: `Total Engagements on all video posts in the time period by selected channel. Smaller text shows % and # change from the previous time period. If set to 7 days, this will compare to the previous 7 days.`,
  totalFollowersSubs: `Total Followers and Subscriptions in the time period by selected channel. Smaller text shows % and # change from the previous time period. If set to 7 days, this will compare to the previous 7 days.`,
  kPiRange:
    'Social KPI Benchmarks - How do you know a post did well as compared to all of your other posts. Sort by Impressions, Engagement, and Shares. For example, if a post has higher Impressions than the number in the Top 10% box, that post is more popular than 90% of other posts. GOAT = the highest valued post of all time. Click to view the post',
  voKpiRange: `Social KPI Benchmarks for Video Posts ONLY - How do you know a post did well as compared to all of your other posts. Sort by Views, Engagement, and Minutes Watched. For example, if a post has higher Views than the number in the Top 10% box, that post is more popular than 90% of other posts. GOAT = the highest valued post of all time. Click to view the post.`,

  // video performance
  vpVideoViews: `Total Video Views for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  audienceGainedLost: `Total Followers added or lost for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  growthRateOverTime: `Total Followers for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. This is the same as audience gained lost and is put here for your convenience. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  watchTime: `Total Watch Time for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day. So a metric for all posts viewed that day.`,
  averageWatchTime: `Average Video Watch Time in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day. So a metric for all posts viewed that day.`,
  vpEngagement: `Total Engagements for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day.  This will allow you to compare YouTube to Facebook and other channels fairly. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  vpEngagementPercent: `Total Engagement % for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This will allow you to compare YouTube to Facebook and other channels fairly. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  vpComments: `Total Comment Count for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. This will allow you to compare YouTube to Facebook and other channels fairly. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  vpShares: `Total Share Count for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. Not all social networks have Share Counts. This will allow you to compare YouTube to Facebook and other channels fairly. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  vpLikes: `Total 'Like' Count for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. Not all social networks have 'Like' Counts. This will allow you to compare YouTube to Facebook and other channels fairly. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  vpDislikes: `Total 'Dislike' Count for all video posts in the time period by selected channel. Hover over each bar for metrics by channel per day. NOTE: This shows all active posts that day, NOT just posts published that day. Not all social networks have 'Dislike' Counts. This will allow you to compare YouTube to Facebook and other channels fairly. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day.`,
  videoLength: `The video length on the selected channels in the time period, that contain a video in them, in the time period. This will allow you to compare YouTube to Facebook and other channels fairly. This graph shows the views bucketed by day in UTC time zone (we have to use UTC as all the networks agree on it). It is important to note this is time-series data so it is all posts tracked that day, not just the posts published that day. Not all networks have share counts.`,

  // Third Party Media
  tpmPrimaryReport:
    'At first setup this may include your default 3rd Party Media report and/or your default Earned Media report. Editing this group will not affect those defaults.',
  tpmNarrative: `A Narrative is a group of near-identical mentions that span multiple airtimes and sources.`,
  tpmRatingsAndImpactCount: `TV ratings based on Nielsen (local viewership if available, else national viewership). Podcast listens based on Podchaser's average monthly audience reach. Web impact based on article shares across popular social media sites.`,
  tpmNarrativeGraph: 'Overlaid graphs only 10 narratives at a time, Totaled graphs all narratives.',
  tpmEstimatedAudience: `Calculated by multiplying the number of earned shares of each article by the average impression count of your owned shares.`,

  // Annotations
  annoUser: 'Annotations added by users in your organization.',
  annoSystem: 'Annotations added by Alembic.',

  // Optimize / Program Lift
  optEvent: 'Event type of interest.',
  optOccurrence:
    'The number of correlations that include both an event of the listed type (e.g., Web Visits) and at least one target event (e.g., Brand Equity events). This tells you how often an event is associated with a goal.',
  optContribution:
    'Sum the magnitudes of the target events (e.g., Brand Equity events) across all correlations in Occurrence, and divide by the total magnitude of the raw data underlying those events. This, as a percentage, tells you how much of a goal an event is associated with.',
  optVariation:
    'The median number of events of the listed type across all correlations in Occurrence, divided by the total number of correlations (Occurrence). This tells you how varied an event is in association with a goal.',
  optMagnitude:
    'The median percent change of events of the listed type (e.g., Web Visits) across all correlations in Occurrence. This tells you how much an event typically spikes in association with a goal.',
  optImpactScore:
    'Take the average of percentileRank(Occurrence) + percentileRank(Contribution) + 1-percentileRank(Variation) + percentileRank(Magnitude), and multiply by 100 (lower Variation = better). This tells you what to keep doing.',
  optChange:
    'By what percent (and how much) the Impact Score differs from the previous time period.',
  optLeverageScore:
    'Take the percentile rank of relativeRank(Occurrence) * Contribution * Magnitude, and multiply by 100 (lower Occurrence = better). This tells you what to try next.',

  // Plan Dashboard
  planHaloOrDependencies: `These are the top five other events that correlate with the event, by strength of association. If the score is red, they're highly correlated; if it's yellow, they're moderately correlated; and if it's green, they're lowly correlated. Click Show More to see the top ten.`,

  // smoke tests
  testText: `basic smoke test`,
  testHtml: (
    <span>
      <em>And here is</em> <b>some</b> <u>amazing content</u> It is very engaging. Right?
    </span>
  ),
  /*
    testNewLine does not work, the \n is ignored.
  */
  testNewLine: 'this is me testing newline\ndid it work? am I on a new line?',
  /*
    testArray does not work, it breaks the app. don't use this pattern
  */
  testArray: ['Line one', 'Line two', 'does not work'],
  /*
    testObject does not work, it breaks the app. don't use this pattern
  */
  testObject: { key: 'value' }
};

export default tooltipText;
