/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const COINCIDENT_EVENT = gql`
  query coincidentEvent(
    $id: ID!
    $startDateGraph: DateTimeISO
    $endDateGraph: DateTimeISO
    $graph: Boolean!
    $typeFilter: [String]
  ) {
    coincidentEvent(
      id: $id
      startDateGraph: $startDateGraph
      endDateGraph: $endDateGraph
      graph: $graph
    ) {
      id
      coinc_start_date
      coinc_end_date
      detectedEvents(typeFilter: $typeFilter) {
        id
        event_type
        event_start_date
        event_end_date
        magnitude
        mag_unit
        mag_base
        is_complete
        channel
        event_label
        secondary_event_label
        eventInfo {
          postBody
          sourceName
        }
        linktoken {
          id
          type
          remote_name
        }
        campaignEvent {
          id
          linked_account_id
          completed_at
          published_url
        }
        graph(startDate: $startDateGraph, endDate: $endDateGraph) @include(if: $graph) {
          event_label
          timeseries {
            x
            y
          }
        }
      }
    }
  }
`;

export const COINCIDENT_EVENTS = gql`
  query coincidentEvents(
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $startDateGraph: DateTimeISO
    $endDateGraph: DateTimeISO
    $graph: Boolean!
    $typeFilter: [String]
  ) {
    coincidentEvents(
      startDate: $startDate
      endDate: $endDate
      startDateGraph: $startDateGraph
      endDateGraph: $endDateGraph
      graph: $graph
    ) {
      id
      coinc_start_date
      coinc_end_date
      detectedEvents(typeFilter: $typeFilter) {
        id
        event_type
        event_start_date
        event_end_date
        magnitude
        mag_unit
        mag_base
        is_complete
        channel
        event_label
        secondary_event_label
        eventInfo {
          postBody
          sourceName
        }
        linktoken {
          id
          type
          remote_name
        }
        campaignEvent {
          id
          linked_account_id
          completed_at
          published_url
        }
        graph(startDate: $startDateGraph, endDate: $endDateGraph) @include(if: $graph) {
          event_label
          timeseries {
            x
            y
          }
        }
      }
    }
  }
`;

export const COINCIDENT_EVENTS_HEATMAP = gql`
  query coincidentEvents($startDate: DateTimeISO, $endDate: DateTimeISO, $graph: Boolean!) {
    coincidentEvents(startDate: $startDate, endDate: $endDate, graph: $graph) {
      id
      coinc_start_date
      coinc_end_date
      detectedEvents {
        id
        event_type
        event_start_date
        event_end_date
        magnitude
        mag_unit
        mag_base
        is_complete
        channel
        event_label
        secondary_event_label
        eventInfo {
          postBody
          sourceName
        }
        linktoken {
          id
          remote_name
        }
        campaignEvent {
          id
          linked_account_id
          completed_at
          published_url
        }
      }
    }
  }
`;

export const COINCIDENT_EVENTS_DASH_TABLE = gql`
  query coincidentEvents(
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $startDateGraph: DateTimeISO
    $endDateGraph: DateTimeISO
    $graph: Boolean!
  ) {
    coincidentEvents(
      startDate: $startDate
      endDate: $endDate
      startDateGraph: $startDateGraph
      endDateGraph: $endDateGraph
      graph: $graph
    ) {
      id
      coinc_start_date
      coinc_end_date
      detectedEvents {
        id
        event_type
        event_start_date
        event_end_date
        magnitude
        mag_unit
        mag_base
        is_complete
        channel
        event_label
        eventInfo {
          postBody
          sourceName
        }
        linktoken {
          id
          remote_name
        }
        campaignEvent {
          id
          linked_account_id
          completed_at
          published_url
        }
      }
    }
  }
`;
