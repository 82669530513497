import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { SHORTLINK_TRENDING } from 'gql/analytics';
import AlbTable from 'components/AlbTable';
import { customShortlinkRender, customChangeRender } from 'components/AlbTable/CustomRender';
import { getDatesForAnalytics } from 'util/date';

const ShortlinkTrending = props => {
  const { dates } = props;

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(SHORTLINK_TRENDING, { variables: { startDate, endDate } });

  useEffect(() => {
    if (data?.shortlinkTrending) {
      setTableData(
        data.shortlinkTrending.map(shortlink => ({
          ...shortlink,
          short_link: shortlink
        }))
      );
    }
  }, [data]);

  const tableColumns = [
    {
      name: 'short_link',
      label: 'SHORTLINK',
      options: {
        customBodyRender: customShortlinkRender
      }
    },
    {
      name: 'clicks',
      label: 'TOTAL CLICKS'
    },
    {
      name: 'unique_clicks',
      label: 'UNIQUE CLICKS'
    },
    {
      name: 'change',
      label: 'CHANGE',
      options: {
        customBodyRender: customChangeRender
      }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    sort: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard'
  };

  return <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />;
};

ShortlinkTrending.propTypes = {
  dates: PropTypes.shape().isRequired
};

export default ShortlinkTrending;
