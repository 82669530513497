import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, ClickAwayListener } from '@material-ui/core';
import { MoreVert as Actions } from '@material-ui/icons';
import Box from 'components/Box';
import ArchiveShareOfVoiceModal from 'components/AlembicModalConfirm/ArchiveShareOfVoiceModal';
import UnarchiveShareOfVoiceModal from 'components/AlembicModalConfirm/UnarchiveShareOfVoiceModal';
import DeleteShareOfVoiceModal from 'components/AlembicModalConfirm/DeleteShareOfVoiceModal';
import DefaultShareOfVoiceModal from 'components/AlembicModalConfirm/DefaultShareOfVoiceModal';
import { withRouter } from 'react-router-dom';
import CreateShareOfVoiceModal from 'components/AlembicModalForm/CreateShareOfVoiceReportModal';

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    direction: 'rtl',
    textAlign: 'center'
  },
  actions: {
    position: 'absolute',
    alignItems: 'start',
    textAlign: 'left'
  },
  actionsMenu: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: 'white',
    padding: '25px',
    zIndex: 1
  },
  actionsOptions: {
    marginRight: '20px',

    '& div': {
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },

    '& :not(:last-child)': {
      marginBottom: '30px'
    }
  }
});

/**
 * @method
 * @summary This component renders vertical (...) icon with a list of options when clicked @see ShareOfVoiceTable
 * @name ShareOfVoiceTableActions
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.isEnabled - A boolean value for the share of voice enabled state
 * @param {boolean} props.isDefault - Determines if the share of voice report is the default
 * @param {string} props.shareOfVoiceId - A string value for the share of voice id
 * @param {string} props.shareOfVoiceName - A string value for the share of voice name
 * @param {Function} props.refetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const ShareOfVoiceTableActions = props => {
  const { isEnabled, isDefault, shareOfVoiceId, shareOfVoiceName, refetch } = props;
  const [displayedActions, setDisplayedActions] = useState();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDefaultModalOpen, setIsDefaultModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const classes = useStyles();

  const actions = [
    {
      label: 'Edit & Save As New',
      action: () => {
        setIsEditModalOpen(!isEditModalOpen);
      }
    }
  ];

  if (isEnabled) {
    actions.push({
      label: 'Archive',
      action: () => {
        setIsArchiveModalOpen(!isArchiveModalOpen);
      }
    });
  } else {
    actions.push({
      label: 'Unarchive',
      action: () => {
        setIsUnarchiveModalOpen(!isUnarchiveModalOpen);
      }
    });

    actions.push({
      label: 'Delete',
      action: () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }
    });
  }

  if (isEnabled && !isDefault) {
    actions.push({
      label: 'Make Default',
      action: () => {
        setIsDefaultModalOpen(!isDefaultModalOpen);
      }
    });
  }

  return (
    <Box className={classes.actionsWrapper}>
      {displayedActions && (
        <Grid container wrap="nowrap" className={classes.actions}>
          <ClickAwayListener onClickAway={() => setDisplayedActions(false)}>
            <Paper className={classes.actionsMenu}>
              <Grid item className={classes.actionsOptions}>
                {actions.map(action => (
                  <Grid
                    item
                    key={action.label}
                    onClick={e => {
                      e.stopPropagation();
                      action.action();
                      setDisplayedActions(false);
                    }}
                  >
                    {action.label}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Grid>
      )}
      <IconButton
        size="small"
        style={displayedActions ? { zIndex: 2 } : {}}
        onClick={e => {
          e.stopPropagation();
          setDisplayedActions(true);
        }}
      >
        <Actions />
      </IconButton>
      {/** create modal components for share of voice */}
      {isEditModalOpen && (
        <CreateShareOfVoiceModal
          open={isEditModalOpen}
          handleCancel={() => setIsEditModalOpen(false)}
          existingId={shareOfVoiceId}
          refetchTableData={refetch}
        />
      )}
      <ArchiveShareOfVoiceModal
        isModalOpen={isArchiveModalOpen}
        onChange={event => {
          setIsArchiveModalOpen(event.isModalOpen);
          if (event?.newShareOfVoiceData) {
            refetch();
          }
        }}
        shareOfVoiceId={shareOfVoiceId}
        shareOfVoiceName={shareOfVoiceName}
      />
      <UnarchiveShareOfVoiceModal
        isModalOpen={isUnarchiveModalOpen}
        onChange={event => {
          setIsUnarchiveModalOpen(event.isModalOpen);
          if (event?.newShareOfVoiceData) {
            refetch();
          }
        }}
        shareOfVoiceId={shareOfVoiceId}
        shareOfVoiceName={shareOfVoiceName}
      />
      <DeleteShareOfVoiceModal
        isModalOpen={isDeleteModalOpen}
        onChange={event => {
          setIsDeleteModalOpen(event.isModalOpen);
          if (event?.newShareOfVoiceData) {
            refetch();
          }
        }}
        shareOfVoiceId={shareOfVoiceId}
        shareOfVoiceName={shareOfVoiceName}
      />
      <DefaultShareOfVoiceModal
        isModalOpen={isDefaultModalOpen}
        onChange={event => {
          setIsDefaultModalOpen(event.isModalOpen);
          if (event?.newShareOfVoiceData) {
            refetch();
          }
        }}
        shareOfVoiceId={shareOfVoiceId}
        shareOfVoiceName={shareOfVoiceName}
      />
    </Box>
  );
};

ShareOfVoiceTableActions.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired,
  shareOfVoiceId: PropTypes.string.isRequired,
  shareOfVoiceName: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired
};

const ShareOfVoiceTableActionsWithRouter = withRouter(ShareOfVoiceTableActions);

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see ShareOfVoiceTableActions
 * @name customActionsRender
 * @param {Object} actions - Object passed from @see MUIDataTable
 * @param {Object[]} props.isEnabled - A boolean value for the share of voice enabled state
 * @param {boolean} props.isDefault - Determines if the share of voice report is the default
 * @param {string} props.shareOfVoiceId - A string value for the share of voice id
 * @param {string} props.shareOfVoiceName - A string value for the share of voice name
 * @param {Function} props.refetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const customActionsRender = actions => {
  return (
    <ShareOfVoiceTableActionsWithRouter
      isEnabled={actions.isEnabled}
      isDefault={actions.isDefault}
      shareOfVoiceId={actions.shareOfVoiceId}
      shareOfVoiceName={actions.shareOfVoiceName}
      refetch={actions.refetch}
    />
  );
};

export default customActionsRender;
