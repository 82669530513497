import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ATTENTION_ACTION_DELETE_UGC_LABEL } from 'gql/attentionAction';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';
import { refetchQueries } from 'views/DashboardUnderstand/util';

const DeleteActionAttentionUGCLabelModal = props => {
  const { selectedUGC, isModalOpen, onChange, onCompleted } = props;
  const { actionAttentionId, ugcLabel, text } = selectedUGC;

  const [deleteUgcLabel, { error, data }] = useMutation(ATTENTION_ACTION_DELETE_UGC_LABEL, {
    refetchQueries,
    onCompleted
  });

  useEffect(() => {
    if (data?.attentionActionDeleteUgcLabel) {
      showToast(data.attentionActionDeleteUgcLabel.message, 'success');
      onChange({ isModalOpen: false });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({ isModalOpen: false });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Delete Event Label"
        body={
          <span style={{ fontFamily: 'Poppins' }}>
            Are you sure you want to delete the event label:&nbsp;
            <span style={{ color: '#0A1734' }}>{ugcLabel}</span>
            &nbsp;for&nbsp;
            <span style={{ color: '#0A1734' }}>{text}</span>?
          </span>
        }
        cancelTitle="Cancel"
        confirmTitle="Delete"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => deleteUgcLabel({ variables: { actionAttentionId } })}
      />
    </>
  );
};

DeleteActionAttentionUGCLabelModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedUGC: PropTypes.shape({
    actionAttentionId: PropTypes.string.isRequired,
    ugcLabel: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  onCompleted: PropTypes.func
};

DeleteActionAttentionUGCLabelModal.defaultProps = {
  onCompleted: () => {}
};

export default DeleteActionAttentionUGCLabelModal;
