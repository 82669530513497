import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { PROGRAM_LIFT_REPORTS } from 'gql/programLiftReport';
import {
  ADD_POST_TO_PROGRAM_LIFT_REPORT,
  REMOVE_POST_FROM_PROGRAM_LIFT_REPORT,
  PROGRAM_LIFT_REPORTS_FOR_POST
} from 'gql/programLiftReportCampaignEvent';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';

const useStyles = makeStyles({
  root: {
    zIndex: '1500'
  },
  addToButton: {
    fontWeight: 600,
    fontSize: '17px',
    color: '#0A1734'
  },
  addToButtonDisabled: {
    fontWeight: 500,
    fontSize: '17px',
    color: '#6F6F6F'
  }
});

const AddToProgramLiftButton = props => {
  const { campaignEventId } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [listState, setListState] = useState({});

  const programLiftReportsResult = useQuery(PROGRAM_LIFT_REPORTS, {
    variables: {
      enabled: true,
      count: 50,
      after: 0
    },
    fetchPolicy: 'no-cache'
  });
  const programLftReportsForPostResult = useQuery(PROGRAM_LIFT_REPORTS_FOR_POST, {
    variables: {
      id: campaignEventId
    },
    fetchPolicy: 'no-cache'
  });
  const [addPostToProgramLiftReport, { loadingAdd }] = useMutation(ADD_POST_TO_PROGRAM_LIFT_REPORT);
  const [removePostFromProgramLiftReport, { loadingRemove }] = useMutation(
    REMOVE_POST_FROM_PROGRAM_LIFT_REPORT
  );

  useEffect(() => {
    const postMap = {};

    programLftReportsForPostResult?.data?.programLiftReportsForPost?.forEach(result => {
      postMap[result.program_lift_reports_id] = result.campaign_event_id;
    });

    setListState(postMap);
  }, [programLftReportsForPostResult?.data]);

  const handleReportItemClick = (reportId, reportName) => {
    if (listState[reportId]) {
      removePostFromProgramLiftReport({
        variables: {
          programLiftReportId: reportId,
          campaignEventId
        }
      })
        .then(() => {
          setListState({
            ...listState,
            [reportId]: null
          });
          showToast(`Post removed from ${reportName}`, 'success');
        })
        .catch(e => {
          handleGraphQLError(e);
        });
    } else {
      addPostToProgramLiftReport({
        variables: {
          programLiftReportId: reportId,
          campaignEventId
        }
      })
        .then(() => {
          setListState({
            ...listState,
            [reportId]: campaignEventId
          });
          showToast(`Post added to ${reportName}`, 'success');
        })
        .catch(e => {
          handleGraphQLError(e);
        });
    }
  };

  return (
    <div>
      <Button
        variant="text"
        className={classes.addToButton}
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
      >
        + Add To
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        style={{
          zIndex: 1400
        }}
      >
        <List component="div">
          {programLiftReportsResult?.data?.programLiftReports?.count === 0 && (
            <ListItem>
              <ListItemText>
                No Program Lift reports available to add to. Create one first.
              </ListItemText>
            </ListItem>
          )}
          {programLiftReportsResult?.data?.programLiftReports?.rows?.map(report => {
            return (
              <ListItem
                key={report.id}
                button
                onClick={() => handleReportItemClick(report.id, report.name)}
                disabled={loadingAdd || loadingRemove}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={!!listState?.[report.id]}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText>{report.name}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </div>
  );
};

AddToProgramLiftButton.propTypes = {
  campaignEventId: PropTypes.string.isRequired
};

AddToProgramLiftButton.defaultProps = {};

export default AddToProgramLiftButton;
