import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CREATE_ARTICLE_SEARCH_FILTER, UPDATE_ARTICLE_SEARCH_FILTER } from 'gql/articleSearch';
import { showToast } from 'contexts/ToastContext';
import AlembicModalConfirm from './AlembicModalConfirm';

/**
 * @name EarnedReportFilterModal - react component wrapper for @AlembicModalConfirm
 * @summary - Displays a modal for creating or editing a 3rd party media filtered report.
 * @param {Bool} isModalOpen - governs whether the modal is open or closed.
 * @param {Function} onChange - callback function to set the modal to a closed state.
 * @param {string} articleSearchId - the id of the article search that we are creating a filter on.
 * @param {string} keywords - comma-separated list of keywords that are going to be used to filter the article search on the title of the article.
 * @returns - React JSX
 */

const EarnedReportFilterModal = ({
  isModalOpen,
  onChange,
  articleSearchId,
  filterId,
  keywords
}) => {
  const [listOfKeywords, setListOfKeywords] = useState('');
  const [createArticleSearchFilter, { data, error }] = useMutation(CREATE_ARTICLE_SEARCH_FILTER);
  const [updateArticleSearchFilter, { data: updateData, error: updateError }] = useMutation(
    UPDATE_ARTICLE_SEARCH_FILTER
  );

  useEffect(() => {
    if (keywords) {
      setListOfKeywords(keywords);
    }
  }, [keywords]);

  useEffect(() => {
    if (data?.createArticleSearchFilter) {
      showToast('Success! Filter saved.', 'success');
      onChange({
        isModalOpen: false,
        newArticleSearchData: data.createArticleSearchFilter
      });
    }

    if (updateData?.updateArticleSearchFilter) {
      showToast(updateData?.updateArticleSearchFilter.message, 'success');
      onChange({
        isModalOpen: false,
        newArticleSearchData: updateData.updateArticleSearchFilter
      });
    }

    if (error || updateError) {
      showToast('Error, please try again.', 'error');
      onChange({
        isModalOpen: false
      });
    }
  }, [data, error, updateData, updateError]);

  const createOrEditFilteredReport = () => {
    if (listOfKeywords?.length) {
      if (!filterId) {
        createArticleSearchFilter({ variables: { articleSearchId, keywords: listOfKeywords } });
      } else {
        updateArticleSearchFilter({ variables: { id: filterId, newKeywords: listOfKeywords } });
      }
    } else {
      showToast('Keywords are required to save a report filter.', 'warn');
    }
  };

  return (
    <>
      {/* {isModalOpen && error && <AlbError toast error={error} />} */}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title={`${filterId ? 'Edit' : 'Create'} Filtered Report`}
        body={
          <ValidatorForm onSubmit={createOrEditFilteredReport}>
            <TextValidator
              autoFocus
              fullWidth
              variant="filled"
              placeholder="Enter comma-separated keyword(s)"
              value={listOfKeywords}
              onChange={e => setListOfKeywords(e.target.value)}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </ValidatorForm>
        }
        fullLengthBody
        cancelTitle="Cancel"
        confirmTitle="Save"
        handleCancel={() => {
          onChange({ isModalOpen: false });
          setListOfKeywords(keywords);
        }}
        handleConfirm={createOrEditFilteredReport}
      />
    </>
  );
};

EarnedReportFilterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  articleSearchId: PropTypes.string,
  filterId: PropTypes.string,
  keywords: PropTypes.string
};

EarnedReportFilterModal.defaultProps = {
  articleSearchId: null,
  filterId: null,
  keywords: ''
};

export default EarnedReportFilterModal;
