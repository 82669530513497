/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, InputAdornment, IconButton, Chip } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import HasAnyPriv from 'components/HasAnyPriv';

const styles = makeStyles({
  formMargin: {
    marginBottom: '8px'
  },
  formReset: {
    marginBottom: '0px !important'
  },
  tag: {
    margin: '1px',
    fontSize: '10px'
  },
  pending: {
    fontSize: '10px'
  }
});

const charLimit = 25;
// props: selectedAsset from MediaLayout.
const AssetTags = props => {
  const { onChange, autoTags, userTags, displayOnly } = props;
  const [autoTagsState, setAutoTagsState] = useState(autoTags);
  const [userTagsState, setUserTagsState] = useState(userTags);
  const [inputTag, setInputTag] = useState('');
  const classes = styles();

  useEffect(() => {
    setAutoTagsState(autoTags);
  }, [autoTags]);

  useEffect(() => {
    setUserTagsState(userTags);
  }, [userTags]);

  const removeAutoTag = index => {
    const autoTagsCopy = [...autoTagsState];
    autoTagsCopy.splice(index, 1);
    setAutoTagsState(autoTagsCopy);
    onChange({ autoTags: autoTagsCopy, userTags: userTagsState });
  };

  const addUserTag = event => {
    if ((event.keyCode === 13 || event.type === 'click') && inputTag !== '') {
      event.preventDefault();
      setUserTagsState([...userTagsState, inputTag]);
      setInputTag('');
      onChange({ autoTags: autoTagsState, userTags: [...userTagsState, inputTag] });
    }
  };

  const removeUserTag = index => {
    const userTagsCopy = [...userTagsState];
    userTagsCopy.splice(index, 1);
    setUserTagsState(userTagsCopy);
    onChange({ autoTags: autoTagsState, userTags: userTagsCopy });
  };

  return (
    <Grid container spacing={1} className={classes.formMargin}>
      {!displayOnly && (
        <HasAnyPriv privs={['SVC:ASSET:UPDATE']}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="filled"
              placeholder="Add tag(s)..."
              className={classes.formReset}
              value={inputTag}
              onChange={event => setInputTag(event.target.value)}
              onKeyDown={event => addUserTag(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={event => addUserTag(event)} size="small">
                      <ArrowForward />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              // es-lint is wrong about this one. They are both valid & different props in material ui.
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: charLimit
              }}
              helperText={`${inputTag.length}/${charLimit}`}
            />
          </Grid>
        </HasAnyPriv>
      )}
      <Grid item xs={12}>
        {autoTagsState?.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={displayOnly ? null : () => removeAutoTag(index)}
            variant="outlined"
            className={classes.tag}
          />
        ))}
        {userTagsState?.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={displayOnly ? null : () => removeUserTag(index)}
            variant="default"
            className={classes.tag}
          />
        ))}
      </Grid>
    </Grid>
  );
};

AssetTags.propTypes = {
  autoTags: PropTypes.arrayOf(PropTypes.string),
  userTags: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  displayOnly: PropTypes.bool
};

AssetTags.defaultProps = {
  autoTags: null,
  userTags: null,
  displayOnly: false
};

export default AssetTags;
