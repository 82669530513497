/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import ShareOfVoiceHorizontalBar from './Graphs/ShareOfVoiceHorizontalBar';
import ShareOfVoiceBreakdown from './Tables/ShareOfVoiceBreakdown';

const useStyles = makeStyles({
  container: {
    flex: 1
  }
});

/**
 * @method
 * @summary This component contains the share of company impact horizontal bar and breakdown table
 * @name ShareOfVoiceImpact
 * @param {Object} props - React props passed to this component
 * @param {Object} props.shareOfVoiceReport - The result of the competitive intelligence report query
 * @return {Object} - React JSX
 */
const ShareOfVoiceImpact = props => {
  const { shareOfVoiceReport } = props;
  const classes = useStyles();

  const [articleSearches, setArticleSearches] = useState([]);
  const [articleSearchFilters, setArticleSearchFilters] = useState([]);

  useEffect(() => {
    if (shareOfVoiceReport?.article_searches?.length) {
      setArticleSearches(shareOfVoiceReport?.article_searches);
      setArticleSearchFilters(shareOfVoiceReport?.article_search_filters);
    }
  }, [shareOfVoiceReport]);

  return (
    <Box className={classes.container}>
      <ShareOfVoiceHorizontalBar
        articleSearches={articleSearches}
        articleSearchFilters={articleSearchFilters}
      />
      <ShareOfVoiceBreakdown
        articleSearches={articleSearches}
        articleSearchFilters={articleSearchFilters}
      />
    </Box>
  );
};

ShareOfVoiceImpact.propTypes = {
  shareOfVoiceReport: PropTypes.shape({
    article_searches: PropTypes.arrayOf(PropTypes.shape()),
    article_search_filters: PropTypes.arrayOf(PropTypes.shape())
  })
};

ShareOfVoiceImpact.defaultProps = {
  shareOfVoiceReport: {}
};

export default ShareOfVoiceImpact;
