import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  h1: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#0A1734'
  }
});

const AnalyticsInsightsH1 = props => {
  const { children } = props;
  const classes = useStyles();

  return <Typography className={classes.h1}>{children}</Typography>;
};

AnalyticsInsightsH1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired
};

export default AnalyticsInsightsH1;
