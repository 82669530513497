import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { topojson } from 'chartjs-chart-geo';
import colors from 'util/colors';
import countriesJSON from 'util/countries';
import { Gradient } from './Legend';

const gradient = [
  colors.blue1,
  colors.blueGeo1,
  colors.blue2,
  colors.blue3,
  colors.blue4,
  colors.blue5,
  colors.blue6,
  colors.darkBlue
];

const percentageRanges = gradient.map((color, i) => ({
  color,
  percentage: (100 / (gradient.length - 1)) * i
}));

const AlbGeoGraph = props => {
  const { codes, datasets } = props;

  const [graphData, setGraphData] = useState([]);
  const [max, setMax] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [countryValues, setCountryValues] = useState({});

  const canvas = useRef(null);
  const geoGraph = useRef(null);
  const countryValuesRef = useRef({});

  useEffect(() => {
    countryValuesRef.current = countryValues;
  }, [countryValues]);

  const initializeGeoGraph = res => {
    const countries = topojson.feature(res, res.objects.countries).features;

    geoGraph.current = new Chart(canvas.current.getContext('2d'), {
      type: 'choropleth',
      data: {
        labels: countries.map(({ properties }) => properties.name),
        datasets: [{ data: countries.map(feature => ({ feature })), backgroundColor: gradient[0] }]
      },
      options: {
        showOutline: true,
        showGraticule: true,
        legend: { display: false },
        scale: { projection: 'equalEarth' },
        tooltips: {
          callbacks: {
            label: (item, data) => {
              const country = data.labels[item.index];
              const countryValue = countryValuesRef.current[country] || 0;

              return `${country}: ${countryValue.toLocaleString()}`;
            }
          }
        }
      }
    });

    setCountryList(countries);
  };

  useEffect(() => {
    initializeGeoGraph(countriesJSON);
  }, []);

  useEffect(() => {
    if (datasets?.[0]) {
      setGraphData(codes.map((x, i) => ({ x, y: datasets[0].data[i] })));
      setMax(Math.max(...datasets[0].data));
    }
  }, [codes, datasets]);

  const formatGraphData = (countries, data) => {
    const values = {};
    const formattedGraphData = countries.map(feature => {
      const { name } = feature.properties;
      const value = data.find(({ x }) => x === feature.id)?.y || 0;

      values[name] = value;

      return { feature, value };
    });

    setCountryValues(values);

    return formattedGraphData;
  };

  const backgroundColor = context => {
    const value = context.dataset.data[context.dataIndex]?.value || 0;
    const round = Math.round((value / max) * 100) || 0;

    return percentageRanges.find(range => round <= range.percentage).color;
  };

  useEffect(() => {
    if (graphData && countryList.length) {
      geoGraph.current.data.datasets = [
        { label: 'Countries', data: formatGraphData(countryList, graphData), backgroundColor }
      ];

      geoGraph.current.update();
    }
  }, [graphData, countryList]);

  return (
    <>
      <canvas ref={canvas} />
      <Gradient gradient={gradient} />
    </>
  );
};

AlbGeoGraph.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default AlbGeoGraph;
