/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DetectedEventDrawerGraph from './DetectedEventDrawerGraph';
import DetectedEventDrawerTable from './DetectedEventDrawerTable';

const useStyles = makeStyles({
  dateContainer: {
    padding: '16px 27px 30px 27px'
  },
  dateLabel: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#0A1734'
  },
  dateText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#585858'
  },
  graphContainer: {
    padding: '0px 20px'
  }
});

const DetectedEventDrawerView = props => {
  const { detectedEvent, graphDates } = props;
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.dateContainer}>
        <Grid container direction="column">
          <Grid item>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateLabel}>
              Detection Start:{' '}
            </Typography>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateText}>
              {moment.utc(detectedEvent?.event_start_date).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateLabel}>
              Detection End:{' '}
            </Typography>
            <Typography variantMapping={{ body1: 'span' }} className={classes.dateText}>
              {moment.utc(detectedEvent?.event_end_date).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.graphContainer}>
        <DetectedEventDrawerGraph detectedEvent={detectedEvent} graphDates={graphDates} />
      </Grid>

      <DetectedEventDrawerTable detectedEvent={detectedEvent} />
    </>
  );
};

DetectedEventDrawerView.propTypes = {
  detectedEvent: PropTypes.shape().isRequired,
  graphDates: PropTypes.shape()
};

DetectedEventDrawerView.defaultProps = {
  graphDates: null
};

export default DetectedEventDrawerView;
