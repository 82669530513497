/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { UPDATE_CONTAINER } from 'gql/container';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';
import store from 'store';
import { updateContainer } from 'actions/authActions';

const useStyles = makeStyles({
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px'
  },
  subtitle: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px'
  },
  noteLabel: {
    color: '#585858',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '19px'
  },
  note: {
    color: '#585858',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px'
  }
});

const ValuationModel = props => {
  const { containerData } = props;
  const [newEventsDisplayDefault, setNewEventsDisplayDefault] = useState('new_events');
  const [updateContainerMutation, { loading, error, data }] = useMutation(UPDATE_CONTAINER);
  const classes = useStyles();

  useEffect(() => {
    if (containerData.new_events_display_default === true) {
      setNewEventsDisplayDefault('new_events');
    }

    if (containerData.new_events_display_default === false) {
      setNewEventsDisplayDefault('all_events');
    }
  }, [containerData?.new_events_display_default]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.updateContainer?.id) {
      store.dispatch(updateContainer(data.updateContainer));
      showToast('Container updated', 'success');
    }
  }, [data]);

  return (
    <>
      <ValidatorForm
        onSubmit={() => {
          updateContainerMutation({
            variables: {
              id: containerData.id,
              newEventsDisplayDefault: newEventsDisplayDefault === 'new_events'
            }
          });
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography className={classes.title}>
              Default Settings For Event Correlation
            </Typography>
          </Grid>

          <Grid item>
            <Typography variantMapping={{ body1: 'span' }} className={classes.noteLabel}>
              Please note:{' '}
            </Typography>
            <Typography variantMapping={{ body1: 'span' }} className={classes.note}>
              This will be the default selection for the results displayed on the Event Correlation
              Dashboard. This effects only singular events displayed and not correlation
              computations.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.subtitle}>Default Switch Position</Typography>
          </Grid>

          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={newEventsDisplayDefault}
                onChange={event => setNewEventsDisplayDefault(event.target.value)}
              >
                <FormControlLabel value="new_events" control={<Radio />} label="New Events" />
                <FormControlLabel value="all_events" control={<Radio />} label="All Events" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

ValuationModel.propTypes = {
  containerData: PropTypes.shape()
};

ValuationModel.defaultProps = {
  containerData: null
};

export default ValuationModel;
