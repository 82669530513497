import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

export const CREATE_CAMPAIGN = gql`
  mutation(
    $name: String!
    $description: String!
    $start_date: DateTimeISO!
    $end_date: DateTimeISO
  ) {
    createCampaign(
      name: $name
      description: $description
      start_date: $start_date
      end_date: $end_date
    ) {
      id
      name
      description
      start_date
      end_date
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation(
    $id: ID!
    $name: String
    $description: String
    $start_date: DateTimeISO
    $end_date: DateTimeISO
  ) {
    updateCampaign(
      id: $id
      name: $name
      description: $description
      start_date: $start_date
      end_date: $end_date
    ) {
      id
      name
      description
      start_date
      end_date
    }
  }
`;

export const GET_CAMPAIGNS_FOR_CURRENT_CONTAINER = gql`
  query($internal: Boolean, $exclude_completed: Boolean, $end_date: DateTimeISO) {
    campaigns(internal: $internal, exclude_completed: $exclude_completed, end_date: $end_date) {
      id
      container_id
      name
      description
      snoozed
      state
      start_date
      end_date
      created_by
      created_at
      updated_at
      last_updated_by
      container_default
      external
    }
  }
`;

export async function apiUpdateCampaign(campaign) {
  return apolloClientHelper(
    UPDATE_CAMPAIGN,
    { ...campaign },
    true // mutation
  );
}
