import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid, MenuItem, Typography, Checkbox } from '@material-ui/core';
import { CheckBox as CheckedIcon, CheckBoxOutlineBlank as UncheckedIcon } from '@material-ui/icons';
import Select from 'react-select';
import { connect } from 'react-redux';
import Box from 'components/Box';
import { LINKTOKENS_FOR_SELECTOR } from 'gql/linktoken';
import getSimpleSocialType from 'util/getSimpleSocialType';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';
import SocialLogo from 'components/SocialLogo';

const NoOptionsMessage = () => (
  <Box py={10} display="flex" justifyContent="center" alignItems="center">
    <Typography>No reports available</Typography>
  </Box>
);

const Option = props => {
  const { data, innerProps, isSelected } = props;
  const { onClick } = innerProps;

  return (
    <MenuItem onClick={onClick} style={{ padding: '12px 16px' }} key={data.id}>
      <Grid container alignItems="center" wrap="nowrap">
        <Checkbox
          style={{ padding: 0, marginRight: '10px' }}
          checked={isSelected}
          icon={<UncheckedIcon fontSize="small" />}
          checkedIcon={<CheckedIcon fontSize="small" />}
        />
        <SocialLogo width={20} height={20} type={getSimpleSocialType(data.linktoken.type, true)} />
        <Typography style={{ marginLeft: '10px' }}>{data.name}</Typography>
      </Grid>
    </MenuItem>
  );
};

Option.propTypes = {
  data: PropTypes.shape().isRequired,
  innerProps: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired
};

const MultiValueContainer = props => {
  const { data } = props;

  return (
    <Box mr={10} display="flex">
      <SocialLogo width={20} height={20} type={getSimpleSocialType(data.linktoken.type, true)} />
    </Box>
  );
};

MultiValueContainer.propTypes = {
  data: PropTypes.shape().isRequired
};

const SingleValue = props => {
  const { data } = props;

  return (
    <Box mr={10} display="flex">
      <SocialLogo width={20} height={20} type={getSimpleSocialType(data.linktoken.type, true)} />
    </Box>
  );
};

SingleValue.propTypes = {
  data: PropTypes.shape().isRequired
};

const LinktokenSelector = props => {
  const { useDefault, isMulti, filterTypes, onChange, currentContainer } = props;
  const [selectedValues, setSelectedValues] = useState([]);
  const { data, refetch } = useQuery(LINKTOKENS_FOR_SELECTOR, {
    variables: { showOnlyEnabled: true },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    refetch();
  }, [currentContainer.id]);

  const handleOnChange = newSelectedValues => {
    setSelectedValues(newSelectedValues);

    if (onChange != null && isMulti) {
      // Process the selected values and get the original report
      const tempLinktokens = [];

      newSelectedValues?.forEach(({ linktoken }) => {
        tempLinktokens.push(linktoken);
      });

      onChange({
        linktokens: tempLinktokens
      });
    }

    if (onChange != null && !isMulti) {
      onChange(newSelectedValues ?? null);
    }
  };

  const filterOptions = linktoken => {
    if (filterTypes?.length > 0) {
      return filterTypes.includes(linktoken.type);
    }

    return true;
  };

  const mapOptions = linktoken => {
    let name = linktoken.remote_name;

    if (linktoken.type === LINKTOKEN_TYPE_ENUM.GOOGLE_VIEW && linktoken.parent != null) {
      name = `${linktoken.parent.remote_name} > ${linktoken.remote_name}`;
    }

    return {
      id: linktoken.id,
      value: linktoken.id,
      name,
      linktoken
    };
  };

  useEffect(() => {
    if (data?.linkedAccounts?.length > 0 && useDefault) {
      if (isMulti === false) {
        const tempOptions = data.linkedAccounts.filter(filterOptions).map(mapOptions);
        setSelectedValues(tempOptions[0]);
        handleOnChange(tempOptions[0]);
      }
    }
  }, [data]);

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 40
    })
  };

  return (
    <Select
      isMulti={isMulti}
      placeholder="Select Account"
      menuPlacement="auto"
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable
      components={{
        Option,
        NoOptionsMessage,
        MultiValueContainer,
        SingleValue
      }}
      options={
        data?.linkedAccounts?.length > 0
          ? data.linkedAccounts.filter(filterOptions).map(mapOptions)
          : []
      }
      value={selectedValues}
      onChange={handleOnChange}
      styles={customStyles}
    />
  );
};

LinktokenSelector.propTypes = {
  useDefault: PropTypes.bool,
  isMulti: PropTypes.bool,
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  currentContainer: PropTypes.shape().isRequired
};

LinktokenSelector.defaultProps = {
  useDefault: false,
  isMulti: true,
  filterTypes: [],
  onChange: null
};

const mapStateToProps = state => ({
  currentContainer: state.auth.currentContainer
});

export default connect(mapStateToProps)(LinktokenSelector);
