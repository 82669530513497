import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import getValueAbbreviation from 'util/getValueAbbreviation';
import styles from './overviewCardStyles';

const useStyles = makeStyles(styles);

/**
 * @method
 * @summary This component contains the view elements for @see Overview
 * @name OverviewCard
 * @param {Object} props - React props passed to this component
 * @param {string} props.surgeStart - When the resurgence started
 * @param {string} props.surgeEnd - When the resurgence ended
 * @param {string} props.showing - The date range of the resurgence
 * @param {number} props.valuation - The valuation amount based on the bonus impressions
 * @param {number} props.bonusImpressions - The number of impressions gained
 * @param {string} props.surge - A major or minor resurgence
 * @param {string} props.isComplete - Whether or not the resurgence is completed
 * @param {string} props.followerGrowth - The number of followers gained
 * @param {string} props.length - The number of days for the resurgence
 * @return {Object} - React JSX
 */
const OverviewCard = props => {
  const {
    surgeStart,
    surgeEnd,
    showing,
    valuation,
    bonusImpressions,
    // surge,
    isComplete,
    followerGrowth,
    length
    // halfDrawerView
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.cardContainer}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardHeader}
        >
          <Grid item className={classes.cardHeaderItem}>
            <Typography className={classes.headerMainText}>Surge Start:&nbsp;</Typography>
            <Typography className={classes.headerSubText}>
              {surgeStart == null ? <>&nbsp;</> : surgeStart}
            </Typography>
          </Grid>
          <Grid item className={classes.cardHeaderItem}>
            <Typography className={classes.headerMainText}>Surge End:&nbsp;</Typography>
            <Typography className={classes.headerSubText}>
              {surgeEnd == null ? <>&nbsp;</> : surgeEnd}
            </Typography>
          </Grid>
          <Grid item className={classes.cardHeaderItem}>
            <Typography className={classes.headerMainText}>Showing:&nbsp;</Typography>
            <Typography className={classes.headerSubText}>
              {showing == null ? <>&nbsp;</> : showing}
            </Typography>
          </Grid>
          <Grid item className={classes.cardHeaderItem}>
            <Typography className={classes.headerMainText}>Valuation:&nbsp;</Typography>
            <Typography className={classes.headerSubText}>
              {getValueAbbreviation(valuation) ? `$${getValueAbbreviation(valuation)}` : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          className={classes.cardBody}
        >
          <div className={classes.cardBodyItem}>
            <Typography className={classes.bodyItemHeader}>BONUS IMPRESSIONS</Typography>
            <Typography className={classes.bodyItemBody}>
              {getValueAbbreviation(bonusImpressions)
                ? `${getValueAbbreviation(bonusImpressions)}`
                : '-'}
            </Typography>
          </div>
          {/* <div className={classes.cardBodyItem}>
            <Typography className={classes.bodyItemHeader}>SURGE</Typography>
            <Typography className={classes.bodyItemBody}>{surge == null ? '-' : surge}</Typography>
          </div> */}
          {/* {halfDrawerView && (
            <div className={classes.cardBodyItem}>
              <Typography className={classes.bodyItemHeader}>SURGE</Typography>
              <Typography className={classes.bodyItemBody}>
                Surge Placeholder
              </Typography>
            </div>
          )} */}
          <div className={classes.cardBodyItem}>
            <Typography className={classes.bodyItemHeader}>COMPLETE?</Typography>
            <Typography className={classes.bodyItemBody}>
              {isComplete == null ? '-' : isComplete}
            </Typography>
          </div>
          <div className={classes.cardBodyItem}>
            <Typography className={classes.bodyItemHeader}>FOLLOWER GROWTH IN PERIOD</Typography>
            <Typography className={classes.bodyItemBody}>
              {followerGrowth == null ? '-' : followerGrowth}
            </Typography>
          </div>
          {/* {halfDrawerView && (
            <div className={classes.cardBodyItem}>
              <Typography className={classes.bodyItemHeader}>% CHANGE LTV IMPRESSIONS</Typography>
              <Typography className={classes.bodyItemBody}>
                LTV Impressions Placeholder
              </Typography>
            </div>
          )} */}
          <div className={classes.cardBodyItem}>
            <Typography className={classes.bodyItemHeader}>LENGTH</Typography>
            <Typography className={classes.bodyItemBody}>
              {length == null ? '-' : length}
            </Typography>
          </div>
        </Grid>
      </div>
    </div>
  );
};

OverviewCard.propTypes = {
  surgeStart: PropTypes.string,
  surgeEnd: PropTypes.string,
  showing: PropTypes.string,
  valuation: PropTypes.number,
  bonusImpressions: PropTypes.number,
  // surge: PropTypes.string,
  isComplete: PropTypes.string,
  followerGrowth: PropTypes.string,
  length: PropTypes.string
  // halfDrawerView: PropTypes.bool
};

OverviewCard.defaultProps = {
  surgeStart: null,
  surgeEnd: null,
  showing: null,
  valuation: null,
  bonusImpressions: null,
  // surge: null,
  isComplete: null,
  followerGrowth: null,
  length: null
  // halfDrawerView: false
};

export default OverviewCard;
