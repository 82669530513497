import { ApolloClient, InMemoryCache, split, HttpLink, from, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import getCurrentContainerIdFromRedux from 'util/container';

const CONTAINER_HEADER = 'x-container';
const JWT_HEADER = 'x-alembic-jwt';

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  credentials: 'include'
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.WS_URL,
    credentials: 'include',
    connectionParams: () => {
      return { [JWT_HEADER]: localStorage.getItem('alb_jwt_token') || '' };
    }
  })
);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      [CONTAINER_HEADER]: getCurrentContainerIdFromRedux(),
      [JWT_HEADER]: localStorage.getItem('alb_jwt_token') || '',
      ...headers
    }
  }));

  return forward(operation);
});

const HttpLinkWithAuth = from([authLink, httpLink]);

// https://www.apollographql.com/docs/react/api/link/introduction#directional-composition
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  HttpLinkWithAuth
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  credentials: 'include' // apollo client will not respect cookies unless this is set.
});

export default client;
