import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SocialLogo from 'components/SocialLogo';
import AlembicAvatar from 'components/AlembicAvatar';
import { convertToUserTimeZone } from 'util/date';
import getSimpleSocialType from 'util/getSimpleSocialType';

const useStyles = makeStyles({
  textHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  textHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1
  },
  avatar: {
    marginLeft: '10px'
  },
  handleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px'
  },
  textHandle: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#32327D'
  },
  textTime: {
    fontSize: '10px',
    fontWeight: '500',
    color: '#6F6F6F'
  }
});

/**
 * @method
 * @summary This component renders the post header at the top of @see PostDrawerView inside @see PostDrawerDetails
 * @name PostPreviewHeader
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const PostPreviewHeader = props => {
  const { currentUser, postDetails } = props;
  const classes = useStyles();
  const { type, completedAt, avatarUrl, remoteName } = postDetails;

  const formatPostTimeAndDate = () => {
    return `${convertToUserTimeZone(completedAt, currentUser.time_zone).format(
      'MM/DD/YY'
    )} @ ${convertToUserTimeZone(completedAt, currentUser.time_zone).format('h:mma')}`;
  };

  return (
    <div className={classes.textHeader}>
      <div className={classes.textHeaderContent}>
        <SocialLogo width={27} height={27} type={getSimpleSocialType(type)} />

        <div className={classes.avatar}>
          <AlembicAvatar avatarSrc={avatarUrl} width={27} height={27} />
        </div>

        <div className={classes.handleContainer}>
          <Typography className={classes.textHandle}>{remoteName}</Typography>
          <Typography className={classes.textTime}>{formatPostTimeAndDate()}</Typography>
        </div>
      </div>
    </div>
  );
};

PostPreviewHeader.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  postDetails: PropTypes.shape()
};

PostPreviewHeader.defaultProps = {
  postDetails: {
    linkTokenType: null,
    completedAt: null,
    avatarUrl: null,
    remoteName: null,
    publishedUrl: null
  }
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(PostPreviewHeader);
