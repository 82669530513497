import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { GET_DISABLED_MENTION_GROUPS } from 'gql/mention_group';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { getDatesForAnalytics } from 'util/date';
import NarrativeTable from './NarrativeTable';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const DisabledNarrative = props => {
  const {
    selectedDates,
    broadcastSearchIds,
    podcastSearchIds,
    mediaType,
    after,
    setAfter,
    sortOrder,
    setSortOrder
  } = props;
  const classes = useStyles();
  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const { loading, error, data, refetch } = useQuery(GET_DISABLED_MENTION_GROUPS, {
    variables: {
      ...(broadcastSearchIds?.length > 0 && {
        broadcastSearchIds
      }),
      ...(podcastSearchIds?.length > 0 && {
        podcastSearchIds
      }),
      startDate,
      endDate,
      after: after * 10,
      count: 10,
      orderBy: sortOrder,
      mediaType
    },
    fetchPolicy: 'network-only'
  });

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      <NarrativeTable
        dataResponse={data}
        dataRefetch={refetch}
        after={after}
        setAfter={setAfter}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
};

DisabledNarrative.propTypes = {
  selectedDates: PropTypes.shape().isRequired,
  broadcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  podcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  mediaType: PropTypes.string.isRequired,
  after: PropTypes.number.isRequired,
  setAfter: PropTypes.func.isRequired,
  sortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSortOrder: PropTypes.func.isRequired
};

export default DisabledNarrative;
