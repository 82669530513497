/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import AlbTable from 'components/AlbTable';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RESURGENCES from 'gql/resurgence';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import AnalyticsBox from 'components/AnalyticsBox';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { getDatesForAnalytics } from 'util/date';
import getValueAbbreviation from 'util/getValueAbbreviation';
import colors from 'util/colors';
import { ResurgenceAnalyticsPathMatch } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  }
});

const tableThemeOverrides = {
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap',

      '&:nth-child(-n+2)': {
        width: 'unset'
      }
    },
    sortAction: {
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  },
  MUIDataTableBodyRow: {
    root: {
      '&:nth-child(even)': {
        backgroundColor: '#f5f5f5'
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: colors.athensGray
      }
    }
  }
};

/**
 * @method
 * @summary This component renders the table inside @see AnalyticsResurgence
 * @name Overview
 * @param {Object} props - React props passed to this component
 * @param {Object} props.history - The history object from react router
 * @return {Object} - React JSX
 */
const ResurgenceTable = props => {
  const { history, location } = props;

  const classes = useStyles();
  const drawerContext = useContext(PostDrawerViewContext);

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['', '']);

  const [rowLookup, setRowLookup] = useState({});

  const { loading, error, data } = useQuery(RESURGENCES, {
    fetchPolicy: 'no-cache'
  });

  const customCenterRender = text => <div align="center">{text}</div>;

  const customBonusImpressionsRender = impressions => (
    <div align="center">{getValueAbbreviation(impressions) || '-'}</div>
  );

  const customValuationRender = valuation => (
    <div align="right" style={{ color: colors.purple }}>
      {valuation ? `$${valuation.toLocaleString()}` : '-'}
    </div>
  );

  useEffect(() => {
    if (data?.resurgences) {
      const lookup = {};

      const formattedTableData = data.resurgences.map(resurgence => {
        const {
          id,
          linktoken_id,
          post_id,
          begin_date,
          end_date,
          post_date,
          numberOfPosts: number_posts,
          bonusImpressions: bonus_impressions,
          valuation,
          type
        } = resurgence;

        const resurgenceDate = `${moment(begin_date).format('M/DD/YY')} - ${moment(end_date).format(
          'M/DD/YY'
        )}`;
        const postDate = post_date ? moment(post_date).format('M/DD/YY') : '-';

        const dates = getDatesForAnalytics(
          moment(begin_date).subtract(3, 'days'),
          moment(end_date).add(3, 'days')
        );

        lookup[id] = {
          id,
          linktoken_id,
          post_id,
          begin_date,
          end_date,
          type,
          ...dates
        };

        return {
          id,
          resurgence_date: resurgenceDate,
          post_date: postDate,
          number_posts,
          bonus_impressions,
          valuation
        };
      });

      const columns = [
        {
          name: 'id',
          options: { display: false }
        },
        {
          name: 'resurgence_date',
          label: 'RESURGENCE DATE'
        },
        {
          name: 'post_date',
          label: 'POST PUB. DATE'
        },
        {
          name: 'number_posts',
          label: '# OF POSTS',
          options: {
            customBodyRender: customCenterRender,
            setCellHeaderProps: () => ({ className: classes.centeredHeader })
          }
        },
        {
          name: 'bonus_impressions',
          label: 'BONUS IMP.',
          options: {
            customBodyRender: customBonusImpressionsRender,
            setCellHeaderProps: () => ({ className: classes.centeredHeader })
          }
        },
        {
          name: 'valuation',
          label: 'VALUATION',
          options: {
            customBodyRender: customValuationRender,
            setCellHeaderProps: () => ({ className: classes.rightHeader })
          }
        }
      ];

      setRowLookup(lookup);
      setTableColumns(columns);
      setTableData(formattedTableData);
    }
  }, [data]);

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const onRowClick = rowData => {
    const [id] = rowData;

    const { linktoken_id, post_id, begin_date, end_date, type, start, end } = rowLookup[id];

    if (type === 'SPR') {
      drawerContext.toggleDrawer(
        true,
        null,
        post_id,
        linktoken_id,
        start,
        end,
        true,
        begin_date,
        end_date
      );
    }

    if (type === 'MPR') {
      const searchParams = new URLSearchParams(location.search);
      let dashboardPath = '';

      if (searchParams.has('from_dash')) {
        dashboardPath = '&from_dash';
      }
      if (searchParams.has('from_dashv3')) {
        dashboardPath = '&from_dashv3';
      }

      goToRoute(
        `${ResurgenceAnalyticsPathMatch}?linktoken_id=${linktoken_id}&begin_date=${begin_date}&end_date=${end_date}${dashboardPath}`,
        history
      );
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    onRowClick,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  if (loading) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbLoading />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={error} />
      </Grid>
    );
  }

  return (
    <AnalyticsBox tabs={['Resurgences']} onChange={() => {}} noPadding noMargin>
      <AlbTable
        tableData={tableData}
        tableColumns={tableColumns}
        tableOptions={tableOptions}
        tableThemeOverrides={tableThemeOverrides}
      />
    </AnalyticsBox>
  );
};

ResurgenceTable.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default withRouter(ResurgenceTable);
