import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton, Button, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import HasAnyPriv from 'components/HasAnyPriv';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlembicModalForm from 'components/AlembicModalForm';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import TabNav from 'components/TabNav';
import {
  GET_TOPICS_FOR_CURRENT_CONTAINER,
  CREATE_TOPIC,
  UPDATE_TOPIC,
  DELETE_TOPIC
} from 'gql/topic';
import { GET_THEMES_FOR_CURRENT_CONTAINER } from 'gql/theme';
import { showToast } from 'contexts/ToastContext';
import { convertToUserTimeZone } from 'util/date';

const styles = makeStyles({
  svgIcon: {
    color: '#0d1a3a'
  },
  editIcon: {
    borderRadius: 0
  },
  deleteIcon: {
    borderRadius: 0
  }
});

const createFields = [
  { fieldName: 'name', title: 'Topic Name', type: 'input', validators: ['required'] },
  { fieldName: 'theme_id', title: 'Theme', type: 'dropdown', validators: ['required'], options: [] }
];
const updateFields = [
  { fieldName: 'name', title: 'Topic Name', type: 'input', validators: ['required'] },
  { fieldName: 'active', title: 'Active', type: 'checkbox' }
];

const TopicList = props => {
  const { currentUser, openCreateTopic, handleCloseCreateTopic, loading, queryError, data } = props;

  const classes = styles();

  const [openEditTopic, setEditTopic] = useState(false);
  const [openDeleteTopic, setDeleteTopic] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({});

  const { loading: themeLoading, error: themeQueryError, data: themeData } = useQuery(
    GET_THEMES_FOR_CURRENT_CONTAINER
  );
  const [createTopic, { error: creationError }] = useMutation(CREATE_TOPIC, {
    refetchQueries: () => {
      return [
        { query: GET_TOPICS_FOR_CURRENT_CONTAINER },
        { query: GET_THEMES_FOR_CURRENT_CONTAINER }
      ];
    }
  });
  const [editTopic, { error: updateError }] = useMutation(UPDATE_TOPIC);
  const [deleteTopic, { error: deletionError }] = useMutation(DELETE_TOPIC, {
    refetchQueries: () => {
      return [
        { query: GET_TOPICS_FOR_CURRENT_CONTAINER },
        { query: GET_THEMES_FOR_CURRENT_CONTAINER }
      ];
    }
  });

  useEffect(() => {
    if (themeData?.themes) {
      const themeField = createFields.find(field => field.fieldName === 'theme_id');
      const activeThemes = themeData.themes.filter(theme => theme.active === true);

      themeField.options = activeThemes.map(theme => ({
        value: theme.id,
        name: theme.name
      }));
    }
  }, [themeData]);

  const options = {
    pagination: false,
    selectableRows: 'none',
    responsive: 'vertical',
    rowHover: false,
    fixedHeader: false,
    sortFilterList: false,
    sort: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false
  };

  const columns = ['Name', 'Theme', 'Created By', 'Created At', 'Edit', 'Action'];

  const handleOpenEditTopic = topic => {
    setEditTopic(true);
    setSelectedTopic(topic);
  };

  const handleCloseEditTopic = () => {
    setEditTopic(false);
    setSelectedTopic(null);
  };

  const handleOpenDeleteTopic = topic => {
    setDeleteTopic(true);
    setSelectedTopic(topic);
  };

  const handleCloseDeleteTopic = () => {
    setDeleteTopic(false);
  };

  const handleSaveTopic = topic => {
    createTopic({ variables: topic });
    handleCloseCreateTopic();
  };

  const handleEditTopic = ({ name, active }) => {
    editTopic({ variables: { id: selectedTopic.id, name, active } });
    handleCloseEditTopic();
  };

  const handleDeleteTopic = () => {
    deleteTopic({ variables: { id: selectedTopic.id } });
    handleCloseDeleteTopic();
  };

  const handleButtonAction = topic => {
    const hasEvents = !!topic.campaign_events.length;
    const action = hasEvents
      ? () => editTopic({ variables: { id: topic.id, name: topic.name, active: false } })
      : () => handleOpenDeleteTopic(topic);

    const button = (
      <HasAnyPriv privs={['THEMES:DELETE']}>
        <Button
          disabled={hasEvents && !topic.active}
          variant="outlined"
          color="primary"
          size="small"
          onClick={action}
        >
          {hasEvents && topic.active ? 'Set as Inactive' : 'Delete'}
        </Button>
      </HasAnyPriv>
    );

    if (hasEvents && !topic.active) {
      return (
        <Tooltip title="Cannot delete topics associated with a post">
          <div>{button}</div>
        </Tooltip>
      );
    }

    return button;
  };

  const topicItem = topic => [
    topic.name,
    topic.theme.name,
    `${topic.created_by_user.first_name} ${topic.created_by_user.last_name}`,
    topic.created_at
      ? convertToUserTimeZone(topic.created_at, currentUser.time_zone).format('L LT')
      : '-- --',
    <HasAnyPriv privs={['THEMES:EDIT']}>
      <IconButton
        className={classes.editIcon}
        onClick={() => handleOpenEditTopic(topic)}
        disableRipple
      >
        <EditIcon className={classes.svgIcon} />
      </IconButton>
    </HasAnyPriv>,
    handleButtonAction(topic)
  ];

  if (loading || themeLoading) return <AlbLoading />;
  if (queryError || themeQueryError) return `Error! ${queryError}`;
  if (creationError && creationError.graphQLErrors) {
    showToast(
      `Topic creation failed. Error: ${creationError.graphQLErrors.map(({ message }) => message)}`,
      'error'
    );
    creationError.graphQLErrors = null;
  }
  if (updateError && updateError.graphQLErrors) {
    showToast(
      `Topic edit failed. Error: ${updateError.graphQLErrors.map(({ message }) => message)}`,
      'error'
    );
    updateError.graphQLErrors = null;
  }
  if (deletionError && deletionError.graphQLErrors) {
    showToast(
      `Topic deletion failed. Error: ${deletionError.graphQLErrors.map(({ message }) => message)}`,
      'error'
    );
    deletionError.graphQLErrors = null;
  }

  return (
    <Fragment>
      <TabNav tabs={['Active', 'Inactive']}>
        <Box mt={10} mx={10}>
          <MUIDataTable
            data={data.topics.filter(item => item.active).map(topicItem)}
            columns={columns}
            options={options}
          />
        </Box>
        <Box mt={10} mx={10}>
          <MUIDataTable
            data={data.topics.filter(item => !item.active).map(topicItem)}
            columns={columns}
            options={options}
          />
        </Box>
      </TabNav>

      <AlembicModalForm
        saveTitle="Create Topic"
        cancelTitle="Cancel"
        open={openCreateTopic}
        onClose={handleCloseCreateTopic}
        handleConfirm={handleSaveTopic}
        fields={createFields}
      />

      <AlembicModalForm
        saveTitle="Edit Topic"
        cancelTitle="Cancel"
        open={openEditTopic}
        onClose={handleCloseEditTopic}
        handleConfirm={handleEditTopic}
        fields={updateFields}
        currentObject={selectedTopic}
      />

      <AlembicModalConfirm
        isDelete
        isOpen={openDeleteTopic}
        title="Delete Topic"
        body="Are you sure you want to delete this topic?"
        cancelTitle="Cancel"
        confirmTitle="Delete topic"
        handleCancel={handleCloseDeleteTopic}
        handleConfirm={handleDeleteTopic}
      />
    </Fragment>
  );
};

TopicList.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  openCreateTopic: PropTypes.bool.isRequired,
  handleCloseCreateTopic: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.shape(),
  queryError: PropTypes.shape()
};

TopicList.defaultProps = {
  loading: false,
  data: {},
  queryError: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(TopicList);
