import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import AlembicPageHeader from 'components/AlembicPageHeader';
import Box from 'components/Box';
import setPageTitle from 'util/titles';
import { ManageOrganizationPath } from 'util/paths';
import InviteUserModal from './InviteUserModal';
import UserTable from './UserTable';
import InviteTable from './InviteTable';
import TabNav from '../TabNav';

const ManageUsers = () => {
  useEffect(() => {
    setPageTitle('Manage Users');
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const containerId = urlParams.get('id');
  const [inviteDialog, setInviteDialog] = useState(false);
  const [refetchInvite, setRefetchInvite] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  if (containerId === null) {
    return (
      <Grid container justifyContent="center">
        <Typography>No container selected</Typography>
      </Grid>
    );
  }

  return (
    <>
      {inviteDialog && (
        <InviteUserModal
          closeCallback={() => setInviteDialog(false)}
          setRefetchInvite={() => setRefetchInvite(true)}
          onInvite={() => setSelectedTab(1)}
          containerId={containerId}
        />
      )}
      <Box mb={16}>
        <AlembicPageHeader
          backButton
          backRoute={ManageOrganizationPath}
          pageTitle="Manage Users"
          buttonTitle="Invite User"
          buttonOnClick={() => setInviteDialog(!inviteDialog)}
          buttonPrivs={['USERS:CREATE']}
        />
      </Box>
      <TabNav
        tabs={['Current', 'Invited']}
        onChange={(event, value) => setSelectedTab(value)}
        value={selectedTab}
        external
      >
        <UserTable containerId={containerId} />
        <InviteTable
          containerId={containerId}
          refetchInvite={refetchInvite}
          setRefetchInvite={() => setRefetchInvite(false)}
        />
      </TabNav>
    </>
  );
};

export default ManageUsers;
