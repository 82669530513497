/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItemIcon, Collapse } from '@material-ui/core';
import { COINCIDENT_EVENT, COINCIDENT_EVENTS } from 'gql/coincident_event';
import GraphHeader from 'components/PostDrawerView/GraphHeader';
import DetectedEventTableHeader from 'components/DetectedEventsTable/DetectedEventTableHeader';
import DetectedEventTableRow from 'components/DetectedEventsTable/DetectedEventTableRow';
import { getDetectedEventProps } from 'components/DetectedEventsTable/DetectedEventsTable';
import TypeDropdown from 'components/DashboardIntelligenceTable/TypeDropdown';
import {
  TYPE_ORDER_MAP,
  MEDIA_MENTIONS_TYPES,
  MEDIA_RATINGS_TYPES,
  FILTER_TYPES,
  DETECTED_EVENT_TYPE
} from 'util/detectedEvents';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import CorrelationDrawerGraph from './CorrelationDrawerGraph';

const useStyles = makeStyles({
  container: {
    width: '1228px',
    padding: '16px 25px'
  },
  summary: {
    alignItems: 'start',
    display: 'grid',
    gridColumnGap: '100px',
    gridRowGap: '60px',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: '30px'
  },
  graphHeaderDate: {
    flex: 1
  },
  listItem: {
    minWidth: 'unset'
  },
  tableHeaderContainer: {
    marginTop: '30px'
  },
  tableRowContainer: {
    marginTop: '-14px',
    marginBottom: ' -14px',
    boxShadow: '0px 0px 13px rgba(10, 23, 52, 0.1)'
  },
  rowContainer: {
    borderBottom: '1px solid #C4C4C4',

    '&:last-child': {
      borderBottom: 'none'
    }
  },
  empty: {
    fontFamily: 'Poppins'
  }
});

const CorrelationDrawerView = props => {
  const { event } = props;
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCollapsedSummary, setIsCollapsedSummary] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(0); // defaulted to +/- 3 days
  const [detectedEvents, setDetectedEvents] = useState([]);

  const [graphs, setGraphs] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const loadAfter = 25;
  const [lastRow, setLastRow] = useState(loadAfter);

  useEffect(() => {
    if (event?.detectedEvents) {
      // doing the graph filtering through the UI because the event object is passed from way higher up

      let filteredEvents = event.detectedEvents;

      if (selectedTypes.length) {
        // get all detected event types within the filter
        const selectedEventTypes = selectedTypes.flatMap(type =>
          FILTER_TYPES[type].detectedEvent.concat(FILTER_TYPES[type].linktokenEvent)
        );
        // get detected events which are within the selected types
        filteredEvents = event.detectedEvents.filter(({ event_type }) =>
          selectedEventTypes.includes(event_type)
        );
      }

      const eventTypes = filteredEvents.reduce(
        (
          acc,
          { id, event_type: eventType, eventInfo, magnitude, mag_base, secondary_event_label }
        ) => {
          // filtering out all the social events, which all have mag_base set to -1.
          if (mag_base > -1) {
            const difference = magnitude - mag_base;

            let key = eventType;
            // third party media events don't get grouped by event type, but into two separate pie charts for 'mentions' and 'ratings'
            if (MEDIA_MENTIONS_TYPES.includes(eventType)) {
              key = 'MEDIA_MENTIONS';
            }

            if (MEDIA_RATINGS_TYPES.includes(eventType)) {
              key = 'MEDIA_RATINGS';
            }
            let addOn = '';

            if (
              [
                DETECTED_EVENT_TYPE.AP_PURCHASE_BY_PRODUCT_BY_DEVICE,
                DETECTED_EVENT_TYPE.AP_REVENUE_BY_PRODUCT_BY_DEVICE
              ].includes(eventType)
            ) {
              if (secondary_event_label != null) {
                addOn = ` on ${
                  secondary_event_label === 'Desktop' ? 'Mac' : secondary_event_label
                }`;
              }
            }

            if (
              [
                DETECTED_EVENT_TYPE.GO_PURCHASES_BY_PRODUCT,
                DETECTED_EVENT_TYPE.GO_REVENUE_BY_PRODUCT
              ].includes(eventType)
            ) {
              addOn = 'on Android';
            }

            if (acc[key]) {
              acc[key].push([{ id, ...eventInfo, addOn }, difference]);
            } else {
              acc[key] = [[{ id, ...eventInfo, addOn }, difference]];
            }
          }

          return acc;
        },
        {}
      );

      const typeSort = (typeA, typeB) => {
        if (TYPE_ORDER_MAP[typeA[0]] > TYPE_ORDER_MAP[typeB[0]]) {
          return 1;
        }

        if (TYPE_ORDER_MAP[typeA[0]] < TYPE_ORDER_MAP[typeB[0]]) {
          return -1;
        }

        return 0;
      };

      const formattedEventTypes = Object.entries(eventTypes)
        .sort(typeSort)
        .map(([title, values]) => {
          const labels = [];
          let bar = false;

          const sortedValues = values.sort(([, a], [, b]) => b - a);
          const topTen = sortedValues.slice(0, 10);
          const other = sortedValues
            .slice(10, sortedValues.length)
            .reduce(([info, total], [, difference]) => [info, total + difference], [
              { id: 'Other', postBody: 'Other', sourceName: 'Other' },
              0
            ]);

          const data = [...topTen, ...(sortedValues.length > 10 ? [other] : [])].map(
            ([label, difference]) => {
              labels.push(label);
              // if negative value is encountered, graph displayed becomes a bar
              bar = bar || difference < 0;
              return difference;
            }
          );

          return { key: title, title, data, labels, bar };
        });

      setGraphs(formattedEventTypes);
    }
  }, [event, selectedTypes]);

  const scrollObserver = (entries, observer) => {
    entries.forEach(en => {
      if (en.intersectionRatio > 0) {
        setLastRow(prevState => prevState + loadAfter);
        observer.unobserve(en.target);
      }
    });
  };

  const bottomBoundaryRef = useCallback(node => {
    if (node !== null) {
      const observer = new IntersectionObserver(scrollObserver);
      observer.observe(node);
    }
  }, []);

  const dateRangeOptions = [
    {
      label: '+/- 3D',
      range: {
        startDate: moment
          .utc(event.coinc_start_date)
          .clone()
          .subtract(3, 'd'),
        endDate: moment
          .utc(event.coinc_end_date)
          .clone()
          .add(3, 'd')
          .endOf('day')
      }
    },
    {
      label: '+/- 7D',
      range: {
        startDate: moment
          .utc(event.coinc_start_date)
          .clone()
          .subtract(7, 'd'),
        endDate: moment
          .utc(event.coinc_end_date)
          .clone()
          .add(7, 'd')
          .endOf('day')
      }
    },
    {
      label: '+/- 30D',
      range: {
        startDate: moment
          .utc(event.coinc_start_date)
          .clone()
          .subtract(30, 'd'),
        endDate: moment
          .utc(event.coinc_end_date)
          .clone()
          .add(30, 'd')
          .endOf('day')
      }
    }
  ];

  const [getCoincidentEvent, { data: coincidentEventData }] = useLazyQuery(COINCIDENT_EVENT, {
    fetchPolicy: 'network-only'
  });

  const [getCoincidentEvents, { data: coincidentEventsData }] = useLazyQuery(COINCIDENT_EVENTS, {
    fetchPolicy: 'network-only'
  });

  // if user changes +/- 3, 7, 30 days, then update graph
  useEffect(() => {
    if (event?.id != null) {
      getCoincidentEvent({
        variables: {
          id: event.id,
          startDateGraph: dateRangeOptions[selectedDateRange].range.startDate,
          endDateGraph: dateRangeOptions[selectedDateRange].range.endDate,
          graph: true,
          typeFilter: selectedTypes
        }
      });
    } else {
      getCoincidentEvents({
        variables: {
          startDate: event.coinc_start_date,
          endDate: event.coinc_end_date,
          startDateGraph: dateRangeOptions[selectedDateRange].range.startDate,
          endDateGraph: dateRangeOptions[selectedDateRange].range.endDate,
          graph: true,
          typeFilter: selectedTypes
        }
      });
    }
  }, [selectedDateRange, selectedTypes]);

  const formatDetectedEvents = detectedEventsParam => {
    const groupedEvents = detectedEventsParam.reduce((acc, currentValue) => {
      const eventType = currentValue.event_type;

      if (acc[eventType]) {
        acc[eventType].push(currentValue);
      } else {
        acc[eventType] = [currentValue];
      }

      return acc;
    }, {});

    const typeSort = (typeA, typeB) => {
      if (TYPE_ORDER_MAP[typeA[0]] > TYPE_ORDER_MAP[typeB[0]]) {
        return 1;
      }

      if (TYPE_ORDER_MAP[typeA[0]] < TYPE_ORDER_MAP[typeB[0]]) {
        return -1;
      }

      return 0;
    };

    const tempDetectedEvents = [];

    Object.entries(groupedEvents)
      .sort(typeSort)
      .forEach(([, values]) => {
        const sortedValues = values.sort((eventA, eventB) => {
          // const propsA = getDetectedEventProps(eventA);
          // const propsB = getDetectedEventProps(eventB);
          const differenceA = eventA.mag_base > -1 ? eventA.magnitude - eventA.mag_base : null;
          const differenceB = eventB.mag_base > -1 ? eventB.magnitude - eventB.mag_base : null;

          if (differenceA == null && differenceB == null) {
            return 0;
          }

          if (differenceA == null && differenceB != null) {
            return 1;
          }

          if (differenceA != null && differenceB == null) {
            return -1;
          }

          if (differenceA > differenceB) {
            return -1;
          }

          if (differenceA < differenceB) {
            return 1;
          }

          return 0;
        });

        sortedValues.forEach(sortedEvent => {
          tempDetectedEvents.push(sortedEvent);
        });
      });

    return tempDetectedEvents;
  };

  useEffect(() => {
    let tempDetectedEvents = [];

    if (coincidentEventData?.coincidentEvent?.detectedEvents?.length > 0) {
      tempDetectedEvents = formatDetectedEvents(
        coincidentEventData?.coincidentEvent?.detectedEvents
      );
    }

    if (coincidentEventsData?.coincidentEvents?.[0]?.detectedEvents?.length > 0) {
      tempDetectedEvents = formatDetectedEvents(
        coincidentEventsData?.coincidentEvents?.[0]?.detectedEvents
      );
    }

    setDetectedEvents(tempDetectedEvents);
  }, [coincidentEventData, coincidentEventsData]);

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container className={classes.graphHeaderDate}>
        <Grid item onClick={() => setIsCollapsedSummary(!isCollapsedSummary)}>
          <ListItemIcon classes={{ root: classes.listItem }}>
            {isCollapsedSummary ? <Right /> : <Down />}
          </ListItemIcon>
        </Grid>
        <GraphHeader
          label="Summary by Δ (Difference of Peak Above Expected)"
          isCollapsed={isCollapsedSummary}
          // TODO: tooltipText=""
          dateRangeOptions={[]}
          selectedDateRange={0}
          setSelectedDateRange={() => {}}
          hasGraph
        />
      </Grid>
      <Collapse in={!isCollapsedSummary}>
        <Grid className={classes.summary} container>
          {graphs.map((graph, i) => (
            <CorrelationDrawerGraph {...graph} index={i} />
          ))}
        </Grid>
      </Collapse>
      <Grid container className={classes.graphHeaderDate}>
        <Grid item onClick={() => setIsCollapsed(!isCollapsed)}>
          <ListItemIcon classes={{ root: classes.listItem }}>
            {isCollapsed ? <Right /> : <Down />}
          </ListItemIcon>
        </Grid>
        <GraphHeader
          label="Correlations"
          isCollapsed={isCollapsed}
          // TODO: tooltipText=""
          dateRangeOptions={dateRangeOptions}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          hasGraph
        >
          <TypeDropdown selectedTypes={selectedTypes} setSelectedTypes={setSelectedTypes} />
        </GraphHeader>
      </Grid>
      <Grid item>
        <Collapse in={!isCollapsed}>
          <div className={classes.tableHeaderContainer}>
            <DetectedEventTableHeader hasIndex />
          </div>
          {detectedEvents?.length === 0 && (
            <div className={classes.empty}> There are no detected events</div>
          )}
          <div className={classes.tableRowContainer}>
            {detectedEvents?.slice(0, lastRow).map((newEvent, index) => {
              const newProps = getDetectedEventProps(newEvent);

              return (
                <div key={newEvent.id} className={classes.rowContainer}>
                  <DetectedEventTableRow
                    index={index + 1}
                    dateRange={dateRangeOptions[selectedDateRange]}
                    selectedDate={event.date}
                    {...newProps}
                    onClick={newEvent.onClick ? null : null}
                    graph
                    popout
                    ref={index + 1 === lastRow ? bottomBoundaryRef : null}
                  />
                </div>
              );
            })}
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
};

CorrelationDrawerView.propTypes = {
  event: PropTypes.shape().isRequired
};

export default CorrelationDrawerView;
