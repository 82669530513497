import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import Box from 'components/Box';
import SocialLogo from 'components/SocialLogo';
import AlembicAvatar from 'components/AlembicAvatar';
import AssetThumbnail from 'components/AssetThumbnail';
import { trimPreviewText } from 'util/social';
import { DefaultUserAvatar } from 'util/assets';
import { getPageUrl, openExternalPost } from 'util/externalLinks';
import getSimpleSocialType from 'util/getSimpleSocialType';
import colors from 'util/colors';
import { showToast } from 'contexts/ToastContext';
import { formatMillisecondsDisplay } from 'util/time';

const linkStyle = {
  overflow: 'hidden',
  display: '-webkit-box',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1
};

export const customAccountRender = account => {
  const openPage = () => {
    const externalUrl = getPageUrl({
      type: account.linkTokenType,
      remote_id: account.linkTokenRemoteId,
      remote_name: account.linkTokenRemoteName
    });

    if (externalUrl) {
      window.open(externalUrl);
    } else {
      showToast('Unable to open page', 'error');
    }
  };

  return (
    <div
      tabIndex="0"
      role="button"
      onKeyPress={e => {
        if (e.key === 'Enter') {
          openPage();
        }
      }}
      onClick={() => {
        openPage();
      }}
      style={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <SocialLogo type={getSimpleSocialType(account.campaignEventType)} width={38} height={38} />
    </div>
  );
};

export const customHandleRender = handle => {
  const openPost = () => {
    if (handle?.publishedUrl) {
      openExternalPost(handle.publishedUrl);
    } else {
      showToast('Unable to open post', 'error');
    }
  };

  return (
    <div
      tabIndex="0"
      role="button"
      onKeyPress={e => {
        if (e.key === 'Enter') {
          openPost();
        }
      }}
      onClick={() => {
        openPost();
      }}
      style={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <AlembicAvatar avatarSrc={handle.avatarUrl} width={38} height={38} />
    </div>
  );
};

const TablePostColumn = props => {
  const { body, bodyPreview, assets, publishedUrl, thumbnailUrl } = props;
  const [isPreview, setIsPreview] = useState(true);

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
      <Grid>
        <AssetThumbnail
          thumbnailUrl={thumbnailUrl}
          assets={assets}
          width={60}
          height={60}
          rounded
          onClickEvent={() => {
            if (publishedUrl) {
              openExternalPost(publishedUrl);
            } else {
              showToast('Unable to open post', 'error');
            }
          }}
        />
      </Grid>
      <Typography
        style={{
          fontSize: '13px',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          marginLeft: '10px',
          ...(isPreview && { WebkitLineClamp: 1 })
        }}
        onClick={() => {
          setIsPreview(!isPreview);
        }}
      >
        {isPreview ? bodyPreview : body}
      </Typography>
    </Box>
  );
};

TablePostColumn.propTypes = {
  body: PropTypes.string,
  bodyPreview: PropTypes.string,
  assets: PropTypes.arrayOf(PropTypes.shape()),
  publishedUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string
};

TablePostColumn.defaultProps = {
  body: null,
  bodyPreview: null,
  assets: null,
  publishedUrl: null,
  thumbnailUrl: null
};

export const customPostRender = post => {
  let body = null;
  let bodyPreview = null;
  let assets = null;

  if (post?.body) {
    ({ body } = post);

    if (post.body.length > 175) {
      bodyPreview = trimPreviewText(body, 175);
    } else {
      bodyPreview = body;
    }
  }

  if (post?.assets) {
    ({ assets } = post);
  }
  return (
    <TablePostColumn
      body={body}
      bodyPreview={bodyPreview}
      assets={assets}
      publishedUrl={post?.publishedUrl}
      thumbnailUrl={post?.thumbnailUrl}
    />
  );
};

export const customValueRender = (value, tableMeta) => {
  const { columnData } = tableMeta;

  let formattedValue = Math.round(value).toLocaleString();

  if (columnData?.name === 'time_watched') {
    formattedValue = formatMillisecondsDisplay(value);
  }

  return <div style={{ textAlign: 'right' }}>{formattedValue}</div>;
};

export const customAgeValueRender = value => (
  <div style={{ textAlign: 'center' }}>{`${Math.round(value).toLocaleString()}d`}</div>
);

/**
 * Unpack a shortlink object and provide a object with the link and code
 * @param {Object} shortlink the shortlink object with domain and code
 * @returns {JSX.Element}
 */
export const customShortlinkRender = shortlink => {
  if (!shortlink) {
    return null;
  }

  const proto = shortlink.https_enabled ? 'https://' : 'http://';
  const code = shortlink.custom_short_link ? shortlink.custom_short_link : shortlink.short_link;
  const shortlinkUrl = `${proto}${shortlink.domain.domain}/${code}+`;

  return (
    // This will open the "debug" page for the shortlink
    <div style={linkStyle}>
      <a href={`${shortlinkUrl}`} rel="noreferrer" target="_blank">
        {code}
      </a>
    </div>
  );
};

export const customLinkRender = text => {
  return (
    <Tooltip title={text}>
      <div style={linkStyle}>
        <a href={`${text}`} rel="noreferrer" target="_blank">
          {text}
        </a>
      </div>
    </Tooltip>
  );
};

export const customChangeRender = text => (
  <div style={{ ...(text && { color: text > 0 ? '#00A84F' : colors.red }) }}>
    {`${text > 0 ? '+' : ''}${text}%`}
  </div>
);

export const customOwnerRender = owner => {
  // eslint-disable-next-line camelcase
  const finalAvatar = owner?.avatar_url || DefaultUserAvatar;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img alt="" src={finalAvatar} style={{ width: 40, height: 40, borderRadius: '50%' }} />
      <div style={{ marginLeft: '10px' }}>
        {`${owner.first_name ?? ''} ${owner.last_name ?? ''}`}
      </div>
    </div>
  );
};
