/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import theme from 'theme';
import { Typography, TableCell } from '@material-ui/core';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import AssetThumbnail from 'components/AssetThumbnail';
import PostContent from 'components/AnalyticsInsights/AnalyticsPostContent';
import Box from 'components/Box';
import getValueAbbreviation from 'util/getValueAbbreviation';
import colors from 'util/colors';
import { convertToUserTimeZone, getDatesForAnalytics } from 'util/date';
import { socialIconForChannel } from 'util/social';
import ResurgenceLine from '../Graphs/ResurgenceLine';

const useStyles = makeStyles({
  metric: {
    fontSize: '24px',
    fontWeight: 600,
    color: colors.purple,
    textAlign: 'center'
  }
});

/**
 * @method
 * @summary This component renders the table in @see MultiPostResurgence
 * @name ResurgenceByPost
 * @param {Object} props - React props passed to this component
 * @param {Object} props.linktokenResurgeImpression - The object containing the multi post resurgences
 * @param {string} props.currentUser - The current user object
 * @return {Object} - React JSX
 */
const ResurgenceByPost = props => {
  const { linktokenResurgeImpression, currentUser } = props;
  const { linktoken_id, begin_date, end_date } = linktokenResurgeImpression;
  const drawerContext = useContext(PostDrawerViewContext);
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [fullPreviewEvent, toggleFullPreviewEvent] = useState([false, null]);

  const [rowLookup, setRowLookup] = useState({});

  const dates = getDatesForAnalytics(
    moment(begin_date).subtract(3, 'days'),
    moment(end_date).add(3, 'days')
  );

  useEffect(() => {
    if (linktokenResurgeImpression?.postresurgeimpressions?.length) {
      const { postresurgeimpressions } = linktokenResurgeImpression;

      const lookup = {};

      const sortedResurgences = postresurgeimpressions.sort((a, b) => {
        const { campaign_event: eventA } = a;
        const { campaign_event: eventB } = b;

        return new Date(eventB.completed_at) - new Date(eventA.completed_at);
      });

      const formattedTableData = sortedResurgences.map((resurgence, i) => {
        const {
          peak_count_diff: bonus_impressions,
          resurgence_timeseries,
          campaign_event
        } = resurgence;
        const {
          type,
          post_id,
          assets,
          thumbnail_url,
          completed_at: date,
          event_title: title,
          event_body: content,
          lifetime_views,
          lifetime_engagement,
          valuation
        } = campaign_event;

        lookup[i] = post_id;

        return {
          id: i,
          platform: type,
          event: { assets, thumbnail_url },
          body: { date, title, content },
          resurgence_timeseries,
          bonus_impressions,
          lifetime_views,
          lifetime_engagement,
          valuation
        };
      });

      setRowLookup(lookup);
      setTableData(formattedTableData);
    }
  }, [linktokenResurgeImpression]);

  const tableTheme = createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiPaper: {
        root: { backgroundColor: '#F0F0F0', boxShadow: 'none !important' }
      },
      MUIDataTable: {
        responsiveBase: { backgroundColor: '#F0F0F0' }
      },
      MuiTableCell: {
        head: { backgroundColor: '#F0F0F0 !important' },
        footer: { borderBottom: 'none' }
      },
      MUIDataTableHeadRow: {
        root: {
          '& th': {
            borderBottom: 'none'
          },
          '& th:nth-child(1)': {
            padding: '24px',
            borderBottom: 'none'
          }
        }
      },
      MUIDataTableBodyRow: {
        root: {
          backgroundColor: 'white',
          position: 'relative',
          '& td:nth-child(-n+1)': {
            backgroundColor: `${colors.navy} !important`,
            padding: '0px',
            color: 'white'
          },
          '& td:nth-child(n+3):nth-child(-n+3)': {
            textAlign: 'center',
            padding: '0px'
          },
          '& td:nth-child(n+4):nth-child(-n+4)': {
            textAlign: 'left'
          },
          '& td:nth-child(n+6):nth-child(-n+6), & td:nth-child(n+8):nth-child(-n+8)': {
            padding: '20px',
            backgroundColor: colors.wildSand,

            '&:hover': {
              backgroundColor: colors.athensGray
            }
          },
          '& td:only-child': {
            backgroundColor: 'white !important',
            padding: '16px',
            color: 'unset'
          },
          '&:hover': {
            '& td:not(:first-child)': {
              backgroundColor: colors.athensGray
            }
          }
        }
      },
      MUIDataTableFooter: {
        root: {
          backgroundColor: '#F0F0F0'
        }
      }
    }
  });

  const customHeadRender = head => (
    <TableCell key={head.index} style={{ backgroundColor: colors.gray95, color: 'black' }}>
      <Typography style={{ fontSize: '12px', fontWeight: 500 }}>{head.label}</Typography>
    </TableCell>
  );

  const customCenterHeadRender = head => (
    <TableCell key={head.index} style={{ backgroundColor: colors.gray95, color: 'black' }}>
      <Typography align="center" style={{ fontSize: '12px', fontWeight: 500 }}>
        {head.label}
      </Typography>
    </TableCell>
  );

  const customOrderRender = order => (
    <Typography align="center" style={{ fontSize: '18px', fontWeight: 600 }}>
      {order + 1}
    </Typography>
  );

  const customChannelRender = channel => (
    <div style={{ textAlign: 'center' }}>
      <img alt="channel" width={25} height={25} src={socialIconForChannel(channel)} />
    </div>
  );

  const customImageRender = event => (
    <AssetThumbnail
      thumbnailUrl={event.thumbnail_url}
      assets={event.assets}
      width={193}
      height={193}
    />
  );

  const customContentRender = (body, { rowData }) => {
    const [id] = rowData;
    const { date, title, content } = body;
    const timestamp = convertToUserTimeZone(date, currentUser.time_zone).format('M/D/YY LT');
    const isTextInPreview = !!(fullPreviewEvent[0] === true && fullPreviewEvent[1] === id);

    return (
      <div style={{ maxWidth: '300px' }}>
        <PostContent
          title={title}
          timestamp={timestamp}
          content={content}
          eventId={id}
          preview={isTextInPreview}
          togglePreview={toggleFullPreviewEvent}
        />
      </div>
    );
  };

  const customSparklineRender = timeseries => <ResurgenceLine data={timeseries} />;

  const customMetricRender = metric => (
    <Typography className={classes.metric}>{getValueAbbreviation(metric) || '-'}</Typography>
  );

  const customValuationRender = valuation => (
    <Typography className={classes.metric}>
      {getValueAbbreviation(valuation) ? `$${getValueAbbreviation(valuation)}` : '-'}
    </Typography>
  );

  const tableColumns = [
    // used for post drawer lookup reference
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'id',
      label: '#',
      options: { customHeadRender: customCenterHeadRender, customBodyRender: customOrderRender }
    },
    {
      name: 'platform',
      label: ' ',
      options: { customHeadRender, customBodyRender: customChannelRender }
    },
    {
      name: 'event',
      label: 'POST',
      options: { customHeadRender, customBodyRender: customImageRender }
    },
    {
      name: 'body',
      label: ' ',
      options: { customBodyRender: customContentRender }
    },
    {
      name: 'resurgence_timeseries',
      label: 'RESURGENCE',
      options: { customHeadRender, customBodyRender: customSparklineRender }
    },
    {
      name: 'lifetime_views',
      label: 'VIEWS',
      options: { customHeadRender: customCenterHeadRender, customBodyRender: customMetricRender }
    },
    {
      name: 'bonus_impressions',
      label: 'BONUS IMP.',
      options: { customHeadRender: customCenterHeadRender, customBodyRender: customMetricRender }
    },
    {
      name: 'lifetime_engagement',
      label: 'ENG.',
      options: { customHeadRender: customCenterHeadRender, customBodyRender: customMetricRender }
    },
    {
      name: 'valuation',
      label: 'VALUATION',
      options: { customHeadRender: customCenterHeadRender, customBodyRender: customValuationRender }
    }
  ];

  const onRowClick = ([id]) =>
    drawerContext.toggleDrawer(
      true,
      null,
      rowLookup[id],
      linktoken_id,
      dates.start,
      dates.end,
      true,
      begin_date,
      end_date
    );

  const tableOptions = {
    selectableRows: 'none',
    rowHover: false,
    fixedHeader: false,
    filter: false,
    sort: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard',
    onRowClick
  };

  return (
    <Box mb={50}>
      <Typography color="primary" variant="h1" style={{ fontWeight: '400' }}>
        Resurgence By Post
      </Typography>
      <MuiThemeProvider theme={tableTheme}>
        <MUIDataTable data={tableData} columns={tableColumns} options={tableOptions} />
      </MuiThemeProvider>
    </Box>
  );
};

ResurgenceByPost.propTypes = {
  linktokenResurgeImpression: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(ResurgenceByPost);
