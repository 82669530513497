/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useQuery, useMutation } from '@apollo/client';
import { RECREATE_COST_REPORT_ARTICLES } from 'gql/recreateCostReport';
import { PROXY_SA_URL_SUBMIT } from 'gql/articleSearch';
import ArticleDrawerViewContext from 'contexts/ArticleDrawerViewContext';
import { showToast } from 'contexts/ToastContext';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbTable from 'components/AlbTable/AlbTable';
import IgnoreArticleFromCTRModal from 'components/AlembicModalConfirm/IgnoreArticleFromCTRModal';
import SomethingMissingModal from 'components/AlembicModalConfirm/SomethingMissingModal';
import Box from 'components/Box';
import { convertToUserTimeZone } from 'util/date';
import { formatCurrencyRound } from 'util/formatCurrency';
import colors from 'util/colors';
import isValidUrl from 'util/isValidUrl';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  article: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    height: '58px',
    width: '58px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    marginRight: '9px'
  },
  articleTitle: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.navy,
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  domain: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.navy
  },
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  exclude: {
    backgroundColor: 'transparent',
    padding: '8px 18px',

    '&:hover': {
      backgroundColor: colors.red,
      borderColor: colors.red,
      color: 'white'
    }
  },
  include: {
    backgroundColor: colors.navy,
    padding: '8px 18px',

    '&:hover': {
      backgroundColor: colors.navy
    }
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  missingButton: {
    margin: '10px 22px',
    border: '1px solid #0D1A3A',
    padding: '0px 9px 0px 9px'
  },
  missingButtonLabel: {
    whiteSpace: 'nowrap',
    color: '#0D1A3A',
    margin: '8px 18px 8px 18px'
  },
  somethingMissingText: {
    fontSize: '13px',
    color: '#585858',
    weight: 500
  },
  placeholderImg: {
    marginTop: '23px'
  }
});

/**
 * Table for 3rd Party Media in the CTR report
 * @name MediaValuationEarned
 * @param {Object} props - React props passed to this component
 * @param {Object} props.currentUser - An object containing the current user data from Redux
 * @param {string} props.recreateCostReportId - A string value for the CTR id
 * @param {String} props.valuationType - The type of 'cpm' or 'cpa' calculation
 * @param {Object} props.dates - An object containing start and end dates
 * @param {String} props.filter - A string used in the query to filter out excluded articles
 * @return {Object} - React JSX
 */
const MediaValuationEarned = props => {
  const { currentUser, recreateCostReportId, valuationType, dates, filter, refetchGraph } = props;

  const drawerContext = useContext(ArticleDrawerViewContext);
  const classes = useStyles();

  const isExcludedTab = filter === 'ONLY';

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['total_share_count', 'desc']);

  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedArticleTitle, setSelectedArticleTitle] = useState('');
  const [openIgnoreModal, toggleOpenIgnoreModal] = useState(false);
  const [somethingMissingModal, toggleSomethingMissingModal] = useState(false);
  const [url, updateUrl] = useState('');

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  const { loading, error, data, refetch: refetchTable } = useQuery(RECREATE_COST_REPORT_ARTICLES, {
    variables: {
      id: recreateCostReportId,
      count: rowsPerPage,
      after: page * rowsPerPage,
      orderBy: sortOrder,
      valuationType,
      ignoredArticleFilter: filter,
      ...dates
    },
    fetchPolicy: 'no-cache'
  });

  const [proxySAUrlSubmit, { data: urlSubmitData, error: urlSubmitError }] = useMutation(
    PROXY_SA_URL_SUBMIT
  );

  const submitUrlQuery = urlInput => {
    const validUrl = isValidUrl(urlInput);

    if (validUrl) {
      proxySAUrlSubmit({ variables: { url: urlInput } });
    } else {
      showToast('Please enter a valid url and try again.', 'error');
    }
  };

  useEffect(() => {
    if (urlSubmitData) {
      showToast('Thank you for your submission.', 'success');
      toggleSomethingMissingModal(false);
    }

    if (urlSubmitError) {
      showToast('Error occurred. Please try again.', 'error');
    }
  }, [urlSubmitData, urlSubmitError]);

  useEffect(() => {
    if (data?.recreateCostReport?.paginatedArticles?.rows) {
      setTableData(data.recreateCostReport.paginatedArticles.rows);
      setCount(data.recreateCostReport.paginatedArticles.count);
    }
  }, [data]);

  const customCenterRender = content => {
    const text = typeof content === 'number' ? Math.round(content).toLocaleString() : content;

    return <div align="center">{text}</div>;
  };

  const customArticleRender = (title, { rowData }) => {
    const [id, , imageUrl] = rowData;

    return (
      <Box className={classes.article}>
        <div
          aria-hidden="true"
          className={classes.image}
          style={{ backgroundImage: `url('${imageUrl}')` }}
          onClick={() => drawerContext.toggleDrawer(true, id)}
        />
        <Button
          style={{ backgroundColor: 'transparent' }}
          onClick={() => drawerContext.toggleDrawer(true, id)}
        >
          <Typography className={classes.articleTitle}>{title}</Typography>
        </Button>
      </Box>
    );
  };

  const customDateRender = date => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {`${convertToUserTimeZone(date, currentUser.time_zone).format(
        'M/DD/YY'
      )} @ ${convertToUserTimeZone(date, currentUser.time_zone).format('h:mma')}`}
    </div>
  );

  const customValuationRender = valuation => (
    <div align="right">
      {valuation ? `${valuation < 0 ? '-' : ''}$${formatCurrencyRound(Math.abs(valuation))}` : '-'}
    </div>
  );

  const centeredColumnOptions = {
    customBodyRender: customCenterRender,
    setCellHeaderProps: () => ({ className: classes.centeredHeader })
  };

  const handleOpenIgnoreModal = (id, title) => {
    setSelectedArticle(id.toString());
    setSelectedArticleTitle(title);
    toggleOpenIgnoreModal(true);
  };

  const customExcludeRender = (_, { rowData }) => {
    const [id, , , title] = rowData;

    return (
      <div align="right">
        <Button
          className={`${!isExcludedTab ? classes.exclude : classes.include}`}
          variant={isExcludedTab ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleOpenIgnoreModal(id, title)}
        >
          {`${!isExcludedTab ? 'Exclude' : 'Include'}`}
        </Button>
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'url',
      options: { display: false }
    },
    {
      name: 'thumbnail',
      options: { display: false }
    },
    {
      name: 'title',
      label: 'POST',
      options: { sort: false, customBodyRender: customArticleRender }
    },
    {
      name: 'published_date',
      label: 'DATE / TIME',
      options: { customBodyRender: customDateRender }
    },
    {
      name: 'total_share_count',
      label: 'SHARES',
      options: { ...centeredColumnOptions }
    },
    {
      name: 'valuation',
      label: 'VALUE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false,
        customBodyRender: customValuationRender
      }
    },
    {
      name: '',
      label: 'EXCLUDE',
      options: {
        display: !isExcludedTab,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false,
        customBodyRender: customExcludeRender
      }
    },
    {
      name: '',
      label: 'INCLUDE',
      options: {
        display: isExcludedTab,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false,
        customBodyRender: customExcludeRender
      }
    }
  ];

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    textLabels: {
      body: {
        noMatch: 'No articles found'
      }
    }
  };

  return (
    <div className={classes.container}>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <BoxHeader tabs="Media Valuation" breakdown />
        <Button
          variant="outlined"
          onClick={() => toggleSomethingMissingModal(true)}
          classes={{ outlined: classes.missingButton, label: classes.missingButtonLabel }}
        >
          Something Missing?
        </Button>
      </Box>
      {!!error && <AlbError error={error} />}
      {loading && (
        <Box className={classes.loading}>
          <AlbLoading />
        </Box>
      )}
      {!error && (
        <Box style={{ opacity: loading ? 0.5 : 1 }}>
          <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />
        </Box>
      )}
      <IgnoreArticleFromCTRModal
        isModalOpen={openIgnoreModal}
        onChange={e => {
          toggleOpenIgnoreModal(e.isModalOpen);
          refetchTable();
          refetchGraph();
        }}
        articleId={selectedArticle}
        articleTitle={selectedArticleTitle}
        reportId={recreateCostReportId}
        exclude={!isExcludedTab}
      />
      {somethingMissingModal && (
        <SomethingMissingModal
          isModalOpen={somethingMissingModal}
          toggleModal={toggleSomethingMissingModal}
          onConfirm={() => submitUrlQuery(url)}
          subtitle="Please enter the URL of the missing article below."
          fullLengthBody
        >
          <ValidatorForm onSubmit={() => submitUrlQuery(url)}>
            <TextValidator
              autoFocus
              fullWidth
              variant="outlined"
              value={url}
              validators={['required']}
              errorMessages={['* Required']}
              placeholder="Enter Article URL"
              onChange={e => updateUrl(e.target.value)}
              style={{ marginTop: '20px', marginBottom: '8px' }}
            />
          </ValidatorForm>
        </SomethingMissingModal>
      )}
    </div>
  );
};

MediaValuationEarned.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  recreateCostReportId: PropTypes.string.isRequired,
  valuationType: PropTypes.string,
  dates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }).isRequired,
  filter: PropTypes.string.isRequired,
  refetchGraph: PropTypes.func.isRequired
};

MediaValuationEarned.defaultProps = {
  valuationType: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(MediaValuationEarned);
