import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UNARCHIVE_BROADCAST_SEARCH } from 'gql/broadcastSearch';
import { UNARCHIVE_PODCAST_SEARCH } from 'gql/podcastSearch';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';
import { BROADCAST, PODCAST } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';

/** Unarchives a 3rd party media report.
 * @param {Boolean} isModalOpen - controls the state of the modal
 * @param {Function} onChange - callback function to toggle modal state and pass back search data.
 * @param {String} searchId - id of report to unarchive.
 * @param {String} searchName - name of report to display in modal
 * @param {String} mediaType - either podcast or broadcast/radio report which determines type of mutation to use.
 * @returns - React JSX
 */

const UnarchiveMediaSearchModal = props => {
  const { isModalOpen, onChange, searchId, searchName, mediaType } = props;

  const [unarchiveBroadcastSearch, { error: broadcastError, data: broadcastData }] = useMutation(
    UNARCHIVE_BROADCAST_SEARCH
  );
  const [unarchivePodcastSearch, { error: podcastError, data: podcastData }] = useMutation(
    UNARCHIVE_PODCAST_SEARCH
  );

  useEffect(() => {
    if (broadcastData?.enableBroadcastSearch) {
      showToast(`Report unarchived`, 'success');
      onChange({
        isModalOpen: false,
        newMediaSearchData: broadcastData.enableBroadcastSearch
      });
    }

    if (podcastData?.enablePodcastSearch) {
      showToast('Report unarchived', 'success');
      onChange({
        isModalOpen: false,
        newMediaSearchData: podcastData.enablePodcastSearch
      });
    }
  }, [broadcastData, podcastData]);

  useEffect(() => {
    if (broadcastError || podcastError) {
      onChange({
        isModalOpen: false
      });
    }
  }, [broadcastError, podcastError]);

  const unarchiveSearch = () => {
    if (mediaType === BROADCAST) {
      unarchiveBroadcastSearch({ variables: { id: searchId } });
    }

    if (mediaType === PODCAST) {
      unarchivePodcastSearch({ variables: { id: searchId } });
    }
  };

  return (
    <>
      {isModalOpen && broadcastError && <AlbError toast error={broadcastError} />}
      {isModalOpen && podcastError && <AlbError toast error={podcastError} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Unarchive Report"
        body={
          <>
            Are you sure you want to unarchive the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{searchName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Unarchive"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={unarchiveSearch}
      />
    </>
  );
};

UnarchiveMediaSearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  searchId: PropTypes.string,
  searchName: PropTypes.string,
  mediaType: PropTypes.string.isRequired
};

UnarchiveMediaSearchModal.defaultProps = {
  searchId: null,
  searchName: null
};

export default UnarchiveMediaSearchModal;
