import React from 'react';
import PropTypes from 'prop-types';
import AlembicPageHeader from 'components/AlembicPageHeader';
import { determineAnalyticsBackRoute } from 'util/routesHelpers';
import ResurgenceTable from './Tables/ResurgenceTable';

/**
 * @method
 * @summary This component renders the @see ResurgenceTable
 * @name AnalyticsResurgence
 * @return {Object} - React JSX
 */
const AnalyticsResurgence = ({ location }) => {
  return (
    <>
      <AlembicPageHeader
        pageTitle="Resurgence"
        backButton
        backRoute={determineAnalyticsBackRoute(location, 'social')}
      />
      <ResurgenceTable />
    </>
  );
};

AnalyticsResurgence.propTypes = {
  location: PropTypes.shape().isRequired
};

export default AnalyticsResurgence;
