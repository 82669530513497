import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox, TextField, Button, IconButton, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SettingsIcon as SETTINGS_ICON } from 'util/assets';
import { makeStyles } from '@material-ui/core/styles';
import { Edit as EditIcon } from '@material-ui/icons';
import { AlbTooltip } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';
import { showToast } from 'contexts/ToastContext';
import { getIconSrc, TYPE, BUTTON_TEXT } from './util';

const styles = makeStyles({
  root: {
    width: '318px',
    height: '41px'
  },
  clickableIcon: {
    '&:hover': {
      cursor: 'pointer'
    },
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  validateIcon: {
    height: '13px',
    margin: '10px'
  },
  createButton: {
    width: '85%',
    color: '#ffffff',
    margin: '10px',
    background: '#979797'
  },
  report: {
    fontWeight: 500,
    fontStyle: 'Poppins',
    fontSize: '13px',
    color: 'black'
  },
  group: {
    fontWeight: 700,
    color: '#32327D',
    fontStyle: 'Poppins',
    fontSize: '13px'
  },
  iconButton: {
    color: '#686868',
    margin: '5px'
  },
  option: {
    '&:active': {
      backgroundColor: 'transparent'
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent'
    }
  },
  inputRoot: {
    height: '100%'
  },
  lowerButtons: {
    position: 'sticky',
    bottom: 0,
    background: '#ffffff'
  },
  listbox: {
    padding: 0,
    maxHeight: '660px'
  },
  clearIndicatorOverride: {
    padding: '4px',
    visibility: 'visible',
    marginRight: '-2px'
  }
});

const DropdownActionButton = ({
  updateGroup,
  createGroup,
  selectedGroup,
  reportsDiverged,
  selectedReportIds,
  isThirdPartyAccessAllowed
}) => {
  const classes = styles();

  // Updating a group if the report selection changes and a group is selected, otherwise creating a new group.
  const update = !!(reportsDiverged && selectedGroup);

  const buttonText = update ? BUTTON_TEXT.UPDATE : BUTTON_TEXT.CREATE;

  // disable button if we are creating a new group, but haven't selected more than 2 reports,
  // OR if we are updating a group, and the reports haven't diverged.
  let disabled = false;

  if ((selectedReportIds.length < 2 && !selectedGroup) || (!reportsDiverged && selectedGroup)) {
    disabled = true;
  }

  if (!isThirdPartyAccessAllowed) {
    disabled = true;
  }

  const onClick = update ? () => updateGroup(selectedGroup) : createGroup;

  return (
    <Button className={classes.createButton} disabled={disabled} onClick={onClick}>
      {buttonText}
    </Button>
  );
};

DropdownActionButton.propTypes = {
  updateGroup: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  selectedGroup: PropTypes.shape(),
  reportsDiverged: PropTypes.bool.isRequired,
  selectedReportIds: PropTypes.arrayOf(PropTypes.string),
  isThirdPartyAccessAllowed: PropTypes.bool.isRequired
};

DropdownActionButton.defaultProps = {
  selectedGroup: null,
  selectedReportIds: null
};

/**
 * @method
 * @summary This component renders the reports dropdown for Third Party Media Analytics section
 * @name ThirdPartyMediaReportsDropdown
 * @param {String} selectedReport - The currently displayed report
 * @param {Function} onSelect - Callback function triggered after selecting a new report.
 * @param {Object[]} reports - all reports in the dropdown
 * @param {Boolean} loading - loading state from gql data object
 * @return {Object} - React JSX
 */

const ThirdPartyMediaReportsDropdown = ({
  options,
  setSelectedReports,
  selectedGroup,
  setSelectedGroup,
  settingsClickAction,
  setGroupModal,
  openDropdown,
  setOpenDropdown,
  selectedReportIds,
  setSelectedReportIds,
  getFullReports,
  setModalSelectedReports,
  setModalSelectedGroup,
  clearSelection,
  isThirdPartyAccessAllowed
}) => {
  const [reportsDiverged, setReportsDiverged] = useState(false);

  const classes = styles();

  const onCheckboxChange = (checked, option) => {
    if (option.type === TYPE.REPORT) {
      let modifiedReports = [...selectedReportIds];

      if (checked) {
        modifiedReports.push(option.id);
      } else {
        modifiedReports = selectedReportIds.filter(id => id !== option.id);
      }

      setSelectedReportIds(modifiedReports);

      // if a group is currently selected, and we change the report selection, we need to diverge.
      if (selectedGroup) {
        setReportsDiverged(true);
      }
    }

    // checking and un-checking a group will control which reports render at the bottom.
    if (option.type === TYPE.GROUP) {
      if (checked) {
        setSelectedReportIds(option.reports);
        setSelectedGroup(option);
        setModalSelectedGroup(option);
      } else {
        // uncheck the group and all reports.
        setSelectedGroup(null);
        setModalSelectedGroup(null);
        setSelectedReportIds([]);
      }
      // modifying group selection syncs the reports to their default state.
      setReportsDiverged(false);
    }
  };

  const onInputChange = (e, option, reason) => {
    if (reason === 'clear') {
      clearSelection();
    }
  };

  const onCreateGroup = () => {
    const fullReports = getFullReports(selectedReportIds);

    if (fullReports?.length > 1) {
      setModalSelectedReports(fullReports);
      setModalSelectedGroup(null);

      setGroupModal({ open: true, action: 'create' });
    } else {
      showToast('Please include at least two reports to create a new group.', 'warn');
    }
  };

  const onUpdateGroup = group => {
    if (group) {
      const fullReports = getFullReports(selectedReportIds);

      setModalSelectedReports(fullReports);
      setModalSelectedGroup(group);

      setGroupModal({ open: true, action: 'update' });
    }
  };

  const renderGroup = params => {
    return [
      <div key="group" style={{ padding: '5px 24px', background: '#EEEEEE', fontSize: '10px' }}>
        {params.group === 'group' ? 'GROUPS' : 'REPORTS'}
      </div>,
      params.children,
      <Fragment key="report">
        {params.group === 'report' && (
          <Grid container alignItems="center" className={classes.lowerButtons}>
            <DropdownActionButton
              updateGroup={onUpdateGroup}
              createGroup={onCreateGroup}
              selectedGroup={selectedGroup}
              reportsDiverged={reportsDiverged}
              selectedReportIds={selectedReportIds}
              isThirdPartyAccessAllowed={isThirdPartyAccessAllowed}
            />
            <img
              src={SETTINGS_ICON}
              alt="settings_icon"
              className={classes.clickableIcon}
              onClick={settingsClickAction}
              role="presentation"
            />
          </Grid>
        )}
      </Fragment>
    ];
  };

  const getImageSrc = reportType => {
    if (reportType === 'broadcast') {
      return getIconSrc('broadcast_and_radio');
    }

    return getIconSrc(reportType);
  };

  const getInputIcons = () => {
    const newIcons = [];
    let reportIcon;

    selectedReportIds.forEach(id => {
      const fullReport = options.find(report => report.id === id && report.type === 'report');

      if (fullReport?.reportType) {
        reportIcon = getImageSrc(fullReport.reportType);
      }

      if (reportIcon && !newIcons.includes(reportIcon)) {
        newIcons.push(reportIcon);
      }
    });

    return newIcons.map(icon => (
      <InputAdornment position="start" key={icon}>
        <img src={icon} alt="report_type" height="17px" style={{ margin: '5px' }} />
      </InputAdornment>
    ));
  };

  const onClose = () => {
    const fullReports = getFullReports();

    setSelectedReports(fullReports);
    setOpenDropdown(false);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        autoSelect
        disableCloseOnSelect
        options={options}
        classes={{
          option: classes.option,
          inputRoot: classes.inputRoot,
          listbox: classes.listbox,
          clearIndicator: selectedReportIds?.length > 0 ? classes.clearIndicatorOverride : null
        }}
        open={openDropdown}
        onClose={onClose}
        getOptionLabel={option => option.name}
        renderGroup={renderGroup}
        groupBy={option => option.type}
        onInputChange={onInputChange}
        renderOption={option => {
          let selectOption = false;

          // reports are also selected along with the groups
          if (option.type === 'report' && selectedReportIds.includes(option.id)) {
            selectOption = true;
          }

          if (option.type === 'group' && selectedGroup?.id === option.id) {
            selectOption = true;
          }

          return (
            <Grid
              container
              alignItems="center"
              key={option.id}
              wrap="nowrap"
              onClick={() => {
                onCheckboxChange(!selectOption, option);
              }}
            >
              <Checkbox checked={selectOption} />
              {option.type === 'report' && (
                <img
                  src={getImageSrc(option.reportType)}
                  alt="type icon"
                  className={classes.validateIcon}
                />
              )}
              {option.type === 'report' && <div className={classes.report}>{option.name}</div>}
              {option.type === 'group' && <div className={classes.group}>{option.name}</div>}
              {option.type === 'group' && (
                <IconButton
                  className={classes.iconButton}
                  disableFocusRipple
                  size="small"
                  disabled={!isThirdPartyAccessAllowed}
                  onClick={e => {
                    onUpdateGroup(option);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
              {option.type === 'group' && option.primary && (
                <div style={{ marginLeft: '5px' }}>
                  <AlbTooltip title={tooltipText.tpmPrimaryReport} />
                </div>
              )}
            </Grid>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: getInputIcons()
              }}
              variant="outlined"
              placeholder={selectedReportIds?.length > 0 ? '' : 'Add Reports'}
              onClick={() => setOpenDropdown(!openDropdown)}
            />
          );
        }}
      />
    </div>
  );
};

ThirdPartyMediaReportsDropdown.propTypes = {
  selectedReport: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  setSelectedReports: PropTypes.func.isRequired,
  settingsClickAction: PropTypes.func,
  setGroupModal: PropTypes.func.isRequired,
  selectedGroup: PropTypes.shape(),
  setSelectedGroup: PropTypes.func,
  openDropdown: PropTypes.bool.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  selectedReportIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedReportIds: PropTypes.func.isRequired,
  getFullReports: PropTypes.func.isRequired,
  setModalSelectedReports: PropTypes.func.isRequired,
  setModalSelectedGroup: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
  isThirdPartyAccessAllowed: PropTypes.bool.isRequired
};

ThirdPartyMediaReportsDropdown.defaultProps = {
  selectedReport: null,
  options: [],
  settingsClickAction: () => {},
  selectedGroup: null,
  setSelectedGroup: () => {}
};

export default ThirdPartyMediaReportsDropdown;
