import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = makeStyles({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: props => {
      const { useSpacer, hasIndex } = props;
      if (useSpacer || hasIndex) {
        return '0.5fr 0.75fr 3fr 2fr 1.25fr 1.25fr 1.25fr';
      }

      return '0.75fr 3fr 2fr 1.25fr 1.25fr 1.25fr';
    },
    gridTemplateRows: '45px'
  },
  headerText: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#000000',
    textTransform: 'uppercase'
  },
  headerShadow: {
    width: '100%',
    height: '14px',
    position: 'absolute',
    bottom: '0',
    background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
    mixBlendMode: 'multiply'
  },
  startColumn: {
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  centerColumn: {
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
});

const DetectedEventTableRow = props => {
  const { useSpacer, hasIndex, useHeaderShadow } = props;
  const classes = styles({ useSpacer, hasIndex });

  return (
    <div className={classes.container}>
      {useSpacer && <div className={classes.startColumn} />}
      {hasIndex && (
        <div className={classes.centerColumn}>
          <Typography className={classes.headerText}>#</Typography>
        </div>
      )}
      <div className={classes.centerColumn}>
        <Typography className={classes.headerText}>Source</Typography>
      </div>
      <div className={classes.startColumn}>
        <Typography className={classes.headerText}>Event</Typography>
      </div>
      <div className={classes.startColumn}>
        <Typography className={classes.headerText}>Graph</Typography>
      </div>
      <div className={classes.centerColumn}>
        <Typography className={classes.headerText}>Median</Typography>
      </div>
      <div className={classes.centerColumn}>
        <Typography className={classes.headerText}>Metric</Typography>
      </div>
      <div className={classes.centerColumn}>
        <Typography className={classes.headerText}>Valuation Change Δ</Typography>
      </div>
      {useHeaderShadow && <div className={classes.headerShadow} />}
    </div>
  );
};

DetectedEventTableRow.propTypes = {
  useSpacer: PropTypes.bool,
  hasIndex: PropTypes.bool,
  useHeaderShadow: PropTypes.bool
};

DetectedEventTableRow.defaultProps = {
  useSpacer: false,
  hasIndex: false,
  useHeaderShadow: false
};

export default DetectedEventTableRow;
