import { gql } from '@apollo/client';

export const GET_HASHTAGS_FOR_TEXT = gql`
  query hashtagForText($text: String!, $event_id: ID) {
    hashtagForText(text: $text, event_id: $event_id) {
      tags {
        hashtag
        exposure
      }
    }
  }
`;

export default GET_HASHTAGS_FOR_TEXT;
