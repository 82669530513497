import React from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import AlbLoading from 'components/AlbLoading';
import { SubdirectoryArrowRight as RightArrowIcon } from '@material-ui/icons';

/**
 * @method
 * @summary This component renders the report dropdown for the title section of analytics
 * @name AnalyticsReportPicker
 * @param {String} selectedReport - The currently displayed report
 * @param {Function} onSelect - Callback function triggered after selecting a new report.
 * @param {Object[]} reports - all reports in the dropdown
 * @param {Boolean} loading - loading state from gql data object
 * @return {Object} - React JSX
 */

const AnalyticsReportPicker = ({ selectedReport, onSelect, reports, loading }) => {
  return (
    <TextField
      select
      variant="filled"
      value={selectedReport}
      onChange={onSelect}
      style={{ minWidth: '247px' }}
    >
      <MenuItem hidden disabled selected value="" style={{ display: 'none' }}>
        Select Search To Display
      </MenuItem>
      {loading && (
        <MenuItem>
          <AlbLoading />
        </MenuItem>
      )}
      {reports?.map(report => {
        return (
          <MenuItem key={report.id} value={report.id}>
            {report.filter && <RightArrowIcon style={{ fontSize: '12px' }} />}
            {report.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

AnalyticsReportPicker.propTypes = {
  selectedReport: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  reports: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool
};

AnalyticsReportPicker.defaultProps = {
  selectedReport: null,
  reports: null,
  loading: false
};

export default AnalyticsReportPicker;
