/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SocialAssets from 'components/SocialAssets';
import { AlbTooltip } from 'components/AlbTooltip';
import AnnotationsList from 'components/DetectedEventsTable/AnnotationsList';
import tooltipText from 'util/tooltipText';
import DetailsHeader from './DetailsHeader';
import DetailsBody from './DetailsBody';

const useStyles = makeStyles({
  container: {
    padding: '28px 25px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '36px',
    color: '#0A1734',
    marginRight: '10px'
  },
  header: {
    marginBottom: '24px'
  },
  body: {
    display: 'flex',
    marginBottom: '24px'
  },
  textContainer: {
    flex: 1
  },
  tooltip: {
    margin: 'auto'
  }
});

/**
 * @method
 * @summary This component renders the details of a post at the top of @see PostDrawerView
 * @name PostDrawerDetails
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const PostDrawerDetails = props => {
  const { campaignEvent, isInitialSurge, isResurgence, annotationsStart, annotationsEnd } = props;
  const classes = useStyles();

  const getTitle = () => {
    if (isInitialSurge) {
      return 'Initial Post Surge';
    }

    if (isResurgence) {
      return 'Post Resurgence';
    }
    return 'Post Details';
  };

  return (
    <div className={classes.container}>
      <Grid className={classes.header} container alignItems="center">
        <Typography className={classes.title}>{getTitle()}</Typography>
        <AlbTooltip title={tooltipText.drawerMain} />
      </Grid>
      <div className={classes.body}>
        <SocialAssets
          width={124}
          height={124}
          assets={campaignEvent?.assets?.length > 0 ? campaignEvent?.assets : null}
          videoThumbnail={campaignEvent?.assets?.length === 0 ? campaignEvent?.thumbnail_url : null}
        />

        <div className={classes.textContainer}>
          <DetailsHeader
            postDetails={{
              id: campaignEvent?.id,
              linkTokenType: campaignEvent?.type,
              avatarUrl: campaignEvent?.linked_account?.avatar_url,
              remoteName: campaignEvent?.linked_account?.remote_name,
              completedAt: campaignEvent?.completed_at,
              publishedUrl: campaignEvent?.published_url
            }}
          />

          <DetailsBody eventBody={campaignEvent?.event_body} />
        </div>
      </div>
      <AnnotationsList startDate={annotationsStart} endDate={annotationsEnd} includeTitle />
    </div>
  );
};

PostDrawerDetails.propTypes = {
  campaignEvent: PropTypes.shape().isRequired,
  isInitialSurge: PropTypes.bool,
  isResurgence: PropTypes.bool,
  annotationsStart: PropTypes.string.isRequired,
  annotationsEnd: PropTypes.string.isRequired
};

PostDrawerDetails.defaultProps = {
  isInitialSurge: false,
  isResurgence: false
};

export default PostDrawerDetails;
