/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import ReactFilestack from 'filestack-react';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { defaultProps, withProps } from 'recompose';
import { Button } from '@material-ui/core';
import { showToast } from 'contexts/ToastContext';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { ASSETS_GET_UL_POLICY, ASSET_FROM_HANDLE } from 'gql/asset';
import { IMPORT_FROM_HANDLE, GET_IMPORTS } from 'gql/lead';
import { validateFilename } from 'util/assetNameValidation';

import HasAnyPriv from 'components/HasAnyPriv';

const defaultFormStyle = {
  minWidth: '100px',
  position: 'relative',
  marginTop: 'auto',
  marginBottom: 'auto',
  cursor: 'pointer'
};

const MaterialButton = ({ buttonText, color, onPick, variant }) => (
  <Button color={color} variant={variant} onClick={onPick}>
    {buttonText}
  </Button>
);

MaterialButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  color: PropTypes.string,
  onPick: PropTypes.func.isRequired,
  variant: PropTypes.string
};

MaterialButton.defaultProps = { color: 'primary', variant: 'contained' };

const onFileUploadFailed = () => {};

const onError = async () => {};

const uploadPending = () => {
  showToast('Upload in progress...', 'success', 1500);
};

const storeFiles = async filesUploaded => {
  if (filesUploaded) {
    let message;
    if (filesUploaded.length > 1) {
      message = `Successfully uploaded ${filesUploaded.length} files.`;
    } else if (filesUploaded.length === 1) {
      message = `Successfully uploaded ${filesUploaded[0].filename || '1 file.'}`;
    } else {
      return null;
    }

    showToast(message, 'success');
    return filesUploaded;
  }
  return null;
};

class GenericFileButton extends React.Component {
  state = {
    fsSecurity: {},
    newFileName: null,
    loadingVisible: false
  };

  // currentTab prop is to upload assets with specific properties by tab, if needed.
  // Currently, only used for uploading from Shared tab in Media Library.
  onSuccess = async ({ filesUploaded }) => {
    const { callback, mutate, uploadfolder, currentTab, getUploadData } = this.props;

    let handles = [];
    let orgShared = false;

    if (filesUploaded) {
      handles = filesUploaded.map(file => ({ id: file.handle }));
    }

    // if the asset is being uploaded to the Shared Tab, mark it as public by default.
    if (currentTab === 4) {
      orgShared = true;
    }

    if (handles) {
      return mutate({
        variables: {
          handles,
          uploadfolder,
          orgShared
        }
      })
        .then(result => {
          if (result && result.data) {
            if (getUploadData) {
              getUploadData(result.data);
            }

            if (result.data.createLeadBatch) {
              storeFiles(result.data.createLeadBatch);
            } else if (result.data.createAssetFromHandles) {
              storeFiles(result.data.createAssetFromHandles);
            } else if (result.data.videoData) {
              storeFiles(result.data.videoData);
            }
            if (callback) {
              callback(result);
            }
          }
          return null;
        })
        .catch(error => {
          // When the error is of level "warn", the upload actually goes through, yet it won't trigger the above .then functions.
          if (error.graphQLErrors && error.graphQLErrors.length) {
            error.graphQLErrors.map(({ message, level }) => {
              if (message.includes('File name exists.')) {
                return showToast(
                  `Error: ${message} Please rename your file and try again.`,
                  'error'
                );
              }
              if (level === 'warn') {
                return showToast(`Warning: ${message}`, 'warn');
              }
              return showToast(`Error: ${message}`, 'error');
            });
          }
          return showToast('Error occurred. Please try again.', 'error');
        });
    }
    return null;
  };

  render() {
    const { buttonText, display, formStyle, render, fsOptions } = this.props;

    const { onFileSelected } = fsOptions;
    const actionOptions = fsOptions;

    if (onFileSelected) {
      // Call onFileSelected with file, properties and state
      actionOptions.onFileSelected = file => {
        return onFileSelected(file, this.props, this.state).catch(error => {
          throw error;
        });
      };
    }

    return (
      <Query query={ASSETS_GET_UL_POLICY}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <AlbLoading />;
          if (error) return <AlbError error={error} refetch={refetch} />;

          if (data) {
            const { policy, signature } = data.getUploadPolicy;
            const clientOptions = { security: { policy, signature } };

            return (
              <form style={formStyle}>
                <ReactFilestack
                  {...this.props}
                  actionOptions={actionOptions}
                  clientOptions={clientOptions}
                  customRender={render}
                  onError={onError}
                  onSuccess={this.onSuccess}
                  componentDisplayMode={{ type: display, customText: buttonText }}
                />
              </form>
            );
          }
          return null;
        }}
      </Query>
    );
  }
}

GenericFileButton.propTypes = {
  apikey: PropTypes.string,
  buttonText: PropTypes.string,
  callback: PropTypes.func,
  currentUser: PropTypes.shape(),
  display: PropTypes.string,
  formStyle: PropTypes.shape(),
  fsOptions: PropTypes.shape(),
  fsSecurity: PropTypes.shape(),
  render: PropTypes.func,
  source: PropTypes.string,
  action: PropTypes.string,
  mutate: PropTypes.func,
  uploadfolder: PropTypes.string,
  onFileSelected: PropTypes.func,
  onFileUploadFailed: PropTypes.func,
  currentTab: PropTypes.number,
  getUploadData: PropTypes.func
};

GenericFileButton.defaultProps = {
  apikey: process.env.FS_API_KEY,
  buttonText: 'Upload',
  fsOptions: {
    onFileUploadFailed
  },
  callback: () => {},
  currentUser: {},
  display: 'button',
  formStyle: defaultFormStyle,
  fsSecurity: {},
  onFileSelected: validateFilename,
  onFileUploadFailed: () => {},
  render: MaterialButton,
  source: undefined,
  action: 'pick',
  mutate: () => {},
  uploadfolder: '',
  currentTab: null,
  getUploadData: null
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.auth.currentUser
});

export const CsvFileButton = compose(
  connect(mapStateToProps),
  withProps(ownProps => ({
    fsOptions: {
      accept: 'text/csv',
      disableTransformer: true,
      fromSources: ['local_file_system']
    },
    // eslint-disable-next-line react/prop-types
    render: ({ onPick }) => (
      <HasAnyPriv privs={['SVC:CRM:IMPORT']}>
        <MaterialButton onPick={onPick} buttonText="Import Contacts" {...ownProps} />
      </HasAnyPriv>
    )
  })),
  defaultProps({
    formStyle: { display: 'inline-block' }
  }),
  graphql(IMPORT_FROM_HANDLE, {
    options: {
      refetchQueries: [{ query: GET_IMPORTS }]
    }
  })
)(GenericFileButton);

export const ImageUpload = compose(
  connect(mapStateToProps),
  withProps(ownProps => ({
    fsOptions: {
      ...ownProps.fsOptions, // Merge incoming properies
      storeTo: {
        workflows: [process.env.FS_AUTOTAGS_WORKFLOWID]
      }
    }, // for storing autotags webhook
    // eslint-disable-next-line react/prop-types
    render: ({ onPick }) => <MaterialButton onPick={onPick} {...ownProps} />
  })),
  graphql(ASSET_FROM_HANDLE, {
    options: () => ({
      refetchQueries: ['getAssets'] // calling a query by name will refetch the last query with the same variables.
    })
  })
)(GenericFileButton);

export const AssetFileButton = compose(
  withProps({
    // Options to merge with ImageUpload
    fsOptions: {
      accept: ['image/*', 'video/*'],
      exposeOriginalFile: true,
      onFileSelected: validateFilename,
      maxFiles: 20,
      onFileUploadFinished: uploadPending,
      fromSources: ['local_file_system']
    }
  })
)(ImageUpload);
