import React from 'react';

const ArticleDrawerViewContext = React.createContext({
  isArticleDrawerOpen: false,
  articleId: null,
  articleSearchId: null,
  domainFilter: null,
  toggleDrawer: () => {}
});

export default ArticleDrawerViewContext;
