import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PercentChange from 'components/PercentChange/PercentChange';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { AlbTooltip } from 'components/AlbTooltip';

const useStyles = makeStyles({
  primaryText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734'
  },
  card: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px',
    padding: '18px 20px 20px 20px',
    position: 'relative'
  },
  cardTitle: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#0A1734',
    textAlign: 'center',
    marginBottom: '20px'
  },
  cardSubtitle: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#6F6F6F',
    textAlign: 'center',
    marginBottom: '10px'
  },
  subtitleValue: {
    fontWeight: '600',
    color: '#32327D'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const PerformanceItem = props => {
  const { hasBackground, title, data, dataPost } = props;
  const [published, setPublished] = useState(null);
  const [longTail, setLongTail] = useState(null);

  useEffect(() => {
    let tempPublished = null;
    let tempLongTail = null;

    if (data?.publishedValue < 0) {
      tempPublished = {
        value: '0',
        percentChange: 0,
        diffValue: 0
      };
    }

    if (data?.publishedValue >= 0) {
      tempPublished = {
        value: Math.round(data.publishedValue).toLocaleString(),
        percentChange: data?.publishedDiffPercentChange,
        diffValue: Math.round(data?.publishedDiffValue)
      };
    }

    if (data?.longTailValue < 0) {
      tempLongTail = {
        value: '0',
        percentChange: 0,
        diffValue: 0
      };
    }

    if (data?.longTailValue >= 0) {
      tempLongTail = {
        value: Math.round(data.longTailValue).toLocaleString(),
        percentChange: data?.longTailDiffPercentChange,
        diffValue: Math.round(data?.longTailDiffValue)
      };
    }

    setPublished(tempPublished);
    setLongTail(tempLongTail);
  }, [data]);

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        padding: '10px 0px 15px 0px',
        ...(hasBackground && { backgroundColor: 'rgba(151, 151, 151, 0.1)' })
      }}
    >
      <Grid container justifyContent="center">
        <Typography
          style={{ fontWeight: '500', fontSize: '18px', lineHeight: '27px', color: '#6F6F6F' }}
        >
          {title}
        </Typography>
        {title === 'Posts' && (
          <div style={{ marginTop: '5px', marginLeft: '5px' }}>
            <AlbTooltip title="Number of posts contributing to report (vs. total number of posts published in time period). Missing post data is often due to accounts being unlinked for a period of time." />
          </div>
        )}
      </Grid>
      {data == null && (
        <Typography
          style={{ fontWeight: '500', fontSize: '18px', lineHeight: '27px', color: '#32327D' }}
        >
          -
        </Typography>
      )}
      {data != null && (
        <>
          <Grid
            container
            item
            sm={6}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{ fontWeight: '500', fontSize: '30px', lineHeight: '45px', color: '#32327D' }}
            >
              {title === 'Posts' ? dataPost?.totalPostsValue : published?.value ?? '-'}{' '}
              {title === 'Posts' ? `(${published?.value})` : ''}
            </Typography>
            <Typography
              style={{ fontWeight: '500', fontSize: '9px', lineHeight: '14pz', color: '#6F6F6F' }}
            >
              Published
            </Typography>
            <PercentChange
              percentValue={published?.percentChange}
              changeValue={published?.diffValue}
            />
          </Grid>
          <Grid
            container
            item
            sm={6}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{ fontWeight: '500', fontSize: '30px', lineHeight: '45px', color: '#32327D' }}
            >
              {longTail?.value ?? '-'}
            </Typography>
            <Typography
              style={{ fontWeight: '500', fontSize: '9px', lineHeight: '14px', color: '#6F6F6F' }}
            >
              Long Tail
            </Typography>
            <PercentChange
              percentValue={longTail?.percentChange}
              changeValue={longTail?.diffValue}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

PerformanceItem.defaultProps = {
  hasBackground: false,
  data: null,
  dataPost: null
};

PerformanceItem.propTypes = {
  hasBackground: PropTypes.bool,
  title: PropTypes.string.isRequired,
  dataPost: PropTypes.shape({
    totalPostsValue: PropTypes.number
  }),
  data: PropTypes.shape({
    publishedValue: PropTypes.number,
    publishedDiffValue: PropTypes.number,
    publishedDiffPercentChange: PropTypes.number,
    longTailValue: PropTypes.number,
    longTailDiffValue: PropTypes.number,
    longTailDiffPercentChange: PropTypes.number
  })
};

const METRIC_MAP = {
  count: 'Posts',
  alembicImpressions: 'Impressions',
  alembicEngagement: 'Engagement',
  alembicShares: 'Shares'
};

const PerformanceSummary = props => {
  const { loading, error, data, dataPost } = props;
  const classes = useStyles();

  return (
    <div className={classes.card}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      <Grid container justifyContent="center">
        <Typography className={classes.cardTitle}>
          Your social content has long tail performance and value
        </Typography>

        {data == null && (
          <Typography className={classes.primaryText}>
            There is not any data to display at this time.
          </Typography>
        )}

        {data != null &&
          data
            .filter(dataset => dataset.metric !== 'count' || dataset.metric !== 'alembicShares')
            .map(dataset => {
              const publishedValue = dataset.publishedValue >= 0 ? dataset.publishedValue : 0;
              const longTailValue = dataset.longTailValue >= 0 ? dataset.longTailValue : 0;
              const total = publishedValue + longTailValue;
              let displayValue = '0 (0%)';

              if (longTailValue > 0) {
                const longTailPercentage = (dataset.longTailValue / total) * 100;
                displayValue = `${dataset.longTailValue.toLocaleString()} (${longTailPercentage.toFixed(
                  2
                )}%)`;
              }

              return (
                <Typography key={METRIC_MAP[dataset.metric]} className={classes.cardSubtitle}>
                  {dataset.metric === 'alembicImpressions' && (
                    <>
                      We saw that <span className={classes.subtitleValue}>{displayValue}</span> of
                      your <b>impressions</b> came from older social posts that resonated with your
                      audience for a longer time.
                    </>
                  )}

                  {dataset.metric === 'alembicEngagement' && (
                    <>
                      And <span className={classes.subtitleValue}>{displayValue}</span> of your{' '}
                      <b>engagements</b> were created from these long tail social posts.
                    </>
                  )}
                </Typography>
              );
            })}
        {data != null &&
          data.map(dataset => (
            <Grid key={METRIC_MAP[dataset.metric]} item sm={6}>
              <PerformanceItem
                hasBackground={
                  METRIC_MAP[dataset.metric] === 'Posts' || METRIC_MAP[dataset.metric] === 'Shares'
                }
                title={METRIC_MAP[dataset.metric]}
                data={dataset}
                dataPost={METRIC_MAP[dataset.metric] === 'Posts' ? dataPost : null}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

PerformanceSummary.defaultProps = {
  loading: false,
  error: null,
  data: null,
  dataPost: null
};

PerformanceSummary.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()),
  dataPost: PropTypes.shape()
};

export default PerformanceSummary;
