import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import tableConsts from 'components/AnalyticsSocial/AnalyticsTableConsts';
import { kpiLabels } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import BoxContentChange from './BoxContent/BoxContentChange';
import BoxContentTop from './BoxContent/BoxContentTop';
import BoxContentKPIContainer from './BoxContent/BoxContentKPIContainer';
import BoxContentTable from './BoxContent/BoxContentTable';

const AnalyticsVideoOverview = props => {
  const { accounts, dates, userTimeZone } = props;

  return (
    <>
      {/** Change / KPI boxes */}
      <Grid container style={{ marginTop: '-25px' }}>
        <Grid container item sm={12} md={6}>
          <Grid container>
            <BoxContentChange
              title="Views Growth"
              metric="alembicViews"
              accounts={accounts}
              dates={dates}
              growth
              userTimeZone={userTimeZone}
              onlyVideo
              prefix="vo"
            />
            <BoxContentChange
              title="Average Watch Time"
              metric="alembicWatchTime"
              accounts={accounts}
              dates={dates}
              userTimeZone={userTimeZone}
              onlyVideo
            />
          </Grid>
          <Grid container>
            <BoxContentChange
              title="Engagements Growth"
              metric="alembicEngagement"
              accounts={accounts}
              dates={dates}
              growth
              userTimeZone={userTimeZone}
              onlyVideo
            />
            <BoxContentChange
              title="Total Followers / Subs."
              metric="alembicFollowers"
              accounts={accounts}
              dates={dates}
              userTimeZone={userTimeZone}
              // Purposely leave out onlyVideo, since followers is a per-page metric and hasVideo is per-post
              // onlyVideo
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6}>
          <BoxContentKPIContainer
            tabs={[kpiLabels.videoViews, kpiLabels.engagement, kpiLabels.minutesWatched]}
            accounts={accounts}
            dates={dates}
            userTimeZone={userTimeZone}
            defaultMetric="lifetime_views"
            onlyVideo
          />
        </Grid>
      </Grid>
      {/** Top boxes */}
      <Grid container>
        <Grid item sm={12} md={4}>
          <BoxContentTop
            title="Top Demographic"
            widget="demographic"
            accounts={accounts}
            dates={dates}
            userTimeZone={userTimeZone}
            // Purposely leave out onlyVideo, since demographics are a per-page metric and hasVideo is per-post
            // onlyVideo
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <BoxContentTop
            title="Top Country"
            widget="country"
            accounts={accounts}
            dates={dates}
            userTimeZone={userTimeZone}
            // Purposely leave out onlyVideo, since demographics are a per-page metric and hasVideo is per-post
            // onlyVideo
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <BoxContentTop
            title="Top City"
            widget="city"
            accounts={accounts}
            dates={dates}
            userTimeZone={userTimeZone}
            // Purposely leave out onlyVideo, since demographics are a per-page metric and hasVideo is per-post
            // onlyVideo
          />
        </Grid>
      </Grid>
      {/** Table */}
      <Grid container>
        <BoxContentTable
          tabs={['Top Videos', 'Recent Videos', 'Top by Campaign', 'Top by Theme/Topic']}
          query={tableConsts.topVideosv2.query}
          accounts={accounts}
          dates={dates}
          userTimeZone={userTimeZone}
          onlyVideo
        />
      </Grid>
    </>
  );
};

AnalyticsVideoOverview.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  userTimeZone: PropTypes.string.isRequired
};

export default AnalyticsVideoOverview;
