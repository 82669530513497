import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_SALESFORCE_PIPELINE } from 'gql/salesforce';
import { getDatesForAnalytics } from 'util/date';
import ProjectionsTable from './Tables/ProjectionsTable';
import LeadsAndOpportunitiesGraph from './Graphs/LeadsAndOpportunitiesGraph';
import SegmentsTable from './Tables/SegmentsTable';

const useStyles = makeStyles({
  box: {
    margin: '25px 15px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  }
});

const SalesforceFunnel = props => {
  const { linktokenId, dates, statuses, stages } = props;
  const classes = useStyles();

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const [allCategoryData, setAllCategoryData] = useState(null);
  const [segment, setSegment] = useState({ value: 'source', label: 'Source' });
  const [lookback, setLookback] = useState({ value: '6_M', label: '6 Months' });

  const { data, loading, error } = useQuery(GET_SALESFORCE_PIPELINE, {
    variables: {
      linktokenId,
      startDate,
      endDate,
      statuses,
      stages,
      segment: segment.value,
      lookBackTime: lookback.value
    },
    fetchPolicy: 'network-only',
    skip: linktokenId == null
  });

  useEffect(() => {
    let tempAllCategoryData;

    if (data?.getSalesforcePipeline?.length) {
      tempAllCategoryData = data.getSalesforcePipeline.find(({ category }) => category === 'ALL');
    }

    setAllCategoryData(tempAllCategoryData);
  }, [data]);

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item className={classes.box}>
        <ProjectionsTable
          data={allCategoryData?.projection}
          loading={loading}
          error={error}
          lookback={lookback}
          setLookback={setLookback}
        />
        <div
          style={{
            height: '14px',
            width: '100%',
            background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
            mixBlendMode: 'multiply'
          }}
        />
        {allCategoryData && (
          <>
            <LeadsAndOpportunitiesGraph data={allCategoryData} loading={loading} error={error} />
            <div
              style={{
                height: '14px',
                width: '100%',
                background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
                mixBlendMode: 'multiply'
              }}
            />
          </>
        )}
        {data?.getSalesforcePipeline?.length && (
          <SegmentsTable
            data={data.getSalesforcePipeline}
            loading={loading}
            error={error}
            segment={segment}
            setSegment={setSegment}
          />
        )}
      </Grid>
    </Grid>
  );
};

SalesforceFunnel.propTypes = {
  linktokenId: PropTypes.string,
  dates: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)])
  }).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  stages: PropTypes.arrayOf(PropTypes.string).isRequired
};

SalesforceFunnel.defaultProps = {
  linktokenId: null
};

export default SalesforceFunnel;
