import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { showToast } from 'contexts/ToastContext';
import Box from '../Box';

import { getConfigObject, getConsentURL } from './constants';

/**
 * This is a class that starts the first leg of the OAuth2 flow. It will redirect
 * the user to the OAuth2 provider's consent screen.
 */
const OAuthButton = props => {
  const { type } = props;
  const [config, setConfig] = useState(null);

  useEffect(() => {
    setConfig(getConfigObject(type));
  }, []);

  const onLogin = async event => {
    event.preventDefault();
    // send the user to the authorize dialog on the remote site.
    const consentURL = await getConsentURL(config);

    if (consentURL) {
      window.location = consentURL;
    } else {
      showToast(
        'This account requires relinking of the main account to repair. Please relink the main account.',
        'error'
      );
    }
  };

  if (!config) {
    return null;
  }

  return (
    <Button
      fullWidth
      size="large"
      variant="outlined"
      onClick={onLogin}
      style={{ padding: '10px 2px 10px 2px' }}
    >
      <Box mx={10}>
        <div style={{ display: 'flex' }}>
          {config.logos.map(logo => (
            <img
              alt="logo"
              width={20}
              height={20}
              src={logo}
              key={logo}
              style={{ marginLeft: '7px' }}
            />
          ))}
        </div>
      </Box>
      {`Connect ${config.name}`}
    </Button>
  );
};

OAuthButton.propTypes = {
  type: PropTypes.string.isRequired
};

export default withRouter(OAuthButton);
