import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlembicTitleChip from 'components/AlembicTitleChip';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import Banner from 'components/Banner';
import WebAppsGraph from './Graphs/WebAppsGraph';
import WebAppsTable from './Tables/WebAppsTable';

const useStyles = makeStyles({
  chipContainer: {
    margin: '15px',
    '& > div': {
      marginRight: '30px',
      marginBottom: '10px'
    }
  },
  box: {
    margin: '25px 15px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  },
  banner: {
    width: '100%',
    padding: '15px 30px 0px'
  },
  graph: {
    flexDirection: 'column',
    padding: '30px'
  },
  table: {
    boxShadow: 'inset 0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
  }
});

/**
 * @summary This component renders the Web and Apps apps tab
 * @name WebApps
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.accounts - An array of link token objects from @see PostAccountSelector
 * @param {Object} props.dates - An object containing start and end dates from @see AnalyticsDatePicker
 * @return {Object} - React JSX
 */
const WebApps = props => {
  const { accounts, dates } = props;

  const classes = useStyles();

  const tabs = [
    { title: 'Installs', metric: 'webAndAppsInstalls', defaultSort: 'count' },
    { title: 'Revenue', metric: 'webAndAppsRevenue', defaultSort: 'revenue' },
    { title: 'Purchases', metric: 'webAndAppsPurchases', defaultSort: 'count' },
    {
      title: 'Active Devices',
      metric: 'webAndAppsActiveDevices',
      defaultSort: 'count',
      banner: true
    },
    { title: 'Uninstalls', metric: 'webAndAppsUninstalls', defaultSort: 'count', banner: true }
  ];

  const [currentTab, setCurrentTab] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['count', 'DESC']);

  const [count, setCount] = useState(0);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setSortOrder(([, prevSortOrder]) => [tabs[currentTab].defaultSort, prevSortOrder]);
    setPage(0);
  }, [currentTab]);

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item xs={12} className={classes.chipContainer}>
        {accounts.map(account => {
          const chip = {
            key: `${account.id}`,
            title: 'App',
            icon: account.platform.icon,
            body: account.label,
            customBodyStyle: {
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              whiteSpace: 'nowrap'
            }
          };

          return <AlembicTitleChip {...chip} />;
        })}
      </Grid>
      <Grid container item className={classes.box}>
        <BoxHeader
          tabs={tabs.map(({ title }) => title)}
          dates={dates}
          onTabChange={setCurrentTab}
        />
        {tabs[currentTab].banner && (
          <Grid className={classes.banner}>
            <Banner
              useIcon
              message="Only available for Google Play Store"
              variant="filled"
              severity="warning"
            />
          </Grid>
        )}
        <Grid container className={classes.graph}>
          <WebAppsGraph
            tab={tabs[currentTab]}
            accounts={accounts}
            dates={dates}
            page={page}
            rowsPerPage={rowsPerPage}
            sortOrder={sortOrder}
          />
        </Grid>
        <Grid container item className={classes.table}>
          <BoxHeader tabs={[`${tabs[currentTab].title} Breakdown`]} dates={dates} />
          <WebAppsTable
            tab={tabs[currentTab]}
            accounts={accounts}
            dates={dates}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            count={count}
            setCount={setCount}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

WebApps.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default WebApps;
