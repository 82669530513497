import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Box from 'components/Box';
import ExcludeMentionModal from 'components/AlembicModalConfirm/ExcludeMentionModal';
import IncludeMentionModal from 'components/AlembicModalConfirm/IncludeMentionModal';

/**
 * @method
 * @summary This component renders vertical (...) icon with a list of options when clicked @see MentionsTable
 * @name MentionsTableActions
 * @param {Object} props - React props passed to this component
 * @param {Object} props.actions - An object containing all the parameters for this component
 * @param {boolean} actions.disabled - Determines if a mention is enabled or disabled
 * @param {string} actions.id - The id of the mention
 * @param {string} actions.name - The name of the mention
 * @param {Function} actions.dataRefetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const MentionsTableActions = props => {
  const { actions } = props;
  const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false);
  const [isIncludeModalOpen, setIsIncludeModalOpen] = useState(false);

  return (
    <Box>
      {actions.disabled === false && (
        <Button variant="outlined" color="primary" onClick={() => setIsExcludeModalOpen(true)}>
          Exclude
        </Button>
      )}
      {(actions.disabled == null || actions.disabled === true) && (
        <Button variant="outlined" color="primary" onClick={() => setIsIncludeModalOpen(true)}>
          Include
        </Button>
      )}

      <ExcludeMentionModal
        isModalOpen={isExcludeModalOpen}
        onChange={event => {
          setIsExcludeModalOpen(event.isModalOpen);

          if (event.updated) {
            actions.dataRefetch();
          }
        }}
        mentionId={actions.id}
        mentionName={actions.name}
        type={actions.type}
      />
      <IncludeMentionModal
        isModalOpen={isIncludeModalOpen}
        onChange={event => {
          setIsIncludeModalOpen(event.isModalOpen);

          if (event.updated) {
            actions.dataRefetch();
          }
        }}
        mentionId={actions.id}
        mentionName={actions.name}
        type={actions.type}
      />
    </Box>
  );
};

MentionsTableActions.propTypes = {
  actions: PropTypes.shape().isRequired
};

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see MentionsTableActions
 * @name customActionsRender
 * @param {Object} actions - Object passed from @see MUIDataTable
 * @return {Object} - React JSX
 */
const customActionsRender = actions => {
  return <MentionsTableActions actions={actions} />;
};

export default customActionsRender;
