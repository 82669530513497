import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { GET_HASHTAGS_FOR_TEXT } from 'gql/hashtag';

const useStyles = makeStyles({
  tag: {
    fontSize: '10px',
    fontWeight: 500,
    padding: '5px'
  },
  button: {
    cursor: 'pointer'
  },
  exposure: {
    borderRadius: '3px 0px 0px 3px',
    backgroundColor: 'rgba(10, 23, 52, 0.2)',
    padding: '5px 7px'
  },
  hashtag: {
    borderRadius: '0px 3px 3px 0px',
    backgroundColor: 'rgba(13, 26, 58, 0.1)',
    padding: '5px 7px'
  },
  hashtags: {
    overflow: 'auto'
  }
});

const Hashtag = ({ exposure, hashtag, platform }) => {
  const classes = useStyles();

  function getHashtagLink(eventPlatform) {
    const encodedHashtag = encodeURI(hashtag);

    switch (eventPlatform) {
      case 'facebook':
        return `https://www.facebook.com/hashtag/${encodedHashtag}`;

      case 'instagram':
        return `https://www.instagram.com/explore/tags/${encodedHashtag}/`;

      case 'linkedin':
        return `https://www.linkedin.com/search/results/content/?keywords=%23${encodedHashtag}`;

      case 'twitter':
      default:
        return `https://twitter.com/search?q=%23${encodedHashtag}`;
    }
  }

  function openHashtagPage() {
    const url = getHashtagLink(platform);

    if (url) window.open(url);
  }

  function formatExposureNumber() {
    return Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(
      exposure
    );
  }

  return (
    <Grid item className={classes.tag}>
      <Grid container className={classes.button} onClick={() => openHashtagPage(hashtag)}>
        <Box component="span" className={classes.exposure}>
          {formatExposureNumber(exposure)}
        </Box>
        <Box component="span" className={classes.hashtag}>
          {hashtag}
        </Box>
      </Grid>
    </Grid>
  );
};

Hashtag.propTypes = {
  exposure: PropTypes.number.isRequired,
  hashtag: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired
};

const EventHashtags = props => {
  const { eventId, text, platform } = props;

  const classes = useStyles();

  const maxDisplayedHashtags = 10;

  const { loading, error, refetch, data } = useQuery(GET_HASHTAGS_FOR_TEXT, {
    variables: { event_id: eventId, text }
  });

  if (loading) return <AlbLoading />;
  if (error) return <AlbError error={error} refetch={refetch} />;

  return (
    <Grid container className={classes.hashtags}>
      {data?.hashtagForText?.tags?.slice(0, maxDisplayedHashtags).map(tag => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        // <div style={{ cursor: 'pointer' }} key={tag.hashtag}>
        <Hashtag key={tag.hashtag} platform={platform} {...tag} />
        // </div>
      ))}
    </Grid>
  );
};

EventHashtags.propTypes = {
  eventId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired
};

export default EventHashtags;
