import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showToast } from 'contexts/ToastContext';
import { makeStyles } from '@material-ui/core/styles';
import { getCurrentTimeInUserTimeZone } from 'util/date';
import setPageTitle from 'util/titles';
import { parseQueryString } from 'util/routesHelpers';
import CostToRecreateHeader from './CostToRecreateHeader';
import MediaValueContainer from './MediaValueContainer';
import CostToRecreateAdvancedView from './CostToRecreateAdvancedView';

const useStyles = makeStyles(() => ({
  marginSection: {
    margin: '30px'
  }
}));

/**
 * Renders form for a new cost to recreate report with preview
 * @name CreateCostToRecreateReport
 * @return {Object} - React JSX
 */
const CreateCostToRecreateReport = props => {
  const { location, currentUser } = props;
  // eslint-disable-next-line no-unused-vars
  const [valuationType, setValuationType] = useState('cpm');
  const [useLifetimeValue, setUseLifeTimeValue] = useState(true);
  const [reportId, setReportId] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const { startDate, endDate } = {
    startDate: getCurrentTimeInUserTimeZone(currentUser.time_zone)
      .subtract(14, 'days')
      .format('YYYY-MM-DD'),
    endDate: getCurrentTimeInUserTimeZone(currentUser.time_zone).format('YYYY-MM-DD')
  };

  const [costToRecreateFields, setCostToRecreateFields] = useState({
    postTextKeywords: null,
    postTypes: null,
    videoTags: null,
    linkedAccounts: [],
    imageTags: null,
    mediaTypes: null
  });

  const costToRecreateFieldsPre = useRef();

  const classes = useStyles();

  const changeMetricHandle = event => {
    if (event?.useCPM) {
      setValuationType('cpm');
    }

    if (event?.useCPA) {
      setValuationType('cpa');
    }
  };

  const handleOnChangeLifeTimeValue = event => {
    setUseLifeTimeValue(event);
  };

  const refreshReportHandle = () => {
    showToast('Report is updating', 'success');
    setCostToRecreateFields(costToRecreateFieldsPre.current);
  };

  const fieldsCallback = obj => {
    costToRecreateFieldsPre.current = obj;
  };

  useEffect(() => {
    setPageTitle('Preview Media Valuation Analytics');

    if (location?.search) {
      const reportIdParamArr = parseQueryString('reportId', location);
      const isFilterParamArr = parseQueryString('isFilter', location);

      if (reportIdParamArr?.length) {
        setReportId(reportIdParamArr[0]);
      }

      if (isFilterParamArr?.length && isFilterParamArr[0] === 'true') {
        setIsFilter(true);
      }
    }
  }, []);

  return (
    <>
      <CostToRecreateAdvancedView fieldsCallback={fieldsCallback} />

      <CostToRecreateHeader
        isPreview
        onChangeMetric={changeMetricHandle}
        onClickRefreshPreview={refreshReportHandle}
        onChangeLifeTimeValue={handleOnChangeLifeTimeValue}
        useLifetimeValueChecked={useLifetimeValue}
      />

      {reportId ? (
        <MediaValueContainer
          className={classes.marginSection}
          valuationType={valuationType}
          useLifetimeValue={useLifetimeValue}
          startDate={startDate}
          endDate={endDate}
          reportId={reportId}
          isFilter={isFilter}
          isPreview
          costToRecreateFields={costToRecreateFields}
        />
      ) : (
        <></>
      )}
    </>
  );
};

CreateCostToRecreateReport.propTypes = {
  location: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(CreateCostToRecreateReport);
