/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { ANALYTICS_LIFETIME } from 'gql/analytics';
import { GET_CAMPAIGNS_FOR_CURRENT_CONTAINER } from 'gql/campaign';
import { GET_THEMES_FOR_CURRENT_CONTAINER } from 'gql/theme';
import theme from 'theme';
import Box from 'components/Box';
import AlbError from 'components/AlbError';
import { tableColumnsv2 } from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import AssetThumbnail from 'components/AssetThumbnail';
import { showToast } from 'contexts/ToastContext';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import CustomFooter from 'components/TablePagination';
import { trimPreviewText, storyIcons, handleNullTableValue } from 'util/social';
import { DefaultUserAvatar } from 'util/assets';
import { getDatesForAnalytics, renderUIDate, renderUILocalTimeFromDate } from 'util/date';
import { openExternalPage } from 'util/externalLinks';
import colors from 'util/colors';
import BoxHeader from './BoxHeader';

const useStyles = makeStyles({
  box: {
    flex: 1,
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  },
  accountIcon: {
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    '& > img': {
      width: 38,
      height: 38
    }
  },
  accountLabel: {
    fontSize: '13px',
    fontWeight: 700,
    color: '#32327D',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const tableTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap',

        '&:nth-child(-n+2)': {
          width: 1
        }
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(odd)': {
          backgroundColor: '#f5f5f5'
        },
        '&:hover': {
          backgroundColor: colors.athensGray
        }
      }
    }
  }
});

const BoxContentTable = props => {
  const { tabs, query, accounts, dates, onlyVideo, prefix } = props;
  const drawerContext = useContext(PostDrawerViewContext);
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [previewText, setPreviewText] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [sortOrder, updateSortOrder] = useState([query.sortColumn, query.sortOrder]);

  const [campaigns, setCampaigns] = useState([]);
  const [themes, setThemes] = useState([]);
  const [topics, setTopics] = useState([]);
  const [tabFilter, setTabFilter] = useState(0);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const createDropdownMenu = data => data.map(({ id, name }) => ({ id, name }));

  const columnsNameLookupTable = new Map();

  // To match the analytics v2 graphs and tables which always show UTC, use this Analytics function instead of converting to user timezone
  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data, error } = useQuery(ANALYTICS_LIFETIME, {
    variables: {
      ...query,
      accountIds: accounts.map(account => account.id),
      campaign: selectedCampaign,
      theme: selectedTheme,
      topic: selectedTopic,
      startDate,
      endDate,
      after: page * rowsPerPage,
      count: rowsPerPage,
      orderBy: sortOrder,
      onlyVideo
    },
    fetchPolicy: 'no-cache'
  });

  const { data: campaignData, error: campaignError } = useQuery(
    GET_CAMPAIGNS_FOR_CURRENT_CONTAINER
  );
  const { data: themeData, error: themeError } = useQuery(GET_THEMES_FOR_CURRENT_CONTAINER);

  useEffect(() => {
    if (campaignError) {
      showToast(`Failed to load campaigns: ${campaignError}.`, 'error');
    }

    if (themeError) {
      showToast(`Failed to load themes and topics: ${themeError}.`, 'error');
    }
  }, [campaignError, themeError]);

  useEffect(() => {
    if (selectedTheme) {
      const topicsByTheme = themeData.themes.find(({ id }) => id === selectedTheme).topics;

      setTopics(createDropdownMenu(topicsByTheme));
    }
  }, [selectedTheme]);

  useEffect(() => {
    // sort by descending lifetime impressions
    if (tabFilter === 0) {
      setCampaigns(null);
      setThemes(null);
      setTopics(null);
      setSelectedCampaign(null);
      setSelectedTheme(null);
      setSelectedTopic(null);
      updateSortOrder([query.sortColumn, query.sortOrder]);
    }

    // sort by descending date
    if (tabFilter === 1) {
      setCampaigns(null);
      setThemes(null);
      setTopics(null);
      setSelectedCampaign(null);
      setSelectedTheme(null);
      setSelectedTopic(null);
      updateSortOrder(['date', 'desc']);
    }

    // filter by campaign and sort by descending lifetime impressions
    if (tabFilter === 2) {
      setCampaigns(createDropdownMenu(campaignData.campaigns));
      setThemes(null);
      setTopics(null);
      setSelectedTheme(null);
      setSelectedTopic(null);
      updateSortOrder([query.sortColumn, query.sortOrder]);
    }

    // filter by theme/topic and sort by descending lifetime impressions
    if (tabFilter === 3) {
      setThemes(createDropdownMenu(themeData.themes));
      setCampaigns(null);
      setSelectedCampaign(null);
      updateSortOrder([query.sortColumn, query.sortOrder]);
    }
  }, [tabFilter]);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  /**
   * Reformat incoming data from the graphQL call to work within the datatable
   * @param {Object} param0.rows - rows of the table
   */
  const formatTable = ({ rows }) => {
    if (!rows) {
      return null;
    }

    const formattedData = rows.map(
      ({
        id,
        publishedUrl,
        date,
        account: linktokenId,
        body,
        type,
        assets,
        metrics,
        thumbnailUrl
      }) => {
        const currentAccount = accounts.find(account => account.id === linktokenId) || {};
        // eslint-disable-next-line camelcase
        const { label, platform = {}, avatar_url } = currentAccount;

        return {
          id,
          linktokenId,
          thumbnailUrl,
          publishedUrl,
          date,
          account: { label, src: platform?.icon },
          label,
          // eslint-disable-next-line camelcase
          avatar_url: avatar_url || DefaultUserAvatar,
          type,
          body,
          assets,
          ...metrics.reduce((acc, { metric, value }) => {
            acc[metric] = value;
            return acc;
          }, {})
        };
      }
    );

    setTableData(formattedData);

    return formattedData;
  };

  useEffect(() => {
    if (data?.tablesLifetime) {
      formatTable(data.tablesLifetime);
      setCount(data.tablesLifetime.count);
    }
  }, [data, accounts, dates]);

  const customDateRender = date => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {renderUIDate(date)} @ {renderUILocalTimeFromDate(date)}
    </div>
  );

  const customAccountRender = (account, tableMeta) => {
    const linktokenIdIndex = columnsNameLookupTable.get('linktokenId');
    const typeIndex = columnsNameLookupTable.get('type');
    const linktokenId = tableMeta.rowData[linktokenIdIndex];
    const type = tableMeta.rowData[typeIndex];

    const socialIcon = storyIcons[type] || account.src;

    return (
      <div className={classes.accountIcon}>
        <img
          role="presentation"
          alt="logo"
          src={socialIcon}
          onClick={() => openExternalPage(accounts, linktokenId)}
          onKeyPress={() => openExternalPage(accounts, linktokenId)}
        />
      </div>
    );
  };

  const customImageRender = src => {
    if (src) {
      return (
        <div className={classes.accountIcon}>
          <img role="presentation" alt="logo" src={src} />
        </div>
      );
    }
    return '';
  };

  const customLabelRender = label => (
    <span role="presentation" className={classes.accountLabel}>
      {label}
    </span>
  );

  const customPostBodyRender = (inputText, tableMeta) => {
    // Sanitize the inputText, so that if it's null we just show an empty string below and don't blow up on null
    const text = inputText ?? '';
    const { rowData, rowIndex } = tableMeta;

    const assetsIndex = columnsNameLookupTable.get('assets');
    const assets = rowData[assetsIndex];

    const thumbnailIndex = columnsNameLookupTable.get('thumbnailUrl');
    const thumbnailUrl = rowData[thumbnailIndex];

    const isInPreview = rowIndex === previewText;
    const maxLengthText = 175;
    let croppedText = text;

    if (text.length > maxLengthText) {
      croppedText = trimPreviewText(text, maxLengthText);
    }

    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <Grid>
          <AssetThumbnail
            thumbnailUrl={thumbnailUrl}
            assets={assets}
            width={60}
            height={60}
            rounded
          />
        </Grid>
        <Typography
          style={{
            fontSize: '13px',
            marginLeft: '10px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            ...(!isInPreview && { WebkitLineClamp: 1 })
          }}
          onClick={() => {
            return isInPreview ? setPreviewText(null) : setPreviewText(rowIndex);
          }}
        >
          {rowIndex === previewText ? text : croppedText}
          {text.length > maxLengthText && !isInPreview && '...'}
        </Typography>
      </Box>
    );
  };

  const customValueRender = value => (
    <div style={{ textAlign: 'center' }}>{handleNullTableValue(value)}</div>
  );

  const customCurrencyRender = value => (
    <div style={{ textAlign: 'right' }}>{handleNullTableValue(value, false, true)}</div>
  );

  const onRowClick = rowData => {
    const campaignEventIdIndex = columnsNameLookupTable.get('id');
    const campaignEventId = rowData[campaignEventIdIndex];

    drawerContext.toggleDrawer(true, campaignEventId, null, null, startDate, endDate);
  };

  const columns = [
    /* 
      The following fields are not used for display (as columns) but they are passed through (as keys)
      so that MUI datatables has access to these data from the tableData array.

      The mapping through the columnsNameLookupTable map allows access to these within customBodyRender functions.

      This is largely due to the fact that within the customBodyRender call, access to the raw rowData is not possible.
    */
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'linktokenId',
      options: { display: false }
    },
    {
      name: 'thumbnailUrl',
      options: { display: false }
    },
    {
      name: 'publishedUrl',
      options: { display: false }
    },
    {
      name: 'assets',
      options: { display: false }
    },
    {
      name: 'type',
      options: { display: false }
    },
    /* begin actual displayed columns */
    {
      name: 'account',
      label: 'ACCOUNT',
      options: { customBodyRender: customAccountRender, sort: false }
    },
    {
      name: 'avatar_url',
      label: 'HANDLE',
      options: { customBodyRender: customImageRender, sort: false }
    },
    {
      name: 'label',
      label: ' ',
      options: { customBodyRender: customLabelRender, sort: false }
    },
    {
      name: 'date',
      label: 'DATE/TIME',
      options: {
        customBodyRender: customDateRender
      }
    },
    {
      name: 'body',
      label: 'POST',
      options: { customBodyRender: customPostBodyRender, sort: false }
    },
    ...query.metrics
      .filter(metric => metric !== 'lifetime_shares')
      .map(metric => ({
        name: metric,
        label: tableColumnsv2[metric],
        options: {
          customBodyRender: metric === 'valuationModel' ? customCurrencyRender : customValueRender,
          setCellHeaderProps: () => ({
            className: metric === 'valuationModel' ? classes.rightHeader : classes.centeredHeader
          }),
          sort: metric !== 'valuationModel'
        }
      }))
  ];

  // store a column index => name map for quick lookup.
  columns.forEach((column, i) => columnsNameLookupTable.set(column.name, i));

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    onRowClick,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter: CustomFooter,
    sortOrder: sortOrder && sortOrder.length > 1 && { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => updateSortOrder([changedColumn, direction])
  };

  if (error) return <AlbError error={error} />;

  return (
    <Box className={classes.box}>
      <BoxHeader
        tabs={tabs}
        campaigns={campaigns}
        themes={themes}
        topics={topics}
        prefix={prefix}
        selectCampaign={setSelectedCampaign}
        selectTheme={setSelectedTheme}
        selectTopic={setSelectedTopic}
        setFilter={setTabFilter}
      />

      <MuiThemeProvider theme={tableTheme}>
        <MUIDataTable key={count} data={tableData} columns={columns} options={options} />
      </MuiThemeProvider>
    </Box>
  );
};

BoxContentTable.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  query: PropTypes.shape().isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired,
  onlyVideo: PropTypes.bool,
  prefix: PropTypes.string
};

BoxContentTable.defaultProps = {
  onlyVideo: false,
  prefix: ''
};

export default BoxContentTable;
