import getValueAbbreviation from './getValueAbbreviation';

const formatValueWithSymbol = (value, options) => {
  const {
    useDash = true,
    useAbbreviation = true,
    usePrefixSymbol = false,
    prefixSymbol = '$',
    usePostfixSymbol = false,
    postfixSymbol = '%',
    usePositive = false,
    useNegative = true
  } = options;

  let formattedValue = value;

  if (value == null) {
    if (useDash) {
      return '-';
    }

    return null;
  }

  if (typeof value !== 'number') {
    return null;
  }

  if (useAbbreviation) {
    formattedValue = getValueAbbreviation(Math.abs(value));
  }

  if (usePrefixSymbol) {
    formattedValue = `${prefixSymbol}${formattedValue}`;
  }

  if (usePostfixSymbol) {
    formattedValue = `${formattedValue}${postfixSymbol}`;
  }

  if (usePositive && value > 0) {
    formattedValue = `+${formattedValue}`;
  }

  if (useNegative && value < 0) {
    formattedValue = `-${formattedValue}`;
  }

  return formattedValue;
};

export default formatValueWithSymbol;
