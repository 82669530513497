/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { BOX_KPI_WIDGET } from 'gql/analytics';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import { AlbTooltip } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';
import BoxHeader from './BoxHeader';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  kpiLabel: {
    fontSize: '15px',
    fontWeight: 500,
    marginTop: '5px'
  },
  kpiDate: {
    fontWeight: 400,
    color: '#6F6F6F'
  },
  container: {
    flex: 1,
    padding: '20px',

    '& > :nth-child(odd)': {
      backgroundColor: '#F5F5F5'
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: '35px 35px'
  },
  range: {
    fontSize: '30px',
    fontWeight: 500,
    color: '#32327D'
  },
  label: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#6F6F6F'
  },
  value: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#6F6F6F'
  },
  dates: {
    marginTop: '5px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#6F6F6F'
  }
});

const BoxContentKPIValue = props => {
  const { range, topLabel, middleLabel, bottomLabel, value, isLoading, onClick } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      item
      sm={12}
      md={4}
      direction="column"
      align="center"
      className={classes.content}
      {...(onClick && { style: { cursor: 'pointer' }, onClick })}
    >
      {isLoading && <AlbLoading />}
      {topLabel && (
        <Grid item className={classes.label}>
          {topLabel}
        </Grid>
      )}
      <Grid item className={classes.range}>
        {range}
      </Grid>
      {middleLabel && (
        <Grid item className={classes.label}>
          {middleLabel}
        </Grid>
      )}
      <Grid item className={classes.value}>
        {value ? value.toLocaleString() : '-'}
      </Grid>
      {bottomLabel && (
        <Grid item className={classes.label}>
          {bottomLabel}
        </Grid>
      )}
    </Grid>
  );
};

BoxContentKPIValue.propTypes = {
  range: PropTypes.string.isRequired,
  topLabel: PropTypes.string,
  middleLabel: PropTypes.string,
  bottomLabel: PropTypes.string,
  value: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

BoxContentKPIValue.defaultProps = {
  topLabel: null,
  middleLabel: null,
  bottomLabel: null,
  value: null,
  onClick: null
};

const BoxContentKPIContainer = props => {
  const { tabs, accounts, dates, onlyVideo, defaultMetric } = props;
  const drawerContext = useContext(PostDrawerViewContext);
  const [isLoading, setIsLoading] = useState(false);
  const [metric, setMetric] = useState(defaultMetric);
  const [activeTab, setActiveTab] = useState(0);

  const classes = useStyles();

  const contentKPIResult = [
    {
      key: 'fifty',
      range: '50%',
      topLabel: 'To Be in the Top',
      middleLabel: 'Of All Posts',
      bottomLabel: `${tabs[activeTab]} Needed`
    },
    {
      key: 'ninety',
      range: '10%',
      topLabel: 'To Be in the Top',
      middleLabel: 'Of All Posts',
      bottomLabel: `${tabs[activeTab]} Needed`
    },
    {
      key: 'ninety_five',
      range: '5%',
      topLabel: 'To Be in the Top',
      middleLabel: 'Of All Posts',
      bottomLabel: `${tabs[activeTab]} Needed`
    },
    {
      key: 'ninety_eight',
      range: '2%',
      topLabel: 'To Be in the Top',
      middleLabel: 'Of All Posts',
      bottomLabel: `${tabs[activeTab]} Needed`
    },
    {
      key: 'ninety_nine',
      range: '1%',
      topLabel: 'To Be in the Top',
      middleLabel: 'Of All Posts',
      bottomLabel: `${tabs[activeTab]} Needed`
    },
    {
      key: 'max',
      range: 'GOAT',
      topLabel: 'Greatest of All Time',
      bottomLabel: tabs[activeTab]
    }
  ];

  const { loading, error, data } = useQuery(BOX_KPI_WIDGET, {
    variables: {
      linkTokens: accounts.map(({ id }) => id),
      metric,
      onlyVideo
    }
  });

  const tooltipKey = onlyVideo ? 'voKpiRange' : 'kPiRange';

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading]);

  return (
    <Box className={classes.box}>
      <BoxHeader
        tabs={tabs}
        // kpi={[{ id: 0, name: 'All' }]}
        kpiOnChange={() => null}
        setMetric={setMetric}
        onTabChange={setActiveTab}
      />
      {error && <AlbError error={error} />}
      <Grid
        container
        className={classes.kpiLabel}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <span style={{ marginRight: '8px' }}>KPI RANGE (All Available Posts)</span>
        <AlbTooltip title={tooltipText[tooltipKey]} />
      </Grid>
      <Grid container className={classes.container}>
        {contentKPIResult.map(content => {
          const newValue = data?.boxKPIWidget?.[content.key]
            ? Math.round(data.boxKPIWidget[content.key])
            : null;

          const topPostId = data?.boxKPIWidget?.top_post_id;

          const newProps = {
            ...content,
            value: newValue,
            isLoading,
            dates,
            ...(content.key === 'max' &&
              topPostId && {
                onClick: () =>
                  drawerContext.toggleDrawer(true, topPostId, null, null, dates.start, dates.end)
              })
          };

          return <BoxContentKPIValue {...newProps} />;
        })}
      </Grid>
    </Box>
  );
};

BoxContentKPIContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired,
  onlyVideo: PropTypes.bool,
  defaultMetric: PropTypes.string.isRequired
};

BoxContentKPIContainer.defaultProps = {
  onlyVideo: false
};

export default BoxContentKPIContainer;
