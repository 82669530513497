import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

export const FS_CDN_URL = 'https://cdn.filestackcontent.com';
export const FS_THUMB_URL = `${FS_CDN_URL}/resize=width:300,height:300,fit:crop`;

export const ASSET_CREATE_FOLDER = gql`
  mutation($path: String!) {
    createAssetFolder(path: $path) {
      id
      uuid
      filename
    }
  }
`;

export async function apiAssetCreateFolder(data) {
  return apolloClientHelper(ASSET_CREATE_FOLDER, data, true);
}

export const ASSET_FIELD_NAMES = `
  auto_tags
  created_at
  filename
  filesize
  handle
  height
  id
  mime_type
  notes
  path
  tags
  signed_thumbnail_url
  signed_video_preview
  title
  uuid
  width
  fs_uuid
  duration
  thumbnail_url
  expires_at
  org_shared
  expires_at
  image_exif_offset
  image_orientation
  image_x_resolution
  image_y_resolution
  image_exif_length
  image_resolution_unit
  image_exif_width
  audio_bitrate
  audio_channels
  audio_codec
  audio_sample_rate
  ext_name
  fps
  video_bitrate
  video_codec
`;

// This owner call can be extremely expensive when you have a lot of assets as it requires an individual select for every asset, so try to avoid it if possible, or load it just when needed
export const ASSET_FIELD_NAMES_WITH_OWNER = `${ASSET_FIELD_NAMES}
owner {
  id
  first_name
  last_name
  title
  bio
  avatar_url
}
`;

const STOCK_PHOTO_FIELD_NAMES = `
  id
  description
  urls {
    raw
    full
    regular
    small
    thumb
  }
  width
  height
  `;

// Partial start/end parts of the queries used in the two different versions of GET_ASSETS below
const GET_ASSETS_START = `
  query getAssets(
    $after: Int
    $count: Int
    $orderBy: [String]
    $filterBy: String
    $folderName: String
    $fileName: String
    $keyword: String
    $containerId: ID
    $accounts: [ID]
  ) {
    assets(
      after: $after
      count: $count
      orderBy: $orderBy
      filterBy: $filterBy
      folderName: $folderName
      fileName: $fileName
      keyword: $keyword
      containerId: $containerId
      accounts: $accounts
    ) {
  `;

const GET_ASSETS_END = `
    }
  }
`;

export const GET_ASSETS = gql`
  ${GET_ASSETS_START}
  ${ASSET_FIELD_NAMES}
  ${GET_ASSETS_END}
`;

export const GET_ASSETS_WITH_OWNER = gql`
  query {
    recentlyContributedAssets {
      id
      filename
      mime_type
      signed_thumbnail_url
      created_at
      auto_tags
      owner {
        id
        first_name
        last_name
        title
        avatar_url
      }
    }
  }
`;

export const GET_FOLDER_FIRST_IMAGE_AND_COUNT = gql`
  query ($folderName: String) {
    getFirstImageAndCount (folderName: $folderName) {
      asset {
        ${ASSET_FIELD_NAMES}
      }
      count
    }
  }
`;

export const GET_PUBLIC_ASSETS = gql`
  query ($keyword: String) {
    getPublicOrgAssets (keyword: $keyword) {
      ${ASSET_FIELD_NAMES}
    }
  }
`;

export async function apiGetAssets(data) {
  return apolloClientHelper(GET_ASSETS, data, false);
}

export const GET_STOCK_PHOTOS = gql`
  query($text: String!) {
    getStockPhotos(text: $text) {
      total
      total_pages
      results {
        ${STOCK_PHOTO_FIELD_NAMES}
      }
    }
  }
`;

export async function apiGetStockPhotos(data) {
  return apolloClientHelper(GET_STOCK_PHOTOS, data, false);
}

export const GET_RANDOM_STOCK_PHOTOS = gql`
  query {
    getRandomPhotos {
      ${STOCK_PHOTO_FIELD_NAMES}
    }
  }
`;

export const ASSET_UPDATE = gql`
  mutation($id: ID!, $title: String, $notes: String, $tags: String, $auto_tags: String, $org_shared: Boolean, $expires_at: DateTimeISO) {
    updateAsset(id: $id, title: $title, notes: $notes, tags: $tags, auto_tags: $auto_tags, org_shared: $org_shared, expires_at: $expires_at) {
      ${ASSET_FIELD_NAMES}
    }
  }
`;

export async function apiAssetUpdate(data) {
  return apolloClientHelper(ASSET_UPDATE, data, true);
}

export const ASSET_DELETE = gql`
  mutation($id: ID!) {
    deleteAsset(id: $id) {
      result
      message
    }
  }
`;

export async function apiAssetDelete(data) {
  return apolloClientHelper(ASSET_DELETE, data, true);
}

const ASSET_DELETE_FOLDER = gql`
  mutation($path: String!) {
    deleteAssetFolder(path: $path) {
      result
      message
    }
  }
`;

export async function apiAssetDeleteFolder(data) {
  return apolloClientHelper(ASSET_DELETE_FOLDER, data, true);
}

const ASSET_RENAME = gql`
  mutation($path: String!, $newpath: String!) {
    renameAsset(path: $path, newpath: $newpath) {
      result
      message
    }
  }
`;

export async function apiAssetRename(data) {
  return apolloClientHelper(ASSET_RENAME, data, true);
}

const ASSET_RENAME_FOLDER = gql`
  mutation($path: String!, $newpath: String!) {
    renameAssetFolder(path: $path, newpath: $newpath) {
      result
      message
    }
  }
`;

export async function apiAssetRenameFolder(data) {
  return apolloClientHelper(ASSET_RENAME_FOLDER, data, true);
}

export const ASSET_FROM_HANDLE = gql`
  mutation($uploadfolder: String!, $handles: [FSHandle]!, $newName: String, $orgShared: Boolean) {
    createAssetFromHandles(uploadfolder: $uploadfolder, handles: $handles, newName: $newName, orgShared: $orgShared) {
      ${ASSET_FIELD_NAMES}
    }
  }
`;

export const IMPORT_FROM_HANDLE = gql`
  mutation($uploadfolder: String!, $handles: [FSHandle]!, $newName: String) {
    createAssetFromHandles(uploadfolder: $uploadfolder, handles: $handles, newName: $newName) {
      id
      filename
      filesize
      mime_type
      notes
      height
      width
      created_at
      handle
      uuid
    }
  }
`;

export async function apiAssetFromHandles(data) {
  return apolloClientHelper(
    ASSET_FROM_HANDLE,
    data,
    true // mutation
  );
}

export const ASSETS_GET_UL_POLICY = gql`
  query {
    getUploadPolicy {
      signature
      policy
      valid_until
      organization {
        id
        org_uuid
        name
      }
    }
  }
`;

export async function apiAssetsGetUploadPolicy(data) {
  return apolloClientHelper(
    ASSETS_GET_UL_POLICY,
    data,
    true // mutation
  );
}

const GET_FS_DL_URL = gql`
  mutation {
    getDownloadUrl {
      url
    }
  }
`;

export async function apiAssetsGetDownloadPolicy(data) {
  return apolloClientHelper(
    GET_FS_DL_URL,
    data,
    true // mutation
  );
}

export const VIDEO_SUBSCRIPTION = gql`
  subscription ($currentUserId: ID!) {
    videoConversion (currentUserId: $currentUserId) {
      ${ASSET_FIELD_NAMES}
    }
  }
`;

export const ASSET_TAG_RESULT = gql`
  subscription ($currentUserId: ID!) {
    assetTagCompletion (currentUserId: $currentUserId) {
      ${ASSET_FIELD_NAMES}
    }
  }
`;
