import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, Delete, Done, Close } from '@material-ui/icons';
import { Typography, TextField, Button, IconButton } from '@material-ui/core';
import Box from 'components/Box';

const styles = makeStyles({
  title: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#0A1734'
  }
});

const truncateValue = value => {
  if (value.length > 20) {
    return `${value.slice(0, 20)}...`;
  }
  return value;
};

const EditInPlaceInput = props => {
  const { onChange, value, useEdit, useDelete, useIcons, customTitleStyle } = props;
  const [inputValue, setInputValue] = useState(value);
  const [isEdit, setIsEdit] = useState(false);

  const classes = styles();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Box display="flex" alignItems="center">
      {!isEdit && (
        <Typography className={customTitleStyle || classes.title}>
          {truncateValue(value)}
        </Typography>
      )}
      {isEdit && !useIcons && (
        <>
          <TextField
            autoFocus
            variant="filled"
            value={inputValue}
            onChange={event => {
              setInputValue(event.target.value);
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsEdit(false);
            }}
            style={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onChange({ value: inputValue });
              setIsEdit(false);
            }}
            style={{ marginLeft: '4px' }}
            disabled={value === inputValue}
          >
            Save
          </Button>
        </>
      )}
      {isEdit && useIcons && (
        <>
          <TextField
            autoFocus
            variant="filled"
            value={inputValue}
            onChange={event => {
              setInputValue(event.target.value);
            }}
          />
          <IconButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setIsEdit(false);
            }}
            style={{ marginLeft: '8px' }}
          >
            <Close style={{ color: '#E81828' }} />
          </IconButton>
          <IconButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              onChange({ value: inputValue });
              setIsEdit(false);
            }}
            style={{ marginLeft: '4px' }}
            disabled={value === inputValue}
          >
            <Done style={{ color: '#00A84F' }} />
          </IconButton>
        </>
      )}
      {useEdit && !isEdit && (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setIsEdit(!isEdit);
          }}
          style={{ marginLeft: '24px' }}
        >
          <Edit />
        </IconButton>
      )}
      {useDelete && (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            onChange({
              delete: true
            });
          }}
          style={{ marginLeft: '8px' }}
        >
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};

EditInPlaceInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  useEdit: PropTypes.bool,
  useDelete: PropTypes.bool,
  useIcons: PropTypes.bool,
  customTitleStyle: PropTypes.string
};

EditInPlaceInput.defaultProps = {
  useEdit: false,
  useDelete: false,
  useIcons: false,
  customTitleStyle: null
};

export default EditInPlaceInput;
