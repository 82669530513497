/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FS_THUMB_URL } from 'gql/asset';

const styles = {
  root: {
    display: 'flex',
    position: 'relative'
  },
  numberOfImages: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  imageCard: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  loading: {
    backgroundColor: '#000000',
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  }
};

const ImageCounterOverlay = props => {
  const { width, height, rounded, onClickEvent, classes } = props;

  // Images can be modified if this component is called without images and a campaignEventId instead
  const { images } = props;

  return (
    <div className={classes.root}>
      {images?.length > 1 && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.numberOfImages}
        >
          <Typography> + {images.length - 1}</Typography>
        </Grid>
      )}
      {images?.length && images[0].signed_thumbnail_url ? (
        <img
          role="presentation"
          alt=""
          src={`${FS_THUMB_URL}${images[0].signed_thumbnail_url}`}
          width={width}
          height={height}
          className={onClickEvent && classes.imageCard}
          style={{
            opacity: images.length > 1 ? 0.4 : 1,
            ...(rounded && { borderRadius: '3px' })
          }}
          onClick={onClickEvent || null}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

ImageCounterOverlay.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()),
  width: PropTypes.number,
  height: PropTypes.number,
  rounded: PropTypes.bool,
  onClickEvent: PropTypes.func,
  classes: PropTypes.shape()
};

ImageCounterOverlay.defaultProps = {
  images: [],
  width: 300,
  height: 300,
  rounded: false,
  onClickEvent: null,
  classes: {}
};

export default withStyles(styles)(ImageCounterOverlay);
