import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import colors from 'util/colors';

const AlbTable = props => {
  const { tableData, tableColumns, tableOptions, tableThemeOverrides, tableComponents } = props;

  const tableTheme = createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTable: {
        paper: {
          boxShadow: 'none'
        },
        responsiveBase: {
          position: 'unset !important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          whiteSpace: 'nowrap',

          '&:nth-child(-n+2)': {
            width: 1
          }
        },
        sortAction: {
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(even)': {
            backgroundColor: '#f5f5f5'
          },
          '&:hover': {
            backgroundColor: colors.athensGray
          }
        }
      },
      ...tableThemeOverrides
    }
  });

  return (
    <MuiThemeProvider theme={tableTheme}>
      <MUIDataTable
        data={tableData}
        columns={tableColumns}
        options={tableOptions}
        components={tableComponents}
      />
    </MuiThemeProvider>
  );
};

AlbTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableOptions: PropTypes.shape().isRequired,
  tableThemeOverrides: PropTypes.shape(),
  tableComponents: PropTypes.shape()
};

AlbTable.defaultProps = {
  tableThemeOverrides: {},
  tableComponents: {}
};

export default AlbTable;
