/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Box from 'components/Box';
import { UPDATE_MENTION_GROUP_PROMOTION_TYPE } from 'gql/mention_group';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';
import theme from 'theme';

const promotionsTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    PrivateSwitchBase: {
      root: {
        padding: '0px'
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0px',
        marginRight: '0px'
      },
      labelPlacementTop: {
        marginLeft: '10px',
        marginRight: '0px'
      }
    },
    MuiIconButton: {
      label: {
        margin: '5px'
      }
    }
  }
});

/**
 * @method
 * @summary This component renders checkboxes to display and select the promotion_type for the mention group row @see NarrativeTable
 * @name NarrativeTablePromotion
 * @param {Object} props - React props passed to this component
 * @param {Object} props.promotionType - An object containing the string value representing the promotion_type for the mention group
 * @return {Object} - React JSX
 */
const NarrativeTablePromotion = props => {
  const { promotionType } = props;
  const [isCampaignChecked, setIsCampaignChecked] = useState(false);
  const [isSyndicationChecked, setIsSyndicationChecked] = useState(false);
  const [isPartnerChecked, setIsPartnerChecked] = useState(false);

  const [updateMentionGroupPromotionType, { loading, error, data }] = useMutation(
    UPDATE_MENTION_GROUP_PROMOTION_TYPE
  );

  useEffect(() => {
    if (promotionType?.value === 'campaign') {
      setIsCampaignChecked(true);
      setIsSyndicationChecked(false);
      setIsPartnerChecked(false);
    }

    if (promotionType?.value === 'syndication') {
      setIsCampaignChecked(false);
      setIsSyndicationChecked(true);
      setIsPartnerChecked(false);
    }

    if (promotionType?.value === 'partner') {
      setIsCampaignChecked(false);
      setIsSyndicationChecked(false);
      setIsPartnerChecked(true);
    }

    if (promotionType?.value == null) {
      setIsCampaignChecked(false);
      setIsSyndicationChecked(false);
      setIsPartnerChecked(false);
    }
  }, [promotionType?.value]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.updateMentionGroupPromotionType?.id) {
      showToast(`Narrative updated`, 'success');
      promotionType.dataRefetch();
    }
  }, [data]);

  useEffect(() => {
    if (isCampaignChecked) {
      setIsSyndicationChecked(false);
      setIsPartnerChecked(false);
    }
  }, [isCampaignChecked]);

  useEffect(() => {
    if (isSyndicationChecked) {
      setIsCampaignChecked(false);
      setIsPartnerChecked(false);
    }
  }, [isSyndicationChecked]);

  useEffect(() => {
    if (isPartnerChecked) {
      setIsCampaignChecked(false);
      setIsSyndicationChecked(false);
    }
  }, [isPartnerChecked]);

  return (
    <MuiThemeProvider theme={promotionsTheme}>
      <Box display="flex" justifyContent="center">
        <Box>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="C"
            labelPlacement="top"
            checked={isCampaignChecked}
            onChange={event => {
              setIsCampaignChecked(event.target.checked);
              if (event.target.checked === true) {
                updateMentionGroupPromotionType({
                  variables: {
                    id: promotionType.id,
                    promotionType: 'campaign'
                  }
                });
              }

              if (
                event.target.checked === false &&
                isSyndicationChecked === false &&
                isPartnerChecked === false
              ) {
                updateMentionGroupPromotionType({
                  variables: {
                    id: promotionType.id,
                    promotionType: null
                  }
                });
              }
            }}
            disabled={loading}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="P"
            labelPlacement="top"
            checked={isPartnerChecked}
            onChange={event => {
              setIsPartnerChecked(event.target.checked);
              if (event.target.checked === true) {
                updateMentionGroupPromotionType({
                  variables: {
                    id: promotionType.id,
                    promotionType: 'partner'
                  }
                });
              }

              if (
                event.target.checked === false &&
                isCampaignChecked === false &&
                isSyndicationChecked === false
              ) {
                updateMentionGroupPromotionType({
                  variables: {
                    id: promotionType.id,
                    promotionType: null
                  }
                });
              }
            }}
            disabled={loading}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="S"
            labelPlacement="top"
            checked={isSyndicationChecked}
            onChange={event => {
              setIsSyndicationChecked(event.target.checked);
              if (event.target.checked === true) {
                updateMentionGroupPromotionType({
                  variables: {
                    id: promotionType.id,
                    promotionType: 'syndication'
                  }
                });
              }

              if (
                event.target.checked === false &&
                isCampaignChecked === false &&
                isPartnerChecked === false
              ) {
                updateMentionGroupPromotionType({
                  variables: {
                    id: promotionType.id,
                    promotionType: null
                  }
                });
              }
            }}
            disabled={loading}
          />
        </Box>
      </Box>
    </MuiThemeProvider>
  );
};

NarrativeTablePromotion.propTypes = {
  promotionType: PropTypes.shape().isRequired
};

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see NarrativeTablePromotion
 * @name customPromotionRender
 * @param {Object} promotionType - Object passed from @see MUIDataTable
 * @return {Object} - React JSX
 */
const customPromotionRender = promotionType => {
  return <NarrativeTablePromotion promotionType={promotionType} />;
};

export default customPromotionRender;
