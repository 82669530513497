import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { useQuery } from '@apollo/client';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import { uniqBy } from 'lodash';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { Palette } from 'components/AlbGraphs/Legend';
import Box from 'components/Box';
import colors, { topPostsColors } from 'util/colors';
import theme from 'theme';
import RadioDropdown from '../RadioDropdown';
import { timeToEventTooltip } from '../../AlbGraphs/CustomTooltipTemplates';
import { lookBackTimeOptions, dimensionOptions, opportunityOptions } from '../SalesforceConsts';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  tooltip: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    pointerEvents: 'none'
  },
  hidden: {
    display: 'none !important'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  dropdowns: {
    display: 'flex'
  }
});

const tableTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputAdornment: {
      positionStart: {
        marginRight: '0px'
      }
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: '10px'
      }
    }
  }
});

const TimeToEventGraph = props => {
  const {
    lookBackTime,
    setLookBackTime,
    dimension,
    setDimension,
    opportunity,
    setOpportunity,
    page,
    rowsPerPage,
    data,
    loading,
    error
  } = props;

  const classes = useStyles();

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [nullDatasets, setNullDatasets] = useState([]);

  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipLookup, setTooltipLookup] = useState({});

  useEffect(() => {
    // labels we want to display
    const xAxisLabels = [5, 10, 20, 30, 90, 180, 270, 360, 720];
    const graphLabels = [];

    // Create an object to store the grouped data
    const groupedData = {};
    const tempTooltipLookup = {};

    data?.getSalesforceTimeToEvents.forEach(item => {
      const { label } = item;
      if (!groupedData[label]) {
        groupedData[label] = { tteRates: [], n: 0 };
      }
      groupedData[label].n = item.total_outcome_sample_size;
      groupedData[label].tteRates.push({
        rate: item.tte_rate,
        days: item.tte_days
      });
    });

    const after = page * rowsPerPage;

    const nullResult = [];

    // Convert the grouped data into an array of objects
    const result = Object.entries(groupedData)
      // sort groups by total_outcome_sample_size
      .sort((a, b) => b[1].n - a[1].n)
      .slice(after, after + rowsPerPage)
      .map(([label, { tteRates }], index) => {
        // data points sometimes don't come sorted - sort by days and make distinct (just in case)
        const sortedRates = uniqBy(
          tteRates.sort((a, b) => a.days - b.days),
          'days'
        );

        // generate graph x-axis labels using the first dataset
        if (!index) {
          sortedRates.forEach(dataPoint => {
            const xAxisLabel = xAxisLabels.includes(dataPoint.days) ? [dataPoint.days, 'DAYS'] : '';
            graphLabels.push(xAxisLabel);
          });
        }

        // build dataset to replace null values with the last non-null value
        let lastValue = null;
        let nullData = sortedRates.map(({ rate, days }, i) => {
          if (rate == null) {
            lastValue = sortedRates[i - 1].rate || lastValue;
            return { rate: lastValue, days };
          }

          return { rate: null, days };
        });

        // add trailing values to bridge gaps between solid and dashed lines
        nullData = nullData.map((value, i) => {
          if (nullData[i + 1]?.rate) {
            return nullData[i + 1];
          }

          if (!value.rate) {
            return nullData[i - 1];
          }

          return value;
        });

        tempTooltipLookup[label || '-'] = topPostsColors[index % topPostsColors.length];

        const dataset = {
          type: 'line',
          label: label || '-',
          backgroundColor: topPostsColors[index % topPostsColors.length],
          borderColor: topPostsColors[index % topPostsColors.length],
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          borderWidth: 2
        };

        nullResult.push({
          ...dataset,
          label: `${dataset.label}:null`,
          data: nullData,
          borderDash: [4, 4]
        });

        return {
          ...dataset,
          data: sortedRates.map(({ rate, days }) => ({ x: days, y: rate }))
        };
      });

    setLabels(graphLabels);
    setNullDatasets(nullResult);
    setDatasets(result);
    setTooltipLookup(tempTooltipLookup);
  }, [data, page, rowsPerPage]);

  const options = {
    // https://www.chartjs.org/docs/2.9.4/configuration/legend.html
    legend: {
      display: false
    },
    // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
    tooltips: {
      enabled: false,
      intersect: false,
      custom: tooltipModel => {
        const { offsetTop, offsetLeft } = canvasRef.current.canvas;
        const { dataPoints } = tooltipModel;
        // if tooltip.opacity is 0 then we need to hide the tooltip
        const tempIsTooltipVisible = tooltipModel.opacity !== 0;
        let tooltipWidth = 295;
        setIsTooltipVisible(tempIsTooltipVisible);

        let title;

        if (tooltipModel.body) {
          const [line] = tooltipModel.body[0].lines;
          const id = line.split(':');
          [title] = id;
        }

        if (tempIsTooltipVisible) {
          dataPoints.forEach(dataPoint => {
            if (tooltipRef.current) {
              const innerHTML = timeToEventTooltip({
                title,
                value: `${dataPoint.yLabel.toLocaleString()}%`,
                boxColor: tooltipLookup[title]
              });

              const style = {};

              const top = offsetTop + dataPoint.y;
              const left = offsetLeft + dataPoint.x;

              style.direction = 'ltr';
              style.top = `${top - 2.5}px`;
              style.left = `${left - 2.5}px`;

              if (tooltipRef.current.offsetWidth > tooltipWidth) {
                tooltipWidth = tooltipRef.current.offsetWidth;
              }

              if (left + tooltipWidth > canvasRef.current.canvas.getBoundingClientRect().width) {
                style.direction = 'rtl';
              }

              Object.assign(tooltipRef.current.style, style);
              Object.assign(tooltipRef.current, { innerHTML });
            }
          });
        }
      }
    },
    // https://www.chartjs.org/docs/2.9.4/general/interactions/
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    animation: {
      duration: 0 // general animation time
    },
    // https://www.chartjs.org/docs/2.9.4/general/performance.html#disable-animations
    responsiveAnimationDuration: 0, // animation duration after a resize
    scales: {
      xAxes: [
        {
          // https://www.chartjs.org/docs/2.9.4/axes/styling.html#grid-line-configuration
          gridLines: {
            display: false
          },
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/#tick-configuration
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/linear.html
          ticks: {
            callback: value => [value, 'DAYS'],
            max: 720,
            maxTicksLimit: 20,
            maxRotation: 0,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 8
          },
          type: 'logarithmic',
          stacked: false
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            callback: value => `${value.toLocaleString()}%`,
            precision: 2,
            beginAtZero: true,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 12
          },
          scaleLabel: {
            display: true,
            labelString: 'Conversion Rate %'
          },
          stacked: false
        }
      ]
    }
  };

  const graphData = canvas => {
    canvasRef.current = canvas.getContext('2d');

    return { labels, datasets: [...datasets, ...nullDatasets] };
  };

  return (
    <MuiThemeProvider theme={tableTheme}>
      <Grid container className={classes.container}>
        {error && <AlbError error={error} />}
        {loading && (
          <Grid item className={classes.loadingOverlay}>
            <AlbLoading />
          </Grid>
        )}
        <div style={{ width: '100%' }}>
          <Grid container justifyContent="space-between">
            <span>
              <BoxHeader tabs={['Close Rate']} />
            </span>
            <Grid item className={classes.dropdowns}>
              <RadioDropdown
                options={opportunityOptions}
                selectedOption={opportunity}
                setSelectedOption={setOpportunity}
              />
              <RadioDropdown
                options={dimensionOptions}
                selectedOption={dimension}
                setSelectedOption={setDimension}
                conditionalDisable={{
                  // if 'Non-Lead Generated Opportunities' is selected
                  condition: opportunity.value === false,
                  // disable these dimension values
                  disabledOptions: ['industry', 'country', 'lead_source']
                }}
              />
              <RadioDropdown
                options={lookBackTimeOptions}
                selectedOption={lookBackTime}
                setSelectedOption={setLookBackTime}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container style={{ padding: '30px', opacity: loading ? 0.5 : 1 }}>
          <Bar data={graphData} options={options} />
          <Grid container justifyContent="center">
            <Palette
              legend={{
                palette: datasets.map(({ label, backgroundColor: color }) => {
                  return {
                    label,
                    key: label,
                    color
                  };
                })
              }}
              sm
            />
          </Grid>
          <Box
            ref={tooltipRef}
            className={`${classes.tooltip} ${isTooltipVisible ? '' : classes.hidden}`}
          />
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

TimeToEventGraph.defaultProps = {
  loading: false,
  error: null,
  data: null
};

TimeToEventGraph.propTypes = {
  lookBackTime: PropTypes.shape().isRequired,
  setLookBackTime: PropTypes.func.isRequired,
  dimension: PropTypes.shape().isRequired,
  setDimension: PropTypes.func.isRequired,
  opportunity: PropTypes.shape().isRequired,
  setOpportunity: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  data: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.shape()
};

export default TimeToEventGraph;
