import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

const useStyles = makeStyles({
  container: {
    width: '100%',
    minWidth: '600px',
    height: '100%',
    maxHeight: '230px',
    position: 'relative',
    '& > canvas': {
      width: '100% !important'
    }
  }
});

const ActivityGraph = props => {
  const { data, seasonality, activityStartDate, activityEndDate } = props;
  const classes = useStyles();

  const graphOptions = {
    legend: { display: false },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'label',
      intersect: false,
      callbacks: {
        title(tooltipItem) {
          return moment.utc(tooltipItem[0].label).format('LL');
        },
        label(tooltipItem) {
          return `${tooltipItem.yLabel}`;
        },
        labelColor() {
          return {
            borderColor: '#C40075',
            backgroundColor: '#C40075',
            borderWidth: 0
          };
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: value => {
              return moment
                .utc(value)
                .format('MMM')
                .toUpperCase();
            },
            maxRotation: 0,
            maxTicksLimit: 12,
            fontColor: '#7A7A7A',
            fontSize: 10
          },
          gridLines: { display: false }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
            maxTicksLimit: 6,
            fontColor: '#7A7A7A',
            fontSize: 10
          },
          gridLines: { display: false }
        }
      ]
    },
    annotation: {
      annotations: [
        ...(seasonality != null
          ? [
              {
                backgroundColor: 'rgba(191,191,191,0.2)',
                borderColor: 'transparent',
                borderWidth: 0,
                type: 'box',
                xMax: seasonality.group_start_date,
                xMin: seasonality.group_end_date,
                xScaleID: 'x-axis-0'
              }
            ]
          : []),
        {
          backgroundColor: 'rgba(191,191,191,0.2)',
          borderColor: 'transparent',
          borderWidth: 0,
          type: 'box',
          xMax: activityStartDate,
          xMin: activityEndDate,
          xScaleID: 'x-axis-0'
        }
      ]
    },
    maintainAspectRatio: false
  };

  const xData = data.reduce((accumulator, currentValue) => {
    accumulator.push(currentValue.x);
    return accumulator;
  }, []);

  const yData = data.reduce((accumulator, currentValue) => {
    accumulator.push(currentValue.y);
    return accumulator;
  }, []);

  return (
    <div className={classes.container}>
      <Bar
        data={{
          labels: xData,
          datasets: [
            {
              type: 'line',
              data: yData,
              backgroundColor: ['#C40075'],
              borderColor: '#C40075',
              pointHoverBackgroundColor: '#C40075',
              pointHoverBorderColor: '#C40075',
              fill: false,
              lineTension: 0,
              pointRadius: 0,
              borderWidth: 1
            }
          ]
        }}
        options={graphOptions}
      />
    </div>
  );
};

ActivityGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  seasonality: PropTypes.shape(),
  activityStartDate: PropTypes.string.isRequired,
  activityEndDate: PropTypes.string.isRequired
};

ActivityGraph.defaultProps = {
  data: [],
  seasonality: null
};

export default ActivityGraph;
