import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'util/colors';
import AlembicPageTitle from 'components/AlembicPageTitle';
import AnalyticsBox from 'components/AnalyticsBox';
import { MIX_GROUP_OPTIONS_WITH_OVERALL } from 'views/DashboardUnderstand/util';
import ProgramLiftBreakDownTable from './ProgramLiftBreakdownTable';

const useStyles = makeStyles({
  container: {
    padding: '18px 20px 30px 20px'
  },
  title: {
    fontWeight: '500',
    fontSize: '24px',
    color: '#0A1734'
  },
  collapseRow: {
    padding: '8px 0px 16px 0px'
  },
  label: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0A1734'
  },
  subLabel: {
    marginLeft: '4px',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#979797'
  },
  collapseIconOverride: {
    cursor: 'pointer',
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInnerOverride: {
    paddingLeft: '20px',
    paddingRight: '8px',
    paddingBottom: '12px'
  }
});

const ProgramLiftBreakDown = props => {
  const { selectedDates, selectedPeriodical, isSwitchChecked, programLiftReportId } = props;

  const [selectedMixGroup, setSelectedMixGroup] = useState('OVERALL');

  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={3} className={classes.container}>
      <Grid className={classes.headingContainer} container direction="column">
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <AlembicPageTitle nomargin title="Show me the biggest" />
          </Box>
          <TextField
            select
            variant="filled"
            value={selectedMixGroup}
            onChange={event => {
              setSelectedMixGroup(event.target.value);
            }}
          >
            {MIX_GROUP_OPTIONS_WITH_OVERALL.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <Box ml={2}>
            <AlembicPageTitle nomargin title="contributors" />
          </Box>
        </Box>
      </Grid>

      <Grid className={classes.container}>
        <AnalyticsBox
          noMargin
          noPadding
          prefix="mv"
          tabs={[
            `Program Lift ${
              MIX_GROUP_OPTIONS_WITH_OVERALL.find(({ value }) => value === selectedMixGroup).title
            } Breakdown`,
            `Non-Program Lift ${
              MIX_GROUP_OPTIONS_WITH_OVERALL.find(({ value }) => value === selectedMixGroup).title
            } Breakdown`
          ]}
          onChange={() => {}}
        >
          <ProgramLiftBreakDownTable
            selectedMixGroup={selectedMixGroup}
            selectedDates={selectedDates}
            selectedPeriodical={selectedPeriodical}
            isSwitchChecked={isSwitchChecked}
            inProgramLiftReport
            programLiftReportId={programLiftReportId}
          />
          <ProgramLiftBreakDownTable
            selectedMixGroup={selectedMixGroup}
            selectedDates={selectedDates}
            selectedPeriodical={selectedPeriodical}
            isSwitchChecked={isSwitchChecked}
            inProgramLiftReport={false}
            programLiftReportId={programLiftReportId}
          />
        </AnalyticsBox>
      </Grid>
    </Grid>
  );
};

ProgramLiftBreakDown.propTypes = {
  selectedDates: PropTypes.shape(),
  selectedPeriodical: PropTypes.string,
  isSwitchChecked: PropTypes.bool,
  programLiftReportId: PropTypes.string
};

ProgramLiftBreakDown.defaultProps = {
  selectedDates: null,
  selectedPeriodical: null,
  isSwitchChecked: null,
  programLiftReportId: null
};

export default ProgramLiftBreakDown;
