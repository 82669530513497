import {
  MediaPath,
  MediaImagesPath,
  MediaVideosPath,
  MediaStockPhotosPath
  // MediaSharedPath
} from 'util/paths';

export const mediaTabs = Object.freeze({
  HOME: 0,
  IMAGES: 1,
  VIDEOS: 2,
  STOCK_PHOTOS: 3
  // SHARED_MEDIA: 4
});

export const mediaLabels = {
  0: 'Media',
  1: 'Images',
  2: 'Videos',
  3: 'Stock Photos'
};

export const mediaRoutes = [
  { path: MediaPath, name: 'All', tabValue: mediaTabs.HOME },
  { path: MediaImagesPath, name: 'Images', tabValue: mediaTabs.IMAGES },
  { path: MediaVideosPath, name: 'Videos', tabValue: mediaTabs.VIDEOS },
  { path: MediaStockPhotosPath, name: 'Stock Photos', tabValue: mediaTabs.STOCK_PHOTOS }
  // { path: MediaSharedPath, name: 'Shared', tabValue: mediaTabs.SHARED_MEDIA }
];
