import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  page: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 600,
    height: '35px'
  },
  jump: {
    padding: '12px',
    color: '#B4B4B4'
  }
});

const PageOptions = props => {
  const { page: currentPage, lastPage, onPageChange } = props;

  const classes = useStyles();

  const pages = [...Array(lastPage).keys()].filter(
    page =>
      page === 0 ||
      page === currentPage ||
      page - 1 === currentPage ||
      page + 1 === currentPage ||
      page === lastPage - 1
  );

  return pages.map(page => {
    const isLastPage =
      pages[pages.length - 2] && pages[pages.length - 2] !== lastPage - 2 && page === lastPage - 1;
    const isFirstPage = pages[1] && pages[1] !== 1 && page === 0;

    return (
      <span key={page}>
        {!!isLastPage && <span className={`${classes.jump} ${classes.page}`}>...</span>}
        <IconButton
          style={{
            color: '#B4B4B4',
            ...(currentPage === page && { color: '#0A1734', pointerEvents: 'none' })
          }}
          className={classes.page}
          onClick={() => onPageChange(null, page)}
        >
          {page + 1}
        </IconButton>
        {!!isFirstPage && <span className={`${classes.jump} ${classes.page}`}>...</span>}
      </span>
    );
  });
};

PageOptions.propTypes = {
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default PageOptions;
