import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';

const useStyles = makeStyles({
  legendBox: {
    width: '18px',
    height: '18px',
    borderRadius: '3px'
  }
});

const LegendBox = props => {
  const { color } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.legendBox}
      style={{
        backgroundColor: color
      }}
    />
  );
};

LegendBox.propTypes = {
  color: PropTypes.string.isRequired
};

export default LegendBox;
