import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CostToRecreateTopTable from 'components/AlbTable/CostToRecreateTopTable';
import AlbLoading from 'components/AlbLoading';
import SocialLogo from 'components/SocialLogo';
import getSimpleSocialType from 'util/getSimpleSocialType';

const useStyles = makeStyles({
  image: {
    marginRight: '10px',
    borderRadius: '3px'
  },
  channel: {
    fontWeight: '600',
    fontSize: '13px',
    width: '250px',
    color: '#0A1734',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

/**
 * @summary Renders Top Channels Table for cost to recreate reports
 * @name TopChannelTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.topChannels - list of all top channels
 * @param {String} props.valuationType - valuation as cpm or cpa
 * @return {Object} - React JSX
 */
const TopChannelTable = props => {
  const { isLoading, topChannels, valuationType } = props;
  const classes = useStyles();

  // formatTable helper function
  const formatTable = dataSet =>
    dataSet.map(({ linktoken, shares, impressions, valuation }) => {
      return {
        channel: (
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Grid>
              <Box className={classes.image}>
                <SocialLogo type={getSimpleSocialType(linktoken?.type)} />
              </Box>
            </Grid>
            <Box>
              <Typography className={classes.channel}>{linktoken.remote_name}</Typography>
            </Box>
          </Box>
        ),
        shares,
        impressions,
        valuation
      };
    });

  const tableColumns = [
    { name: 'channel', label: 'ACCOUNT' },
    {
      name: valuationType === 'cpm' ? 'impressions' : 'shares',
      label: valuationType === 'cpm' ? 'IMPRESSIONS' : 'ACTIONS'
    },
    { name: 'valuation', label: 'VALUE' }
  ];

  if (isLoading) {
    return <AlbLoading />;
  }

  return (
    <CostToRecreateTopTable tableData={formatTable(topChannels)} tableColumns={tableColumns} />
  );
};

TopChannelTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  topChannels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  valuationType: PropTypes.string.isRequired
};

export default TopChannelTable;
