import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumbs } from '@material-ui/core';
import { Route, Redirect } from 'react-router-dom';
import { goToRoute } from 'util/routesHelpers';
import {
  MediaSharedPath,
  MediaSharedContainerPath,
  MediaSharedPublicPath,
  gatherTab
} from 'util/paths';
import { CONTAINERS } from 'gql/container';
import { showToast } from 'contexts/ToastContext';
import getCurrentContainerIdFromRedux from 'util/container';
import { formatCrumb } from 'util/assetNameValidation';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto'
  },
  crumbs: {
    display: 'flex',
    marginTop: '10px',
    marginLeft: '20px',
    color: '#0d1a3a',
    opacity: 0.5,
    fontSize: '12px',
    fontWeight: '500'
  },
  crumbValues: {
    color: '#0d1a3a',
    opacity: 0.5,
    fontSize: '12px',
    fontWeight: '500',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  lastCrumbValue: {
    color: '#0d1a3a',
    opacity: 0.5,
    fontSize: '12px',
    fontWeight: '500'
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
});

const getContainerIds = location => {
  const searchQuery = location.search;
  const searchParams = new URLSearchParams(searchQuery);
  const containerIds = searchParams.getAll('id');

  return containerIds;
};

// Routed Media Breadcrumbs
const MediaBreadcrumbs = props => {
  const { classes, location } = props;
  let containerIds = [];
  let sharedTab = false;

  if (location.pathname.includes(MediaSharedPath)) {
    containerIds = getContainerIds(location);
    sharedTab = true;
  }

  const { data: containerData, error } = useQuery(CONTAINERS, {
    skip: !containerIds.length,
    variables: {
      ids: containerIds
    }
  });

  if (error) {
    const currentContainer = getCurrentContainerIdFromRedux();

    showToast('Access denied.', 'error');

    return <Redirect to={`/${currentContainer}${MediaSharedPath}`} />;
  }

  const getToValue = (pathNames, currentValue, index) => {
    if (sharedTab) {
      const searchString = location.search;

      // we're inside a nested container
      if (searchString.length) {
        // lookup container id in containerData and construct path string.
        const containerIndex = containerData?.containers?.findIndex(
          c => c.name === currentValue.toString()
        );
        const containerIdsInPath = containerData?.containers
          ?.slice(0, containerIndex + 1)
          .map(c => c.id);

        // get all the containers before this one
        if (containerIdsInPath?.length) {
          const pathArray = [];

          containerIdsInPath.forEach((id, i) => {
            if (i !== 0) {
              pathArray.push(`&id=${id}`);
            } else {
              pathArray.push(`id=${id}`);
            }
          });

          const containerString = pathArray.join('');

          return `${MediaSharedContainerPath}?${containerString}`;
        }

        return MediaSharedPath;
      }

      return MediaSharedPath;
    }

    return `${gatherTab}/${pathNames.slice(0, index + 1).join('/')}`;
  };

  // split path into breadcrumb names. If in sharedTab & inside a nested container,
  // display the name of the container.
  const getPathNames = () => {
    if (sharedTab) {
      if (location.pathname.includes(MediaSharedContainerPath) && containerData?.containers) {
        const idsInLocation = getContainerIds(location);
        const containerNames = [];

        // get container names from location, not from data - as it may be stale.
        // ensures breadcrumbs always match url.
        idsInLocation.forEach(id => {
          const containerInData = containerData.containers.find(container => container.id === id);

          if (containerInData) {
            containerNames.push(containerInData.name);
          }
        });

        return ['Shared', ...containerNames];
      }
      if (location.pathname.includes(MediaSharedPublicPath)) {
        return ['Shared', 'Public'];
      }
      if (location.pathname.includes(MediaSharedPath)) {
        return ['Shared'];
      }
    }

    // if not in shared tab, we're inside the main media (folder) grid
    const pathArr = location.pathname.split('/');
    // make sure there are no empty values in path
    const cleanPathArr = pathArr.filter(name => name.length);
    const beginningIndex = cleanPathArr.indexOf('media');

    return cleanPathArr.slice(beginningIndex, cleanPathArr.length);
  };

  return (
    <div className={classes.root}>
      <Route>
        {({ history }) => {
          const pathNames = getPathNames();

          return (
            <Breadcrumbs className={classes.crumbs} aria-label="Breadcrumb">
              {pathNames.map((value, index) => {
                const last = index === pathNames.length - 1;
                const to = getToValue(pathNames, value, index);

                return last ? (
                  <h3 key={value} className={classes.lastCrumbValue} variant="h4">
                    {formatCrumb(value)}
                  </h3>
                ) : (
                  <h3
                    className={classes.crumbValues}
                    key={value}
                    onClick={() => goToRoute(to, history)}
                    role="presentation"
                  >
                    {formatCrumb(value)}
                  </h3>
                );
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </div>
  );
};

MediaBreadcrumbs.propTypes = {
  classes: PropTypes.shape(),
  location: PropTypes.shape()
};

MediaBreadcrumbs.defaultProps = {
  classes: {},
  location: {}
};

export default withStyles(styles)(MediaBreadcrumbs);
