/* eslint-disable camelcase */
import store from 'store/index';
import { apiGetUserPrivs, apiGetUserFeatureFlags, GET_ME, GET_USER_FEATURE_FLAGS } from 'gql/user';
import { CONTAINER } from 'gql/container';
import { apolloClientQuery } from 'middleware/api';
import client from 'middleware/apolloClient';
import { EditContainerPath, ManageUsersPath, ManageOrganizationPath, MediaPath } from 'util/paths';
import { showToast } from 'contexts/ToastContext';
import {
  CHECK_EXISTING_AUTH,
  AUTHENTICATED,
  DEAUTHENTICATE,
  SWITCH_CONTAINER,
  UPDATE_CONTAINER,
  MFA_ENROLL,
  MFA_CHANGE_STATE,
  UPDATE_USER
} from './types';
import { SESSION_CURRENT_CONTAINER_KEY } from '../reducers/authReducer';
import { setOpenReplayData } from '../util/openreplay';

/**
 * convenience function for dispatch to check if the user is already authenticated
 * @returns {Object} Redux Action Object
 */
export const checkExistingAuth = () => ({
  type: CHECK_EXISTING_AUTH,
  isLoading: true
});

/**
 * convenience function for dispatch to tell the SPA that the user is authenticated
 * privs are optional in authenticate; they can be set or updated afterwards
 * @returns {Object} Redux Action Object
 */

export const authenticated = (
  user,
  container,
  organization,
  privs,
  featureFlags = [],
  token,
  expires
) => {
  return {
    type: AUTHENTICATED,
    user,
    container,
    organization,
    privs,
    featureFlags,
    token,
    expires
  };
};

/**
 * convenience function for dispatch to tell the SPA that the user has passed MFA (or not)
 * @param {Boolean} mfaPassed
 * @returns {Object} Redux Action Object
 */
export const mfaChangeState = mfaPassed => {
  return {
    type: MFA_CHANGE_STATE,
    mfaPassed
  };
};

/**
 * convenience function for dispatch to tell the SPA that the user has enrolled in MFA
 * @returns Object
 */
export const mfaEnroll = () => {
  return {
    type: MFA_ENROLL
  };
};

/**
 * Redux Action - log the user completely out of the local browser, removes
 * authentication and user data from the store.
 *
 * I do not know of any reason why you would not want to wipe local storage, but
 * if you don't, you can pass false to the function.
 * @param {Boolean} [clearLocalStorage=true] if True, also wipes local storage.
 * @returns {Object} Redux Action Object
 */
export const deauthenticate = (clearLocalStorage = true) => {
  return {
    type: DEAUTHENTICATE,
    clearLocalStorage
  };
};

/**
 * Redux Action - broadcast the currently active container
 * @param {Object} Container to store
 * @returns {Object} Redux Action Object
 */
export const updateContainer = container => {
  return {
    type: UPDATE_CONTAINER,
    container
  };
};

/**
 * Redux Action - Finalize a user's login. Sets privileges and feature flags.
 * This is dispatched by Redux when login completes for a user. Called from
 * Become, Invite, Login, MFA Input, ConfirmEmail, and RecoverWithToken
 *
 * @param {Object} user The user object from the API
 * @param {String} token The JWT token from the API
 * @param {String} expires The expiration date of the login
 * @param {Boolean} mfa_passed Whether or not the user passed MFA
 * @returns {Promise} A promise that resolves when the user is fully logged in
 */
export const completeLogin = async (user, token, expires, mfa_passed) => {
  let privs;
  const container = user?.home_container?.id ? user.home_container : null;

  // If the user object has privileges passed in just use those, otherwise fetch them now
  if (user.privileges) {
    privs = user.privileges.map(priv => priv.short_name);
  } else {
    privs = await apiGetUserPrivs();
  }

  // Any completeLogin action should also re-identify the user
  if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
    const trackerData = {
      displayName: `${user.first_name} ${user.last_name}`,
      organizationId: user.organization_id,
      email: user.email
    };
    setOpenReplayData(user.uuid, trackerData);
  }

  // fetch user feature flags
  const featureFlagsData = await apiGetUserFeatureFlags();
  const featureFlags = featureFlagsData?.payload?.data?.getFeatureFlagsForUser;

  // fully deauthenticate the user and wipe localstorage
  store.dispatch(deauthenticate(true));

  // authenticated sets the user, container, and organization inside of the store
  // it also sets the per-window sesion storage for the current container (SESSION_CURRENT_CONTAINER_KEY)
  store.dispatch(
    authenticated(
      user,
      container,
      user?.current_organization,
      privs,
      featureFlags,
      token, // note that this is only available on signIn. We do not refresh JWT tokens.
      expires
    )
  );

  // last, set mfa state.
  store.dispatch(mfaChangeState(mfa_passed));
};

/**
 * Redux Action - sets localStorage MFA state and requests currently logged in
 * user from the API
 *
 * @param {Object} user The user object from the API. If null, will refetch the user from the API.
 */
export const updateUser = async (user = null) => {
  let meResult;
  let apiUser = user;

  if (!apiUser) {
    meResult = await apolloClientQuery(GET_ME, {});
    apiUser = meResult?.data?.me.user;
  }

  store.dispatch({
    type: UPDATE_USER,
    user: apiUser
  });
};

/**
 * Redux Action - switches the current container
 * @param {Integer} containerId The ID of the container to switch to
 * @returns Dispatch Function
 */
export const switchContainer = containerId => {
  return async dispatch => {
    const { pathname } = window.location;
    // eslint-disable-next-line no-unused-vars
    const [empty, currentContainerId, ...rest] = pathname.split('/');

    const route = `/${rest.join('/')}`;

    if (route === EditContainerPath || route === ManageUsersPath) {
      window.location.href = `/${containerId}${ManageOrganizationPath}`;
    } else if (route.startsWith(MediaPath)) {
      window.history.replaceState({}, document.title, `/${containerId}${MediaPath}`);
    } else {
      window.history.replaceState({}, document.title, `/${containerId}${route}`);
    }
    // Get new container data
    const {
      data: { container }
    } = await apolloClientQuery(CONTAINER, { id: containerId });

    // Dispatch action with new container
    return new Promise(resolve => {
      dispatch({
        type: SWITCH_CONTAINER,
        container
      });

      resolve();
    }).then(() => {
      client.reFetchObservableQueries();
    });
  };
};

/**
 * Redux Action - Dispatched after initial page load if the user is holding a
 * session in localStorage. Verifies login and either deauthenticates the user
 * entirely or sets UI state to logged in.
 * @returns Redux Dispatch Method
 */
export const restoreAuthentication = async () => {
  let apiUser;
  let apiSession;
  let featureFlags;

  try {
    // The first thing we do is ask the API who we are. It is the source of
    // truth because we use session authentication.
    const meResult = await apolloClientQuery(GET_ME, {});
    apiUser = meResult?.data?.me.user;
    apiSession = meResult?.data?.me.session;

    if (!apiUser) {
      // we can't find your login on the API, so we're going to log you out.
      store.dispatch(deauthenticate(true));
      return;
    }

    const ffResult = await apolloClientQuery(GET_USER_FEATURE_FLAGS, {});
    featureFlags = ffResult?.data?.getFeatureFlagsForUser;
  } catch (error) {
    showToast(`error getting user: ${error}`, 'error');
    store.dispatch(deauthenticate(true));
    return;
  }

  let container = apiUser.home_container;
  const { pathname } = window.location;
  const pathContainerId = pathname.split('/')[1];

  if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production') {
    const trackerData = {
      displayName: `${apiUser.first_name} ${apiUser.last_name}`,
      organizationId: apiUser.organization_id,
      email: apiUser.email
    };

    setOpenReplayData(apiUser.uuid, trackerData);
  }

  // container id in the route takes precedence over container id in session storage
  const activeContainerStr = sessionStorage.getItem(SESSION_CURRENT_CONTAINER_KEY);
  let activeContainerId = parseInt(activeContainerStr, 10) || null;

  // eslint-disable-next-line no-restricted-globals
  if (pathContainerId && !isNaN(pathContainerId) && container.id !== pathContainerId) {
    activeContainerId = pathContainerId;
  }

  if (activeContainerId !== null) {
    const {
      data: { container: newContainer }
    } = await apolloClientQuery(CONTAINER, { id: activeContainerId });
    container = newContainer;
  }

  // Finish the action by setting the user data
  store.dispatch(
    authenticated(
      apiUser,
      container,
      apiUser?.current_organization,
      apiUser.privileges.map(priv => priv.short_name),
      featureFlags,
      localStorage.getItem('alb_jwt_token'),
      apiSession.expires
    )
  );
};
