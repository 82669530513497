/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const COINCIDENT_EVENT_GROUPS = gql`
  query coincidentEventGroups($startDate: DateTimeISO, $endDate: DateTimeISO) {
    coincidentEventGroups(startDate: $startDate, endDate: $endDate) {
      id
      group_start_date
      group_end_date
      seasonality {
        id
        group_start_date
        group_end_date
      }
      timeseries {
        x
        y
      }
    }
  }
`;
