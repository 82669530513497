import { gql } from '@apollo/client';
import { ASSET_FIELD_NAMES } from './asset';

export const CAMPAIGN_EVENT_FIELD_NAMES = `
  id
  post_group_uuid
  post_id
  start_date
  event_title
  event_body
  type
  state
  error
  linked_account_id
  linked_account {
    id
    remote_name
    type
  }
  created_by {
    id
    first_name
    last_name
  }
  deleted_at
  published_url
  thumbnail_url
  assets {
    ${ASSET_FIELD_NAMES}
  }
  has_photo
  has_video
  campaign_id
  campaign {
    id
    name
  }
  theme_id
  theme {
    id
    name
  }
  topic_id
  topic {
    id
    name
  }
`;

export const CAMPAIGN_EVENT = gql`
  query($id: ID, $post_id: ID, $linktoken_id: ID) {
    campaign_event(id: $id, post_id: $post_id, linktoken_id: $linktoken_id) {
      ${CAMPAIGN_EVENT_FIELD_NAMES}
    }
  }
`;

export const CAMPAIGN_EVENTS_BY_STATE = gql`
  query($states: [String!], $after: Int, $count: Int, $orderBy: [String]) {
    campaign_events_by_state(states: $states, after: $after, count: $count, orderBy: $orderBy) {
      rows {
        groupId
        campaignEvents {
          ${CAMPAIGN_EVENT_FIELD_NAMES}
        }
      }
      count
    }
  }
`;

export const CAMPAIGN_EVENTS_BY_UUID = gql`
  query($post_group_uuid: String!) {
    campaign_events_by_uuid(post_group_uuid: $post_group_uuid) {
      ${CAMPAIGN_EVENT_FIELD_NAMES}
    }
  }
`;

export const CAMPAIGN_EVENTS_CALENDAR = gql`
  query {
    campaign_events_calendar {
      groups {
        id
        name
        start_date
        end_date
      }
      items {
        startDate
        endDate
        type
        campaign
        events {
          post_date
          number_of_post
        }
      }
    }
  }
`;

export const CAMPAIGN_EVENTS_DASHBOARD = gql`
  query($start_date: String!, $end_date: String!) {
    campaign_events_dashboard(start_date: $start_date, end_date: $end_date) {
      groups {
        id
        name
        start_date
        end_date
      }
      items {
        startDate
        endDate
        type
        campaign
        events {
          post_date
          number_of_post
        }
      }
    }
  }
`;

export const CAMPAIGN_EVENTS_BY_DAY = gql`
  query($date: String!, $type: String, $campaign: ID) {
    campaign_events_by_day(date: $date, type: $type, campaign: $campaign) {
      id
      post_group_uuid
      event_title
      event_body
      display_title
      start_date
      linked_account {
        id
        remote_name
        type
      }
      calendar_assets {
        id
        signed_thumbnail_url
      }
      state
      type
      published_url
      email {
        id
        html
        css
      }
      lifetime_impressions
      lifetime_engagement
    }
  }
`;

export const CAMPAIGN_EVENTS_BY_ASSET = gql`
  query($asset_id: ID!) {
    campaign_events_by_asset(asset_id: $asset_id) {
      id
      completed_at
      linked_account {
        id
        type
      }
      event_title
      lifetime_impressions
      lifetime_engagement
      lifetime_shares
      lifetime_views
    }
  }
`;

export const CAMPAIGN_EVENT_GROUP_CREATE = gql`
  mutation($events: [CreateCampaignEventInput!]!) {
    createGroupedCampaignEvents(events: $events) {
      id
      campaign_id
      event_title
      event_body
      start_date
      linked_account_id
      created_at
      post_group_uuid
      link
    }
  }
`;

export const CAMPAIGN_EVENT_GROUP_UPDATE = gql`
  mutation($events: [CreateCampaignEventInput!]!) {
    updateGroupedCampaignEvents(events: $events) {
      id
      campaign_id
      event_title
      event_body
      start_date
      linked_account_id
      created_at
      post_group_uuid
      link
    }
  }
`;

export const UPDATE_GROUPED_CAMPAIGN_EVENT_STATE = gql`
  mutation($postGroupUuid: String!, $newState: CampaignEventStateEnum!, $note: String) {
    updateGroupedCampaignEventsState(
      postGroupUuid: $postGroupUuid
      newState: $newState
      note: $note
    ) {
      post_group_uuid
      state
      denied_note
    }
  }
`;

export const UPDATE_CAMPAIGN_EVENT = gql`
  mutation($id: ID!, $campaignEventObject: CampaignEventInput) {
    updateCampaignEvent(id: $id, campaignEventObject: $campaignEventObject) {
      ${CAMPAIGN_EVENT_FIELD_NAMES}
    }
  }
`;

export const DELETE_GROUPED_CAMPAIGN_EVENT = gql`
  mutation($postGroupUuid: String!) {
    deleteGroupedCampaignEvent(postGroupUuid: $postGroupUuid)
  }
`;

export const ASSETS_FOR_CAMPAIGN_EVENT = gql`
  query($id: ID!) {
    campaign_event(id: $id) {
      assets {
        id
        signed_thumbnail_url
      }
    }
  }
`;

export const CAMPAIGN_EVENT_LIFETIME = gql`
  query($id: ID, $post_id: ID, $linktoken_id: ID) {
    campaign_event(id: $id, post_id: $post_id, linktoken_id: $linktoken_id) {
      id
      event_body
      published_url
      completed_at
      post_id
      linked_account {
        id
        type
        remote_name
        avatar_url
      }
      assets {
        id
        signed_thumbnail_url
      }
      type
      lifetime_impressions
      lifetime_reach
      total_comments
      ig_taps_forward
      ig_taps_backward
      ig_exits
      thumbnail_url
    }
  }
`;
