/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const START_2FA_ENROLLMENT = gql`
  mutation {
    enrollOTP {
      qr_code
      codes
      otpauth_url
    }
  }
`;

export const COMPLETE_2FA_ENROLLMENT = gql`
  mutation completeOTP($code: String!) {
    completeOTP(code: $code) {
      user {
        id
      }
      expires
    }
  }
`;

export const REGEN_2FA = gql`
  mutation regenOTPBackup($code: String!) {
    regenOTPBackup(code: $code) {
      codes
    }
  }
`;

export const DISABLE_2FA = gql`
  mutation disableOTP($code: String!) {
    disableOTP(code: $code) {
      user {
        id
      }
      expires
    }
  }
`;
