import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropUp as Arrow } from '@material-ui/icons';
import { formatCurrencyRound } from 'util/formatCurrency';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { AlbTooltip } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 18,
    color: '#FFF'
  },
  value: {
    fontSize: 40,
    color: '#FFF'
  },
  arrow: {
    height: '40px',
    width: '40px',
    margin: '-5px'
  },
  change: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 11,
    color: '#FFF'
  },
  floatRight: {
    float: 'right'
  },
  fullHeight: {
    height: '100%'
  }
}));

/**
 * Renders Salesforce revenue card
 * @name SalesforceRevenueCard
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const SalesforceRevenueCard = props => {
  const classes = useStyles();
  const { bgcolor, metricName, value, percentChange, difference, tooltipKey } = props;

  const [truncateValues, setTruncateValues] = useState(false);

  const handleResize = useCallback(() => setTruncateValues(window.innerWidth <= 1200));

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Grid className={classes.fullHeight} item>
      <Box
        className={classes.fullHeight}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        p={5}
        color="#0D1A3A"
        bgcolor={bgcolor}
      >
        <Box>
          <Box className={classes.floatRight}>
            <AlbTooltip title={tooltipText[tooltipKey]} iconColor="white" />
          </Box>
          <Typography className={classes.heading} align="center">
            {metricName}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.value} align="center">
            {value < 0 ? '-' : ''}
            {truncateValues
              ? getValueAbbreviation(Math.abs(value), true)
              : `$${formatCurrencyRound(Math.abs(value))}`}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.change} align="center">
            {!!difference && (
              <Arrow
                className={classes.arrow}
                style={{
                  ...(difference > 0 && { fill: '#00A84F' }),
                  ...(difference < 0 && { transform: 'rotate(180deg)' })
                }}
              />
            )}
            {percentChange}% {difference < 0 ? '-' : '+'}
            {truncateValues
              ? getValueAbbreviation(Math.abs(difference))
              : formatCurrencyRound(Math.abs(difference))}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

SalesforceRevenueCard.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  metricName: PropTypes.string.isRequired,
  value: PropTypes.number,
  percentChange: PropTypes.number,
  difference: PropTypes.number,
  tooltipKey: PropTypes.string.isRequired
};

SalesforceRevenueCard.defaultProps = {
  value: null,
  percentChange: null,
  difference: null
};

export default SalesforceRevenueCard;
