import { gql } from '@apollo/client';
import { ASSET_FIELD_NAMES } from './asset';

const LINKTOKEN_RESURGE_IMPRESSION = gql`
  query($linktoken_id: String!, $begin_date: String!, $end_date: String!) {
    linktokenResurgeImpression(
      linktoken_id: $linktoken_id
      begin_date: $begin_date
      end_date: $end_date
    ) {
      linktoken_id
      begin_date
      end_date
      lt_resurge_id
      peak_num_posts
      peak_delta_count
      peak_excess_count
      is_complete
      valuation
      follower_growth
      postresurgeimpressions {
        peak_count_diff
        resurgence_timeseries {
          x
          y
        }
        campaign_event {
          post_id
          type
          assets {
            ${ASSET_FIELD_NAMES}
          }
          thumbnail_url
          event_title
          completed_at
          event_body
          lifetime_views
          lifetime_engagement
          valuation
        }        
      }
    }
  }
`;

export default LINKTOKEN_RESURGE_IMPRESSION;
