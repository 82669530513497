import graph from './AnalyticsGraphConsts';
import table from './AnalyticsTableConsts';

export const tableColumns = {
  alembicImpressions: 'IMPRESSIONS',
  alembicLikes: 'ENGAGED',
  alembicShares: 'REACTIONS',
  alembicEngagement: 'TOTAL',
  alembicComments: 'COMMENTS',
  alembicSentiment: 'SENTIMENT',
  'alembicShares/alembicImpressions*100': 'VIRALITY',
  'starting(alembicFollowers)': 'EXISTING',
  'ending(alembicFollowers)': 'TOTAL',
  'ending(alembicFollowers)-starting(alembicFollowers)': 'CHANGE',
  'ending(alembicFollowers)/starting(alembicFollowers)*100': '%',
  post_impressions_organic: 'ORGANIC',
  'post_impressions_organic/alembicImpressions*100': 'ORGANIC %',
  post_impressions_paid: 'PAID',
  'post_impressions_paid/alembicImpressions*100': 'PAID %',
  'starting(alembicAllAges)': 'EXISTING',
  'ending(alembicAllAges)': 'TOTAL',
  'ending(alembicAllAges)-starting(alembicAllAges)': 'CHANGE',
  'ending(alembicAllAges)/starting(alembicAllAges)*100': '%',
  'ending(alembicAge13to17)/ending(alembicAllAges)*100': '13-17',
  'ending(alembicAge18to24)/ending(alembicAllAges)*100': '18-24',
  'ending(alembicAge25to34)/ending(alembicAllAges)*100': '24-34',
  'ending(alembicAge35to44)/ending(alembicAllAges)*100': '35-44',
  'ending(alembicAge45to54)/ending(alembicAllAges)*100': '45-54',
  'ending(alembicAge55to64)/ending(alembicAllAges)*100': '55-64',
  'ending(alembicAge65plus)/ending(alembicAllAges)*100': '65+',
  'ending(alembicEngagement)': 'ENGAGEMENT',
  'ending(alembicEngagement)/ending(alembicFollowers)*100': 'ENGAGEMENT %',
  'ending(alembicPositiveReactions)': 'POSITIVE REACTIONS',
  'ending(alembicSentiment)': 'SENTIMENT'
};

export const tableColumnsv2 = {
  lifetime_impressions: 'IMPRESSIONS',
  lifetime_engagement: 'ENGAGEMENT',
  lifetime_views: 'VIEWS',
  lifetime_positive_reactions: 'POSITIVE REACTIONS',
  lifetime_negative_reactions: 'NEGATIVE REACTIONS',
  lifetime_shares: 'SHARES',
  lifetime_reach: 'REACH',
  alembicEngagementRate: 'ENGAGEMENT %',
  total_comments: 'COMMENTS',
  sentiment: 'SENTIMENT',
  valuationModel: 'VALUATION'
};

export const storiesColumns = {
  lifetime_impressions: 'IMPRESSIONS',
  lifetime_reach: 'REACH',
  total_comments: 'REPLIES',
  alembicForwardRate: 'FORWARD RATE',
  alembicBackRate: 'BACK RATE',
  alembicExitRate: 'EXIT RATE'
};

export const videoEngagementColumns = {
  lifetime_impressions: 'IMPRESSIONS',
  lifetime_engagement: 'ENGAGEMENT',
  alembicEngagementRate: 'ENGAGEMENT %',
  total_comments: 'COMMENTS',
  lifetime_shares: 'SHARES',
  lifetime_positive_reactions: 'LIKES',
  lifetime_negative_reactions: 'DISLIKES',
  sentiment: 'SENTIMENT',
  yt_duration: 'LENGTH'
};

// situational text replacement
export const shortenText = {
  'United States Of America': 'USA',
  'United States': 'USA',
  total: 'Total',
  male: 'Men',
  female: 'Women'
};

export const impressionsWidget = [
  {
    tab: 'Impressions',
    graphDruid: graph.reachTotal,
    postsTable: table.impressionsTotalv2
  },
  {
    tab: 'Impressions by Type',
    tooltip: 'These metrics are only displayed for Facebook accounts.',
    graphDruid: graph.reachType,
    druidTable: {
      query: {
        ...table.impressionsType.query,
        linkTokenTypes: ['FB_PAGE']
      }
    },
    typeFilter: ['FB_PAGE']
  }
];

export const growthWidget = [
  {
    tab: 'Audience Gained/Lost',
    graphDruid: graph.growthRateTotal,
    tableDruid: table.growthTotalv2
  },
  {
    tab: 'Growth Rate Over Time',
    graphDruid: graph.growthRateTime,
    tableDruid: table.growthTotalv2
  }
];

export const engagementWidget = [
  {
    tab: 'Engagement',
    graphDruid: graph.engagementTotal,
    postsTable: table.engagementTotalv2
  },
  {
    tab: 'Engagement %',
    graphDruid: graph.engagementRate,
    postsTable: table.engagementRatev2
  },
  {
    tab: 'Comments',
    graphDruid: graph.engagementComments,
    postsTable: table.engagementCommentsv2
  },
  {
    tab: 'Shares',
    graphDruid: graph.engagementShares,
    postsTable: table.engagementSharesv2
  }
];

export const engagementVideoWidget = [
  {
    tab: 'Engagement',
    graphDruid: graph.engagementTotal,
    postsTable: table.engagementTotalVideov2
  },
  {
    tab: 'Engagement %',
    graphDruid: graph.engagementRate,
    postsTable: table.engagementRateVideov2
  },
  {
    tab: 'Comments',
    graphDruid: graph.engagementComments,
    postsTable: table.engagementCommentsVideov2
  },
  {
    tab: 'Shares',
    graphDruid: graph.engagementShares,
    postsTable: table.engagementSharesVideov2
  },
  {
    tab: 'Likes',
    graphDruid: graph.engagementLikes,
    postsTable: table.engagementLikesv2
  },
  {
    tab: 'Dislikes',
    graphDruid: graph.engagementDislikes,
    postsTable: table.engagementDislikesv2
  }
];

export const demographicsWidget = [
  {
    tab: 'Location Totals',
    graphDruid: graph.followersLocation,
    geoTable: 'locationTable'
  },
  {
    tab: 'Gender',
    graphDruid: graph.followersGender,
    ageTable: 'demographicGenderDruid'
  },
  {
    tab: 'Age',
    graphDruid: graph.followersAge,
    ageTable: 'demographicAgeDruid'
  }
];

export const webAndAppsDemographicsWidget = [
  {
    tab: 'Location Totals',
    graphDruid: graph.webAndAppsLocationMap,
    geoTable: 'webAndAppsLocationMap'
  },
  {
    tab: 'Gender',
    graphDruid: graph.webAndAppsGender,
    ageTable: 'webAndAppsGender'
  },
  {
    tab: 'Age',
    graphDruid: graph.webAndAppsAge,
    ageTable: 'webAndAppsAge'
  }
];

export const storiesWidget = [
  {
    tab: 'Instagram Stories',
    postsTable: table.instagramStories,
    tableType: 'stories'
  }
];

export const videoWidget = [
  {
    tab: 'Video Views',
    tooltip: 'These metrics are only displayed for Facebook and YouTube accounts.',
    graphDruid: graph.videoViews,
    druidTable: {
      query: {
        ...table.videoViews.query,
        linkTokenTypes: ['FB_PAGE', 'YOUTUBE']
      }
    },
    typeFilter: ['FB_PAGE', 'YOUTUBE']
  },
  {
    tab: 'Average % Viewed',
    tooltip: 'These metrics are only displayed for YouTube accounts.',
    graphDruid: graph.averagePercentageViewed,
    druidTable: {
      query: {
        ...table.videoViews.query,
        linkTokenTypes: ['YOUTUBE']
      }
    },
    typeFilter: ['YOUTUBE']
  }
];

export const watchTimeWidget = [
  {
    tab: 'Watch Time',
    tooltip: 'These metrics are only displayed for Facebook and YouTube accounts.',
    graphDruid: graph.watchTime,
    druidTable: {
      query: {
        ...table.watchTime.query,
        linkTokenTypes: ['FB_PAGE', 'YOUTUBE']
      }
    },
    typeFilter: ['FB_PAGE', 'YOUTUBE']
  },
  {
    tab: 'Average Watch Time',
    tooltip: 'These metrics are only displayed for Facebook and YouTube accounts.',
    graphDruid: graph.averageWatchTime,
    druidTable: {
      query: {
        ...table.watchTime.query,
        linkTokenTypes: ['FB_PAGE', 'YOUTUBE']
      }
    },
    typeFilter: ['FB_PAGE', 'YOUTUBE']
  }
];

export const videoGrowthWidget = [
  {
    tab: 'Audience Gained/Lost',
    graphDruid: graph.videoGrowthRateTotal,
    tableDruid: table.growthTotalv2
  },
  {
    tab: 'Growth Rate Over Time',
    graphDruid: graph.videoGrowthRateTime,
    tableDruid: table.growthTotalv2
  }
];

export const videoLengthWidget = [
  {
    tab: 'Video Length',
    postsTable: table.videoLength,
    tableType: 'length'
  }
];
