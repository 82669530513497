/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import CostToRecreateTopTable from 'components/AlbTable/CostToRecreateTopTable';
import AlbLoading from 'components/AlbLoading';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';

const useStyles = makeStyles({
  image: {
    objectFit: 'cover',
    width: '58px',
    height: '58px',
    float: 'left',
    marginRight: '10px',
    borderRadius: '3px'
  },
  post: {
    fontWeight: '600',
    fontSize: '13px',
    textAlign: 'left',
    width: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

/**
 * @summary Renders Top Posts Table for cost to recreate reports
 * @name TopPostTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.topChannels - list of all top posts
 * @param {String} props.valuationType - valuation as cpm or cpa
 * @return {Object} - React JSX
 */
const TopPostTable = props => {
  const { isLoading, topPosts, valuationType } = props;
  const classes = useStyles();
  const drawerContext = useContext(PostDrawerViewContext);

  // formatTable helper function
  const formatTable = dataSet =>
    dataSet.map(({ campaignEvent, shares, impressions, valuation }) => {
      let image;

      if (campaignEvent?.type === 'YOUTUBE_VIDEO' && campaignEvent?.assets?.length === 0) {
        image = campaignEvent?.thumbnail_url;
      } else if (campaignEvent?.assets?.[0]) {
        image = `https://cdn.filestackcontent.com/resize=width:116,height:116${campaignEvent?.assets?.[0]?.signed_thumbnail_url}`;
      }
      return {
        post: (
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Grid>
              {image ? (
                <img src={image} width={58} height={58} className={classes.image} alt="" />
              ) : (
                ''
              )}
            </Grid>
            <Box>
              <Button
                style={{ backgroundColor: 'transparent' }}
                onClick={() => drawerContext.toggleDrawer(true, campaignEvent.id)}
              >
                <Typography className={classes.post}>
                  {campaignEvent?.type === 'YOUTUBE_VIDEO'
                    ? campaignEvent.event_title
                    : campaignEvent.event_body}
                </Typography>
              </Button>
            </Box>
          </Box>
        ),
        shares,
        impressions,
        valuation
      };
    });

  const tableColumns = [
    { name: 'post', label: 'POST' },
    {
      name: valuationType === 'cpm' ? 'impressions' : 'shares',
      label: valuationType === 'cpm' ? 'IMPRESSIONS' : 'SHARES'
    },
    { name: 'valuation', label: 'VALUE' }
  ];

  if (isLoading) {
    return <AlbLoading />;
  }

  return <CostToRecreateTopTable tableData={formatTable(topPosts)} tableColumns={tableColumns} />;
};

TopPostTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  topPosts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  valuationType: PropTypes.string.isRequired
};

export default TopPostTable;
