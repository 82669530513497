import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SpikeTotalUsersGraph from 'components/SpikeTotalUsersGraph';
import SpikeTable from 'components/SpikeTable';

const useStyles = makeStyles({
  datesBlock: {
    marginBottom: '20px',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: '#6f6f6f'
  },
  dateStyle: {
    fontWeight: 600,
    color: '#0a1734'
  },
  container: {
    padding: '0px 27px',
    paddingTop: '20px'
  }
});

const PageSpikeDrawerView = ({ event, dateRange }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.container}>
        <Grid item className={classes.datesBlock}>
          <div>
            <span className={classes.dateStyle}>Surge Start:</span>{' '}
            {(dateRange?.start).format('MMM DD, YYYY')}
          </div>
          <div>
            <span className={classes.dateStyle}>Surge End:</span>{' '}
            {(dateRange?.end).format('MMM DD, YYYY')}
          </div>
        </Grid>

        <SpikeTotalUsersGraph dates={dateRange} linktokenEventId={event?.id} spikeType="page" />
      </Grid>
      <SpikeTable linktokenEvent={event} />
    </>
  );
};

PageSpikeDrawerView.propTypes = {
  event: PropTypes.shape().isRequired,
  dateRange: PropTypes.shape().isRequired
};

export default PageSpikeDrawerView;
