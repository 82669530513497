import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DELETE_SMART_FILTER } from 'gql/smartFilter';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const DeleteSmartFilterModal = props => {
  const { isModalOpen, onChange, smartFilterId, name } = props;
  const [deleteSmartFilter, { error, data }] = useMutation(DELETE_SMART_FILTER);

  useEffect(() => {
    if (data?.deleteSmartFilter) {
      showToast(`Smart Filter deleted`, 'success');
      onChange({
        isModalOpen: false,
        filterDeleted: true
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Delete Smart Filter"
        body={`Are you sure you want to delete the saved Smart Filter: ${name}`}
        cancelTitle="Cancel"
        confirmTitle="Delete"
        handleCancel={() => {
          onChange({
            isModalOpen: false
          });
        }}
        handleConfirm={() => {
          deleteSmartFilter({
            variables: {
              id: smartFilterId
            }
          }).catch(() => {});
          // .catch to hack console error because apollo provides error handling
        }}
      />
    </>
  );
};

DeleteSmartFilterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  smartFilterId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

DeleteSmartFilterModal.defaultProps = {};

export default DeleteSmartFilterModal;
