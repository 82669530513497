import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TextField, MenuItem } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { RECREATE_COST_REPORTS } from 'gql/recreateCostReport';
import AlbLoading from 'components/AlbLoading';
import { CostToRecreateAnalyticsPath } from 'util/paths';
import { goToRouteReplace } from 'util/routesHelpers';
import handleGraphQLError from 'util/error';

/**
 * @method
 * @summary This component renders the report dropdown on the right of @see CostToRecreateHeader
 * @name CostToRecreateReportPicker
 * @param {Object} props - React props passed to this component
 * @param {string} props.history - The react router history object
 * @param {string} props.recreateCostReportId - The ID of the current report
 * @return {Object} - React JSX
 */
const CostToRecreateReportPicker = props => {
  const { location, recreateCostReportId } = props;
  const [selectedReport, setSelectedReport] = useState('');

  const { loading, error, data } = useQuery(RECREATE_COST_REPORTS, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.recreateCostReports?.rows?.length > 0) {
      data.recreateCostReports.rows.forEach(recreateCostReport => {
        if (recreateCostReport.id === recreateCostReportId) {
          setSelectedReport(recreateCostReport.id);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  return (
    <TextField
      select
      variant="filled"
      value={selectedReport}
      onChange={event => {
        setSelectedReport(event.target.value);
        goToRouteReplace(`${CostToRecreateAnalyticsPath}/${event.target.value}${location.search}`);
      }}
      style={{ minWidth: '247px' }}
    >
      <MenuItem hidden disabled selected value="" style={{ display: 'none' }}>
        Select Search To Display
      </MenuItem>
      {loading && (
        <MenuItem>
          <AlbLoading />
        </MenuItem>
      )}
      {data?.recreateCostReports?.rows?.map(recreateCostReport => {
        return (
          <MenuItem key={recreateCostReport.id} value={recreateCostReport.id}>
            {recreateCostReport.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

CostToRecreateReportPicker.propTypes = {
  location: PropTypes.shape().isRequired,
  recreateCostReportId: PropTypes.string.isRequired
};

export default withRouter(CostToRecreateReportPicker);
