import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Box from 'components/Box';
import AlembicPageHeader from 'components/AlembicPageHeader';
import setPageTitle from 'util/titles';
import AdobeAnalyticsConversionEventNamesTable from 'components/AlbTable/AdobeAnalyticsConversionEventNamesTable';
import AdobeAnalyticsMangePredictionEventsForm from './AdobeAnalyticsMangePredictionEventsForm';

/**
 * @method
 * @summary This component renders the flow for connecting adobe analytics
 * @name LinkAdobeAnalyticsAccount
 * @return {Object} - React JSX
 */
const AdobeAnalyticsManagePredictionEvents = () => {
  useEffect(() => {
    setPageTitle('Predict Adobe Analytics Conversions');
  }, []);

  return (
    <>
      <Box mb={30}>
        <AlembicPageHeader pageTitle="Predict Adobe Analytics Conversions" />
      </Box>

      <Paper>
        <Box p={24}>
          <AdobeAnalyticsMangePredictionEventsForm />
          <AdobeAnalyticsConversionEventNamesTable />
        </Box>
      </Paper>
    </>
  );
};

export default AdobeAnalyticsManagePredictionEvents;
