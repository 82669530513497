/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { client } from 'filestack-react';
import { apiAssetsGetUploadPolicy, apiGetAssets, apiAssetFromHandles } from 'gql/asset';
import ToastContext from 'contexts/ToastContext';
import ImageEditor from './ImageEditor';

const filestack = client.init(process.env.FS_API_KEY, {});

// recursively modify filename until we find one that doesn't match.
const generateNewFilename = async (name, count) => {
  let dir;
  let ext;

  if (count === 1 && !name.includes('(')) {
    dir = name.slice(0, name.indexOf('.'));
    ext = name.slice(name.indexOf('.'), name.length);
  } else {
    dir = name.slice(0, name.indexOf('('));
    ext = name.slice(name.indexOf('.'), name.length);
  }

  const newName = `${dir}(${count})${ext}`;
  const result = await apiGetAssets({ fileName: newName });

  if (result.payload.data.assets[0]) {
    const newCount = count + 1;
    return generateNewFilename(newName, newCount);
  }
  return newName;
};

// Checking if filename exists, and increment until no match is found.
const validateFilename = async filename => {
  const result = await apiGetAssets({ fileName: filename });

  if (result.payload.data.assets[0]) {
    try {
      const newName = await generateNewFilename(filename, 1);
      return newName;
    } catch (e) {
      return e;
    }
  } else {
    return false;
  }
};

class FileDetail extends React.Component {
  static propTypes = {
    file: PropTypes.shape().isRequired,
    close: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      fsSecurity: null,
      basicOptions: null
    };
  }

  componentDidMount() {
    this.getFSPolicy();
  }

  onSuccess = async (result, folder) => {
    const toast = this.context;
    const newHandles = [];
    const fsArray = { resultset: [result] };

    // eslint-disable-next-line array-callback-return
    Object.values(fsArray.resultset).map((value, i) => {
      newHandles.push({ id: fsArray.resultset[i].handle });
    });

    const response = await apiAssetFromHandles({
      uploadfolder: folder,
      handles: newHandles
    });

    if (!response.success) {
      return;
    }

    // notifications can be noisy. If we have more than three files, just say the count.
    if (response.payload.data.createAssetFromHandles.length > 3) {
      toast.showToast(
        `Successfully uploaded ${response.payload.data.createAssetFromHandles.length} files.`,
        'success'
      );
    } else {
      Object.values(response.payload.data.createAssetFromHandles).map((value, i) => {
        return toast.showToast(
          `Successfully uploaded ${response.payload.data.createAssetFromHandles[i].filename}`,
          'success'
        );
      });
    }
  };

  onSave = async (imageData, name) => {
    const { basicOptions, fsSecurity } = this.state;
    // eslint-disable-next-line no-alert
    let filename = prompt('Please enter a new file name', `${name}`);

    const nameExists = await validateFilename(filename);

    if (nameExists) {
      filename = nameExists;
    }

    filestack.upload(imageData, {}, { filename }, basicOptions, fsSecurity).then(results => {
      this.onSuccess(results, '');
    });
  };

  getFolderName = filename => {
    const pathArr = [];

    filename.split('/').map(part => pathArr.push(part));
    pathArr.pop();

    return pathArr.join('/');
  };

  getFSPolicy = async () => {
    const { file } = this.props;

    const folderName = this.getFolderName(file.filename);

    // get the current security policy for accessing ReactFilestack
    await apiAssetsGetUploadPolicy().then(result => {
      const basicOptions = {
        accept: 'image/*',
        maxSize: 1024 * 1024,
        maxFiles: 10,
        storeTo: folderName
      };

      // this doesn't match the reactfilestack docs but it does match their
      // code. wtf. security is set as props on the component and not as a
      // passed-in client like they say.
      this.setState({
        fsSecurity: {
          policy: result.payload.data.getUploadPolicy.policy,
          signature: result.payload.data.getUploadPolicy.signature
        },
        basicOptions
      });
    });
  };

  onClose = event => {
    const { close } = this.props;
    if (event) event.preventDefault();
    close();
  };

  render() {
    const {
      file,
      file: { filename },
      file: { signed_thumbnail_url }
    } = this.props;

    // only image assets should be loaded into the photo editor. If video comes in, shouldn't return anything.

    if (!file.mime_type.includes('video')) {
      const image = `https://cdn.filestackcontent.com/resize=height:500/${signed_thumbnail_url}`;

      return (
        <div>
          <ImageEditor image={image} name={filename} onSave={this.onSave} onClose={this.onClose} />
        </div>
      );
    }
    return null;
  }
}

FileDetail.contextType = ToastContext;

export default FileDetail;
