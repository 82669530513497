import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnalyticsBox from 'components/AnalyticsBox';
import AlbGraphDruid from 'components/AlbGraphs/AlbGraphDruid';
import HypePostTable from 'components/AlbTable/HypePostTable';
import graph from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import { getToolTipTitleValue } from 'components/AlbTooltip';

const CONFIG = ['alembicImpressions', 'alembicEngagement', 'alembicShares', 'alembicViews'];

const tableTabs = ['Impressions', 'Engagement', 'Shares', 'Video Views'];

const HypeMetricTimeSeries = props => {
  const { accounts, dates } = props;

  const [currentTab, setCurrentTab] = useState(0);

  const hypeGraphData = {
    ...graph.hype,
    druidQuery: {
      ...graph.hype.druidQuery,
      queryV2: CONFIG[currentTab]
    }
  };

  return (
    <>
      <AnalyticsBox
        useContainer
        tabs={tableTabs}
        onChange={newTabValue => setCurrentTab(newTabValue)}
        tooltip={getToolTipTitleValue('top10PostsInTimePeriod')}
      >
        <AlbGraphDruid tabData={hypeGraphData} accounts={accounts} dates={dates} graphHover />
        <AlbGraphDruid tabData={hypeGraphData} accounts={accounts} dates={dates} graphHover />
        <AlbGraphDruid tabData={hypeGraphData} accounts={accounts} dates={dates} graphHover />
        <AlbGraphDruid tabData={hypeGraphData} accounts={accounts} dates={dates} graphHover />
        <div
          shadow
          style={{
            height: '14px',
            background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
            mixBlendMode: 'multiply'
          }}
        />
        <HypePostTable table accounts={accounts} dates={dates} metric={CONFIG[currentTab]} />
      </AnalyticsBox>
    </>
  );
};

HypeMetricTimeSeries.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default HypeMetricTimeSeries;
