import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import Box from 'components/Box';
import CustomFooter from 'components/TablePagination';
import AlbTable from 'components/AlbTable';
import { AlbTooltip } from 'components/AlbTooltip';
import { TV_ICON, RADIO_ICON, PODCAST_ICON, ARTICLE_ICON } from 'util/assets';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import ArticleDrawerViewContext from 'contexts/ArticleDrawerViewContext';
import openDashboardDrawer from 'util/drawerUtil';
import customActionsRender from './MentionsTableActions';
import {
  MENTION_GROUP_SOURCE_ENUM,
  mapSourceToEventType,
  excludedRatingsSourceTypes
} from '../util';
import { MENTION } from '../ThirdPartyMediaConsts';

const useStyles = makeStyles(() => ({
  reportNameText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    marginLeft: '20px',
    whiteSpace: 'nowrap'
  },
  narrativeTitleText: {
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#0A1734'
    // whiteSpace: 'nowrap'
  },
  highlightMentionText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    wordBreak: 'break-word'
  },
  image: {
    height: '58px',
    width: '58px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    marginRight: '9px'
  }
}));

const EVENT_TABLE = {
  MENTIONS: 'mentions',
  ARTICLES: 'articles'
};

const MentionsTable = props => {
  const { isEnabled, dataResponse, dataRefetch, after, setAfter, sortOrder, setSortOrder } = props;

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const mentionDrawerContext = useContext(DashboardIntelligenceDrawerContext);
  const articleDrawerContext = useContext(ArticleDrawerViewContext);

  const classes = useStyles();

  const rowsPerPageOptions = [];

  const formatMentionsTableData = mentionTableData => {
    const tempTableData = [];

    mentionTableData.forEach(mention => {
      tempTableData.push({
        id: mention.id,
        reportName: {
          name: mention.title,
          mediaType:
            mention.table_name === EVENT_TABLE.ARTICLES
              ? MENTION_GROUP_SOURCE_ENUM.WEB
              : mention.source
        },
        airDate: mention.air_time,
        narrative: mention.group_seq !== null ? `Narrative ${mention.group_seq + 1}` : '-',
        mention: {
          highlightMentionText: mention.highlight_mention_text,
          thumbnail: mention.thumbnail
        },
        mentionsCount: 1,
        ...(!excludedRatingsSourceTypes.includes(mention.source) && {
          ratingsAndImpactCount: mention.viewership_count
        }),
        actions: {
          disabled: mention.disabled,
          id: mention.id,
          name: mention.title,
          type: mention.table_name,
          dataRefetch
        }
      });
    });

    return tempTableData;
  };

  useEffect(() => {
    if (dataResponse) {
      const { count: responseCount, rows } = dataResponse;

      setCount(responseCount);
      setTableData(formatMentionsTableData(rows));
    }
  }, [dataResponse]);

  const customReportNameRender = reportName => (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Box display="flex" justifyContent="center" style={{ width: '30px' }}>
        {reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.TV && (
          <img src={TV_ICON} alt="TV Icon" width={17} height={13} />
        )}
        {reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.RADIO && (
          <img src={RADIO_ICON} alt="Radio Icon" width={17} height={13} />
        )}
        {(reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.PODCAST_TRANSCRIPT ||
          reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.PODCAST_SUMMARY) && (
          <img src={PODCAST_ICON} alt="Podcast Icon" width={10} height={14} />
        )}
        {reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.WEB && (
          <img src={ARTICLE_ICON} alt="Article Icon" width={10} height={14} />
        )}
      </Box>

      <Typography variantMapping={{ body1: 'span' }} className={classes.reportNameText}>
        {reportName?.name || ''}
      </Typography>
    </Box>
  );

  const customDateRender = date => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {moment.utc(date).format('M/DD/YY')}
      <br />
      {`@ ${moment.utc(date).format('h:mma')}`}
    </div>
  );

  const customNarrativeRender = narrative => (
    <Typography
      variantMapping={{ body1: 'span' }}
      align={narrative !== '-' ? 'left' : 'center'}
      className={classes.narrativeTitleText}
    >
      {narrative}
    </Typography>
  );

  const customMentionRender = mention => (
    <Box display="flex" justifyContent="flex-start">
      {mention.thumbnail && (
        <div
          aria-hidden="true"
          className={classes.image}
          style={{ backgroundImage: `url('${mention.thumbnail}')` }}
        />
      )}
      <Tooltip
        title={mention?.highlightMentionText?.length > 30 ? mention.highlightMentionText : ''}
      >
        <Typography variantMapping={{ body1: 'span' }} className={classes.highlightMentionText}>
          {mention.highlightMentionText}
        </Typography>
      </Tooltip>
    </Box>
  );

  const customValueRender = value => (
    <div style={{ textAlign: 'right' }}>{value ? Math.round(value).toLocaleString() : '-'}</div>
  );

  const customHeadRender = tableMeta => {
    const tooltip = {
      narrative: 'Narrative',
      ratingsAndImpactCount: 'RatingsAndImpactCount'
    }[tableMeta.name];

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>{tableMeta.label}</span>
        <AlbTooltip lookupKey={tooltip} prefix="tpm" />
      </div>
    );
  };

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'reportName',
      label: 'REPORT NAME',
      options: {
        customBodyRender: customReportNameRender,
        sort: false
      }
    },
    {
      name: 'airDate',
      label: 'DATE / TIME',
      options: {
        customBodyRender: customDateRender
      }
    },
    {
      name: 'narrative',
      label: 'NARRATIVE',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customNarrativeRender,
        sort: false
      }
    },
    {
      name: 'mention',
      label: 'MENTION',
      options: {
        customBodyRender: customMentionRender,
        sort: false
      }
    },
    {
      name: 'mentionsCount',
      label: 'MENTIONS',
      options: {
        customBodyRender: customValueRender,
        sort: false
      }
    },
    {
      name: 'ratingsAndImpactCount',
      label: 'RATINGS / IMPACT',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender
      }
    },
    {
      name: 'actions',
      label: isEnabled ? 'EXCLUDE' : 'INCLUDE',
      options: {
        customBodyRender: customActionsRender,
        sort: false
      }
    }
  ];

  const mentionColumnIndex = tableColumns.map(({ name }) => name).indexOf('mention');

  const tableThemeOverrides = {
    MUIDataTableHeadRow: {
      root: {
        [`& > :not(:nth-child(${mentionColumnIndex}))`]: {
          width: 'auto'
        }
      }
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',

        '& th': {
          padding: '0px',
          borderBottom: 'none'
        }
      }
    }
  };

  const onCellClick = (rowData, { rowIndex, colIndex }) => {
    // prevent firing when clicking in actions col
    if (colIndex !== 7) {
      const mention = dataResponse.rows[rowIndex];
      const eventType = mention.table_name;

      if (mention && eventType === EVENT_TABLE.MENTIONS) {
        const tempDrawerDetails = {
          id: mention.id,
          reportName: mention.title,
          type: mapSourceToEventType(mention.source),
          mediaType: mention.source,
          drawerType: MENTION,
          narrativeName: mention.group_seq !== null ? `Narrative ${mention.group_seq + 1}` : '-',
          text: mention.highlight_mention_text,
          mentions: 1,
          ...(!excludedRatingsSourceTypes.includes(mention.source) && {
            ratings: mention?.viewership_count?.toLocaleString() ?? '-'
          }),
          dates: [moment.utc(mention.air_time).format('M/DD/YY')],
          chips: {
            ...(mention?.network_affiliation && {
              networkAffiliation: mention?.network_affiliation
            }),
            ...(mention?.station_name && {
              stationName: mention?.station_name
            }),
            ...(mention?.author && {
              podcastAuthor: mention?.author
            }),
            ...(mention?.program_title && {
              programTitle: mention?.program_title
            }),
            ...(mention?.podcast_title && {
              podcastTitle: mention?.podcast_title
            }),
            ...(mention?.source === MENTION_GROUP_SOURCE_ENUM.PODCAST_TRANSCRIPT && {
              mentionInTranscript: 'In Transcript'
            }),
            ...(mention?.source === MENTION_GROUP_SOURCE_ENUM.PODCAST_SUMMARY && {
              mentionInSummary: 'In Summary'
            }),
            ...(mention?.language && {
              mentionLanguage: mention?.language
            })
          },
          length: 1
        };

        openDashboardDrawer(tempDrawerDetails, mentionDrawerContext);
      }

      if (mention && eventType === EVENT_TABLE.ARTICLES) {
        articleDrawerContext.toggleDrawer(true, mention.id);
      }
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setAfter,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page: after,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    onCellClick
  };

  return (
    <AlbTable
      tableData={tableData}
      tableColumns={tableColumns}
      tableOptions={tableOptions}
      tableThemeOverrides={tableThemeOverrides}
    />
  );
};

MentionsTable.propTypes = {
  dataResponse: PropTypes.shape().isRequired,
  dataRefetch: PropTypes.func.isRequired,
  after: PropTypes.number.isRequired,
  setAfter: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  sortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEnabled: PropTypes.bool
};

MentionsTable.defaultProps = {
  isEnabled: false
};

export default MentionsTable;
