import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import AlembicTitleChip from 'components/AlembicTitleChip';

/**
 * @method
 * @summary This component renders the chips in the Search Criteria column for multiple tables
 * @name SearchCriteria
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.chips - The chips to be displayed in { title, body } format
 * @param {string} props.chips.title - The title of the chip
 * @param {string} props.chips.body - The body text of the chip
 * @return {Object} - React JSX
 */
const SearchCriteria = props => {
  const { chips } = props;

  return (
    <Grid container spacing={2}>
      {chips.map(({ title, body, icon }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item key={`${title}-${i}`}>
          <AlembicTitleChip title={title} body={body} icon={icon || null} />
        </Grid>
      ))}
    </Grid>
  );
};

SearchCriteria.propTypes = {
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired
    })
  )
};

SearchCriteria.defaultProps = {
  chips: []
};

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see SearchCriteria
 * @name customCriteriaRender
 * @param {Object} criteria - Object passed from @see MUIDataTable
 * @return {Object} - React JSX
 */
const customCriteriaRender = criteria => <SearchCriteria {...criteria} />;

export default customCriteriaRender;
