/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-did-update-set-state */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  FilledInput,
  Grid,
  TextField,
  Slide,
  DialogTitle,
  // Switch,
  // Tooltip,
  IconButton,
  Collapse,
  InputLabel,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
// import { Clear as ClearIcon } from '@material-ui/icons';

import { showToast } from 'contexts/ToastContext';
import { FS_THUMB_URL, FS_CDN_URL } from 'gql/asset';
import { VideoPlaceholderImage as videoPlaceholderImg } from 'util/assets';
import HasAnyPriv from 'components/HasAnyPriv';
// import Datetime from 'react-datetime';
import { convertToUserTimeZone } from 'util/date';
import PostSupport from './PostSupport';
import ApiButton from '../ApiButton';
import AssetTags from '../AssetTags';
import FileDetail from '../FileDetail';
import AlbLoading from '../AlbLoading';

const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    '& > .MuiGrid-item': {
      position: 'relative',
      marginBottom: theme.spacing(1.5),
      height: 'auto',
      width: '100%'
    },
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: '1em'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  deleteButton: {
    marginRight: theme.spacing(1)
  },
  dialog: {
    textAlign: 'center'
  },
  image: {
    marginBottom: theme.spacing(1),
    background: '#d3d3d3',
    '& img': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      position: 'relative'
    },
    '&:hover': {
      outline: '2px solid #0A1734',
      outlineOffset: '3px',
      cursor: 'pointer'
    }
  },
  timestamp: {
    fontSize: '10px',
    color: '#6f6f6f',
    textAlign: 'center',
    marginTop: '5px'
  },
  photoIcon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: 'none',
    width: '50%',
    height: 'auto'
  },
  photoIconBackground: {
    color: '#D3D3D3',
    width: '100%',
    paddingTop: '25%',
    paddingBottom: '25%',
    backgroundColor: theme.palette.grey[100]
  },
  // closeIcon: {
  //   cursor: 'pointer',
  //   float: 'right',
  //   marginTop: '5px',
  //   marginRight: '10px',
  //   width: '20px'
  // },
  closeIcon: {
    height: '45px',
    width: '45px',
    backgroundColor: '#0b1835',
    color: 'white',
    top: '40px',
    right: '40px',
    position: 'fixed',
    zIndex: 2
  },
  imageModal: {
    width: '100%',
    height: 'auto'
  },
  videoPlaceholderText: {
    position: 'absolute',
    top: '2%',
    left: '2%',
    fontSize: '14px'
  },
  loadingPlaceholder: {
    backgroundColor: '#000000',
    height: '300px',
    width: '100%',
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  Triangle: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '2',
    width: '0',
    height: '0',
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderLeft: '30px solid white'
  },
  publicToggle: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    color: '#6f6f6f',
    fontWeight: 500,
    textAlign: 'center'
  },
  clearIcon: {
    minWidth: '0'
  },
  fileDetailsGrid: {
    border: '1px solid black',
    padding: '3px'
  },
  editor: {
    background: '#f2f2f2',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  }
}));

const dialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const cleanName = filename => {
  // cut off the extension.
  const noExtensionName = filename.slice(0, filename.lastIndexOf('.'));

  // only the last element contains the name of the image (the prefix is the name of the folder).
  const finalName = noExtensionName.split('/').pop();

  return finalName;
};

const parseTagString = tagString => {
  return tagString
    ? tagString
        .split(',')
        .map(tag => tag.trim()) // Remove padded spaces
        .filter(tag => tag.length > 0) // Remove empty tags
    : [];
};

const MediaPreview = props => {
  const {
    count,
    deleteAsset,
    modal,
    onSelect,
    currentAsset,
    selectedAssets,
    currentCampaignEvents,
    updateAssetFields,
    sharedGrid,
    // addToUserLibrary,
    currentUser
  } = props;
  const classes = styles();

  const [fileDetails, updateFileDetails] = useState(null);
  const [showDeleteDialog, toggleDeleteDialog] = useState(false);
  const [showEditDialog, toggleEditDialog] = useState(false);
  const [showImagePreview, toggleImagePreview] = useState(false);
  const [publicAsset, updatePublicAsset] = useState(currentAsset?.org_shared || false);
  const [metaExpanded, toggleMetaExpanded] = useState(false);
  const [assetExpiryExpanded, toggleAssetExpiryExpanded] = useState(false);
  const [expiryDate, setExpiryDate] = useState(currentAsset?.expires_at || null);
  const isVideoAsset = currentAsset ? currentAsset.mime_type.includes('video') : false;

  useEffect(() => {
    if (currentAsset) {
      updateFileDetails({
        ...currentAsset,
        // split tags into arrays. If auto tags are null - the autotags webhook didn't come back yet and we display a pending state.
        // In all other cases values get parsed into an array.
        auto_tags: currentAsset.auto_tags === null ? null : parseTagString(currentAsset.auto_tags),
        tags: parseTagString(currentAsset.tags),
        title: currentAsset.title || cleanName(currentAsset.filename),
        expires_at: currentAsset.expires_at
      });

      updatePublicAsset(currentAsset.org_shared);

      if (!currentAsset.expires_at) {
        setExpiryDate(null);
      } else {
        setExpiryDate(convertToUserTimeZone(currentAsset.expires_at, currentUser.time_zone));
      }

      if (assetExpiryExpanded) {
        toggleAssetExpiryExpanded(false);
      }
    }
  }, [currentAsset]);

  const handleDeleteAsset = asset => {
    deleteAsset(asset);
    toggleDeleteDialog(!showDeleteDialog);
  };

  const updateField = e => {
    updateFileDetails({
      ...fileDetails,
      [e.target.name]: e.target.value
    });
  };

  const updateTags = ({ autoTags, userTags }) => {
    updateFileDetails({
      ...fileDetails,
      auto_tags: autoTags,
      tags: userTags
    });
  };

  const getExpiresAt = () => {
    let expires_at = currentAsset?.expires_at || null;

    if (
      currentAsset.expires_at !==
      convertToUserTimeZone(expiryDate, currentUser.time_zone).toISOString()
    ) {
      // valid new date was selected - not cleared
      if (expiryDate && expiryDate !== '') {
        const formattedExpiryDate = convertToUserTimeZone(
          expiryDate,
          currentUser.time_zone
        ).toISOString();

        expires_at = formattedExpiryDate;
      }

      // expiration date was cleared
      if (expiryDate === '') {
        expires_at = null;
      }
    }

    return expires_at;
  };

  const handleSave = () => {
    // eslint-disable-next-line camelcase
    const { auto_tags, tags } = fileDetails;
    const updatedAsset = {
      ...fileDetails,
      auto_tags: auto_tags && auto_tags.length > 0 ? auto_tags.join(',') : null,
      tags: tags.length > 0 ? tags.join(',') : null,
      org_shared: publicAsset,
      expires_at: getExpiresAt()
    };

    return updateAssetFields(updatedAsset);
  };

  if (!currentAsset) {
    return null;
  }

  const assetMetaFields = [
    { field: 'filesize', title: 'File Size (bytes)' },
    { field: 'width', title: 'Width' },
    { field: 'height', title: 'Height' },
    { field: 'image_orientation', title: 'Orientation' },
    { field: 'image_x_resolution', title: 'X Resolution' },
    { field: 'image_y_resolution', title: 'Y Resolution' },
    { field: 'audio_bitrate', title: 'Audio Bitrate' },
    { field: 'audio_channels', title: 'Audio Channels' },
    { field: 'audio_codec', title: 'Audio Codec' },
    { field: 'audio_sample_rate', title: 'Audio Sample Rate' },
    { field: 'ext_name', title: 'Extension' },
    { field: 'fps', title: 'Frame Rate' },
    { field: 'video_bitrate', title: 'Video Bitrate' },
    { field: 'video_codec', title: 'Video Codec' }
  ];

  const formatFieldValue = value => {
    if (typeof value === 'number' && Number.isFinite(value)) {
      return new Intl.NumberFormat().format(value);
    }

    return value;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      {/* Image */}
      <Grid
        item
        className={classes.image}
        onClick={
          currentAsset.signed_thumbnail_url
            ? () => toggleImagePreview(true)
            : () => showToast('Please wait until the video finishes loading.', 'warn')
        }
      >
        <div>
          {/* The only time signed_thumbnail_id is null, is if the asset is a newly uploaded video. */}
          {currentAsset.signed_thumbnail_url ? (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                src={`${FS_THUMB_URL}${currentAsset.signed_thumbnail_url}`}
                alt={currentAsset.filename}
              />
              {isVideoAsset && <div className={classes.Triangle} />}
            </div>
          ) : (
            <div className={classes.loadingPlaceholder}>
              <AlbLoading color="inherit" />
            </div>
          )}
        </div>
      </Grid>
      {/* Full-size Asset Preview */}
      <Dialog open={showImagePreview} onClose={() => toggleImagePreview(false)}>
        {isVideoAsset && currentAsset.signed_video_preview && (
          <ReactPlayer url={currentAsset.signed_video_preview} playing controls width="100%" />
        )}
        {isVideoAsset && !currentAsset.signed_video_preview && (
          <div>
            <img src={videoPlaceholderImg} alt="video" width="100%" height="auto" />
            <p className={classes.videoPlaceholderText}>Please refresh page to see video.</p>
          </div>
        )}
        {!isVideoAsset && (
          <img
            src={`${FS_CDN_URL}${currentAsset.signed_thumbnail_url}`}
            alt={currentAsset.filename}
            className={classes.imageModal}
          />
        )}
      </Dialog>
      {/* Image editor */}
      {!modal && !isVideoAsset && !sharedGrid && (
        <HasAnyPriv privs={['SVC:ASSET:UPDATE']}>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => toggleEditDialog(true)}
            >
              Edit Photo
            </Button>
            <Dialog
              disableEnforceFocus
              fullScreen
              TransitionComponent={dialogTransition}
              open={showEditDialog}
              onClose={() => toggleEditDialog(false)}
              classes={{ paperFullScreen: classes.editor }}
            >
              <DialogTitle style={{ padding: '0' }}>
                <IconButton onClick={() => toggleEditDialog(false)} className={classes.closeIcon}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <FileDetail file={currentAsset} close={() => toggleEditDialog(false)} />
            </Dialog>
          </Grid>
        </HasAnyPriv>
      )}
      {/* Modal: Read-only file details */}
      {modal && (
        <Grid item>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => {
              onSelect(selectedAssets);
            }}
          >
            {`Select - ${count}`}
          </Button>
          <p>{currentAsset?.title ?? cleanName(currentAsset.filename)}</p>
          {currentAsset.notes && <p>{currentAsset.notes}</p>}
        </Grid>
      )}
      {/* Date info */}
      {/* <Grid item>
        <p className={classes.timestamp}>
          Added:
          {convertToUserTimeZone(currentAsset.created_at, currentUser.time_zone).format('L LT')}
        </p>
      </Grid> */}
      {/* Meta Details */}
      <Grid item>
        <Button color="secondary" onClick={() => toggleMetaExpanded(!metaExpanded)}>
          {metaExpanded ? 'Hide' : 'Show'} Details
        </Button>
        <Collapse in={metaExpanded} style={{ textTransform: 'capitalize' }}>
          <Grid container direction="column" className={classes.fileDetailsGrid}>
            {assetMetaFields.map(field => {
              let output = '';

              if (currentAsset[field.field]) {
                output = (
                  <Grid item key={field.field} className={classes.fileDetailsGrid}>
                    <Grid container justifyContent="space-between">
                      <Grid item>{field.title}</Grid>
                      <Grid item>{formatFieldValue(currentAsset[field.field])}</Grid>
                    </Grid>
                  </Grid>
                );
              }

              return output;
            })}
          </Grid>
        </Collapse>
      </Grid>
      {/* Expiring Assets */}
      {/* {!sharedGrid && (
        <Grid item>
          <Button color="secondary" onClick={() => toggleAssetExpiryExpanded(!assetExpiryExpanded)}>
            {currentAsset?.expires_at ? 'Edit' : 'Add'} Expiration Date
          </Button>
          <Collapse in={assetExpiryExpanded}>
            {assetExpiryExpanded && (
              <Grid container style={{ margin: '5px' }} alignItems="center">
                <Grid item>
                  <Datetime value={expiryDate} onChange={e => setExpiryDate(e)} />
                </Grid>
                {(currentAsset?.expires_at || expiryDate) && (
                  <Grid style={{ margin: '5px' }}>
                    <Tooltip title="clear expiration date">
                      <Button
                        onClick={() => setExpiryDate('')}
                        classes={{ root: classes.clearIcon }}
                      >
                        <ClearIcon style={{ fontSize: '12px' }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            )}
          </Collapse>
          {currentAsset?.expires_at && !assetExpiryExpanded && (
            <div
              style={{
                fontSize: '12px',
                color: '#4c6087',
                padding: '6px 8px'
              }}
            >
              Expiration date:{' '}
              {convertToUserTimeZone(currentAsset.expires_at, currentUser.time_zone).format('L LT')}
            </div>
          )}
        </Grid>
      )} */}
      <hr style={{ width: '100%' }} />
      {/* {!sharedGrid ? (
        <Grid item>
          <p className={classes.publicToggle}>
            Shared with Org
            <Switch
              checked={publicAsset}
              onChange={() => {
                updatePublicAsset(!publicAsset);
              }}
              color="primary"
            />
          </p>
        </Grid>
      ) : ( */}
      <Grid item>
        {/* <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => {
            addToUserLibrary(currentAsset);
          }}
        >
          Save To My Library
        </Button> */}
        {/* <div style={{ marginTop: '3px', marginBottom: '3px' }}>
          <InputLabel variant="filled">Title:</InputLabel>
          <Typography>{cleanName(currentAsset.filename)}</Typography>
        </div> */}
        {currentAsset.notes && (
          <div style={{ marginTop: '3px', marginBottom: '3px' }}>
            <InputLabel variant="filled">Notes:</InputLabel>
            <Typography>{currentAsset.notes}</Typography>
          </div>
        )}
      </Grid>
      {/* )} */}
      {/* Gallery: Editable file details, or view-only if in shared grid. */}
      {!modal && fileDetails && (
        <Grid item>
          {!sharedGrid && currentCampaignEvents?.length ? (
            <PostSupport postData={currentCampaignEvents} />
          ) : null}
          {!sharedGrid && <InputLabel variant="filled">Tags</InputLabel>}
          {/* Show label in shared grid only if tags are present. */}
          {sharedGrid && (fileDetails?.auto_tags || fileDetails?.tags?.length) ? (
            <InputLabel variant="filled">Tags</InputLabel>
          ) : null}
          <AssetTags
            onChange={event =>
              updateTags({
                autoTags: event.autoTags,
                userTags: event.userTags
              })
            }
            displayOnly={!!sharedGrid}
            useAutoTags
            autoTags={fileDetails.auto_tags}
            userTags={fileDetails.tags}
          />
          {!sharedGrid && (
            <TextField
              onChange={updateField}
              label="Title"
              fullWidth
              name="title"
              variant="filled"
              value={fileDetails.title}
            />
          )}
          {!sharedGrid && (
            <FilledInput
              fullWidth
              multiline
              name="notes"
              onChange={updateField}
              placeholder="Edit description"
              rows={4}
              value={fileDetails?.notes ? fileDetails.notes : ''}
            />
          )}
        </Grid>
      )}
      {!sharedGrid && !modal && (
        <>
          <Grid item style={{ textAlign: 'right' }}>
            <HasAnyPriv privs={['SVC:ASSET:DELETE']}>
              <Button
                className={classes.deleteButton}
                color="secondary"
                onClick={() => toggleDeleteDialog(!showDeleteDialog)}
                variant="outlined"
              >
                Delete
              </Button>
            </HasAnyPriv>
            <HasAnyPriv privs={['SVC:ASSET:UPDATE']}>
              <ApiButton onClick={handleSave}>Save</ApiButton>
            </HasAnyPriv>
          </Grid>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            open={showDeleteDialog}
            onClose={() => toggleDeleteDialog(false)}
          >
            <div className={classes.modal}>
              {/* If title is null in initial state, use filename. */}
              <p>Are you sure you want to delete this image?</p>
              <div>
                <Button color="secondary" fullWidth onClick={() => handleDeleteAsset(currentAsset)}>
                  Yes, delete it now
                </Button>
                <Button fullWidth variant="text" onClick={() => toggleDeleteDialog(false)}>
                  No, keep it
                </Button>
              </div>
            </div>
          </Dialog>
        </>
      )}
    </Grid>
  );
};

MediaPreview.propTypes = {
  count: PropTypes.number,
  deleteAsset: PropTypes.func,
  modal: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  currentAsset: PropTypes.shape(),
  selectedAssets: PropTypes.arrayOf(PropTypes.shape()),
  currentCampaignEvents: PropTypes.arrayOf(PropTypes.shape()),
  updateAssetFields: PropTypes.func,
  sharedGrid: PropTypes.bool,
  // addToUserLibrary: PropTypes.func,
  currentUser: PropTypes.shape().isRequired
};

MediaPreview.defaultProps = {
  count: 0,
  deleteAsset: null,
  onSelect: null,
  currentAsset: null,
  selectedAssets: [],
  currentCampaignEvents: [],
  updateAssetFields: null,
  sharedGrid: false
  // addToUserLibrary: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(MediaPreview);
