import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AlembicTitleChip from 'components/AlembicTitleChip';
import RadarGraphContainer from './Graphs/RadarGraphContainer';
import TopSourcesContainer from './Graphs/TopSourcesContainer';
import NarrativeGraphAndTable from './Graphs/NarrativeGraphAndTable';
import { getIconSrc } from './util';

const useStyles = makeStyles({
  chipContainer: {
    margin: '15px',
    '& > div': {
      marginRight: '30px',
      marginBottom: '10px'
    }
  }
});

const ThirdPartyMediaOverview = props => {
  const { selectedDates, selectedReports } = props;
  const [articleSearchIds, setArticleSearchIds] = useState([]);
  const [articleSearchFilterIds, setArticleSearchFilterIds] = useState([]);
  const [broadcastSearchIds, setBroadcastSearchIds] = useState([]);
  const [podcastSearchIds, setPodcastSearchIds] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const tempArticleSearchIds = [];
    const tempArticleSearchFilterIds = [];
    const tempBroadcastSearchIds = [];
    const tempPodcastSearchIds = [];

    if (selectedReports?.length > 0) {
      selectedReports.forEach(report => {
        const [, id, filterId] = report.id.split(':');

        // for filtered article searches, the url string looks like this - "web:filter:{id}"
        if (report.reportType === 'web') {
          if (filterId) {
            tempArticleSearchFilterIds.push(filterId);
          } else {
            tempArticleSearchIds.push(id);
          }
        }

        if (report.reportType === 'broadcast') {
          tempBroadcastSearchIds.push(id);
        }

        if (report.reportType === 'podcast') {
          tempPodcastSearchIds.push(id);
        }
      });
    }

    setArticleSearchIds(tempArticleSearchIds);
    setArticleSearchFilterIds(tempArticleSearchFilterIds);
    setBroadcastSearchIds(tempBroadcastSearchIds);
    setPodcastSearchIds(tempPodcastSearchIds);
  }, [selectedReports]);

  const getImageSrc = reportType => {
    if (reportType === 'broadcast') {
      return getIconSrc('broadcast_and_radio');
    }

    return getIconSrc(reportType);
  };

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item xs={12} className={classes.chipContainer}>
        {selectedReports.map(report => {
          const chip = {
            key: `${report.id}`,
            title: 'Report',
            icon: getImageSrc(report.reportType),
            body: report.name,
            customBodyStyle: {
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              whiteSpace: 'nowrap'
            }
          };

          return <AlembicTitleChip {...chip} />;
        })}
      </Grid>

      <Grid container justifyContent="center">
        <RadarGraphContainer
          selectedDates={selectedDates}
          articleSearchIds={articleSearchIds}
          articleSearchFilterIds={articleSearchFilterIds}
          broadcastSearchIds={broadcastSearchIds}
          podcastSearchIds={podcastSearchIds}
        />
      </Grid>

      <Grid container justifyContent="center">
        <TopSourcesContainer
          selectedDates={selectedDates}
          articleSearchIds={articleSearchIds}
          articleSearchFilterIds={articleSearchFilterIds}
          broadcastSearchIds={broadcastSearchIds}
          podcastSearchIds={podcastSearchIds}
        />
      </Grid>

      <Grid container>
        <NarrativeGraphAndTable
          selectedDates={selectedDates}
          articleSearchIds={articleSearchIds}
          articleSearchFilterIds={articleSearchFilterIds}
          broadcastSearchIds={broadcastSearchIds}
          podcastSearchIds={podcastSearchIds}
        />
      </Grid>
    </Grid>
  );
};

ThirdPartyMediaOverview.propTypes = {
  selectedDates: PropTypes.shape(),
  selectedReports: PropTypes.arrayOf(PropTypes.shape())
};

ThirdPartyMediaOverview.defaultProps = {
  selectedDates: null,
  selectedReports: []
};

export default ThirdPartyMediaOverview;
