import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AnalyticsBox from 'components/AnalyticsBox';
import { useQuery } from '@apollo/client';
import { TOP_ACTIVE_OLD_POSTS } from 'gql/analyticsHypeAndFlow';
import { getDatesForAnalytics } from 'util/date';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import GraphHover from 'components/GraphHover';
import TopPostsOver30Column from './TopPostsOver30Column';

const useStyles = makeStyles({
  container: {
    height: '100%'
  },
  primaryText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734'
  }
});

/**
 * @method
 * @summary This component renders the top 10 active post greater than 30 days old
 * @name TopPostsOver30
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.accounts - An array of link token objects from @see PostAccountSelector
 * @param {Object} props.dates - An object containing start and end dates from @see AnalyticsDatePicker
 * @return {Object} - React JSX
 */
const TopPostsOver30 = props => {
  const { accounts, dates } = props;
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [hoveredPostId, setHoveredPostId] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [linkToken, setLinkToken] = useState(null);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(
    TOP_ACTIVE_OLD_POSTS,
    {
      variables: {
        startDate,
        endDate,
        linkTokens: accounts.map(({ id }) => id),
        metric: 'alembicImpressions'
      },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (queryData?.topActiveOldPosts) {
      setTableData(queryData.topActiveOldPosts);
    }
  }, [queryData]);

  const closeGraphHover = () => {
    setHoveredPostId(null);
    setPopoverAnchorEl(null);
    setLinkToken(null);
  };

  const handleRowClick = (e, { postId, linkTokenId }) => {
    setHoveredPostId(postId);
    setPopoverAnchorEl(e.currentTarget);
    setLinkToken(linkTokenId);
  };

  const onClick = (e, row) => {
    if (row?.id && !popoverAnchorEl) {
      handleRowClick(e, {
        postId: row?.id,
        linkTokenId: row?.linkTokenId
      });
    }
  };

  return (
    <AnalyticsBox
      usePadding
      tabs={['Top Posts Over 30 Days Old']}
      dates={dates}
      onChange={() => {}}
    >
      <Grid container justifyContent="center" alignItems="center" className={classes.container}>
        {!!queryLoading && <AlbLoading />}
        {!!queryError && <AlbError error={queryError} />}
        {queryData?.topActiveOldPosts?.length === 0 && (
          <Typography className={classes.primaryText}>
            There is not any data to display at this time.
          </Typography>
        )}
        {queryData?.topActiveOldPosts?.length > 0 && (
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <TopPostsOver30Column
                tableData={tableData}
                bottomBound={0}
                topBound={5}
                onClick={onClick}
              />
            </Grid>

            <Grid item xs={6}>
              <TopPostsOver30Column
                tableData={tableData}
                bottomBound={5}
                topBound={10}
                onClick={onClick}
              />
            </Grid>
          </Grid>
        )}
        {!!popoverAnchorEl && (
          <GraphHover
            open={!!popoverAnchorEl}
            anchorEl={popoverAnchorEl}
            startDate={dates.start}
            endDate={dates.end}
            eventId={hoveredPostId}
            linkTokenId={linkToken}
            withLTV
            clickBehavior
            close={closeGraphHover}
          />
        )}
      </Grid>
    </AnalyticsBox>
  );
};

TopPostsOver30.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default TopPostsOver30;
