import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { LogoColored, LogoStacked } from 'util/assets';

const AlembicLogo = props => {
  const { kind, width } = props;
  switch (kind) {
    case 'stacked':
      return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <img src={LogoStacked} alt="Alembic" width="180px" height="85px" />
        </Grid>
      );
    default:
      return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <img src={LogoColored} alt="Alembic" width={width} height="80px" />
        </Grid>
      );
  }
};

AlembicLogo.propTypes = {
  kind: PropTypes.string,
  width: PropTypes.string
};

AlembicLogo.defaultProps = {
  kind: 'default',
  width: null
};

export default AlembicLogo;
