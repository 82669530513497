import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ARCHIVE_RECREATE_COST_REPORT } from 'gql/recreateCostReport';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

/**
 * @method
 * @summary This component renders the modal used to archive cost to recreate reports accessed from @see CostToRecreateTableActions
 * @name ArchiveCostToRecreateModal
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isModalOpen - A boolean value to determine the modal's open state
 * @param {Function} props.onChange - A function run on successful mutation used to update parent component
 * @param {string} props.costToRecreateId - A string value for the cost to recreate report id
 * @param {string} props.costToRecreateName - A string value for the cost to recreate report name
 * @return {Object} - React JSX
 */
const ArchiveCostToRecreateModal = props => {
  const { isModalOpen, onChange, costToRecreateId, costToRecreateName } = props;

  const [archiveRecreateCostReport, { error, data }] = useMutation(ARCHIVE_RECREATE_COST_REPORT);

  useEffect(() => {
    if (data?.disableRecreateCostReport) {
      showToast(`Report archived`, 'success');
      onChange({
        isModalOpen: false,
        newRecreateCostReportData: data.disableRecreateCostReport
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Archive Report"
        body={
          <>
            Are you sure you want to archive the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{costToRecreateName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Archive"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => {
          archiveRecreateCostReport({ variables: { id: costToRecreateId } });
        }}
      />
    </>
  );
};

ArchiveCostToRecreateModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  costToRecreateId: PropTypes.string,
  costToRecreateName: PropTypes.string
};

ArchiveCostToRecreateModal.defaultProps = {
  costToRecreateId: null,
  costToRecreateName: null
};

export default ArchiveCostToRecreateModal;
