import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import { AlbTooltip } from 'components/AlbTooltip';
import { getAttentionActionTitle } from 'util/detectedEvents';

const useDataPointStyles = makeStyles({
  // Ratio styles
  container: {
    padding: '30px 0px'
  },
  categoryTitle: {
    fontWeight: '500',
    fontSize: '12px',
    color: '#6F6F6F'
  },
  scoreTitle: {
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    fontSize: '20px'
  },
  ratioTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '14px',
    color: '#6F6F6F',

    '& > svg': {
      marginLeft: '5px'
    }
  },
  barContainer: {
    position: 'relative',
    width: '100%',
    height: '34px',
    display: 'flex',
    alignItems: 'center'
  },
  horizontalBar: {
    width: '100%',
    height: '20px'
  },
  verticalBar: {
    position: 'absolute',
    width: '3px',
    height: '34px',
    background: '#000000'
  },

  // Association styles
  associationTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '14px',
    color: '#0A1734',

    '& > svg': {
      marginLeft: '5px'
    }
  }
});

const useAssociationRowStyles = makeStyles({
  associationRow: {
    backgroundColor: '#EAEAEA',
    marginTop: '10px',
    height: '25px',
    fontFamily: 'Poppins',
    fontSize: '11px',
    borderRadius: '3px',
    lineHeight: '25px'
  },
  associationLabel: {
    fontWeight: '400',
    paddingLeft: '10px'
  },
  associationScore: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '600',
    color: '#FFFFFF',
    width: '48px',
    borderRadius: '3px'
  },
  truncateText: {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const AttentionActionAssociationRow = props => {
  const { event, score } = props;
  const classes = useAssociationRowStyles();

  let backgroundColor = '#555593';

  if (score > 90) {
    backgroundColor = '#E6232B';
  } else if (score > 80) {
    backgroundColor = '#D62F38';
  } else if (score > 70) {
    backgroundColor = '#C83644';
  } else if (score > 60) {
    backgroundColor = '#B93E51';
  } else if (score > 50) {
    backgroundColor = '#A9455C';
  } else if (score > 40) {
    backgroundColor = '#9A4867';
  } else if (score > 30) {
    backgroundColor = '#8A4C72';
  } else if (score > 20) {
    backgroundColor = '#7B4F7D';
  } else if (score > 10) {
    backgroundColor = '#6A5289';
  } else {
    backgroundColor = '#555593';
  }

  return (
    <Grid container justifyContent="space-between" className={classes.associationRow}>
      <div className={`${classes.associationLabel} ${classes.truncateText}`}>
        {event.toUpperCase()}
      </div>
      <div className={classes.associationScore} style={{ backgroundColor }}>
        {score}
      </div>
    </Grid>
  );
};

AttentionActionAssociationRow.propTypes = {
  event: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired
};

const AttentionActionRatioAssociation = props => {
  const { dataPoint, isSwitchChecked } = props;
  const classes = useDataPointStyles();

  // how many associations to show
  const [count, setCount] = useState(5);

  return (
    <Grid container className={classes.container}>
      {/* Attention Ratio Container */}
      <Grid container>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography className={classes.categoryTitle}>Brand</Typography>
            <Typography className={classes.scoreTitle} style={{ color: '#C40075' }}>
              {Math.round(dataPoint.brand_equity_impact_score * 100)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography className={classes.ratioTitle}>
              Attention Ratio
              <AlbTooltip
                title={
                  "This determines where on the y-axis the event falls, by trading off the event's score for Brand vs. Demand."
                }
              />
            </Typography>
            <div className={classes.barContainer}>
              <div
                className={classes.horizontalBar}
                style={{
                  background: '#C40075'
                }}
              />
              <div
                className={classes.verticalBar}
                style={{
                  left: `calc(50% + ${50 *
                    (dataPoint.demand_generation_impact_score -
                      dataPoint.brand_equity_impact_score)}%)`
                }}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography className={classes.categoryTitle}>Demand</Typography>
            <Typography className={classes.scoreTitle} style={{ color: '#C40075' }}>
              {Math.round(dataPoint.demand_generation_impact_score * 100)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Action Ratio Container */}
      <Grid container style={{ marginTop: '30px' }}>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography className={classes.categoryTitle}>Interaction</Typography>
            <Typography className={classes.scoreTitle} style={{ color: '#32327D' }}>
              {Math.round(dataPoint.audience_interaction_impact_score * 100)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography className={classes.ratioTitle}>
              Action Ratio
              <AlbTooltip
                title={
                  "This determines where on the x-axis the event falls, by trading off the event's score for Conversion vs. Interaction."
                }
              />
            </Typography>
            <div className={classes.barContainer}>
              <div
                className={classes.horizontalBar}
                style={{
                  background: '#32327D'
                }}
              />
              <div
                className={classes.verticalBar}
                style={{
                  left: `calc(50% + ${50 *
                    (dataPoint.conversion_impact_score -
                      dataPoint.audience_interaction_impact_score)}%)`
                }}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography className={classes.categoryTitle}>Conversion</Typography>
            <Typography className={classes.scoreTitle} style={{ color: '#32327D' }}>
              {Math.round(dataPoint.conversion_impact_score * 100)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Association Score Container */}
      {dataPoint?.associations?.length === 0 && (
        <Grid container style={{ marginTop: '30px' }}>
          <Grid container justifyContent="center">
            <Typography className={classes.associationTitle}>
              POSSIBLE HALO OR DEPENDENCIES
              <AlbTooltip
                title={
                  "These are the top five other events that correlate with the event, by strength of association. If the score is red, they're highly correlated; if it's yellow, they're moderately correlated; and if it's green, they're lowly correlated. Click Show More to see the top ten."
                }
              />
            </Typography>
          </Grid>
          <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
            <Typography className={classes.categoryTitle}>
              None (only correlates with events of the same type)
            </Typography>
          </Grid>
        </Grid>
      )}
      {dataPoint?.associations?.length > 0 && (
        <Grid container style={{ marginTop: '30px' }}>
          <Grid container justifyContent="center">
            <Typography className={classes.associationTitle}>
              POSSIBLE HALO OR DEPENDENCIES
              <AlbTooltip
                title={
                  "These are the top five other events that correlate with the event, by strength of association. If the score is red, they're highly correlated; if it's yellow, they're moderately correlated; and if it's green, they're lowly correlated. Click Show More to see the top ten."
                }
              />
            </Typography>
          </Grid>
          <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
            <Grid item xs={8}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className={classes.categoryTitle}>EVENT</Typography>
                <Typography className={classes.categoryTitle}>ASSOCIATION SCORE</Typography>
              </Box>
              {dataPoint.associations
                .slice(0, count)
                .map(
                  ({
                    associated_event_type: eventType,
                    associated_event_label: eventLabel,
                    score
                  }) => {
                    const event =
                      !isSwitchChecked && eventLabel?.length
                        ? eventLabel
                        : getAttentionActionTitle(eventType);
                    return (
                      <AttentionActionAssociationRow
                        key={`${eventType}|${eventLabel}`}
                        event={event}
                        score={score}
                      />
                    );
                  }
                )}
              {dataPoint.associations.length > count && (
                <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setCount(dataPoint.associations.length)}
                  >
                    Show More
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

AttentionActionRatioAssociation.propTypes = {
  dataPoint: PropTypes.shape().isRequired,
  isSwitchChecked: PropTypes.bool.isRequired
};

export default AttentionActionRatioAssociation;
