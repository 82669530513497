// this format is used in Advanced Form, to work with SmartFiltersForm
export const COST_TO_RECREATE_FIELDS = {
  post_text_keyword: {
    displayName: 'Social Keyword',
    countMax: 1,
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  },
  account: {
    displayName: 'Account',
    countMax: 1,
    inputProps: {
      required: true,
      inputType: 'custom-select',
      validators: [],
      errorMessages: []
    }
  },
  image_tag: {
    displayName: 'Image Tag',
    countMax: 1,
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  },
  video_tag: {
    displayName: 'Video Tag',
    countMax: 1,
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  },
  media_type: {
    displayName: 'Media Type',
    countMax: 1,
    inputProps: {
      inputType: 'select',
      selectOptions: [
        // key values match DB enums
        { key: 'ALL', value: { value: 'ALL', displayName: 'All' } },
        { key: 'TEXT_ONLY', value: { value: 'TEXT_ONLY', displayName: 'Text-Only' } },
        { key: 'IMAGE_ONLY', value: { value: 'IMAGE_ONLY', displayName: 'Image-Only' } },
        { key: 'VIDEO_ONLY', value: { value: 'VIDEO_ONLY', displayName: 'Video-Only' } }
      ],
      validators: [],
      errorMessages: []
    }
  },
  post_type: {
    displayName: 'Post Type',
    countMax: 1,
    inputProps: {
      inputType: 'select',
      selectOptions: [
        // key values match DB enums
        { key: 'STORY', value: { value: 'STORY', displayName: 'Story' } },
        { key: 'TIMELINE', value: { value: 'TIMELINE', displayName: 'Timeline' } }
      ],
      validators: [],
      errorMessages: []
    }
  }
};

const DEFAULT_OPTIONS = {};
const CUSTOM_OPTIONS = {};

// turns data into an array, adds options, other fields.
export const getCostToRecreateFields = fields => {
  return Object.keys(fields).map(key => ({
    ...fields[key],
    name: key,
    label: fields[key].displayName,
    options: CUSTOM_OPTIONS[key] || DEFAULT_OPTIONS
  }));
};

// this format is used in Basic Form
const FORM_FIELDS = [
  {
    fieldName: 'comparedEarnedMediaReport',
    title: 'Compared 3rd Party Media Report (Required)',
    type: 'dropdown',
    options: [],
    validators: ['required'],
    validationError: ['*Required']
  },
  {
    fieldName: 'costToRecreateReportName',
    title: 'Media Valuation Report Name (Required)',
    type: 'input',
    validators: ['required'],
    validationError: ['*Required']
  },
  {
    fieldName: 'postTextKeyword',
    title: 'Social Keywords(s)',
    type: 'input',
    placeholder: '(Separated by commas)'
  },
  { fieldName: 'account', title: 'Account(s)', type: 'custom' },
  {
    fieldName: 'imageTag',
    title: 'Image Tag(s)',
    type: 'input',
    placeholder: '(Separated by commas)'
  },
  {
    fieldName: 'videoTag',
    title: 'Video Tag(s)',
    type: 'input',
    placeholder: '(Separated by commas)'
  },
  {
    fieldName: 'mediaType',
    title: 'Media Type(s)',
    type: 'dropdown',
    options: [
      // values match DB enums
      { value: 'ALL', name: 'All' },
      { value: 'TEXT_ONLY', name: 'Text-Only' },
      { value: 'IMAGE_ONLY', name: 'Image-Only' },
      { value: 'VIDEO_ONLY', name: 'Video-Only' }
    ]
  },
  {
    fieldName: 'postType',
    title: 'Post Type(s)',
    type: 'dropdown',
    options: [
      // key values match DB enums
      { value: 'ALL', name: 'All' },
      { value: 'STORY', name: 'Story' },
      { value: 'TIMELINE', name: 'Timeline' }
    ]
  }
];

export default FORM_FIELDS;
