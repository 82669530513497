import { gql } from '@apollo/client';

export const CAUSAL_CHAINS_SUMMARY = gql`
  query causalChainsSummary($timeSlice: TimeSlice!) {
    causalChainsSummary(timeSlice: $timeSlice) {
      id
      summary
      feedback: prompt_template_feedback_from_user {
        feedback_type
      }

      causal_chains {
        id
        title
        summary
        justification
        feedback: prompt_template_feedback_from_user {
          feedback_type
        }
      }
    }
  }
`;

export const CAUSAL_CHAINS_SUMMARY_MOST_RECENT_DAY = gql`
  query causalChainsSummaryMostRecentDay {
    causalChainsSummaryMostRecentDay {
      end_date
    }
  }
`;

export const CREATE_PROMPT_TEMPLATE_FEEDBACK = gql`
  mutation createPromptTemplateFeedback($input: PromptTemplateFeedbackInput!) {
    createPromptTemplateFeedback(input: $input) {
      id
    }
  }
`;
