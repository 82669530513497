import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import colors from 'util/colors';
import { getDatesForAnalytics } from 'util/date';
import { SHORTLINK_DISTRIBUTION } from 'gql/analytics';

const ShortlinkBrowsers = props => {
  const { dates, shortlink } = props;

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data: shortlinkData } = useQuery(SHORTLINK_DISTRIBUTION, {
    variables: {
      column: 'ua_device_type',
      startDate,
      endDate,
      // eventual support for querying outside of the user's current container
      // containerIds: [sessionStorage.current_container],
      campaignIds: [],
      ...(shortlink !== null && { shortlinkIds: [shortlink] })
    }
  });

  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (shortlinkData?.shortlinkDistribution) {
      setGraphData(shortlinkData.shortlinkDistribution);
    }
  }, [shortlinkData]);

  const data = {
    labels: graphData.map(({ x }) => x.replace(x.charAt(0), x.charAt(0).toUpperCase())),
    datasets: [
      {
        data: graphData.map(({ y }) => y),
        backgroundColor: [colors.darkBlue, colors.darkBlue50, colors.darkBlue20, colors.darkBlue10]
      }
    ]
  };

  const options = { aspectRatio: 1.5, cutoutPercentage: 60, legend: { display: false } };

  return (
    <Grid container>
      <Grid item xs={8}>
        <Doughnut data={data} options={options} height={null} width={null} />
      </Grid>
      <Grid item xs={4}>
        <Grid container style={{ height: '100%' }}>
          {graphData.map(({ x, y }) => (
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              xs={9}
              key={x}
              style={{ fontSize: '18px', fontWeight: 500 }}
            >
              <Grid item>{x.replace(x.charAt(0), x.charAt(0).toUpperCase())}</Grid>
              <Grid item>{y}%</Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

ShortlinkBrowsers.propTypes = {
  dates: PropTypes.shape().isRequired,
  shortlink: PropTypes.string
};

ShortlinkBrowsers.defaultProps = {
  shortlink: null
};

export default ShortlinkBrowsers;
