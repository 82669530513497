/**
 * @method
 * @summary This function takes a feature flag name and determines whether or not to display it
 * @name isFeatureVisible
 * @param {Object[]} featureFlags - A list of feature flags from unleashed
 * @param {string} targetFeature - A feature flag name
 * @return {boolean} - Returns whether or not to display the target feature
 */

const isFeatureVisible = (featureFlags, targetFeature) => {
  if (!featureFlags || !targetFeature) {
    return false;
  }

  if (!Array.isArray(featureFlags)) {
    throw new Error('Must provide an array to the first parameter');
  }

  if (targetFeature == null) {
    throw new Error('Must provide a value to the second parameter');
  }

  if (typeof targetFeature !== 'string') {
    throw new Error('Must provide a string to the second parameter');
  }

  const feature = featureFlags.find(({ name }) => name === targetFeature);

  // If the feature flag is added to unleashed then check the enabled status
  if (feature) {
    if (feature?.enabled === true) {
      return true;
    }

    if (feature?.enabled === false) {
      return false;
    }
  }

  // The feature is hidden by default
  return false;
};

export default isFeatureVisible;
