import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog, Container } from '@material-ui/core';
import {
  SHARE_OF_VOICE_REPORT,
  SHARE_OF_VOICE_ANALYTICS,
  UPDATE_SHARE_OF_VOICE_REPORT
} from 'gql/shareOfVoice';
import HasAnyPriv from 'components/HasAnyPriv';
import Box from 'components/Box';
import { getCurrentTimeInUserTimeZone, getEndDate, renderUILongDate } from 'util/date';
import { showToast } from 'contexts/ToastContext';
import AnalyticsTitle from 'components/AnalyticsTitle';
import SimpleAnalyticsHeader from 'components/SimpleAnalyticsHeader';
import { goToRoute } from 'util/routesHelpers';
import { ShareOfVoiceAnalyticsPath } from 'util/paths';
import Mindshare from './Graphs/ShareOfVoiceMindshare';
import Doughnut from './Graphs/ShareOfVoiceDoughnut';
import Values from './Graphs/ShareOfVoiceValues';
import Impact from './ShareOfVoiceImpact';
import ShareOfVoiceReportPicker from './ShareOfVoiceReportPicker';

const useStyles = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  },
  container: {
    padding: '25px 15px'
  },
  middleGraphs: {
    flexDirection: 'row'
  }
});

/**
 * @method
 * @summary This component renders the // components based on the selected report
 * @name ShareOfVoiceAnalytics
 * @param {Object} props - React props passed to this component
 * @param {Object} props.history - The history object from react router
 * @param {Object} props.match - The match object from react router
 * @return {Object} - React JSX
 */
const ShareOfVoiceAnalytics = props => {
  const { history, match, currentUser } = props;

  const classes = useStyles();

  const [selectedReportId, setSelectedReportId] = useState(match?.params?.reportId);

  const [reportName, setReportName] = useState('');
  const [totalArticles, setTotalArticles] = useState(0);
  const [myArticles, setMyArticles] = useState(0);
  const [myBestMonth, setMyBestMonth] = useState('-');
  const [myWorstMonth, setMyWorstMonth] = useState('-');

  const [report, setReport] = useState(null);

  const calRange = {
    start: getCurrentTimeInUserTimeZone(currentUser.time_zone)
      .startOf('month')
      .subtract(12, 'months'),
    end: getCurrentTimeInUserTimeZone(currentUser.time_zone).endOf('day')
  };

  const { data: queryData, error: queryError, loading: queryLoading } = useQuery(
    SHARE_OF_VOICE_REPORT,
    {
      variables: {
        id: selectedReportId,
        startDate: calRange.start,
        endDate: calRange.end
      },
      fetchPolicy: 'network-only'
    }
  );

  const { data: analyticsData, error: analyticsError } = useQuery(SHARE_OF_VOICE_ANALYTICS, {
    variables: {
      id: selectedReportId,
      startDate: calRange.start,
      endDate: calRange.end
    },
    fetchPolicy: 'network-only'
  });

  const [updateShareOfVoiceReport, { error: mutationError, data: mutationData }] = useMutation(
    UPDATE_SHARE_OF_VOICE_REPORT
  );

  useEffect(() => {
    if (queryData?.shareOfVoiceReport) {
      setReport(queryData.shareOfVoiceReport);
      setReportName(queryData.shareOfVoiceReport.name);
    }
  }, [queryData]);

  useEffect(() => {
    if (analyticsData?.shareOfVoiceAnalytics) {
      const {
        myArticles: myArticlesData,
        totalArticles: totalArticlesData,
        myBestMonth: bestMonthData,
        myWorstMonth: worstMonthData
      } = analyticsData.shareOfVoiceAnalytics;

      setMyArticles(myArticlesData);
      setTotalArticles(totalArticlesData);

      setMyBestMonth(bestMonthData?.date ? moment(bestMonthData?.date).format('MMMM') : '-');
      setMyWorstMonth(worstMonthData?.date ? moment(worstMonthData?.date).format('MMMM') : '-');
    }
  }, [analyticsData]);

  useEffect(() => {
    if (queryError) {
      showToast(queryError.message, 'error');
    }

    if (analyticsError) {
      showToast(analyticsError.message, 'error');
    }
  }, [queryError, analyticsError]);

  useEffect(() => {
    if (mutationData?.updateShareOfVoiceReport) {
      showToast('Report name updated.', 'success');
      setReportName(mutationData.updateShareOfVoiceReport.name);
    }

    if (mutationError) {
      showToast('Error saving new name. Please try again later.', 'error');
    }
  }, [mutationData, mutationError]);

  const updateReportName = newName => {
    if (newName.value !== reportName) {
      updateShareOfVoiceReport({
        variables: { id: selectedReportId, newName: newName.value }
      });
    }
  };

  const getDatesStr = () =>
    `${renderUILongDate(calRange.start)} - ${getEndDate(renderUILongDate(calRange.end))}`;

  return (
    <HasAnyPriv privs={['SVC:CHAN:STATS', 'SVC:CHAN:STATS:EM']} showAlert>
      <Dialog fullScreen open classes={{ paperFullScreen: classes.root }}>
        <SimpleAnalyticsHeader title="Competitive Intelligence" onClose={() => history.goBack()} />
        <Container>
          <Box my={50}>
            <AnalyticsTitle
              title={reportName}
              queryLoading={queryLoading}
              queryError={queryError}
              queryData={queryData}
              editInputAction={updateReportName}
              dates={getDatesStr()}
              buttonTitle="Manage Reports"
              buttonAction={() => goToRoute(ShareOfVoiceAnalyticsPath, history)}
            >
              <ShareOfVoiceReportPicker
                currentReportId={selectedReportId}
                setSelectedReportId={setSelectedReportId}
                history={history}
              />
            </AnalyticsTitle>
            <Mindshare dates={calRange} shareOfVoiceReport={report} />
            <Grid className={classes.middleGraphs} container direction="row">
              <Grid container item sm={12} md={6}>
                <Doughnut shareOfVoiceReport={report} />
              </Grid>
              <Grid item sm={12} md={6}>
                <Values
                  totalArticles={totalArticles}
                  myArticles={myArticles}
                  myBestMonth={myBestMonth}
                  myWorstMonth={myWorstMonth}
                  dates={calRange}
                />
              </Grid>
              <Impact shareOfVoiceReport={report} />
            </Grid>
          </Box>
        </Container>
      </Dialog>
    </HasAnyPriv>
  );
};

ShareOfVoiceAnalytics.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(ShareOfVoiceAnalytics);
