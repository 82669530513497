import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AnalyticsBox from 'components/AnalyticsBox';
import Box from 'components/Box';
import AlbSwitch from 'components/AlbSwitch';
import { THIRD_PARTY_TOP_SOURCES } from 'gql/analyticsThirdPartyMedia';
import { getDatesForAnalytics } from 'util/date';
import TopSources from './TopSources';

const useStyles = makeStyles(() => ({
  switchLabel: {
    fontSize: '13px',
    lineHeight: '20px'
  }
}));

const TopSourcesContainer = props => {
  const {
    selectedDates,
    articleSearchIds,
    articleSearchFilterIds,
    broadcastSearchIds,
    podcastSearchIds
  } = props;
  const [typeFilter, setTypeFilter] = useState(null);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [topSourcesData, setTopSourcesData] = useState(null);
  const classes = useStyles();
  const tabs = ['Top Sources', 'TV', 'Radio', 'Podcast', 'Web'];

  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const [getThirdPartyTopSources, { loading, error, data }] = useLazyQuery(THIRD_PARTY_TOP_SOURCES);

  useEffect(() => {
    const omitRatingsGraphTypes = ['Web'];
    const skipQuery = omitRatingsGraphTypes.includes(typeFilter) && isSwitchChecked;

    if (
      (articleSearchIds?.length > 0 ||
        articleSearchFilterIds?.length > 0 ||
        broadcastSearchIds?.length > 0 ||
        podcastSearchIds?.length > 0) &&
      !skipQuery
    ) {
      getThirdPartyTopSources({
        variables: {
          ...(articleSearchIds?.length > 0 && {
            articleSearchIds
          }),
          ...(articleSearchFilterIds?.length > 0 && {
            articleSearchFilterIds
          }),
          ...(broadcastSearchIds?.length > 0 && {
            broadcastSearchIds
          }),
          ...(podcastSearchIds?.length > 0 && {
            podcastSearchIds
          }),
          startDate,
          endDate,
          isViewershipSelected: isSwitchChecked,
          mediaType: typeFilter
        },
        fetchPolicy: 'network-only'
      });
    }

    if (
      (articleSearchIds?.length === 0 &&
        articleSearchFilterIds?.length === 0 &&
        broadcastSearchIds?.length === 0 &&
        podcastSearchIds?.length === 0) ||
      skipQuery
    ) {
      setTopSourcesData([]);
    }
  }, [
    typeFilter,
    isSwitchChecked,
    selectedDates,
    articleSearchIds,
    articleSearchFilterIds,
    broadcastSearchIds,
    podcastSearchIds
  ]);

  useEffect(() => {
    if (data?.thirdPartyTopSources?.length > 0) {
      setTopSourcesData(data.thirdPartyTopSources.slice(0, 10));
    } else {
      setTopSourcesData([]);
    }
  }, [data]);

  return (
    <Grid item xs={12}>
      <AnalyticsBox
        singleChild
        tabs={tabs}
        onChange={tabValue => {
          setTypeFilter(tabs[tabValue]);
        }}
        customComponent={
          <Box pr={24} display="flex" alignItems="center">
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Mentions
            </Typography>
            <AlbSwitch
              onChange={event => setIsSwitchChecked(event.target.checked)}
              checked={isSwitchChecked}
            />
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Ratings / Impact
            </Typography>
          </Box>
        }
      >
        <div>
          {loading && <AlbLoading />}
          {error && <AlbError error={error} />}
          {topSourcesData?.length === 0 && (
            <Grid container justifyContent="center">
              <Typography variantMapping={{ body1: 'span' }}>
                Sorry, no data available to display.
              </Typography>
            </Grid>
          )}
          {topSourcesData?.length > 0 && <TopSources dataResponse={topSourcesData} />}
        </div>
      </AnalyticsBox>
    </Grid>
  );
};

TopSourcesContainer.propTypes = {
  selectedDates: PropTypes.shape().isRequired,
  articleSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  broadcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  podcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

TopSourcesContainer.defaultProps = {};

export default TopSourcesContainer;
