/* eslint-disable camelcase */
import React, { useState } from 'react';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { showToast } from 'contexts/ToastContext';
import ReactFilestack from 'filestack-react';
import { ASSETS_GET_UL_POLICY, ASSET_FROM_HANDLE, GET_ASSETS } from 'gql/asset';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { Button } from '@material-ui/core';

const onError = error => {
  return <div>{`Error: ${error}`}</div>;
};

const UploadAssetByUrlButton = props => {
  const { source, callback, buttonText } = props;
  const [uploadInProgress, toggleUploadInProgress] = useState(false);

  const onSuccess = result => {
    const { mutate } = props;
    let handles = [];

    if (result) {
      handles = [{ id: result.handle }];
    }

    if (handles.length) {
      mutate({
        variables: {
          handles,
          uploadfolder: ''
        }
      })
        .then(async res => {
          if (res.data.createAssetFromHandles.length > 1) {
            showToast(
              `Successfully uploaded ${res.length} files to your media library.`,
              'success'
            );
          }
          if (res.data.createAssetFromHandles.length === 1) {
            showToast(`Successfully uploaded file to your media library.`, 'success');
          }
          if (callback) {
            callback(result);
          }
        })
        .catch(error => {
          // put in an exception for non-Graphql errors
          if (error.graphQLErrors && error.graphQLErrors.length) {
            return showToast(
              `Upload failed: ${error.graphQLErrors.map(({ message }) =>
                message.includes('File name exists.')
                  ? `${message} Please rename your file and try again.`
                  : `${message}`
              )}`,
              'error'
            );
          }
          return null;
        });
    }
  };

  return (
    <Query query={ASSETS_GET_UL_POLICY}>
      {({ loading, error, data, refetch }) => {
        if (loading) return <AlbLoading />;
        if (error) return <AlbError error={error} refetch={refetch} />;

        if (data) {
          const { policy, signature } = data.getUploadPolicy;
          const clientOptions = { security: { policy, signature } };

          // Filestack-react docs say to use 'actionOptions', but 'storeUrl' still requires 'options' to avoid "Invalid Store Params".
          return (
            <div>
              {uploadInProgress && <AlbLoading />}
              {!uploadInProgress && (
                <ReactFilestack
                  action="storeUrl"
                  apikey={process.env.FS_API_KEY}
                  options={{
                    accept: 'image/*',
                    storeTo: { workflows: [process.env.FS_AUTOTAGS_WORKFLOWID] }
                  }}
                  // onUploadStarted={onUploadStarted}
                  clientOptions={clientOptions}
                  source={source}
                  onClose={() => toggleUploadInProgress(false)}
                  onError={err => {
                    onError(err);
                  }}
                  customRender={({ onPick }) => (
                    <Button
                      onClick={event => {
                        toggleUploadInProgress(true);
                        onPick(event);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      {buttonText}
                    </Button>
                  )}
                  onSuccess={result => onSuccess(result)}
                />
              )}
            </div>
          );
        }
        return null;
      }}
    </Query>
  );
};

UploadAssetByUrlButton.propTypes = {
  mutate: PropTypes.func,
  source: PropTypes.string.isRequired,
  callback: PropTypes.func,
  buttonText: PropTypes.string
};

UploadAssetByUrlButton.defaultProps = {
  mutate: {},
  callback: {},
  buttonText: 'Save to Library'
};

export default compose(
  graphql(ASSET_FROM_HANDLE, {
    options: {
      refetchQueries: [
        {
          query: GET_ASSETS,
          variables: {
            orderBy: ['mime_type'],
            folderName: '/'
          }
        },
        {
          query: GET_ASSETS,
          variables: {
            filterBy: 'image'
          }
        }
      ]
    }
  })
)(UploadAssetByUrlButton);
