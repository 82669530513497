const formatCurrency = value => {
  return Number(value).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const formatCurrencyRound = value => {
  return Number(value).toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

export { formatCurrency as default, formatCurrencyRound };
