import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import AlembicPageHeader from 'components/AlembicPageHeader';
import CustomFooter from 'components/TablePagination';
import AlbTable from 'components/AlbTable';
import { ProgramLiftPath } from 'util/paths';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import customNameRender from 'components/AlbTable/ReportName';
import customCriteriaRender from 'components/AlbTable/SearchCriteria';
import { PROGRAM_LIFT_REPORTS } from 'gql/programLiftReport';
import CreateProgramLiftReport from './CreateProgramLiftReport';
import ProgramLiftReportActions from './ProgramLiftReportActions';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '30px'
  },
  tabContainer: {
    background: '#ffffff',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  tabOverRide: {
    fontSize: 18,
    fontWeight: 500
  },
  loadingContainer: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const customActionsRender = actions => {
  return (
    <ProgramLiftReportActions
      programLiftReport={actions.programLiftReport}
      queryRefetch={actions.queryRefetch}
    />
  );
};

/**
 * Renders @see ProgramLiftReportTable
 * @name AnalyticsProgramLift
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const AnalyticsProgramLift = () => {
  // const { history, location } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { loading, error, data, refetch } = useQuery(PROGRAM_LIFT_REPORTS, {
    variables: {
      enabled: true,
      count: 10,
      after: 0
    },
    fetchPolicy: 'no-cache'
  });

  const rowsPerPageOptions = [5, 10, 20];
  const formatData = report => {
    // Report Name
    const reportName = {
      id: report.id,
      path: ProgramLiftPath,
      name: report?.name ?? ''
    };

    const keywords =
      report?.keywords?.split(',')?.map(keyword => ({ title: 'Keyword', body: keyword })) || [];

    // Search Criteria
    const searchCriteria = {
      chips: [...keywords]
    };

    if (report?.start_date && report?.end_date) {
      searchCriteria.chips.push({
        title: 'Date Range',
        body: `${moment.utc(report.start_date).format('l')} - ${moment
          .utc(report.end_date)
          .format('l')}`
      });
    }

    // Actions
    const actions = {
      programLiftReport: report,
      queryRefetch: refetch
    };

    return {
      reportName,
      searchCriteria,
      actions
    };
  };
  const tableData = data?.programLiftReports?.rows?.map(formatData) ?? [];
  const tableColumns = [
    {
      name: 'reportName',
      label: 'REPORT NAME',
      options: {
        customBodyRender: customNameRender,
        sort: false
      }
    },
    {
      name: 'searchCriteria',
      label: 'SEARCH CRITERIA',
      options: {
        customBodyRender: customCriteriaRender,
        sort: false
      }
    },
    {
      name: 'actions',
      label: 'ACTIONS',
      options: {
        customBodyRender: customActionsRender,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false
      }
    }
  ];
  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: numberOfRows => {
      setRowsPerPage(numberOfRows);
      refetch({
        count: numberOfRows,
        after: page * rowsPerPage
      });
    },
    onChangePage: currentPage => {
      setPage(currentPage);
      refetch({
        after: currentPage * rowsPerPage
      });
    },
    draggableColumns: { enabled: false },
    page,
    rowsPerPage,
    count: data?.programLiftReports?.count ?? 0,
    customFooter: (
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) =>
      CustomFooter(
        footerCount,
        footerPage,
        footerRowsPerPage,
        changeRowsPerPage,
        changePage,
        textLabels,
        rowsPerPageOptions
      )
  };

  return (
    <>
      <Grid container className={classes.header}>
        <AlembicPageHeader
          pageTitle="Program Lift Reports"
          buttonTitle="Create New Report"
          backButton
          buttonOnClick={() => {
            setIsModalOpen(true);
          }}
        />
      </Grid>
      {error && <AlbError error={error} />}
      <Grid container className={classes.tabContainer}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
            // Reset the state back to original values on tab change
            setPage(0);
            setRowsPerPage(10);
            refetch({
              enabled: newValue === 0,
              count: 10,
              after: 0
            });
          }}
        >
          <Tab
            label="Active"
            classes={{
              root: classes.tabOverRide
            }}
          />
          <Tab
            label="Archived"
            classes={{
              root: classes.tabOverRide
            }}
          />
        </Tabs>
      </Grid>
      <div className={classes.loadingContainer}>
        {loading && (
          <div className={classes.loading}>
            <AlbLoading />
          </div>
        )}
        <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />
      </div>
      {isModalOpen === true && (
        <CreateProgramLiftReport
          isModalOpen={isModalOpen}
          handleSuccess={() => {
            refetch();
          }}
          handleCancel={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

AnalyticsProgramLift.propTypes = {
  // history: PropTypes.shape().isRequired,
  // location: PropTypes.shape().isRequired
};

export default withRouter(AnalyticsProgramLift);
