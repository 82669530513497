import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Drawer, Grid, Typography, IconButton } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { ArrowRight, Close } from '@material-ui/icons';
import { useQuery, useMutation } from '@apollo/client';
import { showToast } from 'contexts/ToastContext';
import {
  ATTENTION_ACTION_DETECTED_EVENTS,
  ATTENTION_ACTION_CREATE_UGC_LABEL,
  ATTENTION_ACTION_UPDATE_UGC_LABEL
} from 'gql/attentionAction';
import Box from 'components/Box';
import EditInPlaceInput from 'components/EditInPlaceInput';
import DeleteActionAttentionUGCLabelModal from 'components/AlembicModalConfirm/DeleteActionAttentionUGCLabelModal';
import AlbLoading from 'components/AlbLoading';
import DashboardUnderstandDrawerContext from 'contexts/DashboardUnderstandDrawerContext';
import AttentionActionRatioAssociation from 'views/DashboardUnderstand/AttentionActionRatioAssociation';
import {
  UGC_EDITABLE_LABEL_EVENT_TYPES,
  MIX_GROUP_OPTIONS_WITH_OVERALL,
  refetchQueries
} from 'views/DashboardUnderstand/util';
import handleGraphQLError from 'util/error';
import DashboardUnderstandDrawerTable from './DashboardUnderstandDrawerTable';
import DashboardUnderstandDrawerGraph from './DashboardUnderstandDrawerGraph';

const useStyles = makeStyles({
  // Drawer
  drawerPaper: {
    minWidth: '617px',
    maxWidth: '617px',
    flexDirection: 'row',
    overflow: 'unset'
  },
  container: {
    width: '100%',
    overflowX: 'hidden',
    paddingBottom: '30px'
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    background: '#0A1734',
    height: 'auto',
    cursor: 'pointer'
  },
  arrow: {
    color: '#ffffff'
  },

  // Header
  header: {
    padding: '28px 25px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px'
  },
  logoContainer: {
    marginRight: '20px'
  },
  labelText: {
    fontSize: '18px',
    lineHeight: '27px',
    fontFamily: 'Poppins'
  },
  eventText: {
    fontSize: '13px'
  },

  // Ratio
  ratio: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  }
});

const modalTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      paper: {
        // conditional for the delete modal
        '& > div': {
          padding: '12px'
        }
      },
      root: {
        zIndex: '1400 !important'
      },
      ...theme.overrides.MuiDialog
    }
  }
});

/**
 * @method
 * @summary Implements useContext() on DashboardUnderstandDrawerContext and displays corresponding data in a drawer.
 * @name DashboardUnderstandDrawer
 * @return {Object} - React JSX
 */

const DashboardUnderstandDrawer = () => {
  const classes = useStyles();
  const drawerContext = useContext(DashboardUnderstandDrawerContext);

  const { isDrawerOpen, startDate, endDate, isSwitchChecked, event, toggleDrawer } = drawerContext;

  const [timeseriesLookup, setTimeseriesLookup] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ugcLabel, setUgcLabel] = useState(null);

  const [sortOrder, setSortOrder] = useState(['date', 'DESC']);

  useEffect(() => {
    setUgcLabel(event.ugc_label);
  }, [event.ugc_label]);

  const { loading, error, data } = useQuery(ATTENTION_ACTION_DETECTED_EVENTS, {
    variables: {
      id: event.id,
      startDate,
      endDate,
      orderBy: sortOrder
    },
    fetchPolicy: 'network-only',
    skip: event.id == null || startDate == null || endDate == null
  });

  useEffect(() => {
    let tempTimeseriesLookup = {};
    const tempTableData = [];

    if (data?.attentionActionScore?.detectedEvents?.length) {
      tempTimeseriesLookup = data.attentionActionScore.detectedEvents
        .flatMap(detectedEvent => {
          const { graph, ...rest } = detectedEvent;
          let timeseries = [];

          if (graph?.length) {
            [{ timeseries }] = graph;
          }

          tempTableData.push(rest);

          return timeseries;
        })
        .reduce((acc, { x, y }) => {
          if (acc[x]) {
            acc[x] += y;
          } else {
            acc[x] = y;
          }

          return acc;
        }, {});
    }

    setTimeseriesLookup(tempTimeseriesLookup);
    setTableData(tempTableData);
  }, [data]);

  const [
    createUgcLabel,
    { loading: createLoading, error: createError, data: createData }
  ] = useMutation(ATTENTION_ACTION_CREATE_UGC_LABEL, { refetchQueries });

  const [
    updateUgcLabel,
    { loading: updateLoading, error: updateError, data: updateData }
  ] = useMutation(ATTENTION_ACTION_UPDATE_UGC_LABEL, { refetchQueries });

  useEffect(() => {
    if (createData?.attentionActionCreateUgcLabel) {
      showToast(createData.attentionActionCreateUgcLabel.message, 'success');
    }
  }, [createData]);

  useEffect(() => {
    if (updateData?.attentionActionUpdateUgcLabel) {
      showToast(updateData.attentionActionUpdateUgcLabel.message, 'success');
    }
  }, [updateData]);

  useEffect(() => {
    if (createError || updateError) {
      handleGraphQLError(createError || updateError);
    }
  }, [createError, updateError]);

  const editableType =
    event.event_label && UGC_EDITABLE_LABEL_EVENT_TYPES.includes(event.event_type);

  const GraphMemo = useMemo(() => {
    const graphData = Object.entries(timeseriesLookup).map(([x, y]) => ({ x, y }));

    return (
      <DashboardUnderstandDrawerGraph
        data={graphData}
        dates={{ startDate, endDate }}
        eventType={event.event_type}
        eventLabel={ugcLabel || event.event_label}
      />
    );
  }, [timeseriesLookup]);

  return (
    <MuiThemeProvider theme={modalTheme}>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div
          role="button"
          className={classes.bar}
          onClick={() => toggleDrawer(false)}
          aria-hidden="true"
        >
          <ArrowRight className={classes.arrow} />
        </div>
        {isDrawerOpen && (
          <Grid className={classes.container}>
            {/* Header */}
            <Grid container className={classes.header} direction="column">
              <Box pb={2.5}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography color="primary" className={classes.title}>
                    Event Details for{' '}
                    {
                      MIX_GROUP_OPTIONS_WITH_OVERALL.find(
                        ({ value }) => value === event.selectedMixGroup
                      )?.title
                    }{' '}
                    Goal
                  </Typography>
                  <IconButton onClick={() => toggleDrawer(false)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Box>
              <Box pb={2.5}>
                {(createLoading || updateLoading) && (
                  <div className={classes.loading}>
                    <AlbLoading />
                  </div>
                )}
                <Grid container wrap="nowrap" alignItems="center">
                  <Grid item className={classes.labelText}>
                    {editableType ? (
                      <EditInPlaceInput
                        customTitleStyle={classes.labelText}
                        useEdit
                        useIcons
                        useDelete={ugcLabel != null}
                        value={ugcLabel || event.event}
                        onChange={e => {
                          if (e.value) {
                            if (ugcLabel) {
                              updateUgcLabel({
                                variables: { actionAttentionId: event.id, title: e.value }
                              });
                            } else {
                              createUgcLabel({
                                variables: { actionAttentionId: event.id, title: e.value }
                              });
                            }

                            setUgcLabel(e.value);
                          }

                          if (e.delete) {
                            setIsDeleteModalOpen(true);
                          }
                        }}
                      />
                    ) : (
                      event.event
                    )}
                    {ugcLabel && (
                      <Grid item className={classes.eventText}>
                        {event.event}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* Ratio */}
            <Grid container className={classes.ratio} direction="column">
              <AttentionActionRatioAssociation
                dataPoint={event}
                isSwitchChecked={isSwitchChecked}
              />
            </Grid>
            {/* Graph */}
            <Grid container className={classes.graph} direction="column">
              {GraphMemo}
            </Grid>
            {/* Table */}
            <Grid container className={classes.table} direction="column">
              <DashboardUnderstandDrawerTable
                data={tableData}
                loading={loading}
                error={error}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            </Grid>
          </Grid>
        )}
      </Drawer>
      {isDeleteModalOpen && (
        <DeleteActionAttentionUGCLabelModal
          selectedUGC={{
            actionAttentionId: event.id,
            ugcLabel,
            text: event.event
          }}
          isModalOpen={isDeleteModalOpen}
          onChange={e => setIsDeleteModalOpen(e.isModalOpen)}
          onCompleted={() => setUgcLabel(null)}
        />
      )}
    </MuiThemeProvider>
  );
};

export default DashboardUnderstandDrawer;
