const PasswordValidator = require('password-validator');

const minPWLength = 10;
const maxPWLength = 100;

const passwordLengthValidator = password => {
  if (password.length >= minPWLength) {
    return true;
  }
  return false;
};

// This password validator is identical to the code used in the
// changeMyPassword GraphQL Resolver.
const passwordValidator = pw => {
  const schema = new PasswordValidator();

  schema
    .is()
    .min(minPWLength) // Minimum length 10
    .is()
    .max(maxPWLength) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least one number
    .has()
    .symbols(1); // Must have at least one symbol

  return schema.validate(pw);
};

module.exports = {
  minPWLength,
  maxPWLength,
  passwordLengthValidator,
  passwordValidator
};
