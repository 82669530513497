import { gql } from '@apollo/client';

export const GET_SALESFORCE_STATE_OPTION = gql`
  query getSalesforceStateOption($linktokenId: ID!) {
    getSalesforceStateOption(linktokenId: $linktokenId) {
      statuses {
        id
        master_label
      }
      stages {
        id
        master_label
      }
    }
  }
`;

export const GET_SALESFORCE_PIPELINE = gql`
  query getSalesforcePipeline(
    $linktokenId: ID!
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $statuses: [String]
    $stages: [String]
    $segment: String
    $lookBackTime: String
  ) {
    getSalesforcePipeline(
      linktokenId: $linktokenId
      startDate: $startDate
      endDate: $endDate
      statuses: $statuses
      stages: $stages
      segment: $segment
      lookBackTime: $lookBackTime
    ) {
      id
      category
      leadCount
      selectedLeadCount
      opportunityCount
      selectedOpportunityCount
      leadToOpportunityAvg
      leadToOpportunityPct
      opportunityToCloseAvg
      opportunityToClosePct
      opportunityValueAvg
      lengthAvg
      projection {
        id
        closeWonValue
        opportunityValue
        netNewOpportunityCount
      }
      states {
        id
        order
        name
        count
        value
      }
      steps {
        id
        order
        conversionPct
        count
        conversionCount
        valueAvg
        lengthAvg
      }
    }
  }
`;

export const GET_SALESFORCE_PROJECTIONS = gql`
  query getSalesforceProjections {
    getSalesforceProjections {
      id
      closeWonValue
      opportunityValue
      netNewOpportunityCount
    }
  }
`;

export const CREATE_OR_UPDATE_SALESFORCE_PROJECTIONS = gql`
  mutation createOrUpdateSalesforceProjections(
    $targetOpportunityValue: Float
    $targetNetNewOpportunityCount: Float
    $targetCloseWonValue: Float
  ) {
    createOrUpdateSalesforceProjections(
      targetOpportunityValue: $targetOpportunityValue
      targetNetNewOpportunityCount: $targetNetNewOpportunityCount
      targetCloseWonValue: $targetCloseWonValue
    ) {
      id
      closeWonValue
      opportunityValue
      netNewOpportunityCount
    }
  }
`;

export const GET_SALESFORCE_GROUPS = gql`
  query getSalesforceGroups {
    getSalesforceGroups {
      id
      name
      lead_status_ids
      opportunity_stage_ids
      is_default
    }
  }
`;

export const CREATE_SALESFORCE_GROUP = gql`
  mutation createSalesforceGroup(
    $name: String!
    $leadStatusIds: [ID!]!
    $opportunityStageIds: [ID!]!
    $isDefault: Boolean
  ) {
    createSalesforceGroup(
      name: $name
      leadStatusIds: $leadStatusIds
      opportunityStageIds: $opportunityStageIds
      isDefault: $isDefault
    ) {
      id
      name
      lead_status_ids
      opportunity_stage_ids
      is_default
    }
  }
`;

export const UPDATE_SALESFORCE_GROUP = gql`
  mutation updateSalesforceGroup(
    $id: ID!
    $name: String!
    $leadStatusIds: [ID!]!
    $opportunityStageIds: [ID!]!
  ) {
    updateSalesforceGroup(
      id: $id
      name: $name
      leadStatusIds: $leadStatusIds
      opportunityStageIds: $opportunityStageIds
    ) {
      id
      name
      lead_status_ids
      opportunity_stage_ids
      is_default
    }
  }
`;

export const DELETE_SALESFORCE_GROUP = gql`
  mutation deleteSalesforceGroup($id: ID!) {
    deleteSalesforceGroup(id: $id) {
      result
      message
    }
  }
`;

export const GET_SALESFORCE_TIME_TO_EVENTS = gql`
  query getSalesforceTimeToEvents(
    $linktokenId: ID!
    $opportunityFromLead: Boolean
    $dimension: String
    $lookBackTime: String
  ) {
    getSalesforceTimeToEvents(
      linktokenId: $linktokenId
      opportunityFromLead: $opportunityFromLead
      dimension: $dimension
      lookBackTime: $lookBackTime
    ) {
      id
      linktoken_id
      outcome
      dimension
      label
      lookback_days
      tte_days
      tte_rate
      upper_confidence_interval
      lower_confidence_interval
      total_sample_size
      total_outcome_sample_size
      opportunity_from_lead
    }
  }
`;

export const GET_SALESFORCE_REVENUE_TOTALS = gql`
  query getSalesforceRevenueTotals(
    $linktokenId: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $periodical: PeriodicalEnum
  ) {
    getSalesforceRevenueTotals(
      linktokenId: $linktokenId
      startDate: $startDate
      endDate: $endDate
      periodical: $periodical
    ) {
      lead_revenue
      lead_revenue_difference
      lead_revenue_percent_change
      non_lead_revenue
      non_lead_revenue_difference
      non_lead_revenue_percent_change
      total_percent_change
    }
  }
`;

export const GET_SALESFORCE_REVENUE_TOP_BY_LEAD = gql`
  query getSalesforceRevenueTopByLead(
    $linktokenId: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $periodical: PeriodicalEnum
  ) {
    getSalesforceRevenueTopByLead(
      linktokenId: $linktokenId
      startDate: $startDate
      endDate: $endDate
      periodical: $periodical
    ) {
      lead_opportunities {
        id
        label
        dimension
        revenue
        close_rate
        deal_size
        volume
      }
      non_lead_opportunities {
        id
        label
        dimension
        revenue
        close_rate
        deal_size
        volume
      }
    }
  }
`;

export const GET_SALESFORCE_TOP_INSIGHTS = gql`
  query getSalesforceTopInsights(
    $linktokenId: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $periodical: PeriodicalEnum
  ) {
    getSalesforceTopInsights(
      linktokenId: $linktokenId
      startDate: $startDate
      endDate: $endDate
      periodical: $periodical
    ) {
      type
      dimension
      label
      value
      value_difference
      value_percent_change
    }
  }
`;

export const GET_SALESFORCE_REVENUE_TOP_PERFORMERS = gql`
  query getSalesforceRevenueTopPerformers(
    $linktokenId: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $periodical: PeriodicalEnum
  ) {
    getSalesforceRevenueTopPerformers(
      linktokenId: $linktokenId
      startDate: $startDate
      endDate: $endDate
      periodical: $periodical
    ) {
      id
      linktoken {
        type
      }
      label
      dimension
      opportunity_from_lead
      revenue
      revenue_rel_rank
      close_rate
      close_rate_rel_rank
      deal_size
      deal_size_rel_rank
      volume
      volume_rel_rank
      note
    }
  }
`;

export const CREATE_OR_UPDATE_SALESFORCE_REVENUE_NOTE = gql`
  mutation createOrUpdateSalesforceRevenueNote($id: ID!, $note: String!) {
    createOrUpdateSalesforceRevenueNote(id: $id, note: $note) {
      result
      message
    }
  }
`;

export const DELETE_SALESFORCE_REVENUE_NOTE = gql`
  mutation deleteSalesforceRevenueNote($id: ID!) {
    deleteSalesforceRevenueNote(id: $id) {
      result
      message
    }
  }
`;

export const GET_SALESFORCE_HISTORY_FORECAST = gql`
  query getSalesforceHistoryForecast(
    $linktokenId: ID!
    $opportunityFromLead: Boolean
    $after: Int
    $count: Int
    $tab: String!
  ) {
    getSalesforceHistoryForecast(
      linktokenId: $linktokenId
      opportunityFromLead: $opportunityFromLead
      after: $after
      count: $count
      tab: $tab
    ) {
      rows {
        dimension
        label
        timeseries {
          x
          y
        }
        timeseries_forecast {
          x
          y
        }
        history {
          start_date
          end_date
          sum
        }
        forecast {
          days
          change
        }
        hideTimeseries
      }
      count
    }
  }
`;
