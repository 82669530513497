/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DetailsBody from 'components/PostDrawerView/DetailsBody';
import AnnotationsList from 'components/DetectedEventsTable/AnnotationsList';
import DetailsHeader from './ArticleDetailsHeader';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginTop: '24px',
    marginBottom: '20px'
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '36px',
    color: '#0A1734'
  },
  imageContainer: {
    width: '124px'
  },
  image: {
    height: '124px',
    width: '124px',
    borderRadius: '3px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover'
  },
  textContainer: {
    flex: 1,
    marginLeft: '20px'
  }
});

/**
 * @method
 * @summary This component renders the details of an article at the top of the drawer or from a list of articles of @see ArticleDrawerView
 * @name ArticleDrawerDetails
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const ArticleDrawerDetails = props => {
  const { article } = props;
  const { thumbnail, created_at, published_date, domain, url, articleText = '' } = article;
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <div className={classes.image} style={{ backgroundImage: `url('${thumbnail}')` }} />
        </div>
        <div className={classes.textContainer}>
          <DetailsHeader
            articleDetails={{ created_at, published_date, domain, url, articleText }}
          />
          {/* article text provided by social animal API goes here */}
          <DetailsBody eventBody={articleText} />
        </div>
      </div>
      <AnnotationsList startDate={published_date} endDate={published_date} includeTitle />
    </>
  );
};

ArticleDrawerDetails.propTypes = {
  article: PropTypes.shape().isRequired
};

export default ArticleDrawerDetails;
