import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { MAKE_DEFAULT_RECREATE_COST_REPORT } from 'gql/recreateCostReport';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

/**
 * @method
 * @summary This component renders the modal used to default cost to recreate reports accessed from @see CostToRecreateTableActions
 * @name DefaultCostToRecreateModal
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isModalOpen - A boolean value to determine the modal's open state
 * @param {Function} props.onChange - A function run on successful mutation used to update parent component
 * @param {string} props.costToRecreateId - A string value for the cost to recreate report id
 * @param {string} props.costToRecreateName - A string value for the cost to recreate report name
 * @return {Object} - React JSX
 */
const DefaultCostToRecreateModal = props => {
  const { isModalOpen, onChange, costToRecreateId, costToRecreateName } = props;

  const [makeDefaultRecreateCostReport, { error, data }] = useMutation(
    MAKE_DEFAULT_RECREATE_COST_REPORT
  );

  useEffect(() => {
    if (data?.makeDefaultRecreateCostReport) {
      showToast(`Report ${costToRecreateName} is now the default`, 'success');
      onChange({
        isModalOpen: false,
        newRecreateCostReportData: data.makeDefaultRecreateCostReport
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Default Report"
        body={
          <>
            Are you sure you want to make the default report:&nbsp;
            <span style={{ color: '#0A1734' }}>{costToRecreateName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Make Default"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => {
          makeDefaultRecreateCostReport({ variables: { id: costToRecreateId } });
        }}
      />
    </>
  );
};

DefaultCostToRecreateModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  costToRecreateId: PropTypes.string,
  costToRecreateName: PropTypes.string
};

DefaultCostToRecreateModal.defaultProps = {
  costToRecreateId: null,
  costToRecreateName: null
};

export default DefaultCostToRecreateModal;
