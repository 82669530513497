import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { THIRD_PARTY_COUNT_DATA_BY_TYPE } from 'gql/analyticsThirdPartyMedia';
import { getDatesForAnalytics } from 'util/date';
import ImpactByType from './ImpactByType';
import MentionsByType from './MentionsByType';

const RadarGraphContainer = props => {
  const {
    selectedDates,
    articleSearchIds,
    articleSearchFilterIds,
    broadcastSearchIds,
    podcastSearchIds
  } = props;
  const [mentionsByTypeData, setMentionsByTypeData] = useState([]);
  const [impactByTypeData, setImpactByTypeData] = useState([]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const { loading, error, data } = useQuery(THIRD_PARTY_COUNT_DATA_BY_TYPE, {
    variables: {
      ...(articleSearchIds?.length > 0 && {
        articleSearchIds
      }),
      ...(articleSearchFilterIds?.length > 0 && {
        articleSearchFilterIds
      }),
      ...(broadcastSearchIds?.length > 0 && {
        broadcastSearchIds
      }),
      ...(podcastSearchIds?.length > 0 && {
        podcastSearchIds
      }),
      startDate,
      endDate
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    const omitRatingsGraphTypes = ['web'];

    if (data?.thirdPartyCountDataByType) {
      const graphDataSetOrder = ['television', 'radio', 'podcast', 'web'];
      const tempMentionData = [];
      const tempImpactData = [];

      graphDataSetOrder.forEach(key => {
        if (data.thirdPartyCountDataByType[key].mentionCount != null) {
          tempMentionData.push(Math.round(data.thirdPartyCountDataByType[key].mentionCount));
        }

        if (data.thirdPartyCountDataByType[key].viewershipCount != null) {
          let { viewershipCount } = data.thirdPartyCountDataByType[key];

          if (omitRatingsGraphTypes.includes(key)) {
            viewershipCount = 0;
          }

          tempImpactData.push(Math.round(viewershipCount));
        }
      });

      setMentionsByTypeData(tempMentionData);
      setImpactByTypeData(tempImpactData);
    } else {
      setMentionsByTypeData([]);
      setImpactByTypeData([]);
    }
  }, [data]);

  return (
    <>
      {loading && <AlbLoading />}
      {error && <AlbError error={error} />}

      <Grid item xs={6}>
        <MentionsByType dataSet={mentionsByTypeData} />
      </Grid>

      <Grid item xs={6}>
        <ImpactByType dataSet={impactByTypeData} />
      </Grid>
    </>
  );
};

RadarGraphContainer.propTypes = {
  selectedDates: PropTypes.shape().isRequired,
  articleSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  broadcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  podcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

RadarGraphContainer.defaultProps = {};

export default RadarGraphContainer;
