import React from 'react';
import PropTypes from 'prop-types';
import AlembicModalConfirm from './AlembicModalConfirm';

/**
 * @summary SomethingMissingModal is typically used in analytics tables (i.e. 3rd Party Media and Cost To Recreate)
 * to provide feedback on missing data. It is a wrapper for generic component AlembicModalConfirm.
 * @prop {Boolean} isModalOpen - indicates if modal is open
 * @prop {Function} toggleModal - controls opening/closing of modal
 * @prop {String} title - Title of modal
 * @prop {String} subtitle - Text below the title
 * @prop {Object} children - Optional content inside the modal
 * @prop {Boolean} fullLengthBody - prop for AlembicModalConfirm that controls the width of the modal body.
 * @prop {Function} onConfirm - submit action of modal
 * @prop {Boolean} isConfirmOnly - If true, will only show one button in modal
 * @prop {String} confirmTitle - Optional title for the confirm button
 * @prop {String} width - Optional width of modal container
 * @returns - React JSX
 */

const SomethingMissingModal = props => {
  const {
    isModalOpen,
    toggleModal,
    title,
    subtitle,
    children,
    fullLengthBody,
    onConfirm,
    isConfirmOnly,
    confirmTitle,
    width
  } = props;

  return (
    <AlembicModalConfirm
      isOpen={isModalOpen}
      title={title}
      subtitle={subtitle}
      body={children}
      fullLengthBody={fullLengthBody}
      note="It may take a while for submitted articles to appear, if at all, due to limitations of the system."
      handleCancel={() => toggleModal(false)}
      confirmTitle={confirmTitle}
      handleConfirm={onConfirm}
      onEnter={onConfirm}
      isConfirmOnly={isConfirmOnly}
      width={width}
    />
  );
};

SomethingMissingModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.element,
  fullLengthBody: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  isConfirmOnly: PropTypes.bool,
  confirmTitle: PropTypes.string,
  width: PropTypes.string
};

SomethingMissingModal.defaultProps = {
  title: 'Something Missing?',
  subtitle: null,
  children: null,
  fullLengthBody: false,
  isConfirmOnly: false,
  confirmTitle: 'Submit',
  width: null
};

export default SomethingMissingModal;
