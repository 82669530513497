/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz } from '@material-ui/icons';
import { Typography, IconButton } from '@material-ui/core';
import { trimPreviewText } from 'util/social';

const useStyles = makeStyles({
  textBody: {
    display: 'flex',
    marginTop: '14px'
  },
  textBodyContainer: {
    fontSize: '14px',
    margin: 0
  },
  textPost: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    color: '#0A1734'
  },
  ellipsisButton: {
    color: '#0A1734',
    border: '0.5px solid #0A1734',
    marginLeft: '10px'
  },
  ellipsisButtonOverride: {
    fontSize: '0.8rem'
  }
});

/**
 * @method
 * @summary This component renders the post body at the top of @see PostDrawerView inside @see PostDrawerDetails
 * @name DetailsBody
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const DetailsBody = props => {
  const { eventBody } = props;
  const [usePreviewText, setUsePreviewText] = useState(true);
  const classes = useStyles();

  return (
    <div className={classes.textBody}>
      <p className={classes.textBodyContainer}>
        <Typography variantMapping={{ body1: 'span' }} className={classes.textPost}>
          {eventBody?.length > 200 && usePreviewText ? trimPreviewText(eventBody, 200) : eventBody}
        </Typography>

        {eventBody?.length > 200 && (
          <IconButton
            size="small"
            className={classes.ellipsisButton}
            classes={{
              sizeSmall: classes.ellipsisButtonOverride
            }}
            onClick={e => {
              e.stopPropagation();
              setUsePreviewText(prevState => !prevState);
            }}
          >
            <MoreHoriz fontSize="inherit" color="inherit" />
          </IconButton>
        )}
      </p>
    </div>
  );
};

DetailsBody.propTypes = {
  eventBody: PropTypes.string
};

DetailsBody.defaultProps = {
  eventBody: null
};

export default DetailsBody;
