import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import RadioDropdown from 'components/AnalyticsSalesforce/RadioDropdown';
import { GET_SALESFORCE_HISTORY_FORECAST } from 'gql/salesforce';
import HistoryForecastGraph from './Graphs/HistoryForecastGraph';
import HistoryForecastTable from './Tables/HistoryForecastTable';
import { opportunityOptions } from './SalesforceConsts';

const useStyles = makeStyles({
  box: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  },
  dropdowns: {
    display: 'flex'
  }
});

const tabs = [
  { title: 'Revenue', tab: 'revenue', isRevenue: true },
  { title: 'Deal Size', tab: 'deal_size', isRevenue: true },
  { title: 'Volume', tab: 'volume', isRevenue: false }
];

const SalesforceHistoryForecast = props => {
  const { linktokenId } = props;

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const [graphData, setGraphData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const [opportunity, setOpportunity] = useState({ value: null, label: 'All' });

  const { data, loading, error } = useQuery(GET_SALESFORCE_HISTORY_FORECAST, {
    variables: {
      linktokenId,
      opportunityFromLead: opportunity.value,
      after: page * rowsPerPage,
      count: rowsPerPage,
      tab: tabs[selectedTab].tab
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setPage(0);
  }, [selectedTab, opportunity]);

  useEffect(() => {
    const tempGraphData = [];
    const tempTableData = [];
    let tempCount = 0;

    if (!loading) {
      if (data?.getSalesforceHistoryForecast) {
        const { rows, count: totalCount } = data.getSalesforceHistoryForecast;

        rows.forEach(
          ({
            dimension,
            label,
            timeseries,
            timeseries_forecast: timeseriesForecast,
            history,
            forecast,
            hideTimeseries
          }) => {
            tempGraphData.push({
              dimension,
              label,
              timeseries,
              timeseriesForecast,
              hideTimeseries
            });
            tempTableData.push({ dimension, label, history, forecast });
          }
        );

        tempCount = totalCount;
      }

      setGraphData(tempGraphData);
      setTableData(tempTableData);
      setCount(tempCount);
    }
  }, [data, loading]);

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item className={classes.box}>
        <Grid container justifyContent="space-between">
          <span>
            <BoxHeader tabs={tabs.map(({ title }) => title)} onTabChange={setSelectedTab} />
          </span>
          <Grid item className={classes.dropdowns}>
            <RadioDropdown
              options={opportunityOptions}
              selectedOption={opportunity}
              setSelectedOption={setOpportunity}
            />
          </Grid>
        </Grid>
        <HistoryForecastGraph
          data={graphData}
          loading={loading}
          error={error}
          isRevenue={tabs[selectedTab].isRevenue}
        />
        <div
          style={{
            height: '14px',
            width: '100%',
            background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
            mixBlendMode: 'multiply'
          }}
        />
        <Grid container>
          <BoxHeader tabs={[`${tabs[selectedTab].title} Breakdown`]} />
        </Grid>
        <HistoryForecastTable
          tab={tabs[selectedTab].title}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          data={tableData}
          totalCount={count}
          loading={loading}
          error={error}
          isRevenue={tabs[selectedTab].isRevenue}
        />
      </Grid>
    </Grid>
  );
};

SalesforceHistoryForecast.propTypes = {
  linktokenId: PropTypes.string
};

SalesforceHistoryForecast.defaultProps = {
  linktokenId: null
};

export default SalesforceHistoryForecast;
