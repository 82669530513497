import { gql } from '@apollo/client';
import apolloClientHelper, { apolloClientQuery } from 'middleware/api';

export const INITIAL_USER_AUTH = `
  id
  avatar_url
  first_name
  last_name
  email
  phone
  company
  title
  bio
  time_zone
  force_password_change
  otp_enabled
  admin_level
  current_organization_id
  eula_accepted_at
  email_verified
  current_organization {
    id
    name
  }
  home_container_id
  home_container {
    id
    name
    avatar_url
  }
  role {
    id
    name
    role_type
  }
  privileges {
    short_name
  }
  preferences {
    key
    value_i
    value_s
  }
`;

export const GET_USER = gql`
  query($id: ID!) {
    User(id: $id) {
      id
      first_name
      last_name
      email
      role {
        id
        name
      }
      preferences {
        key
        value_i
        value_s
      }
    }
  }
`;

export const GET_ME = gql`
  query {
    me {
      user {
        ${INITIAL_USER_AUTH}
      }
      session {
        id
        user_id
        login_at
        expires
        mfa_passed
        mfa_required
      }
    }
  }
`;

export const GET_PRIVILEGES = gql`
  query {
    me {
      user {
        id
        privileges {
          short_name
        }
      }
    }
  }
`;

export async function apiGetUserPrivs() {
  // Fetch the user's permissions for the current container
  const privsResult = await apolloClientQuery(GET_PRIVILEGES, {});

  const privs = privsResult.data.me.user.privileges.map(priv => priv.short_name);

  return privs;
}

export const USER_SIGN_IN = gql`
  mutation($emailOrPhone: String!, $password: String!) {
    signIn(password: $password, emailOrPhone: $emailOrPhone) {
      user {
        ${INITIAL_USER_AUTH}
      }
      otpRequired
      expires
      token
    }
  }
`;

export const USER_SIGN_OUT = gql`
  mutation {
    signOut {
      message
      result
    }
  }
`;

export async function apiUserSignOut() {
  return apolloClientHelper(
    USER_SIGN_OUT,
    {},
    true // mutation
  );
}

export const USER_AUTH_OTP = gql`
  mutation($code: String!, $rememberDevice: Boolean!) {
    authOTP(code: $code, rememberDevice: $rememberDevice) {
      user {
        ${INITIAL_USER_AUTH}
      }
      expires
      token
    }
  }
`;

export const CREATE_USER = gql`
  mutation(
    $first_name: String!
    $last_name: String!
    $company: String!
    $password: String!
    $phone: String!
    $email: String!
    $time_zone: String!
    $is_eula_required: Boolean
    $eula_accepted: Boolean
  ) {
    createUser(
      first_name: $first_name
      last_name: $last_name
      company: $company
      password: $password
      email: $email
      phone: $phone
      time_zone: $time_zone
      is_eula_required: $is_eula_required
      eula_accepted: $eula_accepted
    ) {
      user {
        ${INITIAL_USER_AUTH}
      }
      expires
      token
    }
  }
`;

export const USER_START_RECOVER = gql`
  mutation($emailOrPhone: String!) {
    startUserRecovery(emailOrPhone: $emailOrPhone) {
      message
      result
    }
  }
`;

export const USER_EXEC_RECOVERY = gql`
  mutation($token: String!) {
    recoverWithToken(token: $token) {
      error
      result
      expires
      user {
        id
        first_name
        last_name
        bio
        title
        company
        phone
        phone_confirmed
        email
        email_verified
        time_zone
        otp_enabled
        home_container_id
        current_organization_id
        force_password_change
        created_at
        updated_at
        avatar_url
        privileges {
          short_name
        }
      }
    }
  }
`;

export const USER_VERIFY_RECOVERY_TOKEN = gql`
  query($token: String!) {
    verifyRecoveryToken(token: $token)
  }
`;

export const USER_CHANGE_PASSWORD = gql`
  mutation($old_password: String, $password: String!, $close_other_sessions: Boolean) {
    changeMyPassword(
      old_password: $old_password
      password: $password
      close_other_sessions: $close_other_sessions
    ) {
      message
      result
    }
  }
`;

export const USER_RECOVER_WITH_TOKEN = gql`
  mutation($token: String!, $password: String!) {
    recoverUserWithToken(token: $token, password: $password) {
      message
      result
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation(
    $first_name: String!
    $last_name: String!
    $company: String!
    $phone: String
    $time_zone: String
    $bio: String
    $title: String
  ) {
    updateUserProfile(
      first_name: $first_name
      last_name: $last_name
      bio: $bio
      title: $title
      company: $company
      phone: $phone
      time_zone: $time_zone
    ) {
      error
      result
      user {
        ${INITIAL_USER_AUTH}
      }
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation($handle: String!) {
    updateUserAvatar(handle: $handle) {
      error
      result
    }
  }
`;

export const DELETE_USER_AVATAR = gql`
  mutation {
    deleteUserAvatar {
      error
      result
    }
  }
`;

export const VERIFY_CONFIRMATION_CODE = gql`
  mutation($code: String!) {
    confirmPhone(code: $code) {
      message
      result
    }
  }
`;

export const RESEND_CONFIRMATION_CODE = gql`
  mutation($id: ID) {
    resendPhoneConfirmation(id: $id) {
      message
      result
    }
  }
`;

export const VERIFY_CONFIRMATION_EMAIL_CODE = gql`
  mutation($code: String!) {
    confirmEmail(code: $code) {
      auth {
        user {
          ${INITIAL_USER_AUTH}
        }
        expires
        token
      }
      email_confirm {
        message
        result
      }
    }
  }
`;

export async function apiUserResendConfirmation(id) {
  return apolloClientHelper(
    RESEND_CONFIRMATION_CODE,
    { id }, // A user ID is optional here; if not passed in, the confirmation code is re-sent to the current user
    true // mutation
  );
}

const USER_SEND_SMS = gql`
  mutation($userIds: [String]!, $message: String!) {
    sendSMS(userIds: $userIds, message: $message) {
      message
      result
    }
  }
`;

export async function apiUserSendSMS(userIds, message) {
  return apolloClientHelper(
    USER_SEND_SMS,
    { userIds, message },
    true // mutation
  );
}

const UPDATE_USER = gql`
  mutation($id: ID!, $first_name: String, $last_name: String, $phone: String!, $title: String) {
    updateUser(
      id: $id
      first_name: $first_name
      last_name: $last_name
      title: $title
      phone: $phone
    ) {
      error
      result
      user {
        id
        first_name
        last_name
        title
        phone
        phone_confirmed
      }
    }
  }
`;

export async function apiUserUpdate(data) {
  return apolloClientHelper(
    UPDATE_USER,
    {
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      bio: data.bio,
      title: data.title,
      company: data.company,
      phone: data.phone,
      time_zone: data.time_zone
    },
    true // mutation
  );
}

const CREATE_USER_BY_PHONE = gql`
  mutation(
    $first_name: String!
    $last_name: String!
    $title: String
    $phone: String!
    $existing_container_id: ID
  ) {
    createUser(
      first_name: $first_name
      last_name: $last_name
      title: $title
      phone: $phone
      existing_container_id: $existing_container_id
    ) {
      user {
        id
        first_name
        last_name
        title
        phone
        phone_confirmed
        email
        email_verified
        time_zone
        force_password_change
        home_container_id
        current_organization_id
        created_at
        updated_at
        avatar_url
      }
      expires
      token
    }
  }
`;

export const RESEND_CONFIRMATION_EMAIL = gql`
  mutation {
    resendConfirmationEmail {
      message
      result
    }
  }
`;

export async function apiUserRegisterByPhone(data) {
  return apolloClientHelper(
    CREATE_USER_BY_PHONE,
    {
      first_name: data.first_name,
      last_name: data.last_name,
      title: data.title,
      phone: data.phone,
      existing_container_id: data.existing_container_id
    },
    true // mutation
  );
}

export const UPDATE_USER_PREFERENCE = gql`
  mutation updateUserPreference($key: String!, $value_i: Int, $value_s: String) {
    updateUserPreference(key: $key, value_i: $value_i, value_s: $value_s) {
      id
      key
      value_i
      value_s
      created_at
      updated_at
    }
  }
`;

export async function apiUpdateUserPrefs(data) {
  return apolloClientHelper(UPDATE_USER_PREFERENCE, data, true);
}

export const GET_USER_FEATURE_FLAGS = gql`
  query getFeatureFlagsForUser {
    getFeatureFlagsForUser {
      name
      enabled
    }
  }
`;

export async function apiGetUserFeatureFlags(data) {
  return apolloClientHelper(GET_USER_FEATURE_FLAGS, data, false);
}

export const GET_VERSION = gql`
  query getVersion {
    getVersion {
      version
      environment
    }
  }
`;
