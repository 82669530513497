import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import Box from 'components/Box';
import CustomFooter from 'components/TablePagination';
import AlbTable from 'components/AlbTable';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { AlbTooltip } from 'components/AlbTooltip';
import IgnoreArticleModal from 'components/AlembicModalConfirm/IgnoreArticleModal';
import { GET_PAGINATED_ARTICLES } from 'gql/article';
import { ARTICLE_ICON } from 'util/assets';
import { getDatesForAnalytics } from 'util/date';
import colors from 'util/colors';
import ArticleDrawerViewContext from 'contexts/ArticleDrawerViewContext';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  reportNameText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    marginLeft: '5px',
    whiteSpace: 'nowrap'
  },
  article: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    height: '58px',
    width: '58px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    marginRight: '9px'
  },
  highlightMentionText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    wordBreak: 'break-word'
  },
  exclude: {
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: colors.red,
      borderColor: colors.red,
      color: 'white'
    }
  },
  include: {
    backgroundColor: colors.navy,

    '&:hover': {
      backgroundColor: colors.navy
    }
  }
});

const ArticlesTable = props => {
  const { selectedDates, articleSearchIds, articleSearchFilterIds, isEnabled } = props;
  const classes = useStyles();
  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['airDate', 'desc']);

  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedArticleTitle, setSelectedArticleTitle] = useState('');
  const [openIgnoreModal, setOpenIgnoreModal] = useState(false);

  const drawerContext = useContext(ArticleDrawerViewContext);

  const { loading, error, data, refetch } = useQuery(GET_PAGINATED_ARTICLES, {
    variables: {
      startDate,
      endDate,
      articleSearchIds,
      articleSearchFilterIds,
      after: page * rowsPerPage,
      count: rowsPerPage,
      orderBy: sortOrder,
      enabled: isEnabled
    },
    fetchPolicy: 'network-only'
  });

  const formatTableData = articleTableData =>
    articleTableData.map(article => ({
      ...article,
      airDate: article.published_date,
      mentionsCount: 1,
      ratingsAndImpactCount: article.total_ratings,
      articleSearch: article.article_search
    }));

  useEffect(() => {
    if (data?.getPaginatedArticles?.rows?.length) {
      setTableData(formatTableData(data.getPaginatedArticles.rows));
      setCount(data.getPaginatedArticles.count);
    } else {
      setTableData([]);
      setCount(0);
    }
  }, [data]);

  useEffect(() => {
    setTableData([]);
    setCount(0);
  }, [isEnabled]);

  const customArticleRender = (title, { rowData }) => {
    const [, imageUrl] = rowData;

    return (
      <Box className={classes.article}>
        {imageUrl && (
          <div
            aria-hidden="true"
            className={classes.image}
            style={{ backgroundImage: `url('${imageUrl}')` }}
          />
        )}
        <Typography className={classes.highlightMentionText}>{title}</Typography>
      </Box>
    );
  };

  const customReportNameRender = articleSearch => (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Box display="flex" justifyContent="center" style={{ width: '30px' }}>
        <img src={ARTICLE_ICON} alt="TV Icon" width={17} height={13} />
      </Box>

      <Typography variantMapping={{ body1: 'span' }} className={classes.reportNameText}>
        {articleSearch.name}
      </Typography>
    </Box>
  );

  const customDateRender = date => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {moment.utc(date).format('M/DD/YY')}
      <br />
      {`@ ${moment.utc(date).format('h:mma')}`}
    </div>
  );

  const customNarrativeRender = () => <Typography variantMapping={{ body1: 'span' }}>-</Typography>;

  const customValueRender = value => (
    <div style={{ textAlign: 'right' }}>{value ? Math.round(value).toLocaleString() : '-'}</div>
  );

  const customHeadRender = tableMeta => {
    const tooltip = {
      narrative: 'Narrative',
      ratingsAndImpactCount: 'RatingsAndImpactCount'
    }[tableMeta.name];

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>{tableMeta.label}</span>
        <AlbTooltip lookupKey={tooltip} prefix="tpm" />
      </div>
    );
  };

  const handleOpenIgnoreModal = (id, title) => {
    setSelectedArticle(id.toString());
    setSelectedArticleTitle(title);
    setOpenIgnoreModal(true);
  };

  const customExcludeRender = (_, { rowData }) => {
    const [id, , , , , title] = rowData;

    return (
      <Button
        className={`${isEnabled ? classes.exclude : classes.include}`}
        variant={!isEnabled ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => handleOpenIgnoreModal(id, title)}
      >
        {`${isEnabled ? 'Exclude' : 'Include'}`}
      </Button>
    );
  };

  const rowsPerPageOptions = [];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'thumbnail',
      options: { display: false }
    },
    {
      name: 'articleSearch',
      label: 'REPORT NAME',
      options: {
        customBodyRender: customReportNameRender,
        sort: false
      }
    },
    {
      name: 'airDate',
      label: 'DATE / TIME',
      options: {
        customBodyRender: customDateRender
      }
    },
    {
      name: 'narrative',
      label: 'NARRATIVE',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customNarrativeRender,
        sort: false
      }
    },
    {
      name: 'title',
      label: 'MENTION',
      options: {
        customBodyRender: customArticleRender,
        sort: false
      }
    },
    {
      name: 'mentionsCount',
      label: 'MENTIONS',
      options: {
        customBodyRender: customValueRender,
        sort: false
      }
    },
    {
      name: 'ratingsAndImpactCount',
      label: 'RATINGS / IMPACT',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender
      }
    },
    {
      name: 'actions',
      label: isEnabled ? 'EXCLUDE' : 'INCLUDE',
      options: {
        customBodyRender: customExcludeRender,
        sort: false
      }
    }
  ];

  const tableColumnNames = tableColumns.map(({ name }) => name);

  const mentionColumnIndex = tableColumnNames.indexOf('mention');
  const actionsColumnIndex = tableColumnNames.indexOf('actions');

  const tableThemeOverrides = {
    MUIDataTableHeadRow: {
      root: {
        [`& > :not(:nth-child(${mentionColumnIndex}))`]: {
          width: 'auto'
        }
      }
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',

        '& th': {
          padding: '0px',
          borderBottom: 'none'
        }
      }
    }
  };

  const onCellClick = (_, { rowIndex, colIndex }) => {
    if (colIndex !== actionsColumnIndex) {
      const { id } = tableData[rowIndex];
      drawerContext.toggleDrawer(true, id);
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    onCellClick,
    textLabels: {
      body: {
        noMatch: 'Sorry, no data available to display.'
      }
    }
  };

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      <AlbTable
        tableData={tableData}
        tableColumns={tableColumns}
        tableOptions={tableOptions}
        tableThemeOverrides={tableThemeOverrides}
      />
      <IgnoreArticleModal
        isModalOpen={openIgnoreModal}
        onChange={e => {
          setOpenIgnoreModal(e.isModalOpen);
          refetch();
        }}
        articleId={selectedArticle}
        articleTitle={selectedArticleTitle}
        exclude={isEnabled}
      />
    </div>
  );
};

ArticlesTable.propTypes = {
  selectedDates: PropTypes.shape().isRequired,
  articleSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEnabled: PropTypes.bool.isRequired
};

export default ArticlesTable;
