import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TOP_PROGRAM_LIFT_CONTRIBUTORS = gql`
  query topProgramLiftContributors(
    $programLiftReportId: ID!
    $endDate: DateTimeISO!
    $periodical: PeriodicalEnum!
    $hideDimensions: Boolean!
  ) {
    topProgramLiftContributors(
      programLiftReportId: $programLiftReportId
      endDate: $endDate
      periodical: $periodical
      hideDimensions: $hideDimensions
    ) {
      id
      program_lift_report_id
      event_type
      event_label
      base_magnitude
      non_program_lift_magnitude
      program_lift_magnitude
      event_end_date
      periodical
      ugc_label
    }
  }
`;
