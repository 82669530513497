import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const text = {
  BRAND_EQUITY: 'General Attention (Brand)',
  DEMAND_GENERATION: 'Specific Attention (Demand)',
  AUDIENCE_INTERACTION: 'Audience Interaction',
  CONVERSION: 'Target (Conv./ Revenue)'
};

const AlbProgressCircle = props => {
  const { radius, stroke, progress, type } = props;
  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const strokeDashoffsetA = circumference - (100 / 100) * circumference;

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        style={{
          height: radius * 2,
          width: 'min-content',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          position: 'absolute',
          fontWeight: '500',
          fontSize: '15px',
          color: '#6F6F6F'
        }}
      >
        {text[type]}
      </Typography>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#C4C4C4"
          fill="transparent"
          // strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{
            strokeDashoffset: strokeDashoffsetA,
            transition: 'stroke-dashoffset 0.35s',
            transform: 'rotate(-90deg)',
            transformOrigin: ' 50% 50%'
          }}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="#4DA457"
          fill="transparent"
          // strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{
            strokeDashoffset,
            transition: 'stroke-dashoffset 0.35s',
            transform: 'rotate(-90deg)',
            transformOrigin: ' 50% 50%'
          }}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};

AlbProgressCircle.propTypes = {
  radius: PropTypes.number,
  stroke: PropTypes.number,
  progress: PropTypes.number,
  type: PropTypes.string
};

AlbProgressCircle.defaultProps = {
  radius: null,
  stroke: null,
  progress: null,
  type: null
};

export default AlbProgressCircle;
