import { gql } from '@apollo/client';

export const PROGRAM_LIFT_REPORT = gql`
  query programLiftReport($id: ID!) {
    programLiftReport(id: $id) {
      id
      container_id
      name
      keywords
      start_date
      end_date
      enabled
      deleted
      explanation
    }
  }
`;

export const PROGRAM_LIFT_REPORTS = gql`
  query programLiftReports($enabled: Boolean, $count: Int, $after: Int) {
    programLiftReports(enabled: $enabled, count: $count, after: $after) {
      rows {
        id
        container_id
        name
        keywords
        start_date
        end_date
        enabled
        deleted
      }
      count
    }
  }
`;

export const UPDATE_PROGRAM_LIFT_REPORT = gql`
  mutation updateProgramLiftReport($id: ID!, $input: UpdateProgramLiftReportInput!) {
    updateProgramLiftReport(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const ARCHIVE_PROGRAM_LIFT_REPORT = gql`
  mutation disableProgramLiftReport($id: ID!) {
    disableProgramLiftReport(id: $id) {
      id
      name
    }
  }
`;

export const UNARCHIVE_PROGRAM_LIFT_REPORT = gql`
  mutation enableProgramLiftReport($id: ID!) {
    enableProgramLiftReport(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_PROGRAM_LIFT_REPORT = gql`
  mutation deleteProgramLiftReport($id: ID!) {
    deleteProgramLiftReport(id: $id) {
      result
      message
    }
  }
`;

export const CREATE_PROGRAM_LIFT_REPORT = gql`
  mutation createProgramLiftReport(
    $name: String!
    $keywords: String!
    $linktokenIds: [ID!]
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
    $articleSearchIds: [ID!]
    $articleSearchFilterIds: [ID!]
    $include_shortlink: Boolean
  ) {
    createProgramLiftReport(
      name: $name
      keywords: $keywords
      linktokenIds: $linktokenIds
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
      articleSearchIds: $articleSearchIds
      articleSearchFilterIds: $articleSearchFilterIds
      include_shortlink: $include_shortlink
    ) {
      id
      name
    }
  }
`;
