import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { RECREATE_COST_REPORT } from 'gql/recreateCostReport';
import AnalyticsHeader from 'components/AnalyticsHeader';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import AlbLoading from 'components/AlbLoading';
import { parseQueryString, setQueryStringForAnalyticsModal, goToRoute } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import {
  getInitialAnalyticsStartDate,
  getInitialAnalyticsEndDate,
  getDatesForAnalytics,
  checkValidDate
} from 'util/date';
import handleGraphQLError from 'util/error';
import { CostToRecreateAnalyticsPath, DashboardEarnedPathV4 } from 'util/paths';
import MediaValueContainer from './MediaValueContainer';
import FinalSavedCostToRecreate from './FinalSavedCostToRecreate';
import CostToRecreateHeader from './CostToRecreateHeader';

const style = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  },
  marginSection: {
    margin: '30px'
  }
});

/**
 * Renders an existing cost to recreate report
 * @name CostToRecreateAnalytics
 * @param {Object} props - React props passed to this component
 * @param {Object} props.history - The history object from react router
 * @param {Object} props.location - The location object from react router
 * @param {Object} props.match - The match object from react router
 * @param {Object} props.currentUser - The currentUser object from redux
 * @return {Object} - React JSX
 */
const CostToRecreateAnalytics = props => {
  const { history, location, match, currentUser } = props;
  const [recreateCostReport, setRecreateCostReport] = useState(null);
  const [valuationType, setValuationType] = useState('cpm');
  const [useLifetimeValue, setUseLifeTimeValue] = useState(true);

  const classes = style();
  const dialogRef = useRef();

  const [calRange, changeCalRange] = useState({
    start: getInitialAnalyticsStartDate(30, currentUser, 'days'),
    end: getInitialAnalyticsEndDate(currentUser)
  });

  const { start: startDate, end: endDate } = getDatesForAnalytics(calRange.start, calRange.end);

  const { loading, error, data, refetch } = useQuery(RECREATE_COST_REPORT, {
    variables: {
      id: match?.params?.reportId,
      valuationType,
      startDate,
      endDate,
      useLifetimeValue
    }
  });

  // After component has mounted check the URL parameters for a different start/end date than the default
  useEffect(() => {
    setPageTitle('Media Valuation Analytics');

    if (location?.search) {
      let queryStringStartDate = parseQueryString('start_date', location);
      let queryStringEndDate = parseQueryString('end_date', location);

      if (queryStringStartDate[0] && queryStringEndDate[0]) {
        queryStringStartDate = new Date(moment(queryStringStartDate[0]));
        queryStringEndDate = new Date(moment(queryStringEndDate[0]));

        if (
          checkValidDate(queryStringStartDate) &&
          checkValidDate(queryStringEndDate) &&
          queryStringStartDate < queryStringEndDate
        ) {
          // If the dates do not match then set the date picker range to the dates from the URL
          if (calRange.start !== queryStringStartDate || calRange.end !== queryStringEndDate) {
            changeCalRange({ start: queryStringStartDate, end: queryStringEndDate });
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.recreateCostReport?.id) {
      setRecreateCostReport(data.recreateCostReport);
    }
  }, [data]);

  // Update URL parameters when date range changes
  useEffect(() => {
    const startDateTemp = moment(calRange.start);
    const endDateTemp = moment(calRange.end);

    setQueryStringForAnalyticsModal('start_date', [startDateTemp.format('YYYY-MM-DD')]);
    setQueryStringForAnalyticsModal('end_date', [endDateTemp.format('YYYY-MM-DD')]);
  }, [calRange]);

  const handleOnChangeMetric = event => {
    if (event?.useCPM) {
      setValuationType('cpm');
    }

    if (event?.useCPA) {
      setValuationType('cpa');
    }
  };

  const handleOnChangeLifeTimeValue = event => {
    setUseLifeTimeValue(event);
  };

  return (
    <HasAnyPriv
      privs={[
        'SVC:CHAN:STATS',
        'SVC:CHAN:STATS:LI',
        'SVC:CHAN:STATS:FB',
        'SVC:CHAN:STATS:TW',
        'SVC:CHAN:STATS:IN',
        'SVC:CHAN:STATS:EM',
        'SVC:CHAN:STATS:DM'
      ]}
      showAlert
    >
      <Dialog fullScreen open classes={{ paperFullScreen: classes.root }} ref={dialogRef}>
        <Grid container justifyContent="center">
          <AnalyticsHeader
            displayTitle="Media Valuation"
            calRange={calRange}
            changeCalRange={changeCalRange}
            defaultButtonLabel="30"
            onClose={() => {
              const searchParams = new URLSearchParams(location.search);
              let backRoute = CostToRecreateAnalyticsPath;

              if (searchParams.has('from_dash')) {
                backRoute = DashboardEarnedPathV4;
              }

              goToRoute(backRoute, history);
            }}
            dialogRef={dialogRef}
            hideAccountSelector
            hideContainerSelector
            delta
          />
          <Grid item xs={9}>
            <Box my={50}>
              {loading && <AlbLoading />}
              {!loading && recreateCostReport != null && (
                <>
                  <CostToRecreateHeader
                    isAnalytics
                    onChangeMetric={handleOnChangeMetric}
                    recreateCostReport={recreateCostReport}
                    switchChecked={valuationType === 'cpa'}
                    onChangeLifeTimeValue={handleOnChangeLifeTimeValue}
                    useLifetimeValueChecked={useLifetimeValue}
                  />
                  <MediaValueContainer
                    className={classes.marginSection}
                    useLifetimeValue={useLifetimeValue}
                    valuationType={valuationType}
                    startDate={startDate}
                    endDate={endDate}
                    isPreview={false}
                    costToRecreateId={recreateCostReport?.id}
                  />
                  <FinalSavedCostToRecreate
                    className={classes.marginSection}
                    recreateCostReport={recreateCostReport}
                    valuationType={valuationType}
                    dates={{ startDate, endDate }}
                    useLifetimeValue={useLifetimeValue}
                    refetch={refetch}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </HasAnyPriv>
  );
};

CostToRecreateAnalytics.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(CostToRecreateAnalytics);
