import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    height: '176px',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    '@media print': {
      '& > div': {
        flex: 'none'
      }
    }
  }
});

const AnalyticsHeader = props => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.header}>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
};

AnalyticsHeader.propTypes = {
  children: PropTypes.node.isRequired
};

AnalyticsHeader.defaultProps = {};

export default AnalyticsHeader;
