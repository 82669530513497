// ErrorBoundary.js

import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      // render fallback UI
      return (
        <div className="error">
          <h1>Something went wrong and we&apos;ve been notified</h1>
          <pre>{error.message}</pre>
        </div>
      );
    }

    // when there's not an error, render children untouched
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};

export default ErrorBoundary;
