import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontWeight: 600,
    fontSize: '26px',
    color: '#0A1734'
  }
});

const ProgramIntensity = props => {
  const { loading, error, data } = props;
  const classes = useStyles();

  const mapLabels = ({ intensity_date: x }) => {
    return moment.utc(x).format('M/D/YY');
  };

  const mapData = ({ value: y }) => {
    return y;
  };

  const graphData = {
    labels: data?.programLiftIntensities?.map(mapLabels) ?? [],
    datasets:
      data?.programLiftIntensities?.length > 0
        ? [
            {
              label: '',
              type: 'line',
              data: data?.programLiftIntensities?.map(mapData),
              backgroundColor: '#1DA1F2',
              borderColor: '#1DA1F2',
              fill: false,
              lineTension: 0,
              pointRadius: 0
            }
          ]
        : []
  };

  const graphOptions = {
    legend: {
      display: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'label',
      intersect: false,
      callbacks: {
        label(tooltipItem, chartData) {
          const valueText = Math.round(
            chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          ).toLocaleString();

          return valueText;
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: { display: false },
          gridLines: { display: false }
        }
      ],
      yAxes: [
        {
          ticks: { display: false },
          gridLines: { display: false }
        }
      ]
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>PROGRAM INTENSITY</Typography>
      {loading && <AlbLoading />}
      {error && <AlbError error={error} />}
      <div style={{ height: 100, width: 380, position: 'relative' }}>
        <Bar data={graphData} options={graphOptions} />
      </div>
    </div>
  );
};

ProgramIntensity.defaultProps = {
  loading: false,
  error: null,
  data: null
};

ProgramIntensity.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  data: PropTypes.shape()
};

export default ProgramIntensity;
