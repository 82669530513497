import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItemIcon, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { linePalette } from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import GraphBody from 'components/PostDrawerView/GraphBody';
import GraphHeader from 'components/PostDrawerView/GraphHeader';
import Box from 'components/Box';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';
import colors from 'util/colors';

const useStyles = makeStyles({
  graphContainer: {
    flex: 1
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInner: {
    position: 'relative',
    minHeight: '40px',

    '& > :last-child': {
      paddingBottom: '0px !important'
    }
  },
  legend: {
    marginTop: '10px'
  },
  legendColor: {
    height: '16px',
    width: '16px',
    backgroundColor: linePalette.backgroundColor,
    borderRadius: '3px',
    marginRight: '10px'
  },
  legendText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '12px',
    color: '#6F6F6F',

    '& > :last-child': {
      color: '#0b1835'
    }
  }
});

const webEventTypes = [DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT, DETECTED_EVENT_TYPE.SA_TOTAL_SHARES];

const ThirdPartyMediaDrawerGraph = props => {
  const { details } = props;
  const {
    label,
    query,
    queryName,
    variables,
    firstAirDate,
    lastAirDate,
    startDate,
    endDate,
    type
  } = details;
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState(webEventTypes.includes(type) ? 0 : 3);

  const startOfFirstDate = moment.utc(firstAirDate).startOf('day');

  const startOfStartDate = moment.utc(startDate).startOf('day');
  const endOfEndDate = moment.utc(endDate).endOf('day');

  const dateRangeOptions = webEventTypes.includes(type)
    ? [
        {
          label: '+/- 3D',
          range: {
            start: startOfStartDate.clone().subtract(3, 'd'),
            end: endOfEndDate.clone().add(3, 'd')
          }
        },
        {
          label: '+/- 7D',
          range: {
            start: startOfStartDate.clone().subtract(7, 'd'),
            end: endOfEndDate.clone().add(7, 'd')
          }
        },
        {
          label: '+/- 30D',
          range: {
            start: startOfStartDate.clone().subtract(30, 'd'),
            end: endOfEndDate.clone().add(30, 'd')
          }
        }
      ]
    : [
        {
          label: 'FIRST 7D',
          range: {
            start: startOfFirstDate,
            end: moment
              .utc(lastAirDate)
              .endOf('day')
              .add(7, 'd')
          }
        },
        {
          label: 'FIRST 30D',
          range: {
            start: startOfFirstDate,
            end: moment
              .utc(lastAirDate)
              .endOf('day')
              .add(30, 'd')
          }
        },
        {
          label: 'LIFETIME',
          range: { start: startOfFirstDate, end: moment.utc(lastAirDate).endOf('day') }
        },
        {
          label: 'IN TIME PERIOD',
          range: { start: startDate, end: endDate }
        }
      ];

  const { loading, error, data } = useQuery(query, {
    variables: {
      startDate: dateRangeOptions[selectedDateRange].range.start,
      endDate: dateRangeOptions[selectedDateRange].range.end,
      ...variables
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    let currentTotal = 0;
    const labels = [];
    const datasets = [];

    if (data?.[queryName]?.length > 0) {
      const tempData = [];
      data[queryName].forEach(dataPoint => {
        labels.push(moment.utc(dataPoint.x).format('MMM D/YYYY'));
        tempData.push(dataPoint.y);
        currentTotal += dataPoint.y;
      });

      datasets.push({
        label,
        type: 'bar',
        data: tempData,
        ...linePalette
      });
    }
    setTotal(Math.round(currentTotal));
    setGraphData({ labels, datasets });
  }, [data]);

  return (
    <Grid container style={{ padding: '25px' }}>
      <Grid
        item
        className={classes.collapse}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        <ListItemIcon classes={{ root: classes.listItem }}>
          {isCollapsed ? <Right /> : <Down />}
        </ListItemIcon>
        {!isCollapsed && <Box className={classes.expandedIndicator} />}
      </Grid>
      <Grid item className={classes.graphContainer}>
        <GraphHeader
          label={label}
          isCollapsed={isCollapsed}
          dateRangeOptions={dateRangeOptions}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          hasGraph
        />
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          <Grid container justifyContent="center">
            {loading && <AlbLoading />}
            {error && <AlbError error={error} />}
            {!loading && !error && (
              <GraphBody
                graphData={graphData}
                isCollapsed={isCollapsed}
                graphChildren={[]}
                maxWidth={580}
                hasGraph
              />
            )}
            {!isCollapsed && (
              <Grid className={classes.legend} container justifyContent="center">
                <Grid className={classes.legendColor} item />
                <Grid className={classes.legendText} item>
                  <Grid item>{label}</Grid>
                  <Grid item>{total.toLocaleString()}</Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

ThirdPartyMediaDrawerGraph.propTypes = {
  details: PropTypes.shape().isRequired
};

export default ThirdPartyMediaDrawerGraph;
