/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AlbTable from 'components/AlbTable';

/**
 * @summary Renders AA Segments linktokens table
 * @name TopChannelTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.tableColumns - column data
 * @param {String} props.tableData - table row data
 * @return {Object} - React JSX
 */
const AdobeAnalyticsSegmentsTable = props => {
  const { tableColumns, tableData } = props;

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [count] = useState(0);

  const tableThemeOverrides = {
    ...(tableData.length && {
      MuiTableRow: {
        root: {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr'
        }
      },
      MuiTableBody: {
        root: {
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr '.repeat(tableData.length)
        }
      }
    }),
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        height: '100%'
      },
      responsiveBase: {
        position: 'unset !important',
        height: '100% !important'
      },
      tableRoot: {
        height: '100%'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap'
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none'
      },
      body: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        height: '100%'
      }
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    sort: false,
    pagination: false,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count
  };

  return (
    <AlbTable
      tableData={tableData}
      tableColumns={tableColumns}
      tableOptions={tableOptions}
      tableThemeOverrides={tableThemeOverrides}
    />
  );
};

AdobeAnalyticsSegmentsTable.propTypes = {
  tableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default AdobeAnalyticsSegmentsTable;
