/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import AlbTable from 'components/AlbTable';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import CustomFooter from 'components/TablePagination';
import { customValueRender } from 'components/AlbTable/CustomRender';
import customNameRender from './ShareOfVoiceName';
import customActionsRender from './ShareOfVoiceTableActions';

/**
 * @method
 * @summary This component renders a table for share of voice reports
 * @name ShareOfVoiceTable
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.enabled - A boolean flag to query enabled or disabled reports
 * @return {Object} - React JSX
 */
const ShareOfVoiceTable = props => {
  const { data, page, rowsPerPage, setPage, setRowsPerPage, refetch, error, loading } = props;

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [sortOrder, setSortOrder] = useState(['', '']);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (data?.paginatedShareOfVoiceReports?.rows) {
      const { rows, count: rowsCount } = data.paginatedShareOfVoiceReports;

      const formattedTableData = rows.map(report => {
        const {
          id,
          name,
          enabled: isEnabled,
          is_default: isDefault,
          article_searches,
          article_search_filters
        } = report;

        const reportName = {
          shareOfVoiceId: id,
          shareOfVoiceName: name,
          isDefault
        };

        const actions = {
          shareOfVoiceId: id,
          shareOfVoiceName: name,
          isEnabled,
          isDefault,
          refetch
        };

        const combinedArticleCount = article_searches.length + article_search_filters.length;

        return {
          reportName,
          articleSearchCount: combinedArticleCount,
          actions
        };
      });

      setTableData(formattedTableData);
      setCount(rowsCount);
    }
  }, [data]);

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableColumns = [
    {
      name: 'reportName',
      label: 'REPORT NAME',
      options: { customBodyRender: customNameRender, sort: false }
    },
    {
      name: 'articleSearchCount',
      label: 'BASE REPORTS',
      options: { customBodyRender: customValueRender, sort: false }
    },
    {
      name: 'actions',
      label: 'ACTIONS',
      options: {
        customBodyRender: customActionsRender,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false
      }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  if (loading) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbLoading />
      </Grid>
    );
  }
  if (error) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={error} />
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />
    </Grid>
  );
};

ShareOfVoiceTable.propTypes = {
  data: PropTypes.shape(),
  error: PropTypes.shape(),
  loading: PropTypes.bool,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

ShareOfVoiceTable.defaultProps = {
  data: null,
  error: null,
  loading: false
};

export default ShareOfVoiceTable;
