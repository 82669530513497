import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, ClickAwayListener } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MoreVert as Actions } from '@material-ui/icons';
import DeleteLabelFilterModal from 'components/AlembicModalConfirm/DeleteLabelFilterModal';
import Box from 'components/Box';
import theme from 'theme';

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    direction: 'rtl',
    textAlign: 'center'
  },
  actions: {
    position: 'absolute',
    alignItems: 'start',
    textAlign: 'left'
  },
  actionsMenu: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: 'white',
    padding: '25px',
    zIndex: 1
  },
  actionsOptions: {
    marginRight: '20px',

    '& div': {
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },

    '& :not(:last-child)': {
      marginBottom: '30px'
    }
  }
});

const modalTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      paper: {
        '& > form': {
          '& > div': {
            padding: '12px'
          }
        },

        '& > div': {
          padding: '12px'
        }
      },
      paperWidthSm: {
        padding: '8px'
      }
    }
  }
});

const LabelFiltersTableActions = props => {
  const { eventType, eventLabel } = props;

  const classes = useStyles();

  const [displayedActions, setDisplayedActions] = useState(false);
  const [isDeleteLabelFilterModalOpen, toggleDeleteLabelFilterModal] = useState(false);

  const actions = [
    {
      label: 'Restore Event',
      action: () => toggleDeleteLabelFilterModal(!isDeleteLabelFilterModalOpen)
    }
  ];

  return (
    <Box className={classes.actionsWrapper}>
      {displayedActions && (
        <Grid container wrap="nowrap" className={classes.actions}>
          <ClickAwayListener onClickAway={() => setDisplayedActions(false)}>
            <Paper className={classes.actionsMenu}>
              <Grid item className={classes.actionsOptions}>
                {actions.map(action => (
                  <Grid
                    item
                    key={action.label}
                    onClick={e => {
                      e.stopPropagation();
                      action.action();
                    }}
                  >
                    {action.label}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Grid>
      )}
      <IconButton
        size="small"
        style={displayedActions ? { zIndex: 2 } : {}}
        onClick={e => {
          e.stopPropagation();
          setDisplayedActions(true);
        }}
      >
        <Actions />
      </IconButton>
      <MuiThemeProvider theme={modalTheme}>
        {isDeleteLabelFilterModalOpen && (
          <DeleteLabelFilterModal
            eventType={eventType}
            eventLabel={eventLabel}
            isModalOpen={isDeleteLabelFilterModalOpen}
            onChange={e => toggleDeleteLabelFilterModal(e.isModalOpen)}
          />
        )}
      </MuiThemeProvider>
    </Box>
  );
};

LabelFiltersTableActions.propTypes = {
  eventType: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired
};

export default LabelFiltersTableActions;
