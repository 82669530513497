import { gql } from '@apollo/client';
import { ASSET_FIELD_NAMES } from './asset';

export const RECREATE_COST_REPORT = gql`
  query recreateCostReport(
    $id: ID!
    $valuationType: String!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $after: Int
    $count: Int
    $useLifetimeValue: Boolean!
  ) {
    recreateCostReport(id: $id) {
      id
      name
      article_search_id
      owned(
        valuationType: $valuationType
        startDate: $startDate
        endDate: $endDate
        after: $after
        count: $count
        useLifetimeValue: $useLifetimeValue
      ) {
        graphData {
          x
          y
        }
      }
      ownedIgnored(
        valuationType: $valuationType
        startDate: $startDate
        endDate: $endDate
        after: $after
        count: $count
        useLifetimeValue: $useLifetimeValue
      ) {
        graphData {
          x
          y
        }
      }
      earned(
        valuationType: $valuationType
        startDate: $startDate
        endDate: $endDate
        after: $after
        count: $count
      ) {
        graphData {
          x
          y
        }
      }
      earnedIgnored(
        valuationType: $valuationType
        startDate: $startDate
        endDate: $endDate
        after: $after
        count: $count
      ) {
        graphData {
          x
          y
        }
      }
      article_search {
        id
      }
    }
  }
`;

export const RECREATE_COST_REPORTS = gql`
  query recreateCostReports($enabled: Boolean, $count: Int, $after: Int) {
    recreateCostReports(enabled: $enabled, count: $count, after: $after) {
      rows {
        id
        name
        article_search_id
        post_text_keywords
        image_tags
        video_tags
        media_types
        post_types
        enabled
        deleted
        is_default
        selectedCampaignEvents {
          id
        }
        total_articles
        linktokens {
          id
        }
      }
      count
    }
  }
`;

export const RECREATE_COST_REPORT_CAMPAIGN_EVENTS = gql`
  query recreateCostReport(
    $id: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $count: Int
    $after: Int
    $orderBy: [String]
    $valuationType: String
    $useLifetimeValue: Boolean!
    $ignoredArticleFilter: ignoredArticleFilterEnum
  ) {
    recreateCostReport(id: $id) {
      id
      paginatedCampaignEvents(
        startDate: $startDate
        endDate: $endDate
        count: $count
        after: $after
        orderBy: $orderBy
        valuationType: $valuationType
        useLifetimeValue: $useLifetimeValue
        ignoredArticleFilter: $ignoredArticleFilter
      ) {
        rows {
          campaignEvent {    
            id
            type
            event_body
            event_title
            completed_at
            lifetime_impressions
            lifetime_shares
            lifetime_views
            valuation
            linked_account {
              type
            }
            thumbnail_url
            assets {
              ${ASSET_FIELD_NAMES}
            }
          }
          valuation
          impressions
          shares
        }
        count
      }
    }
  }
`;

export const RECREATE_COST_REPORT_ARTICLES = gql`
  query recreateCostReport(
    $id: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $count: Int
    $after: Int
    $orderBy: [String]
    $valuationType: String
    $ignoredArticleFilter: ignoredArticleFilterEnum
  ) {
    recreateCostReport(id: $id) {
      id
      paginatedArticles(
        startDate: $startDate
        endDate: $endDate
        count: $count
        after: $after
        orderBy: $orderBy
        valuationType: $valuationType
        ignoredArticleFilter: $ignoredArticleFilter
      ) {
        rows {
          id
          image
          thumbnail
          url
          created_at
          published_date
          title
          total_share_count
          valuation
        }
        count
      }
    }
  }
`;

export const UPDATE_RECREATE_COST_REPORT = gql`
  mutation updateRecreateCostReport(
    $id: ID!
    $name: String
    $reportId: ID
    $isFilter: Boolean
    $linkedAccounts: [ID]
    $postTextKeywords: String
    $imageTags: String
    $videoTags: String
    $mediaTypes: MediaTypeEnum
    $postTypes: PostTypeEnum
    $enabled: Boolean
  ) {
    updateRecreateCostReport(
      id: $id
      name: $name
      reportId: $reportId
      isFilter: $isFilter
      linkedAccounts: $linkedAccounts
      postTextKeywords: $postTextKeywords
      imageTags: $imageTags
      videoTags: $videoTags
      mediaTypes: $mediaTypes
      postTypes: $postTypes
      enabled: $enabled
    ) {
      id
      name
    }
  }
`;

export const CREATE_RECREATE_COST_REPORT = gql`
  mutation createRecreateCostReport(
    $reportId: ID!
    $isFilter: Boolean!
    $name: String!
    $linkedAccounts: [ID!]
    $postTextKeywords: String
    $imageTags: String
    $videoTags: String
    $mediaTypes: MediaTypeEnum
    $postTypes: PostTypeEnum
    $enabled: Boolean
  ) {
    createRecreateCostReport(
      reportId: $reportId
      isFilter: $isFilter
      name: $name
      linkedAccounts: $linkedAccounts
      postTextKeywords: $postTextKeywords
      imageTags: $imageTags
      videoTags: $videoTags
      mediaTypes: $mediaTypes
      postTypes: $postTypes
      enabled: $enabled
    ) {
      id
      name
    }
  }
`;

export const MAKE_DEFAULT_RECREATE_COST_REPORT = gql`
  mutation makeDefaultRecreateCostReport($id: ID!) {
    makeDefaultRecreateCostReport(id: $id) {
      id
      name
    }
  }
`;

export const ARCHIVE_RECREATE_COST_REPORT = gql`
  mutation disableRecreateCostReport($id: ID!) {
    disableRecreateCostReport(id: $id) {
      id
      name
    }
  }
`;

export const UNARCHIVE_RECREATE_COST_REPORT = gql`
  mutation enableRecreateCostReport($id: ID!) {
    enableRecreateCostReport(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_RECREATE_COST_REPORT = gql`
  mutation deleteRecreateCostReport($id: ID!) {
    deleteRecreateCostReport(id: $id) {
      result
      message
    }
  }
`;

export const ADD_CAMPAIGN_EVENT_TO_COST_REPORTS = gql`
  mutation addCampaignEventToCostReports($campaignEventId: ID!, $reportIds: [ID!]) {
    addCampaignEventToCostReports(campaignEventId: $campaignEventId, reportIds: $reportIds) {
      result
      message
    }
  }
`;

export const DELETE_CAMPAIGN_EVENT_FROM_COST_REPORTS = gql`
  mutation deleteCampaignEventFromCostReports($campaignEventId: ID!, $reportIds: [ID!]) {
    deleteCampaignEventFromCostReports(campaignEventId: $campaignEventId, reportIds: $reportIds) {
      result
      message
    }
  }
`;

export const EXCLUDE_POST_FROM_COST_REPORT = gql`
  mutation excludeCampaignEventFromCostReport(
    $campaignEventId: ID!
    $reportId: ID!
    $ignored: Boolean!
  ) {
    excludeCampaignEventFromCostReport(
      campaignEventId: $campaignEventId
      reportId: $reportId
      ignored: $ignored
    ) {
      result
      message
    }
  }
`;

export const EXCLUDE_ARTICLE_FROM_COST_REPORT = gql`
  mutation excludeArticleFromCostReport($articleId: ID!, $reportId: ID!, $ignored: Boolean!) {
    excludeArticleFromCostReport(articleId: $articleId, reportId: $reportId, ignored: $ignored) {
      result
      message
    }
  }
`;

export const GET_DEFAULT_RECREATE_COST_REPORT = gql`
  query getDefaultRecreateCostReport {
    getDefaultRecreateCostReport {
      id
      name
    }
  }
`;

export const GET_TOP_CHANNELS_AND_POSTS_RECREATE_COST_REPORT = gql`
  query recreateCostReportPreview(
    $id: ID
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $valuationType: String!
    $isPreview: Boolean!
    $postTextKeywords: String
    $postTypes: PostTypeEnum
    $videoTags: String
    $linkedAccounts: [ID!]
    $imageTags: String
    $mediaTypes: MediaTypeEnum
    $useLifetimeValue: Boolean!
  ) {
    recreateCostReportPreview(
      id: $id
      startDate: $startDate
      endDate: $endDate
      valuationType: $valuationType
      isPreview: $isPreview
      postTextKeywords: $postTextKeywords
      postTypes: $postTypes
      videoTags: $videoTags
      linkedAccounts: $linkedAccounts
      imageTags: $imageTags
      mediaTypes: $mediaTypes
      useLifetimeValue: $useLifetimeValue
    ) {
      topChannels {
        linktoken {
          id
          remote_name
          type
        }
        impressions
        shares
        valuation
      }
      topPosts {
        campaignEvent {
          id
          event_title
          event_body
          type
          assets {
            id
            signed_thumbnail_url
          }
          thumbnail_url
        }
        impressions
        shares
        valuation
      }
      totalValuation
    }
  }
`;

export const GET_TOP_DOMAINS_AND_ARTICLES_RECREATE_COST_REPORT = gql`
  query recreateCostReportEarnedPreview(
    $id: ID
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $valuationType: String!
    $isPreview: Boolean!
    $reportId: String
    $isFilter: Boolean
  ) {
    recreateCostReportEarnedPreview(
      id: $id
      startDate: $startDate
      endDate: $endDate
      valuationType: $valuationType
      isPreview: $isPreview
      reportId: $reportId
      isFilter: $isFilter
    ) {
      totalValuation
      topDomains {
        domain
        shares
        valuation
      }
      topArticles {
        article {
          title
          thumbnail
        }
        shares
        valuation
      }
    }
  }
`;
