import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Typography } from '@material-ui/core';
import Box from 'components/Box';
import AlbSwitch from 'components/AlbSwitch';
import { AlbTooltip } from 'components/AlbTooltip';
import { formatDateRange } from 'util/date';
import TopNarrativesGraph from './TopNarrativesGraph';
import TotaledNarrativesLineGraph from './TotaledNarrativesLineGraph';
import TotaledMentionsBarGraph from './TotaledMentionsBarGraph';
import EnabledNarratives from '../Tables/EnabledNarratives';
import DisabledNarratives from '../Tables/DisabledNarratives';
import EnabledMentions from '../Tables/EnabledMentions';
import DisabledMentions from '../Tables/DisabledMentions';
import ArticlesTable from '../Tables/ArticlesTable';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    background: '#FFFFFF',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  tabOverRide: {
    fontSize: 18,
    fontWeight: 500
  },
  datesLabel: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
    color: '#0A1734'
  },
  datesText: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    color: '#6F6F6F',
    marginLeft: '4px',
    marginRight: '24px'
  },
  switchLabel: {
    fontSize: '13px',
    lineHeight: '20px'
  },
  reportNameText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    marginLeft: '20px'
  },
  narrativeTitleText: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#0A1734'
  },
  highlightMentionText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000'
  }
}));

const NarrativeGraphAndTable = props => {
  const {
    selectedDates,
    articleSearchIds,
    articleSearchFilterIds,
    broadcastSearchIds,
    podcastSearchIds
  } = props;
  const classes = useStyles();
  const [activeGraphTab, setActiveGraphTab] = useState(0);
  const [activeTableTab, setActiveTableTab] = useState(0);

  const [isTotaledSwitchChecked, setIsTotaledSwitchChecked] = useState(false);
  const [isImpactSwitchChecked, setIsImpactSwitchChecked] = useState(false);
  const [isMentionsSwitchChecked, setIsMentionsSwitchChecked] = useState(false);
  const [isWebTab, setIsWebTab] = useState(false);
  const [after, setAfter] = useState(0);
  const [sortOrder, setSortOrder] = useState(['mentionsCount', 'desc']);
  const isEnabled = activeTableTab === 0;

  const WEB_TAB = 'Web';

  const graphTabs = [
    isMentionsSwitchChecked ? 'All Mentions' : 'Top Narratives',
    'TV',
    'Radio',
    'Podcast',
    WEB_TAB
  ];

  const webTabIndex = graphTabs.indexOf(WEB_TAB);
  const tableNarrativeTabs = ['Narratives Breakdown', 'Excluded Narratives'];
  const tableMentionTabs = ['Mentions Breakdown', 'Excluded Mentions'];

  useEffect(() => {
    if (isWebTab) {
      setIsTotaledSwitchChecked(true);
      setIsMentionsSwitchChecked(true);
    }
  }, [isWebTab]);

  useEffect(() => {
    setAfter(0);
  }, [
    broadcastSearchIds,
    podcastSearchIds,
    activeGraphTab,
    isEnabled,
    isTotaledSwitchChecked,
    isMentionsSwitchChecked
  ]);

  useEffect(() => {
    setAfter(0);
    if (isImpactSwitchChecked) {
      setSortOrder(['ratingsAndImpactCount', 'desc']);
    } else {
      setSortOrder(['mentionsCount', 'desc']);
    }
  }, [isImpactSwitchChecked]);

  useEffect(() => {
    setAfter(0);
    if (sortOrder?.[0] === 'mentionsCount') {
      setIsImpactSwitchChecked(false);
    }

    if (sortOrder?.[0] === 'ratingsAndImpactCount') {
      setIsImpactSwitchChecked(true);
    }
  }, [sortOrder]);

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs
          value={activeGraphTab}
          onChange={(event, newValue) => {
            setActiveGraphTab(newValue);
            setIsWebTab(newValue === webTabIndex);
          }}
        >
          {graphTabs.map(tab => {
            return (
              <Tab
                key={tab}
                label={tab}
                classes={{
                  root: classes.tabOverRide
                }}
              />
            );
          })}
        </Tabs>

        {!isMentionsSwitchChecked && (
          <div style={{ marginLeft: '0px', marginRight: 'auto' }}>
            <AlbTooltip title="Top Narratives does not currently include Web mentions." />
          </div>
        )}
        <Box display="flex">
          <Typography className={classes.datesLabel}>Dates:</Typography>
          <Typography className={classes.datesText}>
            {formatDateRange(selectedDates.start, selectedDates.end)}
          </Typography>
        </Box>
      </Box>
      {/* Graph for All, TV, Radio, Podcast */}
      <Box px={24} pb={28}>
        <Grid container justifyContent="flex-end">
          <Grid item style={{ display: 'flex', alignItems: 'center', paddingRight: '30px' }}>
            <span style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
              <AlbTooltip lookupKey="tpmNarrativeGraph" />
            </span>
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Overlaid
            </Typography>
            <AlbSwitch
              onChange={event => setIsTotaledSwitchChecked(event.target.checked)}
              checked={isMentionsSwitchChecked || isTotaledSwitchChecked || isWebTab}
              disabled={isMentionsSwitchChecked || isWebTab}
            />
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Totaled
            </Typography>
          </Grid>
          <Grid item>
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Mentions
            </Typography>
            <AlbSwitch
              onChange={event => setIsImpactSwitchChecked(event.target.checked)}
              checked={isImpactSwitchChecked}
            />
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Ratings / Impact
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box px={30} pb={20}>
        {isMentionsSwitchChecked === false && !isWebTab && (
          <>
            {/* When switch is set to overlaid show 10 opaque line graphs */}
            {isTotaledSwitchChecked === false && (
              <TopNarrativesGraph
                isTotaledSwitchChecked={isTotaledSwitchChecked}
                isImpactSwitchChecked={isImpactSwitchChecked}
                broadcastSearchIds={broadcastSearchIds}
                podcastSearchIds={podcastSearchIds}
                mediaType={graphTabs[activeGraphTab]}
                selectedDates={selectedDates}
                isEnabled={isEnabled}
                after={after}
                sortOrder={sortOrder}
              />
            )}

            {/* When switch is set to overlaid show 1 line graphs */}
            {isTotaledSwitchChecked === true && (
              <TotaledNarrativesLineGraph
                isTotaledSwitchChecked={isTotaledSwitchChecked}
                isImpactSwitchChecked={isImpactSwitchChecked}
                broadcastSearchIds={broadcastSearchIds}
                podcastSearchIds={podcastSearchIds}
                mediaType={graphTabs[activeGraphTab]}
                selectedDates={selectedDates}
                isEnabled={isEnabled}
                after={after}
                sortOrder={sortOrder}
              />
            )}

            {/*
            There is no case where isTotaledSwitchChecked === false && isMentionsSwitchChecked === true 
            When isMentionsSwitchChecked === true then isTotaledSwitchChecked is set to true as well
        */}

            {/* When switch is set to totaled and mentions is true then we show a bar graph */}
          </>
        )}
        {(isMentionsSwitchChecked === true || isWebTab) && (
          <TotaledMentionsBarGraph
            isTotaledSwitchChecked={isTotaledSwitchChecked}
            isImpactSwitchChecked={isImpactSwitchChecked}
            broadcastSearchIds={broadcastSearchIds}
            podcastSearchIds={podcastSearchIds}
            articleSearchIds={articleSearchIds}
            articleSearchFilterIds={articleSearchFilterIds}
            mediaType={graphTabs[activeGraphTab]}
            selectedDates={selectedDates}
            isEnabled={isEnabled}
            web={isWebTab}
          />
        )}
      </Box>

      <div
        style={{
          height: '14px',
          background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
          mixBlendMode: 'multiply'
        }}
      />

      {/* Table for Narratives Breakdown, Excluded Narratives */}
      <Grid container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Tabs
            value={activeTableTab}
            onChange={(event, newValue) => {
              setActiveTableTab(newValue);
            }}
          >
            {isMentionsSwitchChecked === false &&
              !isWebTab &&
              tableNarrativeTabs.map(tab => {
                return (
                  <Tab
                    key={tab}
                    label={tab}
                    classes={{
                      root: classes.tabOverRide
                    }}
                  />
                );
              })}
            {(isMentionsSwitchChecked === true || isWebTab) &&
              tableMentionTabs.map(tab => {
                return (
                  <Tab
                    key={tab}
                    label={tab}
                    classes={{
                      root: classes.tabOverRide
                    }}
                  />
                );
              })}
          </Tabs>
          <Grid item style={{ paddingRight: '24px' }}>
            <Box display="flex" alignItems="center">
              <Typography
                variantMapping={{ body1: 'span' }}
                className={classes.switchLabel}
                style={{ color: '#6F6F6F' }}
              >
                Grouping by:
              </Typography>
              <Typography
                variantMapping={{ body1: 'span' }}
                className={classes.switchLabel}
                style={{ paddingLeft: '15px' }}
              >
                Narratives
              </Typography>
              <AlbSwitch
                onChange={event => setIsMentionsSwitchChecked(event.target.checked)}
                checked={isMentionsSwitchChecked || isWebTab}
                disabled={isWebTab}
              />
              <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
                Mentions
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="column">
          {!isWebTab && (
            <>
              {isMentionsSwitchChecked === false && isEnabled && (
                <EnabledNarratives
                  selectedDates={selectedDates}
                  broadcastSearchIds={broadcastSearchIds}
                  podcastSearchIds={podcastSearchIds}
                  mediaType={graphTabs[activeGraphTab]}
                  after={after}
                  setAfter={setAfter}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                />
              )}
              {isMentionsSwitchChecked === false && !isEnabled && (
                <DisabledNarratives
                  selectedDates={selectedDates}
                  broadcastSearchIds={broadcastSearchIds}
                  podcastSearchIds={podcastSearchIds}
                  mediaType={graphTabs[activeGraphTab]}
                  after={after}
                  setAfter={setAfter}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                />
              )}
              {isMentionsSwitchChecked === true && isEnabled && (
                <EnabledMentions
                  selectedDates={selectedDates}
                  broadcastSearchIds={broadcastSearchIds}
                  podcastSearchIds={podcastSearchIds}
                  articleSearchIds={articleSearchIds}
                  articleSearchFilterIds={articleSearchFilterIds}
                  mediaType={graphTabs[activeGraphTab]}
                />
              )}
              {isMentionsSwitchChecked === true && !isEnabled && (
                <DisabledMentions
                  selectedDates={selectedDates}
                  broadcastSearchIds={broadcastSearchIds}
                  podcastSearchIds={podcastSearchIds}
                  articleSearchIds={articleSearchIds}
                  articleSearchFilterIds={articleSearchFilterIds}
                  mediaType={graphTabs[activeGraphTab]}
                />
              )}
            </>
          )}
          {isWebTab && (
            <ArticlesTable
              selectedDates={selectedDates}
              articleSearchIds={articleSearchIds}
              articleSearchFilterIds={articleSearchFilterIds}
              isEnabled={isEnabled}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

NarrativeGraphAndTable.propTypes = {
  selectedDates: PropTypes.shape().isRequired,
  articleSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  broadcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  podcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default NarrativeGraphAndTable;
