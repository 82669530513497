/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ENABLE_MENTION } from 'gql/mention';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

/**
 * @method
 * @summary This component renders the modal used to include a mention
 * @name IncludeMentionModal
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isModalOpen - A boolean value to determine the modal's open state
 * @param {Function} props.onChange - A function run on successful mutation used to update parent component
 * @param {string} props.mentionId - A string value for the mention id
 * @param {string} props.mentionName - A string value for the mention name
 * @return {Object} - React JSX
 */
const IncludeMentionModal = props => {
  const { isModalOpen, onChange, mentionId, mentionName } = props;

  const [enableMention, { error, data }] = useMutation(ENABLE_MENTION);

  useEffect(() => {
    if (data?.enableMention?.disabled === false) {
      showToast(`Mention included`, 'success');
      onChange({
        isModalOpen: false,
        updated: true
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Include Mention"
        body={
          <>
            Are you sure you want to include the mention:&nbsp;
            <span style={{ color: '#0A1734' }}>{mentionName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Include"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => enableMention({ variables: { id: mentionId } })}
      />
    </>
  );
};

IncludeMentionModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  mentionId: PropTypes.string,
  mentionName: PropTypes.string
};

IncludeMentionModal.defaultProps = {
  mentionId: null,
  mentionName: null
};

export default IncludeMentionModal;
