import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItemIcon, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { AlbTooltip } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';
import Box from 'components/Box';
import NumberGrid from 'components/PostDrawerView/NumberGrid';
import colors from 'util/colors';
import ArticleDrawerDetails from './ArticleDrawerDetails';

const useStyles = makeStyles({
  container: {
    padding: '25px'
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  flex: {
    flex: 1
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    color: colors.navy,
    marginLeft: '25px',
    paddingRight: '12px'
  },
  content: {
    marginLeft: '25px'
  }
});

// ** UNUSED **

/**
 * @method
 * @summary This component renders the details of an article at the top of the drawer or from a list of articles of @see ArticleDrawerView
 * @name ArticleDrawerMetrics
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const ArticleDrawerMetrics = props => {
  const { article, index, detailed } = props;
  const { title } = article;
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const gridMetrics = [
    {
      graph_title: 'Facebook Shares',
      metric_name: 'facebook_share_count'
    },
    { graph_title: 'Twitter Shares', metric_name: 'twitter_share_count' },
    { graph_title: 'Total Shares', metric_name: 'total_share_count' },
    { graph_title: 'Total Ratings', metric_name: 'total_ratings' }
  ];

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        className={classes.collapse}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        <ListItemIcon classes={{ root: classes.listItem }}>
          {isCollapsed ? <Right /> : <Down />}
        </ListItemIcon>
        {!isCollapsed && <Box className={classes.expandedIndicator} />}
      </Grid>
      <Grid className={classes.flex}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Typography className={classes.title}>{detailed ? title : 'Article Metrics'}</Typography>
          <AlbTooltip title={tooltipText.articleMetrics} />
        </Grid>
        <Collapse
          in={!isCollapsed}
          classes={{
            root: classes.flex
          }}
        >
          <div className={classes.content}>
            {detailed && <ArticleDrawerDetails article={article} />}
            <NumberGrid item={article} gridItems={gridMetrics} index={index} size="small" />
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
};

ArticleDrawerMetrics.propTypes = {
  article: PropTypes.shape().isRequired,
  index: PropTypes.number,
  detailed: PropTypes.bool
};

ArticleDrawerMetrics.defaultProps = {
  index: 0,
  detailed: false
};

export default ArticleDrawerMetrics;
