/**
 * @method
 * @summary Recursive function to check if a user has a specific privilege
 * @param {String[]} currentPrivs - Array of user's current privileges using short_name
 * @param {String} priv - Specific privilege to check
 * @return {Bool} Does the user have this privilege?
 */
const checkForPriv = (currentPrivs, priv) => {
  if (!priv) {
    return false;
  }

  if (currentPrivs.includes('.')) {
    // Special permission with the short_name . allows global access per the database
    return true;
  }

  if (currentPrivs.includes(priv)) {
    // Exact match for the requested priv
    return true;
  }

  // If priv still wasn't found, check one level higher recursively
  const parentPriv = priv.substring(0, priv.lastIndexOf(':'));

  return checkForPriv(currentPrivs, parentPriv);
};

export default checkForPriv;
