import { gql } from '@apollo/client';

export const SOCIAL_IMPACT_POST_METRIC_BREAKDOWN = gql`
  query socialImpactPostMetricBreakdown(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
  ) {
    socialImpactPostMetricBreakdown(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
    ) {
      totalPostsValue
    }
  }
`;

export const SOCIAL_IMPACT_METRIC_BREAKDOWN = gql`
  query socialImpactMetricBreakdown(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
  ) {
    socialImpactMetricBreakdown(startDate: $startDate, endDate: $endDate, linkTokens: $linkTokens) {
      metric
      publishedValue
      publishedDiffValue
      publishedDiffPercentChange
      longTailValue
      longTailDiffValue
      longTailDiffPercentChange
    }
  }
`;

export const SOCIAL_IMPACT_METRIC_TIMESERIES = gql`
  query socialImpactMetricTimeSeries(
    $metric: String!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
  ) {
    socialImpactMetricTimeSeries(
      metric: $metric
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
    ) {
      published {
        x
        y
      }
      longTail {
        x
        y
      }
    }
  }
`;

export const SOCIAL_IMPACT_METRIC_TABLE = gql`
  query socialImpactMetricTable(
    $metric: String!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $after: Int!
    $count: Int!
  ) {
    socialImpactMetricTable(
      metric: $metric
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      after: $after
      count: $count
    ) {
      rows {
        campaignEvent {
          id
          event_body
          completed_at
          type
          assets {
            id
            signed_thumbnail_url
          }
          thumbnail_url
          linked_account {
            id
            avatar_url
            remote_name
          }
          post_id
          linked_account_id
        }
        impressions
        engagement
        shares
      }
      count
    }
  }
`;
