/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Chart from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Bar, HorizontalBar, defaults } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { merge } from 'lodash';
import { generateDruidObject } from 'util/analytics';
import colors, { topPostsColors, topPostsGradient } from 'util/colors';
import {
  convertToUserTimeZone,
  getDatesForAnalytics,
  datesWithMissingData,
  renderUILongDate
} from 'util/date';
import { formatSecondsDisplay, formatHumanReadableTime } from 'util/time';
import { apolloClientQuery } from 'middleware/api';
import {
  // conventional graph queries
  DRUID_TIME_SERIES,
  DRUID_TIME_SERIES_DELTA,
  DRUID_GROUP_BY,
  // web and apps graph queries
  WEB_AND_APPS_SOURCES_AND_PAGES,
  WEB_AND_APPS_LOCATION_GRAPH,
  WEB_AND_APPS_GENDER_GRAPH
} from 'gql/analytics';
import { IMPACT_VS_AGE, TOP_ACTIVE_POST_TIME_SERIES } from 'gql/analyticsHypeAndFlow';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import {
  hypeGraphLabels,
  customLabels,
  stackedBarCustomGraphTypes
} from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import GraphHover from 'components/GraphHover';
import Geo from './AlbGeoGraph';
import { Palette, AccountsPalette } from './Legend';
import { formatFlowLabel, sourcesAndPagesTooltip } from './CustomTooltipTemplates';

Chart.plugins.register(annotationPlugin);

merge(defaults, { global: { defaultFontFamily: 'Poppins' } });

const useStyles = makeStyles({
  graphContainer: {
    position: 'relative',
    width: '100%'
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  tooltip: {
    position: 'absolute',
    minHeight: '20px',
    backgroundColor: 'white',
    fontSize: '12px',
    color: colors.darkBlue,
    boxShadow: '0px 0px 6px rgba(50, 50, 125, 0.2)',
    padding: '2px 5px',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    pointerEvents: 'none',
    zIndex: 1
  },
  point: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    backgroundColor: '#0A1734',
    borderRadius: '50%',
    transformStyle: 'preserve-3d'
  },
  hidden: {
    display: 'none !important'
  }
});

const AlbGraphDruid = props => {
  const { currentUser, tabData, accounts, dates, onlyVideo, graphHover, setGraphBanner } = props;
  const {
    druidQuery,
    queries,
    stacked,
    fill,
    lineTension,
    interactionMode,
    tooltipWidthCustom = 200,
    customGraph,
    customPresets,
    customLegend,
    additionalTooltips = []
  } = tabData;

  const classes = useStyles();

  const [options, setOptions] = useState({});
  const [labels, setLabels] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [hideTooltips, setHideTooltips] = useState(true);
  const [loadingOverlay, displayLoadingOverlay] = useState(true);
  const [topPosts, setTopPosts] = useState({});
  const [graphLegend, setGraphLegend] = useState(customLegend);
  const [hiddenDataSets, setHiddenDataSets] = useState({});

  const [error, setError] = useState(null);

  // graphHover state variables
  const [graphHoverAnchor, setGraphHoverAnchor] = useState(null);
  const [graphHoverPostId, setGraphHoverPostId] = useState(null);
  const [graphHoverLinkToken, setGraphHoverLinkToken] = useState(null);
  const [missingData, setMissingData] = useState(false);

  // create refs to enable tooltip modification
  const tooltipRefs = useRef(queries.map(() => React.createRef()));
  const additionalTooltipRefs = useRef(additionalTooltips.map(() => React.createRef()));
  const [customTooltipLabels, setCustomTooltipLabels] = useState([]);
  // canvas context ref
  const contextRef = useRef();

  useEffect(() => {
    if (stackedBarCustomGraphTypes.includes(customGraph) || customGraph === 'stackedBarMetric') {
      tooltipRefs.current = customTooltipLabels.map(() => React.createRef());
    }
  }, [customTooltipLabels]);

  useEffect(() => {
    if (!stackedBarCustomGraphTypes.includes(customGraph)) {
      tooltipRefs.current = queries.map(() => React.createRef());
    }
  }, [queries]);

  useEffect(() => {
    additionalTooltipRefs.current = additionalTooltips.map(() => React.createRef());
  }, [additionalTooltips]);

  const formatGraphDataSets = batchQueries => {
    let graphLabels = [];
    const totals = [];

    const graphDataSets = batchQueries.map(
      ({ label, type, graphData, graphType, palette }, index) => {
        // Note that we changed the return from the API when we switched to Druid; this used to return graphs, now timeSeries
        const graphs = graphData?.data?.[graphType];

        if (!graphs) {
          // If we didn't get back any data for some reason, return an empty object for now; in the future, show an error?
          return { label, data: [], type, ...palette, settings: {} };
        }

        if (!index || !graphLabels.length) {
          // labels should be the same throughout queried dataSets
          // so we define the labels on the first pass through

          graphLabels = graphs.map((dataPoint, i) => {
            const dateFormat = 'MMM D/YYYY';

            switch (customGraph) {
              case 'webAndAppsGender':
              case 'genderAge':
                return customLabels[dataPoint.x];
              case 'webAndAppsAge':
              case 'horizontalAge':
                switch (i) {
                  case 0:
                    return [
                      'START',
                      convertToUserTimeZone(dates.start, currentUser.time_zone)
                        .format(dateFormat)
                        .toUpperCase()
                    ];
                  default:
                    return [
                      'END',
                      convertToUserTimeZone(dates.end, currentUser.time_zone)
                        .format(dateFormat)
                        .toUpperCase()
                    ];
                }
              case 'webAndAppsLocationMap':
              case 'geoCountries':
                return dataPoint.x;
              default:
                return moment
                  .utc(dataPoint.x)
                  .format(dateFormat)
                  .toUpperCase();
            }
          });
        }

        const data =
          // the impactVsAge resolver sends empty arrays for lack of data, so here
          // we manually insert zeroes into the array so the tooltip labels will be
          // applied to the correct dataset
          customGraph === 'flow' && !graphs.length
            ? Array.from(
                Array(Math.abs(moment(dates.start).diff(moment(dates.end), 'days')) + 1),
                () => 0
              )
            : graphs.map((dataPoint = {}, i) => {
                const { y } = dataPoint;
                const positiveValue = y > 0 ? y : 0;

                if (stacked && index) {
                  totals[i] += positiveValue;
                } else {
                  totals.push(positiveValue);
                }

                switch (customGraph) {
                  case 'webAndAppsGender':
                  case 'genderAge':
                    switch (index) {
                      case 0:
                        return y;
                      default:
                        return Math.abs(y) * -1;
                    }
                  default:
                    return y;
                }
              });

        const settings = {
          ...(type === 'line' && {
            fill: !!fill,
            lineTension: lineTension || 0,
            pointRadius: 0
          }),
          ...(type === 'bar' && {})
        };

        return { label, data, type, ...palette, ...settings };
      }
    );

    return {
      graphLabels,
      graphDataSets: graphLabels.length ? graphDataSets : []
    };
  };

  useEffect(() => {
    let componentIsMounted = true;
    setHiddenDataSets({});

    const initializeData = async () => {
      if (!accounts || accounts?.length !== 0) {
        displayLoadingOverlay(true);

        const queryTypes = {
          timeSeries: DRUID_TIME_SERIES,
          timeSeriesDelta: DRUID_TIME_SERIES_DELTA,
          groupBy: DRUID_GROUP_BY,
          // hype graph query
          topActivePostTimeSeries: TOP_ACTIVE_POST_TIME_SERIES,
          // flow graph query
          impactVsAge: IMPACT_VS_AGE,
          // top sources and pages query in web and apps
          webAndAppsTable: WEB_AND_APPS_SOURCES_AND_PAGES,
          webAndAppsLocationMap: WEB_AND_APPS_LOCATION_GRAPH,
          webAndAppsGender: WEB_AND_APPS_GENDER_GRAPH,
          webAndAppsAge: WEB_AND_APPS_GENDER_GRAPH
        };

        let batchQuery = [];

        if (customGraph === 'genderAge') {
          const { queryV2, graphType } = druidQuery;
          try {
            const graphData = await apolloClientQuery(
              queryTypes[graphType],
              generateDruidObject(queryV2, accounts, dates),
              null,
              true
            );

            const genderAgeData = graphData?.data?.[graphType] || [];

            const f = {
              data: { [graphType]: genderAgeData.filter(({ x }) => x.includes('Female')) }
            };
            const m = {
              data: { [graphType]: genderAgeData.filter(({ x }) => x.includes('Male')) }
            };

            const filteredGraphData = [f, m];

            batchQuery = queries.map(({ ...properties }, i) => {
              return {
                graphData: filteredGraphData[i],
                graphType,
                ...properties
              };
            });
          } catch (queryError) {
            setError(queryError);

            throw queryError;
          }
        } else if (customGraph === 'horizontalAge') {
          const { queryV2, graphType } = druidQuery;
          try {
            const graphData = await apolloClientQuery(
              queryTypes[graphType],
              generateDruidObject(queryV2, accounts, dates),
              null,
              true
            );

            const horizontalAgeData = graphData?.data?.[graphType] || [];

            const start = horizontalAgeData
              .filter(({ x }) => x.includes('Start'))
              .map(({ x, y }) => ({ x: x.split(' ')[1], y }));

            const end = horizontalAgeData
              .filter(({ x }) => x.includes('End'))
              .map(({ x, y }) => ({ x: x.split(' ')[1], y }));

            const filteredGraphData = [start, end];

            batchQuery = customPresets.map(({ preset, label }, presetIndex) => ({
              graphData: {
                data: {
                  [graphType]: queries.map(
                    (_, queryIndex) => filteredGraphData[queryIndex][presetIndex]
                  )
                }
              },
              graphType,
              ...preset,
              label
            }));
          } catch (queryError) {
            setError(queryError);

            throw queryError;
          }
        } else if (customGraph === 'hype') {
          const { queryV2, graphType } = druidQuery;
          const { start, end } = getDatesForAnalytics(dates.start, dates.end);
          try {
            const graphData = await apolloClientQuery(
              queryTypes[graphType],
              {
                startDate: start,
                endDate: end,
                linkTokens: accounts.map(({ id }) => id),
                metric: queryV2
              },
              null,
              true
            );

            const hypeGraphData = graphData?.data?.[graphType] || [];

            // create reference for postIds by rank
            const topPostsData = hypeGraphData.reduce((acc, { postId, linkTokenId }, i) => {
              acc[hypeGraphLabels[i]] = { postId, linkTokenId };
              return acc;
            }, {});

            setTopPosts(topPostsData);

            // fix to hide empty tooltips from displaying with empty datasets
            tooltipRefs.current.length = 1;

            batchQuery = customPresets.map(({ preset, label }, presetIndex) => ({
              graphData: {
                data: {
                  [graphType]: hypeGraphData[presetIndex]?.dataset || []
                }
              },
              graphType,
              ...preset,
              label
            }));
          } catch (queryError) {
            setError(queryError);

            throw queryError;
          }
        } else if (customGraph === 'flow') {
          const { queryV2, graphType } = druidQuery;
          const { start, end } = getDatesForAnalytics(dates.start, dates.end);
          try {
            const graphData = await apolloClientQuery(
              queryTypes[graphType],
              {
                startDate: start,
                endDate: end,
                linkTokens: accounts.map(({ id }) => id),
                metric: queryV2
              },
              null,
              true
            );

            const flowGraphData = graphData?.data?.[graphType] || [];

            batchQuery = customPresets.map(({ preset, label }, presetIndex) => ({
              graphData: {
                data: {
                  [graphType]: flowGraphData[presetIndex]?.dataset || []
                }
              },
              graphType,
              ...preset,
              label
            }));
          } catch (queryError) {
            setError(queryError);

            throw queryError;
          }
        } else if (customGraph === 'sourcesAndPages') {
          const { queryV2, graphType, page, rowsPerPage } = druidQuery;
          const { start, end } = getDatesForAnalytics(dates.start, dates.end);
          try {
            const graphData = await apolloClientQuery(
              queryTypes[graphType],
              {
                startDate: start,
                endDate: end,
                linkTokens: accounts.map(({ id }) => id),
                metric: queryV2,
                after: page * rowsPerPage,
                count: rowsPerPage
              },
              null,
              true
            );

            const topSourcesAndPagesLegend = [];
            const sourcesAndPagesGraphData = graphData?.data?.[graphType]?.rows || [];

            // create reference for pages and sources
            const tooltipReference = {};
            const remoteNameLookup = accounts.reduce((acc, { id, remote_name: remoteName }) => {
              acc[id] = remoteName;
              return acc;
            }, {});

            batchQuery = sourcesAndPagesGraphData.map(
              ({ linktoken, metric: label, value }, presetIndex) => {
                const preset = queries[presetIndex];
                // used to obtain the plural form suffix of number ranking
                const format = new Intl.PluralRules('en', { type: 'ordinal' });
                const rank = presetIndex + 1 + page * rowsPerPage;
                const rankSuffix = { one: 'st', two: 'nd', few: 'rd', other: 'th' }[
                  format.select(rank)
                ];

                const fullRemoteName = linktoken?.id ? remoteNameLookup[linktoken.id] : '';
                let color;

                if (tabData?.useTopPostsColors === true) {
                  color = topPostsColors[presetIndex];
                } else {
                  [color] = topPostsGradient[presetIndex];
                }

                topSourcesAndPagesLegend.push({ label, color, key: presetIndex });
                // generate lookup for tooltip by dataset/rank
                tooltipReference[`${rank.toLocaleString()}${rankSuffix}`] = {
                  ...(label !== 'Other' && { linktoken: { ...linktoken, fullRemoteName } }),
                  label,
                  value,
                  unit: queryV2.includes('Revenue') ? 'Revenue' : 'Users'
                };

                return {
                  graphData: {
                    data: {
                      [graphType]: sourcesAndPagesGraphData[presetIndex]?.timeseries || []
                    }
                  },
                  graphType,
                  ...preset,
                  label: `${rank.toLocaleString()}${rankSuffix}`
                };
              }
            );

            // not actually 'posts' but we have too many state vars in this component so this one is being reused here
            // this object is used as reference for the tooltip to access the linktoken and values to render
            setTopPosts(tooltipReference);
            setGraphLegend({ palette: topSourcesAndPagesLegend });

            tooltipRefs.current.length = 1;
          } catch (queryError) {
            setError(queryError);

            throw queryError;
          }
        } else if (customGraph === 'webAndAppsLocationMap') {
          batchQuery = await Promise.all(
            queries.map(async ({ queryV2, graphType, ...properties }) => {
              const { start, end } = getDatesForAnalytics(dates.start, dates.end);
              try {
                const graphData = await apolloClientQuery(
                  queryTypes[graphType],
                  {
                    startDate: start,
                    endDate: end,
                    linkTokens: accounts.map(({ id }) => id),
                    metric: queryV2
                  },
                  null,
                  true
                );

                const { countryMap = [] } = graphData?.data?.[graphType];

                return {
                  graphData: {
                    data: {
                      [graphType]: countryMap
                        .map(({ metric, value }) => ({ x: metric, y: value }))
                        .filter(({ x }) => x != null)
                    }
                  },
                  graphType,
                  ...properties
                };
              } catch (queryError) {
                setError(queryError);

                throw queryError;
              }
            })
          );
        } else if (customGraph === 'webAndAppsGender') {
          const { queryV2, graphType } = druidQuery;
          const { start, end } = getDatesForAnalytics(dates.start, dates.end);
          try {
            const graphData = await apolloClientQuery(
              queryTypes[graphType],
              {
                startDate: start,
                endDate: end,
                linkTokens: accounts.map(({ id }) => id),
                metric: queryV2
              },
              null,
              true
            );

            const { genderGraph = [] } = graphData?.data?.[graphType];

            const genderAgeTotals = genderGraph.reduce((acc, { metric, value }) => {
              const [ageGroup] = metric.split(' ');
              if (acc[ageGroup]) {
                acc[ageGroup] += value;
              } else {
                acc[ageGroup] = value;
              }

              return acc;
            }, {});

            const formattedGenderGraphData = genderGraph
              .sort((a, b) => a.metric.localeCompare(b.metric))
              .map(({ metric, value }) => {
                const [ageGroup] = metric.split(' ');
                const percentage = !genderAgeTotals[ageGroup]
                  ? 0
                  : (value / genderAgeTotals[ageGroup]) * 100;

                return {
                  x: metric,
                  y: Math.round((percentage + Number.EPSILON) * 100) / 100
                };
              });

            const f = {
              data: {
                [graphType]: formattedGenderGraphData.filter(({ x }) => x.includes('Female'))
              }
            };
            const m = {
              data: { [graphType]: formattedGenderGraphData.filter(({ x }) => x.includes('Male')) }
            };

            const filteredGraphData = [f, m];

            batchQuery = queries.map(({ ...properties }, i) => {
              return {
                graphData: filteredGraphData[i],
                graphType,
                ...properties
              };
            });
          } catch (queryError) {
            setError(queryError);

            throw queryError;
          }
        } else if (customGraph === 'webAndAppsAge') {
          const { queryV2, graphType } = druidQuery;
          const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);
          try {
            const graphData = await apolloClientQuery(
              queryTypes[graphType],
              {
                startDate,
                endDate,
                linkTokens: accounts.map(({ id }) => id),
                metric: queryV2
              },
              null,
              true
            );

            const { genderGraph = [] } = graphData?.data?.webAndAppsGender;

            // determine which age ranges we are provided data for
            const availableAgeRanges = genderGraph.reduce((acc, { metric }) => {
              const [, ageRange] = metric.split(' ');
              acc[ageRange] = true;

              return acc;
            }, {});

            // filter the presets to render the graph and legend
            const filteredAgePresets = customPresets.filter(
              ({ label }) => availableAgeRanges[label]
            );

            // generate a lookup to calculate percentages of each total against the full dataset
            const startEndTotals = genderGraph.reduce((acc, { metric, value }) => {
              const [position] = metric.split(' ');
              if (acc[position]) {
                acc[position] += value;
              } else {
                acc[position] = value;
              }

              return acc;
            }, {});

            if (!startEndTotals.Start && !startEndTotals.End) {
              setGraphBanner(
                `No data for ${renderUILongDate(dates.start)} or ${renderUILongDate(dates.end)}.`
              );
            } else if (!startEndTotals.Start) {
              setGraphBanner(`No data for ${renderUILongDate(dates.start)}.`);
            } else if (!startEndTotals.End) {
              setGraphBanner(`No data for ${renderUILongDate(dates.endDate)}.`);
            } else {
              setGraphBanner(null);
            }

            const formattedAgeGraphData = genderGraph
              .sort((a, b) => a.metric.localeCompare(b.metric))
              .map(({ metric, value }) => {
                const [position] = metric.split(' ');
                const percentage = (value / startEndTotals[position]) * 100;

                return {
                  x: metric,
                  y: Math.round((percentage + Number.EPSILON) * 100) / 100
                };
              });

            const start = formattedAgeGraphData
              .filter(({ x }) => x.includes('Start'))
              .map(({ x, y }) => ({ x: x.split(' ')[1], y }));

            const end = formattedAgeGraphData
              .filter(({ x }) => x.includes('End'))
              .map(({ x, y }) => ({ x: x.split(' ')[1], y }));

            const filteredGraphData = [start, end];

            batchQuery = filteredAgePresets.map(({ preset, label }, presetIndex) => ({
              graphData: {
                data: {
                  webAndAppsAge: queries.map(
                    (_, queryIndex) => filteredGraphData[queryIndex][presetIndex]
                  )
                }
              },
              graphType: 'webAndAppsAge',
              ...preset,
              label
            }));
          } catch (queryError) {
            setError(queryError);

            throw queryError;
          }
        } else if (stackedBarCustomGraphTypes.includes(customGraph)) {
          // assuming 1 query
          const [query] = queries;
          const { queryV2, graphType, ...properties } = query;

          batchQuery = await Promise.all(
            accounts.map(async (account, i) => {
              const { id, platform, remote_name: handle } = account;
              const { displayName } = platform;
              try {
                const graphData = await apolloClientQuery(
                  queryTypes[graphType],
                  generateDruidObject(
                    queryV2,
                    [account],
                    getDatesForAnalytics(dates.start, dates.end),
                    onlyVideo
                  ),
                  null,
                  true
                );

                const backgroundColor = topPostsColors[i % topPostsColors.length];
                const tooltipLabel = `${handle} - ${displayName}`;

                return {
                  ...properties,
                  label: `${tooltipLabel}_${id}`,
                  tooltipLabel,
                  palette: { backgroundColor },
                  graphData,
                  graphType
                };
              } catch (queryError) {
                setError(queryError);

                throw queryError;
              }
            })
          );

          setCustomTooltipLabels(batchQuery);
        } else {
          batchQuery = await Promise.all(
            queries.map(async ({ queryV2, graphType, ...properties }) => {
              try {
                const graphData = await apolloClientQuery(
                  queryTypes[graphType],
                  generateDruidObject(
                    queryV2,
                    accounts,
                    getDatesForAnalytics(dates.start, dates.end),
                    onlyVideo
                  ),
                  null,
                  true
                );

                return {
                  graphData,
                  graphType,
                  ...properties
                };
              } catch (queryError) {
                setError(queryError);

                throw queryError;
              }
            })
          );

          if (customGraph === 'stackedBarMetric') {
            setCustomTooltipLabels(batchQuery);
          }
        }

        if (componentIsMounted) {
          const { graphLabels, graphDataSets } = formatGraphDataSets(batchQuery);

          displayLoadingOverlay(false);
          setLabels(graphLabels);
          setDataSets(graphDataSets);
        }
      } else {
        setDataSets([]);

        displayLoadingOverlay(false);
      }
    };

    if (tabData) initializeData();

    return () => {
      componentIsMounted = false;
    };
  }, [tabData, accounts, dates]);

  // providing data to the graph through a function allows access to the context
  // interact with canvas to create gradient for line graphs
  const data = canvas => {
    contextRef.current = canvas.getContext('2d');

    let datasets = dataSets;

    if (customGraph === 'hype' || customGraph === 'sourcesAndPages') {
      datasets = dataSets.map(({ gradient, ...properties }) => {
        const canvasHeight = contextRef.current.canvas.offsetHeight;

        const gradientFill = contextRef.current.createLinearGradient(0, 0, 0, canvasHeight);
        gradientFill.addColorStop(0, gradient[0]);
        gradientFill.addColorStop(1, gradient[1]);

        return {
          backgroundColor: gradientFill,
          ...properties
        };
      });
    }

    datasets = datasets.map(({ ...properties }, i) => ({
      ...properties,
      ...(hiddenDataSets[i] && { data: null })
    }));

    return { labels, datasets };
  };

  const round = number => Math.round((number + Number.EPSILON) * 100) / 100;

  const custom = tooltip => {
    if (customGraph === 'horizontalAge' || customGraph === 'webAndAppsAge') return;

    let alternateLabel = null;
    let tooltipReferenceLabel;

    if (customGraph === 'hype' && tooltip.body) {
      const [line] = tooltip.body[0].lines;
      const [rank] = line.split(':');

      // here we derive the label for the tooltip from the data itself instead of the queries
      // may need to use this instead of the queries in the future for accuracy
      alternateLabel = rank;

      // postId is provided here
      if (graphHover) {
        setGraphHoverLinkToken(topPosts[rank].linkTokenId);
        setGraphHoverPostId(topPosts[rank].postId);
      }
    }

    if (customGraph === 'sourcesAndPages' && tooltip.body) {
      const [line] = tooltip.body[0].lines;
      const [rank] = line.split(':');

      tooltipReferenceLabel = rank;
    }

    const { dataPoints, caretX, title, labelColors } = tooltip;
    const { offsetTop, offsetLeft } = contextRef.current.canvas;
    const showTooltips = !!tooltip.opacity;

    setHideTooltips(!showTooltips);

    if (!showTooltips && graphHover) {
      setGraphHoverAnchor(null);
    }

    if (showTooltips && !loadingOverlay) {
      let total = 0;
      let topTooltipY = Infinity;

      const tooltips = [];
      // Used in stacked graphs with multiple datasets to display all data in the uppermost tooltip.
      const legendTooltipLabelData = [];
      let legendTooltipStyle;

      let tooltipWidth = tooltipWidthCustom;

      dataPoints.forEach((dataPoint, i) => {
        const { yLabel: yValue, xLabel } = dataPoint;

        const [tooltipDate] = xLabel.split('/');

        if (tooltipRefs.current[i]?.current) {
          const { current } = tooltipRefs.current[i];

          let innerHTML = '';
          const dataPointColor = labelColors[i].backgroundColor;

          switch (customGraph) {
            case 'webAndAppsGender':
            case 'genderAge':
              innerHTML = `${Math.abs(round(yValue)).toLocaleString()}% ${queries[i].label}`;
              total += Math.abs(round(yValue));
              break;
            case 'watchTime':
              innerHTML = `${formatSecondsDisplay(yValue)} ${queries[i].label}`;
              total += yValue;
              break;
            case 'hype':
              innerHTML = `${round(yValue).toLocaleString()} ${alternateLabel}`;
              total += round(yValue);
              break;
            case 'sourcesAndPages':
              innerHTML = sourcesAndPagesTooltip(
                // { linktoken, label, value }
                topPosts[tooltipReferenceLabel],
                // formatted value
                round(yValue).toLocaleString(),
                // date
                xLabel.replace('/', ', '),
                // rank
                tooltipReferenceLabel
              );
              break;
            case 'flow':
              legendTooltipLabelData.push({
                date: tooltipDate,
                ageRange: queries[i].label,
                yValue: round(yValue).toLocaleString(),
                labelColor: dataPointColor
              });
              total += round(yValue);
              break;
            case 'stackedBar':
              legendTooltipLabelData.push({
                date: tooltipDate,
                ageRange: customTooltipLabels[i].tooltipLabel,
                yValue: round(yValue).toLocaleString(),
                labelColor: dataPointColor
              });
              total += round(yValue);
              break;
            case 'stackedBarMetric':
              legendTooltipLabelData.push({
                date: tooltipDate,
                ageRange: customTooltipLabels[i].label,
                yValue: round(yValue).toLocaleString(),
                labelColor: dataPointColor
              });
              total += round(yValue);
              break;
            case 'watchTimeStackedBar':
              legendTooltipLabelData.push({
                date: tooltipDate,
                ageRange: customTooltipLabels[i].tooltipLabel,
                yValue: formatSecondsDisplay(yValue),
                labelColor: dataPointColor
              });
              total += yValue;
              break;
            default:
              innerHTML = `${round(yValue).toLocaleString()} ${queries[i].label}`;
              total += round(yValue);
          }

          const style = {
            display: yValue ? 'unset' : 'none'
          };

          const top = offsetTop + dataPoint.y;
          const left = offsetLeft + dataPoint.x;

          if (customGraph === 'hype' || customGraph === 'sourcesAndPages') {
            style.direction = 'ltr';
            style.top = `${top - 2.5}px`;
            style.left = `${left - 2.5}px`;
          } else {
            style.top = `${top - 10}px`;
            style.left = `${left + 10}px`;
          }

          if (current.offsetWidth > tooltipWidth) {
            tooltipWidth = current.offsetWidth;
          }

          if (left + tooltipWidth > contextRef.current.canvas.getBoundingClientRect().width) {
            if (customGraph === 'sourcesAndPages') {
              style.direction = 'rtl';
            } else {
              style.left = `${left - 5 - tooltipWidth}px`;
            }
          }

          topTooltipY = Math.min(topTooltipY, dataPoint.y);
          tooltips.push({ ref: current, top: dataPoint.y });

          if (
            customGraph !== 'flow' &&
            customGraph !== 'stackedBarMetric' &&
            !stackedBarCustomGraphTypes.includes(customGraph)
          ) {
            Object.assign(current.style, style);
            Object.assign(current, { innerHTML });
          } else {
            // set display to none until we sort these tooltip labels in the function below, yet generate its position with top and left values.
            legendTooltipStyle = {
              display: 'none',
              top: `${top - 10}px`,
              left: `${left + 10}px`
            };

            if (left + tooltipWidth > contextRef.current.canvas.getBoundingClientRect().width) {
              legendTooltipStyle.left = `${left - 5 - tooltipWidth}px`;
            }
          }
        }
      });

      let previousTop = null;

      let highestTooltip = null;

      tooltips
        .sort((first, second) => first.top - second.top)
        .forEach(({ ref, top }) => {
          if (previousTop !== null && top - previousTop < 25) {
            Object.assign(ref.style, { top: `${offsetTop + previousTop + 15}px` });
          }

          previousTop = top;

          const [tooltipDate] = title;

          if (datesWithMissingData.includes(tooltipDate)) {
            Object.assign(ref.style, { padding: '6px', top: '30%' });
            Object.assign(ref.style, { display: 'unset' });
            Object.assign(ref, {
              innerHTML:
                'NO DATA<br /><br />Warning: Incomplete API data received from social networks on this date.<br />This does NOT affect lifetime values.'
            });

            setMissingData(true);
          } else {
            setMissingData(false);
          }

          // for Flow's 'Impact vs Age' and 'stacked bar' graphs we want to consolidate all the tooltips into the top one.
          if (
            customGraph === 'flow' ||
            customGraph === 'stackedBarMetric' ||
            stackedBarCustomGraphTypes.includes(customGraph)
          ) {
            const [query] = queries;
            const { tooltipTitle } = query;

            if (top > highestTooltip) {
              highestTooltip = top;
              Object.assign(ref.style, legendTooltipStyle);
              Object.assign(ref.style, { display: 'unset' });
              Object.assign(ref, {
                innerHTML: formatFlowLabel(legendTooltipLabelData, tooltipTitle)
              });

              if (datesWithMissingData.includes(tooltipDate)) {
                Object.assign(ref.style, { top: '10%' });
                Object.assign(ref, {
                  innerHTML: formatFlowLabel(legendTooltipLabelData, tooltipTitle, true)
                });
              }
            } else {
              // set all other tooltips to invisible since we display only the highest value.
              Object.assign(ref.style, { opacity: '0' });
            }
          }

          // don't open the hover if display:none
          if (graphHover && ref.style.display !== 'none') {
            setGraphHoverAnchor({ ref, innerHTML: ref.innerHTML });

            // remove the label css to just show the hover.
            Object.assign(ref, { innerHTML: '' });
          }

          if (graphHover && ref.style.display === 'none') {
            setGraphHoverAnchor(null);
          }
        });

      if (additionalTooltips?.length) {
        additionalTooltips.forEach((type, i) => {
          const { current } = additionalTooltipRefs.current[i];
          const tooltipPosition = current.getBoundingClientRect();

          const tooltipStyles = {
            // total tooltips etc.
            apex: {
              display: total ? 'unset' : 'none',
              top: `${offsetTop + topTooltipY - 35}px`,
              left: `${offsetLeft + caretX + 10}px`
            },
            // date tooltips etc.
            header: {
              top: `${offsetTop - 20}px`,
              left: `${offsetLeft + caretX}px`,
              backgroundColor: colors.navy,
              color: 'white',
              textAlign: 'center'
            },
            point: {
              display: 'none'
              // top: `${offsetTop - 30}px`,
              // left: `${offsetLeft + caretX}px`,
              // backgroundColor: colors.navy
              // color: 'white',
              // textAlign: 'center'
            },
            line: {
              top: `${offsetTop}px`,
              left: `${offsetLeft + caretX - 1}px`,
              width: '2px',
              height: `${contextRef.current.canvas.getBoundingClientRect().height - 28}px`,
              maxHeight: 'unset',
              backgroundColor: colors.navy25,
              boxShadow: 'none',
              padding: '0px'
            }
          };

          const [tooltipDate] = title;
          const [innerTextDate] = tooltipDate.split('/');

          // provide tooltip text
          Object.assign(current, {
            ...(type === 'date' && { innerText: innerTextDate }),
            ...((type === 'topTotal' || type === 'total') && {
              innerText: `${round(total).toLocaleString()} TOTAL`
            }),
            ...(type === 'topTotal' &&
              (customGraph === 'genderAge' || customGraph === 'webAndAppsGender') && {
                innerText: `${Math.abs(Math.round(total)).toLocaleString()}% TOTAL`
              })
          });

          // apply tooltip styling
          Object.assign(current.style, {
            ...((type === 'date' || type === 'topTotal') && {
              ...tooltipStyles.header,
              left: `${offsetLeft + caretX - tooltipPosition.width / 2}px`
            }),
            ...(type === 'total' && tooltipStyles.apex),
            // style cosmetic tooltips
            ...({}.hasOwnProperty.call(tooltipStyles, type) && tooltipStyles[type])
          });
        });
      }
    }
  };

  const htmlTooltips = () => {
    const list = customTooltipLabels.length ? customTooltipLabels : queries;

    return list.map((key, i) => {
      if (tooltipRefs.current[i]) {
        return (
          <Box
            key={key?.label ? `${key.label}-${i}` : key}
            ref={tooltipRefs.current[i]}
            className={
              customGraph !== 'hype' && customGraph !== 'sourcesAndPages'
                ? `${classes.tooltip} ${hideTooltips ? classes.hidden : ''}`
                : `${classes.point} ${hideTooltips ? classes.hidden : ''}`
            }
          />
        );
      }

      return '';
    });
  };

  const htmlAdditionalTooltips = () =>
    additionalTooltips.map((key, i) =>
      additionalTooltipRefs.current[i] ? (
        <Box
          key={key?.label ? `${key.label}-${i}` : key}
          ref={additionalTooltipRefs.current[i]}
          className={`${classes.tooltip} ${hideTooltips ? classes.hidden : ''}`}
        />
      ) : (
        ''
      )
    );

  // customize graph legend
  const legend = {
    display: false,
    onClick: event => {
      // This is a little hack to stop hiding of data when click on legend
      event.stopPropagation();
    },
    ...((customGraph === 'genderAge' ||
      customGraph === 'horizontalAge' ||
      customGraph === 'webAndAppsGender' ||
      customGraph === 'webAndAppsAge') && {
      display: true,
      position: 'bottom',
      labels: { fontColor: '#6F6F6F' }
    })
  };

  // customize graph tooltip settings/render
  const tooltips = {
    enabled: false,
    intersect: false,
    // chart.js tooltip interaction mode
    mode: interactionMode || 'index',
    custom
  };

  const hover = {
    mode: 'nearest',
    intersect: true,
    animationDuration: 0
  };

  const animation = {
    onComplete: ({ chart }) => {
      if (customGraph === 'horizontalAge' || customGraph === 'webAndAppsAge') {
        const ctx = contextRef.current;

        if (chart.data.datasets.length) {
          chart.data.datasets.forEach(({ data: dataPoint, _meta }) => {
            const id = Object.keys(_meta)[0];

            _meta[id].data.forEach(({ _view }, i) => {
              const { x, y, base } = _view;
              const value = `${Math.round(dataPoint[i])}%`;
              const width = x - base;

              if (width < 20) {
                return;
              }

              ctx.fillStyle = 'white';
              ctx.fillText(value, x - (width / 2 + 8), y);
            });
          });
        }
      }
    },
    duration: 0
  };

  const xAxes = [
    {
      gridLines: {
        color: colors.gray,
        display: false
      },
      ticks: {
        callback: value => {
          if (typeof value === 'string') {
            const [date] = value.split('/');
            return date;
          }
          return value;
        },
        display: (() => {
          switch (customGraph) {
            case 'webAndAppsAge':
            case 'horizontalAge':
              return false;
            default:
              return true;
          }
        })(),
        autoSkip: true,
        maxTicksLimit: (() => {
          switch (customGraph) {
            case 'webAndAppsGender':
            case 'genderAge':
              return 7;
            default:
              return 5;
          }
        })(),
        ...((customGraph === 'horizontalAge' || customGraph === 'webAndAppsAge') && { max: 100 }),
        maxRotation: 0,
        fontColor: colors.darkGray,
        fontSize: 14
      },
      stacked
    }
  ];

  const yAxes = [
    {
      gridLines: {
        color: colors.gray,
        drawBorder: false
      },
      ticks: {
        fontColor: colors.darkGray,
        callback: value => {
          switch (customGraph) {
            case 'webAndAppsGender':
            case 'genderAge':
              return `${Math.round(Math.abs(value), 1)}%`;
            case 'watchTimeStackedBar':
            case 'watchTime': {
              return formatSecondsDisplay(value);
            }
            case 'webAndAppsAge':
            case 'horizontalAge': {
              if (Array.isArray(value)) {
                const [position, date] = value;
                const [dateLabel] = date.split('/');
                return [position, dateLabel];
              }
              return value.toLocaleString();
            }
            case 'hype': {
              if (tabData?.druidQuery?.queryV2 === 'alembicWatchTime') {
                return formatHumanReadableTime(value);
              }
              return value.toLocaleString();
            }
            default:
              return value.toLocaleString();
          }
        },
        precision: 2,
        beginAtZero: customGraph?.includes('watchTime')
      },
      stacked
    }
  ];

  // since we can't outright use the array of dates to generate annotations,
  // we determine the ranges themselves and apply annotations onto those
  // here we determine the 'end' of each period with missing data
  const missingDateAnnotationRangeEnds = datesWithMissingData.filter(
    (date, i) =>
      moment
        .utc(date)
        .add(1, 'd')
        .format('MMM D/YYYY')
        .toUpperCase() !== datesWithMissingData[i + 1]
  );

  // using the end ranges, we generate the start and end and pass them into the annotation field for the graph to render
  const missingDateAnnotationRanges = useRef(
    datesWithMissingData.reduce(
      (acc, curr) => {
        // we write the range start if we are on the first date or have just pushed a range into the annotation list
        if (!acc.rangeStart) {
          acc.rangeStart = curr;
        }

        // if we encounter one of the end ranges determined above, we push and reset the start
        if (missingDateAnnotationRangeEnds.includes(curr)) {
          acc.annotations.push({
            type: 'box',
            xScaleID: 'x-axis-0',
            xMin: acc.rangeStart,
            xMax: curr,
            borderColor: 'transparent',
            backgroundColor: 'rgba(191,191,191,0.2)',
            borderWidth: 0
          });

          acc.rangeStart = null;
        }

        return acc;
      },
      { rangeStart: null, annotations: [] }
    )
  );

  useEffect(() => {
    const graphOptions = {
      legend,
      tooltips,
      hover,
      animation,
      scales: { xAxes, yAxes },
      annotation: {
        annotations: missingDateAnnotationRanges.current.annotations
      }
    };

    setOptions(graphOptions);
  }, [tabData, loadingOverlay]);

  if (error) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={error} />
      </Grid>
    );
  }

  return (
    <>
      <Box className={classes.graphContainer} mb={20}>
        {loadingOverlay && (
          <Box className={classes.loadingContainer}>
            <AlbLoading />
          </Box>
        )}
        <Box style={{ opacity: loadingOverlay ? 0.5 : 1 }}>
          <Box onMouseOut={() => graphHoverAnchor && setGraphHoverAnchor(null)}>
            {/* TODO - Add an overlay specifying no analyzed activity for this period */}
            {error && <AlbError error={error} />}
            {(() => {
              const graphProps = { data, options };

              switch (customGraph) {
                case 'webAndAppsAge':
                case 'horizontalAge':
                  return <HorizontalBar {...graphProps} />;
                case 'webAndAppsLocationMap':
                case 'geoCountries': {
                  if (dataSets[0]?.label === 'COUNTRIES' || dataSets.length === 0) {
                    return <Geo codes={labels} datasets={dataSets} />;
                  }
                  return <AlbLoading />;
                }
                default:
                  return <Bar {...graphProps} />;
              }
            })()}
            {graphLegend && (
              <Palette
                legend={graphLegend}
                hiddenDataSets={hiddenDataSets}
                setHiddenDataSets={setHiddenDataSets}
              />
            )}
            {stackedBarCustomGraphTypes.includes(customGraph) && (
              <AccountsPalette accounts={accounts} />
            )}
          </Box>
          {htmlAdditionalTooltips()}
          {htmlTooltips()}
        </Box>
        {graphHover && graphHoverAnchor && (
          <GraphHover
            open={!!graphHoverAnchor}
            anchorEl={graphHoverAnchor}
            withLTV
            startDate={dates.start}
            endDate={dates.end}
            eventId={graphHoverPostId}
            linkTokenId={graphHoverLinkToken}
            missingData={missingData}
          />
        )}
      </Box>
    </>
  );
};

AlbGraphDruid.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  tabData: PropTypes.shape().isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired,
  onlyVideo: PropTypes.bool,
  graphHover: PropTypes.bool,
  setGraphBanner: PropTypes.func
};

AlbGraphDruid.defaultProps = {
  onlyVideo: false,
  graphHover: false,
  setGraphBanner: () => {}
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(AlbGraphDruid);
