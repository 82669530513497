import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Tabs, Tab, Divider } from '@material-ui/core';
import HasAnyPriv from 'components/HasAnyPriv';
import ContainerWidget from 'components/ContainerWidget';
import SecurityRoles from 'components/SecurityRoles';
import AuditTrail from 'components/SecurityRoles/AuditTrail';
import CalendarSettings from 'components/CalendarSettings';
import MFASettings from 'components/MFASettings';
import Box from 'components/Box';
import { setQueryStringWithoutPageReload, parseQueryString } from 'util/routesHelpers';
import setPageTitle from 'util/titles';

const ManageOrganization = props => {
  const { currentUser, location } = props;
  useEffect(() => {
    setPageTitle('Manage Organization');
  }, []);

  const [currentTab, setCurrentTab] = useState(0);

  const tabsAction = useRef();

  const organizationTabs = [
    { tab: 0, label: 'Container Settings', privs: ['ORG:READ', 'USERS'] },
    { tab: 1, label: 'Roles and Permissions', privs: ['.'] },
    { tab: 2, label: 'Audit Logs', privs: ['.'] },
    { tab: 3, label: 'Calendar Settings', privs: ['.'] },
    { tab: 4, label: 'Multi-Factor Authentication', privs: ['ORG:UPDATE'] }
  ];

  // returns a unique list of privs for each tab
  const mergeTabPrivs = [...new Set(organizationTabs.map(({ privs }) => privs).flat())];

  // set component state from url on first load.
  useEffect(() => {
    const tabsInUrl = parseQueryString('tab', location);

    if (tabsInUrl[0]) {
      let tabId = 0;
      const tabMatch = organizationTabs.find(tab => tab.label === tabsInUrl[0]);

      if (tabMatch) {
        tabId = tabMatch.tab;
      }

      setCurrentTab(tabId);
    }
  }, []);

  useEffect(() => {
    if (tabsAction.current) {
      tabsAction.current.updateIndicator();
    }
  }, [tabsAction.current]);

  // need this when loading tabs from url. Otherwise the tab indicator doesn't properly resize.
  const updateIndicator = indicator => {
    tabsAction.current = indicator;
  };

  // update url based on changes.
  useEffect(() => {
    const selectedTab = organizationTabs.find(tab => tab.tab === currentTab);
    const tabLabel = selectedTab.label;

    setQueryStringWithoutPageReload(`?tab=${encodeURI(tabLabel)}`);
  }, [currentTab]);

  return (
    <Paper>
      {/* Check if user should have access to any of the tabs before rendering the <Tabs/> container */}
      <HasAnyPriv privs={mergeTabPrivs}>
        <>
          {' '}
          <Tabs value={currentTab} action={updateIndicator}>
            {organizationTabs.map(({ privs, tab, label }) => (
              // Tabs don't fire onChange if wrapped in a parent component
              <HasAnyPriv privs={privs} key={tab}>
                <Box onClick={() => setCurrentTab(tab)}>
                  <Tab label={label} disableRipple />
                </Box>
              </HasAnyPriv>
            ))}
          </Tabs>
          <Divider />
        </>
      </HasAnyPriv>

      <HasAnyPriv privs={['ORG:READ', 'USERS']} showAlert>
        <>
          {currentTab === 0 && (
            <ContainerWidget
              masterContainer
              showSubContainer
              containerId={currentUser.home_container_id}
            />
          )}
        </>
      </HasAnyPriv>
      <HasAnyPriv privs={['.']}>
        <>
          {currentTab === 1 && <SecurityRoles />}
          {currentTab === 2 && <AuditTrail />}
          {currentTab === 3 && <CalendarSettings />}
          {currentTab === 4 && <MFASettings />}
        </>
      </HasAnyPriv>
    </Paper>
  );
};

ManageOrganization.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(ManageOrganization);
