/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton } from '@material-ui/core';
import { ThumbUp, ThumbDown, ThumbUpOutlined, ThumbDownOutlined } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { CREATE_PROMPT_TEMPLATE_FEEDBACK } from 'gql/community';
import { showToast } from 'contexts/ToastContext';
import PromptFeedbackModal from 'components/AlembicModalForm/PromptFeedbackModal';
import CausalChainJustificationModal from 'components/AlembicModalConfirm/CausalChainJustificationModal';
import handleGraphQLError from 'util/error';
import CausalAccordion from './CausalAccordion';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '10px',
    padding: '36px',
    marginBottom: '48px'
  },
  title: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734'
  },
  altTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734'
  },
  summaryTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    direction: 'ltr'
  },
  actions: {
    position: 'absolute',
    top: '-9px',
    right: '0px'
  },
  body: {
    fontSize: '20px',
    overflow: 'hidden'
  },
  actionButton: {
    color: '#000000'
  },
  disabled: {
    backgroundColor: 'transparent',
    cursor: 'default',
    pointerEvents: 'none'
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  email: {
    color: '#000000',
    fontSize: '0px'
  },
  insightRow: {
    justifyContent: 'center'
  },
  summary: {
    display: 'flex'
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#979797',
    margin: '28px 0px'
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  }
});

/**
 * @method
 * @summary This component renders the thumbs up and down buttons and the feedback modal per causal chain
 * @name Feedback
 * @return {Object} - React JSX
 */

const Feedback = props => {
  const { data, refetch } = props;
  const feedback = data?.feedback;
  const classes = useStyles();

  const [isPromptFeedbackModalOpen, togglePromptFeedbackModalOpen] = useState(false);

  const [createFeedback, { error, data: feedbackData }] = useMutation(
    CREATE_PROMPT_TEMPLATE_FEEDBACK
  );

  useEffect(() => {
    if (feedbackData?.createPromptTemplateFeedback) {
      showToast('Feedback sent.', 'success');
      refetch();
    }
  }, [feedbackData]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const positiveFeedback = e => {
    createFeedback({
      variables: {
        input: {
          causal_chain_id: parseInt(data.id, 10),
          feedback_type: 'thumbs_up'
        }
      }
    });

    e.stopPropagation();
  };

  const negativeFeedback = e => {
    togglePromptFeedbackModalOpen(true);
    e.stopPropagation();
  };

  const isPositiveFeedback = feedback?.feedback_type === 'thumbs_up';
  const isNegativeFeedback = feedback?.feedback_type === 'thumbs_down';

  return (
    <>
      <IconButton
        className={`${classes.actionButton} ${isPositiveFeedback ? classes.disabled : ''}`}
        onClick={positiveFeedback}
      >
        {isPositiveFeedback ? <ThumbUp /> : <ThumbUpOutlined />}
      </IconButton>
      <IconButton
        className={`${classes.actionButton} ${isNegativeFeedback ? classes.disabled : ''}`}
        onClick={negativeFeedback}
      >
        {isNegativeFeedback ? <ThumbDown /> : <ThumbDownOutlined />}
      </IconButton>
      {data.id != null && isPromptFeedbackModalOpen && (
        <PromptFeedbackModal
          id={data.id}
          isModalOpen={isPromptFeedbackModalOpen}
          onChange={e => togglePromptFeedbackModalOpen(e.isModalOpen)}
          refetch={refetch}
        />
      )}
    </>
  );
};

Feedback.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    feedback: PropTypes.shape()
  }).isRequired,
  refetch: PropTypes.func
};

Feedback.defaultProps = {
  refetch: () => {}
};

/**
 * @method
 * @summary This component renders the results and justify sections for the GraphFish UI
 * @name InsightsSummary
 * @return {Object} - React JSX
 */

const InsightsSummary = props => {
  const { justifyData, refetch } = props;
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [visibleJustification, setVisibleJustification] = useState(false);

  const [isJustificationModalOpen, toggleJustificationModal] = useState(false);
  const [selectedCausalChain, setSelectedCausalChain] = useState(null);

  useEffect(() => {
    // handle re(fetching) the selected causal chain when feedback is provided in the modal
    if (isJustificationModalOpen) {
      const tempSelectedCausalChain = justifyData.find(item => item.id === selectedCausalChain.id);

      setSelectedCausalChain(tempSelectedCausalChain);
    }
    setVisibleJustification(true);
  }, [justifyData]);

  const expandJustify = (e, causalChain) => {
    setSelectedCausalChain(causalChain);
    toggleJustificationModal(true);
    e.stopPropagation();
  };

  return (
    <Grid container direction="column">
      <Paper className={`${classes.box}`}>
        {justifyData?.length === 0 && visibleJustification && (
          <div className={classes.altTitle} style={{ margin: '12px 0px' }}>
            There are no causal chains in this container.
          </div>
        )}
        {justifyData?.length > 0 && visibleJustification && (
          <>
            <div className={classes.title} style={{ marginBottom: '32px' }}>
              Causal Chains
            </div>
            <CausalAccordion justifyData={justifyData} expandJustify={expandJustify} />

            {selectedCausalChain?.id != null && isJustificationModalOpen && (
              <CausalChainJustificationModal
                title={selectedCausalChain.title}
                justification={selectedCausalChain.justification}
                isModalOpen={isJustificationModalOpen}
                onChange={e => toggleJustificationModal(e.isModalOpen)}
                feedbackComponent={
                  <Feedback
                    data={{ id: selectedCausalChain.id, feedback: selectedCausalChain.feedback }}
                    refetch={refetch}
                  />
                }
              />
            )}
          </>
        )}
      </Paper>
    </Grid>
  );
};

InsightsSummary.propTypes = {
  justifyData: PropTypes.arrayOf(PropTypes.shape()),
  refetch: PropTypes.func
};

InsightsSummary.defaultProps = {
  justifyData: null,
  refetch: () => {}
};

export default InsightsSummary;
