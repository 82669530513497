/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import debounce from 'lodash/debounce';
import AlbLoading from 'components/AlbLoading';
import Box from 'components/Box';
import CustomFooter from 'components/TablePagination';
import { apiGetPaginatedAuditEntries } from 'gql/auditEntry';
import { convertToUserTimeZone } from 'util/date';
import AuditLogMeta from './AuditLogMeta';

const AuditTrail = props => {
  const { currentUser } = props;
  const [auditTrail, setAuditTrail] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState(null);

  const [loading, setLoading] = useState(true);

  // use refs to pass state to handlers to account for stale state
  const refRows = useRef(rowsPerPage);
  const refPage = useRef(currentPage);
  const refSearch = useRef(searchText);

  const handleFormatTable = result => {
    const auditEntries = result?.payload?.data?.paginated_audit_entries;

    if (auditEntries) {
      const { rows, count } = auditEntries;

      setTotalCount(count);
      setAuditTrail(
        rows.map(auditEntry => ({
          ...auditEntry,
          meta: JSON.stringify(auditEntry.meta),
          created_at: convertToUserTimeZone(auditEntry.created_at, currentUser.time_zone).format(
            'L LT'
          ),
          user: auditEntry.user_id
            ? `(#${auditEntry.user_id}) ${auditEntry?.user?.first_name ?? ''} ${auditEntry?.user
                ?.last_name ?? ''}`
            : 'Alembic System' /* System update use null value as the audit user */,
          container: auditEntry?.container?.name ?? ''
        }))
      );
    }
  };

  // component initialization
  useEffect(() => {
    apiGetPaginatedAuditEntries({ count: rowsPerPage }).then(result => {
      handleFormatTable(result);
      setLoading(false);
    });
  }, []);

  // update ref state on component state changes
  useEffect(() => {
    refRows.current = rowsPerPage;
  }, [rowsPerPage]);

  useEffect(() => {
    refPage.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    refSearch.current = searchText;
  }, [searchText]);

  const handleChangeRows = numberOfRows => {
    let after = refPage.current * numberOfRows;

    while (after > totalCount) {
      // handle row change requests exceeding the number of existing entries
      // and decrement the page to maintain consistency with the rows
      after -= numberOfRows;
      refPage.current -= 1;
    }

    const args = { after, count: numberOfRows, keyword: refSearch.current };

    apiGetPaginatedAuditEntries(args).then(result => {
      setCurrentPage(refPage.current);
      setRowsPerPage(numberOfRows);
      handleFormatTable(result);
    });
  };

  const handlePagination = page => {
    apiGetPaginatedAuditEntries({
      after: page * refRows.current,
      count: refRows.current,
      keyword: refSearch.current
    }).then(result => {
      setCurrentPage(page);
      handleFormatTable(result);
    });
  };

  const handleSearch = debounce(keyword => {
    const args = {
      count: refRows.current,
      keyword
    };

    setSearchText(keyword);
    setCurrentPage(0);

    apiGetPaginatedAuditEntries(args).then(handleFormatTable);
  }, 500);

  const handleChange = (action, tableState) => {
    // handle search close
    if (action === 'search' && !tableState.searchText) {
      setSearchText(null);
      setCurrentPage(0);

      apiGetPaginatedAuditEntries({ count: refRows.current }).then(handleFormatTable);
    }
  };

  const columns = [
    { name: 'created_at', label: 'Timestamp' },
    { name: 'container', label: 'Container' },
    { name: 'user', label: 'User' },
    { name: 'model_id', label: 'Recipient' },
    { name: 'model', label: 'Object' },
    { name: 'action', label: 'Action' },
    { name: 'meta', options: { display: 'excluded' } }
  ];

  const expandRow = rowData => (
    <AuditLogMeta
      meta={JSON.parse(rowData[columns.findIndex(column => column.name === 'meta')])}
      columnCount={columns.length - 1}
    />
  );

  const options = {
    selectableRows: 'none',
    rowHover: false,
    fixedHeader: false,
    filter: false,
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    expandableRows: true,
    renderExpandableRow: expandRow,
    onChangeRowsPerPage: handleChangeRows,
    onChangePage: handlePagination,
    onSearchChange: handleSearch,
    onTableChange: handleChange,
    responsive: 'standard',
    page: currentPage,
    rowsPerPage,
    count: totalCount,
    searchText,
    customFooter: CustomFooter
  };

  if (loading) return <AlbLoading />;

  return (
    <Box mx={10}>
      <MUIDataTable key={totalCount} data={auditTrail} columns={columns} options={options} />
    </Box>
  );
};

AuditTrail.propTypes = {
  currentUser: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(AuditTrail);
