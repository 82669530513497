import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrencyRound } from 'util/formatCurrency';
import { AlbTooltip } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';

const useStyles = makeStyles(() => ({
  digitalHeading: {
    fontSize: 18,
    color: '#FFF'
  },
  digitalMetric: {
    fontSize: 10,
    color: '#FFF'
  },
  digitalValue: {
    fontSize: 40,
    color: '#FFF',
    marginTop: 30
  },
  iconButton: {
    padding: 0
  },
  infoIcon: {
    fill: 'white'
  },
  floatRight: {
    float: 'right'
  }
}));

/**
 * Renders media value card
 * @name MediaValueCard
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const MediaValueCard = props => {
  const classes = useStyles();
  const { bgcolor, metricName, pricingModel, value, tooltipKey } = props;

  return (
    <Grid item>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        p={5}
        color="#0D1A3A"
        bgcolor={bgcolor}
      >
        <Box>
          <Box className={classes.floatRight}>
            <AlbTooltip title={tooltipText[tooltipKey]} iconColor="white" />
          </Box>
          <Typography className={classes.digitalHeading} align="center">
            {metricName}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.digitalMetric} align="center">
            {pricingModel}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.digitalValue} align="center">
            {value < 0 ? '-' : ''}${formatCurrencyRound(Math.abs(value))}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

MediaValueCard.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  metricName: PropTypes.string.isRequired,
  pricingModel: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  tooltipKey: PropTypes.string.isRequired
};

export default MediaValueCard;
