import { ENABLE_TOKEN } from 'gql/linktoken';
import PlayArrow from '@material-ui/icons/PlayArrow';
/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import handleGraphQLError from 'util/error';

import { showToast } from 'contexts/ToastContext';
import getSimpleSocialType from 'util/getSimpleSocialType';

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';

import SocialLogo from 'components/SocialLogo';

const EnableMenuItem = React.forwardRef((props, ref) => {
  const { id, refetch, close, remoteName, accountType } = props;
  const [enableLinktoken] = useMutation(ENABLE_TOKEN);
  const [responseData, setResponseData] = useState();
  const [error, setError] = useState();
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [transferConfirmed, setTransferConfirmed] = useState(false);
  const [transferConfirmText, setTransferConfirmText] = useState('');

  useEffect(() => {
    if (error) {
      // did we conflict?
      if (error?.graphQLErrors[0]?.extensions?.status === 409) {
        setTransferModalOpen(true);
      } else {
        handleGraphQLError(error);
      }
    }
  }, [error]);

  useEffect(() => {
    if (responseData) {
      if (responseData?.enableToken?.result === true) {
        showToast(`Account Enabled: ${remoteName}`, 'success');
        close();
        refetch();
      }
    }
  }, [responseData]);

  const handleTransferClose = () => {
    setTransferModalOpen(false);
    setTransferConfirmed(false);
    setTransferConfirmText('');
    close();
  };

  const onTransferConfirm = async () => {
    // removeSocialLink({
    // variables: {
    //  id
    // }
    // });
    setTransferModalOpen(false);
    setTransferConfirmed(false);
    setTransferConfirmText('');

    try {
      const { data } = await enableLinktoken({
        variables: {
          id,
          takeover: true
        }
      });
      setResponseData(data);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <>
      <MenuItem
        innerRef={ref}
        onClick={async () => {
          try {
            const { data } = await enableLinktoken({
              variables: {
                id
              }
            });
            setResponseData(data);
          } catch (e) {
            setError(e);
          }
        }}
      >
        <ListItemIcon>
          <PlayArrow fontSize="small" />
        </ListItemIcon>
        <ListItemText>Enable</ListItemText>
      </MenuItem>
      <Dialog
        open={transferModalOpen}
        onClose={handleTransferClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <SocialLogo width={40} height={40} type={getSimpleSocialType(accountType)} />
        <DialogTitle id="alert-dialog-title">Conflict: {remoteName} </DialogTitle>
        <DialogContent>
          <Typography paragraph style={{ color: 'darkred' }}>
            {error?.graphQLErrors[0]?.extensions?.exception?.userFacingErrorMessage}
          </Typography>

          <Typography paragraph style={{ color: 'black' }}>
            When an account is transferred from one user to another, the new user will have full
            control over the account, the account will be deleted from the other user&apos;s list,
            and all data from the (prior) account will be retained.
          </Typography>

          <Typography paragraph style={{ color: 'black' }}>
            To confirm that you wish to take over this account, please type <em>CONFIRM</em> in
            UPPERCASE.
          </Typography>

          <TextField
            fullWidth
            variant="filled"
            value={transferConfirmText}
            placeholder="CONFIRM"
            onChange={event => {
              setTransferConfirmText(event.target.value);
              setTransferConfirmed(event.target.value === 'CONFIRM');
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTransferClose} variant="outlined" color="primary">
            CANCEL
          </Button>
          <Button
            onClick={onTransferConfirm}
            variant="outlined"
            color="primary"
            style={transferConfirmed ? { backgroundColor: '#e00000', color: '#fff' } : {}}
            autoFocus
            disabled={!transferConfirmed}
          >
            TAKEOVER ACCOUNT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

EnableMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  remoteName: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default EnableMenuItem;
