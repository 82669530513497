import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DELETE_ARTICLE_SEARCH, DELETE_ARTICLE_SEARCH_FILTER } from 'gql/articleSearch';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';
import { makeStyles } from '@material-ui/core/styles';

const filterDeleteWarning = `Warning: Deleting a filtered report will also delete other reports that are
currently using it, for example in Media Valuation or Competitive Intelligence. Make sure that you intend to delete those reports before proceeding.`;

const styles = makeStyles({
  filterWarning: {
    marginTop: '20px',
    fontSize: '13px',
    color: 'red'
  }
});

const DeleteArticleSearchModal = props => {
  const { isModalOpen, onChange, articleSearchId, articleSearchName, filterId } = props;
  const classes = styles();

  const [deleteArticleSearch, { error, data }] = useMutation(DELETE_ARTICLE_SEARCH, {
    skip: !articleSearchId && filterId
  });

  const [deleteArticleSearchFilter, { error: filterError, data: filterData }] = useMutation(
    DELETE_ARTICLE_SEARCH_FILTER,
    {
      skip: !filterId && articleSearchId
    }
  );

  useEffect(() => {
    if (data?.deleteArticleSearch?.result || filterData?.deleteArticleSearchFilter?.result) {
      showToast(`Report deleted`, 'success');

      const queryResult = data?.deleteArticleSearch || filterData?.deleteArticleSearchFilter;

      onChange({
        isModalOpen: false,
        newArticleSearchData: queryResult?.result
      });
    }
  }, [data, filterData]);

  useEffect(() => {
    if (error || filterError) {
      showToast('Error deleting report, please try again.', 'error');

      onChange({
        isModalOpen: false
      });
    }
  }, [error, filterError]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        isDelete
        title="Delete Report"
        body={
          <>
            Are you sure you want to delete the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{articleSearchName}</span>?
            {filterId && <div className={classes.filterWarning}>{filterDeleteWarning}</div>}
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Delete"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => {
          if (articleSearchId) {
            deleteArticleSearch({ variables: { id: articleSearchId } });
          }

          if (filterId) {
            deleteArticleSearchFilter({ variables: { id: filterId } });
          }
        }}
      />
    </>
  );
};

DeleteArticleSearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  articleSearchId: PropTypes.string,
  articleSearchName: PropTypes.string,
  filterId: PropTypes.string
};

DeleteArticleSearchModal.defaultProps = {
  articleSearchId: null,
  articleSearchName: null,
  filterId: null
};

export default DeleteArticleSearchModal;
