/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItemIcon, Collapse } from '@material-ui/core';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { SINGLE_POST_GRAPH, SINGLE_POST_TABLE } from 'gql/analytics';
import Box from 'components/Box';
import { linePalette } from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { getDatesForAnalytics } from 'util/date';
import colors from 'util/colors';
import { supportedDrawerGraphTypes, supportedDrawerTableTypes } from 'util/social';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import { formatHumanReadableTime } from 'util/time';
import GraphHeader from './GraphHeader';
// eslint-disable-next-line import/no-cycle
import GraphBody from './GraphBody';
import NumberGrid from './NumberGrid';
import PostDrawerTable from './PostDrawerTable';

const useStyles = makeStyles({
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  graphContainer: {
    flex: 1
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInner: {
    position: 'relative',
    minHeight: '40px',

    '& > :last-child': {
      paddingBottom: '0px !important'
    }
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  legend: {
    marginTop: '10px'
  },
  legendColor: {
    height: '16px',
    width: '16px',
    backgroundColor: linePalette.backgroundColor,
    borderRadius: '3px',
    marginRight: '10px'
  },
  legendText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '12px',
    color: '#6F6F6F',

    '& > :last-child': {
      color: '#0b1835'
    }
  },
  tooltip: {
    fontSize: '15px'
  }
});

/**
 * @method
 * @summary This component renders individual graphs per metric for a post in the single post drawer
 * @name PostDrawerGraph
 * @param {Object} props - React props passed to this component
 * @param {String} props.graph_title - Title of the graph
 * @param {String} props.graph_type - Type of the graph (bar, line, etc.)
 * @param {Object[]} props.children - An array provided by the json config to hold all graph children to be displayed within the current layer
 * @param {String} props.tooltip_text - Descriptive text for the info button on hover
 * @param {String} props.metric_name - The metric associated with current graph to be queried (impressions, views, etc.)
 * @param {String} props.default_open - Determines whether the graph is un-collapsed by default
 * @param {String} props.conversion - Specifies a conditional conversion to be made on a graph label
 * @param {String|Date} [props.startDate=null] - The start date passed from the parent component - If a date range is provided
 * @param {String|Date} [props.endDate=null] - The end date passed from the parent component - If a date range is provided
 * @param {Object} [props.campaignEvent={}] - The current campaign event
 * @param {Number} [props.depth=0] - The current depth of the graph (used for custom styling)
 * @return {Object} - React JSX
 */
const PostDrawerGraph = props => {
  const {
    graph_title: label,
    // TODO - add support for graph types other than line and bar
    graph_type: graphType,
    children,
    tooltip_text,
    metric_name: metric,
    default_open: defaultOpen,
    conversion,
    startDate,
    endDate,
    campaignEvent,
    depth,
    isInitialSurge
  } = props;
  const {
    completed_at: completedAt,
    linked_account: linkedAccount,
    post_id: postId
  } = campaignEvent;
  const { id: linkTokenId } = linkedAccount;

  const classes = useStyles();

  const stringToBool = string => string === 'TRUE';

  const isNumberHeader = graphType === 'NUMBER_HEADER';

  const [isCollapsed, setIsCollapsed] = useState(!stringToBool(defaultOpen));
  const [selectedDateRange, setSelectedDateRange] = useState(0); // defaulted to 7 days
  const [formattedGraphData, setFormattedGraphData] = useState(null);
  const [formattedTableData, setFormattedTableData] = useState(null);
  const [total, setTotal] = useState();
  const [timePeriod, setTimePeriod] = useState(null);

  const [getGraph, { data: graphData, error: graphError, loading: graphLoading }] = useLazyQuery(
    SINGLE_POST_GRAPH
  );

  const [getTable, { data: tableData, error: tableError, loading: tableLoading }] = useLazyQuery(
    SINGLE_POST_TABLE
  );

  useEffect(() => {
    if (startDate && endDate) {
      const startDateUTC = moment(startDate)
        .utc()
        .toISOString();

      const endDateUTC = moment(endDate)
        .utc()
        .endOf('day')
        .toISOString();

      setTimePeriod({
        start: startDateUTC,
        end: endDateUTC
      });

      if (!isInitialSurge) {
        setSelectedDateRange(3);
      } else {
        setSelectedDateRange(0);
      }
    }
  }, [startDate, endDate, isInitialSurge]);

  useEffect(() => {
    if (graphData?.singlePostGraph) {
      let currentTotal = 0;
      const labels = [];
      const type = graphType.toLowerCase();

      const datasets = [
        {
          label,
          type,
          data: graphData.singlePostGraph.map(({ x, y }) => {
            labels.push(moment.utc(x).format('MMM D'));
            currentTotal += y;

            return y;
          }),
          ...linePalette,
          ...(type === 'line' && {
            fill: false,
            lineTension: 0,
            pointRadius: 0
          })
        }
      ];

      setTotal(currentTotal);
      setFormattedGraphData({ labels, datasets });
    }
  }, [graphData]);

  useEffect(() => {
    if (tableData?.singlePostTable) {
      setFormattedTableData(tableData.singlePostTable);
    }
  }, [tableData]);

  const Legend = () => (
    <Grid className={classes.legend} container justifyContent="center">
      <Grid className={classes.legendColor} item />
      <Grid className={classes.legendText} item>
        <Grid item>{label}</Grid>
        <Grid item>
          {conversion === 'ms' ? formatHumanReadableTime(total) : total.toLocaleString()}
        </Grid>
      </Grid>
    </Grid>
  );

  const dateRangeOptions = [
    {
      label: 'FIRST 7D',
      range: getDatesForAnalytics(completedAt, moment(completedAt).add(7, 'd'))
    },
    {
      label: 'FIRST 30D',
      range: getDatesForAnalytics(completedAt, moment(completedAt).add(30, 'd'))
    },
    { label: 'LIFETIME', range: getDatesForAnalytics(completedAt, moment().toDate()) },
    // if a time period is passed in from parent component,
    // display an option to query dates within this period
    ...(timePeriod ? [{ label: 'IN TIME PERIOD', range: timePeriod }] : [])
  ];

  useEffect(() => {
    if (!isCollapsed && !isNumberHeader) {
      const { start, end } = dateRangeOptions[selectedDateRange].range;
      const variables = { startDate: start, endDate: end, linkTokenId, postId, metric };

      if (supportedDrawerTableTypes.includes(graphType)) {
        getTable({ variables });
      } else {
        getGraph({ variables });
      }
    }
  }, [isCollapsed, selectedDateRange]);

  const hasGraph = !children.length && supportedDrawerGraphTypes.includes(graphType);
  const initialWidth = 480;
  // intended for removing width from each graph
  const paddingWidth = depth * 37;

  const postProps = {
    campaignEvent,
    startDate,
    endDate,
    depth: depth + 1,
    maxWidth: `${initialWidth - paddingWidth}px`,
    isInitialSurge
  };

  // impressions tooltips is different across campaign event types
  let prefix = 're';
  if (label === 'Impressions') {
    if (campaignEvent.type.includes('TWITTER')) {
      prefix = 'reTwitter';
    } else if (
      campaignEvent.type.includes('INSTAGRAM_PHOTO') ||
      campaignEvent.type.includes('INSTAGRAM_VIDEO')
    ) {
      prefix = 'reInstagramPhotoVideo';
    } else if (campaignEvent.type.includes('INSTAGRAM_ALBUM')) {
      prefix = 'reInstagramAlbum';
    } else if (campaignEvent.type.includes('INSTAGRAM_STORY')) {
      prefix = 'reInstagramStory';
    } else if (campaignEvent.type.includes('LINKEDIN')) {
      prefix = 'reLinkedIn';
    } else if (campaignEvent.type.includes('FACEBOOK_PAGE')) {
      prefix = 'reFacebookPage';
    } else {
      prefix = 're';
    }
  } else if (label === 'Video Views') {
    if (campaignEvent.type.includes('INSTAGRAM_VIDEO')) {
      prefix = 'instagramVideo';
    } else if (campaignEvent.type.includes('INSTAGRAM_ALBUM_VIDEO')) {
      prefix = 'instagramAlbumVideo';
    } else if (campaignEvent.type.includes('LINKEDIN_VIDEO')) {
      prefix = 'linkedInVideo';
    } else if (campaignEvent.type.includes('TWITTER')) {
      prefix = 'twitter';
    } else if (campaignEvent.type.includes('FACEBOOK_PAGE')) {
      prefix = 'facebookPage';
    } else if (campaignEvent.type.includes('YOUTUBE_VIDEO')) {
      prefix = 'youtubeVideo';
    }
  }

  const tooltipText = getToolTipTitleValue(label, prefix) || tooltip_text;

  return (
    <Grid
      container
      style={{ padding: !depth ? '25px' : '15px', paddingRight: !depth ? '25px' : '0px' }}
    >
      <Grid
        item
        className={classes.collapse}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        <ListItemIcon classes={{ root: classes.listItem }}>
          {isCollapsed ? <Right /> : <Down />}
        </ListItemIcon>
        {!isCollapsed && <Box className={classes.expandedIndicator} />}
      </Grid>
      <Grid item className={classes.graphContainer}>
        <GraphHeader
          label={label}
          tooltipText={tooltipText}
          hasGraph={hasGraph}
          isCollapsed={isCollapsed}
          dateRangeOptions={dateRangeOptions}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
        />
        <Collapse
          in={!isCollapsed}
          classes={{
            wrapperInner: classes.wrapperInner
          }}
        >
          {!!graphError && <AlbError error={graphError} />}
          {!!tableError && <AlbError error={tableError} />}
          {(!!graphLoading || !!tableLoading) && (
            <Box className={classes.loadingOverlay}>
              <AlbLoading />
            </Box>
          )}
          {formattedGraphData && (
            <div>
              <GraphBody
                graphData={formattedGraphData}
                hasGraph={hasGraph}
                isCollapsed={isCollapsed}
                graphChildren={children}
                conversion={conversion}
                {...postProps}
              />
              {hasGraph && !isCollapsed && <Legend />}
            </div>
          )}
          {formattedTableData && (
            <PostDrawerTable tableData={formattedTableData} type={graphType} />
          )}
          {isNumberHeader && (
            <NumberGrid gridItems={children} item={campaignEvent} {...postProps} />
          )}
        </Collapse>
      </Grid>
    </Grid>
  );
};

PostDrawerGraph.propTypes = {
  graph_title: PropTypes.string.isRequired,
  graph_type: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tooltip_text: PropTypes.string,
  metric_name: PropTypes.string.isRequired,
  default_open: PropTypes.string.isRequired,
  conversion: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  campaignEvent: PropTypes.shape().isRequired,
  depth: PropTypes.number,
  isInitialSurge: PropTypes.bool
};

PostDrawerGraph.defaultProps = {
  tooltip_text: '-',
  conversion: null,
  startDate: null,
  endDate: null,
  depth: 0,
  isInitialSurge: false
};

export default PostDrawerGraph;
