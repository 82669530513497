/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ENABLE_MENTION_GROUP } from 'gql/mention_group';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

/**
 * @method
 * @summary This component renders the modal used to include a narrative
 * @name IncludeNarrativeModal
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isModalOpen - A boolean value to determine the modal's open state
 * @param {Function} props.onChange - A function run on successful mutation used to update parent component
 * @param {string} props.narrativeId - A string value for the narrative id
 * @param {string} props.narrativeName - A string value for the narrative name
 * @return {Object} - React JSX
 */
const IncludeNarrativeModal = props => {
  const { isModalOpen, onChange, narrativeId, narrativeName } = props;

  const [enableMentionGroup, { error, data }] = useMutation(ENABLE_MENTION_GROUP);

  useEffect(() => {
    if (data?.enableMentionGroup?.disabled === false) {
      showToast(`Narrative included`, 'success');
      onChange({
        isModalOpen: false,
        updated: true
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Include Narrative"
        body={
          <>
            Are you sure you want to include the narrative:&nbsp;
            <span style={{ color: '#0A1734' }}>{narrativeName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Include"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() =>
          enableMentionGroup({
            variables: { id: narrativeId },
            refetchQueries: ['thirdPartyNarrativesGraphTop', 'thirdPartyNarrativesGraphTotal']
          })
        }
      />
    </>
  );
};

IncludeNarrativeModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  narrativeId: PropTypes.string,
  narrativeName: PropTypes.string
};

IncludeNarrativeModal.defaultProps = {
  narrativeId: null,
  narrativeName: null
};

export default IncludeNarrativeModal;
