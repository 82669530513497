import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Tooltip, Typography } from '@material-ui/core';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { getAttentionActionTitle } from 'util/detectedEvents';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    fontSize: '26px',
    color: '#0A1734',
    marginBottom: '20px'
  },
  eventTitle: {
    maxWidth: '290px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  eventTitleLarge: {
    fontSize: '22px'
  },
  eventTitleSmall: {
    fontSize: '18px'
  },
  graphContainer: {
    display: 'inline',
    width: '100%',
    marginBottom: '75px'
  },
  horizontalStackGraph: {
    display: 'flex',
    width: '100%',
    whiteSpace: 'nowrap',
    position: 'relative',
    '& div:nth-child(1) > span': {
      // styles for first span child
      bottom: '-25px',
      left: '0px'
    },
    '& div:nth-child(2) > span': {
      // styles for second span child
      top: '-25px',
      right: '0px'
    },
    '& div:nth-child(3) > span': {
      // styles for third span child
      bottom: '-25px',
      right: '0px'
    }
  },
  horizontalStackGraphLarge: {
    height: '36px'
  },
  horizontalStackGraphSmall: {
    height: '10px'
  },
  range: {
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    fontSize: '12px',
    backgroundColor: '#32327D'
  },
  legendTitle: {
    position: 'absolute',
    color: '#32327D',
    fontSize: '18px'
  },
  eventTitleContainer: {
    color: '#32327D',
    display: 'grid',
    gridTemplateColumns:
      'auto 1fr' /* First column is sized based on content, second column takes up remaining space */
  },
  eventTitleText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    fontFamily: 'Poppins'
  },
  eventTitleValue: {
    marginLeft: '5px' /* Add some spacing between the columns */
  },
  '@media (max-width: 1800px)': {
    eventTitleLarge: {
      fontSize: '18px'
    },
    eventTitleSmall: {
      fontSize: '14px'
    },
    legendTitle: {
      fontSize: '14px'
    },
    eventTitle: {
      maxWidth: '220px'
    }
  }
});

const formatValue = value =>
  typeof value === 'number'
    ? Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(value)
    : value;

/**
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const TopTenContributors = props => {
  const { loading, error, data } = props;

  const classes = useStyles();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (data?.topProgramLiftContributors?.length > 0) {
      const tableData = data?.topProgramLiftContributors?.map(originalObj => {
        const obj = {
          base_magnitude: originalObj.base_magnitude,
          non_program_lift_magnitude: originalObj.non_program_lift_magnitude,
          program_lift_magnitude: originalObj.program_lift_magnitude
        };

        // Get the total sum mag sum
        const valuesSum = Object.values(obj).reduce((accumulator, value) => accumulator + value, 0);

        // compute % of each mag value
        const percentagesObj = Object.entries(obj).reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key]: valuesSum !== 0 ? Math.round((value / valuesSum) * 100) : '100'
          }),
          {}
        );

        const objLiftOnly = {
          non_program_lift_magnitude: originalObj.non_program_lift_magnitude,
          program_lift_magnitude: originalObj.program_lift_magnitude
        };

        // Get the total mag sum for lift only
        const valuesLiftOnlySum = Object.values(objLiftOnly).reduce(
          (accumulator, value) => accumulator + value,
          0
        );

        // compute % of each mag value for lift only
        const percentagesLiftOnlyObj = Object.entries(objLiftOnly).reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key]: valuesLiftOnlySum !== 0 ? Math.round((value / valuesLiftOnlySum) * 100) : '100'
          }),
          {}
        );

        return {
          ...originalObj,
          event: originalObj.event_label
            ? `${originalObj.event_label} (${getAttentionActionTitle(originalObj.event_type)})`
            : getAttentionActionTitle(originalObj.event_type),
          ranges: [
            {
              percentageOfTotal: percentagesObj.base_magnitude,
              magnitude: obj.base_magnitude,
              title: 'base'
            },
            {
              percentageOfTotal: percentagesObj.non_program_lift_magnitude,
              magnitude: obj.non_program_lift_magnitude,
              title: 'non-program lift'
            },
            {
              percentageOfTotal: percentagesObj.program_lift_magnitude,
              magnitude: obj.program_lift_magnitude,
              percentageOfLift: percentagesLiftOnlyObj.program_lift_magnitude,
              title: 'program lift'
            }
          ]
        };
      });

      setEvents(tableData);
    } else {
      setEvents([]);
    }
  }, [data]);

  if (loading) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbLoading />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={error} />
      </Grid>
    );
  }

  return (
    <>
      <Typography className={classes.title}>EVENTS LIFTED - TOP 10</Typography>
      <Grid container spacing={2}>
        <Grid item xs={8} style={{ paddingRight: '51px' }}>
          <Grid container>
            {events.slice(0, 4).map(item => (
              <Box key={item.id} className={classes.graphContainer}>
                <Tooltip title={item.ugcLabel || item.event}>
                  <p className={`${classes.eventTitle} ${classes.eventTitleLarge}`}>
                    {item.ugcLabel || item.event}
                  </p>
                </Tooltip>
                <Grid
                  item
                  className={`${classes.horizontalStackGraph} ${classes.horizontalStackGraphLarge}`}
                >
                  {item.ranges.map(
                    ({ magnitude, percentageOfTotal, title, percentageOfLift }, index) => (
                      <Box
                        key={title}
                        className={classes.range}
                        style={{
                          width: `${percentageOfTotal}%`,
                          ...(index === 0 && { borderRadius: '3px 0px 0px 3px', opacity: 1 }),
                          ...(index === 1 && { opacity: 0.8 }),
                          ...(index === 2 && { borderRadius: '0px 3px 3px 0px', opacity: 0.6 })
                        }}
                      >
                        <span className={classes.legendTitle}>
                          {`${title} ${formatValue(magnitude)} (${
                            index === 2
                              ? `${percentageOfLift}% of lift, ${percentageOfTotal}% of total`
                              : `${percentageOfTotal}%`
                          })`}
                        </span>
                      </Box>
                    )
                  )}
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: '51px', paddingRight: '51px' }}>
          <Grid container>
            {events.slice(4, 10).map(item => (
              <Box key={item.id} className={classes.graphContainer}>
                <div className={`${classes.eventTitleContainer} ${classes.eventTitleSmall}`}>
                  <Tooltip title={item.ugcLabel || item.event}>
                    <div className={`${classes.eventTitleText}`}>{item.ugcLabel || item.event}</div>
                  </Tooltip>
                  <div className={`${classes.eventTitleValue}`}>
                    +{formatValue(item.ranges[2].magnitude)}
                  </div>
                </div>
                <Grid
                  item
                  className={`${classes.horizontalStackGraph} ${classes.horizontalStackGraphSmall}`}
                >
                  {item.ranges.map(({ percentageOfTotal, title }, index) => (
                    <Box
                      key={title}
                      className={classes.range}
                      style={{
                        width: `${percentageOfTotal}%`,
                        ...(index === 0 && {
                          opacity: 0.1,
                          borderRadius: '5px 0px 0px 5px',
                          // if this bar is 100%, then override with a left and right end cap
                          ...(percentageOfTotal === 100 && {
                            borderRadius: '5px 5px 5px 5px'
                          })
                        }),
                        ...(index === 1 && {
                          opacity: 0.8,
                          // if the stack bar on the left is 0%, then add a left end cap
                          ...(item.ranges[0].percentageOfTotal === 0 && {
                            borderRadius: '5px 0px 0px 5px'
                          }),
                          // if the stack bar on the right is 0%, then add a right end cap
                          ...(item.ranges[2].percentageOfTotal === 0 && {
                            borderRadius: '0 5px 5px 0px'
                          }),
                          // if this bar is 100%, then override with a left and right end cap
                          ...(percentageOfTotal === 100 && {
                            borderRadius: '5px 5px 5px 5px'
                          })
                        }),
                        ...(index === 2 && {
                          opacity: 0.6,
                          borderRadius: '0 5px 5px 0px',
                          // if this bar is 100%, then override with a left and right end cap
                          ...(percentageOfTotal === 100 && {
                            borderRadius: '5px 5px 5px 5px'
                          })
                        })
                      }}
                    >
                      {index === 2 && (
                        <span className={classes.legendTitle}>{`${percentageOfTotal}%`}</span>
                      )}
                    </Box>
                  ))}
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

TopTenContributors.defaultProps = {
  loading: false,
  error: null,
  data: null
};

TopTenContributors.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  data: PropTypes.shape()
};

export default TopTenContributors;
