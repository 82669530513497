import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { LIST_DETECTED_EVENTS } from 'gql/detected_event';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import CustomFooter from 'components/TablePagination';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';

const styles = makeStyles({
  container: {
    padding: '30px 0px',
    zIndex: 0,
    position: 'relative'
  },
  title: {
    color: '#0a1734',
    fontSize: '16px',
    fontWeight: 500,
    paddingLeft: '27px'
  },
  showMoreButton: {
    width: '250px',
    marginTop: '20px'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const DetectedEventDrawerTable = props => {
  const { detectedEvent } = props;
  const classes = styles();

  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [after, setAfter] = useState(0);
  const [count, setCount] = useState(0);
  const [sortOrder, setSortOrder] = useState(['magnitude', 'desc']);

  const { data, loading, error } = useQuery(LIST_DETECTED_EVENTS, {
    variables: {
      startDate: moment
        .utc()
        .startOf('day')
        .subtract(1, 'year')
        .toISOString(),
      endDate: moment
        .utc()
        .startOf('day')
        .toISOString(),
      ...(![DETECTED_EVENT_TYPE.NS_FUND_FLOW].includes(detectedEvent.event_type) && {
        eventType: detectedEvent.event_type
      }),
      ...([DETECTED_EVENT_TYPE.AA_CONVERSIONS_BY_EVENT, DETECTED_EVENT_TYPE.NS_FUND_FLOW].includes(
        detectedEvent.event_type
      ) && {
        eventLabel: detectedEvent.event_label
      }),
      after: after * 5,
      count: 5,
      orderBy: sortOrder
    }
  });

  useEffect(() => {
    let tempTableData = [];
    let tempCount = 0;

    if (data?.listDetectedEvents?.rows?.length > 0) {
      tempTableData = data.listDetectedEvents.rows.map(event => {
        return {
          date: {
            startDate: event.event_start_date,
            endDate: event.event_end_date
          },
          magnitude: {
            value: event.magnitude,
            unit: event.mag_unit
          }
        };
      });
      tempCount = data.listDetectedEvents.count;
    }

    setTableData(tempTableData);
    setCount(tempCount);
  }, [data]);

  const customDateRender = date => {
    const { startDate, endDate } = date;

    // show range if dates are different
    if (startDate !== endDate) {
      return `${moment.utc(startDate).format('L')} - ${moment.utc(endDate).format('L')}`;
    }

    return moment.utc(startDate).format('L');
  };

  const customMetricRender = metric => {
    const { value, unit } = metric;

    return `${unit === 'revenue' ? '$' : ''}${getValueAbbreviation(value)} ${unit}`;
  };

  const columns = [
    {
      name: 'date',
      label: 'EVENT DATE',
      options: {
        customBodyRender: customDateRender,
        sort: false
      }
    },
    {
      name: 'magnitude',
      label: 'METRIC',
      options: {
        customBodyRender: customMetricRender
      }
    }
  ];

  const rowsPerPageOptions = [];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setAfter,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page: after,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    textLabels: {
      body: {
        noMatch: 'No events found'
      }
    }
  };

  return (
    <Grid container className={classes.container} direction="column">
      <Typography className={classes.title}>Other events in the last year</Typography>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      {data?.listDetectedEvents && (
        <AlbTable tableData={tableData} tableColumns={columns} tableOptions={tableOptions} />
      )}
    </Grid>
  );
};

DetectedEventDrawerTable.propTypes = {
  detectedEvent: PropTypes.shape().isRequired
};

export default DetectedEventDrawerTable;
