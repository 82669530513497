/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbGraphDruid from 'components/AlbGraphs/AlbGraphDruid';
import BoxContentChange from 'components/AnalyticsSocialV2/BoxContent/BoxContentChange';
import BoxContentTop from 'components/AnalyticsSocialV2/BoxContent/BoxContentTop';
import colors from 'util/colors';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';
import WebTopTable from './Tables/WebTopTable';

const useStyles = makeStyles({
  box: {
    margin: '25px 15px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  },
  graph: {
    flexDirection: 'column',
    padding: '30px'
  },
  tab: {
    fontSize: '18px',
    fontWeight: 500,
    padding: '24px',
    whiteSpace: 'nowrap',
    maxWidth: 'unset',
    color: colors.navy
  },
  dateText: {
    color: '#6f6f6f'
  }
});

const WebOverview = props => {
  const { accounts, dates } = props;

  const classes = useStyles();

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [hasAdobeAnalytics, setHasAdobeAnalytics] = useState(false);
  const [hasGoogleAnalytics, setHasGoogleAnalytics] = useState(false);

  useEffect(() => {
    setHasAdobeAnalytics(
      accounts.filter(account => [LINKTOKEN_TYPE_ENUM.AA_SEGMENT].includes(account.type)).length > 0
    );

    setHasGoogleAnalytics(
      accounts.filter(account =>
        [LINKTOKEN_TYPE_ENUM.GOOGLE_VIEW, LINKTOKEN_TYPE_ENUM.GOOGLE_WEBPROPERTY_G4].includes(
          account.type
        )
      ).length > 0
    );
  }, [accounts]);

  const graph = {
    tabTitle: 'TOTAL',
    customGraph: 'stackedBar',
    granularity: 'month',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: isSwitchChecked ? 'UniqueUsers' : 'Users',
        queryV2: isSwitchChecked ? 'webAndAppsTotalUniqueUsersGraph' : 'webAndAppsTotalUsersGraph',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  };

  const totalUserBoxes = [
    {
      title: 'Unique Users',
      metric: 'webAndAppsTotalUniqueUsers'
    }
  ];

  const totalBoxes = [
    {
      title: 'Total Conversions',
      metric: 'webAndAppsTotalConversions'
    },
    {
      title: 'Total Transactions',
      metric: 'webAndAppsTotalTransactions'
    },
    {
      title: 'Revenue',
      metric: 'webAndAppsRevenue',
      useCurrency: true
    }
  ];

  let gridProps = {
    lg: 3,
    sm: 6
  };

  const totalUsers = {
    title: 'Total Users',
    metric: 'webAndAppsTotalUsers'
  };

  if (hasGoogleAnalytics) {
    totalBoxes.unshift(totalUsers);
  } else if (hasAdobeAnalytics) {
    totalUserBoxes.unshift(totalUsers);
    gridProps = {
      md: 4
    };
  }

  const topBoxes = [
    {
      title: 'Top Demographic',
      widget: 'webAndAppsTopDemographic'
    },
    {
      title: 'Top Country',
      widget: 'webAndAppsTopCountry'
    },
    {
      title: 'Top City',
      widget: 'webAndAppsTopCity'
    }
  ];

  return (
    <>
      <Grid container style={{ marginTop: '-25px' }}>
        {hasAdobeAnalytics && (
          <Grid container item>
            {/* User Boxes */}
            {totalUserBoxes.map(({ title, metric }) => (
              <Grid key={title} item style={{ display: 'flex' }} md={6} xs={12}>
                <BoxContentChange
                  title={title}
                  metric={metric}
                  accounts={accounts}
                  dates={dates}
                  growth
                  prefix="wa"
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container item>
          {/* Total Boxes */}
          {totalBoxes.map(({ title, metric, useCurrency }) => (
            <Grid key={title} item style={{ display: 'flex' }} {...gridProps} xs={12}>
              <BoxContentChange
                title={title}
                metric={metric}
                accounts={accounts}
                dates={dates}
                growth
                useCurrency={useCurrency}
                prefix="wa"
              />
            </Grid>
          ))}
        </Grid>
        <Grid container item>
          {/* Top Boxes */}
          {topBoxes.map(({ title, widget }) => (
            <Grid key={title} item style={{ display: 'flex' }} md={4} xs={12}>
              <BoxContentTop
                title={title}
                widget={widget}
                accounts={accounts}
                dates={dates}
                prefix="wa"
              />
            </Grid>
          ))}
        </Grid>
        <Grid container item className={classes.box}>
          {/* Total Users / Unique Users Graph w/ Toggle */}
          <BoxHeader
            switches={hasAdobeAnalytics ? ['Total', 'Unique'] : []}
            isSwitchChecked={isSwitchChecked}
            setIsSwitchChecked={setIsSwitchChecked}
            tabs={['Users']}
            dates={dates}
            prefix="wa"
          />
          <Grid container className={classes.graph}>
            <AlbGraphDruid tabData={graph} accounts={accounts} dates={dates} />
          </Grid>
        </Grid>
        <Grid container item className={classes.box}>
          {/* Top Sources Box */}
          <BoxHeader tabs={['Top Sources']} dates={dates} prefix="wa" />
          <WebTopTable metric="webAndAppsTopSourcesTable" accounts={accounts} dates={dates} />
        </Grid>
        <Grid container item className={classes.box}>
          {/* Top Pages Box */}
          <BoxHeader tabs={['Top Pages']} dates={dates} prefix="wa" />
          <WebTopTable metric="webAndAppsTopPagesTable" accounts={accounts} dates={dates} />
        </Grid>
      </Grid>
    </>
  );
};

WebOverview.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default WebOverview;
