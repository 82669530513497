import { gql } from '@apollo/client';

export const PODCAST_SEARCH = gql`
  query podcastSearch($id: ID!) {
    podcastSearch(id: $id) {
      id
      name
      query_keywords
      mention_extract_keyword
    }
  }
`;

export const PODCAST_SEARCHES = gql`
  query listPodcastSearches($enabled: Boolean, $count: Int, $after: Int) {
    listPodcastSearches(enabled: $enabled, count: $count, after: $after) {
      rows {
        id
        name
        enabled
        is_default
        query_keywords
        total_results
        initial_search_completed
        mention_extract_keyword
      }
      count
    }
  }
`;

export const GET_PODCAST_SEARCHES = gql`
  query podcastSearches($enabled: Boolean) {
    podcastSearches(enabled: $enabled) {
      id
      name
      enabled
      is_default
      query_keywords
    }
  }
`;

export const CREATE_PODCAST_SEARCH = gql`
  mutation($search: BroadcastPodcastSearchInput!, $isDefault: Boolean) {
    createPodcastSearch(search: $search, isDefault: $isDefault) {
      id
    }
  }
`;

export const PREVIEW_PODCAST_SEARCH = gql`
  query previewPodcastSearchResults($query_keywords: String!) {
    previewPodcastSearchResults(query_keywords: $query_keywords) {
      rows {
        author
        url
        description
        language
        country
        mediaType
        publishDate
        title
        imageUrl
        summary
        sourceLink
      }
      totalCount
    }
  }
`;

export const ARCHIVE_PODCAST_SEARCH = gql`
  mutation disablePodcastSearch($id: ID!) {
    disablePodcastSearch(id: $id) {
      id
      name
      enabled
      is_default
      query_keywords
    }
  }
`;

export const UNARCHIVE_PODCAST_SEARCH = gql`
  mutation enablePodcastSearch($id: ID!) {
    enablePodcastSearch(id: $id) {
      id
      name
      enabled
      is_default
      query_keywords
    }
  }
`;

export const DELETE_PODCAST_SEARCH = gql`
  mutation deletePodcastSearch($id: ID!) {
    deletePodcastSearch(id: $id) {
      result
      message
    }
  }
`;

export const SET_DETECTING_PODCAST_SEARCH = gql`
  mutation setDetectingPodcastSearch($id: ID!, $setDetecting: Boolean!) {
    setDetectingPodcastSearch(id: $id, setDetecting: $setDetecting) {
      id
      name
      enabled
      is_default
      query_keywords
    }
  }
`;

export const UPDATE_PODCAST_SEARCH = gql`
  mutation updatePodcastSearch($id: ID!, $patch: BroadcastPodcastSearchInput!) {
    updatePodcastSearch(id: $id, patch: $patch) {
      id
    }
  }
`;
