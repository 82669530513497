import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Dialog, Grid, Typography, Button, MenuItem, InputLabel, Tooltip } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { SECURITY_ROLES } from 'gql/securityRole';
import { CREATE_INVITE } from 'gql/invitation';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { showToast } from 'contexts/ToastContext';

const InviteUserModal = props => {
  const { containerId, closeCallback, setRefetchInvite, onInvite } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');

  const { loading, error: queryError, data: roleData, refetch } = useQuery(SECURITY_ROLES);
  const [createInvitation, { error: updateError, data: updateData }] = useMutation(CREATE_INVITE);

  useEffect(() => {
    if (updateData) {
      setRefetchInvite();
      closeCallback();
      onInvite();
      showToast('User has been invited.', 'success');
    }
  }, [updateData]);

  if (loading) return <AlbLoading />;
  if (queryError) return <AlbError error={queryError} refetch={refetch} />;

  return (
    <Dialog open onClose={closeCallback}>
      <Box p={30}>
        <Box mb={16}>
          <Typography variant="h6">Invite User</Typography>
        </Box>

        <ValidatorForm
          onSubmit={() => {
            createInvitation({
              variables: {
                container_id: containerId,
                recipient_first_name: firstName,
                recipient_last_name: lastName,
                recipient_email: email,
                recipient_role: role
              }
            });
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>First Name</InputLabel>
              <TextValidator
                fullWidth
                autoFocus
                name="firstName"
                variant="filled"
                placeholder="John"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
                validators={['required']}
                errorMessages={['* Required']}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Last Name</InputLabel>
              <TextValidator
                fullWidth
                name="lastName"
                variant="filled"
                placeholder="Doe"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
                validators={['required']}
                errorMessages={['* Required']}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <TextValidator
                fullWidth
                name="email"
                variant="filled"
                placeholder="user@example.com"
                value={email}
                onChange={event => setEmail(event.target.value)}
                validators={['required']}
                errorMessages={['* Required']}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Role</InputLabel>

              <TextValidator
                select
                fullWidth
                name="role"
                variant="filled"
                placeholder="Admin"
                value={role}
                onChange={event => setRole(event.target.value)}
                validators={['required']}
                errorMessages={['* Required']}
              >
                {roleData.security_roles.map(securityRole => {
                  return (
                    <MenuItem key={securityRole.id} value={parseInt(securityRole.id, 10)}>
                      <Tooltip title={securityRole.description}>
                        <div>{securityRole.name}</div>
                      </Tooltip>
                    </MenuItem>
                  );
                })}
              </TextValidator>
            </Grid>
            {updateError && <AlbError error={updateError} />}
            <Grid item container>
              <Typography variant="body2" style={{ margin: '13px 0px' }}>
                By inviting a user, you are granting them access to this container. They will
                receive an email with a single-use only link to create an account.
              </Typography>

              <Typography variant="body2" style={{ margin: '13px 0px' }}>
                Invitation links expire after 7 days and will need to be resent if not used.
              </Typography>
            </Grid>
            <Grid item container>
              <Button fullWidth type="submit" variant="contained" color="primary">
                Invite User
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Box>
    </Dialog>
  );
};

InviteUserModal.propTypes = {
  containerId: PropTypes.string.isRequired,
  closeCallback: PropTypes.func.isRequired,
  setRefetchInvite: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired
};

export default InviteUserModal;
