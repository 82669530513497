/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import AnalyticsBox from 'components/AnalyticsBox';
import EarnedBar from './Graphs/EarnedBar';
import OwnedBar from './Graphs/OwnedBar';
import EarnedExcludedBar from './Graphs/EarnedExcludedBar';
import OwnedExcludedBar from './Graphs/OwnedExcludedBar';
import MediaValuationOwned from './Tables/MediaValuationOwned';
import MediaValuationEarned from './Tables/MediaValuationEarned';

/**
 * Renders table for a final/saved cost to recreate report
 * @name FinalSavedCostToRecreate
 * @param {Object} props - React props passed to this component
 * @param {Object} props.recreateCostReport - The RecreateCostReport object
 * @param {String} props.valuationType - The type of 'cpm' or 'cpa' calculation
 * @param {Object} props.dates - An object containing start and end dates
 * @param {Boolean} props.useLifetimeValue - Boolean flag indicating whether to use lifetime values or not
 * @return {Object} - React JSX
 */

const FinalSavedCostToRecreate = props => {
  const { recreateCostReport, valuationType, dates, useLifetimeValue, refetch } = props;
  const tableProps = {
    recreateCostReportId: recreateCostReport?.id,
    valuationType,
    dates,
    useLifetimeValue
  };

  const tabs = [
    {
      title: 'Owned',
      filter: 'FILTER',
      graph: OwnedBar,
      table: MediaValuationOwned,
      graphData: recreateCostReport?.owned?.graphData,
      refetchGraph: refetch
    },
    {
      title: 'Earned',
      filter: 'FILTER',
      graph: EarnedBar,
      table: MediaValuationEarned,
      graphData: recreateCostReport?.earned?.graphData,
      refetchGraph: refetch
    },
    {
      title: 'Owned Excluded',
      filter: 'ONLY',
      graph: OwnedExcludedBar,
      table: MediaValuationOwned,
      graphData: recreateCostReport?.ownedIgnored?.graphData,
      refetchGraph: refetch
    },
    {
      title: 'Earned Excluded',
      filter: 'ONLY',
      graph: EarnedExcludedBar,
      table: MediaValuationEarned,
      graphData: recreateCostReport?.earnedIgnored?.graphData,
      refetchGraph: refetch
    }
  ];

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AnalyticsBox
              noMargin
              noPadding
              tabs={tabs.map(({ title }) => title)}
              onChange={() => {}}
              prefix="mv"
            >
              {tabs.map(({ title, graph, table, graphData, filter, refetchGraph }) => {
                const Graph = graph;
                const Table = table;
                return (
                  <Box key={title}>
                    <Graph graphData={graphData} />
                    <Table {...tableProps} filter={filter} refetchGraph={refetchGraph} />
                  </Box>
                );
              })}
            </AnalyticsBox>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

FinalSavedCostToRecreate.propTypes = {
  recreateCostReport: PropTypes.shape(),
  valuationType: PropTypes.string,
  dates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }).isRequired,
  useLifetimeValue: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired
};

FinalSavedCostToRecreate.defaultProps = {
  recreateCostReport: null,
  valuationType: null
};

export default FinalSavedCostToRecreate;
