/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbGraphDruid from 'components/AlbGraphs/AlbGraphDruid';
import { sourcesAndPagesGraphLabels } from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import Banner from 'components/Banner';
import { topPostsGradient } from 'util/colors';
import WebTopTable from './Tables/WebTopTable';

const useStyles = makeStyles({
  box: {
    margin: '25px 15px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  },
  banner: {
    width: '100%',
    padding: '15px 30px 0px'
  },
  graph: {
    flexDirection: 'column',
    padding: '30px'
  },
  table: {
    boxShadow: 'inset 0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
  }
});

const WebSearch = props => {
  const { accounts, dates } = props;

  const classes = useStyles();

  const tabs = ['Paid + Organic', 'On-Site', 'First Converting Paid'];
  const queries = ['webAndAppsTopPaid', 'webAndAppsTopOrganic', 'webAndAppsTopConverting'];
  // eslint-disable-next-line no-sparse-arrays
  const banners = [, , 'Only available on GA4.'];

  const [currentTab, setCurrentTab] = useState(0);
  const [pageGraph, setPageGraph] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setPageGraph(0);
  }, [currentTab]);

  const createGraph = (tab, page) => ({
    customGraph: 'sourcesAndPages',
    additionalTooltips: ['point'],
    druidQuery: {
      queryV2: `${queries[tab]}Graph`,
      graphType: 'webAndAppsTable',
      page,
      rowsPerPage
    },
    fill: true,
    lineTension: 0.4,
    interactionMode: 'nearest',
    tooltipWidthCustom: 361,
    // sourcesAndPagesGraphLabels is a filler array with 10 indices which is only used to provide the graph with an
    // indicator to render 10 tooltips/legend entries, but we may need to dynamically update the queries
    // field in the graph with the results of the query if we enable more than 10 rows in the table since
    // we're planning to have the graph and table be 1:1
    queries: sourcesAndPagesGraphLabels.map((label, i) => ({
      type: 'line',
      palette: {
        gradient: topPostsGradient[i],
        borderColor: 'transparent'
      },
      // the label is used here to act as 'key' for label/tooltip mapping, otherwise unrelated to the query
      label
    }))
  });

  const [graph, setGraph] = useState(createGraph(currentTab, 0));

  useEffect(() => {
    setGraph(createGraph(currentTab, 0));
  }, [currentTab]);

  useEffect(() => {
    setGraph(createGraph(currentTab, pageGraph));
  }, [pageGraph]);

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item className={classes.box}>
        <BoxHeader tabs={tabs} dates={dates} onTabChange={setCurrentTab} prefix="wa" />
        {banners[currentTab] && (
          <Grid className={classes.banner}>
            <Banner useIcon message={banners[currentTab]} variant="filled" severity="warning" />
          </Grid>
        )}
        <Grid container className={classes.graph}>
          <AlbGraphDruid tabData={graph} accounts={accounts} dates={dates} />
        </Grid>
        <Grid container item className={classes.table}>
          <BoxHeader tabs={[`${tabs[currentTab]} Breakdown`]} dates={dates} prefix="wa" />
          <WebTopTable
            metric={`${queries[currentTab]}Table`}
            accounts={accounts}
            dates={dates}
            setPageGraph={setPageGraph}
            rowsPerPageOptions={[]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

WebSearch.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default WebSearch;
