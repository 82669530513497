import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import Box from 'components/Box';
import Legend from 'components/Legend';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';

const useStyles = makeStyles({
  primaryText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734'
  },
  card: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px',
    position: 'relative'
  },
  cardContent: {
    padding: '24px'
  },
  tabOverRide: {
    fontSize: 18,
    fontWeight: 500
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const METRIC_MAP = {
  alembicImpressions: 'Impressions',
  alembicEngagement: 'Engagement',
  alembicShares: 'Shares'
};

const ComparisonItem = props => {
  const { title, data } = props;

  return (
    <Grid container>
      <Grid container item sm={2} alignItems="center">
        <Typography
          style={{ fontWeight: '500', fontSize: '18px', lineHeight: '27px', color: '#000000' }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid container item sm={10} justifyContent="center" style={{ position: 'relative' }}>
        {data == null && (
          <Typography
            style={{ fontWeight: '500', fontSize: '18px', lineHeight: '27px', color: '#000000' }}
          >
            -
          </Typography>
        )}
        {data != null && (
          <>
            <div
              style={{
                width: `${data?.publishedPercentage ?? 0}%`,
                height: '51px',
                backgroundColor: '#C40075'
              }}
            />
            <div
              style={{
                width: `${data?.longTailPercentage ?? 0}%`,
                height: '51px',
                backgroundColor: '#32327D'
              }}
            />
            <Typography
              style={{
                position: 'absolute',
                bottom: '-27px',
                left: 0,
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '27px',
                color: '#000000'
              }}
            >
              {data?.publishedValue
                ? `${data.publishedValue.toLocaleString()} (${data.publishedPercentage.toFixed(
                    2
                  )}%)`
                : '-'}
            </Typography>
            <Typography
              style={{
                position: 'absolute',
                bottom: '-27px',
                right: 0,
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '27px',
                color: '#000000'
              }}
            >
              {data?.longTailValue
                ? `${data.longTailValue.toLocaleString()} (${data.longTailPercentage.toFixed(2)}%)`
                : '-'}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

ComparisonItem.defaultProps = {
  data: null
};

ComparisonItem.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    publishedValue: PropTypes.number,
    publishedPercentage: PropTypes.number,
    longTailValue: PropTypes.number,
    longTailPercentage: PropTypes.number
  })
};

const PublishedVsLongTail = props => {
  const { loading, error, data } = props;
  const classes = useStyles();

  return (
    <div className={classes.card}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs value={0}>
          <Tab
            label="Published vs. Long Tail"
            classes={{
              root: classes.tabOverRide
            }}
          />
        </Tabs>
        <Box display="flex" mr={24}>
          <Legend
            items={[
              { color: '#C40075', label: 'Published' },
              { color: '#32327D', label: 'Long Tail' }
            ]}
          />
        </Box>
      </Box>

      <Grid container justifyContent="center" className={classes.cardContent}>
        {data == null && (
          <Typography className={classes.primaryText}>
            There is not any data to display at this time.
          </Typography>
        )}
        {data != null &&
          data
            .filter(dataset => dataset.metric !== 'count')
            .map(dataset => {
              const publishedValue = dataset?.publishedValue > 0 ? dataset.publishedValue : 0;
              const longTailValue = dataset?.longTailValue > 0 ? dataset.longTailValue : 0;
              const total = publishedValue + longTailValue;

              if (total === 0) {
                return null;
              }

              const dataProp = {
                publishedValue,
                publishedPercentage: (publishedValue / total) * 100,
                longTailValue,
                longTailPercentage: (longTailValue / total) * 100
              };

              return (
                <Grid
                  key={METRIC_MAP[dataset.metric]}
                  item
                  xs={12}
                  style={{ marginBottom: '38px' }}
                >
                  <ComparisonItem title={METRIC_MAP[dataset.metric]} data={dataProp} />
                </Grid>
              );
            })}
      </Grid>
    </div>
  );
};

PublishedVsLongTail.defaultProps = {
  loading: false,
  error: null,
  data: null
};

PublishedVsLongTail.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape())
};

export default PublishedVsLongTail;
