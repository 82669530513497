import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const AlembicPageTitle = props => {
  const { title, nomargin } = props;
  const style = { fontWeight: '500' };
  if (!nomargin) style.marginBottom = '30px';
  return (
    <Typography color="primary" variant="h1" style={style}>
      {title}
    </Typography>
  );
};

AlembicPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  nomargin: PropTypes.bool
};
AlembicPageTitle.defaultProps = {
  nomargin: false
};

export default AlembicPageTitle;
