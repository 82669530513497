import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import DashboardIntelligenceTable from 'components/DashboardIntelligenceTable';
import CoincidentEventsHeatmap from './CoincidentEventsHeatmap';
import DetectedCalendarHeatmap from './DetectedEventsHeatmap';

const styles = makeStyles({
  componentContainer: {
    paddingBottom: '50px'
  }
});

const DashboardIntelligenceV4 = props => {
  const { currentContainer, selectedDates, setSelectedDates } = props;
  const classes = styles();
  const [isToggleSwitchOn, setIsToggleSwitchOn] = useState(false);

  useEffect(() => {
    if (currentContainer?.new_events_display_default === true) {
      setIsToggleSwitchOn(false);
    }

    if (currentContainer?.new_events_display_default === false) {
      setIsToggleSwitchOn(true);
    }
  }, [currentContainer]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.componentContainer}>
        <CoincidentEventsHeatmap
          isToggleSwitchOn={isToggleSwitchOn}
          selectedDates={selectedDates}
        />
      </Grid>

      <Grid item xs={12} className={classes.componentContainer}>
        <DetectedCalendarHeatmap
          isToggleSwitchOn={isToggleSwitchOn}
          setIsToggleSwitchOn={setIsToggleSwitchOn}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />
      </Grid>

      <Grid item xs={12} className={classes.componentContainer}>
        <DashboardIntelligenceTable startDate={selectedDates.start} endDate={selectedDates.end} />
      </Grid>
    </Grid>
  );
};

DashboardIntelligenceV4.propTypes = {
  currentContainer: PropTypes.shape().isRequired,
  selectedDates: PropTypes.shape().isRequired,
  setSelectedDates: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    currentContainer: state.auth.currentContainer,
    featureFlags: state.auth.featureFlags
  };
};

export default connect(mapStateToProps)(DashboardIntelligenceV4);
