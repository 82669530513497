import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Select, FormControl, MenuItem, Radio, ListItemText } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from 'theme';

const dropdownTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiMenuItem: {
      root: {
        padding: '0px !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1
      }
    }
  }
});

const styles = makeStyles({
  select: {
    margin: 'auto 26px auto auto',
    width: '190px',
    height: '41px'
  },
  label: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#0D1A3A',
    marginRight: '10px'
  }
});

const SegmentsTableDropdown = ({ selectedOption, setSelectedOption }) => {
  const classes = styles();

  const options = [
    { value: 'source', label: 'Source' },
    { value: 'industry', label: 'Industry' },
    { value: 'country', label: 'Country' },
    { value: 'campaign', label: 'Campaign' },
    { value: 'type', label: 'Type' }
  ];

  return (
    <MuiThemeProvider theme={dropdownTheme}>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <span className={classes.label}>Segment:</span>
        <Grid item className={classes.select}>
          <FormControl>
            <Select
              className={classes.select}
              variant="outlined"
              labelId="input-label"
              value={JSON.stringify(selectedOption)}
              renderValue={option => JSON.parse(option).label}
              onChange={e => setSelectedOption(JSON.parse(e.target.value))}
              IconComponent={ExpandMoreIcon}
            >
              {options.map(option => (
                <MenuItem key={option.label} value={JSON.stringify(option)}>
                  <Radio checked={selectedOption.value === option.value} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

SegmentsTableDropdown.propTypes = {
  selectedOption: PropTypes.shape(),
  setSelectedOption: PropTypes.func.isRequired
};

SegmentsTableDropdown.defaultProps = {
  selectedOption: { value: 'source', label: 'Source' }
};

export default SegmentsTableDropdown;
