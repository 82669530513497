import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  impressionsWidget,
  growthWidget,
  engagementWidget,
  storiesWidget
} from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import BoxContentWidget from './BoxContent/BoxContentWidget';

const AnalyticsPerformance = props => {
  const { accounts, dates } = props;

  const filterWidget = widget =>
    widget.filter(({ typeFilter }) => {
      const accountTypes = accounts.map(({ type }) => type);

      return !typeFilter || typeFilter?.some(type => accountTypes.includes(type));
    });

  const filteredImpressionsWidget = filterWidget(impressionsWidget);
  const filteredGrowthWidget = filterWidget(growthWidget);
  const filteredEngagementWidget = filterWidget(engagementWidget);
  const filteredStoriesWidget = filterWidget(storiesWidget);

  return (
    <Grid
      container
      style={{
        width: '100%',
        flexDirection: 'column',
        margin: '25px 15px'
      }}
    >
      {/* 
          widgets display a paired graph and table 
          metrics queried by each section of the widget are assigned in AnalyticsSocialConsts          
        */}
      {!!filteredImpressionsWidget.length && (
        <BoxContentWidget
          tabData={filteredImpressionsWidget}
          accounts={accounts}
          dates={dates}
          prefix="sb"
        />
      )}
      {!!filteredGrowthWidget.length && (
        <BoxContentWidget
          tabData={filteredGrowthWidget}
          accounts={accounts}
          dates={dates}
          prefix="sb"
        />
      )}
      {!!filteredEngagementWidget.length && (
        <BoxContentWidget
          tabData={filteredEngagementWidget}
          accounts={accounts}
          dates={dates}
          prefix="sb"
        />
      )}
      {!!filteredStoriesWidget.length && (
        <BoxContentWidget
          tabData={filteredStoriesWidget}
          accounts={accounts}
          dates={dates}
          prefix="sb"
        />
      )}
    </Grid>
  );
};

AnalyticsPerformance.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default AnalyticsPerformance;
