import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Info } from '@material-ui/icons';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import Box from 'components/Box';
import colors from 'util/colors';

const styles = makeStyles({
  label: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0A1734'
  },
  subLabelContainer: {
    marginLeft: '4px'
  },
  subLabel: {
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#979797'
  }
});

const AlembicInputLabel = props => {
  const {
    isRequired,
    isOptional,
    useTooltip,
    primaryText,
    secondaryText,
    tooltipText,
    useWithIcon,
    iconWidth,
    iconHeight
  } = props;
  const classes = styles();

  return (
    <Grid item container style={{ marginBottom: '8px' }}>
      <Grid item>
        <Box display="flex" alignItems="center">
          {useWithIcon && (
            <img
              src={useWithIcon}
              width={iconWidth}
              height={iconHeight}
              alt="icon"
              style={{ marginRight: '10px' }}
            />
          )}
          <Typography className={classes.label}>{primaryText}</Typography>
          {secondaryText && (
            <Typography
              className={classes.label}
              style={{ fontWeight: '400', fontSize: '13px', color: '#A0A0A0' }}
            >
              &nbsp;{secondaryText}
            </Typography>
          )}
          {useTooltip && (
            <Tooltip title={tooltipText}>
              <Info fontSize="inherit" htmlColor={colors.navy25} style={{ marginLeft: '10px' }} />
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item className={classes.subLabelContainer}>
        {isRequired && <Typography className={classes.subLabel}>(Required)</Typography>}
        {isOptional && <Typography className={classes.subLabel}>(Optional)</Typography>}
      </Grid>
    </Grid>
  );
};

AlembicInputLabel.propTypes = {
  isRequired: PropTypes.bool,
  isOptional: PropTypes.bool,
  useTooltip: PropTypes.bool,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  tooltipText: PropTypes.string,
  useWithIcon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number
};

AlembicInputLabel.defaultProps = {
  isRequired: false,
  isOptional: false,
  useTooltip: false,
  secondaryText: null,
  tooltipText: null,
  useWithIcon: null,
  iconWidth: 20,
  iconHeight: 20
};

export default AlembicInputLabel;
