import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button } from '@material-ui/core';
import Box from 'components/Box';
import modalStyles from 'components/AlembicModalForm/modalStyles';

const styles = makeStyles({
  ...modalStyles,
  text: {
    color: '#0A1734',
    fontSize: '18px',
    fontWeight: 500,
    whiteSpace: 'pre-line'
  }
});

/** Displays on click of feature gated card
 * @param {String} title - card title
 * @param {String} text - text outlining how to enable or request activation of feature
 * @param {Boolean} isModalOpen - controls the state of the modal
 * @param {Function} setIsModalOpen - function to toggle modal state
 * @returns - React JSX
 */

const FeatureModal = props => {
  const { title, text, isModalOpen, setIsModalOpen } = props;
  const classes = styles();

  const onClose = e => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  return (
    <Dialog maxWidth="xs" open={isModalOpen} onClose={onClose}>
      <Box p={20}>
        <Grid item container>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item style={{ marginBottom: '24px', opacity: '50%' }}>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};

FeatureModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired
};

export default FeatureModal;
