import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, ClickAwayListener } from '@material-ui/core';
import { MoreVert as Actions } from '@material-ui/icons';
import Box from 'components/Box';
import ArchiveProgramLiftReportModal from 'components/AlembicModalConfirm/ArchiveProgramLiftReportModal';
import UnarchiveProgramLiftReportModal from 'components/AlembicModalConfirm/UnarchiveProgramLiftReportModal';
import DeleteProgramLiftReportModal from 'components/AlembicModalConfirm/DeleteProgramLiftReportModal';

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    direction: 'rtl',
    textAlign: 'center'
  },
  actions: {
    position: 'absolute',
    alignItems: 'start',
    textAlign: 'left'
  },
  actionsMenu: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: 'white',
    padding: '25px',
    zIndex: 1
  },
  actionsOptions: {
    marginRight: '20px',

    '& div': {
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },

    '& :not(:last-child)': {
      marginBottom: '30px'
    }
  }
});

/**
 * @method
 * @summary This component renders vertical (...) icon with a list of options when clicked @see ProgramLiftReportTable
 * @name ProgramLiftReportActions
 * @param {Object} props - React props passed to this component
 * @param {Object} props.programLiftReport - The object containing the program lift report data
 * @param {Function} props.queryRefetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const ProgramLiftReportActions = props => {
  const { programLiftReport, queryRefetch } = props;
  const [displayedActions, setDisplayedActions] = useState();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const classes = useStyles();

  const actions = [
    // {
    //   label: 'Edit',
    //   action: () => {}
    // }
  ];

  if (programLiftReport.enabled === true) {
    actions.push({
      label: 'Archive',
      action: () => {
        setIsArchiveModalOpen(!isArchiveModalOpen);
      }
    });
  } else {
    actions.push({
      label: 'Unarchive',
      action: () => {
        setIsUnarchiveModalOpen(!isUnarchiveModalOpen);
      }
    });

    actions.push({
      label: 'Delete',
      action: () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }
    });
  }

  return (
    <Box className={classes.actionsWrapper}>
      {displayedActions && (
        <Grid container wrap="nowrap" className={classes.actions}>
          <ClickAwayListener onClickAway={() => setDisplayedActions(false)}>
            <Paper className={classes.actionsMenu}>
              <Grid item className={classes.actionsOptions}>
                {actions.map(action => (
                  <Grid
                    item
                    key={action.label}
                    onClick={e => {
                      e.stopPropagation();
                      action.action();
                      setDisplayedActions(false);
                    }}
                  >
                    {action.label}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Grid>
      )}
      <IconButton
        size="small"
        style={displayedActions ? { zIndex: 2 } : {}}
        onClick={e => {
          e.stopPropagation();
          setDisplayedActions(true);
        }}
      >
        <Actions />
      </IconButton>
      <ArchiveProgramLiftReportModal
        isModalOpen={isArchiveModalOpen}
        onChange={event => {
          setIsArchiveModalOpen(event.isModalOpen);
          if (event?.newProgramLiftReportData) {
            queryRefetch();
          }
        }}
        programLiftReportId={programLiftReport.id}
        programLiftReportName={programLiftReport.name}
      />
      <UnarchiveProgramLiftReportModal
        isModalOpen={isUnarchiveModalOpen}
        onChange={event => {
          setIsUnarchiveModalOpen(event.isModalOpen);
          if (event?.newProgramLiftReportData) {
            queryRefetch();
          }
        }}
        programLiftReportId={programLiftReport.id}
        programLiftReportName={programLiftReport.name}
      />
      <DeleteProgramLiftReportModal
        isModalOpen={isDeleteModalOpen}
        onChange={event => {
          setIsDeleteModalOpen(event.isModalOpen);
          if (event?.newProgramLiftReportData) {
            queryRefetch();
          }
        }}
        programLiftReportId={programLiftReport.id}
        programLiftReportName={programLiftReport.name}
      />
    </Box>
  );
};

ProgramLiftReportActions.propTypes = {
  programLiftReport: PropTypes.shape().isRequired,
  queryRefetch: PropTypes.func.isRequired
};

export default ProgramLiftReportActions;
