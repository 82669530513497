/**
 * @method
 * @summary This function converts a big number to a short abbreviated version
 * @name getValueAbbreviation
 * @param {number} value - The number value
 * @param {boolean} currency - Prefixes with currency symbol (currently set as USD)
 * @param {boolean} compact - Compact display
 * @return {(string|null)} - Returns a short abbreviated number
 */
const getValueAbbreviation = (value, currency = false, compact = true) => {
  if (value == null) {
    return null;
  }

  if (typeof value !== 'number') {
    return null;
  }

  return Intl.NumberFormat('en-US', {
    ...(compact && {
      notation: 'compact',
      compactDisplay: 'short'
    }),
    ...(currency && { style: 'currency', currency: 'USD' })
  }).format(value);
};

export default getValueAbbreviation;
