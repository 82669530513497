import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ARCHIVE_ARTICLE_SEARCH } from 'gql/articleSearch';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const ArchiveArticleSearchModal = props => {
  const { isModalOpen, onChange, articleSearchId, articleSearchName } = props;

  const [archiveArticleSearch, { error, data }] = useMutation(ARCHIVE_ARTICLE_SEARCH);

  useEffect(() => {
    if (data?.disableArticleSearch) {
      showToast(`Report archived`, 'success');
      onChange({
        isModalOpen: false,
        newArticleSearchData: data.disableArticleSearch
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Archive Report"
        body={
          <>
            Are you sure you want to archive the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{articleSearchName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Archive"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => archiveArticleSearch({ variables: { id: articleSearchId } })}
      />
    </>
  );
};

ArchiveArticleSearchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  articleSearchId: PropTypes.string,
  articleSearchName: PropTypes.string
};

ArchiveArticleSearchModal.defaultProps = {
  articleSearchId: null,
  articleSearchName: null
};

export default ArchiveArticleSearchModal;
