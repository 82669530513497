import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Box from 'components/Box';
import StackedIcon from './StackedIcon';

class AccountItem extends Component {
  state = {
    showRemove: false
  };

  showRemove = toggleState => {
    this.setState({ showRemove: toggleState });
  };

  onRemove = () => {
    const {
      data,
      onChange,
      selectedSingleAccounts,
      selectedGroupedAccounts,
      setSelectedGroupAccounts
    } = this.props;

    if (data.linktokens) {
      let singleAccounts = selectedSingleAccounts;

      const accountRemoveList = [];
      const groupNameRemoveList = [];

      singleAccounts.forEach(account => {
        // If they have the same group name
        if (account?.groupNames?.includes(data.name)) {
          if (account.groupNames.length > 1) {
            groupNameRemoveList.push(account.id);
          } else {
            accountRemoveList.push(account.id);
          }
        }
      });

      singleAccounts = singleAccounts.filter(account => !accountRemoveList.includes(account.id));

      for (let i = 0; i < singleAccounts.length; i += 1) {
        if (groupNameRemoveList.includes(singleAccounts[i].id)) {
          singleAccounts[i].groupNames = singleAccounts[i].groupNames.filter(
            groupName => groupName !== data.name
          );
        }
      }

      const remainingGroupedList = selectedGroupedAccounts.filter(
        account => account.name !== data.name
      );

      onChange(singleAccounts);
      setSelectedGroupAccounts(remainingGroupedList);
    } else {
      const filteredSingleList = selectedSingleAccounts.filter(account => account.id !== data.id);

      onChange(filteredSingleList);
    }
  };

  render() {
    const { showRemove } = this.state;
    const { data, iconWidth, iconHeight } = this.props;

    return (
      <ListItem
        divider
        style={{ position: 'relative' }}
        onMouseEnter={() => this.showRemove(true)}
        onMouseLeave={() => this.showRemove(false)}
        onFocus={() => undefined}
        onBlur={() => undefined}
      >
        <ListItemIcon>
          <Box ml={5} mr={20} my={10}>
            <StackedIcon
              accounts={data?.linktokens ? data.linktokens : [data]}
              width={iconWidth}
              height={iconHeight}
            />
          </Box>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle2' }}
          secondaryTypographyProps={{ variant: 'body2', color: 'textPrimary' }}
          primary={data.label}
        />
        <ListItemSecondaryAction onMouseEnter={this.showRemove} onMouseLeave={this.hideRemove}>
          {showRemove && (
            <IconButton aria-label="remove" size="small" onClick={this.onRemove}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

AccountItem.propTypes = {
  data: PropTypes.shape().isRequired,
  selectedSingleAccounts: PropTypes.arrayOf(PropTypes.shape()),
  selectedGroupedAccounts: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  setSelectedGroupAccounts: PropTypes.func,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number
};

AccountItem.defaultProps = {
  selectedSingleAccounts: [],
  selectedGroupedAccounts: [],
  setSelectedGroupAccounts: null,
  iconWidth: null,
  iconHeight: null
};

export default AccountItem;
