import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_MENTION_GROUP_FOR_DRAWER } from 'gql/mention_group';
import Box from 'components/Box';
import SocialLogo from 'components/SocialLogo';
import AnnotationsList from 'components/DetectedEventsTable/AnnotationsList';
import getEventFormatted from 'components/DetectedEventsFormatted';
import { showToast } from 'contexts/ToastContext';
import { NARRATIVE_EVENT_TYPES } from 'util/detectedEvents';

const useStyles = makeStyles({
  container: {
    padding: '28px 25px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    width: 'auto',
    height: 'auto'
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px'
  },
  logoContainer: {
    marginRight: '20px'
  },
  eventText: {
    fontSize: '18px',
    lineHeight: '27px',
    fontFamily: 'Poppins',

    '& strong': {
      color: '#32327D'
    }
  },
  snippetText: {
    fontSize: '14px',
    fontFamily: 'Poppins'
  }
});

/**
 * @method
 * @summary The header section of @DashboardIntelligenceDrawer
 * @name IntelligenceDrawerHeader
 * @param { Function } toggleDrawer - callback function that controls the closing of the drawer via the 'x' icon
 * @param { String } headerTitle - the description of the event passed down from the event object.
 * @param { Object } event - detected event object needed to build the event details string
 * @param { String } logoType - detected event object needed to build the event details string
 * @param { Function } setGraphDates - Function to conditionally pass dates to the drawer graph
 * @return {Object} - React JSX
 */

const IntelligenceDrawerHeader = ({
  toggleDrawer,
  headerTitle,
  event,
  logoType,
  noEventText,
  isCorrelation,
  setGraphDates
}) => {
  const classes = useStyles();

  const annotationsStart = isCorrelation ? event.coinc_start_date : event.startDate;
  const annotationsEnd = isCorrelation ? event.coinc_end_date : event.endDate;

  const [transcriptSnippet, setTranscriptSnippet] = useState('');

  const [getMentionGroupForDrawer, { loading, error, data }] = useLazyQuery(
    GET_MENTION_GROUP_FOR_DRAWER,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    // conditional transcript pull for narrative events
    if (NARRATIVE_EVENT_TYPES.includes(event?.type)) {
      const mentionGroupId = event?.originalObject?.secondary_id;

      // pull transcript and air dates
      getMentionGroupForDrawer({ variables: { id: mentionGroupId } });
    }

    return () => {
      setTranscriptSnippet('');
      setGraphDates(null);
    };
  }, []);

  useEffect(() => {
    if (data?.getMentionGroup) {
      setTranscriptSnippet(data?.getMentionGroup?.highlight_mention_text);
      setGraphDates(data?.getMentionGroup?.air_dates_druid || {});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showToast('Error retrieving narrative.', 'error');
    }
  }, [error]);

  return (
    <Grid container className={classes.container} direction="column">
      <Box pb={2.5}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography color="primary" className={classes.title}>
            {headerTitle}
          </Typography>
          <IconButton onClick={() => toggleDrawer(false)}>
            <Close />
          </IconButton>
        </Grid>
      </Box>
      <Box pb={2.5}>
        <Grid container wrap="nowrap" alignItems="center">
          {logoType && (
            <Grid item className={classes.logoContainer}>
              <SocialLogo type={logoType} width={40} height={40} />
            </Grid>
          )}
          {!noEventText && (
            <Grid item className={classes.eventText}>
              {getEventFormatted(event)}
            </Grid>
          )}
        </Grid>
      </Box>
      {!loading && transcriptSnippet.length > 0 && (
        <Box pb={2.5}>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item className={classes.logoContainer} style={{ minWidth: '40px' }} />
            <Grid item className={classes.snippetText}>
              &quot;{transcriptSnippet}&quot;
            </Grid>
          </Grid>
        </Box>
      )}
      <AnnotationsList startDate={annotationsStart} endDate={annotationsEnd} includeTitle />
    </Grid>
  );
};

IntelligenceDrawerHeader.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  event: PropTypes.shape().isRequired,
  headerTitle: PropTypes.string.isRequired,
  logoType: PropTypes.string,
  noEventText: PropTypes.bool,
  isCorrelation: PropTypes.bool,
  setGraphDates: PropTypes.func
};

IntelligenceDrawerHeader.defaultProps = {
  logoType: null,
  noEventText: false,
  isCorrelation: false,
  setGraphDates: () => {}
};

export default IntelligenceDrawerHeader;
