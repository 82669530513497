import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_SESSIONS, KILL_SESSION } from 'gql/user_sessions';

import { Grid, Typography, Box, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AlbLoading from 'components/AlbLoading';
import moment from 'moment';
import { CheckCircle } from '@material-ui/icons';
import { showToast } from 'contexts/ToastContext';
import { connect } from 'react-redux';
import { ChangePasswordPath } from '../../util/paths';
import AlbError from '../AlbError/AlbError';

const styles = makeStyles({
  sectionHeader: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'left',
    letterSpacing: '0.03em'
  },
  tableHeader: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
    textTransform: 'uppercase',
    color: '#0A1734'
  },
  rowText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '20px'
  },
  divider: {
    height: '14px',
    background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
    mixBlendMode: 'multiply',
    paddingTop: '14px',
    baddingBottom: '14px'
  },
  dimBg: {
    background: 'rgba(151, 151, 151, 0.1)'
  },
  checkCircle: {
    color: '#4DA457'
  }
});

const StyledButton = withStyles({
  root: {
    backgroundColor: '#fff',

    color: '#0A1734',
    '&:hover': {
      backgroundColor: '#bb0000',
      color: '#fff',
      borderColor: '#bb0000'
    }
  }
})(Button);

const UserSessionManager = props => {
  const { currentContainer } = props;
  const classes = styles();

  const { loading, data, refetch, error } = useQuery(GET_USER_SESSIONS);
  const [killSession, { loading: killLoading }] = useMutation(KILL_SESSION);

  const handleClick = async id => {
    await killSession({
      variables: {
        sessionId: id
      }
    })
      .then(() => {
        showToast('Session sucessfully signed out', 'success', true);
        refetch();
      })
      .catch(() => {
        showToast(
          'There was an error signing out of this session. Please try again.',
          'error',
          true
        );
      });
  };

  if (loading) {
    return <AlbLoading />;
  }

  if (error) {
    return <AlbError error={error} />;
  }

  let rows;
  let sessionItems = [];

  if (data?.getActiveSessions?.length > 0) {
    rows = data?.getActiveSessions;

    let count = 0;

    sessionItems = rows.map(row => {
      const lastSeen = moment(row?.updated_at).fromNow();
      // {"type":"Desktop","browser":"Chrome","engine":"Webkit","version":"111.0.0.0",
      // "os":"Mac","userAgent":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7)
      // AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36"}

      const rowColor = count % 2 === 0 ? '' : classes.dimBg;
      count += 1;

      const location =
        row.city_name && row.region_name && row.country_code
          ? `${row.city_name}, ${row.region_name}, ${row.country_code}`
          : 'Location not available';

      return (
        <Fragment key={`row-${row.id}`}>
          <Grid item xs={5} key={`1-${row.id}`} className={rowColor}>
            <Typography className={classes.rowText}>
              <b>{row.browser?.os}</b> <br />
              {row.browser?.browser} <br />
              {location}
            </Typography>
            {row.yours && (
              <Box pt={1}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  <CheckCircle className={classes.checkCircle} />
                  &nbsp;<span>Your current session</span>
                </div>
              </Box>
            )}
          </Grid>
          <Grid item xs={4} key={`2-${row.id}`} className={rowColor}>
            <Typography className={classes.rowText}>{lastSeen}</Typography>
          </Grid>
          <Grid item xs={3} key={`3-${row.id}`} className={rowColor}>
            {!row.yours && (
              <StyledButton
                variant="outlined"
                color="primary"
                onClick={() => handleClick(row.session_id)}
                disabled={killLoading}
              >
                Sign Out
              </StyledButton>
            )}
          </Grid>
        </Fragment>
      );
    });
  }

  if (loading) {
    return <AlbLoading />;
  }

  return (
    <>
      <Box mb={2}>
        <Typography className={classes.sectionHeader}>Where you&apos;re signed in</Typography>
      </Box>

      <Box mt={2} mb={2}>
        <Typography className={classes.rowText}>
          You&apos;re signed in to your account from the following devices and locations. If you
          suspect your account has been compromised, you can{' '}
          <a href={`/${currentContainer.id}${ChangePasswordPath}`}>change your password</a> and sign
          out of all other sessions.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={5}>
          <span className={classes.tableHeader}>DEVICE</span>
        </Grid>
        <Grid item xs={4}>
          <span className={classes.tableHeader}>LAST SEEN</span>
        </Grid>
        <Grid item xs={3}>
          <span className={classes.tableHeader}>ACTION</span>
        </Grid>

        <Grid item xs={12} className={classes.divider} />
        {sessionItems}
      </Grid>
    </>
  );
};

UserSessionManager.propTypes = {
  currentContainer: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentContainer: state.auth.currentContainer
  };
};

export default connect(mapStateToProps)(UserSessionManager);
