// External Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { ArrowDropUp as Arrow } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import Carousel from '@brainhubeu/react-carousel';

// Utilities
import getCarouselPlugins from 'util/carouselPlugins';
import formatValueWithSymbol from 'util/formatValueWithSymbol';

// GraphQL
import { GET_SALESFORCE_TOP_INSIGHTS } from 'gql/salesforce';

// Components
import AlbError from 'components/AlbError';
import AlbLoading from 'components/AlbLoading';
import Box from 'components/Box';

import formatSalesforceLabel from './formatSalesforceLabel';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    minHeight: '242px'
  },
  box: {
    margin: '0px 15px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    height: '100%',
    width: '100%',
    padding: '24px'
  },
  arrow: {
    height: '40px',
    width: '40px',
    margin: '-5px'
  },
  carousel: {
    '& li': {
      padding: '0 10px'
    },
    '& button': {
      padding: '0',
      '& svg': {
        height: '42px',
        width: '42px'
      }
    },
    '& button:hover': {
      opacity: 1
    }
  },
  label: {
    fontSize: '30px',
    color: '#0A1734',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'center'
  },
  value: {
    fontSize: '30px',
    color: '#0A1734',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'center'
  },
  percent_change: {
    fontSize: '11px',
    display: 'flex',
    alignItems: 'center'
  },
  column: {
    borderRight: '1px solid #000'
  },
  title: {
    marginBottom: '40px'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const percentTypes = ['TOP_CLOSE_RATE'];

const SalesforceTopInsights = props => {
  const { linktokenId, startDate, endDate, selectedPeriodical } = props;
  const classes = useStyles();

  const [topInsights, setTopInsights] = useState([]);

  const { loading, data, error } = useQuery(GET_SALESFORCE_TOP_INSIGHTS, {
    variables: {
      linktokenId,
      startDate,
      endDate,
      periodical: selectedPeriodical
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data?.getSalesforceTopInsights.length) {
      const typeMapping = {
        TOP_REVENUE: 'Most Revenue',
        TOP_VOLUME: 'Biggest Volume',
        TOP_DEAL_SIZE: 'Largest Deal Size',
        TOP_CLOSE_RATE: 'Highest Close Rate'
      };

      const mappedInsights = data?.getSalesforceTopInsights.map(item => ({
        ...item,
        title: typeMapping[item.type] || 'Unknown'
      }));

      setTopInsights(mappedInsights);
    }
  }, [data]);

  return (
    <Grid item xs={12} container className={classes.container}>
      {error && <AlbError error={error} />}
      {loading && (
        <Grid item className={classes.loadingOverlay}>
          <AlbLoading />
        </Grid>
      )}

      {!error && !loading && (
        <Carousel
          className={classes.carousel}
          draggable={false}
          plugins={getCarouselPlugins(2, false)}
        >
          {topInsights.map(insight => (
            <Box key={insight.title} px={4} className={classes.box}>
              <Typography
                align="center"
                variant="h6"
                component="h2"
                className={classes.title}
                gutterBottom
              >
                {insight.title}
              </Typography>

              {/* Using Grid to create two columns for label and value */}
              <Grid container spacing={2} justifyContent="center">
                <Grid
                  className={classes.column}
                  item
                  xs={6}
                  container
                  direction="column"
                  alignItems="center"
                >
                  <Typography className={classes.label}>
                    {formatSalesforceLabel(insight).length ? formatSalesforceLabel(insight) : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6} container direction="column" alignItems="center">
                  <Typography className={classes.value}>
                    {formatValueWithSymbol(insight.value, {
                      usePostfixSymbol: percentTypes.includes(insight.type)
                    })}
                  </Typography>
                  {!!insight.value_difference && (
                    <Typography className={classes.percent_change} align="center">
                      <Arrow
                        className={classes.arrow}
                        style={{
                          ...(insight.value_difference > 0 && { fill: '#00A84F' }),
                          ...(insight.value_difference < 0 && {
                            transform: 'rotate(180deg)',
                            fill: 'red'
                          })
                        }}
                      />
                      {insight.value_percent_change}% &nbsp;
                      {formatValueWithSymbol(insight.value_difference, {
                        usePositive: true,
                        useNegative: true
                      })}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Carousel>
      )}
    </Grid>
  );
};

SalesforceTopInsights.propTypes = {
  linktokenId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedPeriodical: PropTypes.string
};

SalesforceTopInsights.defaultProps = {
  linktokenId: null,
  startDate: null,
  endDate: null,
  selectedPeriodical: null
};

export default SalesforceTopInsights;
