import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Dialog } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { PROGRAM_LIFT_REPORT } from 'gql/programLiftReport';
import setPageTitle from 'util/titles';
import { PROGRAM_LIFT_CARD_TITLE } from 'util/analyticsTitles';
import AnalyticsHeader from 'components/AnalyticsComponents/AnalyticsHeader';
import AnalyticsContainer from 'components/AnalyticsComponents/AnalyticsContainer';
import AnalyticsPageTitle from 'components/AnalyticsComponents/AnalyticsPageTitle';
import AnalyticsCloseButton from 'components/AnalyticsComponents/AnalyticsCloseButton';
import AlbSwitchOption from 'components/AlbSwitch/AlbSwitchOption';
import DatePickerV2 from 'components/AnalyticsDatePicker/DatePickerV2';
import {
  ONE_MONTH,
  ONE_QUARTER,
  ONE_YEAR,
  LAST_90_DAYS,
  LAST_180_DAYS,
  LAST_365_DAYS
} from 'components/AnalyticsDatePicker/datePickerRanges';
import Box from 'components/Box';
import ProgramLiftQuad from './ProgramLiftQuad';
import ProgramLiftBreakDown from './ProgramLiftBreakDown';

const useStyle = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  },
  analyticsHeader: {
    width: '100%'
  },
  programLiftBreakDown: {
    padding: '32px'
  },
  datePicker: {
    marginRight: '50px'
  },
  orgName: {
    marginRight: '20px'
  },
  closeButton: {
    position: 'absolute',
    top: '40px',
    right: '40px'
  },
  printButton: {
    position: 'absolute',
    top: '100px',
    right: '30px'
  },
  '@media print': {
    analyticsHeader: {
      display: 'none'
    },
    programLiftReport: {
      background: '#FFF'
    },
    programLiftBreakDown: {
      display: 'none'
    }
  }
});

const AnalyticsProgramLiftReport = props => {
  const { match } = props;
  const classes = useStyle();
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedPeriodical, setSelectedPeriodical] = useState(null);
  const [isSwitchChecked, setIsSwitchChecked] = useState(true);
  const { data } = useQuery(PROGRAM_LIFT_REPORT, {
    variables: {
      id: match?.params?.reportId
    },
    skip: match?.params?.reportId == null
  });

  useEffect(() => {
    setPageTitle(PROGRAM_LIFT_CARD_TITLE);
  }, []);

  return (
    <Dialog fullScreen open classes={{ paperFullScreen: classes.root }}>
      <Grid container justifyContent="center" className={classes.programLiftReport}>
        <div className={classes.analyticsHeader}>
          <AnalyticsHeader>
            <Grid container justifyContent="space-between" alignItems="flex-start">
              <Box display="flex">
                <Box className={classes.orgName}>
                  <AnalyticsContainer />
                </Box>
                <AnalyticsPageTitle title={PROGRAM_LIFT_CARD_TITLE} />
              </Box>
              <AlbSwitchOption
                leftOption="Show Dimensions"
                rightOption="Hide Dimensions"
                onChange={event => {
                  setIsSwitchChecked(event.checked);
                }}
                checked
              />

              {data?.programLiftReport?.id != null && (
                <DatePickerV2
                  handleDateSelect={event => {
                    setSelectedDates({
                      start: event.start,
                      end: event.end
                    });

                    if (event?.periodical) {
                      setSelectedPeriodical(event.periodical);
                    }
                  }}
                  ranges={[
                    ONE_MONTH,
                    ONE_QUARTER,
                    ONE_YEAR,
                    LAST_90_DAYS,
                    LAST_180_DAYS,
                    LAST_365_DAYS
                  ]}
                  defaultSelectedRangeIndex={4}
                />
              )}

              <Box className={classes.closeButton}>
                <AnalyticsCloseButton />
              </Box>

              <Button
                className={classes.printButton}
                variant="contained"
                color="primary"
                onClick={() => window.print()}
              >
                <Print />
              </Button>
            </Grid>
          </AnalyticsHeader>
        </div>

        <ProgramLiftQuad
          reportId={match?.params?.reportId}
          selectedDates={selectedDates}
          selectedPeriodical={selectedPeriodical}
          isSwitchChecked={isSwitchChecked}
        />

        <Box className={classes.programLiftBreakDown}>
          <ProgramLiftBreakDown
            selectedDates={selectedDates}
            selectedPeriodical={selectedPeriodical}
            isSwitchChecked={isSwitchChecked}
            programLiftReportId={match?.params?.reportId}
          />
        </Box>
      </Grid>
    </Dialog>
  );
};

AnalyticsProgramLiftReport.propTypes = {
  match: PropTypes.shape().isRequired
};

export default AnalyticsProgramLiftReport;
