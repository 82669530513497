import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog } from '@material-ui/core';
import AnalyticsHeader from 'components/AnalyticsHeader';
import AnalyticsPlaceholder from 'components/AnalyticsPlaceholder';
import SubheaderTabs from 'components/AnalyticsHeader/SubheaderTabs';
import { tabs, hypeAndFlowSubTabs } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import { parseQueryString, setQueryStringForAnalyticsModal, goToRoute } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import {
  getInitialAnalyticsStartDate,
  getInitialAnalyticsEndDate,
  checkValidDate
} from 'util/date';
import { AnalyticsPath } from 'util/paths';
import { socialBroadcastPlatforms } from 'util/social';
import AnalyticsFlow from './AnalyticsFlow';
import AnalyticsHype from './AnalyticsHype';

const style = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  }
});

const AnalyticsHypeAndFlow = props => {
  const { currentUser, history, location } = props;

  const classes = style();

  const [activeLowerTab, setActiveLowerTab] = useState(tabs.flow);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [placeholder, setPlaceholder] = useState(false); // this is set to true if no accounts are linked or selected within the current container
  // dates are passed into graphs separately and are updated through this state call.
  const [calRange, changeCalRange] = useState({
    start: getInitialAnalyticsStartDate(14, currentUser, 'days'),
    end: getInitialAnalyticsEndDate(currentUser)
  });
  const dialogRef = useRef();

  // After component has mounted check the URL parameters for a different start/end date than the default
  useEffect(() => {
    setPageTitle('Hype & Flow Analytics');

    if (location?.search) {
      const startDate = parseQueryString('start_date', location);
      const endDate = parseQueryString('end_date', location);

      if (startDate[0] && endDate[0]) {
        const newStartDate = new Date(moment(startDate[0]));
        const newEndDate = new Date(moment(endDate[0]));

        if (
          checkValidDate(newStartDate) &&
          checkValidDate(newEndDate) &&
          newStartDate < newEndDate
        ) {
          // If the dates do not match then set the date picker range to the dates from the URL
          if (calRange.start !== newStartDate || calRange.end !== newEndDate) {
            changeCalRange({ start: newStartDate, end: newEndDate });
          }
        }
      }
    }
  }, []);

  // Update URL parameters when selected accounts changes
  // Update URL parameters when date range changes
  useEffect(() => {
    if (selectedAccounts?.length) {
      setQueryStringForAnalyticsModal('account', [selectedAccounts.map(({ id }) => id)]);
    }

    setQueryStringForAnalyticsModal('start_date', [moment(calRange.start).format('YYYY-MM-DD')]);
    setQueryStringForAnalyticsModal('end_date', [moment(calRange.end).format('YYYY-MM-DD')]);
  }, [selectedAccounts, calRange]);

  return (
    <HasAnyPriv
      privs={[
        'SVC:CHAN:STATS',
        'SVC:CHAN:STATS:LI',
        'SVC:CHAN:STATS:FB',
        'SVC:CHAN:STATS:TW',
        'SVC:CHAN:STATS:IN',
        'SVC:CHAN:STATS:EM',
        'SVC:CHAN:STATS:DM'
      ]}
      showAlert
    >
      <Dialog fullScreen open classes={{ paperFullScreen: classes.root }} ref={dialogRef}>
        <Grid container justifyContent="center">
          <AnalyticsHeader
            displayTitle="Hype &amp; Flow"
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            setPlaceholder={setPlaceholder}
            calRange={calRange}
            changeCalRange={changeCalRange}
            defaultButtonLabel="14"
            onClose={() => goToRoute(AnalyticsPath, history)}
            dialogRef={dialogRef}
            location={location}
            platformFilter={socialBroadcastPlatforms.map(({ name }) => name)}
          />
          <SubheaderTabs
            tabValues={hypeAndFlowSubTabs}
            activeTab={activeLowerTab}
            callback={setActiveLowerTab}
          />
          <Grid item xs={9}>
            <Box my={50}>
              {selectedAccounts?.length > 0 && (
                <>
                  {activeLowerTab === tabs.flow && (
                    <AnalyticsFlow accounts={selectedAccounts} dates={calRange} />
                  )}
                  {activeLowerTab === tabs.hype && (
                    <AnalyticsHype accounts={selectedAccounts} dates={calRange} />
                  )}
                </>
              )}
              {placeholder && <AnalyticsPlaceholder history={history} />}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </HasAnyPriv>
  );
};

AnalyticsHypeAndFlow.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(AnalyticsHypeAndFlow);
