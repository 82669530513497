import { gql } from '@apollo/client';

export const POST_RESURGE_IMPRESSION = gql`
  query postResurgeImpression(
    $linktoken_id: ID!
    $post_id: ID!
    $post_date: String!
    $begin_date: String!
    $end_date: String!
  ) {
    postResurgeImpression(
      linktoken_id: $linktoken_id
      post_id: $post_id
      post_date: $post_date
      begin_date: $begin_date
      end_date: $end_date
    ) {
      peak_count_diff
      is_complete
      event_relative_rank
      follower_growth
    }
  }
`;

export const PAGINATE_POST_RESURGE_IMPRESSION = gql`
  query paginatePostResurgeImpression($orderBy: [String], $count: Int, $after: Int) {
    paginatePostResurgeImpression(orderBy: $orderBy, count: $count, after: $after) {
      rows {
        linktoken_id
        peak_count_diff
        is_complete
        event_relative_rank
        begin_date
        end_date
        campaign_event {
          id
          completed_at
          valuation
        }
        linktoken {
          id
          remote_name
          type
        }
      }
      count
    }
  }
`;
