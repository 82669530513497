import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import {
  SOCIAL_IMPACT_METRIC_BREAKDOWN,
  SOCIAL_IMPACT_POST_METRIC_BREAKDOWN
} from 'gql/analyticsSocialImpact';
import { getDatesForAnalytics } from 'util/date';
import FlowMetricDistribution from 'components/AnalyticsHypeAndFlow/Graphs/FlowMetricDistribution';
import PerformanceSummary from './PerformanceSummary';
import PublishedVsLongTail from './PublishedVsLongTail';
import PublishedVsLongTailGraphAndTable from './PublishedVsLongTailGraphAndTable';

const SocialImpactOverview = props => {
  const { selectedAccounts, selectedDates } = props;
  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );
  const { loading, error, data } = useQuery(SOCIAL_IMPACT_METRIC_BREAKDOWN, {
    variables: {
      linkTokens: selectedAccounts.map(({ id }) => id),
      startDate,
      endDate
    }
  });

  const { loading: loadingPost, error: errorPost, data: dataPost } = useQuery(
    SOCIAL_IMPACT_POST_METRIC_BREAKDOWN,
    {
      variables: {
        linkTokens: selectedAccounts.map(({ id }) => id),
        startDate,
        endDate
      }
    }
  );

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item sm={12} md={7}>
        <PerformanceSummary
          loading={loading || loadingPost}
          error={error || errorPost}
          data={data?.socialImpactMetricBreakdown}
          dataPost={dataPost?.socialImpactPostMetricBreakdown}
        />
      </Grid>
      <Grid container item sm={12} md={5}>
        <FlowMetricDistribution accounts={selectedAccounts} dates={selectedDates} />
      </Grid>

      <Grid container item xs={12}>
        <PublishedVsLongTail
          loading={loading}
          error={error}
          data={data?.socialImpactMetricBreakdown}
        />
      </Grid>

      <Grid container item xs={12}>
        <PublishedVsLongTailGraphAndTable
          selectedAccounts={selectedAccounts}
          selectedDates={selectedDates}
        />
      </Grid>
    </Grid>
  );
};

SocialImpactOverview.propTypes = {
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDates: PropTypes.shape().isRequired
};

export default SocialImpactOverview;
