import { gql } from '@apollo/client';

export const ATTENTION_ACTION_QUAD = gql`
  query attentionActionQuad(
    $endDate: DateTimeISO
    $periodical: PeriodicalEnum
    $hideDimensions: Boolean!
    $showFilteredEvents: Boolean!
  ) {
    attentionActionQuad(
      endDate: $endDate
      periodical: $periodical
      hideDimensions: $hideDimensions
      showFilteredEvents: $showFilteredEvents
    ) {
      events {
        event_type
        event_label
        brand_equity_impact_score
        conversion_impact_score
        demand_generation_impact_score
        audience_interaction_impact_score
        sum_mix_group_impact_scores
        graph {
          x
          y
        }
        ugc_label
        associations(hideDimensions: $hideDimensions) {
          associated_event_type
          associated_event_label
          score
        }
      }
    }
  }
`;

export const ATTENTION_ACTION_TOP_EVENTS_BY_CATEGORY = gql`
  query attentionActionTopEventsByCategory(
    $endDate: DateTimeISO!
    $periodical: PeriodicalEnum!
    $hideDimensions: Boolean!
    $showFilteredEvents: Boolean!
  ) {
    attentionActionTopEventsByCategory(
      endDate: $endDate
      periodical: $periodical
      hideDimensions: $hideDimensions
      showFilteredEvents: $showFilteredEvents
    ) {
      brandEquity {
        event_type
        event_label
        impact_score
        ugc_label
      }
      demandGeneration {
        event_type
        event_label
        impact_score
        ugc_label
      }
      audienceInteraction {
        event_type
        event_label
        impact_score
        ugc_label
      }
      conversion {
        event_type
        event_label
        impact_score
        ugc_label
      }
    }
  }
`;

export const ATTENTION_ACTION_EVENT_TABLE = gql`
  query attentionActionEventTable(
    $mixGroup: String!
    $endDate: DateTimeISO!
    $periodical: PeriodicalEnum!
    $after: Int!
    $count: Int!
    $orderBy: [String]!
    $hideDimensions: Boolean!
    $programLiftReportId: Int
    $inProgramLiftReport: Boolean
    $showFilteredEvents: Boolean!
  ) {
    attentionActionEventTable(
      mixGroup: $mixGroup
      endDate: $endDate
      periodical: $periodical
      after: $after
      count: $count
      orderBy: $orderBy
      hideDimensions: $hideDimensions
      programLiftReportId: $programLiftReportId
      inProgramLiftReport: $inProgramLiftReport
      showFilteredEvents: $showFilteredEvents
    ) {
      rows {
        id
        event_type
        event_label
        occurrence_score
        contribution_score
        variation_score
        magnitude_score
        impact_score
        leverage_score
        percent_change
        change
        graphData {
          brand_equity_impact_score
          conversion_impact_score
          demand_generation_impact_score
          audience_interaction_impact_score
        }
        associations(endDate: $endDate, periodical: $periodical, hideDimensions: $hideDimensions) {
          id
          associated_event_type
          associated_event_label
          score
        }
        ugc_label
        graphData {
          event_type
          event_label
          brand_equity_impact_score
          conversion_impact_score
          demand_generation_impact_score
          audience_interaction_impact_score
          associations {
            associated_event_type
            associated_event_label
            score
          }
        }
      }
      count
    }
  }
`;

export const ATTENTION_ACTION_DETECTED_EVENTS = gql`
  query attentionActionScore(
    $id: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $orderBy: [String]!
  ) {
    attentionActionScore(id: $id) {
      detectedEvents(startDate: $startDate, endDate: $endDate, orderBy: $orderBy) {
        event_start_date
        event_end_date
        magnitude
        mag_unit
        eventInfo {
          postBody
          sourceName
        }
        campaignEvent {
          published_url
        }
        graph(startDate: $startDate, endDate: $endDate) {
          timeseries {
            x
            y
          }
        }
      }
    }
  }
`;

export const ATTENTION_ACTION_CREATE_UGC_LABEL = gql`
  mutation attentionActionCreateUgcLabel($actionAttentionId: ID!, $title: String!) {
    attentionActionCreateUgcLabel(actionAttentionId: $actionAttentionId, title: $title) {
      message
      result
    }
  }
`;

export const ATTENTION_ACTION_UPDATE_UGC_LABEL = gql`
  mutation attentionActionUpdateUgcLabel($actionAttentionId: ID!, $title: String!) {
    attentionActionUpdateUgcLabel(actionAttentionId: $actionAttentionId, title: $title) {
      message
      result
    }
  }
`;

export const ATTENTION_ACTION_DELETE_UGC_LABEL = gql`
  mutation attentionActionDeleteUgcLabel($actionAttentionId: ID!) {
    attentionActionDeleteUgcLabel(actionAttentionId: $actionAttentionId) {
      message
      result
    }
  }
`;

export const ATTENTION_ACTION_CREATE_LABEL_FILTER = gql`
  mutation attentionActionCreateLabelFilter($event_type: String!, $event_label: String!) {
    attentionActionCreateLabelFilter(event_type: $event_type, event_label: $event_label) {
      message
      result
    }
  }
`;

export const ATTENTION_ACTION_DELETE_LABEL_FILTER = gql`
  mutation attentionActionDeleteLabelFilter($event_type: String!, $event_label: String!) {
    attentionActionDeleteLabelFilter(event_type: $event_type, event_label: $event_label) {
      message
      result
    }
  }
`;

export const ATTENTION_ACTION_GET_LABEL_FILTERS = gql`
  query paginatedAttentionActionLabelFilters($after: Int, $count: Int, $orderBy: [String]) {
    paginatedAttentionActionLabelFilters(after: $after, count: $count, orderBy: $orderBy) {
      rows {
        id
        event_type
        event_label
      }
      count
    }
  }
`;
