const LINKTOKEN_TYPE_ENUM = {
  AA_USER: 'AA_USER',
  AA_GLOBAL_COMPANY: 'AA_GLOBAL_COMPANY',
  AA_REPORT_SUITE: 'AA_REPORT_SUITE',
  AA_SEGMENT: 'AA_SEGMENT',
  FB_PROFILE: 'FB_PROFILE',
  FB_PAGE: 'FB_PAGE',
  FB_GROUP: 'FB_GROUP',
  GOOGLE_PROFILE: 'GOOGLE_PROFILE',
  GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
  GOOGLE_WEBPROPERTY_G3: 'GOOGLE_WEBPROPERTY_G3',
  GOOGLE_WEBPROPERTY_G4: 'GOOGLE_WEBPROPERTY_G4',
  GOOGLE_VIEW: 'GOOGLE_VIEW',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN_PROFILE: 'LINKEDIN_PROFILE',
  LINKEDIN_COMPANY: 'LINKEDIN_COMPANY',
  PINTEREST: 'PINTEREST',
  SENDGRID: 'SENDGRID',
  SPARKPOST: 'SPARKPOST',
  TWITTER: 'TWITTER',
  WORDPRESS: 'WORDPRESS',
  YOUTUBE: 'YOUTUBE',
  SALESFORCE: 'SALESFORCE',
  TIKTOK: 'TIKTOK',
  OTHER: 'OTHER'
};

export default LINKTOKEN_TYPE_ENUM;
