/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { convertToUserTimeZone } from 'util/date';
import OverviewCard from './OverviewCard';

/**
 * @method
 * @summary This component renders card at the top of @see MultiPostResurgence
 * @name Overview
 * @return {Object} - React JSX
 */
const Overview = props => {
  const { currentUser, linktokenResurgeImpression, halfDrawerView } = props;
  const [overviewData, setOverviewData] = useState({});

  useEffect(() => {
    if (linktokenResurgeImpression) {
      const {
        begin_date,
        end_date,
        peak_excess_count,
        is_complete,
        valuation,
        follower_growth
      } = linktokenResurgeImpression;

      let surgeStart;
      let surgeEnd;
      let showing;
      let surge;
      let isComplete;
      let followerGrowth;
      let impressionChange;
      let length;

      if (begin_date != null) {
        surgeStart = convertToUserTimeZone(begin_date, currentUser.time_zone).format('ll');
      }

      if (end_date != null) {
        surgeEnd = convertToUserTimeZone(end_date, currentUser.time_zone).format('ll');
      }

      if (begin_date != null && end_date != null) {
        showing = `${convertToUserTimeZone(begin_date, currentUser.time_zone)
          .subtract(3, 'days')
          .format('ll')} - ${convertToUserTimeZone(end_date, currentUser.time_zone)
          .add(3, 'days')
          .format('ll')}`;

        const numberOfDays = Math.abs(moment(begin_date).diff(moment(end_date), 'd'));

        length = `${numberOfDays} Day${numberOfDays === 1 ? '' : 's'}`;
      }

      if (is_complete != null) {
        isComplete = is_complete ? 'Yes' : 'No';
      }

      if (follower_growth != null) {
        let symbol = '';

        if (follower_growth > 0) {
          symbol = '+';
        }

        if (follower_growth < 0) {
          symbol = '-';
        }

        followerGrowth = `${symbol}${Math.abs(follower_growth)}`;
      }

      setOverviewData({
        surgeStart,
        surgeEnd,
        showing,
        valuation,
        bonusImpressions: peak_excess_count,
        surge,
        isComplete,
        followerGrowth,
        impressionChange,
        length
      });
    }
  }, [linktokenResurgeImpression]);

  return <OverviewCard {...overviewData} halfDrawerView={halfDrawerView} />;
};

Overview.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  linktokenResurgeImpression: PropTypes.shape().isRequired,
  halfDrawerView: PropTypes.bool
};

Overview.defaultProps = {
  halfDrawerView: false
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(Overview);
