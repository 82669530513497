/* eslint-disable camelcase */
import React from 'react';

import { Tooltip, Typography } from '@material-ui/core';

import { showToast } from 'contexts/ToastContext';
import SocialLogo from 'components/SocialLogo';

import AlembicAvatar from 'components/AlembicAvatar';
import { getPageUrl } from 'util/externalLinks';
import getSimpleSocialType from 'util/getSimpleSocialType';

import AlbLoading from 'components/AlbLoading';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';

import AccountMenu from './AccountMenu';
import Stem from './Stem';

export const customChannelRender = channel => {
  const onChannelClick = () => {
    const externalUrl = getPageUrl({
      type: channel.type,
      remote_id: channel.remote_id,
      remote_name: channel.remote_name
    });

    if (externalUrl) {
      window.open(externalUrl);
    } else {
      showToast("This account doesn't have a public URL that we can link to.", 'error');
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onChannelClick();
        }
      }}
      onClick={() => {
        onChannelClick();
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        },
        opacity: channel.isEnabled ? 1 : 0.6,
        ...(channel.depth === 0 && { padding: '18px 0px' })
      }}
    >
      {Array.from(Array(channel.depth)).map((_, index) => {
        return (
          <Stem
            // eslint-disable-next-line react/no-array-index-key
            key={`${channel.id}-${index}`}
            indentPosition={channel.depth - index}
            isLast={channel.depth - 1 === index}
            hasSibling={channel.hasSibling}
          />
        );
      })}
      <SocialLogo width={40} height={40} type={getSimpleSocialType(channel.type)} />
    </div>
  );
};

export const customNameRender = token => {
  return (
    <Tooltip title={`Linked on: ${token.created_at}`} placement="left-start">
      <div style={{ display: 'flex', alignItems: 'center', opacity: token.isEnabled ? 1 : 0.6 }}>
        <AlembicAvatar width={40} height={40} avatarSrc={token.avatar_url} />
        <Typography style={{ marginLeft: '15px' }}>
          {token.remote_name}
          <br />
          {token.remote_id}
        </Typography>
      </div>
    </Tooltip>
  );
};

export const customOwnerRender = name => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', opacity: name.isEnabled ? 1 : 0.6 }}>
      <AlembicAvatar width={40} height={40} avatarSrc={name.avatar_url} />
      <Typography style={{ marginLeft: '15px' }}>
        {name.first_name}&nbsp;{name.last_name}
      </Typography>
    </div>
  );
};

export const customTextRender = text => {
  /* In order to make it clear that an item is disabled, we use a different
     opacity for disabled rows; there is no simple way to apply the styling to the
     entire row */
  return <div style={{ opacity: text.isEnabled ? 1 : 0.6 }}>{text.value}</div>;
};

export const isPrimaryAccount = type => {
  // Top level accounts should only be able to be unlinked
  const unlinkList = [
    'FB_PROFILE',
    'TWITTER',
    'LINKEDIN_PROFILE',
    'YOUTUBE',
    'GOOGLE_PROFILE',
    'AA_USER',
    'SALESFORCE',
    'TIKTOK'
  ];
  return unlinkList.includes(type);
};

export const isSubAccount = type => {
  // Sub level accounts(pages) should only be able to be enabled
  const enableList = [
    'FB_PAGE',
    'INSTAGRAM',
    'TWITTER',
    'LINKEDIN_COMPANY',
    'YOUTUBE',
    'GOOGLE_WEBPROPERTY_G4',
    'GOOGLE_VIEW',
    'AA_USER',
    'AA_GLOBAL_COMPANY',
    'AA_SEGMENT',
    'APP_STORE_CONNECT_APP',
    'GOOGLE_PLAY_CONSOLE_APP',
    'SALESFORCE',
    'TIKTOK'
  ];
  return enableList.includes(type);
};

/**
 * Called by the Datatable to render actions for a single linktoken.
 * @param {Object} action Object with fields for the linktoken
 * @returns Object
 */
export const customActionsRender = action => {
  const {
    id,
    remote_name,
    type,
    valid_token,
    isEnabled,
    canModifyLinks,
    canDeleteLinks,
    isOwnedByCurrentUser,
    refetch,
    orgTreeNetworkStatus,
    tableDataNetworkStatus,
    error,
    history
  } = action;

  let buttonText = '-';
  let canBeToggled = false;
  let canBeUnlinked = false;
  let canRepair = false;
  let buttonStyle = {};
  const buttonColor = 'primary';

  // only sub accounts can be toggled, but we also allow the master AA account.
  if (isSubAccount(type) || type === LINKTOKEN_TYPE_ENUM.AA_GLOBAL_COMPANY) {
    if (isEnabled) {
      buttonText = 'Enabled';
      buttonStyle = { color: '#fff', backgroundColor: '#006300' };
    } else {
      buttonText = `Disabled`;
    }
  } else {
    // use the default
    buttonText = 'Linked';
  }

  // TODO: User, Org, and Container be able to enable and disable sub accounts regardless of ownership.
  // if the token is valid and the primary, then it can be unlinked/relinked.
  if (
    (isSubAccount(type) || type === LINKTOKEN_TYPE_ENUM.AA_GLOBAL_COMPANY) &&
    isOwnedByCurrentUser &&
    canModifyLinks
  ) {
    canBeToggled = true;
  }

  if (isPrimaryAccount(type) && (isOwnedByCurrentUser || canDeleteLinks)) {
    canBeUnlinked = true;
  }

  if (!valid_token) {
    buttonText = 'Errored';
    buttonStyle = { color: '#fff', backgroundColor: '#aa0000' };
  }

  if (isPrimaryAccount(type) && isOwnedByCurrentUser) {
    canRepair = true;
  }

  if (tableDataNetworkStatus !== 7 || orgTreeNetworkStatus !== 7) {
    return <AlbLoading />;
  }

  return (
    <AccountMenu
      id={id}
      remote_name={remote_name}
      type={type}
      isEnabled={isEnabled}
      buttonText={buttonText}
      buttonStyle={buttonStyle}
      buttonColor={buttonColor}
      canBeToggled={canBeToggled}
      canBeUnlinked={canBeUnlinked}
      canRepair={canRepair}
      refetch={refetch}
      valid_token={valid_token}
      error={error}
      history={history}
    />
  );
};
