import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AnalyticsBox from 'components/AnalyticsBox';
import AlembicPageHeader from 'components/AlembicPageHeader';
import CostToRecreateTable from 'components/AlbTable/CostToRecreateTable';
import { CREATE_RECREATE_COST_REPORT } from 'gql/recreateCostReport';
import { showToast } from 'contexts/ToastContext';
import { goToRoute, determineAnalyticsBackRoute } from 'util/routesHelpers';
import { CreateCostToRecreateAnalyticsPath, CostToRecreateAnalyticsPath } from 'util/paths';
import BasicFormCreateModal from './BasicFormCreateModal';

/**
 * Renders cost to recreate reports
 * @name CostToRecreateReport
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const CostToRecreateReport = ({ history, location }) => {
  const [createModalOpen, toggleCreateModalOpen] = useState(false);

  const [createCtrReport, { data: createCtrData, error }] = useMutation(
    CREATE_RECREATE_COST_REPORT
  );

  useEffect(() => {
    if (createCtrData?.createRecreateCostReport?.id) {
      showToast('Report created!', 'success');
      toggleCreateModalOpen(false);

      goToRoute(
        `${CostToRecreateAnalyticsPath}/${createCtrData?.createRecreateCostReport?.id}`,
        history
      );
    }

    if (error) {
      showToast(`Mutation failed: ${error}`, 'error');
      toggleCreateModalOpen(false);
    }
  }, [createCtrData, error]);

  const handleCreateReport = fieldsObj => {
    // triggers mutation
    createCtrReport({ variables: fieldsObj });
  };

  // TODO: route to advanced component. Pass the fieldsObj into url.
  const handleAdvancedClick = fieldObj => {
    let queryString = '';
    const keys = Object.keys(fieldObj);

    keys.forEach((key, index) => {
      const value = fieldObj[key];

      if (value) {
        queryString = queryString.concat(`${key}=${encodeURIComponent(value)}`);

        if (index !== keys.length - 1) {
          queryString = queryString.concat('&');
        }
      }
    });

    goToRoute(`${CreateCostToRecreateAnalyticsPath}?${queryString}`, history);
  };

  return (
    <>
      <AlembicPageHeader
        pageTitle="Media Valuation Reports"
        buttonTitle="Create New Report"
        backRoute={determineAnalyticsBackRoute(location, 'earned')}
        backButton
        buttonOnClick={() => toggleCreateModalOpen(true)}
      />
      <AnalyticsBox noMargin tabs={['Active', 'Archived']} onChange={() => {}}>
        <CostToRecreateTable tableOnly />
        <CostToRecreateTable tableOnly enabled={false} />
      </AnalyticsBox>
      {createModalOpen && (
        <BasicFormCreateModal
          open={createModalOpen}
          handleCancel={() => toggleCreateModalOpen(false)}
          handleCreateReport={handleCreateReport}
          handleAdvancedClick={handleAdvancedClick}
          history={history}
        />
      )}
    </>
  );
};

CostToRecreateReport.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default withRouter(CostToRecreateReport);
