import React, { Component } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import 'assets/tui/tui-image-editor.css';
import ToastImageEditor from '@toast-ui/react-image-editor';
import Box from 'components/Box';

export default class ImageEditor extends Component {
  constructor(props) {
    super(props);

    this.editorRef = React.createRef();
  }

  updateImage = async callback => {
    if (callback) {
      const editorInstance = this.editorRef.current.getInstance();
      callback(editorInstance.toDataURL());
    }
  };

  render() {
    const { onClose, onSave, name, image } = this.props;

    const albTheme = {
      'common.bi.image': '',
      'common.bisize.width': '0',
      'common.bisize.height': '0',
      // 'common.backgroundImage': './img/bg.png',
      'common.backgroundColor': '#fff',
      // 'common.border': '0',

      // header
      'header.backgroundImage': 'none',
      'header.backgroundColor': 'transparent',
      'header.border': '0px',

      // load button
      // 'loadButton.backgroundColor': '#fff',
      // 'loadButton.border': '1px solid #ddd',
      // 'loadButton.color': '#222',
      // 'loadButton.fontSize': '12px',
      'loadButton.display': 'none',

      // download button
      // 'downloadButton.backgroundColor': '#fdba3b',
      // 'downloadButton.border': '1px solid #fdba3b',
      // 'downloadButton.color': '#fff',
      // 'downloadButton.fontSize': '12px',
      'downloadButton.display': 'none',

      // main icons
      'menu.normalIcon.path': '/assets/tui/icon-d.svg',
      'menu.normalIcon.name': 'icon-d',
      'menu.activeIcon.path': '/assets/tui/icon-b.svg',
      'menu.activeIcon.name': 'icon-b',
      'menu.disabledIcon.path': '/assets/tui/icon-a.svg',
      'menu.disabledIcon.name': 'icon-a',
      'menu.hoverIcon.path': '/assets/tui/icon-c.svg',
      'menu.hoverIcon.name': 'icon-c',
      'menu.iconSize.width': '24px',
      'menu.iconSize.height': '24px',

      // submenu primary color
      'submenu.backgroundColor': 'transparent',
      'submenu.partition.color': '#e5e5e5',

      // submenu icons
      'submenu.normalIcon.path': '/assets/tui/icon-d.svg',
      'submenu.normalIcon.name': 'icon-d',
      'submenu.activeIcon.path': '/assets/tui/icon-b.svg',
      'submenu.activeIcon.name': 'icon-b',
      'submenu.iconSize.width': '32px',
      'submenu.iconSize.height': '32px',

      // submenu labels
      'submenu.normalLabel.color': '#858585',
      'submenu.normalLabel.fontWeight': 'normal',
      'submenu.activeLabel.color': '#000',
      'submenu.activeLabel.fontWeight': 'normal',

      // checkbox style
      'checkbox.border': '1px solid #ccc',
      'checkbox.backgroundColor': '#fff',

      // range style
      'range.pointer.color': '#333',
      'range.bar.color': '#ccc',
      'range.subbar.color': '#606060',

      'range.disabledPointer.color': '#d3d3d3',
      'range.disabledBar.color': 'rgba(85,85,85,0.06)',
      'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

      'range.value.color': '#000',
      'range.value.fontWeight': 'normal',
      'range.value.fontSize': '11px',
      'range.value.border': '0',
      'range.value.backgroundColor': '#f5f5f5',
      'range.title.color': '#000',
      'range.title.fontWeight': 'lighter',

      // colorpicker style
      'colorpicker.button.border': '0px',
      'colorpicker.title.color': '#000'
    };

    return (
      <Grid container justifyContent="center">
        <Grid
          container
          justifyContent="center"
          style={{
            backgroundColor: 'white',
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
            marginBottom: '50px',
            zIndex: 1
          }}
        >
          <Grid item xs={9}>
            <Box my={30}>
              <h2 style={{ fontWeight: 600 }}>Photo Editor</h2>
              <p style={{ textTransform: 'uppercase', color: '#1DA1F2', fontWeight: 500 }}>
                {name}
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Paper style={{ overflow: 'auto', borderRadius: '0px', marginBottom: 50 }}>
            <div>
              <div
                style={{
                  position: 'relative',
                  background: '#eee',
                  height: '75vh'
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  // padding: 10
                }}
              >
                <ToastImageEditor
                  ref={this.editorRef}
                  includeUI={{
                    loadImage: {
                      path: image,
                      name
                    },
                    theme: albTheme,
                    menu: ['filter', 'crop', 'flip', 'rotate', 'shape', 'text'],
                    initMenu: 'filter',
                    // uiSize: {
                    //   width: '1000px',
                    //   height: '700px'
                    // },
                    menuBarPosition: 'right'
                  }}
                  // cssMaxHeight={500}
                  // cssMaxWidth={700}
                  // selectionStyle={{
                  // cornerSize: 20,
                  // rotatingPointOffset: 70
                  // }}
                  usageStatistics={false}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 30 }}>
                <Button
                  variant="contained"
                  style={{
                    cursor: 'pointer',
                    background: 'white',
                    color: '#0d1a3a',
                    border: '1px solid #0d1a3a',
                    overflow: 'hidden',
                    padding: '3px 20px',
                    margin: '10',
                    marginRight: '8px'
                  }}
                  onClick={onClose}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '10'
                  }}
                  onClick={() => {
                    this.updateImage(imageData => {
                      onSave(imageData, name);
                    });
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ImageEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};
