import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

const AUDIT_ENTRIES_FIELDS = gql`
  fragment auditEntriesFields on AuditEntry {
    id
    user_id
    model_id
    model
    action
    meta
    created_at
    user {
      first_name
      last_name
    }
    container {
      name
    }
  }
`;

export const AUDIT_ENTRIES = gql`
  query {
    audit_entries {
      ...auditEntriesFields
    }
  }
  ${AUDIT_ENTRIES_FIELDS}
`;

export const GET_PAGINATED_AUDIT_ENTRIES = gql`
  query($after: Int, $count: Int, $orderBy: [String], $keyword: String) {
    paginated_audit_entries(after: $after, count: $count, orderBy: $orderBy, keyword: $keyword) {
      rows {
        ...auditEntriesFields
      }
      count
    }
  }
  ${AUDIT_ENTRIES_FIELDS}
`;

export async function apiGetPaginatedAuditEntries(data) {
  return apolloClientHelper(GET_PAGINATED_AUDIT_ENTRIES, data, false);
}
