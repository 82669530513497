import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Typography, Checkbox, Tooltip } from '@material-ui/core';
import Box from 'components/Box';
import Select from 'react-select';
import { CheckBox as CheckedIcon, CheckBoxOutlineBlank as UncheckedIcon } from '@material-ui/icons';
import { platforms } from 'util/social';
import { withStyles } from '@material-ui/core/styles';
import SocialLogo from 'components/SocialLogo';
import SIMPLE_SOCIAL_TYPE_ENUM from 'util/getSimpleSocialTypeEnum';
import { showToast } from 'contexts/ToastContext';

const styles = {
  accountContainer: {
    flex: 1,
    minWidth: '300px',
    alignItems: 'center'
  }
};

const NoOptionsMessage = () => (
  <Box py={10} display="flex" justifyContent="center" alignItems="center">
    <Typography>No social platforms available.</Typography>
  </Box>
);

const Option = props => {
  const { data, innerProps, isSelected } = props;
  const { onClick } = innerProps;

  const platformDisplayName = platforms.find(platform => platform.name === data.value)?.displayName;

  return (
    <MenuItem onClick={onClick} style={{ padding: '12px 16px' }} key={data.id}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Checkbox
            style={{ padding: 0, marginRight: '10px' }}
            checked={isSelected}
            icon={<UncheckedIcon fontSize="small" />}
            checkedIcon={<CheckedIcon fontSize="small" />}
          />
          <SocialLogo type={data.value} width={20} height={20} />
          <Typography style={{ marginLeft: '10px' }}>{platformDisplayName}</Typography>
        </Box>
      </Grid>
    </MenuItem>
  );
};

Option.propTypes = {
  data: PropTypes.shape().isRequired,
  innerProps: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired
};

const MultiValueContainer = ({ data }) => {
  const CustomTooltip = withStyles({
    tooltip: {
      padding: '3px 10px',
      borderRadius: '0px'
    }
  })(Tooltip);

  return (
    <Box mr={10} display="flex">
      <CustomTooltip title={data.value}>
        <Box display="flex" alignItems="center">
          <SocialLogo type={data.value} width={20} height={20} />
        </Box>
      </CustomTooltip>
    </Box>
  );
};

MultiValueContainer.propTypes = {
  data: PropTypes.shape().isRequired
};

/** *
 * @summary - Simpler version of PostAccountSelector, which allows selection by full platform types instead of individual linkTokens.
 * @prop {String[]} availablePlatforms - all platforms listed in the selector, passed as an array of strings matching platform names in util/social.
 * @prop {String[]} selectedPlatforms - the checked platforms in the selector.
 * @prop {Function} updateSelectedPlatforms - callback from parent to update the list of selected platforms.
 * @returns - React JSX
 */

const PlatformSelector = props => {
  const { availablePlatforms, selectedPlatforms, updateSelectedPlatforms, classes } = props;

  const [selectedValues, updateSelectedValues] = useState([]);
  const [allValues, updateAllValues] = useState([]);

  // we need to format the values into an array of { id, value } for this package to recognize the check mark action correctly.
  useEffect(() => {
    if (selectedPlatforms) {
      const selectedPlatformsWithIds = selectedPlatforms.map((platformName, i) => {
        return {
          id: i,
          value: platformName
        };
      });

      updateSelectedValues(selectedPlatformsWithIds);
    }

    if (availablePlatforms) {
      const allPlatformsWithIds = availablePlatforms.map((platformName, i) => {
        return {
          id: i,
          value: platformName
        };
      });

      updateAllValues(allPlatformsWithIds);
    }
  }, [selectedPlatforms, availablePlatforms]);

  const onChange = (newPlatforms, event) => {
    let tempPlatformValues = [];
    let newOptionType;

    if (event.action === 'select-option' && event?.option != null) {
      newOptionType = event.option.value;
    }

    if (newPlatforms?.length) {
      tempPlatformValues = newPlatforms?.map(platform => platform.value);
    }

    if (newOptionType === SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE) {
      tempPlatformValues = tempPlatformValues.filter(
        value => value !== SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS
      );
    }

    if (newOptionType === SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS) {
      tempPlatformValues = tempPlatformValues.filter(
        value => value !== SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE
      );
    }

    if (tempPlatformValues?.length < newPlatforms?.length) {
      showToast(
        'Cannot combine data from separate analytics platforms into the same view. Please inspect one at a time.',
        'error'
      );
    }

    // only pass back the string array of platform names.
    updateSelectedPlatforms(tempPlatformValues);
  };

  return (
    <Grid container alignItems="center">
      <Box px={10} className={classes.accountContainer}>
        <Select
          isMulti
          placeholder="Select Platforms"
          classes={classes}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue
          isClearable
          components={{
            Option,
            NoOptionsMessage,
            MultiValueContainer
          }}
          options={allValues}
          value={selectedValues}
          onChange={onChange}
        />
      </Box>
    </Grid>
  );
};

PlatformSelector.propTypes = {
  classes: PropTypes.shape(),
  selectedPlatforms: PropTypes.arrayOf(PropTypes.string),
  availablePlatforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateSelectedPlatforms: PropTypes.func.isRequired
};

PlatformSelector.defaultProps = {
  classes: null,
  selectedPlatforms: null
};

export default withStyles(styles)(PlatformSelector);
