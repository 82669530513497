import React, { useState, useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { showToast } from 'contexts/ToastContext';
import uuidv4 from 'uuid';
import LoadSmartFilterModal from 'components/AlembicModalForm/LoadSmartFilterModal';
import SaveSmartFilterModal from 'components/AlembicModalForm/SaveSmartFilterModal';
import DeleteSmartFilterModal from 'components/AlembicModalConfirm/DeleteSmartFilterModal';
import EditInPlaceInput from 'components/EditInPlaceInput';
import { iso639_1 as languages } from 'util/iso';
import SelectedFieldBox from './SelectedFieldBox';
import {
  ApplyFieldsSubmitter,
  SaveFilterSubmitter,
  ALL_RULES,
  ANY_RULES
} from './smartFilterEnums';
import SmartFiltersStyles from './smartFiltersStyles';

/**
 * @param {Object} currentUser - required object passed from mapStateToProps in this component, used for timezone.
 * @param {Boolean} hidden - required, uses CSS to toggle feature on/off by setting display: none
 * @param {Array} fields - required map of fields that is shown in the right pane.
 * @param {Function} setFieldsApplied - optional function to pass the count of selected fields back to the parent
 * @param {Function} setSmartFilterId - required, passes the smart filter id to the parent component which generally does another fetch by id to filter the table below.
 * @param {Function} formatFieldsAndSave - optional, if we want to pass in a function that formats the fields before saving them.
 * @param {Function} createFilterMutation - optional, gql mutation triggered on filter creation.
 * @param {Function} updateFilterNameMutation - optional, gql mutation to update name of the filter.
 * @param {Boolean} contacts - true if this form is part of the ContactsSmartFilters component
 * @param {Boolean} earnedMedia - true if used in Earned Media component
 * @param {Object} createData - optional, after the createFilter mutation is triggered, this data is passed back for UI purposes.
 * @param {Object} updateData - optional, after the updateFilter is triggered, this data is passed back to reflect in UI.
 * @param {String} pageTitle - required page title
 * @param {String} headerButtonTitle - optional button title
 * @param {Object} classes - material-ui prop
 * @return {Object} React JSX of the Smart Filters Form
 */

const SELECT = 'select';
const REMOVE = 'remove';
const ADD = 'add';
const RESET = 'reset';

const requiredFieldToast = option => {
  if (option === REMOVE) {
    showToast("Can't remove required field.", 'warn');
  }
  if (option === SELECT) {
    showToast('Required field is already selected', 'warn');
  }
};

const selectedFieldsReducer = (state, action) => {
  switch (action.type) {
    case ADD: {
      const fieldToAdd = Object.values(action.payload)[0];
      const allowedCount = fieldToAdd.countMax;
      const currentCountInState = Object.values(state).filter(value => {
        return value.name === fieldToAdd.name;
      }).length;

      if (!allowedCount || allowedCount > currentCountInState) {
        return { ...state, ...action.payload };
      }

      if (allowedCount > 1) {
        showToast(
          `You can only add the field "${fieldToAdd.displayName}" up to ${allowedCount} times.`,
          'warn'
        );
      } else {
        showToast(`You can only add the field "${fieldToAdd.displayName}" once.`, 'warn');
      }

      return state;
    }
    case REMOVE: {
      const newState = { ...state };
      const fieldToRemoveKey = Object.keys(action.payload)[0];
      const fieldToRemoveValue = Object.values(action.payload)[0];

      // don't delete any required fields.
      if (!fieldToRemoveValue.inputProps?.required) {
        delete newState[fieldToRemoveKey];
      }

      return newState;
    }
    case RESET: {
      // payload would be any required fields.
      if (action.payload) {
        return action.payload;
      }
      return {};
    }
    default:
      return state;
  }
};

const SmartFiltersForm = ({
  currentUser,
  hidden,
  fields,
  setFieldsApplied,
  setSmartFilterId,
  formatFieldsAndSave,
  createFilterMutation,
  updateFilterNameMutation,
  contacts,
  earnedMedia,
  displayActionButton,
  createData,
  updateData,
  headerButtonTitle,
  actionButtonTitle,
  additionalFields,
  manageAdditionalFields,
  updateCurrentSearchFields,
  earnedMediaExistingFilter,
  costToRecreateExistingFilter,
  children,
  classes
}) => {
  const [universalCheck, toggleUniversalCheck] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);
  const [clearAllModalOpen, setClearAllModalOpen] = useState(false);
  const [loadFilterModal, toggleLoadFilterModal] = useState(false);
  const [saveFilterModal, toggleSaveFilterModal] = useState(false);
  const [deleteFilterModal, toggleDeleteFilterModal] = useState(false);
  const [createFilter, toggleCreateFilter] = useState(true); // if an existing filter is loaded in, toggle create off.
  const [rulesMatchScope, setRulesMatchScope] = useState(null);
  const [checkedSelectedFields, dispatchCheckedSelectedFields] = useReducer(
    selectedFieldsReducer,
    {}
  );
  const [selectedFields, dispatchSelectedFields] = useReducer(selectedFieldsReducer, {});
  const [finalFilterFields, saveFinalFilterFields] = useState([]);
  const [existingFilter, updateExistingFilter] = useState(null); // if this field is set, we are looking at an existing filter, not creating a new one.
  const [existingFilterModified, toggleExistingFilterModified] = useState(false);
  const [newFilterFormName, setNewFilterFormName] = useState('');
  const [disabledActionButton, toggleDisabledActionButton] = useState(true);
  const [clearAllActive, toggleClearAll] = useState(false);
  const [fieldsExceedingLimit, setFieldsExceedingLimit] = useState([]);

  const completedFieldInputs = useRef({});
  const keywordsCount = useRef([]);
  const blockedKeywordsCount = useRef([]);
  const blockedDomainsCount = useRef([]);

  const charCounts = useRef({});

  useEffect(() => {
    if (createData?.createSmartFilter) {
      // callback to apply filter to leads table.
      setSmartFilterId(createData.createSmartFilter.id);
    }

    if (updateData?.updateSmartFilterName) {
      setNewFilterFormName(updateData.updateSmartFilterName);
      showToast('Your filter name was updated!', 'success');
    }
  }, [createData, updateData]);

  // show the remaining count of certain fields.
  const manageFieldsCount = () => {
    // have to re-iterate through the fields from selectedFields to maintain the right order in the form.
    const checkedKeywordKeys = [];
    const checkedBlockedKeywordKeys = [];
    const checkedBlockedDomainKeys = [];

    // query keywords:
    const selectedQueryKeywords = Object.entries(selectedFields).filter(
      ([, v]) => v.name === 'query_keywords'
    );

    // ensure the field is checked before adding to count. We only count checked fields.
    selectedQueryKeywords.forEach(entry => {
      const key = entry[0];

      if (checkedSelectedFields[key]) {
        checkedKeywordKeys.push(key);
      }
    });

    // blocked keywords:
    const checkedBlockedKeywords = Object.entries(selectedFields).filter(
      ([, v]) => v.name === 'filter_keyword_blacklist'
    );

    checkedBlockedKeywords.forEach(entry => {
      const key = entry[0];

      if (checkedSelectedFields[key]) {
        checkedBlockedKeywordKeys.push(key);
      }
    });

    // blacklisted domains:
    const checkedDomainBlacklist = Object.entries(selectedFields).filter(
      ([, v]) => v.name === 'filter_domain_blacklist'
    );

    checkedDomainBlacklist.forEach(entry => {
      const key = entry[0];

      if (checkedSelectedFields[key]) {
        checkedBlockedDomainKeys.push(key);
      }
    });

    // update counts for each special field.
    keywordsCount.current = checkedKeywordKeys;
    blockedKeywordsCount.current = checkedBlockedKeywordKeys;
    blockedDomainsCount.current = checkedBlockedDomainKeys;
  };

  const setExistingFilter = filter => {
    if (!filter || !existingFilterModified) {
      dispatchSelectedFields({ type: RESET });
    }

    if (filter) {
      // find each of the fields and load them into selectedFields and checkedSelectedFields.
      let selectedFieldsObj = {};
      let newFieldObj = {};

      if (contacts) {
        const smartFields = filter.smart_filter_fields;

        smartFields.forEach(field => {
          const foundColumn = fields[field.source_column_alembic];
          const selectValue = fields[field.criteria];

          if (foundColumn && selectValue) {
            const key = uuidv4();

            // only existing fields will have ids in selectedFields
            const existingFieldWithValues = {
              ...foundColumn,
              id: field.id,
              value: field.input1,
              secondValue: field.input2,
              select: selectValue,
              name: field.source_column_alembic
            };

            newFieldObj = Object.fromEntries(new Map([[key, existingFieldWithValues]]));

            selectedFieldsObj = { ...selectedFieldsObj, ...newFieldObj };
          }
        });
      }

      if (earnedMedia) {
        const filterFields = filter.articleSearch;

        if (filterFields) {
          Object.entries(filterFields).forEach(fieldEntry => {
            const fieldKey = fieldEntry[0];
            let value = fieldEntry[1];
            const foundColumn = Object.values(fields).find(v => v.name === fieldKey);

            // the following columns can contain comma-separated values.
            // separate them into different rows.
            let valueArr = [];

            if (
              fieldKey === 'filter_keyword_blacklist' ||
              fieldKey === 'filter_domain_blacklist' ||
              fieldKey === 'query_keywords'
            ) {
              valueArr = value ? value.split(',') : [];
            }

            if (fieldKey === 'language') {
              const languageEntry = Object.entries(languages).find(([k]) => k === value);

              if (languageEntry[1]) {
                // eslint-disable-next-line prefer-destructuring
                value = languageEntry[1];
              }
            }

            if (foundColumn && value !== null && !valueArr.length) {
              // only fill the columns that have values
              const key = uuidv4();

              // only existing fields will have ids in selectedFields
              const existingFieldWithValues = {
                ...foundColumn,
                value
              };

              newFieldObj = Object.fromEntries(new Map([[key, existingFieldWithValues]]));

              selectedFieldsObj = { ...selectedFieldsObj, ...newFieldObj };
            }

            if (foundColumn && value !== null && valueArr.length) {
              valueArr.forEach(word => {
                // only fill the columns that have values
                const key = uuidv4();

                // only existing fields will have ids in selectedFields
                const existingFieldWithValues = {
                  ...foundColumn,
                  value: word
                };

                newFieldObj = Object.fromEntries(new Map([[key, existingFieldWithValues]]));

                selectedFieldsObj = { ...selectedFieldsObj, ...newFieldObj };
              });
            }
          });
        }
      }

      if (costToRecreateExistingFilter) {
        Object.entries(costToRecreateExistingFilter).forEach(field => {
          const fieldKey = field[0];
          const value = field[1];

          if (value) {
            const foundColumn = Object.values(fields).find(v => v.name === fieldKey);

            if (foundColumn) {
              const key = uuidv4();

              // only existing fields will have ids in selectedFields
              const existingFieldWithValues = {
                ...foundColumn,
                value
              };

              newFieldObj = Object.fromEntries(new Map([[key, existingFieldWithValues]]));

              selectedFieldsObj = { ...selectedFieldsObj, ...newFieldObj };
            }
          }
        });
      }

      dispatchSelectedFields({ type: RESET, payload: selectedFieldsObj });
      dispatchCheckedSelectedFields({ type: RESET, payload: selectedFieldsObj });
      completedFieldInputs.current = selectedFieldsObj;

      updateExistingFilter(filter);
      setRulesMatchScope(filter.requires_all_fields ? ALL_RULES : ANY_RULES);
      setNewFilterFormName(filter.name);
      setSmartFilterId(filter.id);
      toggleExistingFilterModified(false);
    }
  };

  useEffect(() => {
    if (earnedMediaExistingFilter) {
      setExistingFilter(earnedMediaExistingFilter);
      updateExistingFilter(earnedMediaExistingFilter);
    }

    if (costToRecreateExistingFilter) {
      setExistingFilter(costToRecreateExistingFilter);
      updateExistingFilter(costToRecreateExistingFilter);
    }
  }, [earnedMediaExistingFilter, costToRecreateExistingFilter]);

  const handleFieldSelect = (fieldName, assignedValue) => {
    const field = fields?.find(f => f.displayName === fieldName);

    if (field) {
      // required fields can only be selected once. Check if it's selected first.
      if (field.inputProps.required) {
        const fieldAlreadySelected = Object.values(selectedFields).find(
          entry => entry.displayName === field.displayName
        );

        if (fieldAlreadySelected) {
          requiredFieldToast(SELECT);
          return;
        }
      }

      const key = uuidv4();
      const newFieldObj = Object.fromEntries(new Map([[key, { ...field }]]));

      if (assignedValue) {
        newFieldObj[key].value = assignedValue;
      }

      dispatchSelectedFields({ type: ADD, payload: newFieldObj });
      dispatchCheckedSelectedFields({ type: ADD, payload: newFieldObj });
      toggleDisabledActionButton(false);
    }
  };

  // toggles universal check and updates field counts.
  useEffect(() => {
    let checked = true;

    // a field was unchecked
    if (Object.keys(checkedSelectedFields).length < Object.keys(selectedFields).length) {
      checked = false;
    }

    toggleUniversalCheck(checked);
    manageFieldsCount();
  }, [checkedSelectedFields]);

  // these keywords can be selected additionally and automatically passed to the form with their values.
  useEffect(() => {
    const queryKeywordsDisplayName = fields?.find(field => field.name === 'query_keywords')
      ?.displayName;

    // add the word to selected fields.
    if (additionalFields.query_keywords?.length) {
      const lastEl = additionalFields.query_keywords[additionalFields.query_keywords.length - 1];

      handleFieldSelect(queryKeywordsDisplayName, lastEl);
    }
  }, [additionalFields.query_keywords]);

  // same as above, tracks blacklisted keywords.
  useEffect(() => {
    const blockedKeywordsDisplayName = fields?.find(
      field => field.name === 'filter_keyword_blacklist'
    )?.displayName;

    if (additionalFields.filter_keyword_blacklist?.length) {
      const lastEl =
        additionalFields.filter_keyword_blacklist[
          additionalFields.filter_keyword_blacklist.length - 1
        ];

      handleFieldSelect(blockedKeywordsDisplayName, lastEl);
    }
  }, [additionalFields.filter_keyword_blacklist]);

  const preloadRequiredFields = () => {
    fields.forEach(field => {
      if (field.inputProps.required) {
        handleFieldSelect(field.displayName);
      }
    });
  };

  // set earned media form presets.
  useEffect(() => {
    if (earnedMedia) {
      // check if we are loading an existing filter (from path)
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const searchId = parseInt(params.get('id'), 10);

      if (searchId) {
        // fetch the filter info.
        setSmartFilterId(searchId);
      } else {
        // if no existing filter, preload mandatory fields into the form.
        preloadRequiredFields();

        // set rules to AND (ALL_RULES)
        setRulesMatchScope(ALL_RULES);
      }
    }
  }, []);

  // after Clear All takes effect, select the required fields again.
  useEffect(() => {
    if (earnedMedia && clearAllActive && !Object.keys(selectedFields).length) {
      preloadRequiredFields();
      toggleClearAll(false);
    }
  }, [clearAllActive]);

  // if an existing filter gets initialized, preload all the fields with the current values.
  useEffect(() => {
    if (!existingFilter && !existingFilterModified && !earnedMedia) {
      dispatchSelectedFields({ type: RESET });
      dispatchCheckedSelectedFields({ type: RESET });
    }
  }, [existingFilter]);

  useEffect(() => {
    const selectedFieldsLength = Object.keys(selectedFields).length;

    if (selectedFieldsLength) {
      setRulesMatchScope(ALL_RULES);
    }

    // if there are no selected fields, there shouldn't be anything else.
    if (!selectedFieldsLength && !earnedMedia && !costToRecreateExistingFilter) {
      setRulesMatchScope(null);
      dispatchCheckedSelectedFields({ type: RESET });
      updateExistingFilter(null);
      saveFinalFilterFields([]);
      completedFieldInputs.current = {};
      setSmartFilterId(null);
      setFieldsApplied(null);
    }
  }, [selectedFields]);

  const updateCharCount = allFields => {
    let charCountOnFields = {};

    Object.entries(allFields).forEach(([k, v]) => {
      const fullFieldObj = checkedSelectedFields[k];
      const text = v.value;
      const fieldName = v.name;

      if (fullFieldObj?.charLimit) {
        const currentLength = charCountOnFields[fieldName] || 0;

        const newCharCount = text.length + currentLength;

        if (newCharCount > fullFieldObj.charLimit) {
          // set TextField to non-active.
          showToast(
            `Cannot exceed ${fullFieldObj.charLimit} characters for this field.`,
            'warning'
          );

          if (!fieldsExceedingLimit.includes(k)) {
            setFieldsExceedingLimit([...fieldsExceedingLimit, k]);
          }

          charCountOnFields = { ...charCountOnFields, [fieldName]: text.length + currentLength };
        } else {
          charCountOnFields = { ...charCountOnFields, [fieldName]: text.length + currentLength };

          if (fieldsExceedingLimit.includes(k)) {
            const idx = fieldsExceedingLimit.indexOf(k);

            // if we are no longer exceeding the limit, remove the field from fieldsExceedingLimit.
            if (idx !== -1) {
              const modifiedFields = [...fieldsExceedingLimit];

              modifiedFields.splice(idx, 1);
              setFieldsExceedingLimit(modifiedFields);
            }
          }
        }
      }
    });

    charCounts.current = charCountOnFields;
  };

  const saveCompletedFieldInputs = (identifier, inputs) => {
    const fieldInputs = {};
    fieldInputs[identifier] = inputs;

    const currentInputs = { ...completedFieldInputs.current };

    // Will override previous inputs if identifier is the same, or save the new field.
    const mergedFieldInputs = Object.assign(currentInputs, fieldInputs);

    // We modified an existing filter if an existingFilter is passed in, and:
    // a) the identifier already exists in currentInputs - we are modifying that field,
    // b) the length of fields doesn't equal - we are adding/deleting a field.
    if (existingFilter) {
      if (
        currentInputs[identifier] ||
        Object.keys(currentInputs).length !== Object.keys(mergedFieldInputs).length
      ) {
        toggleExistingFilterModified(true);
      }
    }

    // if the value is different from previous, enable the 'Apply to Articles' button
    Object.entries(completedFieldInputs.current).forEach(([k, v]) => {
      const newFieldValue = mergedFieldInputs[k].value;

      if (v.value !== newFieldValue) {
        toggleDisabledActionButton(false);
      }
    });

    updateCharCount(mergedFieldInputs);

    completedFieldInputs.current = mergedFieldInputs;

    if (updateCurrentSearchFields) {
      // We want to immediately update current search fields so that the user can save them when saving a media report
      updateCurrentSearchFields(completedFieldInputs, checkedSelectedFields);
    }
  };

  const submitForm = form => {
    // Apply Fields filters the CRM table.
    // Form submitters map to different action buttons.
    if (form.submitter === ApplyFieldsSubmitter) {
      if (existingFilter && !existingFilterModified) {
        // filter leads by existing filter.
        setSmartFilterId(existingFilter.id);
      } else {
        // create new system-managed filter, and apply it to leads table via useEffect.

        if (contacts) {
          const finalApiFields = formatFieldsAndSave(completedFieldInputs, checkedSelectedFields);

          if (finalApiFields.length) {
            const variables = {
              requires_all_fields: rulesMatchScope === ALL_RULES,
              is_user_managed: false, // user isn't electing to save this filter, the system is.
              smart_filter_fields: finalApiFields
            };

            createFilterMutation({
              ...variables
            });
          }

          // Callback from Contacts.js to show length of fields.
          setFieldsApplied(Object.keys(completedFieldInputs.current).length);
        }

        if (earnedMedia) {
          // this function handles the query in CreateMediaReport
          formatFieldsAndSave(completedFieldInputs, checkedSelectedFields);
          toggleDisabledActionButton(true);
        }
      }

      // Add different submit logic for other components
    }

    if (form.submitter === SaveFilterSubmitter) {
      if (contacts) {
        const finalApiFields = formatFieldsAndSave(completedFieldInputs, checkedSelectedFields);

        if (finalApiFields.length) {
          saveFinalFilterFields(finalApiFields);
          toggleSaveFilterModal(!saveFilterModal);
        }
      }
      // Add different submit logic for other components
    }
  };

  // submit in this form is used for multiple actions: Applying Fields to Contacts and Saving Filter.
  const handleSubmit = e => {
    e.preventDefault();
    const form = document.getElementById('smartFiltersForm');

    formatFieldsAndSave(completedFieldInputs, checkedSelectedFields);

    submitForm(form);
    toggleDisabledActionButton(true);
  };

  // unselects (aka deletes) field in smart filter form when a user clicks on the 'X' icon.
  const unselectField = identifier => {
    const newFields = { ...selectedFields };
    const completedFields = { ...completedFieldInputs.current };

    // eslint-disable-next-line no-unused-vars
    const fieldToDeleteArr = Object.entries(selectedFields).filter(([k, v]) => k === identifier);
    const fieldToDeleteObj = Object.fromEntries(fieldToDeleteArr);

    // can't remove required fields.
    if (!fieldToDeleteObj[identifier].inputProps.required) {
      delete newFields[identifier];
      delete completedFields[identifier];

      dispatchSelectedFields({ type: REMOVE, payload: fieldToDeleteObj });
      completedFieldInputs.current = completedFields;

      dispatchCheckedSelectedFields({
        type: REMOVE,
        payload: fieldToDeleteObj
      });

      // remove from additionalFields, if any
      const queryKeywords = fieldToDeleteObj[identifier].name === 'query_keywords';
      const blockedKeywords = fieldToDeleteObj[identifier].name === 'filter_keyword_blacklist';

      if (queryKeywords) {
        // eslint-disable-next-line camelcase
        const keywordsInArr = additionalFields?.query_keywords;
        const word = fieldToDeleteObj[identifier].value;

        if (keywordsInArr?.includes(word)) {
          const idx = keywordsInArr.indexOf(word);
          keywordsInArr.splice(idx, 1);

          if (manageAdditionalFields) {
            manageAdditionalFields({ ...additionalFields, query_keywords: keywordsInArr });
          }
        }
      }

      if (blockedKeywords) {
        // eslint-disable-next-line camelcase
        const keywordsInArr = additionalFields?.filter_keyword_blacklist;
        const word = fieldToDeleteObj[identifier].value;

        if (keywordsInArr?.includes(word)) {
          const idx = keywordsInArr.indexOf(word);
          keywordsInArr.splice(idx, 1);

          if (manageAdditionalFields) {
            manageAdditionalFields({
              ...additionalFields,
              filter_keyword_blacklist: keywordsInArr
            });
          }
        }
      }

      toggleDisabledActionButton(false);

      updateCharCount(completedFieldInputs.current);

      if (updateCurrentSearchFields) {
        // We want to immediately update current search fields so that the user can save them when saving a media report
        updateCurrentSearchFields(completedFieldInputs, checkedSelectedFields);
      }
    } else {
      requiredFieldToast(REMOVE);
    }
  };

  // add or remove individual field from checkedSelectedFields.
  // If all fields are now checked/un-checked, also toggle the universal check.
  const handleCheckField = (field, identifier) => {
    const existingFieldArr = Object.entries(checkedSelectedFields).filter(
      ([k]) => k === identifier
    );
    const existingFieldObj = Object.fromEntries(existingFieldArr);

    if (existingFieldObj[identifier]) {
      // can't remove required fields.
      if (!existingFieldObj[identifier].inputProps.required) {
        dispatchCheckedSelectedFields({ type: REMOVE, payload: existingFieldObj });
        toggleDisabledActionButton(false);
      } else {
        requiredFieldToast(REMOVE);
      }
    } else {
      const newFieldObj = Object.fromEntries(new Map([[identifier, { ...field }]]));

      dispatchCheckedSelectedFields({ type: ADD, payload: newFieldObj });
      toggleDisabledActionButton(false);
    }
  };

  const manageSaveFilter = filterStateObj => {
    toggleSaveFilterModal(filterStateObj.isModalOpen);

    // returned filter after saving. Init the form with the fields.
    if (filterStateObj.smartFilter) {
      // now we're looking at an existing filter so can only do update operations
      setExistingFilter(filterStateObj.smartFilter);
      toggleCreateFilter(false);
    }
  };

  // the query for filter is in the modal component. The filter data is passed in from filterStateObj.
  const manageLoadFilter = filterStateObj => {
    toggleLoadFilterModal(filterStateObj.isModalOpen);

    if (filterStateObj?.smartFilter) {
      setExistingFilter(filterStateObj.smartFilter);
      toggleCreateFilter(false);
    }
  };

  const manageDeleteFilter = deleteFilterObj => {
    const { isModalOpen, filterDeleted } = deleteFilterObj;

    toggleDeleteFilterModal(isModalOpen);

    if (filterDeleted) {
      dispatchSelectedFields({ type: RESET });
      setNewFilterFormName('');
    }
  };

  const handleClearAll = () => {
    dispatchSelectedFields({ type: RESET, payload: {} });
    dispatchCheckedSelectedFields({ type: RESET, payload: {} });
    completedFieldInputs.current = {};
    setSmartFilterId(null);
    toggleUniversalCheck(checkedSelectedFields.length === selectedFields.length);
    if (manageAdditionalFields) {
      manageAdditionalFields({});
    }
    setClearAllModalOpen(false);

    // activates a useEffect listener which re-loads required fields.
    toggleClearAll(true);
  };

  // manual universal check overrides specific user selections and checks/un-checks all fields
  // handleUniversalCheck only checks/un-checks the top checkbox.
  const handleUniversalManualCheck = checked => {
    if (checked) {
      dispatchCheckedSelectedFields({ type: RESET, payload: selectedFields });
    } else {
      // remove required fields from this. Keep them checked always.
      const keepChecked = Object.entries(selectedFields).filter(([, v]) => v.inputProps.required);
      const checkedObj = Object.fromEntries(keepChecked);

      dispatchCheckedSelectedFields({ type: RESET, payload: checkedObj });
    }

    toggleUniversalCheck(checked);
  };

  const handleActionClick = () => {
    formatFieldsAndSave(completedFieldInputs, checkedSelectedFields);

    const form = document.getElementById('smartFiltersForm');
    form.submitter = ApplyFieldsSubmitter;
  };

  const getRemainingCount = (fKey, fValue) => {
    if (fValue.countMax && fValue.countMax !== 1) {
      const allowedCount = fValue.countMax;
      let position;

      if (fValue.name === 'query_keywords') {
        position = keywordsCount.current.indexOf(fKey);
      }

      if (fValue.name === 'filter_keyword_blacklist') {
        position = blockedKeywordsCount.current.indexOf(fKey);
      }

      if (fValue.name === 'filter_domain_blacklist') {
        position = blockedDomainsCount.current.indexOf(fKey);
      }

      if (position !== -1 && position !== undefined) {
        position += 1;

        return `(Field ${position} of ${allowedCount})`;
      }
    }

    return null;
  };

  const getCharLimit = fValue => {
    if (fValue.charLimit && charCounts.current[fValue.name]) {
      return `(${charCounts.current[fValue.name]} characters of ${fValue.charLimit})`;
    }

    return null;
  };

  return (
    <Paper className={hidden ? classes.hidden : classes.root}>
      <ValidatorForm
        id="smartFiltersForm"
        onSubmit={Object.keys(checkedSelectedFields).length ? handleSubmit : () => {}}
      >
        <Container>
          {/* newFilterFormName used for Contacts' Smart Filters when an existing filter is loaded */}
          {contacts ? (
            <>
              <Grid left style={{ marginLeft: '0px' }}>
                {newFilterFormName && (
                  <EditInPlaceInput
                    left
                    useEdit
                    useDelete
                    value={newFilterFormName || ''}
                    onChange={event => {
                      if (event.value && event.value !== newFilterFormName) {
                        updateFilterNameMutation({
                          variables: {
                            id: existingFilter.id,
                            name: event.value
                          }
                        });
                      }
                      if (event.delete) {
                        toggleDeleteFilterModal(true);
                      }
                    }}
                  />
                )}
              </Grid>
              <Button
                type="button"
                right
                variant="contained"
                className={classes.loadFilter}
                onClick={() => toggleLoadFilterModal(!loadFilterModal)}
              >
                Load Filter
              </Button>
              <Button
                right
                disabled={!Object.keys(checkedSelectedFields).length}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.saveFilter}
                onClick={() => {
                  const form = document.getElementById('smartFiltersForm');
                  form.submitter = SaveFilterSubmitter;
                }}
              >
                {headerButtonTitle}
              </Button>
            </>
          ) : null}
          <Paper className={classes.formContainer}>
            <Grid container>
              <Grid item xs={3}>
                <Paper className={classes.rightFields}>
                  <Grid
                    container
                    style={{ height: '50px' }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className={classes.fieldsTitle}>Fields</Typography>
                    <Button
                      variant="outlined"
                      disabled={!Object.keys(selectedFields).length}
                      className={classes.clearAll}
                      onClick={() => setClearAllModalOpen(true)}
                    >
                      Clear All
                    </Button>
                  </Grid>
                  <div className={classes.line} />
                  <Autocomplete
                    options={fields?.map(field => field.displayName)}
                    value={fieldValue}
                    onChange={(e, newValue) => {
                      setFieldValue(newValue);
                      handleFieldSelect(newValue);
                    }}
                    onSubmit={(e, newValue) => {
                      setFieldValue(newValue);
                      handleFieldSelect(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Search"
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: '15px' }}
                      />
                    )}
                  />
                  <Grid style={{ maxHeight: '330px', overflow: 'auto', marginTop: '15px' }}>
                    <List>
                      {fields?.map(field => {
                        if (
                          (fieldValue &&
                            (field.displayName.includes(fieldValue) ||
                              field.name.includes(fieldValue))) ||
                          !fieldValue
                        ) {
                          return (
                            <ListItem
                              key={field.name}
                              onClick={() => handleFieldSelect(field.displayName)}
                              button
                            >
                              <ListItemText primary={field.displayName} />
                            </ListItem>
                          );
                        }
                        return null;
                      })}
                    </List>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={9} style={{ maxHeight: '496px' }}>
                <Paper
                  style={{
                    height: '15%',
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      height: '100%',
                      paddingLeft: '10px',
                      wrap: 'no-wrap'
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={universalCheck}
                      onChange={e => handleUniversalManualCheck(e.target.checked)}
                      disabled={!Object.keys(selectedFields).length}
                    />
                    <Typography style={{ marginLeft: '0px', marginRight: 'auto' }}>
                      FIELDS SELECTED: {Object.keys(checkedSelectedFields).length}
                    </Typography>
                    {contacts ? (
                      <Grid className={classes.radioLabels}>
                        <RadioGroup
                          row
                          value={rulesMatchScope}
                          onChange={e => setRulesMatchScope(e.target.value)}
                        >
                          <FormControlLabel
                            value={ANY_RULES}
                            disabled={!Object.keys(selectedFields).length}
                            label={
                              <Typography
                                className={
                                  rulesMatchScope === ANY_RULES
                                    ? classes.activeRadio
                                    : classes.radioLabel
                                }
                              >
                                Any Rules Can Match
                              </Typography>
                            }
                            control={
                              <Radio
                                color={rulesMatchScope === ANY_RULES ? 'primary' : 'default'}
                              />
                            }
                          />
                          <FormControlLabel
                            value={ALL_RULES}
                            disabled={!Object.keys(selectedFields).length}
                            control={
                              <Radio
                                color={rulesMatchScope === ALL_RULES ? 'primary' : 'default'}
                              />
                            }
                            label={
                              <Typography
                                className={
                                  rulesMatchScope === ALL_RULES
                                    ? classes.activeRadio
                                    : classes.radioLabel
                                }
                              >
                                All Rules Must Match
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                    ) : null}
                  </Grid>
                </Paper>
                <Grid container style={{ height: '75%', overflow: 'auto' }}>
                  {Object.entries(selectedFields).length ? (
                    <List style={{ width: '100%', maxHeight: '100%' }} disablePadding>
                      {Object.entries(selectedFields).map(([key, value], i) => {
                        let evenIndex;

                        if (i % 2 === 0) {
                          evenIndex = true;
                        } else {
                          evenIndex = false;
                        }

                        // don't show conjunction on last index.
                        const lastRow = Object.entries(selectedFields).length - 1 === i;

                        // is field checked?
                        const checkedField = Object.keys(checkedSelectedFields).includes(key);

                        // We can load in an existing field from the DB, or push a field to the form with a value in it from someplace else (ex. earned media)
                        // Only existing fields have values or ids.
                        const existingField = !!value.value || !!value.id;

                        // in required fields, don't display the checkbox or the close out icon.
                        const requiredField = value.inputProps?.required;

                        return (
                          <Grid key={key}>
                            <ListItem
                              disableGutters
                              className={evenIndex ? classes.evenFieldRow : classes.oddFieldRow}
                            >
                              <SelectedFieldBox
                                field={value}
                                required={requiredField}
                                existingField={existingField}
                                identifier={key}
                                unselectField={unselectField} // removes field from right pane
                                conjunction={!lastRow ? rulesMatchScope : null}
                                checkedField={!!checkedField}
                                handleCheckField={handleCheckField}
                                saveCompletedFieldInputs={saveCompletedFieldInputs}
                                userTimeZone={currentUser.time_zone}
                                remainingCount={getRemainingCount(key, value)}
                                charLimit={getCharLimit(value)}
                                customComponent={children}
                                costToRecreate={!!costToRecreateExistingFilter}
                                fieldsExceedingLimit={fieldsExceedingLimit}
                              />
                            </ListItem>
                            {!lastRow && rulesMatchScope && (
                              <Grid container>
                                <Divider
                                  className={`${classes.spacer} ${
                                    evenIndex ? classes.evenFieldRow : classes.oddFieldRow
                                  }`}
                                />
                                <Divider className={classes.divider} />
                              </Grid>
                            )}
                            {lastRow && rulesMatchScope && <Divider className={classes.divider} />}
                          </Grid>
                        );
                      })}
                    </List>
                  ) : (
                    <Grid container justifyContent="center" alignItems="center">
                      <Typography align="center" style={{ color: '#6F6F6F' }}>
                        Please select a field to filter by from the left menu by clicking the field.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {displayActionButton && (
                  <Paper
                    style={{
                      height: '10%',
                      width: '100%'
                    }}
                  >
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      style={{
                        height: '100%',
                        paddingLeft: '10px',
                        wrap: 'no-wrap'
                      }}
                    >
                      <Grid className={classes.applyFilters}>
                        <Button
                          type="submit"
                          variant="contained"
                          // color="primary"
                          disabled={disabledActionButton}
                          className={classes.actionButton}
                          onClick={handleActionClick}
                        >
                          {actionButtonTitle}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Container>
        {clearAllModalOpen && (
          <AlembicModalConfirm
            isOpen={clearAllModalOpen}
            title="Clear All Selected Fields"
            body="Are you sure you want to clear all selected fields?"
            confirmTitle="Clear"
            handleCancel={() => setClearAllModalOpen(false)}
            handleConfirm={handleClearAll}
          />
        )}
        {loadFilterModal && (
          <LoadSmartFilterModal isModalOpen={loadFilterModal} onChange={manageLoadFilter} />
        )}
        {saveFilterModal && (
          <SaveSmartFilterModal
            isModalOpen={saveFilterModal}
            onChange={manageSaveFilter}
            smartFilter={finalFilterFields}
            isCreate={createFilter}
            requiresAll={rulesMatchScope === ALL_RULES}
            updateFilterId={existingFilter && existingFilter.id}
          />
        )}
        {deleteFilterModal && (
          <DeleteSmartFilterModal
            isModalOpen={deleteFilterModal}
            name={existingFilter?.name}
            smartFilterId={existingFilter.id}
            onChange={manageDeleteFilter}
          />
        )}
      </ValidatorForm>
    </Paper>
  );
};

SmartFiltersForm.propTypes = {
  currentUser: PropTypes.shape(),
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  classes: PropTypes.shape(),
  filterList: PropTypes.arrayOf(PropTypes.shape()),
  hidden: PropTypes.bool.isRequired,
  setFieldsApplied: PropTypes.func,
  setSmartFilterId: PropTypes.func,
  createData: PropTypes.shape(),
  updateData: PropTypes.shape(),
  formatFieldsAndSave: PropTypes.func,
  createFilterMutation: PropTypes.func,
  updateFilterNameMutation: PropTypes.func,
  contacts: PropTypes.bool,
  earnedMedia: PropTypes.bool,
  displayActionButton: PropTypes.bool,
  pageTitle: PropTypes.string,
  headerButtonTitle: PropTypes.string,
  actionButtonTitle: PropTypes.string,
  additionalFields: PropTypes.shape(),
  manageAdditionalFields: PropTypes.func,
  updateCurrentSearchFields: PropTypes.func,
  earnedMediaExistingFilter: PropTypes.shape(),
  costToRecreateExistingFilter: PropTypes.shape(),
  children: PropTypes.element
};

SmartFiltersForm.defaultProps = {
  currentUser: null,
  classes: null,
  filterList: null,
  createData: null,
  updateData: null,
  formatFieldsAndSave: null,
  contacts: false,
  earnedMedia: false,
  displayActionButton: true,
  createFilterMutation: null,
  updateFilterNameMutation: null,
  setFieldsApplied: () => {},
  setSmartFilterId: () => {},
  pageTitle: null,
  headerButtonTitle: 'Create',
  actionButtonTitle: 'Apply Filter',
  additionalFields: {},
  manageAdditionalFields: null,
  updateCurrentSearchFields: null,
  earnedMediaExistingFilter: null,
  costToRecreateExistingFilter: null,
  children: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(withStyles(SmartFiltersStyles)(SmartFiltersForm));
