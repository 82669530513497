import { apiGetAssets } from 'gql/asset';

// recursively modify filename until we find one that doesn't match.
const generateNewFilename = async (name, count, ul) => {
  let dir;
  let ext;

  if (count === 1) {
    dir = name.slice(0, name.lastIndexOf('.'));
    ext = name.slice(name.lastIndexOf('.'), name.length);
  } else {
    dir = name.slice(0, name.lastIndexOf('('));
    ext = name.slice(name.lastIndexOf('.'), name.length);
  }

  const fullName = `${ul}/${dir}(${count})${ext}`;
  const newName = `${dir}(${count})${ext}`;
  const result = await apiGetAssets({ fileName: fullName });

  // returns at least one result - generate new name until we don't get anything back.
  if (result.payload.data.assets[0]) {
    const newCount = count + 1;
    // throws eslint error, but this recursive async function doesn't work properly without 'await'.
    const newFileName = await generateNewFilename(newName, newCount, ul);
    return newFileName;
  }
  return newName;
};

// replace spaces with underscores:
const getNameWithNoSpaces = name => name.split(' ').join('_');

// Checking if filename exists, and increment until no match is found.
export const validateFilename = async (file, { uploadfolder }) => {
  const originalName = file.filename;

  const nameWithNoSpaces = getNameWithNoSpaces(originalName);

  const fullName = `${uploadfolder}/${nameWithNoSpaces}`;
  const result = await apiGetAssets({ fileName: fullName });
  const nameExists = result.payload.data.assets[0];

  if (nameExists) {
    try {
      const newName = await generateNewFilename(nameWithNoSpaces, 1, uploadfolder);
      return { ...file, name: newName };
    } catch (e) {
      return e;
    }
  }

  return { ...file, name: nameWithNoSpaces };
};

// if we are in root directory, use a single slash, else put the current folder name first for nested folders.
// If path exists, api will throw an error in the component, thus we don't need to do another call to check here, and unlike filenames, we don't generate one automatically.
export const validateFolderName = ({ name, uploadFolder }) => {
  const nameWithNoSpaces = getNameWithNoSpaces(name);

  if (uploadFolder === '/') {
    return `/${nameWithNoSpaces}`;
  }

  return `${uploadFolder}/${nameWithNoSpaces}`;
};

export const formatFolderName = name => {
  const cleanArray = [];

  name.split('/').map(element => {
    if (element) {
      cleanArray.push(element);
    }
    return null;
  });

  const newName = cleanArray.pop().split('.')[0];
  const nameWithSpaces = newName.split('_').join(' ');

  if (nameWithSpaces.length > 12) {
    return `${nameWithSpaces.slice(0, 13)}...`;
  }

  return nameWithSpaces;
};

export const formatCrumb = name => {
  const formattedName = formatFolderName(name);

  const upperCasedArray = formattedName
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1));

  return upperCasedArray.join(' ');
};
