import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { TOP_ACTIVE_POST_TOTAL } from 'gql/analyticsHypeAndFlow';
import { getDatesForAnalytics, formatDateRange } from 'util/date';
import { topPostsColors } from 'util/colors';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import GraphHover from 'components/GraphHover';
import AlbDoughnutGraph from './AlbDoughnutGraph';
import AlbDoughnutGraphLegend from './AlbDoughnutGraphLegend';

const useStyles = makeStyles({
  container: {
    height: '100%',
    textAlign: 'center'
  },
  graphContainer: {
    flex: '1'
  },
  primaryText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734'
  },
  secondaryText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#6F6F6F'
  }
});

const reduceQueryData = data =>
  data.reduce(
    (accumulator, currentValue, index) => {
      accumulator.data.push(currentValue.y);
      accumulator.labels.push(currentValue.x);
      accumulator.legendRows.push({
        boxColor: topPostsColors[index],
        position: currentValue.x,
        value: currentValue.y,
        postId: currentValue.postId,
        linkTokenId: currentValue.linkTokenId
      });

      return accumulator;
    },
    { data: [], labels: [], legendRows: [] }
  );

/**
 * @method
 * @summary This component renders the top 10 active post by summing the metric across the period
 * @name TopActivePosts
 * @param {Object} props - React props passed to this component
 * @param {Object} props.currentUser - An object containing the current user data from Redux
 * @param {string} props.metric - The metric name
 * @param {Object[]} props.accounts - An array of link token objects from @see PostAccountSelector
 * @param {Object} props.dates - An object containing start and end dates from @see AnalyticsDatePicker
 * @return {Object} - React JSX
 */
const TopActivePosts = props => {
  const { metric, accounts, dates } = props;
  const [graphData, setGraphData] = useState({});
  const [legendData, setLegendData] = useState([]);
  const [hoveredPostId, setHoveredPostId] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [linkToken, setLinkToken] = useState(null);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const classes = useStyles();
  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(
    TOP_ACTIVE_POST_TOTAL,
    {
      variables: {
        startDate,
        endDate,
        linkTokens: accounts.map(({ id }) => id),
        metric
      }
    }
  );

  useEffect(() => {
    if (queryData?.topActivePostTotal?.topPosts) {
      const { data = [], labels = [], legendRows = [] } = reduceQueryData(
        queryData.topActivePostTotal.topPosts
      );

      setGraphData({
        datasets: [
          {
            data,
            backgroundColor: topPostsColors,
            borderWidth: 0
          }
        ],
        labels
      });
      setLegendData(legendRows);
    }
  }, [queryData]);

  const closeGraphHover = () => {
    setHoveredPostId(null);
    setPopoverAnchorEl(null);
    setLinkToken(null);
  };

  const handleRowClick = (e, { postId, linkTokenId }) => {
    setHoveredPostId(postId);
    setPopoverAnchorEl(e.currentTarget);
    setLinkToken(linkTokenId);
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Typography className={classes.primaryText}>
        # of Posts Contributing in Time Period
      </Typography>

      <Typography className={classes.secondaryText}>
        {formatDateRange(dates.start, dates.end)}
      </Typography>

      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        className={classes.graphContainer}
      >
        {!!queryLoading && <AlbLoading />}
        {!!queryError && <AlbError error={queryError} />}
        {queryData?.topActivePostTotal?.topPosts?.length === 0 && (
          <Typography className={classes.primaryText}>
            There is not any data to display at this time.
          </Typography>
        )}

        {queryData?.topActivePostTotal?.topPosts?.length > 0 && (
          <>
            <Grid item xs={7}>
              <AlbDoughnutGraph
                data={graphData}
                count={Math.round(queryData?.topActivePostTotal?.count).toLocaleString()}
                label="Posts"
              />
            </Grid>
            <Grid container item xs={5} direction="column" justifyContent="center">
              {legendData.map(row => (
                <AlbDoughnutGraphLegend
                  key={row.position}
                  boxColor={row.boxColor}
                  position={row.position}
                  value={row.value}
                  onClick={e => {
                    if (row?.postId && !popoverAnchorEl) {
                      handleRowClick(e, {
                        postId: row?.postId,
                        linkTokenId: row?.linkTokenId
                      });
                    }
                  }}
                />
              ))}
            </Grid>
            {!!popoverAnchorEl && (
              <GraphHover
                open={!!popoverAnchorEl}
                anchorEl={popoverAnchorEl}
                startDate={dates.start}
                endDate={dates.end}
                eventId={hoveredPostId}
                linkTokenId={linkToken}
                withLTV
                clickBehavior
                close={closeGraphHover}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

TopActivePosts.propTypes = {
  metric: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired
};

TopActivePosts.defaultProps = {};

export default TopActivePosts;
