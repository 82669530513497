import {
  SOCIAL_MEDIA_CARD_TITLE,
  HYPE_AND_FLOW_CARD_TITLE,
  THIRD_PARTY_MEDIA_CARD_TITLE,
  SHARE_OF_VOICE_CARD_TITLE,
  MEDIA_VALUATION_CARD_TITLE,
  ORG_TREE_CARD_TITLE,
  SHORTLINK_CARD_TITLE,
  RESURGENCE_CARD_TITLE,
  WEB_AND_APPS_CARD_TITLE,
  PROGRAM_LIFT_CARD_TITLE,
  SALESFORCE_CARD_TITLE,
  TARGETING_CARD_TITLE
} from 'util/analyticsTitles';

import {
  SOCIAL_MEDIA_ICON,
  HYPE_AND_FLOW_ICON,
  THIRD_PARTY_MEDIA_ICON,
  SHARE_OF_VOICE_ICON,
  MEDIA_VALUATION_ICON,
  ORG_TREE_ICON,
  LINK_SHORTENER_ICON,
  RESURGENCE_ICON,
  WEB_AND_APPS_ICON,
  PROGRAM_LIFT_ICON,
  SALESFORCE_ICON,
  TARGETING_ICON
} from 'util/assets';
import {
  SocialAnalyticsPath, // Social Media
  HypeAndFlowAnalyticsPath,
  ThirdPartyMediaAnalyticsPath,
  ShareOfVoiceAnalyticsPath,
  CostToRecreateAnalyticsPath, // Media Valuation
  OrganizationTreePath,
  ShortlinkPath,
  ResurgenceAnalyticsPath,
  WebAndAppAnalyticsPath,
  ProgramLiftPath,
  SalesforcePath,
  TargetingAnalyticsPath
} from 'util/paths';
import tooltipText from 'util/tooltipText';

const cards = [
  {
    title: SOCIAL_MEDIA_CARD_TITLE,
    images: [SOCIAL_MEDIA_ICON],
    path: SocialAnalyticsPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.socialBroadcast
  },
  {
    title: THIRD_PARTY_MEDIA_CARD_TITLE,
    images: [THIRD_PARTY_MEDIA_ICON],
    path: ThirdPartyMediaAnalyticsPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.thirdPartyMedia,
    showBanner: {
      footer: 'Premium Feature',
      text:
        'For more information on 3rd Party Media access, please contact your Client Partner or support@getalembic.com for details.'
    }
  },
  {
    title: WEB_AND_APPS_CARD_TITLE,
    images: [WEB_AND_APPS_ICON],
    path: WebAndAppAnalyticsPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.webAndApps
  },
  {
    title: SALESFORCE_CARD_TITLE,
    images: [SALESFORCE_ICON],
    path: SalesforcePath,
    isValuationRequired: false,
    beta: true,
    showBanner: {
      footer: 'Premium Feature',
      text:
        'For more information on Salesforce access, please contact your Client Partner or support@getalembic.com for details.'
    }
  },
  {
    title: SHARE_OF_VOICE_CARD_TITLE,
    images: [SHARE_OF_VOICE_ICON],
    path: ShareOfVoiceAnalyticsPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.shareOfVoice
  },
  {
    title: PROGRAM_LIFT_CARD_TITLE,
    images: [PROGRAM_LIFT_ICON],
    path: ProgramLiftPath,
    isValuationRequired: false
  },
  {
    title: SHORTLINK_CARD_TITLE,
    images: [LINK_SHORTENER_ICON],
    path: ShortlinkPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.shortLink
  },
  {
    title: ORG_TREE_CARD_TITLE,
    images: [ORG_TREE_ICON],
    path: OrganizationTreePath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.organizationTree
  },
  {
    title: HYPE_AND_FLOW_CARD_TITLE,
    images: [HYPE_AND_FLOW_ICON],
    path: HypeAndFlowAnalyticsPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.hypeAndFlow
  },
  {
    title: MEDIA_VALUATION_CARD_TITLE,
    images: [MEDIA_VALUATION_ICON],
    path: CostToRecreateAnalyticsPath,
    isValuationRequired: true,
    tooltipTitle: tooltipText.costToRecreate
  },
  {
    title: RESURGENCE_CARD_TITLE,
    images: [RESURGENCE_ICON],
    path: ResurgenceAnalyticsPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.resurgence
  },
  {
    title: TARGETING_CARD_TITLE,
    images: [TARGETING_ICON],
    path: TargetingAnalyticsPath,
    isValuationRequired: false,
    tooltipTitle: tooltipText.targeting
  }
];

export default cards;
