/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getDisabledRoutes } from 'util/featureFlags';
import { goToRoute } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import HasAnyPriv from 'components/HasAnyPriv/HasAnyPriv';
import AlembicPageTitle from 'components/AlembicPageTitle/AlembicPageTitle';
import cardData from './cardData';
import AnalyticsCards from './AnalyticsCards';

const getCardsByFeature = (allCards, featureFlags) => {
  return allCards.filter(({ path }) => !getDisabledRoutes(featureFlags).includes(path));
};

const getShownDisabledCards = (allCards, featureFlags) => {
  return allCards.filter(
    ({ path, showIfDisabled }) => getDisabledRoutes(featureFlags).includes(path) && !!showIfDisabled
  );
};

const WrappedAnalytics = props => {
  useEffect(() => {
    setPageTitle('Report');
  }, []);

  const { history, featureFlags } = props;
  const handleRouting = path => goToRoute(path, history);
  const cardsToRender = getCardsByFeature(cardData, featureFlags);
  const disabledCardsToRender = getShownDisabledCards(cardData, featureFlags);

  return (
    <Grid container>
      <HasAnyPriv
        privs={[
          'SVC:CHAN:STATS:TW',
          'SVC:CHAN:STATS:LI',
          'SVC:CHAN:STATS:IN',
          'SVC:CHAN:STATS:FB',
          'SVC:CHAN:STATS:EM',
          'SVC:CHAN:STATS:DM',
          'SVC:CHAN:STATS'
        ]}
        showAlert
      >
        <>
          <AlembicPageTitle title="Report" />

          <AnalyticsCards
            {...props}
            handleRouting={handleRouting}
            cardsToRender={cardsToRender}
            disabledCardsToRender={disabledCardsToRender}
          />
        </>
      </HasAnyPriv>
    </Grid>
  );
};

WrappedAnalytics.propTypes = {
  history: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentOrganization: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    featureFlags: state.auth.featureFlags,
    currentOrganization: state.auth.currentOrganization
  };
};
export default connect(mapStateToProps)(withRouter(WrappedAnalytics));
