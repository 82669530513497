import React from 'react';
import PropTypes from 'prop-types';

const OptimizeIcon = props => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2422 16.4705C13.9649 18.9512 10.3658 20.0086 6.97003 18.8699C2.39493 17.3042 -0.0857943 12.3428 1.45957 7.74731"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M11.3621 15.6572V16.5722L15.8966 16.6129L15.5306 21.127L16.4456 21.2084L16.8929 15.6979L11.3621 15.6572Z"
        fill={color}
      />
      <path
        d="M3.20868 4.71748C5.46573 2.15542 9.12582 1.05739 12.5826 2.21642C17.178 3.76179 19.6384 8.74357 18.093 13.3187"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M8.10885 5.46979V4.53444L3.57441 4.57511L3.85908 0.0610014L2.94406 0L2.59839 5.51046L8.10885 5.46979Z"
        fill={color}
      />
    </svg>
  );
};

OptimizeIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

OptimizeIcon.defaultProps = {
  width: 20,
  height: 22,
  color: '#FEFEFE'
};

export default OptimizeIcon;
