import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Grid,
  Typography,
  Button,
  ListItemIcon,
  Collapse,
  Checkbox
} from '@material-ui/core';
import { ArrowDropDown as DownArrowIcon, ArrowRight as RightArrowIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { CREATE_PROGRAM_LIFT_REPORT } from 'gql/programLiftReport';
import AlembicInputLabel from 'components/AlembicInputLabel';
import LinktokenSelector from 'components/LinktokenSelector';
import ThirdPartyMediaSelector from 'components/ThirdPartyMediaSelector';
import colors from 'util/colors';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';

const useStyles = makeStyles({
  container: {
    width: '100%',
    margin: '0px',
    padding: '8px 8px 18px'
  },
  title: {
    fontWeight: '500',
    fontSize: '24px',
    color: '#0A1734'
  },
  collapseRow: {
    padding: '8px 0px 16px 0px'
  },
  label: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0A1734'
  },
  subLabel: {
    marginLeft: '4px',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#979797'
  },
  collapseIconOverride: {
    cursor: 'pointer',
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInnerOverride: {
    paddingLeft: '20px',
    paddingRight: '8px',
    paddingBottom: '12px'
  },
  dialogPaperOverride: {
    overflowY: 'unset',
    minHeight: '600px'
  }
});

const CreateProgramLiftReport = props => {
  const { isModalOpen, handleSuccess, handleCancel } = props;
  const [reportName, setReportName] = useState('');
  const [keywords, setKeywords] = useState('');
  const [linktokens, setLinktokens] = useState([]);
  const [broadcastReports, setBroadcastReports] = useState([]);
  const [podcastReports, setPodcastReports] = useState([]);
  const [articleReports, setArticleReports] = useState([]);
  const [articleFilterReports, setArticleFilterReports] = useState([]);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isShortlinkChecked, setIsShortlinkChecked] = useState(true);

  const classes = useStyles();

  const [createProgramLiftReport, { loading: loadingCreate }] = useMutation(
    CREATE_PROGRAM_LIFT_REPORT
  );

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleCancel}
      classes={{ paper: isCollapseOpen ? classes.dialogPaperOverride : '' }}
    >
      <ValidatorForm
        onSubmit={() => {
          createProgramLiftReport({
            variables: {
              name: reportName,
              keywords,
              linktokenIds: linktokens.map(({ id }) => id),
              broadcastSearchIds: broadcastReports.map(({ id }) => id),
              podcastSearchIds: podcastReports.map(({ id }) => id),
              articleSearchIds: articleReports.map(({ id }) => id),
              articleSearchFilterIds: articleFilterReports.map(({ id }) => id),
              ...(isShortlinkChecked === false && {
                include_shortlink: isShortlinkChecked
              })
            }
          })
            .then(() => {
              showToast(
                'Program Lift report created. If non-detecting reports were selected then check back in 2 hours.',
                'success'
              );
              handleSuccess();
              handleCancel();
            })
            .catch(e => {
              handleGraphQLError(e);
            });
        }}
      >
        <Grid container direction="column" spacing={3} className={classes.container}>
          <Grid item>
            <Typography className={classes.title}>Create Program Lift Report</Typography>
          </Grid>

          {/* Report Name */}
          <Grid item>
            <AlembicInputLabel isRequired primaryText="Program Lift Report Name" />
            <TextValidator
              fullWidth
              name="reportName"
              variant="filled"
              value={reportName}
              validators={['required']}
              errorMessages={['* Required']}
              placeholder="Enter Program Lift Report Name"
              onChange={e => setReportName(e.target.value)}
            />
          </Grid>

          {/* Keyword */}
          <Grid item>
            <AlembicInputLabel isRequired primaryText="Keyword" />

            <TextValidator
              fullWidth
              name="keywords"
              variant="filled"
              value={keywords}
              validators={['required']}
              errorMessages={['* Required']}
              placeholder="Enter Keyword"
              onChange={e => setKeywords(e.target.value)}
            />
          </Grid>

          {/* Accounts & Reports */}
          <Grid container alignItems="center" className={classes.collapseRow}>
            <ListItemIcon
              classes={{ root: classes.collapseIconOverride }}
              onClick={() => setIsCollapseOpen(prevState => !prevState)}
            >
              {isCollapseOpen ? <DownArrowIcon /> : <RightArrowIcon />}
            </ListItemIcon>
            <Typography variantMapping={{ body1: 'span' }} className={classes.label}>
              Accounts & Reports
            </Typography>
            <Typography variantMapping={{ body1: 'span' }} className={classes.subLabel}>
              (Optional)
            </Typography>
          </Grid>

          <Collapse
            in={isCollapseOpen}
            classes={{
              wrapperInner: classes.wrapperInnerOverride
            }}
          >
            <Grid container spacing={3}>
              {/* Social & Analytics Accounts */}
              <Grid container item>
                <AlembicInputLabel isOptional primaryText="Social & Analytics Accounts" />
                <div style={{ width: '100%' }}>
                  <LinktokenSelector
                    onChange={event => {
                      setLinktokens(event.linktokens);
                    }}
                  />
                </div>
              </Grid>

              {/* 3rd Party Media Reports */}
              <Grid container item>
                <AlembicInputLabel isOptional primaryText="3rd Party Media Reports" />
                <div style={{ width: '100%' }}>
                  <ThirdPartyMediaSelector
                    isProgramLift
                    onChange={event => {
                      setBroadcastReports(event.broadcastReports);
                      setPodcastReports(event.podcastReports);
                      setArticleReports(event.articleReports);
                      setArticleFilterReports(event.articleFilterReports);
                    }}
                  />
                </div>
              </Grid>

              {/* Shortlink Toggle */}
              <Grid container item alignItems="center">
                <Checkbox
                  checked={isShortlinkChecked}
                  onChange={event => setIsShortlinkChecked(event.target.checked)}
                />
                <Typography className={classes.label}>Include Shortlinks</Typography>
              </Grid>
            </Grid>
          </Collapse>

          <Grid item container justifyContent="space-between" alignItems="center">
            <Button
              variant="outlined"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button color="primary" type="submit" variant="contained" disabled={loadingCreate}>
              Create
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Dialog>
  );
};

CreateProgramLiftReport.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default CreateProgramLiftReport;
