import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  Tooltip,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';

const PrivilegeTree = props => {
  const {
    privs,
    padding,
    isDisabled,
    selectedPrivileges,
    indeterminatePrivileges,
    onPrivilegeSelect,
    setCollapse,
    collapse
  } = props;

  return (
    <List>
      {privs.map(priv => (
        <Fragment key={priv.id}>
          <ListItem
            style={{
              flexDirection: 'row-reverse',
              justifyContent: 'flex-end',
              padding: `0px 0px 0px ${priv.children.length ? padding : padding + 56}px`
            }}
          >
            <Tooltip key={priv.id} title={priv.description} placement="top-start">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled}
                    checked={!!selectedPrivileges[priv.id]}
                    indeterminate={
                      !!indeterminatePrivileges[priv.id] && !selectedPrivileges[priv.id]
                    }
                    name={priv.display_name}
                    onChange={() => onPrivilegeSelect(priv.id)}
                  />
                }
                label={priv.display_name}
              />
            </Tooltip>
            {!!priv.children.length && (
              <ListItemIcon
                onClick={() => setCollapse({ ...collapse, [priv.id]: !collapse[priv.id] })}
              >
                {collapse[priv.id] ? <ExpandMore /> : <ChevronRight />}
              </ListItemIcon>
            )}
          </ListItem>
          <Collapse in={collapse[priv.id]}>
            <PrivilegeTree
              privs={priv.children}
              padding={padding + 20}
              isDisabled={isDisabled}
              selectedPrivileges={selectedPrivileges}
              indeterminatePrivileges={indeterminatePrivileges}
              onPrivilegeSelect={onPrivilegeSelect}
              setCollapse={setCollapse}
              collapse={collapse}
            />
          </Collapse>
        </Fragment>
      ))}
    </List>
  );
};

PrivilegeTree.propTypes = {
  privs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  padding: PropTypes.number,
  isDisabled: PropTypes.bool.isRequired,
  selectedPrivileges: PropTypes.shape().isRequired,
  indeterminatePrivileges: PropTypes.shape(),
  onPrivilegeSelect: PropTypes.func.isRequired,
  setCollapse: PropTypes.func.isRequired,
  collapse: PropTypes.shape().isRequired
};

PrivilegeTree.defaultProps = {
  padding: 20,
  indeterminatePrivileges: {}
};

export default PrivilegeTree;
