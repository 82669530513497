import { gql } from '@apollo/client';

export const DASHBOARD_INTELLIGENCE_EVENT = gql`
  query dashboardIntelligenceEvent($id: ID!) {
    dashboardIntelligenceEvent(id: $id) {
      id
      linktokenId
      event
      growth
      major
      metric
      metricValue
      sourceName
      sourceType
      source
      valuation
      numberOfPosts
      type
      startDate
      endDate
      postDayLength
      eventRelativeRank
      gaGoalName {
        goal_id
        name
      }
      campaignEvent {
        id
        linked_account_id
        type
        event_title
        event_body
        assets {
          signed_thumbnail_url
        }
      }
      linktoken {
        id
        remote_name
        type
      }
      eventInfo {
        postBody
        sourceName
      }
    }
  }
`;

export const DASHBOARD_INTELLIGENCE_EVENTS = gql`
  query dashboardIntelligenceEvents(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $types: [DashboardTypeEnum]!
    $enableNewDetectedEvents: Boolean
    $useNewEventToggle: Boolean
  ) {
    dashboardIntelligenceEvents(
      startDate: $startDate
      endDate: $endDate
      types: $types
      enableNewDetectedEvents: $enableNewDetectedEvents
      useNewEventToggle: $useNewEventToggle
    ) {
      all {
        count
        day
        events {
          id
          linktokenId
          event
          growth
          major
          metric
          metricValue
          sourceName
          sourceType
          source
          valuation
          numberOfPosts
          type
          startDate
          endDate
          postDayLength
          eventRelativeRank
          gaGoalName {
            goal_id
            name
          }
          campaignEvent {
            id
            linked_account_id
            type
            event_title
            event_body
            assets {
              signed_thumbnail_url
            }
          }
          linktoken {
            id
            remote_name
            type
          }
          eventInfo {
            postBody
            sourceName
          }
          secondary_id
        }
      }
      social {
        count
        day
        events {
          id
          linktokenId
          event
          growth
          major
          metric
          metricValue
          sourceName
          sourceType
          valuation
          numberOfPosts
          type
          startDate
          endDate
          campaignEvent {
            id
            type
            event_title
            event_body
            linked_account_id
            assets {
              signed_thumbnail_url
            }
          }
          linktoken {
            id
            type
            remote_name
          }
          eventInfo {
            postBody
            sourceName
          }
        }
      }
      web {
        count
        day
        events {
          id
          event
          growth
          major
          metric
          metricValue
          sourceName
          sourceType
          source
          valuation
          numberOfPosts
          type
          startDate
          endDate
          campaignEvent {
            id
            linked_account_id
            type
            event_title
            event_body
            assets {
              signed_thumbnail_url
            }
          }
          linktoken {
            id
            type
            remote_name
          }
          eventInfo {
            postBody
            sourceName
          }
        }
      }
      conversion {
        count
        day
        events {
          id
          event
          growth
          major
          metric
          metricValue
          sourceName
          sourceType
          gaGoalName {
            goal_id
            name
          }
          valuation
          numberOfPosts
          type
          startDate
          endDate
          campaignEvent {
            id
            linked_account_id
            type
            event_title
            event_body
            assets {
              signed_thumbnail_url
            }
          }
          linktoken {
            id
            type
            remote_name
          }
          eventInfo {
            postBody
            sourceName
          }
        }
      }
      media {
        count
        day
        events {
          id
          event
          growth
          major
          metric
          metricValue
          sourceName
          sourceType
          source
          valuation
          numberOfPosts
          type
          startDate
          endDate
          eventInfo {
            postBody
            sourceName
          }
          secondary_id
        }
      }
    }
  }
`;

/**
 * spikeType can only be one of "page" or "source"
 */
export const GET_WEB_TOTAL_USERS_DRUID = gql`
  query getWebTotalUsers(
    $linktokenEventId: ID
    $detectedEventId: ID
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $spikeType: String!
  ) {
    getWebTotalUsers(
      linktokenEventId: $linktokenEventId
      detectedEventId: $detectedEventId
      startDate: $startDate
      endDate: $endDate
      spikeType: $spikeType
    ) {
      totalUsers
      result {
        x
        y
      }
    }
  }
`;

export const SURGE_EVENTS_PAST_YEAR = gql`
  query surgeEventsPastYear($linktokenEventId: ID, $detectedEventId: ID) {
    surgeEventsPastYear(linktokenEventId: $linktokenEventId, detectedEventId: $detectedEventId) {
      id
      startDate
      endDate
      metricValue
    }
  }
`;

export const CONVERSION_OUTLIERS = gql`
  query conversionOutliers(
    $linktokenEventId: ID
    $detectedEventId: ID
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
  ) {
    conversionOutliers(
      linktokenEventId: $linktokenEventId
      detectedEventId: $detectedEventId
      startDate: $startDate
      endDate: $endDate
    ) {
      totalConversions
      result {
        x
        y
      }
    }
  }
`;

export const CONVERSIONS_PAST_YEAR = gql`
  query conversionOutlierEventsPastYear($linktokenEventId: ID, $detectedEventId: ID) {
    conversionOutlierEventsPastYear(
      linktokenEventId: $linktokenEventId
      detectedEventId: $detectedEventId
    ) {
      id
      startDate
      endDate
      metricValue
    }
  }
`;
