/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import PostDrawerViewContext from './PostDrawerViewContext';

class PostDrawerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDrawer = (
      isPostDrawerOpenParam = false,
      campaignEventIdParam = null,
      postIdParam = null,
      linkTokenIdParam = null,
      startDateParam = null,
      endDateParam = null,
      isResurgenceParam = false,
      surgeStartParam = null,
      surgeEndParam = null,
      initialSurge = false
    ) => {
      this.setState({
        isPostDrawerOpen: isPostDrawerOpenParam,
        campaignEventId: campaignEventIdParam,
        postId: postIdParam,
        linkTokenId: linkTokenIdParam,
        startDate: startDateParam,
        endDate: endDateParam,
        isResurgence: isResurgenceParam,
        surgeStart: surgeStartParam,
        surgeEnd: surgeEndParam,
        isInitialSurge: initialSurge
      });
    };

    this.state = {
      isPostDrawerOpen: false,
      campaignEventId: null,
      postId: null,
      linkTokenId: null,
      startDate: null,
      endDate: null,
      isResurgence: false,
      surgeStart: null,
      surgeEnd: null,
      isInitialSurge: false,
      toggleDrawer: this.toggleDrawer
    };
  }

  render() {
    const { children } = this.props;
    return (
      <PostDrawerViewContext.Provider value={this.state}>{children}</PostDrawerViewContext.Provider>
    );
  }
}

PostDrawerWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PostDrawerWrapper;
