// color names derived from 'chir.ag/projects/name-that-color/'
export default {
  athensGray: '#E3E3ED !important',

  black80: 'rgba(0, 0, 0, 0.8)',
  blue: '#2F80ED',
  blue1: '#DBDAE3',
  blue2: '#B1B0C9',
  blue3: '#9191B6',
  blue4: '#7574A4',
  blue5: '#595892',
  blue6: '#464585',
  blueGeo1: '#CFCFDD',

  darkBlue10: '#EBEBF3',
  darkBlue20: '#D9DAE6',
  darkBlue30: '#C2C2D8',
  darkBlue50: '#8685B1',
  darkBlue: '#32327D',

  darkGray: '#979797',
  darkGray10: '#C4C4C4',
  doveGray: '#6F6F6F',

  flamingo: '#EC5C27',

  gallery: '#ECECEC',
  gray: '#EAEAEA',
  gray95: '#F2F2F2',
  green: '#00A84F',

  kimberly: '#65659E',
  lightGray20: 'rgba(0, 0, 0, 0.2)',
  lightGray50: 'rgba(151, 151, 151, 0.5)',

  navy: '#0A1734',
  navy25: 'rgba(10, 23, 52, 0.25)',
  navy50: 'rgba(10, 23, 52, 0.5)',
  navy50Tint: '#9898BE',

  orange: '#F39B25',

  pacific: '#009FC4',
  pink: '#C40075',
  purple: '#32327D',

  red: '#E81828',
  red1: '#DD3434',

  twitterBlue: '#1DA1F2',

  white: '#FFFFFF',
  wildSand: '#F5F5F5'
};

export const topPostsColors = [
  '#C40075',
  '#32327D',
  '#F39B25',
  '#E81828',
  '#009FC4',
  '#00A84F',
  '#0A1734',
  '#65659E',
  '#979797',
  '#EC5C27',
  '#6F6F6F'
];

export const topPostsGradient = [
  ['rgba(196,0,117,0.3)', 'rgba(196,0,117,0.1)'],
  ['rgba(50,50,125,0.3)', 'rgba(50,50,125,0.1)'],
  ['rgba(243,155,37,0.3)', 'rgba(243,155,37,0.1)'],
  ['rgba(232,24,40,0.3)', 'rgba(232,24,40,0.1)'],
  ['rgba(0,159,196,0.3)', 'rgba(0,159,196,0.1)'],
  ['rgba(0,168,79,0.3)', 'rgba(0,168,79,0.1)'],
  ['rgba(10,23,52,0.3)', 'rgba(10,23,52,0.1)'],
  ['rgba(101,101,158,0.3)', 'rgba(101,101,158,0.1)'],
  ['rgba(151,151,151,0.3)', 'rgba(151,151,151,0.1)'],
  ['rgba(236,92,39,0.3)', 'rgba(236,92,39,0.1)'],
  ['rgba(111,111,111,0.3)', 'rgba(111,111,111,0.1)']
];
