import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Box from 'components/Box';
import ExcludeNarrativeModal from 'components/AlembicModalConfirm/ExcludeNarrativeModal';
import IncludeNarrativeModal from 'components/AlembicModalConfirm/IncludeNarrativeModal';

/**
 * @method
 * @summary This component renders vertical (...) icon with a list of options when clicked @see NarrativeTable
 * @name NarrativeTableActions
 * @param {Object} props - React props passed to this component
 * @param {Object} props.actions - An object containing all the parameters for this component
 * @param {boolean} actions.disabled - Determines if a narrative is enabled or disabled
 * @param {string} actions.id - The id of the narrative
 * @param {string} actions.name - The name of the narrative
 * @param {Function} actions.dataRefetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const NarrativeTableActions = props => {
  const { actions } = props;
  const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false);
  const [isIncludeModalOpen, setIsIncludeModalOpen] = useState(false);

  return (
    <Box>
      {actions.disabled === false && (
        <Button variant="outlined" color="primary" onClick={() => setIsExcludeModalOpen(true)}>
          Exclude
        </Button>
      )}
      {(actions.disabled == null || actions.disabled === true) && (
        <Button variant="outlined" color="primary" onClick={() => setIsIncludeModalOpen(true)}>
          Include
        </Button>
      )}

      <ExcludeNarrativeModal
        isModalOpen={isExcludeModalOpen}
        onChange={event => {
          setIsExcludeModalOpen(event.isModalOpen);

          if (event.updated) {
            actions.dataRefetch();
          }
        }}
        narrativeId={actions.id}
        narrativeName={actions.name}
      />
      <IncludeNarrativeModal
        isModalOpen={isIncludeModalOpen}
        onChange={event => {
          setIsIncludeModalOpen(event.isModalOpen);

          if (event.updated) {
            actions.dataRefetch();
          }
        }}
        narrativeId={actions.id}
        narrativeName={actions.name}
      />
    </Box>
  );
};

NarrativeTableActions.propTypes = {
  actions: PropTypes.shape().isRequired
};

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see NarrativeTableActions
 * @name customActionsRender
 * @param {Object} actions - Object passed from @see MUIDataTable
 * @return {Object} - React JSX
 */
const customActionsRender = actions => {
  return <NarrativeTableActions actions={actions} />;
};

export default customActionsRender;
