import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

export const LEAD_FIELD_NAMES = `
  id
  first_name
  last_name
  title
  company
  phone
  email
  website
  address
  alembic_contact_id
  alembic_contact_status
  annual_revenue
  billing_city
  billing_country
  billing_postal_code
  billing_state
  billing_street
  city
  country
  date_of_birth
  department
  do_not_call_reason
  do_not_call
  email_invalid_cause
  email_invalid
  facebook_display_name
  facebook_id
  facebook_photo_url
  facebook_profile_url
  facebook_reach
  facebook_referred_enrollments
  facebook_referred_visits
  fax
  gender
  industry
  inferred_city
  inferred_company
  inferred_country
  inferred_metropolitan_area
  inferred_phone_area_code
  inferred_postal_code
  inferred_state_region
  instagram_display_name
  instagram_profile_url
  instagram_reach
  instagram_referred_enrollments
  instagram_referred_visits
  instagram_user_url
  is_anonymous
  last_referred_enrollment
  last_referred_visit
  lead_role
  lead_score
  lead_source
  lead_status
  linked_in_display_name
  linked_in_id
  linked_in_photo_url
  linked_in_profile_url
  linked_in_reach
  linked_in_referred_enrollments
  linked_in_referred_visits
  main_phone
  middle_name
  mobile_phone
  number_of_employees
  phone
  postal_code
  priority
  rating
  relative_score
  salutation
  sic_code
  site
  state
  syndication_id
  title
  total_referred_enrollments
  total_referred_visits
  twitter_display_name
  twitter_id
  twitter_photo_url
  twitter_profile_url
  twitter_reach
  twitter_referred_enrollments
  twitter_referred_visits
  unsubscribed_reason
  unsubscribed
  urgency
  notes {
    id
    note_text
    user_id
    created_at
    updated_at
    user {
      first_name
      last_name
    }
  }
  archived_at
  archived_by
`;

export const CREATE_LEAD = gql`
  mutation($leadFields: FieldInput!) {
    createLead(leadFields: $leadFields) {
      id
      first_name
    }
  }
`;

export const GET_LEADS = gql`
  query($count: Int!) {
    leads(count: $count) {
      ${LEAD_FIELD_NAMES}
      created_at
      updated_at
    }
  }
`;

export const GET_PAGINATED_LEADS = gql`
  query($after: Int, $count: Int, $orderBy: [String], $filter: [filterObject], $state: String, $smartFilterId: ID) {
    paginatedLeads(after: $after, count: $count, orderBy: $orderBy, filter: $filter, state: $state, smartFilterId: $smartFilterId) {
      rows {
        ${LEAD_FIELD_NAMES}
      }
      count
    }
  }
`;

export const ARCHIVE_LEADS = gql`
  mutation($ids: [ID!]!) {
    archiveLeads(ids: $ids) {
      id
      archived_at
      archived_by
    }
  }
`;

export const UNARCHIVE_LEADS = gql`
  mutation($ids: [ID!]!) {
    unarchiveLeads(ids: $ids) {
      id
      archived_at
      archived_by
    }
  }
`;

export const DELETE_LEADS = gql`
  mutation($ids: [ID!]!) {
    deleteLeads(ids: $ids)
  }
`;

export async function apiGetPaginatedLeads(data) {
  return apolloClientHelper(GET_PAGINATED_LEADS, data, false);
}

export const GET_LEAD = gql`
  query($id: ID!) {
    lead(id: $id) {
      ${LEAD_FIELD_NAMES}
    }
  }
`;

export const GET_IMPORTS = gql`
  query($count: Int, $state: LeadStateEnum) {
    leadCatalogs(count: $count, state: $state) {
      id
      batch_id
      container_id
      handle
      organization_id
    }
  }
`;

export const GET_IMPORT_PREVIEW = gql`
  query($handle: FSHandle!) {
    previewLeadCatalog(handle: $handle) {
      handle
      content
    }
  }
`;

export const GET_FILE_PREVIEW = gql`
  query($file: Upload!) {
    previewFile(file: $file) {
      filename
      preview
      mimetype
    }
  }
`;

export const IMPORT_FROM_HANDLE = gql`
  mutation($handles: [FSHandle]!) {
    createLeadBatch(handles: $handles) {
      id
      batch_id
      container_id
      created_at
      handle
      organization_id
      state
    }
  }
`;

export const GET_LEAD_NOTES = gql`
  query($lead_id: ID!, $after: Int, $count: Int, $orderBy: [String]) {
    notes(lead_id: $lead_id, after: $after, count: $count, orderBy: $orderBy) {
      id
      note_text
      user_id
      created_at
      updated_at
    }
  }
`;

export async function apiGetLeadNotes(data) {
  return apolloClientHelper(GET_LEAD_NOTES, data, false);
}

export const CREATE_LEAD_NOTE = gql`
  mutation($note_text: String!, $lead_id: ID!) {
    createNote(note_text: $note_text, lead_id: $lead_id) {
      id
      note_text
      user_id
      created_at
      updated_at
    }
  }
`;

export async function apiCreateLeadNote(data) {
  return apolloClientHelper(CREATE_LEAD_NOTE, data, true);
}

export const UPDATE_LEAD_FIELD = gql`
  mutation updateLeadField($lead_id: ID!, $field_input: FieldInput!) {
    updateLeadField(lead_id: $lead_id, field_input: $field_input) {
      ${LEAD_FIELD_NAMES}
    }
  }
`;

export async function apiUpdateLeadField(data) {
  return apolloClientHelper(UPDATE_LEAD_FIELD, data, true);
}

export const EDIT_LEAD_NOTE = gql`
  mutation($lead_id: ID!, $id: ID!, $new_text: String) {
    editNote(lead_id: $lead_id, id: $id, new_text: $new_text) {
      result
    }
  }
`;

export const DELETE_LEAD_NOTE = gql`
  mutation($lead_id: ID!, $id: ID!) {
    deleteNote(lead_id: $lead_id, id: $id) {
      result
    }
  }
`;
