import { duration as momentDuration } from 'moment';

const convertTimeToDisplay = time => {
  let { days, hours, minutes, seconds } = time;

  if (!days) {
    days = '';
  } else {
    days = `${days}:`;
  }

  if (days && hours && hours > 0 && hours < 10) {
    hours = `0${hours}:`;
  } else if (days && !hours) {
    hours = '00:';
  } else if (!days && !hours) {
    hours = '';
  } else {
    hours = `${hours}:`;
  }

  if (hours && minutes > 0 && minutes < 10) {
    minutes = `0${minutes}:`;
  } else if (hours && !minutes) {
    minutes = '00:';
  } else if (!hours && !minutes) {
    minutes = '0:';
  } else {
    minutes = `${minutes}:`;
  }

  if (seconds > 0 && seconds < 10) {
    seconds = `0${seconds}`;
  }
  if (!seconds) {
    seconds = '00';
  }

  return `${days}${hours}${minutes}${seconds}`;
};

export const formatSecondsDisplay = duration => {
  // duration param is in seconds
  const safeDuration = Math.abs(duration);

  const seconds = Math.floor(safeDuration % 60);
  const minutes = Math.floor((safeDuration / 60) % 60);
  const hours = Math.floor((safeDuration / (60 * 60)) % 24);
  const days = Math.floor(safeDuration / (24 * 60 * 60));

  return `${duration >= 0 ? '' : '-'}${convertTimeToDisplay({ days, hours, minutes, seconds })}`;
};

export const formatMillisecondsDisplay = (duration, full) => {
  // duration param is in milliseconds
  const safeDuration = Math.abs(duration);

  const seconds = Math.floor((safeDuration / 1000) % 60);
  const minutes = Math.floor((safeDuration / (1000 * 60)) % 60);
  const hours = full
    ? Math.floor(safeDuration / (1000 * 60 * 60))
    : Math.floor((safeDuration / (1000 * 60 * 60)) % 24);

  return `${duration >= 0 ? '' : '-'}${convertTimeToDisplay({ hours, minutes, seconds })}`;
};

export const formatHumanReadableTime = durationInMs => {
  const durationFormat = momentDuration(durationInMs);

  // eslint-disable-next-line no-underscore-dangle
  const { days, hours, minutes, months, seconds, years } = durationFormat._data;

  if (years) {
    return `${years ? `${years}y` : ''} ${months ? `${months}Mo` : ''}`;
  }

  if (months) {
    return `${months ? `${months}Mo` : ''} ${days ? `${days}d` : ''}`;
  }

  if (days) {
    return `${days ? `${days}d` : ''} ${hours ? `${hours}h` : ''}`;
  }

  if (hours) {
    return `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''}`;
  }

  if (minutes) {
    return `${minutes ? `${minutes}m` : ''} ${seconds ? `${seconds}s` : ''}`;
  }

  if (seconds) {
    return `${seconds ? `${seconds}s` : ''}`;
  }

  return '';
};
