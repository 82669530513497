import React from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

/**
 * @file Universal toast system
 *
 * @module ToastContext
 *
 * @example <caption>Show a success toast</caption>
 * showToast('Saved successfully', 'success');
 *
 * @example <caption>Show a warning toast that auto-closes after 2 seconds</caption>
 * showToast('New update available, refresh when ready', 'warning', 2000);
 *
 * @example <caption>Show an error toast</caption>
 * showToast('Error while saving', 'error');
 */

toast.configure();

/**
 * @method
 * @summary Show a toast on screen
 * @param {String} message - Message for the toast
 * @param {String} type - Toast type (success, warning, or error)
 * @param {Number} [autoClose=8000] - Auto-close after x milliseconds
 */
export const showToast = (message, type, autoClose) => {
  if (type === 'error') {
    Sentry.captureEvent({
      message: `Error Toast: ${message}`
    });
  }

  toast[type](message, {
    position: 'top-right',
    autoClose: autoClose || 8000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false
  });
};

const ToastContext = React.createContext({
  showToast
});

export default ToastContext;
