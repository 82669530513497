const styles = {
  card: {
    width: '100%',
    background: '#ffffff',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    marginBottom: '50px'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 24px 27px 24px'
  },
  cardHeader: {
    marginBottom: '30px'
  },
  cardHeaderItem: {
    display: 'flex'
  },
  headerMainText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#0A1734'
  },
  headerSubText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#6F6F6F'
  },
  cardBodyItem: {
    height: '152px',
    minWidth: '155px',
    flex: '1 1 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '&:nth-child(odd)': {
      background: 'rgba(151, 151, 151, 0.1)'
    }
  },
  bodyItemHeader: {
    height: '40%',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '15px',
    color: '#0D1A3A',
    paddingTop: '25px'
  },
  bodyItemBody: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '36px',
    color: '#32327D'
  }
};

export default styles;
