import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog } from '@material-ui/core';
import AnalyticsHeader from 'components/AnalyticsHeader';
import AnalyticsPlaceholder from 'components/AnalyticsPlaceholder';
import SubheaderTabs from 'components/AnalyticsHeader/SubheaderTabs';
import { tabs, socialBroadcastSubTabs } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import { parseQueryString, setQueryStringForAnalyticsModal, goToRoute } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import {
  getInitialAnalyticsStartDate,
  getInitialAnalyticsEndDate,
  checkValidDate
} from 'util/date';
import { AnalyticsPath, DashboardSocialPathV4 } from 'util/paths';
import { socialBroadcastPlatforms } from 'util/social';
import Calendar from 'views/Calendar';
import AnalyticsInsights from 'components/AnalyticsInsights';
import SocialImpactOverview from 'components/AnalyticsSocialImpact/SocialImpactOverview';
import AnalyticsOverview from './AnalyticsOverview';
import AnalyticsPerformance from './AnalyticsPerformance';
import AnalyticsDemographics from './AnalyticsDemographics';
import AnalyticsVideoOverview from './AnalyticsVideoOverview';
import AnalyticsVideoPerformance from './AnalyticsVideoPerformance';

const style = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  }
});

const AnalyticsSocial = props => {
  const { currentUser, history, location } = props;

  const classes = style();

  const [activeLowerTab, setActiveLowerTab] = useState(tabs.overview);
  const [selectedAccounts, setSelectedAccounts] = useState([]); // This is the list of all selected accounts within the drop down
  const [placeholder, setPlaceholder] = useState(false); // this is set to true if no accounts are linked within the current container
  const [calRange, changeCalRange] = useState({
    // dates are passed into graphs separately and are updated through this state call.
    start: getInitialAnalyticsStartDate(7, currentUser, 'days'),
    end: getInitialAnalyticsEndDate(currentUser)
  });
  const dialogRef = useRef();

  // After component has mounted check the URL parameters for a different start/end date than the default
  useEffect(() => {
    setPageTitle('Social Media');

    if (location?.search) {
      const startDate = parseQueryString('start_date', location);
      const endDate = parseQueryString('end_date', location);

      if (startDate[0] && endDate[0]) {
        const newStartDate = new Date(moment(startDate[0]));
        const newEndDate = new Date(moment(endDate[0]));

        if (
          checkValidDate(newStartDate) &&
          checkValidDate(newEndDate) &&
          newStartDate < newEndDate
        ) {
          // If the dates do not match then set the date picker range to the dates from the URL
          if (calRange.start !== newStartDate || calRange.end !== newEndDate) {
            changeCalRange({ start: newStartDate, end: newEndDate });
          }
        }
      }
    }
  }, []);

  // Update URL parameters when selected accounts changes
  // Update URL parameters when date range changes
  useEffect(() => {
    setQueryStringForAnalyticsModal('account', [selectedAccounts.map(({ id }) => id)]);
    setQueryStringForAnalyticsModal('start_date', [moment(calRange.start).format('YYYY-MM-DD')]);
    setQueryStringForAnalyticsModal('end_date', [moment(calRange.end).format('YYYY-MM-DD')]);
  }, [selectedAccounts, calRange]);

  return (
    <HasAnyPriv
      privs={[
        'SVC:CHAN:STATS',
        'SVC:CHAN:STATS:LI',
        'SVC:CHAN:STATS:FB',
        'SVC:CHAN:STATS:TW',
        'SVC:CHAN:STATS:IN',
        'SVC:CHAN:STATS:EM',
        'SVC:CHAN:STATS:DM'
      ]}
      showAlert
    >
      <Dialog fullScreen open classes={{ paperFullScreen: classes.root }} ref={dialogRef}>
        <Grid container justifyContent="center">
          <AnalyticsHeader
            displayTitle="Social Media"
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            setPlaceholder={setPlaceholder}
            calRange={calRange}
            changeCalRange={changeCalRange}
            onClose={() => {
              const searchParams = new URLSearchParams(location.search);

              if (searchParams.has('from_dash')) {
                goToRoute(DashboardSocialPathV4, history);
              } else {
                goToRoute(AnalyticsPath, history);
              }
            }}
            dialogRef={dialogRef}
            location={location}
            delta
            platformFilter={socialBroadcastPlatforms.map(({ name }) => name)}
          />
          <SubheaderTabs
            tabValues={socialBroadcastSubTabs}
            activeTab={activeLowerTab}
            callback={setActiveLowerTab}
          />
          <Grid item xs={9}>
            <Box my={50}>
              {selectedAccounts?.length > 0 && (
                <>
                  {activeLowerTab === tabs.overview && (
                    <AnalyticsOverview accounts={selectedAccounts} dates={calRange} />
                  )}
                  {activeLowerTab === tabs.calendar && <Calendar />}
                  {activeLowerTab === tabs.insights && (
                    <AnalyticsInsights accounts={selectedAccounts} dates={calRange} />
                  )}
                  {activeLowerTab === tabs.impact && (
                    <SocialImpactOverview
                      selectedAccounts={selectedAccounts}
                      selectedDates={calRange}
                    />
                  )}
                  {activeLowerTab === tabs.performance && (
                    <AnalyticsPerformance
                      accounts={selectedAccounts}
                      dates={calRange}
                      userTimeZone={currentUser.time_zone}
                    />
                  )}
                  {activeLowerTab === tabs.demographics && (
                    <AnalyticsDemographics
                      accounts={selectedAccounts}
                      dates={calRange}
                      userTimeZone={currentUser.time_zone}
                    />
                  )}
                  {activeLowerTab === tabs.videoOverview && (
                    <AnalyticsVideoOverview
                      accounts={selectedAccounts}
                      dates={calRange}
                      userTimeZone={currentUser.time_zone}
                    />
                  )}
                  {activeLowerTab === tabs.videoPerformance && (
                    <AnalyticsVideoPerformance
                      accounts={selectedAccounts}
                      dates={calRange}
                      userTimeZone={currentUser.time_zone}
                    />
                  )}
                </>
              )}
              {placeholder && <AnalyticsPlaceholder history={history} />}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </HasAnyPriv>
  );
};

AnalyticsSocial.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(AnalyticsSocial);
