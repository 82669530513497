import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, ClickAwayListener } from '@material-ui/core';
import { MoreVert as Actions } from '@material-ui/icons';
import Box from 'components/Box';
import RenameThirdPartyMediaReportModal from 'components/AlembicModalForm/RenameThirdPartyMediaReportModal';
import ArchiveArticleSearchModal from 'components/AlembicModalConfirm/ArchiveArticleSearchModal';
import UnarchiveArticleSearchModal from 'components/AlembicModalConfirm/UnarchiveArticleSearchModal';
import DeleteArticleSearchModal from 'components/AlembicModalConfirm/DeleteArticleSearchModal';
import DetectingArticleSearchModal from 'components/AlembicModalConfirm/DetectingArticleSearchModal';
import EarnedReportFilterModal from 'components/AlembicModalConfirm/EarnedReportFilterModal';
import DetectingArticleSearchFilterModal from 'components/AlembicModalConfirm/DetectingArticleSearchFilterModal';
import { WEB } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';
import HasAnyPriv from 'components/HasAnyPriv';
import { goToRoute } from 'util/routesHelpers';
import { withRouter } from 'react-router-dom';
import { CreateEarnedMediaAnalyticsPath } from 'util/paths';
import { connect } from 'react-redux';
import isFeatureVisible from 'util/isFeatureVisible';

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    direction: 'rtl',
    textAlign: 'center'
  },
  actions: {
    position: 'absolute',
    alignItems: 'start',
    textAlign: 'left'
  },
  actionsMenu: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: 'white',
    padding: '25px',
    zIndex: 1
  },
  actionsOptions: {
    marginRight: '20px',

    '& div': {
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },

    '& :not(:last-child)': {
      marginBottom: '30px'
    }
  }
});

/**
 * @method
 * @summary This component renders vertical (...) icon with a list of options when clicked @see EarnedMediaTable
 * @name EarnedMediaTableActions
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.isEnabled - A boolean value for the article search enabled state
 * @param {Object} props.isDetecting - A boolean value for the article search detecting events state
 * @param {string} props.searchId - A string value for the article search id
 * @param {string} props.searchName - A string value for the article search name
 * @param {Function} props.queryRefetch - A function to refetch the data in the table
 * @param {Object} props.history - the history object from React router.
 * @param {string} props.keywords - if a search filter is passed in, display current list of included keywords
 * @param {Boolean} props.filterId - if a search filter is passed in, provides its id
 * @return {Object} - React JSX
 */

const mapStateToProps = state => ({
  featureFlags: state.auth.featureFlags
});

const EarnedMediaTableActions = connect(mapStateToProps)(
  withRouter(props => {
    const {
      isEnabled,
      isDetecting,
      isDetectingFilter,
      searchId,
      searchName,
      queryRefetch,
      history,
      keywords,
      filterId,
      featureFlags
    } = props;

    const [displayedActions, setDisplayedActions] = useState();
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDetectingModalOpen, setIsDetectingModalOpen] = useState(false);
    const [isReportFilterModalOpen, setIsReportFilterModalOpen] = useState(false);
    const [isDetectingFilterModalOpen, setIsDetectingFilterModalOpen] = useState(false);
    const classes = useStyles();

    const actions = [
      {
        label: 'Rename',
        action: () => {
          setIsRenameModalOpen(!isRenameModalOpen);
        }
      }
    ];

    if (!filterId) {
      actions.push({
        label: 'Edit & Save As New',
        action: () => {
          // route to and pre-fill SmartFiltersForm
          goToRoute(`${CreateEarnedMediaAnalyticsPath}/?id=${searchId}`, history);
        }
      });
    }

    if (isFeatureVisible(featureFlags, 'ArticleSearchFiltersToggle')) {
      actions.push({
        label: `${filterId ? 'Edit' : 'Create'} Report Filter`,
        action: () => {
          setIsReportFilterModalOpen(!isReportFilterModalOpen);
        }
      });
    }

    if (isEnabled && !filterId) {
      actions.push({
        label: 'Archive',
        action: () => {
          setIsArchiveModalOpen(!isArchiveModalOpen);
        }
      });
    } else {
      if (!filterId) {
        actions.push({
          label: 'Unarchive',
          action: () => {
            setIsUnarchiveModalOpen(!isUnarchiveModalOpen);
          }
        });
      }

      actions.push({
        label: 'Delete',
        action: () => {
          setIsDeleteModalOpen(!isDeleteModalOpen);
        }
      });
    }

    if (isEnabled && !isDetecting) {
      actions.push({
        color: '#00A84F',
        label: 'Detect Events',
        action: () => {
          setIsDetectingModalOpen(!isDetectingModalOpen);
        }
      });
    }

    if (isEnabled && isDetecting) {
      actions.push({
        color: 'red',
        label: 'Stop Detecting Events',
        action: () => {
          setIsDetectingModalOpen(!isDetectingModalOpen);
        }
      });
    }

    if (filterId != null) {
      if (!isDetectingFilter) {
        actions.push({
          color: '#00A84F',
          label: 'Detect Events',
          action: () => {
            setIsDetectingFilterModalOpen(!isDetectingFilterModalOpen);
          }
        });
      }

      if (isDetectingFilter) {
        actions.push({
          color: 'red',
          label: 'Stop Detecting Events',
          action: () => {
            setIsDetectingFilterModalOpen(!isDetectingFilterModalOpen);
          }
        });
      }
    }

    return (
      <Box className={classes.actionsWrapper}>
        {displayedActions && (
          <Grid container wrap="nowrap" className={classes.actions}>
            <ClickAwayListener onClickAway={() => setDisplayedActions(false)}>
              <Paper className={classes.actionsMenu}>
                <Grid item className={classes.actionsOptions}>
                  {actions.map(action => (
                    <Grid
                      item
                      key={action.label}
                      style={action.color && { color: action.color }}
                      onClick={e => {
                        e.stopPropagation();
                        action.action();
                        setDisplayedActions(false);
                      }}
                    >
                      {action.label}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </ClickAwayListener>
          </Grid>
        )}
        <IconButton
          size="small"
          style={displayedActions ? { zIndex: 2 } : {}}
          onClick={e => {
            e.stopPropagation();
            setDisplayedActions(true);
          }}
        >
          <Actions />
        </IconButton>
        <ArchiveArticleSearchModal
          isModalOpen={isArchiveModalOpen}
          onChange={event => {
            setIsArchiveModalOpen(event.isModalOpen);
            if (event?.newArticleSearchData) {
              queryRefetch();
            }
          }}
          articleSearchId={searchId}
          articleSearchName={searchName}
        />
        <UnarchiveArticleSearchModal
          isModalOpen={isUnarchiveModalOpen}
          onChange={event => {
            setIsUnarchiveModalOpen(event.isModalOpen);
            if (event?.newArticleSearchData) {
              queryRefetch();
            }
          }}
          articleSearchId={searchId}
          articleSearchName={searchName}
        />
        <DeleteArticleSearchModal
          isModalOpen={isDeleteModalOpen}
          onChange={event => {
            setIsDeleteModalOpen(event.isModalOpen);
            if (event?.newArticleSearchData) {
              queryRefetch();
            }
          }}
          articleSearchId={filterId ? null : searchId}
          articleSearchName={searchName}
          filterId={filterId}
        />
        <DetectingArticleSearchModal
          isModalOpen={isDetectingModalOpen}
          onChange={event => {
            setIsDetectingModalOpen(event.isModalOpen);
            if (event?.newArticleSearchData) {
              queryRefetch();
            }
          }}
          articleSearchId={searchId}
          articleSearchName={searchName}
          setDetecting={!isDetecting}
        />
        <DetectingArticleSearchFilterModal
          isModalOpen={isDetectingFilterModalOpen}
          onChange={event => {
            setIsDetectingFilterModalOpen(event.isModalOpen);
            if (event?.newArticleSearchFilterData) {
              queryRefetch();
            }
          }}
          articleSearchFilterId={filterId}
          articleSearchName={searchName}
          setDetecting={!isDetectingFilter}
        />
        <EarnedReportFilterModal
          isModalOpen={isReportFilterModalOpen}
          onChange={e => {
            setIsReportFilterModalOpen(e.isModalOpen);
            if (e?.newArticleSearchData) {
              queryRefetch();
            }
          }}
          articleSearchId={searchId}
          filterId={filterId}
          articleSearchName={searchName}
          keywords={keywords}
        />
        {isRenameModalOpen && (
          <RenameThirdPartyMediaReportModal
            isModalOpen={isRenameModalOpen}
            onChange={event => {
              setIsRenameModalOpen(event.isModalOpen);
              if (event?.updateArticleSearch) {
                queryRefetch();
              }
            }}
            reportId={searchId}
            reportName={searchName}
            mediaType={WEB}
          />
        )}
      </Box>
    );
  })
);

EarnedMediaTableActions.propTypes = {
  isEnabled: PropTypes.bool,
  isDetecting: PropTypes.bool,
  isDetectingFilter: PropTypes.bool,
  searchId: PropTypes.string,
  searchName: PropTypes.string.isRequired,
  queryRefetch: PropTypes.func.isRequired,
  filterId: PropTypes.string
};

EarnedMediaTableActions.defaultProps = {
  searchId: null,
  filterId: null,
  isEnabled: false,
  isDetecting: false,
  isDetectingFilter: false
};

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see EarnedMediaTableActions
 * @name customActionsRender
 * @param {Object} actions - Object passed from @see MUIDataTable
 * @param {Boolean} actions.isEnabled - A boolean value for the article search enabled state
 * @param {Boolean} actions.isDetecting - A boolean value for the article search detecting state
 * @param {Boolean} actions.isDetectingFilter - A boolean value for the article search filter detecting state
 * @param {string} props.searchId - A string value for the article search id
 * @param {string} props.searchName - A string value for the article search name
 * @param {Function} props.queryRefetch - A function to refetch the data in the table
 * @param {string} props.filterId - if a search filter is passed in, provides its id
 * @param {string} props.keywords - if a search filter is passed in, display current list of includes keywords
 * @return {Object} - React JSX
 */
const customActionsRender = actions => {
  return (
    <HasAnyPriv
      privs={[
        'TVEYES',
        'TVEYES:SEARCH',
        'TVEYES:SEARCH:CREATE',
        'TVEYES:SEARCH:DELETE',
        'TVEYES:SEARCH:UPDATE'
      ]}
    >
      <EarnedMediaTableActions {...actions} />
    </HasAnyPriv>
  );
};

export default customActionsRender;
