/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Tabs, Select, MenuItem, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { kpiLabels } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import { formatDateRange } from 'util/date';
import colors from 'util/colors';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import TabWithTooltip from 'components/TabWithTooltip';
import AlbSwitch from 'components/AlbSwitch';

const useStyles = makeStyles({
  root: {
    maxWidth: 'unset'
  },
  tab: {
    fontSize: '18px',
    fontWeight: 500,
    padding: '24px',
    whiteSpace: 'nowrap',
    maxWidth: 'unset',
    color: colors.navy
  },
  tabsIndicator: {
    display: 'none'
  },
  select: {
    border: '1px solid #0A1734',
    borderRadius: '2.375px',
    marginLeft: '10px',
    marginRight: '20px',
    fontFamily: 'Poppins',
    fontSize: '15px',
    height: '35px',

    '&::before': {
      display: 'none'
    },

    '& :first-child': {
      padding: '8px 10px',
      paddingRight: '30px'
    }
  },
  option: {
    fontSize: '14px'
  },
  headerDate: {
    fontSize: '18px',
    fontWeight: 500,
    padding: '24px',
    whiteSpace: 'nowrap',
    maxWidth: 'unset',
    color: colors.navy
  },
  dateText: {
    color: '#6f6f6f'
  }
});

const Dropdown = props => {
  const { options, onChange, onOpen, themeDropdownOpen, placeholder } = props;

  const classes = useStyles();

  const [value, setValue] = useState(placeholder ? ' ' : options[0].id);

  // clear topic dropdown on theme selection to prevent out of range error on providing a new list of topics
  useEffect(() => {
    if (themeDropdownOpen) {
      setValue(' ');
    }
  }, [themeDropdownOpen]);

  return (
    <Select
      autoWidth
      value={value}
      className={classes.select}
      onChange={e => {
        onChange(e.target.value);
        setValue(e.target.value);

        if (onOpen) onOpen(false);
      }}
      onOpen={() => {
        if (onOpen) onOpen(true);
      }}
    >
      {placeholder && (
        <MenuItem hidden disabled selected value={' '} className={classes.option}>
          {placeholder}
        </MenuItem>
      )}
      {options.map(option => (
        <MenuItem key={option.id} value={option.id} className={classes.option}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  themeDropdownOpen: PropTypes.bool
};

Dropdown.defaultProps = {
  onOpen: null,
  themeDropdownOpen: false
};

const TabItem = ({ tab, tooltip, breakdown, onTabChange, ...props }) => {
  // props are passed from the 'Tabs' component into child 'Tab' components to enable onChange functionality
  const classes = useStyles();
  return tooltip?.length ? (
    <Tab
      label={<TabWithTooltip tabTitle={tab} tooltipTitle={tooltip} />}
      classes={{ ...(breakdown && { root: classes.root }) }}
      className={classes.tab}
      style={{ ...(!onTabChange && { cursor: 'default' }) }}
      {...props}
    />
  ) : (
    <Tab
      label={tab}
      classes={{ ...(breakdown && { root: classes.root }) }}
      className={classes.tab}
      style={{ ...(!onTabChange && { cursor: 'default' }) }}
      {...props}
    />
  );
};

const BoxHeader = props => {
  const {
    tabs,
    onTabChange,
    kpi,
    campaigns,
    themes,
    topics,
    switches,
    kpiOnChange,
    selectCampaign,
    selectTheme,
    selectTopic,
    isSwitchChecked,
    setIsSwitchChecked,
    setMetric,
    setFilter,
    breakdown,
    dates,
    prefix
  } = props;

  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const [themeDropdownOpen, setThemeDropdownOpen] = useState(false);

  useEffect(() => {
    if (onTabChange) {
      onTabChange(activeTab);
    }
  }, [activeTab]);

  return (
    <Grid container alignItems="center" justifyContent="space-between" direction="row">
      <Tabs
        value={activeTab}
        classes={{ ...(breakdown && { indicator: classes.tabsIndicator }) }}
        onChange={(e, value) => {
          setActiveTab(value);

          // only applies to KPI section
          if (setMetric) {
            if (tabs[value] === kpiLabels.impressions) {
              setMetric('lifetime_impressions');
            }

            if (tabs[value] === kpiLabels.engagement) {
              setMetric('lifetime_engagement');
            }

            if (tabs[value] === kpiLabels.shares) {
              setMetric('lifetime_shares');
            }

            if (tabs[value] === kpiLabels.videoViews) {
              setMetric('lifetime_views');
            }

            if (tabs[value] === kpiLabels.minutesWatched) {
              setMetric('lifetime_watched_ms');
            }
          }

          if (setFilter) {
            setFilter(value);
          }
        }}
      >
        {[tabs].flat().map(tab => (
          <TabItem
            key={tab}
            tab={tab}
            tooltip={getToolTipTitleValue(tab, prefix)}
            breakdown={breakdown}
            onTabChange={onTabChange}
          />
        ))}
      </Tabs>
      <Grid>
        {!!switches.length && (
          <Box display="inline" alignItems="center">
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              {switches[0]}
            </Typography>
            <AlbSwitch
              onChange={event => setIsSwitchChecked(event.target.checked)}
              checked={isSwitchChecked}
            />
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              {switches[1]}
            </Typography>
          </Box>
        )}

        {!!kpi?.length && <Dropdown options={kpi} onChange={kpiOnChange} />}
        {!!campaigns?.length && (
          <Dropdown options={campaigns} onChange={selectCampaign} placeholder="Select a Campaign" />
        )}
        {!!themes?.length && (
          <Dropdown
            options={themes}
            onChange={selectTheme}
            onOpen={setThemeDropdownOpen}
            placeholder="Select a Theme"
          />
        )}
        {!!topics?.length && (
          <Dropdown
            options={topics}
            onChange={selectTopic}
            themeDropdownOpen={themeDropdownOpen}
            placeholder="Select a Topic"
          />
        )}
        {dates && (
          <Typography
            align="center"
            className={classes.headerDate}
            display={switches.length ? 'inline' : 'block'}
          >
            {`Dates: `}
            <span className={classes.dateText}>{formatDateRange(dates.start, dates.end)}</span>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

BoxHeader.propTypes = {
  tabs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  onTabChange: PropTypes.func,
  kpi: PropTypes.arrayOf(PropTypes.shape()),
  campaigns: PropTypes.arrayOf(PropTypes.shape()),
  themes: PropTypes.arrayOf(PropTypes.shape()),
  topics: PropTypes.arrayOf(PropTypes.shape()),
  switches: PropTypes.arrayOf(PropTypes.string),
  isSwitchChecked: PropTypes.bool,
  kpiOnChange: PropTypes.func,
  selectCampaign: PropTypes.func,
  selectTheme: PropTypes.func,
  selectTopic: PropTypes.func,
  setMetric: PropTypes.func,
  setFilter: PropTypes.func,
  setIsSwitchChecked: PropTypes.func,
  breakdown: PropTypes.bool,
  dates: PropTypes.shape()
};

BoxHeader.defaultProps = {
  onTabChange: null,
  kpi: [],
  campaigns: [],
  themes: [],
  topics: [],
  switches: [],
  isSwitchChecked: null,
  kpiOnChange: null,
  selectCampaign: null,
  selectTheme: null,
  selectTopic: null,
  setMetric: null,
  setFilter: null,
  setIsSwitchChecked: null,
  breakdown: false,
  dates: null
};

export default BoxHeader;
