/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { RECREATE_COST_REPORT_CAMPAIGN_EVENTS } from 'gql/recreateCostReport';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbTable from 'components/AlbTable/AlbTable';
import IgnorePostFromCTRModal from 'components/AlembicModalConfirm/IgnorePostFromCTRModal';
import SomethingMissingModal from 'components/AlembicModalConfirm/SomethingMissingModal';
import AssetThumbnail from 'components/AssetThumbnail';
import Box from 'components/Box';
import { PostDetailScreenshot as postScreenshot } from 'util/assets';
import { convertToUserTimeZone } from 'util/date';
import { formatCurrencyRound } from 'util/formatCurrency';
import { socialIconForChannel } from 'util/social';
import getValueAbbreviation from 'util/getValueAbbreviation';
import colors from 'util/colors';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  event: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    height: '58px',
    width: '58px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    marginRight: '9px'
  },
  thumbnail: {
    objectFit: 'cover',
    width: '58px',
    height: '58px',
    borderRadius: '3px'
  },
  eventText: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.navy,
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  domain: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.navy
  },
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  exclude: {
    backgroundColor: 'transparent',
    padding: '8px 18px',

    '&:hover': {
      backgroundColor: colors.red,
      borderColor: colors.red,
      color: 'white'
    }
  },
  include: {
    backgroundColor: colors.navy,
    padding: '8px 18px',

    '&:hover': {
      backgroundColor: colors.navy
    }
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  missingButton: {
    margin: '10px 22px',
    border: '1px solid #0D1A3A',
    padding: '0px 9px 0px 9px'
  },
  missingButtonLabel: {
    whiteSpace: 'nowrap',
    color: '#0D1A3A',
    margin: '8px 18px 8px 18px'
  },
  somethingMissingText: {
    fontSize: '13px',
    color: '#585858',
    weight: 500
  },
  placeholderImg: {
    marginTop: '23px'
  }
});

/**
 * Table for owned media in the CTR report
 * @name MediaValuationOwned
 * @param {Object} props - React props passed to this component
 * @param {Object} props.currentUser - An object containing the current user data from Redux
 * @param {string} props.recreateCostReportId - A string value for the CTR report id
 * @param {String} props.valuationType - The type of 'cpm' or 'cpa' calculation
 * @param {Object} props.dates - An object containing start and end dates
 * @param {String} props.filter - A string used in the query to filter out excluded posts
 * @param {Boolean} props.useLifetimeValue - Boolean flag indicating whether to use lifetime values or not
 * @return {Object} - React JSX
 */
const MediaValuationOwned = props => {
  const {
    currentUser,
    recreateCostReportId,
    valuationType,
    dates,
    filter,
    useLifetimeValue,
    refetchGraph
  } = props;

  const drawerContext = useContext(PostDrawerViewContext);
  const classes = useStyles();

  const isExcludedTab = filter === 'ONLY';

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(
    valuationType === 'cpm' ? ['lifetime_impressions', 'desc'] : ['lifetime_shares', 'desc']
  );

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openIgnoreModal, toggleOpenIgnoreModal] = useState(false);
  const [somethingMissingModal, toggleSomethingMissingModal] = useState(false);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  const { loading, error, data, refetch: refetchTable } = useQuery(
    RECREATE_COST_REPORT_CAMPAIGN_EVENTS,
    {
      variables: {
        id: recreateCostReportId,
        count: rowsPerPage,
        after: page * rowsPerPage,
        orderBy: sortOrder,
        valuationType,
        ignoredArticleFilter: filter,
        useLifetimeValue,
        ...dates
      },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (data?.recreateCostReport?.paginatedCampaignEvents?.rows) {
      setTableData(
        data.recreateCostReport.paginatedCampaignEvents.rows.map(
          ({ campaignEvent, valuation, impressions, shares }) => {
            return {
              ...campaignEvent,
              post:
                campaignEvent?.type === 'YOUTUBE_VIDEO'
                  ? campaignEvent.event_title
                  : campaignEvent.event_body,
              valuation,
              impressions,
              shares
            };
          }
        )
      );
      setCount(data.recreateCostReport.paginatedCampaignEvents.count);
    }
  }, [data]);

  const customPostRender = (inputText, { rowData }) => {
    // Sanitize the inputText, so that if it's null we just show an empty string below and don't blow up on null
    const text = inputText ?? '';
    const [id, assets, linkedAccount, thumbnailUrl] = rowData;

    const socialIcon = (
      <img
        role="presentation"
        alt="logo"
        src={socialIconForChannel(linkedAccount.type)}
        style={{ width: 28, height: 28 }}
      />
    );

    const Assets = () =>
      thumbnailUrl || assets[0] ? (
        <Grid style={{ position: 'relative', width: 58, height: 58 }}>
          <AssetThumbnail
            thumbnailUrl={thumbnailUrl}
            assets={assets}
            width={58}
            height={58}
            rounded
          />
          <div style={{ position: 'absolute', bottom: 0, right: 0 }}>{socialIcon}</div>
        </Grid>
      ) : (
        socialIcon
      );

    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <Assets />
        <Button
          style={{ backgroundColor: 'transparent' }}
          onClick={() => drawerContext.toggleDrawer(true, id)}
        >
          <Typography className={classes.eventText}>{text}</Typography>
        </Button>
      </Box>
    );
  };

  const customDateRender = date => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {`${convertToUserTimeZone(date, currentUser.time_zone).format(
        'M/DD/YY'
      )} @ ${convertToUserTimeZone(date, currentUser.time_zone).format('h:mma')}`}
    </div>
  );

  const customMetricRender = metric => (
    <div align="center">{getValueAbbreviation(metric) || '-'}</div>
  );

  const customValuationRender = valuation => (
    <div align="right">
      {valuation ? `${valuation < 0 ? '-' : ''}$${formatCurrencyRound(Math.abs(valuation))}` : '-'}
    </div>
  );

  const handleOpenIgnoreModal = id => {
    setSelectedEvent(id.toString());
    toggleOpenIgnoreModal(true);
  };

  const customExcludeRender = (_, { rowData }) => {
    const [id] = rowData;

    return (
      <div align="right">
        <Button
          className={`${!isExcludedTab ? classes.exclude : classes.include}`}
          variant={isExcludedTab ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleOpenIgnoreModal(id)}
        >
          {`${!isExcludedTab ? 'Exclude' : 'Include'}`}
        </Button>
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'assets',
      options: { display: false }
    },
    {
      name: 'linked_account',
      options: { display: false }
    },
    {
      name: 'thumbnail_url',
      options: { display: false }
    },
    {
      name: 'post',
      label: 'POST',
      options: { sort: false, customBodyRender: customPostRender }
    },
    {
      name: 'completed_at',
      label: 'DATE / TIME',
      options: { customBodyRender: customDateRender }
    },
    {
      name: 'lifetime_impressions',
      label: 'IMPRESSIONS',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        customBodyRender: customMetricRender
      }
    },
    {
      name: 'lifetime_shares',
      label: 'SHARES',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        customBodyRender: customMetricRender
      }
    },
    {
      name: 'lifetime_views',
      label: 'VIEWS',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        customBodyRender: customMetricRender
      }
    },
    ...(useLifetimeValue === false
      ? [
          {
            ...(valuationType === 'cpm' && {
              name: 'impressions',
              label: 'IMP. IN PER'
            }),
            ...(valuationType === 'cpa' && {
              name: 'shares',
              label: 'SHARES IN PER'
            }),
            options: {
              sort: false,
              setCellHeaderProps: () => ({ className: classes.centeredHeader }),
              customBodyRender: customMetricRender
            }
          }
        ]
      : []),
    {
      name: 'valuation',
      label: 'VALUE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false,
        customBodyRender: customValuationRender
      }
    },
    {
      name: '',
      label: 'EXCLUDE',
      options: {
        display: !isExcludedTab,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false,
        customBodyRender: customExcludeRender
      }
    },
    {
      name: '',
      label: 'INCLUDE',
      options: {
        display: isExcludedTab,
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false,
        customBodyRender: customExcludeRender
      }
    }
  ];

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    textLabels: {
      body: {
        noMatch: 'No posts found'
      }
    }
  };

  return (
    <div className={classes.container}>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <BoxHeader tabs="Media Valuation" breakdown />
        <Button
          variant="outlined"
          onClick={() => toggleSomethingMissingModal(true)}
          classes={{ outlined: classes.missingButton, label: classes.missingButtonLabel }}
        >
          Something Missing?
        </Button>
      </Box>
      {!!error && <AlbError error={error} />}
      {loading && (
        <Box className={classes.loading}>
          <AlbLoading />
        </Box>
      )}
      {!error && (
        <Box style={{ opacity: loading ? 0.5 : 1 }}>
          <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />
        </Box>
      )}
      <IgnorePostFromCTRModal
        isModalOpen={openIgnoreModal}
        onChange={e => {
          toggleOpenIgnoreModal(e.isModalOpen);
          refetchTable();
          refetchGraph();
        }}
        campaignEventId={selectedEvent}
        reportId={recreateCostReportId}
        exclude={!isExcludedTab}
      />
      {somethingMissingModal && (
        <SomethingMissingModal
          isModalOpen={somethingMissingModal}
          toggleModal={toggleSomethingMissingModal}
          onConfirm={() => toggleSomethingMissingModal(false)}
          confirmTitle="Close"
          isConfirmOnly
          width="459px"
        >
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography className={classes.somethingMissingText}>
                If something is missing, you can go to the single post drawer and add a post to an
                3rd Party Media report. That post will then be included in this Media Valuation
                report.
              </Typography>
              <br />
              <Typography className={classes.somethingMissingText}>
                You will click the &quot;+ Add To&quot; button, and select the report.
              </Typography>
              <br />
              <Typography className={classes.somethingMissingText}>See example below.</Typography>
            </Grid>
            <Grid item className={classes.placeholderImg}>
              <img src={postScreenshot} alt="post example" width="459px" />
            </Grid>
          </Grid>
        </SomethingMissingModal>
      )}
    </div>
  );
};

MediaValuationOwned.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  recreateCostReportId: PropTypes.string.isRequired,
  valuationType: PropTypes.string,
  dates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }).isRequired,
  filter: PropTypes.string.isRequired,
  useLifetimeValue: PropTypes.bool.isRequired,
  refetchGraph: PropTypes.func.isRequired
};

MediaValuationOwned.defaultProps = {
  valuationType: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(MediaValuationOwned);
