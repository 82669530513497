import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { FS_THUMB_URL } from 'gql/asset';

const useStyles = makeStyles({
  carouselContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px'
  },
  assetContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flex: 1
  },
  asset: {
    borderRadius: '3px'
  },
  otherAsset: {
    opacity: '0.5',
    cursor: 'pointer'
  },
  arrowButton: {
    color: '#0A1734'
  },
  arrowButtonOverride: {
    fontSize: '0.9rem'
  }
});

/**
 * @method
 * @summary This component renders a 3 image carousel with left and right navigation
 * @name SocialAssetCarousel
 * @param {Object} props - React props passed to this component
 * @param {number} props.width - The width for the asset of the carousel indicators
 * @param {number} props.height - The height for the asset of the carousel indicators
 * @param {Object[]} props.assets - The list of assets within the campaign event
 * @param {string} props.mainAssetId - The id of the big asset displayed above the carousel
 * @param {number} props.mainAssetIndex - The index of the big asset displayed above the carousel
 * @param {Function} props.setMainAssetIndex - The function to update the state of the mainAssetIndex
 * @return {Object} - React JSX
 */
const SocialAssetCarousel = props => {
  const { width, height, assets, mainAssetId, mainAssetIndex, setMainAssetIndex } = props;
  const classes = useStyles();

  let bottomBound = mainAssetIndex;
  let topBound = mainAssetIndex;

  if (mainAssetIndex === assets.length - 1) {
    bottomBound -= 2;
  } else {
    bottomBound -= 1;
  }

  if (mainAssetIndex === 0) {
    topBound += 3;
  } else {
    topBound += 2;
  }

  if (bottomBound < 0) {
    bottomBound = 0;
  }

  if (topBound > assets.length) {
    topBound = assets.length;
  }

  return (
    <div className={classes.carouselContainer}>
      <IconButton
        size="small"
        classes={{
          sizeSmall: classes.arrowButtonOverride
        }}
        className={classes.arrowButton}
        onClick={e => {
          e.stopPropagation();

          if (mainAssetIndex > 0) {
            setMainAssetIndex(mainAssetIndex - 1);
          }
        }}
        disabled={mainAssetIndex === 0}
      >
        <ArrowLeft fontSize="inherit" color="inherit" />
      </IconButton>
      <div className={classes.assetContainer}>
        {/* Assets */}
        {assets?.length > 0 &&
          assets.slice(bottomBound, topBound).map(asset => (
            <img
              role="presentation"
              key={asset.signed_thumbnail_url}
              className={`${classes.asset} ${mainAssetId !== asset.id ? classes.otherAsset : null}`}
              src={`${FS_THUMB_URL}${asset.signed_thumbnail_url}`}
              alt="Post"
              width={width}
              height={height}
              onClick={e => {
                e.stopPropagation();

                const clickedAssetIndex = assets.findIndex(({ id }) => id === asset.id);

                if (clickedAssetIndex !== mainAssetIndex) {
                  setMainAssetIndex(clickedAssetIndex);
                }
              }}
            />
          ))}
      </div>
      <IconButton
        size="small"
        classes={{
          sizeSmall: classes.arrowButtonOverride
        }}
        className={classes.arrowButton}
        onClick={e => {
          e.stopPropagation();

          if (mainAssetIndex < assets.length) {
            setMainAssetIndex(mainAssetIndex + 1);
          }
        }}
        disabled={mainAssetIndex === assets.length - 1}
      >
        <ArrowRight fontSize="inherit" color="inherit" />
      </IconButton>
    </div>
  );
};

SocialAssetCarousel.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mainAssetId: PropTypes.string.isRequired,
  mainAssetIndex: PropTypes.number.isRequired,
  setMainAssetIndex: PropTypes.func.isRequired
};

SocialAssetCarousel.defaultProps = {};

export default SocialAssetCarousel;
