export const tabs = {
  overview: 'OVERVIEW',
  performance: 'PERFORMANCE',
  demographics: 'DEMOGRAPHICS',
  videoOverview: 'VIDEO OVERVIEW',
  videoPerformance: 'VIDEO PERFORMANCE',
  sources: 'SOURCES & PAGES',
  search: 'SEARCH TERMS',
  channels: 'CHANNELS & CAMPAIGNS',
  apps: 'APPS',
  conversions: 'CONVERSIONS',
  results: 'RESULTS',
  allArticles: 'ALL ARTICLES',
  flow: 'FLOW',
  hype: 'HYPE',
  settings: 'SETTINGS',
  timeToEvent: 'CLOSE RATE',
  dealSize: 'DEAL SIZE',
  volume: 'VOLUME',
  calendar: 'CALENDAR',
  insights: 'TOP & BOTTOM POSTS',
  impact: 'RESURGING POSTS',
  survey2022: '2022 DATA',
  survey2024: '2024 DATA',
  funnel: 'FUNNEL'
};

export const kpiLabels = {
  impressions: 'Impressions',
  engagement: 'Engagement',
  shares: 'Shares',
  videoViews: 'Views',
  minutesWatched: 'Min. Watched'
};

// example = [{
//   tab: tabs.tab,               - the label shown in the tab
//   enabled: true,               - boolean denoting that a tab is enabled in dev (code complete and released to users)
//   toggle: ['GatedFeatureToggle'] - the unleash flag(s) gating this specific tab
// }]

export const socialBroadcastSubTabs = [
  { tab: tabs.overview, enabled: true },
  { tab: tabs.calendar, enabled: true },
  { tab: tabs.insights, enabled: true },
  { tab: tabs.impact, enabled: true },
  { tab: tabs.performance, enabled: true },
  { tab: tabs.demographics, enabled: true },
  { tab: tabs.videoOverview, enabled: true },
  { tab: tabs.videoPerformance, enabled: true }
];

export const webAndAppsSubTabs = [
  { tab: tabs.overview, enabled: true },
  { tab: tabs.demographics, enabled: true },
  { tab: tabs.sources, enabled: true },
  { tab: tabs.search, enabled: true },
  { tab: tabs.channels, enabled: true },
  { tab: tabs.apps, enabled: true, toggle: ['AppStoreConnectLinking', 'GooglePlayConsoleLinking'] },
  { tab: tabs.conversions, enabled: true }
];

export const earnedMediaSubTabs = [
  { tab: tabs.results, enabled: true },
  { tab: tabs.allArticles, enabled: true }
];

export const hypeAndFlowSubTabs = [
  { tab: tabs.flow, enabled: true },
  { tab: tabs.hype, enabled: true }
];

export const thirdPartyMediaTabs = [
  { tab: tabs.overview, enabled: true },
  { tab: tabs.settings, enabled: true }
];

export const salesforceTabs = [
  { tab: tabs.overview, enabled: true },
  { tab: tabs.funnel, enabled: true, toggle: ['SalesforceFunnel'] },
  { tab: tabs.timeToEvent, enabled: true },
  { tab: tabs.dealSize, enabled: true },
  { tab: tabs.volume, enabled: true }
];

export const targetingTabs = [
  { tab: tabs.survey2022, enabled: true },
  { tab: tabs.survey2024, enabled: true }
];
