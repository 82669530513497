import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { EXCLUDE_ARTICLE_FROM_COST_REPORT } from 'gql/recreateCostReport';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const IgnoreArticleFromCTRModal = props => {
  const { isModalOpen, onChange, articleId, articleTitle, reportId, exclude } = props;

  const [excludeArticleFromCostReport, { error, data }] = useMutation(
    EXCLUDE_ARTICLE_FROM_COST_REPORT
  );

  useEffect(() => {
    if (data?.excludeArticleFromCostReport?.result) {
      showToast(data.excludeArticleFromCostReport.message, 'success');
      onChange({ isModalOpen: false, newArticleData: data.excludeArticleFromCostReport });
    }
  }, [data]);

  useEffect(() => {
    if (error) onChange({ isModalOpen: false });
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        isDelete={exclude}
        title={`${exclude ? 'Exclude' : 'Include'} Article`}
        body={
          <span style={{ fontFamily: 'Poppins' }}>
            {`Are you sure you want to ${exclude ? 'exclude' : 'include'} the article:`}&nbsp;
            <span style={{ color: '#0A1734' }}>{articleTitle}</span> {`${exclude ? 'from' : 'to'}`}{' '}
            the report?
          </span>
        }
        cancelTitle="Cancel"
        confirmTitle={`${exclude ? 'Exclude' : 'Include'} Article`}
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() =>
          excludeArticleFromCostReport({ variables: { articleId, reportId, ignored: exclude } })
        }
      />
    </>
  );
};

IgnoreArticleFromCTRModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  articleId: PropTypes.string,
  articleTitle: PropTypes.string,
  reportId: PropTypes.string,
  exclude: PropTypes.bool
};

IgnoreArticleFromCTRModal.defaultProps = {
  articleId: null,
  articleTitle: null,
  reportId: null,
  exclude: false
};

export default IgnoreArticleFromCTRModal;
