import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { AlbTooltip } from 'components/AlbTooltip';

const TabWithTooltip = props => {
  const { tabTitle, tooltipTitle } = props;

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography style={{ marginRight: '8px', fontSize: 18, fontWeight: 500 }}>
        {tabTitle}
      </Typography>
      <AlbTooltip title={tooltipTitle} placement="top" />
    </Grid>
  );
};

TabWithTooltip.propTypes = {
  tabTitle: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string.isRequired
};

export default TabWithTooltip;
