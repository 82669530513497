import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { CREATE_OR_UPDATE_SALESFORCE_REVENUE_NOTE } from 'gql/salesforce';
import Box from 'components/Box';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import modalStyles from './modalStyles';

const styles = makeStyles({
  ...modalStyles,
  error: {
    color: '#E81828'
  },
  textfield: {
    '& > div': { width: '100%' }
  },
  actions: {
    marginTop: '24px',
    '& button:last-child': {
      marginLeft: '20px'
    },

    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const refetchQueries = ['getSalesforceRevenueTopPerformers'];

const SalesforceRevenueNoteModal = props => {
  const { id, note, isModalOpen, onChange } = props;

  const [noteText, setNoteText] = useState(note);

  const classes = styles();

  useEffect(() => {
    ValidatorForm.addValidationRule('isMaxLength', value => value.length <= 255);
  }, []);

  const [
    createOrUpdateNote,
    { loading, error, data }
  ] = useMutation(CREATE_OR_UPDATE_SALESFORCE_REVENUE_NOTE, { refetchQueries });

  useEffect(() => {
    if (data?.createOrUpdateSalesforceRevenueNote) {
      showToast(`Note ${note != null ? 'updated' : 'added'}.`, 'success');
      setNoteText('');
      onChange({ isModalOpen: false });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const closeModal = () => {
    setNoteText('');
    onChange({ isModalOpen: false });
  };

  return (
    <>
      <Dialog maxWidth="sm" open={isModalOpen} onClose={closeModal}>
        <ValidatorForm
          onSubmit={() => {
            createOrUpdateNote({ variables: { id, note: noteText } });
          }}
        >
          <Box p={12}>
            <Grid container>
              <Grid item container>
                <Typography className={classes.title}>
                  {`${note != null ? 'Edit' : 'Add'} Note`}
                </Typography>
              </Grid>
              <Grid item container style={{ marginTop: '12px' }}>
                <Typography className={classes.label}>Note</Typography>
              </Grid>
              <Grid className={classes.textfield} item container>
                <TextValidator
                  autoFocus
                  fullWidth
                  multiline
                  rows={2}
                  variant="filled"
                  placeholder="Enter Note Text"
                  value={noteText}
                  onChange={event => setNoteText(event.target.value)}
                  validators={['required', 'isMaxLength']}
                  errorMessages={[
                    '* Required',
                    `Must not exceed 255 characters in length. Current length: ${noteText?.length ||
                      0}`
                  ]}
                />
              </Grid>
              <Grid item container justifyContent="space-between" className={classes.actions}>
                <span>
                  <Button size="large" variant="outlined" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    Save
                  </Button>
                </span>
              </Grid>
            </Grid>
          </Box>
        </ValidatorForm>
      </Dialog>
    </>
  );
};

SalesforceRevenueNoteModal.propTypes = {
  id: PropTypes.string.isRequired,
  note: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

SalesforceRevenueNoteModal.defaultProps = {
  note: null
};

export default SalesforceRevenueNoteModal;
