import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AlbProgressCircle from 'components/AlbGraphs/AlbProgressCircle';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';

const useItemStyles = makeStyles({
  liftRow: {
    marginBottom: '10px'
  },
  liftTitle: {
    fontWeight: '500',
    fontSize: '22px',
    color: '#32327D'
  },
  baseTitle: {
    fontWeight: '500',
    fontSize: '22px',
    color: '#6F6F6F'
  },
  percent: {
    fontWeight: '500',
    fontSize: '18px',
    color: '#6F6F6F'
  }
});

const Item = props => {
  const { type, progressValue, programLiftValue, baseValue } = props;
  const classes = useItemStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={6}>
        <AlbProgressCircle radius={68} stroke={12} progress={progressValue} type={type} />
      </Grid>

      <Grid container item xs={6} direction="column" alignContent="center">
        <Grid container justifyContent="space-between" className={classes.liftRow}>
          <Typography className={classes.liftTitle}>Program Lift</Typography>
          <Typography className={classes.percent}>{programLiftValue}</Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography className={classes.baseTitle}>Non-Program Lift</Typography>
          <Typography className={classes.percent}>{baseValue}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

Item.propTypes = {
  type: PropTypes.string,
  progressValue: PropTypes.number,
  programLiftValue: PropTypes.string,
  baseValue: PropTypes.string
};

Item.defaultProps = {
  type: null,
  progressValue: null,
  programLiftValue: null,
  baseValue: null
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: 600,
    fontSize: '26px',
    color: '#0A1734',
    marginBottom: '20px'
  }
});

const ContributionScoreLift = props => {
  const { loading, error, data } = props;
  const classes = useStyles();

  const mapScores = score => {
    let progressValue = 0;
    let programLiftValue = '-';
    let baseValue = '-';

    if (score.base_score !== 0) {
      progressValue = Math.round((score.program_lift_score / score.base_score) * 100);
      programLiftValue = `${progressValue}%`;
      baseValue = Math.round(
        ((score.base_score - score.program_lift_score) / score.base_score) * 100
      );
      baseValue = `${baseValue}%`;
    }

    return (
      <Item
        key={score.id}
        type={score.mix_group}
        progressValue={progressValue}
        programLiftValue={programLiftValue}
        baseValue={baseValue}
      />
    );
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>CONTRIBUTION SCORE LIFT</Typography>
      {loading && <AlbLoading />}
      {error && <AlbError error={error} />}
      {data?.programLiftContributionScores?.map(mapScores)}
    </div>
  );
};

ContributionScoreLift.defaultProps = {
  loading: false,
  error: null,
  data: null
};

ContributionScoreLift.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  data: PropTypes.shape()
};

export default ContributionScoreLift;
