import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Paper, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { mediaTabs, mediaRoutes } from 'util/mediaRoutes';
import MediaNav from '../MediaNav';
import StockPhotoGrid from '../StockPhotoGrid';
import { FolderGrid, ImageGrid, VideoGrid } from '../MediaGrid/MediaGrid';
import SimpleBreadcrumbs from '../MediaBreadcrumbs/SimpleBreadcrumbs';

const style = {
  container: {
    height: '70vh'
  },
  paper: {
    maxHeight: '70vh'
  }
};

const MediaModal = props => {
  const {
    currentTabIndex,
    classes,
    uploadFolder,
    currentUser,
    handleSearchInput,
    handleStockPhotoSearch,
    handleModalNavigation,
    getFolderName,
    searchFilter,
    filteredStockPhotos,
    onClose,
    open,
    containerId,
    ...allprops
  } = props;

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      open={open}
      scroll="body"
    >
      <>
        <Paper style={{ minHeight: '50vh' }}>
          <Grid container className={classes.navGrid} justifyContent="space-between">
            <MediaNav
              modal
              currentUser={currentUser}
              mediaRoutes={mediaRoutes}
              handleSearchInput={handleSearchInput}
              handleStockPhotoSearch={handleStockPhotoSearch}
              handleModalNavigation={handleModalNavigation}
              tabValue={currentTabIndex}
            />
            <SimpleBreadcrumbs
              uploadFolder={uploadFolder}
              getFolderName={getFolderName}
              currentTabIndex={currentTabIndex}
            />
            {currentTabIndex === mediaTabs.HOME && (
              <FolderGrid
                {...allprops}
                searchFilter={searchFilter}
                modal
                getFolderName={getFolderName}
                uploadFolder={uploadFolder}
                containerId={containerId}
              />
            )}
            {currentTabIndex === mediaTabs.IMAGES && (
              <ImageGrid
                {...allprops}
                modal
                searchFilter={searchFilter}
                uploadFolder={uploadFolder}
                containerId={containerId}
              />
            )}
            {currentTabIndex === mediaTabs.VIDEOS && (
              <VideoGrid
                {...allprops}
                modal
                searchFilter={searchFilter}
                uploadFolder={uploadFolder}
                containerId={containerId}
              />
            )}
            {currentTabIndex === mediaTabs.STOCK_PHOTOS && (
              <Grid item xs={12} className={classes.scrollContainer}>
                <StockPhotoGrid
                  {...allprops}
                  modal
                  inPost
                  filteredStockPhotos={filteredStockPhotos}
                  folderName={uploadFolder}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    </Dialog>
  );
};

MediaModal.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  classes: PropTypes.shape(),
  uploadFolder: PropTypes.string.isRequired,
  currentUser: PropTypes.shape(),
  handleSearchInput: PropTypes.func.isRequired,
  handleStockPhotoSearch: PropTypes.func.isRequired,
  handleModalNavigation: PropTypes.func.isRequired,
  getFolderName: PropTypes.func.isRequired,
  searchFilter: PropTypes.string,
  filteredStockPhotos: PropTypes.arrayOf(PropTypes.shape()),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  containerId: PropTypes.string
};

MediaModal.defaultProps = {
  classes: null,
  currentUser: null,
  searchFilter: null,
  filteredStockPhotos: null,
  onClose: null,
  open: true,
  containerId: null
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default withStyles(style)(connect(mapStateToProps)(MediaModal));
