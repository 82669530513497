import { gql } from '@apollo/client';

export const MEDIA_GROUPS = gql`
  query getMediaGroups {
    getMediaGroups {
      id
      name
      is_default
      mediaGroupReports {
        broadcastSearches
        podcastSearches
        articleSearches
        articleSearchFilters
      }
    }
  }
`;

export const MEDIA_GROUP = gql`
  query getMediaGroup($id: ID!) {
    getMediaGroup(id: $id) {
      id
      name
      is_default
      mediaGroupReports {
        broadcastSearches
        podcastSearches
        articleSearches
        articleSearchFilters
      }
    }
  }
`;

export const CREATE_MEDIA_GROUP = gql`
  mutation createMediaGroup(
    $name: String!
    $isDefault: Boolean
    $podcastReportIds: [ID!]
    $broadcastReportIds: [ID!]
    $articleReportIds: [ID!]
    $articleFilterReportIds: [ID!]
  ) {
    createMediaGroup(
      name: $name
      isDefault: $isDefault
      podcastReportIds: $podcastReportIds
      broadcastReportIds: $broadcastReportIds
      articleReportIds: $articleReportIds
      articleFilterReportIds: $articleFilterReportIds
    ) {
      id
      name
      is_default
    }
  }
`;

export const UPDATE_MEDIA_GROUP_REPORTS = gql`
  mutation updateMediaGroupReports(
    $mediaGroupId: ID!
    $broadcastReportIds: [ID]
    $podcastReportIds: [ID]
    $articleReportIds: [ID]
    $articleFilterReportIds: [ID]
  ) {
    updateMediaGroupReports(
      mediaGroupId: $mediaGroupId
      broadcastReportIds: $broadcastReportIds
      podcastReportIds: $podcastReportIds
      articleReportIds: $articleReportIds
      articleFilterReportIds: $articleFilterReportIds
    ) {
      id
      name
      is_default
      mediaGroupReports {
        broadcastSearches
        podcastSearches
        articleSearches
        articleSearchFilters
      }
    }
  }
`;

export const UPDATE_MEDIA_GROUP_NAME = gql`
  mutation updateMediaGroupName($id: ID!, $name: String!) {
    updateMediaGroupName(id: $id, name: $name) {
      id
      name
      is_default
      mediaGroupReports {
        broadcastSearches
        podcastSearches
        articleSearches
      }
    }
  }
`;

export const DELETE_MEDIA_GROUP = gql`
  mutation deleteMediaGroup($id: ID!) {
    deleteMediaGroup(id: $id)
  }
`;
