/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { Typography, Grid } from '@material-ui/core';
import SocialAssets from 'components/SocialAssets';
import DetailsBody from 'components/PostDrawerView/DetailsBody';
import PostPreviewHeader from './PostPreviewHeader';

const useStyles = makeStyles({
  container: {
    padding: '28px 25px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '36px',
    color: '#0A1734',
    marginRight: '10px'
  },
  header: {
    marginBottom: '24px'
  },
  body: {
    display: 'flex',
    marginBottom: '24px'
  },
  textContainer: {
    flex: 1
  }
});

/**
 * @method
 * @summary This component renders the details of a post at the top of @see PostDrawerView
 * @name PostDrawerDetails
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const PostDrawerDetails = props => {
  const { campaignEvent } = props;
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <SocialAssets
        width={124}
        height={124}
        assets={campaignEvent?.assets?.length > 0 ? campaignEvent?.assets : null}
        videoThumbnail={campaignEvent?.assets?.length === 0 ? campaignEvent?.thumbnail_url : null}
      />

      <div className={classes.textContainer}>
        <PostPreviewHeader
          postDetails={{
            type: campaignEvent.type,
            avatarUrl: campaignEvent.linked_account.avatar_url,
            remoteName: campaignEvent.linked_account.remote_name,
            completedAt: campaignEvent.completed_at
          }}
        />

        <DetailsBody eventBody={campaignEvent.event_body} />
      </div>
    </div>
  );
};

PostDrawerDetails.propTypes = {
  campaignEvent: PropTypes.shape().isRequired
};

PostDrawerDetails.defaultProps = {};

export default PostDrawerDetails;
