import { gql } from '@apollo/client';

export const SHARE_OF_VOICE_REPORT = gql`
  query shareOfVoiceReport($id: ID!, $startDate: DateTimeISO, $endDate: DateTimeISO) {
    shareOfVoiceReport(id: $id) {
      id
      name
      article_searches {
        id
        name
        getArticleShareCountByPlatform(startDate: $startDate, endDate: $endDate) {
          total_articles
          total_totals_share_count
          total_twitter_share_count
          total_facebook_share_count
        }
        getArticleShareCountByMonth(startDate: $startDate, endDate: $endDate) {
          period_start_date
          total_articles
          total_totals_share_count
        }
      }
      article_search_filters {
        id
        article_search_id
        getArticleShareCountByPlatform(startDate: $startDate, endDate: $endDate) {
          total_articles
          total_totals_share_count
          total_twitter_share_count
          total_facebook_share_count
        }
        getArticleShareCountByMonth(startDate: $startDate, endDate: $endDate) {
          period_start_date
          total_articles
          total_totals_share_count
        }
      }
    }
  }
`;

export const SHARE_OF_VOICE_REPORTS = gql`
  query shareOfVoiceReports($enabled: Boolean) {
    shareOfVoiceReports(enabled: $enabled) {
      id
      name
      article_searches {
        id
        name
      }
      article_search_filters {
        id
      }
    }
  }
`;

export const LIST_SHARE_OF_VOICE_REPORTS = gql`
  query paginatedShareOfVoiceReports($enabled: Boolean, $count: Int, $after: Int) {
    paginatedShareOfVoiceReports(enabled: $enabled, count: $count, after: $after) {
      rows {
        id
        name
        enabled
        is_default
        article_searches {
          id
        }
        article_search_filters {
          id
        }
      }
      count
    }
  }
`;

export const SHARE_OF_VOICE_ANALYTICS = gql`
  query shareOfVoiceAnalytics($id: ID!, $startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    shareOfVoiceAnalytics(id: $id, startDate: $startDate, endDate: $endDate) {
      totalArticles
      myArticles
      myBestMonth {
        date
        sharesSum
      }
      myWorstMonth {
        date
        sharesSum
      }
    }
  }
`;

export const GET_DEFAULT_SHARE_OF_VOICE_REPORT = gql`
  query getDefaultShareOfVoiceReport {
    getDefaultShareOfVoiceReport {
      id
      name
      article_searches {
        id
      }
      article_search_filters {
        id
      }
    }
  }
`;

export const CREATE_SHARE_OF_VOICE_REPORT = gql`
  mutation createShareOfVoiceReport(
    $primaryReportId: ID!
    $primaryArticleFilterId: ID
    $earnedMediaReportIds: [ID!]
    $earnedMediaFilterIds: [ID!]
    $name: String!
    $enabled: Boolean
  ) {
    createShareOfVoiceReport(
      primaryReportId: $primaryReportId
      primaryArticleFilterId: $primaryArticleFilterId
      earnedMediaReportIds: $earnedMediaReportIds
      earnedMediaFilterIds: $earnedMediaFilterIds
      name: $name
      enabled: $enabled
    )
  }
`;

export const UPDATE_SHARE_OF_VOICE_REPORT = gql`
  mutation updateShareOfVoiceReport($id: ID!, $newName: String!) {
    updateShareOfVoiceReport(id: $id, newName: $newName) {
      id
      name
    }
  }
`;

export const MAKE_DEFAULT_SHARE_OF_VOICE_REPORT = gql`
  mutation makeDefaultShareOfVoiceReport($id: ID!) {
    makeDefaultShareOfVoiceReport(id: $id) {
      id
      name
    }
  }
`;

export const ARCHIVE_SHARE_OF_VOICE_REPORT = gql`
  mutation disableShareOfVoiceReport($id: ID!) {
    disableShareOfVoiceReport(id: $id) {
      id
      name
    }
  }
`;

export const UNARCHIVE_SHARE_OF_VOICE_REPORT = gql`
  mutation enableShareOfVoiceReport($id: ID!) {
    enableShareOfVoiceReport(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_SHARE_OF_VOICE_REPORT = gql`
  mutation deleteShareOfVoiceReport($id: ID!) {
    deleteShareOfVoiceReport(id: $id) {
      result
      message
    }
  }
`;
