import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { GET_SALESFORCE_TIME_TO_EVENTS } from 'gql/salesforce';
import TimeToEventGraph from './Graphs/TimeToEventGraph';
import TimeToEventBreakdownTable from './Tables/TimeToEventBreakdownTable';

const useStyles = makeStyles({
  box: {
    margin: '25px 15px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  }
});

const SalesforceTimeToEvent = props => {
  const { linktokenId } = props;
  const classes = useStyles();

  const [lookBackTime, setLookBackTime] = useState({ value: '1440', label: '4 Years' });
  const [dimension, setDimension] = useState({ value: 'type', label: 'Type' });
  const [opportunity, setOpportunity] = useState({ value: null, label: 'All' });

  const [page, setPage] = useState(0);
  const rowsPerPage = 15;

  useEffect(() => {
    setPage(0);
  }, [lookBackTime, dimension, opportunity]);

  const { data, loading, error } = useQuery(GET_SALESFORCE_TIME_TO_EVENTS, {
    variables: {
      linktokenId,
      opportunityFromLead: opportunity.value,
      dimension: dimension.value,
      lookBackTime: lookBackTime.value
    },
    fetchPolicy: 'network-only',
    skip: linktokenId == null
  });

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item className={classes.box}>
        <TimeToEventGraph
          lookBackTime={lookBackTime}
          setLookBackTime={setLookBackTime}
          dimension={dimension}
          setDimension={setDimension}
          opportunity={opportunity}
          setOpportunity={setOpportunity}
          page={page}
          rowsPerPage={rowsPerPage}
          data={data}
          loading={loading}
          error={error}
        />
        <div
          style={{
            height: '14px',
            width: '100%',
            background: 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%)',
            mixBlendMode: 'multiply'
          }}
        />
        <TimeToEventBreakdownTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          data={data}
          loading={loading}
          error={error}
        />
      </Grid>
    </Grid>
  );
};

SalesforceTimeToEvent.propTypes = {
  linktokenId: PropTypes.string
};

SalesforceTimeToEvent.defaultProps = {
  linktokenId: null
};

export default SalesforceTimeToEvent;
