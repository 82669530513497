import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

const LINK_ACCOUNT = gql`
  mutation createSocialLink(
    $token_type: LinkEnum!
    $remote_token: String!
    $remote_verifier: String
  ) {
    createSocialLink(
      token_type: $token_type
      remote_token: $remote_token
      remote_verifier: $remote_verifier
    ) {
      result
      onboard_ready
      token {
        id
        remote_name
        remote_id
        type
      }
      pages
    }
  }
`;

const LINKED_ACCOUNTS_FIELDS = gql`
  fragment linkedAccountsFields on Linktoken {
    id
    organization {
      id
      name
    }
    container {
      id
      name
    }
    user_id
    user {
      id
      first_name
      last_name
    }
    type
    remote_id
    remote_name
    enabled
    valid_token
    avatar_url
    created_at
    updated_at
    error
  }
`;

export async function apiLinkAccount(data) {
  return apolloClientHelper(
    LINK_ACCOUNT,
    data,
    true // True enabled a GraphQL mutation
  );
}

export const LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER = gql`
  query linkedAccounts($showOnlyEnabled: Boolean) {
    linkedAccounts(showOnlyEnabled: $showOnlyEnabled) {
      ...linkedAccountsFields
    }
  }
  ${LINKED_ACCOUNTS_FIELDS}
`;

export const LINKTOKENS_FOR_SELECTOR = gql`
  query linkedAccounts($showOnlyEnabled: Boolean) {
    linkedAccounts(showOnlyEnabled: $showOnlyEnabled) {
      id
      type
      remote_name
      parent {
        id
        remote_name
      }
    }
  }
`;

export const WEB_LINKED_ACCOUNTS = gql`
  query webLinkedAccounts($appStores: Boolean) {
    webLinkedAccounts(appStores: $appStores) {
      ...linkedAccountsFields
    }
  }
  ${LINKED_ACCOUNTS_FIELDS}
`;

export const PAGINATED_LINKED_ACCOUNTS = gql`
  query paginatedLinkedAccounts($after: Int, $count: Int, $orderBy: [String]) {
    paginatedLinkedAccounts(after: $after, count: $count, orderBy: $orderBy) {
      rows {
        ...linkedAccountsFields
      }
      count
    }
  }
  ${LINKED_ACCOUNTS_FIELDS}
`;

export const LINKTOKEN_RELATIONSHIPS = gql`
  query linktokenRelationships($orderBy: [String], $filter: LRFilterEnum!) {
    linktokenRelationships(orderBy: $orderBy, filter: $filter) {
      id
      user_id
      user {
        id
        first_name
        last_name
        avatar_url
      }
      remote_id
      avatar_url
      remote_name
      type
      valid_token
      enabled
      error
      depth
      hasSibling
      organization {
        id
        name
      }
      container {
        id
        name
      }
      created_at
      updated_at
    }
  }
`;

export const ENABLE_TOKEN = gql`
  mutation($id: ID!, $takeover: Boolean) {
    enableToken(id: $id, takeover: $takeover) {
      result
    }
  }
`;

export const DISABLE_TOKEN = gql`
  mutation($id: ID!) {
    disableToken(id: $id) {
      result
    }
  }
`;
export const SET_TOKEN_CONTAINER = gql`
  mutation($id: ID!, $container_id: ID!) {
    setTokenContainer(id: $id, container_id: $container_id) {
      id
      container {
        id
        name
      }
      remote_name
      container_id
      organization_id
    }
  }
`;

export const REMOVE_SOCIAL_LINK = gql`
  mutation($id: ID!) {
    removeSocialLink(id: $id)
  }
`;

export const GET_LINKED_GROUPED_ACCOUNTS = gql`
  query {
    linkedGroupedAccounts {
      id
      name
      linktokens {
        ...linkedAccountsFields
      }
    }
  }
  ${LINKED_ACCOUNTS_FIELDS}
`;

export const CREATE_LINKED_GROUP = gql`
  mutation($name: String!, $linktokenIds: [ID!]!) {
    createLinkedGroup(name: $name, linktokenIds: $linktokenIds) {
      id
      name
      linktokens {
        id
        organization {
          id
          name
        }
        container {
          id
          name
        }
        user_id
        user {
          id
          first_name
          last_name
        }
        type
        remote_id
        remote_name
        enabled
        valid_token
        avatar_url
        created_at
        updated_at
        error
      }
    }
  }
`;

export const UPDATE_LINKED_GROUP = gql`
  mutation($id: ID!, $name: String, $linktokenIds: [ID!]!) {
    updateLinkedGroup(id: $id, name: $name, linktokenIds: $linktokenIds) {
      id
      name
      linktokens {
        id
        organization {
          id
          name
        }
        container {
          id
          name
        }
        user_id
        user {
          id
          first_name
          last_name
        }
        type
        remote_id
        remote_name
        enabled
        valid_token
        avatar_url
        created_at
        updated_at
        error
      }
    }
  }
`;

export const DELETE_LINKED_GROUP = gql`
  mutation($id: ID!) {
    deleteLinkedGroup(id: $id)
  }
`;

export const LINK_ADOBE_ANALYTICS_ACCOUNT = gql`
  mutation createAdobeAnalyticsLink(
    $global_company_id: String!
    $organization_id: String!
    $client_id: String!
    $technical_account_id: String!
    $client_secret: String!
    $private_key: String!
  ) {
    createAdobeAnalyticsLink(
      global_company_id: $global_company_id
      organization_id: $organization_id
      client_id: $client_id
      technical_account_id: $technical_account_id
      client_secret: $client_secret
      private_key: $private_key
    ) {
      result
      onboard_ready
      token {
        id
        remote_name
        remote_id
        type
      }
      pages
    }
  }
`;

export const LINK_APP_STORE_CONNECT_ACCOUNT = gql`
  mutation createAppStoreConnectLink(
    $vendorId: String!
    $keyId: String!
    $issuer: String!
    $private_key: String!
  ) {
    createAppStoreConnectLink(
      vendorId: $vendorId
      keyId: $keyId
      issuer: $issuer
      private_key: $private_key
    ) {
      result
      onboard_ready
      token {
        id
        remote_name
        remote_id
        type
      }
      pages
    }
  }
`;

export const LINK_GOOGLE_PLAY_CONSOLE = gql`
  mutation createGooglePlayConsoleLink(
    $bucket_name: String!
    $developer_id: String!
    $developer_name: String!
  ) {
    createGooglePlayConsoleLink(
      bucket_name: $bucket_name
      developer_id: $developer_id
      developer_name: $developer_name
    ) {
      result
      onboard_ready
      token {
        id
        remote_name
        remote_id
        type
      }
      pages
    }
  }
`;
