import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';

const AlbLoading = props => {
  const { color, size } = props;

  return (
    <Grid container justifyContent="center">
      <CircularProgress color={color} size={size} />
    </Grid>
  );
};

AlbLoading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

AlbLoading.defaultProps = {
  color: 'primary',
  size: 40
};

export default AlbLoading;
