import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumbs } from '@material-ui/core';
import { formatCrumb } from 'util/assetNameValidation';
import { mediaLabels } from 'util/mediaRoutes';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  crumbs: {
    display: 'flex',
    marginTop: '10px',
    marginLeft: '20px',
    color: '#0d1a3a',
    opacity: 0.5,
    fontSize: '12px',
    fontWeight: '500'
  },
  crumbValues: {
    color: '#0d1a3a',
    opacity: 0.5,
    fontSize: '12px',
    fontWeight: '500',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  lastCrumb: {
    color: '#0d1a3a',
    opacity: 0.5,
    fontSize: '12px',
    fontWeight: '500'
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
});

// Simple Breadcrumbs for social modal
const SimpleBreadcrumbs = props => {
  const { classes, uploadFolder, getFolderName, currentTabIndex } = props;
  const folderNameArr = ['Media'];

  if (uploadFolder !== '/') {
    const uploadFolderArr = uploadFolder.split('/').filter(el => el !== '');

    uploadFolderArr.forEach(crumb => {
      folderNameArr.push(crumb);
    });
  }

  const handleFolderSelect = value => {
    const index = folderNameArr.findIndex(el => el === value);
    const slicedArr = folderNameArr.slice(1, index + 1).join('/');
    const navigateTo = `/${slicedArr}`;

    getFolderName(navigateTo);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs className={classes.crumbs} aria-label="Breadcrumb">
        {currentTabIndex === 0 ? (
          folderNameArr.map((value, index) => {
            const last = index === folderNameArr.length - 1;

            return last ? (
              <h3 key={value} className={classes.lastCrumb} variant="h4">
                {formatCrumb(value)}
              </h3>
            ) : (
              <h3
                className={classes.crumbValues}
                onClick={() => handleFolderSelect(value)}
                role="presentation"
              >
                {formatCrumb(value)}
              </h3>
            );
          })
        ) : (
          <h3 className={classes.lastCrumb}>{formatCrumb(mediaLabels[currentTabIndex])}</h3>
        )}
      </Breadcrumbs>
    </div>
  );
};

SimpleBreadcrumbs.propTypes = {
  classes: PropTypes.shape(),
  uploadFolder: PropTypes.string.isRequired,
  getFolderName: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

SimpleBreadcrumbs.defaultProps = {
  classes: {}
};

export default withStyles(styles)(SimpleBreadcrumbs);
