import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';

import { showToast } from 'contexts/ToastContext';
import { ORGANIZATION_SETTINGS, UPDATE_ORGANIZATION_DATE_PREFERENCES } from 'gql/organization';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';

const CalendarSettings = () => {
  const [weekStart, setWeekStart] = useState(0);
  const [quarterStart, setQuarterStart] = useState(1);

  const weekdays = moment.weekdays();
  const months = moment.months().map((month, i) => ({
    index: i + 1,
    month
  }));

  const { data, loading, error: queryError } = useQuery(ORGANIZATION_SETTINGS, {
    fetchPolicy: 'network-only'
  });

  const [updateDatePreferences] = useMutation(UPDATE_ORGANIZATION_DATE_PREFERENCES);

  useEffect(() => {
    if (data?.getCurrentOrganization) {
      const { week_start: startDay, quarter_start: startMonth } = data.getCurrentOrganization;

      setWeekStart(startDay);
      setQuarterStart(startMonth);
    }
  }, [data]);

  const handleSelectWeekStart = event => {
    setWeekStart(event.target.value);

    updateDatePreferences({ variables: { week_start: event.target.value } })
      .then(response => {
        if (response?.data?.updateDatePreferences)
          showToast('Successfully set week start date.', 'success');
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  const handleSelectQuarterStart = event => {
    setQuarterStart(event.target.value);

    updateDatePreferences({ variables: { quarter_start: event.target.value } })
      .then(response => {
        if (response?.data?.updateDatePreferences)
          showToast('Successfully set quarter start month.', 'success');
      })
      .catch(error => {
        showToast(error.message, 'error');
      });
  };

  if (loading) return <AlbLoading />;
  if (queryError) return <p>{queryError.message}</p>;

  return (
    <Box p={30}>
      <ValidatorForm
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Grid container direction="column" spacing={4}>
          <Grid container item xs={8} md={6} lg={4}>
            <Typography gutterBottom>Choose Week Start Date</Typography>
            <SelectValidator
              value={weekStart}
              onChange={handleSelectWeekStart}
              variant="outlined"
              fullWidth
            >
              {weekdays.map((weekday, key) => (
                <MenuItem key={weekday} value={key}>
                  {weekday}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>
          <Grid container item xs={8} md={6} lg={4}>
            <Typography gutterBottom>Choose Quarter Start Month</Typography>
            <SelectValidator
              value={quarterStart}
              onChange={handleSelectQuarterStart}
              variant="outlined"
              fullWidth
            >
              {months.map(({ month, index }) => (
                <MenuItem key={month} value={index}>
                  {month}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Box>
  );
};

export default CalendarSettings;
