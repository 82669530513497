/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlbTable from 'components/AlbTable';
import { ATTENTION_ACTION_GET_LABEL_FILTERS } from 'gql/attentionAction';
import AlbError from 'components/AlbError';
import AlbLoading from 'components/AlbLoading';
import AlembicPageHeader from 'components/AlembicPageHeader';
import Box from 'components/Box';
import CustomFooter from 'components/TablePagination';
import setPageTitle from 'util/titles';
import { getAttentionActionTitle } from 'util/detectedEvents';
import LabelFiltersTableActions from './LabelFiltersTableActions';

const styles = makeStyles({
  correlations: {
    '& > :not(:last-child)': {
      marginBottom: '10px'
    }
  },
  correlation: {
    whiteSpace: 'nowrap',
    color: '#0A1734',
    fontWeight: 600,
    fontSize: '13px',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const LabelFilters = () => {
  const classes = styles();

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['dates', 'DESC']);

  useEffect(() => {
    setPageTitle('Excluded Events');
  }, []);

  const { data, loading, error } = useQuery(ATTENTION_ACTION_GET_LABEL_FILTERS, {
    variables: {
      count: rowsPerPage,
      after: page * rowsPerPage,
      orderBy: sortOrder
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (data?.paginatedAttentionActionLabelFilters) {
      const tempTableData = data.paginatedAttentionActionLabelFilters.rows.map(row => ({
        dates: [row.start_date, row.end_date],
        ...row
      }));
      setTableData(tempTableData);
      setCount(data.paginatedAttentionActionLabelFilters.count);
    }
  }, [data]);

  const customActionsRender = (_, { rowData }) => {
    const [, event_type, event_label] = rowData;
    return <LabelFiltersTableActions eventType={event_type} eventLabel={event_label} />;
  };

  const customTypeRender = (_, { rowData }) => {
    const [, event_type] = rowData;
    return getAttentionActionTitle(event_type);
  };

  const columns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'event_type',
      label: 'EVENT',
      options: { customBodyRender: customTypeRender, sort: false }
    },
    {
      name: 'event_label',
      label: 'DIMENSION',
      options: { sort: false }
    },
    {
      name: 'actions',
      label: 'ACTIONS',
      options: {
        display: true,
        customBodyRender: customActionsRender,
        sort: false
      }
    }
  ];

  const rowsPerPageOptions = [10, 20, 50];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    rowHover: false,
    fixedHeader: false,
    sortFilterList: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    page,
    rowsPerPage,
    count,
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1].toLowerCase() },
    onColumnSortChange: (changedColumn, direction) =>
      setSortOrder([changedColumn, direction.toUpperCase()]),
    draggableColumns: { enabled: true, transitionTime: 300 },
    customFooter,
    textLabels: {
      body: {
        noMatch: 'No excluded events found'
      }
    }
  };

  const tableThemeOverrides = {
    MUIDataTableHeadCell: {
      toolButton: {
        marginLeft: '0px',
        marginRight: '0px'
      }
    }
  };

  return (
    <>
      <Box mb={30}>
        <AlembicPageHeader backButton pageTitle="Excluded Events" />
      </Box>
      {error && <AlbError error={error} />}
      <Paper style={{ position: 'relative' }}>
        {loading && (
          <div className={classes.loadingOverlay}>
            <AlbLoading />
          </div>
        )}
        <AlbTable
          tableData={tableData}
          tableColumns={columns}
          tableOptions={options}
          tableThemeOverrides={tableThemeOverrides}
        />
      </Paper>
    </>
  );
};

LabelFilters.propTypes = {};

export default LabelFilters;
