import { gql } from '@apollo/client';

const SECURITY_ROLE_FIELDS = gql`
  fragment securityRoleFields on SecurityRole {
    id
    role_type
    name
    description
    created_at
    updated_at
    organization_id
    privs {
      id
    }
  }
`;

export const SECURITY_ROLES = gql`
  query {
    security_roles {
      ...securityRoleFields
    }
  }
  ${SECURITY_ROLE_FIELDS}
`;

export const CREATE_SECURITY_ROLE = gql`
  mutation($name: String!, $description: String!) {
    createSecurityRole(name: $name, description: $description) {
      ...securityRoleFields
    }
  }
  ${SECURITY_ROLE_FIELDS}
`;

export const DUPLICATE_SECURITY_ROLE = gql`
  mutation($id: ID!, $name: String, $description: String) {
    duplicateSecurityRole(id: $id, name: $name, description: $description) {
      ...securityRoleFields
    }
  }
  ${SECURITY_ROLE_FIELDS}
`;

export const UPDATE_SECURITY_ROLE = gql`
  mutation($id: ID!, $name: String, $description: String, $privs: [ID]) {
    updateSecurityRole(id: $id, name: $name, description: $description, privs: $privs) {
      ...securityRoleFields
    }
  }
  ${SECURITY_ROLE_FIELDS}
`;

export const DELETE_SECURITY_ROLE = gql`
  mutation($id: ID!) {
    deleteSecurityRole(id: $id)
  }
`;
