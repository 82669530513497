import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Bar, defaults } from 'react-chartjs-2';
import { merge } from 'lodash';
import Box from 'components/Box';
import colors from 'util/colors';
import { formatHumanReadableTime } from 'util/time';
// eslint-disable-next-line import/no-cycle
import PostDrawerGraph from './PostDrawerGraph';

merge(defaults, { global: { defaultFontFamily: 'Poppins' } });

const useStyles = makeStyles({
  graph: {
    position: 'relative',
    display: 'flex',
    marginTop: '15px',
    marginLeft: '20px',
    flex: 1
  }
});

/**
 * @method Simple method to check if the value is range, added to make the code slightly more readable
 * @param {Number} value - The value to check if it's within the range of the min/max values
 * @param {Number} min - The minimum value of the range
 * @param {Number} max - The maximum value of the range
 * @returns boolean - True if the value is within the range of the min/max values
 */
const isValueBetween = (value, min, max) => {
  return value > min && value < max;
};

/**
 * @method
 * @summary Contains the graph and child graphs contained within the graph - recursively displays the PostDrawerGraph component for each child
 * @name GraphBody
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.graphData - The post formatted queried graph data
 * @param {Boolean} props.isCollapsed - True if the component is collapsed (not visible) in the containing component
 * @param {Boolean} props.hasGraph - True if the current layer is intended to hold graph children and not display a graph
 * @param {Object[]} props.graphChildren - An array provided by the json config to hold all graph children to be displayed within the current layer
 * @param {String} props.conversion - Specifies a conditional conversion to be made on a graph label
 * @param {Number} props.maxWidth - A string containing a width limitation determined by the current depth multiplied by the default padding
 * recursive props passed down to each layer of graph
 * @param {String} [props.postId=null] - The post ID of the current campaign event
 * @param {String} [props.linkTokenId=null] - The ID of the current campaign event's associated link token
 * @param {Object} [props.campaignEvent={}] - The current campaign event
 * @param {String|Date} [props.startDate=null] - The start date passed from the drawer's parent component - If a date range is provided
 * @param {String|Date} [props.endDate=null] - The end date passed from the drawer's parent component - If a date range is provided
 * @param {Number} props.depth - The current depth of the graph (used for custom styling)
 * @return {Object} - React JSX
 */
const GraphBody = props => {
  const {
    graphData,
    isCollapsed,
    hasGraph,
    graphChildren,
    conversion,
    maxWidth,
    ...postProps
  } = props;

  const classes = useStyles();

  const graphOptions = {
    legend: { display: false },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'label',
      intersect: false,
      callbacks: {
        label(tooltipItem, chartData) {
          const dataPoint = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          let valueText;
          // if the resulting valueText is between 1 and -1, then return without rounding.
          if (conversion !== 'ms') {
            valueText = isValueBetween(dataPoint, -1, 1)
              ? dataPoint
              : Math.round(dataPoint).toLocaleString();
          }

          if (conversion === 'ms') {
            valueText = formatHumanReadableTime(dataPoint);
          }

          return valueText;
        }
      },
      // hide tooltip for zero values
      filter: (tooltipItem, chartData) =>
        !!chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: colors.gray,
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600'
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            precision: 2,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600',
            callback: value => {
              switch (conversion) {
                case 'ms':
                  return formatHumanReadableTime(value);
                default:
                  return value.toLocaleString();
              }
            }
          }
        }
      ]
    }
  };

  return (
    <>
      {hasGraph && !isCollapsed && (
        <Grid container>
          <Box className={classes.graph} style={{ maxWidth }}>
            <Bar data={graphData} options={graphOptions} />
          </Box>
        </Grid>
      )}
      {graphChildren.map(childProps => (
        <PostDrawerGraph key={childProps.sequence_id} {...childProps} {...postProps} />
      ))}
    </>
  );
};

GraphBody.propTypes = {
  graphData: PropTypes.shape().isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  hasGraph: PropTypes.bool.isRequired,
  graphChildren: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  conversion: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // recursive props passed down to each layer of graph
  postId: PropTypes.string,
  linkTokenId: PropTypes.string,
  campaignEvent: PropTypes.shape(),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  depth: PropTypes.number
};

GraphBody.defaultProps = {
  conversion: null,
  postId: null,
  linkTokenId: null,
  campaignEvent: {},
  startDate: null,
  endDate: null,
  depth: 0
};

export default GraphBody;
