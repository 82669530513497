import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import { MenuItem } from '@material-ui/core';
import defaultTimezones from './timezones.json';

class TimezonePicker extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      timeZone: value
    };
  }

  handleInput = event => {
    const { onChange } = this.props;
    onChange(event.target.value);
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { timeZone } = this.state;
    return (
      <TextValidator
        select
        fullWidth
        name="timeZone"
        variant="filled"
        placeholder="Time Zone"
        value={timeZone}
        onChange={this.handleInput}
        validators={['required']}
        errorMessages={['* Required']}
      >
        {Object.keys(defaultTimezones).map(key => {
          return (
            <MenuItem key={key} value={defaultTimezones[key]}>
              {`${key} - ${defaultTimezones[key]}`}
            </MenuItem>
          );
        })}
      </TextValidator>
    );
  }
}

TimezonePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TimezonePicker;
