import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import { useQuery } from '@apollo/client';
// import moment from 'moment';
// import {
// CAUSAL_CHAINS_SUMMARY,
// CAUSAL_CHAINS_SUMMARY_MOST_RECENT_DAY
// } from 'gql/causalChainsSummary';
import setPageTitle from 'util/titles';
// import PeriodicalDropdown from './PeriodicalDropdown';
// import InsightsAnimation from './InsightsAnimation';
// import InsightsSummary from './InsightsSummary';
import SummaryOfSummaries from './SummaryOfSummaries';

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontFamily: 'Poppins',
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 40
  }
});

// const dropdownOptions = [
//   {
//     option: 'What are the most important things that we saw yesterday?',
//     timeSlice: 'PreviousDay',
//     length: 1
//   },
//   {
//     option: 'What are the most important things that we saw this week?',
//     timeSlice: 'ThisWeek',
//     length: 7
//   },
//   {
//     option: 'What were the causal chain reactions that we saw in the last 30 days?',
//     timeSlice: 'ThisMonth',
//     length: 30
//   },
//   {
//     option: 'What were the causal chain reactions that we saw in the last 90 days?',
//     timeSlice: 'ThisQuarter',
//     length: 90
//   }
// ];

const DashboardInsights = () => {
  const classes = useStyles();

  // const [openDropdown, setOpenDropdown] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(0);

  // const [animateImage, setAnimateImage] = useState(false);
  // const [animateSummary, setAnimateSummary] = useState(false);
  // const animateSummary = true;

  // const [justifyData, setJustifyData] = useState([]);
  // const [mostRecentDay, setMostRecentDay] = useState(null);

  // const [updatedDropdownOptions, setUpdatedDropdownOptions] = useState(dropdownOptions);

  // const { data: recentData } = useQuery(CAUSAL_CHAINS_SUMMARY_MOST_RECENT_DAY, {
  //   fetchPolicy: 'network-only'
  // });

  // useEffect(() => {
  //   if (recentData?.causalChainsSummaryMostRecentDay) {
  //     const tempMostRecentDay = recentData.causalChainsSummaryMostRecentDay.end_date;
  //     const formattedRecentDay = moment.utc(tempMostRecentDay).format('MMM D, YYYY');

  //     // setMostRecentDay(formattedRecentDay);
  //     setUpdatedDropdownOptions([
  //       {
  //         option: `What are the most important things that we saw on ${formattedRecentDay}?`,
  //         timeSlice: 'PreviousDay',
  //         length: 1
  //       },
  //       ...dropdownOptions.slice(1, dropdownOptions.length)
  //     ]);
  //   }
  // }, [recentData]);

  // const {
  //   timeSlice
  //   // length
  // } = dropdownOptions[selectedOption];

  // const { data, loading, refetch } = useQuery(CAUSAL_CHAINS_SUMMARY, {
  //   variables: { timeSlice },
  //   fetchPolicy: 'network-only'
  // });

  useEffect(() => {
    setPageTitle('Insights');
  }, []);

  // useEffect(() => {
  //   if (data?.causalChainsSummary) {
  //     const tempJustify = data.causalChainsSummary.causal_chains;

  //     setJustifyData(tempJustify);
  //   } else {
  //     setJustifyData([]);
  //   }
  // }, [data]);

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <SummaryOfSummaries />
    </Grid>
  );
};

DashboardInsights.propTypes = {};

export default DashboardInsights;
