import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

export const CREATE_ORGANIZATION = gql`
  mutation(
    $name: String!
    $company_short_name: String!
    $business_type_id: ID!
    $structure_type_id: Int!
  ) {
    createInitialOrganization(
      name: $name
      company_short_name: $company_short_name
      business_type_id: $business_type_id
      structure_type_id: $structure_type_id
    ) {
      id
      name
    }
  }
`;

export const CREATE_ORGANIZATION_PILOT = gql`
  mutation($name: String!, $company_short_name: String!, $structure_type_id: Int!) {
    createInitialOrganizationPilot(
      name: $name
      company_short_name: $company_short_name
      structure_type_id: $structure_type_id
    ) {
      id
      name
    }
  }
`;

export const ORGANIZATION_SETTINGS = gql`
  query {
    getCurrentOrganization {
      id
      mfa_mandatory
      mfa_on_from
      mfa_new_user_grace_days
      mfa_permit_device_trust
      mfa_exception_group
      week_start
      quarter_start
    }
  }
`;

export const UPDATE_ORGANIZATION_DATE_PREFERENCES = gql`
  mutation($week_start: Int, $quarter_start: Int) {
    updateDatePreferences(week_start: $week_start, quarter_start: $quarter_start)
  }
`;

export const UPDATE_ORGANIZATION_MFA_SETTINGS = gql`
  mutation(
    $mfa_mandatory: Boolean!
    $mfa_on_from: DateTimeISO
    $mfa_new_user_grace_days: Int!
    $mfa_permit_device_trust: Boolean!
    $mfa_exception_group: Int
  ) {
    updateMFASettings(
      mfa_mandatory: $mfa_mandatory
      mfa_on_from: $mfa_on_from
      mfa_new_user_grace_days: $mfa_new_user_grace_days
      mfa_permit_device_trust: $mfa_permit_device_trust
      mfa_exception_group: $mfa_exception_group
    ) {
      id
      mfa_mandatory
      mfa_on_from
      mfa_new_user_grace_days
      mfa_permit_device_trust
      mfa_exception_group
    }
  }
`;

export async function apiCreateInitialOrganization(
  name,
  companyName,
  businessTypeId,
  structureTypeId
) {
  return apolloClientHelper(
    CREATE_ORGANIZATION,
    {
      name,
      company_short_name: companyName,
      business_type_id: businessTypeId,
      structure_type_id: structureTypeId
    },
    true // mutation
  );
}
