/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { ASSET_FIELD_NAMES } from './asset';

export const ANALYTICS = gql`
  query analytics($query: String!, $startDate: DateTimeISO, $endDate: DateTimeISO) {
    analytics(query: $query, startDate: $startDate, endDate: $endDate) {
      target_field
      date
      time
      organization
      container
      theme
      topic
      campaign
      type
      associated_id
      grouping
      metric
      value
      delta
    }
  }
`;

export const GRAPHS = gql`
  query graphs($query: String!) {
    graphs(query: $query) {
      x
      y
    }
  }
`;

export const ANALYTICS_POSTS = gql`
  query tables(
    $type: String!
    $metrics: [String]!
    $accountIds: [ID]!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $after: Int
    $count: Int
    $insights: Boolean
  ) {
    tables(
      type: $type
      metrics: $metrics
      accountIds: $accountIds
      startDate: $startDate
      endDate: $endDate
      after: $after
      count: $count
      insights: $insights
    ) {
      rows {
        account
        date
        body
        post
        id
        assets {
          ${ASSET_FIELD_NAMES}
        }
        metrics {
          metric
          value
        }
      }
      count
    }
  }
`;

export const ANALYTICS_PAGES = gql`
  query tables(
    $type: String!
    $metrics: [String]!
    $accountIds: [ID]!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $validPlatforms: [String]
  ) {
    tables(
      type: $type
      metrics: $metrics
      accountIds: $accountIds
      startDate: $startDate
      endDate: $endDate
      validPlatforms: $validPlatforms
    ) {
      rows {
        account
        metrics {
          metric
          value
        }
      }
      count
    }
  }
`;

export const ANALYTICS_LIFETIME = gql`
  query tablesLifetime(
    $metrics: [String]!
    $accountIds: [ID]!
    $campaign: ID
    $theme: ID
    $topic: ID
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $after: Int
    $count: Int,
    $orderBy: [String]
    $types: [String]
    $onlyVideo: Boolean
    ) {
    tablesLifetime(
      metrics: $metrics
      accountIds: $accountIds
      campaign: $campaign
      theme: $theme
      topic: $topic
      startDate: $startDate
      endDate: $endDate
      after: $after
      count: $count
      orderBy: $orderBy
      types: $types
      onlyVideo: $onlyVideo
    ) {
      rows {
        account
        date
        body
        post
        id
        type
        thumbnailUrl
        publishedUrl
        assets {
          ${ASSET_FIELD_NAMES}
        }
        metrics {
          metric
          value
        }
      }
      count
    }
  }
`;

export const SHORTLINK_TIME_SERIES = gql`
  query shortlinkTimeSeries(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $containerIds: [ID]
    $campaignIds: [ID]
    $shortlinkIds: [ID]
  ) {
    shortlinkTimeSeries(
      startDate: $startDate
      endDate: $endDate
      containerIds: $containerIds
      campaignIds: $campaignIds
      shortlinkIds: $shortlinkIds
    ) {
      x
      y
    }
  }
`;

export const SHORTLINK_DISTRIBUTION = gql`
  query shortlinkDistribution(
    $column: String!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $containerIds: [ID]
    $campaignIds: [ID]
    $shortlinkIds: [ID]
  ) {
    shortlinkDistribution(
      column: $column
      startDate: $startDate
      endDate: $endDate
      containerIds: $containerIds
      campaignIds: $campaignIds
      shortlinkIds: $shortlinkIds
    ) {
      x
      y
    }
  }
`;

export const SHORTLINK_TABLE = gql`
  query shortlinkTable(
    $column: String!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $containerIds: [ID]
    $campaignIds: [ID]
    $shortlinkIds: [ID]
  ) {
    shortlinkTable(
      column: $column
      startDate: $startDate
      endDate: $endDate
      containerIds: $containerIds
      campaignIds: $campaignIds
      shortlinkIds: $shortlinkIds
    ) {
      distribution
      total_clicks
      unique_clicks
    }
  }
`;

export const SHORTLINK_TOTAL = gql`
  query shortlinkTotal(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $after: Int
    $count: Int
    $orderBy: [String]
    $containerIds: [ID]
    $campaignIds: [ID]
  ) {
    shortlinkTotal(
      startDate: $startDate
      endDate: $endDate
      after: $after
      count: $count
      orderBy: $orderBy
      containerIds: $containerIds
      campaignIds: $campaignIds
    ) {
      rows {
        id
        created_at
        short_link
        custom_short_link
        long_url
        title
        clicks
        unique_clicks
        campaign_id
        domain {
          domain
          https_enabled
        }
      }
      count
    }
  }
`;

export const SHORTLINK_TRENDING = gql`
  query shortlinkTrending($startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    shortlinkTrending(startDate: $startDate, endDate: $endDate) {
      short_link
      custom_short_link
      clicks
      unique_clicks
      change
      domain {
        domain
        https_enabled
      }
    }
  }
`;

export const SHORTLINK_RECENT = gql`
  query shortlinkRecent {
    shortlinkRecent {
      short_link
      custom_short_link
      updated_at
      domain {
        domain
        https_enabled
      }
      created_by {
        avatar_url
        first_name
        last_name
      }
    }
  }
`;

export const BOX_TOTAL_WIDGET = gql`
  query analyticsTotalBox(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
    $onlyVideo: Boolean
  ) {
    analyticsTotalBox(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
      onlyVideo: $onlyVideo
    ) {
      primaryValue
      secondaryValue
      percentage
    }
  }
`;

export const BOX_GROWTH_WIDGET = gql`
  query analyticsGrowthBox(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    analyticsGrowthBox(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      primaryValue
      secondaryValue
      percentage
    }
  }
`;

export const CONTAINER_TREE_NODE = gql`
  query containerTree(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
    $metricSearchId: ID
    $containerId: ID!
    $total: Boolean
  ) {
    containerTree(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
      metricSearchId: $metricSearchId
      containerId: $containerId
      total: $total
    ) {
      primaryValue
    }
  }
`;

export const BOX_DEMOGRAPHIC_WIDGET = gql`
  query analyticsDemographicWidget(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $widget: String!
    $onlyVideo: Boolean
  ) {
    analyticsDemographicWidget(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      widget: $widget
      onlyVideo: $onlyVideo
    ) {
      metric
      value
    }
  }
`;

export const BOX_KPI_WIDGET = gql`
  query boxKPIWidget(
    $linkTokens: [ID!]
    $metric: String!
    $onlyVideo: Boolean
    $showAllContainerLinkTokens: Boolean
  ) {
    boxKPIWidget(
      linkTokens: $linkTokens
      metric: $metric
      onlyVideo: $onlyVideo
      showAllContainerLinkTokens: $showAllContainerLinkTokens
    ) {
      fifty
      ninety
      ninety_five
      ninety_eight
      ninety_nine
      max
      top_post_id
    }
  }
`;

export const DRUID_TIME_SERIES = gql`
  query timeSeries(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $graph: String!
  ) {
    timeSeries(startDate: $startDate, endDate: $endDate, linkTokens: $linkTokens, graph: $graph) {
      x
      y
    }
  }
`;

export const DRUID_TIME_SERIES_DELTA = gql`
  query timeSeriesDelta(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $graph: String!
    $onlyVideo: Boolean
  ) {
    timeSeriesDelta(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      graph: $graph
      onlyVideo: $onlyVideo
    ) {
      x
      y
    }
  }
`;

export const DRUID_GROUP_BY = gql`
  query groupBy(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $graph: String!
  ) {
    groupBy(startDate: $startDate, endDate: $endDate, linkTokens: $linkTokens, graph: $graph) {
      x
      y
    }
  }
`;

export const DRUID_LOCATION_TABLE = gql`
  query locationTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]
    $showAllContainerLinkTokens: Boolean
  ) {
    locationTable(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      showAllContainerLinkTokens: $showAllContainerLinkTokens
    ) {
      countries {
        column
        total
        change
        percentage
        difference
      }
      cities {
        column
        total
        change
        percentage
        difference
      }
      languages {
        column
        total
        change
        percentage
        difference
      }
    }
  }
`;

export const DRUID_ACCOUNT_TABLE = gql`
  query accountTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $table: String!
  ) {
    accountTable(startDate: $startDate, endDate: $endDate, linkTokens: $linkTokens, table: $table) {
      id
      metrics {
        column
        total
      }
    }
  }
`;

export const DRUID_DEMOGRAPHIC_TABLE = gql`
  query demographicTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $table: String!
  ) {
    demographicTable(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      table: $table
    ) {
      column
      columnValue
      metrics {
        column
        total
        change
        percentage
        difference
      }
    }
  }
`;

export const INSIGHT_ANALYTICS = gql`
  query insightAnalytics($startDate: DateTimeISO!, $endDate: DateTimeISO!, $linkTokens: [ID!]!, $position: String!, $count: Int, $after: Int) {
    insightAnalytics(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      position: $position
      count: $count
      after: $after
    ) {
      rows {
        account
        date
        body
        post
        id
        type
        assets {
          ${ASSET_FIELD_NAMES}
        }
        metrics {
          metric
          value
        }
        thumbnail_url
      }
      count
    }
  }
`;

export const DRUID_TABLE = gql`
query postTable(
  $startDate: DateTimeISO!
  $endDate: DateTimeISO!
  $linkTokens: [ID]!
  $table: String!
  $after: Int
  $count: Int
  $orderBy: [String]
  $linkTokenTypes: [String]
  $onlyVideo: Boolean
) {
  postTable(
    startDate: $startDate
    endDate: $endDate
    linkTokens: $linkTokens
    table: $table
    after: $after
    count: $count
    orderBy: $orderBy
    linkTokenTypes: $linkTokenTypes
    onlyVideo: $onlyVideo
  ) {
    rows {
      account
      date
      body
      post
      id
      type
      thumbnailUrl
      publishedUrl
      assets {
        ${ASSET_FIELD_NAMES}
      }
      metrics {
        metric
        value
      }
    }
    count
  }
}
`;

export const SINGLE_POST_GRAPH = gql`
  query singlePostGraph(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokenId: ID!
    $postId: String!
    $metric: String!
  ) {
    singlePostGraph(
      startDate: $startDate
      endDate: $endDate
      linkToken: $linkTokenId
      postId: $postId
      metric: $metric
    ) {
      x
      y
    }
  }
`;

export const SINGLE_POST_TABLE = gql`
  query singlePostTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokenId: ID!
    $postId: String!
    $metric: String!
  ) {
    singlePostTable(
      startDate: $startDate
      endDate: $endDate
      linkToken: $linkTokenId
      postId: $postId
      metric: $metric
    ) {
      column
      total
      change
      percentage
      difference
    }
  }
`;

export const GENDER_BY_DATE = gql`
  query genderByDate($startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    genderByDate(startDate: $startDate, endDate: $endDate) {
      count
      label
      percent
    }
  }
`;

export const AUDIENCE_SIZE = gql`
  {
    linkedAccounts(showOnlyEnabled: true) {
      type
      lifetime_followers
    }
  }
`;

export const WEB_AND_APPS_TABLE = gql`
  query webAndAppsTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
    $after: Int
    $count: Int
  ) {
    webAndAppsTable(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
      after: $after
      count: $count
    ) {
      rows {
        linktoken {
          id
        }
        metric2
        metric
        value
      }
      count
    }
  }
`;

export const WEB_AND_APPS_SOURCES_AND_PAGES = gql`
  query webAndAppsTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
    $after: Int
    $count: Int
  ) {
    webAndAppsTable(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
      after: $after
      count: $count
    ) {
      rows {
        linktoken {
          id
          remote_name
          type
        }
        metric
        value
        timeseries {
          x
          y
        }
      }
      count
    }
  }
`;

export const WEB_AND_APPS_LOCATION_GRAPH = gql`
  query webAndAppsLocationMap(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    webAndAppsLocationMap(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      countryMap {
        metric
        value
      }
    }
  }
`;

export const WEB_AND_APPS_LOCATION_TABLE = gql`
  query webAndAppsLocationMap(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    webAndAppsLocationMap(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      countryTable {
        metric
        growth {
          primaryValue
          secondaryValue
          percentage
        }
      }
      cityTable {
        metric
        growth {
          primaryValue
          secondaryValue
          percentage
        }
      }
    }
  }
`;

export const WEB_AND_APPS_GENDER_GRAPH = gql`
  query webAndAppsGender(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    webAndAppsGender(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      genderGraph {
        metric
        value
      }
    }
  }
`;

export const WEB_AND_APPS_GENDER_TABLE = gql`
  query webAndAppsGender(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $table: String!
  ) {
    webAndAppsGender(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $table
    ) {
      genderTable {
        gender
        columns {
          metric
          growth {
            primaryValue
            secondaryValue
            percentage
          }
        }
      }
    }
  }
`;

export const WEB_AND_APPS_APPS_GRAPH = gql`
  query webAndAppsAppsGraph(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
    $after: Int
    $count: Int
    $orderBy: [String]
  ) {
    webAndAppsAppsGraph(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
      after: $after
      count: $count
      orderBy: $orderBy
    ) {
      label
      lifetimeTotal
      timeseries {
        x
        y
      }
    }
  }
`;

export const WEB_AND_APPS_APPS_TABLE = gql`
  query webAndAppsAppsTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
    $after: Int
    $count: Int
    $orderBy: [String]
  ) {
    webAndAppsAppsTable(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
      after: $after
      count: $count
      orderBy: $orderBy
    ) {
      rows {
        linktoken
        product
        price
        device
        count
        revenue
      }
      count
    }
  }
`;
