import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { SHORTLINK_DISTRIBUTION } from 'gql/analytics';
import AlbGeoGraph from 'components/AlbGraphs/AlbGeoGraph';
import { getDatesForAnalytics } from 'util/date';

const ShortlinkLocation = props => {
  const { dates, shortlink } = props;

  const [codes, setCodes] = useState([]);
  const [data, setData] = useState([]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data: shortlinkData } = useQuery(SHORTLINK_DISTRIBUTION, {
    variables: {
      column: 'country_code',
      startDate,
      endDate,
      // eventual support for querying outside of the user's current container
      // containerIds: [sessionStorage.current_container],
      campaignIds: [],
      ...(shortlink !== null && { shortlinkIds: [shortlink] })
    }
  });

  useEffect(() => {
    if (shortlinkData?.shortlinkDistribution) {
      const shortlinks = shortlinkData.shortlinkDistribution;

      setCodes(shortlinks.map(({ x }) => x));
      setData(shortlinks.map(({ y }) => y));
    }
  }, [shortlinkData]);

  return <AlbGeoGraph codes={codes} datasets={[{ data }]} />;
};

ShortlinkLocation.propTypes = {
  dates: PropTypes.shape().isRequired,
  shortlink: PropTypes.string
};

ShortlinkLocation.defaultProps = {
  shortlink: null
};

export default ShortlinkLocation;
