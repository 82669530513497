import { gql } from '@apollo/client';

export const COMMUNITY_INSIGHT = gql`
  query communityInsight($timeSlice: TimeSlice!) {
    communityInsight(timeSlice: $timeSlice) {
      id
      container_id
      template_id
      prompt_summary
      response_summary
      prompt_justify
      response_justify
      start_date
      end_date

      prompt_template_feedback_from_user {
        feedback_type
      }
    }
  }
`;

export const COMMUNITY_INSIGHT_MOST_RECENT_DAY = gql`
  query communityInsightMostRecentDay {
    communityInsightMostRecentDay {
      end_date
    }
  }
`;

export const CREATE_PROMPT_TEMPLATE_FEEDBACK = gql`
  mutation createPromptTemplateFeedback($input: PromptTemplateFeedbackInput!) {
    createPromptTemplateFeedback(input: $input) {
      id
      template_id
      created_by
      feedback_type
      feedback
      created_at
      updated_at
    }
  }
`;
