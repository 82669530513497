import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import AlbSwitch from 'components/AlbSwitch';

const useStyles = makeStyles({
  switchLabel: {
    fontSize: 18,
    fontWeight: '500',
    lineHeight: '27px'
  }
});

const AlbSwitchOption = props => {
  const { leftOption, rightOption, onChange, checked } = props;
  const [isSwitchChecked, setIsSwitchChecked] = useState(checked);
  const classes = useStyles();

  return (
    <Box display="inline" alignItems="center">
      <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
        {leftOption}
      </Typography>
      <AlbSwitch
        onChange={event => {
          setIsSwitchChecked(event.target.checked);
          onChange({ checked: event.target.checked });
        }}
        checked={isSwitchChecked}
      />
      <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
        {rightOption}
      </Typography>
    </Box>
  );
};

AlbSwitchOption.propTypes = {
  leftOption: PropTypes.string.isRequired,
  rightOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool
};

AlbSwitchOption.defaultProps = {
  checked: false
};

export default AlbSwitchOption;
