/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const LINKTOKEN_EVENTS = gql`
  query linktokenEvents(
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $startDateGraph: DateTimeISO
    $endDateGraph: DateTimeISO
    $typeFilter: [String]
    $after: Int
    $count: Int
  ) {
    linktokenEvents(
      startDate: $startDate
      endDate: $endDate
      startDateGraph: $startDateGraph
      endDateGraph: $endDateGraph
      typeFilter: $typeFilter
      after: $after
      count: $count
    ) {
      id
      linktoken_id
      event_type
      event_level
      event_metric
      begin_date
      end_date
      lt_resurge_id
      peak_num_posts
      peak_delta_count
      is_complete
      post_id
      post_date
      num_missing
      num_zero_delta
      peak_count_diff
      event_mean_count
      event_delta_count
      event_excess_count
      event_end_count
      event_relative_rank
      lifetime_count
      metadata_json
      campaign_events {
        id
        linked_account_id
        post_id
        type
      }
      goal_id
      source
      created_at
      updated_at
      linktoken {
        id
        type
        remote_name
      }
      goal {
        id
        goal_id
        name
      }
      valuation
      graph(startDate: $startDateGraph, endDate: $endDateGraph) {
        event_label
        timeseries {
          x
          y
        }
      }
    }
  }
`;
