import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { CreateNewFolderOutlined as CreateFolderIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Modal, Button, Card } from '@material-ui/core';
import { ASSET_CREATE_FOLDER } from 'gql/asset';
import { showToast } from 'contexts/ToastContext';
import { validateFolderName } from 'util/assetNameValidation';

const styles = () => ({
  createFolderIcon: {
    bottom: 0,
    height: '30%',
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '30%'
  },
  card: {
    paddingBottom: '100%',
    position: 'relative',
    background: '#f8f9fa',
    border: '2px solid transparent',
    '&:hover': {
      outline: '2px solid #0A1734',
      outlineOffset: '3px',
      cursor: 'pointer'
    }
  },
  modal: {
    position: 'relative',
    top: '50%',
    width: '30%',
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'center',
    padding: '15px',
    backgroundColor: 'white',
    boxShadow: '5'
  },
  createFolderText: {
    position: 'absolute',
    fontWeight: '500',
    color: '#383838',
    fontSize: '12px',
    marginTop: '-25px',
    fontFamily: 'Poppins',
    zIndex: 20
  }
});

const CreateFolderCard = props => {
  const { classes, uploadFolder, refetch } = props;
  const [folderCreateModal, toggleFolderCreateModal] = useState(false);
  const [createFolderInput, onInputChange] = useState(null);
  const [hoverText, toggleHoverText] = useState(false);

  const [createAssetFolder, { data, error }] = useMutation(ASSET_CREATE_FOLDER);

  useEffect(() => {
    if (data?.createAssetFolder) {
      // prop to refetch assets & update grid.
      refetch();
      toggleFolderCreateModal(false);
    }

    if (error) {
      showToast(`Error: ${error} - Please try again`, 'error');
    }
  }, [data, error]);

  const validateAndCreateFolder = () => {
    if (createFolderInput) {
      const folderNamePath = validateFolderName({ name: createFolderInput, uploadFolder });

      if (folderNamePath) {
        createAssetFolder({
          variables: {
            path: folderNamePath
          }
        });
      } else {
        showToast('Please enter a valid folder name.', 'error');
      }
    } else {
      showToast('Please enter a name for your new folder.', 'error');
    }
  };

  return (
    <Grid item xs={6} md={3} lg={2} className={classes.gridItem}>
      <div>
        <Card
          elevation={0}
          square
          className={classes.card}
          onClick={() => toggleFolderCreateModal(true)}
          onMouseEnter={() => toggleHoverText(true)}
          onMouseLeave={() => toggleHoverText(false)}
        >
          <CreateFolderIcon className={classes.createFolderIcon} />
          {hoverText && (
            <Grid
              container
              justifyContent="center"
              alignItems="baseline"
              className={classes.createFolderText}
            >
              <div>Create New Folder</div>
            </Grid>
          )}
        </Card>
        <Modal open={folderCreateModal} onClose={() => toggleFolderCreateModal(false)}>
          <div className={classes.modal}>
            <TextField
              onChange={e => onInputChange(e.target.value)}
              placeholder="Name Your Folder"
            />
            <Button onClick={validateAndCreateFolder}>Create</Button>
          </div>
        </Modal>
      </div>
    </Grid>
  );
};

CreateFolderCard.propTypes = {
  classes: PropTypes.shape().isRequired,
  uploadFolder: PropTypes.string,
  refetch: PropTypes.func
};

CreateFolderCard.defaultProps = {
  uploadFolder: '/',
  refetch: () => {}
};

export default withStyles(styles)(CreateFolderCard);
