import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NotInterestedTwoToneIcon from '@material-ui/icons/NotInterestedTwoTone';
import Box from 'components/Box';
import checkForPriv from 'util/checkForPriv';

const styles = makeStyles({
  container: {
    padding: '24px'
  },
  permissionError: {
    color: '#0A1734',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '36px'
  }
});
/**
 * @file HasAnyPriv is a front-end wrapper component which will by default hide any components within it if the user
 * does not have sufficient privileges; privs should exactly match the items in the privs table in the API DB.
 *
 * @module HasAnyPriv
 * @extends Component
 *
 * @params {String[]} privs - Array of privileges (1+ required), any matching will allow access
 * @params {Object} children - Child component to display if user has the right privilege
 * @params {String[]} [currentPrivs=<provided by mapStateToProps>] - Array of user's current privileges
 * @params {Function} [no=() => null] - Called if user does not have the required privilege
 *
 * @example <caption>Only show the component if the user has access, otherwise show nothing</caption>
 * <HasAnyPriv privs={['THEMES:DELETE']}>
 *   <SecretComponent/>
 * </HasAnyPriv>
 *
 * @example <caption>Show the component if the user has access, otherwise show a message guiding the user on how to
 * request access from a manager. Will be most often used to wrap entire pages of functionality</caption>
 * <HasAnyPriv privs={['CAMPAIGNS:EDIT']} showAlert>
 *   <SecretComponent/>
 * </HasAnyPriv>
 *
 * @example <caption>Show a different component if the user does not have access</caption>
 * <HasAnyPriv privs={['CAMPAIGNS:CREATE']} no={() => 'Access Denied'}>
 *   <SecretComponent/>
 * </HasAnyPriv>
 */

const HasAnyPriv = props => {
  const { children, privs, no, showAlert, currentPrivs } = props;
  const classes = styles();

  // If the user has one of the listed privs, return the encapsulated children
  if (currentPrivs && privs.length) {
    let canAccess = false;

    privs.forEach(priv => {
      if (checkForPriv(currentPrivs, priv.toUpperCase())) {
        canAccess = true;
      }
    });

    if (canAccess) {
      return children;
    }
  }

  if (showAlert) {
    return (
      <Grid container justifyContent="center">
        <Paper>
          <Grid container justifyContent="center" alignItems="center" className={classes.container}>
            <Box mr={12}>
              <NotInterestedTwoToneIcon color="error" fontSize="large" />
            </Box>
            <Typography className={classes.permissionError}>
              You do not currently have permission to access this area.
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return no();
};

/**
 * @name HasAnyPriv propTypes
 * @type {propTypes}
 * @param {Object} props - React PropTypes
 * @property {String[]} privs - Array of privileges (1+ required), any matching will allow access
 * @property {Object} children - Child component to display if user has the right privilege
 * @property {String[]} [currentPrivs=<provided by mapStateToProps>] - Array of user's current privileges
 * @property {Component} [no=() => null] - Return if user does not have the required privilege
 * @property {Bool} [showAlert=false] - If true, shows an alert to the user that they do not have access. Most useful for full pages
 * @return {Array} - React propTypes
 */
HasAnyPriv.propTypes = {
  privs: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.shape().isRequired,
  currentPrivs: PropTypes.arrayOf(PropTypes.string).isRequired, // provided by mapStateToProps
  no: PropTypes.func,
  showAlert: PropTypes.bool
};

HasAnyPriv.defaultProps = {
  no: () => null,
  showAlert: false
};

const mapStateToProps = state => {
  return {
    currentPrivs: state.auth.currentPrivs
  };
};

export default connect(mapStateToProps)(HasAnyPriv);
