import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnalyticsBox from 'components/AnalyticsBox';
import ThirdPartyMediaTable from 'components/AlbTable/ThirdPartyMediaTable';
import AlembicPageHeader from 'components/AlembicPageHeader';
import {
  ThirdPartyMediaAnalyticsSettingsPath,
  CreateThirdPartyMediaAnalyticsPath,
  CreateEarnedMediaAnalyticsPath
} from 'util/paths';
import { goToRoute, goToRouteReplace, parseQueryString } from 'util/routesHelpers';
import { withRouter } from 'react-router-dom';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { WEB, BROADCAST, PODCAST } from './ThirdPartyMediaConsts';

/**
 * @method
 * @summary This component renders the @see ThirdPartyMediaTable with an option to select Active/Archived 3rd party media searches
 * @name ThirdPartyMediaReports
 * @return {Object} - React JSX
 */
const ThirdPartyMediaReports = props => {
  const { history, location, refetchReports, isThirdPartyAccessAllowed } = props;

  const [mediaType, setMediaType] = useState(WEB);

  useEffect(() => {
    // web, broadcast or podcast toggle
    const media = parseQueryString('mediaType', location)?.[0];

    if (media) {
      goToRouteReplace(`${ThirdPartyMediaAnalyticsSettingsPath}?mediaType=${media}`);
      setMediaType(media);
    }
  }, []);

  const handleChange = event => {
    goToRouteReplace(`${ThirdPartyMediaAnalyticsSettingsPath}?mediaType=${event.target.value}`);
    setMediaType(event.target.value);
  };

  return (
    <>
      <AlembicPageHeader
        pageTitle="3rd Party Media Reports"
        buttonTitle="Create New Report"
        buttonPrivs={[
          'TVEYES',
          'TVEYES:SEARCH',
          'TVEYES:SEARCH:CREATE',
          'TVEYES:SEARCH:DELETE',
          'TVEYES:SEARCH:UPDATE'
        ]}
        buttonDisabled={
          (mediaType === BROADCAST || mediaType === PODCAST) && !isThirdPartyAccessAllowed
        }
        buttonOnClick={() => {
          if (mediaType === BROADCAST || mediaType === PODCAST) {
            goToRoute(`${CreateThirdPartyMediaAnalyticsPath}?mediaType=${mediaType}`, history);
          }

          if (mediaType === WEB) {
            goToRoute(CreateEarnedMediaAnalyticsPath, history);
          }
        }}
      >
        <div right>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={mediaType}
            onChange={handleChange}
          >
            <FormControlLabel value={WEB} control={<Radio />} label="Web" />
            <FormControlLabel value={BROADCAST} control={<Radio />} label="TV/Radio" />
            <FormControlLabel value={PODCAST} control={<Radio />} label="Podcast" />
          </RadioGroup>
        </div>
      </AlembicPageHeader>
      <AnalyticsBox noMargin tabs={['Active', 'Archived']} onChange={() => {}}>
        <ThirdPartyMediaTable tableOnly mediaType={mediaType} refetchReports={refetchReports} />
        <ThirdPartyMediaTable
          tableOnly
          mediaType={mediaType}
          enabled={false}
          refetchReports={refetchReports}
        />
      </AnalyticsBox>
    </>
  );
};

ThirdPartyMediaReports.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  refetchReports: PropTypes.func.isRequired,
  isThirdPartyAccessAllowed: PropTypes.bool.isRequired
};

export default withRouter(ThirdPartyMediaReports);
