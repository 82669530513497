import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox, Radio, TextField, Chip, Button, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { GET_SALESFORCE_GROUPS } from 'gql/salesforce';
// eslint-disable-next-line import/no-cycle
import SalesforceGroupModal from 'components/AlembicModalForm/SalesforceGroupModal';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { BUTTON_TEXT } from 'components/AnalyticsThirdPartyMedia/util';
import { showToast } from 'contexts/ToastContext';
import theme from 'theme';

const styles = makeStyles({
  root: {
    width: '318px',
    height: '41px'
  },
  paper: {
    fontFamily: 'Poppins'
  },
  selectOption: {
    fontWeight: 500,
    fontStyle: 'Poppins',
    fontSize: '13px',
    color: 'black'
  },
  option: {
    '&:active': {
      backgroundColor: 'transparent'
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent'
    }
  },
  inputRoot: {
    height: '100%'
  },
  listbox: {
    padding: 0,
    maxHeight: '660px'
  },
  group: {
    padding: '9px'
  },
  iconButton: {
    color: '#686868',
    margin: '5px'
  },
  lowerButton: {
    position: 'sticky',
    bottom: 0,
    background: '#ffffff'
  },
  createButton: {
    color: '#FFFFFF',
    margin: '20px'
  }
});

const dropdownTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiAutocomplete: {
      inputRoot: {
        minHeight: '40px',
        padding: '0px !important'
      }
    }
  }
});

/**
 * @method
 * @summary This component renders the statuses and stages dropdown for Salesforce Analytics section
 * @name StatusAndStageDropdown
 * @return {Object} - React JSX
 */

const StatusAndStageDropdown = ({
  options,
  openDropdown,
  setOpenDropdown,
  selectedOptions,
  setSelectedOptions,
  clearSelection,
  modal
}) => {
  const classes = styles();

  const [optionsWithGroups, setOptionsWithGroups] = useState(options);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const { data, loading, error } = useQuery(GET_SALESFORCE_GROUPS, {
    fetchPolicy: 'network-only',
    skip: modal
  });

  // status/stage limit in dropdown
  const limit = 7;
  // tags display limit in autocomplete
  const limitTags = 1;

  const getOptionKey = ({ type, id }) => `${type}-${id}`;

  const filterStatuses = ({ type }) => type === 'statuses';
  const filterStages = ({ type }) => type === 'stages';

  useEffect(() => {
    if (options.length && !loading) {
      // provide placeholder default until user updates the default
      const defaultStatuses = options.filter(filterStatuses).slice(0, limit);
      const defaultStages = options.filter(filterStages).slice(0, limit);

      let defaultGroup = {
        id: 'default',
        name: 'Default Group',
        lead_status_ids: defaultStatuses.map(({ id }) => id),
        opportunity_stage_ids: defaultStages.map(({ id }) => id),
        type: 'groups',
        is_default: true
      };

      let otherGroups = [];

      if (data?.getSalesforceGroups?.length) {
        const salesforceGroups = data.getSalesforceGroups.map(group => ({
          type: 'groups',
          ...group
        }));

        otherGroups = salesforceGroups.filter(group => !group.is_default);

        if (salesforceGroups.find(group => group.is_default)) {
          defaultGroup = salesforceGroups.find(group => group.is_default);
        }
      }

      setOptionsWithGroups([...[defaultGroup, ...otherGroups], ...options]);
      setSelectedGroup(defaultGroup);
    } else {
      setOptionsWithGroups([]);
      setSelectedGroup(null);
    }
  }, [data, loading, options]);

  useEffect(() => {
    if (selectedGroup) {
      const { lead_status_ids: statusIds, opportunity_stage_ids: stageIds } = selectedGroup;

      const statuses = options.filter(filterStatuses).filter(({ id }) => statusIds.includes(id));
      const stages = options.filter(filterStages).filter(({ id }) => stageIds.includes(id));

      setSelectedOptions([...statuses, ...stages]);
    }
  }, [selectedGroup]);

  const onInputChange = (e, option, reason) => {
    if (reason === 'clear' || reason === 'reset') {
      clearSelection();
      setSelectedGroup(null);

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const renderGroup = params => {
    const label = {
      groups: 'GROUPS',
      stages: 'OPPORTUNITY STAGES',
      statuses: 'LEAD STATUSES'
    }[params.group];

    return [
      <div
        key={params.key}
        style={{ padding: '5px 24px', background: '#EEEEEE', fontSize: '10px' }}
      >
        {label}
      </div>,
      params.children,
      <Fragment key="stages">
        {!modal && params.group === 'stages' && (
          <Grid container alignItems="center" className={classes.lowerButton}>
            <Button
              fullWidth
              className={classes.createButton}
              variant="contained"
              color="primary"
              onMouseDown={() => {
                setIsModalOpen(true);
              }}
            >
              {selectedGroup ? BUTTON_TEXT.UPDATE : BUTTON_TEXT.CREATE}
            </Button>
          </Grid>
        )}
      </Fragment>
    ];
  };

  const onClose = () => {
    setOpenDropdown(false);
  };

  const onChange = (e, value, reason, details) => {
    let statuses = value.filter(filterStatuses);
    let stages = value.filter(filterStages);

    if (details) {
      const { option } = details;

      if (option.type === 'groups') {
        setSelectedGroup(prevState => (prevState?.id === option.id ? null : option));
      } else {
        if (reason === 'select-option') {
          const type = {
            statuses: 'lead statuses',
            stages: 'opportunity stages'
          }[option.type];

          if (option.type === 'statuses' && statuses.length > limit) {
            showToast(
              `You can only select up to ${limit} ${type}, please unselect one first.`,
              'warn'
            );

            const prevStatuses = statuses.slice(0, limit);
            statuses = prevStatuses;
          }

          if (option.type === 'stages' && stages.length > limit) {
            showToast(
              `You can only select up to ${limit} ${type}, please unselect one first.`,
              'warn'
            );

            const prevStages = stages.slice(0, limit);
            stages = prevStages;
          }
        }

        setSelectedGroup(null);
        setSelectedOptions([...statuses, ...stages]);
      }
    }
  };

  return (
    <MuiThemeProvider theme={dropdownTheme}>
      <div className={classes.root}>
        {loading && <AlbLoading />}
        {error && <AlbError error={error} />}
        <Autocomplete
          multiple
          limitTags={limitTags}
          options={modal ? options : optionsWithGroups}
          value={selectedOptions}
          classes={{
            option: classes.option,
            inputRoot: classes.inputRoot,
            listbox: classes.listbox
          }}
          open={openDropdown}
          onClose={onClose}
          disableCloseOnSelect
          getOptionLabel={option => option.name}
          renderGroup={renderGroup}
          groupBy={option => option.type}
          onChange={onChange}
          onInputChange={onInputChange}
          renderOption={option => {
            const selectOption = selectedOptions.map(getOptionKey).includes(getOptionKey(option));

            return (
              <Grid container alignItems="center" key={getOptionKey(option)} wrap="nowrap">
                {option.type === 'groups' && <Radio checked={selectedGroup?.id === option.id} />}
                {option.type !== 'groups' && <Checkbox checked={selectOption} />}
                <div className={`${classes.selectOption} ${classes.group}`}>{option.name}</div>
                {option.type === 'groups' && (
                  <IconButton
                    className={classes.iconButton}
                    disableFocusRipple
                    size="small"
                    onClick={e => {
                      setSelectedGroup(option);
                      setIsModalOpen(true);

                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            );
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps
                }}
                variant="outlined"
                placeholder={selectedOptions.length ? '' : 'Statuses & Stages'}
                onClick={() => setOpenDropdown(!openDropdown)}
              />
            );
          }}
          renderTags={(value, getTagProps) => {
            if (selectedGroup) {
              return <Chip style={{ margin: '3px' }} label={selectedGroup.name} />;
            }

            const numTags = value.length;

            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={getOptionKey(option)}
                    label={option.name}
                  />
                ))}
                {numTags > limitTags && ` +${numTags - limitTags}`}
              </>
            );
          }}
        />
      </div>
      {isModalOpen && !modal && (
        <SalesforceGroupModal
          options={options}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          previousSelectedOptions={selectedOptions}
          isModalOpen={isModalOpen}
          onChange={e => {
            setIsModalOpen(e.isModalOpen);
          }}
        />
      )}
    </MuiThemeProvider>
  );
};

StatusAndStageDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  openDropdown: PropTypes.bool.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
  modal: PropTypes.bool
};

StatusAndStageDropdown.defaultProps = {
  modal: false
};

export default StatusAndStageDropdown;
