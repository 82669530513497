import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditInPlaceInput from 'components/EditInPlaceInput';
import { UPDATE_RECREATE_COST_REPORT } from 'gql/recreateCostReport';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';
import { AlbTooltip } from 'components/AlbTooltip';
import AlbSwitch from 'components/AlbSwitch';
import tooltipText from 'util/tooltipText';
import CostToRecreateReportPicker from './CostToRecreateReportPicker';

const useStyles = makeStyles(() => ({
  previewHeading: {
    fontSize: 24,
    fontWeight: 500
  },
  previewBox: {
    marginBottom: '50px'
  },
  switchContainer: {
    minWidth: 500
  },
  switchLabel: {
    fontSize: 18,
    fontWeight: '500',
    lineHeight: '27px'
  },
  switch: {
    maxWidth: 85
  },
  switchCell: {},
  title: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    color: '#0A1734'
  },
  infoIconLeft: {
    marginRight: '8px'
  },
  infoIconRight: {
    marginLeft: '8px'
  }
}));

/**
 * Renders cost to recreate reports
 * @name CostToRecreateHeader
 * @param {Object} props - React props passed to this component
 * @param {Function} props.onChangeMetric - The callback function to call when a switch change is triggered
 * @param {Function} props.onClickRefreshPreview - The callback function to call when the refresh button is clicked
 * @param {Function} props.onChangeLifeTimeValue - The callback function to call when a switch change for lifetime value is triggered
 * @param {Boolean} props.isPreview - The boolean to indicate preview mode
 * @param {Boolean} props.isAnalytics - The boolean to indicate analytics mode
 * @param {Boolean} props.isDashboard - The boolean to indicate dashboard mode
 * @param {Boolean} props.switchChecked - boolean initial state of the switch component (false = CPM, true = CPA)
 * @param {Boolean} props.useLifetimeValueChecked - boolean initial state of the switch component (true = lifetime value calculation, false = druid time series calculation)
 * @param {Object} props.recreateCostReport - The RecreateCostReport object
 * @return {Object} - React JSX
 */
const CostToRecreateHeader = props => {
  const {
    onChangeMetric,
    onClickRefreshPreview,
    onChangeLifeTimeValue,
    isPreview,
    isAnalytics,
    isDashboard,
    switchChecked,
    useLifetimeValueChecked,
    recreateCostReport
  } = props;

  // initial state derived from prop
  const [isMetricChecked, setIsMetricChecked] = useState(switchChecked);
  const [useLifetimeValue, setUseLifeTimeValue] = useState(useLifetimeValueChecked);

  const classes = useStyles();

  const [updateRecreateCostReport, { error, data }] = useMutation(UPDATE_RECREATE_COST_REPORT);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.updateRecreateCostReport?.id) {
      showToast('Report name updated.', 'success');
    }
  }, [data]);

  // drive updated state from props
  useEffect(() => {
    setIsMetricChecked(switchChecked);
  }, [switchChecked]);

  useEffect(() => {
    // When the switch value changes, send an "event" object back up indicating which metric to use
    if (isMetricChecked === false) {
      onChangeMetric({
        value: isMetricChecked,
        useCPM: true
      });
    }

    if (isMetricChecked === true) {
      onChangeMetric({
        value: isMetricChecked,
        useCPA: true
      });
    }
  }, [isMetricChecked]);

  // drive updated lifetime value switch state from from props
  useEffect(() => {
    setUseLifeTimeValue(useLifetimeValueChecked);
  }, [useLifetimeValueChecked]);

  // send new lifetime value switch state to parent
  useEffect(() => {
    onChangeLifeTimeValue(useLifetimeValue);
  }, [useLifetimeValue]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={classes.previewBox}
    >
      <Grid item>
        {isPreview === true && <Typography className={classes.previewHeading}>Preview</Typography>}
        {isAnalytics === true && recreateCostReport?.id && (
          <EditInPlaceInput
            useEdit
            value={recreateCostReport.name}
            onChange={event => {
              if (event.value && event.value !== recreateCostReport.name) {
                updateRecreateCostReport({
                  variables: {
                    id: recreateCostReport.id,
                    name: event.value
                  }
                });
              }
            }}
          />
        )}
        {isDashboard && (
          <Typography className={classes.title}>{recreateCostReport.name}</Typography>
        )}
      </Grid>

      <Grid item className={classes.switchContainer}>
        <Grid container justifyContent="center" spacing={3} alignItems="center">
          <Grid item xs={5} align="right" className={classes.switchCell}>
            <AlbTooltip title={tooltipText.mvImpressionsCpm} />
            <span className={`${classes.switchLabel} ${classes.infoIconRight}`}>
              Impressions CPM
            </span>
          </Grid>
          <Grid item xs={1} align="center" className={`${classes.switch} ${classes.switchCell}`}>
            <AlbSwitch
              onChange={event => setIsMetricChecked(event.target.checked)}
              checked={isMetricChecked}
              name="useCPA"
              inputProps={{ 'aria-label': 'use CPM or CPA' }}
            />
          </Grid>
          <Grid item xs={5} className={classes.switchCell}>
            <span className={`${classes.switchLabel} ${classes.infoIconLeft}`}>Engagement CPA</span>
            <AlbTooltip title={tooltipText.mvEngagementCpa} />
          </Grid>
        </Grid>

        {!isDashboard && (
          <Grid container justifyContent="center" spacing={3} alignItems="center">
            <Grid item xs={5} align="right" className={classes.switchCell}>
              <AlbTooltip title={tooltipText.mvPublishedPosts} />
              <span className={`${classes.switchLabel} ${classes.infoIconRight}`}>
                Published Posts
              </span>
            </Grid>
            <Grid item xs={1} align="center" className={`${classes.switch} ${classes.switchCell}`}>
              <AlbSwitch
                onChange={event => setUseLifeTimeValue(!event.target.checked)}
                checked={!useLifetimeValue}
                name="useLifetimeValue"
                inputProps={{ 'aria-label': 'use lifetime value or Active Posts in Period' }}
              />
            </Grid>
            <Grid item xs={5} className={classes.switchCell}>
              <span className={`${classes.switchLabel} ${classes.infoIconLeft}`}>Active Posts</span>
              <AlbTooltip title={tooltipText.mvActivePosts} />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item>
        {isPreview === true && (
          <Button variant="contained" color="primary" onClick={onClickRefreshPreview}>
            Refresh
          </Button>
        )}
        {(isDashboard || isAnalytics) && recreateCostReport?.id != null && (
          <CostToRecreateReportPicker recreateCostReportId={recreateCostReport.id} />
        )}
      </Grid>
    </Grid>
  );
};

CostToRecreateHeader.propTypes = {
  onChangeMetric: PropTypes.func.isRequired,
  onClickRefreshPreview: PropTypes.func,
  onChangeLifeTimeValue: PropTypes.func.isRequired,
  isPreview: PropTypes.bool,
  isAnalytics: PropTypes.bool,
  isDashboard: PropTypes.bool,
  switchChecked: PropTypes.bool,
  useLifetimeValueChecked: PropTypes.bool,
  recreateCostReport: PropTypes.shape()
};

CostToRecreateHeader.defaultProps = {
  onClickRefreshPreview: () => {},
  isPreview: false,
  isAnalytics: false,
  isDashboard: false,
  switchChecked: false,
  useLifetimeValueChecked: false,
  recreateCostReport: {}
};

export default CostToRecreateHeader;
