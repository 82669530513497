import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@material-ui/core';
import {
  SECURITY_ROLES,
  CREATE_SECURITY_ROLE,
  UPDATE_SECURITY_ROLE,
  DUPLICATE_SECURITY_ROLE,
  DELETE_SECURITY_ROLE
} from 'gql/securityRole';
import PRIVILEGES from 'gql/privilege';
import { showToast } from 'contexts/ToastContext';

import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlembicModalForm from 'components/AlembicModalForm';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import PrivilegeList from 'components/SecurityRoles/PrivilegeList';
import RolesList from 'components/SecurityRoles/RolesList';

const styles = makeStyles({
  roles: {
    minWidth: 300,
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  privileges: {
    width: 'inherit'
  }
});

const createFields = [
  { fieldName: 'name', title: 'Role Name', type: 'input', validators: ['required'] },
  { fieldName: 'description', title: 'Description', type: 'textarea', validators: ['required'] }
];
const updateFields = [
  { fieldName: 'name', title: 'Role Name', type: 'input' },
  { fieldName: 'description', title: 'Description', type: 'textarea' }
];

const SecurityRoles = () => {
  const classes = styles();

  const [rolesTab, setRolesTab] = useState(0);
  const [rolesList, setRolesList] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedPrivileges, setSelectedPrivileges] = useState({});
  const [privilegeChildrenLookup, setPrivilegeChildrenLookup] = useState({});
  const [privilegeParentLookup, setPrivilegeParentLookup] = useState({});

  const [openCreateRole, setOpenCreateRole] = useState(false);
  const [openUpdateRole, setOpenUpdateRole] = useState(false);
  const [openDuplicateRole, setOpenDuplicateRole] = useState(false);
  const [openDeleteRole, setOpenDeleteRole] = useState(false);

  const { loading: roleLoading, error: roleError, data: roleData, refetch } = useQuery(
    SECURITY_ROLES,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (roleData) {
      setRolesList(roleData.security_roles);
    }
  }, [roleData]);

  const { loading: privLoading, error: privError, data: privData } = useQuery(PRIVILEGES, {
    fetchPolicy: 'network-only',
    // prevent onCompleted from firing every update
    variables: {}
  });

  useEffect(() => {
    if (privData) {
      setPrivilegeChildrenLookup(
        privData.listAllPrivileges.reduce((acc, curr) => {
          if (Object.prototype.hasOwnProperty.call(acc, curr.parent_id)) {
            acc[curr.parent_id].push(curr.id);
          } else {
            acc[curr.parent_id] = [curr.id];
          }

          return acc;
        }, {})
      );

      const lookup = new Map();

      [...privData.listAllPrivileges]
        .reverse()
        .forEach(item => lookup.set(item.id, item.parent_id));

      setPrivilegeParentLookup(lookup);
    }
  }, [privData]);

  const setPrivilegesForSelectedRole = role => {
    setSelectedRole(role);
    setSelectedPrivileges(
      role.privs.reduce((acc, curr) => {
        acc[curr.id] = true;

        return acc;
      }, {})
    );
  };

  const [createRole, { data: creationData, error: creationError }] = useMutation(
    CREATE_SECURITY_ROLE,
    {
      onCompleted: ({ createSecurityRole }) => {
        refetch();
        setPrivilegesForSelectedRole(createSecurityRole);
      }
    }
  );
  const [updateRole, { data: updateData, error: updateError }] = useMutation(UPDATE_SECURITY_ROLE, {
    onCompleted: ({ updateSecurityRole }) => {
      setPrivilegesForSelectedRole(updateSecurityRole);
      refetch();
    }
  });
  const [duplicateRole, { data: duplicationData, error: duplicationError }] = useMutation(
    DUPLICATE_SECURITY_ROLE,
    {
      onCompleted: ({ duplicateSecurityRole }) => {
        setPrivilegesForSelectedRole(duplicateSecurityRole);
        setRolesTab(1);
        refetch();
      }
    }
  );
  const [deleteRole, { data: deletionData, error: deletionError }] = useMutation(
    DELETE_SECURITY_ROLE,
    {
      onCompleted: () => {
        setPrivilegesForSelectedRole({ privs: [] });
        refetch();
      }
    }
  );

  // success and error handling
  useEffect(() => {
    if (creationData && !creationData.toasted) {
      showToast('Role creation successful', 'success');
      creationData.toasted = true;
    }
    if (updateData && !updateData.toasted) {
      showToast('Role update successful', 'success');
      updateData.toasted = true;
    }
    if (duplicationData && !duplicationData.toasted) {
      showToast('Role duplication successful', 'success');
      duplicationData.toasted = true;
    }
    if (deletionData && !deletionData.toasted) {
      showToast('Role deletion successful', 'success');
      deletionData.toasted = true;
    }

    if (creationError && creationError.graphQLErrors) {
      showToast(
        `Role creation failed. Error: ${creationError.graphQLErrors.map(({ message }) => message)}`,
        'error'
      );
      creationError.graphQLErrors = null;
    }
    if (updateError && updateError.graphQLErrors) {
      showToast(
        `Role update failed. Error: ${updateError.graphQLErrors.map(({ message }) => message)}`,
        'error'
      );
      updateError.graphQLErrors = null;
    }
    if (duplicationError && duplicationError.graphQLErrors) {
      showToast(
        `Role duplication failed. Error: ${duplicationError.graphQLErrors.map(
          ({ message }) => message
        )}`,
        'error'
      );
      duplicationError.graphQLErrors = null;
    }
    if (deletionError && deletionError.graphQLErrors) {
      showToast(
        `Role deletion failed. Error: ${deletionError.graphQLErrors.map(({ message }) => message)}`,
        'error'
      );
      deletionError.graphQLErrors = null;
    }
  }, [
    creationData,
    creationError,
    updateData,
    updateError,
    duplicationData,
    duplicationError,
    deletionData,
    deletionError
  ]);

  const handleRolesTabChange = (e, rolesTabValue) => {
    setRolesTab(rolesTabValue);
    setPrivilegesForSelectedRole({ privs: [] });
  };

  const handleOpenCreateRole = () => {
    setOpenCreateRole(true);
  };

  const handleOpenUpdateRole = () => {
    setOpenUpdateRole(true);
  };

  const handleOpenDuplicateRole = () => {
    setOpenDuplicateRole(true);
  };

  const handleOpenDeleteRole = () => {
    setOpenDeleteRole(true);
  };

  const handleCloseCreateRole = () => {
    setOpenCreateRole(false);
  };

  const handleCloseUpdateRole = () => {
    setOpenUpdateRole(false);
  };

  const handleCloseDuplicateRole = () => {
    setOpenDuplicateRole(false);
  };

  const handleCloseDeleteRole = () => {
    setOpenDeleteRole(false);
  };

  const handleCreateRole = role => {
    createRole({ variables: role });
    handleCloseCreateRole();
  };

  const handleUpdateRole = role => {
    const privs = Object.entries(selectedPrivileges).reduce((acc, [key, value]) => {
      if (value) acc.push(key);

      return acc;
    }, []);

    updateRole({ variables: { ...role, id: selectedRole.id, privs } });
    handleCloseUpdateRole();
  };

  const handleDuplicateRole = role => {
    duplicateRole({ variables: { ...role, id: selectedRole.id } });
    handleCloseDuplicateRole();
  };

  const handleDeleteRole = () => {
    deleteRole({ variables: { id: selectedRole.id } });
    handleCloseDeleteRole();
  };

  const handleSetSelectedPrivileges = privId => {
    let updatedPrivileges = {};
    let parentPrivSelected = null;

    const handlePrivilegeSelection = id => {
      if (parentPrivSelected === null) {
        parentPrivSelected = !selectedPrivileges[id];
      }

      updatedPrivileges = { ...updatedPrivileges, [id]: parentPrivSelected };

      if (Object.prototype.hasOwnProperty.call(privilegeChildrenLookup, id)) {
        privilegeChildrenLookup[id].forEach(handlePrivilegeSelection);
      }
    };

    const handleParentPrivilegeSelection = id => {
      const parentId = privilegeParentLookup.get(id);
      const currentPrivileges = { ...selectedPrivileges, ...updatedPrivileges };
      const isSelected = child => currentPrivileges[child];

      if (parentId) {
        // unselect parent if child is unselected
        if (!updatedPrivileges[id]) {
          updatedPrivileges = { ...updatedPrivileges, [parentId]: false };
        }

        // select parent if all children are selected
        if (privilegeChildrenLookup[parentId].every(isSelected)) {
          updatedPrivileges = { ...updatedPrivileges, [parentId]: true };
        }

        handleParentPrivilegeSelection(parentId);
      }
    };

    handlePrivilegeSelection(privId);
    handleParentPrivilegeSelection(privId);
    setSelectedPrivileges({ ...selectedPrivileges, ...updatedPrivileges });
  };

  if (roleLoading || privLoading) return <AlbLoading />;
  if (roleError || privError) return `Error! ${roleError || privError}`;

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid container direction="column">
          <Grid container wrap="nowrap">
            {/* Left Pane: Roles */}
            <Grid item className={classes.roles}>
              <Tabs value={rolesTab} onChange={handleRolesTabChange}>
                <Tab disableRipple value={0} label="System" />
                <Tab disableRipple value={1} label="Custom" />
              </Tabs>
              {rolesTab === 0 && (
                <RolesList
                  currentTab={rolesTab}
                  roleTypes="S"
                  rolesList={rolesList}
                  selectedRole={selectedRole}
                  onSelect={setPrivilegesForSelectedRole}
                />
              )}
              {rolesTab === 1 && (
                <RolesList
                  currentTab={rolesTab}
                  roleTypes="C"
                  rolesList={rolesList}
                  selectedRole={selectedRole}
                  onCreate={handleOpenCreateRole}
                  onSelect={setPrivilegesForSelectedRole}
                />
              )}
            </Grid>
            {/* Privileges */}
            <Box p={30} className={classes.privileges}>
              {privData.listAllPrivileges && (
                <PrivilegeList
                  currentTab={rolesTab}
                  selectedRole={selectedRole}
                  privilegeList={privData.listAllPrivileges}
                  selectedPrivileges={selectedPrivileges}
                  onPrivilegeSelect={handleSetSelectedPrivileges}
                  handleConfirm={handleUpdateRole}
                  handleOpenUpdate={handleOpenUpdateRole}
                  handleOpenDuplicate={handleOpenDuplicateRole}
                  handleOpenDelete={handleOpenDeleteRole}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <AlembicModalForm
        saveTitle="Create Role"
        cancelTitle="Cancel"
        open={openCreateRole}
        onClose={handleCloseCreateRole}
        handleConfirm={handleCreateRole}
        fields={createFields}
      />

      <AlembicModalForm
        saveTitle="Update Role"
        cancelTitle="Cancel"
        open={openUpdateRole}
        onClose={handleCloseUpdateRole}
        handleConfirm={handleUpdateRole}
        fields={updateFields}
        currentObject={selectedRole}
      />

      <AlembicModalForm
        saveTitle="Duplicate Role"
        cancelTitle="Cancel"
        open={openDuplicateRole}
        onClose={handleCloseDuplicateRole}
        handleConfirm={handleDuplicateRole}
        fields={updateFields}
        currentObject={selectedRole}
      />

      <AlembicModalConfirm
        isDelete
        isOpen={openDeleteRole}
        title="Delete Role"
        body="Are you sure you want to delete this role?"
        cancelTitle="Cancel"
        confirmTitle="Delete role"
        handleCancel={handleCloseDeleteRole}
        handleConfirm={handleDeleteRole}
      />
    </>
  );
};

export default SecurityRoles;
