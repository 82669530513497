import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Paper } from '@material-ui/core';

const ModalContainer = ({ open, close, style, children }) => {
  return (
    <Modal open={open} onClose={close} style={style}>
      <Paper>{children}</Paper>
    </Modal>
  );
};

ModalContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.shape(),
  style: PropTypes.shape()
};

ModalContainer.defaultProps = {
  children: null,
  style: null
};

export default ModalContainer;
