import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Typography, IconButton } from '@material-ui/core';
import { Add as ShowIcon, Remove as HideIcon } from '@material-ui/icons';
import { CONTAINER } from 'gql/container';
import { DefaultContainerAvatar } from 'util/assets';
import { switchContainer } from 'actions/authActions';
import store from 'store';

const style = {
  widget: {
    minWidth: '464px',
    padding: '13px 20px',
    borderBottom: 'solid 1px rgba(13, 26, 58, 0.2)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(111, 111, 111, 0.1)'
    }
  },
  displayButton: {
    width: '28px',
    height: '28px',
    marginRight: '12px',
    borderRadius: '100%',
    padding: '0px'
  },
  containerAvatar: {
    width: '28px',
    height: '28px',
    marginRight: '18px'
  },
  containerTitle: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#6f6f6f'
  },
  showMore: {
    height: '54px'
  }
};

class DashboardContainerNavigation extends Component {
  constructor(props) {
    super(props);
    const { showSubContainer } = this.props;
    this.state = {
      showSubContainer
    };
  }

  toggleSubContainer = event => {
    event.stopPropagation();
    const { showSubContainer } = this.state;
    this.setState({
      showSubContainer: !showSubContainer
    });
  };

  render() {
    const {
      classes,
      containerId,
      masterContainer,
      closeWidget,
      handleContainerChange
    } = this.props;
    const { showSubContainer } = this.state;

    return (
      <Query query={CONTAINER} variables={{ id: containerId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `${error}`;
          return (
            <Fragment>
              <Grid
                container
                onMouseEnter={this.handleShowOverlay}
                onMouseLeave={this.handleHideOverlay}
                alignItems="center"
                className={classes.widget}
                style={{
                  paddingLeft: `${20 +
                    40 * (data.container.depth - 1) +
                    (!masterContainer && data.container.children.length === 0 ? 40 : 0)}px`
                }}
                onClick={() => {
                  handleContainerChange();
                  store.dispatch(switchContainer(containerId)).then(() => {
                    closeWidget();
                  });
                }}
              >
                {(!masterContainer && data.container.children.length) > 0 && (
                  <IconButton
                    color="primary"
                    onClick={this.toggleSubContainer}
                    className={classes.displayButton}
                  >
                    {showSubContainer && <HideIcon />}
                    {!showSubContainer && <ShowIcon />}
                  </IconButton>
                )}
                <Avatar
                  alt="Container Avatar"
                  src={data.container.avatar_url || DefaultContainerAvatar}
                  className={classes.containerAvatar}
                />
                <Typography className={classes.containerTitle}>{data.container.name}</Typography>
              </Grid>
              {showSubContainer &&
                data.container.children.map(child => {
                  return (
                    <DashboardContainerNavigation
                      key={child.id}
                      classes={classes}
                      containerId={child.id}
                      closeWidget={closeWidget}
                      handleContainerChange={handleContainerChange}
                    />
                  );
                })}
              {/* {masterContainer && data.container.children.length > 10 && (
                <Button fullWidth className={classes.showMore}>
                  SHOW MORE
                </Button>
              )} */}
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

DashboardContainerNavigation.propTypes = {
  classes: PropTypes.shape().isRequired,
  containerId: PropTypes.string.isRequired,
  showSubContainer: PropTypes.bool,
  masterContainer: PropTypes.bool,
  closeWidget: PropTypes.func.isRequired,
  handleContainerChange: PropTypes.func.isRequired
};

DashboardContainerNavigation.defaultProps = {
  showSubContainer: false,
  masterContainer: false
};

export default withStyles(style)(DashboardContainerNavigation);
