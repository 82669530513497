import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    '& :first-child': {
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px'
    },
    '& :last-child': {
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    background: '#CCCCCC',
    padding: '4px 10px'
  },
  titleContainerClickable: {
    background: '#CCCCCC',
    padding: '4px 10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  bodyContainer: {
    background: '#EAEAEA',
    padding: '4px 10px',
    maxWidth: '240px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  bodyContainerClickable: {
    display: 'flex',
    background: '#EAEAEA',
    padding: '4px 10px',
    alignItems: 'bottom',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  text: {
    fontSize: '13px',
    color: '#000000',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  recurrence: {
    fontSize: '13px',
    color: '#32327D'
  },
  rightButtonContainer: {
    background: '#CCCCCC',
    padding: '4px 10px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

/**
 * @method
 * @summary This component renders a chip with title text on the left and body text on the right
 * @name AlembicTitleChip
 * @param {Object} props - React props passed to this component
 * @param {string} props.title - The string on the left side of the chip
 * @param {string} props.body - The string on the right side of the chip
 * @param {string} props.recurrence - A number of recurrences of a keyword
 * @return {Object} - React JSX
 */
const AlembicTitleChip = props => {
  const {
    title,
    body,
    recurrence,
    rightButton,
    onTitleClick,
    onButtonClick,
    onBodyClick,
    icon,
    customBodyStyle
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div
        className={onTitleClick ? classes.titleContainerClickable : classes.titleContainer}
        onClick={onTitleClick}
        role="presentation"
      >
        <Typography className={classes.text}>{title}</Typography>
      </div>
      <div
        className={`${onBodyClick ? classes.bodyContainerClickable : classes.bodyContainer}`}
        style={customBodyStyle || null}
        onClick={onBodyClick}
        role="presentation"
      >
        {icon && (
          <img
            src={icon}
            alt="icon"
            height="13px"
            style={{ marginRight: '10px', paddingBottom: '0px' }}
          />
        )}
        <Tooltip title={body}>
          <Typography component="span" className={classes.text}>
            {body}
          </Typography>
        </Tooltip>
        {recurrence && (
          <>
            <Typography component="span" className={classes.text}>
              &nbsp;-&nbsp;
            </Typography>
            <Typography component="span" className={classes.recurrence}>
              {recurrence}
            </Typography>
          </>
        )}
      </div>
      {rightButton ? (
        <div className={classes.rightButtonContainer} onClick={onButtonClick} role="presentation">
          <Typography className={classes.text}>{rightButton}</Typography>
        </div>
      ) : null}
    </div>
  );
};

AlembicTitleChip.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  recurrence: PropTypes.number,
  rightButton: PropTypes.string,
  onTitleClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  onBodyClick: PropTypes.func,
  icon: PropTypes.string,
  customBodyStyle: PropTypes.shape()
};

AlembicTitleChip.defaultProps = {
  recurrence: null,
  rightButton: null,
  onTitleClick: null,
  onButtonClick: null,
  onBodyClick: null,
  icon: null,
  customBodyStyle: null
};

export default AlembicTitleChip;
