import { iso639_1 as languages } from 'util/iso';
// inputType: one of: text, number, checkbox, datepicker, radio, select

export const formatLanguageOptions = () => {
  // list derived from 'https://app.socialanimal.com/?q=test' - language dropdown
  const SALanguageFilter = [
    'English',
    'Spanish',
    'German',
    'French',
    'Dutch',
    'Portuguese',
    'Danish',
    'Italian',
    'Polish',
    'Finnish',
    'Swedish',
    'Arabic',
    'Hebrew',
    'Russian',
    'Chinese',
    'Indonesian',
    'Japanese'
  ];
  const languagesObj = {};

  Object.entries(languages).forEach(([k, v]) => {
    languagesObj[v] = {
      key: k,
      value: { value: v, displayName: v }
    };
  });

  const filteredLanguages = SALanguageFilter.map(language => languagesObj[language]);

  return filteredLanguages;
};

export const formatLanguageOptionsND = () => {
  // list derived from 'https://newsdata.io/documentation/#news-archive' - language dropdown
  const NDLanguageFilter = [
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'Bulgarian',
    'Burmese',
    'Catalan',
    'Central Kurdish',
    'Chinese',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Filipino',
    'Finnish',
    'French',
    'Georgian',
    'German',
    'Greek',
    'Gujarati',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Italian',
    'Japanese',
    'Kannada',
    'Khmer',
    'Kinyarwanda',
    'Korean',
    'Latvian',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Oriya',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Samoan',
    'Serbian',
    'Shona',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Spanish',
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Telugu',
    'Thai',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh'
  ];

  const languagesObj = {};

  Object.entries(languages).forEach(([k, v]) => {
    // Newsdata doesn't follow exact iso639_1 standards
    // If the value is 'Japanese' and the key is 'ja', change the key to 'jp'
    if (v === 'Japanese' && k === 'ja') {
      // eslint-disable-next-line no-param-reassign
      k = 'jp';
    }

    languagesObj[v] = {
      key: k,
      value: { value: v, displayName: v }
    };
  });

  const filteredLanguages = NDLanguageFilter.map(language => languagesObj[language]).filter(
    v => v !== undefined
  );

  return filteredLanguages;
};

const articleTypeOptions = {
  // (enum/string) - We store this as a string in the database but it is a list of one or more words separated by commas.
  listicle: {
    value: 'Listicle',
    displayName: 'Listicle'
  },
  infographic: {
    value: 'Infographic',
    displayName: 'Infographic'
  },
  how_to_guide: {
    value: 'How to Guide',
    displayName: 'How to Guide'
  },
  case_study: {
    value: 'Case Study',
    displayName: 'Case Study'
  },
  guest_post: {
    value: 'Guest Post',
    displayName: 'Guest Post'
  },
  review: {
    value: 'Review',
    displayName: 'Review'
  },
  video: {
    value: 'Video',
    displayName: 'Video'
  },
  podcast: {
    value: 'Podcast',
    displayName: 'Podcast'
  },
  webinar: {
    value: 'Webinar',
    displayName: 'Webinar'
  },
  interview: {
    value: 'Interview',
    displayName: 'Interview'
  },
  quote: {
    value: 'Quote',
    displayName: 'Quote'
  },
  meme: {
    value: 'Meme',
    displayName: 'Meme'
  },
  giveaway: {
    value: 'Giveaway',
    displayName: 'Giveaway'
  },
  quiz: {
    value: 'Quiz',
    displayName: 'Quiz'
  }
};

const formatArticleTypeOptions = articleOptions => {
  const formattedArticleOptions = [];

  Object.entries(articleOptions).forEach(([k, v]) => {
    formattedArticleOptions.push({ key: k, value: v });
  });

  return formattedArticleOptions;
};

export const EARNED_MEDIA_FIELDS = {
  query_title: {
    // is mandatory, cannot be removed, all other fields optional. If query_keywords are entered, it's technically still possible to make the query.
    displayName: 'Title',
    countMax: 1,
    charLimit: 255, // our db limit
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  },
  query_keywords: {
    displayName: 'Must Contain Keywords',
    countMax: 50,
    charLimit: 255, // our db limit
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  }, //  (String) –- Match one or more keywords in the article. Multiple values are separated by comma. Boolean OR is applied when multiple keywords are specified. Keywords are always tightly matched. Just as with title, case is always ignored. The count of keywords needs to be 50 or less. filter_keywords (string) is an alias for this.
  // When we display this to the user we show them one keyword per row.
  // When storing this field we collapse the strings down to quoted strings, comma-separated
  filter_keyword_blacklist: {
    displayName: 'Block Keywords',
    countMax: 50,
    charLimit: 255, // our db limit
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  },
  // (String) - Simple string to filter author by
  filter_author: {
    displayName: 'Only by Author',
    charLimit: 255, // our db limit
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  },
  // Restricts search to a single top level domain (i.e. .com) Users should not enter the leading period.
  filter_tld: {
    displayName: 'Only from TLD',
    countMax: 1,
    charLimit: 255, // our db limit
    inputProps: {
      inputType: 'text',
      validators: ['isString'],
      errorMessages: []
    }
  },
  filter_domain_blacklist: {
    displayName: 'Block Domains',
    countMax: 50,
    charLimit: 255, // our db limit
    inputProps: {
      inputType: 'text',
      validators: [],
      errorMessages: []
    }
  }, // (max 50)  - Comma separated string
  // (Int) - articles need at least these many words to be selected
  filter_min_word_count: {
    displayName: 'Min. Word Count',
    countMax: 1,
    inputProps: {
      inputType: 'number',
      validators: ['isNumber', 'isPositive'],
      errorMessages: ['Please enter a number.', 'Please enter a positive number.']
    }
  },
  filter_article_type: {
    displayName: 'Filter by Type',
    inputProps: {
      inputType: 'select',
      selectOptions: formatArticleTypeOptions(articleTypeOptions),
      validators: [],
      errorMessages: []
    }
  },
  // (Boolean) true/false - article must have an image, default false.
  filter_with_image: {
    displayName: 'Require Image',
    countMax: 1,
    inputProps: {
      inputType: 'radio',
      defaultValue: 'FALSE',
      validators: [],
      errorMessages: []
    }
  },
  filter_strict_mode: {
    displayName: 'Strict NSFW Exclude',
    countMax: 1,
    inputProps: {
      inputType: 'radio',
      default: 'FALSE',
      validators: [],
      errorMessages: []
    }
  },
  language: {
    displayName: 'Language',
    countMax: 1,
    inputProps: {
      inputType: 'select',
      selectOptions: formatLanguageOptions()
    }
  }
};

const DEFAULT_OPTIONS = {};
const CUSTOM_OPTIONS = {};

// turns data into an array, adds options, other fields.
export const getEarnedMediaFields = () => {
  return Object.keys(EARNED_MEDIA_FIELDS).map(key => ({
    ...EARNED_MEDIA_FIELDS[key],
    name: key,
    label: EARNED_MEDIA_FIELDS[key].displayName,
    options: CUSTOM_OPTIONS[key] || DEFAULT_OPTIONS
  }));
};

export const INCLUDE = 'include';
export const BLACKLIST = 'blacklist';
