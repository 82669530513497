import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, IconButton, Button, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import HasAnyPriv from 'components/HasAnyPriv';
import { goToRoute } from 'util/routesHelpers';

/**
 * @file Components used for pages with a title
 *
 * @module AlembicPageHeader
 *
 * @example <caption>Page header with a title</caption>
 * <AlembicPageHeader
 *   pageTitle="Contacts"
 * />
 *
 * @example <caption>Page header with a title and button</caption>
 * <AlembicPageHeader
 *   pageTitle="Contacts"
 *   buttonTitle="Add Contact"
 *   buttonOnClick={() => { setOpenModal(true) }}
 * />
 *
 * @example <caption>Page header with a title and button + number of contacts on left side</caption>
 * <AlembicPageHeader
 *   pageTitle="Contacts"
 *   buttonTitle="Add Contact"
 *   buttonOnClick={() => { setOpenModal(true) }}
 * >
 *  <div left>{numberOfContacts}</div>
 * </AlembicPageHeader>
 */

const AlembicPageHeader = props => {
  const {
    history,
    children,
    pageTitle,
    backRoute,
    backButton,
    buttonTitle,
    buttonOnClick,
    buttonPrivs,
    buttonDisabled
  } = props;

  return (
    <Grid container>
      <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
        {backButton && (
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => {
              if (backRoute) {
                goToRoute(backRoute, history);
              } else {
                history.goBack();
              }
            }}
            style={{ marginRight: '16px', border: '1px solid #979797' }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        )}
        <Typography color="primary" variant="h1" style={{ fontWeight: '500' }}>
          {pageTitle}
        </Typography>
        {/*
          When using AlembicPageHeader tag the child component with a left prop
          to place content on the left side
          <AlembicPageHeader>
            <div left>Left side</div>
          </AlembicPageHeader>
        */}
        {children &&
          React.Children.map(children, child => {
            const { left, ...rest } = child.props;
            if (left) {
              return <child.type {...rest} />;
            }
            return null;
          })}
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
        {/*
          When using AlembicPageHeader tag the child component with a right prop
          to place content on the right side
          <AlembicPageHeader>
            <div right>Right side</div>
          </AlembicPageHeader>
        */}
        {children &&
          React.Children.map(children, child => {
            const { right, ...rest } = child.props;
            if (right) {
              return <child.type {...rest} />;
            }
            return null;
          })}
        {buttonTitle && !buttonPrivs && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => buttonOnClick()}
            disabled={buttonDisabled}
          >
            {buttonTitle}
          </Button>
        )}
        {buttonTitle && buttonPrivs && (
          <HasAnyPriv privs={buttonPrivs}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => buttonOnClick()}
              disabled={buttonDisabled}
            >
              {buttonTitle}
            </Button>
          </HasAnyPriv>
        )}
      </Grid>
    </Grid>
  );
};

/**
 * @name AlembicPageHeader propTypes
 * @type {propTypes}
 * @param {Object} props - React PropTypes
 * @property {Object} history - Object from react router to handle navigation
 * @property {Object[]} [children=null] - Custom children can be added with left or right props
 * @property {String} pageTitle - Title for the page
 * @property {Boolean} [backRoute=null] - The route that the back button will navigate to or else will default to history back function
 * @property {Boolean} [backButton=null] - If true then there will be a back button present in the component
 * @property {String} [buttonTitle=Save] - Title for the button
 * @property {String} [buttonOnClick=null] - Function for the button
 * @property {Function} [buttonPrivs=null] - Privileges for the button
 * @return {Array} - React propTypes
 */
AlembicPageHeader.propTypes = {
  history: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  pageTitle: PropTypes.string.isRequired,
  backRoute: PropTypes.string,
  backButton: PropTypes.bool,
  buttonTitle: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonPrivs: PropTypes.arrayOf(PropTypes.string),
  buttonDisabled: PropTypes.bool
};

AlembicPageHeader.defaultProps = {
  children: null,
  backRoute: null,
  backButton: null,
  buttonTitle: null,
  buttonOnClick: null,
  buttonPrivs: null,
  buttonDisabled: null
};

export default withRouter(AlembicPageHeader);
