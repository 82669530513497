import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';
import { FolderOpen } from '@material-ui/icons';
import PropTypes from 'prop-types';

const style = {
  card: {
    paddingBottom: '100%',
    position: 'relative',
    background: '#f8f9fa',
    '&:hover': {
      outline: '2px solid #0A1734',
      outlineOffset: '3px',
      cursor: 'pointer'
    },
    '& > *': {
      bottom: 0,
      height: '30%',
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '30%'
    }
  },
  folderTitle: {
    color: '#383838',
    fontSize: '15px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    padding: '3px',
    marginTop: '3px',
    marginBottom: '20px',
    textAlign: 'center'
  }
};

const SharedFolder = props => {
  const { classes, folderName, callback, folderId } = props;
  const [disabled, toggleDisabled] = useState(false);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    toggleDisabled(true);
    callback(folderId, folderName);
  };

  return (
    <div>
      <Card elevation={0} square className={classes.card} onClick={handleClick} disabled={disabled}>
        <FolderOpen className={classes.folderIcon} />
      </Card>
      <Grid container justifyContent="center" alignItems="baseline" className={classes.folderTitle}>
        <div>{folderName}</div>
      </Grid>
    </div>
  );
};

SharedFolder.propTypes = {
  classes: PropTypes.shape(),
  folderName: PropTypes.string,
  callback: PropTypes.func,
  folderId: PropTypes.string
};

SharedFolder.defaultProps = {
  classes: null,
  folderName: null,
  callback: null,
  folderId: null
};

export default withStyles(style)(SharedFolder);
