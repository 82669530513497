import { gql } from '@apollo/client';

export const BROADCAST_SEARCH = gql`
  query broadcastSearch($id: ID!) {
    broadcastSearch(id: $id) {
      id
      name
      query_keywords
      mention_extract_keyword
    }
  }
`;

// use for pagination
export const BROADCAST_SEARCHES = gql`
  query listBroadcastSearches($enabled: Boolean, $count: Int, $after: Int) {
    listBroadcastSearches(enabled: $enabled, count: $count, after: $after) {
      rows {
        id
        name
        enabled
        is_default
        query_keywords
        total_results
        initial_search_completed
        mention_extract_keyword
      }
      count
    }
  }
`;

// all results by container id
export const GET_BROADCAST_SEARCHES = gql`
  query broadcastSearches($enabled: Boolean, $containerId: ID) {
    broadcastSearches(enabled: $enabled, containerId: $containerId) {
      id
      name
      is_default
    }
  }
`;

export const PREVIEW_BROADCAST_SEARCH = gql`
  query previewBroadcastSearchResults($query_keywords: String!) {
    previewBroadcastSearchResults(query_keywords: $query_keywords) {
      rows {
        stationName
        marketName
        country
        mediaType
        transcript
        completedDateUTC
      }
      totalCount
    }
  }
`;

export const CREATE_BROADCAST_SEARCH = gql`
  mutation($search: BroadcastPodcastSearchInput!, $isDefault: Boolean) {
    createBroadcastSearch(search: $search, isDefault: $isDefault) {
      id
    }
  }
`;

export const ARCHIVE_BROADCAST_SEARCH = gql`
  mutation disableBroadcastSearch($id: ID!) {
    disableBroadcastSearch(id: $id) {
      id
      name
      enabled
      is_default
      query_keywords
    }
  }
`;

export const UNARCHIVE_BROADCAST_SEARCH = gql`
  mutation enableBroadcastSearch($id: ID!) {
    enableBroadcastSearch(id: $id) {
      id
      name
      enabled
      is_default
      query_keywords
    }
  }
`;

export const DELETE_BROADCAST_SEARCH = gql`
  mutation deleteBroadcastSearch($id: ID!) {
    deleteBroadcastSearch(id: $id) {
      result
      message
    }
  }
`;

export const SET_DETECTING_BROADCAST_SEARCH = gql`
  mutation setDetectingBroadcastSearch($id: ID!, $setDetecting: Boolean!) {
    setDetectingBroadcastSearch(id: $id, setDetecting: $setDetecting) {
      id
      name
      enabled
      is_default
      query_keywords
    }
  }
`;

export const UPDATE_BROADCAST_SEARCH = gql`
  mutation updateBroadcastSearch($id: ID!, $patch: BroadcastPodcastSearchInput!) {
    updateBroadcastSearch(id: $id, patch: $patch) {
      id
    }
  }
`;
