/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { POST_RESURGE_IMPRESSION } from 'gql/postresurgeimpressions';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { getResurgenceType } from 'util/social';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { AlbTooltip, getToolTipTitleValue } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';
import { renderUTCLongDate } from 'util/date';
import PostDrawerMetricGrid from './PostDrawerMetricGrid';

const useStyles = makeStyles({
  dateText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px'
  }
});
/**
 * @method
 * @summary This component renders the resurgence section in single post drawer
 * @name PostDrawerResurgence
 * @param {Object} props - React props passed to this component
 * @param {Object} props.campaignEvent - An object containing the post displayed in the drawer
 * @param {Date} props.startDate - A start date representing the scope of dates shown within the post drawer
 * @param {Date} props.endDate - A end date representing the scope of dates shown within the post drawer
 * @param {String} props.surgeStart - A string date representing the start of the post surge
 * @param {String} props.surgeEnd - A string date representing the end of the post surge
 * @param {Boolean} props.isInitialSurge - Is this for the dashboard v3 initial surge drawer?
 * @return {Object} - React JSX
 */
const PostDrawerResurgence = props => {
  const { campaignEvent, startDate, endDate, surgeStart, surgeEnd, isInitialSurge } = props;
  const { linked_account, completed_at, post_id } = campaignEvent;

  const [dates, setDates] = useState([]);
  const [gridItems, setGridItems] = useState([]);

  useEffect(() => {
    if (completed_at && startDate && endDate) {
      const postAge = Math.abs(moment(completed_at).diff(moment(surgeStart), 'd'));

      setDates([
        { title: 'Surge Start', date: renderUTCLongDate(surgeStart) },
        { title: 'Surge End', date: renderUTCLongDate(surgeEnd) },
        { title: 'Post Age at Surge', date: `${postAge} Day${postAge === 1 ? '' : 's'}` },
        {
          title: 'Showing',
          date: `${renderUTCLongDate(startDate)} - ${renderUTCLongDate(endDate)}`
        }
      ]);
    }
  }, [completed_at, startDate, endDate]);

  const classes = useStyles();

  const { loading, error, data } = useQuery(POST_RESURGE_IMPRESSION, {
    variables: {
      linktoken_id: linked_account.id,
      post_id,
      post_date: moment.utc(completed_at).format('YYYY-MM-DD'),
      begin_date: moment.utc(surgeStart).format('YYYY-MM-DD'),
      end_date: moment.utc(surgeEnd).format('YYYY-MM-DD')
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data?.postResurgeImpression) {
      const {
        event_relative_rank,
        is_complete,
        peak_count_diff,
        follower_growth
      } = data.postResurgeImpression;

      const isComplete = is_complete ? 'Yes' : 'No';
      // determines if major or minor resurgence
      const surgeType = getResurgenceType(event_relative_rank);
      const bonusImpressions =
        peak_count_diff == null ? '-' : getValueAbbreviation(peak_count_diff);
      const postAge = Math.abs(moment(surgeStart).diff(moment(surgeEnd), 'd'));
      const followerGrowth =
        follower_growth == null
          ? '-'
          : `${follower_growth > 0 ? '+' : ''}${getValueAbbreviation(follower_growth)}`;

      if (!isInitialSurge) {
        setGridItems([
          {
            graphTitle: 'BONUS IMP.',
            metricValue: bonusImpressions,
            tooltipText: getToolTipTitleValue('reBonusImp')
          },
          {
            graphTitle: 'SURGE',
            metricValue: surgeType,
            tooltipText: getToolTipTitleValue('reSurge')
          },
          {
            graphTitle: 'COMPLETE?',
            metricValue: isComplete,
            tooltipText: getToolTipTitleValue('reComplete')
          },
          {
            graphTitle: 'FOLLOWER GROWTH IN PERIOD',
            metricValue: followerGrowth,
            tooltipText: getToolTipTitleValue('reFollowerGrowthInPeriod')
          },
          {
            graphTitle: 'RANK',
            metricValue: Math.round(event_relative_rank),
            tooltipText: ''
          },
          {
            graphTitle: 'LENGTH',
            metricValue: `${postAge} Day${postAge === 1 ? '' : 's'}`,
            tooltipText: getToolTipTitleValue('reLength')
          }
        ]);
      }

      if (isInitialSurge) {
        setGridItems([
          {
            graphTitle: 'RANK',
            metricValue: Math.round(event_relative_rank),
            tooltipText: ''
          }
        ]);
      }
    }
  }, [data]);

  if (loading) return <AlbLoading />;
  if (error) return <AlbError error={error} />;

  return (
    <Grid container>
      {!isInitialSurge ? (
        <>
          <Grid item xs={11}>
            {dates.map(({ title, date }) => (
              <Grid key={title} item>
                <Typography
                  variantMapping={{ body1: 'span' }}
                  className={classes.dateText}
                  style={{ color: '#0A1734' }}
                >
                  {title}:{' '}
                </Typography>
                <Typography
                  variantMapping={{ body1: 'span' }}
                  className={classes.dateText}
                  style={{ color: '#979797' }}
                >
                  {date}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={1}>
            <AlbTooltip title={tooltipText.reSurgeStart} placement="right" />
          </Grid>
        </>
      ) : null}
      <PostDrawerMetricGrid gridItems={gridItems} />
    </Grid>
  );
};

PostDrawerResurgence.propTypes = {
  campaignEvent: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  surgeStart: PropTypes.string.isRequired,
  surgeEnd: PropTypes.string.isRequired,
  isInitialSurge: PropTypes.bool
};

PostDrawerResurgence.defaultProps = {
  isInitialSurge: false
};

export default PostDrawerResurgence;
