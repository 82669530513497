/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { UPDATE_CONTAINER } from 'gql/container';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';
import ValuationModelForm from './ValuationModelForm';

const useStyles = makeStyles({
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px'
  }
});

const ValuationModel = props => {
  const { containerData } = props;
  const [impressionCPM, setImpressionCPM] = useState(null);
  const [engagementCPA, setEngagementCPA] = useState(null);
  const [updateContainer, { loading, error, data }] = useMutation(UPDATE_CONTAINER);
  const classes = useStyles();

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.updateContainer?.id) {
      showToast('Container updated', 'success');
    }
  }, [data]);

  return (
    <>
      <ValidatorForm
        onSubmit={() => {
          const cpmData = {
            valuationModelCPM: {
              valuation_instagram: null,
              valuation_facebook: null,
              valuation_linkedin: null,
              valuation_twitter: null,
              valuation_youtube: null,
              valuation_tiktok: null
            }
          };
          const cpaData = {
            valuationModelCPA: {
              valuation_instagram: null,
              valuation_facebook: null,
              valuation_linkedin: null,
              valuation_twitter: null,
              valuation_youtube: null,
              valuation_tiktok: null
            }
          };

          if (impressionCPM) {
            cpmData.valuationModelCPM.valuation_instagram = parseFloat(
              impressionCPM?.valuationInstagram
            );
            cpmData.valuationModelCPM.valuation_facebook = parseFloat(
              impressionCPM?.valuationFacebook
            );
            cpmData.valuationModelCPM.valuation_linkedin = parseFloat(
              impressionCPM?.valuationLinkedIn
            );
            cpmData.valuationModelCPM.valuation_twitter = parseFloat(
              impressionCPM?.valuationTwitter
            );
            cpmData.valuationModelCPM.valuation_youtube = parseFloat(
              impressionCPM?.valuationYouTube
            );
            cpmData.valuationModelCPM.valuation_tiktok = parseFloat(impressionCPM?.valuationTikTok);
          }

          if (engagementCPA) {
            cpaData.valuationModelCPA.valuation_instagram = parseFloat(
              engagementCPA?.valuationInstagram
            );
            cpaData.valuationModelCPA.valuation_facebook = parseFloat(
              engagementCPA?.valuationFacebook
            );
            cpaData.valuationModelCPA.valuation_linkedin = parseFloat(
              engagementCPA?.valuationLinkedIn
            );
            cpaData.valuationModelCPA.valuation_twitter = parseFloat(
              engagementCPA?.valuationTwitter
            );
            cpaData.valuationModelCPA.valuation_youtube = parseFloat(
              engagementCPA?.valuationYouTube
            );
            cpaData.valuationModelCPA.valuation_tiktok = parseFloat(engagementCPA?.valuationTikTok);
          }

          updateContainer({
            variables: {
              id: containerData.id,
              ...cpmData,
              ...cpaData
            }
          });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              Valuation Model For Impression CPM (Per 1,000)
            </Typography>
          </Grid>
          <ValuationModelForm
            valuationData={{
              instagram: containerData?.valuation_instagram,
              facebook: containerData?.valuation_facebook,
              linkedin: containerData?.valuation_linkedin,
              twitter: containerData?.valuation_twitter,
              youtube: containerData?.valuation_youtube,
              tiktok: containerData?.valuation_tiktok
            }}
            onChange={event => {
              if (event?.data) {
                setImpressionCPM(event.data);
              } else {
                setImpressionCPM(null);
              }
            }}
          />
          <Grid item xs={12}>
            <Typography className={classes.title} style={{ marginTop: '50px' }}>
              Valuation Model For Engagement CPA
            </Typography>
          </Grid>
          <ValuationModelForm
            valuationData={{
              instagram: containerData?.valuation_instagram_cpa,
              facebook: containerData?.valuation_facebook_cpa,
              linkedin: containerData?.valuation_linkedin_cpa,
              twitter: containerData?.valuation_twitter_cpa,
              youtube: containerData?.valuation_youtube_cpa,
              tiktok: containerData?.valuation_tiktok_cpa
            }}
            onChange={event => {
              if (event?.data) {
                setEngagementCPA(event.data);
              } else {
                setEngagementCPA(null);
              }
            }}
          />
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

ValuationModel.propTypes = {
  containerData: PropTypes.shape()
};

ValuationModel.defaultProps = {
  containerData: null
};

export default ValuationModel;
