/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import { flowRight as compose } from 'lodash';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import MediaNav from 'components/MediaNav';
import MediaBreadcrumbs from 'components/MediaBreadcrumbs';
import { apiGetStockPhotos } from 'gql/asset';
import { ImageGrid, VideoGrid, FolderGrid, SharedGrid } from 'components/MediaGrid/MediaGrid';
import ToastContext from 'contexts/ToastContext';
import MediaModal from 'components/MediaModal';
import MediaFilters from 'components/MediaFilters';
import AlembicPageTitle from 'components/AlembicPageTitle';
import {
  MediaPath,
  MediaImagesPath,
  MediaSharedPath,
  MediaStockPhotosPath,
  MediaVideosPath,
  MediaSharedPublicPath,
  MediaSharedContainerPath
} from 'util/paths';
import getCurrentContainerIdFromRedux from 'util/container';
import HasAnyPriv from 'components/HasAnyPriv';
import { goToRoute, parseQueryString } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import { mediaRoutes, mediaTabs } from './mediaRoutes';

const StockPhotoGrid = React.lazy(() => import('components/StockPhotoGrid'));

const styles = {
  headingContainer: {
    paddingBottom: '30px'
  },
  container: {
    minHeight: '70vh'
  },
  navBar: {},
  paper: {}
};

class MediaLayout extends Component {
  constructor(props) {
    super(props);
    setPageTitle('Media');
  }

  state = {
    searchFilter: null,
    filteredStockPhotos: null,
    // only used for modal
    currentTabIndex: mediaTabs.HOME,
    uploadFolder: '/',
    sortOrder: ['created_at', 'DESC'],
    accounts: []
  };

  setSearchFilterInTabs = searchFilter => {
    const path = window.location.pathname;

    if (searchFilter) {
      if (path.includes(MediaStockPhotosPath)) {
        this.getFilteredStockPhotos(searchFilter);
        this.setState({ searchFilter });
      } else {
        this.setState({ searchFilter });
      }
    } else {
      this.setState({ searchFilter: null });
    }
  };

  updateFilterValues = (sortOrder, accounts) => {
    const accountIds = accounts?.map(account => account.id);

    this.setState({ sortOrder, accounts: accountIds });
  };

  // used for modal navigation.
  handleModalNavigation = tabValue => this.setState({ currentTabIndex: tabValue });

  // Derive folder name from location.
  folderFromLocation = () => {
    const { match } = this.props;
    const path = window.location.pathname || null;

    if (!path.includes(MediaImagesPath) && !path.includes(MediaSharedPath)) {
      const start = `${match.url}`.length;
      const { length } = `${path}`;

      const foldername = path.substr(start, length);

      return foldername || '/';
    }

    return '';
  };

  // For Shared Tab's children containers.
  containerFromLocation = () => {
    const { location } = window;
    const containerId = location.pathname.split('/')[1];

    return containerId || getCurrentContainerIdFromRedux();
  };

  getFolderName = folderName => {
    this.setState({ uploadFolder: folderName });
  };

  handleSearchInput = keyword => {
    const { history, modal } = this.props;
    const path = window.location.pathname;
    let prefix = path;
    let searchUrlValue = `?search=${encodeURI(keyword)}`;

    // handle shared container path separately since it has container ids
    if (path.includes(MediaSharedContainerPath)) {
      const containerIds = parseQueryString('id', window.location);
      const pathArray = [];

      containerIds.forEach((id, i) => {
        if (i !== 0) {
          pathArray.push(`&id=${id}`);
        } else {
          pathArray.push(`id=${id}`);
        }
      });

      const containerString = pathArray.join('');

      // only add the container ids to the prefix.
      prefix = `${path}?${containerString}`;
      // use & instead of ? when appending to query param
      searchUrlValue = `&search=${encodeURI(keyword)}`;
    }

    if (keyword && keyword.length > 2) {
      this.setState({ searchFilter: keyword });

      // there is no routing in modal view.
      if (!modal) {
        history.push(`${prefix}${searchUrlValue}`);
      }
    }

    if (keyword && keyword.length <= 2) {
      const toast = this.context;
      toast.showToast(`Please enter a search term longer than 2 characters.`, 'warn');
    }

    // handle clearing search input.
    if (!keyword) {
      this.setState({ searchFilter: null });

      // no routing in modal.
      if (!modal) {
        history.push(prefix);
      }
    }
  };

  getFilteredStockPhotos = keyword => {
    apiGetStockPhotos({ text: keyword }).then(result => {
      if (result.success) {
        this.setState({ filteredStockPhotos: result.payload.data.getStockPhotos.results });
      }
    });
  };

  handleStockPhotoSearch = keyword => {
    const { history, modal } = this.props;

    if (keyword) {
      // trigger filtered stock photo query.
      this.getFilteredStockPhotos(keyword);

      // update url path with search term.
      if (!modal) {
        goToRoute(`${MediaStockPhotosPath}?search=${encodeURI(keyword)}`, history);
      }
    } else {
      this.setState({ filteredStockPhotos: null, searchFilter: null });

      // no routing in modal.
      if (!modal) {
        goToRoute(MediaStockPhotosPath, history);
      }
    }
  };

  render() {
    const { currentUser, modal, match, contributedAsset } = this.props;
    const { classes, ...allprops } = this.props;

    const {
      currentTabIndex,
      filteredStockPhotos,
      searchFilter,
      uploadFolder,
      sortOrder,
      accounts
    } = this.state;

    const folderName = this.folderFromLocation();

    // used as MediaGrid's Shared Tab query arg to fetch assets in children containers.
    const containerId = this.containerFromLocation();

    if (!modal) {
      return (
        <HasAnyPriv privs={['SVC:ASSET:CREATE', 'SVC:ASSET']} showAlert>
          <>
            <Grid className={classes.headingContainer} container>
              <AlembicPageTitle nomargin title="Media Library" />
            </Grid>
            <Fragment>
              <Paper style={{ minHeight: '50vh' }}>
                <Grid
                  container
                  className={classes.navGrid}
                  alignContent="flex-end"
                  justifyContent="space-between"
                >
                  <MediaNav
                    currentUser={currentUser}
                    handleSearchInput={this.handleSearchInput}
                    handleStockPhotoSearch={this.handleStockPhotoSearch}
                    mediaRoutes={mediaRoutes}
                    tabValue={currentTabIndex}
                    folderName={folderName}
                    searchFilter={searchFilter}
                    setSearchFilterInTabs={this.setSearchFilterInTabs}
                    callback={() => {
                      this.forceUpdate();
                    }}
                  />
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <MediaBreadcrumbs mediaRoutes={mediaRoutes} location={window.location} />
                  <MediaFilters updateFilterValues={this.updateFilterValues} />
                </Grid>
                <Switch>
                  <Route
                    exact
                    path={match.path}
                    render={() => (
                      <FolderGrid
                        {...allprops}
                        searchFilter={searchFilter}
                        sortOrder={sortOrder}
                        accounts={accounts}
                        folderName={folderName}
                        setSearchFilterInTabs={this.setSearchFilterInTabs}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/:containerId${MediaImagesPath}`}
                    render={() => (
                      <ImageGrid
                        {...allprops}
                        searchFilter={searchFilter}
                        sortOrder={sortOrder}
                        accounts={accounts}
                        folderName={folderName}
                        setSearchFilterInTabs={this.setSearchFilterInTabs}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/:containerId${MediaVideosPath}`}
                    render={() => (
                      <VideoGrid
                        {...allprops}
                        searchFilter={searchFilter}
                        sortOrder={sortOrder}
                        accounts={accounts}
                        folderName={folderName}
                        setSearchFilterInTabs={this.setSearchFilterInTabs}
                      />
                    )}
                  />
                  <Route
                    path={`/:containerId${MediaStockPhotosPath}`}
                    render={() => (
                      <StockPhotoGrid
                        filteredStockPhotos={filteredStockPhotos}
                        folderName={folderName}
                        setSearchFilterInTabs={this.setSearchFilterInTabs}
                      />
                    )}
                  />
                  <Route
                    path={`/:containerId${MediaSharedPath}`}
                    render={() => (
                      <SharedGrid
                        {...allprops}
                        searchFilter={searchFilter}
                        sortOrder={sortOrder}
                        accounts={accounts}
                        currentUser={currentUser}
                        containerId={containerId}
                        setSearchFilterInTabs={this.setSearchFilterInTabs}
                      />
                    )}
                  />
                  <Route
                    path={`/:containerId${MediaSharedPublicPath}`}
                    render={() => (
                      <SharedGrid
                        {...allprops}
                        containerId={containerId}
                        sortOrder={sortOrder}
                        accounts={accounts}
                        searchFilter={searchFilter}
                        setSearchFilterInTabs={this.setSearchFilterInTabs}
                      />
                    )}
                  />
                  <Route
                    path={`/:containerId${MediaPath}/:folderName`}
                    render={() => (
                      <FolderGrid
                        {...allprops}
                        searchFilter={searchFilter}
                        sortOrder={sortOrder}
                        accounts={accounts}
                        folderName={folderName}
                        setSearchFilterInTabs={this.setSearchFilterInTabs}
                      />
                    )}
                  />
                </Switch>
              </Paper>
            </Fragment>
          </>
        </HasAnyPriv>
      );
    }

    // Media Modal opens from Social post.
    if (modal) {
      return (
        <MediaModal
          {...allprops}
          uploadFolder={uploadFolder}
          handleSearchInput={this.handleSearchInput}
          handleStockPhotoSearch={this.handleStockPhotoSearch}
          handleModalNavigation={this.handleModalNavigation}
          getFolderName={this.getFolderName}
          searchFilter={searchFilter}
          filteredStockPhotos={filteredStockPhotos}
          currentTabIndex={currentTabIndex}
          contributedAsset={contributedAsset}
          containerId={containerId}
        />
      );
    }

    return null;
  }
}

MediaLayout.propTypes = {
  data: PropTypes.shape(),
  classes: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  currentContainer: PropTypes.shape().isRequired,
  modal: PropTypes.bool,
  onSelect: PropTypes.func,
  history: PropTypes.shape(),
  match: PropTypes.shape(),
  multiselect: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  contributedAsset: PropTypes.shape()
};

MediaLayout.defaultProps = {
  data: {},
  classes: {},
  modal: false,
  onSelect: () => {},
  history: {},
  multiselect: false,
  match: null,
  onClose: null,
  open: true,
  contributedAsset: null
};

MediaLayout.contextType = ToastContext;

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    currentContainer: state.auth.currentContainer
  };
};

export default compose(connect(mapStateToProps), withStyles(styles), withRouter)(MediaLayout);
