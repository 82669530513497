/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid, Paper, Button, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import debounce from 'lodash/debounce';
import AlbTable from 'components/AlbTable';
import { GET_PAGINATED_ANNOTATIONS } from 'gql/annotation';
import moment from 'moment';
import AlbError from 'components/AlbError';
import AlbLoading from 'components/AlbLoading';
import AlembicPageTitle from 'components/AlembicPageTitle';
import CustomFooter from 'components/TablePagination';
import CreateAnnotationModal from 'components/AlembicModalForm/CreateAnnotationModal';
import TabWithTooltip from 'components/TabWithTooltip';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import HasAnyPriv from 'components/HasAnyPriv/HasAnyPriv';
import setPageTitle from 'util/titles';
import openDashboardDrawer from 'util/drawerUtil';
import AnnotationsTableActions from './AnnotationsTableActions';

const styles = makeStyles({
  correlations: {
    '& > :not(:last-child)': {
      marginBottom: '10px'
    }
  },
  correlation: {
    whiteSpace: 'nowrap',
    color: '#0A1734',
    fontWeight: 600,
    fontSize: '13px',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const Annotations = () => {
  const classes = styles();

  const [selectedTab, setSelectedTab] = useState(0);

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['dates', 'DESC']);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [isCreateAnnotationModalOpen, toggleCreateAnnotationModal] = useState(false);
  const [annotationsLookup, setAnnotationsLookup] = useState({});

  const drawerContext = useContext(DashboardIntelligenceDrawerContext);

  useEffect(() => {
    setPageTitle('Annotations');
  }, []);

  const tabs = ['User', 'System'];

  const { data, loading, error } = useQuery(GET_PAGINATED_ANNOTATIONS, {
    variables: {
      count: rowsPerPage,
      after: page * rowsPerPage,
      orderBy: sortOrder,
      typeFilter: tabs[selectedTab],
      searchKeyword
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (data?.paginatedAnnotations) {
      const tempTableData = data.paginatedAnnotations.rows.map(row => ({
        dates: [row.start_date, row.end_date],
        ...row
      }));
      setTableData(tempTableData);
      setCount(data.paginatedAnnotations.count);
      setAnnotationsLookup(
        data.paginatedAnnotations.rows.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {})
      );
    }
  }, [data]);

  const customDateRender = ([start, end]) => {
    const dateArray = [
      ...new Set([moment.utc(start).format('MM/DD/YYYY'), moment.utc(end).format('MM/DD/YYYY')])
    ];

    return <div style={{ whiteSpace: 'nowrap' }}>{dateArray.join(' - ')}</div>;
  };

  const customCorrelationsRender = list => (
    <div className={classes.correlations}>
      {!!list.length &&
        list.map(correlation => {
          const { id, detectedEvents, coinc_start_date, coinc_end_date } = correlation;

          const eventCount = detectedEvents.length;
          const start = moment.utc(coinc_start_date).format('MM/DD/YYYY');
          const end = moment.utc(coinc_end_date).format('MM/DD/YYYY');
          const dateArray = [...new Set([start, end])];

          return (
            <Typography
              key={id}
              className={classes.correlation}
              onClick={() => {
                openDashboardDrawer(
                  {
                    id,
                    detectedEvents,
                    type: 'CORRELATION',
                    coinc_start_date,
                    coinc_end_date,
                    date: moment.utc()
                  },
                  drawerContext
                );
              }}
            >
              {eventCount} Events correlated between {dateArray.join(' - ')}
            </Typography>
          );
        })}
      {!list.length && 'No Associated Correlations'}
    </div>
  );

  const customActionsRender = (_, { rowData }) => {
    const [id] = rowData;
    return <AnnotationsTableActions selectedAnnotation={annotationsLookup[id]} />;
  };

  const columns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'dates',
      label: 'DATE(S)',
      options: { customBodyRender: customDateRender }
    },
    {
      name: 'message',
      label: 'ANNOTATION',
      options: { sort: false }
    },
    {
      name: 'coincidentEvents',
      label: 'ASSOCIATED CORRELATIONS',
      options: { customBodyRender: customCorrelationsRender, sort: false }
    },
    {
      name: 'actions',
      label: 'ACTIONS',
      options: {
        display: tabs[selectedTab] !== 'System',
        customBodyRender: customActionsRender,
        sort: false
      }
    }
  ];

  const rowsPerPageOptions = [10, 20, 50];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const handleSearch = debounce(keyword => {
    setSearchKeyword(keyword);
    setPage(0);
  }, 500);

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    rowHover: false,
    fixedHeader: false,
    sortFilterList: false,
    filter: false,
    search: true,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    page,
    rowsPerPage,
    count,
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    onSearchChange: handleSearch,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1].toLowerCase() },
    onColumnSortChange: (changedColumn, direction) =>
      setSortOrder([changedColumn, direction.toUpperCase()]),
    draggableColumns: { enabled: true, transitionTime: 300 },
    customFooter,
    textLabels: {
      body: {
        noMatch: 'No annotations found'
      }
    }
  };

  const tableThemeOverrides = {
    MUIDataTableHeadCell: {
      toolButton: {
        marginLeft: '0px',
        marginRight: '0px'
      }
    }
  };

  return (
    <>
      <HasAnyPriv privs={['ORG:UPDATE']} showAlert>
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <AlembicPageTitle title="Annotations" />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => toggleCreateAnnotationModal(true)}
              >
                Create New Annotation
              </Button>
            </Grid>
          </Grid>
          {error && <AlbError error={error} />}
          <Paper style={{ position: 'relative' }}>
            {loading && (
              <div className={classes.loadingOverlay}>
                <AlbLoading />
              </div>
            )}
            <Grid container justifyContent="space-between" alignItems="center" direction="row">
              <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                {tabs.map(label => (
                  <Tab
                    key={label}
                    label={
                      <TabWithTooltip
                        tabTitle={label}
                        tooltipTitle={getToolTipTitleValue(`anno${label}`)}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Grid>
            <AlbTable
              tableData={tableData}
              tableColumns={columns}
              tableOptions={options}
              tableThemeOverrides={tableThemeOverrides}
            />
          </Paper>
          {isCreateAnnotationModalOpen && (
            <CreateAnnotationModal
              isModalOpen={isCreateAnnotationModalOpen}
              onChange={e => toggleCreateAnnotationModal(e.isModalOpen)}
              hideSeeAllButton
            />
          )}
        </>
      </HasAnyPriv>
    </>
  );
};

Annotations.propTypes = {};

export default Annotations;
