import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SET_DETECTING_ARTICLE_SEARCH_FILTER } from 'gql/articleSearch';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const DetectingArticleSearchFilterModal = props => {
  const { isModalOpen, onChange, articleSearchFilterId, articleSearchName, setDetecting } = props;

  const [setDetectingArticleSearchFilter, { error, data }] = useMutation(
    SET_DETECTING_ARTICLE_SEARCH_FILTER
  );

  useEffect(() => {
    if (data?.setDetectingArticleSearchFilter) {
      if (data.setDetectingArticleSearchFilter.is_detecting) {
        showToast(`Report filter ${articleSearchName} is now detecting events.`, 'success');
      }

      if (!data.setDetectingArticleSearchFilter.is_detecting) {
        showToast(`Report filter ${articleSearchName} stopped detecting events.`, 'success');
      }

      onChange({
        isModalOpen: false,
        newArticleSearchFilterData: data.setDetectingArticleSearchFilter
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        title="Update Event Detection"
        body={
          <>
            Do you want to make this report filter {setDetecting ? 'detect' : 'stop detecting'}{' '}
            events:
            <span style={{ color: '#0A1734' }}>{articleSearchName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle={`${setDetecting ? 'Start' : 'Stop'} Detecting`}
        isDelete={!setDetecting}
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() =>
          setDetectingArticleSearchFilter({
            variables: { id: articleSearchFilterId, setDetecting }
          })
        }
      />
    </>
  );
};

DetectingArticleSearchFilterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  articleSearchFilterId: PropTypes.string,
  articleSearchName: PropTypes.string,
  setDetecting: PropTypes.bool.isRequired
};

DetectingArticleSearchFilterModal.defaultProps = {
  articleSearchFilterId: null,
  articleSearchName: null
};

export default DetectingArticleSearchFilterModal;
