import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SocialAssetCarousel from 'components/SocialAssetCarousel';
import { FS_THUMB_URL } from 'gql/asset';

const useStyles = makeStyles({
  container: {
    width: '124px',
    marginRight: '20px'
  },
  assetContainer: {
    position: 'relative'
  },
  asset: {
    borderRadius: '3px'
  },
  assetCount: {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '2px',
    right: '2px',
    background: '#0A1734',
    color: '#ffffff',
    borderRadius: '2px'
  },
  countText: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '15px'
  }
});

/**
 * @method
 * @summary This component renders an image with a carousel below
 * @name SocialAssets
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const SocialAssets = props => {
  const { width, height, assets, videoThumbnail } = props;
  const [mainAssetIndex, setMainAssetIndex] = useState(0);

  const classes = useStyles();

  return (
    <>
      {(assets?.length >= 1 || videoThumbnail) && (
        <div className={classes.container}>
          <div className={classes.assetContainer} style={{ width, height }}>
            {videoThumbnail && (
              <img
                className={classes.asset}
                // eslint-disable-next-line camelcase
                src={videoThumbnail}
                alt="Main asset"
                width={width}
                height={height}
              />
            )}
            {assets?.length >= 1 && (
              <img
                className={classes.asset}
                // eslint-disable-next-line camelcase
                src={`${FS_THUMB_URL}${assets[mainAssetIndex]?.signed_thumbnail_url}`}
                alt="Main asset"
                width={width}
                height={height}
              />
            )}
            {assets?.length >= 2 && (
              <div className={classes.assetCount}>
                <Typography className={classes.countText}>+{assets.length - 1}</Typography>
              </div>
            )}
          </div>
          {assets?.length > 1 && (
            <SocialAssetCarousel
              width={20}
              height={20}
              assets={assets}
              mainAssetId={assets[mainAssetIndex].id}
              mainAssetIndex={mainAssetIndex}
              setMainAssetIndex={setMainAssetIndex}
            />
          )}
        </div>
      )}
    </>
  );
};

SocialAssets.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape()),
  videoThumbnail: PropTypes.string
};

SocialAssets.defaultProps = {
  assets: [],
  videoThumbnail: null
};

export default SocialAssets;
