import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from '@apollo/client/react/components';
import Timeline, { TimelineHeaders, SidebarHeader, CustomHeader } from 'react-calendar-timeline';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Grid, Button, Typography } from '@material-ui/core';
import { CAMPAIGN_EVENTS_CALENDAR } from 'gql/campaignEvent';
import HasAnyPriv from 'components/HasAnyPriv';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import Box from 'components/Box';
import AlembicPageTitle from 'components/AlembicPageTitle';
import CalendarWidget from 'components/CalendarWidget';
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
  YouTubeLogo,
  TikTokLogo
} from 'util/assets';

const colors = ['#4373b9', '#c83f5e', '#1da1f2', '#0082be', '#FF0000', '#000000'];

let minTime = moment()
  .add(-6, 'months')
  .hour(0)
  .valueOf();
const maxTime = moment()
  .add(6, 'months')
  .hour(0)
  .valueOf();

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end'
};

const ItemComponent = ({ item, getItemProps, timelineContext }) => {
  const { style } = getItemProps();
  const difference =
    moment(timelineContext.visibleTimeEnd).diff(moment(timelineContext.visibleTimeStart), 'days') +
    1;
  const circleSize = timelineContext.timelineWidth / difference;
  let size = 29;

  if (circleSize < size) {
    size = circleSize;
  }

  return (
    <div
      // Container For The Bar
      style={{
        width: style.width,
        height: '2px',
        position: 'absolute',
        left: style.left,
        top: (item.group - 1) * 277 + item.row * 46 - 23 + (item.group - 1) * 2,
        background: item.color
      }}
    >
      {item.events.map(event => {
        return (
          <div
            // Container For The Circle
            key={`${item.id}-${event.post_date}`}
            style={{
              position: 'absolute'
            }}
          >
            <CalendarWidget event={event} style={style} item={item} size={size} />
          </div>
        );
      })}
    </div>
  );
};

ItemComponent.propTypes = {
  item: PropTypes.shape().isRequired,
  timelineContext: PropTypes.shape().isRequired,
  getItemProps: PropTypes.func.isRequired
};

const GroupComponent = ({ group }) => (
  <Grid container wrap="nowrap">
    <Grid item container direction="column" justifyContent="center" alignItems="center">
      <Typography
        style={{ color: '#757575', fontSize: '13px', fontWeight: '600', marginBottom: '15px' }}
      >
        {group.title}
      </Typography>
      <Typography style={{ color: '#757575', fontSize: '10px', fontWeight: '600' }}>
        {`${moment(group.start).format('MMM D')} - ${
          group.end === null ? 'Indefinite' : moment(group.end).format('MMM D')
        }`}
      </Typography>
    </Grid>
    <Grid
      item
      container
      direction="column"
      style={{
        width: '20px'
      }}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: '46px'
        }}
      >
        <img src={FacebookLogo} width={20} height={20} alt="Facebook" />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: '46px'
        }}
      >
        <img src={InstagramLogo} width={20} height={20} alt="Instagram" />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: '46px'
        }}
      >
        <img src={TwitterLogo} width={20} height={20} alt="Twitter" />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: '46px'
        }}
      >
        <img src={LinkedinLogo} width={20} height={20} alt="LinkedIn" />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: '46px'
        }}
      >
        <img src={YouTubeLogo} width={20} height={20} alt="YouTube" />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: '46px'
        }}
      >
        <img src={TikTokLogo} width={20} height={20} alt="TikTok" />
      </Grid>
    </Grid>
  </Grid>
);

GroupComponent.propTypes = {
  group: PropTypes.shape().isRequired
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTimeStart: moment().startOf('month'),
      defaultTimeEnd: moment().endOf('month'),
      drawTimeline: true,
      zoomLevel: 1000 * 86400 * 30
    };
  }

  // this limits the timeline to -6 months ... +6 months
  handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  setCalendarTimeFrame = timeFrame => {
    let zoomLevel = null;

    if (timeFrame === 'week') {
      zoomLevel = 1000 * 86400 * 7;
    }

    if (timeFrame === 'month') {
      zoomLevel = 1000 * 86400 * 30;
    }

    this.setState(
      {
        defaultTimeStart: moment().startOf(timeFrame),
        defaultTimeEnd: moment().endOf(timeFrame),
        drawTimeline: false,
        zoomLevel
      },
      () => this.setState({ drawTimeline: true })
    );
  };

  viewByWeek = () => {
    this.setCalendarTimeFrame('week');
  };

  viewByMonth = () => {
    this.setCalendarTimeFrame('month');
  };

  render() {
    const { currentUser } = this.props;
    const { defaultTimeStart, defaultTimeEnd, drawTimeline, zoomLevel } = this.state;

    return (
      <Query query={CAMPAIGN_EVENTS_CALENDAR}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <AlbLoading />;
          if (error) return <AlbError error={error} refetch={refetch} />;
          if (
            data?.campaign_events_calendar?.groups?.length === 0 &&
            data?.campaign_events_calendar?.items?.length === 0
          ) {
            return (
              <>
                <HasAnyPriv privs={['SVC:CAL:READ']} showAlert>
                  <Grid container justifyContent="center">
                    <Box p={30} mt={50}>
                      <AlembicPageTitle
                        nomargin
                        title="There are no associated events in the calendar "
                      />
                    </Box>
                  </Grid>
                </HasAnyPriv>
              </>
            );
          }

          // Re-fetch data every mount
          refetch();

          const groups = [];

          data.campaign_events_calendar.groups.forEach((group, index) => {
            groups.push({
              id: index + 1,
              title: group.name,
              start: group.start_date,
              end: group.end_date,
              height: 276
            });
          });

          const items = [];

          data.campaign_events_calendar.items.forEach(calendar => {
            let row = 0;
            let color = '';
            const [fbColor, inColor, twColor, lnColor, ytColor, ttColor] = colors;

            if (calendar.type === 'FB_PAGE') {
              row = 1;
              color = fbColor;
            }
            if (calendar.type === 'INSTAGRAM') {
              row = 2;
              color = inColor;
            }
            if (calendar.type === 'TWITTER') {
              row = 3;
              color = twColor;
            }
            if (calendar.type.includes('LINKEDIN')) {
              row = 4;
              color = lnColor;
            }
            if (calendar.type.includes('YOUTUBE')) {
              row = 5;
              color = ytColor;
            }
            if (calendar.type.includes('TIKTOK')) {
              row = 6;
              color = ttColor;
            }

            const start = moment(momentTimezone.tz(calendar.startDate, currentUser.time_zone));
            const end = moment(momentTimezone.tz(calendar.endDate, currentUser.time_zone));

            if (start.hour(0).valueOf() < minTime) {
              minTime = start.hour(0).valueOf();
            }

            calendar.events.forEach(event => {
              const eventDay = moment(momentTimezone.tz(event.post_date, currentUser.time_zone));

              items.push({
                id: `${calendar.campaign} ${calendar.type} ${event.post_date}`,
                start: eventDay.hour(12).valueOf(),
                end: eventDay.hour(12).valueOf(),
                length: 0,
                group:
                  data.campaign_events_calendar.groups.findIndex(
                    group => group.id === calendar.campaign
                  ) + 1,
                row,
                color,
                canMove: false,
                canResize: false,
                canChangeGroup: false,
                events: [
                  {
                    campaign: calendar.campaign,
                    type: calendar.type,
                    diff: eventDay.diff(start, 'days'),
                    ...event
                  }
                ],
                event: true
              });
            });

            items.push({
              id: `${calendar.campaign} ${calendar.type}`,
              start: start.hour(12).valueOf(),
              end: end.hour(12).valueOf(),
              length: end.diff(start, 'days'),
              group:
                data.campaign_events_calendar.groups.findIndex(
                  group => group.id === calendar.campaign
                ) + 1,
              row,
              color,
              canMove: false,
              canResize: false,
              canChangeGroup: false,
              events: []
            });
          });
          return (
            <>
              <HasAnyPriv privs={['SVC:CAL:READ']} showAlert>
                <Grid
                  item
                  style={{
                    margin: '0px 15px',
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <>
                    <Box py={25} px={40}>
                      <Grid container justifyContent="flex-end" spacing={1}>
                        <Grid item>
                          <Button variant="contained" color="primary" onClick={this.viewByWeek}>
                            View by Week
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" color="primary" onClick={this.viewByMonth}>
                            View by Month
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid>
                      {drawTimeline && (
                        <Timeline
                          keys={keys} // An array specifying keys in the items and groups
                          items={items} // Expects either a vanilla JS array or an immutableJS array consisting of objects
                          itemRenderer={ItemComponent}
                          groups={groups} // Component that will be used to render the content of groups in the sidebar
                          groupRenderer={GroupComponent}
                          canMove={false}
                          sidebarWidth={230} // Determines The Width of Left
                          minZoom={zoomLevel}
                          maxZoom={zoomLevel}
                          defaultTimeStart={defaultTimeStart} // Specify where the calendar begins
                          defaultTimeEnd={defaultTimeEnd} // Specify where the calendar ends
                          onTimeChange={this.handleTimeChange} // Time Change - Called when the user tries to scroll
                        >
                          <TimelineHeaders
                            style={{ borderBottom: 'solid 2px rgba(151,151,151, 0.2)' }}
                          >
                            <SidebarHeader>
                              {({ getRootProps }) => {
                                return (
                                  <div {...getRootProps()}>
                                    <div style={{ height: 35 }} />
                                    <div
                                      style={{ height: 35, background: 'rgba(76, 96, 135, 0.29)' }}
                                    />
                                    <div style={{ height: 35 }} />
                                  </div>
                                );
                              }}
                            </SidebarHeader>
                            <CustomHeader height={35} unit="month">
                              {({
                                headerContext: { intervals },
                                getRootProps,
                                getIntervalProps
                              }) => {
                                return (
                                  <div {...getRootProps()}>
                                    {intervals.map(interval => {
                                      const intervalStyle = {
                                        display: 'flex',
                                        justifyContent: 'center'
                                      };
                                      return (
                                        <div
                                          {...getIntervalProps({
                                            interval,
                                            style: intervalStyle
                                          })}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: '25px',
                                              fontWeight: '600',
                                              marginRight: '4px'
                                            }}
                                          >
                                            {interval.startTime.format('MMMM')}
                                          </Typography>
                                          <Typography
                                            style={{ fontSize: '25px', fontWeight: '300' }}
                                          >
                                            {interval.startTime.format('YYYY')}
                                          </Typography>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }}
                            </CustomHeader>
                            <CustomHeader height={35} unit="day">
                              {({
                                headerContext: { intervals },
                                getRootProps,
                                getIntervalProps
                              }) => {
                                const { style } = getRootProps();
                                return (
                                  <div
                                    className="header-day"
                                    style={{ ...style, background: 'rgba(76, 96, 135, 0.29)' }}
                                  >
                                    {intervals.map(interval => {
                                      const intervalStyle = {
                                        lineHeight: '30px',
                                        textAlign: 'center',
                                        color: '#ffffff',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        fontFamily: 'Poppins',
                                        height: '100%'
                                      };
                                      return (
                                        <div
                                          {...getIntervalProps({
                                            interval,
                                            style: intervalStyle
                                          })}
                                        >
                                          {interval.startTime.format('dd')}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }}
                            </CustomHeader>
                            <CustomHeader height={35} unit="day">
                              {({
                                headerContext: { intervals },
                                getRootProps,
                                getIntervalProps
                              }) => {
                                return (
                                  <div className="header-day" {...getRootProps()}>
                                    {intervals.map(interval => {
                                      const intervalStyle = {
                                        lineHeight: '30px',
                                        textAlign: 'center',
                                        color: '#2e2e39',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        fontFamily: 'Poppins',
                                        height: '100%'
                                      };
                                      const todayStyle = {
                                        backgroundColor: '#0d1a3a',
                                        lineHeight: '30px',
                                        textAlign: 'center',
                                        color: '#ffffff',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        fontFamily: 'Poppins',
                                        height: '100%'
                                      };
                                      const difference = moment().diff(interval.startTime, 'hours');
                                      if (difference >= 0 && difference <= 24) {
                                        return (
                                          <div
                                            {...getIntervalProps({
                                              interval,
                                              style: todayStyle
                                            })}
                                          >
                                            {interval.startTime.format('D')}
                                          </div>
                                        );
                                      }
                                      return (
                                        <div
                                          {...getIntervalProps({
                                            interval,
                                            style: intervalStyle
                                          })}
                                        >
                                          {interval.startTime.format('D')}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }}
                            </CustomHeader>
                          </TimelineHeaders>
                        </Timeline>
                      )}
                    </Grid>
                  </>
                </Grid>
              </HasAnyPriv>
            </>
          );
        }}
      </Query>
    );
  }
}

Calendar.propTypes = {
  currentUser: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(Calendar);
