import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  stem: {
    width: '40px',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '50%',
      borderLeft: '1px solid #000000',
      transform: 'translate(-50%)'
    }
  }
});

/**
 * @method
 * @summary This component renders the branching stems for @see LinkedAccountTable
 * @name SocialLogo
 * @param {Object} props - React props passed to this component
 * @param {Object} props.isLast - Boolean value used to determine if it is the last child in the tree
 * @param {Object} props.hasSibling - Boolean value used to determine if there is a sibling child in the tree
 * @return {Object} - React JSX
 */
const Stem = props => {
  const { isLast, hasSibling } = props;
  const classes = useStyles();

  const height = 65;

  // Re-enable to remove the "tail", but seems to look better this way as-is
  if (isLast && !hasSibling) {
    // height -= 10;
  }

  return <div className={classes.stem} style={{ height: `${height}px` }} />;
};

Stem.propTypes = {
  isLast: PropTypes.bool.isRequired,
  hasSibling: PropTypes.bool.isRequired
};

export default Stem;
