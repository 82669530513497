const formatSalesforceLabel = ({ label, dimension }) => {
  if (label && dimension) {
    return `${label} (${dimension
      .split('_')
      .map(str => str.charAt(0).toUpperCase() + str.substr(1))
      .join(' ')})`;
  }

  return label ?? '';
};

export default formatSalesforceLabel;
