import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import colors from 'util/colors';

const useStyles = makeStyles({
  legendLeftColumn: {
    display: 'flex'
  },
  legendRightColumn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  legendBox: {
    width: '18px',
    height: '18px',
    borderRadius: '3px',
    marginRight: '10px'
  },
  positionText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '2px'
  },
  clickableText: {
    cursor: 'zoom-in',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  valueText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734'
  },
  subValueText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#6F6F6F',
    marginLeft: '6px'
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    wordBreak: 'break-word'
  },
  tooltip: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    backgroundColor: colors.black80,
    borderRadius: '3px'
  }
});

/**
 * @method
 * @summary This component renders the legend on the right of @see AlbDoughnutGraph
 * @name AlbDoughnutGraphLegend
 * @param {Object} props - React props passed to this component
 * @param {Object} props.boxColor - The color that matches the doughnut graph data
 * @param {Object} props.position - The text key
 * @param {number|string} props.value - The value associated with the position
 * @param {string} props.subValue - An additional value associated with the position
 * @param {Function} props.onClick - A handler function fired on clicking the row
 * @param {string} props.tooltip - The tooltip text displayed on hover
 * @param {boolean} props.large - Larger text
 * @return {Object} - React JSX
 */
const AlbDoughnutGraphLegend = props => {
  const { boxColor, position, value, subValue, onClick, tooltip, large } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      style={{ ...(!large && { margin: '-6px' }) }}
    >
      <Grid item xs={large ? 8 : 6} className={classes.legendLeftColumn}>
        <Tooltip title={tooltip} classes={{ tooltip: classes.tooltip }}>
          <Typography
            className={`${classes.positionText} ${onClick ? classes.clickableText : ''} ${
              tooltip.length ? classes.truncateText : ''
            }`}
            style={{
              color: boxColor,
              pointerEvents: position === 'Other' ? 'none' : 'auto',
              ...(large && { fontSize: '16px' })
            }}
            onClick={onClick}
          >
            {position}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={large ? 4 : 6}
        className={classes.legendRightColumn}
        style={{
          ...(large && { justifyContent: 'space-between' })
        }}
      >
        <Typography className={classes.valueText} style={{ ...(large && { fontSize: '16px' }) }}>
          {typeof value === 'number' ? Math.round(value).toLocaleString() : value}
        </Typography>
        {subValue && <Typography className={classes.subValueText}>{subValue}</Typography>}
      </Grid>
    </Grid>
  );
};

AlbDoughnutGraphLegend.propTypes = {
  boxColor: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  subValue: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  large: PropTypes.bool
};

AlbDoughnutGraphLegend.defaultProps = {
  subValue: null,
  onClick: null,
  tooltip: '',
  large: false
};

export default AlbDoughnutGraphLegend;
