/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Tabs, Tab, Typography } from '@material-ui/core';
import AlbHorizontalBarGraph from 'components/AlbGraphs/AlbHorizontalBarGraph';
import { topPostsColors } from 'util/colors';
import { renderUILongDate, getStartDate } from 'util/date';

const useStyles = makeStyles({
  paper: {
    borderRadius: '0px',
    margin: '25px 15px',
    marginBottom: '0px'
  },
  titleText: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    padding: '0px 24px'
  },
  content: {
    flex: '1',
    padding: '16px 24px'
  },
  container: {
    height: '100%',
    padding: '25px'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

/**
 * @method
 * @summary This component renders the horizontal bar graph section displaying article searches in descending order of article count
 * @name ShareOfVoiceHorizontalBar
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.articleSearches - The article searches contained in the queried competitive intelligence report
 * @return {Object} - React JSX
 */
const ShareOfVoiceHorizontalBar = props => {
  const { articleSearches, articleSearchFilters } = props;
  const classes = useStyles();

  const [datasets, setDatasets] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const graphLabels = [];

    let graphData = [];

    const articleSearchesGraphData = articleSearches.map(
      ({ name, getArticleShareCountByPlatform }) => {
        graphLabels.push(name);
        if (getArticleShareCountByPlatform?.total_articles) {
          return getArticleShareCountByPlatform.total_articles;
        }
        return 0;
      }
    );

    const articleFiltersGraphData = articleSearchFilters.map(
      ({ id, article_search_id: articleSearchId, getArticleShareCountByPlatform }) => {
        graphLabels.push(`Search ${articleSearchId} Filter id: ${id}`);

        if (getArticleShareCountByPlatform?.total_articles) {
          return getArticleShareCountByPlatform.total_articles;
        }
        return 0;
      }
    );

    graphData = [...articleSearchesGraphData, ...articleFiltersGraphData];

    setLabels(graphLabels);
    setDatasets([
      {
        data: graphData,
        backgroundColor: graphData.map((_, i) => topPostsColors[i % topPostsColors.length])
      }
    ]);
  }, [articleSearches, articleSearchFilters]);

  return (
    <Paper className={classes.paper}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Tabs value={0} onChange={() => {}}>
          <Tab label="Share of Company Impact" />
        </Tabs>
        <Typography
          variantMapping={{ body1: 'span' }}
          className={classes.titleText}
          style={{ color: '#979797' }}
        >
          {renderUILongDate(getStartDate(12))} - Present
        </Typography>
      </Grid>
      <div className={classes.content}>
        <Grid container className={classes.container}>
          <AlbHorizontalBarGraph data={{ datasets, labels }} />
        </Grid>
      </div>
    </Paper>
  );
};

ShareOfVoiceHorizontalBar.propTypes = {
  articleSearches: PropTypes.arrayOf(PropTypes.shape()),
  articleSearchFilters: PropTypes.arrayOf(PropTypes.shape())
};

ShareOfVoiceHorizontalBar.defaultProps = {
  articleSearches: [],
  articleSearchFilters: []
};

export default ShareOfVoiceHorizontalBar;
