import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
  Grid,
  Paper,
  Typography,
  Button,
  InputLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { LoginPath, ObserveEventsPathV4 } from 'util/paths';
import Box from 'components/Box';
import { USER_CHANGE_PASSWORD, USER_RECOVER_WITH_TOKEN } from 'gql/user';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { showToast } from 'contexts/ToastContext';
import setPageTitle from 'util/titles';
import AlembicLogo from 'components/AlembicLogo';
import { minPWLength, maxPWLength, passwordValidator } from '../../util/passwordValidator';

const ChangePassword = props => {
  const { token, history, recovery, forcedChange } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [closeOtherSessions, setCloseOtherSessions] = useState(false);

  useEffect(() => {
    setPageTitle('Change Password');
  }, []);

  const [
    changePassword,
    { loading: changeLoading, error: changeError, data: changeData }
  ] = useMutation(USER_CHANGE_PASSWORD);
  const [
    resetPassword,
    { loading: resetLoading, error: resetError, data: resetData }
  ] = useMutation(USER_RECOVER_WITH_TOKEN);

  useEffect(() => {
    const data = changeData || resetData;

    if (data) {
      Object.keys(data).forEach(response => {
        // wipe these to clear any error states
        //        resetState();
        setOldPassword('');
        setNewPassword('');
        setShowOldPassword(false);
        setShowNewPassword(false);
        setCloseOtherSessions(false);

        if (data[response].result) {
          showToast(data[response].message, 'success');

          // if we're in recovery mode we can just continue on to the login screen
          // to make the user log in again.
          if (recovery) {
            history.push(LoginPath);
            return;
          }
          // in forced password change mode, we send then to the dashboard on completion.
          if (forcedChange) {
            // history.push(OptimizePath);
            history.push(ObserveEventsPathV4);
          }
        } else {
          showToast(data[response].message, 'error');
        }
      });
    }
  }, [changeData, resetData]);

  ValidatorForm.addValidationRule('passwordFitsRules', value => passwordValidator(value));

  // There is a bug in the implementation of the
  // react-material-ui-form-validator that the author refuses to fix. The
  // current library does not deal properly with nulls. library that causes it
  // to not properly validate string lengths. This is a workaround. -- jna 3/27/23

  ValidatorForm.addValidationRule('passwordLength', value => {
    if (!value) {
      return null;
    }

    if (value.length < minPWLength || value.length > maxPWLength) {
      return false;
    }
    return true;
  });

  return (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <Paper>
          <Box p={30}>
            {(changeLoading || resetLoading) && (
              <Grid item xs={12}>
                <Box mb={12}>
                  <AlbLoading />
                </Box>
              </Grid>
            )}
            {(changeError || resetError) && (
              <Grid item xs={12}>
                <Box mb={12}>
                  <AlbError error={changeError || resetError} />
                </Box>
              </Grid>
            )}
            <ValidatorForm
              onSubmit={() =>
                recovery
                  ? resetPassword({
                      variables: { token, password: newPassword }
                    }).catch(() => {})
                  : changePassword({
                      variables: {
                        old_password: oldPassword,
                        password: newPassword,
                        close_other_sessions: closeOtherSessions
                      }
                    }).catch(() => {})
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AlembicLogo kind="stacked" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h1">Change Password</Typography>
                  <Typography variant="body1">
                    Please set a new password for your account.
                  </Typography>
                </Grid>
                <TextValidator style={{ display: 'none' }} type="text" autoComplete="username" />
                {recovery !== true && (
                  <Grid item xs={12}>
                    <InputLabel>Old Password</InputLabel>
                    <TextValidator
                      fullWidth
                      autoFocus
                      variant="filled"
                      type={showOldPassword ? 'text' : 'password'}
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      value={oldPassword}
                      onChange={event => setOldPassword(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                              {showOldPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      validators={['required']}
                      errorMessages={['* Required']}
                      autoComplete="new-password"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InputLabel>New Password</InputLabel>
                  <TextValidator
                    fullWidth
                    variant="filled"
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    value={newPassword}
                    onChange={event => setNewPassword(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    validators={[
                      'required',
                      ...(newPassword ? ['passwordLength', 'passwordFitsRules'] : [])
                    ]}
                    errorMessages={[
                      '* Required',
                      ...(newPassword
                        ? [
                            `Password must be at least ${minPWLength} but no more than ${maxPWLength} characters`,
                            'Password should contain at least one number, one symbol, one lower case, and one uppercase letter.'
                          ]
                        : [])
                    ]}
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={closeOtherSessions}
                          onChange={() => setCloseOtherSessions(!closeOtherSessions)}
                        />
                      }
                      label="Log out all other sessions after changing password"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Button fullWidth type="submit" variant="contained" color="primary">
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

ChangePassword.propTypes = {
  token: PropTypes.string,
  history: PropTypes.shape(),
  recovery: PropTypes.bool,
  forcedChange: PropTypes.bool
};

ChangePassword.defaultProps = {
  token: null,
  history: {},
  recovery: false,
  forcedChange: false
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(ChangePassword);
