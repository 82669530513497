import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Avatar } from '@material-ui/core';
import { DefaultContainerAvatar } from 'util/assets';
import Box from 'components/Box';

const useStyles = makeStyles({
  containerImage: {
    width: '35px',
    height: '35px',
    marginRight: '22px'
  },
  containerName: {
    fontWeight: '600',
    fontSize: '15px',
    color: '#0A1734'
  }
});
const AnalyticsContainer = props => {
  const { currentContainer } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        alt="Container Avatar"
        src={currentContainer?.avatar_url ?? DefaultContainerAvatar}
        className={classes.containerImage}
      />
      <Typography className={classes.containerName}>{currentContainer?.name ?? ''}</Typography>
    </Box>
  );
};

AnalyticsContainer.propTypes = {
  currentContainer: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    currentContainer: state.auth.currentContainer
  };
};

export default connect(mapStateToProps)(AnalyticsContainer);
