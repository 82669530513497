import React, { useState, useEffect, useRef, useContext, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { Typography, TableCell } from '@material-ui/core';
import { INSIGHT_ANALYTICS } from 'gql/analytics';
import theme from 'theme';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import EventHashtags from 'components/EventHashtags';
import AssetThumbnail from 'components/AssetThumbnail';
import CustomFooter from 'components/TablePagination';
import { showToast } from 'contexts/ToastContext';
import { getPlatformName, storyIcons } from 'util/social';
import { convertToUserTimeZone } from 'util/date';
import colors from 'util/colors';
import AnalyticsInsightsH1 from './AnalyticsInsightsH1';
import PostContent from './AnalyticsPostContent';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  highlightedPost: {
    '& td': {
      backgroundColor: colors.athensGray
    }
  },
  metric: {
    fontSize: '30px',
    fontWeight: 500,
    color: colors.purple,
    textAlign: 'center'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const Text = memo(({ text, align, fontSize, color }) => (
  <Typography align={align} style={{ fontSize, color, fontWeight: 600 }}>
    {text}
  </Typography>
));

Text.propTypes = {
  text: PropTypes.string,
  align: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string
};

Text.defaultProps = {
  text: '',
  align: 'left',
  fontSize: '14px',
  color: 'black'
};

const Label = memo(({ label }) => (
  <TableCell style={{ backgroundColor: colors.gray95, color: 'black' }}>
    <Typography style={{ fontSize: '12px', fontWeight: 500 }}>{label}</Typography>
  </TableCell>
));

Label.propTypes = {
  label: PropTypes.string
};

Label.defaultProps = {
  label: ''
};

// prevent hashtag re-renders
const Hashtags = memo(hashtagProps => <EventHashtags {...hashtagProps} />);

const InsightsPostTable = props => {
  const {
    currentUser,
    setPosts,
    accounts,
    selectedPosts,
    highlightedPosts,
    handleSelectPosts,
    handleHighlightPosts,
    startDate,
    endDate,
    position,
    mt,
    title,
    startingRowsPerPage,
    hideFooter
  } = props;
  const drawerContext = useContext(PostDrawerViewContext);
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [fullPreviewEvent, toggleFullPreviewEvent] = useState([false, null]);

  const [rowsCount, setRowsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(startingRowsPerPage);

  const { data, loading, error } = useQuery(INSIGHT_ANALYTICS, {
    variables: {
      linkTokens: accounts.map(account => account.id),
      startDate,
      endDate,
      position,
      count: rowsPerPage,
      after: page * rowsPerPage
    }
  });

  useEffect(() => {
    setRowsPerPage(startingRowsPerPage);
  }, [startingRowsPerPage]);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > rowsCount) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (error) showToast(`Error retrieving posts: ${error}`, 'error');
  }, [error]);

  const accountLookup = accountsList =>
    accountsList.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

  const accountsRef = useRef(accountLookup(accounts));
  const selectedPostsRef = useRef(selectedPosts);
  const highlightedPostsRef = useRef(highlightedPosts);

  const formatTableData = rows => {
    const impressionsIndex = 0;
    const engagementIndex = 1;
    const viewsIndex = 2;

    const formattedData = rows.map(({ metrics, ...postProps }, i) => ({
      order: i + 1 + page * rowsPerPage,
      impressions: metrics[impressionsIndex].value,
      engagement: metrics[engagementIndex].value,
      views: metrics[viewsIndex].value,
      thumbnails: { thumbnail_url: postProps.thumbnail_url, assets: postProps.assets },
      ...postProps
    }));

    setTableData(formattedData);
  };

  useEffect(() => {
    if (data?.insightAnalytics?.rows) {
      const { insightAnalytics } = data;
      const { rows, count } = insightAnalytics;

      formatTableData(rows);
      setPosts(rows);
      setRowsCount(count);
    }
  }, [data]);

  useEffect(() => {
    accountsRef.current = accountLookup(accounts);
  }, [accounts]);

  useEffect(() => {
    selectedPostsRef.current = selectedPosts;
  }, [selectedPosts]);

  useEffect(() => {
    highlightedPostsRef.current = highlightedPosts;
  }, [highlightedPosts]);

  const tableTheme = createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiPaper: {
        root: { backgroundColor: '#F0F0F0', boxShadow: 'none !important' }
      },
      MUIDataTable: {
        responsiveBase: { backgroundColor: '#F0F0F0' }
      },
      MuiTableCell: {
        head: { backgroundColor: '#F0F0F0 !important' },
        footer: { borderBottom: 'none' }
      },
      MUIDataTableHeadRow: {
        root: {
          '& th': {
            borderBottom: 'none'
          },
          '& th:nth-child(1)': {
            padding: '24px',
            borderBottom: 'none'
          }
        }
      },
      MUIDataTableBodyRow: {
        root: {
          backgroundColor: 'white',
          position: 'relative',
          // post order column
          '& td:nth-child(-n+1)': {
            backgroundColor: `${colors.navy} !important`,
            padding: '0px',
            color: 'white'
          },
          // post image column
          '& td:nth-child(n+3):nth-child(-n+3)': {
            textAlign: 'center',
            padding: '0px'
          },
          // post content column
          '& td:nth-child(n+4):nth-child(-n+4)': {
            textAlign: 'left'
          },
          // impressions and views columns
          '& td:nth-child(n+6):nth-child(-n+6), & td:nth-child(n+8):nth-child(-n+8)': {
            padding: '20px',
            backgroundColor: colors.wildSand,

            '&:hover': {
              backgroundColor: colors.athensGray
            }
          },
          '& td:only-child': {
            backgroundColor: 'white !important',
            padding: '16px',
            color: 'unset'
          },
          '&:hover': {
            backgroundColor: colors.athensGray
          }
        }
      },
      MUIDataTableFooter: {
        root: {
          backgroundColor: '#F0F0F0'
        }
      }
    }
  });

  const onRowClick = ([eventId]) => {
    drawerContext.toggleDrawer(true, eventId, null, null, startDate, endDate);

    return selectedPostsRef.current.includes(eventId)
      ? handleSelectPosts([])
      : handleSelectPosts([eventId]);
  };

  const setRowProps = ([eventId]) => ({
    onMouseEnter: () => handleHighlightPosts([eventId]),
    onMouseLeave: () => handleHighlightPosts([]),
    ...((highlightedPostsRef.current.includes(eventId) ||
      selectedPostsRef.current.includes(eventId)) && { className: classes.highlightedPost })
  });

  const columnIndices = {
    id: 0,
    date: 1,
    type: 2,
    channel: 5, // linktoken id
    content: 7
  };

  const customHeadRender = headProps => <Label key={headProps.index} label={headProps.label} />;

  const customOrderRender = order => (
    <Text text={`${order}`} align="center" fontSize="18px" color="inherit" />
  );

  const customChannelRender = (id, { rowData }) => {
    const postAccount = accountsRef.current[id];

    const type = rowData[columnIndices.type];
    const socialIcon = storyIcons[type] || postAccount?.platform?.icon;

    return socialIcon ? (
      <div style={{ textAlign: 'center' }}>
        <img alt="channel" width={25} height={25} src={socialIcon} />
      </div>
    ) : (
      ''
    );
  };

  const customThumbnailsRender = content => {
    return (
      <AssetThumbnail
        thumbnailUrl={content.thumbnail_url}
        assets={content.assets}
        width={150}
        height={150}
        rounded
      />
    );
  };

  const customContentRender = (content, tableMeta) => {
    if (tableMeta.rowData) {
      const timestamp = new Date(tableMeta.rowData[columnIndices.date]);
      const postAccount = accountsRef.current[tableMeta.rowData[columnIndices.channel]];
      const campaignEventId = Number(tableMeta.rowData[columnIndices.id]);
      const isTextInPreview = !!(
        fullPreviewEvent[0] === true && fullPreviewEvent[1] === campaignEventId
      );

      // eslint-disable-next-line camelcase
      const account = postAccount?.remote_name || '';

      return (
        <PostContent
          title={account}
          timestamp={convertToUserTimeZone(timestamp, currentUser.time_zone).format('M/D/YY LT')}
          content={content}
          eventId={campaignEventId}
          preview={isTextInPreview}
          togglePreview={toggleFullPreviewEvent}
        />
      );
    }
    return '';
  };

  const customHashtagsRender = (value, tableMeta) => {
    if (tableMeta.rowData) {
      const eventId = tableMeta.rowData[columnIndices.id];
      const text = tableMeta.rowData[columnIndices.content];
      const postAccount = accountsRef.current[tableMeta.rowData[columnIndices.channel]];

      if (postAccount && text) {
        const { type } = postAccount;

        return <Hashtags eventId={+eventId} text={text} platform={getPlatformName({ type })} />;
      }
      return '';
    }
    return '';
  };

  const customMetricRender = metric => {
    const value =
      metric == null
        ? '-'
        : Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(
            metric
          );

    return <Typography className={classes.metric}>{value}</Typography>;
  };

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'date',
      options: { display: false }
    },
    {
      name: 'type',
      options: { display: false }
    },
    {
      name: 'thumbnailUrl',
      options: { display: false }
    },
    {
      name: 'order',
      label: '#',
      options: { customBodyRender: customOrderRender }
    },
    {
      name: 'account',
      label: ' ',
      options: { customHeadRender, customBodyRender: customChannelRender }
    },
    {
      name: 'thumbnails',
      label: 'CONTENT',
      options: { customHeadRender, customBodyRender: customThumbnailsRender }
    },
    {
      name: 'body',
      label: ' ',
      options: { customHeadRender, customBodyRender: customContentRender }
    },
    {
      name: 'hashtags',
      label: 'RECOMMENDED HASHTAGS',
      options: { customHeadRender, customBodyRender: customHashtagsRender }
    },
    {
      name: 'impressions',
      label: 'IMP. LTV',
      options: { customHeadRender, customBodyRender: customMetricRender }
    },
    {
      name: 'engagement',
      label: 'ENG. LTV',
      options: { customHeadRender, customBodyRender: customMetricRender }
    },
    {
      name: 'views',
      label: 'VIEWS LTV',
      options: { customHeadRender, customBodyRender: customMetricRender }
    }
  ];

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    hideFooter ? (
      <></>
    ) : (
      CustomFooter(
        footerCount,
        footerPage,
        footerRowsPerPage,
        changeRowsPerPage,
        changePage,
        textLabels,
        rowsPerPage === 1 ? [1] : rowsPerPageOptions
      )
    );

  const tableOptions = {
    selectableRows: 'none',
    rowHover: false,
    fixedHeader: false,
    filter: false,
    sort: false,
    print: false,
    download: false,
    search: false,
    serverSide: true,
    viewColumns: false,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    onRowClick,
    setRowProps,
    page,
    rowsPerPage,
    count: rowsCount,
    customFooter
  };

  return useMemo(
    () => (
      <Box className={classes.container} mt={mt}>
        {title && (
          <Box mb={10}>
            <AnalyticsInsightsH1>
              {`${position.charAt(0).toUpperCase() + position.substring(1)} Reaching Posts`}
            </AnalyticsInsightsH1>
          </Box>
        )}
        {loading && (
          <Box className={classes.loadingOverlay}>
            <AlbLoading />
          </Box>
        )}
        <Box style={{ opacity: loading ? 0.5 : 1 }}>
          <MuiThemeProvider theme={tableTheme}>
            <MUIDataTable data={tableData} columns={tableColumns} options={tableOptions} />
          </MuiThemeProvider>
        </Box>
      </Box>
    ),
    [tableData, accounts, selectedPosts, highlightedPosts, loading]
  );
};

InsightsPostTable.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  setPosts: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()),
  selectedPosts: PropTypes.arrayOf(PropTypes.string),
  highlightedPosts: PropTypes.arrayOf(PropTypes.string),
  handleSelectPosts: PropTypes.func,
  handleHighlightPosts: PropTypes.func,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  position: PropTypes.string.isRequired,
  title: PropTypes.bool,
  mt: PropTypes.number,
  startingRowsPerPage: PropTypes.number,
  hideFooter: PropTypes.bool
};

InsightsPostTable.defaultProps = {
  accounts: [],
  selectedPosts: [],
  highlightedPosts: [],
  handleSelectPosts: () => {},
  handleHighlightPosts: () => {},
  mt: 50,
  title: true,
  top: false,
  startingRowsPerPage: 5,
  hideFooter: false
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(InsightsPostTable);
