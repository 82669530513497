/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DISABLE_MENTION } from 'gql/mention';
import { SET_ARTICLE_IGNORE } from 'gql/article';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

/**
 * @method
 * @summary This component renders the modal used to exclude a mention
 * @name ExcludeMentionModal
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isModalOpen - A boolean value to determine the modal's open state
 * @param {Function} props.onChange - A function run on successful mutation used to update parent component
 * @param {string} props.mentionId - A string value for the mention id
 * @param {string} props.mentionName - A string value for the mention name
 * @return {Object} - React JSX
 */
const ExcludeMentionModal = props => {
  const { isModalOpen, onChange, mentionId, mentionName, type } = props;

  const [disableMention, { error: mentionError, data: mentionData }] = useMutation(DISABLE_MENTION);
  const [ignoreArticle, { error: articleError, data: articleData }] = useMutation(
    SET_ARTICLE_IGNORE
  );

  useEffect(() => {
    if (mentionData?.disableMention?.disabled === true) {
      showToast('Mention excluded', 'success');
      onChange({
        isModalOpen: false,
        updated: true
      });
    }
  }, [mentionData]);

  useEffect(() => {
    if (articleData?.setArticleIgnore?.ignored === true) {
      showToast('Article excluded.', 'success');
      onChange({
        isModalOpen: false,
        updated: true
      });
    }
  }, [articleData]);

  useEffect(() => {
    if (mentionError || articleError) {
      onChange({
        isModalOpen: false
      });
    }
  }, [mentionError, articleError]);

  return (
    <>
      {isModalOpen && mentionError && <AlbError toast error={mentionError} />}
      {isModalOpen && articleError && <AlbError toast error={articleError} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Exclude Mention"
        body={
          <>
            Are you sure you want to exclude the {type === 'articles' ? 'article' : 'mention'}
            :&nbsp;
            <span style={{ color: '#0A1734' }}>{mentionName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Exclude"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => {
          if (type === 'mentions') {
            disableMention({ variables: { id: mentionId } });
          }

          if (type === 'articles') {
            ignoreArticle({ variables: { id: mentionId, ignored: true } });
          }
        }}
      />
    </>
  );
};

ExcludeMentionModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  mentionId: PropTypes.string,
  mentionName: PropTypes.string,
  type: PropTypes.string.isRequired
};

ExcludeMentionModal.defaultProps = {
  mentionId: null,
  mentionName: null
};

export default ExcludeMentionModal;
