import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { NumericFormat } from 'react-number-format';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_SALESFORCE_PROJECTIONS,
  CREATE_OR_UPDATE_SALESFORCE_PROJECTIONS
} from 'gql/salesforce';
import debounce from 'lodash/debounce';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import theme from 'theme';
import getValueAbbreviation from 'util/getValueAbbreviation';
import RadioDropdown from '../RadioDropdown';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    padding: '25px'
  },
  column: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    '& > div': {
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontWeight: 500,
      padding: '5px'
    }
  },
  header: {
    color: '#0A1734',
    fontSize: '15px'
  },
  value: {
    '& input': {
      fontSize: '25px',
      fontWeight: 500,
      color: '#32327D',
      opacity: 1,
      textAlign: 'center',
      padding: '6px 0 7px'
    },

    '& fieldset': {
      border: 'none'
    }
  },
  editable: {
    '& fieldset': {
      borderRadius: '3px',
      border: '1px solid #000000'
    }
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const tableTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputAdornment: {
      positionStart: {
        marginRight: '0px'
      }
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: '10px'
      }
    }
  }
});

// display abbreviated value when not editing field
const AbbreviatedTextField = props => {
  const { value, float, currency, ...rest } = props;

  const [showAbbreviation, setShowAbbreviation] = useState(true);

  return (
    <TextField
      {...rest}
      value={(showAbbreviation && float > 0 ? getValueAbbreviation(float, currency) : value) || ''}
      onFocus={() => setShowAbbreviation(false)}
      onBlur={() => setShowAbbreviation(true)}
    />
  );
};

AbbreviatedTextField.propTypes = {
  value: PropTypes.string,
  float: PropTypes.number,
  currency: PropTypes.bool
};

AbbreviatedTextField.defaultProps = {
  value: null,
  float: null,
  currency: false
};

const ProjectionsTable = props => {
  const { data, loading, error, lookback, setLookback } = props;
  const classes = useStyles();

  // x = Target Opportunity Value
  // opportunityValue,
  // y = Target Net New Opportunity Count
  // netNewOpportunityCount,
  // z = Target Closed / Won Value
  // closeWonValue: netNewOpportunityCount * opportunityValue

  // If you increase x, keep z constant and change y
  // If you increase y, keep z constant and change x
  // If you increase z, keep x constant and change y

  const [targetOpportunityValue, setTargetOpportunityValue] = useState(null);
  const [targetNetNewOpportunityCount, setTargetNetNewOpportunityCount] = useState(null);
  const [targetCloseWonValue, setTargetCloseWonValue] = useState(null);

  const [isInit, setIsInit] = useState(false);
  const [isQueryInit, setQueryIsInit] = useState(false);

  const { data: queryData, error: queryError } = useQuery(GET_SALESFORCE_PROJECTIONS, {
    fetchPolicy: 'network-only'
  });

  const [createOrUpdateSalesforceProjections, { error: mutationError }] = useMutation(
    CREATE_OR_UPDATE_SALESFORCE_PROJECTIONS
  );

  useEffect(() => {
    if (data.id && !isInit) {
      const { opportunityValue, netNewOpportunityCount, closeWonValue } = data;

      setTargetCloseWonValue(closeWonValue);
      setTargetOpportunityValue(opportunityValue);
      setTargetNetNewOpportunityCount(netNewOpportunityCount);

      setIsInit(true);
    }
  }, [data]);

  useEffect(() => {
    if (queryData?.getSalesforceProjections?.id && isInit && !isQueryInit) {
      const {
        opportunityValue,
        netNewOpportunityCount,
        closeWonValue
      } = queryData.getSalesforceProjections;

      if (closeWonValue != null) {
        setTargetCloseWonValue(closeWonValue);
      }
      if (opportunityValue != null) {
        setTargetOpportunityValue(opportunityValue);
      }
      if (netNewOpportunityCount != null) {
        setTargetNetNewOpportunityCount(netNewOpportunityCount);
      }

      setQueryIsInit(true);
    }
  }, [queryData, isInit]);

  const handleOnChange = useCallback(
    debounce(({ opportunityValue, netNewOpportunityCount, closeWonValue }) => {
      createOrUpdateSalesforceProjections({
        variables: {
          ...(opportunityValue != null && { targetOpportunityValue: parseFloat(opportunityValue) }),
          ...(netNewOpportunityCount != null && {
            targetNetNewOpportunityCount: parseFloat(netNewOpportunityCount)
          }),
          ...(closeWonValue != null && { targetCloseWonValue: parseFloat(closeWonValue) })
        }
      });
    }, 500),
    []
  );

  const lookbackOptions = [
    { value: '6_M', label: '6 Months' },
    { value: '1_y', label: '1 Year' },
    { value: '18_M', label: '18 Months' },
    { value: '2_y', label: '2 Years' },
    { value: 'lifetime', label: 'Lifetime' }
  ];

  return (
    <MuiThemeProvider theme={tableTheme}>
      <div style={{ flex: 1 }}>
        <Grid container justifyContent="space-between">
          <span>
            <BoxHeader tabs={['Projections']} />
          </span>
          <RadioDropdown
            label="Lookback Period:"
            options={lookbackOptions}
            selectedOption={lookback}
            setSelectedOption={setLookback}
          />
        </Grid>
        <Grid container className={classes.container}>
          {error && <AlbError error={error} />}
          {queryError && <AlbError error={queryError} />}
          {mutationError && <AlbError error={mutationError} />}
          {loading && (
            <Grid item className={classes.loadingOverlay}>
              <AlbLoading />
            </Grid>
          )}
          <Grid container style={{ opacity: loading ? 0.5 : 1 }}>
            <Grid item className={classes.column} md={4} lg={2}>
              <div className={classes.header}>Projected Closed / Won Value</div>
              <NumericFormat
                className={classes.value}
                disabled
                value={data?.closeWonValue?.toLocaleString() || ''}
                float={data?.closeWonValue || 0}
                customInput={AbbreviatedTextField}
                prefix="$"
                currency
                thousandSeparator
                variant="outlined"
                decimalScale={2}
              />
            </Grid>
            <Grid item className={classes.column} md={4} lg={2}>
              <div className={classes.header}>Target Closed / Won Value</div>
              <NumericFormat
                className={`${classes.value} ${classes.editable}`}
                value={targetCloseWonValue?.toLocaleString() || ''}
                float={targetCloseWonValue || 0}
                customInput={AbbreviatedTextField}
                prefix="$"
                currency
                thousandSeparator
                variant="outlined"
                decimalScale={2}
                onValueChange={({ value }, { source }) => {
                  if (source === 'event') {
                    setTargetCloseWonValue(parseFloat(value));
                    setTargetNetNewOpportunityCount(value / targetOpportunityValue);

                    handleOnChange({
                      closeWonValue: value,
                      netNewOpportunityCount: value / targetOpportunityValue
                    });
                  }
                }}
              />
            </Grid>
            <Grid item className={classes.column} md={4} lg={2}>
              <div className={classes.header}>Average Opportunity Value</div>
              <NumericFormat
                className={classes.value}
                disabled
                value={data?.opportunityValue?.toLocaleString() || ''}
                float={data?.opportunityValue || 0}
                customInput={AbbreviatedTextField}
                prefix="$"
                currency
                thousandSeparator
                variant="outlined"
                decimalScale={2}
              />
            </Grid>
            <Grid item className={classes.column} md={4} lg={2}>
              <div className={classes.header}>Target Opportunity Value</div>
              <NumericFormat
                className={`${classes.value} ${classes.editable}`}
                value={targetOpportunityValue?.toLocaleString() || ''}
                float={targetOpportunityValue || 0}
                customInput={AbbreviatedTextField}
                prefix="$"
                currency
                thousandSeparator
                variant="outlined"
                decimalScale={2}
                onValueChange={({ value }, { source }) => {
                  if (source === 'event') {
                    setTargetOpportunityValue(parseFloat(value));
                    setTargetNetNewOpportunityCount(targetCloseWonValue / value);

                    handleOnChange({
                      opportunityValue: value,
                      netNewOpportunityCount: targetCloseWonValue / value
                    });
                  }
                }}
              />
            </Grid>
            <Grid item className={classes.column} md={4} lg={2}>
              <div className={classes.header}>Projected Net New Opportunity Count</div>
              <NumericFormat
                className={classes.value}
                disabled
                value={data?.netNewOpportunityCount?.toLocaleString() || ''}
                float={data?.netNewOpportunityCount || 0}
                customInput={AbbreviatedTextField}
                thousandSeparator
                variant="outlined"
                decimalScale={2}
              />
            </Grid>
            <Grid item className={classes.column} md={4} lg={2}>
              <div className={classes.header}>Target Net New Opportunity Count</div>
              <NumericFormat
                className={`${classes.value} ${classes.editable}`}
                value={targetNetNewOpportunityCount?.toLocaleString() || ''}
                float={targetNetNewOpportunityCount || 0}
                customInput={AbbreviatedTextField}
                thousandSeparator
                variant="outlined"
                decimalScale={2}
                onValueChange={({ value }, { source }) => {
                  if (source === 'event') {
                    setTargetNetNewOpportunityCount(parseFloat(value));
                    setTargetOpportunityValue(targetCloseWonValue / value);

                    handleOnChange({
                      netNewOpportunityCount: value,
                      opportunityValue: targetCloseWonValue / value
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
};

ProjectionsTable.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    opportunityValue: PropTypes.number,
    netNewOpportunityCount: PropTypes.number,
    closeWonValue: PropTypes.number
  }),
  loading: PropTypes.bool,
  error: PropTypes.shape({}),
  lookback: PropTypes.shape().isRequired,
  setLookback: PropTypes.func.isRequired
};

ProjectionsTable.defaultProps = {
  data: {
    id: null,
    opportunityValue: 0,
    netNewOpportunityCount: 0,
    closeWonValue: 0
  },
  loading: false,
  error: null
};

export default ProjectionsTable;
