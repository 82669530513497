import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, ClickAwayListener } from '@material-ui/core';
import { MoreVert as Actions } from '@material-ui/icons';
import Box from 'components/Box';
import RenameThirdPartyMediaReportModal from 'components/AlembicModalForm/RenameThirdPartyMediaReportModal';
import ArchiveMediaSearchModal from 'components/AlembicModalConfirm/ArchiveMediaSearchModal';
import UnarchiveMediaSearchModal from 'components/AlembicModalConfirm/UnarchiveMediaSearchModal';
import DeleteMediaSearchModal from 'components/AlembicModalConfirm/DeleteMediaSearchModal';
import DetectingMediaSearchModal from 'components/AlembicModalConfirm/DetectingMediaSearchModal';
import HasAnyPriv from 'components/HasAnyPriv';
import { goToRoute } from 'util/routesHelpers';
import { withRouter } from 'react-router-dom';
import { CreateThirdPartyMediaAnalyticsPath } from 'util/paths';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    direction: 'rtl',
    textAlign: 'center'
  },
  actions: {
    position: 'absolute',
    alignItems: 'start',
    textAlign: 'left'
  },
  actionsMenu: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: 'white',
    padding: '25px',
    zIndex: 1
  },
  actionsOptions: {
    marginRight: '20px',

    '& div': {
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },

    '& :not(:last-child)': {
      marginBottom: '30px'
    }
  }
});

/**
 * @method
 * @summary This component renders vertical (...) icon with a list of options when clicked @see EarnedMediaTable
 * @name ThirdPartyMediaTableActions
 * @param {Boolean} props.isEnabled - A boolean value for the media search enabled state
 * @param {String} props.mediaType - Either Podcast or Broadcast/Radio required identifier
 * @param {Boolean} props.isDetecting - A boolean value for the media search detecting state
 * @param {String} props.searchId - A string value for the media search id
 * @param {String} props.searchName - A string value for the media search name
 * @param {Function} props.queryRefetch - A function to refetch the data in the table
 * @param {Object} props.history - the history object from React router.
 * @param {String} props.filterId - if a search filter is passed in, provides its id
 * @return {Object} - React JSX
 */

const mapStateToProps = state => ({
  featureFlags: state.auth.featureFlags
});

const ThirdPartyMediaTableActions = connect(mapStateToProps)(
  withRouter(props => {
    const {
      isEnabled,
      isDetecting,
      searchId,
      searchName,
      mediaType,
      queryRefetch,
      refetchReports,
      history
    } = props;
    const [displayedActions, setDisplayedActions] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDetectingModalOpen, setIsDetectingModalOpen] = useState(false);
    const classes = useStyles();

    const actions = [
      {
        label: 'Rename',
        action: () => {
          setIsRenameModalOpen(!isRenameModalOpen);
        }
      }
    ];

    actions.push({
      label: 'Edit & Save As New',
      action: () => {
        // route to and pre-fill SmartFiltersForm
        goToRoute(
          `${CreateThirdPartyMediaAnalyticsPath}/?id=${searchId}&mediaType=${mediaType}`,
          history
        );
      }
    });

    if (isEnabled) {
      actions.push({
        label: 'Archive',
        action: () => {
          setIsArchiveModalOpen(!isArchiveModalOpen);
        }
      });
    } else {
      actions.push({
        label: 'Unarchive',
        action: () => {
          setIsUnarchiveModalOpen(!isUnarchiveModalOpen);
        }
      });

      actions.push({
        label: 'Delete',
        action: () => {
          setIsDeleteModalOpen(!isDeleteModalOpen);
        }
      });
    }

    if (isEnabled && !isDetecting) {
      actions.push({
        color: '#00A84F',
        label: 'Detect Events',
        action: () => {
          setIsDetectingModalOpen(!isDetectingModalOpen);
        }
      });
    }

    if (isEnabled && isDetecting) {
      actions.push({
        color: 'red',
        label: 'Stop Detecting Events',
        action: () => {
          setIsDetectingModalOpen(!isDetectingModalOpen);
        }
      });
    }

    return (
      <Box className={classes.actionsWrapper}>
        {displayedActions && (
          <Grid container wrap="nowrap" className={classes.actions}>
            <ClickAwayListener onClickAway={() => setDisplayedActions(false)}>
              <Paper className={classes.actionsMenu}>
                <Grid item className={classes.actionsOptions}>
                  {actions.map(action => (
                    <Grid
                      item
                      style={action.color && { color: action.color }}
                      key={action.label}
                      onClick={e => {
                        e.stopPropagation();
                        action.action();
                        setDisplayedActions(false);
                      }}
                    >
                      {action.label}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </ClickAwayListener>
          </Grid>
        )}
        <IconButton
          size="small"
          style={displayedActions ? { zIndex: 2 } : {}}
          onClick={e => {
            e.stopPropagation();
            setDisplayedActions(true);
          }}
        >
          <Actions />
        </IconButton>
        <ArchiveMediaSearchModal
          isModalOpen={isArchiveModalOpen}
          mediaType={mediaType}
          onChange={event => {
            setIsArchiveModalOpen(event.isModalOpen);
            if (event?.newMediaSearchData) {
              queryRefetch();
              refetchReports();
            }
          }}
          searchId={searchId}
          searchName={searchName}
        />
        <UnarchiveMediaSearchModal
          isModalOpen={isUnarchiveModalOpen}
          onChange={event => {
            setIsUnarchiveModalOpen(event.isModalOpen);
            if (event?.newMediaSearchData) {
              queryRefetch();
              refetchReports();
            }
          }}
          mediaType={mediaType}
          searchId={searchId}
          searchName={searchName}
        />
        <DeleteMediaSearchModal
          isModalOpen={isDeleteModalOpen}
          onChange={event => {
            setIsDeleteModalOpen(event.isModalOpen);
            if (event?.newMediaSearchData) {
              queryRefetch();
              refetchReports();
            }
          }}
          searchId={searchId}
          searchName={searchName}
          mediaType={mediaType}
        />
        <DetectingMediaSearchModal
          isModalOpen={isDetectingModalOpen}
          onChange={event => {
            setIsDetectingModalOpen(event.isModalOpen);
            if (event?.newMediaSearchData) {
              queryRefetch();
            }
          }}
          searchId={searchId}
          searchName={searchName}
          mediaType={mediaType}
          setDetecting={!isDetecting}
        />
        {isRenameModalOpen && (
          <RenameThirdPartyMediaReportModal
            isModalOpen={isRenameModalOpen}
            onChange={event => {
              setIsRenameModalOpen(event.isModalOpen);
              if (event?.updateBroadcastSearch || event?.updatePodcastSearch) {
                queryRefetch();
              }
            }}
            reportId={searchId}
            reportName={searchName}
            mediaType={mediaType}
          />
        )}
      </Box>
    );
  })
);

ThirdPartyMediaTableActions.propTypes = {
  isEnabled: PropTypes.bool,
  isDetecting: PropTypes.bool,
  searchId: PropTypes.string,
  searchName: PropTypes.string.isRequired,
  queryRefetch: PropTypes.func.isRequired,
  mediaType: PropTypes.string.isRequired
};

ThirdPartyMediaTableActions.defaultProps = {
  searchId: null,
  isEnabled: false,
  isDetecting: false
};

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see ThirdPartyMediaTableActions
 * @name customCriteriaRender
 * @param {Object} actions - Object passed from @see MUIDataTable
 * @param {String} mediaType - Either Podcast or Broadcast/radio type
 * @param {Boolean} actions.isEnabled - A boolean value for the media search enabled state
 * @param {Boolean} actions.isDetecting - A boolean value for the media search detecting state
 * @param {String} props.searchId - A string value for the media search id
 * @param {String} props.searchName - A string value for the media search name
 * @param {Function} props.queryRefetch - A function to refetch the data in the table
 * @return {Object} - React JSX
 */
const customActionsRender = actions => {
  return (
    <HasAnyPriv
      privs={[
        'TVEYES',
        'TVEYES:SEARCH',
        'TVEYES:SEARCH:CREATE',
        'TVEYES:SEARCH:DELETE',
        'TVEYES:SEARCH:UPDATE'
      ]}
    >
      <ThirdPartyMediaTableActions
        mediaType={actions.mediaType}
        isEnabled={actions.isEnabled}
        isDetecting={actions.isDetecting}
        searchId={actions.searchId}
        searchName={actions.searchName}
        queryRefetch={actions.queryRefetch}
        refetchReports={actions.refetchReports}
      />
    </HasAnyPriv>
  );
};

export default customActionsRender;
