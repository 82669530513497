import { gql } from '@apollo/client';

export const ALL_ORGS_AND_CONTAINERS = gql`
  query($id: ID!) {
    User(id: $id) {
      id
      organizations {
        id
        name
        org_uuid
        containers {
          id
          parent_id
          name
        }
      }
    }
  }
`;

export const UPDATE_ORGMEMBERSHIP_ROLE = gql`
  mutation updateOrgMembershipRole($userId: ID!, $containerId: ID!, $securityRoleId: ID!) {
    updateOrgMembershipRole(
      userId: $userId
      containerId: $containerId
      securityRoleId: $securityRoleId
    )
  }
`;
