import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  Tab,
  Tabs,
  // Button,
  Input,
  Grid,
  IconButton,
  InputAdornment,
  FormControl
} from '@material-ui/core';
import { Close as Delete } from '@material-ui/icons';
import { mediaTabs } from 'util/mediaRoutes';
import HasAnyPriv from 'components/HasAnyPriv';
import { goToRoute } from 'util/routesHelpers';
import {
  MediaSharedPath,
  MediaImagesPath,
  MediaStockPhotosPath,
  MediaVideosPath
} from 'util/paths';
import { AssetFileButton } from '../GenericFileButton';

// Takes props from MediaLayout.

const styles = theme =>
  createStyles({
    root: {
      width: '100%'
    },
    subNavBar: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      zIndex: 3,
      marginBottom: '1px',
      minWidth: '550px'
    },
    tabsBar: {
      width: '50%',
      [theme.breakpoints.up('md')]: {
        // TODO: adjust for different screen sizes.
        // minHeight: 60
      }
      // display: 'inline-block'
    },
    tab: {
      fontSize: '14px',
      color: 'rgba(0,0,0,0.87)',
      backgroundColor: 'transparent'
    },
    activeTab: {
      fontStyle: 'italic'
    },
    searchBox: {
      position: 'relative',
      width: '200px',
      height: '100%',
      border: '1px solid #ced4da',
      marginLeft: '10px',
      marginRight: '10px',
      marginTop: 'auto',
      marginBottom: 'auto'
    },
    form: {
      position: 'relative',
      marginLeft: 'auto',
      marginTop: 'auto'
    },
    filter: {
      variant: 'contained',
      size: 'small',
      alignSelf: 'center',
      marginRight: '5px'
    },
    uploadButton: {
      position: 'relative',
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  });

class MediaNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputEmpty: true,
      inputValue: null
    };
  }

  // sets the state before component mounts.
  static getDerivedStateFromProps = (props, state) => {
    const { searchFilter } = props;
    const { inputValue } = state;

    // input value will only be null on first load.
    if (searchFilter && inputValue === null) {
      // update initial input value, keep track of previous state.
      return {
        prevInput: state.inputValue,
        inputValue: searchFilter,
        inputEmpty: false
      };
    }
    return null;
  };

  // Derive folder name from router location.
  folderFromLocation = () => {
    const { location, match } = this.props;
    const path = location ? location.pathname : null;

    // images tab doesn't contain folders.
    if (!path.includes(MediaImagesPath)) {
      const start = `${match.url}`.length;
      const { length } = path;
      const foldername = location.pathname.substr(start, length);

      return foldername;
    }

    return '';
  };

  // save or clear input in the search box.
  onInputChange = e => {
    if (e.target.value.length > 0) {
      this.setState({ inputValue: e.target.value, inputEmpty: false });
    } else {
      this.setState({ inputValue: '', inputEmpty: true });
    }
  };

  onFormSubmit = e => {
    const { handleSearchInput, handleStockPhotoSearch, modal, tabValue } = this.props;
    const { inputValue } = this.state;
    const { location } = this.props;
    e.preventDefault();

    if (!modal) {
      if (location.pathname.includes(MediaStockPhotosPath)) {
        handleStockPhotoSearch(inputValue);
      } else {
        handleSearchInput(inputValue);
      }
    }

    if (modal) {
      switch (tabValue) {
        case mediaTabs.STOCK_PHOTOS:
          handleStockPhotoSearch(inputValue);
          break;
        default:
          handleSearchInput(inputValue);
          break;
      }
    }

    this.setState({ inputEmpty: false });
  };

  getTabValue = path => {
    if (path.includes(MediaImagesPath)) {
      return mediaTabs.IMAGES;
    }
    if (path.includes(MediaStockPhotosPath)) {
      return mediaTabs.STOCK_PHOTOS;
    }
    if (path.includes(MediaVideosPath)) {
      return mediaTabs.VIDEOS;
    }
    if (path.includes(MediaSharedPath)) {
      return mediaTabs.SHARED_MEDIA;
    }
    return mediaTabs.HOME;
  };

  handleDeleteInput = e => {
    const { handleSearchInput, handleStockPhotoSearch, location, tabValue } = this.props;
    e.preventDefault();

    // location used for non-modal media, tabValue used for modal.
    if (location.pathname.includes('stockphotos') || tabValue === mediaTabs.STOCK_PHOTOS) {
      handleStockPhotoSearch(null);
    } else {
      handleSearchInput(null);
    }

    return this.setState({ inputEmpty: true, inputValue: null });
  };

  handleNavigation = path => {
    const { history, setSearchFilterInTabs } = this.props;
    const { inputValue } = this.state;

    // clear search value when switching tabs.
    if (inputValue) {
      this.setState({ inputValue: null });
      setSearchFilterInTabs();
    }

    goToRoute(path, history);
  };

  render() {
    const {
      classes,
      mediaRoutes,
      location,
      handleModalNavigation,
      modal,
      callback,
      folderName
    } = this.props;
    let { tabValue } = this.props;
    const { inputEmpty, inputValue } = this.state;
    let ul = '';

    if (!modal) {
      tabValue = this.getTabValue(location.pathname);
      ul = this.folderFromLocation();
    }

    return (
      <div className={classes.root}>
        <Grid
          container
          position="sticky"
          alignItems="center"
          color="default"
          className={classes.subNavBar}
          justifyContent="space-between"
        >
          <Tabs
            className={classes.tabsBar}
            indicatorColor="primary"
            value={tabValue}
            variant="standard"
          >
            {/* Using react router. */}
            {!modal &&
              mediaRoutes.map(route => (
                <Tab
                  onClick={() => this.handleNavigation(route.path)}
                  className={classes.tab}
                  key={route.path}
                  label={route.name}
                />
              ))}
            {/* Basic tab navigation inside modal  */}
            {modal &&
              mediaRoutes
                .filter(route => route.name !== 'Shared')
                .map(route => (
                  <Tab
                    className={classes.tab}
                    label={route.name}
                    key={route.path}
                    onClick={() => handleModalNavigation(route.tabValue)}
                  />
                ))}
          </Tabs>
          <div style={{ display: 'flex' }}>
            <HasAnyPriv privs={['SVC:ASSET:CREATE']}>
              <AssetFileButton
                buttonText="Add Media +"
                uploadfolder={ul}
                callback={callback}
                className={classes.uploadButton}
                folderName={folderName}
                currentTab={tabValue}
              />
            </HasAnyPriv>
            <form name="searchForm" onSubmit={this.onFormSubmit}>
              <FormControl className={classes.searchBox}>
                <Input
                  style={{ paddingLeft: '5px', marginTop: 'auto', marginBottom: 'auto' }}
                  placeholder="Search"
                  onChange={this.onInputChange}
                  value={inputValue || ''}
                  endAdornment={
                    !inputEmpty ? (
                      <InputAdornment>
                        <IconButton onClick={this.handleDeleteInput}>
                          <Delete />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }
                />
              </FormControl>
              {/* <Button className={classes.filter}>Filter</Button> */}
            </form>
          </div>
        </Grid>
      </div>
    );
  }
}

MediaNav.propTypes = {
  handleSearchInput: PropTypes.func,
  classes: PropTypes.shape(),
  handleStockPhotoSearch: PropTypes.func,
  location: PropTypes.shape(),
  mediaRoutes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tabValue: PropTypes.number,
  handleModalNavigation: PropTypes.func,
  modal: PropTypes.bool,
  callback: PropTypes.func,
  folderName: PropTypes.string,
  match: PropTypes.shape(),
  history: PropTypes.shape().isRequired,
  setSearchFilterInTabs: PropTypes.func
};

MediaNav.defaultProps = {
  handleSearchInput: () => {},
  classes: {},
  handleStockPhotoSearch: () => {},
  location: null,
  tabValue: 0,
  handleModalNavigation: () => {},
  modal: false,
  callback: () => {},
  folderName: '',
  match: {},
  setSearchFilterInTabs: () => {}
};

export default withRouter(withStyles(styles)(MediaNav));
