/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import tableConsts from 'components/AnalyticsSocial/AnalyticsTableConsts';
import { kpiLabels } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import AudienceSize from 'components/AnalyticsSocialCV/AudienceSize';
import BoxContentChange from './BoxContent/BoxContentChange';
import BoxContentTop from './BoxContent/BoxContentTop';
import BoxContentKPIContainer from './BoxContent/BoxContentKPIContainer';
import BoxContentTable from './BoxContent/BoxContentTable';

const AnalyticsOverview = props => {
  const { accounts, dates } = props;

  return (
    <>
      {/** Change / KPI boxes */}
      <Grid container style={{ marginTop: '-25px' }}>
        <AudienceSize />
      </Grid>
      <Grid container>
        <Grid container item sm={12} md={6}>
          <Grid container>
            <BoxContentChange
              title="Valuation Model"
              metric="valuationModel"
              accounts={accounts}
              dates={dates}
              growth
              useCurrency
              prefix="sb"
            />
            <BoxContentChange
              title="Impressions Growth"
              metric="alembicImpressions"
              accounts={accounts}
              dates={dates}
              growth
              prefix="sb"
            />
            <BoxContentChange
              title="Followers Growth"
              metric="alembicFollowers"
              accounts={accounts}
              dates={dates}
              growth
              prefix="sb"
            />
          </Grid>
          <Grid container>
            <BoxContentChange
              title="Engagements Growth"
              metric="alembicEngagement"
              accounts={accounts}
              dates={dates}
              growth
              prefix="sb"
            />
            <BoxContentChange
              title="Total Followers"
              metric="alembicFollowers"
              accounts={accounts}
              dates={dates}
              prefix="sb"
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} md={6}>
          <BoxContentKPIContainer
            tabs={[kpiLabels.impressions, kpiLabels.engagement, kpiLabels.shares]}
            accounts={accounts}
            dates={dates}
            defaultMetric="lifetime_impressions"
          />
        </Grid>
      </Grid>
      {/** Top boxes */}
      <Grid container>
        <Grid item sm={12} md={4}>
          <BoxContentTop
            title="Top Demographic"
            widget="demographic"
            accounts={accounts}
            dates={dates}
            prefix="sb"
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <BoxContentTop
            title="Top Country"
            widget="country"
            accounts={accounts}
            dates={dates}
            prefix="sb"
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <BoxContentTop
            title="Top City"
            widget="city"
            accounts={accounts}
            dates={dates}
            prefix="sb"
          />
        </Grid>
      </Grid>
      {/** Table */}
      <Grid container>
        <BoxContentTable
          tabs={['Top Posts', 'Recent Posts', 'Top by Campaign', 'Top by Theme/Topic']}
          query={tableConsts.topPostsv2.query}
          accounts={accounts}
          dates={dates}
          prefix="sb"
        />
      </Grid>
    </>
  );
};

AnalyticsOverview.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default AnalyticsOverview;
