import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  title: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#0A1734'
  }
});

const AnalyticsPageTitle = props => {
  const { title } = props;
  const classes = useStyles();

  return <Typography className={classes.title}>{title}</Typography>;
};

AnalyticsPageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

AnalyticsPageTitle.defaultProps = {};

export default AnalyticsPageTitle;
