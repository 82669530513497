import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import AlbTable from 'components/AlbTable';
import AlbLoading from 'components/AlbLoading';
import { formatCurrencyRound } from 'util/formatCurrency';
import getValueAbbreviation from 'util/getValueAbbreviation';
import formatSalesforceLabel from '../formatSalesforceLabel';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  characteristic: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    color: '#32327D',
    fontWeight: 700
  }
});

/**
 * @summary Renders Top Channels Table for cost to recreate reports
 * @name SalesforceRevenueTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.isLoading
 * @return {Object} - React JSX
 */
const SalesforceRevenueTable = props => {
  const { isLoading, tableData } = props;
  const classes = useStyles();

  const [truncateValues, setTruncateValues] = useState(false);

  const handleResize = useCallback(() => setTruncateValues(window.innerWidth <= 1200));

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tableThemeOverrides = {
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        height: '100%'
      },
      responsiveBase: {
        position: 'unset !important',
        height: '100% !important'
      },
      tableRoot: {
        height: '100%'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap'
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        height: '100%'
      }
    }
  };

  const customCharacteristicRender = (text, { rowData }) => {
    const [dimension] = rowData;

    return (
      <Grid className={classes.characteristic}>
        {formatSalesforceLabel({ label: text, dimension })}
      </Grid>
    );
  };

  const customMetricRender = text => (
    <Grid className={classes.flex}>
      <div align="center" style={{ flex: 1 }}>
        {Math.round(text).toLocaleString()}
      </div>
    </Grid>
  );

  const customPercentRender = text => (
    <Grid className={classes.flex}>
      <div align="center" style={{ flex: 1 }}>
        {Math.round(text).toLocaleString()}%
      </div>
    </Grid>
  );

  const customRevenueRender = text => (
    <Grid className={classes.flex}>
      <div align="center" style={{ flex: 1 }}>
        {text < 0 ? '-' : ''}
        {truncateValues
          ? getValueAbbreviation(Math.abs(text), true)
          : `$${formatCurrencyRound(Math.abs(text))}`}
      </div>
    </Grid>
  );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    sort: false,
    pagination: false,
    responsive: 'standard',
    draggableColumns: { enabled: true, transitionTime: 300 }
  };

  const tableColumns = [
    {
      name: 'dimension',
      options: { display: false }
    },
    {
      name: 'label',
      label: 'FIELD',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customCharacteristicRender
      }
    },
    {
      name: 'revenue',
      label: 'REVENUE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customRevenueRender
      }
    },
    {
      name: 'close_rate',
      label: 'CLOSE RATE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customPercentRender
      }
    },
    {
      name: 'deal_size',
      label: 'DEAL SIZE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customRevenueRender
      }
    },
    {
      name: 'volume',
      label: 'VOLUME',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customMetricRender
      }
    }
  ];

  if (isLoading) {
    return <AlbLoading />;
  }

  return (
    <AlbTable
      tableData={tableData}
      tableColumns={tableColumns}
      tableOptions={tableOptions}
      tableThemeOverrides={tableThemeOverrides}
    />
  );
};

SalesforceRevenueTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default SalesforceRevenueTable;
