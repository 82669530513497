import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import PostPreview from 'components/PostPreview';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import { SOCIAL_IMPACT_METRIC_TABLE } from 'gql/analyticsSocialImpact';
import { getDatesForAnalytics } from 'util/date';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  text: {
    fontWeight: '500',
    fontSize: '16px'
  }
});

const PublishedVsLongTailTable = props => {
  const { selectedMetric, selectedAccounts, selectedDates } = props;
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [after, setAfter] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();
  const rowsPerPageOptions = [];
  const drawerContext = useContext(PostDrawerViewContext);

  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const { loading, error, data } = useQuery(SOCIAL_IMPACT_METRIC_TABLE, {
    variables: {
      metric: selectedMetric,
      linkTokens: selectedAccounts.map(({ id }) => id),
      startDate,
      endDate,
      after: after * rowsPerPage,
      count: rowsPerPage
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setAfter(0);
  }, [selectedMetric]);

  useEffect(() => {
    let tempTableData = [];
    let tempCount = 0;

    if (data?.socialImpactMetricTable?.rows?.length > 0) {
      tempTableData = data.socialImpactMetricTable.rows.map(row => {
        const campaignEventDate = moment.utc(row.campaignEvent.completed_at);

        return {
          id: row.campaignEvent.id,
          post: row.campaignEvent,
          isPublishedInTimePeriod: moment
            .utc(row.campaignEvent.completed_at)
            .isBetween(startDate, endDate),
          daysOld: moment.utc().diff(campaignEventDate, 'days'),
          impressions: row.impressions,
          engagement: row.engagement,
          shares: row.shares
        };
      });
      tempCount = data.socialImpactMetricTable.count;
    }

    setTableData(tempTableData);
    setCount(tempCount);
  }, [data]);

  const customPostRender = post => <PostPreview campaignEvent={post} />;

  const customTimePeriodRender = isPublishedInTimePeriod => (
    <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
      {isPublishedInTimePeriod === true && <Check fontSize="large" style={{ color: '#C40075' }} />}
      {isPublishedInTimePeriod === false && <Close fontSize="large" style={{ color: '#32327D' }} />}
    </div>
  );

  const customDayLengthRender = daysOld => (
    <Typography className={classes.text} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
      {`${daysOld.toLocaleString()} Day${daysOld === 1 ? '' : 's'}`}
    </Typography>
  );

  const customValueRender = value => (
    <Typography className={classes.text} style={{ textAlign: 'right' }}>
      {value ? Math.round(value).toLocaleString() : '-'}
    </Typography>
  );

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'post',
      label: 'POST',
      options: {
        customBodyRender: customPostRender,
        sort: false
      }
    },
    {
      name: 'isPublishedInTimePeriod',
      label: 'PUBLISHED IN TIME PER.',
      options: {
        customBodyRender: customTimePeriodRender,
        sort: false
      }
    },
    {
      name: 'daysOld',
      label: 'DAYS OLD',
      options: {
        customBodyRender: customDayLengthRender,
        sort: false
      }
    },
    {
      name: 'impressions',
      label: 'IMPRESSIONS',
      options: {
        customBodyRender: customValueRender,
        sort: false
      }
    },
    {
      name: 'engagement',
      label: 'ENGAGEMENT',
      options: {
        customBodyRender: customValueRender,
        sort: false
      }
    },
    {
      name: 'shares',
      label: 'SHARES',
      options: {
        customBodyRender: customValueRender,
        sort: false
      }
    }
  ];

  const narrativeColumnIndex = tableColumns.map(({ name }) => name).indexOf('narrative');

  const tableThemeOverrides = {
    MUIDataTableHeadRow: {
      root: {
        [`& > :not(:nth-child(${narrativeColumnIndex}))`]: {
          width: 'auto'
        }
      }
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',

        '& th': {
          padding: '0px',
          borderBottom: 'none'
        }
      },
      toolButton: {
        marginLeft: '0px',
        marginRight: '0px'
      }
    }
  };
  const onRowClick = (_, rowMeta) => {
    const {
      campaignEvent: { post_id: postId, linked_account_id: linkTokenId }
    } = data?.socialImpactMetricTable?.rows?.[rowMeta?.rowIndex];

    if (postId && linkTokenId) {
      drawerContext.toggleDrawer(
        true,
        null,
        postId,
        linkTokenId,
        selectedDates.start,
        selectedDates.end
      );
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setAfter,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page: after,
    rowsPerPage,
    count,
    customFooter,
    onRowClick
  };

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      <AlbTable
        tableData={tableData}
        tableColumns={tableColumns}
        tableOptions={tableOptions}
        tableThemeOverrides={tableThemeOverrides}
      />
    </div>
  );
};

PublishedVsLongTailTable.propTypes = {
  selectedMetric: PropTypes.string.isRequired,
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDates: PropTypes.shape().isRequired
};

PublishedVsLongTailTable.defaultProps = {};

export default PublishedVsLongTailTable;
