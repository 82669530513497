import { gql } from '@apollo/client';

const RESURGENCES = gql`
  query {
    resurgences {
      id
      linktoken_id
      post_id
      begin_date
      end_date
      post_date
      is_complete
      numberOfPosts
      bonusImpressions
      valuation
      type
      linktoken {
        id
        avatar_url
        remote_name
        type
      }
    }
  }
`;

export default RESURGENCES;
