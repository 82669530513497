import { gql } from '@apollo/client';

const TOPIC_FIELDS = gql`
  fragment topicFields on Topic {
    id
    created_by
    theme_id
    container_id
    name
    score
    active
    created_at
    updated_at
    deleted_at
    deleted_by {
      id
    }
    container {
      id
      name
    }
    theme {
      id
      name
    }
    created_by_user {
      id
      first_name
      last_name
    }
  }
`;

export const GET_TOPICS_FOR_CURRENT_CONTAINER = gql`
  query {
    topics {
      ...topicFields
      campaign_events {
        id
      }
    }
  }
  ${TOPIC_FIELDS}
`;

export const CREATE_TOPIC = gql`
  mutation($name: String!, $theme_id: ID!) {
    createTopic(name: $name, theme_id: $theme_id) {
      ...topicFields
    }
  }
  ${TOPIC_FIELDS}
`;

export const UPDATE_TOPIC = gql`
  mutation($id: ID!, $name: String!, $active: Boolean) {
    updateTopic(id: $id, name: $name, active: $active) {
      ...topicFields
    }
  }
  ${TOPIC_FIELDS}
`;

export const DELETE_TOPIC = gql`
  mutation($id: ID!) {
    deleteTopic(id: $id)
  }
`;
