// This component acts as a centralized place for OAuthCallback Handling in the UI.
// It is responsible for handling the OAuthCallback response from the OAuth provider
// and then redirecting the user to the appropriate page.

import querystring from 'query-string';
import { apiLinkAccount } from 'gql/linktoken';
import { getAssociatedAccounts, createLinkTokens } from 'gql/associatedAccounts';
import { showToast } from 'contexts/ToastContext';

import { useEffect } from 'react';
import { LinkAccountsPath, AssociatedAccountsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getOAuthConfigForURL } from './constants';

const OAuthResponder = props => {
  const { history } = props;

  const apiErrorHandler = error => {
    showToast(`Linking error: ${error.toString()}`, 'error');
    goToRoute(LinkAccountsPath, history);
  };

  const linkErrorHandler = response => {
    showToast(`Linking failed: ${response.payload.message}`, 'error');
    goToRoute(LinkAccountsPath, history);
  };

  useEffect(() => {
    // the only thing this call should do is figure out if we're being hit with a call back.
    // everything else goes into render.
    // if code is set, then we can attempt to post to the API to store the
    // token. This will be handled via a graphQL Mutation

    // Determine if we're actively processing a callback on mount
    const currentUrl = window.location.origin + window.location.pathname;
    const configObj = getOAuthConfigForURL(currentUrl);

    // we're reflecting flags here in case the user declines the OAuthCallback.
    // we can't wait for React state to update.
    let myInCallback = false;
    let myDeclined = false;

    if (configObj) {
      // remember this
      myInCallback = true;

      // parse the query string
      const query = window.location.search;
      const queryStringObject = querystring.parse(query);
      const code = queryStringObject[configObj.returnCodeVar];
      let verifier;

      // some OAuth methods use a token and a verifier (twitter)
      if (configObj.returnVerifierVar) {
        verifier = queryStringObject[configObj.returnVerifierVar];
      }

      // lastly, deal with rejection (aw!) It's hard to have this
      // parameterized because there's just too many different failure modes.

      // TODO: I would like to rewrite these as functions in the config object.
      // TODO: Example. "declinedOauthCheck = (queryStringObject) => { return queryStringObject['error'] === 'access_denied' }"

      // Twitter
      if (queryStringObject.denied) {
        myDeclined = true;
      }

      // Facebook
      if (queryStringObject.error === 'access_denied' && queryStringObject.error_code === '200') {
        // oh come on. Error 200 is failure?
        myDeclined = true;
      }

      // LinkedIn
      if (queryStringObject.error === 'user_cancelled_login') {
        myDeclined = true;
      }

      // YouTube
      if (queryStringObject.error === 'access_denied') {
        myDeclined = true;
      }

      if (myInCallback && myDeclined) {
        showToast(`You declined the link.`, 'error');
        goToRoute(LinkAccountsPath, history);
        return;
      }

      if (!code) {
        return;
      }

      // if we've gotten this far we can attempt to link the account and exchange the token.
      apiLinkAccount({
        token_type: configObj.alembicPrimaryType,
        remote_token: code,
        remote_verifier: verifier
      })
        .then(async response => {
          if (response.success) {
            // go back to the links page, we're linked! we have to push history or
            // we will lose redux stage and the message will be eaten by the redirect
            const {
              result: linkResult,
              token,
              pages,
              // eslint-disable-next-line camelcase
              onboard_ready
            } = response.payload.data.createSocialLink;

            // eslint-disable-next-line camelcase
            const onboard = onboard_ready ? 'ready' : 'skip';

            // special case -- linkedIn profiles need to go to an account selection screen.
            if (token.type === 'LINKEDIN_PROFILE' && pages) {
              goToRoute(
                `${AssociatedAccountsPath}?id=${token.remote_id}&type=LINKEDIN_COMPANY&onboard=${onboard}`,
                history
              );
            } else if (token.type === 'FB_PROFILE' && pages) {
              // if FB, associated accounts are already selected in the linking process
              getAssociatedAccounts({ id: token.remote_id })
                .then(res => {
                  if (res.success) {
                    const { associatedAccounts } = res.payload.data;

                    createLinkTokens({
                      associatedAccounts: associatedAccounts.map(account => account.id),
                      accountType: 'INSTAGRAM'
                    })
                      .then(result => {
                        if (!linkResult) {
                          showToast(
                            `Not all permissions for ${token.remote_name}'s ${configObj.name} account were accepted. Please Note: All permissions need to be accepted for Alembic to work.`,
                            'error'
                          );
                        } else if (result?.payload?.data?.createLinkTokens?.length > 0) {
                          showToast('Page Link Success', 'success');
                        } else {
                          showToast('Some pages were not accepted', 'warn');
                        }

                        goToRoute(`${LinkAccountsPath}?onboard=${onboard}`, history);
                      })
                      .catch(apiErrorHandler);
                  } else {
                    linkErrorHandler(res);
                  }
                })
                .catch(apiErrorHandler);
            } else {
              if (linkResult) {
                showToast(
                  `${configObj.name} account for ${token.remote_name} linked. Please enable accounts to begin data collection.`,
                  'success'
                );
              } else {
                showToast(
                  `Not all permissions for ${token.remote_name}'s ${configObj.name} account were accepted. Please Note: All permissions need to be accepted for Alembic to work.`,
                  'error'
                );
              }
              goToRoute(`${LinkAccountsPath}?onboard=${onboard}`, history);
            }
          } else {
            linkErrorHandler(response);
          }
        })
        .catch(apiErrorHandler);
    }
  }, []);

  // right now, this Component does not render anything.
  // TODO: Should we just wrap the oauth callback with this function and render children instead?
  return null;
};

OAuthResponder.propTypes = {
  history: PropTypes.shape().isRequired
};

export default withRouter(OAuthResponder);
