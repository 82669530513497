import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AnalyticsTimeline from 'components/AnalyticsTimeline';
import InsightsPostTable from './InsightsPostTable';

const AnalyticsInsights = props => {
  const { accounts, dates } = props;

  const [selectedPosts, setSelectedPosts] = useState([]);
  const [highlightedPosts, setHighlightedPosts] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [bottomPosts, setBottomPosts] = useState([]);
  const [timelineDates, setTimelineDates] = useState([]);

  // Update URL parameters when date range changes
  useEffect(() => {
    const startDateTemp = moment(dates.start);
    const endDateTemp = moment(dates.end);
    const newTimeLineDates = [];
    let id = 1;

    for (let current = startDateTemp; current <= endDateTemp; current.add(1, 'd')) {
      newTimeLineDates.push({ id, date: current.startOf('d').toDate() });
      id += 1;
    }

    setTimelineDates(newTimeLineDates);
  }, [dates]);

  const sharedProps = {
    selectedPosts,
    highlightedPosts,
    handleSelectPosts: setSelectedPosts,
    handleHighlightPosts: setHighlightedPosts,
    startDate: dates.start,
    endDate: dates.end
  };

  return (
    <>
      {/* Timeline */}
      <AnalyticsTimeline
        dates={timelineDates}
        topPosts={topPosts}
        bottomPosts={bottomPosts}
        {...sharedProps}
      />
      {/* Table */}
      <InsightsPostTable
        setPosts={setTopPosts}
        accounts={accounts}
        position="top"
        {...sharedProps}
      />
      <InsightsPostTable
        setPosts={setBottomPosts}
        accounts={accounts}
        position="bottom"
        {...sharedProps}
      />
    </>
  );
};

AnalyticsInsights.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default AnalyticsInsights;
