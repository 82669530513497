import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';

import LegendBox from './LegendBox';

const useStyles = makeStyles({
  legendLabel: {
    fontWeight: '500',
    fontSize: '14px',
    color: '#6F6F6F'
  }
});

const LegendItem = props => {
  const { color, label } = props;
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box mr={10}>
        <LegendBox color={color} />
      </Box>
      <Typography className={classes.legendLabel}>{label}</Typography>
    </Box>
  );
};

LegendItem.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default LegendItem;
