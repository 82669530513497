import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { CheckBox as CheckedIcon, CheckBoxOutlineBlank as UncheckedIcon } from '@material-ui/icons';
import { Grid, MenuItem, Typography, Checkbox, Paper } from '@material-ui/core';
import Select from 'react-select';
import Box from 'components/Box';
import { GET_BROADCAST_SEARCHES } from 'gql/broadcastSearch';
import { GET_PODCAST_SEARCHES } from 'gql/podcastSearch';
import { ARTICLE_SEARCHES } from 'gql/articleSearch';
import { ARTICLE_SEARCH_FILTERS } from 'gql/articleSearchFilter';
import { PODCAST_ICON, ARTICLE_ICON, TV_AND_RADIO_ICON } from 'util/assets';

const BROADCAST = 'BROADCAST';
const PODCAST = 'PODCAST';
const ARTICLE = 'ARTICLE';
const ARTICLE_FILTER = 'ARTICLE_FILTER';

const Menu = props => {
  const { children, selectProps, innerProps } = props;

  return (
    <Paper
      {...innerProps}
      style={{
        position: 'absolute',
        zIndex: 1,
        maxHeight: '300px',
        marginBottom: '8px',
        marginTop: '8px',
        boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)'
      }}
    >
      {selectProps?.isProgramLift === true && (
        <div style={{ padding: '12px 16px' }}>
          <Typography
            variantMapping={{ body1: 'span' }}
            style={{ fontWeight: '600', fontSize: '13px', color: '#585858' }}
          >
            Please note:&nbsp;
          </Typography>
          <Typography
            variantMapping={{ body1: 'span' }}
            style={{ fontSize: '13px', color: '#585858' }}
          >
            Including a report that isn’t detecting events will turn event detection on for that
            report.
          </Typography>
        </div>
      )}
      {children}
    </Paper>
  );
};

Menu.propTypes = {
  children: PropTypes.shape().isRequired,
  selectProps: PropTypes.shape().isRequired,
  innerProps: PropTypes.shape().isRequired
};

const NoOptionsMessage = () => (
  <Box py={10} display="flex" justifyContent="center" alignItems="center">
    <Typography>No reports available</Typography>
  </Box>
);

const Option = props => {
  const { selectProps, data, innerProps, isSelected } = props;
  const { onClick } = innerProps;
  const type = data.value.split('-')[0];

  return (
    <MenuItem onClick={onClick} style={{ padding: '12px 16px', minHeight: '60px' }} key={data.id}>
      <Grid container alignItems="center" wrap="nowrap">
        <Checkbox
          style={{ padding: 0, marginRight: '10px' }}
          checked={isSelected}
          icon={<UncheckedIcon fontSize="small" />}
          checkedIcon={<CheckedIcon fontSize="small" />}
        />
        {type === BROADCAST && (
          <img src={TV_AND_RADIO_ICON} alt="Television Plus Radio Icon" width={45} height={13} />
        )}
        {type === PODCAST && <img src={PODCAST_ICON} alt="Podcast Icon" width={10} height={14} />}
        {(type === ARTICLE || type === ARTICLE_FILTER) && (
          <img src={ARTICLE_ICON} alt="Web Icon" width={15} />
        )}
        <div style={{ position: 'relative', marginLeft: '10px' }}>
          <Typography style={{ whiteSpace: 'normal' }}>{data.name}</Typography>
          {selectProps?.isProgramLift === true && data.isDetecting === true && (
            <Typography
              style={{
                fontSize: '12px',
                color: '#979797',
                position: 'absolute'
              }}
            >
              Detecting Events
            </Typography>
          )}
        </div>
      </Grid>
    </MenuItem>
  );
};

Option.propTypes = {
  selectProps: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  innerProps: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired
};

const MultiValueContainer = props => {
  const { data } = props;
  const type = data.value.split('-')[0];

  return (
    <Box mr={10} display="flex">
      {type === BROADCAST && (
        <img src={TV_AND_RADIO_ICON} alt="Television Plus Radio Icon" width={45} height={13} />
      )}
      {type === PODCAST && <img src={PODCAST_ICON} alt="Podcast Icon" width={10} height={14} />}
      {(type === ARTICLE || type === ARTICLE_FILTER) && (
        <img src={ARTICLE_ICON} alt="Web Icon" width={15} />
      )}
    </Box>
  );
};

MultiValueContainer.propTypes = {
  data: PropTypes.shape().isRequired
};

const customStyles = {
  menuList: provided => ({
    ...provided,
    // the 'Please note:' disclaimer preceding the menu has a height of 65px,
    // default maxHeight of this component is 300px
    maxHeight: '235px'
  })
};

const ThirdPartyMediaSelector = props => {
  const { onChange, isProgramLift } = props;
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const { data: broadcastReportsData } = useQuery(GET_BROADCAST_SEARCHES, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });
  const { data: podcastReportsData } = useQuery(GET_PODCAST_SEARCHES, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });
  const { data: articleReportsData } = useQuery(ARTICLE_SEARCHES, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });
  const { data: articleFilterReportsData } = useQuery(ARTICLE_SEARCH_FILTERS, {
    variables: {
      enabled: true
    },
    fetchPolicy: 'network-only'
  });

  const handleOnChange = newSelectedValues => {
    setSelectedValues(newSelectedValues);

    if (onChange != null) {
      // Process the selected values and get the original report
      const broadcastReports = [];
      const podcastReports = [];
      const articleReports = [];
      const articleFilterReports = [];

      newSelectedValues?.forEach(data => {
        const [type, id] = data.value.split('-');
        let foundReport;

        if (type === BROADCAST) {
          foundReport = broadcastReportsData?.broadcastSearches?.find(report => report.id === id);
          broadcastReports.push(foundReport);
        }

        if (type === PODCAST) {
          foundReport = podcastReportsData?.podcastSearches?.find(report => report.id === id);
          podcastReports.push(foundReport);
        }

        if (type === ARTICLE) {
          foundReport = articleReportsData?.articleSearches?.find(report => report.id === id);
          articleReports.push(foundReport);
        }

        if (type === ARTICLE_FILTER) {
          foundReport = articleFilterReportsData?.articleSearchFilters?.find(
            report => report.id === id
          );
          articleFilterReports.push(foundReport);
        }
      });

      onChange({
        broadcastReports,
        podcastReports,
        articleReports,
        articleFilterReports
      });
    }
  };

  useEffect(() => {
    if (
      broadcastReportsData != null &&
      podcastReportsData != null &&
      articleReportsData != null &&
      articleFilterReportsData != null &&
      isInit === false
    ) {
      const tempOptions = [];
      const tempSelected = [];

      const mapOptions = (report, type) => {
        let { name } = report;
        let isDetecting = report?.is_default || false;

        if (type === ARTICLE_FILTER) {
          name = `${report.article_search.name} (Filter ID: ${report.id})`;
          isDetecting = report?.is_detecting || false;
        }

        const option = {
          id: `${type}-${report.id}`,
          value: `${type}-${report.id}`,
          name,
          isDetecting
        };

        if (report?.is_default === true) {
          tempSelected.push(option);
        }

        if (report?.is_detecting === true) {
          tempSelected.push(option);
        }

        tempOptions.push(option);
      };

      broadcastReportsData?.broadcastSearches?.forEach(report => mapOptions(report, BROADCAST));
      podcastReportsData?.podcastSearches?.forEach(report => mapOptions(report, PODCAST));
      articleReportsData?.articleSearches?.forEach(report => mapOptions(report, ARTICLE));
      articleFilterReportsData?.articleSearchFilters?.forEach(report =>
        mapOptions(report, ARTICLE_FILTER)
      );

      setOptions(tempOptions);
      handleOnChange(tempSelected);
      setIsInit(true);
    }
  }, [broadcastReportsData, podcastReportsData, articleReportsData, articleFilterReportsData]);

  return (
    <Select
      isMulti
      isProgramLift={isProgramLift}
      placeholder="Select Reports"
      menuPlacement="auto"
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable
      components={{
        Menu,
        Option,
        NoOptionsMessage,
        MultiValueContainer
      }}
      options={options}
      value={selectedValues}
      onChange={handleOnChange}
      styles={customStyles}
    />
  );
};

ThirdPartyMediaSelector.propTypes = {
  onChange: PropTypes.func,
  isProgramLift: PropTypes.bool
};

ThirdPartyMediaSelector.defaultProps = {
  onChange: null,
  isProgramLift: false
};

export default ThirdPartyMediaSelector;
