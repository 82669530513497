import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import colors from 'util/colors';
import { getDatesForAnalytics } from 'util/date';
import { formatSecondsDisplay } from 'util/time';
import { BOX_TOTAL_WIDGET, BOX_GROWTH_WIDGET } from 'gql/analytics';
import BoxHeader from './BoxHeader';
import BoxSocial from './BoxSocial';

const useStyles = makeStyles({
  box: {
    flex: 1,
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px'
  },
  content: {
    padding: '24px'
  },
  value: {
    fontSize: '30px',
    fontWeight: 500,
    color: '#32327D'
  },
  change: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 500
  },
  positive: {
    color: '#00A84F',

    '& > div': {
      borderColor: 'transparent transparent #00A84F'
    }
  },
  negative: {
    color: colors.red,

    '& > div': {
      borderColor: `transparent transparent ${colors.red}`
    }
  },
  neutral: {
    color: '#000000',

    '& > div': {
      borderColor: `transparent transparent #000000`
    }
  },
  triangle: {
    position: 'relative',
    borderWidth: '0px 6px 11px 6px',
    borderStyle: 'solid'
  },
  flipped: {
    transform: 'rotate(180deg)'
  }
});

const BoxContentChange = props => {
  const { title, accounts, dates, metric, growth, onlyVideo, useCurrency, prefix } = props;

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [percent, setPercent] = useState(0);
  const [change, setChange] = useState(0);
  const [activeChannels, setActiveChannels] = useState([]);
  const [contributingChannels, setContributingChannels] = useState([
    'Facebook',
    'Twitter',
    'LinkedIn',
    'Instagram',
    'YouTube',
    'Google',
    'Adobe Analytics',
    'TikTok'
  ]);
  const [displayValues, setDisplayValues] = useState(true);

  const [getTotal, { loading: totalLoading, error: totalError, data: totalData }] = useLazyQuery(
    BOX_TOTAL_WIDGET
  );

  const [
    getGrowth,
    { loading: growthLoading, error: growthError, data: growthData }
  ] = useLazyQuery(BOX_GROWTH_WIDGET, {
    fetchPolicy: metric === 'valuationModel' ? 'network-only' : 'cache-first'
  });

  useEffect(() => {
    // To match the analytics v2 graphs and tables which always show UTC, use this Analytics function instead of converting to user timezone
    const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

    // if average watch time widget and only twitter accounts selected, bypass query and display dash
    if (metric === 'alembicWatchTime' && accounts.every(({ type }) => type === 'TWITTER')) {
      setDisplayValues(false);
    } else {
      setDisplayValues(true);

      const variables = {
        // startDate: convertDateStringToUTC(dates.start, userTimeZone),
        // endDate: convertDateStringToUTC(dates.end, userTimeZone),
        startDate,
        endDate,
        linkTokens: accounts.map(({ id }) => id),
        metric,
        onlyVideo
      };

      if (growth) {
        getGrowth({ variables });
      } else {
        getTotal({ variables });
      }
    }
  }, [accounts, dates]);

  useEffect(() => {
    let data = {};

    if (totalData?.analyticsTotalBox) {
      data = totalData.analyticsTotalBox;
    }

    if (growthData?.analyticsGrowthBox) {
      data = growthData.analyticsGrowthBox;
    }

    // show dash if any values are null
    setDisplayValues(
      data.primaryValue !== null || data.percentage !== null || data.secondaryValue !== null
    );

    setValue(Math.round(data.primaryValue) || 0);
    setPercent(Number(data.percentage) || 0);
    setChange(Math.round(data.secondaryValue) || 0);
  }, [totalData, growthData]);

  useEffect(() => {
    if (metric === 'alembicWatchTime') {
      // remove twitter from contributing channels for watch time box widget
      setContributingChannels(channels => channels.filter(channel => channel !== 'Twitter'));
    }

    setActiveChannels(accounts.map(({ platform }) => platform.displayName));
  }, [accounts]);

  return (
    <Box className={classes.box}>
      <BoxHeader tabs={title} prefix={prefix} />
      {(totalLoading || growthLoading) && (
        <Grid container className={classes.content}>
          <AlbLoading />
        </Grid>
      )}
      {!totalLoading && !growthLoading && (
        <>
          <Grid container direction="column" align="center" className={classes.content}>
            {(totalError || growthError) && <AlbError error={totalError || growthError} />}
            {displayValues && (
              <>
                <Grid item className={classes.value}>
                  {useCurrency && '$'}
                  {metric === 'alembicWatchTime'
                    ? formatSecondsDisplay(value)
                    : value?.toLocaleString()}
                </Grid>
                <Grid
                  item
                  className={`${classes.change} ${change < 0 ? classes.negative : ''} ${
                    change > 0 ? classes.positive : ''
                  } ${change === 0 ? classes.neutral : ''}`}
                >
                  <span
                    className={`${change < 0 ? `${classes.negative} ${classes.flipped}` : ''} ${
                      change > 0 ? `${classes.positive}` : ''
                    } ${change === 0 ? classes.neutral : ''}`}
                  >
                    <div
                      className={`${classes.triangle} ${change < 0 ? classes.negative : ''} ${
                        change > 0 ? classes.positive : ''
                      } ${change === 0 ? classes.neutral : ''}`}
                    />
                  </span>
                  &nbsp;
                  {`${percent?.toLocaleString()}% ${change > 0 ? '+' : ''}${
                    useCurrency ? '$' : ''
                  }${
                    metric === 'alembicWatchTime'
                      ? formatSecondsDisplay(change)
                      : change?.toLocaleString()
                  }`}
                </Grid>
              </>
            )}
            {!displayValues && (
              <Grid item className={classes.value}>
                -
              </Grid>
            )}
            <BoxSocial
              activeChannels={activeChannels}
              contributingChannels={contributingChannels}
            />
          </Grid>
        </>
      )}
    </Box>
  );
};

BoxContentChange.propTypes = {
  title: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  growth: PropTypes.bool,
  onlyVideo: PropTypes.bool,
  useCurrency: PropTypes.bool,
  prefix: PropTypes.string
};

BoxContentChange.defaultProps = {
  growth: false,
  onlyVideo: false,
  useCurrency: false,
  prefix: ''
};

export default BoxContentChange;
