import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { EXCLUDE_POST_FROM_COST_REPORT } from 'gql/recreateCostReport';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const IgnorePostFromCTRModal = props => {
  const { isModalOpen, onChange, campaignEventId, reportId, exclude } = props;

  const [excludeCampaignEventFromCostReport, { error, data }] = useMutation(
    EXCLUDE_POST_FROM_COST_REPORT
  );

  useEffect(() => {
    if (data?.excludeCampaignEventFromCostReport?.result) {
      showToast(data.excludeCampaignEventFromCostReport.message, 'success');
      onChange({ isModalOpen: false, newArticleData: data.excludeCampaignEventFromCostReport });
    }
  }, [data]);

  useEffect(() => {
    if (error) onChange({ isModalOpen: false });
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        isDelete={exclude}
        title={`${exclude ? 'Exclude' : 'Include'} Post`}
        body={
          <span style={{ fontFamily: 'Poppins' }}>
            {`Are you sure you want to ${exclude ? 'exclude' : 'include'} the post ${
              exclude ? 'from' : 'to'
            } the report?`}
          </span>
        }
        cancelTitle="Cancel"
        confirmTitle={`${exclude ? 'Exclude' : 'Include'} Post`}
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() =>
          excludeCampaignEventFromCostReport({
            variables: { campaignEventId, reportId, ignored: exclude }
          })
        }
      />
    </>
  );
};

IgnorePostFromCTRModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  campaignEventId: PropTypes.string,
  reportId: PropTypes.string,
  exclude: PropTypes.bool
};

IgnorePostFromCTRModal.defaultProps = {
  campaignEventId: null,
  reportId: null,
  exclude: false
};

export default IgnorePostFromCTRModal;
