import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import AlembicPageHeader from 'components/AlembicPageHeader';
import AlbTable from 'components/AlbTable';
import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { BROADCAST_SEARCH, PREVIEW_BROADCAST_SEARCH } from 'gql/broadcastSearch';
import { PODCAST_SEARCH, PREVIEW_PODCAST_SEARCH } from 'gql/podcastSearch';
import { showToast } from 'contexts/ToastContext';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import AlbLoading from 'components/AlbLoading';
import CreateThirdPartyMediaModal from 'components/AlembicModalForm/CreateThirdPartyMediaModal';
import CustomFooter from 'components/TablePagination';
import {
  ThirdPartyMediaAnalyticsSettingsPath,
  CreateThirdPartyMediaAnalyticsPath
} from 'util/paths';
import { goToRoute, goToRouteReplace, parseQueryString } from 'util/routesHelpers';
import { getToolTipTitleValue } from 'components/AlbTooltip';
import TabWithTooltip from 'components/TabWithTooltip';
import AlembicInputLabel from 'components/AlembicInputLabel';
import Box from 'components/Box';
import { NoImageAvailable as NO_IMAGE_AVAILABLE, TVEyesLogo } from 'util/assets';
import handleGraphQLError from 'util/error';
import AssetThumbnail from 'components/AssetThumbnail';
import { PODCAST, BROADCAST } from './ThirdPartyMediaConsts';

const styles = makeStyles({
  paper: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  inputLabel: {
    width: '95%'
  },
  booleanField: {
    width: '95%',
    margin: '0px 0px 15px 0px',

    '& input': {
      '&:focus': {
        opacity: 1
      }
    }
  },
  searchRulesText: {
    width: '95%',
    margin: '15px 0px 30px 0px'
  },
  searchRulesPrimary: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#0A1734'
  },
  searchRulesSecondary: {
    fontSize: '14px'
  },
  searchNote: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#0A1734',
    marginBottom: '30px'
  },
  searchNoteSecondary: {
    fontWeight: 500,
    fontSize: '10px',
    color: '#0A1734',
    marginBottom: '10px'
  },
  searchLink: {
    fontWeight: '600',
    color: '#1DA1F2',
    '&:visited': { color: 'purple' }
  }
});

const cleanQueryKeywords = keywords => keywords.replaceAll('"', '');

const CreateThirdPartyMediaReport = ({ history, location }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [tableBroadcastData, setTableBroadcastData] = useState([]);
  const [tablePodcastData, setTablePodcastData] = useState([]);
  const [currentSearchFields, setCurrentSearchFields] = useState({});
  const [podcastCount, setPodcastCount] = useState(0);
  const [broadcastCount, setBroadcastCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [mediaType, setMediaType] = useState(BROADCAST);
  const [totalBroadcastResults, setTotalBroadcastResults] = useState(null);
  const [totalPodcastResults, setTotalPodcastResults] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [totalResults, setTotalResults] = useState(null);
  const [podcastKeywords, setPodcastKeywords] = useState(null);
  const [broadcastKeywords, setBroadcastKeywords] = useState(null);
  const [extractKeywordValuePodcast, setExtractKeywordValuePodcast] = useState('');
  const [extractKeywordValueBroadcast, setExtractKeywordValueBroadcast] = useState('');

  const classes = styles();

  const handleRadioChange = event => {
    const type = event.target.value;

    goToRouteReplace(`${CreateThirdPartyMediaAnalyticsPath}?mediaType=${type}`);
    setMediaType(type);

    if (type === PODCAST) {
      setTableData(tablePodcastData);
      setTableCount(podcastCount);
      setTotalResults(totalPodcastResults);
      setCurrentSearchFields({ query_keywords: podcastKeywords });
    }

    if (type === BROADCAST) {
      setTableData(tableBroadcastData);
      setTableCount(broadcastCount);
      setTotalResults(totalBroadcastResults);
      setCurrentSearchFields({ query_keywords: broadcastKeywords });
    }
  };

  const [
    getExistingBroadcastSearch,
    { data: broadcastSearchData, error: broadcastSearchError }
  ] = useLazyQuery(BROADCAST_SEARCH);
  const [
    getExistingPodcastSearch,
    { data: podcastSearchData, error: podcastSearchError }
  ] = useLazyQuery(PODCAST_SEARCH);

  // preview broadcast data
  const [
    previewBroadcastSearch,
    { data: broadcastData, loading: broadcastLoading, error: broadcastPreviewsError }
  ] = useLazyQuery(PREVIEW_BROADCAST_SEARCH);

  // preview podcast data
  const [
    previewPodcastSearch,
    { data: podcastData, loading: podcastLoading, error: podcastPreviewsError }
  ] = useLazyQuery(PREVIEW_PODCAST_SEARCH);

  useEffect(() => {
    // broadcast or podcast toggle
    const media = parseQueryString('mediaType', location)?.[0];
    // id of existing search if user is 'editing and creating new'
    const id = parseQueryString('id', location)?.[0];

    if (media) {
      setMediaType(media);
    }

    if (id) {
      if (media === BROADCAST) {
        getExistingBroadcastSearch({ variables: { id } });
      }

      if (media === PODCAST) {
        getExistingPodcastSearch({ variables: { id } });
      }
    }
  }, []);

  // format data for table columns.
  useEffect(() => {
    if (broadcastData?.previewBroadcastSearchResults) {
      const tableDataFormatted = broadcastData.previewBroadcastSearchResults.rows.map(search => {
        const description = search.transcript;

        if (description.length > 600) {
          description.slice(0, 600).concat('...');
        }

        return {
          title: {
            title: `${search.stationName} | ${search.marketName}`,
            publishedDate: search.completedDateUTC
          },
          transcript: description
        };
      });

      setTableBroadcastData(tableDataFormatted);
      setTableData(tableDataFormatted);
      setBroadcastCount(tableDataFormatted.length);
      setBroadcastCount(tableDataFormatted.length);
      setTotalBroadcastResults(broadcastData.previewBroadcastSearchResults.totalCount);
      setTotalResults(broadcastData.previewBroadcastSearchResults.totalCount);
    }

    if (podcastData?.previewPodcastSearchResults) {
      const tableDataFormatted = podcastData.previewPodcastSearchResults.rows.map(search => {
        const description = search.description ? search.description : search.summary;

        if (description.length > 600) {
          description.slice(0, 600).concat('...');
        }
        return {
          title: {
            image: search.imageUrl,
            title: search.title,
            publishedDate: search.publishedDate
          },
          transcript: description
        };
      });

      setTablePodcastData(tableDataFormatted);
      setTableData(tableDataFormatted);
      setPodcastCount(tableDataFormatted.length);
      setTableCount(tableDataFormatted.length);
      setTotalPodcastResults(podcastData.previewPodcastSearchResults.totalCount);
      setTotalResults(podcastData.previewPodcastSearchResults.totalCount);
    }
  }, [broadcastData, podcastData]);

  useEffect(() => {
    if (broadcastSearchData?.broadcastSearch?.query_keywords) {
      const keywords = cleanQueryKeywords(broadcastSearchData?.broadcastSearch?.query_keywords);
      setBroadcastKeywords(keywords);
      setExtractKeywordValueBroadcast(
        broadcastSearchData?.broadcastSearch?.mention_extract_keyword ?? ''
      );
      setCurrentSearchFields({ query_keywords: keywords });
    }

    if (podcastSearchData?.podcastSearch?.query_keywords) {
      const keywords = cleanQueryKeywords(podcastSearchData?.podcastSearch?.query_keywords);
      setPodcastKeywords(keywords);
      setExtractKeywordValuePodcast(
        podcastSearchData?.podcastSearch?.mention_extract_keyword ?? ''
      );
      setCurrentSearchFields({ query_keywords: keywords });
    }
  }, [broadcastSearchData, podcastSearchData]);

  useEffect(() => {
    if (broadcastSearchError) {
      handleGraphQLError(broadcastSearchError);
    }

    if (podcastSearchError) {
      handleGraphQLError(podcastSearchError);
    }

    if (broadcastPreviewsError) {
      handleGraphQLError(broadcastPreviewsError);
    }

    if (podcastPreviewsError) {
      handleGraphQLError(podcastPreviewsError);
    }
  }, [broadcastSearchError, podcastSearchError, broadcastPreviewsError, podcastPreviewsError]);

  const createSearch = () => {
    let hasError = false;
    let errorMessage;

    if (!currentSearchFields?.query_keywords) {
      errorMessage = 'Please include a query to search.';
      hasError = true;
    }

    if (
      (mediaType === BROADCAST && !extractKeywordValueBroadcast) ||
      (mediaType === PODCAST && !extractKeywordValuePodcast)
    ) {
      errorMessage = 'Please include a mention to extract';
      hasError = true;
    }

    if (hasError) {
      showToast(errorMessage, 'error');
    } else {
      setIsCreateModalOpen(true);
    }
  };

  const generatePreview = () => {
    if (mediaType === BROADCAST) {
      if (!currentSearchFields?.query_keywords) {
        showToast('Please include a query to search for.', 'error');
      } else {
        previewBroadcastSearch({
          variables: { query_keywords: currentSearchFields.query_keywords }
        });
      }
    }

    if (mediaType === PODCAST) {
      if (!currentSearchFields?.query_keywords) {
        showToast('Please include a query to search for.', 'error');
      } else {
        previewPodcastSearch({
          variables: { query_keywords: currentSearchFields.query_keywords }
        });
      }
    }
  };

  const formatDateTime = date => moment(date).format('MM/DD/YY @ h:mm a');

  const customMediaTitle = media => {
    const { image, title, publishedDate } = media;
    let mediaTitle = title;

    if (title.length > 70) {
      mediaTitle = title.slice(0, 70).concat('...');
    }

    let imageUrl = image;

    if (!image) {
      imageUrl = NO_IMAGE_AVAILABLE;
    }

    return (
      <Grid container wrap="nowrap" spacing={1}>
        {mediaType === PODCAST && (
          <Grid item>
            <AssetThumbnail
              thumbnailUrl={imageUrl}
              placeholderUrl={NO_IMAGE_AVAILABLE}
              width={154}
              height={154}
            />
          </Grid>
        )}
        <Grid item>
          <Tooltip title={title.length > 70 ? title : ''}>
            <div style={{ marginBottom: '15px' }}>{mediaTitle}</div>
          </Tooltip>
          <div className={classes.dates}>Published: {formatDateTime(publishedDate)}</div>
        </Grid>
      </Grid>
    );
  };

  const tableColumns = [
    {
      name: 'title',
      label: 'Title',
      options: { customBodyRender: customMediaTitle, sort: false }
    },
    {
      name: 'transcript',
      label: 'Transcript'
    }
  ];

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count: tableCount,
    customFooter
  };

  return (
    <>
      <AlembicPageHeader
        backButton
        backRoute={`${ThirdPartyMediaAnalyticsSettingsPath}?mediaType=${mediaType}`}
        pageTitle="Create 3rd Party Media Report"
        buttonTitle="Create"
        buttonOnClick={createSearch}
      >
        <div right>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={mediaType}
            onChange={handleRadioChange}
          >
            <FormControlLabel value={BROADCAST} control={<Radio />} label="TV/Radio" />
            <FormControlLabel value={PODCAST} control={<Radio />} label="Podcast" />
          </RadioGroup>
        </div>
      </AlembicPageHeader>

      <Paper className={classes.paper}>
        <Tabs value={0}>
          <Tab
            label={
              <TabWithTooltip
                tabTitle="Filtering Options"
                tooltipTitle={getToolTipTitleValue('emBroadcastPreviews')}
              />
            }
            classes={{
              root: classes.tabOverRide
            }}
          />
        </Tabs>

        <Grid container direction="column" justifyContent="center" alignItems="center">
          <div className={classes.inputLabel} style={{ marginTop: '30px' }}>
            <AlembicInputLabel primaryText="Search Parameters:" />
          </div>
          <TextField
            onChange={e => {
              const keywords = e.target.value;
              setCurrentSearchFields({ query_keywords: keywords });

              if (mediaType === PODCAST) {
                setPodcastKeywords(keywords);
              }

              if (mediaType === BROADCAST) {
                setBroadcastKeywords(keywords);
              }
            }}
            fullWidth
            variant="filled"
            placeholder="Ex. (49ers OR niners) AND football"
            value={currentSearchFields?.query_keywords || ''}
            className={classes.booleanField}
          />
          <div className={classes.inputLabel}>
            <AlembicInputLabel primaryText="Mention to Extract (1 per search):" />
          </div>
          <TextField
            onChange={e => {
              if (mediaType === BROADCAST) {
                setExtractKeywordValueBroadcast(e.target.value);
              }

              if (mediaType === PODCAST) {
                setExtractKeywordValuePodcast(e.target.value);
              }
            }}
            fullWidth
            variant="filled"
            placeholder="Ex. 49ers"
            value={
              mediaType === BROADCAST ? extractKeywordValueBroadcast : extractKeywordValuePodcast
            }
            className={classes.booleanField}
          />
          <Grid item className={classes.searchRulesText}>
            <Typography className={classes.searchRulesPrimary}>How to execute search:</Typography>
            <Typography className={classes.searchRulesSecondary}>
              {'\u2022'} Please see:{' '}
              <a
                href="https://support.getalembic.com/hc/en-us/articles/9414771929229"
                className={classes.searchLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.getalembic.com/hc/en-us/articles/9414771929229
              </a>
            </Typography>
          </Grid>
          <Typography className={classes.searchNote}>
            Reports may take up to an hour to generate. You can see the status of the report from
            the 3rd Party Media Reports list.
          </Typography>
          <Typography className={classes.searchNoteSecondary}>Data provided by TVEyes</Typography>
          <Box mb={20}>
            <img src={TVEyesLogo} alt="TV Eyes Logo" />
          </Box>
        </Grid>
      </Paper>

      <AlembicPageHeader
        pageTitle="Preview"
        buttonTitle="Generate Preview"
        buttonOnClick={generatePreview}
      />

      <Paper className={classes.paper}>
        {broadcastLoading || podcastLoading ? (
          <AlbLoading />
        ) : (
          <>
            <Grid container justifyContent="space-between" alignItems="center">
              <Tabs value={0}>
                <Tab
                  label={
                    <TabWithTooltip
                      tabTitle={mediaType === BROADCAST ? 'TV/Radio' : 'Podcast'}
                      tooltipTitle={getToolTipTitleValue('emBroadcastPreviews')}
                    />
                  }
                  classes={{
                    root: classes.tabOverRide
                  }}
                />
              </Tabs>
              {totalResults > 0 && (
                <Typography style={{ marginRight: '15px' }}>
                  Total Results Available: <b>{totalResults.toLocaleString()}</b>
                </Typography>
              )}
            </Grid>
            <AlbTable
              tableData={tableData}
              tableColumns={tableColumns}
              tableOptions={tableOptions}
            />
          </>
        )}
      </Paper>

      <CreateThirdPartyMediaModal
        isModalOpen={isCreateModalOpen}
        defaultName={currentSearchFields?.query_keywords ?? ''} // prefills name of report
        onChange={e => {
          setIsCreateModalOpen(e.isModalOpen);
          if (e?.newSearch) {
            goToRoute(`${ThirdPartyMediaAnalyticsSettingsPath}?mediaType=${mediaType}`, history);
          }
        }}
        mediaType={mediaType}
        thirdPartyMediaFields={currentSearchFields} // search parameters to persist to db
        extractKeywordValue={
          mediaType === BROADCAST ? extractKeywordValueBroadcast : extractKeywordValuePodcast
        }
      />
    </>
  );
};

CreateThirdPartyMediaReport.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default CreateThirdPartyMediaReport;
