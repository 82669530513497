/* eslint-disable react/no-children-prop */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '10px',
    padding: '36px',
    marginBottom: '48px'
  },
  title: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734'
  },
  summaryTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    direction: 'ltr'
  },
  actions: {
    position: 'absolute',
    top: '-9px',
    right: '0px'
  },
  body: {
    fontSize: '20px',
    overflow: 'hidden'
  },
  actionButton: {
    color: '#000000'
  },
  disabled: {
    backgroundColor: 'transparent',
    cursor: 'default',
    pointerEvents: 'none'
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  email: {
    color: '#000000',
    fontSize: '0px'
  },
  animate: {
    transition: 'all 1s ease'
  },
  insightRow: {
    justifyContent: 'center'
  },
  summary: {
    display: 'flex'
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#979797',
    margin: '28px 0px'
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  }
});

/**
 * @method
 * @summary This component renders the results and justify sections for the GraphFish UI
 * @name StayTuned
 * @return {Object} - React JSX
 */

const StayTuned = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Paper className={`${classes.box} ${classes.animate}`}>
        <div className={classes.title} style={{ margin: '12px 0px' }}>
          No intelligence brief today — stay tuned for the next update!
        </div>
      </Paper>
    </Grid>
  );
};

export default StayTuned;
