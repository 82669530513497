import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { Palette } from 'components/AlbGraphs/Legend';
import { WEB_AND_APPS_APPS_GRAPH } from 'gql/analytics';
import { getDatesForAnalytics } from 'util/date';
import colors, { topPostsColors } from 'util/colors';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { appStoresTooltip } from '../../AlbGraphs/CustomTooltipTemplates';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: '100%'
  },
  tooltip: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    pointerEvents: 'none'
  },
  hidden: {
    display: 'none !important'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

/**
 * @summary This component renders the breakdown tables for Web and Apps apps tab
 * @name WebAppsGraph
 * @param {Object} props - React props passed to this component
 * @param {Object} props.tab - The selected tab displayed in the graph's container
 * @param {Object[]} props.accounts - An array of link token objects from @see PostAccountSelector
 * @param {Object} props.dates - An object containing start and end dates from @see AnalyticsDatePicker
 * @return {Object} - React JSX
 */
const WebAppsGraph = props => {
  const { tab, accounts, dates, page, rowsPerPage, sortOrder } = props;

  const classes = useStyles();

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  // we need to display hovers for zeroed values if they accompany other non-zeroes,
  // otherwise the tooltip will disappear between stacked datasets
  const [dataLookup, setDataLookup] = useState({});
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);

  const { data, error, loading } = useQuery(WEB_AND_APPS_APPS_GRAPH, {
    variables: {
      startDate,
      endDate,
      linkTokens: accounts.map(account => account.id),
      metric: `${tab.metric}Graph`,
      after: page * rowsPerPage,
      count: rowsPerPage,
      orderBy: sortOrder
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    let graphLabels = [];
    let graphDatasets = [];
    let graphDataLookup = {
      tooltipInfo: {}
    };

    if (data?.webAndAppsAppsGraph?.length) {
      graphDatasets = data?.webAndAppsAppsGraph?.map((dataset, i) => {
        // create labels
        if (!i) {
          graphLabels = dataset.timeseries.map(({ x }) => x);
        }

        const color = topPostsColors[i % topPostsColors.length];

        // write time period totals
        graphDataLookup.tooltipInfo[dataset.label] = {
          total: dataset.timeseries.reduce((acc, { y }) => acc + y, 0),
          lifetimeTotal: dataset.lifetimeTotal,
          color
        };

        return {
          type: 'bar',
          label: dataset.label,
          data: dataset.timeseries.map(({ y }) => y),
          backgroundColor: color
        };
      });
    } else {
      graphLabels = [];
      graphDatasets = [];
      graphDataLookup = {
        tooltipInfo: {}
      };
    }

    setLabels(graphLabels);
    setDatasets(graphDatasets);
    setDataLookup(graphDataLookup);
  }, [data]);

  const options = {
    // https://www.chartjs.org/docs/2.9.4/configuration/legend.html
    legend: {
      display: false
    },
    // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
    tooltips: {
      enabled: false,
      intersect: false,
      custom: tooltipModel => {
        const { offsetTop, offsetLeft } = canvasRef.current.canvas;
        const { dataPoints } = tooltipModel;
        // if tooltip.opacity is 0 then we need to hide the tooltip
        const tempIsTooltipVisible = tooltipModel.opacity !== 0;
        let tooltipWidth = 295;
        setIsTooltipVisible(tempIsTooltipVisible);

        let title;

        if (tooltipModel.body) {
          const [line] = tooltipModel.body[0].lines;
          const id = line.split(':');
          title = id.slice(0, -1).join(':');
        }

        if (tempIsTooltipVisible) {
          dataPoints.forEach(dataPoint => {
            if (tooltipRef.current) {
              const innerHTML = appStoresTooltip({
                title,
                date: moment.utc(dataPoint.xLabel).format('MMM. Do, YYYY'),
                value: dataPoint.yLabel,
                total: dataLookup.tooltipInfo[title].total,
                lifetimeTotal: dataLookup.tooltipInfo[title].lifetimeTotal,
                boxColor: dataLookup.tooltipInfo[title].color,
                unit: tab.title
              });

              const style = {
                display: dataPoint.yLabel ? 'unset' : 'none'
              };

              const top = offsetTop + dataPoint.y;
              const left = offsetLeft + dataPoint.x;

              style.direction = 'ltr';
              style.top = `${top - 2.5}px`;
              style.left = `${left - 2.5}px`;

              if (tooltipRef.current.offsetWidth > tooltipWidth) {
                tooltipWidth = tooltipRef.current.offsetWidth;
              }

              if (left + tooltipWidth > canvasRef.current.canvas.getBoundingClientRect().width) {
                style.direction = 'rtl';
              }

              Object.assign(tooltipRef.current.style, style);
              Object.assign(tooltipRef.current, { innerHTML });
            }
          });
        }
      }
    },
    // https://www.chartjs.org/docs/2.9.4/general/interactions/
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    animation: {
      duration: 0 // general animation time
    },
    // https://www.chartjs.org/docs/2.9.4/general/performance.html#disable-animations
    responsiveAnimationDuration: 0, // animation duration after a resize
    scales: {
      xAxes: [
        {
          // https://www.chartjs.org/docs/2.9.4/axes/styling.html#grid-line-configuration
          gridLines: {
            display: false
          },
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/#tick-configuration
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/linear.html
          ticks: {
            maxTicksLimit: 30,
            maxRotation: 0,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14,
            callback: value => {
              return moment
                .utc(value)
                .format('MMM D')
                .toUpperCase();
            }
          },
          stacked: true
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            callback: value => value.toLocaleString(),
            precision: 2,
            beginAtZero: true,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins'
          },
          stacked: true
        }
      ]
    }
  };

  const graphData = canvas => {
    canvasRef.current = canvas.getContext('2d');

    return { labels, datasets };
  };

  return (
    <Grid container className={classes.container}>
      {error && <AlbError error={error} />}
      {loading && (
        <Grid item className={classes.loadingOverlay}>
          <AlbLoading />
        </Grid>
      )}
      <Grid container style={{ opacity: loading ? 0.5 : 1 }}>
        <Bar data={graphData} options={options} />
        <Grid container justifyContent="center">
          <Palette
            legend={{
              palette: datasets.map(({ label, backgroundColor: color, data: dataset }) => {
                const total = dataset.reduce((acc, value) => acc + value, 0);

                return {
                  label,
                  key: label,
                  color,
                  total:
                    tab.title === 'Revenue'
                      ? getValueAbbreviation(total, true, false)
                      : total.toLocaleString()
                };
              })
            }}
          />
        </Grid>
        <Box
          ref={tooltipRef}
          className={`${classes.tooltip} ${isTooltipVisible ? '' : classes.hidden}`}
        />
      </Grid>
    </Grid>
  );
};

WebAppsGraph.propTypes = {
  tab: PropTypes.shape().isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortOrder: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default WebAppsGraph;
