import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_PAGINATED_MENTIONS_AND_ARTICLES } from 'gql/mention';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import Box from 'components/Box';
import { getDatesForAnalytics } from 'util/date';
import MentionsTable from './MentionsTable';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const EnabledMentions = props => {
  const {
    selectedDates,
    broadcastSearchIds,
    podcastSearchIds,
    articleSearchIds,
    articleSearchFilterIds,
    mediaType
  } = props;
  const classes = useStyles();
  const [after, setAfter] = useState(0);
  const [sortOrder, setSortOrder] = useState(['airDate', 'desc']);
  const [dataResponse, setDataResponse] = useState(null);
  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const [getPaginatedMentionsAndArticles, { loading, error, data }] = useLazyQuery(
    GET_PAGINATED_MENTIONS_AND_ARTICLES,
    {
      fetchPolicy: 'network-only'
    }
  );

  const dataRefetch = () => {
    if (
      broadcastSearchIds?.length > 0 ||
      podcastSearchIds?.length > 0 ||
      articleSearchFilterIds?.length > 0 ||
      articleSearchIds?.length > 0
    ) {
      getPaginatedMentionsAndArticles({
        variables: {
          ...(broadcastSearchIds?.length > 0 && {
            broadcastSearchIds
          }),
          ...(podcastSearchIds?.length > 0 && {
            podcastSearchIds
          }),
          ...(articleSearchIds?.length > 0 && {
            articleSearchIds
          }),
          ...(articleSearchFilterIds?.length > 0 && {
            articleSearchFilterIds
          }),
          startDate,
          endDate,
          after: after * 10,
          count: 10,
          orderBy: sortOrder,
          mediaType,
          isEnabled: true
        }
      });
    }
  };

  useEffect(() => {
    dataRefetch();
  }, []);

  useEffect(() => {
    if (loading === false) {
      if (data?.getPaginatedMentionsAndArticles?.rows?.length > 0) {
        setDataResponse(data.getPaginatedMentionsAndArticles);
      } else {
        setDataResponse(null);
      }
    }
  }, [data]);

  useEffect(() => {
    if (after !== 0) {
      setAfter(0);
    } else {
      dataRefetch();
    }
  }, [
    broadcastSearchIds,
    podcastSearchIds,
    articleSearchFilterIds,
    articleSearchIds,
    selectedDates,
    mediaType
  ]);

  useEffect(() => {
    dataRefetch();
  }, [after, sortOrder]);

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      {dataResponse == null && (
        <Box py={24} px={16}>
          <Grid container justifyContent="center">
            <Typography variantMapping={{ body1: 'span' }}>
              Sorry, no data available to display.
            </Typography>
          </Grid>
        </Box>
      )}
      {dataResponse?.rows?.length > 0 && (
        <MentionsTable
          dataResponse={dataResponse}
          dataRefetch={dataRefetch}
          after={after}
          setAfter={setAfter}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          isEnabled
        />
      )}
    </div>
  );
};

EnabledMentions.propTypes = {
  selectedDates: PropTypes.shape().isRequired,
  broadcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  podcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  articleSearchFilterIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  mediaType: PropTypes.string.isRequired
};

export default EnabledMentions;
