import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AlbTooltip } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';
import ConversionsGraph from './ConversionsGraph';
import ConversionsTable from './ConversionsTable';

const styles = makeStyles({
  datesBlock: {
    marginBottom: '20px',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: '#6f6f6f'
  },
  dateStyle: {
    fontWeight: 600,
    color: '#0a1734'
  },
  container: {
    padding: '0px 27px',
    paddingTop: '20px'
  }
});

const ConversionOutlierDrawerView = ({ event, dateRange }) => {
  const classes = styles();

  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item xs={11} className={classes.datesBlock}>
          <span className={classes.dateStyle}>Date of Occurrence: </span>{' '}
          {(dateRange?.start).format('MMM DD, YYYY')}
        </Grid>
        <Grid item xs={1}>
          <AlbTooltip title={tooltipText.conversionOutlierDetail} />
        </Grid>

        <ConversionsGraph event={event} dates={dateRange} />
      </Grid>
      <ConversionsTable event={event} dates={dateRange} />
    </div>
  );
};

ConversionOutlierDrawerView.propTypes = {
  event: PropTypes.shape().isRequired,
  dateRange: PropTypes.shape().isRequired
};

export default ConversionOutlierDrawerView;
