import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Grid, Typography, Button, InputLabel } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { LINK_GOOGLE_PLAY_CONSOLE } from 'gql/linktoken';
import { goToRoute } from 'util/routesHelpers';
import handleGraphQLError from 'util/error';
import { showToast } from 'contexts/ToastContext';
import { LinkAccountsPath } from '../../util/paths';

/**
 * @method
 * @summary This component contains a form allowing the user to input credentials required to connect an Google Play Console account
 * @name LinkGooglePlayConsoleAccountForm
 * @return {Object} - React JSX
 */
const LinkGooglePlayConsoleAccountForm = props => {
  const { history } = props;

  const [bucketName, setBucketName] = useState('');
  const [developerId, setDeveloperId] = useState('');
  const [developerName, setDeveloperName] = useState('');

  const [linkGooglePlayConsoleAccount, { loading, error }] = useMutation(LINK_GOOGLE_PLAY_CONSOLE, {
    onCompleted: result => {
      if (result?.createGooglePlayConsoleLink?.token?.remote_name) {
        showToast(
          // eslint-disable-next-line camelcase
          `${result?.createGooglePlayConsoleLink.token.remote_name} Google Play Console account has been linked. `,
          'success'
        );
        goToRoute(LinkAccountsPath, history);
      }
    }
  });

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  return (
    <>
      <ValidatorForm
        onSubmit={() => {
          linkGooglePlayConsoleAccount({
            variables: {
              bucket_name: bucketName.trim(),
              developer_id: developerId.trim(),
              developer_name: developerName.trim()
            }
          });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Enter your Google Play Console credentials</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Cloud Storage URI</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Cloud Storage URI e.g. pubsite_prod_xxxxxx"
              value={bucketName}
              onChange={event => setBucketName(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Developer ID</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Developer ID"
              value={developerId}
              onChange={event => setDeveloperId(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Developer Name</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Developer Name"
              value={developerName}
              onChange={event => setDeveloperName(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
              Save Credentials
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

LinkGooglePlayConsoleAccountForm.propTypes = {
  history: PropTypes.shape().isRequired
};

export default withRouter(LinkGooglePlayConsoleAccountForm);
