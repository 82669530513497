import React from 'react';
import PropTypes from 'prop-types';
// import { useQuery } from '@apollo/client';
// import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AnalyticsBox from 'components/AnalyticsBox';
import AlbSingleValueGraph from 'components/AlbGraphs/AlbSingleValueGraph';

const ShareOfVoiceValues = ({ totalArticles, myArticles, myBestMonth, myWorstMonth, dates }) => {
  const sovBoxes = [
    {
      title: 'Total Articles',
      value: Math.round(totalArticles).toLocaleString()
    },
    {
      title: 'My Articles',
      value: Math.round(myArticles).toLocaleString()
    },
    {
      title: 'My Best Month',
      value: myBestMonth
    },
    {
      title: 'My Worst Month',
      value: myWorstMonth
    }
  ];

  return (
    <>
      <Grid container item sm={12}>
        {sovBoxes.map(tab => (
          <Grid container item sm={12} md={6} key={tab.title}>
            <AnalyticsBox tabs={[tab.title]} onChange={() => {}}>
              <AlbSingleValueGraph value={tab.value} dates={dates} />
            </AnalyticsBox>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ShareOfVoiceValues.propTypes = {
  totalArticles: PropTypes.number,
  myArticles: PropTypes.number.isRequired,
  myBestMonth: PropTypes.string.isRequired,
  myWorstMonth: PropTypes.string.isRequired,
  dates: PropTypes.shape().isRequired
};

ShareOfVoiceValues.defaultProps = {
  totalArticles: null
};

export default ShareOfVoiceValues;
