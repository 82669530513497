import { gql } from '@apollo/client';

export const PROGRAM_LIFT_REPORTS_FOR_POST = gql`
  query programLiftReportsForPost($id: ID!) {
    programLiftReportsForPost(id: $id) {
      id
      program_lift_reports_id
      campaign_event_id
    }
  }
`;

export const ADD_POST_TO_PROGRAM_LIFT_REPORT = gql`
  mutation addPostToProgramLiftReport($programLiftReportId: ID!, $campaignEventId: ID!) {
    addPostToProgramLiftReport(
      programLiftReportId: $programLiftReportId
      campaignEventId: $campaignEventId
    ) {
      id
      name
    }
  }
`;

export const REMOVE_POST_FROM_PROGRAM_LIFT_REPORT = gql`
  mutation removePostFromProgramLiftReport($programLiftReportId: ID!, $campaignEventId: ID!) {
    removePostFromProgramLiftReport(
      programLiftReportId: $programLiftReportId
      campaignEventId: $campaignEventId
    ) {
      id
      name
    }
  }
`;
