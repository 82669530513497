import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { getDatesForAnalytics } from 'util/date';
import AnalyticsPageTitle from 'components/AnalyticsComponents/AnalyticsPageTitle';

import SalesforceTopInsights from './SalesforceTopInsights';
import SalesforceRevenue from './SalesforceRevenue';
import SalesforceHistoryForecast from './SalesforceHistoryForecast';
import SalesforceBestPerformers from './SalesforceBestPerformers';

const useStyles = makeStyles({
  row: {
    padding: '25px 56px'
  },
  title: {
    margin: '0px 56px 30px 56px'
  }
});

const SalesforceOverview = props => {
  const { linktokenId, dates, selectedPeriodical } = props;
  const classes = useStyles();

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container className={classes.title}>
        <AnalyticsPageTitle title="Top Level Insights" />
      </Grid>
      <SalesforceTopInsights
        linktokenId={linktokenId}
        startDate={startDate}
        endDate={endDate}
        selectedPeriodical={selectedPeriodical}
      />
      <Grid container className={classes.title}>
        <AnalyticsPageTitle title="Revenue" />
      </Grid>
      <Grid container item className={classes.row}>
        <SalesforceRevenue
          linktokenId={linktokenId}
          startDate={startDate}
          endDate={endDate}
          selectedPeriodical={selectedPeriodical}
        />
      </Grid>
      <Grid container className={classes.title}>
        <AnalyticsPageTitle title="Best Performers" />
      </Grid>
      <SalesforceBestPerformers
        linktokenId={linktokenId}
        startDate={startDate}
        endDate={endDate}
        selectedPeriodical={selectedPeriodical}
      />
      <Grid container className={classes.title}>
        <AnalyticsPageTitle title="History + Forecast" />
      </Grid>
      <Grid container item className={classes.row}>
        <SalesforceHistoryForecast linktokenId={linktokenId} />
      </Grid>
    </Grid>
  );
};

SalesforceOverview.propTypes = {
  linktokenId: PropTypes.string,
  dates: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)])
      .isRequired,
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]).isRequired
  }).isRequired,
  selectedPeriodical: PropTypes.string
};

SalesforceOverview.defaultProps = {
  linktokenId: null,
  selectedPeriodical: null
};

export default SalesforceOverview;
