import { showToast } from 'contexts/ToastContext';

// add additional account types here:
export const getPageUrl = account => {
  switch (account.type) {
    case 'INSTAGRAM':
      return `https://www.instagram.com/${account.remote_name}/`;
    case 'FB_PAGE':
      return `https://www.facebook.com/${account.remote_id}`;
    case 'TWITTER':
      return `https://twitter.com/i/user/${account.remote_id}?user_id=${account.remote_id}`;
    case 'LINKEDIN_COMPANY':
      return `https://www.linkedin.com/company/${account.remote_id}/`;
    case 'YOUTUBE':
      return `https://www.youtube.com/channel/${account.remote_id}`;
    default:
      return null;
  }
};

export const openExternalPost = externalUrl => window.open(externalUrl);

export const openExternalPage = (accounts, linktokenId) => {
  // lookup full account info.
  const fullAccount = accounts.find(acct => acct.id === linktokenId);
  const externalUrl = getPageUrl(fullAccount);

  if (externalUrl) {
    window.open(externalUrl);
    return null;
  }

  return showToast('Unable to open link', 'error');
};
