import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ARTICLE_SEARCH_FILTERS = gql`
  query articleSearchFilters($enabled: Boolean) {
    articleSearchFilters(enabled: $enabled) {
      id
      is_detecting
      article_search {
        id
        name
      }
    }
  }
`;
