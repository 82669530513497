/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  InputLabel,
  Button,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  Check as CheckIcon,
  Info as InfoIcon,
  NotInterested as NoCheckIcon
} from '@material-ui/icons';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CONTAINER_USERS } from 'gql/container';
import { SECURITY_ROLES } from 'gql/securityRole';
import { UPDATE_ORGMEMBERSHIP_ROLE } from 'gql/orgMembership';
import HasAnyPriv from 'components/HasAnyPriv';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import Box from 'components/Box';
import { convertToUserTimeZone } from 'util/date';

const styles = makeStyles({
  tableHeading: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#6f6f6f'
  },
  tableBody: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#757575',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableRow: {
    height: '60px',
    padding: '0px 20px'
  }
});

const EditUserModal = props => {
  const { userData, refetch, containerId, closeCallback } = props;
  const [firstName] = useState(userData.firstName);
  const [lastName] = useState(userData.lastName);
  const [email] = useState(userData.email);
  const [role, setRole] = useState(userData.role);

  const { loading, error: queryError, data: queryData, refetch: queryRefetch } = useQuery(
    SECURITY_ROLES
  );
  const [updateRole, { error: updateError, data: updateData }] = useMutation(
    UPDATE_ORGMEMBERSHIP_ROLE
  );

  useEffect(() => {
    if (updateData) {
      refetch();
      closeCallback();
    }
  }, [updateData]);

  return (
    <Dialog open onClose={() => closeCallback()}>
      <Box p={30}>
        {loading && <AlbLoading />}
        {queryError && <AlbError error={queryError} refetch={queryRefetch} />}
        {queryData?.security_roles && (
          <>
            <Box mb={16}>
              <Typography variant="h6">Edit User</Typography>
            </Box>
            <HasAnyPriv privs={['USERS:UPDATE']} showAlert>
              <ValidatorForm
                onSubmit={() => {
                  updateRole({
                    variables: {
                      userId: userData.id,
                      containerId,
                      securityRoleId: role
                    }
                  });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel>First Name</InputLabel>
                    <TextValidator
                      disabled
                      fullWidth
                      name="firstName"
                      variant="filled"
                      placeholder="John"
                      value={firstName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Last Name</InputLabel>
                    <TextValidator
                      disabled
                      fullWidth
                      name="lastName"
                      variant="filled"
                      placeholder="Doe"
                      value={lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Email</InputLabel>
                    <TextValidator
                      disabled
                      fullWidth
                      name="email"
                      variant="filled"
                      placeholder="user@example.com"
                      value={email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Role</InputLabel>
                    <TextValidator
                      select
                      fullWidth
                      name="role"
                      variant="filled"
                      placeholder="Admin"
                      value={role}
                      onChange={event => setRole(event.target.value)}
                      validators={['required']}
                      errorMessages={['* Required']}
                    >
                      {queryData.security_roles.map(securityRole => {
                        return (
                          <MenuItem key={securityRole.id} value={parseInt(securityRole.id, 10)}>
                            <Tooltip title={securityRole.description}>
                              <div>{securityRole.name}</div>
                            </Tooltip>
                          </MenuItem>
                        );
                      })}
                    </TextValidator>
                  </Grid>
                  {(updateError || updateData === false) && (
                    <AlbError error={updateError || 'Update failed'} />
                  )}
                  <Grid item container>
                    <Button fullWidth type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </HasAnyPriv>
          </>
        )}
      </Box>
    </Dialog>
  );
};

EditUserModal.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
  closeCallback: PropTypes.func.isRequired
};

const UserTable = props => {
  const { currentUser, containerId } = props;
  const [editDialog, setEditDialog] = useState(false);
  const [editUser, setEditUser] = useState({});
  const classes = styles();

  const { loading, error, data, refetch } = useQuery(CONTAINER_USERS, {
    variables: {
      id: containerId
    }
  });

  if (loading) return <AlbLoading />;
  if (error) return <AlbError error={error} refetch={refetch} />;

  return (
    <>
      {editDialog && (
        <EditUserModal
          userData={editUser}
          refetch={refetch}
          closeCallback={() => setEditDialog(false)}
          containerId={containerId}
        />
      )}
      <Grid container alignItems="center" className={classes.tableRow}>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>First Name</Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>Last Name</Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>Role</Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>Email</Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>Created</Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={1}>
          <Tooltip
            title="This column shows if the user has multi-factor authentication 
            enabled on their account. Multi-factor authentication protects 
            your organization's accounts from password theft. Individual users can enable this on the
            Profile Page."
            placement="top"
          >
            <Typography className={classes.tableHeading}>
              MFA
              <InfoIcon fontSize="small" />
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={1}>
          <Typography className={classes.tableHeading}>Settings</Typography>
        </Grid>
      </Grid>
      {data?.container?.users?.length === 0 && (
        <Grid container justifyContent="center" alignItems="center" className={classes.tableRow}>
          <Typography>No Users Found</Typography>
        </Grid>
      )}
      {data?.container?.users &&
        data.container.users.map(user => {
          return (
            <Grid key={user.id} container alignItems="center" className={classes.tableRow}>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>{user.first_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>{user.last_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>{user.role.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>{user.email}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>
                  {convertToUserTimeZone(user.created_at, currentUser.time_zone).format('L LT')}
                </Typography>
              </Grid>
              <Grid item container justifyContent="center" alignItems="center" xs={1}>
                {user.otp_enabled && <CheckIcon style={{ color: '#006600' }} />}
                {!user.otp_enabled && <NoCheckIcon color="error" />}
              </Grid>
              <Grid item container justifyContent="center" alignItems="center" xs={1}>
                <IconButton
                  onClick={() => {
                    setEditDialog(true);
                    setEditUser({
                      id: user.id,
                      firstName: user.first_name,
                      lastName: user.last_name,
                      email: user.email,
                      role: user.role.id
                    });
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

UserTable.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  containerId: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(UserTable);
