/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Grid, Typography, Button, InputLabel, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import {
  SEARCH_CONVERSION_VALUES,
  CREATE_CONVERSION_EVENT_NAME,
  GET_ALL_AA_LINKTOKENS,
  GET_ALL_CONVERSION_EVENT_NAMES
} from 'gql/conversionEventName';
import { showToast } from 'contexts/ToastContext';
import AlbLoading from 'components/AlbLoading';
import { makeStyles } from '@material-ui/core/styles';
import handleGraphQLError from 'util/error';
import useDebounce from 'hooks/useDebounce';

const styles = makeStyles({
  autoCompleteRoot: {
    paddingTop: '0!important'
  }
});

/**
 * @method
 * @summary This component contains form that the user can create a persistent conversion value to detect on
 * @name AdobeAnalyticsMangePredictionEventsForm
 * @return {Object} - React JSX
 */
const AdobeAnalyticsMangePredictionEventsForm = () => {
  const classes = styles();
  const [linktokenId, setLinktokenId] = useState('');
  const [eventName, setEventName] = useState('');
  const [autocompleteQuery, setAutoCompleteQuery] = useState('');
  const [finalValue, setFinalValue] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const debouncedInput = useDebounce(autocompleteQuery, 500);

  const { data, error, loading } = useQuery(GET_ALL_AA_LINKTOKENS, {
    fetchPolicy: 'network-only'
  });

  const [
    getAutoCompleteOptions,
    { data: autocompleteData, error: autocompleteError, loading: autocompleteLoading }
  ] = useLazyQuery(SEARCH_CONVERSION_VALUES, {
    fetchPolicy: 'cache-first'
  });

  const [createConversionEventName, { error: createError }] = useMutation(
    CREATE_CONVERSION_EVENT_NAME,
    {
      refetchQueries: [{ query: GET_ALL_CONVERSION_EVENT_NAMES }],
      onCompleted: () => {
        showToast(
          `Conversion event has been created. It will take up to 24 hours to generate a prediction`,
          'success'
        );
      }
    }
  );

  useEffect(() => {
    if (debouncedInput) {
      getAutoCompleteOptions({
        variables: {
          linktoken_id: linktokenId,
          event_name: eventName.toLowerCase().trim(),
          event_value: autocompleteQuery.trim()
        }
      });
    }
  }, [debouncedInput]);

  useEffect(() => {
    if (autocompleteData?.searchConversionValues?.length) {
      const newOptions = autocompleteData?.searchConversionValues?.map(row => ({
        id: row.event_value,
        name: row.event_value
      }));

      setAutocompleteOptions(newOptions);
    } else {
      setAutocompleteOptions([]);
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (createError || error || autocompleteError) {
      handleGraphQLError(createError || error || autocompleteError);
    }
  }, [createError, error, autocompleteError]);

  if (loading) return <AlbLoading />;

  return (
    <>
      <ValidatorForm
        onSubmit={() => {
          createConversionEventName({
            variables: {
              linktoken_id: linktokenId,
              event_name: eventName.toLowerCase().trim(),
              event_value: finalValue.trim()
            }
          });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Create New Conversion Event to Predict</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Segment</InputLabel>
            <SelectValidator
              fullWidth
              variant="filled"
              value={linktokenId}
              onChange={event => setLinktokenId(event.target.value)}
              validators={['required']}
              errorMessages={['* Select a segment']}
            >
              {data?.getAllAALinkTokens?.map(({ id, remote_name }) => (
                <MenuItem key={id} value={id}>
                  {remote_name}
                </MenuItem>
              ))}
            </SelectValidator>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Metric</InputLabel>
            <TextValidator
              fullWidth
              variant="filled"
              placeholder="Metric, ie variables/evar4"
              value={eventName}
              onChange={event => setEventName(event.target.value)}
              validators={['required']}
              errorMessages={['* Metric is required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Event</InputLabel>
            <Autocomplete
              classes={{
                inputRoot: classes.autoCompleteRoot
              }}
              variant="filled"
              freeSolo
              getOptionLabel={option => option?.name ?? ''}
              options={autocompleteOptions}
              onChange={(event, value) => {
                setFinalValue(value?.id);
              }}
              onInputChange={e => {
                // when user begins to input, reset or clear
                setAutoCompleteQuery(e?.target?.value || '');
              }}
              renderInput={params => (
                <TextValidator
                  {...params}
                  placeholder="Event, ie header:click"
                  variant="filled"
                  value={finalValue}
                  validators={['required']}
                  errorMessages={['* Metric is required']}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={autocompleteLoading}
            >
              Create Conversion Prediction
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

AdobeAnalyticsMangePredictionEventsForm.propTypes = {
  history: PropTypes.shape().isRequired
};

export default withRouter(AdobeAnalyticsMangePredictionEventsForm);
