import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  closeButton: {
    width: '45px',
    height: '45px',
    backgroundColor: '#0D1A3A',
    color: 'white',

    '&:hover': {
      backgroundColor: '#3C5A99'
    },

    '@media print': {
      display: 'none'
    }
  }
});

const AnalyticsCloseButton = props => {
  const { history, onClose } = props;
  const classes = useStyles();

  const defaultOnClose = () => {
    history.goBack();
  };

  return (
    <>
      <IconButton onClick={onClose ?? defaultOnClose} className={classes.closeButton}>
        <Close />
      </IconButton>
    </>
  );
};

AnalyticsCloseButton.propTypes = {
  history: PropTypes.shape().isRequired,
  onClose: PropTypes.func
};

AnalyticsCloseButton.defaultProps = {
  onClose: null
};

export default withRouter(AnalyticsCloseButton);
