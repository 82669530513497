import { gql } from '@apollo/client';

export const GET_ALL_AA_LINKTOKENS = gql`
  query getAllAALinkTokens {
    getAllAALinkTokens {
      id
      remote_name
    }
  }
`;

export const GET_ALL_CONVERSION_EVENT_NAMES = gql`
  query getAllConversionEventNames {
    getAllConversionEventNames {
      id
      linked_account_id
      linktoken {
        remote_name
      }
      event_name
      event_value
    }
  }
`;

export const SEARCH_CONVERSION_VALUES = gql`
  query searchConversionValues($linktoken_id: ID!, $event_name: String!, $event_value: String!) {
    searchConversionValues(
      linktoken_id: $linktoken_id
      event_name: $event_name
      event_value: $event_value
    ) {
      event_value
    }
  }
`;

export const CREATE_CONVERSION_EVENT_NAME = gql`
  mutation($linktoken_id: ID!, $event_name: String!, $event_value: String!) {
    createConversionEventName(
      linktoken_id: $linktoken_id
      event_name: $event_name
      event_value: $event_value
    ) {
      event_value
    }
  }
`;

export const UPDATE_CONVERSION_EVENT_NAME_PERSISTENCE = gql`
  mutation($id: ID!, $isPersistent: Boolean!) {
    updateConversionEventNamePersistence(id: $id, isPersistent: $isPersistent) {
      id
      is_persistent
    }
  }
`;

export const DELETE_CONVERSION_EVENT_NAME = gql`
  mutation($id: ID!) {
    deleteConversionEventName(id: $id) {
      event_value
    }
  }
`;
