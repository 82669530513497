const fonts = {
  fontSize: '10px',
  lineHeight: '15px',
  fontFamily: 'Poppins',
  color: '#0A1734',
  fontWeight: 500
};

const graphHoverStyles = {
  root: {
    width: 'auto',
    height: 'auto'
  },
  root_no_pointer_events: {
    width: 'auto',
    height: 'auto',
    pointerEvents: 'none'
  },
  exitCursor: {
    cursor: 'zoom-out'
  },
  grid: {
    background: 'white',
    width: '100%',
    height: '100%',
    pointerEvents: 'auto'
  },
  grid_no_pointer_events: {
    background: 'white',
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  contentGrid: {
    padding: '7px'
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'right',
    marginLeft: '2px',
    marginRight: '2px',
    ...fonts
  },
  table: {
    width: 'auto',
    height: 'auto'
  },
  dataCell: {
    textAlign: 'right',
    ...fonts
  },
  headerCell: {
    textAlign: 'left',
    ...fonts
  },
  noDataText: {
    ...fonts,
    display: 'flex',
    height: '100%',
    width: 'auto',
    padding: '30px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  firstDataCell: {
    width: '60%',
    textAlign: 'left',
    ...fonts
  },
  date: {
    color: '#6f6f6f'
  },
  paper: {
    width: '560px',
    height: '140px',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.2)',
    pointerEvents: 'none',
    overflow: 'hidden'
  },
  loading: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  mouseOver: {
    cursor: 'pointer'
  }
};

export default graphHoverStyles;
