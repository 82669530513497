import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import { trimPreviewText } from 'util/social';
import colors from 'util/colors';

const PostContent = memo(({ title, timestamp, content, eventId, preview, togglePreview }) => {
  let croppedContent = null;
  const maxContentLength = 175;

  if (content?.length && content.length > maxContentLength) {
    croppedContent = trimPreviewText(content, maxContentLength);
  }

  return (
    <Box style={{ fontSize: '11.75px' }}>
      <Box mb={10}>
        <Typography style={{ fontSize: 'inherit', color: colors.pink, fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>
      <Box mb={10}>
        <Typography style={{ color: '#979797', fontSize: 'inherit' }}>{timestamp}</Typography>
      </Box>
      <Box
        onClick={() => {
          if (preview) {
            togglePreview([false, null]);
          } else {
            togglePreview([true, eventId]);
          }
        }}
      >
        {/* if user clicked on ellipsis, or the text is short enough not to get cropped, we show the full text body. */}
        {!croppedContent || preview ? content : croppedContent}
        &nbsp;
        {/* Only show ellipsis if cropped body is showing and post content isn't expanded. */}
        {croppedContent && !preview && '...'}
      </Box>
    </Box>
  );
}, isEqual);

PostContent.propTypes = {
  title: PropTypes.string,
  timestamp: PropTypes.string,
  content: PropTypes.string,
  eventId: PropTypes.number.isRequired,
  preview: PropTypes.bool,
  togglePreview: PropTypes.func.isRequired
};

PostContent.defaultProps = {
  title: '',
  timestamp: '',
  content: '',
  preview: false
};

export default PostContent;
