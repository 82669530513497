import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog } from '@material-ui/core';
import { partition } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_SALESFORCE_STATE_OPTION } from 'gql/salesforce';
import AnalyticsPlaceholder from 'components/AnalyticsPlaceholder';
import SubheaderTabs from 'components/AnalyticsHeader/SubheaderTabs';
import { tabs, salesforceTabs } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import Box from 'components/Box';
import {
  parseQueryString,
  setQueryStringForAnalyticsModal,
  deleteQueryStringParameter,
  goToRoute
} from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import { SALESFORCE_CARD_TITLE } from 'util/analyticsTitles';
import {
  getInitialAnalyticsStartDate,
  getInitialAnalyticsEndDate,
  checkValidDate
} from 'util/date';
import { AnalyticsPath } from 'util/paths';
import isFeatureVisible from 'util/isFeatureVisible';
import AnalyticsHeader from 'components/AnalyticsComponents/AnalyticsHeader';
import ContainerNav from 'components/ContainerNav';
import AnalyticsPageTitle from 'components/AnalyticsComponents/AnalyticsPageTitle';
import LinktokenSelector from 'components/LinktokenSelector';
import AnalyticsCloseButton from 'components/AnalyticsComponents/AnalyticsCloseButton';
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import DatePickerV2 from 'components/AnalyticsDatePicker/DatePickerV2';
import {
  ONE_MONTH,
  ONE_QUARTER,
  ONE_YEAR,
  LAST_90_DAYS,
  LAST_180_DAYS,
  LAST_365_DAYS
} from 'components/AnalyticsDatePicker/datePickerRanges';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';
import SalesforceOverview from './SalesforceOverview';
import SalesforceFunnel from './SalesforceFunnel';
import SalesforceTimeToEvent from './SalesforceTimeToEvent';
import SalesforceVolume from './SalesforceVolume';
import SalesforceDealSize from './SalesforceDealSize';
import StatusAndStageDropdown from './StatusAndStageDropdown';

const style = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  },
  closeButton: {
    position: 'absolute',
    top: '40px',
    right: '40px'
  }
});

const AnalyticsSalesforce = props => {
  const { currentUser, history, location, featureFlags } = props;

  const classes = style();

  const [activeLowerTab, setActiveLowerTab] = useState(tabs.overview);
  const [calRange, changeCalRange] = useState({
    start: getInitialAnalyticsStartDate(7, currentUser, 'days'),
    end: getInitialAnalyticsEndDate(currentUser)
  });

  const [openDropdown, setOpenDropdown] = useState(false);
  const [completeOptions, setCompleteOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dialogRef = useRef();
  const [selectedLinktokenId, setSelectedLinktokenId] = useState(null);
  const [selectedLinktoken, setSelectedLinktoken] = useState(null);
  const [selectedPeriodical, setSelectedPeriodical] = useState(null);

  const overviewTab = activeLowerTab === tabs.overview;
  const funnelTab = activeLowerTab === tabs.funnel;
  const timeToEventTab = activeLowerTab === tabs.timeToEvent;
  const dealSizeTab = activeLowerTab === tabs.dealSize;
  const volumeTab = activeLowerTab === tabs.volume;

  const { data } = useQuery(GET_SALESFORCE_STATE_OPTION, {
    variables: {
      linktokenId: selectedLinktokenId
    },
    fetchPolicy: 'network-only',
    skip: !funnelTab || selectedLinktokenId == null
  });

  useEffect(() => {
    if (data?.getSalesforceStateOption) {
      const { __typename, ...types } = data.getSalesforceStateOption;
      const tempOptions = Object.entries(types)
        .map(([type, options]) =>
          options.map(({ id, master_label: label }) => ({ id, name: label, type }))
        )
        .flat();

      setCompleteOptions(tempOptions);
    } else {
      setCompleteOptions([]);
    }
  }, [data]);

  // After component has mounted check the URL parameters for a different start/end date than the default
  useEffect(() => {
    setPageTitle(SALESFORCE_CARD_TITLE);

    if (location?.search) {
      const startDate = parseQueryString('start_date', location);
      const endDate = parseQueryString('end_date', location);

      if (startDate[0] && endDate[0]) {
        const newStartDate = new Date(moment(startDate[0]));
        const newEndDate = new Date(moment(endDate[0]));

        if (
          checkValidDate(newStartDate) &&
          checkValidDate(newEndDate) &&
          newStartDate < newEndDate
        ) {
          // If the dates do not match then set the date picker range to the dates from the URL
          if (calRange.start !== newStartDate || calRange.end !== newEndDate) {
            changeCalRange({ start: newStartDate, end: newEndDate });
          }
        }
      }
    }
  }, []);

  // Update URL parameters when selected accounts changes
  // Update URL parameters when date range changes
  useEffect(() => {
    setQueryStringForAnalyticsModal('start_date', [moment(calRange.start).format('YYYY-MM-DD')]);
    setQueryStringForAnalyticsModal('end_date', [moment(calRange.end).format('YYYY-MM-DD')]);
  }, [calRange]);

  const enabledTabs = salesforceTabs.filter(({ enabled, toggle: toggles }) => {
    if (toggles) {
      return toggles.every(toggle => isFeatureVisible(featureFlags, toggle) && enabled);
    }

    return enabled;
  });

  useEffect(() => {
    if (timeToEventTab || dealSizeTab || volumeTab) {
      deleteQueryStringParameter('start_date');
      deleteQueryStringParameter('end_date');
    }
  }, [activeLowerTab]);

  const clearSelection = () => {
    setSelectedOptions([]);
  };

  const [statuses, stages] = partition(selectedOptions, ({ type }) => type === 'statuses');

  return (
    <Dialog fullScreen open classes={{ paperFullScreen: classes.root }} ref={dialogRef}>
      <Grid container justifyContent="center">
        <Grid container>
          <AnalyticsHeader>
            <Grid container justifyContent="space-between" alignItems="flex-start">
              <Box display="flex">
                <Box mr={20}>
                  <ContainerNav
                    startingContainer={currentUser.home_container_id}
                    handleContainerChange={() => {
                      setSelectedLinktokenId(null);
                      setSelectedLinktoken(null);
                    }}
                    analytics
                  />
                </Box>

                <AnalyticsPageTitle title={SALESFORCE_CARD_TITLE} />

                <Box display="flex" mr={30} ml={30}>
                  <div style={{ width: '200px' }}>
                    <LinktokenSelector
                      useDefault
                      isMulti={false}
                      filterTypes={[LINKTOKEN_TYPE_ENUM.SALESFORCE]}
                      onChange={event => {
                        if (event) {
                          setSelectedLinktokenId(event?.id);
                          setSelectedLinktoken(event?.linktoken);
                        } else {
                          setSelectedLinktokenId(null);
                          setSelectedLinktoken(null);
                        }
                        clearSelection();
                      }}
                    />
                  </div>
                  {funnelTab && (
                    <Box ml={30}>
                      <StatusAndStageDropdown
                        options={completeOptions}
                        openDropdown={openDropdown}
                        setOpenDropdown={setOpenDropdown}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        clearSelection={clearSelection}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              {overviewTab && (
                <DatePickerV2
                  handleDateSelect={event => {
                    changeCalRange({
                      start: event.start,
                      end: event.end
                    });

                    if (event?.periodical) {
                      setSelectedPeriodical(event.periodical);
                    }
                  }}
                  ranges={[
                    ONE_MONTH,
                    ONE_QUARTER,
                    ONE_YEAR,
                    LAST_90_DAYS,
                    LAST_180_DAYS,
                    LAST_365_DAYS
                  ]}
                />
              )}
              {funnelTab && (
                <AnalyticsDatePicker
                  disableMargin
                  setDateRange={changeCalRange}
                  calRange={calRange}
                  defaultButtonLabel="7"
                  delta
                />
              )}

              <Box className={classes.closeButton}>
                <AnalyticsCloseButton onClose={() => goToRoute(AnalyticsPath, history)} />
              </Box>
            </Grid>
          </AnalyticsHeader>
        </Grid>
        <SubheaderTabs
          tabValues={enabledTabs}
          activeTab={activeLowerTab}
          callback={setActiveLowerTab}
        />
        <Grid item xs={9}>
          <Box my={50}>
            {overviewTab && (
              <>
                {selectedLinktokenId !== null && (
                  <SalesforceOverview
                    linktokenId={selectedLinktokenId}
                    dates={calRange}
                    selectedPeriodical={selectedPeriodical}
                    statuses={statuses.map(({ name }) => name)}
                    stages={stages.map(({ name }) => name)}
                  />
                )}
                {selectedLinktokenId == null && (
                  <AnalyticsPlaceholder
                    history={history}
                    accountsButton={false}
                    text="You must first link or select an account to display analytics data."
                  />
                )}
              </>
            )}
            {funnelTab && (
              <>
                {statuses?.length > 1 && stages?.length > 1 && selectedLinktokenId !== null && (
                  <SalesforceFunnel
                    linktokenId={selectedLinktokenId}
                    dates={calRange}
                    statuses={statuses.map(({ name }) => name)}
                    stages={stages.map(({ name }) => name)}
                  />
                )}
                {selectedLinktokenId == null && (
                  <AnalyticsPlaceholder
                    history={history}
                    accountsButton={false}
                    text="You must first link or select an account to display analytics data."
                  />
                )}
                {selectedLinktokenId !== null && (statuses?.length <= 1 || stages?.length <= 1) && (
                  <AnalyticsPlaceholder
                    history={history}
                    accountsButton={false}
                    text="You must select at least 2 statuses and 2 stages to display analytics data."
                  />
                )}
              </>
            )}
            {timeToEventTab && <SalesforceTimeToEvent linktokenId={selectedLinktokenId} />}
            {dealSizeTab && <SalesforceDealSize account={selectedLinktoken} />}
            {volumeTab && <SalesforceVolume account={selectedLinktoken} />}
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AnalyticsSalesforce.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    featureFlags: state.auth.featureFlags
  };
};

export default connect(mapStateToProps)(AnalyticsSalesforce);
