import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { TextValidator } from 'react-material-ui-form-validator';

class DatePicker extends Component {
  // TODO: test that the render function gives back the right stuff.
  // TODO: test that the handleDateChange function is being called correctly
  renderInput = props => {
    if (props.label) {
      return (
        <TextValidator
          InputLabelProps={{ shrink: true }}
          {...props}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <CalendarToday size="small" />
              </InputAdornment>
            )
          }}
        />
      );
    }

    const newProps = Object.assign({}, props, { onClick: null });

    return (
      <TextValidator
        {...newProps}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday size="small" />
            </InputAdornment>
          )
        }}
      />
    );
  };

  render() {
    const {
      value,
      handleDateChange,
      variant,
      name,
      label,
      isValidDate,
      disabled,
      placeholder,
      timestamp,
      timeZone,
      style
    } = this.props;
    return (
      <Datetime
        // Any props passed here will be delegated to the renderInput function above
        inputProps={{
          variant,
          name,
          label,
          fullWidth: true,
          readOnly: true,
          disabled,
          placeholder,
          style
        }}
        renderInput={this.renderInput}
        value={value}
        theme={style}
        timeFormat={timestamp}
        displayTimeZone={timeZone}
        isValidDate={isValidDate}
        onChange={momentObject => {
          if (name) {
            handleDateChange({
              date: momentObject.toISOString(),
              momentObject,
              target: name
            });
          } else {
            handleDateChange(momentObject);
          }
        }}
      />
    );
  }
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleDateChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  isValidDate: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.shape(),
  timestamp: PropTypes.bool,
  timeZone: PropTypes.string
};

DatePicker.defaultProps = {
  value: null,
  variant: 'filled',
  name: null,
  label: null,
  isValidDate: null,
  disabled: false,
  placeholder: null,
  style: null,
  timestamp: true,
  timeZone: null
};

export default DatePicker;
