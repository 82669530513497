import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog } from '@material-ui/core';
import AnalyticsHeader from 'components/AnalyticsHeader';
import AnalyticsPlaceholder from 'components/AnalyticsPlaceholder';
import SubheaderTabs from 'components/AnalyticsHeader/SubheaderTabs';
import { tabs, webAndAppsSubTabs } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import Box from 'components/Box';
import { parseQueryString, setQueryStringForAnalyticsModal, goToRoute } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import {
  getInitialAnalyticsStartDate,
  getInitialAnalyticsEndDate,
  checkValidDate
} from 'util/date';
import { AnalyticsPath } from 'util/paths';
import isFeatureVisible from 'util/isFeatureVisible';
import WebOverview from './WebOverview';
import WebDemographics from './WebDemographics';
import WebSources from './WebSources';
import WebSearch from './WebSearch';
import WebChannels from './WebChannels';
import WebApps from './WebApps';
import WebConversions from './WebConversions';

const style = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: '0px'
  }
});

const AnalyticsWeb = props => {
  const { currentUser, history, location, featureFlags } = props;

  const classes = style();

  const [activeLowerTab, setActiveLowerTab] = useState(tabs.overview);
  const [selectedAccounts, setSelectedAccounts] = useState([]); // This is the list of all selected accounts within the drop down
  const [placeholder, setPlaceholder] = useState(false); // this is set to true if no accounts are linked within the current container
  // dates are passed into graphs separately and are updated through this state call.
  const [calRange, changeCalRange] = useState({
    start: getInitialAnalyticsStartDate(7, currentUser, 'days'),
    end: getInitialAnalyticsEndDate(currentUser)
  });
  const dialogRef = useRef();

  // After component has mounted check the URL parameters for a different start/end date than the default
  useEffect(() => {
    setPageTitle('Web & Apps Analytics');

    if (location?.search) {
      const startDate = parseQueryString('start_date', location);
      const endDate = parseQueryString('end_date', location);

      if (startDate[0] && endDate[0]) {
        const newStartDate = new Date(moment(startDate[0]));
        const newEndDate = new Date(moment(endDate[0]));

        if (
          checkValidDate(newStartDate) &&
          checkValidDate(newEndDate) &&
          newStartDate < newEndDate
        ) {
          // If the dates do not match then set the date picker range to the dates from the URL
          if (calRange.start !== newStartDate || calRange.end !== newEndDate) {
            changeCalRange({ start: newStartDate, end: newEndDate });
          }
        }
      }
    }
  }, []);

  // Update URL parameters when selected accounts changes
  // Update URL parameters when date range changes
  useEffect(() => {
    if (selectedAccounts?.length) {
      setQueryStringForAnalyticsModal('account', [selectedAccounts.map(({ id }) => id)]);
    }

    setQueryStringForAnalyticsModal('start_date', [moment(calRange.start).format('YYYY-MM-DD')]);
    setQueryStringForAnalyticsModal('end_date', [moment(calRange.end).format('YYYY-MM-DD')]);
  }, [selectedAccounts, calRange]);

  const enabledTabs = webAndAppsSubTabs.filter(({ enabled, toggle: toggles }) => {
    if (toggles) {
      return toggles.every(toggle => isFeatureVisible(featureFlags, toggle) && enabled);
    }

    return enabled;
  });

  const appStoresTab = activeLowerTab === tabs.apps;

  return (
    <Dialog fullScreen open classes={{ paperFullScreen: classes.root }} ref={dialogRef}>
      <Grid container justifyContent="center">
        <AnalyticsHeader
          displayTitle="Web & Apps"
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
          setPlaceholder={setPlaceholder}
          calRange={calRange}
          changeCalRange={changeCalRange}
          onClose={() => goToRoute(AnalyticsPath, history)}
          dialogRef={dialogRef}
          location={location}
          delta
          web
          appStores={appStoresTab}
        />
        <SubheaderTabs
          tabValues={enabledTabs}
          activeTab={activeLowerTab}
          callback={setActiveLowerTab}
        />
        <Grid item xs={9}>
          <Box my={50}>
            {selectedAccounts?.length > 0 && (
              <>
                {activeLowerTab === tabs.overview && (
                  <WebOverview accounts={selectedAccounts} dates={calRange} />
                )}
                {activeLowerTab === tabs.demographics && (
                  <WebDemographics accounts={selectedAccounts} dates={calRange} />
                )}
                {activeLowerTab === tabs.sources && (
                  <WebSources accounts={selectedAccounts} dates={calRange} />
                )}
                {activeLowerTab === tabs.search && (
                  <WebSearch accounts={selectedAccounts} dates={calRange} />
                )}
                {activeLowerTab === tabs.channels && (
                  <WebChannels accounts={selectedAccounts} dates={calRange} />
                )}
                {activeLowerTab === tabs.apps && (
                  <WebApps accounts={selectedAccounts} dates={calRange} />
                )}
                {activeLowerTab === tabs.conversions && (
                  <WebConversions accounts={selectedAccounts} />
                )}
              </>
            )}
            {placeholder && <AnalyticsPlaceholder history={history} />}
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AnalyticsWeb.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    featureFlags: state.auth.featureFlags
  };
};

export default connect(mapStateToProps)(AnalyticsWeb);
