import React, { useEffect, useState, useContext } from 'react';
import colors from 'util/colors';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { showToast } from 'contexts/ToastContext';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import { AlbTooltip } from 'components/AlbTooltip';
import EditInPlaceInput from 'components/EditInPlaceInput';
import DeleteActionAttentionUGCLabelModal from 'components/AlembicModalConfirm/DeleteActionAttentionUGCLabelModal';
import DashboardUnderstandDrawerContext from 'contexts/DashboardUnderstandDrawerContext';
import {
  ATTENTION_ACTION_EVENT_TABLE,
  ATTENTION_ACTION_CREATE_UGC_LABEL,
  ATTENTION_ACTION_UPDATE_UGC_LABEL
} from 'gql/attentionAction';
import { getAttentionActionTitle } from 'util/detectedEvents';
import handleGraphQLError from 'util/error';
import { UGC_EDITABLE_LABEL_EVENT_TYPES, refetchQueries } from 'views/DashboardUnderstand/util';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%'
  },
  card: {
    width: '100%',
    position: 'relative',
    background: '#FFFFFF',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  },
  tabOverRide: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    whiteSpace: 'nowrap',
    minHeight: '64px',
    fontSize: 18,
    fontWeight: 500,
    borderBottom: '2px solid #4c6087'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  text: {
    fontWeight: '500',
    fontSize: '13px'
  },
  positive: {
    color: colors.green
  },
  negative: {
    color: colors.red
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    wordBreak: 'break-word',
    flex: 1
  },
  tableHeaderCell: {
    display: 'flex',
    alignItems: 'center'
  },
  tableHeader: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginLeft: '10px'
  }
});

const tableThemeOverrides = {
  MuiTableBody: {
    root: {
      '& td:nth-child(n+2):nth-child(-n+5)': {
        backgroundColor: 'rgb(0 0 0 / 3%)'
      }
    }
  },
  MuiTableCell: {
    root: {
      padding: '16px 0px 16px 12px',
      borderBottom: 'none',

      '&:first-child': {
        paddingRight: '12px'
      }
    }
  },
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap',

      '&:first-child': {
        width: '188px'
      }
    },
    sortAction: {
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  }
};

/**
 * Renders a table breaking down the events for the selected mix_group
 * @name AttentionActionBreakdownTable
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const ProgramLiftBreakDownTable = props => {
  const {
    selectedMixGroup,
    selectedDates,
    selectedPeriodical,
    isSwitchChecked,
    inProgramLiftReport,
    programLiftReportId
  } = props;
  const drawerContext = useContext(DashboardUnderstandDrawerContext);

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [after, setAfter] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState(['contribution_score', 'desc']);
  const classes = useStyles();
  const rowsPerPageOptions = [];

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUGC, setSelectedUGC] = useState({});

  const { loading, error, data } = useQuery(ATTENTION_ACTION_EVENT_TABLE, {
    variables: {
      mixGroup: selectedMixGroup,
      endDate: selectedDates?.end?.toISOString(),
      periodical: selectedPeriodical,
      after: after * rowsPerPage,
      count: rowsPerPage,
      orderBy: sortOrder,
      hideDimensions: isSwitchChecked,
      inProgramLiftReport,
      programLiftReportId: parseInt(programLiftReportId, 10),
      showFilteredEvents: false
    },
    fetchPolicy: 'network-only',
    skip: programLiftReportId == null || selectedDates == null || selectedPeriodical == null
  });

  const [
    createUgcLabel,
    { loading: createLoading, error: createError, data: createData }
  ] = useMutation(ATTENTION_ACTION_CREATE_UGC_LABEL, { refetchQueries });

  const [
    updateUgcLabel,
    { loading: updateLoading, error: updateError, data: updateData }
  ] = useMutation(ATTENTION_ACTION_UPDATE_UGC_LABEL, { refetchQueries });

  useEffect(() => {
    let tempTableData = [];
    let tempCount = 0;

    if (data?.attentionActionEventTable.rows?.length > 0) {
      const attentionActionEventTable = data?.attentionActionEventTable;

      tempTableData = attentionActionEventTable?.rows.map(event => {
        return {
          id: event.id,
          event: event.event_label
            ? `${event.event_label} (${getAttentionActionTitle(event.event_type)})`
            : getAttentionActionTitle(event.event_type),
          ugc_label: event.ugc_label,
          event_type: event.event_type,
          occurrence_score: event.occurrence_score,
          contribution_score: event.contribution_score,
          variation_score: event.variation_score,
          magnitude_score: event.magnitude_score,
          impact_score: Math.round(event.impact_score * 100).toLocaleString(),
          leverage_score: Math.round(event.leverage_score * 100).toLocaleString(),
          percent_change: event.percent_change === null ? '-' : event.percent_change,
          change: event.change === null ? '-' : Math.round(event.change).toLocaleString(),

          // for drawer access
          associations: event.associations,
          ...event.graphData
        };
      });
      tempCount = attentionActionEventTable?.count;
    }

    setTableData(tempTableData);
    setCount(tempCount);
  }, [data]);

  useEffect(() => {
    if (createData?.attentionActionCreateUgcLabel) {
      showToast(createData.attentionActionCreateUgcLabel.message, 'success');
    }
  }, [createData]);

  useEffect(() => {
    if (updateData?.attentionActionUpdateUgcLabel) {
      showToast(updateData.attentionActionUpdateUgcLabel.message, 'success');
    }
  }, [updateData]);

  useEffect(() => {
    if (createError || updateError) {
      handleGraphQLError(createError || updateError);
    }
  }, [createError, updateError]);

  const customTruncateRender = (text, { columnIndex, rowData }) => {
    const [actionAttentionId] = rowData;
    const ugcLabel = rowData[columnIndex + 1];
    const value = ugcLabel || text;

    const eventType = rowData[columnIndex + 2] ? rowData[columnIndex + 2] : '0';

    // enable event_labels to be editable only when show dimensions is toggled on
    const editableType = isSwitchChecked
      ? false
      : UGC_EDITABLE_LABEL_EVENT_TYPES.includes(eventType);

    return (
      <Tooltip title={text}>
        <Typography
          variantMapping={{ body1: 'span' }}
          className={`${classes.text} ${classes.truncateText}`}
          style={{ textAlign: 'left' }}
        >
          {editableType ? (
            <EditInPlaceInput
              customTitleStyle={`${classes.text} ${classes.truncateText}`}
              useEdit
              useIcons
              useDelete={ugcLabel}
              value={value}
              onChange={event => {
                if (event.value) {
                  if (ugcLabel) {
                    updateUgcLabel({
                      variables: { actionAttentionId, title: event.value }
                    });
                  } else {
                    createUgcLabel({
                      variables: { actionAttentionId, title: event.value }
                    });
                  }
                }

                if (event.delete) {
                  setIsDeleteModalOpen(true);
                  setSelectedUGC({ actionAttentionId, ugcLabel, text });
                }
              }}
            />
          ) : (
            text
          )}
        </Typography>
      </Tooltip>
    );
  };

  const customPercentRender = (percentChange, { columnIndex, rowData }) => {
    const absoluteChange = rowData[columnIndex + 1] ? rowData[columnIndex + 1] : '0';

    return (
      <Typography
        className={`${classes.change} ${percentChange > 0 ? classes.positive : ''}${
          percentChange < 0 ? classes.negative : ''
        }`}
        align="right"
      >
        {`${percentChange}% (${absoluteChange})`}
      </Typography>
    );
  };

  const customValueRender = value => (
    <Typography className={classes.text} style={{ textAlign: 'center' }}>
      {value == null ? '-' : value}
    </Typography>
  );

  const customPercentColRender = () => {
    return (
      <div className={classes.tableHeaderCell}>
        <AlbTooltip lookupKey="Change" prefix="opt" />
        <Typography className={classes.tableHeader} align="right">
          % Change <br /> (Impact)
        </Typography>
      </div>
    );
  };

  const customHeadRender = tableMeta => {
    const tooltip = {
      event: 'Event',
      occurrence_score: 'Occurrence',
      contribution_score: 'Contribution',
      variation_score: 'Variation',
      magnitude_score: 'Magnitude',
      impact_score: 'ImpactScore',
      leverage_score: 'LeverageScore'
    }[tableMeta.name];

    return (
      <div className={classes.tableHeaderCell} key={tableMeta.label}>
        <AlbTooltip lookupKey={tooltip} prefix="opt" />
        <Typography className={classes.tableHeader}>{tableMeta.label}</Typography>
      </div>
    );
  };

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'event',
      label: 'EVENT',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customTruncateRender,
        sort: false
      }
    },
    {
      name: 'ugc_label',
      options: { display: false }
    },
    {
      name: 'event_type',
      options: { display: false }
    },
    {
      name: 'occurrence_score',
      label: 'OCCURRENCE',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender,
        sort: true
      }
    },
    {
      name: 'contribution_score',
      label: 'CONTRIBUTION',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender,
        sort: true
      }
    },
    {
      name: 'variation_score',
      label: 'VARIATION',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender,
        sort: true
      }
    },
    {
      name: 'magnitude_score',
      label: 'MAGNITUDE',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender,
        sort: true
      }
    },
    {
      name: 'impact_score',
      label: 'IMPACT SCORE',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender,
        sort: true
      }
    },
    {
      name: 'percent_change',
      label: 'CHANGE',
      options: {
        customHeadLabelRender: customPercentColRender,
        customBodyRender: customPercentRender,
        sort: true
      }
    },
    {
      name: 'change',
      options: { display: false }
    },
    {
      name: 'leverage_score',
      label: 'LEVERAGE SCORE',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender,
        sort: true
      }
    }
  ];

  const onCellClick = (_, { rowIndex, colIndex }) => {
    const eventColIndex = 1;
    const event = tableData[rowIndex];

    if (colIndex !== eventColIndex) {
      drawerContext.toggleDrawer({
        isDrawerOpen: true,
        startDate: selectedDates?.start,
        endDate: selectedDates?.end,
        isSwitchChecked,
        event: { ...event, selectedMixGroup }
      });
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setAfter,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page: after,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    onCellClick
  };

  return (
    <div className={classes.card}>
      {(loading || createLoading || updateLoading) && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}

      <div className={classes.container}>
        <AlbTable
          tableData={tableData}
          tableColumns={tableColumns}
          tableOptions={tableOptions}
          tableThemeOverrides={tableThemeOverrides}
        />
      </div>

      {isDeleteModalOpen && (
        <DeleteActionAttentionUGCLabelModal
          selectedUGC={selectedUGC}
          isModalOpen={isDeleteModalOpen}
          onChange={e => setIsDeleteModalOpen(e.isModalOpen)}
        />
      )}
    </div>
  );
};

ProgramLiftBreakDownTable.propTypes = {
  selectedMixGroup: PropTypes.string,
  selectedDates: PropTypes.shape(),
  selectedPeriodical: PropTypes.string,
  isSwitchChecked: PropTypes.bool,
  inProgramLiftReport: PropTypes.bool,
  programLiftReportId: PropTypes.string
};

ProgramLiftBreakDownTable.defaultProps = {
  selectedMixGroup: null,
  selectedDates: null,
  selectedPeriodical: null,
  isSwitchChecked: null,
  inProgramLiftReport: null,
  programLiftReportId: null
};

export default ProgramLiftBreakDownTable;
