import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Dialog,
  Grid,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import isFunction from 'util/isFunction';
import colors from 'util/colors';
import { convertToUserTimeZone } from 'util/date';
import DatePicker from 'components/DatePicker';
import FIELDS from 'components/AnalyticsShareOfVoice/shareOfVoiceConsts';

/**
 * @file Modal form used to add/edit data using defined fields and current object.
 *
 * @module AlembicModalForm
 * @extends Component
 *
 * @example <caption>Modal form for adding a new object</caption>
 * <AlembicModalForm
 *   saveTitle="New Campaign"
 *   cancelTitle="Cancel"
 *   open={openModalForm}
 *   onClose={this.handleClose}
 *   handleConfirm={this.handleSave}
 *   fields={fields}
 * />
 *
 * @example <caption>Modal form for editing an existing object</caption>
 * <AlembicModalForm
 *   saveTitle="Update Campaign"
 *   cancelTitle="Cancel"
 *   open={openModalForm}
 *   onClose={this.handleClose}
 *   handleConfirm={this.handleSave}
 *   fields={fields}
 *   currentObject={selectedCampaign}
 * />
 */

const useStyles = makeStyles({
  title: {
    marginTop: '5px',
    fontWeight: 500,
    color: colors.navy,
    fontFamily: 'Poppins'
  },
  paper: {
    // overflowY: 'unset' // this has weird behavior when the screen is minimized and loses the scroll.
  },
  static: {
    margin: '9px 0px'
  },
  warning: {
    color: '#585858'
  },
  mediumGrid: {
    width: '459px'
  },
  delete: {
    '& label': {
      fontSize: '18px',
      marginLeft: '0px'
    },

    '& .footer': {
      justifyContent: 'flex-end',

      '& button': {
        padding: '8px 24px'
      },

      '& .cancel': {
        border: '1px solid #9E9E9E',
        color: '#898787'
      },

      '& .confirm': {
        border: `1px solid ${colors.red}`,
        backgroundColor: colors.red,
        marginLeft: '20px'
      }
    }
  },
  inputLabel: {
    marginLeft: '0px',
    marginRight: 'auto'
  },
  note: {
    color: '#585858',
    fontSize: '13px',
    fontFamily: 'Poppins'
  },
  bold: {
    fontWeight: 600
  },
  middleButton: {
    background: '#1DA1F2',
    borderRadius: '3px',
    color: 'white',
    '&:hover': {
      background: '#0A1734'
    }
  }
});

const SUBMITTER = {
  CONFIRM: 'confirm',
  MIDDLE_BUTTON: 'middleButton'
};

const AlembicModalForm = props => {
  const {
    title,
    note,
    saveTitle,
    cancelTitle,
    open,
    onClose,
    handleConfirm,
    updateFields,
    handleCancel,
    fields,
    handleRemovedFields,
    currentObject,
    variant,
    userTimeZone,
    customComponent,
    middleActionButton,
    middleActionTitle,
    handleMiddleButtonClick,
    middleButtonStyle
  } = props;

  const classes = useStyles();

  const [newObject, setNewObject] = useState(currentObject);
  const [disableForm, toggleDisableForm] = useState(false);
  const [formSubmitter, setSubmitter] = useState(SUBMITTER.CONFIRM);

  // In order to update the form when the object has changed, we need to set the new object here
  useEffect(() => {
    setNewObject(currentObject);
  }, [currentObject]);

  // set Submit button to enabled state.
  useEffect(() => {
    toggleDisableForm(false);
  }, [open]);

  const handleInput = event => {
    const updatedValues = { ...newObject, [event.target.name]: event.target.value };

    // special case for Competitive Intelligence reports - once the primary report is selected, remove it from the second dropdown.
    if (event.target.name === FIELDS.PRIMARY_REPORT_ID) {
      handleRemovedFields(event.target.value);
    }

    setNewObject(updatedValues);
    updateFields(updatedValues);
  };

  const handleDateInput = event => {
    if (
      event.target === 'start_date' &&
      // eslint-disable-next-line camelcase
      newObject?.end_date &&
      event.momentObject.isAfter(convertToUserTimeZone(newObject.end_date, userTimeZone))
    ) {
      setNewObject({ ...newObject, [event.target]: event.date, end_date: null });
    } else {
      setNewObject({ ...newObject, [event.target]: event.date });
    }
  };

  const handleChecked = event => {
    const updatedValues = { ...newObject, [event.target.name]: event.target.checked };

    setNewObject(updatedValues);
  };

  const handleCheckedMultiple = event => {
    let currentChecked = [];

    if (newObject && newObject[event.target.name]) {
      currentChecked = newObject[event.target.name];
    }

    let updatedCheckboxValues = {};
    // uncheck if already included
    if (currentChecked.includes(event.target.value)) {
      const idxToRemove = currentChecked.indexOf(event.target.value);
      currentChecked.splice(idxToRemove, 1);

      updatedCheckboxValues = { ...newObject, [event.target.name]: currentChecked };
    } else {
      updatedCheckboxValues = {
        ...newObject,
        [event.target.name]: [...currentChecked, event.target.value]
      };
    }

    setNewObject(updatedCheckboxValues);
  };

  const isEndDateValid = current => {
    return current.isAfter(convertToUserTimeZone(newObject.start_date, userTimeZone));
  };

  const overrideHandleCancel = () => {
    if (isFunction(handleCancel)) {
      handleCancel();
    }

    onClose();
  };

  const handleSubmit = e => {
    if (disableForm) {
      e.preventDefault();
      return;
    }

    switch (formSubmitter) {
      case SUBMITTER.MIDDLE_BUTTON:
        handleMiddleButtonClick(newObject);
        break;
      default:
        handleConfirm(newObject);
    }
  };

  const renderSelectValue = (value, field) =>
    value.map((id, i) => {
      const option = field.options.find(el => el.value === id);

      if (option) {
        if (i === value.length - 1) {
          return option.name;
        }
        return `${option.name}, `;
      }

      return [];
    });

  const inputType = (field, index, value) => {
    let input = <div />;

    switch (field.type) {
      case 'dropdown':
        input = (
          <SelectValidator
            key={`sv-${index}`}
            variant="outlined"
            fullWidth
            value={value}
            name={field.fieldName}
            validators={field.validators}
            errorMessages={field.validationError || [`${field.title} is required`]}
            onChange={handleInput}
            disabled={field.disabled}
          >
            {field.options?.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </SelectValidator>
        );
        break;
      case 'checkboxDropdown':
        input = (
          <SelectValidator
            key={`sv-${index}`}
            variant="outlined"
            fullWidth
            SelectProps={{ multiple: true, renderValue: val => renderSelectValue(val, field) }}
            value={value || []}
            multiple
            name={field.fieldName}
            id={field.fieldName}
            validators={field.validators}
            errorMessages={field.validationError || [`${field.title} is required`]}
          >
            {field.options.map(option => (
              <FormControlLabel
                style={{ marginLeft: '10px', display: 'flex', direction: 'column' }}
                key={`sv-${field.fieldName}-${option.value}`}
                value={option.value}
                control={
                  <Checkbox
                    checked={
                      (newObject && newObject[field.fieldName]?.includes(option.value)) ?? false
                    }
                    name={field.fieldName}
                    value={option.value || option.id}
                    onChange={handleCheckedMultiple}
                  />
                }
                label={option.name}
              />
            ))}
          </SelectValidator>
        );
        break;
      case 'checkbox':
        input = (
          <Checkbox
            checked={value || false}
            name={field.fieldName}
            onChange={handleChecked}
            key={`cb-${field.fieldName}`}
          />
        );
        break;
      case 'datepicker':
        input = (
          <DatePicker
            key={`dp-${field.fieldName}`}
            name={field.fieldName}
            value={value ? convertToUserTimeZone(value, userTimeZone).format('L LT') : null}
            validators={field.validators}
            errorMessages={field.validationError || [`${field.title} is required`]}
            handleDateChange={handleDateInput}
            isValidDate={field.fieldName === 'end_date' ? isEndDateValid : null}
            timeZone={userTimeZone}
          />
        );
        break;
      case 'static':
        input = (
          <Grid container className={classes.static} key={`static-${field.fieldName}`}>
            <Typography>{value}</Typography>
          </Grid>
        );
        break;
      case 'warning':
        input = (
          <Grid container key={`warn-${field.fieldName}`}>
            <Typography className={classes.warning}>{field.warning}</Typography>
          </Grid>
        );
        break;
      case 'custom':
        input = (
          <Grid key={`gr-${field.fieldName}`} container>
            {customComponent || field.component}
          </Grid>
        );
        break;
      default:
        input = (
          <TextValidator
            key={`tv-${field.fieldName}`}
            autoFocus={index === 0}
            fullWidth
            multiline={field.type === 'textarea'}
            rows={field.type === 'textarea' ? 4 : 1}
            name={field.fieldName}
            disabled={field.disabled}
            variant="filled"
            value={value || ''}
            validators={field.validators}
            errorMessages={field.validationError || [`${field.title} is required`]}
            placeholder={field.placeholder || ''}
            onChange={handleInput}
          />
        );
        break;
    }

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        item
        key={`g3-${field.fieldName}`}
      >
        <InputLabel className={classes.inputLabel} key={`il-${field.fieldName}`}>
          {field.title}
        </InputLabel>
        <div style={{ width: '100%' }} key={`input-div-${index}`}>
          {input}
        </div>
      </Grid>
    );
  };

  const inputFields = (field, index) => {
    // Loop over the array of fields and set the default values from the passed object if one was provided
    let value = '';

    const hasFieldProperty =
      newObject && Object.prototype.hasOwnProperty.call(newObject, field.fieldName);

    if (hasFieldProperty) {
      value = newObject[field.fieldName];
    }

    return inputType(field, index, value);
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <div style={{ padding: '20px', minWidth: '300px' }}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid className={classes[variant]} container direction="column" spacing={2}>
            {title && (
              <Grid item>
                <Typography paragraph className={classes.title} variant="h3">
                  {title}
                </Typography>
              </Grid>
            )}
            {note && (
              <Grid item>
                <span className={`${classes.note} ${classes.bold}`}>Please note: </span>
                <span className={classes.note}>{note}</span>
              </Grid>
            )}
            {fields.map(inputFields)}
            <Grid
              className="footer"
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                className="cancel"
                type="button"
                variant="text"
                onClick={overrideHandleCancel}
              >
                {cancelTitle}
              </Button>

              {middleActionButton && (
                <Button
                  className={!middleButtonStyle ? classes.middleButton : null}
                  style={middleButtonStyle || null}
                  variant="contained"
                  type="submit"
                  onClick={() => setSubmitter(SUBMITTER.MIDDLE_BUTTON)}
                >
                  {middleActionTitle}
                </Button>
              )}

              <Button
                className="confirm"
                color="primary"
                type="submit"
                variant="contained"
                disabled={disableForm}
                onClick={() => setSubmitter(SUBMITTER.CONFIRM)}
              >
                {saveTitle}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </Dialog>
  );
};

/**
 * @name AlembicModalForm propTypes
 * @type {propTypes}
 * @param {Object} props - React PropTypes
 * @property {Object[]} fields - Array of field objects to use in the form
 * @property {Bool} open - Trigger the modal show/hide
 * @property {Function} onClose - Function to call when the modal is closed
 * @property {Function} handleConfirm - Function to call when the save/edit button is clicked
 * @property {String} title - title of the modal
 * @property {String} note - additional note below the title
 * @property {String} [saveTitle=Save] - Title for the save button
 * @property {String} [cancelTitle=Cancel] - Title for the cancel button
 * @property {Function} [handleCancel=null] - Function to call when the cancel button is clicked
 * @property {Object} [currentObject=null] - If the modal is used for editing, use this object to determine the default values
 * @property {String} [variant=''] - The value is used to determine the style based on object key
 * @property {String} userTimeZone - The current user's time zone
 * @property {Object} customComponent - React JXS, optional custom field input
 * @property {Bool} middleActionButton - option to include a middle button in component
 * @property {String} middleActionTitle - title for optional middle button
 * @property {Function} handleMiddleButtonClick - callback function for optional middle button
 * @return {Array} - React propTypes
 */
AlembicModalForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  saveTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  handleCancel: PropTypes.func,
  currentObject: PropTypes.shape(),
  variant: PropTypes.string,
  userTimeZone: PropTypes.string,
  note: PropTypes.string,
  handleRemovedFields: PropTypes.func,
  customComponent: PropTypes.element,
  middleActionButton: PropTypes.bool,
  middleActionTitle: PropTypes.string,
  handleMiddleButtonClick: PropTypes.func,
  updateFields: PropTypes.func,
  middleButtonStyle: PropTypes.shape()
};

AlembicModalForm.defaultProps = {
  title: null,
  saveTitle: 'Save',
  cancelTitle: 'Cancel',
  currentObject: null,
  handleCancel: null,
  variant: '',
  userTimeZone: '',
  note: null,
  handleRemovedFields: null,
  customComponent: null,
  middleActionButton: false,
  middleActionTitle: null,
  handleMiddleButtonClick: () => {},
  updateFields: () => {},
  middleButtonStyle: null
};

export default AlembicModalForm;
