import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AlbTooltip } from 'components/AlbTooltip';
import tooltipText from 'util/tooltipText';
import DetectedEventsTable from 'components/DetectedEventsTable';
import TypeDropdown from './TypeDropdown';

const styles = makeStyles({
  root: {
    background: '#ffffff'
  },
  tabOverRide: {
    minWidth: 'unset',
    maxWidth: 'unset',
    cursor: 'default',
    fontSize: '18px',
    fontWeight: '500'
  },
  tooltip: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
});

const DashboardIntelligenceTable = ({ startDate, endDate }) => {
  const classes = styles();

  // use selected options to filter the table by Type
  const [selectedTypes, setSelectedTypes] = useState([]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container style={{ marginBottom: '20px' }}>
        <Tabs value={0} onChange={() => {}}>
          <Tab
            label="Revenue Generation &amp; Brand Value Events"
            classes={{
              root: classes.tabOverRide
            }}
          />
        </Tabs>

        <Grid item className={classes.tooltip}>
          <AlbTooltip title={tooltipText.revenueGenerationAndBrandValueEvents} />
        </Grid>
        <TypeDropdown selectedTypes={selectedTypes} setSelectedTypes={setSelectedTypes} dashboard />
      </Grid>

      {/* Insert table here */}
      <DetectedEventsTable startDate={startDate} endDate={endDate} typeFilter={selectedTypes} />
    </Grid>
  );
};

export default DashboardIntelligenceTable;

DashboardIntelligenceTable.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
};
