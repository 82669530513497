/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const PROGRAM_LIFT_INTENSITIES = gql`
  query programLiftIntensities($id: ID!, $startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    programLiftIntensities(id: $id, startDate: $startDate, endDate: $endDate) {
      intensity_date
      value
    }
  }
`;
