/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import DashboardUnderstandDrawerContext from './DashboardUnderstandDrawerContext';

class DashboardUnderstandDrawerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDrawer = ({
      isDrawerOpen = false,
      startDate = null,
      endDate = null,
      isSwitchChecked = null,
      event = {}
    }) => {
      this.setState({
        isDrawerOpen,
        startDate,
        endDate,
        isSwitchChecked,
        event
      });
    };

    this.state = {
      isDrawerOpen: false,
      startDate: null,
      endDate: null,
      isSwitchChecked: null,
      event: {},
      toggleDrawer: this.toggleDrawer
    };
  }

  render() {
    const { children } = this.props;
    return (
      <DashboardUnderstandDrawerContext.Provider value={this.state}>
        {children}
      </DashboardUnderstandDrawerContext.Provider>
    );
  }
}

DashboardUnderstandDrawerWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardUnderstandDrawerWrapper;
