// MFAInput.js
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { apiUserSignOut, USER_AUTH_OTP } from 'gql/user';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { deauthenticate } from 'actions/authActions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import client from 'middleware/apolloClient';
import store from 'store/index';

import {
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
  InputAdornment,
  Button
} from '@material-ui/core';
import { LoginPath } from 'util/paths';
import AlembicLogo from 'components/AlembicLogo';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { Help as HelpIcon } from '@material-ui/icons';

const MFAInputDialog = props => {
  const { history } = props;

  const [authOTP, { loading: codeLoading, error: codeError, data: codeData }] = useMutation(
    USER_AUTH_OTP
  );
  const [rememberDevice, setRememberDevice] = useState(false);
  const [code, setCode] = useState('');

  const handleMFASubmit = () => {
    if (!codeLoading) {
      authOTP({ variables: { code, rememberDevice } }).catch(() => {});
    }
  };

  const cancelMFA = () => {
    // wipe this login
    apiUserSignOut().then(() => {
      client.resetStore();
      store.dispatch(deauthenticate());
      history.push(LoginPath);
    });
  };

  useEffect(() => {
    if (codeData) {
      // we're finishing the MFA login and have a valid result from the API
      store.dispatch({
        type: 'MFA_CHANGE_STATE',
        mfaPassed: true,
        expires: codeData.authOTP.expires,
        token: codeData.authOTP.token
      });

      // MFA has passed at this point. completeLogin will update the redux store, which in turn will redirect the user off this page,
      // and the login flow will continue (see loginFlow.js)
    }
  }, [codeData]);

  return (
    <div className="login_bg">
      <Grid
        container
        wrap="nowrap"
        direction="column"
        justifyContent="center"
        alignContent="center"
        style={{ height: '100vh' }}
      >
        <Grid item container justifyContent="center">
          <Grid item xs={10} sm={4}>
            <Paper elevation={3}>
              <div style={{ padding: 60 }}>
                <Grid item container>
                  <AlembicLogo kind="stacked" />
                </Grid>
              </div>
              <ValidatorForm onSubmit={handleMFASubmit}>
                <Grid item xs={12} md={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    style={{ padding: '30px' }}
                    spacing={2}
                  >
                    {codeLoading && (
                      <Grid item>
                        <AlbLoading />
                      </Grid>
                    )}
                    {codeError && (
                      <Grid item>
                        <AlbError error={codeError} />
                      </Grid>
                    )}
                    <Grid item>
                      <Typography align="center" variant="body1" color="primary">
                        Multi-Factor Authentication
                      </Typography>
                      <Typography align="center" variant="body1" color="secondary">
                        Please enter the code displayed by your authenticator app.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextValidator
                        autoFocus
                        name="code"
                        type="text"
                        placeholder="MFA Code"
                        autoComplete="one-time-code"
                        variant="filled"
                        fullWidth
                        value={code}
                        onChange={event => setCode(event.target.value)}
                        onKeyPress={ev => {
                          if (ev.key === 'Enter') {
                            // Do code here
                            handleMFASubmit();
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="submit code"
                                onClick={handleMFASubmit}
                                color="primary"
                                disabled={codeLoading}
                              >
                                <i className="fa fa-arrow-circle-right" />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography align="center" variant="body1" color="primary">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={rememberDevice}
                              onChange={event => setRememberDevice(event.target.checked)}
                            />
                          }
                          label="Remember this device for 30 days"
                          style={{ marginRight: 0 }}
                        />
                        <Tooltip
                          title="Once you enter a successful MFA code, 
                                 Alembic will not ask you for the code again for 30 days if 
                                 this option is selected. Do not set this option on computers 
                                 that you share with others or public machines."
                          interactive
                        >
                          <HelpIcon color="primary" style={{ fontSize: 30, padding: 8 }} />
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography align="center" variant="body2" color="primary">
                        <Button onClick={cancelMFA} variant="outlined" fullWidth>
                          This isn&apos;t me. Take me back to the login page.
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MFAInputDialog.propTypes = {
  history: PropTypes.shape().isRequired
};

export default withRouter(MFAInputDialog);
