import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  videoWidget,
  watchTimeWidget,
  engagementVideoWidget,
  videoGrowthWidget,
  videoLengthWidget
} from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import BoxContentWidget from './BoxContent/BoxContentWidget';

const AnalyticsVideoPerformance = props => {
  const {
    accounts,
    dates
    // userTimeZone
  } = props;

  const filterWidget = widget =>
    widget.filter(({ typeFilter }) => {
      const accountTypes = accounts.map(({ type }) => type);

      return !typeFilter || typeFilter?.some(type => accountTypes.includes(type));
    });

  const filteredVideoWidget = filterWidget(videoWidget);
  const filteredVideoGrowthWidget = filterWidget(videoGrowthWidget);
  const filteredWatchTimeWidget = filterWidget(watchTimeWidget);
  const filteredEngagementVideoWidget = filterWidget(engagementVideoWidget);
  const filteredVideoLengthWidget = filterWidget(videoLengthWidget);

  return (
    <Grid
      container
      style={{
        width: '100%',
        flexDirection: 'column',
        margin: '25px 15px'
      }}
    >
      {/* 
          widgets display a paired graph and table 
          metrics queried by each section of the widget are assigned in AnalyticsSocialConsts          
        */}
      {!!filteredVideoWidget.length && (
        <BoxContentWidget
          tabData={filteredVideoWidget}
          accounts={accounts}
          dates={dates}
          onlyVideo
          prefix="vp"
        />
      )}
      {!!filteredVideoGrowthWidget.length && (
        <BoxContentWidget tabData={filteredVideoGrowthWidget} accounts={accounts} dates={dates} />
      )}
      {!!filteredWatchTimeWidget.length && (
        <BoxContentWidget
          tabData={filteredWatchTimeWidget}
          accounts={accounts}
          dates={dates}
          onlyVideo
        />
      )}
      {/* <BoxContentWidget tabData={growthWidget} accounts={accounts} dates={dates} /> */}
      {/* <BoxContentWidget tabData={storiesWidget} accounts={accounts} dates={dates} /> */}
      {!!filteredEngagementVideoWidget.length && (
        <BoxContentWidget
          tabData={filteredEngagementVideoWidget}
          accounts={accounts}
          dates={dates}
          onlyVideo
          prefix="vp"
        />
      )}
      {!!filteredVideoLengthWidget.length && (
        <BoxContentWidget
          tabData={filteredVideoLengthWidget}
          accounts={accounts}
          dates={dates}
          onlyVideo
        />
      )}
    </Grid>
  );
};

AnalyticsVideoPerformance.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
  // userTimeZone: PropTypes.string.isRequired
};

export default AnalyticsVideoPerformance;
