import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TOP_ACTIVE_CAMPAIGN_EVENT } from 'gql/analyticsHypeAndFlow';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import AlbError from 'components/AlbError';
import CustomFooter from 'components/TablePagination';
import AlbTable from './AlbTable';
import {
  customAccountRender,
  customHandleRender,
  customPostRender,
  customValueRender,
  customAgeValueRender
} from './CustomRender';

const useStyles = makeStyles({
  titleContainer: {
    padding: '0px 24px'
  },
  titleText: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#0A1734'
  },
  countText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734',
    marginRight: '4px'
  },
  secondaryText: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#6F6F6F'
  }
});

const CONFIG = {
  alembicImpressions: {
    columnOneName: 'lifetime_impressions',
    columnOneLabel: 'LTV IMP.',
    columnTwoName: 'period_impressions',
    columnTwoLabel: 'IMP. IN PER.'
  },
  alembicEngagement: {
    columnOneName: 'lifetime_engagement',
    columnOneLabel: 'LTV ENG.',
    columnTwoName: 'period_engagement',
    columnTwoLabel: 'ENG. IN PER.'
  },
  alembicShares: {
    columnOneName: 'lifetime_shares',
    columnOneLabel: 'LTV SHARES',
    columnTwoName: 'period_shares',
    columnTwoLabel: 'SHARES IN PER.'
  },
  alembicViews: {
    columnOneName: 'lifetime_views',
    columnOneLabel: 'LTV VIEWS',
    columnTwoName: 'period_views',
    columnTwoLabel: 'VIEWS IN PER.'
  }
};

const HypePostTable = props => {
  const { dates, accounts, metric } = props;
  const drawerContext = useContext(PostDrawerViewContext);

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['', '']);

  const classes = useStyles();

  const { error: queryError, data: queryData } = useQuery(TOP_ACTIVE_CAMPAIGN_EVENT, {
    variables: {
      startDate: dates.start,
      endDate: dates.end,
      linkTokens: accounts.map(account => account.id),
      metric,
      count: rowsPerPage,
      after: page * rowsPerPage
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (queryData?.topActiveCampaignEvent) {
      const tempTableData = [];

      queryData.topActiveCampaignEvent.rows.forEach((campaignEvent, index) => {
        const linkToken = accounts.find(({ id }) => id === campaignEvent.account);

        const postId = campaignEvent?.post;

        const linkTokenId = linkToken?.id;

        const position = page * rowsPerPage + index + 1; // TODO swap

        const account = {
          campaignEventType: campaignEvent.type,
          socialPlatformIcon: linkToken.platform.icon,
          linkTokenType: linkToken.type,
          linkTokenRemoteId: linkToken.remote_id,
          linkTokenRemoteName: linkToken.remote_name
        };

        const handle = {
          avatarUrl: linkToken.avatar_url,
          thumbnailUrl: campaignEvent.thumbnailUrl,
          publishedUrl: campaignEvent.publishedUrl
        };

        const post = {
          body: campaignEvent.body,
          assets: campaignEvent.assets,
          thumbnailUrl: campaignEvent.thumbnailUrl,
          publishedUrl: campaignEvent.publishedUrl
        };

        tempTableData.push({
          postId,
          linkTokenId,
          position,
          account,
          handle,
          post,
          ...campaignEvent.metrics.reduce((accumulator, currentValue) => {
            accumulator[currentValue.metric] = currentValue.value || null;

            return accumulator;
          }, {})
        });
      });

      const tempTableColumns = [
        {
          name: 'postId',
          options: { display: false }
        },
        {
          name: 'linkTokenId',
          options: { display: false }
        },
        {
          name: 'position',
          label: '#',
          options: { sort: false }
        },
        {
          name: 'account',
          label: 'ACCOUNT',
          options: { customBodyRender: customAccountRender, sort: false }
        },
        {
          name: 'handle',
          label: 'HANDLE',
          options: { customBodyRender: customHandleRender, sort: false }
        },
        {
          name: 'post',
          label: 'POST',
          options: { customBodyRender: customPostRender, sort: false }
        },
        {
          name: 'age',
          label: 'AGE',
          options: { customBodyRender: customAgeValueRender, sort: false }
        },
        {
          name: CONFIG[metric].columnOneName,
          label: CONFIG[metric].columnOneLabel,
          options: { customBodyRender: customValueRender, sort: false }
        }
      ];

      if (CONFIG[metric].columnTwoName) {
        tempTableColumns.push({
          name: CONFIG[metric].columnTwoName,
          label: CONFIG[metric].columnTwoLabel,
          options: { customBodyRender: customValueRender, sort: false }
        });
      }

      setCount(queryData.topActiveCampaignEvent.count);
      setTableData(tempTableData);
      setTableColumns(tempTableColumns);
    }
  }, [queryData?.topActiveCampaignEvent]);

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const onRowClick = rowData => {
    const postIdColumnIndex = tableColumns.findIndex(({ name }) => name === 'postId');
    const linkTokenIdColumnIndex = tableColumns.findIndex(({ name }) => name === 'linkTokenId');

    const postId = rowData[postIdColumnIndex];
    const linkTokenId = rowData[linkTokenIdColumnIndex];

    drawerContext.toggleDrawer(true, null, postId, linkTokenId, dates.start, dates.end);
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    onRowClick,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  if (queryError) {
    return (
      <Grid container direction="column" justifyContent="flex-start">
        <AlbError error={queryError} />
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item>
          <Typography className={classes.titleText}>Post Breakdown</Typography>
        </Grid>
        <Grid item style={{ display: 'flex' }}>
          <Typography className={classes.countText}>{count}</Typography>
          <Typography className={classes.secondaryText}>Posts in Period</Typography>
        </Grid>
      </Grid>
      <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />
    </Grid>
  );
};

HypePostTable.propTypes = {
  dates: PropTypes.shape().isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  metric: PropTypes.string.isRequired
};

HypePostTable.defaultProps = {};

export default HypePostTable;
