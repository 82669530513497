import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import HypeMetricTimeSeries from './Graphs/HypeMetricTimeSeries';

const AnalyticsHype = props => {
  const { accounts, dates } = props;

  // Ideally this should only contain the structure/components needed to render the graphs under the header for the Hype tab
  // Each white square cards containing graphs/data should be defined as a separate component within this display
  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item xs={12}>
        <HypeMetricTimeSeries accounts={accounts} dates={dates} />
      </Grid>
    </Grid>
  );
};

AnalyticsHype.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default AnalyticsHype;
