import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab } from '@material-ui/core';
import isFeatureVisible from 'util/isFeatureVisible';

const useStyles = makeStyles({
  subheader: {
    backgroundColor: '#FFFFFF',
    padding: '22px 0px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  },
  root: {
    display: 'flex',
    minHeight: 0
  },
  container: {
    display: 'unset'
  },
  scroller: {
    whiteSpace: 'unset'
  },
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    minWidth: 0,
    minHeight: 0,
    padding: '12px 18px',
    margin: '0px 5px',
    fontSize: '12px',
    fontWeight: 500
  },
  selectedTab: {
    color: 'white',
    backgroundColor: '#0d1a3a',
    borderRadius: '3px',
    verticalAlign: 'center'
  }
});

const SubheaderTabs = props => {
  const { tabValues, callback, activeTab, featureFlags } = props;

  const enabledTabs = tabValues.filter(({ enabled, toggle: toggles }) => {
    // if feature is locked behind unleash, we check for both permissions to display the tab - (out of dev, enabled in unleash)
    if (toggles) {
      return toggles.every(toggle => isFeatureVisible(featureFlags, toggle) && enabled);
    }

    return enabled;
  });

  const classes = useStyles();

  return (
    <Grid container justifyContent="center" className={classes.subheader}>
      <Grid container justifyContent="center" item xs={9}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => callback(newValue)}
          classes={{
            root: classes.root,
            flexContainer: classes.container,
            scroller: classes.scroller,
            indicator: classes.tabsIndicator
          }}
        >
          {enabledTabs.map(({ tab }) => (
            <Tab
              label={tab}
              key={tab}
              value={tab}
              classes={{
                root: classes.tabRoot
              }}
              className={activeTab === tab ? classes.selectedTab : null}
              onClick={() => callback(tab)}
            />
          ))}
        </Tabs>
      </Grid>
    </Grid>
  );
};

SubheaderTabs.propTypes = {
  tabValues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  callback: PropTypes.func,
  activeTab: PropTypes.string,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

SubheaderTabs.defaultProps = {
  callback: null,
  activeTab: null
};

const mapStateToProps = state => ({
  featureFlags: state.auth.featureFlags
});

export default connect(mapStateToProps)(SubheaderTabs);
