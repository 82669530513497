import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import ModalContainer from 'components/Modal';
import { Typography, Grid, List, Button, Select, MenuItem } from '@material-ui/core';
import AccountItem from 'components/PostAccountSelector/AccountItem';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CREATE_LINKED_GROUP, UPDATE_LINKED_GROUP } from 'gql/linktoken';
import { showToast } from 'contexts/ToastContext';

const GroupAccountsModal = ({
  open,
  onClose,
  selectedAccountsProp,
  allAccounts,
  create,
  edit,
  children,
  group,
  refetchGroups,
  handleGroupDelete
}) => {
  const [groupNameInput, changeGroupNameInput] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [createLinkedGroup, { data: createData }] = useMutation(CREATE_LINKED_GROUP, {
    onError(err) {
      showToast(err.message, 'error');
    },
    onCompleted() {
      refetchGroups();
    }
  });
  const [updateLinkedGroup, { data: editData }] = useMutation(UPDATE_LINKED_GROUP, {
    onError(err) {
      showToast(err.message, 'error');
    },
    onCompleted() {
      refetchGroups();
    }
  });

  useEffect(() => {
    setSelectedAccounts(selectedAccountsProp);
  }, [selectedAccountsProp]);

  useEffect(() => {
    if (createData?.createLinkedGroup) {
      showToast(`Your new group, ${groupNameInput}, was saved.`, 'success');
      onClose();
    }

    if (editData?.updateLinkedGroup) {
      showToast(`Your group, ${groupNameInput}, was updated.`, 'success');
      onClose();
    }
  }, [createData, editData]);

  // set initial group name when editing a group.
  useEffect(() => {
    if (group?.name && !groupNameInput.length) {
      const newSelectedAccounts = [...group.linktokens];

      setSelectedAccounts(newSelectedAccounts);
      changeGroupNameInput(group.name);
    }
  }, [group]);

  const handleCreateGroup = () => {
    const accountIds = selectedAccounts?.map(account => account.id);

    if (!groupNameInput.length) {
      showToast('Please enter a name for your group.', 'warn');
    } else if (accountIds?.length) {
      createLinkedGroup({
        variables: { name: groupNameInput, linktokenIds: accountIds }
      });
    }
  };

  const handleEditGroup = () => {
    const accountIds = selectedAccounts?.map(account => account.id);

    if (group && accountIds?.length) {
      updateLinkedGroup({
        variables: { id: group.id, name: groupNameInput, linktokenIds: accountIds }
      });
    }
  };

  // onChange calls the HandleSelectAccount function from NewPostForm which takes a list of new accounts as a parameter.
  const handleAddAccount = account => {
    const newSelectedAccounts = [...selectedAccounts, account];

    setSelectedAccounts(newSelectedAccounts);

    // onChange(newSelectedAccounts);
  };

  // all accounts should only include accounts that aren't selected.
  let unselectedAccounts = allAccounts;

  // if there are no selected accounts, then all accounts are displayed.
  if (selectedAccounts?.length) {
    const selectedAccountIds = selectedAccounts.map(acc => acc.id);
    const unselectedAccountIds = unselectedAccounts.map(acc => acc.id);

    selectedAccountIds.forEach(id => {
      if (unselectedAccountIds.includes(id)) {
        unselectedAccounts = unselectedAccounts.filter(acc => acc.id !== id);
      }
    });
  }

  return (
    <ModalContainer
      open={open}
      close={onClose}
      style={{
        width: '459px',
        height: '599px',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '25vh',
        marginBottom: 'auto'
      }}
    >
      <Grid style={{ padding: '15px' }}>
        {children && children}
        <ValidatorForm
          onSubmit={() => {
            if (create) {
              handleCreateGroup();
            }

            if (edit) {
              handleEditGroup();
            }
          }}
        >
          <TextValidator
            placeholder="Enter Group Name"
            variant="outlined"
            fullWidth
            autoFocus
            style={{ paddingBottom: '15px' }}
            onChange={e => changeGroupNameInput(e.target.value)}
            value={groupNameInput}
            validators={['required']}
            errorMessages={['* Required']}
          />
          {unselectedAccounts?.length ? (
            <>
              <Typography
                style={{
                  color: 'rgba(10, 23, 52, 0.5)',
                  fontSize: '15px',
                  fontWeight: 600,
                  paddingTop: '15px'
                }}
              >
                Add Additional Accounts
              </Typography>

              <Select
                fullWidth
                variant="outlined"
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                {/* Only display the accounts that aren't selected. */}
                {unselectedAccounts?.map(account => (
                  <MenuItem key={account.id} onClick={() => handleAddAccount(account)}>
                    {/* eslint-disable-next-line camelcase */}
                    {`${account?.platform?.displayName} - ${account?.remote_name}`}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : null}
          <Typography
            style={{
              color: 'rgba(10, 23, 52, 0.5)',
              fontSize: '15px',
              fontWeight: 600,
              paddingBottom: '15px',
              paddingTop: '15px'
            }}
          >
            {create ? 'Accounts to be Grouped' : 'Accounts Grouped'}
          </Typography>
          {selectedAccounts?.length ? (
            <Grid item>
              <List
                style={{
                  paddingTop: 0,
                  maxHeight: '200px',
                  overflow: 'auto'
                }}
              >
                {selectedAccounts?.map(account => (
                  <AccountItem
                    key={account?.id}
                    data={account}
                    iconWidth={20}
                    iconHeight={20}
                    onChange={accounts => setSelectedAccounts(accounts)}
                    selectedSingleAccounts={selectedAccounts}
                  />
                ))}
              </List>
            </Grid>
          ) : (
            'There are no accounts selected.'
          )}
          <Grid container justifyContent="space-between" style={{ marginTop: '15px' }}>
            <Grid item>
              {edit && (
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  onClick={handleGroupDelete}
                >
                  Delete
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {create ? 'Create' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
    </ModalContainer>
  );
};

GroupAccountsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedAccountsProp: PropTypes.arrayOf(PropTypes.shape()),
  allAccounts: PropTypes.arrayOf(PropTypes.shape()),
  create: PropTypes.bool,
  edit: PropTypes.bool,
  children: PropTypes.node,
  group: PropTypes.shape(),
  refetchGroups: PropTypes.func,
  handleGroupDelete: PropTypes.func
};

GroupAccountsModal.defaultProps = {
  selectedAccountsProp: [],
  allAccounts: [],
  create: false,
  edit: false,
  children: null,
  group: null,
  refetchGroups: null,
  handleGroupDelete: null
};

export default GroupAccountsModal;
