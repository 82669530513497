/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const PROGRAM_LIFT_CONTRIBUTION_SCORES = gql`
  query programLiftContributionScores(
    $id: ID!
    $periodical: PeriodicalEnum!
    $endDate: DateTimeISO!
  ) {
    programLiftContributionScores(id: $id, periodical: $periodical, endDate: $endDate) {
      id
      mix_group
      program_lift_score
      base_score
    }
  }
`;
