import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DELETE_SHARE_OF_VOICE_REPORT } from 'gql/shareOfVoice';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';

const DeleteShareOfVoiceModal = props => {
  const { isModalOpen, onChange, shareOfVoiceId, shareOfVoiceName } = props;

  const [deleteShareOfVoiceReport, { error, data }] = useMutation(DELETE_SHARE_OF_VOICE_REPORT);

  useEffect(() => {
    if (data?.deleteShareOfVoiceReport?.result) {
      showToast(`Report deleted`, 'success');
      onChange({
        isModalOpen: false,
        newShareOfVoiceData: data?.deleteShareOfVoiceReport?.result
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({
        isModalOpen: false
      });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isOpen={isModalOpen}
        isDelete
        title="Delete Report"
        body={
          <>
            Are you sure you want to delete the report:&nbsp;
            <span style={{ color: '#0A1734' }}>{shareOfVoiceName}</span>?
          </>
        }
        cancelTitle="Cancel"
        confirmTitle="Delete"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() => deleteShareOfVoiceReport({ variables: { id: shareOfVoiceId } })}
      />
    </>
  );
};

DeleteShareOfVoiceModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  shareOfVoiceId: PropTypes.string,
  shareOfVoiceName: PropTypes.string
};

DeleteShareOfVoiceModal.defaultProps = {
  shareOfVoiceId: null,
  shareOfVoiceName: null
};

export default DeleteShareOfVoiceModal;
