import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import Box from 'components/Box';
import getValueAbbreviation from 'util/getValueAbbreviation';
import colors from 'util/colors';
import { getIconSrc, MENTION_GROUP_SOURCE_ENUM } from 'components/AnalyticsThirdPartyMedia/util';
import {
  PODCAST,
  BROADCAST,
  RADIO,
  WEB
} from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';

const useStyles = makeStyles({
  containerRow: {
    margin: '10px 0px'
  },
  labelRow: {
    height: '100%',
    marginRight: '10px',
    whiteSpace: 'nowrap'
  },
  sourceText: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#6F6F6F',
    marginLeft: '10px',
    marginRight: '10px'
  },
  countText: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#0A1734'
  }
});

const TopSources = props => {
  const { dataResponse } = props;
  const classes = useStyles();

  return (
    <Grid container>
      {dataResponse.map(row => (
        <Grid key={`${row.source}+${row.count}`} container className={classes.containerRow}>
          <Grid item xs={3}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.labelRow}
            >
              {row?.type === MENTION_GROUP_SOURCE_ENUM.TV && (
                <img src={getIconSrc(BROADCAST)} alt="Television icon" width={17} height={13} />
              )}
              {row?.type === MENTION_GROUP_SOURCE_ENUM.RADIO && (
                <img src={getIconSrc(RADIO)} alt="Radio icon" width={17} height={13} />
              )}
              {(row?.type === MENTION_GROUP_SOURCE_ENUM.PODCAST_TRANSCRIPT ||
                row?.type === MENTION_GROUP_SOURCE_ENUM.PODCAST_SUMMARY) && (
                <img src={getIconSrc(PODCAST)} alt="Podcast icon" width={10} height={14} />
              )}
              {row?.type === MENTION_GROUP_SOURCE_ENUM.WEB && (
                <img src={getIconSrc(WEB)} alt="Article icon" width={17} height={20} />
              )}
              <Tooltip title={row?.source?.length > 25 ? row?.source : ''}>
                <Typography className={classes.sourceText}>
                  {row?.source?.length > 25
                    ? row?.source?.slice(0, 22)?.concat('...')
                    : row?.source}
                </Typography>
              </Tooltip>
              <Typography className={classes.countText}>
                {getValueAbbreviation(row?.count) ?? '-'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <div
              style={{
                width: `${(row.count / dataResponse[0].count) * 100}%`,
                height: '40px',
                backgroundColor: colors.darkBlue
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

TopSources.propTypes = {
  dataResponse: PropTypes.arrayOf(PropTypes.shape())
};

TopSources.defaultProps = {
  dataResponse: []
};

export default TopSources;
