import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Dialog } from '@material-ui/core';
import SubheaderTabs from 'components/AnalyticsHeader/SubheaderTabs';
import { tabs, targetingTabs } from 'components/AnalyticsHeader/AnalyticsHeaderConsts';
import Box from 'components/Box';
import { goToRoute } from 'util/routesHelpers';
import setPageTitle from 'util/titles';
import { TARGETING_CARD_TITLE } from 'util/analyticsTitles';
import { AnalyticsPath } from 'util/paths';
import isFeatureVisible from 'util/isFeatureVisible';
import AnalyticsHeader from 'components/AnalyticsComponents/AnalyticsHeader';
import ContainerNav from 'components/ContainerNav';
import AnalyticsPageTitle from 'components/AnalyticsComponents/AnalyticsPageTitle';
import AnalyticsCloseButton from 'components/AnalyticsComponents/AnalyticsCloseButton';
import AlbLoading from 'components/AlbLoading';

const useStyles = makeStyles({
  root: {
    background: '#F0F0F0',
    width: '100%',
    fontFamily: 'Poppins',
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 40
  },
  iframeContainer: {
    width: '100%',
    height: 'calc(100vh - var(--header-height, 273px))'
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
});

const AnalyticsTargeting = ({ currentUser, history, featureFlags }) => {
  const classes = useStyles();
  const [activeLowerTab, setActiveLowerTab] = useState(tabs.survey2022);
  const [isLoading, setIsLoading] = useState(true);
  const dialogRef = useRef();

  useEffect(() => {
    setPageTitle('Targeting');
  }, []);

  const handleLoad = () => setIsLoading(false);

  const enabledTabs = targetingTabs.filter(({ enabled, toggle: toggles }) =>
    toggles ? toggles.every(toggle => isFeatureVisible(featureFlags, toggle) && enabled) : enabled
  );

  const survey2022 = activeLowerTab === tabs.survey2022;
  const survey2024 = activeLowerTab === tabs.survey2024;

  return (
    <Dialog fullScreen open classes={{ paperFullScreen: classes.root }} ref={dialogRef}>
      <Grid container justifyContent="center">
        <AnalyticsHeader>
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Box display="flex">
              <Box mr={20}>
                <ContainerNav startingContainer={currentUser.home_container_id} analytics />
              </Box>
              <AnalyticsPageTitle title={TARGETING_CARD_TITLE} />
            </Box>
            <Box className={classes.closeButton}>
              <AnalyticsCloseButton onClose={() => goToRoute(AnalyticsPath, history)} />
            </Box>
          </Grid>
        </AnalyticsHeader>

        <SubheaderTabs
          tabValues={enabledTabs}
          activeTab={activeLowerTab}
          callback={value => {
            setActiveLowerTab(value);
            setIsLoading(true);
          }}
        />

        <Grid item xs={12}>
          <Box my={5}>
            {survey2022 && (
              <div className={classes.iframeContainer}>
                {isLoading && <AlbLoading />}
                <iframe
                  title="Embedded DisplayR Dashboard"
                  className={classes.iframe}
                  src="https://app.displayr.com/Dashboard?id=de06f087-33d1-4694-9d15-73f56076a8d6#page=03ce3f07-d01c-4c12-a155-f41582cb4a4d"
                  allowFullScreen
                  onLoad={handleLoad}
                />
              </div>
            )}
            {survey2024 && (
              <div className={classes.iframeContainer}>
                {isLoading && <AlbLoading />}
                <iframe
                  title="Embedded DisplayR Dashboard"
                  className={classes.iframe}
                  src="https://app.displayr.com/Dashboard?id=d3ad57c9-251c-48ec-9b10-f80ce03af3e8#page=35e1a279-a8bc-4dcb-a05d-be4f6625a491"
                  allowFullScreen
                  onLoad={handleLoad}
                />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AnalyticsTargeting.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  featureFlags: state.auth.featureFlags
});

export default connect(mapStateToProps)(AnalyticsTargeting);
