import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Star, SubdirectoryArrowRight as RightArrowIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { goToRoute } from 'util/routesHelpers';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  labelOverride: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    color: '#32327D',
    fontWeight: '700'
  },
  starIcon: {
    color: '#FBBB45'
  }
});

/**
 * @method
 * @summary This component renders the report name column for multiple tables and displays a star for default
 * @name ReportName
 * @param {Object} props - React props passed to this component
 * @param {Object} props.history - The history object from react router
 * @param {string} props.id - A string value for the report id
 * @param {string} props.name - A string value for the report name
 * @param {boolean} props.isDefault - Determines if the report is the default
 * @return {Object} - React JSX
 */
const ReportName = props => {
  const { history, location, id, name, isDefault, path, filterId } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        variant="text"
        onClick={() => {
          // go to filtered report
          const searchParams = new URLSearchParams(location.search);
          let dashboardPath = '';

          if (searchParams.has('from_dash')) {
            dashboardPath = 'from_dash';
          }
          if (searchParams.has('from_dashv3')) {
            dashboardPath = 'from_dashv3';
          }

          if (filterId) {
            goToRoute(`${path}/${id}?filter=${filterId}&${dashboardPath}`, history);
          } else {
            goToRoute(`${path}/${id}?${dashboardPath}`, history);
          }
        }}
        classes={{ label: classes.labelOverride }}
      >
        {filterId ? <RightArrowIcon /> : null}
        {name}
      </Button>
      {isDefault && <Star className={classes.starIcon} />}
    </div>
  );
};

ReportName.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  path: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  filterId: PropTypes.string
};

ReportName.defaultProps = {
  isDefault: false,
  filterId: null
};

const ReportNameWithRouter = withRouter(ReportName);

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see ReportName
 * @name customNameRender
 * @param {Object} name - Object passed from @see MUIDataTable
 * @param {string} props.id - A string value for the report id
 * @param {string} props.name - A string value for the report name
 * @param {boolean} props.isDefault - Determines if the report is the default
 * @param {string} props.path - The route prefix to redirect to on name click
 * @return {Object} - React JSX
 */
const customNameRender = name => (
  <ReportNameWithRouter
    id={name.id}
    name={name.name}
    isDefault={name.isDefault}
    path={name.path}
    filterId={name.filterId}
  />
);

export default customNameRender;
