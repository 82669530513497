import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel
} from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from 'theme';

const dropdownTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    }
  }
});

const styles = makeStyles({
  select: {
    margin: 'auto 26px auto auto',
    width: '190px'
  },
  label: {
    position: 'absolute',
    margin: 'auto',
    marginLeft: '15px',
    marginTop: '-15px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '15px',
    color: '#0D1A3A'
  }
});

const TypeDropdown = ({ selectedTypes, setSelectedTypes, dashboard }) => {
  const classes = styles();

  const options = [
    ...(dashboard ? [{ value: 'correlations', label: 'Correlations' }] : []),
    { value: 'media', label: 'Media (3rd party)' },
    { value: 'social', label: 'Social' },
    { value: 'web', label: 'Web' },
    { value: 'conversion', label: 'Conversion' }
  ];

  const renderSelected = selected => {
    return selected.map(name => `${name[0].toUpperCase()}${name.slice(1, name.length)}`).join(', ');
  };

  return (
    <MuiThemeProvider theme={dropdownTheme}>
      <Grid item className={classes.select}>
        <FormControl>
          {!selectedTypes.length && (
            <InputLabel className={classes.label} id="input-label">
              Type
            </InputLabel>
          )}
          <Select
            className={classes.select}
            variant="outlined"
            multiple
            labelId="input-label"
            value={selectedTypes}
            renderValue={renderSelected}
            onChange={e => setSelectedTypes(e.target.value)}
            IconComponent={ExpandMoreIcon}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value} onClick={() => {}}>
                <Checkbox checked={selectedTypes.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </MuiThemeProvider>
  );
};

export default TypeDropdown;

TypeDropdown.propTypes = {
  selectedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedTypes: PropTypes.func.isRequired,
  dashboard: PropTypes.bool
};

TypeDropdown.defaultProps = {
  dashboard: false
};
