import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AnalyticsBox from 'components/AnalyticsBox';
import getValueAbbreviation from 'util/getValueAbbreviation';
import Chart from 'chart.js';

const useStyles = makeStyles(() => ({
  dataLabelContainerStacked: {
    display: 'flex',
    flexDirection: 'column'
  },
  dataLabelMain: {
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#0A1734'
  },
  dataLabelSub: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#6F6F6F'
  }
}));

const MentionsByType = props => {
  const { dataSet } = props;
  const chartRef = useRef();
  const classes = useStyles();

  const updateChartData = (chart, newDataSet) => {
    chart.data.datasets.forEach(dataset => {
      const { length } = dataset.data;

      for (let i = 0; i < length; i += 1) {
        dataset.data.pop();
      }

      newDataSet.forEach(dataPoint => {
        dataset.data.push(dataPoint);
      });
    });
    chart.update();
  };

  const clearChartData = chart => {
    chart.data.datasets.forEach(dataset => {
      const { length } = dataset.data;

      for (let i = 0; i < length; i += 1) {
        dataset.data.pop();
      }
    });
    chart.update();
  };

  const backgroundColor = [
    'rgba(196, 0, 117, 0.4)',
    'rgba(50, 50, 125, 0.4)',
    'rgba(243, 155, 37, 0.4)',
    'rgba(232, 24, 40, 0.4)'
  ];

  useEffect(() => {
    const mentionsByTypeContext = document.getElementById('mentionsByType').getContext('2d');

    const data = {
      labels: ['TV', 'Radio', 'Podcast', 'Web'],
      datasets: [
        {
          data: [],
          backgroundColor,
          borderWidth: 0
        }
      ]
    };

    const options = {
      responsive: true,
      tooltips: {
        callbacks: {
          label(tooltipItem, chartData) {
            const valueText = Math.round(chartData.datasets[0].data[tooltipItem.index]);
            const formattedValueText = valueText.toLocaleString();

            return formattedValueText;
          }
        }
      },
      scale: {
        // pointLabels are the text outside of the graph
        // https://www.chartjs.org/docs/2.9.4/axes/radial/linear.html#point-label-options
        pointLabels: {
          display: false,
          fontColor: '#0A1734',
          fontFamily: 'Poppins',
          fontSize: 15,
          fontStyle: 500
        },
        // gridLines are the circles that create the radar
        // https://www.chartjs.org/docs/2.9.4/axes/styling.html#grid-line-configuration
        gridLines: {
          display: true,
          color: '#D8D8D8'
        },
        // ticks are the numbers for each radar
        // https://www.chartjs.org/docs/2.9.4/axes/radial/linear.html#tick-options
        ticks: {
          display: true,
          backdropColor: 'rgba(255, 255, 255, 0)',
          fontColor: '#6F6F6F',
          fontFamily: 'Poppins',
          fontSize: 15,
          fontStyle: 500,
          callback: value => {
            const { length } = `${getValueAbbreviation(value)}`;
            return ` ${length > 1 ? ' ' : ''}${length > 2 ? ' ' : ''}${getValueAbbreviation(
              value
            )}`;
          }
        }
      },
      legend: {
        display: false
      },
      startAngle: 2 * Math.PI * 1891 - Math.PI / 2
    };

    const config = {
      data,
      type: 'polarArea',
      options
    };

    const mentionsByTypeChart = new Chart(mentionsByTypeContext, config);
    chartRef.current = mentionsByTypeChart;
  }, []);

  const [tvValue, setTvValue] = useState(null);
  const [radioValue, setRadioValue] = useState(null);
  const [podcastValue, setPodcastValue] = useState(null);
  const [webValue, setWebValue] = useState(null);

  useEffect(() => {
    if (dataSet?.length > 0) {
      const [tv, radio, podcast, web] = dataSet;

      setTvValue(tv);
      setRadioValue(radio);
      setPodcastValue(podcast);
      setWebValue(web);
      updateChartData(chartRef.current, dataSet);
    } else {
      setTvValue(null);
      setRadioValue(null);
      setPodcastValue(null);
      setWebValue(null);
      clearChartData(chartRef.current);
    }
  }, [dataSet]);

  const types = [
    { label: 'TV', value: tvValue, position: { top: 0, right: 0 } },
    { label: 'Radio', value: radioValue, position: { bottom: 0, right: 0 } },
    { label: 'Podcast', value: podcastValue, position: { bottom: 0, left: 0 } },
    { label: 'Web', sub: '# Articles', value: webValue, position: { top: 0, left: 0 } }
  ];

  return (
    <AnalyticsBox tabs={['Mentions By Type']} onChange={() => {}}>
      <div style={{ position: 'relative' }}>
        {types.map(({ label, sub, value, position }, i) => (
          <div
            key={label}
            className={classes.dataLabelContainerStacked}
            style={{ position: 'absolute', ...position }}
          >
            <Typography variantMapping={{ body1: 'span' }} className={classes.dataLabelMain}>
              {label}
            </Typography>
            {sub && (
              <Typography variantMapping={{ body1: 'span' }} className={classes.dataLabelSub}>
                {sub}
              </Typography>
            )}
            <Typography
              variantMapping={{ body1: 'span' }}
              className={classes.dataLabelSub}
              style={{ color: backgroundColor[i] }}
            >
              {value?.toLocaleString() || ''}
            </Typography>
          </div>
        ))}
        <canvas id="mentionsByType" width="400" height="400" />
      </div>
    </AnalyticsBox>
  );
};

MentionsByType.propTypes = {
  dataSet: PropTypes.arrayOf(PropTypes.number)
};

MentionsByType.defaultProps = {
  dataSet: []
};

export default MentionsByType;
