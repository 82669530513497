import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import { DRUID_LOCATION_TABLE, WEB_AND_APPS_LOCATION_TABLE } from 'gql/analytics';
import colors from 'util/colors';
import theme from 'theme';
import AlbLoading from 'components/AlbLoading';
import { shortenText } from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import { getDatesForAnalytics } from 'util/date';

const tableTheme = hideOverflow =>
  createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiTableRow: {
        root: {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          whiteSpace: 'nowrap'
        },
        sortAction: {
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(even)': {
            backgroundColor: '#f5f5f5'
          },
          '&:hover': {
            backgroundColor: colors.athensGray
          }
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: 'none'
        },
        body: {
          whiteSpace: 'nowrap',
          ...(!!hideOverflow && { overflow: 'hidden' }),
          textOverflow: 'ellipsis'
        }
      }
    }
  });

const AlbGeoTable = props => {
  const { accounts, dates, metric } = props;

  const useStyles = makeStyles({
    container: {
      display: 'grid',
      // expand grid for 3 tables in social media
      gridTemplateColumns: `1fr 1fr ${metric === 'locationTable' ? '1fr' : ''}`,
      gridGap: '22px'
    },
    column: {
      fontSize: '13px',
      fontWeight: 700,
      color: colors.darkBlue,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    change: {
      fontSize: '13px',
      whiteSpace: 'nowrap'
    },
    positive: {
      fontWeight: 500,
      color: colors.green
    },
    negative: {
      fontWeight: 500,
      color: colors.red
    },
    centeredHeader: {
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    rightHeader: {
      '& span': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }
    }
  });

  const classes = useStyles();

  const [sortOrder, updateSortOrder] = useState({ name: 'total', direction: 'desc' });

  const [countriesData, setCountriesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [languagesData, setLanguagesData] = useState([]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const queries = {
    locationTable: DRUID_LOCATION_TABLE,
    webAndAppsLocationMap: WEB_AND_APPS_LOCATION_TABLE
  };

  const { data, loading } = useQuery(queries[metric], {
    variables: {
      linkTokens: accounts.map(({ id }) => id),
      startDate,
      endDate,
      metric
    }
  });

  const formatWebAndAppsGeoTable = tableData =>
    tableData.map(({ metric: column, growth }) => {
      const { percentage = 0, primaryValue, secondaryValue } = growth;
      const symbol = secondaryValue >= 0 ? '+' : '-';

      return {
        column,
        total: primaryValue,
        difference: secondaryValue,
        change: `${percentage ?? 0}% (${symbol}${Math.abs(secondaryValue)})`
      };
    });

  const formatLocationData = locationData => {
    // we may need to expand this formatting function if we support more incoming data formats
    if (metric === 'webAndAppsLocationMap') {
      const { countryTable, cityTable } = locationData;

      const formattedCountryData = formatWebAndAppsGeoTable(countryTable);
      const formattedCityData = formatWebAndAppsGeoTable(cityTable);

      return {
        countries: formattedCountryData,
        cities: formattedCityData
        // no language table for web and apps
      };
    }

    return locationData;
  };

  useEffect(() => {
    if (data?.[metric]) {
      const locationData = formatLocationData(data[metric]);

      setCountriesData(locationData.countries);
      setCitiesData(locationData.cities);
      setLanguagesData(locationData.languages);
    }
  }, [data]);

  const customTotalRender = total => <div align="center">{total.toLocaleString()}</div>;

  const customChangeRender = (difference, { columnIndex, rowData }) => {
    const changeValue = difference > 0 ? `+${difference}` : difference;
    const formattedValue =
      difference > 0 ? `+${difference.toLocaleString()}` : difference.toLocaleString();

    return (
      <div
        className={`${classes.change} ${difference > 0 ? classes.positive : ''}${
          difference < 0 ? classes.negative : ''
        }`}
        align="right"
      >
        {rowData[columnIndex + 1].replace(changeValue, formattedValue)}
      </div>
    );
  };

  const customColumnRender = column => (
    <Typography className={classes.column}>{shortenText[column] || column}</Typography>
  );

  const columns = [
    {
      name: 'total',
      label: 'TOTAL',
      options: {
        customBodyRender: customTotalRender,
        setCellHeaderProps: () => ({ className: classes.centeredHeader })
      }
    },
    {
      name: 'difference',
      label: 'CHANGE',
      options: {
        customBodyRender: customChangeRender,
        setCellHeaderProps: () => ({ className: classes.rightHeader })
      }
    },
    {
      name: 'change',
      options: { display: false }
    }
  ];

  const countriesColumns = [
    {
      name: 'column',
      label: 'COUNTRY',
      options: { customBodyRender: customColumnRender }
    },
    ...columns
  ];

  const citiesColumns = [
    {
      name: 'column',
      label: 'CITY',
      options: { customBodyRender: customColumnRender }
    },
    ...columns
  ];

  const languagesColumns = [
    {
      name: 'column',
      label: 'LANGUAGE',
      options: { customBodyRender: customColumnRender }
    },
    ...columns
  ];

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard',
    sortOrder,
    onColumnSortChange: (name, direction) => updateSortOrder({ name, direction })
  };

  if (loading) return <AlbLoading />;

  return (
    <Grid className={classes.container} container justifyContent="space-between">
      <Grid item>
        <MuiThemeProvider theme={tableTheme(countriesData?.length > 0)}>
          <MUIDataTable data={countriesData} columns={countriesColumns} options={options} />
        </MuiThemeProvider>
      </Grid>
      <Grid item>
        <MuiThemeProvider theme={tableTheme(citiesData?.length > 0)}>
          <MUIDataTable data={citiesData} columns={citiesColumns} options={options} />
        </MuiThemeProvider>
      </Grid>
      {languagesData?.length > 0 && (
        <Grid item>
          <MuiThemeProvider theme={tableTheme(true)}>
            <MUIDataTable data={languagesData} columns={languagesColumns} options={options} />
          </MuiThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

AlbGeoTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  metric: PropTypes.string.isRequired
};

export default AlbGeoTable;
