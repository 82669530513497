import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

export const ASSOCIATED_ACCOUNTS = gql`
  query($id: ID!) {
    associatedAccounts(id: $id) {
      id
      container_id
      organization_id
      associated_id
      name
      created_by
    }
  }
`;

export const getAssociatedAccounts = async data => apolloClientHelper(ASSOCIATED_ACCOUNTS, data);

export const CREATE_LINK_TOKENS = gql`
  mutation($associatedAccounts: [ID!]!, $accountType: String!) {
    createLinkTokens(associatedAccounts: $associatedAccounts, accountType: $accountType) {
      id
    }
  }
`;

export const createLinkTokens = async data => apolloClientHelper(CREATE_LINK_TOKENS, data, true);
