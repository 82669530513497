/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  text: {
    fontSize: '13px'
  },
  tableHeaderCell: {
    display: 'flex'
  },
  tableHeader: {
    flex: 1,
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase'
  }
});

const tableThemeOverrides = {
  MuiTableCell: {
    root: {
      // padding: '24px', // design has more padding but table is too full
      borderBottom: 'none'
    }
  },
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap'
    }
  },
  MuiPaper: {
    root: {
      flex: 1
    }
  }
};

/**
 * @name TimeToEventBreakdownTable
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const TimeToEventBreakdownTable = props => {
  const { page, setPage, rowsPerPage, data, loading, error } = props;

  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const round = percent => Math.round((percent + Number.EPSILON) * 100) / 100;

  const formatIntervalPercentages = ({
    tte_rate: tteRate,
    lower_confidence_interval: lowerConfidenceInterval,
    upper_confidence_interval: upperConfidenceInterval
  }) =>
    `${tteRate != null ? round(tteRate) : '-'}% (${round(lowerConfidenceInterval) ||
      '--'}% - ${round(upperConfidenceInterval) || '--'}%)`;

  const mapEvent = event => {
    const formattedEvent = {
      id: event.id,
      label: event.label,
      dimension: event.dimension,
      outcome: event.outcome,
      total_sample_size: event.total_sample_size.toLocaleString(),
      total_outcome_sample_size: event.total_outcome_sample_size.toLocaleString()
    };

    // Iterate over the keys of the event object
    Object.keys(event).forEach(key => {
      if (key.startsWith('day_')) {
        formattedEvent[key] = formatIntervalPercentages(event[key]);
      }
    });

    return formattedEvent;
  };

  useEffect(() => {
    // Create an object to store the grouped data
    const groupedData = {};

    if (data?.getSalesforceTimeToEvents?.length) {
      // Group the data by "label" first, then by "tte_days"
      data?.getSalesforceTimeToEvents.forEach(item => {
        const {
          id,
          dimension,
          outcome,
          total_sample_size,
          total_outcome_sample_size,
          tte_days
        } = item;

        const label = item.label || '-';

        if (!groupedData[label]) {
          groupedData[label] = {
            id,
            label,
            dimension,
            outcome,
            total_sample_size,
            total_outcome_sample_size
          };
        }

        const key = `day_${tte_days}`;

        groupedData[label][key] = {
          tte_rate: item.tte_rate,
          lower_confidence_interval: item.lower_confidence_interval,
          upper_confidence_interval: item.upper_confidence_interval
        };
      });

      // Convert the grouped data into an array of objects
      const result = Object.values(groupedData).sort(
        (a, b) => b.total_outcome_sample_size - a.total_outcome_sample_size
      );

      setTableData(result.map(mapEvent));
      setCount(result.length);
    } else {
      setTableData([]);
      setCount(0);
    }
  }, [data]);

  const customValueRender = value => (
    <Typography className={classes.text} style={{ textAlign: 'center' }}>
      {value.toLocaleString()}
    </Typography>
  );

  const customHeadRender = tableMeta => (
    <div className={classes.tableHeaderCell} key={tableMeta.label}>
      <Typography className={classes.tableHeader}>{tableMeta.label}</Typography>
    </div>
  );

  const customCenterHeadRender = tableMeta => (
    <div className={classes.tableHeaderCell} key={tableMeta.label}>
      <Typography align="center" className={classes.tableHeader}>
        {tableMeta.label}
      </Typography>
    </div>
  );

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'label',
      label: 'Field',
      options: { customHeadLabelRender: customHeadRender }
    },
    {
      name: 'total_sample_size',
      label: 'Total Events',
      options: {
        customHeadLabelRender: customCenterHeadRender,
        customBodyRender: customValueRender
      }
    },
    {
      name: 'total_outcome_sample_size',
      label: 'Closed Won Events',
      options: {
        customHeadLabelRender: customCenterHeadRender,
        customBodyRender: customValueRender
      }
    },
    {
      name: 'day_10',
      label: 'Day 10',
      options: { customHeadLabelRender: customHeadRender }
    },
    {
      name: 'day_30',
      label: 'Day 30',
      options: { customHeadLabelRender: customHeadRender }
    },
    {
      name: 'day_90',
      label: 'Day 90',
      options: { customHeadLabelRender: customHeadRender }
    },
    {
      name: 'day_180',
      label: 'Day 180',
      options: { customHeadLabelRender: customHeadRender }
    },
    {
      name: 'day_270',
      label: 'Day 270',
      options: { customHeadLabelRender: customHeadRender }
    },
    {
      name: 'day_360',
      label: 'Day 360',
      options: { customHeadLabelRender: customHeadRender }
    },
    {
      name: 'day_720',
      label: 'Day 720',
      options: { customHeadLabelRender: customHeadRender }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false, // ! TODO - change to true when hooked up to useQuery
    responsive: 'standard',
    sort: false,
    onChangePage: setPage,
    draggableColumns: { enabled: false },
    page,
    rowsPerPage,
    count,
    customFooter: (
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) =>
      CustomFooter(
        footerCount,
        footerPage,
        footerRowsPerPage,
        changeRowsPerPage,
        changePage,
        textLabels,
        [] // rowsPerPageOptions
      )
  };

  return (
    <Grid container className={classes.container}>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      <BoxHeader
        tabs={['Close Rate Breakdown']}
        dates={{
          // start date is today's date
          // end date is 720 days in the future the prediction is for
          start: moment(),
          end: moment().add(720, 'days')
        }}
      />
      <Grid container style={{ overflowX: 'auto' }}>
        <AlbTable
          tableData={tableData}
          tableColumns={tableColumns}
          tableOptions={tableOptions}
          tableThemeOverrides={tableThemeOverrides}
        />
      </Grid>
    </Grid>
  );
};

TimeToEventBreakdownTable.defaultProps = {
  loading: false,
  error: null,
  data: null
};

TimeToEventBreakdownTable.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  data: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.shape()
};

export default TimeToEventBreakdownTable;
