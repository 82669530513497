/* eslint-disable no-else-return */
/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CostToRecreateTopTable from 'components/AlbTable/CostToRecreateTopTable';
import AlbLoading from 'components/AlbLoading';

const useStyles = makeStyles({
  image: {
    objectFit: 'cover',
    width: '58px',
    height: '58px',
    float: 'left',
    marginRight: '10px',
    borderRadius: '3px'
  },
  title: {
    fontWeight: '600',
    fontSize: '13px',
    width: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

/**
 * @summary Renders Top Articles Table for cost to recreate reports
 * @name TopArticleTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.startDate - Start date for all articles published on
 * @param {String} props.endDate - End date for all articles published by
 * @param {Number} props.searchID - Article search ID to pass to GQL
 * @param {String} props.valuationType - The type of 'cpm' or 'cpa' calculation
 * @return {Object} - React JSX
 */
const TopArticleTable = props => {
  const { isLoading, topArticles } = props;
  const classes = useStyles();

  // formatTable helper function
  const formatTable = dataSet =>
    dataSet.map(({ article, shares, valuation }) => {
      return {
        article: (
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Grid>
              {article?.thumbnail ? (
                <img
                  src={article?.thumbnail}
                  width={58}
                  height={58}
                  className={classes.image}
                  alt=""
                />
              ) : (
                ''
              )}
            </Grid>
            <Box>
              <Typography className={classes.title}>{article?.title}</Typography>
            </Box>
          </Box>
        ),
        total_share_count: shares,
        valuation
      };
    });

  const tableColumns = [
    { name: 'article', label: 'ARTICLE' },
    { name: 'total_share_count', label: 'SHARES' },
    { name: 'valuation', label: 'VALUE' }
  ];

  if (isLoading) {
    return <AlbLoading />;
  }

  return (
    <CostToRecreateTopTable tableData={formatTable(topArticles)} tableColumns={tableColumns} />
  );
};

TopArticleTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  topArticles: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default TopArticleTable;
