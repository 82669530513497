import {
  // OptimizePath,
  // PlanPath,
  HypeAndFlowAnalyticsPath,
  CreateEarnedMediaAnalyticsPath,
  ShareOfVoiceAnalyticsPath,
  ShareOfVoiceAnalyticsPathMatch,
  ResurgenceAnalyticsPath,
  ResurgenceAnalyticsPathMatch,
  CreateThirdPartyMediaAnalyticsPath,
  CreateThirdPartyMediaAnalyticsPathMatch,
  AdobeAnalyticsLinkAccountPath,
  LinkAdobeAnalyticsSegmentPath,
  AdobeAnalyticsManagePredictionEventsPath,
  AppStoreConnectLinkAccountPath,
  GooglePlayConsoleLinkAccountPath,
  CostToRecreateAnalyticsPath,
  CostToRecreateAnalyticsPathMatch,
  CreateCostToRecreateAnalyticsPath,
  ProgramLiftPath,
  ProgramLiftAnalyticsPath,
  SalesforcePath,
  InsightPath,
  CausalPath
} from 'util/paths';

export const FEATURE_FLAG = {
  CRM: 'CrmToggle',
  SOCIAL_ANALYTICS: 'SocialAnalyticsToggle',
  EARNED_AND_OWNED: 'EarnedAndOwnedToggle',
  SHARE_OF_VOICE: 'ShareOfVoiceToggle',
  SMS: 'SMSToggle',
  RESURGENCE: 'ResurgenceToggle',
  THIRD_PARTY_MEDIA: 'ThirdPartyMediaToggle',
  THIRD_PARTY_MEDIA_SETTINGS: 'ThirdPartyMediaSettingsToggle',
  ADOBE_ANALYTICS: 'AdobeAnalyticsLinking',
  ADOBE_ANALYTICS_CONVERSION_PREDICTION: 'AdobeAnalyticsConversionPredictionToggle',
  APP_STORE_CONNECT: 'AppStoreConnectLinking',
  GOOGLE_PLAY_CONSOLE: 'GooglePlayConsoleLinking',
  ATTENTION_ACTION: 'AttentionActionToggle',
  COST_TO_RECREATE: 'CostToRecreateToggle',
  // PLAN_DASHBOARD: 'PlanDashboardToggle',
  PROGRAM_LIFT_REPORT: 'ProgramLiftReportToggle',
  TWITTER_LINKING: 'TwitterLinking',
  SALESFORCE: 'SalesforceToggle',
  SALESFORCE_LINKING: 'SalesforceLinking',
  SALESFORCE_FUNNEL: 'SalesforceFunnel',
  TIKTOK_LINKING: 'TikTokLinking',
  INSIGHT_DASHBOARD: 'InsightDashboardToggle',
  CAUSAL_DASHBOARD: 'CausalDashboardToggle',
  GA_LINKING: 'GoogleAnalyticsLinking',
  AA_LINKING: 'AdobeAnalyticsLinking'
};

// list all publicly disabled routes.
const disabledFeaturesByDefault = [
  FEATURE_FLAG.CRM,
  FEATURE_FLAG.SOCIAL_ANALYTICS,
  FEATURE_FLAG.EARNED_AND_OWNED,
  FEATURE_FLAG.SHARE_OF_VOICE,
  FEATURE_FLAG.RESURGENCE,
  FEATURE_FLAG.THIRD_PARTY_MEDIA,
  FEATURE_FLAG.ADOBE_ANALYTICS,
  FEATURE_FLAG.ADOBE_ANALYTICS_CONVERSION_PREDICTION,
  FEATURE_FLAG.APP_STORE_CONNECT,
  FEATURE_FLAG.GOOGLE_PLAY_CONSOLE,
  FEATURE_FLAG.ATTENTION_ACTION,
  FEATURE_FLAG.COST_TO_RECREATE,
  // FEATURE_FLAG.PLAN_DASHBOARD,
  FEATURE_FLAG.PROGRAM_LIFT_REPORT,
  FEATURE_FLAG.TWITTER_LINKING,
  FEATURE_FLAG.SALESFORCE,
  FEATURE_FLAG.SALESFORCE_LINKING,
  FEATURE_FLAG.SALESFORCE_FUNNEL,
  FEATURE_FLAG.TIKTOK_LINKING,
  FEATURE_FLAG.INSIGHT_DASHBOARD
];

const mapToggleToFeatureRoute = toggleName => {
  let paths = [];

  // add all feature toggles here with their corresponding routes array.
  switch (toggleName) {
    case FEATURE_FLAG.SOCIAL_ANALYTICS:
      paths = [HypeAndFlowAnalyticsPath];
      break;
    case FEATURE_FLAG.EARNED_AND_OWNED:
      paths = [CreateEarnedMediaAnalyticsPath];
      break;
    case FEATURE_FLAG.SHARE_OF_VOICE:
      paths = [ShareOfVoiceAnalyticsPath, ShareOfVoiceAnalyticsPathMatch];
      break;
    case FEATURE_FLAG.RESURGENCE:
      paths = [ResurgenceAnalyticsPath, ResurgenceAnalyticsPathMatch];
      break;
    case FEATURE_FLAG.THIRD_PARTY_MEDIA:
      paths = [CreateThirdPartyMediaAnalyticsPath, CreateThirdPartyMediaAnalyticsPathMatch];
      break;
    case FEATURE_FLAG.ADOBE_ANALYTICS:
      paths = [AdobeAnalyticsLinkAccountPath, LinkAdobeAnalyticsSegmentPath];
      break;
    case FEATURE_FLAG.ADOBE_ANALYTICS_CONVERSION_PREDICTION:
      paths = [AdobeAnalyticsManagePredictionEventsPath];
      break;
    case FEATURE_FLAG.APP_STORE_CONNECT:
      paths = [AppStoreConnectLinkAccountPath];
      break;
    case FEATURE_FLAG.GOOGLE_PLAY_CONSOLE:
      paths = [GooglePlayConsoleLinkAccountPath];
      break;
    // case FEATURE_FLAG.ATTENTION_ACTION:
    //   paths = [OptimizePath];
    //   break;
    case FEATURE_FLAG.COST_TO_RECREATE:
      paths = [
        CostToRecreateAnalyticsPath,
        CostToRecreateAnalyticsPathMatch,
        CreateCostToRecreateAnalyticsPath
      ];
      break;
    // case FEATURE_FLAG.PLAN_DASHBOARD:
    //   paths = [PlanPath];
    //   break;
    case FEATURE_FLAG.PROGRAM_LIFT_REPORT:
      paths = [ProgramLiftPath, ProgramLiftAnalyticsPath];
      break;
    case FEATURE_FLAG.SALESFORCE:
      paths = [SalesforcePath];
      break;
    case FEATURE_FLAG.INSIGHT_DASHBOARD:
      paths = [InsightPath];
      break;
    case FEATURE_FLAG.CAUSAL_DASHBOARD:
      paths = [CausalPath];
      break;
    default:
      paths = [];
  }

  return paths;
};

export const getDisabledRoutes = featureFlags => {
  const disabledRoutes = [];
  let disabledFlagNames = [];

  if (featureFlags.length) {
    disabledFlagNames = featureFlags.filter(flag => !flag.enabled).map(flag => flag.name);
  } else {
    disabledFlagNames = disabledFeaturesByDefault;
  }

  // check which features are disabled for user.
  disabledFlagNames.forEach(name => {
    const disabledRoutesArray = mapToggleToFeatureRoute(name);

    if (disabledRoutesArray) {
      disabledRoutes.push(...disabledRoutesArray);
    }
  });

  return disabledRoutes;
};
