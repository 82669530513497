/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ATTENTION_ACTION_DELETE_LABEL_FILTER } from 'gql/attentionAction';
import AlbError from 'components/AlbError';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { showToast } from 'contexts/ToastContext';
import { getAttentionActionTitle } from 'util/detectedEvents';

const refetchQueries = ['paginatedAttentionActionLabelFilters'];

const DeleteLabelFilterModal = props => {
  const { eventType, eventLabel, isModalOpen, onChange } = props;

  const [deleteLabelFilter, { error, data }] = useMutation(ATTENTION_ACTION_DELETE_LABEL_FILTER, {
    refetchQueries
  });

  useEffect(() => {
    if (data?.attentionActionDeleteLabelFilter?.result) {
      showToast('Event restored.', 'success');
      onChange({ isModalOpen: false });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onChange({ isModalOpen: false });
    }
  }, [error]);

  return (
    <>
      {isModalOpen && error && <AlbError toast error={error} />}
      <AlembicModalConfirm
        isDelete
        isOpen={isModalOpen}
        title="Restore Event"
        body={
          <span style={{ fontFamily: 'Poppins' }}>
            Are you sure you want to restore this event?&nbsp;
            <span
              style={{
                color: '#0A1734',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                width: '500px'
              }}
            >
              {getAttentionActionTitle(eventType)}: {eventLabel}
            </span>
          </span>
        }
        cancelTitle="Cancel"
        confirmTitle="Restore"
        handleCancel={() => onChange({ isModalOpen: false })}
        handleConfirm={() =>
          deleteLabelFilter({ variables: { event_type: eventType, event_label: eventLabel } })
        }
      />
    </>
  );
};

DeleteLabelFilterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  eventType: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired
};

export default DeleteLabelFilterModal;
