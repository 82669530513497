import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Star } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { ShareOfVoiceAnalyticsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  labelOverride: {
    color: '#32327D',
    fontWeight: '700'
  },
  starIcon: {
    color: '#FBBB45'
  }
});

/**
 * @method
 * @summary This component renders the name column of @see ShareOfVoiceTable
 * @name ShareOfVoiceName
 * @param {Object} props - React props passed to this component
 * @param {Object} props.history - The history object from react router
 * @param {string} props.shareOfVoiceId - A string value for the share of voice id
 * @param {string} props.shareOfVoiceName - A string value for the share of voice name
 * @param {boolean} props.isDefault - Determines if the share of voice report is the default
 * @return {Object} - React JSX
 */
const ShareOfVoiceName = props => {
  const { history, shareOfVoiceId, shareOfVoiceName, isDefault } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        variant="text"
        onClick={() => {
          goToRoute(`${ShareOfVoiceAnalyticsPath}/${shareOfVoiceId}`, history);
        }}
        classes={{ label: classes.labelOverride }}
      >
        {shareOfVoiceName}
      </Button>
      {isDefault && <Star className={classes.starIcon} />}
    </div>
  );
};

ShareOfVoiceName.propTypes = {
  history: PropTypes.shape().isRequired,
  shareOfVoiceId: PropTypes.string.isRequired,
  shareOfVoiceName: PropTypes.string.isRequired,
  isDefault: PropTypes.bool
};

ShareOfVoiceName.defaultProps = {
  isDefault: false
};

const ShareOfVoiceNameWithRouter = withRouter(ShareOfVoiceName);

/**
 * @method
 * @summary This is a wrapper function for @see MUIDataTable customBodyRender to render @see ShareOfVoiceName
 * @name customNameRender
 * @param {Object} name - Object passed from @see MUIDataTable
 * @param {string} props.shareOfVoiceId - A string value for the share of voice id
 * @param {string} props.shareOfVoiceName - A string value for the share of voice name
 * @param {boolean} props.isDefault - Determines if the share of voice report is the default
 * @return {Object} - React JSX
 */
const customNameRender = name => (
  <ShareOfVoiceNameWithRouter
    shareOfVoiceId={name.shareOfVoiceId}
    shareOfVoiceName={name.shareOfVoiceName}
    isDefault={name.isDefault}
  />
);

export default customNameRender;
