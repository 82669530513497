import { gql } from '@apollo/client';

const PRIVILEGES = gql`
  query {
    listAllPrivileges {
      id
      parent_id
      display_name
      short_name
      description
      created_at
      updated_at
    }
  }
`;

export default PRIVILEGES;
