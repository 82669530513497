import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import colors from 'util/colors';

const AlbSwitch = withStyles({
  switchBase: {
    color: colors.navy,
    '&$checked': {
      color: colors.navy
    },
    '&$checked + $track': {
      backgroundColor: colors.darkGray10
    }
  },
  checked: {},
  track: {}
})(Switch);

export default AlbSwitch;
