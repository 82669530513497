import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import colors from 'util/colors';
import getValueAbbreviation from 'util/getValueAbbreviation';

const useStyles = makeStyles({
  chartOverflow: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& > canvas': {
      width: '100% !important'
    }
  }
});

/**
 * @method
 * @summary This component renders a bar graph
 * @name AlbBarGraph
 * @param {Object} props - React props passed to this component
 * @param {Object|Function} props.data - An object containing ChartJS specific properties such as datasets and labels
 * @param {Object} props.stacked - A boolean indicating whether the datasets should be stacked
 * @param {Boolean} props.simple - A boolean indicating whether to hide ticks (labels)
 * @param {Function} props.customTooltip - A custom tooltip render function
 * @param {boolean} props.currency - Indicates the tooltip values should prepend $
 * @return {Object} - React JSX
 */
const AlbBarGraph = props => {
  const { data, stacked, simple, customTooltip, currency } = props;
  const classes = useStyles();

  const graphOptions = {
    hover: {
      mode: 'nearest',
      intersect: true
    },
    legend: { display: false },
    tooltips: {
      enabled: !customTooltip,
      mode: 'label',
      intersect: false,
      callbacks: {
        label(tooltipItem, chartData) {
          const positionText = chartData.datasets[tooltipItem.datasetIndex].label;
          const valueText = Math.round(
            chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          );
          const formattedValueText = currency
            ? getValueAbbreviation(valueText, true)
            : valueText.toLocaleString();

          if (positionText) {
            return `${positionText} | ${formattedValueText}`;
          }

          return formattedValueText;
        }
      },
      custom: customTooltip
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: colors.gray,
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600'
          },
          stacked
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            precision: 2,
            fontColor: colors.doveGray,
            fontSize: 10,
            fontStyle: '600',
            beginAtZero: true,
            callback: value => {
              if (value == null) {
                return '-';
              }

              if (typeof value === 'number') {
                return value.toLocaleString();
              }

              return value;
            }
          },
          stacked,
          beginAtZero: true
        }
      ],
      ...(simple && {
        xAxes: [
          {
            barThickness: 100,
            ticks: { display: false },
            gridLines: { display: false },
            stacked: true
          }
        ],
        yAxes: [
          {
            ticks: { display: false },
            gridLines: { display: true, zeroLineColor: '#6F6F6F', color: 'transparent' },
            stacked: true
          }
        ]
      })
    }
  };

  return (
    <div className={classes.container}>
      <Bar data={data} options={graphOptions} />
    </div>
  );
};

AlbBarGraph.propTypes = {
  data: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  stacked: PropTypes.bool,
  simple: PropTypes.bool,
  customTooltip: PropTypes.func,
  currency: PropTypes.bool
};

AlbBarGraph.defaultProps = {
  stacked: true,
  simple: false,
  customTooltip: null,
  currency: false
};

export default AlbBarGraph;
