import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell, Collapse, IconButton } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { isPlainObject } from 'lodash';

const AuditLogMeta = props => {
  const { meta, columnCount } = props;

  const [collapse, setCollapse] = useState({});

  const entries = Object.entries(meta || {}).filter(
    ([key]) => !['createdAt', 'updatedAt'].includes(key)
  );

  return (
    <>
      {entries.map(([key, value], i) => {
        const canExpand = isPlainObject(value);
        const isExpanded = collapse[i];

        return (
          <TableRow key={key}>
            <TableCell
              size="small"
              style={{
                ...(canExpand && isExpanded && { verticalAlign: 'top', paddingTop: '16px' }),
                width: 1
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
                {canExpand && (
                  <IconButton
                    onClick={() => setCollapse({ ...collapse, [i]: !isExpanded })}
                    style={!isExpanded ? { transform: 'rotate(270deg)' } : {}}
                    size="small"
                  >
                    <ExpandMore />
                  </IconButton>
                )}
                <div style={{ whiteSpace: 'nowrap' }}>
                  {key
                    .split('_')
                    .map(word =>
                      word === 'id'
                        ? word.toUpperCase()
                        : word.replace(word.charAt(0), word.charAt(0).toUpperCase())
                    )
                    .join(' ')}
                </div>
              </div>
            </TableCell>
            <TableCell colSpan={columnCount} style={{ ...(canExpand && { padding: '0px' }) }}>
              {canExpand ? (
                <Collapse in={isExpanded}>
                  <div>
                    <Table>
                      <TableBody>
                        <AuditLogMeta meta={value} columnCount={columnCount} />
                      </TableBody>
                    </Table>
                  </div>
                </Collapse>
              ) : (
                <div>{value}</div>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

AuditLogMeta.propTypes = {
  meta: PropTypes.shape(),
  columnCount: PropTypes.number.isRequired
};

AuditLogMeta.defaultProps = {
  meta: null
};

export default AuditLogMeta;
