import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  outerColumn: {
    height: '100%',
    background: 'rgba(151, 151, 151, 0.1)',
    padding: '8px 12px'
  },
  middleColumn: {
    height: '100%',
    padding: '8px 12px'
  },
  topText: {
    color: '#32327D',
    fontWeight: '500',
    fontSize: '30px'
  },
  middleText: {
    color: '#6F6F6F',
    fontWeight: '500',
    fontSize: '9px',
    marginTop: '-6px'
  },
  bottomText: {
    color: '#6F6F6F',
    fontWeight: '500',
    fontSize: '20px'
  },
  primaryText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734'
  }
});

/**
 * @method
 * @summary This component renders a column for @see AgeRangePost
 * @name AgeRangePostColumn
 * @param {Object} props - React props passed to this component
 * @param {boolean} props.isOuterColumn - A boolean value for determining the outer columns
 * @param {number} props.value - A number for the age value of the column
 * @param {string} props.group -  A string for the age group of the column
 * @return {Object} - React JSX
 */
const AgeRangePostColumn = props => {
  const { isOuterColumn, value, group } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      className={isOuterColumn ? classes.outerColumn : classes.middleColumn}
    >
      {!value && !isOuterColumn && (
        <Typography className={classes.primaryText}>
          There is not any data to display at this time.
        </Typography>
      )}
      {value !== null && (
        <>
          <Typography className={classes.topText}>{value}</Typography>
          <Typography className={classes.middleText}>Days</Typography>
          <Typography className={classes.bottomText}>{group}</Typography>
        </>
      )}
    </Grid>
  );
};

AgeRangePostColumn.propTypes = {
  isOuterColumn: PropTypes.bool,
  value: PropTypes.number,
  group: PropTypes.string
};

AgeRangePostColumn.defaultProps = {
  isOuterColumn: false,
  value: null,
  group: null
};

export default AgeRangePostColumn;
