import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SocialLogo from 'components/SocialLogo';
import getEventFormatted from 'components/DetectedEventsFormatted';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { getAttentionActionTitle } from 'util/detectedEvents';
import DetectedEventTableGraph from './DetectedEventTableGraph';

const styles = makeStyles({
  container: props => {
    const { onClick, hasIndex, isCorrelation } = props;

    if (onClick) {
      return {
        position: 'relative',
        display: 'grid',
        ...(hasIndex && { gridTemplateColumns: '0.5fr 0.75fr 3fr 2fr 1.25fr 1.25fr 1.25fr' }),
        ...(!hasIndex && { gridTemplateColumns: '0.75fr 3fr 2fr 1.25fr 1.25fr 1.25fr' }),
        gridTemplateRows: '168px',
        ...(isCorrelation && {
          gridTemplateColumns: '0.75fr 3fr 5.75fr',
          gridTemplateRows: 'unset',
          minHeight: '168px'
        }),
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#E3E3ED'
        }
      };
    }

    return {
      position: 'relative',
      display: 'grid',
      ...(hasIndex && { gridTemplateColumns: '0.5fr 0.75fr 3fr 2fr 1.25fr 1.25fr 1.25fr' }),
      ...(!hasIndex && { gridTemplateColumns: '0.75fr 3fr 2fr 1.25fr 1.25fr 1.25fr' }),
      gridTemplateRows: '168px',
      ...(isCorrelation && {
        gridTemplateColumns: '0.75fr 3fr 5.75fr',
        gridTemplateRows: 'unset',
        minHeight: '168px'
      })
    };
  },
  indexColumn: {
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0A1734'
  },
  indexColumnText: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF'
  },
  sourceColumn: {
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '13px 0px'
  },
  eventColumn: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    padding: '13px 0px',
    fontFamily: 'Poppins',
    fontSize: '18px',
    lineHeight: '27px',
    wordBreak: 'break-word',

    '& strong': {
      color: '#32327D'
    },

    '& div': {
      display: '-webkit-box',
      lineClamp: 5,
      WebkitLineClamp: 5,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    }
  },
  graphColumn: {
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    padding: '13px'
  },
  medianColumn: {
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    backgroundColor: 'rgba(151, 151, 151, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  medianColumnText: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '27px',
    color: '#6F6F6F'
  },
  metricColumn: {
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  metricColumnText: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '27px',
    color: '#32327D'
  },
  metricSubTextContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center'
  },
  metricSubText: {
    color: '#6F6F6F',
    fontWeight: '500',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase'
  },
  valueColumn: {
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    backgroundColor: 'rgba(151, 151, 151, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  valueColumnText: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '27px',
    color: '#00A84F'
  },
  tagsColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignSelf: 'stretch',
    alignContent: 'center',
    justifySelf: 'stretch',
    padding: '13px 0px',
    paddingLeft: '40px',
    fontFamily: 'Poppins'
  },
  tag: {
    backgroundColor: '#E6E8EB',
    padding: '4px 10px',
    marginRight: '10px',
    marginBottom: '10px',
    borderRadius: '3px',
    fontSize: '10px',
    fontWeight: 500,
    color: '#0A1734'
  }
});

const DetectedEventTableRow = React.forwardRef((props, ref) => {
  const {
    index,
    source,
    event,
    dateRange,
    selectedDate,
    graph,
    currentMedian,
    metric,
    unit,
    aboveMedian,
    belowMedian,
    valueChange,
    onClick,
    popout
  } = props;
  const isCorrelation = event.type === 'CORRELATION';

  const classes = styles({ hasIndex: index != null, isCorrelation, onClick });

  return (
    <div
      tabIndex="0"
      role="button"
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      className={classes.container}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      ref={ref}
    >
      {index != null && (
        <div className={classes.indexColumn}>
          <Typography className={classes.indexColumnText}>{index}</Typography>
        </div>
      )}

      <div className={classes.sourceColumn}>{source && <SocialLogo type={source} />}</div>

      <div className={classes.eventColumn}>
        {event && getEventFormatted(event, { truncate: true, popout })}
      </div>

      {!isCorrelation && (
        <>
          <div className={classes.graphColumn}>
            {graph && (
              <DetectedEventTableGraph
                dateRange={dateRange}
                selectedDate={selectedDate}
                {...props}
              />
            )}
          </div>

          <div className={classes.medianColumn}>
            <Typography className={classes.medianColumnText}>
              {currentMedian == null ? '-' : currentMedian}
            </Typography>
          </div>

          <div className={classes.metricColumn}>
            <div style={{ position: 'relative' }}>
              <Typography className={classes.metricColumnText}>
                {metric == null ? '-' : metric}
              </Typography>
              <div className={classes.metricSubTextContainer}>
                {unit && <Typography className={classes.metricSubText}>{unit}</Typography>}
                {aboveMedian && (
                  <Typography className={classes.metricSubText}>
                    <span style={{ color: '#00A84F' }}>
                      {Number.isFinite(aboveMedian) ? (
                        `+${getValueAbbreviation(aboveMedian)}%`
                      ) : (
                        <>&infin;%</>
                      )}
                    </span>{' '}
                    above median
                  </Typography>
                )}
                {belowMedian && (
                  <Typography className={classes.metricSubText}>
                    <span style={{ color: '#E81828' }}>{getValueAbbreviation(belowMedian)}%</span>{' '}
                    below median
                  </Typography>
                )}
              </div>
            </div>
          </div>

          <div className={classes.valueColumn}>
            <Typography
              className={unit === 'revenue' ? classes.valueColumnText : classes.medianColumnText}
            >
              {valueChange == null ? '-' : valueChange}
            </Typography>
          </div>
        </>
      )}

      {isCorrelation && (
        <div className={classes.tagsColumn}>
          {[...new Set(event.detectedEvents.map(detectedEvent => detectedEvent.event_type))].map(
            eventType => (
              <span key={eventType} className={classes.tag}>
                {getAttentionActionTitle(eventType)}
              </span>
            )
          )}
        </div>
      )}
    </div>
  );
});

DetectedEventTableRow.propTypes = {
  index: PropTypes.number,
  source: PropTypes.string,
  event: PropTypes.shape(),
  dateRange: PropTypes.shape(),
  selectedDate: PropTypes.shape(),
  graph: PropTypes.bool,
  currentMedian: PropTypes.string,
  metric: PropTypes.string,
  unit: PropTypes.string,
  aboveMedian: PropTypes.number,
  belowMedian: PropTypes.number,
  valueChange: PropTypes.string,
  onClick: PropTypes.func,
  popout: PropTypes.bool
};

DetectedEventTableRow.defaultProps = {
  index: null,
  source: null,
  currentMedian: null,
  event: null,
  dateRange: null,
  selectedDate: null,
  graph: null,
  metric: null,
  unit: null,
  aboveMedian: null,
  belowMedian: null,
  valueChange: null,
  onClick: null,
  popout: null
};

export default DetectedEventTableRow;
