import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Bar } from 'react-chartjs-2';
import { SHORTLINK_TIME_SERIES } from 'gql/analytics';
import colors from 'util/colors';
import { getDatesForAnalytics } from 'util/date';
import moment from 'moment';

const ShortlinkClicks = props => {
  const { dates, shortlink } = props;
  const [graphData, setGraphData] = useState([]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const { data: shortlinkData } = useQuery(SHORTLINK_TIME_SERIES, {
    variables: {
      column: 'referrer_source',
      startDate,
      endDate,
      // eventual support for querying outside of the user's current container
      // containerIds: [sessionStorage.current_container],
      campaignIds: [],
      ...(shortlink !== null && { shortlinkIds: [shortlink] })
    }
  });

  useEffect(() => {
    if (shortlinkData?.shortlinkTimeSeries) {
      setGraphData(shortlinkData.shortlinkTimeSeries);
    }
  }, [shortlinkData]);

  const data = {
    labels: graphData.map(dataPoint =>
      moment
        .utc(dataPoint.x)
        .format('MMM D')
        .toUpperCase()
    ),
    datasets: [
      {
        data: graphData.map(dataPoint => dataPoint.y),
        backgroundColor: colors.darkBlue
      }
    ]
  };

  const options = {
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false
          },
          ticks: {
            fontColor: colors.lightGray20,
            precision: 0
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.lightGray50,
            beginAtZero: true
          }
        }
      ]
    }
  };

  return <Bar data={data} options={options} />;
};

ShortlinkClicks.propTypes = {
  dates: PropTypes.shape().isRequired,
  shortlink: PropTypes.string
};

ShortlinkClicks.defaultProps = {
  shortlink: null
};

export default ShortlinkClicks;
