/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Avatar from 'react-avatar-edit';
import uuidv4 from 'uuid';
import * as filestack from 'filestack-js';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import { Button, IconButton, Grid } from '@material-ui/core';
import apiAvatarGetUploadPolicy from 'gql/avatar';
import { UPDATE_CONTAINER, UPDATE_AVATAR, DELETE_CONTAINER_AVATAR } from 'gql/container';
import AlembicModalForm from 'components/AlembicModalForm';
import AlembicInputLabel from 'components/AlembicInputLabel';
import { DefaultUserAvatar } from 'util/assets';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import store from 'store';
import { updateContainer } from 'actions/authActions';

const useStyles = makeStyles({
  avatarContainer: {
    height: '200px'
  },
  avatarPreview: {
    position: 'relative',
    display: 'flex',
    height: '200px',
    minWidth: '200px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarDelete: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1
  },
  avatarUpload: {
    position: 'relative'
  },
  avatarButtons: {
    marginTop: '16px',

    '& button': {
      margin: '0px 10px'
    }
  },
  label: {
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0A1734',
    marginLeft: '8px'
  },
  checkBox: {
    padding: '0'
  }
});

const EditForm = props => {
  const { containerData, refetchContainerData, mutate } = props;
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [uploadButton, setUploadButton] = useState(false);
  const [uploadButtonText, setUploadButtonText] = useState('Update Avatar');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);

  // Form state variables
  const [containerName, setContainerName] = useState(containerData.name);

  const classes = useStyles();

  const [updateContainerMutation, { error: updateError, data: updateData }] = useMutation(
    UPDATE_CONTAINER
  );
  const [deleteContainerAvatar, { error: deleteError, data: deleteData }] = useMutation(
    DELETE_CONTAINER_AVATAR
  );

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidContainerName', value => {
      if (value.length > 0) {
        const containerRegexp = /^[a-zA-Z0-9.,!&\-_ ()#'/]+$/;
        return containerRegexp.test(value);
      }
      return false;
    });
  }, []);

  useEffect(() => {
    if (updateData?.updateContainer?.id) {
      showToast('Container updated', 'success');
    }
  }, [updateData]);

  useEffect(() => {
    if (deleteData?.deleteContainerAvatar?.id) {
      showToast('Avatar deleted', 'success');
      refetchContainerData();
    }
  }, [deleteData]);

  useEffect(() => {
    if (updateError || deleteData) {
      handleGraphQLError(updateError || deleteData);
    }
  }, [updateError, deleteError]);

  const onUpdateProgress = progObj => {
    setUploadButtonText(`${progObj.totalPercent}%`);
  };

  const onUpdateAvatar = async () => {
    setUploadButton(true);
    await apiAvatarGetUploadPolicy().then(result => {
      // now we have the policy, we can request an upload directly from the user to filestack
      const client = filestack.init(process.env.FS_API_KEY);
      const token = {}; // control token, can be used with .pause/.cancel
      const securityObj = {
        policy: result.payload.data.getAvatarUploadPolicy.policy,
        signature: result.payload.data.getAvatarUploadPolicy.signature
      };
      // this is our only authorized write-path
      const uploadPath = `/${process.env.NODE_ENV || 'development'}/avatars/${uuidv4()}`;
      const storeOpts = {
        filename: uploadPath
      };

      client
        .upload(avatarPreview, { onProgress: onUpdateProgress }, storeOpts, token, securityObj)
        .then(async res => {
          const uploadResult = await mutate({
            variables: { id: containerData.id, handle: res.handle }
          });

          if (uploadResult?.data?.updateContainerAvatar) {
            setEditAvatar(false);
            setAvatarPreview(null);
            setUploadButton(false);
            setUploadButtonText('Update Avatar');
            refetchContainerData();
          }
        })
        .catch(() => {
          showToast('Upload failed', 'error');
        });
    });
  };

  const deleteFields = [
    {
      fieldName: 'avatar',
      title: 'Are you sure you want to delete the avatar?',
      type: 'static'
    }
  ];

  return (
    <>
      <ValidatorForm
        onSubmit={() =>
          updateContainerMutation({
            variables: {
              id: containerData.id,
              name: containerName
            }
          }).then(response => {
            if (response?.data?.updateContainer) {
              store.dispatch(updateContainer(response.data.updateContainer));
            }
          })
        }
      >
        <Grid container spacing={2}>
          <Grid item container justifyContent="space-evenly">
            <Grid
              item
              container
              justifyContent="space-evenly"
              alignItems="center"
              className={classes.avatarContainer}
            >
              <Grid item className={classes.avatarPreview}>
                {!editAvatar ? (
                  <>
                    {containerData?.avatar_url && (
                      <Grid item className={classes.avatarDelete}>
                        <IconButton onClick={() => setOpenDeleteModal(true)} size="small">
                          <Cancel />
                        </IconButton>
                      </Grid>
                    )}
                    <img src={containerData?.avatar_url || DefaultUserAvatar} alt="" />
                  </>
                ) : (
                  <Avatar
                    width={200}
                    height={200}
                    onCrop={image => setAvatarPreview(image)}
                    onClose={() => setAvatarPreview(null)}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item className={classes.avatarButtons}>
              <Grid item className={!avatarPreview ? classes.avatarUpload : ''}>
                {!editAvatar && (
                  <Button onClick={() => setEditAvatar(true)} variant="contained" color="primary">
                    Upload Avatar
                  </Button>
                )}
                {(avatarPreview || editAvatar) && (
                  <Button
                    disabled={uploadButton}
                    onClick={() => {
                      setEditAvatar(false);
                      setAvatarPreview(null);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Cancel
                  </Button>
                )}
                {avatarPreview && (
                  <Button
                    disabled={uploadButton}
                    onClick={onUpdateAvatar}
                    variant="contained"
                    color="primary"
                  >
                    {uploadButtonText}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AlembicInputLabel primaryText="Container Name" />
            <TextValidator
              fullWidth
              variant="filled"
              placeholder="Container Name"
              value={containerName}
              onChange={event => setContainerName(event.target.value)}
              validators={['required', 'isValidContainerName']}
              errorMessages={[
                '* Required',
                "Container name must be alpha-numeric with limited symbols (! # & - _ ( ) / . ' )"
              ]}
            />
          </Grid>
          <Grid item container>
            <Button fullWidth type="submit" variant="contained" color="primary">
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>

      <AlembicModalForm
        title="Delete Container Avatar"
        saveTitle="Delete"
        cancelTitle="Cancel"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleConfirm={() => {
          deleteContainerAvatar({
            variables: { id: containerData.id }
          }).then(() => setOpenDeleteModal(false));
        }}
        fields={deleteFields}
        currentObject={{}}
        variant="delete"
      />
    </>
  );
};

EditForm.propTypes = {
  containerData: PropTypes.shape().isRequired,
  refetchContainerData: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired
};

export default compose(graphql(UPDATE_AVATAR))(EditForm);
