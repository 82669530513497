import { gql } from '@apollo/client';

const WEB_AND_APPS_GOALS = gql`
  query webAndAppsGoal(
    $linkTokens: [ID!]!
    $isPersistent: Boolean
    $opportunityFromLead: Boolean
    $dimension: String
    $metric: String!
  ) {
    webAndAppsGoal(
      linkTokens: $linkTokens
      isPersistent: $isPersistent
      metric: $metric
      opportunityFromLead: $opportunityFromLead
      dimension: $dimension
    ) {
      id
      title
      goalRow {
        id
        name
        dataset {
          trendUpper {
            x
            y
          }
          trend {
            x
            y
          }
          trendLower {
            x
            y
          }
          yhatUpper {
            x
            y
          }
          yhat {
            x
            y
          }
          yhatLower {
            x
            y
          }
          trendUpperForecast {
            x
            y
          }
          trendForecast {
            x
            y
          }
          trendLowerForecast {
            x
            y
          }
          yhatUpperForecast {
            x
            y
          }
          yhatForecast {
            x
            y
          }
          yhatLowerForecast {
            x
            y
          }
          value {
            x
            y
          }
        }
        goalChangePoint {
          x
        }
        thirtyDayForecast
        sixtyDayForecast
        ninetyDayForecast
      }
    }
  }
`;

export default WEB_AND_APPS_GOALS;
