import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { showToast } from 'contexts/ToastContext';
import Banner from 'components/Banner';
import uuidv4 from 'uuid';

const AlbError = props => {
  const { error, generic, toast, refetch } = props;

  if (generic) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Banner message="An error has occurred" variant="filled" severity="error" />
        {refetch && (
          <Button variant="contained" color="primary" onClick={() => refetch()}>
            Try Again
          </Button>
        )}
      </Grid>
    );
  }

  if (toast && error.graphQLErrors) {
    error.graphQLErrors.forEach(gqlError => {
      if (toast) {
        showToast(gqlError.message, 'error');
      }
    });
  }

  if (toast && error.networkError) {
    if (toast) {
      showToast('Cannot reach the Alembic API.', 'error');
    }
  }

  if (!toast) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {error.networkError && (
          <Banner message="Cannot reach the Alembic API." variant="filled" severity="error" />
        )}

        {error.graphQLErrors &&
          error.graphQLErrors.map(gqlError => (
            <Banner
              key={uuidv4()}
              message={gqlError?.extensions?.userFacingErrorMessage || gqlError.message}
              variant="filled"
              severity="error"
            />
          ))}

        {refetch && (
          <Button variant="contained" color="primary" onClick={() => refetch()}>
            Try Again
          </Button>
        )}
      </Grid>
    );
  }

  return null;
};

AlbError.propTypes = {
  error: PropTypes.shape().isRequired,
  generic: PropTypes.bool,
  toast: PropTypes.bool,
  refetch: PropTypes.func
};

AlbError.defaultProps = {
  generic: false,
  toast: false,
  refetch: null
};

export default AlbError;
