import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  leftColumn: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#6F6F6F',
    cursor: 'zoom-in',
    padding: '2px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  middleColumn: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#0A1734'
  },
  rightColumn: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#979797'
  }
});

/**
 * @method
 * @summary This component renders a column for @see TopPostsOver30
 * @name TopPostsOver30Column
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.tableData - An array of data objects containing the top 10 post greater than 30 days
 * @param {number} props.bottomBound - A number from where to start the data .slice()
 * @param {number} props.topBound -  A number from where to end the data .slice()
 * @param {Function} props.onClick - A handler function fired on clicking the row
 * @return {Object} - React JSX
 */
const TopPostsOver30Column = props => {
  const { tableData, bottomBound, topBound, onClick } = props;
  const classes = useStyles();

  const getPostNumLabel = num => {
    switch (num) {
      case 1:
        return 'Top Post';
      case 2:
        return '2nd';
      case 3:
        return '3rd';
      default:
        return `${num}th`;
    }
  };

  return (
    <>
      <Grid container>
        <Grid container item xs={4} />
        <Grid container item xs={4} justifyContent="flex-end">
          <Typography className={classes.middleColumn}>IMP.</Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end">
          <Typography className={classes.middleColumn}>AGE</Typography>
        </Grid>
      </Grid>
      <Grid container>
        {tableData.slice(bottomBound, topBound).map((data, index) => {
          return (
            <React.Fragment key={`${data.id}|${getPostNumLabel(index + bottomBound + 1)}`}>
              <Grid container item xs={4} style={{ margin: '-2px' }}>
                <Typography className={classes.leftColumn} onClick={e => onClick(e, data)}>
                  {`${getPostNumLabel(index + bottomBound + 1)}`}
                </Typography>
              </Grid>
              <Grid container item xs={4} justifyContent="flex-end">
                <Typography className={classes.middleColumn}>
                  {data.value?.toLocaleString()}
                </Typography>
              </Grid>
              <Grid container item xs={4} justifyContent="flex-end">
                <Typography className={classes.rightColumn}>{data?.age}d</Typography>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
};

TopPostsOver30Column.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  bottomBound: PropTypes.number.isRequired,
  topBound: PropTypes.number.isRequired,
  onClick: PropTypes.func
};

TopPostsOver30Column.defaultProps = {
  onClick: () => {}
};

export default TopPostsOver30Column;
