import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxSocial from 'components/AnalyticsSocialV2/BoxContent/BoxSocial';
import { getEndDate, renderUILongDate } from 'util/date';

const useStyles = makeStyles({
  container: {
    height: '100%'
  },
  text: {
    fontSize: '30px',
    fontWeight: 500,
    lineHeight: '45px',
    textAlign: 'center',
    color: '#32327D'
  },
  dates: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#979797',
    margin: '4px'
  }
});

/**
 * @method
 * @summary This component renders a single text value with contributed social channels
 * @name AlbSingleValueGraph
 * @param {Object} props - React props passed to this component
 * @param {string} props.value - The text value
 * @param {string[]} props.activeChannels - An array of strings of optional social channels
 * @param {string[]} props.contributingChannels - An array of strings of which channels are contributing to this value
 * @return {Object} - React JSX
 */
const AlbSingleValueGraph = props => {
  const { value, activeChannels, contributingChannels, dates } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
      direction="column"
    >
      <div className={classes.text}>{value}</div>
      {activeChannels && contributingChannels && (
        <BoxSocial activeChannels={activeChannels} contributingChannels={contributingChannels} />
      )}
      {dates && (
        <div className={classes.dates}>
          {renderUILongDate(dates.start)} - {getEndDate(renderUILongDate(dates.end))}
        </div>
      )}
    </Grid>
  );
};

AlbSingleValueGraph.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  activeChannels: PropTypes.arrayOf(PropTypes.string),
  contributingChannels: PropTypes.arrayOf(PropTypes.string),
  dates: PropTypes.shape()
};

AlbSingleValueGraph.defaultProps = {
  activeChannels: null,
  contributingChannels: null,
  dates: null
};

export default AlbSingleValueGraph;
