import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { DRUID_DEMOGRAPHIC_TABLE, WEB_AND_APPS_GENDER_TABLE } from 'gql/analytics';
import colors from 'util/colors';
import theme from 'theme';
import AlbLoading from 'components/AlbLoading';
import { shortenText } from 'components/AnalyticsSocial/AnalyticsSocialConsts';
import { getDatesForAnalytics } from 'util/date';

const useStyles = makeStyles({
  gender: {
    fontSize: '13px',
    fontWeight: 700,
    color: colors.darkBlue
  },
  total: {
    marginBottom: '10px'
  },
  positive: {
    color: colors.green
  },
  negative: {
    color: colors.red
  },
  bold: {
    fontWeight: 500
  }
});

const BoxContentTableAge = props => {
  const { accounts, dates, table } = props;

  const classes = useStyles();

  const isGenderTable = {
    demographicGenderDruid: true,
    webAndAppsGender: true
  }[table];

  const tableTheme = createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableHeadCell: {
        root: {
          whiteSpace: 'nowrap',

          '&:nth-child(-n+2)': {
            width: 1
          }
        },
        sortAction: {
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      },
      MuiTableCell: {
        head: {
          borderBottom: 'none'
        },
        body: {
          '&:nth-child(odd)': {
            backgroundColor: '#f5f5f5'
          },

          ...(isGenderTable && {
            '&:first-child': {
              backgroundColor: 'transparent'
            }
          })
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:first-child': {
            '& td': {
              boxShadow: 'inset 0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
            }
          },
          '&:hover': {
            backgroundColor: colors.athensGray
          }
        }
      }
    }
  });

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  const queries = {
    demographicGenderDruid: DRUID_DEMOGRAPHIC_TABLE,
    demographicAgeDruid: DRUID_DEMOGRAPHIC_TABLE,
    webAndAppsGender: WEB_AND_APPS_GENDER_TABLE,
    webAndAppsAge: WEB_AND_APPS_GENDER_TABLE
  };

  const { data, loading } = useQuery(queries[table], {
    variables: {
      linkTokens: accounts.map(({ id }) => id),
      startDate,
      endDate,
      table
    }
  });

  // formatting for social age tables
  const formatSocialDemographicTableData = tables =>
    tables.map(({ column, columnValue, metrics }) => ({
      [column]: columnValue,
      ...metrics.reduce((acc, { column: headerColumn, total, change, difference }) => {
        acc[headerColumn] = { total, change, difference };

        return acc;
      }, {})
    }));

  // formatting for web and apps age tables
  const formatWebAndAppsDemographicTableData = tables =>
    tables.map(({ gender, columns }) => ({
      Gender: shortenText[gender],
      ...columns.reduce((acc, { metric, growth }) => {
        const { percentage, primaryValue, secondaryValue } = growth;
        const symbol = secondaryValue >= 0 ? '+' : '-';

        acc[metric] = {
          total: primaryValue ?? '-',
          difference: secondaryValue,
          change:
            secondaryValue == null
              ? '-'
              : `${percentage ?? 0}% (${symbol}${Math.abs(secondaryValue).toLocaleString()})`
        };

        return acc;
      }, {})
    }));

  const availableAgeColumns = columns =>
    [
      { name: 'total', label: 'TOTAL' },
      { name: '13-17', label: '13 - 17' },
      { name: '18-24', label: '18 - 24' },
      { name: '25-34', label: '25 - 34' },
      { name: '35-44', label: '35 - 44' },
      { name: '45-54', label: '45 - 54' },
      { name: '55-64', label: '55 - 64' },
      { name: '65+', label: '65+' }
    ].filter(({ name }) => columns.includes(name));

  const customHeadRender = tableMeta => (
    <TableCell key={tableMeta.label}>
      <div align="center">{tableMeta.label}</div>
    </TableCell>
  );

  const customGenderRender = gender => (
    <div className={classes.gender} align="center">
      {gender}
    </div>
  );

  const customAgeRender = (age, { columnData }) => (
    <div align="center">
      <div className={`${classes.total} ${columnData.name === 'total' ? classes.bold : ''}`}>
        {age.total.toLocaleString()}
      </div>
      <div
        className={`${classes.bold} ${age.difference > 0 ? classes.positive : ''}${
          age.difference < 0 ? classes.negative : ''
        }`}
      >
        {age.change}
      </div>
    </div>
  );

  useEffect(() => {
    let ageColumns = [];

    if (data?.webAndAppsGender?.genderTable?.length) {
      // web and apps doesn't seem to provide all age ranges so we deterministically filter out those we don't have data for
      // extract the first dataset to find which columns are available
      const [firstDataset] = data.webAndAppsGender.genderTable;
      const { columns } = firstDataset;

      ageColumns = availableAgeColumns(columns.map(({ metric }) => metric));
      setTableData(formatWebAndAppsDemographicTableData(data.webAndAppsGender.genderTable));
    }

    if (data?.demographicTable?.length) {
      const [firstDataset] = data.demographicTable;
      const { metrics } = firstDataset;

      ageColumns = availableAgeColumns(metrics.map(({ column }) => column));
      setTableData(formatSocialDemographicTableData(data.demographicTable));
    }

    const columns = [
      // conditional column
      ...(isGenderTable
        ? [
            {
              name: 'Gender',
              label: 'GENDER',
              options: {
                customHeadRender,
                customBodyRender: customGenderRender
              }
            }
          ]
        : []),
      ...ageColumns.map(({ name, label }) => ({
        name,
        label,
        options: {
          customHeadRender,
          customBodyRender: customAgeRender
        }
      }))
    ];

    setTableColumns(columns);
  }, [data]);

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: false,
    responsive: 'standard'
  };

  if (loading) return <AlbLoading />;

  return (
    <MuiThemeProvider theme={tableTheme}>
      <MUIDataTable data={tableData} columns={tableColumns} options={options} />
    </MuiThemeProvider>
  );
};

BoxContentTableAge.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  table: PropTypes.string.isRequired
};

export default BoxContentTableAge;
