import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'util/colors';

const useStyles = makeStyles({
  change: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 500
  },
  positive: {
    color: '#00A84F',

    '& > div': {
      borderColor: 'transparent transparent #00A84F'
    }
  },
  negative: {
    color: colors.red,

    '& > div': {
      borderColor: `transparent transparent ${colors.red}`
    }
  },
  neutral: {
    color: '#000000',

    '& > div': {
      borderColor: `transparent transparent #000000`
    }
  },
  triangle: {
    position: 'relative',
    borderWidth: '0px 4px 7px 4px',
    borderStyle: 'solid',
    marginRight: '4px'
  },
  flipped: {
    transform: 'rotate(180deg)'
  }
});

const PercentChange = props => {
  const { percentValue, changeValue } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={`${classes.change} ${changeValue < 0 ? classes.negative : ''} ${
        changeValue > 0 ? classes.positive : ''
      } ${changeValue == null || changeValue === 0 ? classes.neutral : ''}`}
    >
      <div className={`${classes.triangle}  ${changeValue < 0 ? classes.flipped : ''}`} />
      {`${percentValue != null ? percentValue.toFixed(2) : '0'}%`}{' '}
      {`${changeValue != null && changeValue > 0 ? '+' : ''}`}
      {`${changeValue != null ? changeValue.toLocaleString() : 0}`}
    </Grid>
  );
};

PercentChange.defaultProps = {
  percentValue: 0,
  changeValue: 0
};

PercentChange.propTypes = {
  percentValue: PropTypes.number,
  changeValue: PropTypes.number
};

export default PercentChange;
