import { gql } from '@apollo/client';
import { ASSET_FIELD_NAMES } from './asset';

export const TOP_ACTIVE_POST_TOTAL = gql`
  query topActivePostTotal(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    topActivePostTotal(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      count
      topPosts {
        x
        y
        postId
        linkTokenId
      }
    }
  }
`;

export const TOP_ACTIVE_OLD_POSTS = gql`
  query topActiveOldPosts(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    topActiveOldPosts(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      id
      value
      age
      linkTokenId
    }
  }
`;

export const TOP_ACTIVE_POST_TABLE = gql`
  query topActivePostTable(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    topActivePostTable(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      date
      topTen {
        position
        postId
        linkTokenId
        value
      }
      topTenTotal
      topTenPercentageOfTotal
      otherCount
      otherTotal
      otherPercentageOfTotal
    }
  }
`;

export const TOP_ACTIVE_POST_TIME_SERIES = gql`
  query topActivePostTimeSeries(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    topActivePostTimeSeries(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      postId
      linkTokenId
      dataset {
        x
        y
      }
    }
  }
`;

export const TOP_ACTIVE_CAMPAIGN_EVENT = gql`
  query topActiveCampaignEvent(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!,
    $count: Int
    $after: Int
  ) {
    topActiveCampaignEvent(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
      count: $count
      after: $after
    ) {
      rows {
        id
        account
        date
        body
        post
        type
        thumbnailUrl
        publishedUrl
        assets {
          ${ASSET_FIELD_NAMES}
        }
        metrics {
          metric
          value
        }
      }
      count
    }
  }
`;

export const ACTIVE_POST_AGE_RANGE = gql`
  query activePostAgeRange(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    activePostAgeRange(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    )
  }
`;

export const CAMPAIGN_EVENT_TOOLTIP = gql`
  query campaignEventToolTip(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkToken: ID!
    $postId: String!
  ) {
    campaignEventToolTip(
      startDate: $startDate
      endDate: $endDate
      linkToken: $linkToken
      postId: $postId
    ) {
      campaignEvent {
        event_title
        event_body
        completed_at
        assets {
          signed_thumbnail_url
        }
        published_url
        linked_account {
          id
          type
          remote_name
        }
        lifetime_impressions
        lifetime_engagement
        lifetime_shares
        lifetime_views
      }
      impressions
      engagement
      shares
      videoViews
    }
  }
`;

export const IMPACT_VS_AGE = gql`
  query impactVsAge(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $linkTokens: [ID!]!
    $metric: String!
  ) {
    impactVsAge(
      startDate: $startDate
      endDate: $endDate
      linkTokens: $linkTokens
      metric: $metric
    ) {
      ageGroup
      dataset {
        x
        y
      }
    }
  }
`;
