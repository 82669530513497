/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import colors from 'util/colors';
import { AlbTooltip } from 'components/AlbTooltip';
import getValueAbbreviation from 'util/getValueAbbreviation';

const NumberGrid = props => {
  const { item, gridItems, index, size } = props;

  const darker = index % 2 !== 0;

  const useStyles = makeStyles({
    grid: {
      marginTop: '25px',
      backgroundColor: darker ? colors.wildSand : 'white',

      '& > :nth-child(odd)': {
        backgroundColor: darker ? colors.gallery : colors.wildSand
      }
    },
    metric: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '150px'
    },
    metricLabel: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '25px',

      '& > p': {
        fontSize: '12px',
        fontWeight: 500,
        color: colors.navy
      }
    },
    metricValue: {
      color: colors.darkBlue,
      fontSize: '30px',
      fontWeight: 500,
      marginTop: '15px'
    }
  });

  const classes = useStyles();

  const gridColSize = {
    small: 3,
    medium: 4
  }[size];

  return (
    <Grid container className={classes.grid}>
      {gridItems.map(({ graph_title, metric_name, tooltip_text = '' }) => {
        const hasTooltip = !!tooltip_text?.length;
        const lifetimeMetricValue = getValueAbbreviation(item[metric_name]) ?? '-';

        return (
          <Grid key={graph_title} item className={classes.metric} xs={gridColSize}>
            <div className={classes.metricLabel}>
              <Typography style={hasTooltip ? { marginright: '10px' } : {}}>
                {graph_title}
              </Typography>
              {hasTooltip && <AlbTooltip title={tooltip_text} />}
            </div>
            <Typography className={classes.metricValue}>{lifetimeMetricValue}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

NumberGrid.propTypes = {
  item: PropTypes.shape().isRequired,
  index: PropTypes.number,
  gridItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  size: PropTypes.string
};

NumberGrid.defaultProps = {
  index: 0,
  size: 'medium'
};

export default NumberGrid;
