/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_CONTAINER_LLM_SUMMARY = gql`
  query intelligenceSummaryByContainer($containerId: ID!, $periodical: String!) {
    intelligenceSummaryByContainer(containerId: $containerId, periodical: $periodical) {
      id
      periodical
      summary
    }
  }
`;
