import colors, { topPostsColors, topPostsGradient } from 'util/colors';

const {
  blue1,
  blue2,
  blue3,
  blue4,
  blue5,
  blue6,
  darkBlue,
  navy50Tint,
  pink,
  orange,
  red,
  pacific,
  green,
  navy,
  kimberly,
  darkGray,
  flamingo
} = colors;

export const linePalette = {
  backgroundColor: darkBlue,
  borderColor: darkBlue,
  pointHoverBackgroundColor: navy50Tint,
  pointHoverBorderColor: darkBlue
};

export const graphPresets = {
  line: {
    type: 'line',
    palette: linePalette
  },
  lineGradient: {
    type: 'line',
    palette: linePalette
  },
  barPrimary: { type: 'bar', palette: { backgroundColor: darkBlue } },
  barSecondary: { type: 'bar', palette: { backgroundColor: navy50Tint } },
  barHorizontal1: { type: 'horizontalBar', palette: { backgroundColor: blue1 } },
  barHorizontal2: { type: 'horizontalBar', palette: { backgroundColor: blue2 } },
  barHorizontal3: { type: 'horizontalBar', palette: { backgroundColor: blue3 } },
  barHorizontal4: { type: 'horizontalBar', palette: { backgroundColor: blue4 } },
  barHorizontal5: { type: 'horizontalBar', palette: { backgroundColor: blue5 } },
  barHorizontal6: { type: 'horizontalBar', palette: { backgroundColor: blue6 } },
  barHorizontal7: { type: 'horizontalBar', palette: { backgroundColor: darkBlue } },
  // Use the 0D color from comps for Day 1 for now per Tomas; use pink for 0D in the future and offset again
  lineStacked0: { type: 'line', palette: { backgroundColor: pink, borderColor: 'transparent' } },
  lineStacked1: {
    type: 'line',
    palette: { backgroundColor: darkBlue, borderColor: 'transparent' }
  },
  lineStacked2: { type: 'line', palette: { backgroundColor: orange, borderColor: 'transparent' } },
  lineStacked3: { type: 'line', palette: { backgroundColor: red, borderColor: 'transparent' } },
  lineStacked4: { type: 'line', palette: { backgroundColor: pacific, borderColor: 'transparent' } },
  lineStacked5: { type: 'line', palette: { backgroundColor: green, borderColor: 'transparent' } },
  lineStacked6: { type: 'line', palette: { backgroundColor: navy, borderColor: 'transparent' } },
  lineStacked7: {
    type: 'line',
    palette: { backgroundColor: kimberly, borderColor: 'transparent' }
  },
  lineStacked8: {
    type: 'line',
    palette: { backgroundColor: darkGray, borderColor: 'transparent' }
  },
  lineStacked9: {
    type: 'line',
    palette: { backgroundColor: flamingo, borderColor: 'transparent' }
  },
  lineStacked10: {
    type: 'line',
    palette: { backgroundColor: flamingo, borderColor: 'transparent' }
  }
};

const genderAgeLabels = {
  'page_fans_gender_age.f.13_17': '13-17',
  'page_fans_gender_age.f.18_24': '18-24',
  'page_fans_gender_age.f.25_34': '25-34',
  'page_fans_gender_age.f.35_44': '35-44',
  'page_fans_gender_age.f.45_54': '45-54',
  'page_fans_gender_age.f.55_64': '55-64',
  'page_fans_gender_age.f.65+': '65+',
  'page_fans_gender_age.m.13_17': '13-17',
  'page_fans_gender_age.m.18_24': '18-24',
  'page_fans_gender_age.m.25_34': '25-34',
  'page_fans_gender_age.m.35_44': '35-44',
  'page_fans_gender_age.m.45_54': '45-54',
  'page_fans_gender_age.m.55_64': '55-64',
  'page_fans_gender_age.m.65+': '65+',
  'audience_gender_age.f.13_17': '13-17',
  'audience_gender_age.f.18_24': '18-24',
  'audience_gender_age.f.25_34': '25-34',
  'audience_gender_age.f.35_44': '35-44',
  'audience_gender_age.f.45_54': '45-54',
  'audience_gender_age.f.55_64': '55-64',
  'audience_gender_age.f.65+': '65+',
  'audience_gender_age.m.13_17': '13-17',
  'audience_gender_age.m.18_24': '18-24',
  'audience_gender_age.m.25_34': '25-34',
  'audience_gender_age.m.35_44': '35-44',
  'audience_gender_age.m.45_54': '45-54',
  'audience_gender_age.m.55_64': '55-64',
  'audience_gender_age.m.65+': '65+'
};

const genderAgeLabelsDruid = {
  '13-17 Female': '13-17',
  '13-17 Male': '13-17',
  '18-24 Female': '18-24',
  '18-24 Male': '18-24',
  '25-34 Female': '25-34',
  '25-34 Male': '25-34',
  '35-44 Female': '35-44',
  '35-44 Male': '35-44',
  '45-54 Female': '45-54',
  '45-54 Male': '45-54',
  '55-64 Female': '55-64',
  '55-64 Male': '55-64',
  '65+ Female': '65+',
  '65+ Male': '65+'
};

export const horizontalAgeLabels = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

export const hypeGraphLabels = [
  'Top Post',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th'
];

export const sourcesAndPagesGraphLabels = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  'Other'
];

const impactVsAgeLabels = [
  '1D',
  '2D',
  '3D',
  '4-7D',
  '8-30D',
  '31-90D',
  '91-180D',
  '181-365D',
  '365D+'
];

export const stackedBarCustomGraphTypes = ['stackedBar', 'watchTimeStackedBar'];

export const customLabels = {
  ...genderAgeLabels,
  ...genderAgeLabelsDruid
};

export default {
  reachTotal: {
    tabTitle: 'TOTAL',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Impressions',
        queryV2: 'impressionsTotalDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  reachType: {
    tabTitle: 'TYPE',
    customGraph: 'stackedBarMetric',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        label: 'Organic Impressions',
        tooltipTitle: 'Impressions by Type',
        queryV2: 'impressionsOrganicDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar',
        palette: { backgroundColor: darkBlue }
      },
      {
        label: 'Paid Impressions',
        tooltipTitle: 'Impressions by Type',
        queryV2: 'impressionsPaidDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar',
        palette: { backgroundColor: pink }
      }
    ],
    customLegend: {
      palette: [
        { label: 'Organic Impressions', color: darkBlue },
        { label: 'Paid Impressions', color: pink }
      ]
    }
  },
  growthRateTotal: {
    tabTitle: 'TOTAL',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'New Follower(s)',
        queryV2: 'followersGainedDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  growthRateTime: {
    tabTitle: 'TIME',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Current Followers',
        queryV2: 'followersGrowthDruid',
        graphType: 'timeSeries',
        type: 'bar'
      }
    ]
  },
  engagementTotal: {
    tabTitle: 'TOTAL',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Total',
        queryV2: 'engagementTotalDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  engagementRate: {
    tabTitle: 'RATE',
    additionalTooltips: ['date', 'point', 'line'],
    queries: [
      {
        label: '% ENGAGEMENT',
        queryV2: 'engagementRateDruid',
        graphType: 'timeSeriesDelta',
        ...graphPresets.lineGradient
      }
    ]
  },
  engagementComments: {
    tabTitle: 'COMMENTS',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Comment(s)',
        queryV2: 'engagementCommentsDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  engagementShares: {
    tabTitle: 'SHARES',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Share(s)',
        queryV2: 'engagementSharesDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  engagementLikes: {
    tabTitle: 'LIKES',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Like(s)',
        queryV2: 'engagementLikesDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  engagementDislikes: {
    tabTitle: 'DISLIKES',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Dislike(s)',
        queryV2: 'engagementDislikesDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  followersLocation: {
    tabTitle: 'LOCATION',
    customGraph: 'geoCountries',
    queries: [
      {
        label: 'COUNTRIES',
        queryV2: 'demographicLocationDruid',
        graphType: 'groupBy'
      }
    ]
  },
  followersGender: {
    tabTitle: 'GENDER',
    customGraph: 'genderAge',
    stacked: true,
    additionalTooltips: ['topTotal', 'line'],
    druidQuery: {
      queryV2: 'demographicGenderDruid',
      graphType: 'groupBy'
    },
    queries: [
      {
        label: 'WOMEN BY AGE',
        query: [],
        ...graphPresets.barPrimary
      },
      {
        label: 'MEN BY AGE',
        query: [],
        ...graphPresets.barSecondary
      }
    ]
  },
  followersAge: {
    tabTitle: 'AGE',
    customGraph: 'horizontalAge',
    customPresets: horizontalAgeLabels.map((label, i) => ({
      preset: graphPresets[`barHorizontal${i + 1}`],
      label
    })),
    druidQuery: {
      queryV2: 'demographicAgeDruid',
      graphType: 'groupBy'
    },
    stacked: true,
    queries: [
      {
        label: 'Start',
        query: []
      },
      {
        label: 'End',
        query: []
      }
    ]
  },
  videoViews: {
    tabTitle: 'TOTAL',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Views',
        queryV2: 'videoViewsDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  averagePercentageViewed: {
    tabTitle: 'TOTAL',
    additionalTooltips: ['date', 'point', 'line'],
    queries: [
      {
        label: 'AVERAGE % VIEWED',
        queryV2: 'averagePercentageViewedDruid',
        graphType: 'timeSeriesDelta',
        ...graphPresets.line
      }
    ]
  },
  watchTime: {
    tabTitle: 'TOTAL',
    customGraph: 'watchTimeStackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Watch Time',
        queryV2: 'watchTimeDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  averageWatchTime: {
    tabTitle: 'TOTAL',
    customGraph: 'watchTime',
    additionalTooltips: ['date', 'point', 'line'],
    queries: [
      {
        label: 'AVERAGE WATCH TIME',
        queryV2: 'averageWatchTimeDruid',
        graphType: 'timeSeriesDelta',
        ...graphPresets.line
      }
    ]
  },
  videoGrowthRateTotal: {
    tabTitle: 'TOTAL',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'New Follower(s)',
        queryV2: 'followersGainedDruid',
        graphType: 'timeSeriesDelta',
        type: 'bar'
      }
    ]
  },
  videoGrowthRateTime: {
    tabTitle: 'TIME',
    customGraph: 'stackedBar',
    stacked: true,
    additionalTooltips: ['date', 'line'],
    queries: [
      {
        tooltipTitle: 'Current Followers/Subs',
        queryV2: 'followersGrowthDruid',
        graphType: 'timeSeries',
        type: 'bar'
      }
    ]
  },
  hype: {
    customGraph: 'hype',
    customPresets: hypeGraphLabels.map((label, i) => ({
      preset: {
        type: 'line',
        palette: {
          gradient: topPostsGradient[i],
          borderColor: 'transparent'
        }
      },
      label
    })),
    additionalTooltips: ['date', 'point', 'line'],
    druidQuery: {
      queryV2: 'alembicImpressions',
      graphType: 'topActivePostTimeSeries'
    },
    customLegend: {
      title: 'Post Position',
      palette: hypeGraphLabels.map((label, i) => ({
        label,
        color: topPostsColors[i]
      }))
    },
    fill: true,
    lineTension: 0.4,
    interactionMode: 'nearest',
    queries: hypeGraphLabels.map((label, i) => ({
      type: 'line',
      palette: {
        gradient: topPostsGradient[i],
        borderColor: 'transparent'
      },
      label
    }))
  },
  impactVsAge: {
    customGraph: 'flow',
    customPresets: impactVsAgeLabels.map((label, i) => ({
      preset: graphPresets[`lineStacked${i}`],
      label
    })),
    additionalTooltips: ['date', 'point', 'line'],
    druidQuery: {
      queryV2: 'alembicImpressions',
      graphType: 'impactVsAge'
    },
    customLegend: {
      title: 'Age Range',
      palette: impactVsAgeLabels.map((label, i) => ({
        label,
        color: topPostsColors[i]
      }))
    },
    fill: true,
    stacked: true,
    queries: impactVsAgeLabels.map((label, i) => ({
      label,
      tooltipTitle: 'Age Range',
      ...graphPresets[`lineStacked${i}`]
    }))
  },
  webAndAppsLocationMap: {
    tabTitle: 'LOCATION',
    customGraph: 'webAndAppsLocationMap',
    queries: [
      {
        label: 'COUNTRIES',
        queryV2: 'webAndAppsLocationMap',
        graphType: 'webAndAppsLocationMap'
      }
    ]
  },
  webAndAppsGender: {
    tabTitle: 'GENDER',
    customGraph: 'webAndAppsGender',
    stacked: true,
    additionalTooltips: ['topTotal', 'line'],
    druidQuery: {
      queryV2: 'webAndAppsGender',
      graphType: 'webAndAppsGender'
    },
    queries: [
      {
        label: 'WOMEN BY AGE',
        query: [],
        ...graphPresets.barPrimary
      },
      {
        label: 'MEN BY AGE',
        query: [],
        ...graphPresets.barSecondary
      }
    ]
  },
  webAndAppsAge: {
    tabTitle: 'AGE',
    customGraph: 'webAndAppsAge',
    customPresets: horizontalAgeLabels.map((label, i) => ({
      preset: graphPresets[`barHorizontal${i + 1}`],
      label
    })),
    druidQuery: {
      queryV2: 'webAndAppsAge',
      graphType: 'webAndAppsAge'
    },
    stacked: true,
    queries: [
      {
        label: 'Start',
        query: []
      },
      {
        label: 'End',
        query: []
      }
    ]
  }
};
