import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
// import CustomFooter from 'components/TablePagination';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { openExternalPost } from 'util/externalLinks';

const styles = makeStyles({
  container: {
    padding: '30px 0px',
    zIndex: 0,
    position: 'relative'
  },
  title: {
    color: '#0a1734',
    fontSize: '16px',
    fontWeight: 500,
    paddingLeft: '27px'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  event: {
    alignItems: 'flex-start',
    flexWrap: 'nowrap'
  },
  eventText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 4,
    lineClamp: 4,
    wordBreak: 'break-word',
    fontSize: '13px'
  }
});

const tableThemeOverrides = {
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap',

      '&:nth-child(-n+2)': {
        width: 'unset'
      }
    },
    sortAction: {
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  },
  MuiTableCell: {
    root: {
      padding: '27px',
      borderBottom: 'none'
    }
  }
};

const DashboardUnderstandDrawerTable = props => {
  const { data, loading, error, sortOrder, setSortOrder } = props;
  const classes = styles();

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [after, setAfter] = useState(0);
  // const [count, setCount] = useState(0);

  useEffect(() => {
    const formattedTableData = data.map(
      ({
        event_start_date: startDate,
        event_end_date: endDate,
        mag_unit: unit,
        magnitude,
        eventInfo,
        campaignEvent
      }) => {
        let postBody = eventInfo?.postBody || '';
        const publishedUrl = campaignEvent?.published_url || null;

        if (publishedUrl && eventInfo?.sourceName) {
          postBody = `Social post from ${eventInfo.sourceName}`;
        }

        return {
          date: { startDate, endDate },
          event: { postBody, publishedUrl },
          magnitude: { value: magnitude, unit }
        };
      }
    );
    setTableData(formattedTableData);
    setCount(data.length);
  }, [data]);

  const customDateRender = ({ startDate, endDate }) => {
    const dateArray = [
      ...new Set([moment.utc(startDate).format('M/D/YY'), moment.utc(endDate).format('M/D/YY')])
    ];
    return <div style={{ whiteSpace: 'nowrap' }}>{`${dateArray.join(' - ')}`}</div>;
  };

  const customEventRender = ({ postBody, publishedUrl }) => {
    return (
      <Grid container className={classes.event}>
        <Typography className={classes.eventText}>{postBody}</Typography>
        {publishedUrl && (
          <IconButton
            style={{ padding: '3px', color: '#0A1734' }}
            onClick={() => openExternalPost(publishedUrl)}
          >
            <Launch />
          </IconButton>
        )}
      </Grid>
    );
  };

  const customMetricRender = metric => {
    const { value, unit } = metric;

    return `${unit === 'revenue' ? '$' : ''}${getValueAbbreviation(value)} ${unit}`;
  };

  const columns = [
    {
      name: 'date',
      label: 'EVENT DATE',
      options: { customBodyRender: customDateRender }
    },
    {
      name: 'event',
      label: 'EVENT',
      options: { customBodyRender: customEventRender, sort: false }
    },
    {
      name: 'magnitude',
      label: 'METRIC',
      options: { customBodyRender: customMetricRender }
    }
  ];

  // const rowsPerPageOptions = [];

  // const customFooter = (
  //   footerCount,
  //   footerPage,
  //   footerRowsPerPage,
  //   changeRowsPerPage,
  //   changePage,
  //   textLabels
  // ) =>
  //   CustomFooter(
  //     footerCount,
  //     footerPage,
  //     footerRowsPerPage,
  //     changeRowsPerPage,
  //     changePage,
  //     textLabels,
  //     rowsPerPageOptions
  //   );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    pagination: false,
    responsive: 'standard',
    // onChangeRowsPerPage: setRowsPerPage,
    // onChangePage: setAfter,
    draggableColumns: { enabled: true, transitionTime: 300 },
    // page: after,
    // rowsPerPage,
    count,
    // customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1].toLowerCase() },
    onColumnSortChange: (changedColumn, direction) =>
      setSortOrder([changedColumn, direction.toUpperCase()]),
    textLabels: {
      body: {
        noMatch: 'No events found'
      }
    }
  };

  return (
    <Grid container className={classes.container} direction="column">
      <Typography className={classes.title}>Included events</Typography>
      {loading && (
        <div className={classes.loading}>
          <AlbLoading />
        </div>
      )}
      {error && <AlbError error={error} />}
      {tableData.length > 0 && (
        <AlbTable
          tableData={tableData}
          tableColumns={columns}
          tableOptions={tableOptions}
          tableThemeOverrides={tableThemeOverrides}
        />
      )}
    </Grid>
  );
};

DashboardUnderstandDrawerTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  error: PropTypes.shape(),
  sortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSortOrder: PropTypes.func.isRequired
};

DashboardUnderstandDrawerTable.defaultProps = {
  data: [],
  loading: false,
  error: null
};

export default DashboardUnderstandDrawerTable;
