import React from 'react';
import ImageCounterOverlay from 'components/ImageCounterOverlay';
import PropTypes from 'prop-types';
import { NoThumbnailAvailable } from 'util/assets';

/**
 * This component is a universal renderer for all thumbnails at Alembic. Do not
 * invent this again. Use this component to render all thumbnails.
 *
 * @param {Object} props - the props passed to this component
 * @param {Array} props.assets - an array of assets to render (if incl)
 * @param {Array} props.thumbnailUrl - if present, overrides the default thumbnail url
 * @param {Number} props.width - the width of the thumbnail
 * @param {Number} props.height - the width of the thumbnail
 * @param {Function} props.onClick - Function to call onClick of thumbnail
 * @param {Boolean} props.rounded - If true, renders a rounded thumbnail
 * @param {Boolean} props.showDefault - If false, renders null if no thumbnail is available
 * @param {Object} props.classes - styles object (classes.thumbnail) used by IMG tag and passed to image and ImageCounterOverlay
 */
const AssetThumbnail = props => {
  const {
    assets,
    thumbnailUrl,
    placeholderUrl,
    width,
    height,
    onClick,
    rounded,
    showDefault,
    classes
  } = props;

  // In the case of Youtube, we do not ever have a set of assets so we have to
  // render based on the thumbnail URL
  if (thumbnailUrl) {
    return (
      <img
        src={thumbnailUrl}
        className={classes?.thumbnail}
        alt="thumbnail"
        width={width}
        height={height}
        onError={e => {
          e.target.src = placeholderUrl;
        }}
      />
    );
  }

  // If we have assets, we'll take them.
  if (assets[0]) {
    // If we have assets, render that array
    return (
      <ImageCounterOverlay
        images={assets}
        width={width}
        height={height}
        rounded={rounded}
        onClickEvent={onClick}
        classes={classes}
      />
    );
  }

  // Else, maybe this is a tweet or fb post with no image?

  if (!showDefault) {
    return null;
  }

  return (
    <img
      src={NoThumbnailAvailable}
      alt="post"
      width={width}
      height={height}
      className={classes?.thumbnail}
    />
  );
};

AssetThumbnail.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape()),
  thumbnailUrl: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  showDefault: PropTypes.bool,
  placeholderUrl: PropTypes.string,
  classes: PropTypes.shape()
};

AssetThumbnail.defaultProps = {
  assets: [],
  onClick: null,
  thumbnailUrl: null,
  placeholderUrl: NoThumbnailAvailable,
  rounded: false,
  showDefault: true,
  classes: {}
};

export default AssetThumbnail;
