import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { Switch, Route, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { FIND_DETECTED_EVENT } from 'gql/detected_event';
import { ObserveEventsPathV4, ObservePredictionsPathV4 } from 'util/paths';
import setPageTitle from 'util/titles';
import { AnnotationWhiteIcon } from 'util/assets';
import {
  parseQueryString,
  deleteQueryStringParameter,
  setQueryStringForAnalyticsModal,
  goToRoute
} from 'util/routesHelpers';
import { checkValidDate } from 'util/date';
import colors from 'util/colors';
import AlbLoading from 'components/AlbLoading';
import AlembicPageTitle from 'components/AlembicPageTitle';
import Box from 'components/Box';
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import CreateAnnotationModal from 'components/AlembicModalForm/CreateAnnotationModal';
import HasAnyPriv from 'components/HasAnyPriv/HasAnyPriv';
import DashboardIntelligenceV4 from './DashboardIntelligenceV4';
import DashboardPredictionsV4 from './DashboardPredictionsV4';

const styles = makeStyles({
  noAccounts: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px'
  },
  headerContainer: {
    paddingBottom: '30px'
  },
  annotateButton: {
    backgroundColor: '#0D1A3A',
    color: 'white',
    borderRadius: '3px',
    height: '34px',
    width: '103px',
    marginRight: '30px',

    '& > span': {
      display: 'flex',
      justifyContent: 'space-around'
    }
  },
  tab: {
    padding: '12px 18px'
  },
  default: {
    color: colors.navy50
  },
  selected: {
    backgroundColor: colors.navy,
    color: 'white',

    '&:hover': {
      backgroundColor: colors.navy
    }
  }
});

const DashboardV4 = props => {
  const { history, location } = props;
  const classes = styles();
  const [activeTab, setActiveTab] = useState(0);
  const [isCreateAnnotationModalOpen, toggleCreateAnnotationModal] = useState(false);
  // start and end always going to be the same day, but we don't include current day.
  // AnalyticsDatePicker is expecting a Date object.
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(
      moment()
        .utc()
        .subtract(1, 'd')
    ),
    end: new Date(
      moment()
        .utc()
        .subtract(1, 'd')
    )
  });

  // Update URL parameters when date changes
  useEffect(() => {
    if (activeTab === 0) {
      setQueryStringForAnalyticsModal('date', [moment(selectedDates.start).format('YYYY-MM-DD')]);
    }
  }, [selectedDates]);

  const v4tabs = [
    { label: 'EVENTS', title: 'Events', path: ObserveEventsPathV4 },
    { label: 'PREDICTIONS', title: 'Predictions', path: ObservePredictionsPathV4 }
  ];

  const { loading, data } = useQuery(FIND_DETECTED_EVENT);

  useEffect(() => {
    setPageTitle('Observe');

    v4tabs.forEach(({ path }, index) => {
      if (location.pathname.includes(path)) {
        setActiveTab(index);

        if (index === 0 && location?.search) {
          const date = parseQueryString('date', location);

          if (date[0]) {
            const newDate = new Date(moment(date[0]));

            if (checkValidDate(newDate)) {
              // If the dates do not match then set the date picker range to the dates from the URL
              if (selectedDates.start !== newDate) {
                setSelectedDates({ start: newDate, end: newDate });
              }
            }
          }
        }

        if (index !== 0) {
          deleteQueryStringParameter('date');
        }
      }
    });
  }, []);

  return (
    <Grid container>
      <HasAnyPriv privs={['SVC:CHAN:STATS']} showAlert>
        <>
          {loading && <AlbLoading />}

          {data?.detectedEvent === null && (
            <Grid className={`${classes.noAccounts}`} container>
              <AlembicPageTitle nomargin title="There are no detections in this container." />
            </Grid>
          )}

          {data?.detectedEvent?.id != null && (
            <>
              <Grid container alignItems="flex-start" className={classes.headerContainer}>
                <Grid item sm={12} md={4}>
                  <AlembicPageTitle nomargin title="Dashboard - Observe" />
                </Grid>

                <Grid item sm={12} md={4}>
                  <Box display="flex" justifyContent="center">
                    {v4tabs.map(({ label, path }, i) => (
                      <Button
                        className={`${classes.tab} ${
                          classes[activeTab === i ? 'selected' : 'default']
                        }`}
                        key={label}
                        onClick={() => {
                          setActiveTab(i);
                          goToRoute(path, history);
                        }}
                      >
                        {label}
                      </Button>
                    ))}
                  </Box>
                </Grid>

                <Grid item sm={12} md={4}>
                  {activeTab === 0 && (
                    <Box display="flex" alignItems="center">
                      <Button
                        className={classes.annotateButton}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => toggleCreateAnnotationModal(true)}
                      >
                        <img
                          src={AnnotationWhiteIcon}
                          alt="Annotation icon"
                          width={15}
                          height={15}
                        />
                        Annotate
                      </Button>

                      <AnalyticsDatePicker
                        setDateRange={setSelectedDates}
                        calRange={selectedDates}
                        singleDate
                        hideRangeButtons
                        customArrowRange="30"
                        delta
                        disableMargin
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Switch>
                <Route
                  exact
                  path={`/:containerId${ObserveEventsPathV4}`}
                  render={() => (
                    <DashboardIntelligenceV4
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/:containerId${ObservePredictionsPathV4}`}
                  render={() => <DashboardPredictionsV4 />}
                />
              </Switch>
            </>
          )}

          {isCreateAnnotationModalOpen && (
            <CreateAnnotationModal
              isModalOpen={isCreateAnnotationModalOpen}
              selectedDates={selectedDates}
              onChange={e => toggleCreateAnnotationModal(e.isModalOpen)}
            />
          )}
        </>
      </HasAnyPriv>
    </Grid>
  );
};

DashboardV4.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default withRouter(DashboardV4);
