import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/client/react/components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Checkbox, Button } from '@material-ui/core';
import { CheckBox as CheckedIcon, CheckBoxOutlineBlank as UncheckedIcon } from '@material-ui/icons';
import { ASSOCIATED_ACCOUNTS, CREATE_LINK_TOKENS } from 'gql/associatedAccounts';
import ToastContext from 'contexts/ToastContext';
import { LinkAccountsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import Box from '../Box';
import AlbLoading from '../AlbLoading';
import AlbError from '../AlbError';

const style = {
  pageItem: {
    display: 'flex',
    flexDirection: 'column',
    height: '54px',
    padding: '13px 20px',
    borderBottom: 'solid 1px rgba(13, 26, 58, 0.2)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(111, 111, 111, 0.09)'
    }
  },
  checkBox: {
    width: '0px',
    height: '0px',
    borderRadius: '0%'
  }
};

class AssociatedAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIds: []
    };
  }

  handleSelectPage = id => {
    const { selectedIds } = this.state;
    this.setState({
      selectedIds: [...selectedIds, id]
    });
  };

  handleDeselectPage = id => {
    const { selectedIds } = this.state;
    this.setState({
      selectedIds: selectedIds.filter(selectId => selectId !== id)
    });
  };

  render() {
    const { classes, history, location } = this.props;
    const { selectedIds } = this.state;
    const urlParams = new URLSearchParams(location.search);
    const accountId = urlParams.get('id');
    const accountType = urlParams.get('type');
    let onboard = urlParams.get('onboard');

    if (accountId == null) {
      return <Typography variant="h1">There are no associated pages</Typography>;
    }

    return (
      <Box mt={30}>
        <Paper>
          <Box p={30}>
            <Typography variant="h1">Company Pages Associated with Account</Typography>
            <Typography variant="body1">Select the organizations to connect to Alembic</Typography>
            <Query query={ASSOCIATED_ACCOUNTS} variables={{ id: accountId }}>
              {({ loading, error, refetch, data }) => {
                if (loading) return <AlbLoading />;
                if (error) return <AlbError error={error} refetch={refetch} />;

                return (
                  <Grid container justifyContent="center" alignItems="center" direction="column">
                    {data.associatedAccounts.map(page => (
                      <Grid
                        container
                        item
                        xs={3}
                        justifyContent="center"
                        alignItems="center"
                        key={page.id}
                        className={classes.pageItem}
                        onClick={() => {
                          if (selectedIds.includes(page.id)) {
                            this.handleDeselectPage(page.id);
                          } else {
                            this.handleSelectPage(page.id);
                          }
                        }}
                      >
                        {page.name}
                        <Checkbox
                          className={classes.checkBox}
                          checked={selectedIds.includes(page.id)}
                          icon={<UncheckedIcon fontSize="small" />}
                          checkedIcon={<CheckedIcon fontSize="small" />}
                        />
                      </Grid>
                    ))}
                    <Grid container justifyContent="center">
                      <Grid item xs={6}>
                        <Mutation mutation={CREATE_LINK_TOKENS}>
                          {createLinkTokens => {
                            return (
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  createLinkTokens({
                                    variables: { associatedAccounts: selectedIds, accountType }
                                  }).then(result => {
                                    const toast = this.context;
                                    if (result.data.createLinkTokens.length > 0) {
                                      toast.showToast('Page Link Success', 'success');
                                    } else {
                                      toast.showToast('Some pages were not accepted', 'warn');
                                      onboard = 'skip';
                                    }
                                    goToRoute(`${LinkAccountsPath}?onboard=${onboard}`, history);
                                  });
                                }}
                              >
                                Continue
                              </Button>
                            );
                          }}
                        </Mutation>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            </Query>
          </Box>
        </Paper>
      </Box>
    );
  }
}

AssociatedAccounts.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

AssociatedAccounts.defaultProps = {};

AssociatedAccounts.contextType = ToastContext;

export default withStyles(style)(AssociatedAccounts);
