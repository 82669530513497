/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import ArticleDrawerViewContext from './ArticleDrawerViewContext';

class ArticleDrawerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDrawer = (
      isArticleDrawerOpenParam = false,
      articleIdParam = null,
      articleSearchIdParam = null,
      domainFilterParam = null
    ) => {
      this.setState({
        isArticleDrawerOpen: isArticleDrawerOpenParam,
        articleId: articleIdParam,
        articleSearchId: articleSearchIdParam,
        domainFilter: domainFilterParam
      });
    };

    this.state = {
      isArticleDrawerOpen: false,
      articleId: null,
      articleSearchId: null,
      domainFilter: null,
      toggleDrawer: this.toggleDrawer
    };
  }

  render() {
    const { children } = this.props;
    return (
      <ArticleDrawerViewContext.Provider value={this.state}>
        {children}
      </ArticleDrawerViewContext.Provider>
    );
  }
}

ArticleDrawerWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default ArticleDrawerWrapper;
