const ONE_MONTH = {
  label: '1M',
  periodical: 'MONTHLY',
  rangeKey: 'M',
  rangeString: 'month',
  minimumMonthsRequired: 1
};
const ONE_QUARTER = {
  label: '1Q',
  periodical: 'QUARTERLY',
  rangeKey: 'Q',
  rangeString: 'quarter',
  minimumMonthsRequired: 3
};
const ONE_YEAR = {
  label: '1Y',
  periodical: 'ANNUALLY',
  rangeKey: 'y',
  rangeString: 'year',
  minimumMonthsRequired: 12
};

const LAST_90_DAYS = {
  label: '90',
  rangeAmount: 90,
  periodical: 'LAST_90_DAYS',
  rangeKey: 'd',
  rangeString: 'day',
  static: true
};
const LAST_180_DAYS = {
  label: '180',
  rangeAmount: 180,
  periodical: 'LAST_180_DAYS',
  rangeKey: 'd',
  rangeString: 'day',
  static: true
};
const LAST_365_DAYS = {
  label: '365',
  rangeAmount: 365,
  periodical: 'LAST_365_DAYS',
  rangeKey: 'd',
  rangeString: 'day',
  static: true
};

// const LAST_MONTH = {
//   label: '1M',
//   periodical: 'MONTHLY',
//   rangeKey: 'M',
//   rangeString: 'month',
//   overview: true
// };
// const LAST_QUARTER = {
//   label: '1Q',
//   periodical: 'QUARTERLY',
//   rangeKey: 'Q',
//   rangeString: 'quarter',
//   overview: true
// };
// const LAST_YEAR = {
//   label: '1Y',
//   periodical: 'ANNUALLY',
//   rangeKey: 'y',
//   rangeString: 'year',
//   overview: true
// };

export {
  ONE_MONTH,
  ONE_QUARTER,
  ONE_YEAR,
  LAST_90_DAYS,
  LAST_180_DAYS,
  LAST_365_DAYS
  // LAST_MONTH,
  // LAST_QUARTER,
  // LAST_YEAR
};
