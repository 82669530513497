import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Typography, Button } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMutation } from '@apollo/client';
import { CREATE_PROMPT_TEMPLATE_FEEDBACK } from 'gql/community';
import Box from 'components/Box';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import modalStyles from './modalStyles';

const styles = makeStyles({
  ...modalStyles,
  textfield: {
    '& > div': { width: '100%' }
  }
});

const PromptFeedbackModal = props => {
  const { id, isModalOpen, onChange, refetch } = props;

  const [feedbackText, setFeedbackText] = useState('');

  const classes = styles();

  const [createFeedback, { error, data }] = useMutation(CREATE_PROMPT_TEMPLATE_FEEDBACK);

  useEffect(() => {
    if (data?.createPromptTemplateFeedback) {
      showToast('Feedback sent.', 'success');
      setFeedbackText('');
      onChange({ isModalOpen: false });
      refetch();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const closeModal = e => {
    setFeedbackText('');
    onChange({ isModalOpen: false });
    e.stopPropagation();
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={isModalOpen}
        onClose={closeModal}
        onClick={e => e.stopPropagation()}
      >
        <ValidatorForm
          onSubmit={() => {
            createFeedback({
              variables: {
                input: {
                  causal_chain_id: parseInt(id, 10),
                  feedback_type: 'thumbs_down',
                  feedback: feedbackText
                }
              }
            });
          }}
        >
          <Box p={12}>
            <Grid container>
              <Typography className={classes.label}>
                Tell us what&apos;s wrong so we can improve. Please be specific.
              </Typography>
              <Grid className={classes.textfield} item container>
                <TextValidator
                  autoFocus
                  fullWidth
                  multiline
                  rows={3}
                  variant="filled"
                  placeholder="Enter Feedback Text"
                  value={feedbackText}
                  onChange={event => setFeedbackText(event.target.value)}
                />
              </Grid>
              <Grid item container justifyContent="flex-end" className={classes.actions}>
                <span>
                  <Button variant="outlined" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </span>
              </Grid>
            </Grid>
          </Box>
        </ValidatorForm>
      </Dialog>
    </>
  );
};

PromptFeedbackModal.propTypes = {
  id: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  refetch: PropTypes.func
};

PromptFeedbackModal.defaultProps = {
  id: null,
  refetch: () => {}
};

export default PromptFeedbackModal;
