/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, Button } from '@material-ui/core';
import AlbTable from 'components/AlbTable';
import {
  GET_ALL_CONVERSION_EVENT_NAMES,
  DELETE_CONVERSION_EVENT_NAME
} from 'gql/conversionEventName';
import CustomFooter from 'components/TablePagination';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { showToast } from 'contexts/ToastContext';

/**
 * @summary Renders AA conversion event names table
 * @name TopChannelTable
 * @param {Object} props - React props passed to this component
 * @param {String} props.tableColumns - column data
 * @param {String} props.tableData - table row data
 * @return {Object} - React JSX
 */
const AdobeAnalyticsConversionEventNamesTable = () => {
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['', '']);

  const { loading, error, data } = useQuery(GET_ALL_CONVERSION_EVENT_NAMES, {
    fetchPolicy: 'network-only'
  });

  const [deleteConversionEventName] = useMutation(DELETE_CONVERSION_EVENT_NAME, {
    refetchQueries: [{ query: GET_ALL_CONVERSION_EVENT_NAMES }],
    onCompleted: () => {
      showToast(`Conversion event has been deleted`, 'success');
    }
  });

  useEffect(() => {
    if (data?.getAllConversionEventNames?.length > 0) {
      const temp = data?.getAllConversionEventNames?.map(row => {
        return {
          id: row.id,
          linked_account_id: row.linked_account_id,
          remote_name: row.linktoken.remote_name,
          event_name: row.event_name,
          event_value: row.event_value
        };
      });

      setCount(data.getAllConversionEventNames.length);
      setTableData(temp);
    } else {
      setCount(0);
      setTableData([]);
    }
  }, [data]);

  const rowsPerPageOptions = [5, 10, 20];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  const tableColumns = [
    { name: 'remote_name', label: 'SEGMENT' },
    { name: 'event_name', label: 'METRIC' },
    { name: 'event_value', label: 'EVENT' },
    {
      name: 'id',
      label: 'ACTION',
      options: {
        customBodyRender: id => (
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                deleteConversionEventName({
                  variables: {
                    id
                  }
                });
              }}
            >
              Remove
            </Button>
          </Grid>
        ),
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        sort: false
      }
    }
  ];

  if (loading) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbLoading />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container direction="column" justifyContent="center">
        <AlbError error={error} />
      </Grid>
    );
  }

  return <AlbTable tableData={tableData} tableColumns={tableColumns} tableOptions={tableOptions} />;
};

AdobeAnalyticsConversionEventNamesTable.propTypes = {};

export default AdobeAnalyticsConversionEventNamesTable;
