import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

const ASSETS_GET_AVATARUL_POLICY = gql`
  mutation {
    getAvatarUploadPolicy {
      signature
      policy
      valid_until
    }
  }
`;

export default async function apiAvatarGetUploadPolicy(data) {
  return apolloClientHelper(
    ASSETS_GET_AVATARUL_POLICY,
    data,
    true // mutation
  );
}
