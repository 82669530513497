/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import AlbError from 'components/AlbError';
import AlbLoading from 'components/AlbLoading';
import CustomFooter from 'components/TablePagination';
import AlbTable from 'components/AlbTable';
import { WEB_AND_APPS_TABLE } from 'gql/analytics';
import { DefaultUserAvatar } from 'util/assets';
import { getDatesForAnalytics } from 'util/date';
import truncateUrl from 'util/truncateUrl';
import colors from 'util/colors';

const tableThemeOverrides = {
  MUIDataTable: {
    paper: {
      flex: 1,
      boxShadow: 'none'
    }
  },
  MUIDataTableHeadRow: {
    root: {
      boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
    }
  },
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap'
    },
    sortAction: {
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  }
};

/**
 * @summary This component renders the top tables for Web and Apps analytics overview
 * @name WebTopTable
 * @param {Object} props - React props passed to this component
 * @param {string} props.metric - The metric queried within the table
 * @param {Object[]} props.accounts - An array of link token objects from @see PostAccountSelector
 * @param {Object} props.dates - An object containing start and end dates from @see AnalyticsDatePicker
 * @param {Object} props.setPageGraph - A state updating function intended to pass the current page to accompanying graph in @see WebSources
 * @param {Object} props.rowsPerPageOptions - An array of rows per page options passed to the pagination footer
 * @return {Object} - React JSX
 */
const WebTopTable = props => {
  const { metric, accounts, dates, setPageGraph, rowsPerPageOptions, isRevenue } = props;

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // this table doesn't support sorting yet
  const [sortOrder, setSortOrder] = useState(['', '']);
  const [isChannelColumnVisible, setIsChannelColumnVisible] = useState(false);

  const { start: startDate, end: endDate } = getDatesForAnalytics(dates.start, dates.end);

  useEffect(() => {
    let after = page * rowsPerPage;
    let currentPage = page;

    while (after > count) {
      after -= rowsPerPage;
      currentPage -= 1;
    }

    setPage(currentPage);
  }, [page, rowsPerPage]);

  const { data, error, loading } = useQuery(WEB_AND_APPS_TABLE, {
    variables: {
      startDate,
      endDate,
      linkTokens: accounts.map(account => account.id),
      metric,
      after: page * rowsPerPage,
      count: rowsPerPage
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    setTableData([]);
    setCount(0);
    setPage(0);
    setPageGraph(0);
  }, [metric]);

  useEffect(() => {
    let tempIsChannelColumnVisible = false;
    let tempTableData = [];
    let tempCount = 0;

    if (data?.webAndAppsTable) {
      const { rows, count: rowCount } = data.webAndAppsTable;

      const tableRows = rows.map(({ linktoken, metric: rowMetric, metric2: rowMetric2, value }) => {
        const matchedAccount = accounts.find(account => account.id === linktoken.id) || {};
        const { avatar_url, platform = {}, remote_name } = matchedAccount;

        const metric2 = rowMetric2 || '';

        if (metric2 && tempIsChannelColumnVisible === false) {
          tempIsChannelColumnVisible = true;
        }

        return {
          account: platform?.icon,
          property: { image: avatar_url || DefaultUserAvatar, remote_name },
          metric2,
          metric: rowMetric,
          users: value
        };
      });

      tempTableData = tableRows;
      tempCount = rowCount;
    }

    setIsChannelColumnVisible(tempIsChannelColumnVisible);
    setTableData(tempTableData);
    setCount(tempCount);
  }, [data, accounts, dates]);

  const customImageRender = src => <img alt="logo" src={src} style={{ width: 38, height: 38 }} />;
  const customPropertyRender = property => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {customImageRender(property.image)}
      <span style={{ marginLeft: '15px', color: colors.purple }}>
        <span style={{ fontWeight: 700 }}>{property.remote_name}</span>
      </span>
    </div>
  );
  const customUserRender = users => (
    <span>
      {isRevenue ? '$' : ''}
      {users.toLocaleString()}
    </span>
  );

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const metricLabels = {
    webAndAppsTopSourcesTable: 'SOURCE',
    webAndAppsTopPagesTable: 'PAGE',
    webAndAppsTopConvertingTable: 'SEARCH TERM',
    webAndAppsTopPaidTable: 'SEARCH TERM',
    webAndAppsTopOrganicTable: 'SEARCH TERM',
    webAndAppsTopChannelsTable: 'CHANNEL',
    webAndAppsTopCampaignsTable: 'CAMPAIGN',
    webAndAppsTopChannelsRevenueTable: 'CHANNEL',
    webAndAppsTopCampaignsRevenueTable: 'CAMPAIGN'
  };

  const tableColumns = [
    {
      name: 'account',
      label: 'ACCOUNT',
      options: { customBodyRender: customImageRender, sort: false }
    },
    {
      name: 'property',
      label: 'PROPERTY',
      options: { customBodyRender: customPropertyRender, sort: false }
    },
    ...(isChannelColumnVisible
      ? [
          {
            name: 'metric2',
            label: 'CHANNEL',
            options: { customBodyRender: truncateUrl, sort: false }
          }
        ]
      : []),
    {
      name: 'metric',
      label: metricLabels[metric],
      options: { customBodyRender: truncateUrl, sort: false }
    },
    {
      name: 'users',
      label: isRevenue ? 'REVENUE' : 'USERS',
      options: { customBodyRender: customUserRender, sort: false }
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: currentPage => {
      setPage(currentPage);
      setPageGraph(currentPage);
    },
    draggableColumns: { enabled: true, transitionTime: 300 },
    page,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction])
  };

  if (error) return <AlbError error={error} />;
  if (loading) return <AlbLoading />;

  return (
    <Grid container>
      <AlbTable
        tableData={tableData}
        tableColumns={tableColumns}
        tableOptions={tableOptions}
        tableThemeOverrides={tableThemeOverrides}
      />
    </Grid>
  );
};

WebTopTable.propTypes = {
  metric: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape().isRequired,
  setPageGraph: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  isRevenue: PropTypes.bool
};

WebTopTable.defaultProps = {
  setPageGraph: () => {},
  rowsPerPageOptions: [5, 10, 20],
  isRevenue: false
};

export default WebTopTable;
