import { gql } from '@apollo/client';

export const THIRD_PARTY_COUNT_DATA_BY_TYPE = gql`
  query thirdPartyCountDataByType(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $articleSearchIds: [ID!]
    $articleSearchFilterIds: [ID!]
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
  ) {
    thirdPartyCountDataByType(
      startDate: $startDate
      endDate: $endDate
      articleSearchIds: $articleSearchIds
      articleSearchFilterIds: $articleSearchFilterIds
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
    ) {
      television {
        mentionCount
        viewershipCount
      }
      podcast {
        mentionCount
        viewershipCount
      }
      radio {
        mentionCount
        viewershipCount
      }
      web {
        mentionCount
        viewershipCount
      }
    }
  }
`;

export const THIRD_PARTY_TOP_SOURCES = gql`
  query thirdPartyTopSources(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $isViewershipSelected: Boolean!
    $mediaType: String
    $articleSearchIds: [ID!]
    $articleSearchFilterIds: [ID!]
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
  ) {
    thirdPartyTopSources(
      startDate: $startDate
      endDate: $endDate
      isViewershipSelected: $isViewershipSelected
      mediaType: $mediaType
      articleSearchIds: $articleSearchIds
      articleSearchFilterIds: $articleSearchFilterIds
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
    ) {
      source
      count
      type
    }
  }
`;

export const THIRD_PARTY_NARRATIVES_GRAPH_TOP = gql`
  query thirdPartyNarrativesGraphTop(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
    $isViewershipSelected: Boolean!
    $mediaType: String
    $isEnabled: Boolean!
    $after: Int!
    $count: Int!
    $orderBy: [String]
  ) {
    thirdPartyNarrativesGraphTop(
      startDate: $startDate
      endDate: $endDate
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
      isViewershipSelected: $isViewershipSelected
      mediaType: $mediaType
      isEnabled: $isEnabled
      after: $after
      count: $count
      orderBy: $orderBy
    ) {
      mentionGroup {
        id
        source
        title
        promotion_type
        group_seq
        firstAirDate
        lastAirDate
      }
      lifetimeTotal
      dataset {
        x
        y
      }
    }
  }
`;

export const THIRD_PARTY_NARRATIVES_GRAPH_TOTAL = gql`
  query thirdPartyNarrativesGraphTotal(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
    $isViewershipSelected: Boolean!
    $mediaType: String
    $isEnabled: Boolean!
    $after: Int!
    $count: Int!
    $orderBy: [String]
  ) {
    thirdPartyNarrativesGraphTotal(
      startDate: $startDate
      endDate: $endDate
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
      isViewershipSelected: $isViewershipSelected
      mediaType: $mediaType
      isEnabled: $isEnabled
      after: $after
      count: $count
      orderBy: $orderBy
    ) {
      firstAirDate
      lastAirDate
      mediaTypeList
      lifetimeTotal
      dataset {
        x
        y
      }
    }
  }
`;

export const THIRD_PARTY_MENTIONS_GRAPH_TOTAL = gql`
  query thirdPartyMentionsGraphTotal(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $broadcastSearchIds: [ID!]
    $podcastSearchIds: [ID!]
    $articleSearchIds: [ID!]
    $articleSearchFilterIds: [ID!]
    $isViewershipSelected: Boolean!
    $mediaType: String
    $isEnabled: Boolean!
  ) {
    thirdPartyMentionsGraphTotal(
      startDate: $startDate
      endDate: $endDate
      broadcastSearchIds: $broadcastSearchIds
      podcastSearchIds: $podcastSearchIds
      articleSearchIds: $articleSearchIds
      articleSearchFilterIds: $articleSearchFilterIds
      isViewershipSelected: $isViewershipSelected
      mediaType: $mediaType
      isEnabled: $isEnabled
    ) {
      mediaTypeList
      dataset {
        x
        y
      }
    }
  }
`;

export const THIRD_PARTY_ARTICLE_SEARCHES_GRAPH_TOTAL = gql`
  query thirdPartyArticleSearchesGraphTotal(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $articleSearchIds: [ID!]
    $articleSearchFilterIds: [ID!]
    $isViewershipSelected: Boolean!
    $isEnabled: Boolean!
    $getShareCount: Boolean
  ) {
    thirdPartyArticleSearchesGraphTotal(
      startDate: $startDate
      endDate: $endDate
      articleSearchIds: $articleSearchIds
      articleSearchFilterIds: $articleSearchFilterIds
      isViewershipSelected: $isViewershipSelected
      isEnabled: $isEnabled
      getShareCount: $getShareCount
    ) {
      x
      y
    }
  }
`;

export const THIRD_PARTY_MENTIONS_TIME_SERIES_BY_MENTION_GROUP = gql`
  query thirdPartyMentionsTimeSeriesByMentionGroup(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $mentionGroupId: ID!
  ) {
    thirdPartyMentionsTimeSeriesByMentionGroup(
      startDate: $startDate
      endDate: $endDate
      mentionGroupId: $mentionGroupId
    ) {
      x
      y
    }
  }
`;

export const THIRD_PARTY_RATINGS_TIME_SERIES_BY_MENTION_GROUP = gql`
  query thirdPartyRatingsTimeSeriesByMentionGroup(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $mentionGroupId: ID!
  ) {
    thirdPartyRatingsTimeSeriesByMentionGroup(
      startDate: $startDate
      endDate: $endDate
      mentionGroupId: $mentionGroupId
    ) {
      x
      y
    }
  }
`;

export const THIRD_PARTY_MENTIONS_TIME_SERIES_BY_MENTION_GROUPS = gql`
  query thirdPartyMentionsTimeSeriesByMentionGroups(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $mentionGroupIds: [ID!]
  ) {
    thirdPartyMentionsTimeSeriesByMentionGroups(
      startDate: $startDate
      endDate: $endDate
      mentionGroupIds: $mentionGroupIds
    ) {
      x
      y
    }
  }
`;

export const THIRD_PARTY_RATINGS_TIME_SERIES_BY_MENTION_GROUPS = gql`
  query thirdPartyRatingsTimeSeriesByMentionGroups(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $mentionGroupIds: [ID!]
  ) {
    thirdPartyRatingsTimeSeriesByMentionGroups(
      startDate: $startDate
      endDate: $endDate
      mentionGroupIds: $mentionGroupIds
    ) {
      x
      y
    }
  }
`;

export const THIRD_PARTY_TIME_SERIES_BY_DETECTED_EVENT = gql`
  query thirdPartyTimeSeriesByDetectedEvent(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $detectedEventId: ID!
    $metricName: String!
  ) {
    thirdPartyTimeSeriesByDetectedEvent(
      startDate: $startDate
      endDate: $endDate
      detectedEventId: $detectedEventId
      metricName: $metricName
    ) {
      x
      y
    }
  }
`;
