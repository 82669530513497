import { gql } from '@apollo/client';

const THEME_FIELDS = gql`
  fragment themeFields on Theme {
    id
    created_by
    container_id
    business_type_id
    name
    score
    active
    created_at
    updated_at
    deleted_at
    deleted_by {
      id
    }
    container {
      id
      name
    }
    created_by_user {
      id
      first_name
      last_name
    }
  }
`;

export const GET_THEMES_FOR_CURRENT_CONTAINER = gql`
  query {
    themes {
      ...themeFields
      topics {
        id
        name
      }
      campaign_events {
        id
      }
    }
  }
  ${THEME_FIELDS}
`;

export const GET_THEMES_DROPDOWN = gql`
  query {
    themes {
      id
      name
      topics {
        id
        name
      }
    }
  }
`;

export const CREATE_THEME = gql`
  mutation($name: String!) {
    createTheme(name: $name) {
      ...themeFields
    }
  }
  ${THEME_FIELDS}
`;

export const UPDATE_THEME = gql`
  mutation($id: ID!, $name: String!, $active: Boolean) {
    updateTheme(id: $id, name: $name, active: $active) {
      ...themeFields
      topics {
        id
        name
      }
    }
  }
  ${THEME_FIELDS}
`;

export const DELETE_THEME = gql`
  mutation($id: ID!) {
    deleteTheme(id: $id)
  }
`;
