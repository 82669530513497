import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Select, FormControl, MenuItem, Radio, ListItemText } from '@material-ui/core';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from 'theme';

const dropdownTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiMenuItem: {
      root: {
        padding: '0px !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1
      }
    }
  }
});

const styles = makeStyles({
  select: {
    margin: 'auto 26px auto auto',
    minWidth: '190px',
    height: '41px'
  },
  label: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#0D1A3A',
    marginRight: '10px'
  }
});

const RadioDropdown = ({
  label,
  placeholder,
  options,
  selectedOption,
  setSelectedOption,
  conditionalDisable
}) => {
  const classes = styles();

  useEffect(() => {
    // if a disabled option is chosen and a state change is triggered,
    // default to the first enabled option
    if (
      conditionalDisable.condition &&
      conditionalDisable?.disabledOptions?.includes(selectedOption.value)
    ) {
      const filteredOptions = options.filter(
        option => !conditionalDisable.disabledOptions.includes(option.value)
      );

      setSelectedOption(filteredOptions[0]);
    }
  }, [conditionalDisable]);

  return (
    <MuiThemeProvider theme={dropdownTheme}>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        {label && <span className={classes.label}>{label}</span>}
        <FormControl>
          <Select
            className={classes.select}
            variant="outlined"
            labelId="input-label"
            value={JSON.stringify(selectedOption)}
            renderValue={option => placeholder || JSON.parse(option).label}
            onChange={e => setSelectedOption(JSON.parse(e.target.value))}
            IconComponent={ExpandMoreIcon}
          >
            {options.map(option => (
              <MenuItem
                key={option.label}
                value={JSON.stringify(option)}
                disabled={
                  conditionalDisable.condition &&
                  conditionalDisable?.disabledOptions?.includes(option.value)
                }
              >
                <Radio checked={selectedOption.value === option.value} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </MuiThemeProvider>
  );
};

RadioDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedOption: PropTypes.shape().isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  conditionalDisable: PropTypes.shape()
};

RadioDropdown.defaultProps = {
  label: '',
  placeholder: '',
  conditionalDisable: {}
};

export default RadioDropdown;
