/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper
} from '@material-ui/core';
import { ZoomIn } from '@material-ui/icons';
import { GET_RANDOM_STOCK_PHOTOS, apiGetAssets } from 'gql/asset';
import UploadAssetByUrlButton from 'components/GenericFileButton/UploadAssetByUrlButton';
import AlbLoading from '../AlbLoading';
import AlbError from '../AlbError';

const formStyle = {
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto'
};

const styles = theme =>
  createStyles({
    root: {
      display: 'flex',
      background: '#ffffff',
      height: '100%'
    },
    grid: {
      flexGrow: '1',
      marginTop: '1px'
    },
    card: {
      position: 'relative',
      marginBottom: '8px'
    },
    image: {
      width: '100%',
      height: 'auto',
      position: 'relative'
    },
    actionArea: {
      position: 'absolute',
      background: 'rgba(169,169,169,0.5)',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      zIndex: 100
    },
    zoomIcon: {
      display: 'block',
      marginTop: 'auto',
      marginBottom: 'auto',
      color: 'white'
    },
    dialog: {
      position: 'absolute',
      height: 'auto',
      boxShadow: theme.shadows[5],
      width: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      textAlign: 'center',
      padding: '10px',
      overflow: 'auto'
    },
    dialogText: {
      color: 'black',
      marginTop: '10px'
    },
    dialogActions: {
      justifyContent: 'center'
    }
  });

class StockPhotoGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      selectedPhoto: null,
      modal: false
    };
  }

  componentDidMount = () => {
    const { setSearchFilterInTabs } = this.props;

    const searchParams = new URLSearchParams(window.location.search);
    const searchKeyword = searchParams?.get('search');

    if (searchKeyword) {
      setSearchFilterInTabs(decodeURI(searchKeyword));
    } else {
      setSearchFilterInTabs();
    }
  };

  toggleModal = (e, photo) => {
    const { modal } = this.state;

    if (!modal) {
      // e.preventDefault();
      this.setState({ modal: true, selectedPhoto: photo });
    } else {
      // e.preventDefault();
      this.setState({ modal: false, selectedPhoto: null });
    }
  };

  handleMouseOver = photo => {
    this.setState({ hover: true, selectedPhoto: photo });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  stockPhotoInPostCallback = async result => {
    const { onSelect } = this.props;

    // query for full asset info by filename
    let newStockPhoto = null;

    if (result) {
      newStockPhoto = await apiGetAssets({ fileName: `/${result.filename}` });

      // onSelect() comes from newPostForm and adds an asset to the current post.
      // with Stock Photos, we want to simultaneously select and add the specific asset to the post.
      if (newStockPhoto) {
        onSelect(newStockPhoto.payload?.data?.assets);
      }
    }
  };

  stockPhotoInLibraryCallback = result => {
    this.toggleModal(result);
  };

  render() {
    const { classes, filteredStockPhotos, folderName, inPost } = this.props;
    const { selectedPhoto, hover, modal } = this.state;
    const col1 = [];
    const col2 = [];
    const col3 = [];
    const col4 = [];

    return (
      <div className={classes.root}>
        {modal ? (
          <Dialog open={modal} onClose={e => this.toggleModal(e)} className={classes.dialog}>
            <DialogContent>
              {selectedPhoto && (
                <img
                  src={selectedPhoto.urls.small}
                  alt={selectedPhoto.description}
                  width={400}
                  height={`${Math.round((selectedPhoto.height / selectedPhoto.width) * 400)}`}
                />
              )}
              <DialogContentText className={classes.dialogText}>
                {selectedPhoto.description ? `"${selectedPhoto.description}"` : null}
              </DialogContentText>
              <DialogActions className={classes.dialogActions}>
                <UploadAssetByUrlButton
                  formStyle={formStyle}
                  source={selectedPhoto.urls.full}
                  buttonText={inPost ? 'Save to Library and Add to Post' : 'Save to Library'}
                  callback={
                    inPost ? this.stockPhotoInPostCallback : this.stockPhotoInLibraryCallback
                  }
                  folderName={folderName}
                />
              </DialogActions>
            </DialogContent>
          </Dialog>
        ) : null}

        <Query query={GET_RANDOM_STOCK_PHOTOS}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <AlbLoading />;
            if (error) return <AlbError error={error} refetch={refetch} />;

            if (!data.getRandomPhotos) {
              return <AlbError generic refetch={refetch} />;
            }

            let photos = {};

            if (filteredStockPhotos) {
              photos = filteredStockPhotos;
            } else {
              photos = data.getRandomPhotos;
            }

            // Columns for masonry layout with automatic height.
            for (let i = 0; i < photos.length; i += 4) {
              col1.push(photos[i]);
              if (photos[i + 1] !== undefined && photos[i + 1] !== null) {
                col2.push(photos[i + 1]);
              }
              if (photos[i + 2] !== undefined && photos[i + 2] !== null) {
                col3.push(photos[i + 2]);
              }
              if (photos[i + 3] !== undefined && photos[i + 3] !== null) {
                col4.push(photos[i + 3]);
              }
            }

            return (
              <Paper>
                <Grid container spacing={1}>
                  <Grid item className={classes.grid} xs={3}>
                    {col1.map(photo => {
                      const url = photo.urls.small;

                      return (
                        <Card
                          className={classes.card}
                          key={photo.id}
                          onMouseOver={() => this.handleMouseOver(photo)}
                          onMouseLeave={() => this.handleMouseLeave()}
                          onFocus={() => this.handleMouseOver(photo)}
                          onClick={e => this.toggleModal(e, photo)}
                        >
                          {!hover ? null : photo.id === selectedPhoto.id ? (
                            <div className={classes.actionArea}>
                              <ZoomIn className={classes.zoomIcon} />
                            </div>
                          ) : null}
                          <img
                            src={url}
                            alt={photo.filename}
                            className={classes.image}
                            width={400}
                            height={`${Math.round((photo.height / photo.width) * 400)}`}
                          />
                        </Card>
                      );
                    })}
                  </Grid>
                  <Grid item className={classes.grid} xs={3}>
                    {col2.map(photo => {
                      const url = photo.urls.small;

                      return (
                        <Card
                          className={classes.card}
                          key={photo.id}
                          onMouseOver={() => this.handleMouseOver(photo)}
                          onMouseLeave={() => this.handleMouseLeave()}
                          onFocus={() => this.handleMouseOver(photo)}
                          onClick={e => this.toggleModal(e, photo)}
                        >
                          {!hover ? null : photo.id === selectedPhoto.id ? (
                            <div className={classes.actionArea}>
                              <ZoomIn className={classes.zoomIcon} />
                            </div>
                          ) : null}
                          <img
                            src={url}
                            alt={photo.filename}
                            className={classes.image}
                            width={400}
                            height={`${Math.round((photo.height / photo.width) * 400)}`}
                          />
                        </Card>
                      );
                    })}
                  </Grid>
                  <Grid item className={classes.grid} xs={3}>
                    {col3.map(photo => {
                      const url = photo.urls.small;

                      return (
                        <Card
                          className={classes.card}
                          key={photo.id}
                          onMouseOver={() => this.handleMouseOver(photo)}
                          onMouseLeave={() => this.handleMouseLeave()}
                          onFocus={() => this.handleMouseOver(photo)}
                          onClick={e => this.toggleModal(e, photo)}
                        >
                          {!hover ? null : photo.id === selectedPhoto.id ? (
                            <div className={classes.actionArea}>
                              <ZoomIn className={classes.zoomIcon} />
                            </div>
                          ) : null}
                          <img
                            src={url}
                            alt={photo.filename}
                            className={classes.image}
                            width={400}
                            height={`${Math.round((photo.height / photo.width) * 400)}`}
                          />
                        </Card>
                      );
                    })}
                  </Grid>
                  <Grid item className={classes.grid} xs={3}>
                    {col4.map(photo => {
                      const url = photo.urls.small;

                      return (
                        <Card
                          className={classes.card}
                          key={photo.id}
                          onMouseOver={() => this.handleMouseOver(photo)}
                          onMouseLeave={() => this.handleMouseLeave()}
                          onFocus={() => this.handleMouseOver(photo)}
                          onClick={e => this.toggleModal(e, photo)}
                        >
                          {!hover ? null : photo.id === selectedPhoto.id ? (
                            <div className={classes.actionArea}>
                              <ZoomIn className={classes.zoomIcon} />
                            </div>
                          ) : null}
                          <img
                            src={url}
                            alt={photo.filename}
                            className={classes.image}
                            width={400}
                            height={`${Math.round((photo.height / photo.width) * 400)}`}
                          />
                        </Card>
                      );
                    })}
                  </Grid>
                </Grid>
              </Paper>
            );
          }}
        </Query>
      </div>
    );
  }
}

StockPhotoGrid.propTypes = {
  classes: PropTypes.shape(),
  filteredStockPhotos: PropTypes.arrayOf(PropTypes.shape()),
  folderName: PropTypes.string.isRequired,
  setSearchFilterInTabs: PropTypes.func,
  onSelect: PropTypes.func,
  inPost: PropTypes.bool
};

StockPhotoGrid.defaultProps = {
  classes: {},
  filteredStockPhotos: null,
  setSearchFilterInTabs: () => {},
  onSelect: () => {},
  inPost: false
};

export default withStyles(styles)(StockPhotoGrid);
