import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import SmartFiltersForm from 'components/SmartFiltersForm';
import PostAccountSelector from 'components/PostAccountSelector';
import { LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER } from 'gql/linktoken';
import { CREATE_RECREATE_COST_REPORT, UPDATE_RECREATE_COST_REPORT } from 'gql/recreateCostReport';
import { mapOptions } from 'util/social';
import AlembicPageHeader from 'components/AlembicPageHeader';
import { showToast } from 'contexts/ToastContext';
import { CostToRecreateAnalyticsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import { withRouter } from 'react-router-dom';
import AlembicModalConfirm from 'components/AlembicModalConfirm';
import { TextField, Typography } from '@material-ui/core';
import { COST_TO_RECREATE_FIELDS as FIELDS, getCostToRecreateFields } from './costToRecreateFields';
/**
 * @summary Custom wrapper around SmartFiltersForm that lets users select more advanced filtering options when creating a Cost To Recreate report.
 * @prop {Object} history - passed down from React Router, used to redirect user to the right component.
 * @prop {Function} fieldsCallback - passed from parent a function to lift fields state back up
 * @returns - React JSX
 */
const CostToRecreateAdvancedView = ({ history, fieldsCallback }) => {
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [selectedLinkedAccounts, setSelectedLinkedAccounts] = useState([]);
  const [finalFieldValues, setFinalFieldValues] = useState({});
  const [initialFields, setInitialFields] = useState({});
  const [existingFieldObj, setExistingFieldObj] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const { data } = useQuery(LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER, {
    variables: { showOnlyEnabled: true },
    fetchPolicy: 'network-only'
  });

  const [createCtrReport, { data: createCtrData, error }] = useMutation(
    CREATE_RECREATE_COST_REPORT
  );

  const [updateCtrReport, { data: updateCtrData, error: updateError }] = useMutation(
    UPDATE_RECREATE_COST_REPORT
  );

  // format the fields.
  const finalFields = getCostToRecreateFields(FIELDS);

  useEffect(() => {
    if (data?.linkedAccounts) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const linkedAccountsInParams = urlParams.get('linkedAccounts').split(',');

      // the accounts were already filtered in Basic Modal Form. Show only filtered selection.
      if (linkedAccountsInParams) {
        const filteredAccounts = data.linkedAccounts.filter(account =>
          linkedAccountsInParams.includes(account.id)
        );

        setSelectedLinkedAccounts(mapOptions(filteredAccounts, false));
      }

      setLinkedAccounts(mapOptions(data.linkedAccounts, false));
    }
  }, [data]);

  useEffect(() => {
    if (createCtrData) {
      showToast('Success! Your report was created.', 'success');

      goToRoute(CostToRecreateAnalyticsPath, history);
    }
    if (error) {
      showToast(`There was an error. Please try again. ${error.message}`, 'error');
    }
  }, [createCtrData, error]);

  useEffect(() => {
    if (updateCtrData) {
      showToast('Success! Your report was updated.', 'success');

      goToRoute(CostToRecreateAnalyticsPath, history);
    }
    if (updateError) {
      showToast(`There was an error. Please try again. ${updateError.message}`, 'error');
    }
  }, [updateCtrData, updateError]);

  // save url params to pre-fill certain fields.
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const reportId = urlParams.get('reportId');
    let isFilter = urlParams.get('isFilter');
    const name = urlParams.get('name');

    // convert strings to booleans. String 'false' will be true if using js Boolean conversion.
    if (isFilter === 'true') isFilter = true;
    if (isFilter === 'false' || !isFilter) isFilter = false;

    const requiredFields = {
      reportId,
      isFilter,
      name,
      enabled: true
    };

    // save mandatory fields to state.
    setInitialFields(requiredFields);

    // the only fields that are displayed in SmartFiltersForm
    const fieldObjFromParams = {
      id: urlParams.get('id'),
      account: urlParams.get('linkedAccounts'),
      image_tag: urlParams.get('imageTags'),
      media_type: urlParams.get('mediaTypes'),
      post_text_keyword: urlParams.get('postTextKeywords'),
      post_type: urlParams.get('postTypes'),
      video_tag: urlParams.get('videoTags')
    };

    setExistingFieldObj(fieldObjFromParams);
  }, []);

  // linkedAccounts isn't passed from smartFiltersForm as it's a custom component.
  // Each time user updates linked accounts, update the final object.
  useEffect(() => {
    const linkedAccountsObj = selectedLinkedAccounts?.map(account => account.id);

    setFinalFieldValues({ ...finalFieldValues, linkedAccounts: linkedAccountsObj });
  }, [selectedLinkedAccounts]);

  // send any changes on form back up to parent tagsCallback func
  useEffect(() => {
    fieldsCallback(finalFieldValues);
  }, [finalFieldValues]);

  const handleSelectAccounts = accounts => setSelectedLinkedAccounts(accounts);

  // The Account field is a custom component, so it's value is not passed back from SmartFiltersForm via the fields prop.
  // The selected linktokens are stored in this state. Add them to the final object.
  const formatFields = (fields, selectedFields) => {
    const selectedFieldNames = Object.values(selectedFields).map(v => v.name);

    const finalFieldsObj = { ...finalFieldValues };
    // make sure the field is selected (b/c it may be filled out but unchecked, then we don't want to include it.)
    Object.values(fields.current).forEach(v => {
      if (selectedFieldNames.includes(v.name)) {
        finalFieldsObj[v.name] = v.value;
      }
    });

    // format fields for mutation
    const formattedObj = {
      linkedAccounts: selectedLinkedAccounts?.map(account => account.id),
      postTextKeywords: finalFieldsObj.post_text_keyword || null,
      imageTags: finalFieldsObj.image_tag || null,
      videoTags: finalFieldsObj.video_tag || null,
      mediaTypes: finalFieldsObj.media_type || null,
      postTypes: finalFieldsObj.post_type || null,
      enabled: true
    };

    setFinalFieldValues(formattedObj);
  };

  const createReport = () => {
    // combine all required fields for the call.
    const finalObj = {
      ...initialFields,
      ...finalFieldValues
    };

    // creating or updating report.
    if (existingFieldObj?.id) {
      updateCtrReport({ variables: { id: existingFieldObj.id, ...finalObj } });
    } else {
      createCtrReport({ variables: finalObj });
    }
  };

  return (
    <div>
      <AlembicPageHeader
        backButton
        pageTitle={`${existingFieldObj?.id ? 'Edit' : 'Create'} Media Valuation Report`}
        buttonTitle={existingFieldObj?.id ? 'Save' : 'Create'}
        buttonOnClick={() => setConfirmModal(true)}
      />
      <SmartFiltersForm
        hidden={false}
        fields={finalFields}
        displayActionButton={false}
        updateCurrentSearchFields={formatFields}
        costToRecreateExistingFilter={existingFieldObj}
      >
        <PostAccountSelector
          singleAccountData={linkedAccounts}
          singleSelectedAccounts={selectedLinkedAccounts}
          handleSelectAccount={handleSelectAccounts}
          useIconDisplay
        />
      </SmartFiltersForm>
      {confirmModal && (
        <AlembicModalConfirm
          isOpen={confirmModal}
          title={`${existingFieldObj?.id ? 'Editing' : 'Creating New'} Media Valuation Report`}
          handleConfirm={createReport}
          confirmTitle={`${existingFieldObj?.id ? 'Save' : 'Create'} Report`}
          handleCancel={() => setConfirmModal(false)}
          containerWidth="400px"
          body={
            <div style={{ marginTop: '10px' }}>
              <Typography style={{ marginBottom: '10px' }}>
                Please confirm the name of the report before saving.
              </Typography>
              <TextField
                value={initialFields.name}
                variant="outlined"
                fullWidth
                onChange={e => setInitialFields({ ...initialFields, name: e.target.value })}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

CostToRecreateAdvancedView.propTypes = {
  history: PropTypes.shape().isRequired,
  fieldsCallback: PropTypes.func
};

CostToRecreateAdvancedView.defaultProps = {
  fieldsCallback: null
};

export default withRouter(CostToRecreateAdvancedView);
