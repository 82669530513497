import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbTable from 'components/AlbTable/AlbTable';
import Box from 'components/Box';
import colors from 'util/colors';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    background: '#FFFFFF',
    boxShadow: 'inset 0px 20px 13px -13px rgba(0, 0, 0, 0.1)',
    margin: '25px 15px',
    marginTop: '0px'
  },
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  company: {
    fontSize: '13px',
    fontWeight: 600,
    color: colors.navy
  }
});

const tableThemeOverrides = {
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap',

      '&:nth-child(-n+2)': {
        width: 'unset'
      }
    },
    sortAction: {
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  }
};

/**
 * @method
 * @summary This component renders the table displaying article searches and article search filters and their article count
 * @name ShareOfVoiceBreakdown
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const ShareOfVoiceBreakdown = props => {
  const { articleSearches, articleSearchFilters } = props;
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortField, setSortField] = useState('article_count');

  useEffect(() => {
    let formattedTableData = [];

    const formattedArticleSearches = articleSearches.map(articleSearch => {
      const { id, name, getArticleShareCountByPlatform } = articleSearch;

      /* eslint-disable camelcase */
      return {
        id,
        name,
        article_count: getArticleShareCountByPlatform?.total_articles ?? 0
        // total_share_count: getArticleShareCountByPlatform?.total_totals_share_count ?? 0,
        // facebook_share_count: getArticleShareCountByPlatform?.total_facebook_share_count ?? 0,
        // twitter_share_count: getArticleShareCountByPlatform?.total_twitter_share_count ?? 0
      };
      /* eslint-enable camelcase */
    });

    const formattedSearchFilters = articleSearchFilters.map(filter => {
      const { id, article_search_id: articleSearchId, getArticleShareCountByPlatform } = filter;

      const name = `Search ${articleSearchId} Filter id: ${id}`;
      /* eslint-disable camelcase */
      return {
        id,
        name,
        article_count: getArticleShareCountByPlatform?.total_articles ?? 0
        // total_share_count: getArticleShareCountByPlatform?.total_totals_share_count ?? 0,
        // facebook_share_count: getArticleShareCountByPlatform?.total_facebook_share_count ?? 0,
        // twitter_share_count: getArticleShareCountByPlatform?.total_twitter_share_count ?? 0
      };
      /* eslint-enable camelcase */
    });

    formattedTableData = [...formattedArticleSearches, ...formattedSearchFilters];

    setTableData(formattedTableData);
  }, [articleSearches, articleSearchFilters]);

  const customCenterRender = content => {
    const text = typeof content === 'number' ? Math.round(content).toLocaleString() : content;

    return <div align="center">{text}</div>;
  };

  const customCompanyRender = company => <span className={classes.company}>{company}</span>;

  const centeredColumnOptions = {
    customBodyRender: customCenterRender,
    setCellHeaderProps: () => ({ className: classes.centeredHeader })
  };

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'name',
      label: 'COMPANY',
      options: { sort: false, customBodyRender: customCompanyRender }
    },
    // {
    //   name: 'facebook_share_count',
    //   label: 'FACEBOOK SHARES',
    //   options: centeredColumnOptions
    // },
    // {
    //   name: 'twitter_share_count',
    //   label: 'TWITTER SHARES',
    //   options: centeredColumnOptions
    // },
    // {
    //   name: 'total_share_count',
    //   label: 'TOTAL SHARES',
    //   options: centeredColumnOptions
    // },
    {
      name: 'article_count',
      label: 'ARTICLE COUNT',
      options: centeredColumnOptions
    }
  ];

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'standard',
    pagination: false,
    draggableColumns: { enabled: true, transitionTime: 300 },
    sortOrder: { name: sortField, direction: sortOrder.toLowerCase() },
    onColumnSortChange: (changedColumn, direction) => {
      setSortOrder(direction.toUpperCase());
      setSortField(changedColumn);
    },
    textLabels: {
      body: {
        noMatch: 'No article reports found'
      }
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <BoxHeader tabs="Share of Company Impact Breakdown" breakdown />
        <AlbTable
          tableData={tableData}
          tableColumns={tableColumns}
          tableOptions={tableOptions}
          tableThemeOverrides={tableThemeOverrides}
        />
      </Box>
    </>
  );
};

ShareOfVoiceBreakdown.propTypes = {
  articleSearches: PropTypes.arrayOf(PropTypes.shape()),
  articleSearchFilters: PropTypes.arrayOf(PropTypes.shape())
};

ShareOfVoiceBreakdown.defaultProps = {
  articleSearches: [],
  articleSearchFilters: []
};

export default ShareOfVoiceBreakdown;
