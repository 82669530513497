import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Button } from '@material-ui/core';

const styles = makeStyles({
  selectedRole: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  }
});

const RolesList = props => {
  const { currentTab, roleTypes, rolesList, selectedRole, onCreate, onSelect } = props;

  const classes = styles();

  return (
    <List>
      {rolesList &&
        rolesList
          .filter(role => role.role_type === roleTypes)
          .map(role => (
            <ListItem
              key={role.id}
              button
              onClick={() => onSelect(role)}
              className={role.id === selectedRole.id ? classes.selectedRole : ''}
            >
              <ListItemText>{role.name}</ListItemText>
            </ListItem>
          ))}
      {currentTab === 1 && (
        <ListItem>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onCreate()}
          >
            + Add Role
          </Button>
        </ListItem>
      )}
    </List>
  );
};

RolesList.propTypes = {
  currentTab: PropTypes.number.isRequired,
  roleTypes: PropTypes.string.isRequired,
  rolesList: PropTypes.arrayOf(PropTypes.shape()),
  selectedRole: PropTypes.shape(),
  onCreate: PropTypes.func,
  onSelect: PropTypes.func.isRequired
};

RolesList.defaultProps = {
  rolesList: [],
  selectedRole: {},
  onCreate: null
};

export default RolesList;
