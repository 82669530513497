import { gql } from '@apollo/client';

export const GET_ANNOTATIONS_FOR_CONTAINER = gql`
  query annotationsForContainer($startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    annotationsForContainer(startDate: $startDate, endDate: $endDate) {
      id
      message
      message_type
      start_date
      end_date
    }
  }
`;

export const GET_PAGINATED_ANNOTATIONS = gql`
  query paginatedAnnotations(
    $after: Int
    $count: Int
    $orderBy: [String]
    $typeFilter: String!
    $searchKeyword: String
  ) {
    paginatedAnnotations(
      after: $after
      count: $count
      orderBy: $orderBy
      typeFilter: $typeFilter
      searchKeyword: $searchKeyword
    ) {
      rows {
        id
        message
        start_date
        end_date
        coincidentEvents {
          id
          coinc_start_date
          coinc_end_date
          detectedEvents {
            id
            event_type
            event_start_date
            event_end_date
            magnitude
            mag_unit
            mag_base
            is_complete
            channel
            event_label
            eventInfo {
              postBody
              sourceName
            }
            linktoken {
              id
              remote_name
            }
            campaignEvent {
              id
              linked_account_id
              completed_at
              published_url
            }
          }
        }
      }
      count
    }
  }
`;

export const CREATE_ANNOTATION_FOR_CONTAINER = gql`
  mutation createAnnotationForContainer(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $message: String!
  ) {
    createAnnotationForContainer(startDate: $startDate, endDate: $endDate, message: $message) {
      id
      message
      start_date
      end_date
    }
  }
`;

export const UPDATE_ANNOTATION = gql`
  mutation updateAnnotation(
    $id: ID!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $message: String!
  ) {
    updateAnnotation(id: $id, startDate: $startDate, endDate: $endDate, message: $message) {
      id
      message
      start_date
      end_date
    }
  }
`;

export const DELETE_ANNOTATION = gql`
  mutation deleteAnnotation($id: ID!) {
    deleteAnnotation(id: $id) {
      result
      message
    }
  }
`;
