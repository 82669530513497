import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

const useStyles = makeStyles({
  rangeButtons: {
    display: 'flex'
  },
  rangeButton: {
    minWidth: 0,
    padding: '3px 6px',

    '& > span': {
      fontSize: '10px',
      fontWeight: 600
    }
  }
});

/**
 * @method
 * @summary Renders buttons for changing the date range of an associated graph
 * @name DateRangeOptions
 * @param {Object} props - React props passed to this component
 * @param {Object[]} props.dateRangeOptions - An array of objects containing date labels and configurations for the buttons
 * @param {Number} props.selectedDateRange - The index of currently selected date option from dateRangeOptions
 * @param {Function} props.setSelectedDateRange - A function to select a specified date range to provide to the graph query
 * @return {Object} - React JSX
 */
const DateRangeOptions = props => {
  const { dateRangeOptions, selectedDateRange, setSelectedDateRange } = props;

  const classes = useStyles();

  return (
    <Grid item className={classes.rangeButtons}>
      {dateRangeOptions.map((range, i) => (
        <Button
          classes={{
            root: classes.rangeButton
          }}
          key={range.label}
          variant={selectedDateRange === i ? 'contained' : 'text'}
          style={{ ...(!selectedDateRange === i && { color: '#6F6F6F' }) }}
          color={selectedDateRange === i ? 'primary' : 'default'}
          onClick={() => setSelectedDateRange(i)}
        >
          {range.label}
        </Button>
      ))}
    </Grid>
  );
};

DateRangeOptions.propTypes = {
  dateRangeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDateRange: PropTypes.number.isRequired,
  setSelectedDateRange: PropTypes.func.isRequired
};

export default DateRangeOptions;
