/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar, defaults } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { merge } from 'lodash';
import colors from 'util/colors';
import Box from 'components/Box';
import { graphPresets, horizontalAgeLabels } from 'components/AnalyticsSocial/AnalyticsGraphConsts';

merge(defaults, { global: { defaultFontFamily: 'Poppins' } });

const useStyles = makeStyles({
  graphContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& canvas': {
      width: '100% !important'
    },
    '@media print': {
      '& canvas': {
        height: '100% !important'
      }
    }
  }
});

const PostDrawerAgeBar = props => {
  const { data } = props;
  const classes = useStyles();

  const [dataSets, setDataSets] = useState([]);
  const contextRef = useRef();

  useEffect(() => {
    if (data.length) {
      const [total, ...ranges] = data;

      const { total: totalValue } = total;

      const formattedData = ranges.map(({ column, total: singleValue }, i) => {
        const percentage = Math.round((singleValue / totalValue) * 100);

        return {
          label: column,
          data: horizontalAgeLabels.includes(column) ? [percentage] : [0],
          ...graphPresets[`barHorizontal${i + 1}`]?.palette
        };
      });

      setDataSets(formattedData);
    }
  }, [data]);

  const graphData = canvas => {
    contextRef.current = canvas.getContext('2d');

    return { labels: [], datasets: dataSets };
  };

  const legend = {
    display: false,
    onClick: event => {
      event.stopPropagation();
    },
    position: 'bottom',
    labels: { fontColor: '#6F6F6F' }
  };

  const tooltips = { enabled: false };

  const hover = {
    mode: 'nearest',
    intersect: true,
    animationDuration: 0
  };

  const animation = {
    onComplete: ({ chart }) => {
      const ctx = contextRef.current;

      if (chart.data.datasets.length) {
        chart.data.datasets.forEach(({ data: dataPoint, _meta }) => {
          const id = Object.keys(_meta)[0];

          _meta[id].data.forEach(({ _view }, i) => {
            const { x, y, base } = _view;
            const value = `${Math.round(dataPoint[i])}%`;
            const width = x - base;

            if (width < 20) {
              return;
            }

            ctx.fillStyle = 'white';
            ctx.fillText(value, x - (width / 2 + 8), y);
          });
        });
      }
    },
    duration: 0
  };

  const xAxes = [
    {
      gridLines: {
        color: colors.gray,
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false,
        autoSkip: true,
        max: 100,
        maxRotation: 0,
        fontColor: colors.darkGray,
        fontSize: 14
      },
      stacked: true
    }
  ];

  const yAxes = [
    {
      gridLines: {
        color: colors.gray,
        display: false,
        drawBorder: false
      },
      ticks: {
        fontColor: colors.darkGray,
        callback: value => `${Math.round(Math.abs(value), 1)}%`,
        precision: 2
      },
      stacked: true
    }
  ];

  const options = {
    legend,
    tooltips,
    hover,
    animation,
    scales: { xAxes, yAxes },
    maintainAspectRatio: false
  };

  return (
    <Box className={classes.graphContainer} style={{ maxHeight: 150 }}>
      <HorizontalBar data={graphData} options={options} />
    </Box>
  );
};

PostDrawerAgeBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default PostDrawerAgeBar;
