import React from 'react';
import PropTypes from 'prop-types';

const ObserveIcon = props => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_25)">
        <path
          d="M15.511 0.972376C22.124 0.972376 27.8363 6.14365 29.6598 7.9779C27.8363 9.83425 22.124 14.9834 15.511 14.9834C8.89794 14.9834 3.18568 9.83425 1.34018 8C3.18568 6.14365 8.89794 0.972376 15.511 0.972376ZM15.511 0C6.94259 0 0 8 0 8C0 8 6.94259 16 15.511 16C24.0794 16 31.022 8 31.022 8C31.022 8 24.0794 0 15.511 0Z"
          fill={color}
        />
        <path
          d="M15.511 4.59669C17.3785 4.59669 18.8944 6.12155 18.8944 8C18.8944 9.87845 17.3785 11.4033 15.511 11.4033C13.6435 11.4033 12.1276 9.87845 12.1276 8C12.1276 6.12155 13.6435 4.59669 15.511 4.59669ZM15.511 3.58011C13.0723 3.58011 11.1169 5.56906 11.1169 8C11.1169 10.4309 13.0943 12.4199 15.511 12.4199C17.9277 12.4199 19.905 10.4309 19.905 8C19.905 5.56906 17.9277 3.58011 15.511 3.58011Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_25">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

ObserveIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

ObserveIcon.defaultProps = {
  width: 31,
  height: 16,
  color: '#FEFEFE'
};

export default ObserveIcon;
