import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import LegendItem from './LegendItem';

const useStyles = makeStyles({
  legend: {
    '& > :not(:last-child)': {
      marginRight: '30px'
    }
  }
});

const Legend = props => {
  const { items } = props;
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.legend}>
      {items.map(item => {
        return (
          <LegendItem key={`${item.color}-${item.label}`} color={item.color} label={item.label} />
        );
      })}
    </Box>
  );
};

Legend.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Legend;
