/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CausalTimeline from './CausalTimeline';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  headingNumber: {
    fontWeight: 'bold'
  },
  accordion: {
    marginBottom: '30px'
  },
  footerButton: {
    margin: '1em 0',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  }
}));

/**
 * @method
 * @summary This component renders the accordion
 * @name CausalAccordion
 * @return {Object} - React JSX
 */

const CausalAccordion = props => {
  const { justifyData, expandJustify } = props;
  const classes = useStyles();

  // Function to extract revenue from the causalChain title
  const extractRevenue = title => {
    const match = title.match(/\$([\d,]+\.\d+)/);
    return match ? parseFloat(match[1].replace(/,/g, '')) : 0;
  };

  // Sort justifyData by revenue extracted from causalChain.title
  const sortedData =
    justifyData?.length > 0
      ? [...justifyData].sort((a, b) => extractRevenue(b.title) - extractRevenue(a.title))
      : [];

  return (
    <div className={classes.root}>
      {sortedData?.length > 0 &&
        sortedData.map((causalChain, i) => (
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                <span className={classes.headingNumber}>{i + 1}.</span> {causalChain.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <CausalTimeline causalChainSummary={causalChain.summary} />
                <Grid container justifyContent="center">
                  <Button
                    className={classes.footerButton}
                    variant="contained"
                    onClick={e => expandJustify(e, causalChain)}
                  >
                    JUSTIFY
                  </Button>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

CausalAccordion.propTypes = {
  justifyData: PropTypes.arrayOf(PropTypes.shape()),
  expandJustify: PropTypes.func.isRequired
};

CausalAccordion.defaultProps = {
  justifyData: null
};

export default CausalAccordion;
